import React, { createContext, useEffect, useRef, useState } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Products from './components/pages/Products';
import Details from './components/pages/Details';
import SignUp from './components/pages/SignUp';
import Account from './components/pages/Account';
import FAQ from './components/pages/FAQ';
import Contact from './components/pages/Contact';
import AboutUs from './components/pages/AboutUs';
import Career from './components/pages/Career';
import TermOfService from './components/pages/TermOfService';
import ThirdPartyServices from './components/pages/ThidPartyServices';
import HowItWork from './components/pages/HowItWork'

// import 'font-awesome/css/font-awesome.min.css';
import { AuthContextProvider } from './AuthContent';
import { ChatContextProvider } from './components/ChatContext'
import Profile from './components/pages/Profile';
import BusinessCard from './components/pages/BusinessCard';
import ProductsResidential from './components/pages/ProductsResidential';
import ResidentialDetails from './components/pages/ResidentialDetails';
import ProductsCommercialPurchase from './components/pages/ProductsCommercialPurchase';
import ProductsResidentialPurchase from './components/pages/ProductsResidentialPurchase'
import ResidentialDetailsPurchase from './components/pages/ResidentialDetailsPurchase';
import CommercialDetailsPurchase from './components/pages/CommercialDetailsPurchase';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import NotAvailable from './components/pages/NotAvailable';
import Explore from './components/pages/Explore';
import Deals from './components/pages/Deals';
import AgentTab from './components/pages/AgentTab';
import AgentProfileDetails from './components/pages/AgentProfileDetails';
import HomePageBoth from './components/pages/HomePageBoth';
import JobPage from './components/pages/JobPage';
import RentalDetailPage from './components/pages/RentalDetailPage'
import AccountDeletion from './components/pages/AccountDeletion';
import KebunBaruHome from './components/pages/KebunBaruHome';
function App() {
  const [mobileClick, setMobileClick] = useState(null);
  const [backToDefaultOwnerDirectory, setBackToDefaultOwnerDirectory] = useState(false);
  const navbarRef = useRef(null);

  const subdomain = window.location.hostname.split('.')[1]; // Assumes structure like subdomain.domain.com


  useEffect(() =>{
    console.log(subdomain)
  },[subdomain])


  return (
    <>
    
      <AuthContextProvider>
        <ChatContextProvider>
          <BrowserRouter>
            <Navbar setMobileClick={setMobileClick} navbarRef={navbarRef} setBackToDefaultOwnerDirectory={setBackToDefaultOwnerDirectory} />
            <Routes>
            {subdomain === 'kebunbarucommunity' ? (
          // Define the routing for the kebunbarucommunity subdomain
          <>
            <Route path="/" element={<KebunBaruHome />} />
          </>
        ) :(
          <>
              {/* <Route path='/AgentDirectory/Details/*' element={<AgentProfileDetails></AgentProfileDetails>} ></Route> */}
              {/* <Route path="/Deals" element={<Deals></Deals>} /> */}
              {/* <Route path="/AgentDirectory" element={<AgentTab></AgentTab>} /> */}
              <Route path="/OwnerDirectory" element={<Explore backToDefaultOwnerDirectory={backToDefaultOwnerDirectory} setBackToDefaultOwnerDirectory={setBackToDefaultOwnerDirectory}></Explore>} />
              <Route path="/NotAvailable" element={<NotAvailable />} />
              <Route path="/businessCard" element={<BusinessCard />} />
              <Route path="/account" element={<Account mobileClick={mobileClick} setMobileClick={setMobileClick} />} />
              <Route path="/rental-detail/:projectId" element={<RentalDetailPage></RentalDetailPage>}></Route>
              {/* <Route index element={<HomePageBoth />} /> */}
              {/* <Route path="/properties" element={<Home navbarRef={navbarRef} />} /> */}
              <Route index element={<Home navbarRef={navbarRef} />} />
              <Route path="/deleteAccount" element={<AccountDeletion />} />
              <Route path="/jobs" element={<JobPage />} />
              <Route path="/commercialRentDetails/*" element={<Details />} />
              <Route path="/residentialRentDetails/*" element={<ResidentialDetails />} />
              <Route path="/residentialPurchaseDetails/*" element={<ResidentialDetailsPurchase></ResidentialDetailsPurchase>} />
              <Route path="/commercialPurchaseDetails/*" element={<CommercialDetailsPurchase />} />

              <Route path="/profile/*" element={<Profile />} />
              <Route path='/commercialRent' element={<Products />} />
              <Route path='/commercialPurchase' element={<ProductsCommercialPurchase />} />
              <Route path='/residentialRent' element={<ProductsResidential />} />
              <Route path='/residentialPurchase' element={<ProductsResidentialPurchase />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/FAQ" element={<FAQ />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/Careers" element={<Career />} />
              <Route path="/TermOfService" element={<TermOfService />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/services" element={<ThirdPartyServices />} />
              <Route path="/guides" element={<HowItWork />} />
              </>
        )}
            </Routes>
          </BrowserRouter>
        </ChatContextProvider>
      </AuthContextProvider>

    </>
  );
}

export default App;
