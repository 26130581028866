import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import '../../App.css';
import '../css/NewProject.css';
import { UserAuth } from '../../AuthContent';
import { Link } from 'react-router-dom';
import { doc, setDoc, addDoc, collection, updateDoc, getDoc, getDocs, deleteDoc, deleteField } from "firebase/firestore";
import { db, storage } from '../../firebase/firebase';
import { getDownloadURL, ref, uploadBytes, listAll, getStorage, deleteObject } from 'firebase/storage';
import Acknowledge from './Acknowledge'
import DefaultImage from './DefaultImage';
import Timestamp from 'firebase-firestore-timestamp';
import axios from 'axios';
import CropImage from './CropImage';
import Warning from './Warning';
import LoaderPage from './LoaderPage';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import ExistingPhoto from './ExistingPhoto';
import { type } from '@testing-library/user-event/dist/type';
import Spinner from './Spinner';
import { isDisabled } from '@testing-library/user-event/dist/utils';



export default function NewProject(props) {

  const { user } = UserAuth();
  const [defaultImage, setDefaultImage] = useState(false);
  const [imageUpload, setImageUpload] = useState(null);
  const [defaultUpload, setDefaultUpload] = useState([]);
  const [clickConfirm, setClickConfirm] = useState(false);
  const [leasingDayMin, setLeasingDayMin] = useState(1);
  const [leasingDayMax, setLeasingDayMax] = useState(1);
  const [existingProject, setExistingProject] = useState(null);
  const checkImageRef = useRef();
  const [crop, setCrop] = useState(null);
  const [existingListingAmount, setExistingListingAmount] = useState(0);
  const [cropClick, setCropClick] = useState(false);
  const [address, setAddress] = useState(null);
  const [portrait, setPortrait] = useState([]);
  const [landscape, setLandscape] = useState([]);
  const [selfUploadArr, setSelfUploadArr] = useState([]);
  const [uploadedImageRemove, setUploadedImageRemove] = useState([]);
  const [warning, setWarning] = useState(false);
  const [dualRoleAgent, setDualRoleAgent] = useState(null);
  var moreDetailsID = useRef(null);
  var modifyListingData = useRef(null);
  // var oldListingValue = useRef(0);
  var currentTab = useRef(0);
  const [acknowledgeNormalNoDelete, setAcknowledgeNormalNoDelete] = useState(false);
  const [residentialType, setResidentialType] = useState("Individual");
  const [compositeTenant, setCompositeTenant] = useState("NA");
  const [residentialSmoke, setResidentialSmoke] = useState("No");
  const [residentialRelocation, setResidentialRelocation] = useState("Local Relocation");
  const [continueWarningState, setContinueWarningState] = useState(null);
  const [deckUpload, setDeckUpload] = useState(null);
  const [productPricing, setProductPricing] = useState(null);
  const [additionInformationToggle, setAdditionInformationToggle] = useState(false);
  const [typeOfSpace, setTypeOfSpace] = useState("Commercial");

  const [openLoader, setOpenloader] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [walkInBreakdown, setWalkInBreakdown] = useState(50);
  const [localBreakdown, setLocalBreakdown] = useState(50);
  const [paidUpCapital, setPaidUpCapital] = useState('< 100K');
  const [regularBreakdown, setRegularBreakdown] = useState(50);
  const [touristBreakdown, setTouristBreakdown] = useState(50);
  const [productPricingAutoFill, setProductPricingAutoFill] = useState(null);
  const [deckUploadAutoFill, setDeckUploadAutoFill] = useState(null);
  const [minLeaseNum, setMinLeaseNum] = useState(1);
  const [minLeasePeriod, setMinLeasePeriod] = useState('Days');
  const [maxLeaseNum, setMaxLeaseNum] = useState(1);
  const [maxLeasePeriod, setMaxLeasePeriod] = useState('Days');
  const [autoFill, setAutoFill] = useState(null);
  const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
  const [acknowledgeMessage, setAcknowledgeMessage] = useState("");
  const [confirmedDelete, setConfirmedDelete] = useState(false);
  const [businessName, setBusinessName] = useState(null);
  const [important1, setImportant1] = useState(null);
  const [important2, setImportant2] = useState(null);
  const [important3, setImportant3] = useState(null);
  const [important4, setImportant4] = useState(null);
  const [important5, setImportant5] = useState(null);
  const [acuquisitionSpace, setAcuquisationSpace] = useState(null);
  const [usp, setUSP] = useState(null);
  const MAX_CHARACTERS_SMALL = 100;
  const MAX_CHARACTERS_SMALL_RESIDENTIAL = 23;
  const MAX_CHARACTERS_BIG = 300;
  const [imageUploadTempURL, setImageUploadTempURL] = useState(null);
  const [selfUploadArrTempURL, setSelfUploadArrTempURL] = useState(null);
  const [landscapeTempURL, setLandscapeTempURL] = useState(null);
  const [alreadyImageUpload, setAlreadyImageUpload] = useState([]);
  const [activateExistingPhoto, setActivateExistingPhoto] = useState(false);
  const [listingGender, setListingGender] = useState("Mixed");
  const [floorLevel, setFloorLevel] = useState("Ground");
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [minFloorSize, setMinFloorSize] = useState(null);
  const [maxFloorSize, setMaxFloorSize] = useState(null);
  const [bedrooms, setBedrooms] = useState(1);
  const [entireUnit, setEntireUnit] = useState(false);
  const [furnishingType, setFurnishingType] = useState("Unfurnished");
  const [leaseTermResidential, setLeaseTermResidential] = useState("1 Year");
  const [propertyTypeResidential, setPropertyTypeResidential] = useState("Any");
  const [additionalRemarksResidential, setAdditionalRemarksResidential] = useState(null);
  const [specificPropertyType, setSpecificPropertyType] = useState(null);
  const [residentialGender, setResidentialGender] = useState("Male");
  const [residentialAge, setResidentialAge] = useState("< 21");
  const [residentialNationality, setResidentialNationality] = useState("SINGAPORE");
  const [residentialOccupation, setResidentialOccupation] = useState("");
  const [residentialEmployment, setResidentialEmployment] = useState("NA");
  const [residentialYearsRent, setResidentialYearsRent] = useState("< 1 year");
  const [residentialExpectedStay, setResidentialExpectedStay] = useState("< 2 years");
  const [currentUserInfo, setCurrentUserInfo] = useState(null);

  const district = [
    ['Boat Quay', 'Chinatown', 'Havelock Road', 'Marina Square', 'Raffles Place', 'Suntec City'],
    ['Anson Road', 'Chinatown', 'Neil Road', 'Raffles Place', 'Shenton Way', 'Tanjong Pagar'],
    ['Alexandra Road', 'Tiong Bahru', 'Queenstown'],
    ['Keppel', 'Mount Faber', 'Sentosa', 'Telok Blangah'],
    ['Buona Vista', 'Dover', 'Pasir Panjang', 'West Coast'],
    ['City Hall', 'High Street', 'North Bridge Road'],
    ['Beach Road', 'Bencoolen Road', 'Bugis', 'Rochor'],
    ['Little India', 'Farrer Park', 'Serangoon Road'],
    ['Cairnhill', 'Killiney', 'Leonie Hill', 'Orchard', 'Oxley'],
    ['Balmoral', 'Bukit Timah', 'Grange Road', 'Holland', 'Orchard Boulevard', 'River Valley', 'Tanglin Road'],
    ['Chancery', 'Bukit Timah', 'Dunearn Road', 'Newton'],
    ['Balestier', 'Moulmein', 'Novena', 'Toa Payoh'],
    ['Potong Pasir', 'Machpherson'],
    ['Eunos', 'Geylang', 'Kembangan', 'Paya Lebar'],
    ['Katong', 'Marine Parade', 'Siglap', 'Tanjong Rhu'],
    ['Bayshore', 'Bedok', 'Chai Chee'],
    ['Changi', 'Loyang', 'Pasir Ris'],
    ['Pasir Ris', 'Simei', 'Tampines'],
    ['Hougang', 'Punggol', 'Sengkang'],
    ['Ang Mo Kio', 'Bishan', 'Braddell Road', 'Thomson'],
    ['Clementi', 'Upper Bukit Timah', 'Hume Avenue'],
    ['Boon Lay', 'Jurong', 'Tuas'],
    ['Bukit Batok', 'Choa Chu Kang', 'Hillview Avenue', 'Upper Bukit Timah'],
    ['Kranji', 'Lim Chu Kang', 'Sungei Gedong', 'Tengah'],
    ['Admiralty', 'Woodlands'],
    ['Tagore', 'Yio Chu Kang'],
    ['Admiralty', 'Sembawang', 'Yishun'],
    ['Seletar', 'Yio Chu Kang']
  ];
  const districtAreaCode = ['D01 City - Business District', 'D02 City - Business District', 'D03 Central South', 'D04 South', 'D05 South West', 'D06 City - Business District', 'D07 City',
    'D08 Central', 'D09 Central - Orchard', 'D10 Central - Near Orchard', 'D11 Central - Near Orchard', 'D12 Central', 'D13 Central East', 'D14 Central East',
    'D15 East Coast', 'D16 Upper East Coast', 'D17 Far East', 'D18 Far East', 'D19 North East', 'D20 Central North', 'D21 Central West', 'D22 Far West', 'D23 North West',
    'D24 Far North West', 'D25 Far North', 'D26 North', 'D27 Far North', 'D28 North East'];

  // const defaultImgArr = ['Bakery|./Default/F&B/Bakery/Bakery I.jpeg|true', 'Bakery|./Default/F&B/Bakery/Bakery II.jpeg|false',
  //   'Asian|./Default/F&B/Asian/Asian Food I.jpg|true', 'Asian|./Default/F&B/Asian/Chinese Roast.jpg|false', 'Asian|./Default/F&B/Asian/Dim Sum I.jpg|true', 'Asian|./Default/F&B/Asian/Dim Sum II.jpg|true', 'Asian|./Default/F&B/Asian/Indian Food I.jpg|true', 'Asian|./Default/F&B/Asian/Indian Food II.jpg|true', 'Asian|./Default/F&B/Asian/Japanese Sashimi I.jpg|true', 'Asian|./Default/F&B/Asian/Korean BBQ II.jpg|true', 'Asian|./Default/F&B/Asian/Korean BBQ.jpg|true', 'Asian|./Default/F&B/Asian/Korean I.jpg|true', 'Asian|./Default/F&B/Asian/Satay I.jpg|true', 'Asian|./Default/F&B/Asian/Satay II.jpg|true', 'Asian|./Default/F&B/Asian/Satay III.jpg|true', 'Asian|./Default/F&B/Asian/Sushi I.jpg|true', 'Asian|./Default/F&B/Asian/Thai Cuisine.jpeg|true', 'Asian|./Default/F&B/Asian/Vietnamese Pho I.jpg|true', 'Asian|./Default/F&B/Asian/Vietnamese Pho II.jpg|true',
  //   'Bar|./Default/F&B/Bar/Bar I.jpeg|true', 'Bar|./Default/F&B/Bar/Bar II.jpg|true', 'Bar|./Default/F&B/Bar/Wine Bar I.jpg|true', 'Bar|./Default/F&B/Bar/Wine Bar II.jpg|true', 'Bar|./Default/F&B/Bar/Wine Bar III.jpg|true',
  //   'Western|./Default/F&B/Western/Burger I.jpg|true', 'Western|./Default/F&B/Western/Clams.jpg|true', 'Western|./Default/F&B/Western/Pasta I.jpeg|true', 'Western|./Default/F&B/Western/Pasta II.jpeg|true', 'Western|./Default/F&B/Western/Pasta III.jpeg|true', 'Western|./Default/F&B/Western/Pasta Pizza I.jpg|true', 'Western|./Default/F&B/Western/Pizza I.jpeg|true', 'Western|./Default/F&B/Western/Pizza II.jpeg|true', 'Western|./Default/F&B/Western/Pizza III.jpeg|true', 'Western|./Default/F&B/Western/Ribs I.jpg|true', 'Western|./Default/F&B/Western/Soup I.jpeg|true', 'Western|./Default/F&B/Western/Steakhouse I.jpeg|true', 'Western|./Default/F&B/Western/Steakhouse II.jpg|true', 'Western|./Default/F&B/Western/Steakhouse III.jpg|true', 'Western|./Default/F&B/Western/Western I.jpg|true',
  //   'Cafe|./Default/F&B/Cafe/Cafe I.jpeg|true', 'Cafe|./Default/F&B/Cafe/Cafe II.jpeg|true', 'Cafe|./Default/F&B/Cafe/Cafe III.jpg|true', 'Cafe|./Default/F&B/Cafe/Cafe IV.jpg|false', 'Cafe|./Default/F&B/Cafe/Cafe V.jpg|true', 'Cafe|./Default/F&B/Cafe/Cafe VI.jpg|false', 'Cafe|./Default/F&B/Cafe/Waffle Ice Cream.jpg|true',
  //   'Cakery|./Default/F&B/Cakery/Cakery I.jpeg|false', 'Cakery|./Default/F&B/Cakery/Cakery II.jpeg|false', 'Cakery|./Default/F&B/Cakery/Cakery III.jpeg|true', 'Cakery|./Default/F&B/Cakery/Cakery IV.jpeg|true', 'Cakery|./Default/F&B/Cakery/Cakery V.jpeg|true',
  //   'Art|./Default/Retail/Art/Art Gallery I.jpg|true', 'Art|./Default/Retail/Art/Art Gallery II.jpg|true', 'Art|./Default/Retail/Art/Pottery I.jpg|false', 'Art|./Default/Retail/Art/Pottery II.jpeg|true', 'Art|./Default/Retail/Art/Pottery III.jpg|false', 'Art|./Default/Retail/Art/Vase I.jpeg|true',
  //   'Book Store|./Default/Retail/Book Store/Book Store I.jpeg|true', 'Book Store|./Default/Retail/Book Store/Stationery I.jpg|true', 'Book Store|./Default/Retail/Book Store/Stationery II.jpg|true', 'Book Store|./Default/Retail/Book Store/Stationery III.jpg|true',
  //   'Bottle Shop|./Default/Retail/Bottle Shop/Bottle Shop I.jpeg|true', 'Bottle Shop|./Default/Retail/Bottle Shop/Bottle Shop II.jpeg|true', 'Bottle Shop|./Default/Retail/Bottle Shop/Bottle Shop III.jpeg|true', 'Bottle Shop|./Default/Retail/Bottle Shop/Craft Beer Store I.jpeg|true', 'Bottle Shop|./Default/Retail/Bottle Shop/Craft Beer Store II.jpeg|true', 'Bottle Shop|./Default/Retail/Bottle Shop/Sake Store I.jpeg|true', 'Bottle Shop|./Default/Retail/Bottle Shop/Sake Store II.jpeg|true']
  useEffect(() => {
    if (props.trigger) {
      if (continueWarningState == null) {
        if (props.accountView == "Commercial") {
          setTypeOfSpace("Commercial");
        }
        else {
          setTypeOfSpace("Residential");
        }
      }
      if (props.homeRequirements != null) {
        console.log(props.homeRequirements)
        if (props.homeRequirements.electedRoom == "WholeUnit") {
          setEntireUnit(true);
        }
        else if (props.homeRequirements.electedRoom == "OneUnit") {
          setBedrooms(1);
        }
        else if (props.homeRequirements.electedRoom == "TwoUnit") {
          setBedrooms(2);
        }
        else if (props.homeRequirements.electedRoom == "ThreeUnit") {
          setBedrooms(3);
        }
        else if (props.homeRequirements.electedRoom == "FourUnit") {
          setBedrooms(4);
        }
        document.getElementById('priceOverall').value = props.homeRequirements.budgetTenant
        document.getElementById('locationListing').value = props.homeRequirements.tenantArea

      }

    }
  }, [props])

  useEffect(() => {
    console.log(props);
  }, [props])

  const showAddPropertyQuestionMark = () => {
    const notApprovedText = document.getElementById(`showAddPropertyQuestionMark`);
    if (notApprovedText.style.display == "none") {
      notApprovedText.style.display = "block";
    }
    else {
      notApprovedText.style.display = "none";
    }
  }

  const handleListingGender = (e) => {
    setListingGender(e.target.value);
  }

  const handleFloorLevel = (e) => {
    setFloorLevel(e.target.value);
  }

  const handleChildren = (e) => {
    if (parseInt(adults) + parseInt(e.target.value) >= 7) {
      alert("Maximum Capacity of 6 personnel")
    }
    else {
      setChildren(e.target.value);
    }
  }

  const handleMinFloorSize = (e) => {
    if (e.target.value == "") {
      setMinFloorSize(null);
    }
    else {
      if (parseInt(e.target.value) >= 0) {
        setMinFloorSize(e.target.value);
      }
      else {
        alert("Invalid Value");
      }
    }


  }

  const handleTypeOfSpaceChange = (e) => {
    setTypeOfSpace(e.target.value);
    setBusinessName("");
    if (e.target.value == "Residential") {
      if (currentUserInfo != null) {
        if (currentUserInfo.hasOwnProperty("residentialTenantProfile")) {
          setCompositeTenant(currentUserInfo.residentialTenantProfile.compositeTenant);
          setResidentialRelocation(currentUserInfo.residentialTenantProfile.relocation);
          setResidentialAge(currentUserInfo.residentialTenantProfile.residentialAge);
          setResidentialEmployment(currentUserInfo.residentialTenantProfile.residentialEmployment);
          setResidentialNationality(currentUserInfo.residentialTenantProfile.residentialNationality);
          setResidentialOccupation(currentUserInfo.residentialTenantProfile.residentialOccupation);
          setResidentialType(currentUserInfo.residentialTenantProfile.residentialType);
          setResidentialYearsRent(currentUserInfo.residentialTenantProfile.residentialYearsRent);
          setResidentialSmoke(currentUserInfo.residentialTenantProfile.smoke);
        }
      }
    }
  }

  const handleTypeLeaseChange = (e) => {
    setResidentialType(e.target.value);
  }

  const handleCompositeTenant = (e) => {
    setCompositeTenant(e.target.value);
  }

  const handleSmokeChange = (e) => {
    setResidentialSmoke(e.target.value);
  }

  const handleResidentialRelocation = (e) => {
    setResidentialRelocation(e.target.value);
  }

  const handleResidentialYearsRent = (e) => {
    setResidentialYearsRent(e.target.value);
  }

  const handleResidentialExpectedStay = (e) => {
    setResidentialExpectedStay(e.target.value);
  }

  const handleResidentialGender = (e) => {
    setResidentialGender(e.target.value);
  }

  const handleResidentialAge = (e) => {
    setResidentialAge(e.target.value);
  }

  const handleResidentialNationality = (e) => {
    setResidentialNationality(e.target.value);
  }

  const handleResidentialOccupation = (e) => {
    setResidentialOccupation(e.target.value);
  }

  const handleResidentialEmployment = (e) => {
    setResidentialEmployment(e.target.value);
  }

  const handleSpecificPropertyType = (e) => {
    setSpecificPropertyType(e.target.value);
  }

  const handleMaxFloorSize = (e) => {
    if (e.target.value == "") {
      setMaxFloorSize(null);
    }
    else {
      if (parseInt(e.target.value) >= 0) {
        setMaxFloorSize(e.target.value);
      }
      else {
        alert("Invalid Value");
      }
    }


  }

  const handleBedrooms = (e) => {
    setBedrooms(e.target.value);
  }

  const handleFurnishingType = (e) => {
    setFurnishingType(e.target.value);
  }

  const handleLeaseTermResidential = (e) => {
    setLeaseTermResidential(e.target.value);
  }

  const handleAdditionalRemarksResidential = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS_BIG) {
      setAdditionalRemarksResidential(e.target.value);
    }
  }

  const handleAdults = (e) => {
    if (parseInt(e.target.value) + parseInt(children) >= 7) {
      alert("Maximum Capacity of 6 personnel")
    }
    else {
      setAdults(e.target.value);
    }

  }

  const handlePropertyTypeResidential = (e) => {
    if (e.target.value == "HDB") {
      setSpecificPropertyType("1 Room");
      setPropertyTypeResidential(e.target.value);
    }
    else if (e.target.value == "Condominium") {
      setSpecificPropertyType("Condominium");
      setPropertyTypeResidential(e.target.value);
    }
    else if (e.target.value == "Landed") {
      setSpecificPropertyType("Detached House");
      setPropertyTypeResidential(e.target.value);
    }
    else {
      setSpecificPropertyType(null);
      setPropertyTypeResidential(e.target.value);
    }





  }

  const handleMinLeaseNumChanges = (e) => {
    setMinLeaseNum(e.target.value);
  }

  const handleMinLeasePeriodChanges = (e) => {
    if (maxLeaseNum == "NA") {
      setMinLeaseNum(1);
      setMinLeasePeriod(e.target.value);
    }
    else {
      if (e.target.value == "Months" && maxLeasePeriod == "Days") {
        setMaxLeasePeriod("Months");
      }
      else if (e.target.value == "Years") {
        setMaxLeasePeriod("Years");
      }
      setMinLeaseNum(1);
      setMinLeasePeriod(e.target.value);
    }

  }

  const handleMaxLeaseNumChanges = (e) => {
    if (e.target.value == "NA") {
      setMaxLeaseNum("NA");
    }
    else {
      if (maxLeasePeriod == minLeasePeriod) {
        if (e.target.value < minLeaseNum) {
          alert("Maximum Lease Terms cannot be lesser than Minimum Lease Terms")
        }
        else {
          setMaxLeaseNum(e.target.value);
        }
      }
      else if (maxLeasePeriod == "Years" && minLeasePeriod != "Years") {
        setMaxLeaseNum(e.target.value);
      }
      else if (maxLeasePeriod == "Months") {
        if (minLeasePeriod == "Days") {
          setMaxLeaseNum(e.target.value);
        }
        else {
          alert("Maximum Lease Terms cannot be lesser than Minimum Lease Terms")
        }
      }
    }



  }

  const handleMaxLeasePeriodChanges = (e) => {
    if (minLeasePeriod == "Months" && e.target.value == "Days") {
      alert("Maximum Lease Terms cannot be lesser than Minimum Lease Terms")
    }
    else if (minLeasePeriod == "Years" && (e.target.value == "Days" || e.target.value == "Months")) {
      alert("Maximum Lease Terms cannot be lesser than Minimum Lease Terms")
    }
    else {
      setMaxLeaseNum(minLeaseNum);
      setMaxLeasePeriod(e.target.value);
    }

  }

  const handleChangesCompanyName = (e) => {
    setAutoFill('Auto-Fill');
    setCompanyName(e.target.value);
  }

  const handleBusinessName = (e) => {
    if (typeOfSpace == "Commercial") {
      if (e.target.value.length <= MAX_CHARACTERS_SMALL) {
        setBusinessName(e.target.value);
      }
    }
    else {
      if (e.target.value.length <= MAX_CHARACTERS_SMALL) {
        setBusinessName(e.target.value);
      }
    }


  }

  const uploadSelfImage = useRef(null);

  const handleImportant1Change = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS_SMALL) {
      setImportant1(e.target.value);
    }
  }

  const handleImportant2Change = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS_SMALL) {
      setImportant2(e.target.value);
    }
  }

  const handleImportant3Change = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS_SMALL) {
      setImportant3(e.target.value);
    }
  }

  const handleImportant4Change = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS_SMALL) {
      setImportant4(e.target.value);
    }
  }

  const handleImportant5Change = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS_SMALL) {
      setImportant5(e.target.value);
    }
  }

  const handleAcuquisationSpace = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS_SMALL) {
      setAcuquisationSpace(e.target.value);
    }
  }

  const handleUSPChange = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS_BIG) {
      setUSP(e.target.value);
    }

  }

  const handleChangesWalkInBreakdown = (e) => {
    const newWalkIn = parseInt(e.target.value);
    const newRegular = 100 - newWalkIn;
    setWalkInBreakdown(newWalkIn);
    setRegularBreakdown(newRegular);
    setAutoFill('Auto-Fill');
  }

  const handleChangeslocalBreakdown = (e) => {
    const localBreakdown = parseInt(e.target.value);
    const touristBreakdown = 100 - localBreakdown;
    setTouristBreakdown(touristBreakdown);
    setLocalBreakdown(localBreakdown);
    setAutoFill('Auto-Fill');
  }

  const handlePaidUpChanges = (e) => {
    setPaidUpCapital(e.target.value);
    setAutoFill('Auto-Fill');
  }

  const handleChangesRegular = (e) => {
    const newRegular = parseInt(e.target.value);
    const newWalkIn = 100 - newRegular;
    setWalkInBreakdown(newWalkIn);
    setRegularBreakdown(newRegular);
    setAutoFill('Auto-Fill');
  }
  const handleChangesTourist = (e) => {
    const touristBreakdown = parseInt(e.target.value);
    const localBreakdown = 100 - touristBreakdown;
    setTouristBreakdown(touristBreakdown);
    setLocalBreakdown(localBreakdown);
    setAutoFill('Auto-Fill');
  }



  const handleAutoFillChanges = (e) => {
    if (props.accountBusinessArray.length > 0 && props.accountBusinessArray != null && e.target.value != "Auto-Fill") {
      setAutoFill(props.accountBusinessArray[e.target.value].companyName);
      setCompanyName(props.accountBusinessArray[e.target.value].companyName);
      setPaidUpCapital(props.accountBusinessArray[e.target.value].paidUpCapital);
      setWalkInBreakdown(props.accountBusinessArray[e.target.value].walkInBreakdown);
      setRegularBreakdown(props.accountBusinessArray[e.target.value].regularBreakdown);
      setLocalBreakdown(props.accountBusinessArray[e.target.value].localBreakdown);
      setTouristBreakdown(props.accountBusinessArray[e.target.value].touristBreakdown);
      setProductPricingAutoFill(props.accountBusinessArray[e.target.value].productPricing);
      setDeckUploadAutoFill(props.accountBusinessArray[e.target.value].deckUpload);
      setAutoFill(e.target.value);
    }
    else {
      setAutoFill('Auto-Fill');
    }
  }

  useEffect(() => {
    if (confirmedDelete == true) {
      if (props.accountView == "Commercial") {
        deleteListing();
      }
      else {
        deleteListingResidential();
      }

    }
  }, [confirmedDelete])




  const showTab = (n) => {
    var allStepper = document.getElementsByClassName("stepper_tab");
    allStepper[n].style.display = "block";
    if (n == 0) {
      document.getElementById("prevBtn").style.display = "none";
    } else {
      document.getElementById("prevBtn").style.display = "inline";
    }
    if (n == (allStepper.length - 1)) {
      document.getElementById("nextBtn").style.display = "none";
    } else {
      document.getElementById("nextBtn").style.display = "inline";
    }

    fixStepIndicator(n);
  }

  const fixStepIndicator = (n) => {
    var x = document.getElementsByClassName("step");
    for (var i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(" activeStep", "");
    }
    //... and adds the "active" class on the current step:
    x[n].className += " activeStep";
  }

  useEffect(() => {
    if (defaultUpload.length != 0 || imageUpload != null) {
      var portraitArr = [];
      if (defaultUpload.length != 0) {
        for (var i = 0; i < defaultUpload.length; i++) {
          var img = document.createElement('img');
          img.setAttribute('src', defaultUpload[i]);
          if (img.height > img.width) {
            portraitArr.push(defaultUpload[i]);
          }
        }

        for (var i = 0; i < portraitArr.length; i++) {
          for (var a = 0; a < defaultUpload.length; a++) {
            if (portraitArr[i] == defaultUpload[a]) {
              defaultUpload.splice(a, 1);
              break;
            }
          }
        }
        setPortrait(portraitArr);
        if (portraitArr.length != 0) {
          setCrop(true);
        }
      }
      else if (imageUpload != null) {
        callCheckImageUpload();
      }

    }
  }, [imageUpload, defaultUpload])

  useEffect(() => {
    if (user.hasOwnProperty("uid")) {
      getCurrentUserProfile();
    }
  }, [user])

  const getCurrentUserProfile = async () => {
    await getDoc(doc(db, `userInfo`, user.uid)).then((indivDoc) => {
      if (indivDoc.data().userType.includes("Agent")) {
        setDualRoleAgent("Agent");
      }
      setCurrentUserInfo(indivDoc.data());
    })
  }

  const callCheckImageUpload = async () => {
    var portraitArr = [];
    const fileListArr = Array.from(imageUpload);
    for (var i = (fileListArr.length - 1); i >= 0; i--) {
      var imageDimensions = await checkPortraitUpload(window.URL.createObjectURL(imageUpload[i]));
      if (imageDimensions.width < imageDimensions.height) {
        portraitArr.push(imageUpload[i]);
        fileListArr.splice(i, 1);
      }
    }
    setPortrait(portraitArr);
    setImageUpload(null);
    if (selfUploadArr.length != 0) {
      setSelfUploadArr(prevState => [...prevState, ...fileListArr]);
    }
    else {
      setSelfUploadArr(fileListArr);
    }

    if (portraitArr.length != 0) {
      setCrop(true);
    }

  }

  const checkPortraitUpload = (url) => {
    const img = document.createElement("img");

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        // Natural size is the actual image size regardless of rendering.
        // The 'normal' `width`/`height` are for the **rendered** size.
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Resolve promise with the width and height
        resolve({ width, height });
      };

      // Reject promise on error
      img.onerror = reject;
    });

    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;

    return promise;
  }

  const getModifyListing = async (id) => {
    var photoArr = [];
    var moreDetails;
    const docSnap = await getDoc(doc(db, `projects`, id));
    const querySnapshot = await getDocs(collection(db, `projects/${id}/MoreDetails`));

    querySnapshot.forEach((doc) => {
      moreDetailsID.current = doc.id;
      moreDetails = doc.data();
    })

    modifyListingData.current = docSnap.data();
    var existingProjectData = docSnap.data();
    setBusinessName(existingProjectData.listingName);
    setImportant1(moreDetails.important_1);
    setImportant2(moreDetails.important_2);
    setImportant3(moreDetails.important_3);
    setImportant4(moreDetails.important_4);
    setImportant5(moreDetails.important_5);

    if (currentUserInfo != null) {
      if (currentUserInfo.userType.includes("Agent") && currentUserInfo.userType.includes("Tenant")) {
        if (existingProjectData.propertyAgent == true) {
          setDualRoleAgent("Agent");
        }
        else {
          setDualRoleAgent("Tenant");
        }
      }
      else if (currentUserInfo.userType.includes("Agent")) {
        setDualRoleAgent("Agent");
      }
      else {
        setDualRoleAgent(null);
      }
    }
    var tempUrl = [];
    for (var i = 0; i < 5; i++) {
      if (existingProjectData.hasOwnProperty(`url${i}`)) {
        tempUrl.push({ tempUrl: existingProjectData[`url${i}`], id: i })
      }
    }
    setAlreadyImageUpload(tempUrl);
    // document.getElementById('listingName').value = ;
    // document.getElementById('important1').value = ;
    // document.getElementById('important2').value = ;
    // document.getElementById('important3').value = ;
    // document.getElementById('important4').value = ;
    // document.getElementById('important5').value = ;
    document.getElementById('pricePerSquareFeet').value = existingProjectData.pricePerSquareFeet;
    document.getElementById('priceOverall').value = existingProjectData.priceOverall;
    document.getElementById('districtType').value = existingProjectData.district;
    document.getElementById('locationListing').value = existingProjectData.location;
    document.getElementById('postalCode').value = existingProjectData.postalCode;
    const date = new Timestamp(moreDetails.periodMoveIn.seconds, moreDetails.periodMoveIn.nanoseconds).toDate();
    var year = date.getFullYear();
    if ((date.getMonth() + 1) < 10) {
      var month = "0" + (date.getMonth() + 1);
    }
    else {
      var month = (date.getMonth() + 1);
    }
    if (date.getDate() < 10) {
      var day = "0" + (date.getDate());
    }
    else {
      var day = date.getDate();
    }


    document.getElementById('periodMoveIn').value = year + "-" + month + "-" + day;
    document.getElementById('propertyType').value = existingProjectData.propertyType;
    document.getElementById('minSpace').value = moreDetails.minSpace;
    document.getElementById('maxSpace').value = moreDetails.maxSpace;
    var leaseMinSplit = (existingProjectData.leaseTermMin).split(' ');
    setMinLeaseNum(parseInt(leaseMinSplit[0]));
    setMinLeasePeriod(leaseMinSplit[1]);
    var leaseMaxSplit = (existingProjectData.leaseTermMax).split(' ');
    setMaxLeaseNum(parseInt(leaseMaxSplit[0]));
    setMaxLeasePeriod(leaseMaxSplit[1]);
    // document.getElementById('leaseMax').value = leaseMaxSplit[1];
    // setLeasingDayMax(leaseMaxSplit[1]);
    document.getElementById('yearsOfOperations').value = moreDetails.yearsOfOperation;
    setAcuquisationSpace(moreDetails.acuquisitionSpace);
    // document.getElementById('reasonForAcuquisition').value = moreDetails.acuquisitionSpace;
    setUSP(moreDetails.usp.replace(/<br>/g, '\n'));
    // document.getElementById('usp').value = ;
    if (moreDetails.additionInformation == "Start Ups") {
      document.getElementById('StartUps').checked = true;
    }
    else if (moreDetails.additionInformation == "Relocation") {
      document.getElementById('Relocation').checked = true;
    }
    else if (moreDetails.additionInformation == "Expansion") {
      document.getElementById('Expansion').checked = true;
    }
    document.getElementById('ceilingHeight').value = moreDetails.minCeiling;

    var index = 0;
    while (existingProjectData[`url${index}`] != undefined) {
      photoArr.push(existingProjectData[`url${index}`]);
      index++;
    }


    // await valueOfOldBus().then((res) => { oldListingValue.current = res; console.log(res); })
    if (docSnap.data().preferredListing == true) {
      const preferredDetailsExist = await getDocs(collection(db, `projects/${id}/PreferredDetails`));
      preferredDetailsExist.docs.map((indivExist) => {
        setExistingProject({ ...docSnap.data(), ...moreDetails, ...indivExist.data() });
        if (indivExist.data().autoFillLink != null) {
          setPaidUpCapital(indivExist.data().paidUpCaptial)
          setCompanyName(indivExist.data().companyName);
          setTouristBreakdown(indivExist.data().clinteleBreakdownTourist);
          setLocalBreakdown(indivExist.data().clinteleBreakdownLocal);
          setRegularBreakdown(indivExist.data().revenueBreakdownRegular);
          setWalkInBreakdown(indivExist.data().revenueBreakdownWalkin);
          setDeckUploadAutoFill(indivExist.data().companyDeckURL);
          setProductPricingAutoFill(indivExist.data().product_pricing);
          document.getElementById('coporateTurnover').value = indivExist.data().coporateTurnover;
          document.getElementById('expectedRevenue').value = indivExist.data().expectedRevenue;
          document.getElementById('otherRental').value = indivExist.data().otherRental;
          if (indivExist.data().outletLocation != null) {
            document.getElementById('outletLocation').value = indivExist.data().outletLocation;
          }
          // setAutoFill(indivExist.data().autoFillLink); //got problem here
        }
        else {
          setPaidUpCapital(indivExist.data().paidUpCaptial)
          setCompanyName(indivExist.data().companyName);
          setTouristBreakdown(indivExist.data().clinteleBreakdownTourist);
          setLocalBreakdown(indivExist.data().clinteleBreakdownLocal);
          setRegularBreakdown(indivExist.data().revenueBreakdownRegular);
          setWalkInBreakdown(indivExist.data().revenueBreakdownWalkin);
          setDeckUploadAutoFill(indivExist.data().companyDeckURL);
          setProductPricingAutoFill(indivExist.data().product_pricing);
          document.getElementById('coporateTurnover').value = indivExist.data().coporateTurnover;
          document.getElementById('expectedRevenue').value = indivExist.data().expectedRevenue;
          document.getElementById('otherRental').value = indivExist.data().otherRental;
          if (indivExist.data().outletLocation != null) {
            document.getElementById('outletLocation').value = indivExist.data().outletLocation;
          }
        }
      })
    }
    else {
      setExistingProject({ ...docSnap.data(), ...moreDetails });
    }
  }

  const getModifyListingResidential = async (id) => {

    var moreDetails, preferredDetails;
    const docSnap = await getDoc(doc(db, `residentialListing`, id));
    const querySnapshot = await getDocs(collection(db, `residentialListing/${id}/MoreDetails`));
    const preferredDetailSnapshot = await getDocs(collection(db, `residentialListing/${id}/PreferredDetails`));

    querySnapshot.forEach((doc) => {
      moreDetailsID.current = doc.id;
      moreDetails = doc.data();
    })

    preferredDetailSnapshot.forEach((indivDoc) => {
      if (indivDoc.exists()) {
        setResidentialAge(indivDoc.data().residentialAge);
        setResidentialEmployment(indivDoc.data().residentialEmployment);
        setResidentialYearsRent(indivDoc.data().residentialYearsRent);
        setResidentialNationality(indivDoc.data().residentialNationality);
        setResidentialOccupation(indivDoc.data().residentialOccupation);
        setResidentialExpectedStay(indivDoc.data().residentialExpectedStay);
        setResidentialType(indivDoc.data().residentialType);
        setCompositeTenant(indivDoc.data().compositeTenant);
        setResidentialSmoke(indivDoc.data().smoke);
        setResidentialRelocation(indivDoc.data().relocation);
      }
      preferredDetails = indivDoc.data();
    })

    modifyListingData.current = docSnap.data();
    var existingProjectData = docSnap.data();
    setBusinessName(existingProjectData.listingName);
    setListingGender(moreDetails.tenantGender);
    setFloorLevel(moreDetails.floorLevel);
    setAdults(existingProjectData.adults);
    setChildren(existingProjectData.children);
    setMinFloorSize(moreDetails.minFloorSize);
    setMaxFloorSize(moreDetails.maxFloorSize);
    if (currentUserInfo != null) {
      if (currentUserInfo.userType.includes("Agent") && currentUserInfo.userType.includes("Tenant")) {
        if (existingProjectData.propertyAgent == true) {
          setDualRoleAgent("Agent");
        }
        else {
          setDualRoleAgent("Tenant");
        }
      }
      else if (currentUserInfo.userType.includes("Agent")) {
        setDualRoleAgent("Agent");
      }
      else {
        setDualRoleAgent(null);
      }
    }
    if (existingProjectData.bedrooms == "Entire Unit") {
      setEntireUnit(true);
    }
    else {
      setBedrooms(existingProjectData.bedrooms);
    }
    setFurnishingType(existingProjectData.furnishingType);
    setLeaseTermResidential(existingProjectData.leaseTermResidential);
    setPropertyTypeResidential(existingProjectData.propertyTypeResidential);
    setSpecificPropertyType(existingProjectData.specificPropertyType);
    setAdditionalRemarksResidential(moreDetails.additionalRemarksResidential.replace(/<br>/g, '\n'));

    document.getElementById('priceOverall').value = existingProjectData.priceOverall;
    document.getElementById('districtType').value = existingProjectData.district;
    document.getElementById('locationListing').value = existingProjectData.location;
    document.getElementById('postalCode').value = existingProjectData.postalCode;
    const date = new Timestamp(existingProjectData.periodMoveIn.seconds, existingProjectData.periodMoveIn.nanoseconds).toDate();
    var year = date.getFullYear();
    if ((date.getMonth() + 1) < 10) {
      var month = "0" + (date.getMonth() + 1);
    }
    else {
      var month = (date.getMonth() + 1);
    }
    if (date.getDate() < 10) {
      var day = "0" + (date.getDate());
    }
    else {
      var day = date.getDate();
    }

    document.getElementById('periodMoveIn').value = year + "-" + month + "-" + day;


    setExistingProject({ ...docSnap.data(), ...moreDetails, ...preferredDetails });

  }

  const deleteSelfUploadArr = (id) => {

    const oldImageUpload = Array.from(selfUploadArr);
    oldImageUpload.splice(id, 1);
    setSelfUploadArr(oldImageUpload);
  }

  const deleteLandscape = (id) => {

    const oldImageUpload = [...landscape];
    oldImageUpload.splice(id, 1);
    var newLandScapeTempURL = [...landscapeTempURL];
    newLandScapeTempURL.splice(id, 1);
    setLandscapeTempURL(newLandScapeTempURL);
    setLandscape(oldImageUpload);
  }

  const deleteExistingImageUpload = (imageURL, id) => {
    var newUploadImages = [...alreadyImageUpload];
    newUploadImages.splice(id, 1);
    setAlreadyImageUpload(newUploadImages);
    var localUploadedImageRemove = [...uploadedImageRemove];
    localUploadedImageRemove.push(imageURL);
    setUploadedImageRemove(localUploadedImageRemove);
  }

  useEffect(() => {
    if (!props.trigger) {

    }
    else {

      if (props.modifyListingId != null && props.accountView != null) {
        if (props.accountView == "Commercial") {

          setTypeOfSpace(props.accountView)
          getModifyListing(props.modifyListingId);
          valueOfBus();
        }
        else {
          setTypeOfSpace(props.accountView)
          getModifyListingResidential(props.modifyListingId);
          valueOfResidentialRent();
        }

      }
    }
  }, [props.modifyListingId, props.accountView])

  const valueOfBus = async () => {

    // const commonSnap = await getDoc(doc(db, "commonData", 'information'));
    const docSnap = await getDoc(doc(db, `projects`, props.modifyListingId));
    var maxLeaseNum = docSnap.data().leaseTermMax;
    maxLeaseNum = maxLeaseNum.split(' ');
    var multipler;
    if (maxLeaseNum[1] == "Days") {
      multipler = 1;
    }
    else if (maxLeaseNum[1] == "Months") {
      multipler = Number(maxLeaseNum[0]);
    }
    else if (maxLeaseNum[1] == "Years") {
      multipler = Number(maxLeaseNum[0]) * 12;
    }

    var answer = (docSnap.data().priceOverall * multipler);
    console.log(answer);
    setExistingListingAmount(answer);
  }

  const valueOfResidentialRent = async () => {

    const commonSnap = await getDoc(doc(db, "commonData", 'information'));
    const docSnap = await getDoc(doc(db, `residentialListing`, props.modifyListingId));
    var maxLeaseNum = docSnap.data().leaseTermResidential;
    var multipler;
    if (maxLeaseNum == "Short Term / Flexi") {
      multipler = 1;
    }
    else {
      // maxLeaseNum = maxLeaseNum.split(' ');

      if (docSnap.data().leaseTermResidential == "1 Year") {
        multipler = 1 * 12;
      }
      else if (maxLeaseNum[1] == "2 Years") {
        multipler = 2 * 12;
      }
      else if (maxLeaseNum[1] == "3 Years") {
        multipler = 3 * 12;
      }
    }
    // for (var i = 0; i < pendingListingSale.length; i++) {
    //   var maxPSFBudget = pendingListingSale[i].details.maxFloorSizePSFBudget;

    //   if (pendingListingSale[i].id == id) {
    var answer = (docSnap.data().priceOverall * multipler);
    console.log(answer)
    setExistingListingAmount(answer);
    //     resolve(Number(commonSnap.data().totalCompanyValue) + answer);
    //   }
    // }

  }

  useEffect(() => {
    if (!props.trigger) {
    }
    else {
      // if (typeOfSpace == "Commercial") {
      showTab(currentTab.current);
      // }

    }
  })


  useEffect(() => {
    if (continueWarningState != null) {
      if (continueWarningState == "new") {
        if (typeOfSpace == "Commercial") {
          confirmNewProject(false);
        }
        else {
          newResidentialListing();
        }

      }
      else if (continueWarningState == "modify") {
        // modifyListing();
      }
    }
  }, [warning])

  const newResidentialListing = async () => {

    if (businessName != null && businessName != "" && minFloorSize != null && minFloorSize != "") {
      setOpenloader(true);
      var cardInformation = {}
      var moreDetails = {
        floorLevel: floorLevel,
        minFloorSize: minFloorSize,
        maxFloorSize, maxFloorSize,

        tenantGender: listingGender,
        additionalRemarksResidential: document.getElementById('additionalRemarks').value.replace(/\n/g, '<br>'),
      }

      var currentStatus = "pending"
      if (props.currentUserInfo.userType.includes("Agent") == true && dualRoleAgent == "Agent" && props.publicInfo.agentProfile.verified == true) {
        currentStatus = "leasing"
      }
      if (entireUnit == true) {
        cardInformation = {
          UID: user.uid,
          listingName: businessName,
          furnishingType: furnishingType,
          adults: adults,
          children: children,
          location: document.getElementById('locationListing').value,
          district: document.getElementById('districtType').value,
          bedrooms: "Entire Unit",
          priceOverall: Number(document.getElementById('priceOverall').value),
          propertyTypeResidential: propertyTypeResidential,
          specificPropertyType: specificPropertyType,
          periodMoveIn: new Date(document.getElementById('periodMoveIn').value),
          status: currentStatus,
          eyeballStat: [0, 0, 0, 0, 0, 0, 0],
          connections: 0,
          timePosted: new Date(),
          boost: false,
          boostStart: null,
          boostLeft: 0,
          eyeball: 0,
          conversationRate: 0,
          preferredStatus: false,
          pendingChats: 0,
          username: props.publicInfo.username,
          viewID: null,
          report: 0,
          leaseTermResidential: leaseTermResidential,
          userImage: currentUserInfo.userPhotoURL,
          propertyAgent: dualRoleAgent == "Agent" ? true : false,
          propertyAgentCompany: dualRoleAgent == "Agent" ? props.publicInfo.hasOwnProperty('agentProfile') ? props.publicInfo.agentProfile.CEAAgency : null : null,
        }
      }
      else {
        cardInformation = {
          UID: user.uid,
          listingName: businessName,
          furnishingType: furnishingType,
          adults: adults,
          children: children,
          location: document.getElementById('locationListing').value,
          district: document.getElementById('districtType').value,
          bedrooms: bedrooms,
          priceOverall: Number(document.getElementById('priceOverall').value),
          propertyTypeResidential: propertyTypeResidential,
          specificPropertyType: specificPropertyType,
          periodMoveIn: new Date(document.getElementById('periodMoveIn').value),
          status: currentStatus,
          timePosted: new Date(),
          boost: false,
          boostStart: null,
          boostLeft: 0,
          connections: 0,
          eyeballStat: [0, 0, 0, 0, 0, 0, 0],
          eyeball: 0,
          postalCode: document.getElementById('postalCode').value == '' ? null : document.getElementById('postalCode').value,
          conversationRate: 0,
          preferredStatus: false,
          pendingChats: 0,
          username: props.publicInfo.username,
          viewID: null,
          report: 0,
          leaseTermResidential: leaseTermResidential,
          userImage: currentUserInfo.userPhotoURL,
          propertyAgent: dualRoleAgent == "Agent" ? true : false,
          propertyAgentCompany: dualRoleAgent == "Agent" ? props.publicInfo.hasOwnProperty('agentProfile') ? props.publicInfo.agentProfile.CEAAgency : null : null,
        }
      }
      if (residentialOccupation != "") {
        cardInformation.preferredStatus = true;
      }
      if (cardInformation.status == 'leasing') {
        var companyValue = await getDoc(doc(db, 'commonData', "information"));
        companyValue = companyValue.data().totalCompanyValue;
        if (leaseTermResidential == "Short Term / Flexi") {
          companyValue = companyValue + (Number(document.getElementById('priceOverall').value));
        }
        else if (leaseTermResidential == "1 Year") {
          companyValue = companyValue + (Number(document.getElementById('priceOverall').value) * 12);
        }
        else if (leaseTermResidential == "2 Years") {
          companyValue = companyValue + (Number(document.getElementById('priceOverall').value) * 12 * 2);
        }
        else if (leaseTermResidential == "3 Years") {
          companyValue = companyValue + (Number(document.getElementById('priceOverall').value) * 12 * 3);
        }

        let updatedSnapshot = await updateDoc(doc(db, 'commonData', "information"), {
          totalCompanyValue: companyValue
        })
      }

      await addDoc(collection(db, `residentialListing`), cardInformation).then(async function (docRef) {
        await addDoc(collection(db, `residentialListing/${docRef.id}/MoreDetails`), moreDetails).then(async () => {
          if (cardInformation.preferredStatus == true) {
            await addDoc(collection(db, `residentialListing/${docRef.id}/PreferredDetails`), {
              residentialAge: residentialAge,
              residentialEmployment: residentialEmployment,
              residentialYearsRent: residentialYearsRent,
              residentialNationality: residentialNationality,
              residentialExpectedStay: residentialExpectedStay,
              residentialType: residentialType,
              compositeTenant: compositeTenant,
              smoke: residentialSmoke,
              relocation: residentialRelocation,
              residentialOccupation: residentialOccupation
            }).then(async () => {
              const token = user.accessToken;

              //TEMP TAKE OUT
              // if (document.getElementById("postalCode").value != "") {
              //   const requestOptions = {
              //     method: 'POST',
              //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
              //     body: JSON.stringify({ data: { postalCode: document.getElementById("postalCode").value, listingUID: docRef.id} })
              //   };
              //   await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/nearbyListing', requestOptions).then(data => console.log(data)).then(
              //     error => console.log(error)
              //   );
              // }
              const requestOptions_1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                body: JSON.stringify({ data: { email: `info@nextloop.sg`, path: "New Listing" } })
              };
              await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                error => console.log(error)
              );
              setAcknowledgeMessage("Listing Added");
              setClickConfirm(true);
              setOpenloader(false);
            })
          }
          else {
            const token = user.accessToken;

            //TEMP TAKE OUT
            // if (document.getElementById("postalCode").value != "") {
            //   const requestOptions = {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            //     body: JSON.stringify({ data: { postalCode: document.getElementById("postalCode").value, listingUID: docRef.id} })
            //   };
            //   await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/nearbyListing', requestOptions).then(data => console.log(data)).then(
            //     error => console.log(error)
            //   );
            // }
            const requestOptions_1 = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
              body: JSON.stringify({ data: { email: `info@nextloop.sg`, path: "New Listing" } })
            };
            await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
              error => console.log(error)
            );
            setAcknowledgeMessage("Listing Added");
            setClickConfirm(true);
            setOpenloader(false);
          }
        })
      })


    }
    else {
      alert("Missing Parameter")
    }


  }

  const modifyResidentialListing = async () => {

    if (businessName != null && businessName != "" && minFloorSize != null && minFloorSize != "" && document.getElementById('periodMoveIn').value != "" && document.getElementById('priceOverall').value != "" && Number(document.getElementById('priceOverall').value) > 0) {
      setOpenloader(true);
      var cardInformation = {}
      if (existingProject.hasOwnProperty('viewID')) {
        var moreDetails = {
          floorLevel: floorLevel,
          minFloorSize: minFloorSize,
          maxFloorSize, maxFloorSize,
          tenantGender: listingGender,
          additionalRemarksResidential: document.getElementById('additionalRemarks').value.replace(/\n/g, '<br>'),
          viewID: existingProject.viewID
        }
      }
      else {
        var moreDetails = {
          floorLevel: floorLevel,
          minFloorSize: minFloorSize,
          maxFloorSize, maxFloorSize,
          tenantGender: listingGender,
          additionalRemarksResidential: document.getElementById('additionalRemarks').value.replace(/\n/g, '<br>'),
        }
      }



      if (entireUnit == true) {
        cardInformation = {
          UID: user.uid,
          listingName: businessName,
          furnishingType: furnishingType,
          adults: adults,
          children: children,
          location: document.getElementById('locationListing').value,
          district: document.getElementById('districtType').value,
          bedrooms: "Entire Unit",
          priceOverall: Number(document.getElementById('priceOverall').value),
          propertyTypeResidential: propertyTypeResidential,
          specificPropertyType: specificPropertyType,
          periodMoveIn: new Date(document.getElementById('periodMoveIn').value),
          status: modifyListingData.current.status == "rejected" ? "pending" : modifyListingData.current.status,
          connections: modifyListingData.current.connections,
          eyeballStat: modifyListingData.current.eyeballStat,
          timePosted: new Date(),
          boost: modifyListingData.current.boost,
          boostStart: modifyListingData.current.boostStart,
          boostLeft: modifyListingData.current.boostLeft,
          eyeball: modifyListingData.current.eyeball,
          conversationRate: modifyListingData.current.conversationRate,
          preferredStatus: modifyListingData.current.preferredStatus,
          pendingChats: modifyListingData.current.pendingChats,
          username: props.publicInfo.username,
          viewID: modifyListingData.current.viewID,
          report: modifyListingData.current.report,
          leaseTermResidential: leaseTermResidential,
          userImage: currentUserInfo.userPhotoURL,
          propertyAgent: dualRoleAgent == "Agent" ? true : false,
          propertyAgentCompany: dualRoleAgent == "Agent" ? props.publicInfo.hasOwnProperty('agentProfile') ? props.publicInfo.agentProfile.CEAAgency : null : null,
          modifyBefore: true,
          postalCode: document.getElementById('postalCode').value == '' ? null : document.getElementById('postalCode').value,
        }
      }
      else {
        cardInformation = {
          UID: user.uid,
          listingName: businessName,
          furnishingType: furnishingType,
          adults: adults,
          children: children,
          location: document.getElementById('locationListing').value,
          district: document.getElementById('districtType').value,
          bedrooms: bedrooms,
          priceOverall: Number(document.getElementById('priceOverall').value),
          propertyTypeResidential: propertyTypeResidential,
          specificPropertyType: specificPropertyType,
          periodMoveIn: new Date(document.getElementById('periodMoveIn').value),
          status: modifyListingData.current.status == "rejected" ? "pending" : modifyListingData.current.status,
          eyeballStat: modifyListingData.current.eyeballStat,
          connections: modifyListingData.current.connections,
          timePosted: new Date(),
          boost: modifyListingData.current.boost,
          boostStart: modifyListingData.current.boostStart,
          boostLeft: modifyListingData.current.boostLeft,
          eyeball: modifyListingData.current.eyeball,
          conversationRate: modifyListingData.current.conversationRate,
          preferredStatus: modifyListingData.current.preferredStatus,
          pendingChats: modifyListingData.current.pendingChats,
          username: props.publicInfo.username,
          viewID: modifyListingData.current.viewID,
          report: modifyListingData.current.report,
          leaseTermResidential: leaseTermResidential,
          modifyBefore: true,
          userImage: currentUserInfo.userPhotoURL,
          propertyAgent: dualRoleAgent == "Agent" ? true : false,
          propertyAgentCompany: dualRoleAgent == "Agent" ? props.publicInfo.hasOwnProperty('agentProfile') ? props.publicInfo.agentProfile.CEAAgency : null : null,
          postalCode: document.getElementById('postalCode').value == '' ? null : document.getElementById('postalCode').value,
        }
      }
      if (residentialOccupation != "") {
        cardInformation.preferredStatus = true;
      }
      const commonSnap = await getDoc(doc(db, "commonData", 'information'));
      var multipler = 1;
      if (leaseTermResidential == "Short Term / Flexi") {
        multipler = 1;
      }
      else {

        if (leaseTermResidential == "1 Year") {
          multipler = 1 * 12;
        }
        else if (leaseTermResidential == "2 Years") {
          multipler = 2 * 12;
        }
        else if (leaseTermResidential == "3 Years") {
          multipler = 3 * 12;
        }
      }
      var answer = (Number(document.getElementById('priceOverall').value) * multipler);
      var left = (commonSnap.data().totalCompanyValue - existingListingAmount) + answer;
      await updateDoc(doc(db, "commonData", 'information'), {
        totalCompanyValue: left
      });
      await updateDoc(doc(db, `residentialListing`, props.modifyListingId), cardInformation).then(async () => {
        const querySnapshot = await getDocs(collection(db, `residentialListing/${props.modifyListingId}/MoreDetails`));
        querySnapshot.forEach(async (docIndiv) => {
          if (docIndiv.exists()) {
            await updateDoc(doc(db, `residentialListing/${props.modifyListingId}/MoreDetails`, docIndiv.id), moreDetails).then(async () => {
              if (cardInformation.preferredStatus == true) {
                const querySnapshot = await getDocs(collection(db, `residentialListing/${props.modifyListingId}/PreferredDetails`));
                querySnapshot.forEach(async (docIndiv) => {
                  if (docIndiv.exists()) {
                    await updateDoc(doc(db, `residentialListing/${props.modifyListingId}/PreferredDetails`, docIndiv.id), {
                      residentialAge: residentialAge,
                      residentialEmployment: residentialEmployment,
                      residentialYearsRent: residentialYearsRent,
                      residentialNationality: residentialNationality,
                      residentialExpectedStay: residentialExpectedStay,
                      residentialType: residentialType,
                      compositeTenant: compositeTenant,
                      smoke: residentialSmoke,
                      relocation: residentialRelocation,
                      residentialOccupation: residentialOccupation
                    }).then(() => {
                      setAcknowledgeMessage("Listing Modified");
                      setClickConfirm(true);
                      setOpenloader(false);
                    })
                  }

                })
                if (querySnapshot.docs.length == 0) {
                  await addDoc(collection(db, `residentialListing/${props.modifyListingId}/PreferredDetails`), {
                    residentialAge: residentialAge,
                    residentialEmployment: residentialEmployment,
                    residentialYearsRent: residentialYearsRent,
                    residentialNationality: residentialNationality,
                    residentialExpectedStay: residentialExpectedStay,
                    residentialType: residentialType,
                    compositeTenant: compositeTenant,
                    smoke: residentialSmoke,
                    relocation: residentialRelocation,
                    residentialOccupation: residentialOccupation
                  }).then(() => {
                    setAcknowledgeMessage("Listing Modified");
                    setClickConfirm(true);
                    setOpenloader(false);
                  })
                }

              }
              else {
                setAcknowledgeMessage("Listing Modified");
                setClickConfirm(true);
                setOpenloader(false);
              }



            })
          }
        })

      })


    }
    else {
      alert("Missing Parameter")
    }
  }

  const modifyListing = async () => {
    setOpenloader(true);
    var allowName = false;
    var allowPerSquareFeet = false;
    var allowPriceOverall = false;
    var allowLocationMatch = true;
    var allowPeriodMoveIn = false;
    var allowImage = false;

    var listingName = document.getElementById('listingName');
    var pricePerSquareFeet = document.getElementById('pricePerSquareFeet');
    var priceOverall = document.getElementById('priceOverall');
    var districtType = document.getElementById('districtType');
    var locationAddress = document.getElementById('locationListing');
    var periodMoveIn = document.getElementById('periodMoveIn');
    if (listingName.value == '') {
      listingName.setAttribute('class', 'invalid');
    }
    else {
      listingName.setAttribute('class', 'newListingInput');
      allowName = true;
    }

    if (locationAddress.value != null) {
      var targetIndex = -1;
      var address = (locationAddress.value).toUpperCase();


      for (var i = 0; i < district.length; i++) {
        for (var a = 0; a < district[i].length; a++) {
          var target = district[i][a].toUpperCase();
          if (address.search(target) >= 0) {
            targetIndex = i;
            break;
          }
        }
      }
      if (districtAreaCode[targetIndex] != districtType.value && address != "") {
        districtType.setAttribute('class', 'invalid_special');
        locationAddress.setAttribute('class', 'invalid_special');
        allowLocationMatch = false;
      }
      else {
        districtType.setAttribute('class', 'newListingInput');
        locationAddress.setAttribute('class', 'newListingInput');
        allowLocationMatch = true;
      }
    }

    if (periodMoveIn.value == "") {
      periodMoveIn.setAttribute('class', 'invalid');
    }
    else {
      periodMoveIn.setAttribute('class', 'newListingInput');
      allowPeriodMoveIn = true;
    }

    if (pricePerSquareFeet.value == "" | Number(pricePerSquareFeet.value) <= 0) {
      pricePerSquareFeet.setAttribute('class', 'invalid_special');
      allowPerSquareFeet = false;
    }
    else {

      pricePerSquareFeet.setAttribute('class', 'newListingInput');
      allowPerSquareFeet = true;
    }

    if (priceOverall.value == "" | Number(priceOverall.value) <= 0) {
      priceOverall.setAttribute('class', 'invalid_special');
      allowPriceOverall = false;
    }
    else {

      priceOverall.setAttribute('class', 'newListingInput');
      allowPriceOverall = true;
    }
    if (selfUploadArr.length == 0 && defaultUpload.length == 0 && alreadyImageUpload.length == 0) {
      alert("Image not found");
    }
    else {
      allowImage = true
    }

    if (allowName == true && allowImage == true && allowPerSquareFeet == true && allowPeriodMoveIn == true && allowLocationMatch == true && allowPriceOverall == true) {

      var additionalInfo = document.querySelectorAll('[name="addtionalInfo"]');
      var additionInformation = null;
      for (var i = 0; i < additionalInfo.length; i++) {
        if (additionalInfo[i].checked == true) {
          additionInformation = additionalInfo[i].value;
        }
      }

      if (document.getElementById('periodMoveIn').value == "") {
        var periodMoveIn = null;
      }
      else {
        periodMoveIn = new Date(document.getElementById('periodMoveIn').value);
      }
      if (document.getElementById('minSpace').value === "") {
        var minSpace = null;
      }
      else {
        minSpace = Number(document.getElementById('minSpace').value);
      }
      if (document.getElementById('maxSpace').value == "") {
        var maxSpace = null;
      }
      else {
        maxSpace = Number(document.getElementById('maxSpace').value);
      }
      if (document.getElementById('yearsOfOperations').value == "") {
        var yearsOfOperation = null;
      }
      else if (document.getElementById('yearsOfOperations').value < 0) {
        alert("Negative values detected");
        setOpenloader(false);
        return;
      }
      else {
        yearsOfOperation = Number(document.getElementById('yearsOfOperations').value);
      }
      if (document.getElementById('ceilingHeight').value == "") {
        var ceilingHeight = null;
      }
      else if (document.getElementById('ceilingHeight').value < 0) {
        alert("Negative values detected");
        setOpenloader(false);
        return;
      }
      else {
        ceilingHeight = Number(document.getElementById('ceilingHeight').value);
      }
      var uploadPromisesDelete = [];
      if (uploadedImageRemove.length == 0) {
        const uploadPromiseDelete = new Promise((resolve, reject) => {
          resolve();
        })
        uploadPromisesDelete.push(uploadPromiseDelete)
      }
      else {
        for (var i = 0; i < uploadedImageRemove.length; i++) {
          var url = uploadedImageRemove[i];
          if (url.includes("https://ik.imagekit.io/NextLoop/Default")) {
            const uploadPromiseDelete = new Promise((resolve, reject) => {
              resolve();
            })
            uploadPromisesDelete.push(uploadPromiseDelete)
          }
          else {
            // Get the index of the path start
            var pathStartIndex = url.indexOf("/o/") + 3;

            // Get the index of the path end
            var pathEndIndex = url.indexOf("?alt=media");

            // Extract the path
            var path = url.substring(pathStartIndex, pathEndIndex);

            // Decode the URL-encoded characters
            var decodedPath = decodeURIComponent(path);

            // Get the file name
            var fileName = decodedPath.substring(decodedPath.lastIndexOf("/") + 1);




            const uploadPromiseDelete = new Promise((resolve, reject) => {


              // Upload watermarked image to Firebase Storage
              const storageRef = ref(storage, `${user.uid}/${props.modifyListingId}/${fileName}`);
              deleteObject(storageRef).then(() => {
                resolve();
              }).catch((error) => {
                console.log(error);
              })


            });

            uploadPromisesDelete.push(uploadPromiseDelete)
          }

        }
      }
      Promise.all(uploadPromisesDelete).then(async () => {
        // Create a reference under which you want to list
        var listStorageRef = ref(storage, `${user.uid}/${props.modifyListingId}).value}`);
        var croppedNumber = [0, 1, 2, 3, 4];

        // Find all the prefixes and items.
        listAll(listStorageRef)
          .then((resList) => {
            resList.prefixes.forEach((folderRef) => {
              // All the prefixes under listRef.
              // You may call listAll() recursively on them.
            });
            resList.items.forEach((itemRef) => {
              // All the items under listRef.
              console.log(itemRef.name);
              if (itemRef.name.search("cropped") >= 0) {
                var splitName = itemRef.name.split('_');
                croppedNumber.splice(splitName[1], 1);
              }

            });
          }).catch((error) => {
            // Uh-oh, an error occurred!
          });

        var readyURL = [];
        if (landscape.length != 0) {
          for (var i = 0; i < landscape.length; i++) {
            if (croppedNumber.length < 5) {
              var updatedFile = new File([landscape[i]], `cropped_${croppedNumber[i]}`, { type: landscape[i].type });
              var urlLinkReturn = await firebasestoragePicture(props.modifyListingId, updatedFile);
              if (urlLinkReturn == 'Image not accepted') {
                alert(`Image not accepted`);
                setOpenloader(false);
                return;
              }
              else {
                readyURL.push(urlLinkReturn);
              }
            }
            else {
              var urlLinkReturn = await firebasestoragePicture(props.modifyListingId, landscape[i]);
              if (urlLinkReturn == 'Image not accepted') {
                alert(`Image not accepted`);
                setOpenloader(false);
                return;
              }
              else {
                readyURL.push(urlLinkReturn);
              }
            }


          }
          // }
        }
        else if (defaultUpload.length != 0) {
          for (var i = 0; i < defaultUpload.length; i++) {
            readyURL.push(defaultUpload[i]);
          }
        }
        else if (selfUploadArr.length != 0) {
          for (var i = 0; i < selfUploadArr.length; i++) {
            var urlLinkReturn = await firebasestoragePicture(props.modifyListingId, selfUploadArr[i]);
            if (urlLinkReturn == 'Image not accepted') {
              alert(`Image not accepted`);
              setOpenloader(false);
              return;
            }
            else {
              readyURL.push(urlLinkReturn);
            }
          }
        }
        for (var i = 0; i < alreadyImageUpload.length; i++) {
          readyURL.push(alreadyImageUpload[i].tempUrl)
        }

        checkSecondPage().then(async (res) => {
          // var status = "pending";
          // if (props.hasOwnProperty('rejected')) {
          //   if (props.rejected == false) {
          //     status = modifyListingData.current.status;
          //   }
          // }
          // else {
          //   status = modifyListingData.current.status;
          // }

          var cardInformation = {
            UID: user.uid,
            listingName: document.getElementById('listingName').value,
            pricePerSquareFeet: Number(document.getElementById('pricePerSquareFeet').value),
            priceOverall: Number(document.getElementById('priceOverall').value),
            location: document.getElementById('locationListing').value,
            district: document.getElementById('districtType').value,
            status: modifyListingData.current.status == "rejected" ? "pending" : modifyListingData.current.status,
            modifyBefore: true,
            timePosted: new Date(),
            boost: modifyListingData.current.boost,
            boostStart: modifyListingData.current.boostStart,
            boostLeft: modifyListingData.current.boostLeft,
            click: modifyListingData.current.click,
            eyeballStat: modifyListingData.current.eyeballStat,
            eyeball: modifyListingData.current.eyeball,
            connections: modifyListingData.current.connections,
            conversationRate: modifyListingData.current.conversationRate,
            pendingChats: modifyListingData.current.pendingChats,
            preferredListing: res,
            propertyType: document.getElementById('propertyType').value,
            username: props.publicInfo.username,
            leaseTermMin: minLeaseNum + " " + minLeasePeriod,
            leaseTermMax: maxLeaseNum + " " + maxLeasePeriod,
            report: 0,
            propertyAgent: dualRoleAgent == "Agent" ? true : false,
            userImage: currentUserInfo.userPhotoURL,
            propertyAgentCompany: dualRoleAgent == "Agent" ? props.publicInfo.hasOwnProperty('agentProfile') ? props.publicInfo.agentProfile.CEAAgency : null : null,
            postalCode: document.getElementById('postalCode').value == '' ? null : document.getElementById('postalCode').value,
          }
          if (readyURL.length != 0) {
            for (var i = 0; i < readyURL.length; i++) {
              cardInformation["url" + i] = readyURL[i];
            }
          }
          else {
            var indexURL = 0;
            while (existingProject[`url${indexURL}`] != undefined) {
              cardInformation[`url${indexURL}`] = existingProject[`url${indexURL}`];
              indexURL++;
            }
          }
          if (existingProject.viewID != null) {
            var viewID = existingProject.viewID
          }
          else {
            var viewID = null;
          }
          var moreDetails = {

            important_1: document.getElementById('important1').value,
            important_2: document.getElementById('important2').value,
            important_3: document.getElementById('important3').value,
            important_4: document.getElementById('important4').value,
            important_5: document.getElementById('important5').value,
            periodMoveIn: periodMoveIn,
            minSpace: minSpace,
            maxSpace: maxSpace,
            yearsOfOperation: yearsOfOperation,
            minCeiling: ceilingHeight,
            additionInformation: additionInformation,
            acuquisitionSpace: document.getElementById('reasonForAcuquisition').value,
            usp: document.getElementById('usp').value.replace(/\n/g, '<br>'),
            viewID: viewID
          }
          const commonSnap = await getDoc(doc(db, "commonData", 'information'));
          var multipler = 1;
          if (maxLeasePeriod == "Days") {
            multipler = 1;
          }
          else if (maxLeasePeriod == "Months") {
            multipler = Number(maxLeaseNum);
          }
          else if (maxLeasePeriod == "Years") {
            multipler = Number(maxLeaseNum) * 12;
          }

          var answer = (Number(document.getElementById('priceOverall').value) * multipler);
          var left = (commonSnap.data().totalCompanyValue - existingListingAmount) + answer;
          console.log(left, existingListingAmount, answer);
          await updateDoc(doc(db, "commonData", 'information'), {
            totalCompanyValue: left
          });

          if (res == true) {
            var preferredDetails = {};
            if (autoFill == 'Auto-Fill') {
              if (productPricing == null && deckUpload == null) {
                // alert('Please upload a pdf or select auto-fill');
                // setOpenloader(false);

                if (additionInformationToggle == true) {
                  preferredDetails = {
                    paidUpCaptial: paidUpCapital,
                    coporateTurnover: document.getElementById("coporateTurnover").value,
                    otherRental: document.getElementById("otherRental").value,
                    clinteleBreakdownLocal: localBreakdown,
                    clinteleBreakdownTourist: touristBreakdown,
                    product_pricing: productPricingAutoFill,
                    revenueBreakdownWalkin: walkInBreakdown,
                    revenueBreakdownRegular: regularBreakdown,
                    outletLocation: document.getElementById("outletLocation").value,
                    expectedRevenue: document.getElementById("expectedRevenue").value,
                    companyDeckURL: deckUploadAutoFill,
                    companyName: companyName,
                    autoFillLink: null
                  }


                  const querySnapshot = await getDocs(collection(db, `projects/${props.modifyListingId}/PreferredDetails`));
                  querySnapshot.forEach(async (docIndiv) => {
                    if (docIndiv.exists()) {
                      await updateDoc(doc(db, `projects/${props.modifyListingId}/PreferredDetails`, docIndiv.id), preferredDetails).then(async () => {
                        await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                          await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                        }).then(async () => {

                          if (readyURL.length == 1) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {
                              url1: deleteField(),
                              url2: deleteField(),
                              url3: deleteField(),
                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else if (readyURL.length == 2) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {

                              url2: deleteField(),
                              url3: deleteField(),
                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else if (readyURL.length == 3) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {

                              url3: deleteField(),
                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else if (readyURL.length == 4) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {

                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true)
                          }




                        });
                      });
                    }
                  });
                  if (querySnapshot.docs.length == 0) {
                    await addDoc(collection(db, `projects/${props.modifyListingId}/PreferredDetails`), preferredDetails).then(async () => {
                      await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                        await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                      }).then(async () => {

                        if (readyURL.length == 1) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {
                            url1: deleteField(),
                            url2: deleteField(),
                            url3: deleteField(),
                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else if (readyURL.length == 2) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {

                            url2: deleteField(),
                            url3: deleteField(),
                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else if (readyURL.length == 3) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {

                            url3: deleteField(),
                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else if (readyURL.length == 4) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {

                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else {
                          setAcknowledgeMessage("Listing Modified");
                          setClickConfirm(true)
                        }




                      });
                    });
                  }
                }
                else {
                  preferredDetails = {
                    paidUpCaptial: paidUpCapital,
                    coporateTurnover: document.getElementById("coporateTurnover").value,
                    otherRental: document.getElementById("otherRental").value,
                    clinteleBreakdownLocal: localBreakdown,
                    clinteleBreakdownTourist: touristBreakdown,
                    product_pricing: productPricingAutoFill,
                    revenueBreakdownWalkin: walkInBreakdown,
                    revenueBreakdownRegular: regularBreakdown,
                    outletLocation: null,
                    expectedRevenue: document.getElementById("expectedRevenue").value,
                    companyDeckURL: deckUploadAutoFill,
                    companyName: companyName,
                    autoFillLink: null
                  }
                  const querySnapshot = await getDocs(collection(db, `projects/${props.modifyListingId}/PreferredDetails`));
                  querySnapshot.forEach(async (docIndiv) => {
                    if (docIndiv.exists()) {
                      await updateDoc(doc(db, `projects/${props.modifyListingId}/PreferredDetails`, docIndiv.id), preferredDetails).then(async () => {
                        await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                          await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                        }).then(async () => {

                          if (readyURL.length == 1) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {
                              url1: deleteField(),
                              url2: deleteField(),
                              url3: deleteField(),
                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else if (readyURL.length == 2) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {

                              url2: deleteField(),
                              url3: deleteField(),
                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else if (readyURL.length == 3) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {

                              url3: deleteField(),
                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else if (readyURL.length == 4) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {

                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true)
                          }




                        });
                      });
                    }
                  });
                  if (querySnapshot.docs.length == 0) {
                    await addDoc(collection(db, `projects/${props.modifyListingId}/PreferredDetails`), preferredDetails).then(async () => {
                      await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                        await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                      }).then(async () => {

                        if (readyURL.length == 1) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {
                            url1: deleteField(),
                            url2: deleteField(),
                            url3: deleteField(),
                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else if (readyURL.length == 2) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {

                            url2: deleteField(),
                            url3: deleteField(),
                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else if (readyURL.length == 3) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {

                            url3: deleteField(),
                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else if (readyURL.length == 4) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {

                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else {
                          setAcknowledgeMessage("Listing Modified");
                          setClickConfirm(true)
                        }




                      });
                    });
                  }
                }

              }
              else if (productPricing != null && deckUpload == null) {
                firebasestorageFileProductPricing(props.modifyListingId).then(async (product_pricingURL) => {

                  if (additionInformationToggle == true) {
                    preferredDetails = {
                      paidUpCaptial: paidUpCapital,
                      coporateTurnover: document.getElementById("coporateTurnover").value,
                      otherRental: document.getElementById("otherRental").value,
                      clinteleBreakdownLocal: localBreakdown,
                      clinteleBreakdownTourist: touristBreakdown,
                      product_pricing: product_pricingURL,
                      revenueBreakdownWalkin: walkInBreakdown,
                      revenueBreakdownRegular: regularBreakdown,
                      outletLocation: document.getElementById("outletLocation").value,
                      expectedRevenue: document.getElementById("expectedRevenue").value,
                      companyDeckURL: deckUploadAutoFill,
                      companyName: companyName,
                      autoFillLink: null
                    }
                    const querySnapshot = await getDocs(collection(db, `projects/${props.modifyListingId}/PreferredDetails`));
                    querySnapshot.forEach(async (docIndiv) => {
                      if (docIndiv.exists()) {
                        await updateDoc(doc(db, `projects/${props.modifyListingId}/PreferredDetails`, docIndiv.id), preferredDetails).then(async () => {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                            await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                          }).then(async () => {

                            if (readyURL.length == 1) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {
                                url1: deleteField(),
                                url2: deleteField(),
                                url3: deleteField(),
                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else if (readyURL.length == 2) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                url2: deleteField(),
                                url3: deleteField(),
                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else if (readyURL.length == 3) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                url3: deleteField(),
                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else if (readyURL.length == 4) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true)
                            }




                          });
                        });
                      }
                    });
                    if (querySnapshot.docs.length == 0) {
                      await addDoc(collection(db, `projects/${props.modifyListingId}/PreferredDetails`), preferredDetails).then(async () => {
                        await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                          await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                        }).then(async () => {

                          if (readyURL.length == 1) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {
                              url1: deleteField(),
                              url2: deleteField(),
                              url3: deleteField(),
                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else if (readyURL.length == 2) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {

                              url2: deleteField(),
                              url3: deleteField(),
                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else if (readyURL.length == 3) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {

                              url3: deleteField(),
                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else if (readyURL.length == 4) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {

                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true)
                          }




                        });
                      });
                    }
                  }
                  else {
                    preferredDetails = {
                      paidUpCaptial: paidUpCapital,
                      coporateTurnover: document.getElementById("coporateTurnover").value,
                      otherRental: document.getElementById("otherRental").value,
                      clinteleBreakdownLocal: localBreakdown,
                      clinteleBreakdownTourist: touristBreakdown,
                      product_pricing: product_pricingURL,
                      revenueBreakdownWalkin: walkInBreakdown,
                      revenueBreakdownRegular: regularBreakdown,
                      outletLocation: null,
                      expectedRevenue: document.getElementById("expectedRevenue").value,
                      companyDeckURL: deckUploadAutoFill,
                      companyName: companyName,
                      autoFillLink: null
                    }
                    const querySnapshot = await getDocs(collection(db, `projects/${props.modifyListingId}/PreferredDetails`));
                    querySnapshot.forEach(async (docIndiv) => {
                      if (docIndiv.exists()) {
                        await updateDoc(doc(db, `projects/${props.modifyListingId}/PreferredDetails`, docIndiv.id), preferredDetails).then(async () => {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                            await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                          }).then(async () => {

                            if (readyURL.length == 1) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {
                                url1: deleteField(),
                                url2: deleteField(),
                                url3: deleteField(),
                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else if (readyURL.length == 2) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                url2: deleteField(),
                                url3: deleteField(),
                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else if (readyURL.length == 3) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                url3: deleteField(),
                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else if (readyURL.length == 4) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true)
                            }




                          });
                        });
                      }
                    });
                    if (querySnapshot.docs.length == 0) {
                      await addDoc(collection(db, `projects/${props.modifyListingId}/PreferredDetails`), preferredDetails).then(async () => {
                        await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                          await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                        }).then(async () => {

                          if (readyURL.length == 1) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {
                              url1: deleteField(),
                              url2: deleteField(),
                              url3: deleteField(),
                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else if (readyURL.length == 2) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {

                              url2: deleteField(),
                              url3: deleteField(),
                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else if (readyURL.length == 3) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {

                              url3: deleteField(),
                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else if (readyURL.length == 4) {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), {

                              url4: deleteField()
                            }).then(() => {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true);
                            })
                          }
                          else {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true)
                          }




                        });
                      });
                    }
                  }



                })
              }
              else if (productPricing == null && deckUpload != null) {


                uploadBytes(ref(storage, `${user.uid}/${props.modifyListingId}/companyDeck`), deckUpload).then((snapshot) => {
                  getDownloadURL(ref(storage, `${user.uid}/${props.modifyListingId}/companyDeck`)).then(async (url) => {

                    if (additionInformationToggle == true) {
                      preferredDetails = {
                        paidUpCaptial: paidUpCapital,
                        coporateTurnover: document.getElementById("coporateTurnover").value,
                        otherRental: document.getElementById("otherRental").value,
                        clinteleBreakdownLocal: localBreakdown,
                        clinteleBreakdownTourist: touristBreakdown,
                        product_pricing: productPricingAutoFill,
                        revenueBreakdownWalkin: walkInBreakdown,
                        revenueBreakdownRegular: regularBreakdown,
                        outletLocation: document.getElementById("outletLocation").value,
                        expectedRevenue: document.getElementById("expectedRevenue").value,
                        companyDeckURL: url,
                        companyName: companyName,
                        autoFillLink: null
                      }
                      const querySnapshot = await getDocs(collection(db, `projects/${props.modifyListingId}/PreferredDetails`));
                      querySnapshot.forEach(async (docIndiv) => {
                        if (docIndiv.exists()) {
                          await updateDoc(doc(db, `projects/${props.modifyListingId}/PreferredDetails`, docIndiv.id), preferredDetails).then(async () => {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                              await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                            }).then(async () => {

                              if (readyURL.length == 1) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {
                                  url1: deleteField(),
                                  url2: deleteField(),
                                  url3: deleteField(),
                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else if (readyURL.length == 2) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                  url2: deleteField(),
                                  url3: deleteField(),
                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else if (readyURL.length == 3) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                  url3: deleteField(),
                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else if (readyURL.length == 4) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true)
                              }




                            });
                          });
                        }
                      });
                      if (querySnapshot.docs.length == 0) {
                        await addDoc(collection(db, `projects/${props.modifyListingId}/PreferredDetails`), preferredDetails).then(async () => {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                            await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                          }).then(async () => {

                            if (readyURL.length == 1) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {
                                url1: deleteField(),
                                url2: deleteField(),
                                url3: deleteField(),
                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else if (readyURL.length == 2) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                url2: deleteField(),
                                url3: deleteField(),
                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else if (readyURL.length == 3) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                url3: deleteField(),
                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else if (readyURL.length == 4) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true)
                            }




                          });
                        });
                      }
                    }
                    else {
                      preferredDetails = {
                        paidUpCaptial: paidUpCapital,
                        coporateTurnover: document.getElementById("coporateTurnover").value,
                        otherRental: document.getElementById("otherRental").value,
                        clinteleBreakdownLocal: localBreakdown,
                        clinteleBreakdownTourist: touristBreakdown,
                        product_pricing: productPricingAutoFill,
                        revenueBreakdownWalkin: walkInBreakdown,
                        revenueBreakdownRegular: regularBreakdown,
                        outletLocation: null,
                        expectedRevenue: document.getElementById("expectedRevenue").value,
                        companyDeckURL: url,
                        companyName: companyName,
                        autoFillLink: null
                      }
                      const querySnapshot = await getDocs(collection(db, `projects/${props.modifyListingId}/PreferredDetails`));
                      querySnapshot.forEach(async (docIndiv) => {
                        if (docIndiv.exists()) {
                          await updateDoc(doc(db, `projects/${props.modifyListingId}/PreferredDetails`, docIndiv.id), preferredDetails).then(async () => {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                              await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                            }).then(async () => {

                              if (readyURL.length == 1) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {
                                  url1: deleteField(),
                                  url2: deleteField(),
                                  url3: deleteField(),
                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else if (readyURL.length == 2) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                  url2: deleteField(),
                                  url3: deleteField(),
                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else if (readyURL.length == 3) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                  url3: deleteField(),
                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else if (readyURL.length == 4) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true)
                              }




                            });
                          });
                        }
                      });
                      if (querySnapshot.docs.length == 0) {
                        await addDoc(collection(db, `projects/${props.modifyListingId}/PreferredDetails`), preferredDetails).then(async () => {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                            await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                          }).then(async () => {

                            if (readyURL.length == 1) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {
                                url1: deleteField(),
                                url2: deleteField(),
                                url3: deleteField(),
                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else if (readyURL.length == 2) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                url2: deleteField(),
                                url3: deleteField(),
                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else if (readyURL.length == 3) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                url3: deleteField(),
                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else if (readyURL.length == 4) {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                url4: deleteField()
                              }).then(() => {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true);
                              })
                            }
                            else {
                              setAcknowledgeMessage("Listing Modified");
                              setClickConfirm(true)
                            }




                          });
                        });
                      }
                    }


                  });
                })

              }
              else if (productPricing != null && deckUpload != null) {
                firebasestorageFileProductPricing(props.modifyListingId).then((product_pricingURL) => {
                  uploadBytes(ref(storage, `${user.uid}/${props.modifyListingId}/companyDeck`), deckUpload).then((snapshot) => {
                    getDownloadURL(ref(storage, `${user.uid}/${props.modifyListingId}/companyDeck`)).then(async (url) => {

                      if (additionInformationToggle == true) {
                        preferredDetails = {
                          paidUpCaptial: paidUpCapital,
                          coporateTurnover: document.getElementById("coporateTurnover").value,
                          otherRental: document.getElementById("otherRental").value,
                          clinteleBreakdownLocal: localBreakdown,
                          clinteleBreakdownTourist: touristBreakdown,
                          product_pricing: product_pricingURL,
                          revenueBreakdownWalkin: walkInBreakdown,
                          revenueBreakdownRegular: regularBreakdown,
                          outletLocation: document.getElementById("outletLocation").value,
                          expectedRevenue: document.getElementById("expectedRevenue").value,
                          companyDeckURL: url,
                          companyName: companyName,
                          autoFillLink: null
                        }
                        const querySnapshot = await getDocs(collection(db, `projects/${props.modifyListingId}/PreferredDetails`));
                        querySnapshot.forEach(async (docIndiv) => {
                          if (docIndiv.exists()) {
                            await updateDoc(doc(db, `projects/${props.modifyListingId}/PreferredDetails`, docIndiv.id), preferredDetails).then(async () => {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                                await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                              }).then(async () => {

                                if (readyURL.length == 1) {
                                  await updateDoc(doc(db, `projects`, props.modifyListingId), {
                                    url1: deleteField(),
                                    url2: deleteField(),
                                    url3: deleteField(),
                                    url4: deleteField()
                                  }).then(() => {
                                    setAcknowledgeMessage("Listing Modified");
                                    setClickConfirm(true);
                                  })
                                }
                                else if (readyURL.length == 2) {
                                  await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                    url2: deleteField(),
                                    url3: deleteField(),
                                    url4: deleteField()
                                  }).then(() => {
                                    setAcknowledgeMessage("Listing Modified");
                                    setClickConfirm(true);
                                  })
                                }
                                else if (readyURL.length == 3) {
                                  await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                    url3: deleteField(),
                                    url4: deleteField()
                                  }).then(() => {
                                    setAcknowledgeMessage("Listing Modified");
                                    setClickConfirm(true);
                                  })
                                }
                                else if (readyURL.length == 4) {
                                  await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                    url4: deleteField()
                                  }).then(() => {
                                    setAcknowledgeMessage("Listing Modified");
                                    setClickConfirm(true);
                                  })
                                }
                                else {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true)
                                }




                              });
                            });
                          }
                        });
                        if (querySnapshot.docs.length == 0) {
                          await addDoc(collection(db, `projects/${props.modifyListingId}/PreferredDetails`), preferredDetails).then(async () => {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                              await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                            }).then(async () => {

                              if (readyURL.length == 1) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {
                                  url1: deleteField(),
                                  url2: deleteField(),
                                  url3: deleteField(),
                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else if (readyURL.length == 2) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                  url2: deleteField(),
                                  url3: deleteField(),
                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else if (readyURL.length == 3) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                  url3: deleteField(),
                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else if (readyURL.length == 4) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true)
                              }




                            });
                          });
                        }
                      }
                      else {
                        preferredDetails = {
                          paidUpCaptial: paidUpCapital,
                          coporateTurnover: document.getElementById("coporateTurnover").value,
                          otherRental: document.getElementById("otherRental").value,
                          clinteleBreakdownLocal: localBreakdown,
                          clinteleBreakdownTourist: touristBreakdown,
                          product_pricing: product_pricingURL,
                          revenueBreakdownWalkin: walkInBreakdown,
                          revenueBreakdownRegular: regularBreakdown,
                          outletLocation: null,
                          expectedRevenue: document.getElementById("expectedRevenue").value,
                          companyDeckURL: url,
                          companyName: companyName,
                          autoFillLink: null
                        }
                        const querySnapshot = await getDocs(collection(db, `projects/${props.modifyListingId}/PreferredDetails`));
                        querySnapshot.forEach(async (docIndiv) => {
                          if (docIndiv.exists()) {
                            await updateDoc(doc(db, `projects/${props.modifyListingId}/PreferredDetails`, docIndiv.id), preferredDetails).then(async () => {
                              await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                                await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                              }).then(async () => {

                                if (readyURL.length == 1) {
                                  await updateDoc(doc(db, `projects`, props.modifyListingId), {
                                    url1: deleteField(),
                                    url2: deleteField(),
                                    url3: deleteField(),
                                    url4: deleteField()
                                  }).then(() => {
                                    setAcknowledgeMessage("Listing Modified");
                                    setClickConfirm(true);
                                  })
                                }
                                else if (readyURL.length == 2) {
                                  await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                    url2: deleteField(),
                                    url3: deleteField(),
                                    url4: deleteField()
                                  }).then(() => {
                                    setAcknowledgeMessage("Listing Modified");
                                    setClickConfirm(true);
                                  })
                                }
                                else if (readyURL.length == 3) {
                                  await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                    url3: deleteField(),
                                    url4: deleteField()
                                  }).then(() => {
                                    setAcknowledgeMessage("Listing Modified");
                                    setClickConfirm(true);
                                  })
                                }
                                else if (readyURL.length == 4) {
                                  await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                    url4: deleteField()
                                  }).then(() => {
                                    setAcknowledgeMessage("Listing Modified");
                                    setClickConfirm(true);
                                  })
                                }
                                else {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true)
                                }




                              });
                            });
                          }
                        });
                        if (querySnapshot.docs.length == 0) {
                          await addDoc(collection(db, `projects/${props.modifyListingId}/PreferredDetails`), preferredDetails).then(async () => {
                            await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                              await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                            }).then(async () => {

                              if (readyURL.length == 1) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {
                                  url1: deleteField(),
                                  url2: deleteField(),
                                  url3: deleteField(),
                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else if (readyURL.length == 2) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                  url2: deleteField(),
                                  url3: deleteField(),
                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else if (readyURL.length == 3) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                  url3: deleteField(),
                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else if (readyURL.length == 4) {
                                await updateDoc(doc(db, `projects`, props.modifyListingId), {

                                  url4: deleteField()
                                }).then(() => {
                                  setAcknowledgeMessage("Listing Modified");
                                  setClickConfirm(true);
                                })
                              }
                              else {
                                setAcknowledgeMessage("Listing Modified");
                                setClickConfirm(true)
                              }




                            });
                          });
                        }
                      }


                    });
                  })
                })
              }
            }
            else {
              if (additionInformationToggle == true) {
                preferredDetails = {
                  paidUpCaptial: paidUpCapital,
                  coporateTurnover: document.getElementById("coporateTurnover").value,
                  otherRental: document.getElementById("otherRental").value,
                  clinteleBreakdownLocal: localBreakdown,
                  clinteleBreakdownTourist: touristBreakdown,
                  product_pricing: productPricingAutoFill,
                  revenueBreakdownWalkin: walkInBreakdown,
                  revenueBreakdownRegular: regularBreakdown,
                  outletLocation: document.getElementById("outletLocation").value,
                  expectedRevenue: document.getElementById("expectedRevenue").value,
                  companyDeckURL: deckUploadAutoFill,
                  companyName: companyName,
                  autoFillLink: companyName
                }
                const querySnapshot = await getDocs(collection(db, `projects/${props.modifyListingId}/PreferredDetails`));
                querySnapshot.forEach(async (docIndiv) => {
                  if (docIndiv.exists()) {
                    await updateDoc(doc(db, `projects/${props.modifyListingId}/PreferredDetails`, docIndiv.id), preferredDetails).then(async () => {
                      await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                        await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                      }).then(async () => {

                        if (readyURL.length == 1) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {
                            url1: deleteField(),
                            url2: deleteField(),
                            url3: deleteField(),
                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else if (readyURL.length == 2) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {

                            url2: deleteField(),
                            url3: deleteField(),
                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else if (readyURL.length == 3) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {

                            url3: deleteField(),
                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else if (readyURL.length == 4) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {

                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else {
                          setAcknowledgeMessage("Listing Modified");
                          setClickConfirm(true)
                        }




                      });
                    });
                  }
                });
                if (querySnapshot.docs.length == 0) {
                  await addDoc(collection(db, `projects/${props.modifyListingId}/PreferredDetails`), preferredDetails).then(async () => {
                    await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                      await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                    }).then(async () => {

                      if (readyURL.length == 1) {
                        await updateDoc(doc(db, `projects`, props.modifyListingId), {
                          url1: deleteField(),
                          url2: deleteField(),
                          url3: deleteField(),
                          url4: deleteField()
                        }).then(() => {
                          setAcknowledgeMessage("Listing Modified");
                          setClickConfirm(true);
                        })
                      }
                      else if (readyURL.length == 2) {
                        await updateDoc(doc(db, `projects`, props.modifyListingId), {

                          url2: deleteField(),
                          url3: deleteField(),
                          url4: deleteField()
                        }).then(() => {
                          setAcknowledgeMessage("Listing Modified");
                          setClickConfirm(true);
                        })
                      }
                      else if (readyURL.length == 3) {
                        await updateDoc(doc(db, `projects`, props.modifyListingId), {

                          url3: deleteField(),
                          url4: deleteField()
                        }).then(() => {
                          setAcknowledgeMessage("Listing Modified");
                          setClickConfirm(true);
                        })
                      }
                      else if (readyURL.length == 4) {
                        await updateDoc(doc(db, `projects`, props.modifyListingId), {

                          url4: deleteField()
                        }).then(() => {
                          setAcknowledgeMessage("Listing Modified");
                          setClickConfirm(true);
                        })
                      }
                      else {
                        setAcknowledgeMessage("Listing Modified");
                        setClickConfirm(true)
                      }




                    });
                  });
                }
              }
              else {
                preferredDetails = {
                  paidUpCaptial: paidUpCapital,
                  coporateTurnover: document.getElementById("coporateTurnover").value,
                  otherRental: document.getElementById("otherRental").value,
                  clinteleBreakdownLocal: localBreakdown,
                  clinteleBreakdownTourist: touristBreakdown,
                  product_pricing: productPricingAutoFill,
                  revenueBreakdownWalkin: walkInBreakdown,
                  revenueBreakdownRegular: regularBreakdown,
                  outletLocation: null,
                  expectedRevenue: document.getElementById("expectedRevenue").value,
                  companyDeckURL: deckUploadAutoFill,
                  companyName: companyName,
                  autoFillLink: companyName
                }
                const querySnapshot = await getDocs(collection(db, `projects/${props.modifyListingId}/PreferredDetails`));
                querySnapshot.forEach(async (docIndiv) => {
                  if (docIndiv.exists()) {
                    await updateDoc(doc(db, `projects/${props.modifyListingId}/PreferredDetails`, docIndiv.id), preferredDetails).then(async () => {
                      await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                        await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                      }).then(async () => {

                        if (readyURL.length == 1) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {
                            url1: deleteField(),
                            url2: deleteField(),
                            url3: deleteField(),
                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else if (readyURL.length == 2) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {

                            url2: deleteField(),
                            url3: deleteField(),
                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else if (readyURL.length == 3) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {

                            url3: deleteField(),
                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else if (readyURL.length == 4) {
                          await updateDoc(doc(db, `projects`, props.modifyListingId), {

                            url4: deleteField()
                          }).then(() => {
                            setAcknowledgeMessage("Listing Modified");
                            setClickConfirm(true);
                          })
                        }
                        else {
                          setAcknowledgeMessage("Listing Modified");
                          setClickConfirm(true)
                        }




                      });
                    });
                  }
                });
                if (querySnapshot.docs.length == 0) {
                  await addDoc(collection(db, `projects/${props.modifyListingId}/PreferredDetails`), preferredDetails).then(async () => {
                    await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
                      await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails);
                    }).then(async () => {

                      if (readyURL.length == 1) {
                        await updateDoc(doc(db, `projects`, props.modifyListingId), {
                          url1: deleteField(),
                          url2: deleteField(),
                          url3: deleteField(),
                          url4: deleteField()
                        }).then(() => {
                          setAcknowledgeMessage("Listing Modified");
                          setClickConfirm(true);
                        })
                      }
                      else if (readyURL.length == 2) {
                        await updateDoc(doc(db, `projects`, props.modifyListingId), {

                          url2: deleteField(),
                          url3: deleteField(),
                          url4: deleteField()
                        }).then(() => {
                          setAcknowledgeMessage("Listing Modified");
                          setClickConfirm(true);
                        })
                      }
                      else if (readyURL.length == 3) {
                        await updateDoc(doc(db, `projects`, props.modifyListingId), {

                          url3: deleteField(),
                          url4: deleteField()
                        }).then(() => {
                          setAcknowledgeMessage("Listing Modified");
                          setClickConfirm(true);
                        })
                      }
                      else if (readyURL.length == 4) {
                        await updateDoc(doc(db, `projects`, props.modifyListingId), {

                          url4: deleteField()
                        }).then(() => {
                          setAcknowledgeMessage("Listing Modified");
                          setClickConfirm(true);
                        })
                      }
                      else {
                        setAcknowledgeMessage("Listing Modified");
                        setClickConfirm(true)
                      }
                    });
                  });
                }
              }

            }



          }
          else {
            await updateDoc(doc(db, `projects`, props.modifyListingId), cardInformation).then(async function () {
              await updateDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current), moreDetails).then(async () => {

                if (readyURL.length == 1) {
                  await updateDoc(doc(db, `projects`, props.modifyListingId), {
                    url1: deleteField(),
                    url2: deleteField(),
                    url3: deleteField(),
                    url4: deleteField()
                  }).then(() => {
                    setAcknowledgeMessage("Listing Modified");
                    setClickConfirm(true);
                  })
                }
                else if (readyURL.length == 2) {
                  await updateDoc(doc(db, `projects`, props.modifyListingId), {

                    url2: deleteField(),
                    url3: deleteField(),
                    url4: deleteField()
                  }).then(() => {
                    setAcknowledgeMessage("Listing Modified");
                    setClickConfirm(true);
                  })
                }
                else if (readyURL.length == 3) {
                  await updateDoc(doc(db, `projects`, props.modifyListingId), {

                    url3: deleteField(),
                    url4: deleteField()
                  }).then(() => {
                    setAcknowledgeMessage("Listing Modified");
                    setClickConfirm(true);
                  })
                }
                else if (readyURL.length == 4) {
                  await updateDoc(doc(db, `projects`, props.modifyListingId), {

                    url4: deleteField()
                  }).then(() => {
                    setAcknowledgeMessage("Listing Modified");
                    setClickConfirm(true);
                  })
                }
                else {
                  setAcknowledgeMessage("Listing Modified");
                  setClickConfirm(true)
                }




              });;
            })
          }

        });

      })
    }
    else {
      setOpenloader(false);
    }
  }

  const checkFirstPage = () => {
    return new Promise(function (resolve, reject) {
      var allowName = false;
      var allowPerSquareFeet = false;
      var allowPriceOverall = false;
      var allowLocationMatch = true;
      var allowPeriodMoveIn = false;
      var allowImage = false;
      var allowMinLease = false;

      var additionalInfo = document.querySelectorAll('[name="addtionalInfo"]');
      var additionInformation = null;
      for (var i = 0; i < additionalInfo.length; i++) {
        if (additionalInfo[i].checked == true) {
          additionInformation = additionalInfo[i].value;
          setAdditionInformationToggle(true);
        }
      }

      var listingName = document.getElementById('listingName');
      var pricePerSquareFeet = document.getElementById('pricePerSquareFeet');
      var priceOverall = document.getElementById('priceOverall');
      var districtType = document.getElementById('districtType');
      var locationAddress = document.getElementById('locationListing');
      var periodMoveIn = document.getElementById('periodMoveIn');



      if (listingName.value == '') {
        listingName.setAttribute('class', 'invalid');
      }
      else {
        listingName.setAttribute('class', 'newListingInput');
        allowName = true;
      }

      if (locationAddress.value != null) {
        var targetIndex = -1;
        var address = (locationAddress.value).toUpperCase();

        for (var i = 0; i < district.length; i++) {
          for (var a = 0; a < district[i].length; a++) {
            var target = district[i][a].toUpperCase();
            if (address.search(target) >= 0) {
              targetIndex = i;
              break;
            }
          }
        }
        if (districtAreaCode[targetIndex] != districtType.value && address != "") {
          districtType.setAttribute('class', 'invalid');
          locationAddress.setAttribute('class', 'invalid');
          allowLocationMatch = false;
        }
        else {
          districtType.setAttribute('class', 'newListingInput');
          locationAddress.setAttribute('class', 'newListingInput');
          allowLocationMatch = true;
        }
      }

      if (periodMoveIn.value == "") {
        periodMoveIn.setAttribute('class', 'invalid');
      }
      else {
        periodMoveIn.setAttribute('class', 'newListingInput');
        allowPeriodMoveIn = true;
      }

      if (pricePerSquareFeet.value == "" || Number(pricePerSquareFeet.value) <= 0) {
        pricePerSquareFeet.setAttribute('class', 'invalid');
        allowPerSquareFeet = false;
      }
      else {
        pricePerSquareFeet.setAttribute('class', 'newListingInput');
        allowPerSquareFeet = true;
      }

      if (priceOverall.value == "" | Number(priceOverall.value) <= 0) {
        priceOverall.setAttribute('class', 'invalid');
        allowPriceOverall = false;
      }
      else {
        priceOverall.setAttribute('class', 'newListingInput');
        allowPriceOverall = true;
      }

      console.log(selfUploadArr, defaultUpload, alreadyImageUpload, landscape);
      if (selfUploadArr.length == 0 && defaultUpload.length == 0 && alreadyImageUpload.length == 0 && landscape.length == 0) {
        allowImage = false;
      }

      else {
        if (selfUploadArr.length != 0 || defaultUpload.length != 0) {
          allowImage = true
        }
        else if (landscape.length != 0 && defaultUpload.length == 0) {
          allowImage = true;
        }
        else {
          allowImage = false
        }

      }
      if (allowName == true && allowImage == true && allowPerSquareFeet == true && allowPeriodMoveIn == true && allowLocationMatch == true && allowPriceOverall == true) {
        resolve(true);
      }
      else {
        resolve(false);
      }
    })

  }

  const firebasestorageFileProductPricing = (id) => {
    return new Promise(function (resolve, reject) {
      uploadBytes(ref(storage, `${user.uid}/${id}/productPricing`), productPricing).then((snapshot) => {
        getDownloadURL(ref(storage, `${user.uid}/${id}/productPricing`)).then((url) => {
          resolve(url);
        });
      });
    })
  }

  const firebasestorageFile = (preferredState, id) => {
    return new Promise(function (resolve, reject) {
      var preferredDetails = {};
      if (preferredState == true) {
        if (autoFill == 'Auto-Fill') {
          firebasestorageFileProductPricing(id).then((product_pricingURL) => {
            uploadBytes(ref(storage, `${user.uid}/${id}/companyDeck`), deckUpload).then((snapshot) => {
              getDownloadURL(ref(storage, `${user.uid}/${id}/companyDeck`)).then((url) => {

                if (additionInformationToggle == true) {
                  preferredDetails = {
                    paidUpCaptial: paidUpCapital,
                    coporateTurnover: document.getElementById("coporateTurnover").value,
                    otherRental: document.getElementById("otherRental").value,
                    clinteleBreakdownLocal: localBreakdown,
                    clinteleBreakdownTourist: touristBreakdown,
                    product_pricing: product_pricingURL,
                    revenueBreakdownWalkin: walkInBreakdown,
                    revenueBreakdownRegular: regularBreakdown,
                    outletLocation: document.getElementById("outletLocation").value,
                    expectedRevenue: document.getElementById("expectedRevenue").value,
                    companyDeckURL: url,
                    companyName: companyName,
                    autoFillLink: null
                  }
                }
                else {
                  preferredDetails = {
                    paidUpCaptial: paidUpCapital,
                    coporateTurnover: document.getElementById("coporateTurnover").value,
                    otherRental: document.getElementById("otherRental").value,
                    clinteleBreakdownLocal: localBreakdown,
                    clinteleBreakdownTourist: touristBreakdown,
                    product_pricing: product_pricingURL,
                    revenueBreakdownWalkin: walkInBreakdown,
                    revenueBreakdownRegular: regularBreakdown,
                    outletLocation: null,
                    expectedRevenue: document.getElementById("expectedRevenue").value,
                    companyDeckURL: url,
                    companyName: companyName,
                    autoFillLink: null
                  }
                }

                resolve(preferredDetails);
              });
            })
          })
        }
        else {
          if (additionInformationToggle == true) {
            preferredDetails = {
              paidUpCaptial: paidUpCapital,
              coporateTurnover: document.getElementById("coporateTurnover").value,
              otherRental: document.getElementById("otherRental").value,
              clinteleBreakdownLocal: localBreakdown,
              clinteleBreakdownTourist: touristBreakdown,
              product_pricing: productPricingAutoFill,
              revenueBreakdownWalkin: walkInBreakdown,
              revenueBreakdownRegular: regularBreakdown,
              outletLocation: document.getElementById("outletLocation").value,
              expectedRevenue: document.getElementById("expectedRevenue").value,
              companyDeckURL: deckUploadAutoFill,
              companyName: companyName,
              autoFillLink: companyName
            }
          }
          else {
            preferredDetails = {
              paidUpCaptial: paidUpCapital,
              coporateTurnover: document.getElementById("coporateTurnover").value,
              otherRental: document.getElementById("otherRental").value,
              clinteleBreakdownLocal: localBreakdown,
              clinteleBreakdownTourist: touristBreakdown,
              product_pricing: productPricingAutoFill,
              revenueBreakdownWalkin: walkInBreakdown,
              revenueBreakdownRegular: regularBreakdown,
              outletLocation: null,
              expectedRevenue: document.getElementById("expectedRevenue").value,
              companyDeckURL: deckUploadAutoFill,
              companyName: companyName,
              autoFillLink: companyName
            }
          }
          resolve(preferredDetails);
        }
      }
      else {
        resolve(preferredDetails);
      }
    })

  }

  const saveProfile = async () => {
    if (residentialOccupation != "") {


      await updateDoc(doc(db, `userInfo`, user.uid), {
        residentialTenantProfile: {
          residentialAge: residentialAge,
          residentialEmployment: residentialEmployment,
          residentialYearsRent: residentialYearsRent,
          residentialNationality: residentialNationality,
          residentialExpectedStay: residentialExpectedStay,
          residentialType: residentialType,
          compositeTenant: compositeTenant,
          smoke: residentialSmoke,
          relocation: residentialRelocation,
          residentialOccupation: residentialOccupation
        }
      }).then(() => {
        setAcknowledgeMessage("Your residential tenant profile has been saved! On the next residential listing, the fields will be automatically filled for you.");
        setAcknowledgeNormalNoDelete(true);

      })

    }
    else {
      alert("All fields must be filled out");
    }


  }

  const confirmNewProject = async (preferredState) => {

    setOpenloader(true);
    var additionalInfo = document.querySelectorAll('[name="addtionalInfo"]');
    var additionInformation = null;
    for (var i = 0; i < additionalInfo.length; i++) {
      if (additionalInfo[i].checked == true) {
        additionInformation = additionalInfo[i].value;
      }
    }

    if (document.getElementById('periodMoveIn').value == "") {
      var periodMoveIn = null;
    }
    else {
      periodMoveIn = new Date(document.getElementById('periodMoveIn').value);
    }
    if (document.getElementById('minSpace').value === "") {
      var minSpace = null;
    }
    else {
      minSpace = document.getElementById('minSpace').value;
    }
    if (document.getElementById('maxSpace').value == "") {
      var maxSpace = null;
    }
    else {
      maxSpace = (document.getElementById('maxSpace').value);
    }
    if (document.getElementById('yearsOfOperations').value == "") {
      var yearsOfOperation = null;
    }
    else if (document.getElementById('yearsOfOperations').value < 0) {
      alert("Negative values detected");
      setOpenloader(false);
      return;
    }
    else {
      yearsOfOperation = Number(document.getElementById('yearsOfOperations').value);
    }
    if (document.getElementById('ceilingHeight').value == "") {
      var ceilingHeight = null;
    }
    else if (document.getElementById('ceilingHeight').value < 0) {
      alert("Negative values detected");
      setOpenloader(false);
      return;
    }
    else {
      ceilingHeight = Number(document.getElementById('ceilingHeight').value);
    }

    var readyURL = [];
    await addDoc(collection(db, `projects`), {}).then(async function (docRef) {
      // if (landscape.length != 0) {
      //   // for (var i = 0; i < landscape.length; i++) {


      //   for (var i = 0; i < landscape.length; i++) {
      //     var urlLinkReturn = await firebasestoragePicture(docRef.id, landscape[i]);
      //     if (urlLinkReturn == 'Image not accepted') {
      //       alert(`Image not accepted`);
      //       setOpenloader(false);
      //       return;
      //     }
      //     else {
      //       readyURL.push(urlLinkReturn);
      //     }
      //   }

      //   // }
      // }
      // else 
      if (defaultUpload.length != 0) {
        for (var i = 0; i < defaultUpload.length; i++) {
          readyURL.push(defaultUpload[i]);
        }
        for (var i = 0; i < landscape.length; i++) {
          var urlLinkReturn = await firebasestoragePicture(docRef.id, landscape[i]);
          if (urlLinkReturn == 'Image not accepted') {
            alert(`Image not accepted`);
            setOpenloader(false);
            return;
          }
          else {
            readyURL.push(urlLinkReturn);
          }
        }
      }
      else {
        for (var i = 0; i < selfUploadArr.length; i++) {
          var urlLinkReturn = await firebasestoragePicture(docRef.id, selfUploadArr[i]);
          if (urlLinkReturn == 'Image not accepted') {
            alert(`Image not accepted`);
            setOpenloader(false);
            return;
          }
          else {
            readyURL.push(urlLinkReturn);
          }
        }
        for (var i = 0; i < landscape.length; i++) {
          var urlLinkReturn = await firebasestoragePicture(docRef.id, landscape[i]);
          if (urlLinkReturn == 'Image not accepted') {
            alert(`Image not accepted`);
            setOpenloader(false);
            return;
          }
          else {
            readyURL.push(urlLinkReturn);
          }
        }
      }
      var currentStatus = "pending"
      if (props.currentUserInfo.userType.includes("Agent") == true && dualRoleAgent == "Agent" && props.publicInfo.agentProfile.verified == true) {
        currentStatus = "leasing"
      }
      var cardInformation = {
        UID: user.uid,
        listingName: document.getElementById('listingName').value,
        pricePerSquareFeet: Number(document.getElementById('pricePerSquareFeet').value),
        priceOverall: Number(document.getElementById('priceOverall').value),
        location: document.getElementById('locationListing').value,
        district: document.getElementById('districtType').value,
        status: currentStatus,
        eyeballStat: [0, 0, 0, 0, 0, 0, 0],
        timePosted: new Date(),
        boost: false,
        boostStart: null,
        boostLeft: null,
        click: 0,
        preferredListing: preferredState,
        eyeball: 0,
        pendingChats: 0,
        propertyType: document.getElementById('propertyType').value,
        username: props.publicInfo.username,
        connections: 0,
        conversationRate: 0,
        leaseTermMin: minLeaseNum + " " + minLeasePeriod,
        leaseTermMax: maxLeaseNum + " " + maxLeasePeriod,
        viewID: null,
        propertyAgent: dualRoleAgent == "Agent" ? true : false,
        userImage: currentUserInfo.userPhotoURL,
        propertyAgentCompany: dualRoleAgent == "Agent" ? props.publicInfo.hasOwnProperty('agentProfile') ? props.publicInfo.agentProfile.CEAAgency : null : null,
        postalCode: document.getElementById('postalCode').value == '' ? null : document.getElementById('postalCode').value,
      }
      for (var i = 0; i < readyURL.length; i++) {
        cardInformation["url" + i] = readyURL[i];
      }
      var moreDetails = {

        important_1: document.getElementById('important1').value,
        important_2: document.getElementById('important2').value,
        important_3: document.getElementById('important3').value,
        important_4: document.getElementById('important4').value,
        important_5: document.getElementById('important5').value,
        periodMoveIn: periodMoveIn,
        minSpace: minSpace,
        maxSpace: maxSpace,
        yearsOfOperation: yearsOfOperation,
        minCeiling: ceilingHeight,
        viewID: null,
        additionInformation: additionInformation,
        acuquisitionSpace: document.getElementById('reasonForAcuquisition').value,
        usp: document.getElementById('usp').value.replace(/\n/g, '<br>'),
      }



      if (cardInformation.status == 'leasing') {
        var companyValue = await getDoc(doc(db, 'commonData', "information"));
        companyValue = companyValue.data().totalCompanyValue;

        if (maxLeasePeriod == "Days") {
          companyValue = companyValue + (Number(document.getElementById('priceOverall').value) * 12);
        }
        else if (maxLeasePeriod == "Months") {
          companyValue = companyValue + (Number(document.getElementById('priceOverall').value) * maxLeaseNum);
        }
        else {
          companyValue = companyValue + (Number(document.getElementById('priceOverall').value) * 12 * maxLeaseNum);
        }
        console.log(companyValue)

        try {
          let updatedSnapshot = await updateDoc(doc(db, 'commonData', "information"), {
            totalCompanyValue: companyValue
          })
        }
        catch (error) {
          console.log('error in updating total',error);
        }
      }

      await updateDoc(doc(db, `projects`, docRef.id), cardInformation).then(async function () {
        firebasestorageFile(preferredState, docRef.id).then(async (preferredDetails) => {
          await addDoc(collection(db, `projects/${docRef.id}/MoreDetails`), moreDetails).then(async () => {
            if (preferredState == true) {
              await addDoc(collection(db, `projects/${docRef.id}/PreferredDetails`), preferredDetails);
            }

            const token = user.accessToken;


            const requestOptions_1 = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
              body: JSON.stringify({ data: { email: `info@nextloop.sg`, path: "New Listing" } })
            };
            await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
              error => console.log(error)
            );


          });
        })

      }).then(() => { setAcknowledgeMessage("Listing Added"); setClickConfirm(true); });
    });




  }

  const firebasestoragePicture = (id, target) => {
    return new Promise(function (resolve, reject) {
      const imageRef = ref(storage, `${user.uid}/${id}/${target.name}`);
      const checkingImageRef = ref(storage, `checkingImage/${target.name}`);
      if (currentUserInfo.userType[0] == "Agent") {
        uploadBytes(checkingImageRef, target, { contentType: target.type }).then(() => {

          getDownloadURL(checkingImageRef).then((url) => {
            axios.get('https://api.sightengine.com/1.0/check.json', {
              params: {
                'url': url,
                'models': 'nudity-2.0,wad,offensive,gore,gambling',
                'api_user': '206386815',
                'api_secret': 'BvES5DBmLA68GjALyBXo',
              }
            })
              .then(async function (response) {
                // on success: handle response
                if (response.data.drugs <= 0.10 && response.data.gore.prob <= 0.30 && response.data.nudity.erotica <= 0.60 && response.data.nudity.sexual_activity <= 0.30 && response.data.nudity.sexual_display <= 0.30 && response.data.offensive.prob <= 0.30) {
                  uploadBytes(imageRef, target, { contentType: target.type }).then(() => {

                    getDownloadURL(imageRef).then((url) => {
                      resolve(url);
                    })
                  })
                }
                else {
                  resolve('Image not accepted');

                }

              })
              .catch(function (error) {
                // handle error
                if (error.response) {
                  resolve('Image not accepted');
                }
                else {
                  resolve('Image not accepted');
                }
              });

          })
        })
      }
      else {
        uploadBytes(imageRef, target, { contentType: target.type }).then(() => {
          getDownloadURL(imageRef).then((url) => {
            resolve(url);
          })
        })
      }
    });
  }

  const closeNewProjecct = () => {
    setUploadedImageRemove([]);
    setTypeOfSpace("Commercial");
    setResidentialOccupation("");
    setResidentialAge("< 21");
    setResidentialNationality("SINGAPORE");
    setResidentialEmployment("NA");
    setResidentialYearsRent("< 1 year");
    setResidentialExpectedStay("< 2 years");
    setSelfUploadArr([]);
    setSelfUploadArrTempURL([]);
    setMinLeaseNum(1);
    setExistingListingAmount(0);
    setMinLeasePeriod("Days")
    setMaxLeaseNum(1);
    setMaxLeasePeriod("Days");
    setLandscapeTempURL([]);
    setLandscape([]);
    setCropClick(false);
    setPortrait([]);
    setExistingProject(null);
    setDefaultImage(false);
    setImageUpload(null);
    setDefaultUpload([]);
    setDeckUpload(null);
    setProductPricing(null);
    setContinueWarningState(null);
    setAdditionInformationToggle(false);
    setAutoFill('Auto-Fill');
    setCompanyName('');
    setPaidUpCapital('< 100K');
    setRegularBreakdown(50);
    setLocalBreakdown(50);
    setWalkInBreakdown(50);
    setTouristBreakdown(50);
    setDeckUploadAutoFill(null);
    setProductPricingAutoFill(null);
    setImageUploadTempURL(null);
    setImportant1(null);
    setImportant2(null);
    setImportant3(null);
    setImportant4(null);
    setImportant5(null);
    setBusinessName(null);
    setAlreadyImageUpload([])
    setAcuquisationSpace(null);
    setSelfUploadArr([]);
    setUSP(null);
    currentTab.current = 0;
    props.setTrigger(false);

  }


  const changeLeaseMin = () => {
    document.getElementById('leaseMin').value = document.getElementById('leaseMin').value;
    var value = document.getElementById('leaseMin').value;
    if (value <= 31) {
      document.getElementById('leaseDayMinText').innerHTML = value + " Day";
    }
    else if ((value / 31) <= 12) {
      document.getElementById('leaseDayMinText').innerHTML = Math.floor(value / 31) + " Month";
    }
    else if (((value / 31) / 12) <= 11) {
      document.getElementById('leaseDayMinText').innerHTML = Math.floor((value / 31) / 12) + " Year";
    }
  }

  const changeLeaseMax = () => {
    document.getElementById('leaseMax').value = document.getElementById('leaseMax').value;
    var value = document.getElementById('leaseMax').value;
    if (value <= 31) {
      document.getElementById('leaseDayMaxText').innerHTML = value + " Day";
    }
    else if ((value / 31) <= 12) {
      document.getElementById('leaseDayMaxText').innerHTML = Math.floor(value / 31) + " Month";
    }
    else if (((value / 31) / 12) <= 11) {
      document.getElementById('leaseDayMaxText').innerHTML = Math.floor((value / 31) / 12) + " Year";
    }
  }

  useEffect(() => {
    if (imageUpload != null) {
      const tempUrls = [];
      for (var i = 0; i < imageUpload.length; i++) {
        const file = imageUpload[i];
        const tempUrl = URL.createObjectURL(file);
        tempUrls.push({ tempUrl: tempUrl, id: i });
      }
      setImageUploadTempURL(tempUrls);


    }
  }, [imageUpload])



  useEffect(() => {
    if (selfUploadArr != null) {
      const tempUrls = [];
      for (var i = 0; i < selfUploadArr.length; i++) {
        const file = selfUploadArr[i];
        const tempUrl = URL.createObjectURL(file);
        tempUrls.push({ tempUrl: tempUrl, id: i });
      }
      setSelfUploadArrTempURL(tempUrls);


    }
  }, [selfUploadArr])

  useEffect(() => {
    if (landscape.length != 0) {
      const tempUrls = [];
      for (var i = 0; i < landscape.length; i++) {
        const file = landscape[i];
        const tempUrl = URL.createObjectURL(file);
        tempUrls.push({ tempUrl: tempUrl, id: i });
      }
      setLandscapeTempURL(tempUrls);
    }
  }, [landscape])

  function setPhotoUpload(event) {
    if (alreadyImageUpload == null) {
      if (selfUploadArr.length == 0) {
        if (event.target.files.length <= 5) {
          setImageUpload(event.target.files);
          setDefaultUpload([]);
          setPortrait([]);
          // setLandscape([]);
          // setCropClick(true);
        } else {
          alert('Maximum of 5 photos only');
        }
      } else {
        if (event.target.files.length <= 5 - (selfUploadArr.length + landscape.length)) {
          setImageUpload(event.target.files);
          setDefaultUpload([]);
          setPortrait([]);
          // setLandscape([]);
          // setCropClick(true);
        } else {
          alert(`You can upload ${5 - (selfUploadArr.length + landscape.length)} more photos`);
        }
      }
    }
    else {
      if (selfUploadArr == null) {
        if ((event.target.files.length + alreadyImageUpload.length) <= 5) {
          if (event.target.files.length <= 5) {
            setImageUpload(event.target.files);
            setDefaultUpload([]);
            setPortrait([]);
            // setLandscape([]);
            // setCropClick(true);
          } else {
            alert('Maximum of 5 photos only');
          }
        }
        else {
          alert(`You can upload ${5 - (alreadyImageUpload.length)} more photos`);
        }
      } else {
        if (event.target.files.length <= 5 - (selfUploadArr.length + alreadyImageUpload.length)) {
          setImageUpload(event.target.files);
          setDefaultUpload([]);
          setPortrait([]);
          // setLandscape([]);
          // setCropClick(true);
        } else {
          alert(`You can upload ${5 - (selfUploadArr.length + alreadyImageUpload.length)} more photos`);
        }
      }
    }


  }



  function setFileUpload(event) {
    setDeckUpload(event.target.files[0]);
    setAutoFill('Auto-Fill');
  }

  function setFileUploadProduct_pricing(event) {
    setProductPricing(event.target.files[0]);
    setAutoFill('Auto-Fill');
  }

  const activeDefault = () => {
    setLandscape([]);
    setPortrait([]);
    setSelfUploadArr([]);
    setSelfUploadArrTempURL(null);
    setLandscapeTempURL(null);
    setImageUpload(null);
    setImageUploadTempURL(null);
    setDefaultImage(true);

  }

  const nextPrev = (n) => {
    var allStepper = document.getElementsByClassName("stepper_tab");

    allStepper[currentTab.current].style.display = "none";
    if (currentTab.current + n >= allStepper.length) {
      return false;
    }
    else {
      currentTab.current = currentTab.current + n;
      showTab(currentTab.current);
    }

  }

  const activateConfirmationPopup = (event) => {
    if (typeOfSpace == "Commercial") {
      checkFirstPage().then((resFirst) => {
        if (resFirst == true) {
          checkSecondPage().then((res) => {
            if (res == true) {
              if (event == "new") {
                confirmNewProject(true);
              }
              else if (event == "modify") {
                modifyListing();
              }
            }
            else {
              if (event == "new") {
                setContinueWarningState("pending new")
              }
              else if (event == "modify") {
                setContinueWarningState("pending modify");
              }
              setWarning(true);
            }
          });
        }
        else {
          alert("Missing Parameter or No Pictures Detected")
        }
      });
    }
    else {
      if (businessName != null && businessName != "" && minFloorSize != null && minFloorSize != "" && document.getElementById('periodMoveIn').value != "" && document.getElementById('priceOverall').value != "" && Number(document.getElementById('priceOverall').value) > 0) {
        if (residentialOccupation == "") {
          setContinueWarningState("pending new")
          setWarning(true);
        }
        else {
          newResidentialListing();
        }
      }
      else {
        if (businessName == null) {
          document.getElementById('listingName').style.border = "1px solid red"
        }
        else if (businessName == "") {
          document.getElementById('listingName').style.border = "1px solid red"
        }
        if (minFloorSize == null) {
          document.getElementById('minFloorSize').style.border = "1px solid red"
        }
        else if (minFloorSize == "") {
          document.getElementById('minFloorSize').style.border = "1px solid red"
        }
        if (document.getElementById('periodMoveIn').value == "") {
          document.getElementById('periodMoveIn').style.border = "1px solid red"
        }
        if (document.getElementById('priceOverall').value == "") {
          document.getElementById('priceOverall').style.border = "1px solid red"
        }
        if (Number(document.getElementById('priceOverall').value) <= 0) {
          document.getElementById('priceOverall').style.border = "1px solid red"
        }
        alert("Missing Parameters");
      }

    }

  }

  const checkSecondPage = () => {
    return new Promise(function (resolve, reject) {

      var coporateTurnover = document.getElementById("coporateTurnover").value;
      var otherRental = document.getElementById("otherRental").value;

      // var product_pricing = document.getElementById("product_pricing").value;

      if (additionInformationToggle == true) {
        var outletLocation = document.getElementById("outletLocation").value;
      }

      var expectedRevenue = document.getElementById("expectedRevenue").value;

      //company deck

      if (paidUpCapital == "" || coporateTurnover == "" || otherRental == "" || expectedRevenue == "" || (deckUpload == null && deckUploadAutoFill == null) || (productPricing == null && productPricingAutoFill == null) || companyName == "") {
        resolve(false);
      }
      else {
        if (additionInformationToggle == true) {
          if (outletLocation == "") {
            resolve(false);
          }
          else {
            resolve(true);
          }
        }
        else {
          resolve(true);
        }
      }
    })
  }

  const deleteListing = async () => {
    var cardInformation = {
      UID: user.uid,
      listingName: existingProject.listingName,
      pricePerSquareFeet: existingProject.pricePerSquareFeet,
      priceOverall: existingProject.priceOverall,
      location: existingProject.location,
      district: existingProject.district,
      status: existingProject.status,
      eyeballStat: existingProject.eyeballStat,
      timePosted: existingProject.timePosted,
      boost: existingProject.boost,
      click: existingProject.click,
      preferredListing: existingProject.preferredListing,
      eyeball: existingProject.eyeball,
      pendingChats: existingProject.pendingChats,
      propertyType: existingProject.propertyType,
      username: existingProject.username,
      conversationRate: existingProject.conversationRate,
      leaseTermMin: existingProject.leaseTermMin,
      leaseTermMax: existingProject.leaseTermMax
    }
    var moreDetails = {

      important_1: existingProject.important_1,
      important_2: existingProject.important_2,
      important_3: existingProject.important_3,
      important_4: existingProject.important_4,
      important_5: existingProject.important_5,
      periodMoveIn: existingProject.periodMoveIn,

      minSpace: existingProject.minSpace,
      maxSpace: existingProject.maxSpace,
      yearsOfOperation: existingProject.yearsOfOperation,
      minCeiling: existingProject.minCeiling,
      additionInformation: existingProject.additionInformation,
      acuquisitionSpace: existingProject.acuquisitionSpace,
      usp: existingProject.usp
    }


    await setDoc(doc(db, `deleteListing`, props.modifyListingId), cardInformation).then(async () => {
      await addDoc(collection(db, `deleteListing/${props.modifyListingId}/MoreDetails`), moreDetails, moreDetailsID.current).then(async () => {
        if (existingProject.preferredListing == true) {
          var preferredDetails = {
            paidUpCaptial: existingProject.paidUpCaptial,
            coporateTurnover: existingProject.coporateTurnover,
            otherRental: existingProject.otherRental,
            clinteleBreakdownLocal: existingProject.clinteleBreakdownLocal,
            clinteleBreakdownTourist: existingProject.clinteleBreakdownTourist,
            product_pricing: existingProject.product_pricing,
            revenueBreakdownWalkin: existingProject.revenueBreakdownWalkin,
            revenueBreakdownRegular: existingProject.revenueBreakdownRegular,
            outletLocation: existingProject.outletLocation,
            expectedRevenue: existingProject.expectedRevenue,
            companyDeckURL: existingProject.companyDeckURL,
            companyName: existingProject.companyName,
            autoFillLink: existingProject.autoFillLink
          }
          await getDocs(collection(db, `projects/${props.modifyListingId}/PreferredDetails`)).then(async (preferredDetailIndiv) => {
            preferredDetailIndiv.forEach(async (indivPreferred) => {
              await addDoc(collection(db, `deleteListing/${props.modifyListingId}/PreferredDetails`), preferredDetails, indivPreferred.id)
              await deleteDoc(doc(db, `projects/${props.modifyListingId}/PreferredDetails`, indivPreferred.id));
            })
          })
        }
      })
      await deleteDoc(doc(db, `projects/${props.modifyListingId}/MoreDetails`, moreDetailsID.current));
    })

    const commonSnap = await getDoc(doc(db, "commonData", 'information'));
    if (existingProject.status == "rejected") {
      var left = commonSnap.data().totalCompanyValue - 0;
    }
    else {
      var left = commonSnap.data().totalCompanyValue - existingListingAmount;
    }
    // var left = commonSnap.data().totalCompanyValue - existingListingAmount;
    await updateDoc(doc(db, "commonData", 'information'), {
      totalCompanyValue: left
    }).then(async () => {
      console.log(left);
      await deleteDoc(doc(db, `projects`, props.modifyListingId)).then(async () => {

        const listRef = ref(storage, `${user.uid}/${props.modifyListingId}`);

        // Find all the prefixes and items.
        listAll(listRef)
          .then(async (res) => {
            res.prefixes.forEach((folderRef) => {
              // All the prefixes under listRef.
              // You may call listAll() recursively on them.
            });
            res.items.forEach((itemRef) => {
              // All the items under listRef.
              const deleteRef = ref(storage, `${itemRef.fullPath}`);
              deleteObject(deleteRef).then(() =>
                console.log('done')).catch((error) => console.log(error));
            });
            setAcknowledgeMessage("Listing Deleted");
            setClickConfirm(true);
          }).catch((error) => {
            // Uh-oh, an error occurred!
          });
      });
      await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, props.modifyListingId));
    });




  }

  const deleteListingResidential = async () => {
    var cardInformation = {
      UID: user.uid,
      adults: existingProject.adults,
      bedrooms: existingProject.bedrooms,
      boost: existingProject.boost,
      boostLeft: existingProject.boostLeft,
      boostStart: existingProject.boostStart,
      children: existingProject.children,
      connections: existingProject.connections,
      conversationRate: existingProject.conversationRate,
      district: existingProject.district,
      eyeball: existingProject.eyeball,
      eyeballStat: existingProject.eyeballStat,
      furnishingType: existingProject.furnishingType,
      leaseTermResidential: existingProject.leaseTermResidential,
      listingName: existingProject.listingName,
      location: existingProject.location,
      pendingChats: existingProject.pendingChats,
      periodMoveIn: existingProject.periodMoveIn,
      preferredStatus: existingProject.preferredStatus,
      priceOverall: existingProject.priceOverall,
      propertyTypeResidential: existingProject.propertyTypeResidential,
      report: existingProject.report,
      specificPropertyType: existingProject.specificPropertyType,
      status: existingProject.status,
      timePosted: existingProject.timePosted,
      userImage: existingProject.userImage,
      username: existingProject.username
    }
    var moreDetails = {

      additionalRemarksResidential: existingProject.additionalRemarksResidential,
      floorLevel: existingProject.floorLevel,
      maxFloorSize: existingProject.maxFloorSize,
      minFloorSize: existingProject.minFloorSize,
      tenantGender: existingProject.tenantGender,

    }


    await setDoc(doc(db, `deleteListing`, props.modifyListingId), cardInformation).then(async () => {
      await addDoc(collection(db, `deleteListing/${props.modifyListingId}/MoreDetails`), moreDetails, moreDetailsID.current).then(async () => {
        if (existingProject.preferredListing == true) {
          var preferredDetails = {
            compositeTenant: existingProject.compositeTenant,
            relocation: existingProject.relocation,
            residentialAge: existingProject.residentialAge,
            residentialEmployment: existingProject.residentialEmployment,
            residentialExpectedStay: existingProject.residentialExpectedStay,
            residentialNationality: existingProject.residentialNationality,
            residentialOccupation: existingProject.residentialOccupation,
            residentialType: existingProject.residentialType,
            residentialYearsRent: existingProject.residentialYearsRent,
            smoke: existingProject.smoke
          }
          await getDocs(collection(db, `residentialListing/${props.modifyListingId}/PreferredDetails`)).then(async (preferredDetailIndiv) => {
            preferredDetailIndiv.forEach(async (indivPreferred) => {
              await addDoc(collection(db, `deleteListing/${props.modifyListingId}/PreferredDetails`), preferredDetails, indivPreferred.id)
              await deleteDoc(doc(db, `residentialListing/${props.modifyListingId}/PreferredDetails`, indivPreferred.id));
            })
          })
        }
      })
      await deleteDoc(doc(db, `residentialListing/${props.modifyListingId}/MoreDetails`, moreDetailsID.current));
    })
    const commonSnap = await getDoc(doc(db, "commonData", 'information'));
    if (existingProject.status == "rejected") {
      var left = commonSnap.data().totalCompanyValue - 0;
    }
    else {
      var left = commonSnap.data().totalCompanyValue - existingListingAmount;
    }
    // var left = commonSnap.data().totalCompanyValue - existingListingAmount;
    await updateDoc(doc(db, "commonData", 'information'), {
      totalCompanyValue: left
    });

    await deleteDoc(doc(db, `residentialListing`, props.modifyListingId)).then(async () => {
      await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, props.modifyListingId)).then(async () => {

        setAcknowledgeMessage("Listing Deleted");
        setClickConfirm(true);
      })
    });

  }

  if (!props.trigger) {
    return;
  }
  else {
    return (
      <>


        <div className='modalNewProject'>
          {/* <ExistingPhoto trigger={activateExistingPhoto} setTrigger={setActivateExistingPhoto} alreadyImageUpload={alreadyImageUpload} setAlreadyImageUpload={setAlreadyImageUpload} modifyListingId={props.modifyListingId}></ExistingPhoto> */}
          <Spinner trigger={openLoader} setTrigger={setOpenloader}></Spinner>
          {/* <LoaderPage trigger={openLoader} setTrigger={setOpenloader} /> */}
          <Warning trigger={warning} setTrigger={setWarning} continueWarning={continueWarningState} setContinueWarningState={setContinueWarningState}></Warning>
          <CropImage trigger={crop} setTrigger={setCrop} setPortrait={setPortrait} portrait={portrait} landscape={landscape} setLandscape={setLandscape} allImage={selfUploadArr == null ? defaultUpload : selfUploadArr} setSelfUploadArr={setSelfUploadArr} setDefaultUpload={setDefaultUpload}></CropImage>
          {defaultImage == true ? <DefaultImage trigger={defaultImage} setTrigger={setDefaultImage} setDefaultUpload={setDefaultUpload} defaultUpload={defaultUpload} alreadyImageUpload={alreadyImageUpload} /> : null}
          <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={acknowledgeMessage} setConfirmedDelete={setConfirmedDelete}></AcknowledgeNormal>
          <AcknowledgeNormal trigger={acknowledgeNormalNoDelete} setTrigger={setAcknowledgeNormalNoDelete} message={acknowledgeMessage}></AcknowledgeNormal>
          <Acknowledge trigger={clickConfirm} setTrigger={setClickConfirm} message={acknowledgeMessage} />
          <div className='modal-contentNewProject'>
            <div className='modal-headerNewProject'>
              <div className="notification-close" onClick={() => closeNewProjecct()}>
                ×
              </div>
              <h4 className='modal-titleNewListing'>ADD NEW LISTING</h4>
              <span style={{ paddingLeft: "10px" }}>{typeOfSpace == "Commercial" ? "Commercial Rent" : "Residential Rent"}</span>
            </div>
            <div className='modal-bodyNewProject'>


              {typeOfSpace == "Commercial" ?
                //////////////////////////////////Commercial
                <>

                  <div class="stepper_tab">
                    {/* {props.modifyListingId == null ? <select id="typeOfSpace" value={typeOfSpace} onChange={handleTypeOfSpaceChange}>
                      <option value="Residential">Residential</option>
                      <option value="Commercial">Commercial</option>
                    </select> : null} */}
                    <div style={{ clear: 'both' }}></div>
                    <p className='modalLabel' style={{ marginTop: "1%" }}>Business/Trade Type*</p>
                    <input className='newListingInput' id="listingName" placeholder='(Brand name, if any) Cafe / Bicycle Shop /, etc' style={{ width: "100%" }} value={businessName} onChange={handleBusinessName}></input><span className='characterCount'>{businessName != null ? MAX_CHARACTERS_SMALL - businessName.length : MAX_CHARACTERS_SMALL} characters</span>

                    <div className='newProjectImportantRequirements' style={{ marginTop: "10%" }}>
                      <div className='newProjectImportantRequirementsLeft'>
                        <p className='modalLabel'>Important Requirement 1: </p>
                        <input className='newListingInput' id="important1" placeholder="e.g. Strictly column free" value={important1} onChange={handleImportant1Change}></input><span className='characterCount'>{important1 != null ? MAX_CHARACTERS_SMALL - important1.length : MAX_CHARACTERS_SMALL} characters</span>
                        <p className='modalLabel'>Important Requirement 2: </p>
                        <input className='newListingInput' id="important2" placeholder="e.g. Water point" value={important2} onChange={handleImportant2Change}></input><span className='characterCount'>{important2 != null ? MAX_CHARACTERS_SMALL - important2.length : MAX_CHARACTERS_SMALL} characters</span>
                        <p className='modalLabel'>Important Requirement 3: </p>
                        <input className='newListingInput' id="important3" placeholder="e.g. Able to operate 24hr" value={important3} onChange={handleImportant3Change}></input><span className='characterCount'>{important3 != null ? MAX_CHARACTERS_SMALL - important3.length : MAX_CHARACTERS_SMALL} characters</span>
                      </div>

                      <div className='newProjectImportantRequirementsRight'>
                        <p className='modalLabel'>Important Requirement 4: </p>
                        <input className='newListingInput' id="important4" placeholder="e.g. Lightings and power supply" value={important4} onChange={handleImportant4Change}></input><span className='characterCount'>{important4 != null ? MAX_CHARACTERS_SMALL - important4.length : MAX_CHARACTERS_SMALL} characters</span>

                        <p className='modalLabel'>Important Requirement 5: </p>
                        <input className='newListingInput' id="important5" placeholder="e.g. Washroom" value={important5} onChange={handleImportant5Change}></input><span className='characterCount'>{important5 != null ? MAX_CHARACTERS_SMALL - important5.length : MAX_CHARACTERS_SMALL} characters</span>

                      </div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div style={{ marginTop: "10%" }}>
                      <div id='newListingLeft'>
                        <p className='modalLabel'>Minimum Ceiling Height: (Meters)</p>
                        <input className='newListingInput' id="ceilingHeight" placeholder="Units in meters" type="Number" min="0" onWheel={(e) => { e.preventDefault(); }}></input>
                      </div>
                      <div id='newListingRight'>
                        <p className='modalLabel'>Reason For Acquisition Of Space</p>
                        <input className='newListingInput' id="reasonForAcuquisition" placeholder="Applied to relocation & expansion" value={acuquisitionSpace} onChange={handleAcuquisationSpace}></input><span className='characterCount'>{acuquisitionSpace != null ? MAX_CHARACTERS_SMALL - acuquisitionSpace.length : MAX_CHARACTERS_SMALL} characters</span>
                      </div>
                      <div style={{ clear: 'both' }}></div>
                      <div>
                        <div className='newProjectminSqFeet'>
                          <p className='modalLabel' >Minimum Square Feet Requirement</p>
                          <select className='newListingSelect' id="minSpace">
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                            <option value="400">400</option>
                            <option value="500">500</option>
                            <option value="600">600</option>
                            <option value="700">700</option>
                            <option value="800">800</option>
                            <option value="900">900</option>
                            <option value="1000">1000</option>
                            <option value="1500">1500</option>
                            <option value="2000">2000</option>
                            <option value="2500">2500</option>
                            <option value="3000">3000</option>
                            <option value="3500">3500</option>
                            <option value="4000">4000</option>
                            <option value="4500">4500</option>
                            <option value="5000">5000</option>
                            <option value="5500">5500</option>
                            <option value="6000">6000</option>
                            <option value="6500">6500</option>
                            <option value="7000">7000</option>
                            <option value="7500">7500</option>
                            <option value="8000">8000</option>
                            <option value="8500">8500</option>
                            <option value="9000">9000</option>
                            <option value="10000">10000</option>
                            <option value="> 10000">> 10000</option>
                          </select>
                          <div style={{ clear: 'both' }}></div>
                        </div>
                        <div className='newProjectmaxSqFeet'>
                          <p className='modalLabel'>Maximum Square Feet Requirement</p>
                          <select className='newListingSelect' id="maxSpace">
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                            <option value="400">400</option>
                            <option value="500">500</option>
                            <option value="600">600</option>
                            <option value="700">700</option>
                            <option value="800">800</option>
                            <option value="900">900</option>
                            <option value="1000">1000</option>
                            <option value="1500">1500</option>
                            <option value="2000">2000</option>
                            <option value="2500">2500</option>
                            <option value="3000">3000</option>
                            <option value="3500">3500</option>
                            <option value="4000">4000</option>
                            <option value="4500">4500</option>
                            <option value="5000">5000</option>
                            <option value="5500">5500</option>
                            <option value="6000">6000</option>
                            <option value="6500">6500</option>
                            <option value="7000">7000</option>
                            <option value="7500">7500</option>
                            <option value="8000">8000</option>
                            <option value="8500">8500</option>
                            <option value="9000">9000</option>
                            <option value="10000">10000</option>
                            <option value="> 10000">> 10000</option>
                          </select>
                          <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                      </div>

                      <div>
                        <div id="newProjectMinLeasePeriod">
                          <p className='modalLabel'>*Minimum Lease Terms:{<br></br>}(1 Day to 10 Years) </p>
                          {/* <input className='newListingInput' id="minLeaseNum" placeholder='Min' type="Number" style={{ width: "48%" }} value={minLeaseNum} onChange={handleMinLeaseNumChanges}></input> */}
                          {minLeasePeriod == "Days" ?
                            <select className='newListingSelect' style={{ width: "48%" }} id="dropdown" name="dropdown" value={minLeaseNum} onChange={(e) => { setMinLeaseNum(e.target.value) }}>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                            </select> :
                            minLeasePeriod == "Months" ?
                              <select className='newListingSelect' style={{ width: "48%" }} id="dropdown" name="dropdown" value={minLeaseNum} onChange={(e) => { setMinLeaseNum(e.target.value) }}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                              </select> :
                              <select className='newListingSelect' style={{ width: "48%" }} id="dropdown" name="dropdown" value={minLeaseNum} onChange={(e) => { setMinLeaseNum(e.target.value) }}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                          }
                          <select className='newListingSelect' id="minLeasePeriod" name="dropdown" style={{ width: "48%" }} value={minLeasePeriod} onChange={handleMinLeasePeriodChanges}>
                            <option value="Days">Days</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                        </div>



                        <div id="newProjectMaxLeasePeriod">
                          <p className='modalLabel'>Maximum Lease Terms:{<br></br>}(up to 10 Years) </p>
                          {/* <input className='newListingInput' placeholder='Max' type="Number" style={{ width: "48%" }} value={maxLeaseNum} onChange={handleMaxLeaseNumChanges}></input> */}
                          {maxLeasePeriod == "Days" ?
                            <select className='newListingSelect' style={{ width: "48%" }} id="dropdown" name="dropdown" value={maxLeaseNum} onChange={handleMaxLeaseNumChanges}>
                              <option value="NA">Not Applicable</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                            </select> :
                            maxLeasePeriod == "Months" ?
                              <select className='newListingSelect' style={{ width: "48%" }} id="dropdown" name="dropdown" value={maxLeaseNum} onChange={handleMaxLeaseNumChanges}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                              </select> :
                              <select className='newListingSelect' style={{ width: "48%" }} id="dropdown" name="dropdown" value={maxLeaseNum} onChange={handleMaxLeaseNumChanges}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                          }
                          <select className='newListingSelect' id="dropdown" name="dropdown" style={{ width: "48%" }} value={maxLeasePeriod} onChange={handleMaxLeasePeriodChanges}>
                            <option value="Days">Days</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                        </div>

                        <div style={{ clear: 'both' }}></div>
                      </div>
                    </div>
                    <div style={{ marginTop: "10%" }}>
                      <div id='newListingLeft'>
                        <p className='modalLabel'>*Location: </p>
                        <select className='newListingSelect' id="districtType">
                          <option value="D01 City - Business District">D01 City - Business District</option>
                          <option value="D02 City - Business District">D02 City - Business District</option>
                          <option value="D03 Central South">D03 Central South</option>
                          <option value="D04 South">D04 South</option>
                          <option value="D05 South West">D05 South West</option>
                          <option value="D06 City - Business District">D06 City - Business District</option>
                          <option value="D07 City">D07 City</option>
                          <option value="D08 Central">D08 Central</option>
                          <option value="D09 Central - Orchard">D09 Central - Orchard</option>
                          <option value="D10 Central - Near Orchard">D10 Central - Near Orchard</option>
                          <option value="D11 Central - Near Orchard">D11 Central - Near Orchard</option>
                          <option value="D12 Central">D12 Central</option>
                          <option value="D13 Central East">D13 Central East</option>
                          <option value="D14 Central East">D14 Central East</option>
                          <option value="D15 East Coast">D15 East Coast</option>
                          <option value="D16 Upper East Coast">D16 Upper East Coast</option>
                          <option value="D17 Far East">D17 Far East</option>
                          <option value="D18 Far East">D18 Far East</option>
                          <option value="D19 North East">D19 North East</option>
                          <option value="D20 Central North">D20 Central North</option>
                          <option value="D21 Central West">D21 Central West</option>
                          <option value="D22 Far West">D22 Far West</option>
                          <option value="D23 North West">D23 North West</option>
                          <option value="D24 Far North West">D24 Far North West</option>
                          <option value="D25 Far North">D25 Far North</option>
                          <option value="D26 North">D26 North</option>
                          <option value="D27 Far North">D27 Far North</option>
                          <option value="D28 North East">D28 North East</option>
                        </select>
                        <input className='newListingInput' id="locationListing" placeholder="E.g. specific street, if any"></input>
                      </div>
                      <div id='newListingRight'>
                        <p className='modalLabel' style={{ position: "relative" }}>
                          <i
                            className="fa-solid fa-circle-question"
                            style={{ color: "#316190", paddingRight: "5px" }}
                            onMouseEnter={() => showAddPropertyQuestionMark()}
                            onMouseLeave={() => showAddPropertyQuestionMark()}
                            onClick={() => showAddPropertyQuestionMark()}
                          />
                          <div
                            id={`showAddPropertyQuestionMark`}
                            style={{
                              display: "none",
                              position: "absolute",
                              backgroundColor: "#fff",
                              padding: "15px",
                              border: "1px solid #ccc",
                              borderRadius: "10px",
                              fontWeight: "normal",
                              textAlign: "justify",
                              boxShadow: "0 4px 8px rgba(0,0,0,0.6)"
                            }}
                          >
                            {"This perimeter allows us to match you to your preferred landmarks (e.g. specific school, buildings, etc). Within a 1km radius of your preferred landmark, notifications will be sent to landlords/property agents with properties in the area."}
                          </div>
                          Proximity to:
                        </p>
                        <input className='newListingInput' type="number" id="postalCode" placeholder='Key in postal code' onWheel={(e) => { e.preventDefault(); }}></input>
                      </div>
                      <div style={{ clear: 'both' }}></div>
                      <div id='newListingLeft'>
                        <p className='modalLabel'>Number of years in operations: </p>
                        <input className='newListingInput' id="yearsOfOperations" placeholder="Number of years in operations" type="Number" onWheel={(e) => { e.preventDefault(); }}></input>
                        <p className='modalLabel'>*Moving In Period (Starting from): </p>
                        <input className='newListingInput' type="date" id="periodMoveIn"></input>
                      </div>

                      <div id='newListingRight'>
                        <p className='modalLabel'>Property Usage: </p>
                        <select className='newListingSelect' id="propertyType">
                          <option value="F&B">F&B</option>
                          <option value="Retail">Retail</option>
                          <option value="Services">Services</option>
                          <option value="Storage">Storage</option>
                          <option value="Others">Others</option>
                        </select>
                        <p className='modalLabel'>*Budget: </p>
                        <input className='newListingInput' id="pricePerSquareFeet" placeholder="Monthly PSF budget" type="Number" onWheel={(e) => { e.preventDefault(); }}></input>
                        <input className='newListingInput' id="priceOverall" placeholder="Monthly rental budget" type="Number" onWheel={(e) => { e.preventDefault(); }}></input>
                        <span className='characterCount'>Enter numbers without using the dollar sign ($) or any spacing characters</span>
                      </div>

                      <div style={{ clear: 'both' }}></div>
                    </div>

                    <div style={{ marginTop: "10%" }}>
                      <p className='modalLabel'>Unique Selling Point</p>



                      <textarea class="newListingInputUSP" id="usp" placeholder="Type your paragraph here..." value={usp} onChange={handleUSPChange}></textarea><span className='characterCount'>{usp != null ? MAX_CHARACTERS_BIG - usp.length : MAX_CHARACTERS_BIG} characters</span>
                      <p className='modalLabel'>Additional Information: </p>
                      <div style={{ padding: "10px 10px" }}>
                        <input className='checkboxInput' type="radio" id="Relocation" name="addtionalInfo" value="Relocation" />
                        <label className='checkboxLabel' for="Relocation">Relocation</label>
                        <br></br>
                        <input className='checkboxInput' type="radio" id="Expansion" name="addtionalInfo" value="Expansion" />
                        <label className='checkboxLabel' for="Expansion">Expansion</label>
                        <br></br>
                        <input className='checkboxInput' type="radio" id="StartUps" name="addtionalInfo" value="Start Ups" />
                        <label className='checkboxLabel' for="StartUps">Start Ups</label>
                        <br></br>
                      </div>

                      <p className='modalLabel'>*Business Image: </p>
                      <div id="businessDiv">

                        <button class="defaultUploadBtn" onClick={() => { activeDefault() }}>Default Images</button>

                        <br></br>
                        <button class="defaultUploadBtn" onClick={() => { uploadSelfImage.current.click() }}>Upload Images</button>
                        <input ref={uploadSelfImage} id="file-upload" type="file" accept=".png, .jpg, .jpeg" style={{ display: "none" }} onChange={(event) => { setPhotoUpload(event) }} multiple />
                        <div>
                          <span className='characterCount'>Maximum of 5 photos</span>
                          <br></br>
                          {props.modifyListingId == null ? null : <p>Existing Photos</p>}
                          {alreadyImageUpload != null ? alreadyImageUpload.map((image, index) => (

                            <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                              <img style={{ width: "50px", height: "50px" }} key={index} src={image.tempUrl} alt="Image not available" /><span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "transparent", color: "red", padding: "1px", cursor: "pointer" }} onClick={() => deleteExistingImageUpload(image.tempUrl, index)}>x</span>
                            </div>
                          )) : null}
                          {props.modifyListingId == null ? null : <p>New Photos</p>}
                          {defaultUpload.length != 0 ? defaultUpload.map((indivImg) => {
                            return (
                              <>
                                <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                                  <img src={indivImg} style={{ width: "40px", height: "40px", marginRight: "1%" }}></img>
                                </div>
                              </>
                            );
                          }) : null}


                          {landscapeTempURL != null ? landscapeTempURL.map((indivImg) => {
                            return (
                              <>
                                <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                                  <img src={indivImg.tempUrl} style={{ width: "40px", height: "40px", marginRight: "1%" }}></img><span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "transparent", color: "red", padding: "1px", cursor: "pointer" }} onClick={() => deleteLandscape(indivImg.id)}>x</span>
                                </div>
                              </>
                            );
                          }) : null}


                          {selfUploadArrTempURL != null ?
                            selfUploadArrTempURL.map((indivImg) => {
                              return (
                                <>
                                  <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                                    <img src={indivImg.tempUrl} style={{ width: "40px", height: "40px", marginRight: "1%" }}></img><span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "transparent", color: "red", padding: "1px", cursor: "pointer" }} onClick={() => deleteSelfUploadArr(indivImg.id)}>x</span>
                                  </div>
                                </>
                              );
                            }) : null}

                        </div>

                      </div>

                      {currentUserInfo != null ? currentUserInfo.userType.includes("Agent") && currentUserInfo.userType.includes("Tenant") ?
                        <><p className='modalLabel'>Who do you represent</p>
                          <select value={dualRoleAgent} onChange={(e) => { setDualRoleAgent(e.target.value) }} style={{ padding: "5px 8px", borderRadius: "10px" }}>
                            <option value={"Agent"}>Agent</option>
                            <option value={"Tenant"}>Tenant</option>
                          </select></> : null : null}
                    </div>
                  </div>

                  <div class="stepper_tab">
                    {props.accountBusinessArray != null && props.accountBusinessArray.length > 0 ?
                      <>
                        <div style={{ float: "right", marginRight: "3%", marginBottom: "2%" }}>
                          {/* <select id="preferredListingSelectAutoFill" value={autoFill} onChange={handleAutoFillChanges}>
                        <option value={"Auto-Fill"}>Auto-Fill</option>
                        {props.accountBusinessArray.map((indivBus, index) => {
                          return (
                            <option value={index}>{indivBus.companyName}</option>
                          )
                        })}
                      </select> */}
                          <select id="preferredListingSelectAutoFill" value={autoFill} onChange={handleAutoFillChanges}>
                            <option value={"Auto-Fill"} selected={autoFill === "Auto-Fill"}>Auto-Fill</option>
                            {props.accountBusinessArray.map((indivBus, index) => {
                              return (
                                <option value={index}>{indivBus.companyName}</option>
                              )
                            })}
                          </select>

                        </div>
                        <div style={{ clear: 'both' }}></div>
                      </> : null}

                    <div id='newListingLeft'>
                      <p className='modalLabel'>Company Name</p>
                      <input className='newListingInput' id="companyName" placeholder='Company Name' value={companyName} onChange={handleChangesCompanyName}></input>
                      <p className='modalLabel'>Revenue Breakdown: (Walk In)</p>
                      <input className='newListingInput' type="number" onWheel={(e) => { e.preventDefault(); }} id="revenueBreakdownWalkin" placeholder='Percentage of Walk In' style={{ width: "92%" }} value={walkInBreakdown} onChange={handleChangesWalkInBreakdown}></input> %
                      <p className='modalLabel'>Clintele Breakdown: (Local) </p>
                      <input className='newListingInput' type="number" onWheel={(e) => { e.preventDefault(); }} id="clinteleBreakdownLocal" placeholder='  Percentage Of Local' style={{ width: "92%" }} value={localBreakdown} onChange={handleChangeslocalBreakdown}></input> %


                      {/* <input className='newListingInput' id="product_pricing" placeholder=""></input> */}
                    </div>

                    <div id='newListingRight'>
                      <p className='modalLabel'>Paid Up Capital</p>
                      <select className='newListingInput' id="paidUpCapital" value={paidUpCapital} onChange={handlePaidUpChanges}>
                        <option value={"< 100K"}>&lt; 100K</option>
                        <option value={"100K to < 300K"}>100K to &lt; 300K</option>
                        <option value={"300K to < 500K"}>300K to &lt; 500K</option>
                        <option value={"> 500K"}>&gt; 500K</option>
                      </select>
                      {/* <input  id="paidUpCaptial" placeholder='Paid Up Capital'></input> */}

                      <p className='modalLabel'>Revenue Breakdown: (Regular)</p>
                      <input className='newListingInput' type="number" onWheel={(e) => { e.preventDefault(); }} id="revenueBreakdownRegular" placeholder='Percentage of Regular' style={{ width: "92%" }} value={regularBreakdown} onChange={handleChangesRegular}></input> %
                      <p className='modalLabel'>Clintele Breakdown: (Tourist) </p>
                      <input className='newListingInput' type="number" onWheel={(e) => { e.preventDefault(); }} id="clinteleBreakdownTourist" placeholder='  Percentage Of Tourist' style={{ width: "92%" }} value={touristBreakdown} onChange={handleChangesTourist}></input> %

                    </div>
                    <div style={{ clear: 'both', marginBottom: "5%" }}></div>
                    <div>
                      <div id='newListingLeft'><p className='modalLabel'>Product of business / Pricing of products: </p>
                        <input type="file" className='uploadBtn' id="companyDeck" name="product_pricing" accept=".pdf" onChange={(event) => setFileUploadProduct_pricing(event)}></input>
                        {productPricingAutoFill != null && productPricing == null ?
                          <>
                            <br></br>
                            <i class="fa fa-file-pdf-o" style={{ fontSize: "20px", marginLeft: "3%", color: "grey", cursor: "pointer" }} onClick={() => window.open(productPricingAutoFill)}></i>
                          </> : null}</div>
                      <div id='newListingRight'><p className='modalLabel' >Company Deck <br></br> <p style={{ fontSize: "11px", color: "grey" }}>Accept only pdf format</p></p>
                        <input type="file" className='uploadBtn' id="companyDeck" name="companyDeck" accept=".pdf" onChange={(event) => setFileUpload(event)}></input>
                        {deckUploadAutoFill != null && deckUpload == null ?
                          <>
                            <br></br>
                            <i class="fa fa-file-pdf-o" style={{ fontSize: "20px", marginLeft: "3%", color: "grey", cursor: "pointer" }} onClick={() => window.open(deckUploadAutoFill)}></i>
                          </> : null}</div>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    <div id='newListingLeft'>
                      <p className='modalLabel'>Coporate Turnover: </p>
                      <input className='newListingInput' id="coporateTurnover" placeholder="coporateTurnover"></input>
                      <p className='modalLabel'>Rental Amount At Other Locations</p>
                      <input className='newListingInput' id="otherRental" placeholder="Rental Amount At Other Locations"></input>
                    </div>
                    <div id='newListingRight'>
                      <p className='modalLabel'>Expected Revenue At New Propose Location: </p>
                      <input className='newListingInput' id="expectedRevenue" placeholder="Expected Revenue"></input>
                      <p className='modalLabel' >Outlets Location</p>
                      <input className='newListingInput' id="outletLocation" placeholder="Applied to relocation & expansion"></input>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                  </div>
                </> :
                //////////////////////Residential////////////////////////////////////////
                <>

                  <div class="stepper_tab">
                    {/* {props.modifyListingId == null ? <select id="typeOfSpace" value={typeOfSpace} onChange={handleTypeOfSpaceChange}>
                      <option value="Residential">Residential</option>
                      <option value="Commercial">Commercial</option>
                    </select> : null} */}
                    <div style={{ clear: 'both' }}></div>
                    <p className='modalLabel' style={{ marginTop: "1%" }}>Listing Name*</p>
                    <input className='newListingInput' id="listingName" placeholder='(Brand name, if any) Cafe / Bicycle Shop /, etc' style={{ width: "100%" }} value={businessName} onChange={handleBusinessName}></input><span className='characterCount'>{businessName != null ? MAX_CHARACTERS_SMALL - businessName.length : MAX_CHARACTERS_SMALL} characters</span>
                    <div style={{ clear: 'both' }}></div>
                    <div style={{ marginTop: "5%" }}>
                      <div id='newListingLeft'>
                        <p className='modalLabel'>Tenant Gender</p>
                        <select className='newListingSelect' id="listingGender" value={listingGender} onChange={handleListingGender}>
                          <option value="Mixed">Mixed</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                      <div id='newListingRight'>
                        <p className='modalLabel'>Floor Level</p>
                        <select className='newListingSelect' id="floorLevel" value={floorLevel} onChange={handleFloorLevel}>
                          <option value="Ground">Ground</option>
                          <option value="Low">Low</option>
                          <option value="Mid">Mid</option>
                          <option value="High">High</option>
                          <option value="Penthouse">Penthouse</option>
                          <option value="Any">Any</option>
                        </select>
                      </div>
                      <div>
                        <div className='newProjectminSqFeet'>
                          <p className='modalLabel' >*Adult(s)</p>
                          <select className='newListingSelect' id="adults" value={adults} onChange={handleAdults}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                          <div style={{ clear: 'both' }}></div>
                        </div>
                        <div className='newProjectmaxSqFeet'>
                          <p className='modalLabel'>*Children</p>
                          <select className='newListingSelect' id="children" value={children} onChange={handleChildren}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                          <div style={{ clear: 'both' }}></div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                      </div>

                      <div>
                        <div id="newProjectMinLeasePeriod">
                          <p className='modalLabel'>*Minimum Floor Size</p>
                          <input className='newListingInput' id="minFloorSize" placeholder='Min' type="Number" onWheel={(e) => { e.preventDefault(); }} value={minFloorSize} onChange={handleMinFloorSize}></input>
                          <span className='characterCount'>Square Foot (SQFT)</span>
                        </div>



                        <div id="newProjectMaxLeasePeriod">
                          <p className='modalLabel'>Maximum Floor Size</p>
                          <input className='newListingInput' id="maxFloorSize" placeholder='Max' type="Number" onWheel={(e) => { e.preventDefault(); }} value={maxFloorSize} onChange={handleMaxFloorSize}></input>
                          <span className='characterCount'>Square Foot (SQFT)</span>
                        </div>

                        <div style={{ clear: 'both' }}></div>
                      </div>
                    </div>

                    <div style={{ marginTop: "5%" }}>
                      <div id='newListingLeft'>
                        <p className='modalLabel'>*Location</p>
                        <select className='newListingSelect' id="districtType">
                          <option value="Any">Any</option>
                          <option value="D01 City - Business District">D01 City - Business District</option>
                          <option value="D02 City - Business District">D02 City - Business District</option>
                          <option value="D03 Central South">D03 Central South</option>
                          <option value="D04 South">D04 South</option>
                          <option value="D05 South West">D05 South West</option>
                          <option value="D06 City - Business District">D06 City - Business District</option>
                          <option value="D07 City">D07 City</option>
                          <option value="D08 Central">D08 Central</option>
                          <option value="D09 Central - Orchard">D09 Central - Orchard</option>
                          <option value="D10 Central - Near Orchard">D10 Central - Near Orchard</option>
                          <option value="D11 Central - Near Orchard">D11 Central - Near Orchard</option>
                          <option value="D12 Central">D12 Central</option>
                          <option value="D13 Central East">D13 Central East</option>
                          <option value="D14 Central East">D14 Central East</option>
                          <option value="D15 East Coast">D15 East Coast</option>
                          <option value="D16 Upper East Coast">D16 Upper East Coast</option>
                          <option value="D17 Far East">D17 Far East</option>
                          <option value="D18 Far East">D18 Far East</option>
                          <option value="D19 North East">D19 North East</option>
                          <option value="D20 Central North">D20 Central North</option>
                          <option value="D21 Central West">D21 Central West</option>
                          <option value="D22 Far West">D22 Far West</option>
                          <option value="D23 North West">D23 North West</option>
                          <option value="D24 Far North West">D24 Far North West</option>
                          <option value="D25 Far North">D25 Far North</option>
                          <option value="D26 North">D26 North</option>
                          <option value="D27 Far North">D27 Far North</option>
                          <option value="D28 North East">D28 North East</option>
                        </select>
                        <input className='newListingInput' id="locationListing" placeholder="E.g. specific street, if any"></input>
                      </div>
                      <div id='newListingRight'>
                        <p className='modalLabel' style={{ position: "relative" }}>
                          <i
                            className="fa-solid fa-circle-question"
                            style={{ color: "#316190", paddingRight: "5px" }}
                            onMouseEnter={() => showAddPropertyQuestionMark()}
                            onMouseLeave={() => showAddPropertyQuestionMark()}
                            onClick={() => showAddPropertyQuestionMark()}
                          />
                          <div
                            id={`showAddPropertyQuestionMark`}
                            style={{
                              display: "none",
                              position: "absolute",
                              backgroundColor: "#fff",
                              padding: "15px",
                              border: "1px solid #ccc",
                              borderRadius: "10px",
                              fontWeight: "normal",
                              textAlign: "justify",
                              boxShadow: "0 4px 8px rgba(0,0,0,0.6)"
                            }}
                          >
                            {"This perimeter allows us to match you to your preferred landmarks (e.g. specific school, buildings, etc). Within a 1km radius of your preferred landmark, notifications will be sent to landlords/property agents with properties in the area."}
                          </div>
                          Proximity to:
                        </p>
                        <input className='newListingInput' type="number" id="postalCode" placeholder='Key in postal code' onWheel={(e) => { e.preventDefault(); }}></input>

                      </div>
                      <div style={{ clear: 'both' }}></div>
                      <div id='newListingLeft'>
                        <p className='modalLabel' style={entireUnit == true ? { color: "#ccc" } : { color: "black" }}>*Bedroom(s)</p>
                        <select className='newListingSelect' id="bedrooms" value={bedrooms} onChange={handleBedrooms} style={entireUnit == true ? { backgroundColor: "#ccc", color: "#c0c0c0", border: "#ccc" } : { backgroundColor: "transparent" }} disabled={entireUnit}>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value=">=8">{">=8"}</option>
                        </select>
                        <br></br>
                        <label>
                          <input
                            type="checkbox"
                            checked={entireUnit}
                            onChange={() => setEntireUnit(!entireUnit)}
                          />
                          Entire Unit
                        </label>
                      </div>
                      <div id='newListingRight'>
                        <p className='modalLabel'>Furnishing</p>
                        <select className='newListingSelect' id="furnishingType" value={furnishingType} onChange={handleFurnishingType}>
                          <option value="Unfurnished">Unfurnished</option>
                          <option value="Partially Furnished">Partially Furnished</option>
                          <option value="Fully Furnished">Fully Furnished</option>
                        </select>
                      </div>
                      <div style={{ clear: 'both' }}></div>
                      <div id='newListingLeft'>
                        <p className='modalLabel'>*Budget</p>
                        <input className='newListingInput' id="priceOverall" placeholder="Monthly rental budget" type="Number" onWheel={(e) => { e.preventDefault(); }}></input>
                        <span className='characterCount'>Enter numbers without using the dollar sign ($) or any spacing characters</span>
                      </div>
                      <div id='newListingRight'>
                        <p className='modalLabel'>Lease Term</p>
                        <select className='newListingSelect' id="leaseTermResidential" value={leaseTermResidential} onChange={handleLeaseTermResidential}>
                          <option value="1 Year">1 Year</option>
                          <option value="2 Years">2 Years</option>
                          <option value="3 Years">3 Years</option>
                          <option value="Short Term / Flexi">Short Term / Flexi</option>
                        </select>
                      </div>

                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div style={{ marginTop: "5%" }}>
                      <div id='newListingLeft'>
                        <p className='modalLabel'>*Property Type</p>
                        <select className='newListingSelect' id="propertyTypeResidential" value={propertyTypeResidential} onChange={handlePropertyTypeResidential}>
                          <option value="Any">Any</option>
                          <option value="HDB">HDB</option>
                          <option value="Condominium">Condominium</option>
                          <option value="Landed">Landed</option>
                        </select>
                        <p className='modalLabel'>Specific Type</p>
                        {propertyTypeResidential == "Landed" ?
                          <select className='newListingSelect' id="propertyType" value={specificPropertyType} onChange={handleSpecificPropertyType}>
                            <option value="Detached House">Detached House</option>
                            <option value="Semi-Deteached House">Semi-Deteached House</option>
                            <option value="Corner Terrace">Corner Terrace</option>
                            <option value="Bungalow House">Bungalow House</option>
                            <option value="Good Class Bungalow">Good Class Bungalow</option>
                            <option value="Shophouse">Shophouse</option>
                            <option value="Town House">Town House</option>
                            <option value="Conservation House">Conservation House</option>
                            <option value="Cluster House">Cluster House</option>
                          </select>
                          :
                          propertyTypeResidential == "Condominium" ?
                            <select className='newListingSelect' id="propertyType" value={specificPropertyType} onChange={handleSpecificPropertyType}>
                              <option value="Condominium">Condominium</option>
                              <option value="Apartment">Apartment</option>
                              <option value="Walk-up">Walk-up</option>
                              <option value="Cluster House">Cluster House</option>
                              <option value="Executive Condominium">Executive Condominium</option>
                            </select>
                            :
                            propertyTypeResidential == "HDB" ?
                              <select className='newListingSelect' id="propertyType" value={specificPropertyType} onChange={handleSpecificPropertyType}>
                                <option value="1 Room">1 Room</option>
                                <option value="1 Room / Studio">1 Room / Studio</option>
                                <option value="2 Rooms">2 Rooms</option>
                                <option value="2 Rooms 2A">2 Rooms 2A</option>
                                <option value="2 Rooms 2I (Improved)">2 Rooms 2I (Improved)</option>
                                <option value="2 Rooms 2S (Standard)">2 Rooms 2S (Standard)</option>
                                <option value="3 Rooms">3 Rooms</option>
                                <option value="3 Rooms 3A">3 Rooms 3A</option>
                                <option value="3 Rooms 3NG (New Generation)">3 Rooms 3NG (New Generation)</option>
                                <option value="3 Rooms 3A (Modified)">3 Rooms 3A (Modified)</option>
                                <option value="3 Rooms 3S (Simplified)">3 Rooms 3S (Simplified)</option>
                                <option value="3 Rooms 3STD (Standard)">3 Rooms 3STD (Standard)</option>
                                <option value="3 Rooms 3PA (Premium Apartment)">3 Rooms 3PA (Premium Apartment)</option>
                                <option value="4 Rooms">4 Rooms</option>
                                <option value="4 Rooms 4A">4 Rooms 4A</option>
                                <option value="4 Rooms 4NG (New Generation)">4 Rooms 4NG (New Generation)</option>
                                <option value="4 Rooms 4PA (Premium Apartment)">4 Rooms 4PA (Premium Apartment)</option>
                                <option value="4 Rooms 4S (Simplified)">4 Rooms 4S (Simplified)</option>
                                <option value="4 Rooms 4I (Improved)">4 Rooms 4I (Improved)</option>
                                <option value="4 Rooms 4STD (Standard)">4 Rooms 4STD (Standard)</option>
                                <option value="5 Rooms">5 Rooms</option>
                                <option value="5 Rooms 5A">5 Rooms 5A</option>
                                <option value="5 Rooms 5I (Improved)">5 Rooms 5I (Improved)</option>
                                <option value="5 Rooms 5PA (Premium Apartment)">5 Rooms 5PA (Premium Apartment)</option>
                                <option value="5 Rooms 5S (Simplified)">5 Rooms 5S (Simplified)</option>
                                <option value="Jumbo">Jumbo</option>
                                <option value="EA (Exec Apartment)">EA (Exec Apartment)</option>
                                <option value="EM (Exec Maisonette)">EM (Exec Maisonette)</option>
                                <option value="MG (Multi-Generation)">MG (Multi-Generation)</option>
                                <option value="Terrace">Terrace</option>
                              </select>
                              : null
                        }
                      </div>
                      <div id='newListingRight'>
                        <p className='modalLabel'>*Moving In Period (Starting from): </p>
                        <input className='newListingInput' type="date" id="periodMoveIn"></input>
                      </div>

                      <div style={{ clear: 'both' }}></div>
                      <p className='modalLabel'>Additional Remarks</p>
                      <textarea class="newListingInputUSP" id="additionalRemarks" placeholder="Type your paragraph here..." value={additionalRemarksResidential} onChange={handleAdditionalRemarksResidential}></textarea><span className='characterCount'>{additionalRemarksResidential != null ? MAX_CHARACTERS_BIG - additionalRemarksResidential.length : MAX_CHARACTERS_BIG} characters</span>
                    </div>
                    {currentUserInfo != null ? currentUserInfo.userType.includes("Agent") && currentUserInfo.userType.includes("Tenant") ?
                      <><p className='modalLabel'>Who do you represent</p>
                        <select value={dualRoleAgent} onChange={(e) => { setDualRoleAgent(e.target.value) }} style={{ padding: "5px 8px", borderRadius: "10px" }}>
                          <option value={"Agent"}>Agent</option>
                          <option value={"Tenant"}>Tenant</option>
                        </select></> : null : null}

                  </div>
                  <div class="stepper_tab">
                    <div style={{ float: "right", marginRight: "3%", marginBottom: "2%" }}>

                      <button id="saveResidentialBtn" onClick={() => saveProfile()}>Save Profile</button>

                    </div>
                    <div style={{ clear: 'both' }}></div>

                    <div id='newListingLeft'>

                      <p className='modalLabel'>Age</p>
                      <select className='newListingInput' id="residentialAge" value={residentialAge} onChange={handleResidentialAge}>
                        <option value={"< 21"}>{"< 21"}</option>
                        <option value={"21 - 29"}>{"21 - 29"}</option>
                        <option value={"30 - 39"}>{"30 - 39"}</option>
                        <option value={"40 - 49"}>{"40 - 49"}</option>
                        <option value={"50 - 59"}>{"50 - 59"}</option>
                        <option value={">= 60"}>{">= 60"}</option>
                      </select>

                      <p className='modalLabel'>Type of employment</p>
                      <select className='newListingInput' id="residentialEmployment" value={residentialEmployment} onChange={handleResidentialEmployment}>
                        <option value={"NA"}>NA</option>
                        <option value={"Work Permit"}>Work Permit</option>
                        <option value={"S Pass"}>S Pass</option>
                        <option value={"Employment Pass"}>Employment Pass</option>
                        <option value={"Overseas Networks and Expertise Pass"}>Overseas Networks and Expertise Pass</option>
                        <option value={"EntirePass"}>EntirePass</option>
                        <option value={"Training Employment Pass"}>Training Employment Pass</option>
                        <option value={"Personalised Employment Pass"}>Personalised Employment Pass</option>
                        <option value={"Work Holiday Pass"}>Work Holiday Pass</option>
                        <option value={"Dependant's Pass"}>Dependant's Pass</option>
                        <option value={"Long Term Visit Pass"}>Long Term Visit Pass</option>
                      </select>

                      <p className='modalLabel'>How many years have you been renting in Singapore </p>
                      <select className='newListingInput' id="residentialYearsRent" value={residentialYearsRent} onChange={handleResidentialYearsRent}>
                        <option value={"< 1 year"}>{"< 1 year"}</option>
                        <option value={"1 - 3 years"}>{"1 - 3 years"}</option>
                        <option value={"3 - 5 years"}>{"3 - 5 years"}</option>
                        <option value={"> 5 years"}>{"> 5 years"}</option>
                      </select>





                    </div>

                    <div id='newListingRight'>
                      <p className='modalLabel'>Country of Birth</p>
                      <select className='newListingInput' id="residentialNationality" value={residentialNationality} onChange={handleResidentialNationality}>
                        <option value={"AFGHANISTAN"}>AFGHANISTAN</option>
                        <option value={"ALBANIA"}>ALBANIA</option>
                        <option value={"ALGERIA"}>ALGERIA</option>
                        <option value={"AMERICAN SAMOA"}>AMERICAN SAMOA</option>
                        <option value={"ANDORRA"}>ANDORRA</option>
                        <option value={"ANGOLA"}>ANGOLA</option>
                        <option value={"ANGUILLA"}>ANGUILLA</option>
                        <option value={"ANTIGUA AND BARBUDA"}>ANTIGUA AND BARBUDA</option>
                        <option value={"ARGENTINA"}>ARGENTINA</option>
                        <option value={"ARMENIA"}>ARMENIA</option>
                        <option value={"ARUBA"}>ARUBA</option>
                        <option value={"AUSTRALIA"}>AUSTRALIA</option>
                        <option value={"AUSTRIA"}>AUSTRIA</option>
                        <option value={"AZERBAIJAN"}>AZERBAIJAN</option>
                        <option value={"BAHAMAS"}>BAHAMAS</option>
                        <option value={"BAHRAIN"}>BAHRAIN</option>
                        <option value={"BANGLADESH"}>BANGLADESH</option>
                        <option value={"BARBADOS"}>BARBADOS</option>
                        <option value={"BELARUS"}>BELARUS</option>
                        <option value={"BELGIUM"}>BELGIUM</option>
                        <option value={"BELIZE"}>BELIZE</option>
                        <option value={"BENIN"}>BENIN</option>
                        <option value={"BERMUDA"}>BERMUDA</option>
                        <option value={"BHUTAN"}>BHUTAN</option>
                        <option value={"BOLIVIA"}>BOLIVIA</option>
                        <option value={"BOSNIA AND HERZEGOVINA"}>BOSNIA AND HERZEGOVINA</option>
                        <option value={"BOTSWANA"}>BOTSWANA</option>
                        <option value={"BRAZIL"}>BRAZIL</option>
                        <option value={"BRUNEI"}>BRUNEI</option>
                        <option value={"BULGARIA"}>BULGARIA</option>
                        <option value={"BURKINA FASO"}>BURKINA FASO</option>
                        <option value={"BURUNDI"}>BURUNDI</option>
                        <option value={"COTE D'IVOIRE"}>COTE D'IVOIRE</option>
                        <option value={"CABO VERDE"}>CABO VERDE</option>
                        <option value={"CAMBODIA"}>CAMBODIA</option>
                        <option value={"CAMEROON"}>CAMEROON</option>
                        <option value={"CANADA"}>CANADA</option>
                        <option value={"CENTRAL AFRICAN REP"}>CENTRAL AFRICAN REP</option>
                        <option value={"CHAD"}>CHAD</option>
                        <option value={"CHILE"}>CHILE</option>
                        <option value={"COLOMBIA"}>COLOMBIA</option>
                        <option value={"COMOROS"}>COMOROS</option>
                        <option value={"COSTA RICA"}>COSTA RICA</option>
                        <option value={"CROATIA"}>CROATIA</option>
                        <option value={"CUBA"}>CUBA</option>
                        <option value={"CYPRUS"}>CYPRUS</option>
                        <option value={"CZECH REPUBLIC"}>CZECH REPUBLIC</option>
                        <option value={"DEMOCRATIC REPUBLIC OF THE CONGO"}>DEMOCRATIC REPUBLIC OF THE CONGO</option>
                        <option value={"DENMARK"}>DENMARK</option>
                        <option value={"DJIBOUTI"}>DJIBOUTI</option>
                        <option value={"DOMINICA"}>DOMINICA</option>
                        <option value={"DOMINICAN REPUBLIC"}>DOMINICAN REPUBLIC</option>
                        <option value={"ECUADOR"}>ECUADOR</option>
                        <option value={"EGYPT"}>EGYPT</option>
                        <option value={"EL SALVADOR"}>EL SALVADOR</option>
                        <option value={"EQUATORIAL GUINEA"}>EQUATORIAL GUINEA</option>
                        <option value={"ERITREA"}>ERITREA</option>
                        <option value={"ESTONIA"}>ESTONIA</option>
                        <option value={"ESWATINI"}>ESWATINI</option>
                        <option value={"ETHIOPIA"}>ETHIOPIA</option>
                        <option value={"FIJI"}>FIJI</option>
                        <option value={"FINLAND"}>FINLAND</option>
                        <option value={"FRANCE"}>FRANCE</option>
                        <option value={"GABON"}>GABON</option>
                        <option value={"GAMBIA"}>GAMBIA</option>
                        <option value={"GEORGIA"}>GEORGIA</option>
                        <option value={"GERMANY"}>GERMANY</option>
                        <option value={"GHANA"}>GHANA</option>
                        <option value={"GREECE"}>GREECE</option>
                        <option value={"GRENADA"}>GRENADA</option>
                        <option value={"GUATEMALA"}>GUATEMALA</option>
                        <option value={"GUINEA"}>GUINEA</option>
                        <option value={"GUINES BISSAU"}>GUINES BISSAU</option>
                        <option value={"GUYANA"}>GUYANA</option>
                        <option value={"HAITI"}>HAITI</option>
                        <option value={"HONDURAS"}>HONDURAS</option>
                        <option value={"HUNGARY"}>HUNGARY</option>
                        <option value={"ICELAND"}>ICELAND</option>
                        <option value={"INDIA"}>INDIA</option>
                        <option value={"INDONESIA"}>INDONESIA</option>
                        <option value={"IRAN"}>IRAN</option>
                        <option value={"IRAQ"}>IRAQ</option>
                        <option value={"IRELAND"}>IRELAND</option>
                        <option value={"ISRAEL"}>ISRAEL</option>
                        <option value={"ITALY"}>ITALY</option>
                        <option value={"JAMAICA"}>JAMAICA</option>
                        <option value={"JAPAN"}>JAPAN</option>
                        <option value={"JORDAN"}>JORDAN</option>
                        <option value={"KAZAKHSTAN"}>KAZAKHSTAN</option>
                        <option value={"KENYA"}>KENYA</option>
                        <option value={"KIRIBATI"}>KIRIBATI</option>
                        <option value={"KUWAIT"}>KUWAIT</option>
                        <option value={"KYRGYZSTAN"}>KYRGYZSTAN</option>
                        <option value={"LAOS"}>LAOS</option>
                        <option value={"LATVIA"}>LATVIA</option>
                        <option value={"LEBANON"}>LEBANON</option>
                        <option value={"LESOTHO"}>LESOTHO</option>
                        <option value={"LIBERIA"}>LIBERIA</option>
                        <option value={"LIBYA"}>LIBYA</option>
                        <option value={"LIECHSTENSTEIN"}>LIECHSTENSTEIN</option>
                        <option value={"LITHUANIA"}>LITHUANIA</option>
                        <option value={"LUXEMBOURG"}>LUXEMBOURG</option>
                        <option value={"MADAGASCAR"}>MADAGASCAR</option>
                        <option value={"MALAWI"}>MALAWI</option>
                        <option value={"MALAYSIA"}>MALAYSIA</option>
                        <option value={"MALDIVES"}>MALDIVES</option>
                        <option value={"MALI"}>MALI</option>
                        <option value={"MALTA"}>MALTA</option>
                        <option value={"MARSHALL ISLANDS"}>MARSHALL ISLANDS</option>
                        <option value={"MAURITANIA"}>MAURITANIA</option>
                        <option value={"MAURITIUS"}>MAURITIUS</option>
                        <option value={"MEXICO"}>MEXICO</option>
                        <option value={"MICRONESIA"}>MICRONESIA</option>
                        <option value={"MOLDOVA"}>MOLDOVA</option>
                        <option value={"MONACO"}>MONACO</option>
                        <option value={"MONGOLIA"}>MONGOLIA</option>
                        <option value={"MONTENEGRO"}>MONTENEGRO</option>
                        <option value={"MOROCCO"}>MOROCCO</option>
                        <option value={"MOZAMBIQUE"}>MOZAMBIQUE</option>
                        <option value={"MYANMAR"}>MYANMAR</option>
                        <option value={"NAMIBIA"}>NAMIBIA</option>
                        <option value={"NAURU"}>NAURU</option>
                        <option value={"NEPAL"}>NEPAL</option>
                        <option value={"NETHERLANDS"}>NETHERLANDS</option>
                        <option value={"NEW ZEALAND"}>NEW ZEALAND</option>
                        <option value={"NICARAGUA"}>NICARAGUA</option>
                        <option value={"NIGER"}>NIGER</option>
                        <option value={"NIGERIA"}>NIGERIA</option>
                        <option value={"NORTH KOREA"}>NORTH KOREA</option>
                        <option value={"NORTH MACEDONIA"}>NORTH MACEDONIA</option>
                        <option value={"NORWAY"}>NORWAY</option>
                        <option value={"OMAN"}>OMAN</option>
                        <option value={"PAKISTAN"}>PAKISTAN</option>
                        <option value={"PALAU"}>PALAU</option>
                        <option value={"PANAMA"}>PANAMA</option>
                        <option value={"PAPUA NEW GUINEA"}>PAPUA NEW GUINEA</option>
                        <option value={"PARAGUAY"}>PARAGUAY</option>
                        <option value={"PEOPLE'S REPUBLIC OF CHINA"}>PEOPLE'S REPUBLIC OF CHINA</option>
                        <option value={"PERU"}>PERU</option>
                        <option value={"PHILIPPINES"}>PHILIPPINES</option>
                        <option value={"POLAND"}>POLAND</option>
                        <option value={"PORTUGAL"}>PORTUGAL</option>
                        <option value={"QATAR"}>QATAR</option>
                        <option value={"ROMANIA"}>ROMANIA</option>
                        <option value={"RUSSIA"}>RUSSIA</option>
                        <option value={"RWANDA"}>RWANDA</option>
                        <option value={"SAINT KITTS NEVIS"}>SAINT KITTS NEVIS</option>
                        <option value={"SAINT LUCIA"}>SAINT LUCIA</option>
                        <option value={"SAINT VINCENT AND THE GRENADINES"}>SAINT VINCENT AND THE GRENADINES</option>
                        <option value={"SAMOA"}>SAMOA</option>
                        <option value={"SAN MARINO"}>SAN MARINO</option>
                        <option value={"SAO TOME PRINCIPE"}>SAO TOME PRINCIPE</option>
                        <option value={"SAUDI ARABIA"}>SAUDI ARABIA</option>
                        <option value={"SENEGAL"}>SENEGAL</option>
                        <option value={"SERBIA"}>SERBIA</option>
                        <option value={"SEYCHELLES"}>SEYCHELLES</option>
                        <option value={"SIERRA LEONE"}>SIERRA LEONE</option>
                        <option value={"SINGAPORE"}>SINGAPORE</option>
                        <option value={"SLOVAKIA"}>SLOVAKIA</option>
                        <option value={"SLOVENIA"}>SLOVENIA</option>
                        <option value={"SOLOMON ISLANDS"}>SOLOMON ISLANDS</option>
                        <option value={"SOMALIA"}>SOMALIA</option>
                        <option value={"SOUTH AFRICA"}>SOUTH AFRICA</option>
                        <option value={"SOUTH KOREA"}>SOUTH KOREA</option>
                        <option value={"SOUTH SUDAN"}>SOUTH SUDAN</option>
                        <option value={"SPAIN"}>SPAIN</option>
                        <option value={"SRI LANKA"}>SRI LANKA</option>
                        <option value={"SUDAN"}>SUDAN</option>
                        <option value={"SURINAM"}>SURINAM</option>
                        <option value={"SWEDEN"}>SWEDEN</option>
                        <option value={"SWITZERLAND"}>SWITZERLAND</option>
                        <option value={"SYRIA"}>SYRIA</option>
                        <option value={"TAJIKISTAN"}>TAJIKISTAN</option>
                        <option value={"TANZANIA"}>TANZANIA</option>
                        <option value={"THAILAND"}>THAILAND</option>
                        <option value={"TIMOR-LESTE"}>TIMOR-LESTE</option>
                        <option value={"TOGO"}>TOGO</option>
                        <option value={"TONGA"}>TONGA</option>
                        <option value={"TRINIDAD AND TOBAGO"}>TRINIDAD AND TOBAGO</option>
                        <option value={"TUNISIA"}>TUNISIA</option>
                        <option value={"TURKEY"}>TURKEY</option>
                        <option value={"TURKMENISTAN"}>TURKMENISTAN</option>
                        <option value={"TUVALU"}>TUVALU</option>
                        <option value={"UGANDA"}>UGANDA</option>
                        <option value={"UKRAINE"}>UKRAINE</option>
                        <option value={"UNITED ARAB EMIRATES"}>UNITED ARAB EMIRATES</option>
                        <option value={"UNITED KINGDOM"}>UNITED KINGDOM</option>
                        <option value={"UNITED STATES OF AMERICA"}>UNITED STATES OF AMERICA</option>
                        <option value={"URUGUAY"}>URUGUAY</option>
                        <option value={"UZBEKISTAN"}>UZBEKISTAN</option>
                        <option value={"VATICAN CITY"}>VATICAN CITY</option>
                        <option value={"VANUATU"}>VANUATU</option>
                        <option value={"VENEZUELA"}>VENEZUELA</option>
                        <option value={"VIETNAM"}>VIETNAM</option>
                        <option value={"YEMEN"}>YEMEN</option>
                        <option value={"ZAMBIA"}>ZAMBIA</option>
                        <option value={"ZIMBABWE"}>ZIMBABWE</option>
                      </select>
                      {/* <input  id="paidUpCaptial" placeholder='Paid Up Capital'></input> */}

                      <p className='modalLabel'>Occupation</p>
                      <input className='newListingInput' id="residentialOccupation" placeholder='Nurse, Professor, etc' value={residentialOccupation} onChange={handleResidentialOccupation}></input>

                      <p className='modalLabel' >Expected duration of stay in Singapore</p>
                      <select className='newListingInput' id="residentialExpectedStay" value={residentialExpectedStay} onChange={handleResidentialExpectedStay}>
                        <option value={"< 2 years"}>{"< 2 years"}</option>
                        <option value={"2 - 3 years"}>{"2 - 3 years"}</option>
                        <option value={"3 - 5 years"}>{"3 - 5 years"}</option>
                        <option value={"> 5 years"}>{"> 5 years"}</option>
                      </select>

                    </div>
                    <div style={{ clear: 'both' }}></div>

                    <div id='newListingLeft'>
                      <p className='modalLabel'>Type of Lease</p>
                      <input type="radio" id="Individual" name="typeLease" value="Individual" checked={residentialType == "Individual"}
                        onChange={handleTypeLeaseChange} />
                      <label for="Individual">Individual</label><br />
                      <input type="radio" id="Corporate" name="typeLease" value="Corporate" checked={residentialType == "Corporate"}
                        onChange={handleTypeLeaseChange} />
                      <label for="Corporate">Corporate</label>


                      <p className='modalLabel'>Composite of tenants</p>
                      <input type="radio" id="Family" name="compositeTenant" value="Family" checked={compositeTenant == "Family"}
                        onChange={handleCompositeTenant} />
                      <label for="Family">Family</label><br />
                      <input type="radio" id="Friends" name="compositeTenant" value="Friends" checked={compositeTenant == "Friends"}
                        onChange={handleCompositeTenant} />
                      <label for="Friends">Friends</label><br />
                      <input type="radio" id="Partners" name="compositeTenant" value="Partners" checked={compositeTenant == "Partners"}
                        onChange={handleCompositeTenant} />
                      <label for="Partners">Partners</label><br />
                      <input type="radio" id="NA" name="compositeTenant" value="NA" checked={compositeTenant == "NA"}
                        onChange={handleCompositeTenant} />
                      <label for="NA">Not Applicable</label>
                    </div>
                    <div id='newListingRight'>
                      <p className='modalLabel'>Do you smoke</p>
                      <input type="radio" id="smoker-yes" name="smoker" value="Yes" checked={residentialSmoke == "Yes"}
                        onChange={handleSmokeChange} />
                      <label for="smoker-yes">Yes</label><br />
                      <input type="radio" id="smoker-no" name="smoker" value="No" checked={residentialSmoke == "No"}
                        onChange={handleSmokeChange} />
                      <label for="smoker-no">No</label>
                      <p className='modalLabel'>Is this a</p>
                      <input type="radio" id="Overseas Relocation" name="residentialRelocation" value="Overseas Relocation" checked={residentialRelocation == "Overseas Relocation"}
                        onChange={handleResidentialRelocation} />
                      <label for="Overseas Relocation">Overseas Relocation</label><br />
                      <input type="radio" id="Local Relocation" name="residentialRelocation" value="Local Relocation" checked={residentialRelocation == "Local Relocation"}
                        onChange={handleResidentialRelocation} />
                      <label for="Local Relocation">Local Relocation</label>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                  </div>

                </>}

            </div>
            <div className='modal-footerNewProject'>

              {typeOfSpace == "Commercial" ?
                <>
                  {props.modifyListingId == null ? <Link to='/account'><button className='newListingBtn' onClick={() => activateConfirmationPopup("new")} style={{ marginLeft: "2%" }}>Confirm</button></Link> : <Link to='/account'><button className='newListingBtn' style={{ marginLeft: "2%" }} onClick={modifyListing}>Modify</button></Link>}
                  {props.modifyListingId != null ? <button className='newListingBtn' style={{ marginLeft: "2%" }} onClick={() => { setAcknowledgeMessage("Confirm to delete your listing"); setAcknowledgeNormal(true) }}>Delete</button> : null}
                  <button className='newListingBtn' id="prevBtn" onClick={() => nextPrev(-1)}>Previous</button>
                  <button className='newListingBtn' id="nextBtn" onClick={() => nextPrev(1)}>Next</button>
                  <div style={{ clear: 'both' }}></div>
                  <p>* are mandatory</p>

                  <div style={{ textAlign: "center" }}>
                    <span class="step"></span>
                    <span class="step"></span>
                  </div>
                </>
                :
                <>
                  {props.modifyListingId == null ? <Link to='/account'><button className='newListingBtn' onClick={() => activateConfirmationPopup("new")} style={{ marginLeft: "2%" }}>Confirm</button></Link> : <Link to='/account'><button className='newListingBtn' style={{ marginLeft: "2%" }} onClick={() => modifyResidentialListing()}>Modify</button></Link>}
                  {props.modifyListingId != null ? <button className='newListingBtn' style={{ marginLeft: "2%" }} onClick={() => { setAcknowledgeMessage("Confirm to delete your listing"); setAcknowledgeNormal(true) }}>Delete</button> : null}
                  <button className='newListingBtn' id="prevBtn" onClick={() => nextPrev(-1)}>Previous</button>
                  <button className='newListingBtn' id="nextBtn" onClick={() => nextPrev(1)}>Next</button>
                  <div style={{ clear: 'both' }}></div>
                  <p>* are mandatory</p>
                  <div style={{ textAlign: "center" }}>
                    <span class="step"></span>
                    <span class="step"></span>
                  </div>
                </>
              }



            </div>
          </div>
        </div>

      </>
    );
  }

}
