import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import '../css/Product.css';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { collection, getDocs, onSnapshot, doc, query, orderBy, startAfter, limit, getDoc, setDoc, deleteDoc, where, Timestamp, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../../firebase/firebase'
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Footer from '../Footer';
import Notification from './Notification';
import ZoomPicture from './ZoomPictures';



export default function ProductsResidentialPurchase() {
  const searchbarRef = useRef(null);
  const searchbarRefMobile = useRef(null);
  const moreFilterRef = useRef(null);
  const [combineAllData, SetCombineAllData] = useState([]);
  const [currentListing, setCurrentListing] = useState([]);
  const [shortList, setShortList] = useState(null);
  const { user, deleteUserData } = UserAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [eyeBallAdvListingID, setEyeballAdvListingID] = useState([]);
  const [minPriceBud, setMinPriceBud] = useState(0);
  const [locationDetails, setLocationDetails] = useState(null);
  const [minLeaseNum, setMinLeaseNum] = useState("NA");
  const [minLeasePeriod, setMinLeasePeriod] = useState('Days');
  const [maxPriceBud, setMaxPriceBud] = useState("NA");
  const [maxLeasePeriod, setMaxLeasePeriod] = useState('Days');
  const [showFilters, setShowFilters] = useState(false);
  const [maxLeaseNum, setMaxLeaseNum] = useState("NA");
  const [zoomPic, setZoomPic] = useState(false);
  const [zoomPicURL, setZoomPicURL] = useState(null);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [filterProject, setFilterProject] = useState(null);
  const [totalProject, setTotalProject] = useState(0);
  const [filterProjectSort, setFilterProjectSort] = useState(null);
  const [filterName, setFilterName] = useState(null);
  const [minOption, setMinOption] = useState(true);
  const [isHDB, setIsHDB] = useState(false);
  const [isCondo, setIsCondo] = useState(false);
  const [isLanded, setIsLanded] = useState(false);
  const [is103YearResidential, setIs103YearResidential] = useState(false);
  // const [specificPropertyTypeHDB, setSpecificPropertyTypeHDB] = useState("Not Applicable")
  // const [specificPropertyTypeCondo, setSpeicficPropertyTypeCondo] = useState("Not Applicable");
  // const [specificPropertyTypeLanded, setSpecificPropertyLanded] = useState("Not Applicable");
  // const [leaseTermResidential, setLeaseTermResidential] = useState("Not Applicable");
  const [isAnyResidential, setIsAnyResidential] = useState(true);
  const [is60YearResidential, setIs60YearResidential] = useState(false);
  const [is9999YearResidential, setIs9999YearResidential] = useState(false);
  const [is30YearResidential, setIs30YearResidential] = useState(false);
  const [is99YearResidential, setIs99YearResidential] = useState(false);
  const [isFreeholdResidential, setIsFreeholdResidential] = useState(false);
  const [isTenureNAResidential, setIsTenureNAResidential] = useState(false);
  const [is999YearResidential, setIs999YearResidential] = useState(false);
  const [is110YearResidential, setIs110YearResidential] = useState(false);



  useEffect(() => {
    // getFirstPage();
    getAllData().then((res) => {
      SetCombineAllData(res);
      setTotalProject(res.length);
      checkForPreviousPage(res);
    });

  }, []);

  useEffect(() => {
    const dataString = sessionStorage.getItem('filterHome');
    const filterHomeData = JSON.parse(dataString);
    if (filterHomeData != null && combineAllData.length != 0) {
      searchApply();
    }
  }, [combineAllData])

  useEffect(() => {

    if (!user) {
    }
    else {
      if (user.uid != undefined) {
        const subscriber = onSnapshot(collection(db, `userInfo/${user.uid}/shortList`), (doc) => {
          setShortList(doc.docs)
        });
        return (() => { subscriber() });
      }
    }

  }, [user]);

  useEffect(() => {
    function handleClickOutside(event) {
      // if (window.innerWidth <= 540) {
      //   if (searchbarRefMobile.current && !searchbarRefMobile.current.contains(event.target)) {
      //     if (moreFilterRef.current && moreFilterRef.current.contains(event.target)) {
      //       return;
      //     }
      //     setShowFilters(false);
      //   }
      // }
      // else {
      if (searchbarRef.current && !searchbarRef.current.contains(event.target)) {
        if (moreFilterRef.current && moreFilterRef.current.contains(event.target)) {
          return;
        }
        setFilterName(null);
        setShowFilters(false);
      }
      // }
    }



    document.addEventListener('mousedown', handleClickOutside);
    const filterButton = document.querySelector('.btn--filter');
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      filterButton.removeEventListener('click', handleClick);
    };
  }, [showFilters]);


  useEffect(() => {
    const checkingUser = async () => {
      var docsnap = await getDoc(doc(db, 'userInfo', user.uid));
      if (docsnap.exists()) {
      }
      else {
        // alert("hacked your way through ah");
        deleteUserData();

      }
    }
    if (user != null) {
      if (Object.keys(user).length != 0) {
        checkingUser();
      }
    }
  }, [user])

  const handlesMinPriceBud = (event) => {
    if (event.target.value == "") {
      setMinPriceBud(0);
    }
    else {
      setMinPriceBud(event.target.value);
    }

  }

  const handleLocationDetails = (event) => {
    setLocationDetails(event.target.value);
  }

  const handleMinLeaseNum = (event) => {
    setMinLeaseNum(event.target.value);
  }

  const handleMinLeasePeriod = (event) => {
    setMinLeaseNum(1);
    setMinLeasePeriod(event.target.value);
  }

  const handleMaxPriceBud = (event) => {
    if (event.target.value == "") {
      setMaxPriceBud("NA");
    }
    else {
      setMaxPriceBud(event.target.value);
    }

  }

  const handleMaxLeaseNum = (event) => {
    setMaxLeaseNum(event.target.value);
  }

  const handleMaxLeasePeriod = (event) => {
    setMaxLeaseNum(1);
    setMaxLeasePeriod(event.target.value);
  }

  const handleClick = (event) => {
    if (event.target.id == "BudgetFilterResidentialDropdown") {
      event.target.id = "BudgetFilterResidential";
    }
    else if (event.target.id == "PropertiesFilterResidentialSalesDropdown") {
      event.target.id = "PropertiesFilterResidentialSales";
    }
    else if (event.target.id == "TenureFilterResidentialSalesDropdown") {
      event.target.id = "TenureFilterResidentialSales";
    }


    if (event.target.id == "") {
      return;
    }
    else {
      if (event.target.id == filterName) {
        setFilterName(null);
        // setShow(!show);
        setShowFilters(false);
        // setTarget(event.target);
      }
      else if (filterName == null) {
        setFilterName(event.target.id);
        // setShow(!show);
        setShowFilters(true);
        // setTarget(event.target);
      }
      else {
        setFilterName(event.target.id);
      }
    }

  };

  const handleSortingOrder = (event) => {

    var sortingFilteringProject = filterProject;

    if (event.target.value == "Adescending") {
      sortingFilteringProject.sort((a, b) => a.details.budgetOverall - b.details.budgetOverall);
      setFilterProject(sortingFilteringProject);
      setCurrentPage(1);
      setCurrentListing(sortingFilteringProject.slice(0, 40));
    }
    else if (event.target.value == "Descending") {
      sortingFilteringProject.sort((a, b) => b.details.budgetOverall - a.details.budgetOverall);
      setFilterProject(sortingFilteringProject);
      setCurrentPage(1);
      setCurrentListing(sortingFilteringProject.slice(0, 40));
    }
    else {
      setFilterProject(filterProjectSort);
      setCurrentPage(1);
      setCurrentListing(filterProjectSort.slice(0, 40));
    }


  }



  // useEffect(() => {
  //   if (showFilters) {
  //     handlePropertyClick(propertyType);
  //   }
  // }, [showFilters])

  const onPageChanged = (event, value) => {
    var all = [...combineAllData];

    setCurrentPage(value);
    setCurrentListing(all.slice(((value - 1) * 40), (((value - 1) * 40) + 40)));
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth'
    // });
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    var savedValue = sessionStorage.getItem('currentTab');
    if (savedValue != null) {
      sessionStorage.removeItem('currentTab');
    }
  }, [])

  const handleHDBCheckBoxChange = () => {
    setIsHDB(!isHDB);
    // setSpecificPropertyTypeHDB("Not Applicable")
  }

  const handleCondoCheckBoxChange = () => {
    setIsCondo(!isCondo);
    // setSpeicficPropertyTypeCondo("Not Applicable")
  }

  const handleLandedCheckBoxChange = () => {
    setIsLanded(!isLanded);
    // setSpecificPropertyLanded("Not Applicable");
  }

  // const handleLeaseTermResidential = (e) => {
  //   setLeaseTermResidential(e.target.value);
  // }

  // const handleSpecificPropertyTypeHDB = (e) => {
  //   setSpecificPropertyTypeHDB(e.target.value)
  // }

  // const handleSpecificPropertyTypeCondo = (e) => {
  //   setSpeicficPropertyTypeCondo(e.target.value);
  // }

  // const handleSpecificPropertyTypeLanded = (e) => {
  //   setSpecificPropertyLanded(e.target.value);
  // }


  const searchApply = () => {
    setShowFilters(false);
    setFilterName(null);
    var filteringProject = [...combineAllData];
    const dataString = sessionStorage.getItem('filterHome');
    const filterHomeData = JSON.parse(dataString);
    if (filterHomeData != null) {
      sessionStorage.removeItem('filterHome');
    }

    searchBarFilter(filteringProject, filterHomeData).then((res) => searchPropertyType(res, filterHomeData).then((res) => searchPrice(res, filterHomeData)
      .then((res) => searchLease(res, filterHomeData).then((res) => {
        if (filterHomeData != null) {

          setMinPriceBud(filterHomeData.minPriceBud);
          setLocationDetails(filterHomeData.locationDetails);
          setMaxPriceBud(filterHomeData.maxPriceBud);
          filterHomeData.propertyType.some((item) => {
            if (item.includes("HDB")) {
              if (item.includes("|")) {
                var splitItem = item.split("|");
                var newStr = splitItem[1].trimLeft();
                // setSpecificPropertyTypeHDB(newStr)
              }
              setIsHDB(true);
            }
            else if (item.includes("Condominium")) {
              if (item.includes("|")) {
                var splitItem = item.split("|");
                var newStr = splitItem[1].trimLeft();
                // setSpeicficPropertyTypeCondo(newStr)
              }
              setIsCondo(true);
            }
            else if (item.includes("Landed")) {
              if (item.includes("|")) {
                var splitItem = item.split("|");
                var newStr = splitItem[1].trimLeft();
                // setSpecificPropertyLanded(newStr)
              }
              setIsLanded(true);
            }
          })
          // setLeaseTermResidential(filterHomeData.leaseTermResidential);
        }
        setShow(false);
        setFilterProjectSort(res);
        setFilterProject(res);
        setCurrentPage(1);
        setCurrentListing(res.slice(0, 40));
      }))
    ));
  }

  const searchBarFilter = (filteringProject, filterHomeData) => {
    return new Promise((resolve, reject) => {



      if (locationDetails != null || filterHomeData != null) {
        if (filterHomeData == null) {
          var searchBarLocation = locationDetails.toUpperCase();
          if (searchBarLocation == "D1") {
            searchBarLocation = "D01";
            setLocationDetails("D01");
          }
          else if (searchBarLocation == "D2") {
            searchBarLocation = "D02";
            setLocationDetails("D02");
          }
          else if (searchBarLocation == "D3") {
            searchBarLocation = "D03";
            setLocationDetails("D03");
          }
          else if (searchBarLocation == "D4") {
            searchBarLocation = "D04";
            setLocationDetails("D04");
          }
          else if (searchBarLocation == "D5") {
            searchBarLocation = "D05";
            setLocationDetails("D05");
          }
          else if (searchBarLocation == "D6") {
            searchBarLocation = "D06";
            setLocationDetails("D06");
          }
          else if (searchBarLocation == "D7") {
            searchBarLocation = "D07";
            setLocationDetails("D07");
          }
          else if (searchBarLocation == "D8") {
            searchBarLocation = "D08";
            setLocationDetails("D08");
          }
          else if (searchBarLocation == "D9") {
            searchBarLocation = "D09";
            setLocationDetails("D09");
          }

          for (var i = filteringProject.length - 1; i >= 0; i--) {
            if ('advertiseName' in filteringProject[i].details) {
              filteringProject.splice(i, 1);
            }
            else {
              if (((filteringProject[i].details.district[0]).toUpperCase()).search(searchBarLocation) < 0 && ((filteringProject[i].details.location).toUpperCase()).search(searchBarLocation) < 0 && ((filteringProject[i].details.listingName).toUpperCase()).search(searchBarLocation) < 0) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
            }
           
          }
        }
        else {
          if (filterHomeData.locationDetails != null) {
            var searchBarLocation = filterHomeData.locationDetails.toUpperCase();
            if (searchBarLocation == "D1") {
              searchBarLocation = "D01";
              setLocationDetails("D01");
            }
            else if (searchBarLocation == "D2") {
              searchBarLocation = "D02";
              setLocationDetails("D02");
            }
            else if (searchBarLocation == "D3") {
              searchBarLocation = "D03";
              setLocationDetails("D03");
            }
            else if (searchBarLocation == "D4") {
              searchBarLocation = "D04";
              setLocationDetails("D04");
            }
            else if (searchBarLocation == "D5") {
              searchBarLocation = "D05";
              setLocationDetails("D05");
            }
            else if (searchBarLocation == "D6") {
              searchBarLocation = "D06";
              setLocationDetails("D06");
            }
            else if (searchBarLocation == "D7") {
              searchBarLocation = "D07";
              setLocationDetails("D07");
            }
            else if (searchBarLocation == "D8") {
              searchBarLocation = "D08";
              setLocationDetails("D08");
            }
            else if (searchBarLocation == "D9") {
              searchBarLocation = "D09";
              setLocationDetails("D09");
            }
            else {
              setLocationDetails(filterHomeData.locationDetails);
            }
            for (var i = filteringProject.length - 1; i >= 0; i--) {
              if ('advertiseName' in filteringProject[i].details) {
                filteringProject.splice(i, 1);
              }
              else {
                if (((filteringProject[i].details.district[0]).toUpperCase()).search(searchBarLocation) < 0 && ((filteringProject[i].details.location).toUpperCase()).search(searchBarLocation) < 0 && ((filteringProject[i].details.listingName).toUpperCase()).search(searchBarLocation) < 0) {
                  filteringProject.splice(i, 1);
                  // delete filteringProject[i];
                }
              }
              
            }
          }

        }

      }
      resolve(filteringProject);
    })
  }

  const searchPropertyType = (filteringProject, filterHomeData) => {
    return new Promise((resolve, reject) => {

      if (isHDB == true || isCondo == true || isLanded == true || filterHomeData != null) {
        for (var i = filteringProject.length - 1; i >= 0; i--) {
          if (filteringProject[i] != undefined) {
            if ('advertiseName' in filteringProject[i].details) {
              filteringProject.splice(i, 1);
            }
            else {
              if (filterHomeData != null) {
                console.log(filterHomeData);
                if (filterHomeData.propertyType.length != 0) {
                  if(filterHomeData.propertyType.includes("HDB")){
                    setIsHDB(true);
                  }
                  if(filterHomeData.propertyType.includes('Condominium')){
                    setIsCondo(true);
                  }
                  if(filterHomeData.propertyType.includes('Landed')){
                    setIsLanded(true);
                  }
                  if (filterHomeData.propertyType.includes(filteringProject[i].details.propertyType) == false) {
                    filteringProject.splice(i, 1);
                  }
  
                }
              }
              else {
                var searchPropertyArr = [];
                if (isHDB) {
                  searchPropertyArr.push('HDB');
                }
                if (isCondo) {
                  searchPropertyArr.push('Condominium');
                }
                if (isLanded) {
                  searchPropertyArr.push('Landed');
                }
  
                if (searchPropertyArr.includes(filteringProject[i].details.propertyType) == false) {
                  filteringProject.splice(i, 1);
                }
              }
            }

          }
        }
      }
      resolve(filteringProject);
    })
  }

  const searchPrice = (filteringProject, filterHomeData) => {
    return new Promise((resolve, reject) => {
      if (minPriceBud != "NA" || maxPriceBud != "NA" || filterHomeData != null) {
        for (var i = filteringProject.length - 1; i >= 0; i--) {
          if ('advertiseName' in filteringProject[i].details) {
            filteringProject.splice(i, 1);
          }
          else {
            if (filterHomeData == null) {
              if (minPriceBud != "NA" && maxPriceBud != "NA" && (parseInt(filteringProject[i].details.budgetOverall) < parseInt(minPriceBud) || parseInt(filteringProject[i].details.budgetOverall) > parseInt(maxPriceBud))) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
              else if (minPriceBud != "NA" && parseInt(filteringProject[i].details.budgetOverall) < parseInt(minPriceBud) && maxPriceBud == "NA") {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
              else if (maxPriceBud != "NA" && parseInt(filteringProject[i].details.budgetOverall) > parseInt(maxPriceBud) && minPriceBud == "NA") {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
            }
            else {
              if (parseInt(filterHomeData.minPriceBud) != "NA" && parseInt(filterHomeData.maxPriceBud) != "NA" && parseInt(filteringProject[i].details.budgetOverall) < parseInt(filterHomeData.minPriceBud) && parseInt(filteringProject[i].details.budgetOverall) > parseInt(filterHomeData.maxPriceBud)) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
              else if (parseInt(filterHomeData.minPriceBud) != "NA" && parseInt(filteringProject[i].details.budgetOverall) < parseInt(filterHomeData.minPriceBud) && parseInt(filterHomeData.maxPriceBud) != "NA") {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
              else if (parseInt(filterHomeData.minPriceBud) != "NA" && parseInt(filteringProject[i].details.budgetOverall) > parseInt(filterHomeData.maxPriceBud) && parseInt(filterHomeData.minPriceBud) != "NA") {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
            }
          }


        }
      }
      resolve(filteringProject);
    })
  }

  const searchLease = (filteringProject, filterHomeData) => {
    return new Promise((resolve, reject) => {
      //leaseTermResidential

      if (isAnyResidential == false || filterHomeData != null) {
        for (var i = filteringProject.length - 1; i >= 0; i--) {
          if ('advertiseName' in filteringProject[i].details) {
            filteringProject.splice(i, 1);
          }
          else {
            if (filterHomeData != null) {
              console.log(filterHomeData)
              if (filterHomeData.tenure.includes("Any")) {
                setIsAnyResidential(true)
              }
              else {
                setIsAnyResidential(false);
                if (filterHomeData.tenure.includes("30-year leasehold")) {
                  setIs30YearResidential(true);
                }
                if (filterHomeData.tenure.includes("60-year leasehold")) {
                  setIs60YearResidential(true);
                }
                if (filterHomeData.tenure.includes("99-year leasehold")) {
                  setIs99YearResidential(true);
                }
                if (filterHomeData.tenure.includes("103-year leasehold")) {
                  setIs103YearResidential(true);
                }
                if (filterHomeData.tenure.includes("110-year leasehold")) {
                  setIs110YearResidential(true);
                }
                if (filterHomeData.tenure.includes("999-year leasehold")) {
                  setIs999YearResidential(true);
                }
                if (filterHomeData.tenure.includes("Freehold")) {
                  setIsFreeholdResidential(true);
                }
                if (filterHomeData.tenure.includes("Not Applicable")) {
                  setIsTenureNAResidential(true);
                }
                for (a = 0; a < filterHomeData.tenure.length; a++) {
                  if(filteringProject[i].details.tenure.includes('Any')){
                    break;
                  }
                  else if (filteringProject[i].details.tenure.includes(filterHomeData.tenure[a]) == false && a == filterHomeData.tenure.length-1) {
                    filteringProject.splice(i, 1);
                    break;
                  }
                  else if(filteringProject[i].details.tenure.includes(filterHomeData.tenureArr[a]) == true){
                    break;
                  }
                 
                }
              }
  
            }
            else {
              if (isAnyResidential == false) {
                var tenureArr = [];
                if (is30YearResidential) {
                  tenureArr.push("30-year leasehold");
                }
                if (is60YearResidential) {
                  tenureArr.push("60-year leasehold");
                }
                if (is99YearResidential) {
                  tenureArr.push("99-year leasehold");
                }
                if (is103YearResidential) {
                  tenureArr.push("103-year leasehold");
                }
                if (is110YearResidential) {
                  tenureArr.push("110-year leasehold");
                }
                if (is999YearResidential) {
                  tenureArr.push("999-year leasehold");
                }
                if (is9999YearResidential) {
                  tenureArr.push("9999-year leasehold");
                }
                if (isFreeholdResidential) {
                  tenureArr.push("Freehold");
                }
  
  
                for (var a = 0; a < tenureArr.length; a++) {
                  // console.log(tenureArr.length,a);
                  // console.log(filteringProject[i].details.tenure, tenureArr[a])
                  if(filteringProject[i].details.tenure.includes('Any')){
                    break;
                  }
                  else if (filteringProject[i].details.tenure.includes(tenureArr[a]) == false && a == tenureArr.length-1) {
                    filteringProject.splice(i, 1);
                    break;
                  }
                  else if(filteringProject[i].details.tenure.includes(tenureArr[a]) == true){
                    break;
                  }
                }
              }
            }
          }


        }
      }
      resolve(filteringProject);

    })
  }

  useEffect(() => {
    if (eyeBallAdvListingID.length != 0) {
      activateAdvEyeball();
    }
  }, [eyeBallAdvListingID])

  const activateAdvEyeball = async () => {
    for (var i = 0; i < eyeBallAdvListingID.length; i++) {
      var newEyeballStat = [...eyeBallAdvListingID[i].details.eyeballStat];
      newEyeballStat[0] = newEyeballStat[0] + 1;
      await updateDoc(doc(db, 'advertiseListing', eyeBallAdvListingID[i].id), {
        eyeball: eyeBallAdvListingID[i].details.eyeball + 1,
        eyeballStat: newEyeballStat
      })
    }
  }


  const getAllData = () => {
    return new Promise(async function (resolve, reject) {
      var allData = []
      var firstData = [];
      var boostList = [];
      var needUpEyeballAdv = [];
      var conversationRateListing = [];
      var preferredListing = [];
      var normalListing = [];


      const allListing = await getDocs(collection(db, 'residentialListingPurchase'));
      const activatedAdvertise = query(collection(db, "advertiseListing"), where("status", "==", "leasing"));
      // Get all the documents that match the query criteria
      const activatedAdvertiseSnapshot = await getDocs(activatedAdvertise);
      var activatedAdvertiseListing = activatedAdvertiseSnapshot.docs
      for (const indiv of allListing.docs) {
        if (indiv.data().status === 'leasing') {
          var residentialDetails = indiv.data();
          var periodMoveInDate = new Date(residentialDetails.moveInPeriod.seconds * 1000);
          var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
          residentialDetails.periodMoveInDateFormatted = formattedDate;
          const listing = { details: residentialDetails, id: indiv.id };


          if (listing.details.boost === true) {
            boostList.push(listing);
          } else if (listing.details.preferredListing === true) {
            preferredListing.push(listing);
          } else {
            conversationRateListing.push(listing);
            normalListing.push(listing);
          }
        }
      }

      conversationRateListing.sort((a, b) => {
        return b.details.conversationRate - a.details.conversationRate;
      });
      var boxNumber = 0;
      while (boostList.length != 0 || preferredListing.length != 0 || normalListing.length != 0 || conversationRateListing.length != 0) {
        var randomIndex = Math.floor(Math.random() * activatedAdvertiseListing.length);
        if (firstData.length % 5 == 0 && activatedAdvertiseListing.length != 0 && firstData.length != 0) {
          firstData.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() });
          needUpEyeballAdv.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() })
        }
        if (allData.length % 5 == 0 && activatedAdvertiseListing.length != 0 && allData.length != 0) {
          allData.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() });
          activatedAdvertiseListing.splice(randomIndex, 1);
        }
        if (boxNumber == 0) {
          if (boostList.length != 0) {
            allData.push(boostList[0]);

            if (firstData.length < 40) {
              firstData.push(boostList[0]);
              if (firstData.length == 40) {
                setCurrentListing(firstData);
              }
            }
            boostList.splice(0, 1);

          }

          if (preferredListing.length != 0) {
            boxNumber = 1;
          }
          else if (conversationRateListing.length != 0) {
            boxNumber = 2;
          }
          else if (normalListing.length != 0) {
            boxNumber = 3;
          }
          else {
            boxNumber = 0;
          }

        }
        else if (boxNumber == 1) {
          if (preferredListing.length != 0) {
            allData.push(preferredListing[0]);

            if (firstData.length < 40) {
              firstData.push(preferredListing[0]);
              if (firstData.length == 40) {
                setCurrentListing(firstData);
              }
            }
            preferredListing.splice(0, 1);

          }

          if (conversationRateListing.length != 0) {
            boxNumber = 2;
          }
          else if (normalListing.length != 0) {
            boxNumber = 3;
          }
          else if (boostList.length != 0) {
            boxNumber = 0;
          }
          else {
            boxNumber = 1;
          }

        }
        else if (boxNumber == 2) {
          if (conversationRateListing.length != 0) {
            var keepGoing = true;
            while (conversationRateListing.length != 0 && keepGoing == true) {
              const foundListing = allData.find(item => item.id == conversationRateListing[0].id);
              if (foundListing) {
                conversationRateListing.splice(0, 1);
              }
              else {
                allData.push(conversationRateListing[0]);
                if (firstData.length < 40) {
                  firstData.push(conversationRateListing[0]);
                  if (firstData.length == 40) {
                    setCurrentListing(firstData);
                  }
                }
                conversationRateListing.splice(0, 1);
                keepGoing = false;

              }
            }
          }

          if (normalListing.length != 0) {
            boxNumber = 3;
          }
          else if (boostList.length != 0) {
            boxNumber = 0;
          }
          else if (preferredListing.length != 0) {
            boxNumber = 1;
          }
          else {
            boxNumber = 2;
          }

        }
        else if (boxNumber == 3) {
          if (normalListing.length != 0) {
            var keepGoing = true;
            while (normalListing.length != 0 && keepGoing == true) {
              const foundListing = allData.find(item => item.id == normalListing[0].id);
              if (foundListing) {
                normalListing.splice(0, 1);
              }
              else {
                allData.push(normalListing[0]);

                if (firstData.length < 40) {
                  firstData.push(normalListing[0]);
                  if (firstData.length == 40) {
                    setCurrentListing(firstData);
                  }
                }
                normalListing.splice(0, 1);
                keepGoing = false;

              }
            }
          }

          if (boostList.length != 0) {
            boxNumber = 0;
          }
          else if (preferredListing.length != 0) {
            boxNumber = 1;
          }
          else if (conversationRateListing.length != 0) {
            boxNumber = 2;
          }
          else {
            boxNumber = 3;
          }

        }
      }
      if (firstData.length < 40) {
        var randomIndex = Math.floor(Math.random() * activatedAdvertiseListing.length);
        if (activatedAdvertiseListing.length != 0 && firstData.length != 0 && firstData.length <= 4) {
          firstData.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() });
          needUpEyeballAdv.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() })
        }
        if (activatedAdvertiseListing.length != 0 && allData.length != 0 && firstData.length <= 4) {
          allData.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() });
          activatedAdvertiseListing.splice(randomIndex, 1);
        }
        setCurrentListing(firstData);
      }

      // SetCombineAllData(allData);
      // setTotalProject(allData.length);
      resolve(allData);
    });

  }

  const clearAllFilter = () => {
    setFilterName(null);
    setFilterProject(null);
    setMinPriceBud(0);
    setLocationDetails("");
    // handlePropertyClick('All');
    setMaxPriceBud("NA");
    setIsCondo(false);
    setIsHDB(false);
    setIsLanded(false);
    setIsAnyResidential(true);
    setIs30YearResidential(false);
    setIs60YearResidential(false);
    setIs99YearResidential(false);
    setIs103YearResidential(false);
    setIs110YearResidential(false);
    setIs999YearResidential(false);
    setIs9999YearResidential(false);
    setIsFreeholdResidential(false);
    // setSpecificPropertyTypeHDB("1 Room");
    // setSpeicficPropertyTypeCondo("Condominium");
    // setSpecificPropertyLanded("Detached House");
    // setLeaseTermResidential("1 Year")

    setFilterProject(null)
    var allData = combineAllData;
    var resetListingCurrent = [];
    resetListingCurrent.push(...allData.slice(0, 40));
    setCurrentListing(resetListingCurrent);
    setCurrentPage(1);
  }




  const checkForPreviousPage = (allData) => {
    return new Promise(function (resolve, reject) {
      const persistedId = sessionStorage.getItem(
        "scroll-position-product-id-marker"
      );
      if (persistedId != null) {
        sessionStorage.removeItem("scroll-position-product-id-marker");
        setCurrentPage(Number(persistedId));

        setCurrentListing(allData.slice(((Number(persistedId) - 1) * 40), (((Number(persistedId) - 1) * 40) + 40)));
        // setCurrentListing(allData.current[Number(persistedId) - 1]);
      }
      resolve(true);
    })
  }

  const recordID = (pageNumber) => {
    sessionStorage.setItem("scroll-position-product-id-marker", pageNumber);
  }

  const addShortList = async (userUID, id) => {
    if (user != null && userUID != user.uid) {
      await setDoc(doc(db, `userInfo/${user.uid}/shortList`, id), {});
    }
    else {
      alert("Please Sign Up First or This is your post");
    }
  }

  const deteleShortList = async (id) => {
    if (user != null && id != user.uid) {
      await deleteDoc(doc(db, `userInfo/${user.uid}/shortList`, id));
    }
    else {
      alert("Please Sign Up First or This is your post");
    }
  }


  return (
    <>
      {/* <Notification></Notification> */}
      <ZoomPicture trigger={zoomPic} setTrigger={setZoomPic} zoomPicURL={zoomPicURL}></ZoomPicture>
      <div>
      <center><h2 style={{paddingTop:"2%"}}>RESIDENTIAL PURCHASE</h2></center>
        <div className='searchBar' >
          <div id="searchBar" ref={searchbarRef}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px", backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
              <i class="fa-solid fa-magnifying-glass" id="magnifyingIcon"></i>
              <div className='searchbarIndivCol'>
                <input type="text" placeholder='Search district, listing name, etc' value={locationDetails} onChange={handleLocationDetails}></input>
              </div>
              <div className='searchbarIndivColBtn'>
                <button className="btn--search" style={{ marginLeft: "3%" }} onClick={() => searchApply()}>Search</button>
              </div>
            </div>
            <div id="filterBar">
              <div style={{ width: "30%", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p id="BudgetFilterResidential" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Budget {filterName == "BudgetFilter" ? <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                {minPriceBud == 0 && maxPriceBud == "NA" ? null : <p id="BudgetFilter" className='btn--filter' style={{ cursor: "pointer", marginTop: "2%" }} onClick={(handleClick)}>{minPriceBud == 0 ? "Any" : `$${minPriceBud.toLocaleString()}`} - {maxPriceBud == "NA" ? "Any" : `$${maxPriceBud.toLocaleString()}`}</p>}
              </div>
              {/* <div style={{ width: "30%", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p id="BudgetFilterResidential" className='btn--filter' style={{ cursor: "pointer" }} onClick={(handleClick)}>Budget {filterName == "BudgetFilterResidential" ? <i id="BudgetFilterResidentialDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="BudgetFilterResidentialDropdown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                {filterProject != null ? <p id="filterText" style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '5px', padding: '2px 10px', color: 'black', margin: '5px' }}>{minPriceBud == "NA" ? "Any" : minPriceBud} - {maxPriceBud == "NA" ? "Any" : maxPriceBud}</p> : null}
              </div> */}

              <div style={{ width: '30%', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                <p id="PropertiesFilterResidentialSales" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Properties {filterName == "PropertiesFilterResidentialSales" ? <i id="PropertiesFilterResidentialSalesDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="PropertiesFilterResidentialSalesDropdown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                  {isHDB == false && isCondo == false && isLanded == false ? null :
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', marginTop: "2%" }}>
                      {isHDB ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>HDB <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsHDB(false) }}></i></p> : null}
                      {isCondo ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Condo <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsCondo(false) }}></i></p> : null}
                      {isLanded ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Landed <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsLanded(false) }}></i></p> : null}
                    </div>}
                </div>
              </div>

              <div style={{ width: '30%', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                <p id="TenureFilterResidentialSales" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Lease Term {filterName == "TenureFilterResidentialSales" ? <i id="TenureFilterResidentialSalesDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="TenureFilterResidentialSalesDropdown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                  {isAnyResidential ? null :
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', marginTop: "2%" }}>
                      {is99YearResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>99-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs99YearResidential(false) }}></i></p> : null}
                      {is103YearResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>103-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs103YearResidential(false) }}></i></p> : null}
                      {is110YearResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>110-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs110YearResidential(false) }}></i></p> : null}
                      {is999YearResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>999-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs999YearResidential(false) }}></i></p> : null}
                      {is9999YearResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>9999-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs9999YearResidential(false) }}></i></p> : null}
                      {isFreeholdResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Freehold <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsFreeholdResidential(false) }}></i></p> : null}
                      {isTenureNAResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Any<i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsTenureNAResidential(false) }}></i></p> : null}
                    </div>}
                </div>
              </div>

              {/* <p id="SpecificFilterResidential" className='btn--filter' style={{ cursor: "pointer" }} onClick={(handleClick)}>Specific Type {filterName == "SpecificFilterResidential" ? <i id="SpecificFilterResidentialDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="SpecificFilterResidentialDropdown" onClick={(handleClick)}  class="fa-solid fa-angle-down"></i>}</p> */}

              {/* <button id="filterClear" onClick={() => { clearAllFilter() }}>Clear</button> */}
            </div>
            <div id="clearBtnDiv">
            <button id="filterClear" onClick={() => { clearAllFilter() }}>Clear</button>
            </div>


            {showFilters && (
              <div id="moreFilter" ref={moreFilterRef} style={(filterName == "BudgetFilterResidential") ? { left: "0", maxHeight: "300px" } : filterName == "TenureFilterResidentialSales" ? { right: "0", maxHeight: "200px" } : window.innerWidth > 768 ? { maxHeight: "220px", marginLeft:"33.3%" } : {maxHeight:"220px", left:"0"}}>

                {(filterName == "BudgetFilterResidential") ?
                  <>
                    <div className='searchbarIndivCol'>
                      {/* <p>Maximum Budget</p>
                      <p>Minimum Budget</p> */}
                      <input placeholder='Min' type="Number" id="minPriceBuy" value={minPriceBud} onClick={() => { setMinOption(true) }} onChange={handlesMinPriceBud} style={{ width: "45%", float: "left" }}></input>
                      <p style={{ float: "left", width: "7%", padding: "8px 10px", cursor: "default", border: "none" }}>-</p>
                      <input placeholder='Max' type="Number" id="maxPriceBuy" value={maxPriceBud} onClick={() => { setMinOption(false) }} onChange={handleMaxPriceBud} style={{ width: "45%", float: "left" }}></input>
                      <div style={{ clear: 'both' }}></div>
                      {minOption == true ?
                        <>
                          <p id="optionBudget" onClick={() => setMinPriceBud(500)}>$500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(1000)}>$1,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(1500)}>$1,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(2000)}>$2,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(2500)}>$2,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(3000)}>$3,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(3500)}>$3,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(4000)}>$4,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(4500)}>$4,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(5000)}>$5,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(5500)}>$5,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(6000)}>$6,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(6500)}>$6,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(7000)}>$7,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(7500)}>$7,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(8000)}>$8,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(8500)}>$8,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(9000)}>$9,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(9500)}>$9,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(10000)}>$10,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(10500)}>$10,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(11000)}>$11,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(11500)}>$11,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(12000)}>$12,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(12500)}>$12,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(13000)}>$13,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(13500)}>$13,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(14000)}>$14,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(14500)}>$14,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(15000)}>$15,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(15500)}>$15,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(16000)}>$16,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(16500)}>$16,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(17000)}>$17,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(17500)}>$17,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(18000)}>$18,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(18500)}>$18,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(19000)}>$19,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(19500)}>$19,500</p>
                        </> :
                        <>
                          <p id="optionBudget" onClick={() => setMaxPriceBud("NA")}>Any Price</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(500)}>$500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(1000)}>$1,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(1500)}>$1,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(2000)}>$2,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(2500)}>$2,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(3000)}>$3,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(3500)}>$3,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(4000)}>$4,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(4500)}>$4,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(5000)}>$5,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(5500)}>$5,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(6000)}>$6,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(6500)}>$6,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(7000)}>$7,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(7500)}>$7,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(8000)}>$8,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(8500)}>$8,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(9000)}>$9,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(9500)}>$9,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(10000)}>$10,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(10500)}>$10,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(11000)}>$11,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(11500)}>$11,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(12000)}>$12,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(12500)}>$12,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(13000)}>$13,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(13500)}>$13,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(14000)}>$14,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(14500)}>$14,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(15000)}>$15,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(15500)}>$15,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(16000)}>$16,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(16500)}>$16,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(17000)}>$17,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(17500)}>$17,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(18000)}>$18,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(18500)}>$18,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(19000)}>$19,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(19500)}>$19,500</p>
                        </>
                      }
                    </div>
                  </> :
                  filterName == "PropertiesFilterResidentialSales" ?
                    <>
                      <div className='searchbarIndivCol'>
                        <div className='propertyType' id="buyPropertyType">
                          <label style={{ display: "block", width: "100%" }}>
                            <input
                              type="checkbox"
                              checked={isHDB}
                              onChange={handleHDBCheckBoxChange}
                              style={{ width: "auto" }}
                            />
                            <span class="label-text">HDB</span>
                          </label>
                          <br></br>
                          <label style={{ display: "block", width: "100%" }}>
                            <input
                              type="checkbox"
                              checked={isCondo}
                              onChange={handleCondoCheckBoxChange}
                              style={{ width: "auto" }}
                            />
                            <span class="label-text">Condo</span>
                          </label>
                          <br></br>
                          <label style={{ display: "block", width: "100%" }}>
                            <input
                              type="checkbox"
                              checked={isLanded}
                              onChange={handleLandedCheckBoxChange}
                              style={{ width: "auto" }}
                            />
                            <span class="label-text">Landed</span>
                          </label>

                        </div>
                      </div>
                    </>
                    :
                    filterName == "TenureFilterResidentialSales" ?
                      <>
                        <div className='searchbarIndivCol'>
                          <div className='propertyType' id="buyPropertyType">
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isAnyResidential}
                                onChange={() => {
                                  setIsAnyResidential(true);
                                  setIs30YearResidential(false);
                                  setIs60YearResidential(false);
                                  setIs99YearResidential(false);
                                  setIs103YearResidential(false);
                                  setIs110YearResidential(false);
                                  setIs999YearResidential(false);
                                  setIs9999YearResidential(false);
                                  setIsFreeholdResidential(false);
                                  setIsTenureNAResidential(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text"> Any</span>
                            </label>
                            {/* <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={is30YearResidential}
                                onChange={() => {
                                  setIsAnyResidential(false);
                                  setIs30YearResidential(!is30YearResidential);
                                  // setIs60Year(false);
                                  // setIs99Year(false);
                                  // setIs110Year(false);
                                  // setIs999Year(false);
                                  // setIs9999Year(false);
                                  // setIsFreehold(false);
                                  setIsTenureNAResidential(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text">30-year leasehold</span>
                            </label> */}
                            {/* <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={is60YearResidential}
                                onChange={() => {
                                  setIsAnyResidential(false);
                                  // setIs30Year(false);
                                  setIs60YearResidential(!is60YearResidential);
                                  // setIs99Year(false);
                                  // setIs110Year(false);
                                  // setIs999Year(false);
                                  // setIs9999Year(false);
                                  // setIsFreehold(false);
                                  setIsTenureNAResidential(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text"> 60-year leasehold</span>
                            </label> */}
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={is99YearResidential}
                                onChange={() => {
                                  setIsAnyResidential(false);
                                  // setIs30Year(false);
                                  // setIs60Year(false);
                                  setIs99YearResidential(!is99YearResidential);
                                  // setIs110Year(false);
                                  // setIs999Year(false);
                                  // setIs9999Year(false);
                                  // setIsFreehold(false);
                                  setIsTenureNAResidential(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text"> 99-year leasehold</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={is103YearResidential}
                                onChange={() => {
                                  setIsAnyResidential(false);
                                  // setIs30Year(false);
                                  // setIs60Year(false);
                                  setIs103YearResidential(!is103YearResidential);
                                  // setIs110Year(false);
                                  // setIs999Year(false);
                                  // setIs9999Year(false);
                                  // setIsFreehold(false);
                                  setIsTenureNAResidential(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text"> 103-year leasehold</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={is110YearResidential}
                                onChange={() => {
                                  setIsAnyResidential(false);
                                  // setIs30Year(false);
                                  // setIs60Year(false);
                                  // setIs99Year(false);
                                  setIs110YearResidential(!is110YearResidential);
                                  // setIs999Year(false);
                                  // setIs9999Year(false);
                                  // setIsFreehold(false);
                                  setIsTenureNAResidential(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text"> 110-year leasehold</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={is999YearResidential}
                                onChange={() => {
                                  setIsAnyResidential(false);
                                  // setIs30Year(false);
                                  // setIs60Year(false);
                                  // setIs99Year(false);
                                  // setIs110Year(false);
                                  setIs999YearResidential(!is999YearResidential);
                                  // setIs9999Year(false);
                                  // setIsFreehold(false);
                                  setIsTenureNAResidential(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text"> 999-year leasehold</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={is9999YearResidential}
                                onChange={() => {
                                  setIsAnyResidential(false);
                                  // setIs30Year(false);
                                  // setIs60Year(false);
                                  // setIs99Year(false);
                                  // setIs110Year(false);
                                  // setIs999Year(false);
                                  setIs9999YearResidential(!is9999YearResidential);
                                  // setIsFreehold(false);
                                  setIsTenureNAResidential(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text"> 9999-year leasehold</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isFreeholdResidential}
                                onChange={() => {
                                  setIsAnyResidential(false);
                                  // setIs30Year(false);
                                  // setIs60Year(false);
                                  // setIs99Year(false);
                                  // setIs110Year(false);
                                  // setIs999Year(false);
                                  // setIs9999Year(false);
                                  setIsFreeholdResidential(!isFreeholdResidential);
                                  setIsTenureNAResidential(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text"> Freehold</span>
                            </label>
                            {/* <br></br>
                              <label style={{ display: "block", width: "100%" }}>
                                <input
                                  type="checkbox"
                                  checked={isTenureNAResidential}
                                  onChange={() => {
                                    setIsAnyResidential(false);
                                    setIs30YearResidential(false);
                                    setIs60YearResidential(false);
                                    setIs99YearResidential(false);
                                    setIs110YearResidential(false);
                                    setIs999YearResidential(false);
                                    setIs9999YearResidential(false);
                                    setIsFreeholdResidential(false);
                                    setIsTenureNAResidential(true);
                                  }}
                                  style={{ width: "auto" }}
                                />
                                <span class="label-text"> Not Applicable</span>
                              </label> */}

                          </div>
                        </div>
                      </> :
                      null
                }
              </div>
            )}
          </div>
        </div>



        {filterProject != null && (minPriceBud != "NA" || maxPriceBud != "NA") ? <div>
          <select id="dropdownFilterMin" name="dropdown" onChange={handleSortingOrder}>
            <option value="NA">Not Applicable</option>
            <option value="Adescending">Adescending </option>
            <option value="Descending">Descending</option>
          </select>
          <div style={{ clear: 'both' }}></div>
        </div> : null}

        <div className='rowCard'>
          {currentListing != null ? currentListing[0] != null ? currentListing.map((project, index) => {
            if (project.details.hasOwnProperty('activatedDate')) {
              return (
                <div className="CardAdvertiseDivResidentialSale" id={project.id} onClick={() => { setZoomPicURL(project.details.imageURL); setZoomPic(true) }}>
                  <div style={{ position: 'relative' }}>
                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={project.details.imageURL} alt="Uploaded" />
                    {/* <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#33434dbf', color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                      Adv
                    </div> */}
                  </div>
                  <div className="CardContainer">
                    <div style={{ clear: 'both' }}></div>
                  </div>
                  <div style={{ clear: 'both' }}></div>
                </div>
              )
            }
            else {
              var ID = project.id;
              var heartIconStatus = false;
              if (shortList != null) {
                heartIconStatus = shortList.find(doc => { return doc.id === ID })
              }
              return (
                <div class="CardResidentialSales">
                  <Link to={project.details.status == "leasing" ? "/residentialPurchaseDetails/" + project.id : null}>
                    <div id="residentialPurchaseInfo" style={{ borderBottom: "1px solid #c1c1c1" }}>
                      <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                      <div id="cardResidentialUserInfo" >
                        {project.details.propertyAgent == true ?<p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>AGENT</p> : <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>DIRECT BUYER</p>}
                        {/* <p style={project.details.propertyAgent == true ? {fontWeight:"bold", padding:"4px 0px"}:{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p> */}
                        {/* <div style={{ clear: 'both' }}></div> */}
                        {/* <p style={{ fontWeight: "bold" }}>{project.details.district[0]}</p> */}
                        {/* <p>{project.details.propertyType}</p> */}
                        {project.details.preferredStatus == true ?
                          <div id="residentialPreferred" style={{ margin: "0", height: "auto", padding: "5px" }}>
                            <p>Preferred Listing</p>
                          </div>
                          :
                          null
                        }
                      </div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    {/* {project.details.preferredStatus == true ?
                      <div id="residentialPreferred" style={{height:"10%"}}>
                        <p>Preferred Listing</p>
                      </div>
                      :
                      <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white", height:"10%" }}>

                      </div>
                    } */}

                    <div class="CardContainer" style={{height:"75%"}}>

                      <div id="Card_overall" style={{height:"55%", justifyContent:"center", display:"flex", flexDirection:"column"}}>
                      <p id="Card_district" style={{paddingTop:"0"}}>{project.details.district[0].toUpperCase()}</p>
                      <p id="Card_price">${project.details.budgetOverall.toLocaleString()}</p>
                      <p id="monthlyRentalBudget">Budget Overall</p>
                        {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Budget Overall:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>${project.details.budgetOverall.toLocaleString()}</p> */}
                        {/* <div style={{ clear: 'both' }}></div> */}
                        {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.minFloorSize}</p> */}
                        {/* <div style={{ clear: 'both' }}></div> */}
                        {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Max Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.maxFloorSize}</p> */}
                        {/* <div style={{ clear: 'both' }}></div> */}
                        {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.periodMoveInDateFormatted}</p> */}
                        {/* <div style={{ clear: 'both' }}></div> */}
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", paddingBottom: "5px", paddingTop: "5px", height: "20%" }}>
                        <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                          <i style={{ color: "#717171" }} class="fa-solid fa-house"></i>
                          <p id="Card_type" style={{ fontWeight: "bold", paddingLeft: "5px" }}>{project.details.propertyType}</p>
                        </div>
                        <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                          <i style={{ color: "#717171" }} class="fa-solid fa-bed"></i>
                          <p id="Card_type" style={{ fontWeight: "bold", paddingLeft: "5px" }}>{project.details.bedrooms[0]}</p>

                        </div>
                      </div>
                      <div id='residentialEyeball'>
                        <i class="fa fa-eye" style={{ margin: "0" }}></i> {project.details.eyeball}
                        <i class="far fa-comment-dots" style={{ marginLeft: "5px" }}></i> {project.details.connections}
                      </div>
                      <div id='residentialHeart'>
                        {heartIconStatus == undefined | heartIconStatus == false ? <button id="heartButton" onClick={(e) => { e.preventDefault(); addShortList(project.details.UID, ID) }}><i class="fa-regular fa-heart fa-2x" ></i></button> : <button id="heartButton" onClick={(e) => { e.preventDefault(); deteleShortList(ID) }}><i class="fa-solid fa-heart fa-2x" id="favouriteHeart"></i></button>}
                      </div>


                    </div>
                    <div style={{ clear: 'both' }}></div>
                  </Link>
                  <div style={{ clear: 'both' }}></div>

                  <div style={{ clear: 'both' }}></div>
                </div>

              )
            }
          }) : <center><h3 style={{ marginTop: "15%" }}>No Result</h3></center> : null}
          <div style={{ clear: 'both' }}></div>
        </div>
        <Stack alignItems="center">
          {filterProject != null ?
            <Pagination count={Math.ceil(filterProject.length / 40)} variant="outlined" shape="rounded" style={{ bottom: "0", marginBottom: "2%" }} page={currentPage} onChange={onPageChanged} />
            :
            <Pagination page={currentPage} count={Math.ceil(totalProject / 40)} variant="outlined" shape="rounded" style={{ bottom: "0", marginBottom: "2%" }} onChange={onPageChanged} />}
        </Stack>

      </div>
      <Footer />


    </>
  )
}

