import React, { useEffect, useState, useRef } from 'react';
import { UserAuth } from '../../AuthContent';
import { doc, setDoc, getDocs, collection, addDoc, query, where, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { signInWithPhoneNumber, getAuth, deleteUser } from "firebase/auth";
import firebase from '../../firebase/firebase';
import Acknowledge from './Acknowledge';


export default function TempOTP(props) {
    const { user, deleteUserData,setUser } = UserAuth();
    const auth = getAuth();
    const navigate = useNavigate();
    let locationURL = window.location.href;
    const appVerifier = window.recaptchaVerifier;
    const [timer, setTimer] = useState(0);

    const [allowReverification,setAllowReverification]= useState(false);
    const [otp, setotp] = useState('');
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState('');
    const [OTPsent, setOTPsent] = useState(false);
    const [approvedOTP, setApprovedOTP] = useState(false);
    var userPreviousArr = useRef([]);
    var mynumber = useRef(0);
    const [acknowledge, setAcknowledge] = useState(false);

    var testingCredential_token = useRef(null);

    const confirmNumber = async () => {
        mynumber.current = document.getElementById('phone').value;
        if (mynumber.current === "" || mynumber.current.length < 8) return;
        setshow(true);
        setTimer(30);
        setAllowReverification(false);

        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email, 
            "123456"
        );
        testingCredential_token.current = credential;

        let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        const querySnapshot = await getDocs(query(collection(db, 'userInfo'), where("phoneNumber", "==", Number(mynumber.current))));
        if (querySnapshot.docs.length != 0) {
            alert("this phone used");
            setshow(false);
        }
        else {

            signInWithPhoneNumber(auth, "+65" + mynumber.current, verify).then((result) => {
                setfinal(result);
                alert("code sent")
                setOTPsent(true);
                setshow(false);
                setTimer(30);
               
            })
                .catch((err) => {
                    alert(err);
                    window.location.reload()
                });
        }

    }
    // Validate OTP
    const ValidateOtp = () => {
        if (otp === null || final === null)
            return;
        final.confirm(otp).then(async (result) => {
            // success
            // props.setUserType(true);

            const res = await getDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid));
            if (res._document != null) {
                setApprovedOTP(true);
                await updateDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid), {
                    phoneNumber: Number(mynumber.current)
                })
                // }
            }
            alert("Number Verification Completed. Please relogin to continue");
            await deleteUser(result.user).then(() => {
                setAcknowledge(true);
                return;
            })
            
            
            // props.setUserType(true);
        })
            .catch((err) => {
                console.log(err);
                alert("Wrong code");
                setshow(false);
            })
    }

 
    useEffect(() => {

        if (user != null) {
            if (Object.keys(user).length != 0) {
                userPreviousArr.current.push(user);
            }
        }
    }, [user])


    useEffect(() => {
        if(timer > 0){
        const interval = setInterval(() => {
          setTimer(timer-1)
        }, 1000);
        return () => clearInterval(interval);
        }
        else{
            setAllowReverification(true);
        }
      }, [timer]);

    const reverify = () =>{
        setshow(false);
        setOTPsent(false);
        setTimer(-1);
    }

    if (!props.trigger) {
        return;
    }
    else {


        return (
            <>
            <Acknowledge trigger={acknowledge} setTrigger={setAcknowledge} message = {"Number Verification Completed. Please relogin to continue"} parent={"UserType"} />
                <div className='modalNewProject' style={{ zIndex: "2" }}>
                    <div className='modal-contentNewProject' >
                        <div className='modal-headerNewProject'>
                            <h4 className='modal-titleNewProject'>PHONE NUMBER VERIFICATION</h4>
                        </div>
                        <div className='modal-bodyNewProject'>
                            <center>
                                {show == false && OTPsent == false?
                                    <div>
                                        <b><label for="phone">Enter a phone number: </label></b>
                                        <input type="tel" id="phone" name="phone" placeholder="12345678" pattern="[0-9]{8}" required style={{padding:"0.5%"}}/>
                                        <br></br>
                                        <button id="OTPbtn" className='btn--general' onClick={() => confirmNumber()}>Send One Time Password</button>
                                    </div>
                                    : null}

                                {/* <div id="recaptcha-container"></div> */}
                                {OTPsent == false ? <div style={{ display: show ? "block" : "none" }}>
                                    <div id="recaptcha-container"></div>
                                </div>:null}

                                <div style={{ display: OTPsent ? "block" : "none" }}>
                                    <input type="text" placeholder={"Enter your OTP"}
                                        onChange={(e) => { setotp(e.target.value) }} style={{padding:"1%"}}></input>
                                    <br /><br />
                                    <button className='btn--general' onClick={ValidateOtp}>Verify</button>
                                    {allowReverification == false ? <button className='btn--general' disabled>{timer} Reverification</button>:<button className='btn--general' onClick={() => {reverify()}}>Reverification</button>}
                                    
                                </div>
                            </center>
                            {/* </center>
                            </div> */}
                        </div>

                        <div className='modal-footerNewProject'>

                            {/* <button className='btn--general' >Proceed</button> */}

                        </div>
                    </div>
                </div>
            </>
        );
    }

}