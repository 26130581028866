import React, { useRef, useEffect, useState } from 'react';
import '../../App.css';
import '../css/AboutUs.css'
import { db } from '../../firebase/firebase'
import { onSnapshot, doc, getDoc } from 'firebase/firestore';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import Footer from '../Footer';
import PLServices from './PLServices';




function ThirdPartyServices() {

  const chats = useRef(null);
  const { user, deleteUserData } = UserAuth();
  let unsbscribe;
  const [PL, setPL] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    console.log("t&s", user);
    const checkingUser = async () => {
      var docsnap = await getDoc(doc(db, 'userInfo', user.uid));
      if (docsnap.exists()) {
        console.log("approved user");
      }
      else {
        // alert("hacked your way through ah");
        deleteUserData();

      }
    }
    if (user != null) {
      if (Object.keys(user).length != 0) {
        checkingUser();
      }
    }
  }, [user])

  async function getFirebaseData() {
    unsbscribe = onSnapshot(doc(db, `userChat`, user.uid), (doc) => {
      console.log('snapshot', doc.data());
      if (doc.exists()) {
        var chat_data = Object.entries(doc.data())?.sort((a, b) => b[1].date - a[1].date);

        if (chats.current != null) {

          Store.addNotification({
            title: chat_data[0][1].userInfo.listingName,
            message: chat_data[0][1].userInfo.lastMessage,
            type: "default",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
            width: 300
          })
        }
        chats.current = chat_data;
      }

    })
  }


  useEffect(() => {

    if (!user) {

    }
    else {
      user.uid && getFirebaseData()
      return unsbscribe;
    }

  }, [user]);

  return (
    <>
      <ReactNotifications />
      <PLServices trigger={PL} setTrigger={setPL} />
      <div class="container">
        <div class="top-row">Component 1
          <h2>PL</h2>
          <button onClick={() => setPL(true)}>Proceed</button>
        </div>
        <div class="top-row">Component 2</div>
        <div class="bottom-row">Component 3</div>
        <div class="bottom-row">Component 4</div>
        {/* <div className='ContactUsDiv' style={{ backgroundColor: "#30628E", color: "white" }}>
                    <center><h1>Third Party Services</h1></center>
                 
                  
                  
                    <h2>Another Service</h2>
                    <button >Proceed</button>
                </div> */}

      </div>
      <div style={{ clear: 'both' }}></div>
      <Footer />
    </>


  )
}

export default ThirdPartyServices;
