import React, { useRef, useEffect } from 'react';
import '../../App.css';
import '../css/AboutUs.css'
import { db } from '../../firebase/firebase'
import { onSnapshot, doc, getDoc } from 'firebase/firestore';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import Footer from '../Footer';
import Notification from './Notification';




function TermOfService() {

  const chats = useRef(null);
  const { user, deleteUserData } = UserAuth();
  let unsbscribe;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    console.log("t&s", user);
    const checkingUser = async () => {
      var docsnap = await getDoc(doc(db, 'userInfo', user.uid));
      if (docsnap.exists()) {
        console.log("approved user");
      }
      else {
        // alert("hacked your way through ah");
        deleteUserData();

      }
    }
    if (user != null) {
      if (Object.keys(user).length != 0) {
        checkingUser();
      }
    }
  }, [user])


  return (
    <>
      <Notification />
      <div>
        <center><p className='t_c_title'>Terms Of Services</p></center>
        <div className='t_cDiv'>
          <p className='t_c_date'>Last Updated: 09th July 2023</p>
          <p className='t_c_text'>The following Terms of Service ("Terms") regulate the utilization of the Services offered by NextLoop Pte Ltd (“NextLoop”), including websites, mobile applications, and related services(collectively, the “Services”).</p>
          <p className='t_c_header'>1. General</p>
          <p className='t_c_text'>These Terms of Service apply to all users of the Services provided by NextLoop, including individuals or entities acting as landlords, tenants, business owners, or corporate users accessing content, information, and other materials. If you are using the Services on behalf of a business entity, the term "you" in this agreement refers to the entity you represent, unless otherwise specified by the context. These Terms establish the agreement between you and NextLoop Pte. Ltd. or any other service provider identified for a specific Service.</p>
          <p className='t_c_text'>For convenience, NextLoop Pte Ltd. and its subsidiaries are collectively referred to as "NextLoop" in these Terms.</p>
          <p className='t_c_text'>By using the Services, including visiting or browsing the platforms, you agree to comply with these Terms, as well as any additional terms, conditions, and policies referenced herein or provided through hyperlinks.</p>
          <p className='t_c_text'>NextLoop reserves the right to modify or change these Terms at any time. By continuing to use the Services after the amended Terms are posted, you will be deemed to have accepted the updated Terms. If you have any inquiries, please consult our Help Center.</p>
          <p className='t_c_header'>2. Use of the service</p>
          <p className='t_c_text'>Subject to your adherence to these Terms, NextLoop grants you a limited, non-exclusive, non-transferable, and revocable right and license to utilize the Services. However, you must refrain from engaging in the following actions:</p>
          {/* <div id="SecondPointerDiv"> */}
          <p className='t_c_text'>Uploading any Content (as defined below) that violates or infringes upon the rights of others, such as rights of privacy, publicity, copyright, trademark, or any other intellectual property rights. Copying, decompiling, reverse engineering, disassembling, attempting to derive the source code of, decrypting, interfering with, or disrupting the integrity or performance of the Services.</p>
          <p className='t_c_text'>Making any modifications, adaptations, improvements, enhancements, translations, or derivative works based on the Services.</p>
          <p className='t_c_text'>Making any modifications, adaptations, improvements, enhancements, translations, or derivative works based on the Services.</p>
          <p className='t_c_text'>Using the Services to violate or circumvent any sanctions or embargoes.</p>
          <p className='t_c_text'>Removing, altering, or obscuring any proprietary notices (including copyright and trademark notices) of NextLoop or its affiliates, partners, suppliers, or licensors.</p>
          <p className='t_c_text'>Using the Services for purposes other than those for which they are designed or intended.</p>
          <p className='t_c_text'>Utilizing the Services to create or promote a product, service, or software that directly or indirectly competes with or acts as a substitute for the Services or any services, products, or software offered by NextLoop.</p>
          <p className='t_c_text'>Employing any proprietary information, interfaces, or intellectual property of NextLoop in the design, development, manufacturing, licensing, or distribution of any application, accessories, or devices for use with the Services.</p>
          <p className='t_c_text'>Sending, posting, or otherwise communicating through the Services any Content that is offensive, indecent, threatening, abusive, insulting, harassing, defamatory, libelous, deceptive, fraudulent, tortious, obscene, profane, invasive of another person's privacy, or racially, ethnically, or otherwise objectionable.</p>
          <p className='t_c_text'>Using the Services to transmit automated, unsolicited, or unauthorized messages, advertising, promotional material, junk mail, spam, or chain letters.</p>
          <p className='t_c_text'>Uploading or transmitting through the Services any data, file, software, or link that contains or redirects to viruses, Trojan horses, worms, or other harmful components.</p>
          <p className='t_c_text'>Utilizing any scraper, robot, bot, spider, crawler, or other automated device or means to access, acquire, copy, or monitor any portion of the Services, data, or content found or accessed through the Services.</p>
          <p className='t_c_text'>Attempting to engage in any activity or act that aims to abuse, exploit, or inappropriately manipulate any promotions, campaigns, or discount codes offered through the Services.</p>
          <p className='t_c_text'>NextLoop reserves the right to suspend or terminate your account if you are discovered or suspected to be engaged in any activity or act that violates these Terms, our guidelines, or any additional terms, conditions, and policies.</p>
          {/* </div> */}

          <p className='t_c_header'>3. Account</p>
          <p className='t_c_text'>You can access certain parts of the Services, you must create an account with NextLoop ("Account"). By creating an Account, you confirm and guarantee the following:</p>
          {/* <div id="SecondPointerDiv"> */}
          <p className='t_c_text'>If you are an individual, you are at least 18 years old.</p>
          <p className='t_c_text'>All information you provide is accurate, up-to-date, truthful, and complete.</p>
          <p className='t_c_text'>If you are under 18 years old, you may only use the Services with the consent and supervision of your parent or legal guardian, who will be responsible for all your activities.</p>
          <p className='t_c_text'>You are responsible for all activities and transactions that occur under your Account. We cannot be held responsible if your password and/or Account are misused or accessed by a third party. Therefore, you agree to:</p>
          {/* <div id="SecondPointerDiv"> */}
          <p className='t_c_text'>Maintain the security of your password.</p>
          <p className='t_c_text'>Keep your account information up to date at all times.</p>
          <p className='t_c_text'>You must comply with NextLoop's policies, guidelines (which are part of these Terms), and all applicable laws regarding your activities and the Content you upload to the Services.</p>
          <p className='t_c_text'>Unless expressly permitted by NextLoop and subject to these Terms and any additional terms determined by NextLoop, you may not create multiple Accounts. You may not lend, transfer, or sell your Account or user ID to another party, and you must not use another user's Account without their permission.</p>
          {/* </div> */}
          <p className='t_c_text'>Therefore, NextLoop reserves the right to suspend or terminate your Accounts and/or your access to the Services at any time without prior notice.</p>
          <p className='t_c_header'>3.1 NextLoop Subscriptions</p>
          <p className='t_c_text'>Additionally, you have the option to claim, redeem, and/or purchase subscriptions to access additional features and Services through a subscription service on the Platforms or via other available methods.</p>
          <p className='t_c_text'>Such purchases will be subject to NextLoop's prevailing subscription rules and fees, which are outlined below or provided through hyperlink and are considered part of these Terms.</p>
          <p className='t_c_text'>By using and/or purchasing NextLoop Services, you agree to comply with the Standard Terms for NextLoop Services.</p>

          {/* </div> */}

          <p className='t_c_header'>4. Boast campaigns</p>
          <p className='t_c_text'>You have the option to purchase Boast products, which can enhance the visibility of your listings and profile on NextLoop, based on NextLoop's listing and priority rules and associated charges. Detailed information regarding these products can be found on the Boast page, which is an integral part of these Terms.</p>
          <p className='t_c_text'>By using Boasts, you acknowledge and agree to the following:</p>
          <p className='t_c_text'>The priority of your Boast listings may fluctuate in relation to other users' listings, as determined by NextLoop's listing rules, formulas, and algorithms. Each Boast takes effect immediately, and any fees or charges paid for Boasts are non-refundable.</p>
          {/* <div id="SecondPointerDiv"> */}
          <p className='t_c_text'>If you change the category of a Boasted listing or mark it as closed, the Boast for that listing will be terminated early, and no refunds will be issued for such early termination.</p>
          <p className='t_c_text'>NextLoop does not guarantee or warrant that using Boasts will result in likes, offers, chats, follows, or successful transactions.</p>
          <p className='t_c_text'>Refunds will not be provided in the event of account suspension or termination due to a violation of these Terms, or if any content has been removed in accordance with these Terms.</p>
          <p className='t_c_text'>Note: The text above has been rephrased to avoid plagiarism, but it is important to consult NextLoop's specific terms and conditions for accurate and detailed information regarding Boasts, including pricing and refund policies.</p>
          {/* </div> */}
          <p className='t_c_header'>5. Fees and payment</p>
          <p className='t_c_text'>Depending on the type and number of listings you choose to post and any additional services you request for your Account or listings, you may incur various fees and charges known as "NextLoop Fees." These fees include subscription fees, listing fees, and other charges related to your Account or listings. You have the option to make payment for NextLoop Fees using a credit card or debit card. It is important to note and agree to the following:</p>
          <p className='t_c_text'>For subscription or recurring payments, it is your responsibility to ensure that all payments are made and cleared by your bank before each monthly or annual recurring payment is due. If payment by credit card is unsuccessful, your NextLoop Subscription will be automatically suspended or terminated if payments are not received within three (3) days from the due date.</p>
          <p className='t_c_text'>Unless otherwise stated in this clause, all subscription fees, listing fees, and other fees paid by you to us for your Account or access to the Services are non-refundable. This means that no refunds will be issued for NextLoop Fees in the event of your Account suspension or termination due to a breach of these Terms or if any content has been removed in accordance with these Terms.</p>
          <p className='t_c_text'>Prompt payment of all NextLoop Fees is required before the due date specified by NextLoop, following our payment instructions. In case of late or non-payment, NextLoop reserves the right to take action, including but not limited to terminating or suspending your Account or access to the Services.</p>
          <p className='t_c_text'>Additionally, a late payment fee of 1.5% per month or the maximum rate allowed by applicable law (whichever is lower) may be charged on the overdue amount. This fee will be applied monthly until the overdue amount is paid, along with any legal fees and collection costs incurred by NextLoop in collecting the outstanding amounts.</p>
          <p className='t_c_text'>If your Account is suspended or terminated for any reason, all outstanding amounts on your Account will become immediately due and payable. NextLoop reserves the right to charge any undisputed amounts to your billing method and may terminate your Account, without prejudice to any other rights or remedies available to us.</p>

          {/* <div id="SecondPointerDiv"> */}

          <p className='t_c_header'>5.1 Listing Fees & Listing Quota:</p>
          <p className='t_c_text'>There may be a maximum limit on the number of active listings you can publish on your profile at a given time. If you exceed this limit, additional listing insertions will be subject to listing fees based on NextLoop's prevailing rules and charges, which can be found in the provided article and are considered part of these Terms.</p>
          <p className='t_c_text'>You acknowledge and understand that NextLoop does not guarantee or warrant that payment of NextLoop Fees will result in likes, offers, chats, or successful transactions.</p>
          <p className='t_c_text'>Note: The above rephrased text is an original composition to avoid plagiarism. For accurate and detailed information regarding NextLoop Fees, including specific charges and refund policies, it is important to refer to NextLoop's official terms and conditions.</p>
          {/* </div> */}
          <p className='t_c_header'>6. Content</p>
          <p className='t_c_text'>The Services provided enable users to create listings and share various types of content, including company data, photos, videos, comments, text, links, and other information ("Content"). While you retain ownership rights to the Content you upload or share on the Services, you grant NextLoop a worldwide, fully-paid, royalty-free, sublicensable, and transferable license to host, store, use, display, reproduce, modify, adapt, edit, publish, and distribute such Content (subject to NextLoop's Privacy Policy). This license is granted for the purpose of operating, developing, providing, promoting, and improving the Services, as well as conducting research and developing new products and services. You understand and agree that you bear sole responsibility for the Content you post or share on or through the Services, and any loss or damage resulting from such Content is solely your responsibility. It is important to note that NextLoop does not pre-screen the Content uploaded by users. NextLoop reserves the right (but not the obligation) to refuse, delete, or move any Content available on the Services at its sole discretion. NextLoop may remove Content, without liability or obligation to offer a refund, under the following circumstances:</p>
          <p className='t_c_text'>The Content violates these Terms.</p>
          <p className='t_c_text'>NextLoop receives a complaint or notice of infringement regarding the Content.</p>
          <p className='t_c_text'>The Content is deemed objectionable.</p>
          <p className='t_c_text'>Additionally, NextLoop may block Content and prevent the delivery of communications (including feedback, postings, messages, and chats) to or from the Services as part of its efforts to protect the Services, users, or enforce these Terms.</p>
          {/* <div id="SecondPointerDiv"> */}
          <p className='t_c_text'>NextLoop payment service provider</p>
          <p className='t_c_text'>To facilitate the Payment Services, NextLoop may integrate the services of specific Payment Service Providers, subcontractors, partners, and agents ("Payment Service Providers") into the Platform. The Payment Service Providers offer their payment processing services subject to their own Additional Terms. By using the Payment Services, you agree to comply with these Additional Terms, which may be modified by the Payment Service Providers periodically, along with any other applicable Additional Terms.</p>
          <p className='t_c_text'>For the purpose of using the Payment Services, you must provide NextLoop and/or its related corporations with accurate and complete information, including the necessary account details for at least one valid credit or debit card, through the Platform. NextLoop and/or its related corporations will use this information as described in the Privacy Policy. You acknowledge and agree that we may utilize specific third-party service providers, such as PayPal, to process payments and manage the account information you have provided. You have the ability to add, delete, or modify the payment method account information directly through the Platform as needed.</p>
          <p className='t_c_text'>By providing the account information for a credit or debit card, you represent and warrant that: (i) you have legal authorization to provide such information to us; (ii) you have legal authorization to perform payments from the account(s) you have provided; and (iii) such actions do not violate the terms and conditions associated with your use of the account(s) or applicable laws. When you authorize a payment using any of the provided account(s) via the Platform, you further represent and warrant that there are sufficient funds or credit available to complete the transaction.</p>
          {/* </div> */}
          <p className='t_c_text'>By utilizing a specific payment method, you are agreeing to the terms of service provided by the relevant processing partner and your financial institution. Any fees charged by such processing partners and/or your financial institution for the selected payment method will be your responsibility.</p>
          <p className='t_c_text'>You grant NextLoop and/or its related corporations the authority to process the Payment Services on your behalf, including but not limited to initiating, accepting, and refunding payments, in accordance with the applicable terms and conditions.</p>
          <p className='t_c_text'>Disputes with users</p>
          <p className='t_c_text'>If you find yourself in a dispute with another user of our Services, we recommend reaching out to the user directly to seek a friendly resolution. In the event that you notify NextLoop of the dispute, we will make a genuine effort to assist in resolving the matter. However, please note that NextLoop is not obligated to mediate or settle disputes between users. By engaging in our Services, you agree to release NextLoop from any claims, demands, or damages arising from disputes with other users.</p>

          <p className='t_c_header'>7. Next Loop is a market place</p>
          <p className='t_c_text'>The Services provided by NextLoop serve as a platform for users to interact with one another and engage in transactions. It's important to note that NextLoop does not pre-screen users or the Content they provide, and NextLoop is not directly involved in the transactions conducted between users. Therefore, NextLoop lacks control over and accepts no responsibility or liability for the following:</p>
          {/* <div id="SecondPointerDiv"> */}
          <p className='t_c_text'>The quality, safety, morality, or legality of any aspect related to the listed items.</p>
          <p className='t_c_text'>The accuracy or truthfulness of the listings.</p>
          <p className='t_c_text'>The actual identity, age, or nationality of users.</p>
          <p className='t_c_text'>Any Content posted by users.</p>
          <p className='t_c_text'>You are encouraged to utilize the available features and functions of the Services to communicate with other users and gather more information about the listings offered for a deal. However, it is crucial to exercise common sense and good judgment during your interactions with other users. While NextLoop strives to maintain a safe environment for everyone, it is your sole responsibility to use the Services and engage with other users at your own risk.</p>
          <p className='t_c_text'>Note: The above rephrased content is an original composition created to avoid plagiarism. For accurate and detailed information regarding user interactions, content validity, and user responsibility, it is advised to refer to NextLoop's official terms and conditions.</p>
          {/* </div> */}
          <p className='t_c_header'>8. Intellectual Property</p>
          <p className='t_c_text'>You acknowledge and agree that the Services, including all associated copyrights, patents, trademarks, trade secrets, and other intellectual property rights, are and will remain the exclusive property of NextLoop. Additionally, you recognize and consent that the source and object code of the Services, as well as the format, directories, queries, algorithms, structure, and organization of the Services, constitute the intellectual property, proprietary, and confidential information of NextLoop. Except for the rights expressly granted in these Terms, you are not granted any intellectual property rights to the Services, and all such rights are reserved and retained by NextLoop.</p>
          <p className='t_c_text'>The Services may incorporate or utilize third-party software that is governed by open source and third-party license terms ("Third Party Software"). You acknowledge and agree that your use of such Third Party Software as part of the Services is subject to and governed by the terms and conditions set forth in the applicable open source and third-party licenses. This includes any applicable acknowledgments, license terms, and disclaimers provided within those licenses. In the event of any conflict between these Terms and the terms of the open source or third-party licenses, the terms of the open source or third-party licenses shall prevail regarding your use of the relevant third-party software. It is important to note that the Services or any of its components shall not be considered open source or publicly available software.</p>
          <p className='t_c_text'>Without the prior written consent of NextLoop, you are not authorized to use NextLoop's trademarks in any form of advertising, publicity, or commercial manner. NextLoop reserves the right to withhold such consent for any reason or no reason at all.</p>
          <p className='t_c_header'>9. Reporting unauthorised content</p>
          <p className='t_c_text'>At NextLoop, we hold a strong regard for intellectual property rights and we require our users to do the same. If you believe that your intellectual property rights have been violated by a user, we kindly request that you report the incident to us.</p>
          <p className='t_c_text'>In the event of repeat complaints against you regarding intellectual property infringement, NextLoop reserves the right to suspend or terminate your account and/or your access to the Services.</p>
          <p className='t_c_header'>10. Privacy</p>
          <p className='t_c_text'>We value your privacy and prioritize it at NextLoop. To provide you with a clear understanding of our privacy practices, we have developed the NextLoop Privacy Policy, which can be accessed here. We encourage you to review the Privacy Policy to learn about how we collect, use, and disclose information that we collect from you.</p>

          <p className='t_c_header'>11. Suspension and Termination</p>
          <p className='t_c_text'>You have the option to deactivate your Account at any time by accessing the "Settings" section of your Account.</p>
          <p className='t_c_text'>NextLoop retains the right, at its sole discretion and without incurring any liability, to take the following actions:</p>
          {/* <div id="SecondPointerDiv"> */}
          <p className='t_c_text'>Suspend or terminate your Account and/or your access to the Services at any time, should you breach these Terms, our guidelines, additional terms and conditions, or policies.</p>
          <p className='t_c_text'>Suspend or terminate your Account and/or your access to the Services at any time, without prior notice and for any reason.</p>
          <p className='t_c_text'>Modify, suspend, or discontinue, either temporarily or permanently, the whole or any part of the Services at any time, without prior notice and for any reason.</p>
          <p className='t_c_text'>Suspend or terminate your Account and/or your access to the Services at any time, should you engage in any act or make threats of verbal and/or written abuse against any NextLoop user, NextLoop employees, representatives, or officers.</p>
          <p className='t_c_text'>Please be aware that upon deactivating your Account or in the event of suspension or termination by us, you will lose any data associated with your Account, including Content. It is your responsibility to back up any Content you upload to the Services.</p>
          <p className='t_c_text'>Upon termination, all licenses and rights granted to you in these Terms will cease immediately.</p>
          {/* </div> */}
          <p className='t_c_header'>12. Limitation of Liability</p>
          <p className='t_c_text'>You acknowledge and agree that if you encounter any issues or dissatisfaction with the Services, your sole recourse is to request termination of your Account and/or discontinue using the Services.</p>
          <p className='t_c_text'>In no event shall NextLoop or its subsidiaries, affiliates, partners, suppliers, or licensors be liable for any indirect, incidental, consequential, special, or exemplary damages arising from or in connection with your access to, use of, or inability to access or use the Services or any third-party content and services. This includes damages arising from or in connection with your conduct or the conduct of other users in relation to the use of the Services. Such damages may include, but are not limited to, death, bodily injury, emotional distress, and other damages resulting from communications or meetings with other users or individuals you encounter through the Services. These limitations apply regardless of whether the damages were foreseeable and whether NextLoop was informed of the possibility of such damages. In no event shall NextLoop's aggregate liability to you, whether under contract, tort, statute, or otherwise, exceed the amount of fees received by NextLoop in relation to the specific Services that gave rise to the claims. In relevant cases, the aforementioned limitations shall apply even if the stated remedy fails to achieve its essential purpose.</p>
          <p className='t_c_header'>13. Indemnity</p>
          <p className='t_c_text'>You agree to indemnify and hold harmless NextLoop, its subsidiaries, affiliates, partners, suppliers, licensors, shareholders, officers, employees, independent contractors, and agents, as well as all successors and/or assigns, from any and all actions, claims, proceedings, damages, losses, costs, and expenses arising from:</p>
          {/* <div id="SecondPointerDiv"> */}
          <p className='t_c_text'>(a) Your use of the Services;</p>
          <p className='t_c_text'>(b) Your Content;</p>
          <p className='t_c_text'>(c) Any use of your Account;</p>
          <p className='t_c_text'>(d) Your breach of these Terms;</p>
          <p className='t_c_text'>(e) Your violation of any statutory requirement, duty, or law;</p>
          <p className='t_c_text'>(f) Your infringement upon the rights of another person or entity.</p>
          {/* </div> */}
          <p className='t_c_text'>By doing so, you assume responsibility for any potential liabilities and agree to protect NextLoop and related parties from any adverse consequences resulting from the mentioned actions or violations.</p>
          <p className='t_c_header'>14. Compatibility</p>
          <p className='t_c_text'>NextLoop cannot guarantee the compatibility or interoperability of the Services with your specific device or any other hardware, software, equipment, or device used in conjunction with it. It is important to understand that compatibility issues may arise, which could potentially impact the performance of your device, lead to data loss, and even cause permanent damage or corruption of software and files. Therefore, you acknowledge and accept that NextLoop, along with its subsidiaries, affiliates, partners, suppliers, and licensors, cannot be held liable for any losses you may incur due to such compatibility or interoperability problems.</p>
          <p className='t_c_header'>15. Sub-contracting</p>
          <p className='t_c_text'>NextLoop has the authority to assign, subcontract, or engage related corporations, service providers, partners, subcontractors, and agents to carry out any portion of the Services as deemed necessary by NextLoop.</p>
          <p className='t_c_header'>16. Miscellaneous</p>
          <p className='t_c_text'>You and NextLoop are considered independent entities, and these Terms do not establish any agency, partnership, joint venture, employer-employee, or franchiser-franchisee relationship between you and NextLoop.</p>
          <p className='t_c_text'>If any provision of these Terms is found to be unenforceable, it will be modified to reflect the original intent of the parties. All remaining provisions of these Terms will continue to be fully effective.</p>
          <p className='t_c_text'>Except as expressly stated, any failure by NextLoop to enforce a right or require performance of an obligation under these Terms does not waive NextLoop's ability to exercise that right or require performance in the future. Waiving a breach of these Terms does not constitute a waiver of any subsequent breach.</p>
          <p className='t_c_text'>You are not permitted to assign any rights or delegate any obligations under these Terms without the prior written consent of NextLoop. Any attempted assignment or delegation in violation of this provision will be deemed null and void.</p>
          <p className='t_c_text'>These Terms represent the complete agreement between you and NextLoop, superseding any prior or contemporaneous understandings and agreements between you and NextLoop</p>
          <p className='t_c_header'>17. Governing law and jurisdiction</p>
          <p className='t_c_text'>By accepting these Terms, you agree that the laws of Singapore will govern any disputes arising from or related to these Terms, and that the courts of Singapore will have non-exclusive jurisdiction over such disputes.</p>
          <p className='t_c_header'>18. Subscription Policy</p>
          <p className='t_c_text'>This Subscription Policy ("Policy") outlines the terms and conditions governing the subscription services provided by NextLoop ("Company") to its users ("Subscribers"). By subscribing to our software as a service ("SaaS"), Subscribers agree to comply with this Policy. Please read this Policy carefully before subscribing to our services.</p>
          <p className='t_c_header'>18.1 Subscription Agreement</p>
          <p className='t_c_text'>Subscribers must agree to the terms and conditions of the Subscription Agreement provided by NextLoop before subscribing to our SaaS. The Subscription Agreement shall govern the overall relationship between the Subscriber and NextLoop.</p>
          <p className='t_c_header'>18.2 Subscription Duration</p>
          <p className='t_c_text'>The subscription duration shall be as specified during the subscription process or as mentioned in the Subscription Agreement. Subscribers may choose from various subscription plans offered by NextLoop, each with its own duration and pricing structure.</p>
          <p className='t_c_header'>18.3 Subscription Fees</p>
          <p className='t_c_text'>Subscribers agree to pay the applicable subscription fees as set forth by NextLoop. These fees may vary based on the subscription plan chosen by the Subscriber.</p>
          <p className='t_c_text'>All subscription fees are non-refundable. Once a subscription fee has been paid, no refunds or credits shall be provided, except as required by applicable law.</p>
          <p className='t_c_header'>18.4 Payment Terms</p>
          <p className='t_c_text'>Subscribers shall provide accurate and complete billing information during the subscription process. NextLoop reserves the right to verify and validate the provided billing information.</p>
          <p className='t_c_text'>The Subscriber agrees to pay all subscription fees promptly and in the currency specified by NextLoop.</p>
          <p className='t_c_text'>In the event of non-payment or failure to process payment, NextLoop reserves the right to suspend or terminate the Subscriber's access to the SaaS, without any liability.</p>
          <p className='t_c_header'>18.5 Subscription Renewal and Cancellation</p>
          <p className='t_c_text'>Subscriptions will automatically renew at the end of the subscription period, unless the Subscriber cancels the subscription before the renewal date</p>
          <p className='t_c_text'>Subscribers may cancel their subscription at any time by following the cancellation process provided by NextLoop.</p>
          <p className='t_c_text'>NextLoop reserves the right to modify or discontinue any subscription plan, or change the subscription fees, upon reasonable notice to the Subscribers.</p>
          <p className='t_c_header'>18.6 Intellectual Property</p>
          <p className='t_c_text'>All rights, title, and interest in and to the SaaS, including all intellectual property rights, are and will remain the exclusive property of NextLoop.</p>
          <p className='t_c_text'>Subscribers shall not modify, copy, reproduce, distribute, transmit, display, perform, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from or through the SaaS without the prior written consent of NextLoop.</p>
          <p className='t_c_header'>18.7 Limitation of Liability</p>
          <p className='t_c_text'>NextLoop shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with the use or inability to use the SaaS, even if NextLoop has been advised of the possibility of such damages.</p>
          <p className='t_c_text'>NextLoop's total liability to any Subscriber for any claim arising out of or in connection with the Subscription Agreement, the SaaS, or the use of the SaaS shall be limited to the amount of fees paid by the Subscriber to NextLoop for the subscription.</p>
          <p className='t_c_header'>18.8 Governing Law and Jurisdiction</p>
          <p className='t_c_text'>This Subscription Policy shall be governed by and construed in accordance with the laws of the jurisdiction in which NextLoop is based.</p>
          <p className='t_c_text'>Any disputes arising out of or relating to this Policy or the use of the SaaS shall be subject to the exclusive jurisdiction of the courts in the jurisdiction in which NextLoop is based.</p>
          <p className='t_c_header'>18.9 Modifications to the Policy</p>
          <p className='t_c_text'>NextLoop reserves the right to modify or update this Subscription Policy at any time. Any changes made to the Policy will be effective immediately upon posting the revised version on the NextLoop website or notifying Subscribers through other means.</p>

        </div>
      </div >
      <div style={{ clear: 'both' }}></div>
      <Footer />
    </>


  )
}

export default TermOfService;
