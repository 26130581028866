import React, { useRef, useEffect } from 'react';
import '../../App.css';
import '../css/Contact.css'
import { db } from '../../firebase/firebase'
import { onSnapshot, doc, getDoc } from 'firebase/firestore';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import '../css/FAQ.css';
import Footer from '../Footer';




function Profile() {

    const chats = useRef(null);
    const { user, deleteUserData } = UserAuth();
    let unsbscribe;
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])


    async function getFirebaseData() {
        unsbscribe = onSnapshot(doc(db, `userChat`, user.uid), (doc) => {
            console.log('snapshot', doc.data());
            var chat_data = Object.entries(doc.data())?.sort((a, b) => b[1].date - a[1].date);

            if (chats.current != null) {

                Store.addNotification({
                    title: chat_data[0][1].userInfo.listingName,
                    message: chat_data[0][1].userInfo.lastMessage,
                    type: "default",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                    },
                    width: 300
                })
            }
            chats.current = chat_data;

        })
    }


    useEffect(() => {

        if (!user) {

        }
        else {
            user.uid && getFirebaseData()
            return unsbscribe;
        }

    }, [user]);

    useEffect(() => {
        console.log("Profile", user);
        const checkingUser = async () => {
          var docsnap = await getDoc(doc(db, 'userInfo', user.uid));
          if (docsnap.exists()) {
            console.log("approved user");
          }
          else {
            // alert("hacked your way through ah");
            deleteUserData();
            
          }
        }
        if (user != null) {
          if (Object.keys(user).length != 0) {
            checkingUser();
          }
        }
      }, [user])

    return (
        <>
            <ReactNotifications />
            <div>

               <h1>profile</h1>
            </div>
            <div style={{ clear: 'both' }}></div>
            <Footer />
        </>


    )
}

export default Profile;
