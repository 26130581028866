import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, updateDoc, doc, setDoc, addDoc, serverTimestamp, getDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase/firebase'
import '../css/Details.css';
import Carousel, { CarouselItem } from '../Carousel';
import { RWebShare } from "react-web-share";
import { UserAuth } from '../../AuthContent';
import { useNavigate } from 'react-router-dom';
import Report from './Report';
import { TelegramShareButton, WhatsappShareButton } from "react-share";
import { TelegramIcon, WhatsappIcon } from "react-share";
import Footer from '../Footer';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import UserTypeModal from './UserTypeModal';
import { Link } from 'react-router-dom';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import DropAMessage from './DropAMessage';
import Notification from './Notification';
import Spinner from './Spinner';
import ChangePlan from './ChangePlan';

export default function ResidentialDetails() {

  const [project, setProject] = useState(null);
  const { user } = UserAuth();
  const [shortListed, setShortListed] = useState(false);
  const [navigateAccount, setNavigateAccount] = useState([false, '']);
  const [report, setReportClick] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [approvedDoubleCheck, setApprovedDoubleCheck] = useState(false);
  const navigate = useNavigate();
  const sellerID = useRef(null);
  const chats = useRef(null);
  const [upgradeTier, setUpgradeTier] = useState(false);
  const [acknowledgeMessage, setAcknowledgeMessage] = useState("");
  const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
  const [dropAMessage, setDropAMessage] = useState(false);
  const [handleMessageClick, setHandleMessageClick] = useState(false);
  const [handleMessageTextArea, setHandleMessageTextArea] = useState(null);
  const [allowPreferred, setAllowPreferred] = useState(false);
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const [targetRole, setTargetRole] = useState(null);
  const [failLoadPage, setFailLoadPage] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    var savedValue = sessionStorage.getItem('currentTab');
    if (savedValue != null) {
      sessionStorage.removeItem('currentTab');
    }
  }, [])

  useEffect(() => {
    document.title = 'Nextloop - Details of Residential Purchase';
  }, []);
  useEffect(() => {
    getData();
  }, []);

  const showAddPropertyQuestionMark = () => {
    const notApprovedText = document.getElementById(`showAddPropertyQuestionMark`);
    if (notApprovedText.style.display == "none") {
      notApprovedText.style.display = "block";
    }
    else {
      notApprovedText.style.display = "none";
    }
  }

  const showPreferred = async () => {
    var id = (window.location.pathname).split('/');
    if (project.details.preferredStatus == true && project.details.UID != user.uid) {
     
      if (userInfo.hasOwnProperty('advancedProjectSeen') == false && (userInfo.userType.includes("Landlord") || userInfo.userType.includes("Agent") || userInfo.userType.includes("Master Tenant")) && userInfo.advancedDetails > 0) {

        await updateDoc(doc(db, `userInfo`, user.uid), {
          advancedDetails: userInfo.advancedDetails - 1,
          advancedProjectSeen: id[2]
        })
        setApprovedDoubleCheck(true);
        setAllowPreferred(true);
      }
      else {
        if (userInfo.hasOwnProperty('advancedProjectSeen')) {
          if (userInfo.advancedProjectSeen.search(id[2]) < 0 && (userInfo.userType.includes("Landlord") || userInfo.userType.includes("Agent") || userInfo.userType.includes("Master Tenant")) && userInfo.advancedDetails > 0) {

            await updateDoc(doc(db, `userInfo`, user.uid), {
              advancedDetails: userInfo.advancedDetails - 1,
              advancedProjectSeen: userInfo.advancedProjectSeen + "|" + id[2]
            })
            setApprovedDoubleCheck(true);
            setAllowPreferred(true);
          }
          else if (userInfo.advancedProjectSeen.search(id[2]) >= 0) {
            setApprovedDoubleCheck(true);
            setAllowPreferred(true);
          }
          else {
            alert("No availble advanced details left");
          }
        }
        else {
          alert("No availble advanced details left");
        }


      }
    }
  }

  const getUserInfo = async () => {
    var id = (window.location.pathname).split('/');
    const docRef = await getDoc(doc(db, `userInfo`, user.uid));
    var currentUserInfo = docRef.data();
    if (currentUserInfo.membership != "admin") {
      if (project.details.preferredStatus == true && project.details.UID != user.uid) {
        

          if(currentUserInfo.hasOwnProperty('advancedProjectSeen')){
            if (currentUserInfo.advancedProjectSeen.search(id[2]) >= 0) {
              setApprovedDoubleCheck(true);
              setAllowPreferred(true);
            }
          }


        
      }
      if (project.details.UID == user.uid && project.details.preferredStatus == true) {
        setAllowPreferred(true);
        setApprovedDoubleCheck(true);
      }
    }

    setUserInfo(docRef.data());
  }


  useEffect(() => {
    const activeShortList = async () => {
      const docRef_3 = collection(db, `userInfo/${user.uid}/shortList`);
      const docSnap_3 = await getDocs(docRef_3);
      docSnap_3.forEach((doc) => {
        if (doc.id == project.id) {
          setShortListed(true);
        }
      })
    }


    if (user != null) {
      if (user.uid == undefined || project == null) {
      }
      else {
        getUserInfo();
        activeShortList();
      }
    }
  }, [user, project])

  useEffect(() => {
    if (navigateAccount[0] == true) {
      navigateAccountFunction();
    }
  }, [navigateAccount])



  useEffect(() => {
    if (handleMessageClick == true) {
      handleMessage().then((res) => { res == "yourOwnListing" ? alert("Cannot request to your listing") : res == "noproperty" ? alert("No Propety Profile"): activateAck()  })
    }
  }, [handleMessageClick])

  const navigateAccountFunction = () => {

    navigate('/account', { state: { navID: navigateAccount[1], myID: user.uid } });
  }



  const getData = async () => {
    var combineID_Details = {};
    var id = (window.location.pathname).split('/');
    const docRef = doc(db, "residentialListingPurchase", id[2]);
    const docSnap = await getDoc(docRef);
    var firstInfo = docSnap.data();
    var MoreDetailsID;
    var secondInfo;
    var thirdInfo;
    var preferredDetails;
    var eyeballStat;
    if (docSnap.exists()) {

      // const docRef_1 = collection(db, `residentialListing/${id[2]}/MoreDetails`);
      if(firstInfo.preferredStatus == true){
        const preferredRef = collection(db, `residentialListingPurchase/${id[2]}/PreferredDetails`);
        const docSnapPreferred = await getDocs(preferredRef);
        docSnapPreferred.forEach((indivDoc) =>{
          thirdInfo = indivDoc.data();
        })
        // const docSnap_1 = await getDocs(docRef_1);
        // docSnap_1.forEach((doc) => {
          // secondInfo = doc.data();
          // MoreDetailsID = doc.id;
          var periodMoveInDate = new Date(firstInfo.moveInPeriod.seconds * 1000);
          var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
          firstInfo.periodMoveIn = formattedDate;
          var combineDetails = { ...firstInfo, ...thirdInfo };
          combineID_Details = { id: docSnap.id, details: combineDetails }
          eyeballStat = combineDetails.eyeballStat;
        // });
      }
      else{
        // const docSnap_1 = await getDocs(docRef_1);
        // docSnap_1.forEach((doc) => {
          // secondInfo = doc.data();
          // MoreDetailsID = doc.id;
          var periodMoveInDate = new Date(firstInfo.moveInPeriod.seconds * 1000);
          var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
          firstInfo.periodMoveIn = formattedDate;
          var combineDetails = { ...firstInfo };
          combineID_Details = { id: docSnap.id, details: combineDetails }
          eyeballStat = combineDetails.eyeballStat;
        // });
      }


      if (user != null) {
        if (user.uid != undefined) {
          if (user.uid != combineID_Details.details.UID) {
            if (combineID_Details.details.viewID == null) {
              eyeballStat[0] = eyeballStat[0] + 1;
              await updateDoc(doc(db, `residentialListingPurchase`, id[2]), {
                viewID: user.uid,
                eyeball: firstInfo.eyeball + 1,
                eyeballStat: eyeballStat
              })
             

            }
            else {
              if (combineID_Details.details.viewID.search(user.uid) >= 0) {
                eyeballStat[0] = eyeballStat[0] + 1;
                await updateDoc(doc(db, `residentialListingPurchase`, id[2]), {
                  eyeball: firstInfo.eyeball + 1,
                  eyeballStat: eyeballStat
                })
              }
              else {
                eyeballStat[0] = eyeballStat[0] + 1;
                await updateDoc(doc(db, `residentialListingPurchase`, id[2]), {
                  viewID: combineID_Details.details.viewID + "|" + user.uid,
                  eyeball: firstInfo.eyeball + 1,
                  eyeballStat: eyeballStat
                })
                
              }
            }
          }

        }
      }
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      setFailLoadPage(true);
    }

    setProject(combineID_Details);
  }
  useEffect(() =>{
    if(failLoadPage == true){
      navigate("/NotAvailable");
    }
  },[failLoadPage])



  const getTime = () => {
    var content = [];
    var today = new Date();
    var diff = Math.abs(today - project.details.timePosted.toDate());
    if ((diff / 1000) <= 60) {
      content.push(
        <p id="detail-left-inner-right">{project.details.hasOwnProperty('modifyBefore') ? "Updated" : "Listed" } {Math.floor(diff / 1000)} seconds ago</p>
      )
    }
    else if (((diff / 1000) / 60) <= 60) {
      content.push(
        <p id="detail-left-inner-right">{project.details.hasOwnProperty('modifyBefore') ? "Updated" : "Listed" } {Math.floor((diff / 1000) / 60)} minute(s) ago</p>
      )
    }
    else if ((((diff / 1000) / 60) / 60) <= 24) {
      content.push(
        <p id="detail-left-inner-right">{project.details.hasOwnProperty('modifyBefore') ? "Updated" : "Listed" } {Math.floor((diff / 1000) / 60 / 60)} hour(s) ago</p>
      )
    }
    else if ((((diff / 1000) / 60) / 60 / 24) <= 31){
      content.push(
        <p id="detail-left-inner-right">{project.details.hasOwnProperty('modifyBefore') ? "Updated" : "Listed" } {Math.floor((((diff / 1000) / 60) / 60) / 24)} day(s) ago</p>
      )

    }
    else if ((((diff / 1000) / 60) / 60 / 24 / 30) <= 12){
      
      content.push(
        <p id="detail-left-inner-right">{project.details.hasOwnProperty('modifyBefore') ? "Updated" : "Listed" } {Math.floor((((diff / 1000) / 60) / 60) / 24 / 30)} month(s) ago</p>
      )

    }
    else {
      // console.log((diff / 1000) / 60 / 60 /24/30)
      content.push(
        <p id="detail-left-inner-right">{project.details.hasOwnProperty('modifyBefore') ? "Updated" : "Listed" } {Math.floor((((diff / 1000) / 60) / 60) / 24 / 30 /12)} year(s) ago</p>
      )
    }
   

    return content;
  }

  const addShortList = async () => {
    if (user != null && project.details.UID != user.uid) {
      await setDoc(doc(db, `userInfo/${user.uid}/shortList`, project.id), {});
      setShortListed(true);
    }
    else {
      alert("Please Sign Up First or This is your post");
    }
  }

  const deteleShortList = async () => {
    if (user != null && project.id != user.uid) {
      await deleteDoc(doc(db, `userInfo/${user.uid}/shortList`, project.id));
      setShortListed(false);
    }
    else {
      alert("Please Sign Up First or This is your post");
    }
  }

  const handleMessage = async () => {
    var allowMessage = true;
    // if (userInfo.hasOwnProperty('propertyAddressList') && userInfo.hasOwnProperty('propertyAddressListResidential')) {
    //   if (userInfo.propertyAddressList.length == 0 && userInfo.propertyAddressListResidential.length == 0) {
    //     allowMessage = false;
    //   }
    // }
    // else if(userInfo.hasOwnProperty('propertyAddressListResidential') == false && userInfo.hasOwnProperty('propertyAddressList'))  {
    //   if (userInfo.propertyAddressList.length == 0) {
    //     allowMessage = false;
    //   }
    // }
    // else if(userInfo.hasOwnProperty('propertyAddressList') == false && userInfo.hasOwnProperty('propertyAddressListResidential')){
    //   if (userInfo.propertyAddressListResidential.length == 0) {
    //     allowMessage = false;
    //   }
    // }
    // else if(userInfo.hasOwnProperty('propertyAddressList') == false && userInfo.hasOwnProperty('propertyAddressListResidential') == false){
    //   allowMessage = false;
    // }

    if (allowMessage) {
    setSpinnerLoad(true);
    var senderName, receiverName;
    senderName = user.displayName;
    receiverName = project.details.username;
    var sellerInfo = await getDoc(doc(db, 'userInfo', project.details.UID));
    sellerInfo = sellerInfo.data();


    var combinedID = user.uid > project.details.UID ? user.uid + project.details.UID : project.details.UID + user.uid;
    combinedID = combinedID > project.id ? combinedID + project.id : project.id + combinedID;

    if (user != null && project.details.UID != user.uid) {
      try {
        const res = await getDoc(doc(db, 'chats', combinedID));
        if (!res.exists()) {
          var chatLeftDoc = await getDoc(doc(db, 'userInfo', user.uid));
          if (userInfo.credit >= 23) {
            await updateDoc(doc(db, 'userInfo', user.uid), {
              credit: chatLeftDoc.data().credit - 23
            })
            // await updateDoc(doc(db, 'projects', project.id), {
            //   pendingChats: project.details.pendingChats + 1
            // })
            var imageURL = project.details.url0;
            await setDoc(doc(db, 'chats', combinedID), {
              messages: [{
                createdAt: new Date(),
                sender: user.uid,
                senderName: user.displayName,
                text: handleMessageTextArea
                // , image: imageURL
              }]
            });
            await getDoc(doc(db, 'publicUserInfo', user.uid)).then(async (publicUserIndiv) => {
              await getDoc(doc(db, 'publicUserInfo', project.details.UID)).then(async (oppPublicUserIndiv) => {
                try {
                  var verifiedPropertyCount = 0;
                  var unverifiedPropertyCount = 0;

                  // if (userInfo.hasOwnProperty('propertyAddressList')) {
                  //   for (var i = 0; i < userInfo.propertyAddressList.length; i++) {
                  //     if (userInfo.propertyAddressList[i].verified == "approve") {
                  //       verifiedPropertyCount++;
                  //     } else {
                  //       unverifiedPropertyCount++;
                  //     }
                  //   }
                  // }

                  // if (userInfo.hasOwnProperty('propertyAddressListResidential')) {
                  //   for (var i = 0; i < userInfo.propertyAddressListResidential.length; i++) {
                  //     if (userInfo.propertyAddressListResidential[i].verified == "approve") {
                  //       verifiedPropertyCount++;
                  //     } else {
                  //       unverifiedPropertyCount++;
                  //     }
                  //   }
                  // }

                  const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
                  if(targetRole == "Agent"){
                    var docData = {
                      [combinedID]: {
                        'userInfo': {
                          agentProfile: publicUserIndiv.data().agentProfile,
                          propertyList: { unverified: unverifiedPropertyCount, verified: verifiedPropertyCount },
                          oppUserType: "Tenant",
                          displayName: oppPublicUserIndiv.data().username,
                          photoURL: sellerInfo.userPhotoURL,
                          recommended: oppPublicUserIndiv.data().hasOwnProperty("recommended") ? oppPublicUserIndiv.data().recommended : null,
                          moveInPeriod: project.details.moveInPeriod,
                          listingName: project.details.listingName,
                          budgetOverall: project.details.budgetOverall,
                          listingID: project.id,
                          statusRead: "Read",
                          status: "Pending",
                          lastMessage: handleMessageTextArea,
                          oppositeUID: project.details.UID,
                          myUID: user.uid,
                          chatType: "ResidentialSales",
                          agentRepresented: project.details.propertyAgent
                        },
                        "date": serverTimestamp()
                      }
                    }
                  }
                  else{
                    var docData = {
                      [combinedID]: {
                        'userInfo': {
                          propertyList: { unverified: unverifiedPropertyCount, verified: verifiedPropertyCount },
                          oppUserType: "Tenant",
                          displayName: oppPublicUserIndiv.data().username,
                          photoURL: sellerInfo.userPhotoURL,
                          recommended: oppPublicUserIndiv.data().hasOwnProperty("recommended") ? oppPublicUserIndiv.data().recommended : null,
                          moveInPeriod: project.details.moveInPeriod,
                          listingName: project.details.listingName,
                          budgetOverall: project.details.budgetOverall,
                          listingID: project.id,
                          statusRead: "Read",
                          status: "Pending",
                          lastMessage: handleMessageTextArea,
                          oppositeUID: project.details.UID,
                          myUID: user.uid,
                          chatType: "ResidentialSales",
                          agentRepresented: project.details.propertyAgent
                        },
                        "date": serverTimestamp()
                      }
                    }
                  }
                  

                  if (!res_userChat.exists()) {


                    await setDoc(doc(db, 'userChat', user.uid), docData)
                  }
                  else {

                    await updateDoc(doc(db, 'userChat', user.uid), docData)
                  }
                }
                catch (err2) {

                }

                try {
                  const res_userChat_2 = await getDoc(doc(db, 'userChat', project.details.UID));
                  if(targetRole == "Agent"){
                    var docData = {
                      [combinedID]: {
                        'userInfo': {
                          agentProfile: publicUserIndiv.data().agentProfile,
                          propertyList: { unverified: unverifiedPropertyCount, verified: verifiedPropertyCount },
                          oppUserType: targetRole,
                          displayName: publicUserIndiv.data().username,
                          photoURL: userInfo.userPhotoURL,
                          listingName: project.details.listingName,
                          moveInPeriod: project.details.moveInPeriod,
                          recommended: publicUserIndiv.data().hasOwnProperty('recommended') ? publicUserIndiv.data().recommended : null,
                          budgetOverall: project.details.budgetOverall,
                          listingID: project.id,
                          statusRead: "Unread",
                          status: "Pending",
                          lastMessage: handleMessageTextArea,
                          myUID: project.details.UID,
                          oppositeUID: user.uid,
                          chatType: "ResidentialSales",
                          agentRepresented: project.details.propertyAgent
                        },
                        "date": serverTimestamp()
                      }
                    }
                  }
                  else{
                    var docData = {
                      [combinedID]: {
                        'userInfo': {
                          propertyList: { unverified: unverifiedPropertyCount, verified: verifiedPropertyCount },
                          oppUserType: "Landlord",
                          displayName: publicUserIndiv.data().username,
                          photoURL: userInfo.userPhotoURL,
                          listingName: project.details.listingName,
                          moveInPeriod: project.details.moveInPeriod,
                          recommended: publicUserIndiv.data().hasOwnProperty('recommended') ? publicUserIndiv.data().recommended : null,
                          budgetOverall: project.details.budgetOverall,
                          listingID: project.id,
                          statusRead: "Unread",
                          status: "Pending",
                          lastMessage: handleMessageTextArea,
                          myUID: project.details.UID,
                          oppositeUID: user.uid,
                          chatType: "ResidentialSales",
                          agentRepresented: project.details.propertyAgent
                        },
                        "date": serverTimestamp()
                      }
                    }
                  }
                  
                  if (!res_userChat_2.exists()) {

                    await setDoc(doc(db, 'userChat', project.details.UID), docData)
                  }
                  else {

                    await updateDoc(doc(db, 'userChat', project.details.UID), docData)
                  }
                }
                catch (err3) {

                }

                if (userInfo.membership == "Enterprise") {
                  // try {
                  //   const res_userChat_3 = await getDoc(doc(db, 'userChat', userInfo.premiumLeader));
                  //   var docData = {
                  //     [combinedID]: {
                  //       'userInfo': {
                  //         uid: user.uid,
                  //         displayName: publicUserIndiv.data().username,
                  //         photoURL: user.photoURL,
                  //         listingName: project.details.listingName,
                  //         listingPriceOverall: project.details.priceOverall,
                  //         nearbyListing:"5 mins away",
                  //         listingID: project.id,
                  //         statusRead: "Unread",
                  //         status: "Pending",
                  //         lastMessage: "",
                  //         senderUID: user.uid,
                  //         chatType: "Residential"
                  //       },
                  //       "date": serverTimestamp()
                  //     }
                  //   }
                  //   if (!res_userChat_3.exists()) {

                  //     await setDoc(doc(db, 'userChat', userInfo.premiumLeader), docData)
                  //   }
                  //   else {

                  //     await updateDoc(doc(db, 'userChat', userInfo.premiumLeader), docData)
                  //   }
                  // }
                  // catch (err3) {

                  // }

                }
                if (oppPublicUserIndiv.data().emailNotificationPending == true) {
                  const requestOptions_1 = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                    body: JSON.stringify({ data: { username: oppPublicUserIndiv.data().username, email: oppPublicUserIndiv.data().email, path: `New Connection` } })
                  };
                  await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                    error => console.log(error)
                  );
                }
                setSpinnerLoad(false);
                setHandleMessageClick(false);
                setHandleMessageTextArea(null);
                setTargetRole(null);
                setAcknowledgeMessage("Your request has been submitted")
              })

            }).catch((error) => {
              console.log(error);
            })

            // await getDoc(doc(db, 'publicUserInfo', project.details.UID)).then(async (emailRes) => {


            // })

          }
          else {
            setSpinnerLoad(false);
            setHandleMessageClick(false);
            setHandleMessageTextArea(null);
            setTargetRole(null);
            setAcknowledgeMessage("You do not have any chat connection left.");
          }
        }
        else {
          setSpinnerLoad(false);
          setHandleMessageClick(false);
          setHandleMessageTextArea(null);
          setTargetRole(null);
          setAcknowledgeMessage("Your request has already been submitted or currently in chat with the tenant")
        }
      }
      catch (err) {

      }


    }
    else {
      // alert("This is your post");
      setSpinnerLoad(false);
      return "yourOwnListing";
    }
    setSpinnerLoad(false);
    return combinedID;
  }
  setSpinnerLoad(false);
  return "noproperty";

  }

  const activateAck = () => {


    // setAcknowledgeMessage("Message have been sent");
    setAcknowledgeNormal(true);

  }
  

  return (
    <>
      {/* <Notification></Notification> */}
      <Spinner trigger={spinnerLoad} setTrigger={setSpinnerLoad}></Spinner>
      <DropAMessage trigger={dropAMessage} setTrigger={setDropAMessage} setHandleMessageClick={setHandleMessageClick} setHandleMessageTextArea={setHandleMessageTextArea} userInfo={userInfo} setTargetRole={setTargetRole} listingDetails ={project} type="residential sale"></DropAMessage>
      <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={acknowledgeMessage}></AcknowledgeNormal>
      <ChangePlan trigger={upgradeTier} setTrigger={setUpgradeTier} currentUserInfo={userInfo}  setCurrentUserInfo={setUserInfo} />
      {project != null ? <Report trigger={report} setTrigger={setReportClick} listing_id={project.id} listingType={"ResidentialPurchase"}/> : null}
      <div id="detail-left">
      <h2 id="ContactTitle"style={{paddingBottom:"20px"}}>Requirements For Residential Purchase</h2>
        {project != null ? <img src={project.details.userImage} id="detailResidentialImg"></img> : null}

        <div style={{ float: "right", width: "80%" }}>
          <p id="residentialListingName" style={{ fontWeight: "bold" }}>{project != null ? project.details.listingName : null}</p>
          <p id="residentialMoveIn">{project != null ? "@" + project.details.username : null}{project != null ? project.details.propertyAgent == true ? " [Agent]" : " [Direct Buyer]":null}</p>
          <p id="residentialDistrict" style={{ fontWeight: "bold" }}>{project != null ? project.details.district.map((indivDistrict) => {
            return (
              <>
                {indivDistrict}
                <br></br>
              </>
            )
          }) : null}</p>

          
          {/* <p id="residentialTenantType">{project != null ? project.details.furnishingType : null}</p> */}
          <p id="residentialMonthly">Budget: ${project != null ? project.details.budgetOverall.toLocaleString() : null}</p>
          <p id="residentialMoveIn">{project != null ? "Moving In Period (Starting From): " + project.details.periodMoveIn : null}</p>
         
        </div>
        <div style={{ clear: 'both' }}></div>

        {project != null && project.details.status == "leasing" ? getTime() : null}
        <div style={{ clear: 'both' }}></div>

        {userInfo != null ? <button className='detailsBtn' onClick={userInfo.credit > 23 ? () => setDropAMessage(true) : () => setUpgradeTier(true)} style={{ marginRight: '1%' }}>Request to connect</button> : null}
        {/* {userInfo != null ? (userInfo.userType).search("Landlord") >= 0 || (userInfo.userType).search("Agent") >= 0 ? <button className='detailsBtn' onClick={() => { handleMessage().then((res) => { res == false ? alert("Cannot request to your listing") :  activateAck()}) }} style={{ marginRight: '1%' }}>Request to connect</button> : null : null} */}
        {user == null ? <button className='detailsBtn' style={{ marginRight: '1%' }}><Link to={"/sign-up"} style={{ textDecoration: "none", color: "white" }}>Login to request to connect</Link></button> : null}
        <button className='detailsBtn' onClick={() => { setReportClick(true) }}>Report</button>

      </div>

      <div id="detail-right" >
        <div id="detail-right-first">
          <h4>Requirements</h4>

          <div id="shortlist">
            {shortListed == true ? <button onClick={() => deteleShortList()}><i id="heartIcon" class="fa-solid fa-heart" id="favouriteHeart"></i> ADD TO SHORTLIST</button> : <button onClick={() => addShortList()}><i id="heartIcon" class="fa-regular fa-heart"></i> ADD TO SHORTLIST</button>}
          </div>
          <div id="share">
            {project != null ?
              <>
                <RWebShare
                  data={{
                    text: "",
                    url: window.location.href,
                    title: project.details.location,
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <button><i class="fa-solid fa-share"></i> SHARE</button>
                </RWebShare>
              </> : null}
          </div>

          <div style={{ clear: 'both' }}></div>
        </div>
        <div class="detail-right-childrow">
          <label class="detail-right-label-left">PROPERTY TYPE</label>
          <label class="detail-right-label-right">Bedroom(s)</label>
          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left">{project != null ? project.details.propertyType : null}</h4>
          <h4 class="detail-right-text-right">
            {project != null ? (
              <>
                {project.details.bedrooms.map((indivBedroom, index) => {
                  return (
                    <>
                      {index === project.details.bedrooms.length - 1 ? (
                        <>{" " + indivBedroom}</>
                      ) : (
                        <>
                          {" " + indivBedroom} |
                        </>
                      )}
                    </>
                  );
                })}
              </>
            ) : null}
          </h4>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div class="detail-right-childrow">
          <label class="detail-right-label-left">MINIMUM FLOOR SIZE</label>
          <label class="detail-right-label-right">MAXIMUM FLOOR SIZE</label>
          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left">{project != null ? project.details.minFloorSize + " SQFT" : null}</h4>
          <h4 class="detail-right-text-right">{project != null ? project.details.maxFloorSize + " SQFT" : null}</h4>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div class="detail-right-childrow">
          <label class="detail-right-label-left">FLOOR LEVEL</label>
          <label class="detail-right-label-right">TENURE</label>
          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left">
            {project != null ? (
              <>
                {project.details.floorLevel.map((indivFloorLevel, index) => {
                  return (
                    <>
                      {index === project.details.floorLevel.length - 1 ? (
                        <>{" " + indivFloorLevel}</>
                      ) : (
                        <>
                          {" " + indivFloorLevel} |
                        </>
                      )}
                    </>
                  );
                })}
              </>
            ) : null}
          </h4>
          <h4 class="detail-right-text-right">
            {project != null ? (
              <>
                {project.details.tenure.map((indivTenure, index) => {
                  return (
                    <>
                      {index === project.details.tenure.length - 1 ? (
                        <>{" " + indivTenure}</>
                      ) : (
                        <>
                          {" " + indivTenure} |
                        </>
                      )}
                    </>
                  );
                })}
              </>
            ) : null}
          </h4>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div class="detail-right-childrow">
          <label class="detail-right-label-left">BALCONY</label>
          <label class="detail-right-label-right">AMENITIES</label>
          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left">{project != null ? project.details.balcony : null}</h4>
          <h4 class="detail-right-text-right">
            {project != null ? (
              <>
                {project.details.amenities.map((indivAmenities, index) => {
                  return (
                    <>
                      {index === project.details.amenities.length - 1 ? (
                        <>{" " + indivAmenities}</>
                      ) : (
                        <>
                          {" " + indivAmenities} |
                        </>
                      )}
                    </>
                  );
                })}
              </>
            ) : null}
          </h4>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div class="detail-right-childrow">
          <label class="detail-right-text-left">SPECIFIC STREET</label>
          <label class="detail-right-label-right" style={{position:"relative"}}>
          <i
              className="fa-solid fa-circle-question"
              style={{ color: "#316190", paddingRight: "5px" }}
              onMouseEnter={() => showAddPropertyQuestionMark()}
              onMouseLeave={() => showAddPropertyQuestionMark()}
              onClick={() => showAddPropertyQuestionMark()}
            /><div
              id={`showAddPropertyQuestionMark`}
              style={{
                display: "none",
                position: "absolute",
                backgroundColor: "#fff",
                padding: "15px",
                border: "1px solid #ccc",
                borderRadius: "10px",
                fontWeight: "normal",
                boxShadow: "0 4px 8px rgba(0,0,0,0.6)"
              }}
            >
              {"This perimeter allows us to match you to your preferred landmarks (e.g. specific school, buildings, etc). Within a 1km radius of your preferred landmark, notifications will be sent to landlords/property agents with properties in the area."}
            </div>PROXIMITY TO</label>
          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left">{project != null ? project.details.location != null  ? project.details.location : "-":"-"}</h4>
          <h4 class="detail-right-text-right">{project != null ? project.details.postalCode != null ? `(S) ${project.details.postalCode}` : "-":"-"}</h4>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div class="detail-right-childrow">
          <label class="detail-right-text-left">ADDITIONAL REMARKS</label>
          <div style={{ clear: 'both' }}></div>
         
          <h4 class="detail-right-text-left" style={{ float: "none", width: "100%" }}>{project != null  ? 
          <div dangerouslySetInnerHTML={{ __html: project.details.additionalRemarks }} /> : "-"}</h4>

        </div>



      </div>
      <div style={{ clear: 'both' }}></div>
      {userInfo != null ? project.details.preferredStatus == true && (userInfo.userType.includes("Landlord") || userInfo.userType.includes("Agent") || userInfo.userType.includes("Master Tenant")) && allowPreferred == false ? <button className='detailsBtn' style={userInfo.membership != null && userInfo.membership != "Inactive" ?{ float: "right", marginRight: "5%", marginBottom:"10px" }:{float:"right", marginRight:"5%", marginBottom:"10px", backgroundColor:"#c1c1c1"}} onClick={userInfo.membership != null && userInfo.membership != "Inactive" ?() => showPreferred():null}>Show Preferred</button> : null : null}
      <div style={{ clear: 'both' }}></div>
      {userInfo != null ? (userInfo.userType.includes("Landlord") || userInfo.userType.includes("Agent") || userInfo.userType.includes("Master Tenant")) && allowPreferred == true && approvedDoubleCheck == true && project.details.UID != user.uid && userInfo.membership == "Premium" ?
        <>
          <button className='detailsBtn' style={{ float: "right", marginRight: "5%" }} onClick={() => setUpgradeTier(true)}>Upgrade to view more</button>
          <div style={{ clear: 'both' }}></div>
          <div id="preferredListingContainer">

            <center style={{ borderBottom: "3px solid black", paddingBottom: "1%" }}><h4>Preferred Listing Details</h4></center>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">Do you need financing</label>
              <label class="preferredListingContainer-label-left">Are you willing to pay COV</label>
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.advancedFinancing}</> : null}</h4>
              <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.advancedCOV}</> : null}</h4>
              <div style={{ clear: 'both' }}></div>
            </div>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">Are you agreeable to purchase with tenancy</label>
              <label class="preferredListingContainer-label-right">This property purchase is for</label>

              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              <h4 class="preferredListingContainer-text-right"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>

              <div style={{ clear: 'both' }}></div>
            </div>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">Do you need to sell your existing property before you can make another purchase</label>

              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>

              <div style={{ clear: 'both' }}></div>
            </div>

          </div>
        </>

        : null : null}

      {userInfo != null ? (userInfo.userType.includes("Landlord") || userInfo.userType.includes("Agent") || userInfo.userType.includes("Master Tenant")) && allowPreferred == true && approvedDoubleCheck == true && project.details.UID != user.uid && userInfo.membership == "Super-Premium" ?
        <>
          {/* <button className='detailsBtn' style={{ float: "right", marginRight: "5%" }} onClick={() => setUpgradeTier(true)}>Upgrade to view more</button> */}
          <div style={{ clear: 'both' }}></div>
          <div id="preferredListingContainer">

            <center style={{ borderBottom: "3px solid black", paddingBottom: "1%" }}><h4>Preferred Listing Details</h4></center>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">Do you need financing</label>
              <label class="preferredListingContainer-label-left">Are you willing to pay COV</label>
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.advancedFinancing}</> : null}</h4>
              <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.advancedCOV}</> : null}</h4>
              <div style={{ clear: 'both' }}></div>
            </div>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">Are you agreeable to purchase with tenancy</label>
              <label class="preferredListingContainer-label-right">This property purchase is for</label>

              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.purchaseTenancy}</> : null}</h4>
              <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.purposeOfProperty}</> : null}</h4>

              <div style={{ clear: 'both' }}></div>
            </div>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">Do you need to sell your existing property before you can make another purchase</label>

              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>

              <div style={{ clear: 'both' }}></div>
            </div>

          </div>
        </>

        : null : null}


      {userInfo != null ? userInfo.membership == "admin" || (userInfo.membership == "Enterprise" && approvedDoubleCheck == true && allowPreferred == true) || (approvedDoubleCheck == true && project.details.UID == user.uid) ?
        <div id="preferredListingContainer">
          <center style={{ borderBottom: "3px solid black", paddingBottom: "1%" }}><h4>Preferred Listing Details</h4></center>
          <div class="preferredListingContainer_child">
            <label class="preferredListingContainer-label-left">Do you need financing</label>
            <label class="preferredListingContainer-label-right">Are you willing to pay COV</label>
            <div style={{ clear: 'both' }}></div>
            <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.advancedFinancing}</> : null}</h4>
            <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.advancedCOV}</> : null}</h4>
            <div style={{ clear: 'both' }}></div>
          </div>
          <div class="preferredListingContainer_child">
            <label class="preferredListingContainer-label-left">Are you agreeable to purchase with tenancy</label>
            <label class="preferredListingContainer-label-right">This property purchase is for</label>

            <div style={{ clear: 'both' }}></div>
            <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.purchaseTenancy}</> : null}</h4>
            <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.purposeOfProperty}</> : null}</h4>

            <div style={{ clear: 'both' }}></div>
          </div>
          <div class="preferredListingContainer_child">
            <label class="preferredListingContainer-label-left">Do you need to sell your existing property before you can make another purchase</label>


            <div style={{ clear: 'both' }}></div>
            <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.advancedSellExisting}</> : null}</h4>


            <div style={{ clear: 'both' }}></div>
          </div>

        </div>

        : null : null}


      <Footer />

    </>
  )
}