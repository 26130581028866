import React, { useState } from 'react';
import '../css/NewProject.css';
import '../css/Report.css'
import { UserAuth } from '../../AuthContent';
import emailjs from 'emailjs-com';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import { getDoc, setDoc, updateDoc, doc } from 'firebase/firestore';
import { db, auth } from '../../firebase/firebase'

export default function NewProject(props) {
    const { user } = UserAuth();
    const [message, setMessage] = useState('');
    const [showAck, setShowAck] = useState(false);
    const [topicReport, setTopicReport] = useState("Fraud");

    if (!props.trigger) {
        return;
    }
    else {
        const sendReport = async () => {
            if (user != null) {
                if (document.getElementById('reportMessage').value != "") {
                    await getDoc(doc(db, 'reportChat', props.navID)).then(async (IndivReportListing) => {
                        
                        if (IndivReportListing.exists()) {
                            const date = new Date();
                            const formattedDate = date.toISOString().split('.')[0];
                            await updateDoc(doc(db, 'reportChat', props.navID), {
                                [user.uid + "_" + (formattedDate)]: {
                                    reportDate: new Date(),
                                    reportMessage: document.getElementById('reportMessage').value,
                                    emailFrom: user.email,
                                    reportTopic: topicReport,
                                    oppositePartyEmail: props.oppositePublicInfo.email
                                }
                            }).then(() => {
                                setMessage("Thank you! Our team will look into it");
                                setShowAck(true);
                                setTopicReport("Fraud");
                            })
                        }
                        else {
                            const date = new Date();
                            const formattedDate = date.toISOString().split('.')[0];
                            await setDoc(doc(db, 'reportChat', props.navID), {
                                [user.uid + "_" + (formattedDate)]: {
                                    reportDate: new Date(),
                                    reportMessage: document.getElementById('reportMessage').value,
                                    emailFrom: user.email,
                                    reportTopic: topicReport,
                                    oppositePartyEmail: props.oppositePublicInfo.email
                                }
                            }).then(() => {
                                setMessage("Thank you! Our team will look into it");
                                setShowAck(true);
                                setTopicReport("Fraud");
                            })
                        }
                    })
                }
                else {
                    alert("Invalid Response");
                    return;
                }


            }
            else {
                setMessage("Kindly log in to transmit a report");
                setShowAck(true);
            }
        }

        const closeReportChat = () =>{
            props.setTrigger(false);
            setTopicReport("Fraud");
        }
        
        const handleChangeReportTopic = (e) =>{
            setTopicReport(e.target.value);
        }

        return (
            <>
                <AcknowledgeNormal trigger={showAck} setTrigger={setShowAck} message={message} parentTrigger={props.setTrigger} />
                <div className='modalNewProject'>
                    <div className='modal-contentNewProject'>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => closeReportChat()}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>REPORT</h4>
                        </div>
                        <div className='modal-bodyNewProject'>
                            <div style={{display:"flex", flexDirection:"row", padding:"5px"}}>
                            <p style={{padding:"5px 5px"}}>Topic: </p>
                            <select value={topicReport} onChange={handleChangeReportTopic} style={{width:"100%", borderRadius:"10px", padding:"5px 5px", border:"1px solid #c0c0c0"}}>
                                <option value="Fraud">Fraud</option>
                                <option value="Misrepresentation">Misrepresentation</option>
                                <option value="Others">Others</option>
                            </select>
                            </div>
                            <center><textarea id="reportMessage" style={{ padding: "5px", border:"1px solid #c0c0c0", borderRadius:"10px" }} placeholder=' State what is this report about?' /></center>
                        </div>

                        <div className='modal-footerNewProject'>
                            {/* <button className='reportBtn' onClick={() => props.setTrigger(false)}>Close</button> */}
                            <button className='reportBtn' onClick={() => sendReport()} style={{ marginRight: '1%' }}>Report</button>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

