import React, { useState } from 'react';
import '../css/NewProject.css';
import { UserAuth } from '../../AuthContent';
import { updateDoc } from 'firebase/firestore';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import { collection, getDocs, onSnapshot, doc, arrayUnion, serverTimestamp, deleteDoc, getDoc, query, where, deleteField, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebase'

export default function RedeemCode(props) {
    const { logOut, user } = UserAuth();
    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
    const [acknowledgeMessage, setAcknowledgeMessage] = useState("");
    const [promotionCodeInput, setPromotionCodeInput] = useState('');

    const confirmPromotionCode = async () => {
        if (promotionCodeInput == '') {
            setPromotionCodeInput('');
            setAcknowledgeMessage("Invalid Promotion Code");
            setAcknowledgeNormal(true);
        }
        else {
            var promoCodeData = await getDoc(doc(db, 'promotionCode', 'instagramPromo'));
            promoCodeData = promoCodeData.data();
            var launchedPromotionCode = [...props.currentUserInfo.launchedPromotionCode];
            var transactionHistory = [...props.currentUserInfo.transactionHistory];
            if (promoCodeData.code == promotionCodeInput) {
                if (launchedPromotionCode.includes(promotionCodeInput)) {
                    setPromotionCodeInput('');
                    setAcknowledgeMessage("This code has already been redeemed.");
                    setAcknowledgeNormal(true);
                }
                else {
                    if(transactionHistory.length > 30){
                        transactionHistory.shift();
                        transactionHistory.push({
                            "Actions": `+${promoCodeData.credits} Credits`,
                            "Activity": `Rewards ${promoCodeData.code}`,
                            "Date": new Date(),
                            "Type": "Points Activity"
                        })
                    }
                    else{
                        transactionHistory.push({
                            "Actions": `+${promoCodeData.credits} Credits`,
                            "Activity": `Rewards ${promoCodeData.code}`,
                            "Date": new Date(),
                            "Type": "Points Activity"
                        })
                    }
                    launchedPromotionCode.push(promotionCodeInput)
                    await updateDoc(doc(db, 'userInfo', props.currentUserInfo.uid), {
                        credit: props.currentUserInfo.credit + promoCodeData.credits,
                        launchedPromotionCode: launchedPromotionCode,
                        transactionHistory: transactionHistory
                    }).then(async () => {
                        await updateDoc(doc(db, 'promotionCode', 'instagramPromo'), {
                            count: promoCodeData.count + 1
                        }).then(() => {
                            setPromotionCodeInput('');
                            setAcknowledgeMessage("Redemption Successful");
                            setAcknowledgeNormal(true);
                        })

                    })
                }

            }
            else {
                setPromotionCodeInput('');
                setAcknowledgeMessage("Invalid Promotion Code");
                setAcknowledgeNormal(true);
            }
        }
    }
    if (!props.trigger) {
        return;
    }
    else {

        const closeAcknowledge = async () => {
            setPromotionCodeInput('');
            props.setTrigger(false);
        }


        return (
            <>
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={acknowledgeMessage} parentTrigger={props.setTrigger}></AcknowledgeNormal>
                <div className='modalNewProject' style={{ zIndex: "10" }}>
                    <div className='modal-contentAcknowledge'>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => closeAcknowledge()}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>Redeem Promotion Code</h4>
                        </div>
                        <div class='modal-acknowledge' style={{ textAlign: "left" }}>

                            <input id="usernameInput" style={{ width: "97%", padding: "8px 10px", border: "1px solid #ccc", borderRadius: "10px", cursor: "pointer", marginBottom: "2%", marginLeft: "1%" }}
                                placeholder='Key in promotion code'
                                value={promotionCodeInput}
                                onChange={(e) => setPromotionCodeInput(e.target.value)}
                            ></input>

                        </div>
                        <div className='modal-footerNewProject'>
                            <button className='newListingBtn' onClick={() => confirmPromotionCode()}>Confirm</button>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </div>

            </>
        );
    }

}
