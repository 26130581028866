import React from 'react';
import '../css/NewProject.css';
import { UserAuth } from '../../AuthContent';

export default function Acknowledge(props) {
  const {logOut } = UserAuth();
  if (!props.trigger) {
    return;
  }
  else {

    const closeAcknowledge = async () => {
      window.location.reload(true);
    }

    const signupCompleted = async () => {
      try {
          // await logOut();
          window.location.reload();
        } catch (error) {
        }
    }

    return (
      <>
        <div className='modalNewProject' style={{zIndex:"999"}}>
          <div className='modal-contentAcknowledge'>
            <div className='modal-headerNewProject'>
              <h4 className='modal-titleNewProject'>ACKNOWLEDGEMENT</h4>
            </div>
            <div class='modal-acknowledge'>
              <p>{props.message}</p>

            </div>
            <div className='modal-footerNewProject'>
              {props.parent == "UserType" ? <button className='newListingBtn' onClick={() => signupCompleted()}>Close</button> : <button className='newListingBtn' onClick={() => closeAcknowledge()}>Close</button>}
              <div style={{ clear: 'both' }}></div>
            </div>
          </div>
        </div>

      </>
    );
  }

}
