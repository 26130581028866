import React, { useRef, useEffect, useState } from 'react';
import '../../App.css';
import '../css/Contact.css'
import { db, storage } from '../../firebase/firebase';
import { onSnapshot, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes, listAll, getStorage, deleteObject, uploadString, getMetadata } from 'firebase/storage';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import '../css/FAQ.css';
import Footer from '../Footer';
import Notification from './Notification';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import Spinner from './Spinner';




function Contact() {

  const chats = useRef(null);
  const { user, deleteUserData } = UserAuth();
  let unsbscribe;
  const [topicFeedback, setTopicFeedback] = useState("General feedback");
  const [topicFeedbackOthers, setTopicFeedbackOthers] = useState("");
  const [formName, setFormName] = useState("");
  const [formOrganisation, setFormOrganisation] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formMobile, setFormMobile] = useState("");
  const [formMessage, setFormMessage] = useState("");
  const uploadFileRef = useRef(null);
  const [fileUpload, setFileUpload] = useState(null);
  const MAX_CHARACTERS = 1000;
  const [spinnerActive, setSpinnerActive] = useState(false);
  const [message, setMessage] = useState("");
  const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  const submitForm = async () => {
    var emailTopic = topicFeedback;
    var uploadPromises = [];
    if (formName == "" || formEmail == "" || formMobile == "" || formMessage == "") {
      alert("Please fill in all the required details");
      return;
    }
    else {
      setSpinnerActive(true);
      // if (topicFeedback == "Others") {
      //   if (topicFeedbackOthers == "") {
      //     alert("Please fill in all the required details");
      //   }
      //   else {
      //     emailTopic = topicFeedbackOthers;
      //   }

      // }
      emailTopic = 'Deletion of Account'

      if (fileUpload != null) {
        var ID = 0;
        var url = "";
        const uploadPromise = new Promise(async (resolve, reject) => {
          const reader = new FileReader();


          reader.onload = async () => {
            const docRef = doc(db, 'commonData', 'FormNumber');
            const docSnapshot = await getDoc(docRef);
            ID = parseInt(docSnapshot.data().ID);
            try {
              const storageRef = ref(storage, `contactForm/nextloop_${ID}/${fileUpload.name}`);
              const uploadTaskSnapshot = await uploadString(storageRef, reader.result, 'data_url');
              const documentURL = await getDownloadURL(uploadTaskSnapshot.ref);
              url = documentURL;
              const data = {
                email: "kas@nextloop.sg",
                topic: emailTopic,
                name: formName,
                organisation: formOrganisation,
                mobile: formMobile,
                senderEmail: formEmail,
                message: formMessage,
                supportingDocuments: url
              };
              resolve(data);
            } catch (error) {
              console.log('Error uploading file:', error);
              reject(error);
            }
          };

          reader.onerror = (error) => {
            console.log('Error reading file:', error);
            reject(error);
          };

          reader.readAsDataURL(fileUpload);
        });

        uploadPromises.push(uploadPromise);

        Promise.all(uploadPromises)
          .then(async () => {
            await updateDoc(doc(db, 'commonData', 'FormNumber'), {
              ID: ID + 1
            }).then(async () => {
              const requestOptions_1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                  {
                    data:
                    {
                      email: `info@nextloop.sg`,
                      topic: `${emailTopic} [Ticket Number: nextloop_${ID}]`,
                      name: `${formName}`,
                      organisation: formOrganisation,
                      mobile: formMobile,
                      senderEmail: formEmail,
                      message: formMessage,
                      supportingDocuments: url

                    }
                  })
              };
              await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/contactEmail', requestOptions_1).then(() => {
                setSpinnerActive(false);
                setTopicFeedback("General feedback");
                setTopicFeedbackOthers("");
                setFormName("");
                setFormOrganisation("");
                setFormEmail("");
                setFormMobile("");
                setFormMessage("");
                setFileUpload(null);
                setAcknowledgeNormal(true);

                setMessage("Thank you for your inquiries. Our team will reach out to you within the next 2-3 business days.")
              }).then(
                error => console.log(error)
              );

            })
          })
          .catch((error) => {
            console.log('Error:', error);
          });
      }
      else {
        // var ID = 0;
        // var url = "";
        // const docRef = doc(db, 'commonData', 'FormNumber');
        // const docSnapshot = await getDoc(docRef);
        // ID = parseInt(docSnapshot.data().ID);
        const requestOptions_1 = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(
            {
              data:
              {
                email: `info@nextloop.sg`,
                topic: `${emailTopic}`,
                name: `${formName}`,
                organisation: 'Deletion of Account',
                mobile: formMobile,
                senderEmail: formEmail,
                message: formMessage,
                supportingDocuments: "No Document attached"
              }
            })
        };
        await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/contactEmail', requestOptions_1).then(() => {
          setSpinnerActive(false);
          setTopicFeedback("General feedback");
          setTopicFeedbackOthers("");
          setFormName("");
          setFormOrganisation("");
          setFormEmail("");
          setFormMobile("");
          setFormMessage("");
          setFileUpload(null);
          setAcknowledgeNormal(true);

          setMessage("Thank you for your inquiries. Our team will reach out to you within the next 2-3 business days.")
        }).then(
          error => console.log(error)
        );

        
      }

    }

    // 

  }

  const handleFormMessage = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS) {
      setFormMessage(e.target.value);
    }
  }

  useEffect(() => {
    const checkingUser = async () => {
      var docsnap = await getDoc(doc(db, 'userInfo', user.uid));
      if (docsnap.exists()) {
      }
      else {
        // alert("hacked your way through ah");
        deleteUserData();

      }
    }
    if (user != null) {
      if (Object.keys(user).length != 0) {
        checkingUser();
      }
    }
  }, [user])

  return (
    <>
      {/* <Notification></Notification> */}
      <Spinner trigger={spinnerActive} setTrigger={setSpinnerActive}></Spinner>
      <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={message}></AcknowledgeNormal>
      {/* <ReactNotifications /> */}
      <div>

        <div className='ContactUsDiv' style={{ color: "black" }}>
          <p id="ContactTitle" >Account Deletion</p>
          <p id="ContactSubtitle">Sad to see you leaving the platform! Do let us know where we can improve</p>

          {/* <div className='ContactUsLeft'>
            <p style={{ marginTop: "3%" }} className='contactFormLabel'>Kindly let us know the type of feedback or enquiry</p>
            <select className='contactFormInput' value={topicFeedback} onChange={(e) => { setTopicFeedback(e.target.value) }}>
              <option value="General feedback">General feedback</option>
              <option value="Suggestions">Suggestions</option>
              <option value="Subscription plans">Subscription plans</option>
              <option value="Technical support">Technical support</option>
              <option value="Others">Others</option>
            </select>
            {topicFeedback == "Others" ? <><p className='contactFormLabel'>Others</p>
              <input className='contactFormInput' value={topicFeedbackOthers} onChange={(e) => { setTopicFeedbackOthers(e.target.value) }}></input></> : null}
          </div> */}
          <div className='ContactUsRight'>
            <p className='contactFormLabel'>Your name</p>
            <input className='contactFormInput' placeholder='Full name' value={formName} onChange={(e) => { setFormName(e.target.value) }}></input>
            {/* <p className='contactFormLabel'>Organisation</p>
            <input className='contactFormInput' placeholder='Company Name' value={formOrganisation} onChange={(e) => { setFormOrganisation(e.target.value) }}></input> */}
            <p className='contactFormLabel'>Mobile Number</p>
            <input className='contactFormInput' placeholder='+65 1234 1234' value={formMobile} onChange={(e) => { setFormMobile(e.target.value) }}></input>
            {/* <p className='contactFormLabel'>Email</p>
            <input className='contactFormInput' placeholder='yourEmail@provider.com' value={formEmail} onChange={(e) => { setFormEmail(e.target.value) }}></input> */}
          </div>
          {/* <div style={{ clear: 'both' }}></div> */}
          <div className='ContactUsLeft'>
            <p className='contactFormLabel'>Email</p>
            <input className='contactFormInput' placeholder='yourEmail@provider.com' value={formEmail} onChange={(e) => { setFormEmail(e.target.value) }}></input>
            <p className='contactFormLabel'>Message</p>
            <textarea className='contactFormInputForm' placeholder='Explain your situation to us' value={formMessage} onChange={handleFormMessage}></textarea>
            <span className='characterCount'>{formMessage != null ? MAX_CHARACTERS - formMessage.length : MAX_CHARACTERS} characters</span>
          </div>
          {/* <div className='ContactUsRight'>
            <p className='contactFormLabel'>Supporting Document</p>
            <button class="defaultUploadBtn" onClick={() => { uploadFileRef.current.click() }}>Supporting Document</button>
            <input
              ref={uploadFileRef}
              id="file-upload"
              type="file"
              accept=".pdf, .jpg, .jpeg, .png"
              style={{ display: "none" }}
              onChange={(event) => { setFileUpload(event.target.files[0]) }}
            />
            <br></br>
            <span className='characterCount'>Accept PDF, JPG, and PNG Documents</span>
          </div> */}



          <div style={{ clear: 'both' }}></div>
          <button id="contactSubmitBtn" onClick={() => { submitForm() }}>Submit</button>
        </div>
        <div style={{ clear: 'both' }}></div>
        <div className='addressDiv'>
          <center><h2 style={{ paddingTop: "2%" }}>Corporate Address</h2></center>
          <center style={{ lineHeight: "1.5", paddingTop: "2%", paddingBottom: "2%" }}>Nextloop Pte Ltd<p>170 Upper Bukit Timah Rd<br></br>#B2-10<br></br>Singapore 588179</p></center>
        </div>
        {/* <div className='ContactUsDiv' style={{ color: "black" }}>
        
          <img src='./map.png' id="contactUsMap"></img>
          <div id="contactUsGE">
            <h2>General Enquiries</h2>
            <p style={{ paddingTop: "3%" }}>Have a question? Send us an email at</p>
            <button className='contactUsBtn' style={{marginTop:"2%"}}><i className="fas fa-arrow-right" /> <a href="mailto:info@nextloop.sg">info@nextloop.sg</a></button>
          </div>                   
          <div id="contactUsGE">
            <h2>Support</h2>
            <p style={{ paddingTop: "3%" }}>Need technical support? Our team is here to assist you at</p>
            <button className='contactUsBtn' style={{marginTop:"2%"}}><i className="fas fa-arrow-right" /> <a href="mailto:support@nextloop.sg">support@nextloop.sg</a></button>
          </div>
          <div style={{ clear: 'both' }}></div>
        </div> */}




      </div>
      <div style={{ clear: 'both' }}></div>
      <Footer />
    </>


  )
}

export default Contact;
