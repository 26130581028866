import React from 'react';
import { motion } from 'framer-motion';
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";


// Carousel Component for App Screenshots
const AppScreenshotsCarousel = () => {
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const screenshots = [
        'https://via.placeholder.com/250x500?text=Screenshot+1',
        'https://via.placeholder.com/250x500?text=Screenshot+2',
        'https://via.placeholder.com/250x500?text=Screenshot+3',
        'https://via.placeholder.com/250x500?text=Screenshot+4',
    ];

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % screenshots.length);
    };

    const handlePrev = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + screenshots.length) % screenshots.length
        );
    };

    return (
        <div className="relative flex justify-center">
            <motion.img
                src={screenshots[currentIndex]}
                alt={`Screenshot ${currentIndex + 1}`}
                className="w-64 h-128 object-cover rounded-lg shadow-md"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            />
            <button
                onClick={handlePrev}
                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
            >
                &#8592;
            </button>
            <button
                onClick={handleNext}
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
            >
                &#8594;
            </button>
        </div>
    );
};

// KebunBaruHome Page
const KebunBaruHome = () => {
    return (
        <div>
            <motion.div
                className=""
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <div className='w-full h-96'>
                    <img src="https://firebasestorage.googleapis.com/v0/b/letsprop-6ae81.appspot.com/o/NextLoop%20%40%20Kebun%20Baru%20Website.png?alt=media&token=eb0db72f-b817-4f91-8e7a-eaeb1447edc3" alt={"Kebun Baru Community"} className="w-full h-96 object-cover" />

                </div>
            </motion.div>
            <div className="space-y-16 px-4 py-8">
                {/* Banner Section */}

                <motion.div
                    className=""
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                >
                    <div class="flex justify-between items-end w-full p-4 lg:px-40">
                        <div>
                            <div class="grid grid-rows-2 grid-flow-col gap-4">
                                <h4 className="text-xl mb-4">Welcome to</h4>
                                <h2 className="text-4xl font-bold mb-4">Kebun Baru</h2>
                            </div>
                        </div>
                        <div class="flex flex-col items-end">
                            <p className="text-lg">Powered by <img className="h-8 w-auto" src="https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800" alt="" /></p>
                        </div>
                    </div>

                </motion.div>

                <motion.div>
                <div className="w-full h-auto bg-custom-grey rounded-lg shadow-lg">
  <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
    <div className="h-auto md:h-96 col-span-1 md:col-span-2">
      <img
        className="w-full h-auto md:h-full object-cover rounded-lg"
        src="https://firebasestorage.googleapis.com/v0/b/letsprop-6ae81.appspot.com/o/Nextloop%20IG%20Story%20(Flyer%20(A4)).png?alt=media&token=fb22fb5f-523b-4d48-843b-8fb29ace4872"
        alt=""
      />
    </div>

    <div className="col-span-1 md:col-span-3 grid grid-rows-4 grid-flow-col gap-4 text-white p-4 md:p-8">
      <h2 className="text-2xl md:text-4xl font-bold mb-4 sm:text-lg">Join Kebun Baru group on NextLoop</h2>

      <h4 className="text-lg md:text-xl font-semibold mb-4 flex items-center sm:text-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="h-4 w-4 text-custom-green mr-2"
        >
          <path
            fillRule="evenodd"
            d="M15 8c0 .982-.472 1.854-1.202 2.402a2.995 2.995 0 0 1-.848 2.547 2.995 2.995 0 0 1-2.548.849A2.996 2.996 0 0 1 8 15a2.996 2.996 0 0 1-2.402-1.202 2.995 2.995 0 0 1-2.547-.848 2.995 2.995 0 0 1-.849-2.548A2.996 2.996 0 0 1 1 8c0-.982.472-1.854 1.202-2.402a2.995 2.995 0 0 1 .848-2.547 2.995 2.995 0 0 1 2.548-.849A2.995 2.995 0 0 1 8 1c.982 0 1.854.472 2.402 1.202a2.995 2.995 0 0 1 2.547.848c.695.695.978 1.645.849 2.548A2.996 2.996 0 0 1 15 8Zm-3.291-2.843a.75.75 0 0 1 .135 1.052l-4.25 5.5a.75.75 0 0 1-1.151.043l-2.25-2.5a.75.75 0 1 1 1.114-1.004l1.65 1.832 3.7-4.789a.75.75 0 0 1 1.052-.134Z"
            clipRule="evenodd"
          />
        </svg>
        A platform to bring neighbours together to rekindle the kampung spirit
      </h4>

      <h4 className="text-lg md:text-xl font-semibold mb-4 flex items-center sm:text-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="h-4 w-4 text-custom-green mr-2"
        >
          <path
            fillRule="evenodd"
            d="M15 8c0 .982-.472 1.854-1.202 2.402a2.995 2.995 0 0 1-.848 2.547 2.995 2.995 0 0 1-2.548.849A2.996 2.996 0 0 1 8 15a2.996 2.996 0 0 1-2.402-1.202 2.995 2.995 0 0 1-2.547-.848 2.995 2.995 0 0 1-.849-2.548A2.996 2.996 0 0 1 1 8c0-.982.472-1.854 1.202-2.402a2.995 2.995 0 0 1 .848-2.547 2.995 2.995 0 0 1 2.548-.849A2.995 2.995 0 0 1 8 1c.982 0 1.854.472 2.402 1.202a2.995 2.995 0 0 1 2.547.848c.695.695.978 1.645.849 2.548A2.996 2.996 0 0 1 15 8Zm-3.291-2.843a.75.75 0 0 1 .135 1.052l-4.25 5.5a.75.75 0 0 1-1.151.043l-2.25-2.5a.75.75 0 1 1 1.114-1.004l1.65 1.832 3.7-4.789a.75.75 0 0 1 1.052-.134Z"
            clipRule="evenodd"
          />
        </svg>
        Subsidised activities to spark and nurture community participation
      </h4>

      {/* Flex container for buttons with responsive stacking */}
      <div className="flex flex-col md:flex-row gap-4">
        <GooglePlayButton
          url="https://play.google.com/store/apps/details?id=com.mycompany.nextlabor&hl=en-SG"
          theme="dark"
          className="bg-black text-white p-4 rounded-md text-sm sm:text-base"
        />
        <AppStoreButton
          url="https://apps.apple.com/sg/app/nextloop-rental-marketplace/id6535674656"
          theme="dark"
          className="bg-black text-white p-4 rounded-md text-sm sm:text-base"
        />
      </div>
    </div>
  </div>
</div>


                </motion.div>

                {/* Cards Section */}
                <motion.div
                    className="flex justify-center items-center w-full"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 justify-center">
                        <div className="w-80 p-6 bg-white rounded-lg shadow-lg flex flex-col items-center text-center">
                            <img src="https://firebasestorage.googleapis.com/v0/b/letsprop-6ae81.appspot.com/o/2.png?alt=media&token=8f9007f2-7f74-4a40-a017-250b76718d2b" alt="Community & Recreational Facilities" className="w-32 h-32 rounded-full mb-4 object-cover" />
                            <h3 className="text-xl font-semibold mb-2">Community & Recreational Facilities</h3>
                            <p className="text-sm text-gray-600">
                                <p>Kebun Baru Community Club</p>
                                <p>Ang Mo Kio Town Garden West</p>
                                <p>Kebun Baru Birdsinging Club</p>
                                <p>Lower Seletar Reservoir Park</p>
                                <p>Upper Seletar Reservoir Park</p>
                            </p>
                        </div>

                        <div className="w-80 p-6 bg-white rounded-lg shadow-lg flex flex-col items-center text-center">
                            <img src="https://firebasestorage.googleapis.com/v0/b/letsprop-6ae81.appspot.com/o/3.png?alt=media&token=65ce6a12-0750-401f-8d51-8d4e30b6d709" alt="Community & Recreational Facilities" className="w-32 h-32 rounded-full mb-4 object-cover" />
                            <h3 className="text-xl font-semibold mb-2">Markets & Food Centers</h3>
                            <p className="text-sm text-gray-600">
                                <p>Kebun Baru Market & Food Center</p>
                                <p>Mayflower Market & Food Centre</p>
                            </p>
                        </div>

                        <div className="w-80 p-6 bg-white rounded-lg shadow-lg flex flex-col items-center text-center">
                            <img src="https://firebasestorage.googleapis.com/v0/b/letsprop-6ae81.appspot.com/o/4.png?alt=media&token=f9c1b661-9cd4-4c28-9ee4-1003b7ffdb22" alt="Community & Recreational Facilities" className="w-32 h-32 rounded-full mb-4 object-cover" />
                            <h3 className="text-xl font-semibold mb-2">Retail</h3>
                            <p className="text-sm text-gray-600">
                                <p>Mayflower Mall</p>
                            </p>
                        </div>

                        <div className="w-80 p-6 bg-white rounded-lg shadow-lg flex flex-col items-center text-center">
                            <img src="https://firebasestorage.googleapis.com/v0/b/letsprop-6ae81.appspot.com/o/5.png?alt=media&token=2190211b-f028-435e-9c01-eed30204a2bc" alt="Community & Recreational Facilities" className="w-32 h-32 rounded-full mb-4 object-cover" />
                            <h3 className="text-xl font-semibold mb-2">Educational Institutions</h3>
                            <p className="font-semibold text-gray-600">Primary Schools</p>
                            <p className="text-sm text-gray-600">
                                <p>CHIJ St. Nicholas Girls' School (Primary)</p>
                            </p>
                            <p className="font-semibold text-gray-600 pt-8">Secondary Schools</p>
                            <p className="text-sm text-gray-600">
                                <p>Mayflower Secondary School</p>
                                <p>CHIJ St. Nicholas Girls' School (Secondary)</p>
                            </p>
                        </div>

                        <div className="w-80 p-6 bg-white rounded-lg shadow-lg flex flex-col items-center text-center">
                            <img src="https://firebasestorage.googleapis.com/v0/b/letsprop-6ae81.appspot.com/o/6.png?alt=media&token=cf3f068e-70b4-43fa-b084-bf888f586acf" alt="Community & Recreational Facilities" className="w-32 h-32 rounded-full mb-4 object-cover" />
                            <h3 className="text-xl font-semibold mb-2">Playgrounds</h3>
                            <p className="text-sm text-gray-600">
                                <p>Ang Mo Kio Town Garden West</p>
                                <p>Fudu Walk Playground</p>
                                <p>Teacher's Estate Playground</p>
                                <p>Thomson Green Playground</p>
                            </p>
                        </div>

                        <div className="w-80 p-6 bg-white rounded-lg shadow-lg flex flex-col items-center text-center">
                            <img src="https://firebasestorage.googleapis.com/v0/b/letsprop-6ae81.appspot.com/o/7.png?alt=media&token=4dfa0ed2-2118-4186-a0b4-f572a945b8ba" alt="Community & Recreational Facilities" className="w-32 h-32 rounded-full mb-4 object-cover" />
                            <h3 className="text-xl font-semibold mb-2">Transportation</h3>
                            <p className="font-semibold text-gray-600">Mass Rapid Transit (MRT)</p>
                            <p className="text-sm text-gray-600">
                                <p>Mayflower MRT Station (Part of the Thomson-East Coast Line)</p>
                            </p>
                            <p className="font-semibold text-gray-600 pt-8">Bus Services</p>
                            <p className="text-sm text-gray-600">
                                <p>Service 71</p>
                                <p>Service 76</p>
                                <p>Service 132</p>
                                <p>Service 165</p>
                                <p>Service 166</p>
                                <p>Service 169</p>
                                <p>Service 265</p>
                                <p>Service 268</p>
                                <p>Service 269</p>
                                <p>Service 652</p>
                            </p>
                        </div>



                    </div>
                </motion.div>


                {/* Download Section */}
                {/* <motion.div
                    className="text-center space-y-8"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 1 }}
                >
                    <h2 className="text-3xl font-bold">Download Our App</h2>
                    <div className="flex justify-center gap-6">
                        <GooglePlayButton
                            url={'https://play.google.com/store/apps/details?id=com.mycompany.nextlabor&hl=en-SG'}
                            theme={"light"}
                            className={"custom-style"}
                        />

                        <AppStoreButton
                            url={'https://apps.apple.com/sg/app/nextloop-rental-marketplace/id6535674656'}
                            theme={"light"}
                            className={"custom-style"}
                        />

                    </div>

                   
                    <h3 className="text-xl font-semibold mb-4">See our app in action</h3>
                    <AppScreenshotsCarousel />
                </motion.div> */}
            </div>
        </div>
    );
};

export default KebunBaruHome;
