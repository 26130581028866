import React, { useEffect, useRef, useState } from 'react';
import { UserAuth } from '../../AuthContent';
import { doc, setDoc, getDoc, collection, addDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import Acknowledge from './Acknowledge';
import '../css/UserTypeModal.css';


export default function Verification(props) {



    if (!props.trigger) {
        return;
    }
    else {



        return (
            <>
                <div className='modalNewProject' style={{ zIndex: "999" }}>
                    <div className='modal-contentNewProject'>
                        <div id="roleTab">
                            <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => props.setTrigger(false)}>
                                ×
                            </div>
                                <h4 className='modal-titleNewProject'>VERIFICATION OF EMAIL</h4>
                            </div>

                            <div className='modal-bodyNewProject'>
                                <center>
                                    <p>Please verify your email before proceeding. An email has been send to the particular email that you have signed up with. Please check the spam/junk box if you cannot find it. Refresh the web application once you have verify</p>
                                </center>
                            </div>

                            <div className='modal-footerNewProject'>

                            </div>
                        </div>
                    </div>
                </div>


            </>
        )
    }

}