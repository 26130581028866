import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import '../css/NewProject.css';
import '../css/Boost.css';
import { UserAuth } from '../../AuthContent';
import timeunit from 'timeunit';
import GooglePayButton from "@google-pay/button-react";
import { db } from '../../firebase/firebase'
import { collection, doc, onSnapshot, getDocs, updateDoc, getDoc, setDoc, deleteField } from 'firebase/firestore';
import axios from 'axios';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import PaypalPayment from './PaypalPayment';
import Spinner from './Spinner';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import PromoPageAdvertise from './PromoPageAdvertise';
import AcknowledgeAdvertisementDates from './AcknowledgeAdvertisementDates';

export default function AdvertiseAction(props) {
    const { user } = UserAuth();
    const [showCalendar, setShowCalendar] = useState(false);
    const [daysInMonth, setDaysInMonth] = useState(null);
    const [firstDayOfWeek, setFirstDayOfWeek] = useState(null);
    const [daysInNextMonth, setDaysInNextMonth] = useState(null);
    const [firstDayOfNextWeek, setFirstDayOfNextWeek] = useState(null);
    const [payment, setPayment] = useState(false);
    const [quota, setQuota] = useState(null);
    const [month, setMonth] = useState(null);
    const [nextMonth, setNextMonth] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [spinner, setSpinner] = useState(false);
    const [currentAdvListing, setCurrentAdvListing] = useState(null);
    const [existingAdvertisement, setExistingAdvertisement] = useState(null);
    const [addOns, setAddOns] = useState(true);
    const [deactivateDates, setDeactivateDates] = useState(false);
    const [proceedDeactivate, setProceedDeactivate] = useState(false);
    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
    const [acknowledgeNormalMessage, setAcknowledgeNormalMessage] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const indexQuota = useRef(0);
    const [promoActivated, setPromoActivated] = useState(false);
    const [promoItemNeeded, setPromoItemNeeded] = useState(null);
    const [sectorValue, setSectorValue] = useState("Not restricted to any district");
    const [doubleConfirmDates, setDoubleConfirmDates] = useState(false);
    const [doubleProceed, setDoubleProceed] = useState(false);
    const [confirmDates, setConfirmDates] = useState([]);

    const setUpCalender = async () => {
        var advListing = props.advListing;
        var index = advListing.findIndex(obj => obj.id === props.activateID);

        await getDoc(doc(db, 'commonData', "advertiseDetails")).then((indivDoc) => {
            var today = new Date();
            if (today.getDate() != 1) {
                const daysInNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0).getDate();
                const firstDayOfNextWeek = new Date(today.getFullYear(), today.getMonth() + 1, 1).getDay();
                setDaysInNextMonth(daysInNextMonth);
                setFirstDayOfNextWeek(firstDayOfNextWeek);
            }
            const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
            const firstDayOfWeek = new Date(today.getFullYear(), today.getMonth(), 1).getDay();
            setDaysInMonth(daysInMonth);
            setFirstDayOfWeek(firstDayOfWeek);
            if (today.getMonth() + 1 == 1) {
                setMonth("Jan");
                setNextMonth("Feb");
            }
            else if (today.getMonth() + 1 == 2) {
                setMonth("Feb");
                setNextMonth("Mar");
            }
            else if (today.getMonth() + 1 == 3) {
                setMonth("Mar");
                setNextMonth("Apr");
            }
            else if (today.getMonth() + 1 == 4) {
                setMonth("Apr");
                setNextMonth("May");
            }
            else if (today.getMonth() + 1 == 5) {
                setMonth("May");
                setNextMonth("Jun");
            }
            else if (today.getMonth() + 1 == 6) {
                setMonth("Jun");
                setNextMonth("Jul");
            }
            else if (today.getMonth() + 1 == 7) {
                setMonth("Jul");
                setNextMonth("Aug");
            }
            else if (today.getMonth() + 1 == 8) {
                setMonth("Aug");
                setNextMonth("Sep");
            }
            else if (today.getMonth() + 1 == 9) {
                setMonth("Sep");
                setNextMonth("Oct");
            }
            else if (today.getMonth() + 1 == 10) {
                setMonth("Oct");
                setNextMonth("Nov");
            }
            else if (today.getMonth() + 1 == 11) {
                setMonth("Nov");
                setNextMonth("Dec");
            }
            else if (today.getMonth() + 1 == 12) {
                setMonth("Dec");
                setNextMonth("Jan");
            }
            setCurrentAdvListing(advListing[index])
            setQuota(indivDoc.data().advertiseSlot);
        })

    }

    const selectDay = (targetMonth, day) => {
        indexQuota.current = 0;

        var dayDiv = document.getElementById(`${targetMonth}_${day}`);
        if (dayDiv.className == "Selected") {
            dayDiv.className = "available";
        }
        else {
            dayDiv.className = "Selected";
        }
        const selectedElements = document.querySelectorAll('.Selected');
        setTotalPrice(selectedElements.length * 38);
    }

    useEffect(() => {
        if (!props.trigger) {

        }
        else {
            setUpCalender();
            if (props.advertisePath == "modify") {
                setExistingAdvertisement(props.modifyAdvertiseDetails);
                setAddOns(false);
            }
        }
    }, [props])

    const closeAdvertiseAction = () => {
        indexQuota.current = 0;
        setCurrentTab(0);
        setPayment(false);
        setAddOns(true);
        setPromoItemNeeded(null);
        setPromoActivated(false);
        setAcknowledgeNormalMessage("");
        setAcknowledgeNormal(false);
        setProceedDeactivate(false);
        setDeactivateDates(false);
        setShowCalendar(false);
        setTotalPrice(0);
        setExistingAdvertisement(null);
        props.setAdvertisePath("newAdvertisement");
        props.setTrigger(false);
    }

    const proceedDoubleConfirm = async () =>{
        const selectedElements = document.querySelectorAll('.Selected');
        console.log(selectedElements)
        var dateArr = [];
        var duplicateDates = false;
        var newQuota = [...quota];
        var dateArrAll = props.modifyAdvertiseDetails.details.activatedDate != null ? [...props.modifyAdvertiseDetails.details.activatedDate] : [];
        var sortedDates;

        selectedElements.forEach((element) => {
            // Do something with each selected element
            console.log(element.id)
            var checkMonth = element.id.split("_");
            if(checkMonth[0] == "nextMonth"){
                var activationMonth = new Date().getMonth() + 2;
            }
            else{
                var activationMonth = new Date().getMonth() + 1;
            }
            const pTagId = element.querySelector('.notes p').id
            console.log(pTagId, quota);
            if (quota[pTagId] > 0) {
                var day = element.id.split("_");
                var activatedDate = `${day[1]}/${activationMonth}/${new Date().getFullYear()}`;
                dateArr.push(activatedDate);
            }
        })
        console.log(dateArr)
        setConfirmDates(dateArr);
        setDoubleConfirmDates(true)
    }

    const proceedButton = async () => {

        const selectedElements = document.querySelectorAll('.Selected');
        console.log(selectedElements)
        var dateArr = [];
        var duplicateDates = false;
        var newQuota = [...quota];
        var dateArrAll = props.modifyAdvertiseDetails.details.activatedDate != null ? [...props.modifyAdvertiseDetails.details.activatedDate] : [];
        var sortedDates;

        selectedElements.forEach((element) => {
            // Do something with each selected element
            console.log(element.id)
            var checkMonth = element.id.split("_");
            if(checkMonth[0] == "nextMonth"){
                var activationMonth = new Date().getMonth() + 2;
            }
            else{
                var activationMonth = new Date().getMonth() + 1;
            }
            const pTagId = element.querySelector('.notes p').id
            console.log(pTagId, quota);
            if (quota[pTagId] > 0) {
                var day = element.id.split("_");
                var activatedDate = `${day[1]}/${activationMonth}/${new Date().getFullYear()}`;

                if (existingAdvertisement != null) {
                    if (existingAdvertisement.details.activatedDate.includes(activatedDate)) {
                        alert(activatedDate + " has already been purchase before");
                        duplicateDates = true;
                        return;
                    }

                }

                console.log(activatedDate)
                // newQuota[pTagId] = newQuota[pTagId] - 1;
                dateArrAll.push(activatedDate);
                dateArr.push(activatedDate);
            }


        });

        sortedDates = dateArrAll.sort((a, b) => {
            const [dayA, monthA, yearA] = a.split('/');
            const [dayB, monthB, yearB] = b.split('/');
            const dateA = new Date(`${monthA}/${dayA}/${yearA}`);
            const dateB = new Date(`${monthB}/${dayB}/${yearB}`);
            return dateA - dateB;
        });


        if (duplicateDates == false) {

            if (dateArr.length == 0) {
                alert("No date selected");
            }
            else {
                if (props.currentUserInfo.credit >= totalPrice) {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        credit: props.currentUserInfo.credit - totalPrice
                    }).then(async () => {
                        await updateDoc(doc(db, 'commonData', 'advertiseDetails'), {
                            advertiseSlot: newQuota
                        }).then(async () => {
                            await updateDoc(doc(db, 'advertiseListing', props.activateID), {
                                activatedDate: sortedDates,
                                sector: sectorValue
                            }).then(() => {
                                var advListing = props.advListing;
                                var index = advListing.findIndex(obj => obj.id === props.activateID);
                                advListing[index].details.activatedDate = sortedDates;
                                advListing[index].details.sector = sectorValue;

                                setSpinner(false);
                                setPayment(false);
                                setCurrentTab(0);
                                indexQuota.current = 0;
                                setShowCalendar(false);
                                props.setAdvListing(advListing);
                                props.setActivateID(null);
                                setTotalPrice(0);
                                props.setTrigger(false);
                            })
                        })
                    })





                }
                else {
                    alert("Not enough credits");
                    indexQuota.current = 0;
                    setShowCalendar(false);
                    // setPayment(true);
                }


            }
        }




    }

    const proceedPromo = async () => {

        const selectedElements = document.querySelectorAll('.Selected');
        var dateArr = [];
        var duplicateDates = false;

        selectedElements.forEach((element) => {
            // Do something with each selected element
            const pTagId = element.querySelector('.notes p').id
            if (quota[pTagId] > 0) {
                var day = element.id.split("_");
                var activatedDate = `${day[1]}/${(new Date().getMonth() + 1)}/${new Date().getFullYear()}`;
                if (existingAdvertisement != null) {
                    if (existingAdvertisement.details.activatedDate.includes(activatedDate)) {
                        alert(activatedDate + " has already been purchase before");
                        duplicateDates = true;
                        return;
                    }
                }
                dateArr.push(activatedDate);
            }


        });
        if (duplicateDates == false) {

            if (dateArr.length == 0) {
                alert("No date selected");
            }
            else {
                var dateArr = props.modifyAdvertiseDetails.details.activatedDate != null ? [...props.modifyAdvertiseDetails.details.activatedDate] : [];
                var newQuota = [...quota];
                var sortedDates;
                selectedElements.forEach((element) => {
                    // Do something with each selected element
                    const pTagId = element.querySelector('.notes p').id

                    if (quota[pTagId] > 0) {
                        var day = element.id.split("_");
                        // var activatedDate = new Date(`${(new Date().getMonth() + 1)}/${day[1]}/${new Date().getFullYear()}`);
                        var activatedDate = `${day[1]}/${(new Date().getMonth() + 1)}/${new Date().getFullYear()}`;
                        newQuota[pTagId] = newQuota[pTagId] - 1;
                        dateArr.push(activatedDate);

                        sortedDates = dateArr.sort((a, b) => {
                            const [dayA, monthA, yearA] = a.split('/');
                            const [dayB, monthB, yearB] = b.split('/');
                            const dateA = new Date(`${monthA}/${dayA}/${yearA}`);
                            const dateB = new Date(`${monthB}/${dayB}/${yearB}`);
                            return dateA - dateB;
                        });

                    }

                });
                indexQuota.current = 0;
                setShowCalendar(false);
                setPromoItemNeeded({
                    'newQuota': newQuota,
                    'sortedDates': sortedDates,
                    'dateArrLength': dateArr.length
                })
                setPromoActivated(true);
            }
        }




    }

    const createOrder = (data, actions) => {
        const selectedElements = document.querySelectorAll('.Selected');
        var dateArr = [];
        selectedElements.forEach((element) => {
            // Do something with each selected element
            const pTagId = element.querySelector('.notes p').id
            if (quota[pTagId] > 0) {
                var day = element.id.split("_");
                var activatedDate = new Date(`${(new Date().getMonth() + 1)}/${day[1]}/${new Date().getFullYear()}`);
                dateArr.push(activatedDate);
            }

        });
        var paymentAmount = dateArr.length * 38;

        // Order is created on the server and the order id is returned
        // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/createPayPalOrder
        // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalOrder
        return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalOrder", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + user.accessToken
            },
            // use the "body" param to optionally pass additional order information
            // like product skus and quantities
            body: JSON.stringify({
                data: {
                    amount: paymentAmount,
                    currencyCode: "SGD",
                    intent: "CAPTURE"
                }
            }),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error("Failed to create order");
                }
            })
            .then((data) => {
                return data.result.orderID;
            })
            .catch((err) => {
                console.error(err);
            });

    };

    const onApprove = (data, actions) => {

        const selectedElements = document.querySelectorAll('.Selected');
        var dateArr = props.modifyAdvertiseDetails.details.activatedDate != null ? [...props.modifyAdvertiseDetails.details.activatedDate] : [];
        var newQuota = [...quota];
        var sortedDates;
        selectedElements.forEach((element) => {
            // Do something with each selected element
            const pTagId = element.querySelector('.notes p').id

            if (quota[pTagId] > 0) {
                var day = element.id.split("_");
                // var activatedDate = new Date(`${(new Date().getMonth() + 1)}/${day[1]}/${new Date().getFullYear()}`);
                var activatedDate = `${day[1]}/${(new Date().getMonth() + 1)}/${new Date().getFullYear()}`;
                newQuota[pTagId] = newQuota[pTagId] - 1;
                dateArr.push(activatedDate);

                sortedDates = dateArr.sort((a, b) => {
                    const [dayA, monthA, yearA] = a.split('/');
                    const [dayB, monthB, yearB] = b.split('/');
                    const dateA = new Date(`${monthA}/${dayA}/${yearA}`);
                    const dateB = new Date(`${monthB}/${dayB}/${yearB}`);
                    return dateA - dateB;
                });

            }

        });



        // Capture the funds from the transaction
        // return actions.order.capture().then(function (details) {
        // Send the order ID and captured details to your server
        // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/capturePayPalOrder
        // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/capturePayPalOrder
        setSpinner(true);
        return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/capturePayPalOrder", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + user.accessToken
            },
            body: JSON.stringify({
                data: {
                    orderID: data.orderID,
                    path: "advertise",
                    activatedDate: sortedDates,
                    advertiseID: props.activateID


                    // capturedDetails: details,
                }
            }),
        })
            .then((response) => {

                return response.json();
            })
            .then(async (data) => {
                // Do something with the data here
                if (data.hasOwnProperty('result')) {
                    console.log(data.result);
                    if (data.result.status == "COMPLETED") {
                        await setDoc(doc(db, 'payment', data.result.id), {
                            payer: data.result.payer,
                            amount: data.result.purchase_units
                        }).then(async () => {


                            await updateDoc(doc(db, 'commonData', 'advertiseDetails'), {
                                advertiseSlot: newQuota
                            }).then(() => {
                                // await updateDoc(doc(db, 'advertiseListing', props.activateID), {
                                //     activatedDate: dateArr
                                // })
                                var advListing = props.advListing;
                                var index = advListing.findIndex(obj => obj.id === props.activateID);
                                advListing[index].details.activatedDate = sortedDates;


                                setSpinner(false);
                                setPayment(false);
                                setCurrentTab(0);
                                indexQuota.current = 0;
                                setShowCalendar(false);
                                props.setAdvListing(advListing);
                                props.setActivateID(null);
                                setTotalPrice(0);
                                props.setTrigger(false);
                            })
                        })

                    }
                }
            })
            .catch((error) => {
                console.error('onapprove', error);
            });

    };

    const selectExistingDate = (id) => {
        const selectedElements = document.getElementById(id);
        if (selectedElements.className == "notSelectedAdvertiseDates") {
            selectedElements.className = "selectedAdvertiseDates";
        }
        else {
            selectedElements.className = "notSelectedAdvertiseDates"
        }
    }

    const callDeactivate = async () => {
        const selectedElements = document.querySelectorAll(".selectedAdvertiseDates");
        var allActivateDates = existingAdvertisement.details.activatedDate;
        var newQuota = [...quota];

        if (selectedElements.length != 0) {
            for (var i = 0; i < selectedElements.length; i++) {
                const targetIndex = allActivateDates.indexOf(selectedElements[i].id);
                if (targetIndex >= 0) {
                    allActivateDates.splice(targetIndex, 1);
                    var splitSelectedDate = selectedElements[i].id.split("/");
                    const targetDate = new Date(`${splitSelectedDate[2]}-${splitSelectedDate[1] <= 9 ? "0" + splitSelectedDate[1] : splitSelectedDate[1]}-${splitSelectedDate[0]}`);
                    // Replace with your target date
                    const currentDate = new Date(); // Current date

                    // Calculate the difference in milliseconds between the target date and the current date
                    const timeDifference = targetDate.getTime() - currentDate.getTime();

                    // Convert the time difference to days
                    const daysUntilTarget = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                    //somehow i need to minus 1
                    newQuota[daysUntilTarget - 1] = newQuota[daysUntilTarget - 1] + 1;
                }
            }
            if (allActivateDates.length == 0) {
                allActivateDates = null;
            }
            await updateDoc(doc(db, 'advertiseListing', props.activateID), {
                activatedDate: allActivateDates
            }).then(async () => {
                if(props.currentUserInfo.transactionHistory.length > 30){
                    var newTransactionHistory = [...props.currentUserInfo.transactionHistory];
                    newTransactionHistory.shift();
                    newTransactionHistory.push({
                        "Actions":`+${(selectedElements.length * 38)} Credits`,
                        "Activity": "Refunded Credits",
                        "Date": new Date(),
                        "Type": "Points Activity"
                    })
                }
                else{
                    var newTransactionHistory = [...props.currentUserInfo.transactionHistory];
                    newTransactionHistory.push({
                        "Actions":`+${(selectedElements.length * 38)} Credits`,
                        "Activity": "Refunded Credits",
                        "Date": new Date(),
                        "Type": "Points Activity"
                    })
                }

                await updateDoc(doc(db, 'userInfo', user.uid), {
                    credit: props.currentUserInfo.credit + Number(selectedElements.length * 38),
                    transactionHistory: newTransactionHistory
                })
            }).then(() => {
                var advListing = props.advListing;
                var index = advListing.findIndex(obj => obj.id === props.activateID);

                advListing[index].details.activatedDate = allActivateDates;


                props.setActivateID(null);
                props.setAdvListing(advListing)
                props.setAdvertisePath("newAdvertisement");
                props.setModifyAdvertiseDetails(null);
                closeAdvertiseAction();
            })
        }
        else {
            alert("No dates selected");
        }

    }

    useEffect(() => {
        if (proceedDeactivate == true) {
            callDeactivate();
        }
    }, [proceedDeactivate])

    if (!props.trigger) {
        return;
    }
    else {
        return (
            <>
                <Spinner trigger={spinner} setTrigger={setSpinner}></Spinner>
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={acknowledgeNormalMessage} setProceedDeactivate={setProceedDeactivate}></AcknowledgeNormal>
                {/* <AcknowledgeAdvertisementDates trigger={doubleConfirmDates} setTrigger={setDoubleConfirmDates} confirmDates={confirmDates} setDoubleProceed={setDoubleProceed} credit={totalPrice}></AcknowledgeAdvertisementDates> */}
                <PromoPageAdvertise trigger={promoActivated} setTrigger={setPromoActivated} parentDiv={closeAdvertiseAction} currentUserInfo={props.currentUserInfo} setCurrentUserInfo={props.setCurrentUserInfo} existingAdvertisement={existingAdvertisement} promoItemNeeded={promoItemNeeded} advertiseID={props.activateID}></PromoPageAdvertise>
                <div className='modalNewProject'>
                    <div className='modalBoost'>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => { closeAdvertiseAction(); }}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>ACTIVATE ADVERTISEMENT</h4>
                        </div>
                        <div className='modal-bodyNewProject' style={payment == false ? { height: "300px", display: "block" } : { height: "auto", display: "none" }}>
                            <div style={addOns == true ? { position: "relative", width: "100%" } : { display: "none", width: "100%" }} >
                                {quota != null ? <>
                                    <p onClick={() => { indexQuota.current = 0; setShowCalendar(!showCalendar); }} style={{ width: "100%", backgroundColor: "#33434D", padding: "8px  8px", textAlign: "center", color: "white", borderRadius: "10px", cursor: "pointer" }}>Show Calendar</p>
                                    <div className={showCalendar ? `hidden` : `block p-4 mt-4 rounded border-2 border-slate-300 w-full`}>
                                        <div className='flex mb-4'>
                                            <p className="text-l font-medium text-gray-800">Sector: </p>
                                            <select className='outline-none w-full' value={sectorValue} onChange={(e) => {setSectorValue(e.target.value);indexQuota.current = 0;}}>
                                                <option value={"Not restricted to any district"}>Not restricted to any district</option>
                                                {Array.from({ length: 28 }, (_, index) => (
                                                    <option key={index + 1} value={`District ${index + 1}`}>
                                                        District {index + 1}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <p className="text-sm text-gray-800">*Choose a specific district for your advertisement to be featured in the owners directory. If it is not specific to any district, you may select [Not restricted to any district] and it will be scheduled under the [Latest profile] category.</p>
                                    </div>
                                    <div className="calendar" style={showCalendar ? { display: "block", position: "absolute", top: "100%", width: "100%", backgroundColor: "#f0f0f0", borderRadius: "10px" } : { display: "none", position: "absolute", top: "100%", width: "100%", backgroundColor: "#f0f0f0", borderRadius: "10px" }}>
                                        <div className="month" style={{ textAlign: "center", paddingTop: "8px", paddingBottom: "8px" }}>{currentTab == 1 ? <i class="fa-solid fa-caret-left" style={{ cursor: "pointer" }} onClick={() => { setCurrentTab(0); indexQuota.current = 0; }}></i> : null} {currentTab == 0 ? `${month}/${new Date().getFullYear()}` : nextMonth == "Jan" ? `${nextMonth}/${new Date().getFullYear() + 1}` : `${nextMonth}/${new Date().getFullYear()}`} {currentTab == 0 ? <i class="fa-solid fa-caret-right" style={{ cursor: "pointer" }} onClick={() => { setCurrentTab(1); indexQuota.current = 0; }}></i> : null}</div>
                                        <table style={{ width: "100%", tableLayout: "fixed" }}>
                                            <thead>
                                                <tr>
                                                    <th>Sun</th>
                                                    <th>Mon</th>
                                                    <th>Tue</th>
                                                    <th>Wed</th>
                                                    <th>Thu</th>
                                                    <th>Fri</th>
                                                    <th>Sat</th>
                                                </tr>
                                            </thead>

                                            <tbody style={currentTab == 0 ? { display: "table-row-group" } : { display: "none" }}>
                                                {Array.from(
                                                    { length: Math.ceil((daysInMonth + firstDayOfWeek) / 7) },
                                                    (_, weekIndex) => (
                                                        <tr key={weekIndex}>
                                                            {Array.from({ length: 7 }, (_, dayIndex) => {
                                                                const day = weekIndex * 7 + dayIndex + 1 - firstDayOfWeek;
                                                                const isCurrentMonth = day > 0 && day <= daysInMonth;

                                                                return (
                                                                    <td
                                                                        key={dayIndex}
                                                                        style={{ textAlign: "center", width: "14.2857%" }}
                                                                    >
                                                                        {isCurrentMonth && (
                                                                            <div
                                                                                className={
                                                                                    // quota[indexQuota.current] != 0
                                                                                    day >= new Date().getDate() + 1 && true
                                                                                        ? "available"
                                                                                        : "unavailable"
                                                                                }
                                                                                id={`month_${day}`}
                                                                                onClick={
                                                                                    day >= new Date().getDate() + 1 && true
                                                                                        ? () => selectDay(`month`, day)
                                                                                        : null
                                                                                }
                                                                            >
                                                                                {day}
                                                                                {day >= new Date().getDate() + 1
                                                                                    ? <div className="notes">
                                                                                        {/* {quota[indexQuota.current] <= 3 && true ? (
                                                                                            <span
                                                                                                style={{ fontSize: "13px", color: "Red" }}
                                                                                            >{`${quota[indexQuota.current]} Left`}</span>
                                                                                        ) : null} */}
                                                                                        {day >= new Date().getDate() + 1 ? (
                                                                                            <>
                                                                                                <p
                                                                                                    style={{ display: "none" }}
                                                                                                    id={indexQuota.current}
                                                                                                >
                                                                                                    {indexQuota.current++}
                                                                                                </p>
                                                                                            </>
                                                                                        ) : null}
                                                                                    </div> : null}
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    )
                                                )}



                                            </tbody>
                                            <tbody style={currentTab == 1 ? { display: "table-row-group" } : { display: "none" }}>



                                                {Array.from(
                                                    { length: Math.ceil((daysInNextMonth + firstDayOfNextWeek) / 7) },
                                                    (_, weekIndex) => (
                                                        <tr key={weekIndex}>
                                                            {Array.from({ length: 7 }, (_, dayIndex) => {
                                                                const day =
                                                                    weekIndex * 7 + dayIndex + 1 - firstDayOfNextWeek;
                                                                const isCurrentMonth =
                                                                    day > 0 && day <= daysInNextMonth;

                                                                return (
                                                                    <td
                                                                        key={dayIndex}
                                                                        style={{ textAlign: "center", width: "14.2857%" }}
                                                                    >
                                                                        {isCurrentMonth && (
                                                                            <div
                                                                                className={
                                                                                    day < new Date().getDate() && true
                                                                                        ? "available"
                                                                                        : "unavailable"
                                                                                }
                                                                                id={`nextMonth_${day}`}
                                                                                onClick={
                                                                                    day < new Date().getDate() && true
                                                                                        ? () => selectDay(`nextMonth`, day)
                                                                                        : null
                                                                                }
                                                                            >
                                                                                {day}
                                                                                {day < new Date().getDate() + 1
                                                                                    ? <div className="notes">
                                                                                        {/* {quota[indexQuota.current] <= 3 && true ? (
                                                                                            <span
                                                                                                style={{ fontSize: "13px", color: "Red" }}
                                                                                            >{`${quota[indexQuota.current]} Left`}</span>
                                                                                        ) : null} */}
                                                                                        {day < new Date().getDate() ? (
                                                                                            <>
                                                                                                <p
                                                                                                    style={{ display: "none" }}
                                                                                                    id={indexQuota.current}
                                                                                                >
                                                                                                    {indexQuota.current++}
                                                                                                </p>
                                                                                            </>
                                                                                        ) : null}
                                                                                    </div> : null}
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    )
                                                )}




                                            </tbody>
                                        </table>

                                    </div>
                                </> :
                                    null}

                            </div>
                            <div style={addOns == false && deactivateDates == false ? { display: "block", overflow: "auto", height: "95%" } : { display: "none" }}>
                                <p style={{ fontWeight: "bold", paddingTop: "8px", paddingBottom: "8px" }}>Upcoming activated dates</p>
                                {existingAdvertisement != null && existingAdvertisement.details.activatedDate != null ? existingAdvertisement.details.activatedDate.map((indivDate) => {
                                    return (
                                        <p style={{ paddingTop: "8px", paddingBottom: "8px", borderBottom: "1px solid #ccc" }}>{indivDate}</p>
                                    )
                                }) : null}
                            </div>
                            <div style={deactivateDates == true ? { display: "block", overflow: "auto", height: "95%" } : { display: "none" }}>
                                <center><p style={{ paddingTop: "8px", paddingBottom: "8px" }}>Select the dates that need to be deactivate</p></center>
                                <p style={{ fontWeight: "bold", paddingTop: "8px", paddingBottom: "8px" }}>Upcoming activated dates</p>

                                {existingAdvertisement != null && existingAdvertisement.details.activatedDate != null ? existingAdvertisement.details.activatedDate.map((indivDate) => {
                                    return (
                                        <p className='notSelectedAdvertiseDates' id={indivDate} style={{ paddingTop: "8px", paddingBottom: "8px", borderBottom: "1px solid #ccc", cursor: 'pointer' }} onClick={() => { selectExistingDate(indivDate) }}>{indivDate}</p>
                                    )
                                }) : null}
                            </div>

                        </div>

                        <div className='modal-footerNewProject'>
                            {payment == false ?
                                <>
                                    <div style={{ float: "left" }}>
                                        <p >Total: <b style={{ fontWeight: "bold", color: "black" }}>{totalPrice} Credits</b></p>
                                        {props.currentUserInfo.hasOwnProperty('advertisingCredit') ?
                                            props.currentUserInfo.advertisingCredit != 0 ?
                                                <p>Free {props.currentUserInfo.advertisingCredit} advertising days</p>
                                                : null : null}
                                    </div>

                                    {existingAdvertisement == null ?
                                        <>
                                            <button className='boostBtn' onClick={() => proceedButton()}>Proceed</button>
                                            <button className='boostBtn' onClick={() => proceedPromo()}>Promo Code</button>
                                        </> :
                                        <>
                                            {deactivateDates == false ? addOns == false ? <button className='boostBtn' onClick={() => { setAddOns(true) }}>Add On</button> : <button className='boostBtn' onClick={() => proceedButton()}>Proceed</button> : null}
                                            {deactivateDates == true ? <button className='boostBtn' onClick={() => { setAcknowledgeNormal(true); setAcknowledgeNormalMessage("Once the dates are deactivated, the process becomes irreversible and credits will be refunded back into your account.") }}>Deactivate</button> : null}
                                            {deactivateDates == false && addOns == true ? <button className='boostBtn' onClick={() => proceedPromo()}>Promo Code</button> : null}
                                            {deactivateDates == false ? <button className='boostBtn' onClick={() => { setDeactivateDates(true); setAddOns(false) }}>Deactivate dates</button> : null}
                                        </>}
                                    <div style={{ clear: 'both' }}></div>
                                </>
                                :
                                <>
                                    <div>
                                        {/* <center>
                                            <PayPalScriptProvider
                                                options={{
                                                    "client-id": "AUj95SHnSm6iN1zjvQnpSFuwehgQqPmaO2ZX9ZGFdLX_uNVWVZMnKwedYOeTmDp8UzxlLtlclw1SCnP-",
                                                    currency: "SGD",
                                                    intent: "capture"
                                                }}
                                            >
                                                <PayPalButtons
                                                    createOrder={(data, actions) => createOrder(data, actions)}
                                                    onApprove={(data, actions) => onApprove(data, actions)}
                                                />
                                            </PayPalScriptProvider>
                                        </center> */}
                                    </div>
                                </>
                            }


                        </div>
                    </div >
                </div >
            </>
        );
    }


}