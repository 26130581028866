import React, { useEffect, useRef, useState } from 'react';
import { UserAuth } from '../../AuthContent';
import { doc, setDoc, getDoc, collection, addDoc, updateDoc, serverTimestamp, deleteField } from "firebase/firestore";
import { db, storage } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import Acknowledge from './Acknowledge';
import '../css/UserTypeModal.css';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import AcknowledgeNormal from '../../AcknowledgeNormal';
import Spinner from './Spinner';
import { deleteObject } from 'firebase/storage';

export default function ChangePoints(props) {
    const { user } = UserAuth();




    const [message, setMessage] = useState(null);

    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);

    const [activeSpin, setActiveSpin] = useState(false);

    const [chooseTier, setChooseTier] = useState(true);
    const [standard, setStandard] = useState(false);
    const [premium, setPremium] = useState(false);
    const [superPremium, setSuperPremium] = useState(false);
    const [enterprise, setEnterprise] = useState(false);
    const [paymentTab, setPaymentTab] = useState(false);
    const [promotionTab, setPromotionTab] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [tierPayment, setTierPayment] = useState('Tier 1');
    const [amountCredits, setAmountCredits] = useState(0);
    const [amountPoints, setAmountPoints] = useState(0);




    const closeUserTypeModal = () => {
        setAmountCredits(0);
        setAmountPoints(0);
        props.setTrigger(false);

    }


    const proceedPromotion = async () => {
        var promotionDoc = await getDoc(doc(db, 'promotionCode', 'promoCode'));
        if (promoCode != promotionDoc.data().code && promoCode != promotionDoc.data().agentPromo && promoCode != promotionDoc.data().additionalMonth) {
            alert("Invalid Code");
        }
        else {
            if (props.currentUserInfo.launchedPromotionCode.includes(promoCode) == false) {
                if (promoCode == promotionDoc.data().agentPromo) {
                    var newLaunchedPromotionCode = [...props.currentUserInfo.launchedPromotionCode];
                    newLaunchedPromotionCode.push(promoCode)
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        subscriptionID: "PromotionCode",
                        membership: "Premium",
                        billingAmount: 380,
                        gracePeriod: deleteField(),
                        chatLeft: props.currentUserInfo.chatLeft + 32,
                        advancedDetails: props.currentUserInfo.advancedDetails + 32,
                        subscriptionPeriod: "Monthly",
                        subscriptionCounter: props.currentUserInfo.subscriptionCounter + 60,
                        launchedPromotionCode: newLaunchedPromotionCode,
                        freeAppt: true
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;


                        newCurrentUserInfo.subscriptionID = "PromotionCode";
                        newCurrentUserInfo.membership = "Premium";
                        newCurrentUserInfo.billingAmount = 380;
                        newCurrentUserInfo.chatLeft = props.currentUserInfo.chatLeft + 32;
                        newCurrentUserInfo.advancedDetails = props.currentUserInfo.advancedDetails + 32;
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.subscriptionCounter = props.currentUserInfo.subscriptionCounter + 60;
                        newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;

                        setMessage("Promotion Code Verified. Once your trail period has ended, select a tier to continue your subscription");
                        setAcknowledgeNormal(true);
                        // props.setCurrentUserInfo(newCurrentUserInfo);
                        // closeUserTypeModal();

                    })
                }
                else if (promoCode == promotionDoc.data().additionalMonth) {
                    var allow = false;
                    if (props.commercialPurchase.length != 0) {
                        const timestampDate = new Date(props.commercialPurchase[0].details.timePosted.seconds * 1000 + props.commercialPurchase[0].details.timePosted.nanoseconds / 1000000);
                        const currentDate = new Date();

                        if (
                            timestampDate.getFullYear() === currentDate.getFullYear() &&
                            timestampDate.getMonth() === currentDate.getMonth() &&
                            props.commercialPurchase[0].details.status == "leasing"
                        ) {
                            console.log('commercial', props.commercialPurchase[0]);
                            allow = true;
                        }
                    }
                    if (props.commercialRent != 0) {
                        const timestampDate = new Date(props.commercialRent[0].details.timePosted.seconds * 1000 + props.commercialRent[0].details.timePosted.nanoseconds / 1000000);
                        const currentDate = new Date();

                        if (
                            timestampDate.getFullYear() === currentDate.getFullYear() &&
                            timestampDate.getMonth() === currentDate.getMonth() &&
                            props.commercialRent[0].details.status == "leasing"
                        ) {
                            console.log('commercialRent', props.commercialRent[0]);
                            allow = true;
                        }
                    }
                    if (props.residentialPurchase != 0) {
                        const timestampDate = new Date(props.residentialPurchase[0].details.timePosted.seconds * 1000 + props.residentialPurchase[0].details.timePosted.nanoseconds / 1000000);
                        const currentDate = new Date();

                        if (
                            timestampDate.getFullYear() === currentDate.getFullYear() &&
                            timestampDate.getMonth() === currentDate.getMonth() &&
                            props.residentialPurchase[0].details.status == "leasing"
                        ) {
                            console.log('residentialPurchase', props.residentialPurchase[0]);
                            allow = true;
                        }
                    }
                    if (props.residentialRent != 0) {
                        const timestampDate = new Date(props.residentialRent[0].details.timePosted.seconds * 1000 + props.residentialRent[0].details.timePosted.nanoseconds / 1000000);
                        const currentDate = new Date();

                        if (
                            timestampDate.getFullYear() === currentDate.getFullYear() &&
                            timestampDate.getMonth() === currentDate.getMonth() &&
                            props.residentialRent[0].details.status == "leasing"
                        ) {
                            console.log('residentialRent', props.residentialRent[0]);
                            allow = true;
                        }
                    }
                    if (allow) {
                        var newLaunchedPromotionCode = [...props.currentUserInfo.launchedPromotionCode];
                        newLaunchedPromotionCode.push(promoCode)

                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            subscriptionID: "PromotionCode",
                            membership: "Premium",
                            billingAmount: 380,
                            gracePeriod: deleteField(),
                            chatLeft: props.currentUserInfo.chatLeft + 16,
                            advancedDetails: props.currentUserInfo.advancedDetails + 16,
                            subscriptionPeriod: "Monthly",
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter + 30,
                            launchedPromotionCode: newLaunchedPromotionCode,
                            freeAppt: true
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;


                            newCurrentUserInfo.subscriptionID = "PromotionCode";
                            newCurrentUserInfo.membership = "Premium";
                            newCurrentUserInfo.billingAmount = 380;
                            newCurrentUserInfo.chatLeft = props.currentUserInfo.chatLeft + 16;
                            newCurrentUserInfo.advancedDetails = props.currentUserInfo.advancedDetails + 16;
                            newCurrentUserInfo.subscriptionPeriod = "Monthly";
                            newCurrentUserInfo.subscriptionCounter = props.currentUserInfo.subscriptionCounter + 30;
                            newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;

                            setMessage("Promotion Code Verified. Once your trail period has ended, select a tier to continue your subscription");
                            setAcknowledgeNormal(true);
                            // props.setCurrentUserInfo(newCurrentUserInfo);
                            // closeUserTypeModal();
                        })
                    }
                    else {
                        setMessage("Please have at least 1 approved listing before applying this promotion code");
                        setAcknowledgeNormal(true);
                    }
                }
                else {
                    var newLaunchedPromotionCode = [...props.currentUserInfo.launchedPromotionCode];
                    newLaunchedPromotionCode.push(promoCode)
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        subscriptionID: "PromotionCode",
                        membership: "Premium",
                        billingAmount: 380,
                        gracePeriod: deleteField(),
                        chatLeft: props.currentUserInfo.chatLeft + 16,
                        advancedDetails: props.currentUserInfo.advancedDetails + 16,
                        startDate: new Date(),
                        subscriptionPeriod: "Monthly",
                        subscriptionCounter: props.currentUserInfo.subscriptionCounter + 30,
                        launchedPromotionCode: newLaunchedPromotionCode,
                        freeAppt: true
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;


                        newCurrentUserInfo.subscriptionID = "PromotionCode";
                        newCurrentUserInfo.membership = "Premium";
                        newCurrentUserInfo.billingAmount = 380;
                        newCurrentUserInfo.chatLeft = props.currentUserInfo.chatLeft + 16;
                        newCurrentUserInfo.advancedDetails = props.currentUserInfo.advancedDetails + 16;
                        newCurrentUserInfo.startDate = new Date();
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.subscriptionCounter = props.currentUserInfo.subscriptionCounter + 30;
                        newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;

                        setMessage("Promotion Code Verified. Once your trail period has ended, select a tier to continue your subscription");
                        setAcknowledgeNormal(true);
                        // props.setCurrentUserInfo(newCurrentUserInfo);
                        // closeUserTypeModal();

                    })


                }

            }
            else {
                alert("You have already redeemed the launch bonus")
            }

        }


    }

    const createOrder = async (data, actions) => {
        try {
            // const selectElement = document.querySelector('.select');

            // let planID = "";
            // //P-87V97227543012720MSIEUPQ premium no charges
            // //P-5JG55492SV880132GMSIEUEY standard no charges
            // if (selectElement.id == "standard" && props.currentUserInfo.newUserSetup == true) {
            //     planID = "P-4WE25674YD030400NMSFLZEI";
            // } else if (selectElement.id == "premium" && props.currentUserInfo.newUserSetup == true) {
            //     planID = "P-6M109645LM7508252MSFLZ2Y";
            // }
            // else if (selectElement.id == "superPremium" && props.currentUserInfo.newUserSetup == true) {
            //     planID = "P-7AD3724745234033TMSNY6EQ";
            // }
            // else if (selectElement.id == "standard" && props.currentUserInfo.newUserSetup == false) {
            //     planID = "P-5JG55492SV880132GMSIEUEY"
            // }
            // else if (selectElement.id == "premium" && props.currentUserInfo.newUserSetup == false) {
            //     planID = "P-87V97227543012720MSIEUPQ"
            // }
            // else if (selectElement.id == "superPremium" && props.currentUserInfo.newUserSetup == false) {
            //     planID = "P-4XH70004EV358760SMSNY6RA"
            // }
            var paymentAmount = 25;
            if (tierPayment == "Tier 1") {
                paymentAmount = 100;
            }
            else if (tierPayment == "Tier 2") {
                paymentAmount = 250;
            }
            else if (tierPayment == "Tier 3") {
                paymentAmount = 250;
            }
            else if (tierPayment == "Tier 4") {
                paymentAmount = 500;
            }
            else if (tierPayment == "Tier 5") {
                paymentAmount = 1000;
            }

            return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalOrder", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + user.accessToken
                },
                // use the "body" param to optionally pass additional order information
                // like product skus and quantities
                body: JSON.stringify({
                    data: {
                        amount: paymentAmount,
                        currencyCode: "SGD",
                        intent: "CAPTURE"
                    }
                }),
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error("Failed to create order");
                    }
                })
                .then((data) => {
                    return data.result.orderID;
                })
                .catch((err) => {
                    console.error(err);
                });

            // if (props.currentUserInfo.subscriptionID === "PromotionCode") {
            //     const startDate = new Date(); // Get the current date
            //     startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
            //     startDate.setMinutes(startDate.getMinutes() + 2);
            //     const response = await fetch(
            //         "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
            //         {
            //             method: "POST",
            //             headers: {
            //                 "Content-Type": "application/json",
            //                 Authorization: "Bearer " + user.accessToken,
            //             },
            //             body: JSON.stringify({
            //                 data: {
            //                     plan_id: planID,
            //                     intent: "subscription",
            //                     start_time: startDate
            //                 },
            //             }),
            //         }
            //     );

            //     if (response.ok) {
            //         const data = await response.json();
            //         return data.result.orderID;
            //     } else {
            //         throw new Error("Failed to create order");
            //     }
            // }
            // else if (props.currentUserInfo.subscriptionID == null) {
            //     if (props.currentUserInfo.membership != null) {
            //         const startDate = new Date(); // Get the current date
            //         startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
            //         startDate.setMinutes(startDate.getMinutes() + 2);
            //         console.log(startDate, planID)
            //         const response = await fetch(
            //             "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
            //             {
            //                 method: "POST",
            //                 headers: {
            //                     "Content-Type": "application/json",
            //                     Authorization: "Bearer " + user.accessToken,
            //                 },
            //                 body: JSON.stringify({
            //                     data: {
            //                         plan_id: planID,
            //                         intent: "subscription",
            //                         start_time: startDate
            //                     },
            //                 }),
            //             }
            //         );

            //         if (response.ok) {
            //             const data = await response.json();
            //             return data.result.orderID;
            //         } else {
            //             throw new Error("Failed to create order");
            //         }
            //     }

            //     else {
            //         const response = await fetch(
            //             "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
            //             {
            //                 method: "POST",
            //                 headers: {
            //                     "Content-Type": "application/json",
            //                     Authorization: "Bearer " + user.accessToken,
            //                 },
            //                 body: JSON.stringify({
            //                     data: {
            //                         plan_id: planID,
            //                         intent: "subscription"
            //                     },
            //                 }),
            //             }
            //         );

            //         if (response.ok) {
            //             const data = await response.json();
            //             return data.result.orderID;
            //         } else {
            //             throw new Error("Failed to create order");
            //         }
            //     }
            // }
            // else {
            //     //upgrade
            //     if (selectElement.id == "premium" && props.currentUserInfo.membership == "Standard") {
            //         const response = await fetch(
            //             "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
            //             {
            //                 method: "POST",
            //                 headers: {
            //                     "Content-Type": "application/json",
            //                     Authorization: "Bearer " + user.accessToken,
            //                 },
            //                 body: JSON.stringify({
            //                     data: {
            //                         plan_id: planID,
            //                         intent: "subscription"
            //                     },
            //                 }),
            //             }
            //         );

            //         if (response.ok) {
            //             const data = await response.json();
            //             return data.result.orderID;
            //         } else {
            //             throw new Error("Failed to create order");
            //         }

            //     }
            //     else if (selectElement.id == "superPremium" && props.currentUserInfo.membership == "Premium") {
            //         const response = await fetch(
            //             "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
            //             {
            //                 method: "POST",
            //                 headers: {
            //                     "Content-Type": "application/json",
            //                     Authorization: "Bearer " + user.accessToken,
            //                 },
            //                 body: JSON.stringify({
            //                     data: {
            //                         plan_id: planID,
            //                         intent: "subscription"
            //                     },
            //                 }),
            //             }
            //         );

            //         if (response.ok) {
            //             const data = await response.json();
            //             return data.result.orderID;
            //         } else {
            //             throw new Error("Failed to create order");
            //         }

            //     }
            //     else {
            //         //you have an subscription id
            //         const startDate = new Date(); // Get the current date
            //         startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
            //         startDate.setMinutes(startDate.getMinutes() + 2);
            //         const response = await fetch(
            //             "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
            //             {
            //                 method: "POST",
            //                 headers: {
            //                     "Content-Type": "application/json",
            //                     Authorization: "Bearer " + user.accessToken,
            //                 },
            //                 body: JSON.stringify({
            //                     data: {
            //                         plan_id: planID,
            //                         intent: "subscription",
            //                         start_time: startDate
            //                     },
            //                 }),
            //             }
            //         );

            //         if (response.ok) {
            //             const data = await response.json();
            //             return data.result.orderID;
            //         } else {
            //             throw new Error("Failed to create order");
            //         }

            //     }


            // }
        } catch (error) {
            console.error(error);
        }
    };

    const proceedConverting = async () => {
        let newTransactionHistory = [...props.currentUserInfo.transactionHistory];
        if(newTransactionHistory.length >= 30){
            newTransactionHistory.shift();
            newTransactionHistory.push(
                {
                    Actions:`${amountPoints} Points to ${amountCredits} Credits`,
                    Activity:"Conversion Points",
                    Date: new Date(),
                    Type:"Conversion Points"
                }
            )
        }
        else{
            newTransactionHistory.push(
                {
                    Actions:`${amountPoints} Points to ${amountCredits} Credits`,
                    Activity:"Conversion Points",
                    Date: new Date(),
                    Type:"Conversion Points"
                }
            )
        }
        await updateDoc(doc(db, 'userInfo', user.uid), {
            credit: props.currentUserInfo.credit + amountCredits,
            points: props.currentUserInfo.points - amountPoints,
            transactionHistory:newTransactionHistory
        }).then(() => {
            let newCurrentUserInfo = props.currentUserInfo;
           
            newCurrentUserInfo.credit = props.currentUserInfo.credit + amountCredits;
            newCurrentUserInfo.points =  props.currentUserInfo.points - amountPoints;
            newCurrentUserInfo.transactionHistory = newTransactionHistory
            setMessage("Thank you for converting your points into credits");
            setAcknowledgeNormal(true);
            // props.setCurrentUserInfo(newCurrentUserInfo);
        })
    }


    const checkChangePoints = (e) => {
        if (e.target.value == "") {
            setAmountCredits(parseInt(0));
            setAmountPoints(parseInt(0));
            return;
        }
        console.log(e.target.value, props.currentUserInfo.points);
        if (props.currentUserInfo.points < e.target.value) {
            setAmountCredits(parseInt(props.currentUserInfo.points));
            setAmountPoints(parseInt(props.currentUserInfo.points));
        }
        else {
            setAmountCredits(parseInt(e.target.value));
            setAmountPoints(parseInt(e.target.value));
        }

    }






    if (!props.trigger) {
        return;
    }
    else {

        return (
            <>
                <Spinner trigger={activeSpin} setTrigger={setActiveSpin}></Spinner>
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={message} parentTrigger={closeUserTypeModal}></AcknowledgeNormal>
                <div className='modalNewProject' style={{ zIndex: "2" }}>
                    <div className='modal-contentUserType' style={paymentTab ? { minWidth: "30%" } : { minWidth: "50%" }}>

                        <>

                            <div id="primaryRoleTab" style={chooseTier ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject' >
                                    <div className="notification-close" onClick={() => closeUserTypeModal()}>
                                        ×
                                    </div>
                                    <div class="text-center max-w-xl mx-auto">
                                        <h1 class="text-2xl md:text-2xl font-bold mb-5 mt-5">Points Conversion</h1>
                                        {/* <h3 class="text-xl font-medium mb-5">Different Package comes with different benefits!<br></br>Credits does not expire!</h3> */}
                                    </div>
                                </div>

                                <div className='modal-bodyUserType' style={{ display: "block" }}>
                                    <div class="flex flex-wrap mb-2">

                                        <div class="w-full md:w-1/3 xl:w-1/3 pt-3 px-3 md:pr-2">
                                            <div class="border rounded shadow p-2">
                                                <div class="flex flex-row items-center">
                                                    <div className="flex-1 text-right">
                                                        <h5 className="text-center font-bold">Points</h5>
                                                        <input
                                                            className="text-center text-3xl"
                                                            value={amountPoints}
                                                            onChange={(e) => checkChangePoints(e)}
                                                            style={{ width: '100%', textAlign: "center", outline: "none", background: "transparent" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full md:w-1/3 xl:w-1/3 pt-3 px-3 md:pl-2">
                                            <div class=" p-2">
                                                <div class="flex flex-row items-center" style={{ width: "100%", textAlign: "center" }}>
                                                    <div class="flex-shrink pl-1 pr-4" style={{width:"100%"}}><i class="fa-solid fa-arrow-right-arrow-left fa-2x fa-fw"></i></div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full md:w-1/3 xl:w-1/3 pt-3 px-3 md:pr-2 xl:pr-3 xl:pl-1">
                                            <div class="border rounded shadow p-2">
                                                <div class="flex flex-row items-center">
                                                    <div class="flex-1 text-right pr-1">
                                                        <h5 class="text-center font-bold">Credits</h5>
                                                        <h3 class="text-center text-3xl">{amountCredits}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-center max-w-xl mx-auto">
                                            <p class="text-xs leading-tight">Current Points: {props.currentUserInfo.points}</p>
                                        </div>
                                    </div>

                                </div>

                                <div className='modal-footerNewProject'>

                                    <button id="addPropertyBtn" onClick={() => { proceedConverting() }} style={{ marginRight: "2%" }}>Proceed</button>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>








                        </>
                    </div>
                </div>
            </>
        )
    }
}




