import React, { useState, useEffect, Fragment } from 'react';
import { Button } from './Button';
import { collection, getDocs, updateDoc, doc, setDoc, addDoc, serverTimestamp, getDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/firebase'
import { Link, useLocation } from 'react-router-dom';
import './css/Navbar.css';
import { UserAuth } from '../AuthContent'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,

  XMarkIcon,
  BuildingOffice2Icon,
  BuildingStorefrontIcon,
  HomeModernIcon,
  HomeIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

const commercialItem = [
  { name: 'Rent Requirements', description: 'Find your ideal tenant', href: 'commercialRent', icon: BuildingOffice2Icon },
  { name: 'Sale Requirements', description: 'Find your ideal buyer', href: 'commercialPurchase', icon: BuildingStorefrontIcon },

]
const residentialItem = [
  { name: 'Rent Requirements', description: 'Find your ideal tenant', href: 'residentialRent', icon: HomeModernIcon },
  { name: 'Sale Requirements', description: 'Find your ideal buyer', href: 'residentialPurchase', icon: HomeIcon },

]
// const callsToAction = [
//   { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
//   { name: 'Contact sales', href: '#', icon: PhoneIcon },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function Navbar(props) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const { user, logOut } = UserAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCommercial, setIsOpenCommercial] = useState(false);
  const [isOpenResidential, setIsOpenResidential] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupResidential, setShowPopupResidential] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [topNoti, setTopNoti] = useState(true);
  const [homePageBoth, setHomePageBoth] = useState(false);
  const location = useLocation();

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const togglePopupResidential = () => {
    setShowPopupResidential(!showPopupResidential);
  }

  const handleClick = () => { setIsOpen(false); setClick(!click); }
  const closeMobileMenu = () => { setIsOpen(false); setClick(false); }

  const getUserData = async () => {
    await getDoc(doc(db, 'userInfo', user.uid)).then((res) => {
      setCurrentUserInfo(res.data());
    })
  }

  useEffect(() => {
    if (user != null) {
      if (user.hasOwnProperty('uid')) {
        if (user.uid != null) {
          getUserData();
        }
      }
    }
  }, [user])

  useEffect(() =>{
    if(location.pathname === "/jobs"){
      setHomePageBoth(true);
    }
    else{
      setHomePageBoth(false)
    }
  },[location])

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    // console.log(props.navbarRef)
  }, []);

  const signout = async () => {
    await logOut();
  }
  window.addEventListener('resize', showButton);

  return (
    <>
      {/* <nav className='navbar'>
        <div ref={props.navbarRef} className='navbar-container'>
          <Link id="navLogoLinkContainer" to='/' onClick={closeMobileMenu}>
            {imageError ?
            <img id="navLogo" src="https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800"/>
            :<img id="navLogo" src="./navLogo.png" onError={() => {setImageError(true)}}/>}
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} style={{fontSize:"25px"}} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link
                to='/'
                className='nav-links'
                onClick={closeMobileMenu}
              ><b>Home</b>
              </Link>
            </li>
            <li className="nav-item" id="phoneViewAccountBtn">
              <div>
                <li className="dropdown-toggle" onClick={() => setIsOpenCommercial(!isOpenCommercial)}>
                  <b>Commercial {isOpenCommercial == false ? <i class="fa-solid fa-angle-down"></i> : <i class="fa-solid fa-angle-up"></i>}</b>
                </li>
                {isOpenCommercial && (
                  <ul className="dropdown-menu">
                    <li className="nav-links" onClick={() => {setIsOpenCommercial(!isOpenCommercial); closeMobileMenu(); }}><Link to="/commercialRent" style={{ textDecoration: "none", color: "black" }}>Rent (CR)</Link></li>
                    <li className="nav-links" onClick={() => {closeMobileMenu(); setIsOpenCommercial(!isOpenCommercial) }}><Link to="/commercialPurchase" style={{ textDecoration: "none", color: "black" }}>Purchase (CP)</Link></li>
                  </ul>
                )}
              </div>

            </li>
            {button && <li className='nav-item'  onMouseEnter={togglePopup} onMouseLeave={togglePopup}>
              <Link
                className='nav-links'
                onClick={closeMobileMenu}
              >
                <b>Commercial</b>
              </Link>
              {showPopup && (
                <div className='popup'>
                  <Link
                    to='/commercialPurchase'
                    className='sub-nav-links'
                    onClick={closeMobileMenu}
                  >Purchase (CP)
                  </Link>
                  <Link
                    to='/commercialRent'
                    className='sub-nav-links'
                    onClick={closeMobileMenu}
                  >Rent (CR)
                  </Link>
                </div>
              )}
            </li>}
            {button && <li className='nav-item' onMouseEnter={togglePopupResidential} onMouseLeave={togglePopupResidential}>
              <Link
                className='nav-links'
                onClick={closeMobileMenu}
              ><b>Residential</b>
              </Link>
              {showPopupResidential && (
                <div className='popup'>
                  <Link
                    to='/residentialPurchase'
                    className='sub-nav-links'
                    onClick={closeMobileMenu}
                  >Purchase (RP)
                  </Link>
                  <Link
                    to='/residentialRent'
                    className='sub-nav-links'
                    onClick={closeMobileMenu}
                  >Rent (RR)
                  </Link>
                </div>
              )}
            </li>}
            <li className="nav-item" id="phoneViewAccountBtn">
              <div>
                <li className="dropdown-toggle" onClick={() => setIsOpenResidential(!isOpenResidential)}>
                <b>Residential {isOpenResidential == false ? <i class="fa-solid fa-angle-down"></i> : <i class="fa-solid fa-angle-up"></i>}</b>
                </li>
                {isOpenResidential && (
                  <ul className="dropdown-menu">
                    <li className="nav-links" onClick={() => {setIsOpenResidential(!isOpenResidential); closeMobileMenu(); }}><Link to="/residentialRent" style={{ textDecoration: "none", color: "black" }}>Rent (RR)</Link></li>
                    <li className="nav-links" onClick={() => {closeMobileMenu(); setIsOpenResidential(!isOpenResidential) }}><Link to="/residentialPurchase" style={{ textDecoration: "none", color: "black" }}>Purcahse (RP)</Link></li>
                   
                  </ul>
                )}
              </div>

            </li>
            <li className='nav-item'>
              <Link
                to='/AboutUs'
                className='nav-links'
                onClick={closeMobileMenu}
              ><b>About us</b>
              </Link>
            </li>
            
            <li className='nav-item'>
              <Link
                to='/Contact'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                <b>Contact</b>
              </Link>
            </li>



            {user?.email ? <li className="nav-item" id="phoneViewAccountBtn">
              <div>
                <li className="dropdown-toggle" onClick={toggleMenu}>
                  <b>Account {isOpen == false ? <i class="fa-solid fa-angle-down"></i> : <i class="fa-solid fa-angle-up"></i>}</b>
                </li>
                {isOpen && (
                  <ul className="dropdown-menu">
                    <li className="nav-links" onClick={() => { props.setMobileClick('Dashboard'); toggleMenu(); closeMobileMenu(); }}><Link to="/account" style={{ textDecoration: "none", color: "black" }}>Dashboard</Link></li>
                    <li className="nav-links" onClick={() => { props.setMobileClick('Rent'); closeMobileMenu(); toggleMenu() }}><Link to="/account" style={{ textDecoration: "none", color: "black" }}>Rent</Link></li>
                    <li className="nav-links" onClick={() => { props.setMobileClick('Purchase'); closeMobileMenu(); toggleMenu() }}><Link to="/account" style={{ textDecoration: "none", color: "black" }}>Purchase</Link></li>
                    {currentUserInfo != null ? currentUserInfo.userType != null ? currentUserInfo.userType.includes('Advertiser') ?<li className="nav-links" onClick={() => { props.setMobileClick('Advertise'); closeMobileMenu(); toggleMenu() }}><Link to="/account" style={{ textDecoration: "none", color: "black" }}>Advertise</Link></li>:null:null:null}
                    <li className="nav-links" onClick={() => { props.setMobileClick('Favourites'); closeMobileMenu(); toggleMenu() }}><Link to="/account" style={{ textDecoration: "none", color: "black" }}>Favourites</Link></li>
                    <li className="nav-links" onClick={() => { props.setMobileClick('Message'); closeMobileMenu(); toggleMenu() }}><Link to="/account" style={{ textDecoration: "none", color: "black" }}>Message</Link></li>
                    <li className="nav-links" onClick={() => { props.setMobileClick('PendingMessage'); closeMobileMenu(); toggleMenu() }}><Link to="/account" style={{ textDecoration: "none", color: "black" }}>Pending Message</Link></li>
                    <li className="nav-links" onClick={() => { props.setMobileClick('Profile'); closeMobileMenu(); toggleMenu() }}><Link to="/account" style={{ textDecoration: "none", color: "black" }}>Profile</Link></li>
                    <li className="nav-links" onClick={() => { signout(); closeMobileMenu(); }}><span style={{ backgroundColor:"#33434D",padding:"8px 20px", color: "white", borderRadius:"10px" }}>Logout</span></li>
                    <li className="nav-links"></li>
                  </ul>
                )}
              </div>

            </li> : <li className="nav-item" id="phoneViewAccountBtn"><Link to="/sign-up" className='nav-links' onClick={closeMobileMenu}><b>Sign In</b></Link></li>}
          </ul>
          {user?.email ? button && <div style={{ marginRight: "10%" }}><Link to="/account" style={{ textDecoration: "none" }} className='btn--account' onClick={closeMobileMenu}>Account</Link></div> : button && <div style={{ width: "10%", marginRight: "10%" }}> <Link to="/sign-up" className='btn--account' style={{ textDecoration: "none" }} onClick={closeMobileMenu}><i class="fa-solid fa-user"></i> Sign In</Link></div>}
        </div>
      </nav> */}


      {homePageBoth ? null :
      
      <>
       
        <header  className="bg-white">
          <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">nextloop</span>
                <img className="h-8 w-auto" src="https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800" alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <Popover.Group className="hidden lg:flex lg:gap-x-12">
              


              <Link to="/AboutUs" className="text-sm font-semibold leading-6 text-gray-900">
                About Us
              </Link>

             

            </Popover.Group>
            
          </nav>
          <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <Link href="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800"
                    alt=""
                  />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    
                    <a
                      href="/AboutUs"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      About Us
                    </a>
                   
                  </div>
                  <div className="py-6">
                    
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </>
}

    </>
  );
}

export default Navbar;
