import React, { useEffect, useState, useRef } from 'react';
import { UserAuth } from '../../AuthContent';
import '../css/ZoomPic.css';


export default function ZoomVideo(props) {
    const { user } = UserAuth();




    if (!props.trigger) {
        return;
    }
    else {


        return (
            <>
                <div className='modalNewProject' style={{ zIndex: "3" }}>
                    <div style={{ position: 'relative', height: '90vh', maxWidth:"90%" }}>
                        <video
                            id="zoomVideo"
                            controls
                        >
                            <source src={props.zoomVideoURL} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                padding: '5px',
                                fontSize: '16px',
                                backgroundColor: "black",
                                borderRadius: "30%",
                                color: 'white',
                                cursor: 'pointer',
                                transform: 'translate(50%, -50%)',
                            }}
                            onClick={() => {
                                props.setTrigger(false);
                            }}
                        >
                            X
                        </div>
                    </div>
                    {/* <div style={{ position: "absolute", top: "0", right: "0", color: "black", fontSize: "30px", marginTop: "1%", padding: "2px 5px", cursor: "pointer", background: "white", borderRadius: "20px" }} onClick={() => props.setTrigger(false)}>
                        ×
                    </div>
                    <video
                       
                        style={{ width: "90vh" }}
                        controls
                    >
                        <source src={props.zoomVideoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
                    {/* <video style={{ height: "90vh" }} src={props.zoomVideoURL}></video> */}
                    {/* <div className='modal-contentNewProject' > */}

                    {/* <div className='modal-headerNewProject'>
                            
                           

                        </div>
                        <div className='modal-bodyNewProject'>
                            
                               

                        </div>
                        <div style={{ clear: 'both' }}></div>
                        <div className='modal-footerNewProject'>
                           

                        </div> */}
                    {/* </div> */}
                </div>
            </>
        );
    }

}