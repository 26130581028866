import React, { useState, useEffect } from 'react';
import '../css/NewProject.css';
import { UserAuth } from '../../AuthContent';
import CropImage from './CropImage';
import { db, storage } from '../../firebase/firebase';
import { getDownloadURL, ref, uploadBytes, listAll, getStorage, deleteObject } from 'firebase/storage';
import { updateDoc, doc } from 'firebase/firestore';
import AcknowledgeNormal from '../../AcknowledgeNormal';

 
export default function ChangeProfilePic(props) {
    const [imageUpload, setImageUpload] = useState(null);
    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
    const [acknowledgeMessage, setAcknowledgeMessage] = useState("");
    const { user } = UserAuth();

    if (!props.trigger) {
        return;
    }
    else {

        const closeAcknowledge = async () => {
            props.setTrigger(false);
           
        }

        function setPhotoUpload(event) {
            setImageUpload(event.target.files[0]);
        }

        const confirmUploadPhoto = () =>{
            if(imageUpload != null){
                uploadBytes(ref(storage, `${user.uid}/profilePic`), imageUpload).then((snapshot) => {
                    getDownloadURL(ref(storage, `${user.uid}/profilePic`)).then(async(url) => {
                      await updateDoc(doc(db, 'userInfo',user.uid),{
                        userPhotoURL: url
                      }).then(() =>{
                        setAcknowledgeMessage("Uploaded Profile");
                        setAcknowledgeNormal(true);
                      })
                    });
                  });
            }
            else{
                alert("Please choose an image to upload")
            }

         
        }

        return (
            <>
                {/* <CropImage trigger={crop} setTrigger={setCrop} portrait={portrait} setLandscape={setLandscape} allImage={selfUploadArr == null ? defaultUpload : selfUploadArr} setDefaultUpload={setDefaultUpload}></CropImage> */}
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={acknowledgeMessage} parentTrigger={props.setTrigger}></AcknowledgeNormal>
                <div className='modalNewProject' style={{ zIndex: "1" }}>
                    <div className='modal-contentAcknowledge'>
                        <div className='modal-headerNewProject'>
                        <div className="notification-close" onClick={() => closeAcknowledge()}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>PROFILE PICTURE</h4>
                        </div>
                        <div class='modal-acknowledge'>
                            <input id="file-upload" type="file" accept='image/*' style={{marginLeft:"2%"}} onChange={(event) => { setPhotoUpload(event) }} />

                            {imageUpload != null ? <p>1 files</p> : null}

                        </div>
                        <div className='modal-footerNewProject'>
                            <button className='newListingBtn' onClick={() => confirmUploadPhoto()}>Confirm</button>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </div>

            </>
        );
    }

}
