import React, { useEffect, useState, useRef } from 'react';
import { UserAuth } from '../../AuthContent';
import "../css/ReferAFriend.css";
import copy from 'copy-to-clipboard';
import { deleteField, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase/firebase'
import AcknowledgeNormal from '../../AcknowledgeNormal';



export default function ReferAFriend(props) {
    const { user } = UserAuth();
    const [iconVisible, setVisible] = useState(true);
    const [claimRewards, setClaimRewards] = useState(false);
    const [option, setOption] = useState(null);
    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
    const [messages, setMessages] = useState("");

    const toggleVisibility = () => {
        setVisible(false);

        setTimeout(() => {
            setVisible(true);
        }, 1500); // Adjust the time as needed (in milliseconds) for the desired visibility duration
    };

    const proceedRewards = async () =>{
        if(option == "Extend"){
            await updateDoc(doc(db, 'userInfo', user.uid),{
                subscriptionCounter: props.currentUserInfo.subscriptionCounter + 30,
                referFriendCode: props.currentUserInfo.referFriendCode - 1 <= 0 ? deleteField() : props.currentUserInfo.referFriendCode - 1
            }).then(() =>{
                var newCurrentUserInfo = {...props.currentUserInfo};
                newCurrentUserInfo.subscriptionCounter = props.currentUserInfo.subscriptionCounter + 30;
                if (props.currentUserInfo.referFriendCode - 1 <= 0) {
                    delete newCurrentUserInfo.referFriendCode;
                  } else {
                    newCurrentUserInfo.referFriendCode = props.currentUserInfo.referFriendCode - 1;
                  }
                  
                props.setCurrentUserInfo(newCurrentUserInfo);
                setClaimRewards(false);
                setMessages("Successfully claim your rewards")
                setAcknowledgeNormal(true);
            })
            
        }
        else if(option == "boost"){
            await updateDoc(doc(db, 'userInfo', user.uid),{
                boostCredit: props.currentUserInfo.hasOwnProperty('boostCredit') ? props.currentUserInfo.boostCredit + 3 : 3,
                referFriendCode: props.currentUserInfo.referFriendCode - 1 <= 0 ? deleteField() : props.currentUserInfo.referFriendCode - 1
            }).then(() =>{
                var newCurrentUserInfo = {...props.currentUserInfo};
                newCurrentUserInfo.boostCredit = props.currentUserInfo.hasOwnProperty('boostCredit') ? props.currentUserInfo.boostCredit + 3 : 3;
                if (props.currentUserInfo.referFriendCode - 1 <= 0) {
                    delete newCurrentUserInfo.referFriendCode;
                  } else {
                    newCurrentUserInfo.referFriendCode = props.currentUserInfo.referFriendCode - 1;
                  }
                props.setCurrentUserInfo(newCurrentUserInfo);
                setClaimRewards(false);
                setMessages("Successfully claim your rewards")
                setAcknowledgeNormal(true);
            })
        }
        else if(option == "advertising"){
            await updateDoc(doc(db, 'userInfo', user.uid),{
                advertisingCredit: props.currentUserInfo.hasOwnProperty('advertisingCredit') ? props.currentUserInfo.advertisingCredit + 3 : 3,
                referFriendCode: props.currentUserInfo.referFriendCode - 1 <= 0 ? deleteField() : props.currentUserInfo.referFriendCode - 1
            }).then(() =>{
                var newCurrentUserInfo = {...props.currentUserInfo};
                newCurrentUserInfo.advertisingCredit = props.currentUserInfo.hasOwnProperty('advertisingCredit') ? props.currentUserInfo.advertisingCredit + 3 : 3;
                if (props.currentUserInfo.referFriendCode - 1 <= 0) {
                    delete newCurrentUserInfo.referFriendCode;
                  } else {
                    newCurrentUserInfo.referFriendCode = props.currentUserInfo.referFriendCode - 1;
                  }
                props.setCurrentUserInfo(newCurrentUserInfo);
                setClaimRewards(false);
                setMessages("Successfully claim your rewards")
                setAcknowledgeNormal(true);
            })
        }
    }

    if (!props.trigger) {
        return;
    }
    else {


        return (
            <>
            <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={messages} parentTrigger={props.setTrigger}></AcknowledgeNormal> 
                <div className='modalNewProject' style={{ zIndex: "2" }}>
                    <div className='modal-contentNewProject'>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => { setVisible(true); setClaimRewards(false); setOption(null); props.setTrigger(false) }}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>REFER A FRIEND</h4>
                        </div>

                        {claimRewards == false ? <><div className='modal-bodyNewProject' style={{ position: "relative" }}>
                            {iconVisible ? null : (
                                <p
                                    id="hiddenMessage"
                                    className="hidden-message"
                                >
                                    Copied to clipboard
                                </p>
                            )}
                            <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px", marginBottom: "15px" }}>Spread the word and Reap the Rewards!</p>
                            <p style={{ textAlign: "center", marginBottom: "8px" }}>When your friend signs up using your code, both of you will recieve a special gift from us.</p>
                            <p style={{ textAlign: "center" }}>Get rewarded with 800 points</p>
                        </div>
                            <div className='modal-footerNewProject'>
                                <div style={{ display: "flex", flexDirection: "row", border: "1px solid #cbcbcb", padding: "8px", borderRadius: "8px", justifyContent: "space-between", width: "50%", marginLeft: "25%" }}>
                                    <p>{user.uid}</p>
                                    <i id={"referIcon"} className={`fa-solid fa-paperclip icon`} onClick={() => {
                                        toggleVisibility();
                                        copy(`Hello from Nextloop!\n\nAre you in search of a property and eager to explore all your options? Nextloop is your go-to solution!\n\nWe complement traditional property listings by consolidating all buyers' and tenants' requests. Simply post your property search request on nextloop.sg and let the offers come to you.\n\nJoin us at www.nextloop.sg/sign-up and receive 1000 Nextloop credits (worth SGD$1000)!\n\nUse my referral code below during sign-up, and we'll both receive and extra 800 credits: ${user.uid}`);
                                    }}></i>
                                </div>
                                {props.currentUserInfo.hasOwnProperty('referFriendCode') ?
                                    
                                    props.currentUserInfo.referFriendCode >= 1 ?
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                            {console.log(props.currentUserInfo)}
                                            <button style={{ padding: "8px", backgroundColor: "#33434D", border: "none", color: "white", marginTop: "10px", borderRadius: "8px", cursor: "pointer" }}
                                                onClick={() => setClaimRewards(true)}>Claim Rewards</button>
                                        </div> : null : null}
                            </div> </> : null}

                        {claimRewards ?
                            <>
                                <div className='modal-bodyNewProject'>
                                    <div id="claimRewardsDiv">
                                        {props.currentUserInfo.subscriptionID != null ? 
                                        <p className={option == "Extend" ? "claimRewardsOptionHighlighted":"claimRewardsOption"} onClick={() => setOption('Extend')}>Extend your plan by an extra month</p> : 
                                        <p className={"NotAvailableOption"}>Extend your plan by an extra month</p>}
                                        <p className={option == "boost" ? "claimRewardsOptionHighlighted":"claimRewardsOption"} onClick={() => setOption('boost')}>3 days of boost</p>
                                        <p className={option == "advertising" ? "claimRewardsOptionHighlighted":"claimRewardsOption"} onClick={() => setOption('advertising')}>3 days of advertising</p>
                                    </div>
                                </div>
                                <div className='modal-footerNewProject' style={{position:"relative"}}>
                                <p style={{ padding: "8px", border: "none", marginTop: "10px",color:"grey", borderRadius: "8px", cursor: "pointer", position:"absolute", left:"0", bottom:"0", margin:"8px" }}
                                >Rewards Coupon: {props.currentUserInfo.referFriendCode}</p>
                                    <button style={{ padding: "8px", backgroundColor: "#33434D", border: "none", color: "white", marginTop: "10px", borderRadius: "8px", cursor: "pointer", position:"absolute", right:"0", bottom:"0", margin:"8px" }}
                                        onClick={() => proceedRewards()}>Claim Reward</button>
                                </div>
                            </> : null}
                    </div>
                </div>
            </>
        );
    }

}