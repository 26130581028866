import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import '../css/NewProject.css';

export default function DefaultImage(props) {

    const [filterKeyword, setFilterKeyword] = useState(null);
    const [defaultArray, setDefaultArray] = useState([]);
    const [toggleSwitch, setToggleSwitch] = useState(false);
    const updatedDefaultArrayList = [];
    const [ready, setReady] = useState(false);
    const completedRenderingsRef = useRef(0);
    const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    const loadingMessages = [
        "Loading...",
        "Please wait...",
        "Almost there! Just a moment longer...",
        "We apologize for any delay. High user demand might result in slightly longer loading times.",
        "Please be patient...",
      ];

    const urlEndpoint = 'https://ik.imagekit.io/NextLoop/Default';
    const AllDefaultArr = ["/F_B/Asian/Thai_Cuisine.jpeg", "/F_B/Asian/Vietnamese_Pho_I.jpg", "/F_B/Asian/Sushi_I.jpg", "/F_B/Asian/Vietnamese_Pho_II.jpg", "/F_B/Asian/Satay_II.jpg", "/F_B/Asian/Satay_III.jpg", "/F_B/Asian/Satay_I.jpg",
        "/F_B/Asian/Korean_BBQ.jpg", "/F_B/Asian/Indian_Food_I.jpg", "/F_B/Asian/Korean_I.jpg", "/F_B/Asian/Asian_Food_I.jpg", "/F_B/Asian/Japanese_Sashimi_I.jpg", "/F_B/Asian/Dim_Sum_I.jpg", "/F_B/Asian/Korean_BBQ_II.jpg", "/F_B/Asian/Indian_Food_II.jpg",
        "/F_B/Asian/Chinese_Roast.jpg", "/F_B/Asian/Dim_Sum_II.jpg",
        "/F_B/Western/Soup_I.jpeg", "/F_B/Western/Western_I.jpg", "/F_B/Western/Steakhouse_III.jpg", "/F_B/Western/Steakhouse_I.jpeg",
        "/F_B/Western/Steakhouse_II.jpg", "/F_B/Western/Burger_I.jpg", "/F_B/Western/Pasta_Pizza_I.jpg", "/F_B/Western/Pizza_I.jpeg",
        "/F_B/Western/Pizza_II.jpeg", "/F_B/Western/Pasta_III.jpeg", "/F_B/Western/Pasta_I.jpeg", "/F_B/Western/Ribs_I.jpg",
        "/F_B/Western/Pizza_III.jpeg", "/F_B/Western/Clams.jpg", "/F_B/Western/Pasta_II.jpeg",
        "/F_B/Cakery/Cakery_IV.jpeg", "/F_B/Cakery/Cakery_II.jpeg", "/F_B/Cakery/Cakery_V.jpeg", "/F_B/Cakery/Cakery_I.jpeg", "/F_B/Cakery/Cakery_III.jpeg",
        "/F_B/Cafe/Cafe_II.jpeg", "/F_B/Cafe/Waffle_Ice_Cream.jpg", "/F_B/Cafe/Cafe_I.jpeg", "/F_B/Cafe/Cafe_III.jpg", "/F_B/Cafe/Cafe_VI.jpg", "/F_B/Cafe/Cafe_IV.jpg", "/F_B/Cafe/Cafe_V.jpg",
        "/F_B/Bar/Wine_Bar_I.jpg", "/F_B/Bar/Wine_Bar_III.jpg", "/F_B/Bar/Wine_Bar_II.jpg", "/F_B/Bar/Bar_I.jpeg", "/F_B/Bar/Bar_II.jpg",
        "/F_B/Bakery/Bakery_II.jpeg", "/F_B/Bakery/Bakery_I.jpeg",
        '/Retail/Art/Art_Gallery_I.jpg', '/Retail/Art/Art_Gallery_II.jpg', '/Retail/Art/Pottery_I.jpg', '/Retail/Art/Pottery_II.jpeg', '/Retail/Art/Pottery_III.jpg', '/Retail/Art/Vase_I.jpeg',
        '/Retail/Book_Store/Book_Store_I.jpeg', '/Retail/Book_Store/Stationery_I.jpg', '/Retail/Book_Store/Stationery_II.jpg', '/Retail/Book_Store/Stationery_III.jpg',
        '/Retail/Bottle_Shop/Bottle_Shop_I.jpeg', '/Retail/Bottle_Shop/Bottle_Shop_II.jpeg', '/Retail/Bottle_Shop/Bottle_Shop_III.jpeg', '/Retail/Bottle_Shop/Craft_Beer_Store_I.jpeg', '/Retail/Bottle_Shop/Craft_Beer_Store_II.jpeg', '/Retail/Bottle_Shop/Sake_Store_I.jpeg', '/Retail/Bottle_Shop/Sake_Store_II.jpg',
        '/Retail/Cheese_Shop/Cheese_I.jpg', '/Retail/Cheese_Shop/Cheese_II.jpg',
        '/Retail/Clothing/Clothing_I.jpeg', '/Retail/Clothing/Clothing_II.jpg', '/Retail/Clothing/Clothing_III.jpg', '/Retail/Clothing/Clothing_IV.jpeg', '/Retail/Clothing/Kids_Clothing_I.jpeg', '/Retail/Clothing/Kids_Clothing_II.jpeg', '/Retail/Clothing/Kids_Clothing_III.jpeg', '/Retail/Clothing/Men_s_Clothing_I.jpeg', '/Retail/Clothing/Men_s_Clothing_II.jpeg', '/Retail/Clothing/Men_s_Clothing_III.jpeg',
        '/Retail/Collectibles_Vintage/Model_Car.jpeg', '/Retail/Collectibles_Vintage/Vintage_Book.jpeg', '/Retail/Collectibles_Vintage/Vintage_Camera_I.jpg', '/Retail/Collectibles_Vintage/Vintage_Camera_II.jpg', '/Retail/Collectibles_Vintage/Vintage_Furniture.jpeg', '/Retail/Collectibles_Vintage/Vintage_Home_Decorations__Furnitures.jpg', '/Retail/Collectibles_Vintage/Vintage_Toys_Store.jpeg', '/Retail/Collectibles_Vintage/Vintage_Watch_I.jpg', '/Retail/Collectibles_Vintage/Vinyl_Record_I.jpeg', '/Retail/Collectibles_Vintage/Vinyl_Record_II.jpg',
        '/Retail/Confectionery/Chocolate_I.jpeg', '/Retail/Confectionery/Chocolate_II.jpeg', '/Retail/Confectionery/Chocolate_III.jpeg', '/Retail/Confectionery/Cookie_Store_I.jpeg', '/Retail/Confectionery/Cookie_Store_II.jpeg',
        '/Retail/Dessert/Cupcake_Store_I.jpeg', '/Retail/Dessert/Cupcake_Store_II.jpeg', '/Retail/Dessert/Cupcake_Store_III.jpeg', '/Retail/Dessert/Dessert_Store_I.jpeg', '/Retail/Dessert/Dessert_Store_II.jpeg', '/Retail/Dessert/Donut_Store_I.jpeg', '/Retail/Dessert/Donut_Store_II.jpeg', '/Retail/Dessert/Fruit_Tart_store_I.jpeg', '/Retail/Dessert/Fruit_Tart_Store_II.jpeg', '/Retail/Dessert/Gelato_I.jpeg', '/Retail/Dessert/Gelato_II.jpeg', '/Retail/Dessert/Ice_cream_I.jpeg', '/Retail/Dessert/Ice_Cream_II.jpeg', '/Retail/Dessert/Macaron_I.jpeg', '/Retail/Dessert/Macaron_II.jpeg', '/Retail/Dessert/Tart_Store_I.jpeg',
        '/Retail/Eco/Eco_I.jpeg', '/Retail/Eco/Eco_II.jpeg', '/Retail/Eco/Eco_III.jpg',
        '/Retail/Electronics/Audio_I.jpg', '/Retail/Electronics/Audio_II.jpg', '/Retail/Electronics/Audio_III.jpg', '/Retail/Electronics/Audio_IV.jpg', '/Retail/Electronics/Camera_I.jpg', '/Retail/Electronics/Camera_II.jpg', '/Retail/Electronics/Electronics_I.jpg', '/Retail/Electronics/Electronics_II.jpg',
        '/Retail/Event/Balloons_I.jpg', '/Retail/Event/Balloons_II.jpg', '/Retail/Event/Balloons_III.jpg', '/Retail/Event/Balloons_IV.jpg', '/Retail/Event/Chinese_Decorations_I.jpg', '/Retail/Event/Gift_Shop_I.jpg', '/Retail/Event/Gift_Shop_II.jpg', '/Retail/Event/Gift_Shop_III.jpg', '/Retail/Event/Gift_Shop_IV.jpg', '/Retail/Event/Party_Event_I.jpg', '/Retail/Event/Party_Event_II.jpg', '/Retail/Event/Party_Event_III.jpg', '/Retail/Event/Party_Event_IV.jpg', '/Retail/Event/Party_Event_V.jpg', '/Retail/Event/Party_Shop_I.jpg',
        '/Retail/Gardening/Gardening_I.jpeg', '/Retail/Gardening/Gardening_II.jpg', '/Retail/Gardening/Gardening_III.jpg', '/Retail/Gardening/Gardening_IV.jpg', '/Retail/Gardening/Plant_Store_II.jpeg', '/Retail/Gardening/Succulent_Store_I.jpeg', '/Retail/Gardening/Succulent_Store_II.jpeg',
        '/Retail/Grocer/Butchery_I.jpg', '/Retail/Grocer/Butchery_II.jpg', '/Retail/Grocer/Butchery_III.jpg', '/Retail/Grocer/Butchery_IV.jpg', '/Retail/Grocer/Butchery_V.jpg', '/Retail/Grocer/Convenience_Store_I.jpeg', '/Retail/Grocer/Convenience_Store_II.jpeg', '/Retail/Grocer/Fine_Grocer_I.jpeg', '/Retail/Grocer/Fine_Grocer_II.jpeg', '/Retail/Grocer/Fine_Grocer_III.jpg', '/Retail/Grocer/Fish_Grocer_I.jpeg', '/Retail/Grocer/Fine_Grocer_II.jpeg', '/Retail/Grocer/Fresh_Pasta_Store_I.jpeg', '/Retail/Grocer/Grocer_I.jpeg', '/Retail/Grocer/Grocer_II.jpg', '/Retail/Grocer/Seafood_Grocer_I.jpeg', '/Retail/Grocer/Seafood_Grocer_II.jpeg', '/Retail/Grocer/Seafood_Grocer_III.jpeg',
        '/Retail/Handmade/Handmade_Soap_I.jpg', '/Retail/Handmade/Handmade_Soap_II.jpg', '/Retail/Handmade/Handmade_Soap_III.jpg', '/Retail/Handmade/Leather_Products_I.jpeg', '/Retail/Handmade/Leather_Products_II.jpg', '/Retail/Handmade/Leather_Products_III.jpg',
        '/Retail/Hardware/Hardware_I.jpg', '/Retail/Hardware/Hardware_II.jpg',
        '/Retail/Home/Furniture_I.jpeg', '/Retail/Home/Furniture_II.jpg', '/Retail/Home/Furniture_III.jpg', '/Retail/Home/Home_and_Living_I.jpeg', '/Retail/Home/Home_and_Living_II.jpg', '/Retail/Home/Home_and_Living_III.jpeg',
        '/Retail/Jewellery/Beads_I.jpg', '/Retail/Jewellery/Beads_II.jpg', '/Retail/Jewellery/Crystals_I.jpg', '/Retail/Jewellery/Crystals_II.jpg', '/Retail/Jewellery/Handmade_Jewellery_I.jpg', '/Retail/Jewellery/Handmade_Jewellery_II.jpg', '/Retail/Jewellery/Jewellery_I.jpg', '/Retail/Jewellery/Jewellery_II.jpg', '/Retail/Jewellery/Jewellery_III.jpg', '/Retail/Jewellery/Pearls_I.jpg',
        '/Retail/Musical_Instruments/Musical_Instruments_I.jpg', '/Retail/Musical_Instruments/Musical_Instruments_II.jpg', '/Retail/Musical_Instruments/Musical_Instruments_III.jpeg', '/Retail/Musical_Instruments/Musical_Instruments_IV.jpg', '/Retail/Musical_Instruments/Musical_Instruments_V.jpg', '/Retail/Musical_Instruments/Musical_Instruments_VI.jpg',
        '/Retail/Toys/Plush_Toys_I.jpeg', '/Retail/Toys/Plush_Toys_II.jpg', '/Retail/Toys/Toys_I.jpeg', '/Retail/Toys/Toys_II.jpg', '/Retail/Toys/Trading_Cards_I.jpeg', '/Retail/Toys/Trading_Cards_II.jpg',
        '/Retail/Watches/Watch_Store_I.jpeg', '/Retail/Watches/Watch_Store_II.jpeg',
        '/Services/Beauty/Brow_Threading_I.jpeg', '/Services/Beauty/Facial_Spa_I.jpeg', '/Services/Beauty/Foot_Reflexology_I.jpeg', '/Services/Beauty/Manicure_Pedicure_I.jpeg', '/Services/Beauty/Manicure_Pedicure_II.jpeg', '/Services/Beauty/Saloon_I.jpeg', '/Services/Beauty/Spa_I.jpeg', '/Services/Beauty/Spa_II.jpeg', '/Services/Beauty/Spa_III.jpg',
        '/Services/Entertainment/Capsules_Machines.jpeg', '/Services/Entertainment/Claw_Machines_I.jpeg', '/Services/Entertainment/Claw_Machines_II.jpeg', '/Services/Entertainment/Claw_Machines_III.jpeg',
        '/Services/Florist/Florist_I.jpeg', '/Services/Florist/Florist_II.jpeg', '/Services/Florist/Florist_III.jpeg',
        '/Services/Keysmith/Keysmith_I.jpg',
        '/Services/Laundry/Laundromat_I.jpeg', '/Services/Laundry/Laundry_I.jpg', '/Services/Laundry/Laundry_II.jpg', '/Services/Laundry/Laundry_III.jpg',
        '/Services/Optical/Optical_Store_I.jpeg', '/Services/Optical/Optical_Store_II.jpeg',
        '/Services/Pets/Pets_I.jpg', '/Services/Pets/Pets_II.jpeg', '/Services/Pets/Pets_III.jpeg',
        '/Services/Printing/Custom_Trophy_I.jpg', '/Services/Printing/Printing_I.jpg', '/Services/Printing/Printing_II.jpg',
        '/Services/Repairs/Car_Workshop_I.jpg', '/Services/Repairs/Watch_Repair.jpeg',
        '/Services/Schools/Ballet_School_I.jpeg', '/Services/Schools/Ballet_School_II.jpeg', '/Services/Schools/Ballet_School_III.jpeg', '/Services/Schools/Boxing_School_I.jpeg', '/Services/Schools/Boxing_School_II.jpeg', '/Services/Schools/Boxing_School_III.jpeg', '/Services/Schools/Kids_Art_School_I.jpeg', '/Services/Schools/Kids_Art_School_II.jpeg', '/Services/Schools/Kids_Art_School_III.jpeg', '/Services/Schools/Martial_Arts_School_I.jpeg', '/Services/Schools/Music_School_I.jpg', '/Services/Schools/Music_School_II.jpg', '/Services/Schools/Music_School_III.jpg', '/Services/Schools/Music_School_IV.jpg', '/Services/Schools/Music_School_VI.jpg', '/Services/Schools/Music_School_VII.jpg', '/Services/Schools/Pottery_School_I.jpeg', '/Services/Schools/Pottery_School_II.jpeg', '/Services/Schools/Rock_Climbing_School_I.jpeg', '/Services/Schools/Rock_Climbing_School_II.jpeg', '/Services/Schools/Taekwondo_School_I.jpeg', '/Services/Schools/Taekwondo_School_II.jpeg',
        '/Services/Sports/Archery_I.jpg', '/Services/Sports/Badminton_I.jpg', '/Services/Sports/Bicycle_I.jpg', '/Services/Sports/Bicycle_II.jpg', '/Services/Sports/Fencing_I.jpg', '/Services/Sports/Golf_I.jpg', '/Services/Sports/Golf_II.jpg', '/Services/Sports/Gym_I.jpeg', '/Services/Sports/Gym_II.jpeg', '/Services/Sports/Gym_III.jpeg', '/Services/Sports/Gymnastics_I.jpg', '/Services/Sports/Indoor_Court_I.jpg', '/Services/Sports/Mountain_Biking_I.jpg', '/Services/Sports/Rugby_I.jpg', '/Services/Sports/Skateboard_I.jpeg', '/Services/Sports/Soccer_I.jpg', '/Services/Sports/Squash_I.jpg', '/Services/Sports/Swimming_I.jpg', '/Services/Sports/Tennis_I.jpg', '/Services/Sports/Volleyball_I.jpg', '/Services/Sports/Water_Polo_I.jpg', '/Services/Sports/Yoga_I.jpeg', '/Services/Sports/Yoga_III.jpeg', '/Services/Sports/Yoga_Studio_I.jpeg',
        '/Services/Storage/Storage_I.jpg', '/Services/Storage/Storage_II.jpg',
        '/Services/Studio/Studio_I.jpg', '/Services/Studio/Studio_II.jpg', '/Services/Studio/Studio_III.jpg', '/Services/Studio/Studio_IV.jpg'
    ]

    // const photosDiv = ['bakeryPhotos', 'AsianPhotos', 'BarPhotos', 'CafePhotos', 'CakeryPhotos', 'WesternPhotos', 'ArtPhotos', 'BookStorePhotos', 'BottleShopPhotos', 'Cheese_ShopPhotos', 'ClothingPhotos', 'Collectibles_VintagePhotos', 'ConfectioneryPhotos', 'DessertPhotos', 'EcoPhotos', 'ElectronicsPhotos', 'EventPhotos', 'GardeningPhotos', 'GrocerPhotos', 'HandmadePhotos', 'HardwarePhotos', 'HomePhotos', 'JewelleryPhotos', 'Musical_InstrumentsPhotos', 'ToysPhotos', 'WatchesPhotos', 'BeautyPhotos', 'EntertainmentPhotos', 'FloristPhotos', 'KeysmithPhotos', 'LaundryPhotos', 'OpticalPhotos', 'PetsPhotos', 'PrintingPhotos', 'RepairsPhotos', 'SchoolsPhotos', 'SportsPhotos', 'StoragePhotos', 'StudioPhotos']
    const categoryToTargetDivMap = {
        Bakery: "bakeryPhotos",
        Asian: "AsianPhotos",
        Bar: "BarPhotos",
        Cafe: 'CafePhotos',
        Cakery: 'CakeryPhotos',
        Western: 'WesternPhotos',
        Art: 'ArtPhotos',
        Book_Store: 'BookStorePhotos',
        Bottle_Shop: 'BottleShopPhotos',
        Cheese_Shop: 'Cheese_ShopPhotos',
        Clothing: 'ClothingPhotos',
        Collectibles_Vintage: 'Collectibles_VintagePhotos',
        Confectionery: 'ConfectioneryPhotos',
        Dessert: 'DessertPhotos',
        Eco: 'EcoPhotos',
        Electronics: 'ElectronicsPhotos',
        Event: 'EventPhotos',
        Gardening: 'GardeningPhotos',
        Grocer: 'GrocerPhotos',
        Handmade: 'HandmadePhotos',
        Hardware: 'HardwarePhotos',
        Home: 'HomePhotos',
        Jewellery: 'JewelleryPhotos',
        Musical_Instruments: 'Musical_InstrumentsPhotos',
        Toys: 'ToysPhotos',
        Watches: 'WatchesPhotos',
        Beauty: 'BeautyPhotos',
        Entertainment: 'EntertainmentPhotos',
        Florist: 'FloristPhotos',
        Keysmith: 'KeysmithPhotos',
        Laundry: 'LaundryPhotos',
        Optical: 'OpticalPhotos',
        Pets: 'PetsPhotos',
        Printing: 'PrintingPhotos',
        Repairs: 'RepairsPhotos',
        Schools: 'SchoolsPhotos',
        Sports: 'SportsPhotos',
        Storage: 'StoragePhotos',
        Studio: 'StudioPhotos'
        // Add more categories here as needed
    };

    useEffect(() => {
        // Interval to change the loading message every 8 seconds
        const interval = setInterval(() => {
          setLoadingMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
        }, 8000);
    
        // Clear the interval when the component is unmounted
        return () => clearInterval(interval);
      }, []);

    const printPicture = (stringValue, targetDiv) => {
        var img = new Image();
        var div = document.createElement("div");
        div.className = "w-1/4 p-2";
        img.src = urlEndpoint + stringValue;
        // if (filterKeyword != null && document.getElementById('renderingPictureArea') != null) {
        //     var parentDiv = document.getElementById(targetDiv);
        //     while (parentDiv.firstChild) {
        //         parentDiv.removeChild(parentDiv.lastChild);
        //     }
        // }
        img.onload = () => {
            // var newCompletedRenderings = completedRenderings + 1;
            completedRenderingsRef.current += 1;
            
            console.log(completedRenderingsRef.current)
            if (completedRenderingsRef.current  >= 20) {
                setLoading(false); // Hide the loading icon
            }
            if (img.height > img.width) {

                var img1 = document.createElement('img');
                img1.src = urlEndpoint + "/tr:h-300,w-200" + stringValue;
                img1.id = stringValue;
                img1.onclick = function () { select(stringValue, urlEndpoint + "/tr:h-300,w-200" + stringValue) };
                img1.loading = "lazy";
                img1.className = "defaultPhotoPotrait";
                // parentDiv.appendChild(img1);
            }
            else {
                // var parentDiv = document.getElementById(targetDiv);
                var img1 = document.createElement('img');
                img1.src = urlEndpoint + "/tr:h-200,w-300" + stringValue;
                img1.id = stringValue;
                img1.onclick = function () { select(stringValue, urlEndpoint + "/tr:h-300,w-200" + stringValue) };
                img1.loading = "lazy";
                img1.className = "defaultPhoto";

            }
            div.appendChild(img1);
            var parentDiv = document.getElementById(targetDiv);
            parentDiv.appendChild(div);
        }

    }

    const printPicturesForCategory = (category, targetDiv) => {
        const filteredImages = AllDefaultArr.filter((res) => res.includes(category));
        const parentDiv = document.getElementById(targetDiv);
        if (parentDiv) {
            parentDiv.innerHTML = ''; // Clear existing images
            filteredImages.forEach((res) => {
                printPicture(res, targetDiv);
            });
        }
    };

    const handleFilterSelection = (selectedCategory) => {
        const renderingPictureArea = document.getElementById("renderingPictureArea");
        if (renderingPictureArea) {
            Object.keys(categoryToTargetDivMap).forEach((category) => {

                const targetDiv = categoryToTargetDivMap[category];
                console.log(category, selectedCategory);
                if (category === selectedCategory) {

                    printPicturesForCategory(category, targetDiv);
                } else {
                    const parentDiv = document.getElementById(targetDiv);
                    if (parentDiv) {
                        parentDiv.innerHTML = ''; // Clear other categories
                    }
                }
            });
        }
    };

    useEffect(() => {
        const renderingPictureArea = document.getElementById("renderingPictureArea");
        if (renderingPictureArea) {
            Object.keys(categoryToTargetDivMap).forEach((category) => {
                // console.log(category);
                const targetDiv = categoryToTargetDivMap[category];
                printPicturesForCategory(category, targetDiv);
            });
        }
    }, []);

    useEffect(() => {
        const images = document.querySelectorAll(".defaultPhotoSelect");
        const images_1 = document.querySelectorAll(".defaultPhotoSelectPotrait");

        const selectedImages = [];

        // loop through the images and push the src of each image into selectedImages
        images.forEach((img) => {
            selectedImages.push({ id: img.id, url: img.src });
        });

        // loop through the images_1 and push the src of each image into selectedImages
        images_1.forEach((img) => {
            selectedImages.push({ id: img.id, url: img.src });
        });

        // update the state with the selectedImages array
        setDefaultArray(selectedImages);
    }, [toggleSwitch]);



    const select = (id, url) => {
        var pass = false;
        const images = document.querySelectorAll(".defaultPhotoSelect");
        const images_1 = document.querySelectorAll(".defaultPhotoSelectPotrait");

        const selectedImages = [];

        // loop through the images and push the src of each image into selectedImages
        images.forEach((img) => {
            if (id == img.id) {
                pass = true;

            }
            selectedImages.push({ id: img.id, url: img.src });
        });

        // loop through the images_1 and push the src of each image into selectedImages
        images_1.forEach((img) => {
            if (id == img.id) {
                pass = true;
            }
            selectedImages.push({ id: img.id, url: img.src });
        });
        if (props.alreadyImageUpload != null) {
            if (selectedImages.length >= 5 || (selectedImages.length + props.alreadyImageUpload.length >= 5)) {
                if (pass == false) {
                    if (props.alreadyImageUpload.length == 0) {
                        alert("Maximum of 5 photos");
                        return;
                    }
                    else {
                        alert("Maximum of 5 photos including the existing photos");
                        return;
                    }
                }

            }
        }
        else {
            if (selectedImages.length >= 5) {
                alert("You have already selected 5 photos");
                return;
                // if (pass == false) {
                //     if (props.alreadyImageUpload.length == 0) {
                //         alert("Maximum of 5 photos");
                //         return;
                //     }
                //     else {
                //         alert("Maximum of 5 photos including the existing photos");
                //         return;
                //     }
                // }

            }
        }


        var target = document.getElementById(id);

        if (target.className == "defaultPhoto") {
            target.className = "defaultPhotoSelect";
            // setToggleSwitch(!toggleSwitch);

        } else if (target.className == "defaultPhotoSelect") {
            target.className = "defaultPhoto";
            // setToggleSwitch(!toggleSwitch);

        } else if (target.className == "defaultPhotoPotrait") {
            target.className = "defaultPhotoSelectPotrait";
            // setToggleSwitch(!toggleSwitch);

        } else if (target.className == "defaultPhotoSelectPotrait") {
            target.className = "defaultPhotoPotrait";
            // setToggleSwitch(!toggleSwitch);

        }
        setToggleSwitch((prevState) => !prevState);

    };

    const deleteDefault = (id) => {

        var target = document.getElementById(id);

        if (target.className == "defaultPhotoSelect") {
            target.className = "defaultPhoto";
            // setToggleSwitch(!toggleSwitch);

        } else if (target.className == "defaultPhotoSelectPotrait") {
            target.className = "defaultPhotoPotrait";
            // setToggleSwitch(!toggleSwitch);

        }
        setToggleSwitch((prevState) => !prevState);
    }

    if (!props.trigger) {
        return;
    }
    else {

        const closeAcknowledge = async () => {
            setFilterKeyword(null);
            // setReady(false);
            completedRenderingsRef.current = 0;
            props.setTrigger(false);

        }

        function confirmImage() {
            var selectedPhotoURL = [];

            var selectedPhoto = document.querySelectorAll(".defaultPhotoSelect");
            for (var i = 0; i < selectedPhoto.length; i++) {
                selectedPhotoURL.push(selectedPhoto[i].src);
            }
            var selectedPhoto = document.querySelectorAll(".defaultPhotoSelectPotrait");
            for (var i = 0; i < selectedPhoto.length; i++) {
                selectedPhotoURL.push(selectedPhoto[i].src);
            }
            props.setDefaultUpload(selectedPhotoURL);
            props.setTrigger(false);
        }

        const cleanAllPicture = (target) => {
            var child = target.lastElementChild;
            while (child) {
                target.removeChild(child);
                child = target.lastElementChild;
            }
        }


        return (
            <>
                <div className='modalNewProject'>
                    <div className='modal-contentDefaultImage' style={{ position: "relative" }}>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => closeAcknowledge()}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>DEFAULT IMAGE</h4>
                            {loading ? <div style={{textAlign:"center", marginTop:"5%", marginBottom:"5%"}}>{loadingMessages[loadingMessageIndex]}</div> : null}
                            <div id="defaultButton"  style={loading == false ? {display:"block"}:{display:"none"}}>

                                <div class="dropdown" style={{ marginLeft: "2%" }}>
                                    <button class="dropbtn">F&B</button>
                                    <div class="dropdown-content">
                                        <a onClick={() => { handleFilterSelection('Bakery') }}>Bakery</a>
                                        <a onClick={() => { handleFilterSelection('Asian') }}>Asian</a>
                                        <a onClick={() => { handleFilterSelection('Bar') }}>Bar</a>
                                        <a onClick={() => { handleFilterSelection('Western') }}>Western</a>
                                        <a onClick={() => { handleFilterSelection('Cafe') }}>Cafe</a>
                                        <a onClick={() => { handleFilterSelection('Cakery') }}>Cakery</a>
                                    </div>
                                </div>

                                <div class="dropdown" style={{ marginLeft: "2%" }}>
                                    <button class="dropbtn">Retail</button>
                                    <div class="dropdown-content">
                                        <a onClick={() => { handleFilterSelection('Art') }}>Art</a>
                                        <a onClick={() => { handleFilterSelection('Book_Store') }}>Book Store</a>
                                        <a onClick={() => { handleFilterSelection('Bottle_Shop') }}>Bottle Shop</a>
                                        <a onClick={() => { handleFilterSelection('Cheese_Shop') }}>Cheese Shop</a>
                                        <a onClick={() => { handleFilterSelection('Clothing') }}>Clothing</a>
                                        <a onClick={() => { handleFilterSelection('Collectibles_Vintage') }}>Collectibles Vintage</a>
                                        <a onClick={() => { handleFilterSelection('Confectionery') }}>Confectionery</a>
                                        <a onClick={() => { handleFilterSelection('Dessert') }}>Desserts</a>
                                        <a onClick={() => { handleFilterSelection('Eco') }}>Eco</a>
                                        <a onClick={() => { handleFilterSelection('Electronics') }}>Electronics</a>
                                        <a onClick={() => { handleFilterSelection('Event') }}>Events</a>
                                        <a onClick={() => { handleFilterSelection('Gardening') }}>Gardening</a>
                                        <a onClick={() => { handleFilterSelection('Grocer') }}>Grocer</a>
                                        <a onClick={() => { handleFilterSelection('Handmade') }}>Handmade - Bespoke</a>
                                        <a onClick={() => { handleFilterSelection('Hardware') }}>Hardware</a>
                                        <a onClick={() => { handleFilterSelection('Home') }}>Home</a>
                                        <a onClick={() => { handleFilterSelection('Jewellery') }}>Jewellery</a>
                                        <a onClick={() => { handleFilterSelection('Musical_Instruments') }}>Musical Instruments</a>
                                        <a onClick={() => { handleFilterSelection('Toys') }}>Toys</a>
                                        <a onClick={() => { handleFilterSelection('Watches') }}>Watches</a>
                                    </div>
                                </div>


                                <div class="dropdown" style={{ marginLeft: "2%" }}>
                                    <button class="dropbtn">Services</button>
                                    <div class="dropdown-content">
                                        <a onClick={() => { handleFilterSelection('Beauty') }}>Beauty & Spa</a>
                                        <a onClick={() => { handleFilterSelection('Entertainment') }}>Entertainment</a>
                                        <a onClick={() => { handleFilterSelection('Florist') }}>Florist</a>
                                        <a onClick={() => { handleFilterSelection('Keysmith') }}>Keysmith</a>
                                        <a onClick={() => { handleFilterSelection('Laundry') }}>Laundry</a>
                                        <a onClick={() => { handleFilterSelection('Optical') }}>Optical</a>
                                        <a onClick={() => { handleFilterSelection('Pets') }}>Pets</a>
                                        <a onClick={() => { handleFilterSelection('Printing') }}>Printing</a>
                                        <a onClick={() => { handleFilterSelection('Repairs') }}>Repairs</a>
                                        <a onClick={() => { handleFilterSelection('Schools') }}>Schools</a>
                                        <a onClick={() => { handleFilterSelection('Sports') }}>Sports</a>
                                        <a onClick={() => { handleFilterSelection('Storage') }}>Storage</a>
                                        <a onClick={() => { handleFilterSelection('Studio') }}>Studio</a>
                                    </div>
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <br></br>
                        
                        <div id="renderingPictureArea" className="flex flex-wrap" style={loading == false ? {display:"flex"}:{display:"none"}}>
                            <span id="bakeryPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="AsianPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="BarPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="CafePhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="CakeryPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="WesternPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="ArtPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="BookStorePhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="BottleShopPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="Cheese_ShopPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="ClothingPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="Collectibles_VintagePhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="ConfectioneryPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="DessertPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="EcoPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="ElectronicsPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="EventPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="GardeningPhotos" className='flex flex-wrap w-full'> 
                            </span>
                            <span id="GrocerPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="HandmadePhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="HardwarePhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="HomePhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="JewelleryPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="Musical_InstrumentsPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="ToysPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="WatchesPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="BeautyPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="EntertainmentPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="FloristPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="KeysmithPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="LaundryPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="OpticalPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="PetsPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="PrintingPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="RepairsPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="SchoolsPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="SportsPhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="StoragePhotos" className='flex flex-wrap w-full'>
                            </span>
                            <span id="StudioPhotos" className='flex flex-wrap w-full'>
                            </span>

                        </div>
                        <div className='modal-footerNewProject' style={{ position: "sticky", bottom: "0", left: "0", right: "0", background: "white" }}>
                            {defaultArray.length != 0 ? defaultArray.map((indivImg) => {
                                return (
                                    <>
                                        <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                                            <img src={indivImg.url} style={{ width: "40px", height: "40px", marginRight: "1%" }}></img><span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "transparent", color: "red", padding: "1px", cursor: "pointer" }} onClick={() => deleteDefault(indivImg.id)}>x</span>
                                        </div>
                                    </>
                                );
                            }) : <p>No Photo Selected</p>}
                            <button className='newListingBtn' onClick={() => confirmImage()}>Select Images</button>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </div>

            </>
        );
    }

}
