import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import Footer from '../Footer';
import CalendarComponent from '../CalendarComponent';
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";

const RentalDetailPage = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const docRef = doc(db, 'Rental', projectId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setProject(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (err) {
        console.error('Error fetching document:', err);
        setError('Error fetching project details.');
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return (<div className="flex items-center justify-center">
      <div role="alert" className="rounded border-s-4 border-red-500 bg-red-50 p-4 absolute top-0 mt-10 z-50">
        <strong className="block font-medium text-red-800">Something went wrong</strong>

        <p className="mt-2 text-sm text-red-700">
          There is a missing value or invalid value
        </p>
      </div> </div>);
  }

  return (
    <>
      <div className="container mx-auto p-4">
        {project ? (
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="lg:w-1/2">
              {project.imageURL && <img src={project.imageURL} alt={project.title} className="w-full h-auto" />}
            </div>
            <div className="lg:w-1/2 flex flex-col gap-2">
              <h1 className="mb-4 text-lg font-medium leading-none text-gray-900">{project.title}</h1>
              {project.brand && (
                <p className='detail-right-text-leftblock mb-2 text-sm font-medium text-gray-900'>
                  <span >Brand:</span> {project.brand}
                </p>
              )}
              {project.location && (
                <p className='detail-right-text-leftblock mb-2 text-sm font-medium text-gray-900'>
                  <span>Location:</span> {project.location}
                </p>
              )}
              {project.description && (
                <p className='detail-right-text-leftblock mb-2 text-sm font-medium text-gray-900'>
                  <span>Description:</span> {project.description}
                </p>
              )}
              {project.keyword && project.keyword.length > 0 && (
                <p className='detail-right-text-leftblock mb-2 text-sm font-medium text-gray-900'>
                  <span>Keyword:</span> {project.keyword.join(', ')}
                </p>
              )}

              {project.condition && (
                <p className='detail-right-text-leftblock mb-2 text-sm font-medium text-gray-900'>
                  <span>Condition:</span> {project.condition}
                </p>
              )}
              {project.rules && (
                <p className='detail-right-text-leftblock mb-2 text-sm font-medium text-gray-900'>
                  <span>Rental Rules:</span> {project.rules}
                </p>
              )}
              {project.hasOwnProperty('rentalFree') ?
                <div className="flex items-center space-x-2">
                  <p id="Card_price" className="text-center pl-2 pt-4">Free</p>
                </div>
                : <div className="grid grid-cols-3 gap-0">
                  <div className="py-4">
                    <p id="Card_price" className="text-center">{project.rentalDaily != null ? `$${project.rentalDaily}` : `NA`}</p>
                    <p id="monthlyRentalBudget" className="text-center">Daily</p>
                  </div>
                  <div className="py-4 ">
                    <p id="Card_price" className="text-center">{project.rentalWeekly != null ? `$${project.rentalWeekly}` : `NA`}</p>
                    <p id="monthlyRentalBudget" className="text-center">Weekly</p>
                  </div>
                  <div className="py-4 ">
                    <p id="Card_price" className="text-center">{project.rentalMonthly != null ? `$${project.rentalMonthly}` : `NA`}</p>
                    <p id="monthlyRentalBudget" className="text-center">Monthly</p>
                  </div>
                </div>}

              
                
                <CalendarComponent dataArray={project.calendarQuantity}></CalendarComponent>
<GooglePlayButton
          url={'https://play.google.com/store/apps/details?id=com.mycompany.nextlabor&hl=en-SG'}
          theme={"light"}
          className={"custom-style"}
        />
        <div className='py-4'>
        <AppStoreButton
          url={'https://apps.apple.com/sg/app/nextloop-rental-marketplace/id6535674656'}
          theme={"light"}
          className={"custom-style"}
        />
        </div>
              {/* {project.pickupAvailability && (
                <p className="text-lg">
                  <strong>Pick Up Availability:</strong> {project.pickupAvailability}
                </p>
              )} */}
            </div>
          </div>
        ) : (
          <p>No project details available.</p>
        )}
      </div>
      <Footer></Footer>
    </>

  );
};

export default RentalDetailPage;
