import React, { useEffect, useState, useRef } from 'react';
import { UserAuth } from '../../AuthContent';
import { db, storage } from '../../firebase/firebase'
import { setDoc, doc, getDoc, updateDoc, collection, getDocs, where, query, deleteDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes, deleteObject, uploadString, getBlob } from 'firebase/storage';


export default function AddBusiness(props) {
    const { user } = UserAuth();

    const [walkInBreakdown, setWalkInBreakdown] = useState(50);
    const [regularBreakdown, setRegularBreakdown] = useState(50);
    const [localBreakdown, setLocalBreakdown] = useState(50);
    const [touristBreakdown, setTouristBreakdown] = useState(50);


    const [deckUpload, setDeckUpload] = useState(null);
    const [productPricing, setProductPricing] = useState(null);
    const [companyName, setCompanyName] = useState("");
    const [paidUpCapital, setPaidUpCapital] = useState("< 100K");

    const [spinnerActivated, setSpinnerActivated] = useState(false);


    function handlePaidUpChanges(event) {
        setPaidUpCapital(event.target.value);
    }


    const handleWalkInChange = (e) => {
        const newWalkIn = parseInt(e.target.value);
        const newRegular = 100 - newWalkIn;
        setWalkInBreakdown(newWalkIn);
        setRegularBreakdown(newRegular);
    };

    const handleRegularChange = (e) => {
        const newRegular = parseInt(e.target.value);
        const newWalkIn = 100 - newRegular;
        setWalkInBreakdown(newWalkIn);
        setRegularBreakdown(newRegular);
    };

    const handleLocalChange = (e) => {
        const localBreakdown = parseInt(e.target.value);
        const touristBreakdown = 100 - localBreakdown;
        setTouristBreakdown(touristBreakdown);
        setLocalBreakdown(localBreakdown);
    };

    const handleTouristChange = (e) => {
        const touristBreakdown = parseInt(e.target.value);
        const localBreakdown = 100 - touristBreakdown;
        setTouristBreakdown(touristBreakdown);
        setLocalBreakdown(localBreakdown);
    };

    const closeAddBusiness = () => {
        setTouristBreakdown(50);
        setLocalBreakdown(50);
        setRegularBreakdown(50);
        setWalkInBreakdown(50);
        setPaidUpCapital('< 100K');
        setDeckUpload(null);
        setProductPricing(null);
        setSpinnerActivated(false);
        setCompanyName('');
        props.setModifyBusinessIndex(null);
        props.setTrigger(false);
    }

    const handleCompanyNameChanges = (e) => {
        setCompanyName(e.target.value);
    }



    const addBusinessToFirebase = () => {
        setSpinnerActivated(true);
        var deckURL = null;
        var productPricingURL = null;
        try {
            for (var i = 0; i < props.accountBusinessArray.length; i++) {
                if (props.accountBusinessArray[i].companyName == companyName) {
                    alert("Duplicate Company Name");
                    setSpinnerActivated(false);
                    return;
                }
            }
            if (document.getElementById("companyName").value != "" && deckUpload != null && productPricing != null) {
                uploadBytes(ref(storage, `${user.uid}/${companyName}/companyDeck`), deckUpload).then((snapshot) => {
                    getDownloadURL(ref(storage, `${user.uid}/${companyName}/companyDeck`)).then((url) => {
                        deckURL = url;

                        uploadBytes(ref(storage, `${user.uid}/${companyName}/productPricing`), productPricing).then((snapshot) => {
                            getDownloadURL(ref(storage, `${user.uid}/${companyName}/productPricing`)).then(async (url) => {
                                productPricingURL = url;
                                var newBusiness = {
                                    companyName: companyName,
                                    paidUpCapital: paidUpCapital,
                                    walkInBreakdown: walkInBreakdown,
                                    regularBreakdown: regularBreakdown,
                                    localBreakdown: localBreakdown,
                                    touristBreakdown: touristBreakdown,
                                    productPricing: productPricingURL,
                                    deckUpload: deckURL
                                }
                                //check if got any pass record
                                if (props.accountBusinessArray != null) {
                                    var businessArray = props.accountBusinessArray;
                                }
                                else {
                                    var businessArray = [];
                                }

                                businessArray.push(newBusiness);
                                await updateDoc(doc(db, `userInfo`, user.uid), {
                                    businessArray: businessArray
                                });
                                setSpinnerActivated(false);
                                closeAddBusiness();
                            })
                        })
                    })
                })
            }
            else {
                alert("All infomation are required");
                setSpinnerActivated(false);
                return;
            }
        }
        catch (error) {
            console.log(error);
            closeAddBusiness();
        }
    }

    useEffect(() => {
        if (!props.trigger) {
            return;
        }

    }, [user, props.trigger])

    useEffect(() => {
        if (props.accountBusinessArray.length != 0) {
            setWalkInBreakdown(props.modifyBusinessIndex != null
                ? props.accountBusinessArray[props.modifyBusinessIndex].walkInBreakdown
                : 50);
            setRegularBreakdown(props.modifyBusinessIndex != null
                ? props.accountBusinessArray[props.modifyBusinessIndex].regularBreakdown
                : 50);
            setLocalBreakdown(props.modifyBusinessIndex != null
                ? props.accountBusinessArray[props.modifyBusinessIndex].localBreakdown
                : 50);
            setTouristBreakdown(props.modifyBusinessIndex != null ?
                props.accountBusinessArray[props.modifyBusinessIndex].touristBreakdown
                : 50);
            setCompanyName(props.modifyBusinessIndex != null ?
                props.accountBusinessArray[props.modifyBusinessIndex].companyName : "");
            setPaidUpCapital(props.modifyBusinessIndex != null ?
                props.accountBusinessArray[props.modifyBusinessIndex].paidUpCapital : "< 100K");
        }

    }, [props]);

    useEffect(() => {
        if (props.modifyBusinessIndex != null) {
            document.getElementById('companyName').setAttribute('disabled', '');
        }
    }, [companyName])

    function setFileUpload(event) {
        setDeckUpload(event.target.files[0]);
    }

    function setFileUploadProduct_pricing(event) {
        setProductPricing(event.target.files[0]);
    }
    const updateBusinessToFirebase = async () => {
        var oldName = props.accountBusinessArray[props.modifyBusinessIndex].companyName;
        setSpinnerActivated(true);
        try {
            if (deckUpload != null && productPricing != null) {
                var deckURL = null;
                var productPricingURL = null;
                uploadBytes(ref(storage, `${user.uid}/${companyName}/companyDeck`), deckUpload).then((snapshot) => {
                    getDownloadURL(ref(storage, `${user.uid}/${companyName}/companyDeck`)).then((url) => {
                        deckURL = url;

                        uploadBytes(ref(storage, `${user.uid}/${companyName}/productPricing`), productPricing).then((snapshot) => {
                            getDownloadURL(ref(storage, `${user.uid}/${companyName}/productPricing`)).then(async (url) => {
                                productPricingURL = url;
                                var newBusiness = {
                                    companyName: companyName,
                                    paidUpCapital: paidUpCapital,
                                    walkInBreakdown: walkInBreakdown,
                                    regularBreakdown: regularBreakdown,
                                    localBreakdown: localBreakdown,
                                    touristBreakdown: touristBreakdown,
                                    productPricing: productPricingURL,
                                    deckUpload: deckURL
                                }
                                if (props.accountBusinessArray != null) {
                                    var businessArray = props.accountBusinessArray;
                                }
                                else {
                                    var businessArray = [];
                                }

                                var getallPrefferedListing = await getDocs(query(collection(db, "projects"), where("UID", "==", user.uid)))
                                getallPrefferedListing.forEach(async (indivDoc) => {
                                    await getDoc(doc(db, `projects`, indivDoc.id)).then(async (indivDetails) => {
                                        if (indivDetails.data().preferredListing == true) {
                                            await getDocs(collection(db, `projects/${indivDoc.id}/PreferredDetails`)).then(async (indivPreferred) => {
                                                indivPreferred.forEach(async (indivPreferredDetails) => {
                                                    if (indivPreferredDetails.data().autoFillLink == oldName) {
                                                        var preferredDetails = indivPreferredDetails.data();
                                                        preferredDetails.autoFillLink = companyName;
                                                        preferredDetails.clinteleBreakdownLocal = localBreakdown;
                                                        preferredDetails.clinteleBreakdownTourist = touristBreakdown;
                                                        preferredDetails.companyDeckURL = deckURL;
                                                        preferredDetails.companyName = companyName;
                                                        preferredDetails.paidUpCaptial = paidUpCapital;
                                                        preferredDetails.product_pricing = productPricingURL;
                                                        preferredDetails.revenueBreakdownRegular = regularBreakdown;
                                                        preferredDetails.revenueBreakdownWalkin = walkInBreakdown;
                                                        await updateDoc(doc(db, `projects/${indivDoc.id}/PreferredDetails/${indivPreferredDetails.id}`), preferredDetails)
                                                    }
                                                })
                                                businessArray[props.modifyBusinessIndex] = newBusiness;
                                                await updateDoc(doc(db, `userInfo`, user.uid), {
                                                    businessArray: businessArray
                                                }).then(() => {
                                                    props.setAccountBusinessArray(businessArray);
                                                    setSpinnerActivated(false);
                                                    closeAddBusiness();
                                                });
                                            })
                                        }
                                    })
                                })
                            })
                        })
                    })
                })
            }
            else if (deckUpload != null && productPricing == null) {
                var deckURL = null;
                var productPricingURL = props.accountBusinessArray[props.modifyBusinessIndex].productPricing;
                uploadBytes(ref(storage, `${user.uid}/${companyName}/companyDeck`), deckUpload).then((snapshot) => {
                    getDownloadURL(ref(storage, `${user.uid}/${companyName}/companyDeck`)).then(async (url) => {
                        deckURL = url;
                        var newBusiness = {
                            companyName: companyName,
                            paidUpCapital: paidUpCapital,
                            walkInBreakdown: walkInBreakdown,
                            regularBreakdown: regularBreakdown,
                            localBreakdown: localBreakdown,
                            touristBreakdown: touristBreakdown,
                            productPricing: productPricingURL,
                            deckUpload: deckURL
                        }
                        if (props.accountBusinessArray != null) {
                            var businessArray = props.accountBusinessArray;
                        }
                        else {
                            var businessArray = [];
                        }

                        var getallPrefferedListing = await getDocs(query(collection(db, "projects"), where("UID", "==", user.uid)))
                        getallPrefferedListing.forEach(async (indivDoc) => {
                            await getDoc(doc(db, `projects`, indivDoc.id)).then(async (indivDetails) => {
                                if (indivDetails.data().preferredListing == true) {
                                    await getDocs(collection(db, `projects/${indivDoc.id}/PreferredDetails`)).then(async (indivPreferred) => {
                                        indivPreferred.forEach(async (indivPreferredDetails) => {
                                            if (indivPreferredDetails.data().autoFillLink == oldName) {
                                                var preferredDetails = indivPreferredDetails.data();
                                                preferredDetails.autoFillLink = companyName;
                                                preferredDetails.clinteleBreakdownLocal = localBreakdown;
                                                preferredDetails.clinteleBreakdownTourist = touristBreakdown;
                                                preferredDetails.companyDeckURL = deckURL;
                                                preferredDetails.companyName = companyName;
                                                preferredDetails.paidUpCaptial = paidUpCapital;
                                                preferredDetails.product_pricing = productPricingURL;
                                                preferredDetails.revenueBreakdownRegular = regularBreakdown;
                                                preferredDetails.revenueBreakdownWalkin = walkInBreakdown;
                                                await updateDoc(doc(db, `projects/${indivDoc.id}/PreferredDetails/${indivPreferredDetails.id}`), preferredDetails)
                                            }
                                        })
                                        businessArray[props.modifyBusinessIndex] = newBusiness;
                                        await updateDoc(doc(db, `userInfo`, user.uid), {
                                            businessArray: businessArray
                                        }).then(() => {
                                            props.setAccountBusinessArray(businessArray);
                                            setSpinnerActivated(false);
                                            closeAddBusiness();
                                        });
                                    })
                                }
                            })
                        })
                    })
                })
            }
            else if (deckUpload == null && productPricing != null) {
                var deckURL = props.accountBusinessArray[props.modifyBusinessIndex].deckUpload;
                var productPricingURL = null;
                uploadBytes(ref(storage, `${user.uid}/${companyName}/productPricing`), productPricing).then((snapshot) => {
                    getDownloadURL(ref(storage, `${user.uid}/${companyName}/productPricing`)).then(async (url) => {
                        productPricingURL = url;
                        var newBusiness = {
                            companyName: companyName,
                            paidUpCapital: paidUpCapital,
                            walkInBreakdown: walkInBreakdown,
                            regularBreakdown: regularBreakdown,
                            localBreakdown: localBreakdown,
                            touristBreakdown: touristBreakdown,
                            productPricing: productPricingURL,
                            deckUpload: deckURL
                        }
                        if (props.accountBusinessArray != null) {
                            var businessArray = props.accountBusinessArray;
                        }
                        else {
                            var businessArray = [];
                        }

                        var getallPrefferedListing = await getDocs(query(collection(db, "projects"), where("UID", "==", user.uid)))
                        getallPrefferedListing.forEach(async (indivDoc) => {
                            await getDoc(doc(db, `projects`, indivDoc.id)).then(async (indivDetails) => {
                                if (indivDetails.data().preferredListing == true) {
                                    await getDocs(collection(db, `projects/${indivDoc.id}/PreferredDetails`)).then(async (indivPreferred) => {
                                        indivPreferred.forEach(async (indivPreferredDetails) => {
                                            if (indivPreferredDetails.data().autoFillLink == oldName) {
                                                var preferredDetails = indivPreferredDetails.data();
                                                preferredDetails.autoFillLink = companyName;
                                                preferredDetails.clinteleBreakdownLocal = localBreakdown;
                                                preferredDetails.clinteleBreakdownTourist = touristBreakdown;
                                                preferredDetails.companyDeckURL = deckURL;
                                                preferredDetails.companyName = companyName;
                                                preferredDetails.paidUpCaptial = paidUpCapital;
                                                preferredDetails.product_pricing = productPricingURL;
                                                preferredDetails.revenueBreakdownRegular = regularBreakdown;
                                                preferredDetails.revenueBreakdownWalkin = walkInBreakdown;
                                                await updateDoc(doc(db, `projects/${indivDoc.id}/PreferredDetails/${indivPreferredDetails.id}`), preferredDetails)
                                            }
                                        })
                                        businessArray[props.modifyBusinessIndex] = newBusiness;
                                        await updateDoc(doc(db, `userInfo`, user.uid), {
                                            businessArray: businessArray
                                        }).then(() => {
                                            props.setAccountBusinessArray(businessArray);
                                            setSpinnerActivated(false);
                                            closeAddBusiness();
                                        });
                                    })
                                }
                            })
                        })
                    })
                })
            }
            else {
                var deckURL = props.accountBusinessArray[props.modifyBusinessIndex].deckUpload;
                var productPricingURL = props.accountBusinessArray[props.modifyBusinessIndex].productPricing;
                var newBusiness = {
                    companyName: document.getElementById('companyName').value,
                    paidUpCapital: paidUpCapital,
                    walkInBreakdown: walkInBreakdown,
                    regularBreakdown: regularBreakdown,
                    localBreakdown: localBreakdown,
                    touristBreakdown: touristBreakdown,
                    productPricing: productPricingURL,
                    deckUpload: deckURL
                }
                if (props.accountBusinessArray != null) {
                    var businessArray = props.accountBusinessArray;
                }
                else {
                    var businessArray = [];
                }
                var getallPrefferedListing = await getDocs(query(collection(db, "projects"), where("UID", "==", user.uid)))
                businessArray[props.modifyBusinessIndex] = newBusiness;
                await updateDoc(doc(db, `userInfo`, user.uid), {
                    businessArray: businessArray
                }).then(() => {
                    if (getallPrefferedListing.docs.length != 0) {
                        getallPrefferedListing.forEach(async (indivDoc) => {
                            await getDoc(doc(db, `projects`, indivDoc.id)).then(async (indivDetails) => {
                                if (indivDetails.data().preferredListing == true) {
                                    await getDocs(collection(db, `projects/${indivDoc.id}/PreferredDetails`)).then(async (indivPreferred) => {
                                        indivPreferred.forEach(async (indivPreferredDetails) => {
                                            if (indivPreferredDetails.data().autoFillLink == oldName) {
                                                var preferredDetails = indivPreferredDetails.data();
                                                preferredDetails.autoFillLink = companyName;
                                                preferredDetails.clinteleBreakdownLocal = localBreakdown;
                                                preferredDetails.clinteleBreakdownTourist = touristBreakdown;
                                                preferredDetails.companyDeckURL = deckURL;
                                                preferredDetails.companyName = companyName;
                                                preferredDetails.paidUpCaptial = paidUpCapital;
                                                preferredDetails.product_pricing = productPricingURL;
                                                preferredDetails.revenueBreakdownRegular = regularBreakdown;
                                                preferredDetails.revenueBreakdownWalkin = walkInBreakdown;
                                                await updateDoc(doc(db, `projects/${indivDoc.id}/PreferredDetails/${indivPreferredDetails.id}`), preferredDetails)
                                            }
                                        })

                                    })
                                }
                            })
                        })
                        props.setAccountBusinessArray(businessArray);
                        setSpinnerActivated(false);
                        closeAddBusiness();
                    }
                    else {
                        props.setAccountBusinessArray(businessArray);
                        setSpinnerActivated(false);
                        closeAddBusiness();
                    }

                });



            }
        }
        catch (error) {
            console.log(error);
            closeAddBusiness();
        }
    }

    const deleteBusinessToFirebase = async () => {
        var businessArray = props.accountBusinessArray;
        var deleteBusiness = businessArray[props.modifyBusinessIndex];
        businessArray.splice(props.modifyBusinessIndex, 1); // remove 1 element
        console.log(businessArray);

        await updateDoc(doc(db, `userInfo`, user.uid), {
            businessArray: businessArray
        }).then(async () => {
            await getDoc(doc(db, 'deleteBusiness', `${user.uid}`)).then(async (deleteBusinessUser) => {
                if (deleteBusinessUser.exists()) {
                    if (deleteBusinessUser.hasOwnProperty('businessArray')) {
                        var deleteBusinessArray = deleteBusinessUser.businessArray;
                        deleteBusinessArray.push(deleteBusiness);
                        await updateDoc(doc(db, `deleteBusiness`, user.uid), {
                            businessArray: deleteBusinessArray
                        });
                    }
                    else {
                        var deleteBusinessArray = [deleteBusiness];
                        await updateDoc(doc(db, `deleteBusiness`, user.uid), {
                            businessArray: deleteBusinessArray
                        });
                    }
    
                }
                else {
                    setDoc(doc(db, 'deleteBusiness', `${user.uid}`), { businessArray: [deleteBusiness] });
                }
    
            })
                .then(async () => {
                    var userProjectModify = props.userProject;
                    for (var i = 0; i < props.userProject.length; i++) {
                        if (props.userProject[i].details.preferredListing == true) {
    
                            await getDocs(collection(db, `projects/${props.userProject[i].id}/PreferredDetails`)).then(async (res) => {
                                if (res.docs[0].data().autoFillLink == companyName) {
                                    await deleteDoc(doc(db, `projects/${props.userProject[i].id}/PreferredDetails/${res.docs[0].id}`))
                                        .then(async () => {
                                            await updateDoc(doc(db, `projects/${props.userProject[i].id}`), { preferredListing: false }).then(() => {
                                                
                                                userProjectModify[i].details.preferredListing = false;
                                               
                                            })
                                        });
                                }
                            })
                        }
                    }
                    props.setUserProject(userProjectModify);
                    props.setModifyBusinessIndex(null);
                    props.setAccountBusinessArray(businessArray);
                    setTouristBreakdown(50);
                    setLocalBreakdown(50);
                    setRegularBreakdown(50);
                    setWalkInBreakdown(50);
                    setPaidUpCapital('< 100K');
                    setDeckUpload(null);
                    setProductPricing(null);
                    setSpinnerActivated(false);
                    setCompanyName('');
                    props.setModifyBusinessIndex(null);
                    props.setTrigger(false);
                    return;
    
                })
        });
        



    }
    if (!props.trigger) {
        return;
    }
    else {


        return (
            <>
                <div className='modalNewProject' style={{ zIndex: "2" }}>
                    <div className='modal-contentNewProject'>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => closeAddBusiness()}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>ADD ADVANCE BUSINESS DETAILS</h4>
                        </div>

                        <div className='modal-bodyNewProject'>
                            {spinnerActivated == true ? <center><i class="fas fa-spinner fa-spin fa-lg" style={{ marginTop: "5%", marginBottom: "5%" }}></i></center> :
                                <>
                                    <div className='modalAddBusinessAccountLeft'>
                                        <p>Company Name</p>
                                        <input id="companyName" value={companyName} onChange={handleCompanyNameChanges}></input>
                                    </div>
                                    <div className='modalAddBusinessAccountRight'>
                                        <p >Paid Up Capital</p>
                                        <select id="paidUpCapital" value={paidUpCapital} onChange={handlePaidUpChanges}>
                                            <option value={"< 100K"}>&lt; 100K</option>
                                            <option value={"100K to < 300K"}>100K to &lt; 300K</option>
                                            <option value={"300K to < 500K"}>300K to &lt; 500K</option>
                                            <option value={"> 500K"}>&gt; 500K</option>
                                        </select>
                                    </div>
                                    <div className='modalAddBusinessAccountLeft'>
                                        <p >Revenue Breakdown (Walk In)</p>
                                        <input type="number"
                                            value={walkInBreakdown}
                                            onChange={handleWalkInChange}
                                            min={0}
                                            max={100}></input> %
                                    </div>
                                    <div className='modalAddBusinessAccountRight'>
                                        <p >Revenue Breakdown (Regular)</p>
                                        <input type="number"
                                            value={regularBreakdown}
                                            onChange={handleRegularChange}
                                            min={0}
                                            max={100}></input> %
                                    </div>
                                    <div className='modalAddBusinessAccountLeft'>
                                        <p>Clientele Breakdown (Local)</p>
                                        <input type="number"
                                            value={localBreakdown}
                                            onChange={handleLocalChange}
                                            min={0}
                                            max={100}></input> %
                                    </div>
                                    <div className='modalAddBusinessAccountRight'>
                                        <p >Clientele Breakdown (Tourist)</p>
                                        <input type="number"
                                            value={touristBreakdown}
                                            onChange={handleTouristChange}
                                            min={0}
                                            max={100}></input> %
                                    </div>
                                    <div className='modalAddBusinessAccountLeft'>
                                        <p >Product of business / Pricing of products: </p>
                                        <input type="file" accept=".pdf" style={{ border: "none" }} onChange={(event) => setFileUploadProduct_pricing(event)}></input>
                                        {props.modifyBusinessIndex != null && productPricing == null ?
                                            <>
                                                <br></br>
                                                <i class="fa fa-file-pdf-o" style={{ fontSize: "20px", marginLeft: "3%", color: "grey", cursor: "pointer" }} onClick={() => window.open(props.accountBusinessArray[props.modifyBusinessIndex].productPricing)}></i>
                                            </> : null}
                                    </div>
                                    <div className='modalAddBusinessAccountRight'>
                                        <p >Company Deck <br></br> <p style={{ fontSize: "11px", color: "grey" }}>Accept only pdf format</p></p>
                                        <input type="file" accept=".pdf" style={{ border: "none" }} onChange={(event) => setFileUpload(event)}></input>
                                        {props.modifyBusinessIndex != null && deckUpload == null ?
                                            <>
                                                <br></br>
                                                <i class="fa fa-file-pdf-o" style={{ fontSize: "20px", marginLeft: "3%", color: "grey", cursor: "pointer" }} onClick={() => window.open(props.accountBusinessArray[props.modifyBusinessIndex].deckUpload)}></i>
                                            </> : null}
                                    </div>
                                    <div style={{ clear: 'both' }}></div></>}
                        </div>

                        <div className='modal-footerNewProject'>
                            {
                                spinnerActivated == false ?
                                    props.modifyBusinessIndex == null ?
                                        <button className='AddBusinessBtn' onClick={() => addBusinessToFirebase()}>Add Business</button>
                                        :
                                        <>
                                            <button className='AddBusinessBtn' onClick={() => updateBusinessToFirebase()}>Update Business</button>
                                            <button className='AddBusinessBtn' onClick={() => deleteBusinessToFirebase()} style={{ marginRight: "2%" }}>Delete Business</button>

                                        </>
                                    :
                                    null
                            }
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}