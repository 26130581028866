import React, { useEffect, useRef, useState } from 'react';
import { UserAuth } from '../../AuthContent';
import { doc, setDoc, getDoc, collection, addDoc, updateDoc, serverTimestamp, deleteField } from "firebase/firestore";
import { db, storage } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import Acknowledge from './Acknowledge';
import '../css/UserTypeModal.css';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import AcknowledgeNormal from '../../AcknowledgeNormal';
import Spinner from './Spinner';

export default function NewUserType(props) {
    const { user } = UserAuth();
    const navigate = useNavigate();
    const [acknowledge, setAcknowledge] = useState(false);
    var userPreviousArr = useRef([]);
    const [expandPlan, setExpandPlan] = useState(false);
    const [message, setMessage] = useState(null);
    const [payClick, setPayClick] = useState(false);
    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
    const [roleConfirmed, setRoleConfirmed] = useState(null);
    const [activeSpin, setActiveSpin] = useState(false);
    const [masterTenantType, setMasterTenantType] = useState(false);
    const [landlordType, setLandlordType] = useState(false);
    const [userType, setUserType] = useState(true);
    const [tierTab, setTierTab] = useState(false);
    const [paymentTab, setPaymentTab] = useState(false);
    const [standard, setStandard] = useState(false);
    const [premium, setPremium] = useState(false);
    const [enterprise, setEnterprise] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [promotionTab, setPromotionTab] = useState(false);
    const [superPremium, setSuperPremium] = useState(false);

    useEffect(() => {

        if (user != null) {
            if (Object.keys(user).length != 0) {
                userPreviousArr.current.push(user);
            }
        }
    }, [user])




    useEffect(() => {
        if (props.trigger == true) {
            const elements = document.getElementById('tierUserType');
            if (payClick == true) {
                elements.style.display = "none";
            }
            else {
                elements.style.display = "flex";
            }
        }

    }, [payClick, props])

    const cancelMembership = (type) => {
        setActiveSpin(true);
        return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/cancelSubscription", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + user.accessToken
            },
            body: JSON.stringify({
                data: {
                    orderID: props.currentUserInfo.subscriptionID,
                    // capturedDetails: details,
                }
            }),
        })
            .then(async () => {
                if (type != "backend") {

                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID,
                        gracePeriod: 30,
                        subscriptionID: null
                    }).then(() => {
                        setActiveSpin(false);
                        setMessage("Your membership will be changed at the end of the current billing cycle, and your subscription will not be automatically renewed thereafter.");
                        setAcknowledge(true);
                        return;
                    })

                }
                else {
                    setActiveSpin(false);
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID,
                        subscriptionID: null
                    }).then(() => {
                        return;
                    })
                }

            })

    }




    const closeUserTypePop = () => {
        if (props.path == "account") {
            props.setChangeAccountTier(false);
            setPayClick(false);
            props.setTrigger(false);
            setRoleConfirmed(null);
        }
        else {
            setPayClick(false);
            setRoleConfirmed(null);
            props.setTrigger(false);
        }

    }

    const onApprove = async (data, actions) => {
        const selectElement = document.querySelector('.select');
        const gotSpaceElement = document.querySelector('.userTypeActive');
        if (props.currentUserInfo.subscriptionID === "PromotionCode") {

            if (selectElement.id == "standard") {
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    subscriptionID: data.subscriptionID,
                    newUserSetup: false,
                    downgrade: "Standard",
                    subscriptionPeriod:"Monthly",
                    subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                    freeAppt:props.currentUserInfo.newUserSetup ? true : false,
                }).then(() => {
                    var newCurrentUserInfo = props.currentUserInfo;
                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                    newCurrentUserInfo.downgrade = "Standard";
                    newCurrentUserInfo.newUserSetup = false;
                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    closeUserTypePop();
                })
            }
            else if (selectElement.id == "premium") {
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    subscriptionID: data.subscriptionID,
                    newUserSetup: false,
                    downgrade: "Premium",
                    subscriptionPeriod:"Monthly",
                    subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                }).then(() => {
                    var newCurrentUserInfo = props.currentUserInfo;
                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                    newCurrentUserInfo.downgrade = "Premium";
                    newCurrentUserInfo.newUserSetup = false;
                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    setMessage("Payment has been successful");
                    setAcknowledge(true);
                })
            }
            else {
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    subscriptionID: data.subscriptionID,
                    newUserSetup: false,
                    downgrade: "Super-Premium",
                    subscriptionPeriod:"Monthly",
                    subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                }).then(() => {
                    var newCurrentUserInfo = props.currentUserInfo;
                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                    newCurrentUserInfo.downgrade = "Super-Premium";
                    newCurrentUserInfo.newUserSetup = false;
                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    setMessage("Payment has been successful");
                    setAcknowledge(true);
                })
            }


        }
        else if (props.currentUserInfo.subscriptionID == null) {
            if (selectElement.id == "standard" && props.currentUserInfo.membership == "Standard") {
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                    subscriptionID: data.subscriptionID,
                    newUserSetup: false,
                    subscriptionPeriod:"Monthly",
                    subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                    downgrade: deleteField()
                }).then(() => {
                    var newCurrentUserInfo = props.currentUserInfo;
                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                    newCurrentUserInfo.downgrade = null;
                    newCurrentUserInfo.newUserSetup = false;
                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    setMessage("Payment has been successful");
                    setAcknowledge(true);
                })
            }
            else if (selectElement.id == "standard" && props.currentUserInfo.membership == "Premium") {
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                    subscriptionID: data.subscriptionID,
                    newUserSetup: false,
                    subscriptionPeriod:"Monthly",
                    subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                    downgrade: "Standard"
                }).then(() => {
                    var newCurrentUserInfo = props.currentUserInfo;
                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                    newCurrentUserInfo.downgrade = "Standard";
                    newCurrentUserInfo.newUserSetup = false;
                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    setMessage("Payment has been successful");
                    setAcknowledge(true);
                })
            }
            else if (selectElement.id == "standard" && props.currentUserInfo.membership == "Super-Premium") {
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                    subscriptionID: data.subscriptionID,
                    newUserSetup: false,
                    subscriptionPeriod:"Monthly",
                    subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                    downgrade: "Standard"
                }).then(() => {
                    var newCurrentUserInfo = props.currentUserInfo;
                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                    newCurrentUserInfo.downgrade = "Standard";
                    newCurrentUserInfo.newUserSetup = false;
                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    setMessage("Payment has been successful");
                    setAcknowledge(true);
                })
            }
            else if (selectElement.id == "premium" && props.currentUserInfo.membership == "Premium") {
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                    subscriptionID: data.subscriptionID,
                    subscriptionPeriod:"Monthly",
                    subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                    newUserSetup: false,
                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                    downgrade: deleteField()
                }).then(() => {
                    var newCurrentUserInfo = props.currentUserInfo;
                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                    newCurrentUserInfo.downgrade = null;
                    newCurrentUserInfo.newUserSetup = false;
                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    setMessage("Payment has been successful");
                    setAcknowledge(true);
                })
            }
            else if (selectElement.id == "premium" && props.currentUserInfo.membership == "Standard") {
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                    subscriptionID: data.subscriptionID,
                    subscriptionPeriod:"Monthly",
                    subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                    membership: "Premium",
                    billingAmount: 380,
                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                    chatLeft: props.currentUserInfo.chatLeft + 16,
                    advancedDetails: props.currentUserInfo.advancedDetails + 16,
                    newUserSetup: false
                }).then(() => {
                    var newCurrentUserInfo = props.currentUserInfo;
                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                    newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 16;
                    newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 16;
                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                    newCurrentUserInfo.billingAmount = 380;
                    newCurrentUserInfo.membership = "Premium";
                    newCurrentUserInfo.newUserSetup = false;
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    setMessage("Payment has been successful");
                    setAcknowledge(true);
                })
            }
            else if (selectElement.id == "premium" && props.currentUserInfo.membership == "Super-Premium") {
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                    subscriptionID: data.subscriptionID,
                    subscriptionPeriod:"Monthly",
                    subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                    newUserSetup: false,
                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                    downgrade: "Premium",
                }).then(() => {
                    var newCurrentUserInfo = props.currentUserInfo;
                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                    newCurrentUserInfo.downgrade = "Premium";
                    newCurrentUserInfo.newUserSetup = false;
                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    setMessage("Payment has been successful");
                    setAcknowledge(true);
                })
            }
            else if (selectElement.id == "standard") {
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                    subscriptionID: data.subscriptionID,
                    membership: "Standard",
                    billingAmount: 180,
                    subscriptionCounter: 30,
                    userType: props.currentUserInfo.userType == null? [gotSpaceElement.id]:[...props.currentUserInfo.userType, gotSpaceElement.id],
                    chatLeft: props.currentUserInfo.chatLeft + 7,
                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                    newUserSetup: false,
                    subscriptionPeriod:"Monthly",
                    downgrade: deleteField()
                }).then(() => {
                    var newCurrentUserInfo = props.currentUserInfo;
                    newCurrentUserInfo.subscriptionCounter = 30;
                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                    newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 7;
                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                    newCurrentUserInfo.billingAmount = 180;
                    newCurrentUserInfo.userType = props.currentUserInfo.userType == null? [gotSpaceElement.id]:[...props.currentUserInfo.userType, gotSpaceElement.id];
                    newCurrentUserInfo.membership = "Standard";
                    newCurrentUserInfo.newUserSetup = false;
                    newCurrentUserInfo.subscriptionPeriod = "Monthly";
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    setMessage("Payment has been successful");
                    setAcknowledge(true);
                })
            }
            else if (selectElement.id == "premium") {
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                    subscriptionID: data.subscriptionID,
                    membership: "Premium",
                    billingAmount: 380,
                    subscriptionCounter: 30,
                    userType: props.currentUserInfo.userType == null? [gotSpaceElement.id]:[...props.currentUserInfo.userType, gotSpaceElement.id],
                    chatLeft: props.currentUserInfo.chatLeft + 16,
                    advancedDetails: props.currentUserInfo.advancedDetails + 16,
                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                    newUserSetup: false,
                    subscriptionPeriod:"Monthly",
                    downgrade: deleteField()
                }).then(() => {
                    var newCurrentUserInfo = props.currentUserInfo;
                    newCurrentUserInfo.subscriptionCounter = 30;
                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                    newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 16;
                    newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 16;
                    newCurrentUserInfo.userType = props.currentUserInfo.userType == null? [gotSpaceElement.id]:[...props.currentUserInfo.userType, gotSpaceElement.id];
                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                    newCurrentUserInfo.billingAmount = 380;
                    newCurrentUserInfo.membership = "Premium";
                    newCurrentUserInfo.newUserSetup = false;
                    newCurrentUserInfo.subscriptionPeriod = "Monthly";
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    setMessage("Payment has been successful");
                    setAcknowledge(true);
                })
            }
            else {
                //superpremium
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                    subscriptionID: data.subscriptionID,
                    membership: "Super-Premium",
                    chatLeft: props.currentUserInfo.chatLeft + 40,
                    billingAmount: 880,
                    subscriptionCounter:30,
                    userType: props.currentUserInfo.userType == null? [gotSpaceElement.id]:[...props.currentUserInfo.userType, gotSpaceElement.id],
                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                    advancedDetails: props.currentUserInfo.advancedDetails + 40,
                    subscriptionPeriod: "Monthly",
                    newUserSetup: false,
                    downgrade: deleteField()
                }).then(() => {
                    var newCurrentUserInfo = props.currentUserInfo;
                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                    newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 40;
                    newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 40;
                    newCurrentUserInfo.billingAmount = 880;
                    newCurrentUserInfo.subscriptionCounter = 30;
                    newCurrentUserInfo.userType = props.currentUserInfo.userType == null? [gotSpaceElement.id]:[...props.currentUserInfo.userType, gotSpaceElement.id];
                    newCurrentUserInfo.membership = "Super-Premium";
                    newCurrentUserInfo.subscriptionPeriod = "Monthly";
                    newCurrentUserInfo.newUserSetup = false;
                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    setMessage("Payment has been successful");
                    setAcknowledge(true);
                })
            }
        }
        else {
            await cancelMembership('backend').then(async () => {
                if (selectElement.id == "standard") {
                    if (props.currentUserInfo.membership == "Standard") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            subscriptionPeriod:"Monthly",
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            downgrade: deleteField()
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = null;
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage("Payment has been successful");
                    setAcknowledge(true);
                        })
                    }
                    else if (props.currentUserInfo.membership == "Premium") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            subscriptionPeriod:"Monthly",
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            downgrade: "Standard"
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = "Standard";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage("Payment has been successful");
                    setAcknowledge(true);
                        })
                    }
                    else {
                        //superpremium
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            subscriptionPeriod:"Monthly",
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            downgrade: "Standard"
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = "Standard";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage("Payment has been successful");
                    setAcknowledge(true);
                        })
                    }

                }
                else if (selectElement.id == "premium") {
                    if (props.currentUserInfo.membership == "Premium") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            subscriptionPeriod:"Monthly",
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            downgrade: deleteField()
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = null;
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage("Payment has been successful");
                    setAcknowledge(true);
                        })
                    }
                    else if (props.currentUserInfo.membership == "Super-Premium") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            subscriptionPeriod:"Monthly",
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            downgrade: "Premium"
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = "Premium";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage("Payment has been successful");
                    setAcknowledge(true);
                        })
                    }
                    else {
                        //standard instant
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            membership: "Premium",
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            subscriptionPeriod:"Monthly",
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            billingAmount: 380,
                            chatLeft: props.currentUserInfo.chatLeft + 16,
                            advancedDetails: props.currentUserInfo.advancedDetails + 16,
                            newUserSetup: false
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 16;
                            newCurrentUserInfo.billingAmount = 380;
                            newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 16;
                            newCurrentUserInfo.membership = "Premium";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage("Payment has been successful");
                            setAcknowledge(true);
                        })
                    }

                }
                else {
                    ///////////////////Super Premium////////////////
                    if (props.currentUserInfo.membership == "Super-Premium") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            subscriptionPeriod:"Monthly",
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            downgrade: deleteField()
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = null;
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage("Payment has been successful");
                    setAcknowledge(true);
                        })
                    }
                    else if (props.currentUserInfo.membership == "Premium") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            membership: "Super-Premium",
                            subscriptionPeriod:"Monthly",
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            billingAmount: 880,
                            chatLeft: props.currentUserInfo.chatLeft + 40,
                            advancedDetails: props.currentUserInfo.advancedDetails + 40,
                            newUserSetup: false
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 40;
                            newCurrentUserInfo.billingAmount = 880;
                            newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 40;
                            newCurrentUserInfo.membership = "Super-Premium";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            closeUserTypePop();
                        })
                    }
                    else {
                        //standard instant upgrade
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            membership: "Super-Premium",
                            subscriptionPeriod:"Monthly",
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            billingAmount: 880,
                            chatLeft: props.currentUserInfo.chatLeft + 40,
                            advancedDetails: props.currentUserInfo.advancedDetails + 40,
                            newUserSetup: false
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 40;
                            newCurrentUserInfo.billingAmount = 880;
                            newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 40;
                            newCurrentUserInfo.membership = "Super-Premium";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage("Payment has been successful");
                            setAcknowledge(true);
                        })
                    }

                }
            })
        }
    };
    const paypalOnError = (err) => {
        console.log("Error", err)
    }


    if (!props.trigger) {
        return;
    }
    else {



        const proceedPromotion = async () => {
            var promotionDoc = await getDoc(doc(db, 'promotionCode', 'promoCode'));
            if (promoCode != promotionDoc.data().code) {
                alert("Invalid Code");
            }
            else {
                var userRole = null;
                if (masterTenantType) {
                    userRole = "Master Tenant";
                }
                else {
                    userRole = "Landlord"
                }
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    subscriptionID: "PromotionCode",
                    membership: "Premium",
                    billingAmount: 380,
                    chatLeft: 16,
                    advancedDetails: 16,
                    startDate: new Date(),
                    userType: [userRole],
                    subscriptionPeriod: "Monthly",
                    subscriptionCounter: 30,
                    launchedPromotionCode: [promoCode]
                }).then(() => {
                    setMessage("Promotion Code Verified. Once your trail period has ended, select a tier to continue your subscription");
                    setAcknowledge(true);
                })
            }


        }



        const createSubscription = async (data, actions) => {
            try {

                const selectElement = document.querySelector('.select');
                const gotSpaceElement = document.querySelector('.userTypeActive');
                let planID = "";
                //P-87V97227543012720MSIEUPQ premium no charges
                //P-5JG55492SV880132GMSIEUEY standard no charges
                if (selectElement.id == "standard" && props.currentUserInfo.newUserSetup == true) {
                    planID = "P-4WE25674YD030400NMSFLZEI";
                } else if (selectElement.id == "premium" && props.currentUserInfo.newUserSetup == true) {
                    planID = "P-6M109645LM7508252MSFLZ2Y";
                }
                else if (selectElement.id == "superPremium" && props.currentUserInfo.newUserSetup == true) {
                    planID = "P-7AD3724745234033TMSNY6EQ";
                }
                else if (selectElement.id == "standard" && props.currentUserInfo.newUserSetup == false) {
                    planID = "P-5JG55492SV880132GMSIEUEY"
                }
                else if (selectElement.id == "premium" && props.currentUserInfo.newUserSetup == false) {
                    planID = "P-87V97227543012720MSIEUPQ"
                }
                else if (selectElement.id == "superPremium" && props.currentUserInfo.newUserSetup == false) {
                    planID = "P-4XH70004EV358760SMSNY6RA"
                }



                if (props.currentUserInfo.subscriptionID === "PromotionCode") {
                    const startDate = new Date(); // Get the current date
                    startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
                    startDate.setMinutes(startDate.getMinutes()+2);
                    const response = await fetch(
                        "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + user.accessToken,
                            },
                            body: JSON.stringify({
                                data: {
                                    plan_id: planID,
                                    intent: "subscription",
                                    start_time: startDate
                                },
                            }),
                        }
                    );

                    if (response.ok) {
                        const data = await response.json();
                        return data.result.orderID;
                    } else {
                        throw new Error("Failed to create order");
                    }
                }
                else if (props.currentUserInfo.subscriptionID == null) {
                    if (props.currentUserInfo.membership != null) {
                        const startDate = new Date(); // Get the current date
                        startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
                        startDate.setMinutes(startDate.getMinutes()+2);
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription",
                                        start_time: startDate
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }
                    }

                    else {
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription"
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }
                    }
                }
                else {
                    //upgrade
                    if (selectElement.id == "premium" && props.currentUserInfo.membership == "Standard") {
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription"
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }

                    }
                    else if (selectElement.id == "superPremium" && props.currentUserInfo.membership == "Premium") {
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription"
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }

                    }
                    else {
                        //you have an subscription id
                        const startDate = new Date(); // Get the current date
                        startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
                        startDate.setMinutes(startDate.getMinutes()+2);
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription",
                                        start_time: startDate
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }

                    }


                }
            } catch (error) {
                console.error(error);
            }

        };

        const skipForNow = async () => {
            var userRole = null;
            if (masterTenantType) {
                userRole = "Master Tenant";
            }
            else {
                userRole = "Landlord"
            }
            await updateDoc(doc(db, 'userInfo', user.uid), {
                userType: [userRole],
            }).then(() =>{
                props.setTrigger(false);
            })
        }






        return (
            <>
                <Spinner trigger={activeSpin} setTrigger={setActiveSpin}></Spinner>
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={message}></AcknowledgeNormal>
                <Acknowledge trigger={acknowledge} setTrigger={setAcknowledge} message={message}></Acknowledge>
                <div className='modalNewProject' style={{ zIndex: "3" }}>
                    <div className='modal-contentUserType'>

                        <>
                            <div id="roleTab" style={userType ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject' >
                                    {props.currentUserInfo.userType != null ? <div className="notification-close" onClick={() => closeUserTypePop()}>
                                        ×
                                    </div> : null}

                                    <h4 className='modal-titleNewProject'>CHOOSE A PRIMARY ROLE</h4>
                                </div>

                                <div className='modal-bodyUserType' >

                                    <div className={masterTenantType ? 'userTypeActive' : 'userTypeNotActive'} id="Master Tenant" onClick={() => { setMasterTenantType(!masterTenantType); setLandlordType(false) }}><i class="fa-solid fa-house-user"></i>Master Tenant</div>
                                    <div className={landlordType ? 'userTypeActive' : 'userTypeNotActive'} id="Landlord" onClick={() => { setMasterTenantType(false); setLandlordType(!landlordType) }}><i class="fa-solid fa-key"></i>Landlord</div>
                                </div>

                                <div className='modal-footerNewProject'>

                                    <button id="addPropertyBtn" onClick={() => { setTierTab(true); setUserType(false) }}>Proceed</button>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>

                            <div id="tierTab" style={tierTab ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject'>

                                    {props.currentUserInfo.userType != null ? <div className="notification-close" onClick={() => closeUserTypePop()}>
                                        ×
                                    </div> : null}
                                    <button style={{ backgroundColor: "#C4E5E0", width: "80%", marginLeft: "10%", padding: "8px 8px 8px 8px", border: "none", cursor: "pointer", borderRadius: "10px", marginTop: "15px" }} onClick={() => { skipForNow() }}><b>SKIP FOR NOW</b></button>

                                    <div style={{ display: "flex", flexDirection: "row" }}><h4 className='modal-titleNewProject' style={{ width: "100%" }}>CHOOSE A SUBSCRIPTION PLAN</h4>
                                        {/* {expandPlan ? <i style={{textAlign:"center", marginTop:"15px", width:"10%", cursor:"pointer" }} onClick={() => setExpandPlan(false)} class="fa-solid fa-chevron-up"></i> : <i  style={{textAlign:"center", marginTop:"15px", width:"10%", cursor:"pointer" }} onClick={() => setExpandPlan(true)}class="fa-solid fa-chevron-down"></i>} */}
                                    </div>
                                </div>

                                {/* {expandPlan ?  */}
                                <div className='modal-bodyUserType' id="tierUserType" style={{ padding: "0", flexDirection: "column", width: "80%", marginLeft: "10%" }}>

                                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                        <thead>
                                            <tr style={{ backgroundColor: "#316190" }}>
                                                <th></th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Standard</p>
                                                    <p style={{ margin: 0 }}>$180</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Premium</p>
                                                    <p style={{ margin: 0 }}>$380</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Super Premium</p>
                                                    <p style={{ margin: 0 }}>$880</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Enterprise</p>
                                                    <p style={{ margin: 0 }}>Starting from $3000</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>

                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Rental</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Sales</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-xmark"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Connections</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p>7</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><p>16</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p>40</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Advanced Details</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-xmark"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><p>16</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p>40</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Photos per property profile</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 5 photos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Maximum of 12 photos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 20 photos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Videos per property profile</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>1 video</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Maximum of 3 videos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 5 videos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Top up</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-xmark"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-xmark"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px" }}></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={standard ? "select" : "notSelect"} id="standard" onClick={() => { setStandard(!standard); setPremium(false); setSuperPremium(false); setEnterprise(false) }}>Select</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={premium ? "select" : "notSelect"} id="premium" onClick={() => { setStandard(false); setPremium(!premium); setSuperPremium(false); setEnterprise(false) }}>Select</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={superPremium ? "select" : "notSelect"} id="superPremium" onClick={() => { setStandard(false); setPremium(false); setSuperPremium(!superPremium); setEnterprise(false) }}>Select</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={enterprise ? "select" : "notSelect"} id="enterprise" onClick={() => { setStandard(false); setPremium(false); setSuperPremium(false); setEnterprise(!enterprise) }}>Select</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style={{ fontSize: "14px", color: "grey", marginLeft: "10px" }}>*$80 for a first-time set-up fee</p>


                                </div>
                                {/* :null} */}


                                <div className='modal-footerNewProject'>
                                    {/* {payClick == false ? <> */}

                                    <button id="addPropertyBtn" onClick={() => { setTierTab(false); setPaymentTab(true) }}>Proceed</button>
                                    <button id="addPropertyBtn" onClick={() => { setTierTab(false); setPromotionTab(true) }} style={{ marginRight: "2%" }}>Promo Code</button>
                                    <button id="addPropertyBtn" onClick={() => { setTierTab(false); setUserType(true) }} style={{ marginRight: "2%" }}>Back</button>

                                    <div style={{ clear: 'both' }}></div>
                                </div>

                            </div>
                            <div id="promoTab" style={promotionTab ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject'>
                                    {props.currentUserInfo.userType != null ? <div className="notification-close" onClick={() => closeUserTypePop()}>
                                        ×
                                    </div> : null}
                                    <h4 className='modal-titleNewProject'>PROMOTION CODE</h4>
                                </div>

                                <div className='modal-bodyUserType' id="tierUserType">

                                    <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>

                                        <div>
                                            <center>

                                                <input style={{ padding: "10px 10px", width: "80%", borderRadius: "10px" }} placeholder='Key in your promotion code' value={promoCode} onChange={(e) => setPromoCode(e.target.value)}></input>

                                            </center>
                                        </div>
                                    </div>
                                </div>


                                <div className='modal-footerNewProject'>

                                    <button id="addPropertyBtn" onClick={() => proceedPromotion()}>Proceed</button>
                                    <button id="addPropertyBtn" onClick={() => { setTierTab(true); setPromotionTab(false) }} style={{ marginRight: "2%" }}>Back</button>
                                    <div style={{ clear: 'both' }}></div>

                                </div>

                            </div>

                            <div id="paymentTab" style={paymentTab ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject'>
                                    {props.currentUserInfo.userType != null ? <div className="notification-close" onClick={() => closeUserTypePop()}>
                                        ×
                                    </div> : null}
                                    <h4 className='modal-titleNewProject'>PAYMENT</h4>
                                </div>

                                <div className='modal-bodyUserType' id="tierUserType">

                                    <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                                        <PayPalScriptProvider
                                            options={{
                                                "client-id": "AUj95SHnSm6iN1zjvQnpSFuwehgQqPmaO2ZX9ZGFdLX_uNVWVZMnKwedYOeTmDp8UzxlLtlclw1SCnP-",
                                                currency: "SGD",
                                                intent: "subscription",
                                                vault: true
                                            }}
                                        >
                                            <PayPalButtons
                                                style={{ label: 'subscribe' }}
                                                createSubscription={(data, actions) => createSubscription(data, actions)}
                                                onApprove={(data, actions) => onApprove(data, actions)}
                                                catchError={paypalOnError}
                                                onError={paypalOnError}
                                                onCancel={paypalOnError}

                                            />

                                        </PayPalScriptProvider>


                                    </div>
                                </div>


                                <div className='modal-footerNewProject'>
                                    <button id="addPropertyBtn" onClick={() => { setTierTab(true); setPaymentTab(false) }} >Back</button>

                                    <div style={{ clear: 'both' }}></div>

                                </div>

                            </div>


                        </>
                    </div>
                </div>
            </>
        )
    }
}


