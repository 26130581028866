import React, { useEffect, useState, useRef } from 'react';
import { UserAuth } from '../../AuthContent';
import { db, storage } from '../../firebase/firebase';
import { setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import '../css/ThirdParty.css'
import { jsPDF } from "jspdf";
import SignaturePad from 'signature_pad';


export default function LOI(props) {
    const { user } = UserAuth();
    const canvasRef = useRef(null);
    const signaturePad = useRef(null);
    const [isSigned, setIsSigned] = useState(false);
    const [lesseeName, setLesseeName] = useState("");
    const [lessorName, setLessorName] = useState("");
    const [address, setAddress] = useState("");
    const [leaseTermYear, setLeaseTermYear] = useState(0);
    const [leaseTermMonth, setLeaseTermMonth] = useState(0);
    const [leaseTermDays, setLeaseTermDays] = useState(0);
    const [leaseTermDate, setLeaseTermDate] = useState(null);
    const [PSF, setPSF] = useState(0);
    const [rental, setRental] = useState(0);
    const [additional, setAdditional] = useState("");
    const [lesseeRepresentative, setLesseeRepresentative] = useState("");
    const [expiration, setExpiration] = useState("")

    useEffect(() => {
        if (!props.trigger) {
            return;
        }
        else {

            signaturePad.current = new SignaturePad(canvasRef.current, {
                backgroundColor: 'rgb(255, 255, 255)',
                penColor: 'rgb(0, 0, 0)'
            });

        }
    }, [props.trigger])


    // const approvedLOI = () => {
    //     return new Promise(function (resolve, reject) {
    //         const PDFdoc = new jsPDF();

    //         // add content to the PDF
    //         PDFdoc.setFont("helvetica", "bold");
    //         PDFdoc.setFontSize(12);
    //         PDFdoc.setTextColor(0);
    //         let textWidth = PDFdoc.getStringUnitWidth("LETTER OF INTENT") * PDFdoc.internal.getFontSize() / PDFdoc.internal.scaleFactor;
    //         let x = (PDFdoc.internal.pageSize.width - textWidth) / 2;
    //         PDFdoc.text("LETTER OF INTENT", x, 10);

    //         PDFdoc.setFont("helvetica", "normal");
    //         PDFdoc.text(`This non binding document made on the ${listingName} day of _______________ 20____ is intended to outline`, 10, 20);
    //         PDFdoc.text("the terms under which ________________________________________________________", 10, 30);
    //         PDFdoc.text("NRIC /FIN/Company Registration No. ________________________________________ contact details", 10, 40);
    //         PDFdoc.text("____________________________________ hereinafter called ' the Tenant ' of the one part would", 10, 50);
    //         PDFdoc.text("be willing to pursue negotiation with _____________________________________________ ", 10, 60);
    //         PDFdoc.text("NRIC /FIN/Company Registration No. __________________________________ contact details", 10, 70);
    //         PDFdoc.text("_________________________________________ hereinafter called ' the Landlord ' of the", 10, 80);
    //         PDFdoc.text("other part in regards to the property known as ______________________________________", 10, 90);
    //         const pdfBlob = new Blob([PDFdoc.output("blob")], { type: "application/pdf" });

    //         uploadBytes(ref(storage, `LOI/${user.uid}/LOI_${props.chatID}.pdf`), pdfBlob).then((snapshot) => {
    //             console.log(snapshot);
    //             console.log("uploaded");
    //             getDownloadURL(ref(storage, `LOI/${user.uid}/LOI_${props.chatID}.pdf`)).then(async (url) => {
    //                 const newTab = window.open(url, "_blank");
    //                 // setLOIDone(true);
    //                 await updateDoc(doc(db, "offeredListing", props.chatID), {
    //                     LOI: true
    //                 })
    //                 resolve(url);
    //             });
    //         });
    //     })
    // }

    // const disapprovedLOI = () => {
    //     console.log("cannot LOI");
    //     // setGenerateLOI(false);
    // }

    const handleChangeLesseeName = (e) => {
        setLesseeName(e.target.value)
    }

    const handleChangeLessorName = (e) => {
        setLessorName(e.target.value)

    }

    const handleChangeAddress = (e) => {
        setAddress(e.target.value)
    }

    const handleChangeLeaseTermYear = (e) => {
        if (e.target.value >= 0) {
            setLeaseTermYear(e.target.value)
        }
        else {
            alert("Invalid Year");
        }
    }

    const handleChangeLeaseTermMonth = (e) => {
        if (e.target.value >= 0) {
            setLeaseTermMonth(e.target.value)
        }
        else {
            alert("Invalid Month");
        }
    }

    const handleChangeLeaseTermDays = (e) => {
        if (e.target.value >= 0) {
            setLeaseTermDays(e.target.value)
        }
        else {
            alert("Invalid Day");
        }
    }

    const handleChangeLeaseTermDate = (e) => {
        setLeaseTermDate(e.target.value);
    }

    const handleChangePSF = (e) => {
        if (e.target.value >= 0) {
            setPSF(e.target.value);
        }
        else {
            alert("Invalid PSF");
        }
    }

    const handleChangeRental = (e) => {
        if (e.target.value >= 0) {
            setRental(e.target.value);
        }
        else {
            alert("Invalid Rental");
        }
    }

    const handleChangeAdditional = (e) => {
        setAdditional(e.target.value);
    }

    const handleChangeLesseeRepresentative = (e) => {
        setLesseeRepresentative(e.target.value);
    }

    const handleChangeExpiration = (e) => {
        setExpiration(e.target.value);
    }
    const activateLOI = async (signature) => {
        const doc = new jsPDF();
        doc.addImage(signature, 'PNG', 10, 255, 50, 20);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        doc.setTextColor(0);
        var date = new Date();
        var commencing = new Date(leaseTermDate);
        var options = { day: 'numeric', month: 'long', year: 'numeric' };
        var commencingDate = commencing.toLocaleDateString('en-US', options);
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

        var expirationDate = new Date(expiration);
        var options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        var expirationDateString = expirationDate.toLocaleDateString('en-US', options).replace(/\//g, '-');
        

        doc.text(`Date of offer: ${formattedDate}`, 10, 10);
        let textWidth = doc.getStringUnitWidth("LETTER OF INTENT") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        let x = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text("RE: Letter of intent", x, 20);

        doc.text(`i.    The Lessee: ${lesseeName}`, 30, 30);
        doc.text(`ii.   The Lessor: ${lessorName}`, 30, 40);
        doc.text(`iii.  Address of Premises:`, 30, 50);
        doc.text(address, 80, 50, { maxWidth: 100 });
        doc.text(`iv.   Lease Term:`, 30, 60);
        doc.text(`The term of the lease shall be for a period of ${leaseTermYear} Years ${leaseTermMonth} months ${leaseTermDays} days`, 40, 70, { maxWidth: 170 });
        doc.text(`commencing on the ${commencingDate}.`, 40, 80, { maxWidth: 170 });
        doc.text(`v.    Rental PSF: $${PSF}`, 30, 90);
        doc.text(`vi.   Rental per month: $${rental}`, 30, 100);
        doc.text(`Additional Remarks:`, 20, 110);
        doc.text(additional, 30, 120, { maxWidth: 160 });

        doc.text(`Lessee Representative: ${lesseeRepresentative}`, 10, 240);
        doc.text(`Signature`, 10, 250);
        doc.text(`Expiration of effective offer: ${expirationDateString}`, 10, 290);

        const newTab = window.open("LOI", "_blank");
        newTab.document.write("<iframe width='100%' height='100%' src='" + doc.output('datauristring') + "'></iframe>");
    }

    const clearCanvas = () => {
        signaturePad.current.clear();
        setIsSigned(false);
    };

    const saveSignature = () => {
        const signature = signaturePad.current.toDataURL();
        activateLOI(signature);
    };

    const submitLOI = () => {
        var signature = signaturePad.current.toDataURL();
        const doc = new jsPDF();
        doc.addImage(signature, 'PNG', 10, 255, 50, 20);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        doc.setTextColor(0);
        var date = new Date();
        var commencing = new Date(leaseTermDate);
        var options = { day: 'numeric', month: 'long', year: 'numeric' };
        var commencingDate = commencing.toLocaleDateString('en-US', options);
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;

        var expirationDate = new Date(expiration);
        var options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        var expirationDateString = expirationDate.toLocaleDateString('en-US', options).replace(/\//g, '-');

        doc.text(`Date of offer: ${formattedDate}`, 10, 10);
        let textWidth = doc.getStringUnitWidth("LETTER OF INTENT") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        let x = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text("RE: Letter of intent", x, 20);

        doc.text(`i.    The Lessee: ${lesseeName}`, 30, 30);
        doc.text(`ii.   The Lessor: ${lessorName}`, 30, 40);
        doc.text(`iii.  Address of Premises:`, 30, 50);
        doc.text(address, 80, 50, { maxWidth: 100 });
        doc.text(`iv.   Lease Term:`, 30, 60);
        doc.text(`The term of the lease shall be for a period of ${leaseTermYear} Years ${leaseTermMonth} months ${leaseTermDays} days`, 40, 70, { maxWidth: 170 });
        doc.text(`commencing on the ${commencingDate}.`, 40, 80, { maxWidth: 170 });
        doc.text(`v.    Rental PSF: $${PSF}`, 30, 90);
        doc.text(`vi.   Rental per month: $${rental}`, 30, 100);
        doc.text(`Additional Remarks:`, 20, 110);
        doc.text(additional, 30, 120, { maxWidth: 160 });

        doc.text(`Lessee Representative: ${lesseeRepresentative}`, 10, 240);
        doc.text(`Signature`, 10, 250);
        doc.text(`Expiration of effective offer: ${expirationDateString}`, 10, 290);

        const pdfBlob = new Blob([doc.output("blob")], { type: "application/pdf" });

        uploadBytes(ref(storage, `${props.chatID}/LOI.pdf`), pdfBlob).then((snapshot) => {
            // getDownloadURL(ref(storage, `${props.chatID}/LOI.pdf`)).then(async (url) => {
                setAddress("");
                setLesseeName("");
                setLessorName("");
                setLeaseTermYear(0);
                setLeaseTermMonth(0);
                setLeaseTermDays(0);
                setLeaseTermDate(null);
                setPSF(0);
                setRental(0);
                setAdditional("");
                setLesseeRepresentative("");
                setExpiration("");
                signaturePad.current.clear();
                props.setLOISend(true);
                props.setTrigger(false);
            // });
        });


    }
    
    const closeLOI = () =>{
        setLeaseTermDate(null);
        setLeaseTermMonth(0);
        setLeaseTermDays(0);
        setLeaseTermYear(0);
        setPSF(0);
        setRental(0);
        setLesseeName("");
        setLessorName("");
        setAdditional("");
        setAddress("");
        setLesseeRepresentative("");
        setExpiration("");
        signaturePad.current.clear();
        props.setTrigger(false)
    }

    if (!props.trigger) {
        return;
    }
    else {


        return (
            <>
                <div className='modalNewProject' style={{ zIndex: "3" }}>
                    <div className='modal-contentNewProject' >
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => closeLOI()}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>Letter of Intent</h4>

                        </div>
                        <div className='modal-bodyNewProject'>
                            <div>
                                <div>
                                    <p className='modalLabel'>Lessee Name</p>
                                    <input class="newListingInput" value={lesseeName} onChange={handleChangeLesseeName}></input>
                                    <p className='modalLabel'>Lessor Name</p>
                                    <input class="newListingInput" value={lessorName} onChange={handleChangeLessorName}></input>
                                    <p className='modalLabel'>Address of Premises</p>
                                    <input class="newListingInput" value={address} onChange={handleChangeAddress}></input>
                                    <p className='modalLabel'>Lease Term</p>
                                    <input class="newListingInput" style={{ width: "90%", marginBottom: "1%" }} value={leaseTermDays} onChange={handleChangeLeaseTermDays}></input><span>Days(s)</span>
                                    <input class="newListingInput" style={{ width: "90%", marginBottom: "1%" }} value={leaseTermMonth} onChange={handleChangeLeaseTermMonth} ></input><span>Month(s)</span>
                                    <input class="newListingInput" style={{ width: "90%", marginBottom: "1%" }} value={leaseTermYear} onChange={handleChangeLeaseTermYear}></input><span>Year(s)</span>


                                    <p className='modalLabel'>Commencing Date</p>
                                    <input className='newListingInput' type="date" value={leaseTermDate} onChange={handleChangeLeaseTermDate}></input>
                                    <p className='modalLabel'>Rental PSF</p>
                                    <input class="newListingInput" value={PSF} onChange={handleChangePSF}></input>
                                    <p className='modalLabel'>Rental per month</p>
                                    <input class="newListingInput" value={rental} onChange={handleChangeRental}></input>
                                    <p className='modalLabel'>Additional Remarks</p>
                                    <textarea style={{ height: "150px", resize: "none", textAlign:"left", padding:"10px" }} class="newListingInput" value={additional} onChange={handleChangeAdditional}></textarea>
                                    <p className='modalLabel'>Lessee Representative</p>
                                    <input class="newListingInput" value={lesseeRepresentative} onChange={handleChangeLesseeRepresentative}></input>
                                    <p className='modalLabel'>Expiration of effective offer</p>
                                    <input className='newListingInput' type="date" value={expiration} onChange={handleChangeExpiration}></input>
                                    <p className='modalLabel'>Signature</p>
                                    <canvas ref={canvasRef} id="canvaSign" />
                                    {/* <br />
                                    <button onClick={clearCanvas}>Clear</button>
                                    {!isSigned && (
                                        <button onClick={saveSignature}>Save Signature</button>
                                    )} */}
                                </div>
                            </div>

                        </div>
                        <div style={{ clear: 'both' }}></div>
                        <div className='modal-footerNewProject'>
                            {/* <button id='addPropertyBtn' >Close</button> */}
                            <button id='addPropertyBtn' style={{ marginRight: "1%" }} onClick={() => submitLOI()}>Submit Document</button>
                            <button id='addPropertyBtn' style={{ marginRight: "1%" }} onClick={saveSignature}>Review Document</button>

                            <button id='addPropertyBtn' style={{ marginRight: "1%" }} onClick={clearCanvas}>Clear</button>


                            <div style={{ clear: 'both' }}></div>

                        </div>
                    </div>
                </div>
            </>
        );
    }

}