import React, { useRef, useEffect } from 'react';
import '../../App.css';
import '../css/AboutUs.css'
import { db } from '../../firebase/firebase'
import { onSnapshot, doc, getDoc } from 'firebase/firestore';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import Footer from '../Footer';
import Notification from './Notification';




function PrivacyPolicy() {

  const chats = useRef(null);
  const { user, deleteUserData } = UserAuth();
  let unsbscribe;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    console.log("t&s", user);
    const checkingUser = async () => {
      var docsnap = await getDoc(doc(db, 'userInfo', user.uid));
      if (docsnap.exists()) {
        console.log("approved user");
      }
      else {
        // alert("hacked your way through ah");
        deleteUserData();

      }
    }
    if (user != null) {
      if (Object.keys(user).length != 0) {
        checkingUser();
      }
    }
  }, [user])


  return (
    <>
      <Notification />
      <div>
        <center><p className='t_c_title'>Privacy Policy</p></center>
        <div className='t_cDiv'>
          <p className='t_c_date'>Last Updated: 09th July 2023</p>
          <p className='t_c_header'>1. Introduction</p>
          <p className='t_c_text'>This Privacy Policy complements our Terms of Service and outlines how NextLoop Pte Ltd (“NextLoop”), along with its affiliated companies and subsidiaries that link to this Privacy Policy, handle the collection and usage of information during your utilization of our websites, mobile applications, and services (referred to as the "Services"). It also governs the disclosure, use, and collection of your information.</p>
          <p className='t_c_header'>2. Information collection and reception</p>
          <p className='t_c_text'>We may collect and receive information from you in the following circumstances:</p>
          {/* <div id="SecondPointerDiv"> */}
          <p className='t_c_text'>When you register an account with us ("Account").</p>
          <p className='t_c_text'>When you choose to link your Account with an external third-party service or application, like Facebook or Google.</p>
          <p className='t_c_text'>When you engage in features and functions available on our Services, such as creating listings, interacting with other users through our chat feature, or conducting transactions with other users.</p>
          <p className='t_c_text'>If you contact us with inquiries or to report any issues.</p>
          <p className='t_c_text'>When you register for surveys, campaigns, contests, and/or lucky draws with us.</p>
          <p className='t_c_text'>When you interact with us offline or through an external third party.</p>
          <p className='t_c_text'>You are not obligated to provide us with the information mentioned above. However, if you choose not to disclose the information or withdraw consent for us to receive it, certain aspects of our Services may not be available to you.</p>
          <p className='t_c_text'>By providing us with information pertaining to a third party (e.g., information about your spouse, children, parents, and/or employees), you confirm that you have obtained valid consent from the third party to share their information with us for the specified purposes.</p>
          <p className='t_c_text'>We may collect and receive the following types of information. Please note that not all of our Services collect every type of information listed below:</p>
          <p className='t_c_text'>Account credentials, such as a username.</p>
          <p className='t_c_text'>Profile information, including name, gender, email address, profile photo, mobile number, and date of birth.</p>
          <p className='t_c_text'>Information necessary for any payment and services, such as credit or debit card details, bank account information, address, mobile number and location information</p>
          <p className='t_c_text'>Any other information disclosed by you through the aforementioned methods and/or by using our Services.</p>
          <p className='t_c_text'>Aggregate data collected through the use of our Services.</p>
          <p className='t_c_text'>Any communications exchanged between you and another user of our Services.</p>
          {/* </div> */}

          <p className='t_c_header'>3. Interactions with others</p>
          <p className='t_c_text'>Our Services provide features that enable users to connect and communicate in public or semi-public groups, as well as share information with each other to facilitate transactions. When participating in these groups or sharing information with others, please exercise common sense and use good judgment. We generally do not actively monitor or remove the information you share, and such information may remain accessible on our Services even after your Account has been deactivated, terminated, or closed. You are solely responsible for the information you choose to share with other users in these groups.</p>
          <p className='t_c_header'>4. Purpose of information collection and how we use it</p>
          {/* <div id="SecondPointerDiv"> */}
          <p className='t_c_text'>We collect and utilize the information provided and received from you for the following purposes, as well as any other purposes notified to you at the time of obtaining your consent:</p>
          <p className='t_c_text'>To update you on your listings and inform you about our Services (e.g., new offers, chats, feedback, comments). These updates may be sent via email and/or push notifications, and you can choose to unsubscribe from them at any time through your Account's "Settings" page.</p>
          <p className='t_c_text'>To send you marketing and promotional materials regarding the latest products and services offered by NEXTLOOP or carefully selected third parties. These communications may be sent via email and/or push notifications, and you can opt out of receiving such marketing communications by using the unsubscribe link in the email or adjusting your preferences on the "Settings" page of your Account. Additionally, if you have expressed your preference to receive updates through text messages or voice calls, such communications may also be sent through those channels. For more information on anonymous advertising, opting out, and your choices.</p>
          <p className='t_c_text'>To verify your identity, update our records, and manage your Account with us.</p>
          {/* <div id="SecondPointerDiv"> */}
          <p className='t_c_text'>To provide and customize our Services, including personalized content.</p>
          <p className='t_c_text'>To develop, operate, improve, and maintain our Services.</p>
          <p className='t_c_text'>To process transactions and facilitate payment and services.</p>
          <p className='t_c_text'>To handle and resolve any disputes arising from transactions, in accordance with our Terms of Service.</p>
          <p className='t_c_text'>To detect and monitor fraudulent or prohibited content, businesses, or activities.</p>
          <p className='t_c_text'>To respond to your inquiries and address complaints.</p>
          <p className='t_c_text'>To provide updates, notifications, and confirmations related to transactions.</p>
          <p className='t_c_text'>To conduct polls, surveys, analysis, and research on the usage of our Services and ways to enhance them.</p>
          <p className='t_c_text'>To send you service-related messages, such as confirmation emails when you register your Account and to enforce our Terms of Service and other policies. These messages are not promotional in nature and may be sent via email and/or push notifications. You cannot opt out of receiving service-related messages from NEXTLOOP unless you deactivate your account.</p>
          <p className='t_c_text'>To perform functions or services as notified to you at the time of information collection, such as publishing and using your feedback and stories for marketing purposes.</p>
          <p className='t_c_text'>To provide you with a more personalized online advertising experience. NEXTLOOP utilizes tools and services to create and deliver advertisements to devices on behalf of advertisers, monitor campaign success, and present ads that are likely to be interesting and relevant to you. This type of advertising, known as "interest-based advertising" or "behavioral advertising," can be tailored to your interests. For information on interest-based advertising, opting out, and your choices across different devices and browsers.</p>
          <p className='t_c_text'>Information may also be used to manage your panel membership, send you service-related messages, notify you of new survey opportunities, and provide updates on rewards and incentives. These updates may be sent via email and</p>
          <p className='t_c_header'>5. Disclosure of Your Information</p>
          <p className='t_c_text'>We may disclose information about you in the following manners:</p>
          <p className='t_c_text'>When you create an Account, the information provided on your profile may be visible to other users of our Services. Additionally, other users may have access to the content you upload and share on our Services.</p>
          <p className='t_c_text'>We may share information with third-party service providers who assist us in performing certain functions on our behalf, such as transaction processing, payment or any services, information requests, communication management, marketing list updates, data analysis, support services, and other related tasks. These service providers will only have access to your personal information to the extent necessary to fulfill their responsibilities.</p>
          <p className='t_c_text'>Information may also be shared with third-party payment and/or deferred payment service providers for the purpose of providing and facilitating specific aspects of our Services. In such cases, the personal data you provide may be used for procedures such as database matching against known fraudulent transactions, identity verification, due diligence checks, creditworthiness assessments, risk management protocols, screening activities (including background checks), and compliance with legal or regulatory obligations as established by these third-party service providers.</p>
          <p className='t_c_text'>We may share information with our affiliated companies, related entities, and associates to perform various functions within our Services and as outlined in this Privacy Policy.</p>
          <p className='t_c_text'>Information may be shared with potential partners, investors, and other parties in relation to potential business partnerships, collaborations, joint ventures, or other activities that advance our business objectives.</p>
          <p className='t_c_text'>When utilizing our chat feature, you consent to sharing your personnel number with the relevant users, as applicable.</p>
          <p className='t_c_text'>In accordance with our Terms of Service, we may disclose any information collected under this Privacy Policy to: (i) governmental or regulatory authorities, as well as courts in any jurisdiction; (ii) potential third-party claimants or claimants; or (iii) your card issuing bank, financial institution, and/or payment service provider to address or resolve chargebacks, payment reversals, and disputes arising from or related to transactions conducted through our Services.</p>
          <p className='t_c_text'>In addition to other information described in this Privacy Policy, we may collect and share precise location information with third-party data management platforms, advertising partners, and analytics partners. This location information may be used on its own, aggregated, or combined with mobile identifiers (such as IDFAs and Android IDs) and shared with third parties for advertising, analytics, attribution (e.g., measuring ad performance), and market research purposes. Please refer to the privacy policies of these entities, accessible through the provided links, for further information.</p>
          <p className='t_c_text'>We may disclose your personal information if required by law or if we believe that such action is necessary to prevent fraud or crime, or to protect our Services or the rights, property, or personal safety of any individual.</p>
          <p className='t_c_text'>We may disclose data and aggregate statistics regarding users of our Services and sales to prospective partners, advertisers, sponsors, and other reputable third parties, for the purpose of describing our Services, delivering targeted advertisements, or other lawful activities. Please note that these data and statistics will not include information that can be used to personally identify you.</p>
          <p className='t_c_text'>You have the option to opt out of the sharing of anonymous information, as outlined above, by</p>
          <p className='t_c_header'>6. Transfer of Information</p>
          <p className='t_c_text'>By utilizing our Services, you expressly authorize us to utilize your information in Singapore, and other countries where NEXTLOOP operates, for the aforementioned purposes. We will ensure that your information is transferred in compliance with this Privacy Policy and safeguarded in accordance with applicable laws governing the protection of personal data, including but not limited to the Personal Data Protection Act 2012 of Singapore.</p>
          <p className='t_c_header'>7. Protection of Your Information</p>
          <p className='t_c_text'>We place great importance on the security of your information. We have implemented security measures to protect against loss, misuse, and alteration of information under our control. Furthermore, we adhere to generally accepted industry standards to safeguard information transmitted to us over the Internet, both during transmission and upon receipt. Sensitive information, such as credit card numbers, is encrypted using secure socket layer technology (SSL). Nevertheless, please be aware that no method of transmission over the Internet or electronic storage is completely secure. Although we strive to employ commercially acceptable means to safeguard your information, we cannot guarantee its absolute security.</p>
          <p className='t_c_text'>To prevent unauthorized access to your Account and information, it is crucial that you select a password diligently and keep it and your Account secure (e.g., by signing out after utilizing our Services).</p>
          <p className='t_c_header'>8. Accessing and Updating Your Information</p>
          <p className='t_c_text'>You can access and update your information within our Services through the "Account" page of your Account.</p>
          <p className='t_c_text'>When providing personal information about yourself to us, you are responsible for ensuring the accuracy, absence of misleading details, completeness, and currency of the information. Additionally, it is your responsibility to update this personal information whenever it becomes inaccurate, misleading, incomplete, or outdated.</p>
          <p className='t_c_text'>In certain situations, you may be required to provide us with personal information pertaining to individuals other than yourself. In such cases, we rely on you to notify these individuals that their personal information will be disclosed to NEXTLOOP, ensure that they consent to the provision of their information, and inform them where they can access a copy of this Privacy Policy.</p>
          <p className='t_c_text'>Any request associated with users’ account. Please allow us a reasonable period of time to respond to your request and effect any necessary changes. We may require verification of your identity and additional information related to your request. Should we possess legal grounds to do so, we may decline your request and provide you with an explanation for such a decision.</p>
          <p className='t_c_text'>In cases where you request a copy of the information we hold about you, we may impose a reasonable administrative fee to cover the costs associated with responding to your request. If we opt to levy such a fee, we will provide you with a written estimate of the amount in advance.</p>
          <p className='t_c_header'>9. Tracking Technologies</p>
          <p className='t_c_text'>We employ tracking technologies on our Services to facilitate an understanding of your usage patterns, thereby assisting us in the provision and enhancement of our Services. However, these tracking technologies do not access, receive, or collect information that can be utilized to identify you. Presented below are examples of the tracking technologies employed on our Services:</p>
          <p className='t_c_header'>9.1 Cookies</p>
          <p className='t_c_text'>Cookies are small data files sent from a website to your browser, which are then stored on your device. They serve various purposes, including the display of content that is most relevant to your interests and activities on our Services, estimation and reporting of the overall audience size and traffic of our Services, and conducting research to improve our Services. At times, we may collaborate with selected third-party vendors such as Google Analytics to enable the utilization of tracking technologies and remarketing services on our Platforms through the deployment of first-party and third-party cookies. Configuring your device's settings allows you to express your preference for accepting or rejecting cookies. If you reject all cookies, you may not be able to fully benefit from our Services. </p>
          <p className='t_c_header'>9.2 Pixels</p>
          <p className='t_c_text'>Periodically, we may employ specific Facebook Business Tools, such as the Facebook Pixel. This tool enables NEXTLOOP to monitor user actions subsequent to viewing or clicking on a Facebook advertisement, facilitating the assessment of the effectiveness of Facebook ads for statistical and market research purposes. Consequently, NEXTLOOP gains enhanced comprehension of ad delivery, allowing for improved relevance. The data collected remains anonymous, and NEXTLOOP cannot access the personal data of individual users. However, Facebook retains and processes the collected data, potentially linking it to your Facebook account and utilizing it for their own advertising objectives, in compliance with Facebook's Data Use Policy. Facebook exercises ultimate control over information obtained through the Facebook Business Tools and communications. You have the option to withdraw your consent and opt-out of Facebook's use of cookies and the Facebook Pixel via the settings on your Facebook Account or by withdrawing your consent through this link.</p>
          <p className='t_c_header'>9.3 Clear gifs</p>
          <p className='t_c_text'>We utilize clear gifs (also known as web beacons or web bugs), a software technology, to enhance content management on our Services by providing insights into the effectiveness of content. Clear gifs are minute graphics embedded invisibly on web pages, possessing a unique identifier similar in function to cookies. They are employed to track the online activities of web users. Unlike cookies, which are stored on the user's computer hard drive, clear gifs are integrated within web pages and are approximately the size of a period at the end of a sentence. The data collected in this manner remains anonymous to us, rendering it impossible for us to identify personal data pertaining to individual users. However, this data is stored and processed by Facebook, enabling them to connect it to your Facebook account and utilize it for their own advertising pursuits, in accordance with Facebook's Data Policy, accessible at https://www.facebook.com/about/privacy/. By granting permission, you allow Facebook and its partners to display ads on Facebook and external platforms, while also potentially saving cookies on your device for these purposes. Should you wish to withdraw your consent, please visit this link: https://www.facebook.com/settings/?tab=ads#_=_</p>
          <p className='t_c_header'>9.4 Flash cookies</p>
          <p className='t_c_text'>We employ Flash cookies (also known as local shared objects or LSOs) to store certain viewing preferences related to our Services. These cookies are used to collect and retain information, differing from browser cookies in terms of data storage volume, type, and methodology.</p>
          <p className='t_c_text'>Please note that the utilization of tracking technologies is subject to applicable laws and regulations, as well as our commitment to protecting your privacy.</p>
          <p className='t_c_header'>10. NEXTLOOP Opt-Out</p>
          <p className='t_c_text'>During your usage of our Services, and through various advertising networks and exchanges, we may deliver personalized and/or targeted advertisements ("Targeted Ads") to you.</p>
          <p className='t_c_text'>Please also be aware that although NEXTLOOP does not share your personal data, except for hashed or device identifiers (to the extent they qualify as personal data in certain jurisdictions), with advertising networks and exchanges without your consent or as specified in this Privacy Policy, viewing or interacting with an advertisement through any of your devices may lead an advertiser, advertising network, or exchange to infer that you fall within their target audience. Furthermore, when you click on an advertisement, the publisher and/or advertiser will be aware that you visited the page you clicked on and may still be able to associate personal data directly collected from you by such third parties through their cookies and similar tracking technologies.</p>
          <p className='t_c_header'>11. Changes to this Privacy Policy</p>
          <p className='t_c_text'>We retain the right to modify this Privacy Policy at any time, and all such changes will be posted on this platform. In the event that we consider the alterations to be significant, we will notify you of these changes either through a notice posted on our Services or via email. It is your responsibility to review the changes made to this Privacy Policy. Your continued use of our Services signifies your acceptance of the updated Privacy Policy.</p>
          <p className='t_c_header'>12. Third-Party Sites and Resources</p>
          <p className='t_c_text'>Our Services may occasionally contain links to external sites or resources operated by third parties. We exercise no control over the content and privacy practices of these external sites or resources. It is recommended that you review the privacy policies of such third-party operated sites and resources to understand how your information may be utilized by them.</p>

          
          
        </div>
      </div >
      <div style={{ clear: 'both' }}></div>
      <Footer />
    </>


  )
}

export default PrivacyPolicy;
