import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import '../../App.css';
import '../css/AdvertiseListing.css';
import { UserAuth } from '../../AuthContent';
import { Link } from 'react-router-dom';
import { doc, setDoc, addDoc, collection, updateDoc, getDoc, getDocs, deleteDoc } from "firebase/firestore";
import { db, storage } from '../../firebase/firebase';
import { getDownloadURL, ref, uploadBytes, listAll, getStorage, deleteObject, uploadString } from 'firebase/storage';
import Acknowledge from './Acknowledge'
import DefaultImage from './DefaultImage';
import Timestamp from 'firebase-firestore-timestamp';
import axios from 'axios';
import CropImage from './CropImage';
import Warning from './Warning';
import LoaderPage from './LoaderPage';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import ExistingPhoto from './ExistingPhoto';
import { type } from '@testing-library/user-event/dist/type';
import Spinner from './Spinner';




export default function AdvertiseListing(props) {

  const { user } = UserAuth();
  const uploadSelfImage = useRef(null);
  const [photo, setPhoto] = useState(null);
  const [clickConfirm, setClickConfirm] = useState(false);
  const [message, setMessage] = useState("");
  const [spinnerActive, setSpinnerActive] = useState(false);
  const [advertiseName, setAdvertiseName] = useState('');

  function setPhotoUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setSpinnerActive(true);
      setPhoto(reader.result);
      setSpinnerActive(false);
      const img = new Image();
      // img.onload = () => {
      //   const canvas = document.createElement('canvas');
      //   const ctx = canvas.getContext('2d');

      //   canvas.width = img.width;
      //   canvas.height = img.height;
      //   ctx.drawImage(img, 0, 0);

      //   const watermarkedImageUrl = canvas.toDataURL();
      //   // Upload watermarked image to Firebase Storage
      //   const storageRef = ref(storage, `checkingImage/${file.name}`);
      //   uploadString(storageRef, watermarkedImageUrl, 'data_url')
      //     .then(() => {
      //       getDownloadURL(storageRef).then((url) => {
      //         axios.get('https://api.sightengine.com/1.0/check.json', {
      //           params: {
      //             'url': url,
      //             'models': 'nudity-2.0,wad,offensive,gore,gambling',
      //             'api_user': '206386815',
      //             'api_secret': 'BvES5DBmLA68GjALyBXo',
      //           }
      //         })
      //           .then(function (response) {
      //             // on success: handle response
      //             if (response.data.drugs <= 0.10 && response.data.gore.prob <= 0.30 && response.data.nudity.erotica <= 0.60 && response.data.nudity.sexual_activity <= 0.30 && response.data.nudity.sexual_display <= 0.30 && response.data.offensive.prob <= 0.30) {

      //               setPhoto(reader.result);
      //               setSpinnerActive(false);
      //             }
      //             else {
      //               setSpinnerActive(false);
      //               alert(`${file.name} not accepted`)

      //             }

      //           })
      //           .catch(function (error) {
      //             // handle error
      //             if (error.response) {
      //               console.log(error.response.data);
      //               // resolve('Image not accepted');
      //             }
      //             else {
      //               console.log(error.message);
      //               // resolve('Image not accepted');
      //             }
      //           });



      //       })
      //     })
      //     .catch((error) => {
      //       console.log('Error uploading image:', error);
      //     });


      // };

      // img.src = event.target.result;
    };

    reader.readAsDataURL(file);



    // reader.onload = () => {
    //   setPhoto(reader.result);
    // };

    // if (file) {
    //   reader.readAsDataURL(file);
    // }


  }

  const proceedButton = async () => {
    if (photo) {
      if (advertiseName != '') {
        const docRefAdvertise = await addDoc(collection(db, 'advertiseListing'), {
          UID: user.uid,
          status: "pending",
          advertiseName: advertiseName,
          activatedDate: null,
          imageURL: null,
          eyeballStat: [0, 0, 0, 0, 0, 0, 0],
          eyeball: 0,
          sector: "Not restricted to any district"
        });

        const storageRef = ref(storage, `${user.uid}/advertise_${docRefAdvertise.id}`);

        try {


          await uploadString(storageRef, photo, 'data_url')
            .then(async () => {
              const url = await getDownloadURL(storageRef);
              await updateDoc(doc(db, 'advertiseListing', docRefAdvertise.id), {
                imageURL: url
              }).then(() => {
                setPhoto(null);
                // var newAdvListing = [...props.advListing];
                // var newAdvInfo = {
                //   UID: user.uid,
                //   advertiseName: advertiseName,
                //   status: "pending",
                //   activatedDate: null,
                //   imageURL: url,
                //   eyeballStat: [0, 0, 0, 0, 0, 0, 0],
                //   eyeball: 0
                // }

                // newAdvListing.push(newAdvInfo);
                // props.setAdvListing(newAdvListing);
                // props.setTrigger(false)
                setMessage("Advertisement Added");
                setClickConfirm(true);

              })

            }).catch((error) => {
              deleteDoc(doc(db, 'advertiseListing', docRefAdvertise.id))
              alert("Error Uploading Image");
              console.log('Error uploading image:', error);
            });



        } catch (error) {
          deleteDoc(doc(db, 'advertiseListing', docRefAdvertise.id))
          alert("Error Uploading Image");
          console.log("Error uploading image:", error);
        }
      }
      else {
        alert("Key in a label this advertisement")
      }
    } else {
      console.log("No photo selected.");
    }
  }

  const handleAdvertiseNameChange = (e) => {
    setAdvertiseName(e.target.value);
  }

  if (!props.trigger) {
    return;
  }
  else {

    return (
      <>

        <Acknowledge trigger={clickConfirm} setTrigger={setClickConfirm} message={message} />
        <Spinner trigger={spinnerActive} setTrigger={setSpinnerActive}></Spinner>
        <div className='modalNewProject'>


          <div className='modal-contentNewProject'>
            <div className='modal-headerNewProject'>
              <div className="notification-close" onClick={() => { props.setTrigger(false); setPhoto(null); setAdvertiseName(null) }}>
                ×
              </div>
              <h4 className='modal-titleNewListing'>ADD ADVERTISEMENT</h4>
            </div>
            <div className='modal-bodyNewProject' >

              <p style={{ textAlign: "center" }}>For optimal image optimization, it is recommended to use a 4:5 aspect ratio (e.g. 842 x 1,191 pixels).</p>
              <button class="defaultUploadBtn" onClick={() => { uploadSelfImage.current.click() }} style={{ width: "90%", marginLeft: "5%" }}>Upload Images</button>
              <input ref={uploadSelfImage} id="file-upload" type="file" accept=".png, .jpg, .jpeg" style={{ display: "none" }} onChange={(event) => { setPhotoUpload(event) }} />
              {photo &&
                <>
                  <p style={{ textAlign: "center" }}>Preview</p>
                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "80%", marginLeft: "10%", alignItems: "center" }}>
                    <input placeholder='Advertisement Label' style={{ padding: "5px", borderRadius: "10px", marginBottom: "3%", width: "60%", border: "1px solid #c0c0c0" }} value={advertiseName} onChange={handleAdvertiseNameChange}></input>
                    <img style={{ aspectRatio: "4/5", height: "auto", width: "340px" }} className='advertiseCard' src={photo} alt="Uploaded" />
                  </div>
                </>}
            </div>
            <div className='modal-footerNewProject'>


              <button className='boostBtn' onClick={() => proceedButton()}>Proceed</button>
              <div style={{ clear: 'both' }}></div>


            </div>
          </div>
        </div>

      </>
    );
  }

}
