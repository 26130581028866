import React from 'react';
import './components/css/NewProject.css';
import { UserAuth } from './AuthContent';

export default function AcknowledgeNormal(props) {
    if (!props.trigger) {
        return;
    }
    else {

        const closeAcknowledge = async () => {
            props.setTrigger(false);
            if(props.hasOwnProperty('parentTrigger')){
                props.parentTrigger(false);
            }
            else if(props.hasOwnProperty('setConfirmedNoResearchDeletePhotos')){
                props.setConfirmedNoResearchDeletePhotos(false);
            }
            else if(props.hasOwnProperty('setProcessCancelSubcription')){
                props.setProcessCancelSubcription(false);
            }
            else if(props.hasOwnProperty('closeAppointment')){
                props.closeAppointment(); 
            }

          



        }


        return ( 
            <>
                <div className='modalNewProject' style={{ zIndex: "999" }}>
                    <div className='modal-contentAcknowledge'>
                        <div className='modal-headerNewProject'>
                            <h4 className='modal-titleNewProject'>ACKNOWLEDGEMENT</h4>
                        </div>
                        <div class='modal-acknowledge'>
                            <p>{props.message}</p>

                        </div>
                        <div className='modal-footerNewProject'>
                            <button className='newListingBtn' onClick={() => closeAcknowledge()}>Close</button>
                            {props.hasOwnProperty('setWarningPurchase') ? <button className='newListingBtn'  style={{marginRight:"1%"}}onClick={() => {props.setWarningPurchase(true); props.setTrigger(false)}}>Confirm</button>:null}
                            {props.hasOwnProperty('setConfirmedDeletePhotos') ? <button className='newListingBtn'  style={{marginRight:"1%"}}onClick={() => {props.setConfirmedDeletePhotos(true); props.setTrigger(false)}}>Confirm</button>:null}
                            {props.hasOwnProperty('setProceedAccountDelete') ? <button className='newListingBtn'  style={{marginRight:"1%"}}onClick={() => {props.setProceedAccountDelete(true); props.setTrigger(false)}}>Confirm</button>:null}
                            {props.message == "Confirm to delete your listing" ? <button className='newListingBtn'  style={{marginRight:"1%"}}onClick={() => {props.setConfirmedDelete(true); props.setTrigger(false)}}>Confirm</button>:null}
                            {props.hasOwnProperty('setProcessCancelSubcription') ? <button className='newListingBtn'  style={{marginRight:"1%"}}onClick={() => {props.setProcessCancelSubcription(true); props.setTrigger(false)}}>Confirm</button>:null}
                            {props.hasOwnProperty('setProceedDeactivate') ? <button className='newListingBtn'  style={{marginRight:"1%"}}onClick={() => {props.setProceedDeactivate(true); props.setTrigger(false)}}>Confirm</button>:null}
                            {props.hasOwnProperty('setProceedAdvertiseDelete') ? <button className='newListingBtn'  style={{marginRight:"1%"}}onClick={() => {props.setProceedAdvertiseDelete(true); props.setTrigger(false)}}>Confirm</button>:null}
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </div>

            </>
        );
    }

}
