import React, { useEffect, useState, useRef } from 'react';
import { UserAuth } from '../../AuthContent';
import { setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase/firebase';
import { getDownloadURL, ref, uploadBytes, listAll, getStorage, deleteObject, uploadString, getMetadata } from 'firebase/storage';
import AcknowledgeNormal from '../../AcknowledgeNormal';



export default function PromoPageAdvertise(props) {
    const { user } = UserAuth();
    const [promoCodeInput, setPromoCodeInput] = useState('');
    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
    const [message, setMessage] = useState(null);

    const closePromoPage = () => {
        props.setTrigger(false);
    }

    function addMonths(date, months) {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + months);
        return newDate;
    }
    
    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
    

    const proceedPromotionCode = async () => {
        console.log(props);
        await getDoc(doc(db, 'promotionCode', 'promoCode')).then(async (promotionCodeDoc) => {
            if (promoCodeInput == promotionCodeDoc.data().uniqueAdv) {
                var newAdvertisementList = promotionCodeDoc.data().advertisement;

                if (props.currentUserInfo.launchedPromotionCode.includes(promoCodeInput) == false) {
                    const startDateStr = props.promoItemNeeded.sortedDates[0];
                    const startDateParts = startDateStr.split('/');
                    const startDate = new Date(startDateParts[2], startDateParts[1] - 1, startDateParts[0]);
                    
                    const endDate = addMonths(startDate, 2);
                    const dates = [];
                    let currentDate = startDate;
                    while (currentDate <= endDate) {
                        dates.push(formatDate(currentDate));
                        currentDate.setDate(currentDate.getDate() + 1);
                    }
                    console.log(dates)
                    await updateDoc(doc(db, 'advertiseListing', props.advertiseID), {
                        activatedDate: dates
                    }).then(async () => {
                        await updateDoc(doc(db, 'promotionCode', 'promoCode'), {
                            advertisement: newAdvertisementList
                        }).then(() => {
                            // props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage("Promotion Code Verified.");
                            setAcknowledgeNormal(true);
                            setPromoCodeInput(null);
                        })

                       
                    })



                }
                else {
                    alert("You have already redeemed this promotion")
                }
            }
            else if (promotionCodeDoc.data().advertisement.includes(promoCodeInput)) {
                var newAdvertisementList = promotionCodeDoc.data().advertisement;
                if(props.dateArrLength != 3){
                    alert("Promo code is valid for a one-time advertising credit for 3 days, kindly select 3 dates to continue");
                    return;
                }
                else if (props.currentUserInfo.launchedPromotionCode.includes(promoCodeInput) == false) {
                    newAdvertisementList.splice(newAdvertisementList.indexOf(promoCodeInput), 1);
                    // var newLaunchedPromotionCode = [...props.currentUserInfo.launchedPromotionCode];
                    // newLaunchedPromotionCode.push(promoCodeInput);
                    // var newCurrentUserInfo = props.currentUserInfo;
                    // newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;
                    await updateDoc(doc(db, 'commonData', 'advertiseDetails'), {
                        advertiseSlot: props.promoItemNeeded.newQuota
                    }).then(async () => {
                        await updateDoc(doc(db, 'advertiseListing', props.advertiseID), {
                            activatedDate: props.promoItemNeeded.sortedDates
                        }).then(async () => {
                            await updateDoc(doc(db, 'promotionCode', 'promoCode'), {
                                advertisement: newAdvertisementList
                            }).then(() => {
                                // props.setCurrentUserInfo(newCurrentUserInfo);
                                setMessage("Promotion Code Verified.");
                                setAcknowledgeNormal(true);
                                setPromoCodeInput(null);
                            })

                            // await updateDoc(doc(db, 'userInfo', user.uid), {
                            //     launchedPromotionCode: newLaunchedPromotionCode,
                            // }).then(() => {
                            //     props.setCurrentUserInfo(newCurrentUserInfo);
                            //     setMessage("Promotion Code Verified.");
                            //     setAcknowledgeNormal(true);
                            //     setPromoCodeInput(null);
                            //     // props.setTrigger(false);
                            // })
                        })
                    })


                }
                else {
                    alert("You have already redeemed this promotion")
                }
            }
            else {
                alert('Invalid code')
            }

        })
    }

    if (!props.trigger) {
        return;
    }
    else {

        return (
            <>
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={message} parentTrigger={props.parentDiv}></AcknowledgeNormal>
                <div className='modalNewProject' style={{ zIndex: "3" }}>
                    <div className='modal-contentNewProject' >
                        <div className='modal-headerNewProject'>

                            <div className="notification-close" onClick={() => closePromoPage()}>
                                ×
                            </div>

                            <h4 className='modal-titleNewProject'>PROMOTION CODE</h4>
                        </div>

                        <div className='modal-bodyNewProject'>
                            <input style={{ width: "90%", marginLeft: "5%", padding: "5px 10px", borderRadius: "10px" }} placeholder='Key in your promo code' value={promoCodeInput} onChange={(e) => setPromoCodeInput(e.target.value)}></input>

                        </div>

                        <div className='modal-footerNewProject'>
                            <button id="addPropertyBtn" onClick={() => proceedPromotionCode()}>Proceed</button>

                            <div style={{ clear: 'both' }}></div>
                            {/* <button  onClick={() => savePropertyLocation()}>Save</button> */}

                        </div>
                    </div>
                </div>
            </>
        );
    }

}