import React, { useEffect, useState, useRef } from 'react';
import { UserAuth } from '../../AuthContent';
import { doc, setDoc, getDocs, collection, addDoc, query, where, getDoc, updateDoc, serverTimestamp, deleteField } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { signInWithPhoneNumber, getAuth, deleteUser } from "firebase/auth";
import firebase from '../../firebase/firebase';
import Acknowledge from './Acknowledge';
import '../css/OTP.css';


export default function OTP(props) {
    const { user, deleteUserData, setUser, logOut } = UserAuth();
    const auth = getAuth();
    const navigate = useNavigate();
    let locationURL = window.location.href;
    const appVerifier = window.recaptchaVerifier;
    const [timer, setTimer] = useState(0);
    const [showVerifyBtn, setShowVerifyBtn] = useState(false);
    const [allowReverification, setAllowReverification] = useState(false);
    const [otp, setotp] = useState('');
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState('');
    const [OTPsent, setOTPsent] = useState(false);
    const [approvedOTP, setApprovedOTP] = useState(false);
    var userPreviousArr = useRef([]);
    var mynumber = useRef(0);
    const [acknowledge, setAcknowledge] = useState(false);
    const [HPnum, setHPnum] = useState(null);
    const [spinnerActivated, setSpinnerActivated] = useState(false);
    const [countryCode, setCountryCode] = useState("65");
    const [referFriendCode, setReferFriendCode] = useState("");
    const [choosePrimaryRole, setChoosePrimaryRole] = useState(true);
    const [targetPrimaryRoleCheck, setTargetPrimaryRoleCheck] = useState(null);
    const [otpInput, setOTP] = useState(Array(6).fill(''));
    const inputRefs = useRef([]);
    const [OTPSendNoti, setOTPSendNoti] = useState(false);
    const [somethingWrong, setSomethingWrong] = useState(false);

    var testingCredential_token = useRef(null);

    useEffect(() => {
        if (OTPSendNoti || somethingWrong) {
            // Set a timer to reset both OTPSendNoti and somethingWrong after 5 seconds
            const timer = setTimeout(() => {
                if (OTPSendNoti) {
                    setOTPSendNoti(false);
                }
                if (somethingWrong) {
                    setSomethingWrong(false)
                }
            }, 5000);

            // Cleanup timer when component unmounts or either OTPSendNoti or somethingWrong changes
            return () => clearTimeout(timer);
        }
    }, [OTPSendNoti, somethingWrong]);
    const confirmNumber = async () => {
        const checkbox = document.getElementById('acceptStandard');

        // Check if the checkbox is checked
        if (checkbox.checked) {


            // mynumber.current = document.getElementById('phone').value;
            var inputString = document.getElementById('phone').value;
            var outputHP = "";
            for (let i = 0; i < inputString.length; i++) {
                if (inputString[i] !== " ") {
                    outputHP += inputString[i];
                }
            }
            mynumber.current = outputHP;
            if (mynumber.current === "" || mynumber.current.length < 8) return;
            setshow(true);
            setTimer(30);
            setAllowReverification(false);



            let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
            const querySnapshot = await getDocs(query(collection(db, 'userInfo'), where("phoneNumber", "==", Number(mynumber.current))));
            if (querySnapshot.docs.length != 0) {
                alert("Phone number has been registered before");
                setshow(false);
            }
            else {

                signInWithPhoneNumber(auth, "+65" + mynumber.current, verify).then((result) => {
                    setfinal(result);
                    setOTPSendNoti(true)
                    setShowVerifyBtn(true);
                    setOTPsent(true);
                    setshow(false);
                    setTimer(30);

                })
                    .catch((err) => {
                        alert(err);
                        window.location.reload()
                    });
            }
        }
        else {
            alert("Before proceeding, kindly accept our standard terms and conditions.")
        }

    }

    const handleCountryCode = (e) => {
        setCountryCode(e.target.value);
    }

    useEffect(() => {
        if (props.trigger) {
            if (props.targetPrimaryRole != null) {
                setTargetPrimaryRoleCheck(props.targetPrimaryRole);
                setChoosePrimaryRole(false);
            }

        }
    }, props)
    function mixString(str) {
        let result = "";
        let digits = "0123456789";

        // Mix up the characters in the string
        for (let i = 0; i < str.length; i++) {
            if (Math.random() < 0.5) {
                result += str.charAt(i).toLowerCase();
            } else {
                result += str.charAt(i).toUpperCase();
            }
        }

        // Add random digits to the string
        for (let i = 0; i < 3; i++) {
            let index = Math.floor(Math.random() * result.length);
            let digit = digits.charAt(Math.floor(Math.random() * digits.length));
            result = result.slice(0, index) + digit + result.slice(index);
        }

        return result;
    }

    // Validate OTP
    const ValidateOtp = () => {
        setSpinnerActivated(true);
        if (otpInput === "" || otpInput.length != 6 || final === null) {
            return;
        }

        final.confirm(parseInt(otpInput.join(''))).then(async (result) => {
            // success
            // props.setUserType(true);

            const res = await getDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid));
            if (res._document == null) {
                setApprovedOTP(true);
                const existingDoc = await getDocs(query(collection(db, 'deleteUserInfo'), where('phoneNumber', '==', Number(mynumber.current))));
                var launchedPromotionCodeArr = [];
                var newUser = true;
                let transactionHistoryAllocated = [];
                let pointsAllocated = 0;
                if (referFriendCode != "") {
                    var existingDocReferCode = await getDoc(doc(db, 'userInfo', referFriendCode));
                }
                else {
                    existingDocReferCode = null;
                }
                console.log(existingDocReferCode)
                if (existingDocReferCode != null) {
                    if (existingDocReferCode.exists()) {
                        pointsAllocated = 800;
                        transactionHistoryAllocated = [{
                            "Actions": "+800 Points",
                            "Activity": "Refer a friend",
                            "Date": new Date(),
                            "Type": "Points Activity"
                        }]
                    }
                }
                // if(existingDocReferCode.exists()){
                //     if (!existingDoc.empty) {
                //         const sortedDocs = existingDoc.docs.sort((a, b) => {
                //             const deleteDateA = a.data().deleteDate;
                //             const deleteDateB = b.data().deleteDate;

                //             if (deleteDateA > deleteDateB) {
                //                 return -1;
                //             } else if (deleteDateA < deleteDateB) {
                //                 return 1;
                //             }
                //             return 0;
                //         });
                //         launchedPromotionCodeArr = sortedDocs[0].data().launchedPromotionCode;
                //         newUser = false;
                //         // console.log(querySnapshot.docs[0].data());
                //     }
                //     if (targetPrimaryRoleCheck == "Looking") {
                //         await setDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid), {
                //             displayName: userPreviousArr.current[0].displayName,
                //             connectionsNumber: 0,
                //             userPhotoURL: userPreviousArr.current[0].photoURL,
                //             uid: userPreviousArr.current[0].uid,
                //             userType: ["Tenant"],
                //             membership: null,
                //             chatLeft: 0,
                //             phoneNumber: Number(mynumber.current),
                //             advancedDetails: 0,
                //             billingAmount: 0,
                //             subscriptionCounter: 0,
                //             freeAppt: false,
                //             launchedPromotionCode: launchedPromotionCodeArr,
                //             newUserSetup: true,
                //             subscriptionID: null,
                //             videoCount: 0,
                //             businessArray: [],
                //             startDate: new Date(),
                //             verifiedBefore: false,
                //             referFriendCode: newUser ? 1 : 0
                //         }).then(async () => {
                //             if(newUser){
                //                 await updateDoc(doc(db, 'userInfo', referFriendCode),{
                //                     referFriendCode: existingDocReferCode.data().hasOwnProperty('referFriendCode') ? existingDocReferCode.data().referFriendCode + 1: 1
                //                 })
                //             }

                //             await setDoc(doc(db, 'publicUserInfo', userPreviousArr.current[0].uid), {
                //                 lastLogin: serverTimestamp(),
                //                 username: mixString(userPreviousArr.current[0].displayName),
                //                 email: userPreviousArr.current[0].email,
                //                 emailNotificationPending: true,
                //             })
                //         }).then(async () => {
                //             await deleteUser(result.user).then(() => {
                //                 setAcknowledge(true);
                //                 setSpinnerActivated(false);
                //                 return;
                //             })
                //         })
                //     }
                //     else if (targetPrimaryRoleCheck == "Advertiser") {
                //         await setDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid), {
                //             displayName: userPreviousArr.current[0].displayName,
                //             connectionsNumber: 0,
                //             userPhotoURL: userPreviousArr.current[0].photoURL,
                //             uid: userPreviousArr.current[0].uid,
                //             userType: ["Advertiser"],
                //             membership: null,
                //             chatLeft: 0,
                //             phoneNumber: Number(mynumber.current),
                //             advancedDetails: 0,
                //             billingAmount: 0,
                //             subscriptionCounter: 0,
                //             freeAppt: false,
                //             launchedPromotionCode: launchedPromotionCodeArr,
                //             newUserSetup: true,
                //             videoCount: 0,
                //             subscriptionID: null,
                //             businessArray: [],
                //             startDate: new Date(),
                //             verifiedBefore: false,
                //             referFriendCode: newUser ? 1 : 0,
                //             credit: 10
                //         }).then(async () => {
                //             if(newUser){
                //                 await updateDoc(doc(db, 'userInfo', referFriendCode),{
                //                     referFriendCode: existingDocReferCode.data().hasOwnProperty('referFriendCode') ? existingDocReferCode.data().referFriendCode + 1: 1
                //                 })
                //             }

                //             await setDoc(doc(db, 'publicUserInfo', userPreviousArr.current[0].uid), {
                //                 lastLogin: serverTimestamp(),
                //                 username: mixString(userPreviousArr.current[0].displayName),
                //                 email: userPreviousArr.current[0].email,
                //                 emailNotificationPending: true,
                //             })
                //         }).then(async () => {
                //             await deleteUser(result.user).then(() => {
                //                 setAcknowledge(true);
                //                 setSpinnerActivated(false);
                //                 return;
                //             })
                //         })
                //     }
                //     else if (targetPrimaryRoleCheck == "Property") {
                //         await setDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid), {
                //             displayName: userPreviousArr.current[0].displayName,
                //             connectionsNumber: 0,
                //             userPhotoURL: userPreviousArr.current[0].photoURL,
                //             uid: userPreviousArr.current[0].uid,
                //             propertyAddressList: [],
                //             propertyAddressListResidential: [],
                //             userType: ["Agent"],
                //             membership: null,
                //             chatLeft: 0,
                //             phoneNumber: Number(mynumber.current),
                //             advancedDetails: 0,
                //             billingAmount: 0,
                //             subscriptionCounter: 0,
                //             freeAppt: false,
                //             launchedPromotionCode: launchedPromotionCodeArr,
                //             newUserSetup: true,
                //             videoCount: 0,
                //             subscriptionID: null,
                //             businessArray: [],
                //             startDate: new Date(),
                //             verifiedBefore: false,
                //             referFriendCode:  newUser ? 1 : 0,
                //             credit: 10
                //         }).then(async () => {
                //             if(newUser){
                //                 await updateDoc(doc(db, 'userInfo', referFriendCode),{
                //                     referFriendCode: existingDocReferCode.data().hasOwnProperty('referFriendCode') ? existingDocReferCode.data().referFriendCode + 1: 1
                //                 })
                //             }

                //             await setDoc(doc(db, 'publicUserInfo', userPreviousArr.current[0].uid), {
                //                 lastLogin: serverTimestamp(),
                //                 username: mixString(userPreviousArr.current[0].displayName),
                //                 email: userPreviousArr.current[0].email,
                //                 emailNotificationPending: true,
                //             })
                //         }).then(async () => {
                //             await deleteUser(result.user).then(() => {
                //                 setAcknowledge(true);
                //                 setSpinnerActivated(false);
                //                 return;
                //             })
                //         })
                //     }
                //     else {
                //         await setDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid), {
                //             displayName: userPreviousArr.current[0].displayName,
                //             connectionsNumber: 0,
                //             userPhotoURL: userPreviousArr.current[0].photoURL,
                //             uid: userPreviousArr.current[0].uid,
                //             userType: null,
                //             membership: null,
                //             chatLeft: 0,
                //             phoneNumber: Number(mynumber.current),
                //             advancedDetails: 0,
                //             billingAmount: 0,
                //             subscriptionCounter: 0,
                //             freeAppt: false,
                //             launchedPromotionCode: launchedPromotionCodeArr,
                //             newUserSetup: true,
                //             videoCount: 0,
                //             subscriptionID: null,
                //             businessArray: [],
                //             startDate: new Date(),
                //             verifiedBefore: false,
                //             referFriendCode: newUser ? 1 : 0,
                //             credit: 10
                //         }).then(async () => {
                //             if(newUser){
                //                 await updateDoc(doc(db, 'userInfo', referFriendCode),{
                //                     referFriendCode: existingDocReferCode.data().hasOwnProperty('referFriendCode') ? existingDocReferCode.data().referFriendCode + 1: 1
                //                 })
                //             }

                //             await setDoc(doc(db, 'publicUserInfo', userPreviousArr.current[0].uid), {
                //                 lastLogin: serverTimestamp(),
                //                 username: mixString(userPreviousArr.current[0].displayName),
                //                 email: userPreviousArr.current[0].email,
                //                 emailNotificationPending: true,
                //             })
                //         }).then(async () => {
                //             await deleteUser(result.user).then(() => {
                //                 setAcknowledge(true);
                //                 setSpinnerActivated(false);
                //                 return;
                //             })
                //         })
                //     }

                // }
                // else{
                // if (!existingDoc.empty) {
                //     const sortedDocs = existingDoc.docs.sort((a, b) => {
                //         const deleteDateA = a.data().deleteDate;
                //         const deleteDateB = b.data().deleteDate;

                //         if (deleteDateA > deleteDateB) {
                //             return -1;
                //         } else if (deleteDateA < deleteDateB) {
                //             return 1;
                //         }
                //         return 0;
                //     });
                //     launchedPromotionCodeArr = sortedDocs[0].data().launchedPromotionCode;
                //     // console.log(querySnapshot.docs[0].data());
                // }
                if (targetPrimaryRoleCheck == "User") {
                    await setDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid), {
                        displayName: userPreviousArr.current[0].displayName,
                        userPhotoURL: userPreviousArr.current[0].photoURL,
                        uid: userPreviousArr.current[0].uid,
                        userType: ["User"],
                        phoneNumber: Number(mynumber.current),
                        launchedPromotionCode: launchedPromotionCodeArr,
                        newUserSetup: true,
                        businessArray: [],
                        startDate: new Date(),
                        verifiedBefore: false,
                        points: pointsAllocated,
                        pointsDate: new Date(),
                        credit: 800,
                        transactionHistory: transactionHistoryAllocated
                    }).then(async () => {
                        await setDoc(doc(db, 'publicUserInfo', userPreviousArr.current[0].uid), {
                            lastLogin: serverTimestamp(),
                            username: mixString(userPreviousArr.current[0].displayName),
                            email: userPreviousArr.current[0].email,
                            emailNotificationPending: true,
                        })
                    }).then(async () => {
                        // console.log(result.user)
                        if (props.requirementsFromHome != null) {
                            sessionStorage.setItem('requirements', JSON.stringify(props.requirementsFromHome))
                        }
                        if (existingDocReferCode != null) {
                            if (existingDocReferCode.exists()) {
                                let newTransactionHistory = existingDocReferCode.data().transactionHistory;
                                if (newTransactionHistory.length >= 30) {
                                    newTransactionHistory.shift();
                                    newTransactionHistory.push({
                                        "Actions": "+800 Points",
                                        "Activity": "Refer a friend",
                                        "Date": new Date(),
                                        "Type": "Points Activity"
                                    })
                                }
                                else {
                                    newTransactionHistory.push({
                                        "Actions": "+800 Points",
                                        "Activity": "Refer a friend",
                                        "Date": new Date(),
                                        "Type": "Points Activity"
                                    })
                                }
                                let updateOppPartyPoints = await updateDoc(doc(db, 'userInfo', referFriendCode), {
                                    points: existingDocReferCode.data().points + 800,
                                    transactionHistory: newTransactionHistory

                                })
                            }
                        }
                        await logOut();
                        setAcknowledge(true);
                        setSpinnerActivated(false);
                        return;
                        // await deleteUser(result.user).then(async () => {
                            
                           
                        // })
                    })
                }
                else if (targetPrimaryRoleCheck == "Property") {
                    await setDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid), {
                        displayName: userPreviousArr.current[0].displayName,
                        userPhotoURL: userPreviousArr.current[0].photoURL,
                        uid: userPreviousArr.current[0].uid,
                        userType: ["Agent"],
                        phoneNumber: Number(mynumber.current),
                        launchedPromotionCode: launchedPromotionCodeArr,
                        newUserSetup: true,
                        businessArray: [],
                        startDate: new Date(),
                        verifiedBefore: false,
                        points: pointsAllocated,
                        pointsDate: new Date(),
                        credit: 800,
                        transactionHistory: transactionHistoryAllocated
                    }).then(async () => {
                        await setDoc(doc(db, 'publicUserInfo', userPreviousArr.current[0].uid), {
                            lastLogin: serverTimestamp(),
                            username: mixString(userPreviousArr.current[0].displayName),
                            email: userPreviousArr.current[0].email,
                            emailNotificationPending: true,
                        })
                    }).then(async () => {
                        if (existingDocReferCode != null) {
                            if (existingDocReferCode.exists()) {
                                let newTransactionHistory = existingDocReferCode.data().transactionHistory;
                                if (newTransactionHistory.length >= 30) {
                                    newTransactionHistory.shift();
                                    newTransactionHistory.push({
                                        "Actions": "+800 Points",
                                        "Activity": "Refer a friend",
                                        "Date": new Date(),
                                        "Type": "Points Activity"
                                    })
                                }
                                else {
                                    newTransactionHistory.push({
                                        "Actions": "+800 Points",
                                        "Activity": "Refer a friend",
                                        "Date": new Date(),
                                        "Type": "Points Activity"
                                    })
                                }
                                let updateOppPartyPoints = await updateDoc(doc(db, 'userInfo', referFriendCode), {
                                    points: existingDocReferCode.data().points + 800,
                                    transactionHistory: newTransactionHistory

                                })
                            }
                        }
                        await logOut();
                        setAcknowledge(true);
                        setSpinnerActivated(false);
                        return;
                        
                    })
                }

                // if (targetPrimaryRoleCheck == "Looking") {
                //     await setDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid), {
                //         displayName: userPreviousArr.current[0].displayName,
                //         connectionsNumber: 0,
                //         userPhotoURL: userPreviousArr.current[0].photoURL,
                //         uid: userPreviousArr.current[0].uid,
                //         userType: ["Tenant"],
                //         membership: null,
                //         chatLeft: 0,
                //         phoneNumber: Number(mynumber.current),
                //         advancedDetails: 0,
                //         billingAmount: 0,
                //         subscriptionCounter: 0,
                //         freeAppt: false,
                //         launchedPromotionCode: launchedPromotionCodeArr,
                //         newUserSetup: true,
                //         subscriptionID: null,
                //         videoCount: 0,
                //         businessArray: [],
                //         startDate: new Date(),
                //         verifiedBefore: false
                //     }).then(async () => {
                //         await setDoc(doc(db, 'publicUserInfo', userPreviousArr.current[0].uid), {
                //             lastLogin: serverTimestamp(),
                //             username: mixString(userPreviousArr.current[0].displayName),
                //             email: userPreviousArr.current[0].email,
                //             emailNotificationPending: true,
                //         })
                //     }).then(async () => {
                //         await deleteUser(result.user).then(() => {
                //             setAcknowledge(true);
                //             setSpinnerActivated(false);
                //             return;
                //         })
                //     })
                // }
                // else if (targetPrimaryRoleCheck == "Advertiser") {
                //     await setDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid), {
                //         displayName: userPreviousArr.current[0].displayName,
                //         connectionsNumber: 0,
                //         userPhotoURL: userPreviousArr.current[0].photoURL,
                //         uid: userPreviousArr.current[0].uid,
                //         userType: ["Advertiser"],
                //         membership: null,
                //         chatLeft: 0,
                //         phoneNumber: Number(mynumber.current),
                //         advancedDetails: 0,
                //         billingAmount: 0,
                //         subscriptionCounter: 0,
                //         freeAppt: false,
                //         launchedPromotionCode: launchedPromotionCodeArr,
                //         newUserSetup: true,
                //         videoCount: 0,
                //         subscriptionID: null,
                //         businessArray: [],
                //         startDate: new Date(),
                //         verifiedBefore: false,
                //         credit: 10
                //     }).then(async () => {
                //         await setDoc(doc(db, 'publicUserInfo', userPreviousArr.current[0].uid), {
                //             lastLogin: serverTimestamp(),
                //             username: mixString(userPreviousArr.current[0].displayName),
                //             email: userPreviousArr.current[0].email,
                //             emailNotificationPending: true,
                //         })
                //     }).then(async () => {
                //         await deleteUser(result.user).then(() => {
                //             setAcknowledge(true);
                //             setSpinnerActivated(false);
                //             return;
                //         })
                //     })
                // }
                // else if (targetPrimaryRoleCheck == "Property") {
                //     await setDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid), {
                //         displayName: userPreviousArr.current[0].displayName,
                //         connectionsNumber: 0,
                //         userPhotoURL: userPreviousArr.current[0].photoURL,
                //         uid: userPreviousArr.current[0].uid,
                //         propertyAddressList: [],
                //         propertyAddressListResidential: [],
                //         userType: ["Agent"],
                //         membership: null,
                //         chatLeft: 0,
                //         phoneNumber: Number(mynumber.current),
                //         advancedDetails: 0,
                //         billingAmount: 0,
                //         subscriptionCounter: 0,
                //         freeAppt: false,
                //         launchedPromotionCode: launchedPromotionCodeArr,
                //         newUserSetup: true,
                //         videoCount: 0,
                //         subscriptionID: null,
                //         businessArray: [],
                //         startDate: new Date(),
                //         verifiedBefore: false,
                //         credit: 10
                //     }).then(async () => {
                //         await setDoc(doc(db, 'publicUserInfo', userPreviousArr.current[0].uid), {
                //             lastLogin: serverTimestamp(),
                //             username: mixString(userPreviousArr.current[0].displayName),
                //             email: userPreviousArr.current[0].email,
                //             emailNotificationPending: true,
                //         })
                //     }).then(async () => {
                //         await deleteUser(result.user).then(() => {
                //             setAcknowledge(true);
                //             setSpinnerActivated(false);
                //             return;
                //         })
                //     })
                // }
                // else {
                //     await setDoc(doc(db, 'userInfo', userPreviousArr.current[0].uid), {
                //         displayName: userPreviousArr.current[0].displayName,
                //         connectionsNumber: 0,
                //         userPhotoURL: userPreviousArr.current[0].photoURL,
                //         uid: userPreviousArr.current[0].uid,
                //         userType: null,
                //         membership: null,
                //         chatLeft: 0,
                //         phoneNumber: Number(mynumber.current),
                //         advancedDetails: 0,
                //         billingAmount: 0,
                //         subscriptionCounter: 0,
                //         freeAppt: false,
                //         launchedPromotionCode: launchedPromotionCodeArr,
                //         newUserSetup: true,
                //         videoCount: 0,
                //         subscriptionID: null,
                //         businessArray: [],
                //         startDate: new Date(),
                //         verifiedBefore: false,
                //         credit: 10
                //     }).then(async () => {
                //         await setDoc(doc(db, 'publicUserInfo', userPreviousArr.current[0].uid), {
                //             lastLogin: serverTimestamp(),
                //             username: mixString(userPreviousArr.current[0].displayName),
                //             email: userPreviousArr.current[0].email,
                //             emailNotificationPending: true,
                //         })
                //     }).then(async () => {
                //         await deleteUser(result.user).then(() => {
                //             setAcknowledge(true);
                //             setSpinnerActivated(false);
                //             return;
                //         })
                //     })
                // }
                // }




                // }
            }
            else {
                await deleteUser(result.user).then(() => {
                    setAcknowledge(true);
                    setSpinnerActivated(false);
                    return;
                })
            }



            // props.setUserType(true);
        })
            .catch((err) => {
                if (err.code == "auth/invalid-verification-code") {
                    setOTPSendNoti(true);
                }
                else {
                    //Other Error
                }

                console.log(err);
                // alert(err);
                setshow(false);
                setSpinnerActivated(false);
            })
    }



    useEffect(() => {

        if (user != null) {
            if (Object.keys(user).length != 0) {
                userPreviousArr.current.push(user);
            }
        }
    }, [user])


    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer(timer - 1)
            }, 1000);
            return () => clearInterval(interval);
        }
        else {
            setAllowReverification(true);
        }
    }, [timer]);

    useEffect(() => {
        // if(approvedOTP == true){
        window.onbeforeunload = async function () {
            if (user != null && Object.keys(user).length != 0) {
                console.log(user);
                // await deleteUser(user).then(() => {
                //     return;
                // })
            }
            else {
                return;
            }
            // return "write something here";
            // };

        }
        return () => {
            if (approvedOTP == false) {
                window.onbeforeunload = null;
            }
        };
    }, [user])

    const reverify = () => {
        setshow(false);
        setOTP(Array(6).fill(''));
        setShowVerifyBtn(false);
        setOTPsent(false);
        setTimer(-1);
    }

    const handleHPChanges = (event) => {

        setHPnum(event.target.value);
    }

    const handleOTPChange = (e, index) => {
        const enteredOTP = e.target.value;

        // Only allow 6 digits in the OTP input
        if (/^\d{0,6}$/.test(enteredOTP)) {
            const newOTP = [...otpInput];
            newOTP[index] = enteredOTP;
            console.log(newOTP);
            setOTP(newOTP);

            // Move focus to the next input box if available
            if (enteredOTP && index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };


    const chooseRole = (side) => {
        setTargetPrimaryRoleCheck(side);
        setChoosePrimaryRole(false);
    }

    if (!props.trigger) {
        return;
    }
    else {


        return (
            <>
                <Acknowledge trigger={acknowledge} setTrigger={setAcknowledge} message={"Your account has been created. Login to Next Loop"} parent={"UserType"} />

                <div class="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                    <div class="absolute bg-black opacity-80 inset-0 z-0"></div>
                    {OTPSendNoti ?
                        <div role="alert" className="rounded-xl border border-gray-100 bg-white p-4 absolute top-0 mt-1% z-50">
                            <div className="flex items-start gap-4">
                                <span className="text-green-600">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="h-6 w-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                </span>

                                <div className="flex-1">
                                    <strong className="block font-medium text-gray-900">Successful OTP</strong>

                                    <p className="mt-1 text-sm text-gray-700">
                                        OTP sent
                                    </p>
                                </div>


                            </div>
                        </div> : null}
                    {somethingWrong ? <div role="alert" className="rounded border-s-4 border-red-500 bg-red-50 p-4 absolute top-0 mt-1% z-50">
                        <strong className="block font-medium text-red-800">Something went wrong</strong>

                        <p className="mt-2 text-sm text-red-700">
                            OTP does not match
                        </p>
                    </div> : null}
                    <div class="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">

                        <div class="text-center p-5 flex-auto justify-center">
                            <h2 class="text-xl font-bold py-4 ">{choosePrimaryRole ? "WHAT'S YOUR ROLE?" : "TWO-FACTOR AUTHENTICATION"}</h2>

                        </div>
                        <div >
                            <center>
                                {choosePrimaryRole ?
                                    <>

                                        <p class="text-sm text-gray-500 px-8">Are you a property agent?</p>


                                        <div class="p-3  mt-2 text-center space-x-4 md:block">
                                            <button class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100" onClick={() => { chooseRole("User") }}>
                                                No
                                            </button>
                                            <button class="mb-2 md:mb-0 bg-slate-500 border border-slate-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-slate-600" onClick={() => { chooseRole("Property") }}>Yes</button>
                                        </div>
                                    </>
                                    : null}
                                {/* <div id="primaryRoleSubTier" style={choosePrimaryRole ? { display: "block", width: "100%", cursor: "pointer", paddingTop: "30px", paddingBottom: "30px", borderRadius: "10px", fontWeight: "bold", backgroundColor: "#3161906b" } : { display: "none" }} onClick={() => { chooseRole("Property") }}>Property Agent</div>
                                <div style={choosePrimaryRole ? { display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" } : { display: "none", flexDirection: "row", justifyContent: "center" }}>
                                    <div id="lookingSpace" onClick={() => { chooseRole("Looking") }}>Looking For Space<br></br><span style={{ fontSize: "15px" }}>(Buyers/Tenants)</span></div>
                                    <div id="gotSpace" onClick={() => { chooseRole("Got") }}>Got Space<br></br><span style={{ fontSize: "15px" }}>(Landlords/Master Tenants)</span></div>

                                </div>
                                <div id="primaryRoleSubTier" style={choosePrimaryRole ? { display: "block", width: "100%", cursor: "pointer", paddingTop: "30px", paddingBottom: "30px", borderRadius: "10px", fontSize: "20x", fontWeight: "bold", backgroundColor: "#3161906b" } : { display: "none" }} onClick={() => { chooseRole("Advertiser") }}>Advertise With Us</div> */}


                                {show == false && OTPsent == false && choosePrimaryRole == false ?
                                    <div>
                                        <div style={{ border: "1px solid #de0063", width: "fit-content", display: "flex", flexDirection: "row", padding: "8px 8px", borderRadius: "10px", width: "100%" }}>
                                            <i class="fa-solid fa-triangle-exclamation" style={{ color: "#de0063", display: "flex", alignItems: "center", fontSize: "25px", marginRight: "2%" }}></i>
                                            <div style={{ display: "flex", textAlign: "left", flexDirection: "column" }}>
                                                <p style={{ fontWeight: "bold" }}>For Property Agent</p>
                                                <p>Please provide your mobile number that is registered under CEA</p>
                                            </div>
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", padding: "8px 8px" }}>
                                            {/* <i id="lockIcon" class="fa-solid fa-lock" ></i> */}
                                            <p for="phone" class="text-l font-bold  ">VERIFY YOUR IDENTITY</p>
                                        </div>
                                        <p class="text-sm text-gray-500 p-2">2FA (two-factor authentication) will require a 6-digit verification code. We will send this code to your mobile number.</p>
                                        {targetPrimaryRoleCheck == "Looking" ?
                                            <div style={{ width: "100%" }}>
                                                <select style={{ padding: "10px 0px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", width: "fit-content", textAlign: "center", border: "1px solid grey", width: "25%" }} value={countryCode} onChange={handleCountryCode}>
                                                    <option value="">Select a country</option>
                                                    <option value="93">AFGHANISTAN (+93)</option>
                                                    <option value="355">ALBANIA (+355)</option>
                                                    <option value="213">ALGERIA (+213)</option>
                                                    <option value="1-684">AMERICAN SAMOA (+1-684)</option>
                                                    <option value="376">ANDORRA (+376)</option>
                                                    <option value="244">ANGOLA (+244)</option>
                                                    <option value="1-264">ANGUILLA (+1-264)</option>
                                                    <option value="1-268">ANTIGUA AND BARBUDA (+1-268)</option>
                                                    <option value="54">ARGENTINA (+54)</option>
                                                    <option value="374">ARMENIA (+374)</option>
                                                    <option value="297">ARUBA (+297)</option>
                                                    <option value="61">AUSTRALIA (+61)</option>
                                                    <option value="43">AUSTRIA (+43)</option>
                                                    <option value="994">AZERBAIJAN (+994)</option>
                                                    <option value="1-242">BAHAMAS (+1-242)</option>
                                                    <option value="973">BAHRAIN (+973)</option>
                                                    <option value="880">BANGLADESH (+880)</option>
                                                    <option value="1-246">BARBADOS (+1-246)</option>
                                                    <option value="375">BELARUS (+375)</option>
                                                    <option value="32">BELGIUM (+32)</option>
                                                    <option value="501">BELIZE (+501)</option>
                                                    <option value="229">BENIN (+229)</option>
                                                    <option value="1-441">BERMUDA (+1-441)</option>
                                                    <option value="975">BHUTAN (+975)</option>
                                                    <option value="591">BOLIVIA (+591)</option>
                                                    <option value="387">BOSNIA AND HERZEGOVINA (+387)</option>
                                                    <option value="267">BOTSWANA (+267)</option>
                                                    <option value="55">BRAZIL (+55)</option>
                                                    <option value="673">BRUNEI (+673)</option>
                                                    <option value="359">BULGARIA (+359)</option>
                                                    <option value="226">BURKINA FASO (+226)</option>
                                                    <option value="257">BURUNDI (+257)</option>
                                                    <option value="225">COTE D'IVOIRE (+225)</option>
                                                    <option value="238">CABO VERDE (+238)</option>
                                                    <option value="855">CAMBODIA (+855)</option>
                                                    <option value="237">CAMEROON (+237)</option>
                                                    <option value="1">CANADA (+1)</option>
                                                    <option value="236">CENTRAL AFRICAN REP (+236)</option>
                                                    <option value="235">CHAD (+235)</option>
                                                    <option value="56">CHILE (+56)</option>
                                                    <option value="57">COLOMBIA (+57)</option>
                                                    <option value="269">COMOROS (+269)</option>
                                                    <option value="506">COSTA RICA (+506)</option>
                                                    <option value="385">CROATIA (+385)</option>
                                                    <option value="53">CUBA (+53)</option>
                                                    <option value="357">CYPRUS (+357)</option>
                                                    <option value="420">CZECH REPUBLIC (+420)</option>
                                                    <option value="243">DEMOCRATIC REPUBLIC OF THE CONGO (+243)</option>
                                                    <option value="45">DENMARK (+45)</option>
                                                    <option value="253">DJIBOUTI (+253)</option>
                                                    <option value="1-767">DOMINICA (+1-767)</option>
                                                    <option value="1-809">DOMINICAN REPUBLIC (+1-809)</option>
                                                    <option value="593">ECUADOR (+593)</option>
                                                    <option value="20">EGYPT (+20)</option>
                                                    <option value="503">EL SALVADOR (+503)</option>
                                                    <option value="240">EQUATORIAL GUINEA (+240)</option>
                                                    <option value="291">ERITREA (+291)</option>
                                                    <option value="372">ESTONIA (+372)</option>
                                                    <option value="268">ESWATINI (+268)</option>
                                                    <option value="251">ETHIOPIA (+251)</option>
                                                    <option value="679">FIJI (+679)</option>
                                                    <option value="358">FINLAND (+358)</option>
                                                    <option value="33">FRANCE (+33)</option>
                                                    <option value="241">GABON (+241)</option>
                                                    <option value="220">GAMBIA (+220)</option>
                                                    <option value="995">GEORGIA (+995)</option>
                                                    <option value="49">GERMANY (+49)</option>
                                                    <option value="233">GHANA (+233)</option>
                                                    <option value="30">GREECE (+30)</option>
                                                    <option value="1-473">GRENADA (+1-473)</option>
                                                    <option value="502">GUATEMALA (+502)</option>
                                                    <option value="224">GUINEA (+224)</option>
                                                    <option value="245">GUINES BISSAU (+245)</option>
                                                    <option value="592">GUYANA (+592)</option>
                                                    <option value="509">HAITI (+509)</option>
                                                    <option value="504">HONDURAS (+504)</option>
                                                    <option value="36">HUNGARY (+36)</option>
                                                    <option value="354">ICELAND (+354)</option>
                                                    <option value="91">INDIA (+91)</option>
                                                    <option value="62">INDONESIA (+62)</option>
                                                    <option value="98">IRAN (+98)</option>
                                                    <option value="964">IRAQ (+964)</option>
                                                    <option value="353">IRELAND (+353)</option>
                                                    <option value="972">ISRAEL (+972)</option>
                                                    <option value="39">ITALY (+39)</option>
                                                    <option value="1-876">JAMAICA (+1-876)</option>
                                                    <option value="81">JAPAN (+81)</option>
                                                    <option value="962">JORDAN (+962)</option>
                                                    <option value="7">KAZAKHSTAN (+7)</option>
                                                    <option value="254">KENYA (+254)</option>
                                                    <option value="686">KIRIBATI (+686)</option>
                                                    <option value="965">KUWAIT (+965)</option>
                                                    <option value="996">KYRGYZSTAN (+996)</option>
                                                    <option value="856">LAOS (+856)</option>
                                                    <option value="371">LATVIA (+371)</option>
                                                    <option value="961">LEBANON (+961)</option>
                                                    <option value="266">LESOTHO (+266)</option>
                                                    <option value="231">LIBERIA (+231)</option>
                                                    <option value="218">LIBYA (+218)</option>
                                                    <option value="423">LIECHSTENSTEIN (+423)</option>
                                                    <option value="370">LITHUANIA (+370)</option>
                                                    <option value="352">LUXEMBOURG (+352)</option>
                                                    <option value="261">MADAGASCAR (+261)</option>
                                                    <option value="265">MALAWI (+265)</option>
                                                    <option value="60">MALAYSIA (+60)</option>
                                                    <option value="960">MALDIVES (+960)</option>
                                                    <option value="223">MALI (+223)</option>
                                                    <option value="356">MALTA (+356)</option>
                                                    <option value="692">MARSHALL ISLANDS (+692)</option>
                                                    <option value="222">MAURITANIA (+222)</option>
                                                    <option value="230">MAURITIUS (+230)</option>
                                                    <option value="52">MEXICO (+52)</option>
                                                    <option value="691">MICRONESIA (+691)</option>
                                                    <option value="373">MOLDOVA (+373)</option>
                                                    <option value="377">MONACO (+377)</option>
                                                    <option value="976">MONGOLIA (+976)</option>
                                                    <option value="382">MONTENEGRO (+382)</option>
                                                    <option value="212">MOROCCO (+212)</option>
                                                    <option value="258">MOZAMBIQUE (+258)</option>
                                                    <option value="95">MYANMAR (+95)</option>
                                                    <option value="264">NAMIBIA (+264)</option>
                                                    <option value="674">NAURU (+674)</option>
                                                    <option value="977">NEPAL (+977)</option>
                                                    <option value="31">NETHERLANDS (+31)</option>
                                                    <option value="64">NEW ZEALAND (+64)</option>
                                                    <option value="505">NICARAGUA (+505)</option>
                                                    <option value="227">NIGER (+227)</option>
                                                    <option value="234">NIGERIA (+234)</option>
                                                    <option value="850">NORTH KOREA (+850)</option>
                                                    <option value="389">NORTH MACEDONIA (+389)</option>
                                                    <option value="47">NORWAY (+47)</option>
                                                    <option value="968">OMAN (+968)</option>
                                                    <option value="92">PAKISTAN (+92)</option>
                                                    <option value="680">PALAU (+680)</option>
                                                    <option value="507">PANAMA (+507)</option>
                                                    <option value="675">PAPUA NEW GUINEA (+675)</option>
                                                    <option value="595">PARAGUAY (+595)</option>
                                                    <option value="86">PEOPLE'S REPUBLIC OF CHINA (+86)</option>
                                                    <option value="51">PERU (+51)</option>
                                                    <option value="63">PHILIPPINES (+63)</option>
                                                    <option value="48">POLAND (+48)</option>
                                                    <option value="351">PORTUGAL (+351)</option>
                                                    <option value="974">QATAR (+974)</option>
                                                    <option value="242">REPUBLIC OF THE CONGO (+242)</option>
                                                    <option value="262">REUNION (+262)</option>
                                                    <option value="40">ROMANIA (+40)</option>
                                                    <option value="7">RUSSIA (+7)</option>
                                                    <option value="250">RWANDA (+250)</option>
                                                    <option value="1-869">SAINT KITTS AND NEVIS (+1-869)</option>
                                                    <option value="1-758">SAINT LUCIA (+1-758)</option>
                                                    <option value="685">SAMOA (+685)</option>
                                                    <option value="378">SAN MARINO (+378)</option>
                                                    <option value="239">SAO TOME AND PRINCIPE (+239)</option>
                                                    <option value="966">SAUDI ARABIA (+966)</option>
                                                    <option value="221">SENEGAL (+221)</option>
                                                    <option value="381">SERBIA (+381)</option>
                                                    <option value="248">SEYCHELLES (+248)</option>
                                                    <option value="232">SIERRA LEONE (+232)</option>
                                                    <option value="65">SINGAPORE (+65)</option>
                                                    <option value="421">SLOVAKIA (+421)</option>
                                                    <option value="386">SLOVENIA (+386)</option>
                                                    <option value="677">SOLOMON ISLANDS (+677)</option>
                                                    <option value="252">SOMALIA (+252)</option>
                                                    <option value="27">SOUTH AFRICA (+27)</option>
                                                    <option value="82">SOUTH KOREA (+82)</option>
                                                    <option value="211">SOUTH SUDAN (+211)</option>
                                                    <option value="34">SPAIN (+34)</option>
                                                    <option value="94">SRI LANKA (+94)</option>
                                                    <option value="1-869">ST KITTS (+1-869)</option>
                                                    <option value="1-758">ST LUCIA (+1-758)</option>
                                                    <option value="1-784">ST VINCENT (+1-784)</option>
                                                    <option value="249">SUDAN (+249)</option>
                                                    <option value="597">SURINAME (+597)</option>
                                                    <option value="46">SWEDEN (+46)</option>
                                                    <option value="41">SWITZERLAND (+41)</option>
                                                    <option value="963">SYRIA (+963)</option>
                                                    <option value="886">TAIWAN (+886)</option>
                                                    <option value="992">TAJIKISTAN (+992)</option>
                                                    <option value="255">TANZANIA (+255)</option>
                                                    <option value="66">THAILAND (+66)</option>
                                                    <option value="228">TOGO (+228)</option>
                                                    <option value="676">TONGA (+676)</option>
                                                    <option value="1-868">TRINIDAD AND TOBAGO (+1-868)</option>
                                                    <option value="216">TUNISIA (+216)</option>
                                                    <option value="90">TURKEY (+90)</option>
                                                    <option value="993">TURKMENISTAN (+993)</option>
                                                    <option value="688">TUVALU (+688)</option>
                                                    <option value="256">UGANDA (+256)</option>
                                                    <option value="380">UKRAINE (+380)</option>
                                                    <option value="971">UNITED ARAB EMIRATES (+971)</option>
                                                    <option value="44">UNITED KINGDOM (+44)</option>
                                                    <option value="1">UNITED STATES (+1)</option>
                                                    <option value="598">URUGUAY (+598)</option>
                                                    <option value="998">UZBEKISTAN (+998)</option>
                                                    <option value="678">VANUATU (+678)</option>
                                                    <option value="39">VATICAN CITY (+39)</option>
                                                    <option value="58">VENEZUELA (+58)</option>
                                                    <option value="84">VIETNAM (+84)</option>
                                                    <option value="967">YEMEN (+967)</option>
                                                    <option value="260">ZAMBIA (+260)</option>
                                                    <option value="263">ZIMBABWE (+263)</option>


                                                </select>
                                                <input type="tel" id="phone" name="phone" placeholder="12345678" pattern="[0-9]{8}" required value={HPnum} onChange={handleHPChanges} />
                                            </div>
                                            :
                                            <div style={{ width: "100%" }}>
                                                <select style={{ padding: "10px 0px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", width: "fit-content", textAlign: "center", border: "1px solid grey", width: "25%" }} value={countryCode} onChange={handleCountryCode} disabled>

                                                    <option value="65">SINGAPORE (+65)</option>



                                                </select>
                                                <input type="tel" id="phone" name="phone" placeholder="12345678" pattern="[0-9]{8}" required value={HPnum} onChange={handleHPChanges} />
                                            </div>

                                        }
                                        <div style={{ marginTop: "25px", marginBottom: "25px" }}>
                                            <div>
                                                <p for="phone" class="text-l font-bold">REFERENCE CODE</p>
                                                <input id="referenceCode" placeholder="Code" value={referFriendCode} onChange={(e) => setReferFriendCode(e.target.value)} />
                                            </div>

                                        </div>

                                        <>
                                            <input type="checkbox" id="acceptStandard" />
                                            <label for="acceptStandard" class="text-sm text-gray-500 p-2">By checking this, you accept the standard <a href='/TermOfService' target="_blank" style={{ textDecoration: "none", color: "##33434D" }}><ul>terms and conditions</ul></a>.</label></>
                                        <div class="p-3  mt-2 text-center space-x-4 md:block">
                                            <button class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100" onClick={() => { setChoosePrimaryRole(true) }}>
                                                Back
                                            </button>
                                            <button class="mb-2 md:mb-0 bg-slate-500 border border-slate-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-slate-600" onClick={() => confirmNumber()}>Send OTP</button>
                                        </div>
                                    </div>
                                    : null}

                                {/* <div id="recaptcha-container"></div> */}
                                {OTPsent == false ? <div style={{ display: show ? "block" : "none" }}>
                                    <div id="recaptcha-container"></div>
                                </div> : null}
                                {spinnerActivated == true ? <i class="fas fa-spinner fa-spin fa-lg" style={{ marginTop: "5%", marginBottom: "5%" }}></i>
                                    :
                                    <div style={{ display: OTPsent ? "block" : "none" }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", padding: "8px 8px" }}>
                                            {/* <i id="lockIcon" class="fa-solid fa-lock-open" ></i> */}
                                            <p for="phone" class="text-l font-bold">VERIFICATION CODE</p>
                                        </div>


                                        <p class="text-sm text-gray-500 px-8">Enter the OTP sent to<br></br>+{countryCode} {HPnum != null ? HPnum : null}</p>
                                        <div className="otp-input">
                                            {Array.from({ length: 6 }, (_, index) => (
                                                <input
                                                    className='otpBox'
                                                    key={index}
                                                    type="text"
                                                    maxLength={1}
                                                    value={otpInput[index]}
                                                    onChange={(e) => handleOTPChange(e, index)}
                                                    ref={(ref) => (inputRefs.current[index] = ref)}
                                                />
                                            ))}
                                        </div>
                                        {/* <input id="phone" type="text" placeholder={"Enter your OTP"}
                                            onChange={(e) => { setotp(e.target.value) }} ></input> */}
                                        {/* <br /><br /> */}
                                        <div class="p-3  mt-2 text-center space-x-4 md:block">
                                            {allowReverification == false ?

                                                <button class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">
                                                    {timer}
                                                </button>
                                                :
                                                <button class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100" onClick={() => { reverify() }}>
                                                    Resend
                                                </button>
                                            }
                                            <button class="mb-2 md:mb-0 bg-slate-500 border border-slate-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-slate-600" onClick={ValidateOtp}>Verify</button>

                                        </div>


                                    </div>}
                            </center>
                            {/* </center>
                            </div> */}
                        </div>

                        <div className='modal-footerNewProject'>

                            {/* {show == false && OTPsent == false ?
                            <div>

                                {choosePrimaryRole == false ? <button id="OTPbtn" style={{ marginRight: "2%" }} className='OTPBtn' onClick={() => confirmNumber()}>Send One Time Password</button> : null}
                                {choosePrimaryRole == false ? <button id="OTPbtn" className='OTPBtn' style={{ marginRight: "2%" }} onClick={() => { setChoosePrimaryRole(true) }}>Back</button> : null}
                                <div style={{ clear: 'both' }}></div>
                            </div>
                            : null} */}
                            {/* {showVerifyBtn == true && spinnerActivated == false ? <>
                                <button className='OTPBtn' onClick={ValidateOtp}>Verify</button>
                                {allowReverification == false ? <button className='OTPBtn' style={{ marginRight: "3%" }} disabled>{timer} Resend OTP</button> : <button style={{ marginRight: "3%" }} className='OTPBtn' onClick={() => { reverify() }}>Resend OTP</button>}
                                <div style={{ clear: 'both' }}></div>
                            </> : null} */}



                            {/* <button className='btn--general' >Proceed</button> */}

                        </div>
                    </div>
                </div >
            </>
        );
    }

}