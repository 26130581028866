import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, updateDoc, doc, setDoc, addDoc, serverTimestamp, getDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase/firebase'
import '../css/Details.css';
import Carousel, { CarouselItem } from '../Carousel';
import { RWebShare } from "react-web-share";
import { UserAuth } from '../../AuthContent';
import { useNavigate } from 'react-router-dom';
import Report from './Report';
import { TelegramShareButton, WhatsappShareButton } from "react-share";
import { TelegramIcon, WhatsappIcon } from "react-share";
import Footer from '../Footer';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import UserTypeModal from './UserTypeModal';
import { Link } from 'react-router-dom';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import DropAMessage from './DropAMessage';
import Notification from './Notification';
import Spinner from './Spinner';
import ChangePlan from './ChangePlan';
import NotAvailable from './NotAvailable';

export default function Details() {

  const [project, setProject] = useState(null);
  const { user } = UserAuth();
  const [shortListed, setShortListed] = useState(false);
  const [navigateAccount, setNavigateAccount] = useState([false, '']);
  const [report, setReportClick] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [approvedDoubleCheck, setApprovedDoubleCheck] = useState(false);
  const navigate = useNavigate();
  const sellerID = useRef(null);
  const chats = useRef(null);
  const [upgradeTier, setUpgradeTier] = useState(false);
  const [acknowledgeMessage, setAcknowledgeMessage] = useState("");
  const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
  const [dropAMessage, setDropAMessage] = useState(false);
  const [handleMessageClick, setHandleMessageClick] = useState(false);
  const [handleMessageTextArea, setHandleMessageTextArea] = useState(null);
  const [allowPreferred, setAllowPreferred] = useState(false);
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const [targetRole, setTargetRole] = useState(null);
  const [failLoadPage, setFailLoadPage] = useState(false);


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    var savedValue = sessionStorage.getItem('currentTab');
    if (savedValue != null) {
      sessionStorage.removeItem('currentTab');
    }
  }, [])

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (failLoadPage == true) {
      navigate("/NotAvailable");
    }
  }, [failLoadPage])

  const getUserInfo = async () => {
    var id = (window.location.pathname).split('/');
    const docRef = await getDoc(doc(db, `userInfo`, user.uid));
    var currentUserInfo = docRef.data();
    if (currentUserInfo.membership != "admin") {
      if (project.details.preferredListing == true && project.details.UID != user.uid) {

        if (currentUserInfo.hasOwnProperty('advancedProjectSeen')) {
          if (currentUserInfo.advancedProjectSeen.search(id[2]) >= 0) {
            setApprovedDoubleCheck(true);
            setAllowPreferred(true);
          }
        }


        // }
      }
      if (project.details.UID == user.uid && project.details.preferredListing == true) {
        setApprovedDoubleCheck(true);
        setAllowPreferred(true);
      }
    }

    setUserInfo(docRef.data());
  }

  const showPreferred = async () => {
    var id = (window.location.pathname).split('/');
    if (project.details.preferredListing == true && project.details.UID != user.uid) {
      if (userInfo.hasOwnProperty('advancedProjectSeen') == false && (userInfo.userType.includes("Landlord") || userInfo.userType.includes("Agent") || userInfo.userType.includes("Master Tenant")) && userInfo.advancedDetails > 0) {

        await updateDoc(doc(db, `userInfo`, user.uid), {
          advancedDetails: userInfo.advancedDetails - 1,
          advancedProjectSeen: id[2]
        })
        setApprovedDoubleCheck(true);
        setAllowPreferred(true);
      }
      else {
        if (userInfo.hasOwnProperty('advancedProjectSeen')) {
          if (userInfo.advancedProjectSeen.search(id[2]) < 0 && (userInfo.userType.includes("Landlord") || userInfo.userType.includes("Agent") || userInfo.userType.includes("Master Tenant")) && userInfo.advancedDetails > 0) {
            await updateDoc(doc(db, `userInfo`, user.uid), {
              advancedDetails: userInfo.advancedDetails - 1,
              advancedProjectSeen: userInfo.advancedProjectSeen + "|" + id[2]
            })
            setApprovedDoubleCheck(true);
            setAllowPreferred(true);
          }
          else if (userInfo.advancedProjectSeen.search(id[2]) >= 0) {
            setApprovedDoubleCheck(true);
            setAllowPreferred(true);
          }
          else {
            alert("No availble advanced details left");
          }
        }
        else {
          alert("No availble advanced details left");
        }


      }
    }
  }

  useEffect(() => {
    const activeShortList = async () => {
      const docRef_3 = collection(db, `userInfo/${user.uid}/shortList`);
      const docSnap_3 = await getDocs(docRef_3);
      docSnap_3.forEach((doc) => {
        if (doc.id == project.id) {
          setShortListed(true);
        }
      })
    }


    if (user != null) {
      if (user.uid == undefined || project == null) {
      }
      else {
        getUserInfo();
        activeShortList();
      }
    }
  }, [user, project])

  useEffect(() => {
    if (navigateAccount[0] == true) {
      navigateAccountFunction();
    }
  }, [navigateAccount])










  useEffect(() => {
    if (handleMessageClick == true) {
      handleMessage().then((res) => { res == "yourOwnListing" ? alert("Cannot request to your listing") : res == "noproperty" ? alert("No Propety Profile") : activateAck() })
    }
  }, [handleMessageClick])

  const navigateAccountFunction = () => {

    navigate('/account', { state: { navID: navigateAccount[1], myID: user.uid } });
  }

  const getPreferredListing = (url) => {
    return new Promise(async function (resolve, reject) {
      const docRef = await getDocs(collection(db, `projects/${url}/PreferredDetails`));
      docRef.forEach((doc) => {
        resolve(doc.data());
      })
    })
  }

  const getData = async () => {
    var combineID_Details = {};
    var id = (window.location.pathname).split('/');
    const docRef = doc(db, "projects", id[2]);
    const docSnap = await getDoc(docRef);
    var firstInfo = docSnap.data();
    var MoreDetailsID;
    var secondInfo;
    var preferredDetails;
    var eyeballStat;
    if (docSnap.exists()) {
      if (firstInfo.preferredListing == true) {
        getPreferredListing(id[2]).then(async (res) => {
          preferredDetails = res;
        });
        const docRef_1 = collection(db, `projects/${id[2]}/MoreDetails`);
        const docSnap_1 = await getDocs(docRef_1);
        // if (docSnap_1.exists()) {
        docSnap_1.forEach((doc) => {
          secondInfo = doc.data();
          MoreDetailsID = doc.id;
          secondInfo.periodMoveIn = secondInfo.periodMoveIn.toDate().toDateString();
          var combineDetails = { ...firstInfo, ...secondInfo, ...preferredDetails };
          combineID_Details = { id: docSnap.id, details: combineDetails }
          eyeballStat = combineDetails.eyeballStat;
        });
        // }
        // else {
        //   setFailLoadPage(true);
        // }

      }
      else {
        const docRef_1 = collection(db, `projects/${id[2]}/MoreDetails`);
        const docSnap_1 = await getDocs(docRef_1);
        // if (docSnap_1) {
        docSnap_1.forEach((doc) => {
          secondInfo = doc.data();
          MoreDetailsID = doc.id;
          secondInfo.periodMoveIn = secondInfo.periodMoveIn.toDate().toDateString();
          var combineDetails = { ...firstInfo, ...secondInfo };
          combineID_Details = { id: docSnap.id, details: combineDetails }
          eyeballStat = combineDetails.eyeballStat;
        });
        // }
        // else{
        //   setFailLoadPage(true);
        // }
      }
      if (user != null) {
        if (user.uid != undefined) {
          if (user.uid != combineID_Details.details.UID) {
            if (secondInfo.viewID == null) {
              await updateDoc(doc(db, `projects/${id[2]}/MoreDetails`, MoreDetailsID), {
                viewID: user.uid
              })
              await updateDoc(doc(db, `projects`, id[2]), {
                eyeball: firstInfo.eyeball + 1
              })
              eyeballStat[0] = eyeballStat[0] + 1;
              await updateDoc(doc(db, `projects`, id[2]), {
                eyeballStat: eyeballStat
              })

            }
            else {
              if (secondInfo.viewID.search(user.uid) >= 0) {
                await updateDoc(doc(db, `projects`, id[2]), {
                  eyeball: firstInfo.eyeball + 1
                })
                eyeballStat[0] = eyeballStat[0] + 1;
                await updateDoc(doc(db, `projects`, id[2]), {
                  eyeballStat: eyeballStat
                })
              }
              else {
                await updateDoc(doc(db, `projects/${id[2]}/MoreDetails`, MoreDetailsID), {
                  viewID: secondInfo.viewID + "|" + user.uid
                })
                await updateDoc(doc(db, `projects`, id[2]), {
                  eyeball: firstInfo.eyeball + 1
                })
                eyeballStat[0] = eyeballStat[0] + 1;
                await updateDoc(doc(db, `projects`, id[2]), {
                  eyeballStat: eyeballStat
                })
              }
            }
          }

        }
      }
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      setFailLoadPage(true);
    }

    setProject(combineID_Details);
  }


  const renderCarousel = () => {
    var content = [];
    var count = 0;
    while (project.details['url' + count] != undefined) {
      var image = project.details['url' + count];
      content.push(
        <CarouselItem src={(image.replaceAll('200', '600').replaceAll('300', '800'))}></CarouselItem>
      )
      count++;
    }


    return content;
  }

  const getTime = () => {
    var content = [];
    var today = new Date();
    var diff = Math.abs(today - project.details.timePosted.toDate());
    if ((diff / 1000) <= 60) {
      content.push(
        <p id="detail-left-inner-right">{project.details.hasOwnProperty('modifyBefore') ? "Updated" : "Listed"} {Math.floor(diff / 1000)} seconds ago</p>
      )
    }
    else if (((diff / 1000) / 60) <= 60) {
      content.push(
        <p id="detail-left-inner-right">{project.details.hasOwnProperty('modifyBefore') ? "Updated" : "Listed"} {Math.floor((diff / 1000) / 60)} minute(s) ago</p>
      )
    }
    else if ((((diff / 1000) / 60) / 60) <= 24) {
      content.push(
        <p id="detail-left-inner-right">{project.details.hasOwnProperty('modifyBefore') ? "Updated" : "Listed"} {Math.floor((diff / 1000) / 60 / 60)} hour(s) ago</p>
      )
    }
    else if ((((diff / 1000) / 60) / 60 / 24) <= 31) {
      content.push(
        <p id="detail-left-inner-right">{project.details.hasOwnProperty('modifyBefore') ? "Updated" : "Listed"} {Math.floor((((diff / 1000) / 60) / 60) / 24)} day(s) ago</p>
      )

    }
    else if ((((diff / 1000) / 60) / 60 / 24 / 30) <= 12) {

      content.push(
        <p id="detail-left-inner-right">{project.details.hasOwnProperty('modifyBefore') ? "Updated" : "Listed"} {Math.floor((((diff / 1000) / 60) / 60) / 24 / 30)} month(s) ago</p>
      )

    }
    else {
      // console.log((diff / 1000) / 60 / 60 /24/30)
      content.push(
        <p id="detail-left-inner-right">{project.details.hasOwnProperty('modifyBefore') ? "Updated" : "Listed"} {Math.floor((((diff / 1000) / 60) / 60) / 24 / 30 / 12)} year(s) ago</p>
      )
    }


    return content;
  }

  const addShortList = async () => {
    if (user != null && project.details.UID != user.uid) {
      await setDoc(doc(db, `userInfo/${user.uid}/shortList`, project.id), {});
      setShortListed(true);
    }
    else {
      alert("Please Sign Up First or This is your post");
    }
  }



  const deteleShortList = async () => {
    if (user != null && project.id != user.uid) {
      await deleteDoc(doc(db, `userInfo/${user.uid}/shortList`, project.id));
      setShortListed(false);
    }
    else {
      alert("Please Sign Up First or This is your post");
    }
  }

  const handleMessage = async () => {
    var allowMessage = true;
    // if (userInfo.hasOwnProperty('propertyAddressList') && userInfo.hasOwnProperty('propertyAddressListResidential')) {
    //   if (userInfo.propertyAddressList.length == 0 && userInfo.propertyAddressListResidential.length == 0) {
    //     allowMessage = false;
    //   }
    // }
    // else if (userInfo.hasOwnProperty('propertyAddressListResidential') == false && userInfo.hasOwnProperty('propertyAddressList')) {
    //   if (userInfo.propertyAddressList.length == 0) {
    //     allowMessage = false;
    //   }
    // }
    // else if (userInfo.hasOwnProperty('propertyAddressList') == false && userInfo.hasOwnProperty('propertyAddressListResidential')) {
    //   if (userInfo.propertyAddressListResidential.length == 0) {
    //     allowMessage = false;
    //   }
    // }
    // else if (userInfo.hasOwnProperty('propertyAddressList') == false && userInfo.hasOwnProperty('propertyAddressListResidential') == false) {
    //   allowMessage = false;
    // }

    if (allowMessage) {
      setSpinnerLoad(true);
      var senderName, receiverName;
      senderName = user.displayName;
      receiverName = project.details.username;
      var sellerInfo = await getDoc(doc(db, 'userInfo', project.details.UID));
      sellerInfo = sellerInfo.data();


      var combinedID = user.uid > project.details.UID ? user.uid + project.details.UID : project.details.UID + user.uid;
      combinedID = combinedID > project.id ? combinedID + project.id : project.id + combinedID;

      if (user != null && project.details.UID != user.uid) {
        try {
          const res = await getDoc(doc(db, 'chats', combinedID));
          if (!res.exists()) {
            var chatLeftDoc = await getDoc(doc(db, 'userInfo', user.uid));
            if (chatLeftDoc.data().credit > 23) {
              await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: chatLeftDoc.data().credit - 23
              });

              var imageURL = project.details.url0;
              await setDoc(doc(db, 'chats', combinedID), {
                messages: [{
                  createdAt: new Date(),
                  sender: user.uid,
                  senderName: user.displayName,
                  text: handleMessageTextArea
                }]
              });

              const publicUserIndiv = await getDoc(doc(db, 'publicUserInfo', user.uid));
              const oppPublicUserIndiv = await getDoc(doc(db, 'publicUserInfo', project.details.UID));

              var verifiedPropertyCount = 0;
              var unverifiedPropertyCount = 0;

              // if (userInfo.hasOwnProperty('propertyAddressList')) {
              //   for (var i = 0; i < userInfo.propertyAddressList.length; i++) {
              //     if (userInfo.propertyAddressList[i].verified == "approve") {
              //       verifiedPropertyCount++;
              //     } else {
              //       unverifiedPropertyCount++;
              //     }
              //   }
              // }

              // if (userInfo.hasOwnProperty('propertyAddressListResidential')) {
              //   for (var i = 0; i < userInfo.propertyAddressListResidential.length; i++) {
              //     if (userInfo.propertyAddressListResidential[i].verified == "approve") {
              //       verifiedPropertyCount++;
              //     } else {
              //       unverifiedPropertyCount++;
              //     }
              //   }
              // }

              const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
              if (targetRole == "Agent") {
                var docData = {
                  [combinedID]: {
                    'userInfo': {
                      agentProfile: publicUserIndiv.data().agentProfile,
                      propertyList: { unverified: unverifiedPropertyCount, verified: verifiedPropertyCount },
                      oppUserType: "Tenant",
                      displayName: oppPublicUserIndiv.data().username,
                      photoURL: sellerInfo.userPhotoURL,
                      recommended: oppPublicUserIndiv.data().hasOwnProperty("recommended") ? oppPublicUserIndiv.data().recommended : null,
                      listingPhoto: imageURL,
                      listingName: project.details.listingName,
                      listingPriceOverall: project.details.priceOverall,
                      listingPricePerSquareFeet: project.details.pricePerSquareFeet,
                      listingID: project.id,
                      statusRead: "Read",
                      status: "Pending",
                      lastMessage: handleMessageTextArea,
                      oppositeUID: project.details.UID,
                      myUID: user.uid,
                      chatType: "CommercialRent",
                      agentRepresented: project.details.propertyAgent
                    },
                    "date": serverTimestamp()
                  }
                };
              }
              else {
                var docData = {
                  [combinedID]: {
                    'userInfo': {
                      propertyList: { unverified: unverifiedPropertyCount, verified: verifiedPropertyCount },
                      oppUserType: "Tenant",
                      displayName: oppPublicUserIndiv.data().username,
                      photoURL: sellerInfo.userPhotoURL,
                      recommended: oppPublicUserIndiv.data().hasOwnProperty("recommended") ? oppPublicUserIndiv.data().recommended : null,
                      listingPhoto: imageURL,
                      listingName: project.details.listingName,
                      listingPriceOverall: project.details.priceOverall,
                      listingPricePerSquareFeet: project.details.pricePerSquareFeet,
                      listingID: project.id,
                      statusRead: "Read",
                      status: "Pending",
                      lastMessage: handleMessageTextArea,
                      oppositeUID: project.details.UID,
                      myUID: user.uid,
                      chatType: "CommercialRent",
                      agentRepresented: project.details.propertyAgent
                    },
                    "date": serverTimestamp()
                  }
                };
              }
              if (!res_userChat.exists()) {
                await setDoc(doc(db, 'userChat', user.uid), docData);
              } else {
                await updateDoc(doc(db, 'userChat', user.uid), docData);
              }

              const res_userChat_2 = await getDoc(doc(db, 'userChat', project.details.UID));
              var role;

              if (targetRole == "Agent") {
                var docData = {
                  [combinedID]: {
                    'userInfo': {
                      oppUserType: targetRole,
                      propertyList: { unverified: unverifiedPropertyCount, verified: verifiedPropertyCount },
                      agentProfile: publicUserIndiv.data().agentProfile,
                      displayName: publicUserIndiv.data().username,
                      photoURL: userInfo.userPhotoURL,
                      listingPhoto: imageURL,
                      recommended: publicUserIndiv.data().hasOwnProperty('recommended') ? publicUserIndiv.data().recommended : null,
                      listingName: project.details.listingName,
                      listingPriceOverall: project.details.priceOverall,
                      listingPricePerSquareFeet: project.details.pricePerSquareFeet,
                      listingID: project.id,
                      statusRead: "Unread",
                      status: "Pending",
                      lastMessage: handleMessageTextArea,
                      oppositeUID: user.uid,
                      myUID: project.details.UID,
                      chatType: "CommercialRent",
                      agentRepresented: project.details.propertyAgent
                    },
                    "date": serverTimestamp()
                  }
                };
              } else {
                var docData = {
                  [combinedID]: {
                    'userInfo': {
                      oppUserType: "Landlord",
                      propertyList: { unverified: unverifiedPropertyCount, verified: verifiedPropertyCount },
                      displayName: publicUserIndiv.data().username,
                      photoURL: userInfo.userPhotoURL,
                      listingPhoto: imageURL,
                      recommended: publicUserIndiv.data().hasOwnProperty('recommended') ? publicUserIndiv.data().recommended : null,
                      listingName: project.details.listingName,
                      listingPriceOverall: project.details.priceOverall,
                      listingPricePerSquareFeet: project.details.pricePerSquareFeet,
                      listingID: project.id,
                      statusRead: "Unread",
                      status: "Pending",
                      lastMessage: handleMessageTextArea,
                      oppositeUID: user.uid,
                      myUID: project.details.UID,
                      chatType: "CommercialRent",
                      agentRepresented: project.details.propertyAgent
                    },
                    "date": serverTimestamp()
                  }
                };
              }
              // await setDoc(doc(db, 'userChat', project.details.UID), docData);
              if (!res_userChat_2.exists()) {

                await setDoc(doc(db, 'userChat', project.details.UID), docData)
              }
              else {

                await updateDoc(doc(db, 'userChat', project.details.UID), docData)
              }

              // if (!res_userChat_2.exists()) {
              //   for (var i = 0; i < userInfo.userType.length; i++) {
              //     if (userInfo.userType[i] != "Tenant" && userInfo.userType[i] != "Advertiser") {
              //       role = userInfo.userType[i];
              //       break;
              //     }
              //   }
              //   docData[combinedID].userInfo.oppUserType = role;

              // } else {
              //   for (var i = 0; i < userInfo.userType.length; i++) {
              //     if (userInfo.userType[i] != "Tenant" && userInfo.userType[i] != "Advertiser") {
              //       role = userInfo.userType[i];
              //       break;
              //     }
              //   }
              //   docData[combinedID].userInfo.oppUserType = role;
              //   await updateDoc(doc(db, 'userChat', project.details.UID), docData);
              // }

              if (userInfo.membership == "Enterprise") {
                // Code for updating userChat with userInfo.premiumLeader
              }

              if (oppPublicUserIndiv.data().emailNotificationPending == true) {
                const requestOptions_1 = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                  body: JSON.stringify({ data: { username: oppPublicUserIndiv.data().username, email: oppPublicUserIndiv.data().email, path: `New Connection` } })
                };
                await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1)
                  .then(data => console.log(data))
                  .catch(error => console.log(error));
              }

              setSpinnerLoad(false);
              setHandleMessageClick(false);
              setHandleMessageTextArea(null);
              setTargetRole(null);
              setAcknowledgeMessage("Your request has been submitted");
            } else {
              setSpinnerLoad(false);
              setHandleMessageClick(false);
              setHandleMessageTextArea(null);
              setTargetRole(null);
              setAcknowledgeMessage("You do not have any chat connections left.");
            }
          } else {
            setSpinnerLoad(false);
            setHandleMessageClick(false);
            setHandleMessageTextArea(null);
            setTargetRole(null);
            setAcknowledgeMessage("Your request has already been submitted or you are currently in a chat with the tenant.");
          }
        } catch (error) {
          console.log(error);
        }



      }
      else {
        // alert("This is your post");
        setSpinnerLoad(false);
        return "yourOwnListing";
      }
      setSpinnerLoad(false);
      return combinedID;
    }
    return "noproperty";
  }

  const activateAck = () => {


    // setAcknowledgeMessage("Message have been sent");
    setAcknowledgeNormal(true);

  }


  return (
    <>
      {/* <Notification></Notification> */}
      <Spinner trigger={spinnerLoad} setTrigger={setSpinnerLoad}></Spinner>
      <DropAMessage trigger={dropAMessage} setTrigger={setDropAMessage} setHandleMessageClick={setHandleMessageClick} setHandleMessageTextArea={setHandleMessageTextArea} userInfo={userInfo} setTargetRole={setTargetRole} listingDetails ={project} type="commercial rent"></DropAMessage>
      <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={acknowledgeMessage}></AcknowledgeNormal>
      <ChangePlan trigger={upgradeTier} setTrigger={setUpgradeTier} currentUserInfo={userInfo} setCurrentUserInfo={setUserInfo} />
      {project != null ? <Report trigger={report} setTrigger={setReportClick} listing_id={project.id} listingType={"Commercial"} /> : null}
      <div id="detail-left">
        <h2 id="ContactTitle" style={{ paddingBottom: "20px" }}>Requirements For Commercial Rent</h2>
        <h4 id="details-district">{project != null && project.details.location != "" ? project.details.district + " | " + project.details.location :
          project != null ? project.details.district : null}{project != null && project.details.postalCode != null ? ` (S)${project.details.postalCode}` : null}</h4>
        <Carousel>
          {project != null ? renderCarousel() : null}
        </Carousel>

        <p id="detail-left-inner-left">{project != null ? "Moving In Period (Starting From): " + project.details.periodMoveIn : null}</p>
        {project != null && project.details.status == "leasing" ? getTime() : null}
        <div style={{ clear: 'both' }}></div>
        {/*  */}
        
        <h4>{project != null ? project.details.listingName : null}</h4>
        {/* </div> */}
        <div className="flex -space-x-1 overflow-hidden">
        {project != null ?  <img
          className="inline-block h-6 w-6 rounded-full ring-2 ring-white mr-4"
          src={project.details.userImage}
          alt=""
        />:null}<p>@{project != null ? project.details.username : null}{project != null ? project.details.propertyAgent == true ? ` [ ${project.details.propertyAgentCompany} ]` : " [Direct Buyer]" : null}</p>
        </div>
        <p id="detailsMontlyPSF" style={{ marginTop: "1%" }}>Monthly PSF Budget: ${project != null ? (project.details.pricePerSquareFeet.toLocaleString()) : null}</p>
        {/* setNavigateAccount([true, res]) */}
        <p id="detailsMontlyPSF" style={{ marginTop: "1%" }}>Monthly Rental Budget: ${project != null ? (project.details.priceOverall.toLocaleString()) : null}</p>
        {userInfo != null ? <button className='detailsBtn' onClick={userInfo.credit > 23 ? () => setDropAMessage(true) : () => setUpgradeTier(true)} style={{ marginRight: '1%' }}>Request to connect</button> : null}
        {/* {userInfo != null ? (userInfo.userType).search("Landlord") >= 0 || (userInfo.userType).search("Agent") >= 0 ? <button className='detailsBtn' onClick={() => { handleMessage().then((res) => { res == false ? alert("Cannot request to your listing") :  activateAck()}) }} style={{ marginRight: '1%' }}>Request to connect</button> : null : null} */}
        {user == null ? <button className='detailsBtn' style={{ marginRight: '1%' }}><Link to={"/sign-up"} style={{ textDecoration: "none", color: "white" }}>Login to request to connect</Link></button> : null}
        <button className='detailsBtn' onClick={() => { setReportClick(true) }}>Report</button>

      </div>

      <div id="detail-right">
        <div id="detail-right-first">
          <h4>Requirements</h4>

          <div id="shortlist">
            {shortListed == true ? <button onClick={() => deteleShortList()}><i id="heartIcon" class="fa-solid fa-heart" id="favouriteHeart"></i> ADD TO SHORTLIST</button> : <button onClick={() => addShortList()}><i id="heartIcon" class="fa-regular fa-heart"></i> ADD TO SHORTLIST</button>}
          </div>
          <div id="share">
            {project != null ?
              <>
                <RWebShare
                  data={{
                    text: "",
                    url: window.location.href,
                    title: project.details.location,
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <button><i class="fa-solid fa-share"></i> SHARE</button>
                </RWebShare>
              </> : null}
          </div>
          {/* <TelegramShareButton url={window.location.href} style={{ marginRight: "3%" }}>
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>
          <WhatsappShareButton url={window.location.href} style={{ marginRight: "2%" }}>
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton> */}
          <div style={{ clear: 'both' }}></div>
        </div>
        <div class="detail-right-childrow">
          <label class="detail-right-label-left">MINIMUM SPACE</label>
          <label class="detail-right-label-right">MAXIMUM SPACE</label>
          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left">{project != null ? project.details.minSpace == null ? "-" : project.details.minSpace + " SQFT" : null}</h4>
          <h4 class="detail-right-text-right">{project != null ? project.details.maxSpace == null ? "-" : project.details.maxSpace + " SQFT" : null}</h4>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div class="detail-right-childrow">
          <label class="detail-right-label-left">MINIMUM LEASE PERIOD</label>
          <label class="detail-right-label-right">MAXIMUM LEASE PERIOD</label>
          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left">{project != null ? ((project.details.leaseTermMin).split('|'))[0] : null}</h4>
          <h4 class="detail-right-text-right">{project != null ? ((project.details.leaseTermMax).split('|'))[0] : null}</h4>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div class="detail-right-childrow">
          <label class="detail-right-label-left">NO OF YEARS IN OPERATIONS</label>
          <label class="detail-right-label-right">ADDITIONAL INFORMATION</label>
          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left">{project != null ? project.details.yearsOfOperation == null ? "-" : project.details.yearsOfOperation : null}</h4>
          <h4 class="detail-right-text-right">{project != null ? project.details.additionInformation == null ? '-' : project.details.additionInformation : null}</h4>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div class="detail-right-childrow">
          <label class="detail-right-label-left">PROPERTY TYPE</label>
          <label class="detail-right-label-right">MINIMUM CEILING HEIGHT</label>
          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left">{project != null ? project.details.propertyType : null}</h4>
          <h4 class="detail-right-text-right">{project != null ? project.details.minCeiling == null ? "-" : project.details.minCeiling : null}</h4>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div class="detail-right-childrow">
          <label class="detail-right-label-left">IMPORTANT REQUIREMENT 1</label>
          <label class="detail-right-label-right">IMPORTANT REQUIREMENT 2</label>
          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left">{project != null && project.details.important_1 != "" ? project.details.important_1 : "-"}</h4>
          <h4 class="detail-right-text-right">{project != null && project.details.important_2 != "" ? project.details.important_2 : "-"}</h4>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div class="detail-right-childrow">
          <label class="detail-right-label-left">IMPORTANT REQUIREMENT 3</label>
          <label class="detail-right-label-right">IMPORTANT REQUIREMENT 4</label>
          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left">{project != null && project.details.important_3 != "" ? project.details.important_3 : "-"}</h4>
          <h4 class="detail-right-text-right">{project != null && project.details.important_4 != "" ? project.details.important_4 : "-"}</h4>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div class="detail-right-childrow">
          <label class="detail-right-label-left">IMPORTANT REQUIREMENT 5</label>
          <label class="detail-right-label-right">ACQUISITION OF SPACE</label>

          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left">{project != null && project.details.important_5 != "" ? project.details.important_5 : "-"}</h4>
          <h4 class="detail-right-text-right">{project != null && project.details.acuquisitionSpace != "" ? project.details.acuquisitionSpace : "-"}</h4>

          <div style={{ clear: 'both' }}></div>
        </div>

        <div class="detail-right-childrow">
          <label class="detail-right-text-left">UNIQUE SELLING POINT</label>
          <div style={{ clear: 'both' }}></div>
          <h4 class="detail-right-text-left" style={{ float: "none", width: "100%" }}> {project != null && project.details.usp !== "" ? (
            <div dangerouslySetInnerHTML={{ __html: project.details.usp }} />
          ) : (
            "-"
          )}</h4>

        </div>



      </div>
      <div style={{ clear: 'both' }}></div>
      {userInfo != null ? (userInfo.userType.includes("Landlord") || userInfo.userType.includes("Agent") || userInfo.userType.includes("Master Tenant")) && allowPreferred == false && project.details.preferredListing == true ? <button className='detailsBtn' style={userInfo.membership != null && userInfo.membership != "Inactive" ? { float: "right", marginRight: "5%", marginBottom: "10px" } : { float: "right", marginRight: "5%", marginBottom: "10px", backgroundColor: "#c1c1c1" }} onClick={userInfo.membership != null && userInfo.membership != "Inactive" ? () => showPreferred() : null}>Show Preferred</button> : null : null}
      <div style={{ clear: 'both' }}></div>

      {userInfo != null ? (userInfo.userType.includes("Landlord") || userInfo.userType.includes("Agent") || userInfo.userType.includes("Master Tenant")) && allowPreferred == true && approvedDoubleCheck == true && project.details.UID != user.uid && userInfo.membership == "Premium" ?
        <>
          <button className='detailsBtn' style={{ float: "right", marginRight: "5%" }} onClick={() => setUpgradeTier(true)}>Upgrade to view more</button>
          <div style={{ clear: 'both' }}></div>
          <div id="preferredListingContainer">

            <center style={{ borderBottom: "3px solid black", paddingBottom: "1%" }}><h4>Preferred Listing Details</h4></center>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">COMPANY NAME</label>
              <label class="preferredListingContainer-label-left">PAID UP CAPTIAL</label>
              {/* <label class="preferredListingContainer-label-right">CORPORATE TURNOVER</label> */}
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.companyName}</> : null}</h4>
              <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.paidUpCaptial}</> : null}</h4>
              {/* <h4 class="preferredListingContainer-text-right">{project != null ? <>${project.details.coporateTurnover}</> : null}</h4> */}
              <div style={{ clear: 'both' }}></div>
            </div>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">REVENUE BREAKDOWN</label>
              <label class="preferredListingContainer-label-right">CLINTELE BREAKDOWN</label>
              {/* <label class="preferredListingContainer-label-left">RENTAL AMOUNT AT OTHER LOCATIONS</label>
            <label class="preferredListingContainer-label-right">EXPECTED REVENUE AT NEW PROPOSE LOCATION</label> */}
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              <h4 class="preferredListingContainer-text-right"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>${project.details.outletLocation}</> : null}</h4>
            <h4 class="preferredListingContainer-text-right"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4> */}
              <div style={{ clear: 'both' }}></div>
            </div>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">PRODUCTS OF BUSINESS / PRICING OF PRODUCTS</label>
              <label class="preferredListingContainer-label-right">COMPANY DECK</label>
              {/* <label class="preferredListingContainer-label-right">REVENUE BREAKDOWN</label> */}
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              <h4 class="preferredListingContainer-text-right"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              {/* <h4 class="preferredListingContainer-text-left">{project != null ? <><i class="fa fa-file-pdf-o" style={{fontSize:"24px", color:"red", cursor:"pointer"}} onClick={() =>  window.open(project.details.product_pricing)}></i></> : null}</h4> */}
              {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>${project.details.product_pricing}</> : null}</h4> */}
              {/* <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.revenueBreakdown}</> : null}</h4> */}
              {/* <h4 class="preferredListingContainer-text-right">{project != null ? <><i class="fa fa-file-pdf-o" style={{fontSize:"24px", color:"red", cursor:"pointer"}} onClick={() =>  window.open(project.details.companyDeckURL)}></i></> : null}</h4> */}
              <div style={{ clear: 'both' }}></div>
            </div>
            <div class="preferredListingContainer_child">
              {/* <label class="preferredListingContainer-label-left">OUTLET LOCATIONS <br></br> <p style={{fontSize:"11px"}}>IF IT'S A RELOCATION OR EXPANSION</p></label> */}
              <label class="preferredListingContainer-label-left">CORPORATE TURNOVER</label>
              <label class="preferredListingContainer-label-right">EXPECTED REVENUE</label>
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              <h4 class="preferredListingContainer-text-right"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>${project.details.outletLocation}</> : null}</h4> */}
              {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.revenueBreakdownRegular}%</> : null}</h4> */}
              {/* <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.revenueBreakdownWalkin}%</> : null}</h4> */}
              <div style={{ clear: 'both' }}></div>
            </div>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">OUTLET RENTAL</label>
              <label class="preferredListingContainer-label-right">RENTAL LOCATION</label>
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              <h4 class="preferredListingContainer-text-right"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.clinteleBreakdownLocal}%</> : null}</h4> */}
              {/* <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.clinteleBreakdownTourist}%</> : null}</h4> */}
              <div style={{ clear: 'both' }}></div>
            </div>
            {/* <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">REASON FOR THIS ACUQUISITION OF SPACE</label>
              {project != null ? project.details.outletLocation != null ? <label class="preferredListingContainer-label-right">OUTLET LOCATION</label> : null : null}
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              <h4 class="preferredListingContainer-text-right">{project != null ? project.details.outletLocation != null ? <><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></> : null : null}</h4>
             

              <div style={{ clear: 'both' }}></div>
            </div> */}

          </div>
        </>

        : null : null}

      {userInfo != null ? (userInfo.userType.includes("Landlord") || userInfo.userType.includes("Agent") || userInfo.userType.includes("Master Tenant")) && allowPreferred == true && approvedDoubleCheck == true && project.details.UID != user.uid && userInfo.membership == "Super-Premium" ?
        <>
          {/* <button className='detailsBtn' style={{ float: "right", marginRight: "5%" }} onClick={() => setUpgradeTier(true)}>Upgrade to view more</button> */}
          <div style={{ clear: 'both' }}></div>
          <div id="preferredListingContainer">

            <center style={{ borderBottom: "3px solid black", paddingBottom: "1%" }}><h4>Preferred Listing Details</h4></center>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">COMPANY NAME</label>
              <label class="preferredListingContainer-label-left">PAID UP CAPTIAL</label>
              {/* <label class="preferredListingContainer-label-right">CORPORATE TURNOVER</label> */}
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.companyName}</> : null}</h4>
              <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.paidUpCaptial}</> : null}</h4>
              {/* <h4 class="preferredListingContainer-text-right">{project != null ? <>${project.details.coporateTurnover}</> : null}</h4> */}
              <div style={{ clear: 'both' }}></div>
            </div>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">REVENUE BREAKDOWN</label>
              <label class="preferredListingContainer-label-right">CLINTELE BREAKDOWN</label>
              {/* <label class="preferredListingContainer-label-left">RENTAL AMOUNT AT OTHER LOCATIONS</label>
            <label class="preferredListingContainer-label-right">EXPECTED REVENUE AT NEW PROPOSE LOCATION</label> */}
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.revenueBreakdownRegular}% [Regular] / {project.details.revenueBreakdownWalkin}% [Walk In]</> : null}</h4>
              <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.clinteleBreakdownLocal}% [Local] / {project.details.clinteleBreakdownTourist}% [Tourist]</> : null}</h4>
              {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>${project.details.outletLocation}</> : null}</h4>
            <h4 class="preferredListingContainer-text-right"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4> */}
              <div style={{ clear: 'both' }}></div>
            </div>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">PRODUCTS OF BUSINESS / PRICING OF PRODUCTS</label>
              <label class="preferredListingContainer-label-right">COMPANY DECK</label>
              {/* <label class="preferredListingContainer-label-right">REVENUE BREAKDOWN</label> */}
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              <h4 class="preferredListingContainer-text-right"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              {/* <h4 class="preferredListingContainer-text-left">{project != null ? <><i class="fa fa-file-pdf-o" style={{fontSize:"24px", color:"red", cursor:"pointer"}} onClick={() =>  window.open(project.details.product_pricing)}></i></> : null}</h4> */}
              {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>${project.details.product_pricing}</> : null}</h4> */}
              {/* <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.revenueBreakdown}</> : null}</h4> */}
              {/* <h4 class="preferredListingContainer-text-right">{project != null ? <><i class="fa fa-file-pdf-o" style={{fontSize:"24px", color:"red", cursor:"pointer"}} onClick={() =>  window.open(project.details.companyDeckURL)}></i></> : null}</h4> */}
              <div style={{ clear: 'both' }}></div>
            </div>
            <div class="preferredListingContainer_child">
              {/* <label class="preferredListingContainer-label-left">OUTLET LOCATIONS <br></br> <p style={{fontSize:"11px"}}>IF IT'S A RELOCATION OR EXPANSION</p></label> */}
              <label class="preferredListingContainer-label-left">CORPORATE TURNOVER</label>
              <label class="preferredListingContainer-label-right">EXPECTED REVENUE</label>
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              <h4 class="preferredListingContainer-text-right"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>${project.details.outletLocation}</> : null}</h4> */}
              {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.revenueBreakdownRegular}%</> : null}</h4> */}
              {/* <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.revenueBreakdownWalkin}%</> : null}</h4> */}
              <div style={{ clear: 'both' }}></div>
            </div>
            <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">OUTLET RENTAL</label>
              <label class="preferredListingContainer-label-right">RENTAL LOCATION</label>
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              <h4 class="preferredListingContainer-text-right"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.clinteleBreakdownLocal}%</> : null}</h4> */}
              {/* <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.clinteleBreakdownTourist}%</> : null}</h4> */}
              <div style={{ clear: 'both' }}></div>
            </div>
            {/* <div class="preferredListingContainer_child">
              <label class="preferredListingContainer-label-left">REASON FOR THIS ACUQUISITION OF SPACE</label>
              {project != null ? project.details.outletLocation != null ? <label class="preferredListingContainer-label-right">OUTLET LOCATION</label> : null : null}
              <div style={{ clear: 'both' }}></div>
              <h4 class="preferredListingContainer-text-left"><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></h4>
              <h4 class="preferredListingContainer-text-right">{project != null ? project.details.outletLocation != null ? <><div style={{ color: 'transparent', textShadow: "0 0 8px #000" }}>PAID TO VIEW</div></> : null : null}</h4>
             

              <div style={{ clear: 'both' }}></div>
            </div> */}

          </div>
        </>

        : null : null}


      {userInfo != null ? userInfo.membership == "admin" || (userInfo.membership == "Enterprise" && approvedDoubleCheck == true && allowPreferred == true) || (approvedDoubleCheck == true && project.details.UID == user.uid) ?
        <div id="preferredListingContainer">
          <center style={{ borderBottom: "3px solid black", paddingBottom: "1%" }}><h4>Preferred Listing Details</h4></center>
          <div class="preferredListingContainer_child">
            <label class="preferredListingContainer-label-left">COMPANY NAME</label>
            <label class="preferredListingContainer-label-right">PAID UP CAPTIAL</label>
            <div style={{ clear: 'both' }}></div>
            <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.companyName}</> : null}</h4>
            <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.paidUpCaptial}</> : null}</h4>
            <div style={{ clear: 'both' }}></div>
          </div>
          <div class="preferredListingContainer_child">
            <label class="preferredListingContainer-label-left">REVENUE BREAKDOWN</label>
            <label class="preferredListingContainer-label-right">CLINTELE BREAKDOWN</label>
            {/* <label class="preferredListingContainer-label-left">RENTAL AMOUNT AT OTHER LOCATIONS</label>
            <label class="preferredListingContainer-label-right">EXPECTED REVENUE AT NEW PROPOSE LOCATION</label> */}
            <div style={{ clear: 'both' }}></div>
            <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.revenueBreakdownRegular}% [Regular] / {project.details.revenueBreakdownWalkin}% [Walk In]</> : null}</h4>
            <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.clinteleBreakdownLocal}% [Local] / {project.details.clinteleBreakdownTourist}% [Tourist]</> : null}</h4>
            {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>${project.details.outletLocation}</> : null}</h4>
            <h4 class="preferredListingContainer-text-right">{project != null ? <>${project.details.expectedRevenue}</> : null}</h4> */}
            <div style={{ clear: 'both' }}></div>
          </div>
          <div class="preferredListingContainer_child">
            <label class="preferredListingContainer-label-left">PRODUCTS OF BUSINESS / PRICING OF PRODUCTS</label>
            <label class="preferredListingContainer-label-right">COMPANY DECK</label>
            {/* <label class="preferredListingContainer-label-right">REVENUE BREAKDOWN</label> */}
            <div style={{ clear: 'both' }}></div>
            <h4 class="preferredListingContainer-text-left">{project != null ? <><i class="fa fa-file-pdf-o" style={{ fontSize: "24px", color: "#33434D", cursor: "pointer" }} onClick={() => window.open(project.details.product_pricing)}></i></> : null}</h4>
            {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>${project.details.product_pricing}</> : null}</h4> */}
            {/* <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.revenueBreakdown}</> : null}</h4> */}
            <h4 class="preferredListingContainer-text-right">{project != null ? <><i class="fa fa-file-pdf-o" style={{ fontSize: "24px", color: "#33434D", cursor: "pointer" }} onClick={() => window.open(project.details.companyDeckURL)}></i></> : null}</h4>
            <div style={{ clear: 'both' }}></div>
          </div>
          <div class="preferredListingContainer_child">
            {/* <label class="preferredListingContainer-label-left">OUTLET LOCATIONS <br></br> <p style={{fontSize:"11px"}}>IF IT'S A RELOCATION OR EXPANSION</p></label> */}
            <label class="preferredListingContainer-label-left">CORPORATE TURNOVER</label>
            <label class="preferredListingContainer-label-right">EXPECTED REVENUE</label>
            <div style={{ clear: 'both' }}></div>
            {/* <h4 class="preferredListingContainer-text-left">{project != null ? <>${project.details.outletLocation}</> : null}</h4> */}
            <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.coporateTurnover}%</> : null}</h4>
            <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.revenueBreakdownWalkin}%</> : null}</h4>
            <div style={{ clear: 'both' }}></div>
          </div>
          <div class="preferredListingContainer_child">
            <label class="preferredListingContainer-label-left">OUTLET RENTAL</label>
            <label class="preferredListingContainer-label-right">RENTAL LOCATION</label>
            <div style={{ clear: 'both' }}></div>
            <h4 class="preferredListingContainer-text-left">{project != null ? <>{project.details.otherRental}</> : null}</h4>
            <h4 class="preferredListingContainer-text-right">{project != null ? <>{project.details.outletLocation == null ? "-" : project.details.outletLocation}</> : null}</h4>
            <div style={{ clear: 'both' }}></div>
          </div>


        </div>

        : null : null}
      <Footer />

    </>
  )
}