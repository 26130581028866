import React, { useEffect, useRef, useState } from 'react';
import { UserAuth } from '../../AuthContent';
import { doc, setDoc, getDoc, collection, addDoc, updateDoc, serverTimestamp, deleteField, getDocs, arrayUnion, query } from "firebase/firestore";
import { db, storage } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import Acknowledge from './Acknowledge';
import '../css/UserTypeModal.css';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import AcknowledgeNormal from '../../AcknowledgeNormal';
import Spinner from './Spinner';

export default function ChooseProperty(props) {
    const { user } = UserAuth();
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedPropertyName, setSelectedPropertyName] = useState(null);
    const [commercialUserPropertyList, setCommercialUserPropertyList] = useState([]);
    const [residentialUserPropertyList, setResidentialUserPropertyList] = useState([]);


    const closeChooseProperty = () => {
        setSelectedProperty(null);
        props.setTrigger(false);

    }

    useEffect(() => {
        if (props.trigger) {
            const fetchData = async () => {
                if (props.data.chatType === "CommercialRent" || props.data.chatType === "CommercialSales") {
                    let snapshotCommercialList = await getDoc(doc(db, 'commericalPropertyList', props.currentUserInfo.uid));
                    if (snapshotCommercialList.exists()) {
                        console.log(Object.values(snapshotCommercialList.data()))
                        setCommercialUserPropertyList(Object.values(snapshotCommercialList.data()));
                    }
                } else if (props.data.chatType === "ResidentialRent" || props.data.chatType === "ResidentialSales") {
                    let snapshotResidentialList = await getDoc(doc(db, 'residentialPropertyList', props.currentUserInfo.uid));
                    if (snapshotResidentialList.exists()) {
                        setCommercialUserPropertyList(Object.values(snapshotResidentialList.data()));
                    }
                }
            };

            fetchData(); // Call the async function inside useEffect
        }

    }, [props]); // Make sure to include dependencies in the dependency array


    const proceedSendProperty = async () => {

        if (selectedProperty != null) {

            await updateDoc(doc(db, 'chats', props.navID), {
                messages: arrayUnion({
                    createdAt: new Date(),
                    text: selectedProperty,
                    sender: user.uid,
                    senderName: user.displayName
                }),

            });
            if (props.data.chatType == "CommercialRent") {
                if (props.currentUserInfo.membership == "Enterprise") {


                }
                if (props.data.status == "Pending Closing") {
                    if (props.data.hasOwnProperty('agentProfile')) {
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPhoto: props.data.listingPhoto,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    listingPricePerSquareFeet: props.data.listingPricePerSquareFeet,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPhoto: props.data.listingPhoto,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    listingPricePerSquareFeet: props.data.listingPricePerSquareFeet,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                    else {
                        //No property Agent
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPhoto: props.data.listingPhoto,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    listingPricePerSquareFeet: props.data.listingPricePerSquareFeet,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPhoto: props.data.listingPhoto,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    listingPricePerSquareFeet: props.data.listingPricePerSquareFeet,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }

                }
                else if (props.data.status == "Pending LOO") {
                    if (props.data.hasOwnProperty('agentProfile')) {
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPhoto: props.data.listingPhoto,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    listingPricePerSquareFeet: props.data.listingPricePerSquareFeet,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPhoto: props.data.listingPhoto,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    listingPricePerSquareFeet: props.data.listingPricePerSquareFeet,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                    else {
                        //No property Agent
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPhoto: props.data.listingPhoto,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    listingPricePerSquareFeet: props.data.listingPricePerSquareFeet,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPhoto: props.data.listingPhoto,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    listingPricePerSquareFeet: props.data.listingPricePerSquareFeet,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }

                }
                else {
                    if (props.data.hasOwnProperty('agentProfile')) {
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPhoto: props.data.listingPhoto,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    listingPricePerSquareFeet: props.data.listingPricePerSquareFeet,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPhoto: props.data.listingPhoto,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    listingPricePerSquareFeet: props.data.listingPricePerSquareFeet,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                    else {
                        //No Property Agent
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPhoto: props.data.listingPhoto,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    listingPricePerSquareFeet: props.data.listingPricePerSquareFeet,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPhoto: props.data.listingPhoto,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    listingPricePerSquareFeet: props.data.listingPricePerSquareFeet,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                }
            }
            ////////////////////////////////////CommericalSales
            if (props.data.chatType == "CommercialSales") {
                if (props.currentUserInfo.membership == "Enterprise") {

                }
                if (props.data.status == "Pending Closing") {
                    if (props.data.hasOwnProperty('agentProfile')) {
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.moveInPeriod,
                                    minFloorSizePSFBudget: props.data.minFloorSizePSFBudget,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.moveInPeriod,
                                    minFloorSizePSFBudget: props.data.minFloorSizePSFBudget,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                    else {
                        //No property Agent
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.listingPriceOverall,
                                    minFloorSizePSFBudget: props.data.minFloorSizePSFBudget,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.moveInPeriod,
                                    minFloorSizePSFBudget: props.data.minFloorSizePSFBudget,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }

                }
                else if (props.data.status == "Pending LOO") {
                    if (props.data.hasOwnProperty('agentProfile')) {
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.moveInPeriod,
                                    minFloorSizePSFBudget: props.data.minFloorSizePSFBudget,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.listingPriceOverall,
                                    minFloorSizePSFBudget: props.data.minFloorSizePSFBudget,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                    else {
                        //No property Agent
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.moveInPeriod,
                                    minFloorSizePSFBudget: props.data.minFloorSizePSFBudget,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.moveInPeriod,
                                    minFloorSizePSFBudget: props.data.minFloorSizePSFBudget,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }

                }
                else {
                    if (props.data.hasOwnProperty('agentProfile')) {
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.moveInPeriod,
                                    minFloorSizePSFBudget: props.data.minFloorSizePSFBudget,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.moveInPeriod,
                                    minFloorSizePSFBudget: props.data.minFloorSizePSFBudget,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                    else {
                        //No Property Agent
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.moveInPeriod,
                                    minFloorSizePSFBudget: props.data.minFloorSizePSFBudget,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.moveInPeriod,
                                    minFloorSizePSFBudget: props.data.minFloorSizePSFBudget,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                }
            }
            //////////////////////ResidentialRent
            if (props.data.chatType == "ResidentialRent") {
                if (props.currentUserInfo.membership == "Enterprise") {

                }
                if (props.data.status == "Pending Closing") {
                    if (props.data.hasOwnProperty('agentProfile')) {
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    moveInPeriod: props.data.moveInPeriod,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                    else {
                        //No property Agent
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }

                }
                else if (props.data.status == "Pending LOO") {
                    if (props.data.hasOwnProperty('agentProfile')) {
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                    else {
                        //No property Agent
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }

                }
                else {
                    if (props.data.hasOwnProperty('agentProfile')) {
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                    else {
                        //No Property Agent
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    listingPriceOverall: props.data.listingPriceOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                }
            }
            ///////////////////////////////ResidentialSales
            if (props.data.chatType == "ResidentialSales") {
                if (props.currentUserInfo.membership == "Enterprise") {

                }
                if (props.data.status == "Pending Closing") {
                    if (props.data.hasOwnProperty('agentProfile')) {
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    budgetOverall: props.data.budgetOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    budgetOverall: props.data.budgetOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                    else {
                        //No property Agent
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    budgetOverall: props.data.budgetOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    budgetOverall: props.data.budgetOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    closeCounter: props.data.closeCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }

                }
                else if (props.data.status == "Pending LOO") {
                    if (props.data.hasOwnProperty('agentProfile')) {
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    budgetOverall: props.data.budgetOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    budgetOverall: props.data.budgetOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                    else {
                        //No property Agent
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    budgetOverall: props.data.budgetOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    budgetOverall: props.data.budgetOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended,
                                    looCounter: props.data.looCounter
                                }
                            }
                        })
                        closeChooseProperty();
                    }

                }
                else {
                    if (props.data.hasOwnProperty('agentProfile')) {
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    budgetOverall: props.data.budgetOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    agentProfile: props.data.agentProfile,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    budgetOverall: props.data.budgetOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                    else {
                        //No Property Agent
                        await updateDoc(doc(db, 'userChat', user.uid), {
                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.oppositePublicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.data.photoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    budgetOverall: props.data.budgetOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Read",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.myUID,
                                    oppositeUID: props.data.oppositeUID,
                                    oppUserType: props.data.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        await updateDoc(doc(db, 'userChat', props.data.oppositeUID), {

                            [props.navID]: {
                                date: serverTimestamp(),
                                userInfo: {
                                    agentRepresented: props.data.agentRepresented,
                                    displayName: props.publicInfo.username,
                                    listingID: props.data.listingID,
                                    photoURL: props.currentUserInfo.userPhotoURL,
                                    lastMessage: `${selectedProperty.propertyCompanyName} property profile`,
                                    listingName: props.data.listingName,
                                    budgetOverall: props.data.budgetOverall,
                                    moveInPeriod: props.data.moveInPeriod,
                                    statusRead: "Unread",
                                    chatType: props.data.chatType,
                                    status: props.data.status,
                                    myUID: props.data.oppositeUID,
                                    oppositeUID: props.data.myUID,
                                    oppUserType: props.oppositeChatInfo.oppUserType,
                                    propertyList: props.data.propertyList,
                                    recommended: props.data.recommended
                                }
                            }
                        })
                        closeChooseProperty();
                    }
                }
            }

        }
        else {
            alert("No property profile selected")
        }
    }



    const handleSelectedProperty = (type, index) => {
        if (type == "commercial") {
            var oldTargetProperty = document.querySelector('.selectedChooseProperty');
            if (oldTargetProperty != null) {
                oldTargetProperty.className = "notSelectedChooseProperty";
            }

            var targetProperty = document.getElementById(`commercial${index}`);
            targetProperty.className = 'selectedChooseProperty';
            setSelectedProperty(commercialUserPropertyList[index]);
        }
        else {
            var oldTargetProperty = document.querySelector('.selectedChooseProperty');
            if (oldTargetProperty != null) {
                oldTargetProperty.className = "notSelectedChooseProperty";
            }
            var targetProperty = document.getElementById(`residential${index}`);
            targetProperty.className = 'selectedChooseProperty';
            setSelectedProperty(residentialUserPropertyList[index]);
        }
    }




    if (!props.trigger) {
        return;
    }
    else {


        return (
            <>

                <div className='modalNewProject' style={{ zIndex: "2" }}>
                    <div className='modal-contentUserType'>

                        <>
                            <div id="primaryRoleTab" >
                                <div className='modal-headerNewProject' >
                                    <div className="notification-close" onClick={() => closeChooseProperty()}>
                                        ×
                                    </div>
                                    <h4 className='modal-titleNewProject'>PROPERTY PROFILE</h4>
                                </div>

                                <div className='modal-bodyUserType' style={{ display: "block", margin: "1%" }}>
                                    <center><p>Choose a property profile to send: </p></center>
                                        {console.log(residentialUserPropertyList, commercialUserPropertyList)}
                                        {props.data.hasOwnProperty('chatType')?
                                        (props.data.chatType === "CommercialRent" || props.data.chatType === "CommercialSales") ?
                                        commercialUserPropertyList.map((indivAddressProperty, index) => {
                                            return (
                                                <div className="notSelectedChooseProperty" id={`commercial${index}`} style={{ cursor: "pointer", marginBottom: "1%", borderBottom: "1px solid #c1c1c1", padding: "8px 8px" }} onClick={() => { handleSelectedProperty('commercial', index) }}>
                                                    <p> {indivAddressProperty.agentRepresented == false ? <p style={{ fontSize: "14px", backgroundColor: "#33434D", width: "fit-content", padding: " 4px 8px", borderRadius: "10px", color: "white" }}>{indivAddressProperty.verified == "approve" ? "Verified Property" : "Unverified property"}</p> : null}</p>
                                                    <p>Property Name: {indivAddressProperty.propertyCompanyName}</p>
                                                    <p>{indivAddressProperty.propertyAddress}</p>
                                                </div>
                                            );
                                        })
                                        :
                                    
                                    
                                        residentialUserPropertyList.map((indivAddressProperty, index) => {
                                            return (
                                                <div className="notSelectedChooseProperty" id={`residential${index}`} style={{ cursor: "pointer", marginBottom: "1%", borderBottom: "1px solid #c1c1c1", padding: "8px 8px" }} onClick={() => { handleSelectedProperty('residential', index) }}>
                                                    <p> {indivAddressProperty.agentRepresented == false ? <p style={{ fontSize: "14px", backgroundColor: "#33434D", width: "fit-content", padding: " 4px 8px", borderRadius: "10px", color: "white" }}>{indivAddressProperty.verified == "approve" ? "Verified Property" : "Unverified property"}</p> : null}</p>
                                                    <p>Property Name: {indivAddressProperty.propertyCompanyName}</p>
                                                    <p>{indivAddressProperty.propertyAddress}</p>
                                                </div>
                                            );
                                        }):null}
                                    {/* <select
                                        style={{ padding: "8px 8px", borderRadius: "10px", border: "1px solid #ccc" }}
                                        value={selectedPropertyName}
                                        onChange={handleChangeSelectedProperty}
                                    >
                                        {props.currentUserInfo.hasOwnProperty("propertyAddressList") ? (
                                            props.currentUserInfo.propertyAddressList.map((indivAddressProperty, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={indivAddressProperty.propertyCompanyName}
                                                    >
                                                        {indivAddressProperty.propertyCompanyName}
                                                    </option>
                                                );
                                            })
                                        ) : null}
                                        {props.currentUserInfo.hasOwnProperty("propertyAddressListResidential") ? (
                                            props.currentUserInfo.propertyAddressListResidential.map(
                                                (indivAddressPropertyResidential, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={indivAddressPropertyResidential.propertyCompanyName}
                                                        >
                                                            {indivAddressPropertyResidential.propertyCompanyName}
                                                        </option>
                                                    );
                                                }
                                            )
                                        ) : null}
                                    </select> */}


                                </div>

                                <div className='modal-footerNewProject'>

                                    <button id="addPropertyBtn" onClick={() => { proceedSendProperty() }}>Proceed</button>
                                    {/* <button id="addPropertyBtn" style={{ marginRight: "3%" }} onClick={() => { setChooseAdditionRole(true); setAskAgentCEA(false); setSecondaryRole(null); }}>Back</button> */}
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>












                        </>
                    </div>
                </div>
            </>
        )
    }
}




