import React, { useEffect, useState } from 'react';
import '../css/NewProject.css';
import { UserAuth } from '../../AuthContent';

export default function AcknowledgeNormal(props) {
    const [messageTenant, setMessageTenant] = useState("Hi, I have a property that matches your requirements and would like to send you more details. Hope to connect with you soon!");
    const MAX_CHARACTERS_BIG = 300;
    const [roleSelected, setRoleSelected] = useState(null);
    const handleMessageTenantChange = (e) => {
        if (e.target.value.length <= MAX_CHARACTERS_BIG) {
            setMessageTenant(e.target.value);
        }
    }
    const confirmDropMessage = () => {
        props.setHandleMessageClick(true);
        props.setTargetRole(roleSelected);
        props.setHandleMessageTextArea(messageTenant);
        setMessageTenant(null);
        props.setTrigger(false);
    }


    const handleChangeRole = (e) => {
        setRoleSelected(e.target.value);
    }

    useEffect(() => {
        if (!props.trigger) {

        }
        else {
            for (var i = 0; i < props.userInfo.userType.length; i++) {
                if (props.userInfo.userType[i] != "Tenant" && props.userInfo.userType[i] != "Advertiser") {
                    setRoleSelected(props.userInfo.userType[i]);
                }
            }

        }
    }, [props])


    if (!props.trigger) {
        return;
    }
    else {

        const closeAcknowledge = async () => {
            props.setTrigger(false);
            setMessageTenant(null);
            setRoleSelected(props.userInfo != null ? props.userInfo.userType[0] : null)
            props.setHandleMessageClick(false);
            props.setHandleMessageTextArea(null);

        }



        return (
            <>
                <div className='modalNewProject' style={{ zIndex: "999" }}>
                    <div className='modal-contentAcknowledge'>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => closeAcknowledge()}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>Message Request</h4>
                        </div>
                        <div class='modal-acknowledge'>
                            <div style={{width:"98%", margin:"1%"}}>
                                {console.log(props)}
                                <>
                                <img
                                    className="inline-block h-10 w-10 rounded-full ring-2 ring-white mr-4"
                                    src={props.listingDetails.details.userImage}
                                    alt=""
                                    style={{float:"left"}}
                                />
                                <div style={{float:"left"}}>
                                <p style={{ textAlign:"left", padding:"0"}}><span style={{fontWeight:"bold"}}>Recipient:</span><br></br>{props.listingDetails.details.username} {props.listingDetails.details.propertyAgent == true ? ` [ ${props.listingDetails.details.propertyAgentCompany} ]` : (props.type == "residential rent" || props.type == "commercial rent") ? " [Direct Tenant]" :" [Direct Buyer]"}</p>
                                <p className="text-gray-500"style={{ textAlign:"left", padding:"0"}}>Credit: 23</p>
                                </div>
                                <div style={{ clear: 'both' }}></div>
                                </>
                                
                            </div>
                            <textarea style={{ width: "98%", margin: "1%", padding: "1%", height: "30vh", resize: "none", border: "1px solid #c0c0c0", borderRadius: "10px" }} placeholder='Write a message for the tenant' value={messageTenant} onChange={handleMessageTenantChange}></textarea>
                            <span style={{ margin: "1%" }} className='characterCount'>{messageTenant != null ? MAX_CHARACTERS_BIG - messageTenant.length : MAX_CHARACTERS_BIG} characters</span>
                            <br></br>
                            {props.userInfo.userType.length > 1 ?
                                <>
                                    <span style={{ margin: "1%", fontSize: "13px" }} >Role Represent:</span>
                                    <select style={{ padding: "3px 8px", border: "1px solid #ccc", borderRadius: "10px" }} value={roleSelected} onChange={handleChangeRole}>
                                        {props.userInfo.userType.map((indivRole) => {
                                            return (
                                                <>
                                                    {indivRole != "Tenant" && indivRole != "Advertiser" ? <option value={indivRole}>{indivRole}</option> : null}
                                                </>
                                            )
                                        })}
                                    </select></> : null}
                        </div>
                        <div className='modal-footerNewProject'>
                            <button className='newListingBtn' onClick={() => confirmDropMessage()}>Confirm</button>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </div>

            </>
        );
    }

}
