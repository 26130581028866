import React, { useRef, useEffect } from 'react';
import '../../App.css';
import '../css/AboutUs.css'
import { db } from '../../firebase/firebase'
import { onSnapshot, doc, getDoc } from 'firebase/firestore';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import Footer from '../Footer';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Notification from './Notification';



function AboutUs() {

  const chats = useRef(null);
  const { user, deleteUserData } = UserAuth();
  let unsbscribe;


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])
  

  useEffect(() => {
    const checkingUser = async () => {
      var docsnap = await getDoc(doc(db, 'userInfo', user.uid));
      if (docsnap.exists()) {
      }
      else {
        // alert("hacked your way through ah");
        deleteUserData();

      }
    }
    if (user != null) {
      if (Object.keys(user).length != 0) {
        checkingUser();
      }
    }
  }, [user])

  return (
    <>
    {/* <Notification></Notification> */}
      {/* <ReactNotifications /> */}
      <div id="aboutUsRowOne" >
        <div id="aboutUsLeft">
          <h2>About Us</h2>
          <p>A rental platform to support peer-to-peer lending - Do you have any items that are under utilised at home? Why not monetise them on Nextloop rental app? Instead of letting these unused or under utilised item cluttering your homes, rent them to users around your estate to earn some spare cash. Simply state your rental charges and terms to start your first rental listings!</p>
          {/* <br></br>
          <p>By creating a platform for landlords to reach out to tenants based on industry types, areas and budget. This allows room for more matches, current or future opportunities and networking that will go beyond the transaction of just one commercial space</p> */}
        </div>
        <div id="aboutUsRight">
          <img src='./navLogo.png' style={{ width: "70%", paddingLeft: "10%" }}></img>
          <p id="slogan">HANDSHAKES MADE SIMPLE</p>
        </div>
        {/* <center><h1>How it works</h1></center> */}
        {/* <i class="fa-solid fa-bolt"></i> */}
        {/* <video controls src="https://ik.imagekit.io/NextLoop/Video/homeVideo.mp4?" style={{ width: "100%", height: "600px" }} autoPlay muted loop></video> */}

        <div style={{ clear: 'both' }}></div>
      </div>
      
      {/* <div id="aboutUsRowTwo">
        <div id="aboutUsLeftRow2">
          <img src='./pic3.png' ></img>
        </div>
        <div id="aboutUsRightRow2">
          <h2>Company Vision</h2>
          <p>At Nextloop, we envision a world where real estate stakeholders and individuals access innovative, sustainable solutions effortlessly. As industry leaders, we deliver exceptional products, optimizing time utilization for more meaningful pursuits. Nextloop's core vision is focused on fostering technology, collaboration, and sustainability.</p>
        </div>
      </div> */}
      <div style={{ clear: 'both' }}></div>

      {/* <div id="aboutUsFAQ" >

        <center><h2>Frequently Asked Questions</h2></center>
        <Accordion allowZeroExpanded={true} style={{ marginBottom: "1%" }}>
          <AccordionItem style={{ marginBottom: "10px" }}>
            <AccordionItemHeading>
              <AccordionItemButton>What is Next Loop and how does it work?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Next Loop is a property platform built for tenants, by tenants (ourselves). With Next Loop, skip the lengthy
              negotiations and hefty agent fees. Simply state your terms, your way by creating a listing on our platform.
              Once a landlord is happy to accept the listed terms, they will be in touch with you. You can register for a
              Next Loop account in just 2 minutes and post your listings for free. <a href='/sign-up'>Sign up</a> here now
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{ marginBottom: "10px" }}>
            <AccordionItemHeading>
              <AccordionItemButton>How do I get started?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                    Upon signing up for an account, click on [Account] > [Listing] > [Add Listing], fill in your requirements
              and submit your listing. It will be great if you can upload your actual business photos for an accurate
              representation of your concept, otherwise we do have a deck of trade images for you to choose from.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{ marginBottom: "10px" }}>
            <AccordionItemHeading>
              <AccordionItemButton>Is Next Loop free to use?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Next Loop is a free to use platform for all users.
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{ marginBottom: "10px" }}>
            <AccordionItemHeading>
              <AccordionItemButton>How many tiers of membership are there?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              There are three tiers of membership available - free, freemium and premium.
              <br></br>
              Free membership is free to use for all.
              <br></br>
              Freemium - Coming soon!
              <br></br>
              Premium - Coming soon!
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem style={{ marginBottom: "10px" }}>
            <AccordionItemHeading>
              <AccordionItemButton>How does the referral program work?</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Coming Soon
            </AccordionItemPanel>
          </AccordionItem> */}

          {/* <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>How do I boost my listings?</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    Boost your listing for as low as $1 per day and gain more views from other users. Within your account’s page, 
                    click on the [Boost] button on the listing you would like to promote. Select your dates and proceed to payment.
                    </AccordionItemPanel>
                </AccordionItem> */}

        {/* </Accordion>
      </div> */}
      <div style={{ clear: 'both' }}></div>
      <Footer />
    </>


  )
}

export default AboutUs;
