import React, { useState } from 'react';
import '../css/NewProject.css';
import { UserAuth } from '../../AuthContent';
import { updateDoc } from 'firebase/firestore';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import { collection, getDocs, onSnapshot, doc, arrayUnion, serverTimestamp, deleteDoc, getDoc, query, where, deleteField, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebase'

export default function ChangeUserName(props) {
    const { logOut, user } = UserAuth();
    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
    const [acknowledgeMessage, setAcknowledgeMessage] = useState("");
    if (!props.trigger) {
        return;
    }
    else {

        const closeAcknowledge = async () => {
            props.setTrigger(false);
        }

        const updateUsername = async () => {
            if (document.getElementById('usernameInput').value != "") {
                var duplicatedUsername = false;
                await getDocs(collection(db, 'publicUserInfo')).then((allPublicDoc) => {
                    console.log(allPublicDoc.docs)
                    for (var i = 0; i < allPublicDoc.docs.length; i++) {
                        console.log(allPublicDoc.docs[i].data().username)
                        if (allPublicDoc.docs[i].data().username.toLowerCase().replace(/\s+/g, '') == document.getElementById('usernameInput').value.toLowerCase().replace(/\s+/g, '')) {
                            setAcknowledgeNormal(true);
                            setAcknowledgeMessage("Duplicated Username");
                            duplicatedUsername = true;
                            return
                        }
                    }
                }).then(async () => {

                    if(duplicatedUsername == false){
                        await updateDoc(doc(db, 'publicUserInfo', user.uid), {
                            username: document.getElementById('usernameInput').value,
                            usernameDate: new Date()
                        }).then(() => {
                            var changedPublicInfo = props.publicInfo;
                            changedPublicInfo.allowChangeUsername = false;
    
                            changedPublicInfo.username = document.getElementById('usernameInput').value;
                            props.setPublicInfo(changedPublicInfo);
                            setAcknowledgeNormal(true);
                            setAcknowledgeMessage("Updated")
                        })
                    }
                    
                })
            }
            else {
                setAcknowledgeNormal(true);
                setAcknowledgeMessage("Invalid Username");
            }

        }
        return (
            <>
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={acknowledgeMessage} parentTrigger={props.setTrigger}></AcknowledgeNormal>
                <div className='modalNewProject' style={{ zIndex: "10" }}>
                    <div className='modal-contentAcknowledge'>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => closeAcknowledge()}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>USERNAME</h4>
                        </div>
                        <div class='modal-acknowledge' style={{ textAlign: "left" }}>
                            <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", padding: "3px 8px", borderRadius: "10px",  width: "98%", margin:"1%" }}>
                                <i class="fa-solid fa-exclamation" style={{ color: "#33434d", display: "flex", alignItems: "center", fontSize: "25px", marginRight: "2%" }}></i>

                                <div style={{ display: "flex", textAlign: "left", flexDirection: "column" }}>
                                    <p>Username changes are only available every six months, following any modifications.</p>
                                </div>
                            </div>
                            <p id="changeUsernameLabel">Username</p>
                            <input id="usernameInput" style={{ width: "97%", padding: "8px 10px", border: "1px solid #ccc", borderRadius: "10px", cursor: "pointer", marginBottom: "2%", marginLeft: "1%" }} maxLength="10"
                                onInput={(event) => {
                                    const input = event.target;
                                    if (input.value.length > input.maxLength) {
                                        input.value = input.value.slice(0, input.maxLength);
                                    }
                                }}></input>
                            <p style={{ fontSize: "12px", color: "grey", textAlign: "left" }}>Maximum of 10 Characters</p>

                        </div>
                        <div className='modal-footerNewProject'>
                            <button className='newListingBtn' onClick={() => updateUsername()}>Confirm</button>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </div>

            </>
        );
    }

}
