import React, { useState, useRef, useEffect, useContext, Fragment } from 'react';
import '../../App.css';
import '../css/Home.css'
import Footer from '../Footer'
import { Link, useSearchParams } from 'react-router-dom';
import { collection, getDocs, onSnapshot, doc, query, orderBy, limit, getDoc, setDoc, deleteDoc, startAfter, updateDoc, serverTimestamp, where } from 'firebase/firestore';
import { db } from '../../firebase/firebase'
import CardItem from '../CardItem';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import Overlay from 'react-bootstrap/Overlay';
import { useNavigate } from 'react-router-dom';
import Notification from './Notification';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {
    FireIcon,
    ChatBubbleLeftRightIcon,
    GlobeAltIcon,
    WrenchScrewdriverIcon
} from '@heroicons/react/24/outline'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
    Bars3Icon,

    XMarkIcon,
    BuildingOffice2Icon,
    BuildingStorefrontIcon,
    HomeModernIcon,
    HomeIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import Spinner from './Spinner';
import ZoomPicture from './ZoomPictures';

const commercialItem = [
    { name: 'Rent Requirements', description: 'Find your ideal rent buyer', href: 'commercialRent', icon: BuildingOffice2Icon },
    { name: 'Sale Requirements', description: 'Find your ideal sale buyer', href: 'commercialPurchase', icon: BuildingStorefrontIcon },

]
const residentialItem = [
    { name: 'Rent Requirements', description: 'Find your ideal rent buyer', href: 'commercialRent', icon: HomeModernIcon },
    { name: 'Sale Requirements', description: 'Find your ideal sale buyer', href: 'commercialPurchase', icon: HomeIcon },

]



function Explore(props) {
    const { user } = UserAuth();
    const [breadcrumbDic, setBreadCrumbDic] = useState(null);
    const [zoomPic, setZoomPic] = useState(false);
    const [zoomPicURL, setZoomPicURL] = useState(null);
    const [district1, setDistrict1] = useState(false);
    const [district2, setDistrict2] = useState(false);
    const [district3, setDistrict3] = useState(false);
    const [district4, setDistrict4] = useState(false);
    const [district5, setDistrict5] = useState(false);
    const [district6, setDistrict6] = useState(false);
    const [district7, setDistrict7] = useState(false);
    const [district8, setDistrict8] = useState(false);
    const [district9, setDistrict9] = useState(false);
    const [district10, setDistrict10] = useState(false);
    const [district11, setDistrict11] = useState(false);
    const [district12, setDistrict12] = useState(false);
    const [district13, setDistrict13] = useState(false);
    const [district14, setDistrict14] = useState(false);
    const [district15, setDistrict15] = useState(false);
    const [district16, setDistrict16] = useState(false);
    const [district17, setDistrict17] = useState(false);
    const [district18, setDistrict18] = useState(false);
    const [district19, setDistrict19] = useState(false);
    const [district20, setDistrict20] = useState(false);
    const [district21, setDistrict21] = useState(false);
    const [district22, setDistrict22] = useState(false);
    const [district23, setDistrict23] = useState(false);
    const [district24, setDistrict24] = useState(false);
    const [district25, setDistrict25] = useState(false);
    const [district26, setDistrict26] = useState(false);
    const [district27, setDistrict27] = useState(false);
    const [district28, setDistrict28] = useState(false);
    const [lastestPostClick, setLatestPostClick] = useState(false);
    const [district1List, setDistrict1List] = useState([]);
    const [district2List, setDistrict2List] = useState([]);
    const [district3List, setDistrict3List] = useState([]);
    const [district4List, setDistrict4List] = useState([]);
    const [district5List, setDistrict5List] = useState([]);
    const [district6List, setDistrict6List] = useState([]);
    const [district7List, setDistrict7List] = useState([]);
    const [district8List, setDistrict8List] = useState([]);
    const [district9List, setDistrict9List] = useState([]);
    const [district10List, setDistrict10List] = useState([]);
    const [district11List, setDistrict11List] = useState([]);
    const [district12List, setDistrict12List] = useState([]);
    const [district13List, setDistrict13List] = useState([]);
    const [district14List, setDistrict14List] = useState([]);
    const [district15List, setDistrict15List] = useState([]);
    const [district16List, setDistrict16List] = useState([]);
    const [district17List, setDistrict17List] = useState([]);
    const [district18List, setDistrict18List] = useState([]);
    const [district19List, setDistrict19List] = useState([]);
    const [district20List, setDistrict20List] = useState([]);
    const [district21List, setDistrict21List] = useState([]);
    const [district22List, setDistrict22List] = useState([]);
    const [district23List, setDistrict23List] = useState([]);
    const [district24List, setDistrict24List] = useState([]);
    const [district25List, setDistrict25List] = useState([]);
    const [district26List, setDistrict26List] = useState([]);
    const [district27List, setDistrict27List] = useState([]);
    const [district28List, setDistrict28List] = useState([]);
    const [latestPostList, setLatestPostList] = useState([]);
    const [advertisementList, setAdvertisementList] = useState([]);
    const [advertisementListDistrict1, setAdvertisementListDistrict1] = useState([]);
    const [advertisementListDistrict2, setAdvertisementListDistrict2] = useState([]);
    const [advertisementListDistrict3, setAdvertisementListDistrict3] = useState([]);
    const [advertisementListDistrict4, setAdvertisementListDistrict4] = useState([]);
    const [advertisementListDistrict5, setAdvertisementListDistrict5] = useState([]);
    const [advertisementListDistrict6, setAdvertisementListDistrict6] = useState([]);
    const [advertisementListDistrict7, setAdvertisementListDistrict7] = useState([]);
    const [advertisementListDistrict8, setAdvertisementListDistrict8] = useState([]);
    const [advertisementListDistrict9, setAdvertisementListDistrict9] = useState([]);
    const [advertisementListDistrict10, setAdvertisementListDistrict10] = useState([]);
    const [advertisementListDistrict11, setAdvertisementListDistrict11] = useState([]);
    const [advertisementListDistrict12, setAdvertisementListDistrict12] = useState([]);
    const [advertisementListDistrict13, setAdvertisementListDistrict13] = useState([]);
    const [advertisementListDistrict14, setAdvertisementListDistrict14] = useState([]);
    const [advertisementListDistrict15, setAdvertisementListDistrict15] = useState([]);
    const [advertisementListDistrict16, setAdvertisementListDistrict16] = useState([]);
    const [advertisementListDistrict17, setAdvertisementListDistrict17] = useState([]);
    const [advertisementListDistrict18, setAdvertisementListDistrict18] = useState([]);
    const [advertisementListDistrict19, setAdvertisementListDistrict19] = useState([]);
    const [advertisementListDistrict20, setAdvertisementListDistrict20] = useState([]);
    const [advertisementListDistrict21, setAdvertisementListDistrict21] = useState([]);
    const [advertisementListDistrict22, setAdvertisementListDistrict22] = useState([]);
    const [advertisementListDistrict23, setAdvertisementListDistrict23] = useState([]);
    const [advertisementListDistrict24, setAdvertisementListDistrict24] = useState([]);
    const [advertisementListDistrict25, setAdvertisementListDistrict25] = useState([]);
    const [advertisementListDistrict26, setAdvertisementListDistrict26] = useState([]);
    const [advertisementListDistrict27, setAdvertisementListDistrict27] = useState([]);
    const [advertisementListDistrict28, setAdvertisementListDistrict28] = useState([]);

    const [advertisementNumber, setAdvertisementNumber] = useState([]);
    const [userPublicInfo, setUserPublicInfo] = useState(null);
    const [currentUserInfo, setCurrentUserInfo] = useState(null);
    const [credit, setCredit] = useState(23);
    const [confirmation, setConfirmation] = useState(false);
    const targetDistrictValue = useRef(null);
    const indexSellerProfileValue = useRef(null);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [successfulConnection, setSuccessfulConnection] = useState(false);
    const [somethingWrong, setSomethingWrong] = useState(false);
    const [somethingWrongSentence, setSomethingWrongSentence] = useState("Error");
    const [filter, setFilter] = useState('View All');

    const getSellerData = async () => {
        let allCommercialPropertyList = await getDocs(collection(db, 'commericalPropertyList'));

        // console.log(allCommercialPropertyList.docs);
        let district1 = [];
        let district2 = [];
        let district3 = [];
        let district4 = [];
        let district5 = [];
        let district6 = [];
        let district7 = [];
        let district8 = [];
        let district9 = [];
        let district10 = [];
        let district11 = [];
        let district12 = [];
        let district13 = [];
        let district14 = [];
        let district15 = [];
        let district16 = [];
        let district17 = [];
        let district18 = [];
        let district19 = [];
        let district20 = [];
        let district21 = [];
        let district22 = [];
        let district23 = [];
        let district24 = [];
        let district25 = [];
        let district26 = [];
        let district27 = [];
        let district28 = [];
        let latestPost = [];
        for (let i = 0; i < allCommercialPropertyList.docs.length; i++) {
            let eachProfile = allCommercialPropertyList.docs[i].data();
            let eachProfileKeyArray = Object.keys(allCommercialPropertyList.docs[i].data());
            for (let a = 0; a < eachProfileKeyArray.length; a++) {
                // console.log(eachProfile[eachProfileKeyArray[a]])
                if (eachProfile[eachProfileKeyArray[a]].district == "D01 City - Business District" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district1.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D02 City - Business District" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district2.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D03 Central South" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district3.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D04 South" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district4.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D05 South West" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district5.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D06 City - Business District" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district6.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D07 City" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district7.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D08 Central" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district8.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D09 Central - Orchard" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district9.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D10 Central - Near Orchard" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district10.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D11 Central - Near Orchard" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district11.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D12 Central" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district12.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D13 Central East" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district13.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D14 Central East" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district14.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D15 East Coast" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district15.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D16 Upper East Coast" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district16.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D17 Far East" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district17.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D18 Far East" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district18.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D19 North East" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district19.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D20 Central North" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district20.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D21 Central West" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district21.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D22 Far West" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district22.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D23 North West" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district23.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D24 Far North West" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district24.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D25 Far North" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district25.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D26 North" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district26.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D27 Far North" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district27.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D28 North East" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district28.push(eachProfile[eachProfileKeyArray[a]])
                }
                let timeDate = eachProfile[eachProfileKeyArray[a]].timePosted.toMillis();
                let todayTime = new Date().getTime();
                let timeDiff = todayTime - timeDate;
                if (timeDiff < (720 * 60 * 60 * 1000) && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    latestPost.push(eachProfile[eachProfileKeyArray[a]])
                }
            }

        }

        let allResidentialPropertyList = await getDocs(collection(db, 'residentialPropertyList'));
        // console.log(allResidentialPropertyList.docs);
        for (let i = 0; i < allResidentialPropertyList.docs.length; i++) {
            let eachProfile = allResidentialPropertyList.docs[i].data();
            let eachProfileKeyArray = Object.keys(allResidentialPropertyList.docs[i].data());
            for (let a = 0; a < eachProfileKeyArray.length; a++) {
                // console.log(eachProfile[eachProfileKeyArray[a]])
                if (eachProfile[eachProfileKeyArray[a]].district == "D01 City - Business District" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district1.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D02 City - Business District" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district2.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D03 Central South" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district3.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D04 South" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district4.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D05 South West" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district5.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D06 City - Business District" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district6.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D07 City" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district7.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D08 Central" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district8.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D09 Central - Orchard" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district9.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D10 Central - Near Orchard" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district10.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D11 Central - Near Orchard" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district11.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D12 Central" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district12.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D13 Central East" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district13.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D14 Central East" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district14.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D15 East Coast" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district15.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D16 Upper East Coast" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district16.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D17 Far East" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district17.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D18 Far East" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district18.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D19 North East" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district19.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D20 Central North" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district20.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D21 Central West" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district21.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D22 Far West" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district22.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D23 North West" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district23.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D24 Far North West" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district24.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D25 Far North" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district25.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D26 North" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district26.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D27 Far North" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district27.push(eachProfile[eachProfileKeyArray[a]])
                }
                else if (eachProfile[eachProfileKeyArray[a]].district == "D28 North East" && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    district28.push(eachProfile[eachProfileKeyArray[a]])
                }
                let timeDate = eachProfile[eachProfileKeyArray[a]].timePosted.toMillis();
                let todayTime = new Date().getTime();
                let timeDiff = todayTime - timeDate;
                if (timeDiff < (720 * 60 * 60 * 1000) && eachProfile[eachProfileKeyArray[a]].agentRepresented != true) {
                    latestPost.push(eachProfile[eachProfileKeyArray[a]])
                }
            }

        }

        const querySnapshot = await getDocs(query(collection(db, 'advertiseListing'), where('status', '==', 'leasing')));
        var advertisement = querySnapshot.docs.map(doc => doc.data());
        console.log(advertisement);
        let advertisementDistrict1 = [];
        let advertisementDistrict2 = [];
        let advertisementDistrict3 = [];
        let advertisementDistrict4 = [];
        let advertisementDistrict5 = [];
        let advertisementDistrict6 = [];
        let advertisementDistrict7 = [];
        let advertisementDistrict8 = [];
        let advertisementDistrict9 = [];
        let advertisementDistrict10 = [];
        let advertisementDistrict11 = [];
        let advertisementDistrict12 = [];
        let advertisementDistrict13 = [];
        let advertisementDistrict14 = [];
        let advertisementDistrict15 = [];
        let advertisementDistrict16 = [];
        let advertisementDistrict17 = [];
        let advertisementDistrict18 = [];
        let advertisementDistrict19 = [];
        let advertisementDistrict20 = [];
        let advertisementDistrict21 = [];
        let advertisementDistrict22 = [];
        let advertisementDistrict23 = [];
        let advertisementDistrict24 = [];
        let advertisementDistrict25 = [];
        let advertisementDistrict26 = [];
        let advertisementDistrict27 = [];
        let advertisementDistrict28 = [];

        for (let i = 0; i < advertisement.length; i++) {
            console.log(advertisement[i].sector)
            if (advertisement[i].sector == "District 1") {
                advertisementDistrict1.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 2") {
                advertisementDistrict2.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 3") {
                advertisementDistrict3.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 4") {
                advertisementDistrict4.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 5") {
                advertisementDistrict5.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 6") {
                advertisementDistrict6.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 7") {
                advertisementDistrict7.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 8") {
                advertisementDistrict8.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 9") {
                advertisementDistrict9.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 10") {
                advertisementDistrict10.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 11") {
                advertisementDistrict11.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 12") {
                advertisementDistrict12.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 13") {
                advertisementDistrict13.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 14") {
                advertisementDistrict14.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 15") {
                advertisementDistrict15.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 16") {
                advertisementDistrict16.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 17") {
                advertisementDistrict17.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 18") {
                advertisementDistrict18.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 19") {
                advertisementDistrict19.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 20") {
                advertisementDistrict20.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 21") {
                advertisementDistrict21.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 22") {
                advertisementDistrict22.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 23") {
                advertisementDistrict23.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 24") {
                advertisementDistrict24.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 25") {
                advertisementDistrict25.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 26") {
                advertisementDistrict26.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 27") {
                advertisementDistrict27.push(advertisement[i]);
            }
            else if (advertisement[i].sector == "District 28") {
                advertisementDistrict28.push(advertisement[i]);
            }
        }

        setAdvertisementNumber(0);
        setDistrict1List(district1);
        setDistrict2List(district2);
        setDistrict3List(district3);
        setDistrict4List(district4);
        setDistrict5List(district5);
        setDistrict6List(district6);
        setDistrict7List(district7);
        setDistrict8List(district8);
        setDistrict9List(district9);
        setDistrict10List(district10);
        setDistrict11List(district11);
        setDistrict12List(district12);
        setDistrict13List(district13);
        setDistrict14List(district14);
        setDistrict15List(district15);
        setDistrict16List(district16);
        setDistrict17List(district17);
        setDistrict18List(district18);
        setDistrict19List(district19);
        setDistrict20List(district20);
        setDistrict21List(district21);
        setDistrict22List(district22);
        setDistrict23List(district23);
        setDistrict24List(district24);
        setDistrict25List(district25);
        setDistrict26List(district26);
        setDistrict27List(district27);
        setDistrict28List(district28);
        setLatestPostList(latestPost);

        setAdvertisementList(advertisement);
        setAdvertisementListDistrict1(advertisementDistrict1);
        setAdvertisementListDistrict2(advertisementDistrict2);
        setAdvertisementListDistrict3(advertisementDistrict3);
        setAdvertisementListDistrict4(advertisementDistrict4);
        setAdvertisementListDistrict5(advertisementDistrict5);
        setAdvertisementListDistrict6(advertisementDistrict6);
        setAdvertisementListDistrict7(advertisementDistrict7);
        setAdvertisementListDistrict8(advertisementDistrict8);
        setAdvertisementListDistrict9(advertisementDistrict9);
        setAdvertisementListDistrict10(advertisementDistrict10);
        setAdvertisementListDistrict11(advertisementDistrict11);
        setAdvertisementListDistrict12(advertisementDistrict12);
        setAdvertisementListDistrict13(advertisementDistrict13);
        setAdvertisementListDistrict14(advertisementDistrict14);
        setAdvertisementListDistrict15(advertisementDistrict15);
        setAdvertisementListDistrict16(advertisementDistrict16);
        setAdvertisementListDistrict17(advertisementDistrict17);
        setAdvertisementListDistrict18(advertisementDistrict18);
        console.log(advertisementDistrict19)
        setAdvertisementListDistrict19(advertisementDistrict19);
        setAdvertisementListDistrict20(advertisementDistrict20);
        setAdvertisementListDistrict21(advertisementDistrict21);
        setAdvertisementListDistrict22(advertisementDistrict22);
        setAdvertisementListDistrict23(advertisementDistrict23);
        setAdvertisementListDistrict24(advertisementDistrict24);
        setAdvertisementListDistrict25(advertisementDistrict25);
        setAdvertisementListDistrict26(advertisementDistrict26);
        setAdvertisementListDistrict27(advertisementDistrict27);
        setAdvertisementListDistrict28(advertisementDistrict28);

    }

    const getUserInfo = async () => {
        let currentUserInfo = await getDoc(doc(db, 'userInfo', user.uid));
        setCurrentUserInfo(currentUserInfo.data());
    }

    useEffect(() => {
        getSellerData();
    }, [])

    useEffect(() =>{
        if(props.backToDefaultOwnerDirectory){
            backToDefault();
                props.setBackToDefaultOwnerDirectory(false)
        }
    },[props])

    useEffect(() => {
        if (user) {
            getUserInfo();
        }
    }, [user])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [district1, district2, district3, district4, district5, district6, district7, district8, district9, district10, district11, district12, district13, district14, district15, district16, district17, district18, district19, district20, district21, district22, district23, district24, district25, district26, district27, district28, lastestPostClick])

    useEffect(() => {
        if (successfulConnection || somethingWrong) {
            // Set a timer to reset both OTPSendNoti and somethingWrong after 5 seconds
            const timer = setTimeout(() => {
                if (successfulConnection) {
                    setSuccessfulConnection(false);
                }
                if (somethingWrong) {
                    setSomethingWrong(false)
                }
            }, 5000);

            // Cleanup timer when component unmounts or either OTPSendNoti or somethingWrong changes
            return () => clearTimeout(timer);
        }
    }, [successfulConnection, somethingWrong]);

    const connectToSeller = async () => {
        let targetDistrict = targetDistrictValue.current;
        let indexSellerProfile = indexSellerProfileValue.current;
        // console.log(district20List[indexSellerProfile])
        if (currentUserInfo == null) {
            setSomethingWrongSentence("Login to drop a connection");
            setSomethingWrong(true);
            setSpinnerLoading(false);
            return;
        }

        let newTransactionHistory = [...currentUserInfo.transactionHistory];
        if (newTransactionHistory.length >= 30) {
            newTransactionHistory.pop();
            newTransactionHistory.push(
                {
                    Actions: "+1 Points",
                    Activity: "Seller Profile Connections",
                    Date: new Date(),
                    Type: "Seller Profile"
                }
            )
        }
        else {
            newTransactionHistory.push(
                {
                    Actions: "+1 Points",
                    Activity: "Seller Profile Connections",
                    Date: new Date(),
                    Type: "Seller Profile"
                }
            )
        }
        let snapshotTransaction = await updateDoc(doc(db, 'userInfo', user.uid), {
            transactionHistory: newTransactionHistory,
            points: currentUserInfo.points + 1
        })
        if (targetDistrict == "Latest Profile") {
            if (latestPostList[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${latestPostList[indexSellerProfile].uid}${latestPostList[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${latestPostList[indexSellerProfile].uid}${latestPostList[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', latestPostList[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${latestPostList[indexSellerProfile].uid}${latestPostList[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: latestPostList[indexSellerProfile].username,
                            photoURL: latestPostList[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: latestPostList[indexSellerProfile].sellingPrice,
                            PSFValue: latestPostList[indexSellerProfile].PSFValue,
                            commission: latestPostList[indexSellerProfile].commission,
                            address: latestPostList[indexSellerProfile].propertyAddress,
                            postalCode: latestPostList[indexSellerProfile].postalCode,
                            unitNumber: latestPostList[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: latestPostList[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: latestPostList[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${latestPostList[indexSellerProfile].uid}${latestPostList[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: latestPostList[indexSellerProfile].username,
                            photoURL: latestPostList[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: latestPostList[indexSellerProfile].sellingPrice,
                            PSFValue: latestPostList[indexSellerProfile].PSFValue,
                            commission: latestPostList[indexSellerProfile].commission,
                            address: latestPostList[indexSellerProfile].propertyAddress,
                            postalCode: latestPostList[indexSellerProfile].postalCode,
                            unitNumber: latestPostList[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: latestPostList[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: latestPostList[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }

            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', latestPostList[indexSellerProfile].uid), {
                    [`${user.uid}${latestPostList[indexSellerProfile].uid}${latestPostList[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: latestPostList[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: latestPostList[indexSellerProfile].sellingPrice,
                            PSFValue: latestPostList[indexSellerProfile].PSFValue,
                            commission: latestPostList[indexSellerProfile].commission,
                            address: latestPostList[indexSellerProfile].propertyAddress,
                            postalCode: latestPostList[indexSellerProfile].postalCode,
                            unitNumber: latestPostList[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: latestPostList[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', latestPostList[indexSellerProfile].uid), {
                    [`${user.uid}${latestPostList[indexSellerProfile].uid}${latestPostList[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: latestPostList[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: latestPostList[indexSellerProfile].sellingPrice,
                            PSFValue: latestPostList[indexSellerProfile].PSFValue,
                            commission: latestPostList[indexSellerProfile].commission,
                            address: latestPostList[indexSellerProfile].propertyAddress,
                            postalCode: latestPostList[indexSellerProfile].postalCode,
                            unitNumber: latestPostList[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: latestPostList[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }




            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })
            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 1") {
            if (district1List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district1List[indexSellerProfile].uid}${district1List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district1List[indexSellerProfile].uid}${district1List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district1List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district1List[indexSellerProfile].uid}${district1List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district1List[indexSellerProfile].username,
                            photoURL: district1List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district1List[indexSellerProfile].sellingPrice,
                            PSFValue: district1List[indexSellerProfile].PSFValue,
                            commission: district1List[indexSellerProfile].commission,
                            address: district1List[indexSellerProfile].propertyAddress,
                            postalCode: district1List[indexSellerProfile].postalCode,
                            unitNumber: district1List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district1List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district1List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district1List[indexSellerProfile].uid}${district1List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district1List[indexSellerProfile].username,
                            photoURL: district1List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district1List[indexSellerProfile].sellingPrice,
                            PSFValue: district1List[indexSellerProfile].PSFValue,
                            commission: district1List[indexSellerProfile].commission,
                            address: district1List[indexSellerProfile].propertyAddress,
                            postalCode: district1List[indexSellerProfile].postalCode,
                            unitNumber: district1List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district1List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district1List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }

            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district1List[indexSellerProfile].uid), {
                    [`${user.uid}${district1List[indexSellerProfile].uid}${district1List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district1List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district1List[indexSellerProfile].sellingPrice,
                            PSFValue: district1List[indexSellerProfile].PSFValue,
                            commission: district1List[indexSellerProfile].commission,
                            address: district1List[indexSellerProfile].propertyAddress,
                            postalCode: district1List[indexSellerProfile].postalCode,
                            unitNumber: district1List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district1List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district1List[indexSellerProfile].uid), {
                    [`${user.uid}${district1List[indexSellerProfile].uid}${district1List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district1List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district1List[indexSellerProfile].sellingPrice,
                            PSFValue: district1List[indexSellerProfile].PSFValue,
                            commission: district1List[indexSellerProfile].commission,
                            address: district1List[indexSellerProfile].propertyAddress,
                            postalCode: district1List[indexSellerProfile].postalCode,
                            unitNumber: district1List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district1List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }




            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 2") {
            if (district2List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district2List[indexSellerProfile].uid}${district2List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district2List[indexSellerProfile].uid}${district2List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district2List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district2List[indexSellerProfile].uid}${district2List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district2List[indexSellerProfile].username,
                            photoURL: district2List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district2List[indexSellerProfile].sellingPrice,
                            PSFValue: district2List[indexSellerProfile].PSFValue,
                            commission: district2List[indexSellerProfile].commission,
                            address: district2List[indexSellerProfile].propertyAddress,
                            postalCode: district2List[indexSellerProfile].postalCode,
                            unitNumber: district2List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district2List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district2List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district2List[indexSellerProfile].uid}${district2List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district2List[indexSellerProfile].username,
                            photoURL: district2List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district2List[indexSellerProfile].sellingPrice,
                            PSFValue: district2List[indexSellerProfile].PSFValue,
                            commission: district2List[indexSellerProfile].commission,
                            address: district2List[indexSellerProfile].propertyAddress,
                            postalCode: district2List[indexSellerProfile].postalCode,
                            unitNumber: district2List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district2List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district2List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district2List[indexSellerProfile].uid), {
                    [`${user.uid}${district2List[indexSellerProfile].uid}${district2List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district2List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district2List[indexSellerProfile].sellingPrice,
                            PSFValue: district2List[indexSellerProfile].PSFValue,
                            commission: district2List[indexSellerProfile].commission,
                            address: district2List[indexSellerProfile].propertyAddress,
                            postalCode: district2List[indexSellerProfile].postalCode,
                            unitNumber: district2List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district2List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district2List[indexSellerProfile].uid), {
                    [`${user.uid}${district2List[indexSellerProfile].uid}${district2List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district2List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district2List[indexSellerProfile].sellingPrice,
                            PSFValue: district2List[indexSellerProfile].PSFValue,
                            commission: district2List[indexSellerProfile].commission,
                            address: district2List[indexSellerProfile].propertyAddress,
                            postalCode: district2List[indexSellerProfile].postalCode,
                            unitNumber: district2List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district2List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }




            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 3") {
            if (district3List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district3List[indexSellerProfile].uid}${district3List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district3List[indexSellerProfile].uid}${district3List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district3List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district3List[indexSellerProfile].uid}${district3List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district3List[indexSellerProfile].username,
                            photoURL: district3List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district3List[indexSellerProfile].sellingPrice,
                            PSFValue: district3List[indexSellerProfile].PSFValue,
                            commission: district3List[indexSellerProfile].commission,
                            address: district3List[indexSellerProfile].propertyAddress,
                            postalCode: district3List[indexSellerProfile].postalCode,
                            unitNumber: district3List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district3List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district3List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district3List[indexSellerProfile].uid}${district3List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district3List[indexSellerProfile].username,
                            photoURL: district3List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district3List[indexSellerProfile].sellingPrice,
                            PSFValue: district3List[indexSellerProfile].PSFValue,
                            commission: district3List[indexSellerProfile].commission,
                            address: district3List[indexSellerProfile].propertyAddress,
                            postalCode: district3List[indexSellerProfile].postalCode,
                            unitNumber: district3List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district3List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district3List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }

            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district3List[indexSellerProfile].uid), {
                    [`${user.uid}${district3List[indexSellerProfile].uid}${district3List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district3List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district3List[indexSellerProfile].sellingPrice,
                            PSFValue: district3List[indexSellerProfile].PSFValue,
                            commission: district3List[indexSellerProfile].commission,
                            address: district3List[indexSellerProfile].propertyAddress,
                            postalCode: district3List[indexSellerProfile].postalCode,
                            unitNumber: district3List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district3List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district3List[indexSellerProfile].uid), {
                    [`${user.uid}${district3List[indexSellerProfile].uid}${district3List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district3List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district3List[indexSellerProfile].sellingPrice,
                            PSFValue: district3List[indexSellerProfile].PSFValue,
                            commission: district3List[indexSellerProfile].commission,
                            address: district3List[indexSellerProfile].propertyAddress,
                            postalCode: district3List[indexSellerProfile].postalCode,
                            unitNumber: district3List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district3List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }




            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 4") {
            if (district4List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district4List[indexSellerProfile].uid}${district4List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district4List[indexSellerProfile].uid}${district4List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district4List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district4List[indexSellerProfile].uid}${district4List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district4List[indexSellerProfile].username,
                            photoURL: district4List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district4List[indexSellerProfile].sellingPrice,
                            PSFValue: district4List[indexSellerProfile].PSFValue,
                            commission: district4List[indexSellerProfile].commission,
                            address: district4List[indexSellerProfile].propertyAddress,
                            postalCode: district4List[indexSellerProfile].postalCode,
                            unitNumber: district4List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district4List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district4List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district4List[indexSellerProfile].uid}${district4List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district4List[indexSellerProfile].username,
                            photoURL: district4List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district4List[indexSellerProfile].sellingPrice,
                            PSFValue: district4List[indexSellerProfile].PSFValue,
                            commission: district4List[indexSellerProfile].commission,
                            address: district4List[indexSellerProfile].propertyAddress,
                            postalCode: district4List[indexSellerProfile].postalCode,
                            unitNumber: district4List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district4List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district4List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district4List[indexSellerProfile].uid), {
                    [`${user.uid}${district4List[indexSellerProfile].uid}${district4List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district4List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district4List[indexSellerProfile].sellingPrice,
                            PSFValue: district4List[indexSellerProfile].PSFValue,
                            commission: district4List[indexSellerProfile].commission,
                            address: district4List[indexSellerProfile].propertyAddress,
                            postalCode: district4List[indexSellerProfile].postalCode,
                            unitNumber: district4List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district4List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district4List[indexSellerProfile].uid), {
                    [`${user.uid}${district4List[indexSellerProfile].uid}${district4List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district4List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district4List[indexSellerProfile].sellingPrice,
                            PSFValue: district4List[indexSellerProfile].PSFValue,
                            commission: district4List[indexSellerProfile].commission,
                            address: district4List[indexSellerProfile].propertyAddress,
                            postalCode: district4List[indexSellerProfile].postalCode,
                            unitNumber: district4List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district4List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 5") {
            if (district5List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district5List[indexSellerProfile].uid}${district5List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district5List[indexSellerProfile].uid}${district5List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district5List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district5List[indexSellerProfile].uid}${district5List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district5List[indexSellerProfile].username,
                            photoURL: district5List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district5List[indexSellerProfile].sellingPrice,
                            PSFValue: district5List[indexSellerProfile].PSFValue,
                            commission: district5List[indexSellerProfile].commission,
                            address: district5List[indexSellerProfile].propertyAddress,
                            postalCode: district5List[indexSellerProfile].postalCode,
                            unitNumber: district5List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district5List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district5List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district5List[indexSellerProfile].uid}${district5List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district5List[indexSellerProfile].username,
                            photoURL: district5List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district5List[indexSellerProfile].sellingPrice,
                            PSFValue: district5List[indexSellerProfile].PSFValue,
                            commission: district5List[indexSellerProfile].commission,
                            address: district5List[indexSellerProfile].propertyAddress,
                            postalCode: district5List[indexSellerProfile].postalCode,
                            unitNumber: district5List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district5List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district5List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district5List[indexSellerProfile].uid), {
                    [`${user.uid}${district5List[indexSellerProfile].uid}${district5List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district5List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district5List[indexSellerProfile].sellingPrice,
                            PSFValue: district5List[indexSellerProfile].PSFValue,
                            commission: district5List[indexSellerProfile].commission,
                            address: district5List[indexSellerProfile].propertyAddress,
                            postalCode: district5List[indexSellerProfile].postalCode,
                            unitNumber: district5List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district5List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district5List[indexSellerProfile].uid), {
                    [`${user.uid}${district5List[indexSellerProfile].uid}${district5List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district5List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district5List[indexSellerProfile].sellingPrice,
                            PSFValue: district5List[indexSellerProfile].PSFValue,
                            commission: district5List[indexSellerProfile].commission,
                            address: district5List[indexSellerProfile].propertyAddress,
                            postalCode: district5List[indexSellerProfile].postalCode,
                            unitNumber: district5List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district5List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 6") {
            if (district6List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district6List[indexSellerProfile].uid}${district6List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district6List[indexSellerProfile].uid}${district6List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district6List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district6List[indexSellerProfile].uid}${district6List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district6List[indexSellerProfile].username,
                            photoURL: district6List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district6List[indexSellerProfile].sellingPrice,
                            PSFValue: district6List[indexSellerProfile].PSFValue,
                            commission: district6List[indexSellerProfile].commission,
                            address: district6List[indexSellerProfile].propertyAddress,
                            postalCode: district6List[indexSellerProfile].postalCode,
                            unitNumber: district6List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district6List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district6List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district6List[indexSellerProfile].uid}${district6List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district6List[indexSellerProfile].username,
                            photoURL: district6List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district6List[indexSellerProfile].sellingPrice,
                            PSFValue: district6List[indexSellerProfile].PSFValue,
                            commission: district6List[indexSellerProfile].commission,
                            address: district6List[indexSellerProfile].propertyAddress,
                            postalCode: district6List[indexSellerProfile].postalCode,
                            unitNumber: district6List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district6List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district6List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district6List[indexSellerProfile].uid), {
                    [`${user.uid}${district6List[indexSellerProfile].uid}${district6List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district6List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district6List[indexSellerProfile].sellingPrice,
                            PSFValue: district6List[indexSellerProfile].PSFValue,
                            commission: district6List[indexSellerProfile].commission,
                            address: district6List[indexSellerProfile].propertyAddress,
                            postalCode: district6List[indexSellerProfile].postalCode,
                            unitNumber: district6List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district6List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district6List[indexSellerProfile].uid), {
                    [`${user.uid}${district6List[indexSellerProfile].uid}${district6List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district6List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district6List[indexSellerProfile].sellingPrice,
                            PSFValue: district6List[indexSellerProfile].PSFValue,
                            commission: district6List[indexSellerProfile].commission,
                            address: district6List[indexSellerProfile].propertyAddress,
                            postalCode: district6List[indexSellerProfile].postalCode,
                            unitNumber: district6List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district6List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 7") {
            if (district7List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district7List[indexSellerProfile].uid}${district7List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district7List[indexSellerProfile].uid}${district7List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district7List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district7List[indexSellerProfile].uid}${district7List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district7List[indexSellerProfile].username,
                            photoURL: district7List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district7List[indexSellerProfile].sellingPrice,
                            PSFValue: district7List[indexSellerProfile].PSFValue,
                            commission: district7List[indexSellerProfile].commission,
                            address: district7List[indexSellerProfile].propertyAddress,
                            postalCode: district7List[indexSellerProfile].postalCode,
                            unitNumber: district7List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district7List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district7List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district7List[indexSellerProfile].uid}${district7List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district7List[indexSellerProfile].username,
                            photoURL: district7List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district7List[indexSellerProfile].sellingPrice,
                            PSFValue: district7List[indexSellerProfile].PSFValue,
                            commission: district7List[indexSellerProfile].commission,
                            address: district7List[indexSellerProfile].propertyAddress,
                            postalCode: district7List[indexSellerProfile].postalCode,
                            unitNumber: district7List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district7List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district7List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district7List[indexSellerProfile].uid), {
                    [`${user.uid}${district7List[indexSellerProfile].uid}${district7List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district7List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district7List[indexSellerProfile].sellingPrice,
                            PSFValue: district7List[indexSellerProfile].PSFValue,
                            commission: district7List[indexSellerProfile].commission,
                            address: district7List[indexSellerProfile].propertyAddress,
                            postalCode: district7List[indexSellerProfile].postalCode,
                            unitNumber: district7List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district7List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district7List[indexSellerProfile].uid), {
                    [`${user.uid}${district7List[indexSellerProfile].uid}${district7List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district7List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district7List[indexSellerProfile].sellingPrice,
                            PSFValue: district7List[indexSellerProfile].PSFValue,
                            commission: district7List[indexSellerProfile].commission,
                            address: district7List[indexSellerProfile].propertyAddress,
                            postalCode: district7List[indexSellerProfile].postalCode,
                            unitNumber: district7List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district7List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }




            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 8") {
            if (district8List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district8List[indexSellerProfile].uid}${district8List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district8List[indexSellerProfile].uid}${district8List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district8List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district8List[indexSellerProfile].uid}${district8List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district8List[indexSellerProfile].username,
                            photoURL: district8List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district8List[indexSellerProfile].sellingPrice,
                            PSFValue: district8List[indexSellerProfile].PSFValue,
                            commission: district8List[indexSellerProfile].commission,
                            address: district8List[indexSellerProfile].propertyAddress,
                            postalCode: district8List[indexSellerProfile].postalCode,
                            unitNumber: district8List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district8List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district8List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district8List[indexSellerProfile].uid}${district8List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district8List[indexSellerProfile].username,
                            photoURL: district8List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district8List[indexSellerProfile].sellingPrice,
                            PSFValue: district8List[indexSellerProfile].PSFValue,
                            commission: district8List[indexSellerProfile].commission,
                            address: district8List[indexSellerProfile].propertyAddress,
                            postalCode: district8List[indexSellerProfile].postalCode,
                            unitNumber: district8List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district8List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district8List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district8List[indexSellerProfile].uid), {
                    [`${user.uid}${district8List[indexSellerProfile].uid}${district8List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district8List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district8List[indexSellerProfile].sellingPrice,
                            PSFValue: district8List[indexSellerProfile].PSFValue,
                            commission: district8List[indexSellerProfile].commission,
                            address: district8List[indexSellerProfile].propertyAddress,
                            postalCode: district8List[indexSellerProfile].postalCode,
                            unitNumber: district8List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district8List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district8List[indexSellerProfile].uid), {
                    [`${user.uid}${district8List[indexSellerProfile].uid}${district8List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district8List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district8List[indexSellerProfile].sellingPrice,
                            PSFValue: district8List[indexSellerProfile].PSFValue,
                            commission: district8List[indexSellerProfile].commission,
                            address: district8List[indexSellerProfile].propertyAddress,
                            postalCode: district8List[indexSellerProfile].postalCode,
                            unitNumber: district8List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district8List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }


            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 9") {
            if (district9List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district9List[indexSellerProfile].uid}${district9List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district9List[indexSellerProfile].uid}${district9List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district9List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district9List[indexSellerProfile].uid}${district9List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district9List[indexSellerProfile].username,
                            photoURL: district9List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district9List[indexSellerProfile].sellingPrice,
                            PSFValue: district9List[indexSellerProfile].PSFValue,
                            commission: district9List[indexSellerProfile].commission,
                            address: district9List[indexSellerProfile].propertyAddress,
                            postalCode: district9List[indexSellerProfile].postalCode,
                            unitNumber: district9List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district9List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district9List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district9List[indexSellerProfile].uid}${district9List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district9List[indexSellerProfile].username,
                            photoURL: district9List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district9List[indexSellerProfile].sellingPrice,
                            PSFValue: district9List[indexSellerProfile].PSFValue,
                            commission: district9List[indexSellerProfile].commission,
                            address: district9List[indexSellerProfile].propertyAddress,
                            postalCode: district9List[indexSellerProfile].postalCode,
                            unitNumber: district9List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district9List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district9List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district9List[indexSellerProfile].uid), {
                    [`${user.uid}${district9List[indexSellerProfile].uid}${district9List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district9List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district9List[indexSellerProfile].sellingPrice,
                            PSFValue: district9List[indexSellerProfile].PSFValue,
                            commission: district9List[indexSellerProfile].commission,
                            address: district9List[indexSellerProfile].propertyAddress,
                            postalCode: district9List[indexSellerProfile].postalCode,
                            unitNumber: district9List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district9List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district9List[indexSellerProfile].uid), {
                    [`${user.uid}${district9List[indexSellerProfile].uid}${district9List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district9List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district9List[indexSellerProfile].sellingPrice,
                            PSFValue: district9List[indexSellerProfile].PSFValue,
                            commission: district9List[indexSellerProfile].commission,
                            address: district9List[indexSellerProfile].propertyAddress,
                            postalCode: district9List[indexSellerProfile].postalCode,
                            unitNumber: district9List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district9List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }




            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 10") {
            if (district10List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district10List[indexSellerProfile].uid}${district10List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district10List[indexSellerProfile].uid}${district10List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district10List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district10List[indexSellerProfile].uid}${district10List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district10List[indexSellerProfile].username,
                            photoURL: district10List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district10List[indexSellerProfile].sellingPrice,
                            PSFValue: district10List[indexSellerProfile].PSFValue,
                            commission: district10List[indexSellerProfile].commission,
                            address: district10List[indexSellerProfile].propertyAddress,
                            postalCode: district10List[indexSellerProfile].postalCode,
                            unitNumber: district10List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district10List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district10List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district10List[indexSellerProfile].uid}${district10List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district10List[indexSellerProfile].username,
                            photoURL: district10List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district10List[indexSellerProfile].sellingPrice,
                            PSFValue: district10List[indexSellerProfile].PSFValue,
                            commission: district10List[indexSellerProfile].commission,
                            address: district10List[indexSellerProfile].propertyAddress,
                            postalCode: district10List[indexSellerProfile].postalCode,
                            unitNumber: district10List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district10List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district10List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district10List[indexSellerProfile].uid), {
                    [`${user.uid}${district10List[indexSellerProfile].uid}${district10List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district10List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district10List[indexSellerProfile].sellingPrice,
                            PSFValue: district10List[indexSellerProfile].PSFValue,
                            commission: district10List[indexSellerProfile].commission,
                            address: district10List[indexSellerProfile].propertyAddress,
                            postalCode: district10List[indexSellerProfile].postalCode,
                            unitNumber: district10List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district10List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district10List[indexSellerProfile].uid), {
                    [`${user.uid}${district10List[indexSellerProfile].uid}${district10List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district10List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district10List[indexSellerProfile].sellingPrice,
                            PSFValue: district10List[indexSellerProfile].PSFValue,
                            commission: district10List[indexSellerProfile].commission,
                            address: district10List[indexSellerProfile].propertyAddress,
                            postalCode: district10List[indexSellerProfile].postalCode,
                            unitNumber: district10List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district10List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }




            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 11") {
            if (district11List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district11List[indexSellerProfile].uid}${district11List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district11List[indexSellerProfile].uid}${district11List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district11List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district11List[indexSellerProfile].uid}${district11List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district11List[indexSellerProfile].username,
                            photoURL: district11List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district11List[indexSellerProfile].sellingPrice,
                            PSFValue: district11List[indexSellerProfile].PSFValue,
                            commission: district11List[indexSellerProfile].commission,
                            address: district11List[indexSellerProfile].propertyAddress,
                            postalCode: district11List[indexSellerProfile].postalCode,
                            unitNumber: district11List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district11List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district11List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district11List[indexSellerProfile].uid}${district11List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district11List[indexSellerProfile].username,
                            photoURL: district11List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district11List[indexSellerProfile].sellingPrice,
                            PSFValue: district11List[indexSellerProfile].PSFValue,
                            commission: district11List[indexSellerProfile].commission,
                            address: district11List[indexSellerProfile].propertyAddress,
                            postalCode: district11List[indexSellerProfile].postalCode,
                            unitNumber: district11List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district11List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district11List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district11List[indexSellerProfile].uid), {
                    [`${user.uid}${district11List[indexSellerProfile].uid}${district11List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district11List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district11List[indexSellerProfile].sellingPrice,
                            PSFValue: district11List[indexSellerProfile].PSFValue,
                            commission: district11List[indexSellerProfile].commission,
                            address: district11List[indexSellerProfile].propertyAddress,
                            postalCode: district11List[indexSellerProfile].postalCode,
                            unitNumber: district11List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district11List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district11List[indexSellerProfile].uid), {
                    [`${user.uid}${district11List[indexSellerProfile].uid}${district11List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district11List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district11List[indexSellerProfile].sellingPrice,
                            PSFValue: district11List[indexSellerProfile].PSFValue,
                            commission: district11List[indexSellerProfile].commission,
                            address: district11List[indexSellerProfile].propertyAddress,
                            postalCode: district11List[indexSellerProfile].postalCode,
                            unitNumber: district11List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district11List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }




            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 12") {
            if (district12List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district12List[indexSellerProfile].uid}${district12List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district12List[indexSellerProfile].uid}${district12List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district12List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district12List[indexSellerProfile].uid}${district12List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district12List[indexSellerProfile].username,
                            photoURL: district12List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district12List[indexSellerProfile].sellingPrice,
                            PSFValue: district12List[indexSellerProfile].PSFValue,
                            commission: district12List[indexSellerProfile].commission,
                            address: district12List[indexSellerProfile].propertyAddress,
                            postalCode: district12List[indexSellerProfile].postalCode,
                            unitNumber: district12List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district12List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district12List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district12List[indexSellerProfile].uid}${district12List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district12List[indexSellerProfile].username,
                            photoURL: district12List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district12List[indexSellerProfile].sellingPrice,
                            PSFValue: district12List[indexSellerProfile].PSFValue,
                            commission: district12List[indexSellerProfile].commission,
                            address: district12List[indexSellerProfile].propertyAddress,
                            postalCode: district12List[indexSellerProfile].postalCode,
                            unitNumber: district12List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district12List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district12List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district12List[indexSellerProfile].uid), {
                    [`${user.uid}${district12List[indexSellerProfile].uid}${district12List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district12List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district12List[indexSellerProfile].sellingPrice,
                            PSFValue: district12List[indexSellerProfile].PSFValue,
                            commission: district12List[indexSellerProfile].commission,
                            address: district12List[indexSellerProfile].propertyAddress,
                            postalCode: district12List[indexSellerProfile].postalCode,
                            unitNumber: district12List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district12List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district12List[indexSellerProfile].uid), {
                    [`${user.uid}${district12List[indexSellerProfile].uid}${district12List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district12List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district12List[indexSellerProfile].sellingPrice,
                            PSFValue: district12List[indexSellerProfile].PSFValue,
                            commission: district12List[indexSellerProfile].commission,
                            address: district12List[indexSellerProfile].propertyAddress,
                            postalCode: district12List[indexSellerProfile].postalCode,
                            unitNumber: district12List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district12List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }


            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 13") {
            if (district13List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district13List[indexSellerProfile].uid}${district13List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district13List[indexSellerProfile].uid}${district13List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district13List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district13List[indexSellerProfile].uid}${district13List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district13List[indexSellerProfile].username,
                            photoURL: district13List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district13List[indexSellerProfile].sellingPrice,
                            PSFValue: district13List[indexSellerProfile].PSFValue,
                            commission: district13List[indexSellerProfile].commission,
                            address: district13List[indexSellerProfile].propertyAddress,
                            postalCode: district13List[indexSellerProfile].postalCode,
                            unitNumber: district13List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district13List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district13List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district13List[indexSellerProfile].uid}${district13List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district13List[indexSellerProfile].username,
                            photoURL: district13List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district13List[indexSellerProfile].sellingPrice,
                            PSFValue: district13List[indexSellerProfile].PSFValue,
                            commission: district13List[indexSellerProfile].commission,
                            address: district13List[indexSellerProfile].propertyAddress,
                            postalCode: district13List[indexSellerProfile].postalCode,
                            unitNumber: district13List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district13List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district13List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district13List[indexSellerProfile].uid), {
                    [`${user.uid}${district13List[indexSellerProfile].uid}${district13List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district13List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district13List[indexSellerProfile].sellingPrice,
                            PSFValue: district13List[indexSellerProfile].PSFValue,
                            commission: district13List[indexSellerProfile].commission,
                            address: district13List[indexSellerProfile].propertyAddress,
                            postalCode: district13List[indexSellerProfile].postalCode,
                            unitNumber: district13List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district13List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district13List[indexSellerProfile].uid), {
                    [`${user.uid}${district13List[indexSellerProfile].uid}${district13List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district13List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district13List[indexSellerProfile].sellingPrice,
                            PSFValue: district13List[indexSellerProfile].PSFValue,
                            commission: district13List[indexSellerProfile].commission,
                            address: district13List[indexSellerProfile].propertyAddress,
                            postalCode: district13List[indexSellerProfile].postalCode,
                            unitNumber: district13List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district13List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }




            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 14") {
            if (district14List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district14List[indexSellerProfile].uid}${district14List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district14List[indexSellerProfile].uid}${district14List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district14List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district14List[indexSellerProfile].uid}${district14List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district14List[indexSellerProfile].username,
                            photoURL: district14List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district14List[indexSellerProfile].sellingPrice,
                            PSFValue: district14List[indexSellerProfile].PSFValue,
                            commission: district14List[indexSellerProfile].commission,
                            address: district14List[indexSellerProfile].propertyAddress,
                            postalCode: district14List[indexSellerProfile].postalCode,
                            unitNumber: district14List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district14List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district14List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district14List[indexSellerProfile].uid}${district14List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district14List[indexSellerProfile].username,
                            photoURL: district14List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district14List[indexSellerProfile].sellingPrice,
                            PSFValue: district14List[indexSellerProfile].PSFValue,
                            commission: district14List[indexSellerProfile].commission,
                            address: district14List[indexSellerProfile].propertyAddress,
                            postalCode: district14List[indexSellerProfile].postalCode,
                            unitNumber: district14List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district14List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district14List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district14List[indexSellerProfile].uid), {
                    [`${user.uid}${district14List[indexSellerProfile].uid}${district14List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district14List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district14List[indexSellerProfile].sellingPrice,
                            PSFValue: district14List[indexSellerProfile].PSFValue,
                            commission: district14List[indexSellerProfile].commission,
                            address: district14List[indexSellerProfile].propertyAddress,
                            postalCode: district14List[indexSellerProfile].postalCode,
                            unitNumber: district14List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district14List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district14List[indexSellerProfile].uid), {
                    [`${user.uid}${district14List[indexSellerProfile].uid}${district14List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district14List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district14List[indexSellerProfile].sellingPrice,
                            PSFValue: district14List[indexSellerProfile].PSFValue,
                            commission: district14List[indexSellerProfile].commission,
                            address: district14List[indexSellerProfile].propertyAddress,
                            postalCode: district14List[indexSellerProfile].postalCode,
                            unitNumber: district14List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district14List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }




            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 15") {
            if (district15List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district15List[indexSellerProfile].uid}${district15List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district15List[indexSellerProfile].uid}${district15List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district15List[indexSellerProfile].uid));
            if (res_userChat._document == null) {
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district15List[indexSellerProfile].uid}${district15List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district15List[indexSellerProfile].username,
                            photoURL: district15List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district15List[indexSellerProfile].sellingPrice,
                            PSFValue: district15List[indexSellerProfile].PSFValue,
                            commission: district15List[indexSellerProfile].commission,
                            address: district15List[indexSellerProfile].propertyAddress,
                            postalCode: district15List[indexSellerProfile].postalCode,
                            unitNumber: district15List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district15List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district15List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            else {
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district15List[indexSellerProfile].uid}${district15List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district15List[indexSellerProfile].username,
                            photoURL: district15List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district15List[indexSellerProfile].sellingPrice,
                            PSFValue: district15List[indexSellerProfile].PSFValue,
                            commission: district15List[indexSellerProfile].commission,
                            address: district15List[indexSellerProfile].propertyAddress,
                            postalCode: district15List[indexSellerProfile].postalCode,
                            unitNumber: district15List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district15List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district15List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }

                })
            }
            if (opp_userChat._document == null) {
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district15List[indexSellerProfile].uid), {
                    [`${user.uid}${district15List[indexSellerProfile].uid}${district15List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district15List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district15List[indexSellerProfile].sellingPrice,
                            PSFValue: district15List[indexSellerProfile].PSFValue,
                            commission: district15List[indexSellerProfile].commission,
                            address: district15List[indexSellerProfile].propertyAddress,
                            postalCode: district15List[indexSellerProfile].postalCode,
                            unitNumber: district15List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district15List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else {
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district15List[indexSellerProfile].uid), {
                    [`${user.uid}${district15List[indexSellerProfile].uid}${district15List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district15List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district15List[indexSellerProfile].sellingPrice,
                            PSFValue: district15List[indexSellerProfile].PSFValue,
                            commission: district15List[indexSellerProfile].commission,
                            address: district15List[indexSellerProfile].propertyAddress,
                            postalCode: district15List[indexSellerProfile].postalCode,
                            unitNumber: district15List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district15List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }




            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 16") {
            if (district16List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district16List[indexSellerProfile].uid}${district16List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district16List[indexSellerProfile].uid}${district16List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district16List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district16List[indexSellerProfile].uid}${district16List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district16List[indexSellerProfile].username,
                            photoURL: district16List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district16List[indexSellerProfile].sellingPrice,
                            PSFValue: district16List[indexSellerProfile].PSFValue,
                            commission: district16List[indexSellerProfile].commission,
                            address: district16List[indexSellerProfile].propertyAddress,
                            postalCode: district16List[indexSellerProfile].postalCode,
                            unitNumber: district16List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district16List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district16List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district16List[indexSellerProfile].uid}${district16List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district16List[indexSellerProfile].username,
                            photoURL: district16List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district16List[indexSellerProfile].sellingPrice,
                            PSFValue: district16List[indexSellerProfile].PSFValue,
                            commission: district16List[indexSellerProfile].commission,
                            address: district16List[indexSellerProfile].propertyAddress,
                            postalCode: district16List[indexSellerProfile].postalCode,
                            unitNumber: district16List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district16List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district16List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district16List[indexSellerProfile].uid), {
                    [`${user.uid}${district16List[indexSellerProfile].uid}${district16List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district16List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district16List[indexSellerProfile].sellingPrice,
                            PSFValue: district16List[indexSellerProfile].PSFValue,
                            commission: district16List[indexSellerProfile].commission,
                            address: district16List[indexSellerProfile].propertyAddress,
                            postalCode: district16List[indexSellerProfile].postalCode,
                            unitNumber: district16List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district16List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district16List[indexSellerProfile].uid), {
                    [`${user.uid}${district16List[indexSellerProfile].uid}${district16List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district16List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district16List[indexSellerProfile].sellingPrice,
                            PSFValue: district16List[indexSellerProfile].PSFValue,
                            commission: district16List[indexSellerProfile].commission,
                            address: district16List[indexSellerProfile].propertyAddress,
                            postalCode: district16List[indexSellerProfile].postalCode,
                            unitNumber: district16List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district16List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 17") {
            if (district17List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district17List[indexSellerProfile].uid}${district17List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district17List[indexSellerProfile].uid}${district17List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district17List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district17List[indexSellerProfile].uid}${district17List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district17List[indexSellerProfile].username,
                            photoURL: district17List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district17List[indexSellerProfile].sellingPrice,
                            PSFValue: district17List[indexSellerProfile].PSFValue,
                            commission: district17List[indexSellerProfile].commission,
                            address: district17List[indexSellerProfile].propertyAddress,
                            postalCode: district17List[indexSellerProfile].postalCode,
                            unitNumber: district17List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district17List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district17List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district17List[indexSellerProfile].uid}${district17List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district17List[indexSellerProfile].username,
                            photoURL: district17List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district17List[indexSellerProfile].sellingPrice,
                            PSFValue: district17List[indexSellerProfile].PSFValue,
                            commission: district17List[indexSellerProfile].commission,
                            address: district17List[indexSellerProfile].propertyAddress,
                            postalCode: district17List[indexSellerProfile].postalCode,
                            unitNumber: district17List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district17List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district17List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district17List[indexSellerProfile].uid), {
                    [`${user.uid}${district17List[indexSellerProfile].uid}${district17List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district17List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district17List[indexSellerProfile].sellingPrice,
                            PSFValue: district17List[indexSellerProfile].PSFValue,
                            commission: district17List[indexSellerProfile].commission,
                            address: district17List[indexSellerProfile].propertyAddress,
                            postalCode: district17List[indexSellerProfile].postalCode,
                            unitNumber: district17List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district17List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district17List[indexSellerProfile].uid), {
                    [`${user.uid}${district17List[indexSellerProfile].uid}${district17List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district17List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district17List[indexSellerProfile].sellingPrice,
                            PSFValue: district17List[indexSellerProfile].PSFValue,
                            commission: district17List[indexSellerProfile].commission,
                            address: district17List[indexSellerProfile].propertyAddress,
                            postalCode: district17List[indexSellerProfile].postalCode,
                            unitNumber: district17List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district17List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 18") {
            if (district18List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district18List[indexSellerProfile].uid}${district18List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district18List[indexSellerProfile].uid}${district18List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district18List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district18List[indexSellerProfile].uid}${district18List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district18List[indexSellerProfile].username,
                            photoURL: district18List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district18List[indexSellerProfile].sellingPrice,
                            PSFValue: district18List[indexSellerProfile].PSFValue,
                            commission: district18List[indexSellerProfile].commission,
                            address: district18List[indexSellerProfile].propertyAddress,
                            postalCode: district18List[indexSellerProfile].postalCode,
                            unitNumber: district18List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district18List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district18List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district18List[indexSellerProfile].uid}${district18List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district18List[indexSellerProfile].username,
                            photoURL: district18List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district18List[indexSellerProfile].sellingPrice,
                            PSFValue: district18List[indexSellerProfile].PSFValue,
                            commission: district18List[indexSellerProfile].commission,
                            address: district18List[indexSellerProfile].propertyAddress,
                            postalCode: district18List[indexSellerProfile].postalCode,
                            unitNumber: district18List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district18List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district18List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district18List[indexSellerProfile].uid), {
                    [`${user.uid}${district18List[indexSellerProfile].uid}${district18List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district18List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district18List[indexSellerProfile].sellingPrice,
                            PSFValue: district18List[indexSellerProfile].PSFValue,
                            commission: district18List[indexSellerProfile].commission,
                            address: district18List[indexSellerProfile].propertyAddress,
                            postalCode: district18List[indexSellerProfile].postalCode,
                            unitNumber: district18List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district18List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district18List[indexSellerProfile].uid), {
                    [`${user.uid}${district18List[indexSellerProfile].uid}${district18List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district18List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district18List[indexSellerProfile].sellingPrice,
                            PSFValue: district18List[indexSellerProfile].PSFValue,
                            commission: district18List[indexSellerProfile].commission,
                            address: district18List[indexSellerProfile].propertyAddress,
                            postalCode: district18List[indexSellerProfile].postalCode,
                            unitNumber: district18List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district18List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 19") {
            if (district19List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district19List[indexSellerProfile].uid}${district19List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district19List[indexSellerProfile].uid}${district19List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district19List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district19List[indexSellerProfile].uid}${district19List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district19List[indexSellerProfile].username,
                            photoURL: district19List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district19List[indexSellerProfile].sellingPrice,
                            PSFValue: district19List[indexSellerProfile].PSFValue,
                            commission: district19List[indexSellerProfile].commission,
                            address: district19List[indexSellerProfile].propertyAddress,
                            postalCode: district19List[indexSellerProfile].postalCode,
                            unitNumber: district19List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district19List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district19List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district19List[indexSellerProfile].uid}${district19List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district19List[indexSellerProfile].username,
                            photoURL: district19List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district19List[indexSellerProfile].sellingPrice,
                            PSFValue: district19List[indexSellerProfile].PSFValue,
                            commission: district19List[indexSellerProfile].commission,
                            address: district19List[indexSellerProfile].propertyAddress,
                            postalCode: district19List[indexSellerProfile].postalCode,
                            unitNumber: district19List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district19List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district19List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district19List[indexSellerProfile].uid), {
                    [`${user.uid}${district19List[indexSellerProfile].uid}${district19List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district19List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district19List[indexSellerProfile].sellingPrice,
                            PSFValue: district19List[indexSellerProfile].PSFValue,
                            commission: district19List[indexSellerProfile].commission,
                            address: district19List[indexSellerProfile].propertyAddress,
                            postalCode: district19List[indexSellerProfile].postalCode,
                            unitNumber: district19List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district19List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district19List[indexSellerProfile].uid), {
                    [`${user.uid}${district19List[indexSellerProfile].uid}${district19List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district19List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district19List[indexSellerProfile].sellingPrice,
                            PSFValue: district19List[indexSellerProfile].PSFValue,
                            commission: district19List[indexSellerProfile].commission,
                            address: district19List[indexSellerProfile].propertyAddress,
                            postalCode: district19List[indexSellerProfile].postalCode,
                            unitNumber: district19List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district19List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 20") {
            if (district20List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district20List[indexSellerProfile].uid}${district20List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district20List[indexSellerProfile].uid}${district20List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district20List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district20List[indexSellerProfile].uid}${district20List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district20List[indexSellerProfile].username,
                            photoURL: district20List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district20List[indexSellerProfile].sellingPrice,
                            PSFValue: district20List[indexSellerProfile].PSFValue,
                            commission: district20List[indexSellerProfile].commission,
                            address: district20List[indexSellerProfile].propertyAddress,
                            postalCode: district20List[indexSellerProfile].postalCode,
                            unitNumber: district20List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district20List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district20List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district20List[indexSellerProfile].uid}${district20List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district20List[indexSellerProfile].username,
                            photoURL: district20List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district20List[indexSellerProfile].sellingPrice,
                            PSFValue: district20List[indexSellerProfile].PSFValue,
                            commission: district20List[indexSellerProfile].commission,
                            address: district20List[indexSellerProfile].propertyAddress,
                            postalCode: district20List[indexSellerProfile].postalCode,
                            unitNumber: district20List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district20List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district20List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district20List[indexSellerProfile].uid), {
                    [`${user.uid}${district20List[indexSellerProfile].uid}${district20List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district20List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district20List[indexSellerProfile].sellingPrice,
                            PSFValue: district20List[indexSellerProfile].PSFValue,
                            commission: district20List[indexSellerProfile].commission,
                            address: district20List[indexSellerProfile].propertyAddress,
                            postalCode: district20List[indexSellerProfile].postalCode,
                            unitNumber: district20List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district20List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district20List[indexSellerProfile].uid), {
                    [`${user.uid}${district20List[indexSellerProfile].uid}${district20List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district20List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district20List[indexSellerProfile].sellingPrice,
                            PSFValue: district20List[indexSellerProfile].PSFValue,
                            commission: district20List[indexSellerProfile].commission,
                            address: district20List[indexSellerProfile].propertyAddress,
                            postalCode: district20List[indexSellerProfile].postalCode,
                            unitNumber: district20List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district20List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 21") {
            if (district21List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district21List[indexSellerProfile].uid}${district21List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district21List[indexSellerProfile].uid}${district21List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district21List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district21List[indexSellerProfile].uid}${district21List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district21List[indexSellerProfile].username,
                            photoURL: district21List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district21List[indexSellerProfile].sellingPrice,
                            PSFValue: district21List[indexSellerProfile].PSFValue,
                            commission: district21List[indexSellerProfile].commission,
                            address: district21List[indexSellerProfile].propertyAddress,
                            postalCode: district21List[indexSellerProfile].postalCode,
                            unitNumber: district21List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district21List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district21List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district21List[indexSellerProfile].uid}${district21List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district21List[indexSellerProfile].username,
                            photoURL: district21List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district21List[indexSellerProfile].sellingPrice,
                            PSFValue: district21List[indexSellerProfile].PSFValue,
                            commission: district21List[indexSellerProfile].commission,
                            address: district21List[indexSellerProfile].propertyAddress,
                            postalCode: district21List[indexSellerProfile].postalCode,
                            unitNumber: district21List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district21List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district21List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district21List[indexSellerProfile].uid), {
                    [`${user.uid}${district21List[indexSellerProfile].uid}${district21List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district21List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district21List[indexSellerProfile].sellingPrice,
                            PSFValue: district21List[indexSellerProfile].PSFValue,
                            commission: district21List[indexSellerProfile].commission,
                            address: district21List[indexSellerProfile].propertyAddress,
                            postalCode: district21List[indexSellerProfile].postalCode,
                            unitNumber: district21List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district21List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district21List[indexSellerProfile].uid), {
                    [`${user.uid}${district21List[indexSellerProfile].uid}${district21List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district21List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district21List[indexSellerProfile].sellingPrice,
                            PSFValue: district21List[indexSellerProfile].PSFValue,
                            commission: district21List[indexSellerProfile].commission,
                            address: district21List[indexSellerProfile].propertyAddress,
                            postalCode: district21List[indexSellerProfile].postalCode,
                            unitNumber: district21List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district21List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 22") {
            if (district22List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district22List[indexSellerProfile].uid}${district22List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district22List[indexSellerProfile].uid}${district22List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district22List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district22List[indexSellerProfile].uid}${district22List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district22List[indexSellerProfile].username,
                            photoURL: district22List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district22List[indexSellerProfile].sellingPrice,
                            PSFValue: district22List[indexSellerProfile].PSFValue,
                            commission: district22List[indexSellerProfile].commission,
                            address: district22List[indexSellerProfile].propertyAddress,
                            postalCode: district22List[indexSellerProfile].postalCode,
                            unitNumber: district22List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district22List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district22List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district22List[indexSellerProfile].uid}${district22List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district22List[indexSellerProfile].username,
                            photoURL: district22List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district22List[indexSellerProfile].sellingPrice,
                            PSFValue: district22List[indexSellerProfile].PSFValue,
                            commission: district22List[indexSellerProfile].commission,
                            address: district22List[indexSellerProfile].propertyAddress,
                            postalCode: district22List[indexSellerProfile].postalCode,
                            unitNumber: district22List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district22List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district22List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district22List[indexSellerProfile].uid), {
                    [`${user.uid}${district22List[indexSellerProfile].uid}${district22List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district22List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district22List[indexSellerProfile].sellingPrice,
                            PSFValue: district22List[indexSellerProfile].PSFValue,
                            commission: district22List[indexSellerProfile].commission,
                            address: district22List[indexSellerProfile].propertyAddress,
                            postalCode: district22List[indexSellerProfile].postalCode,
                            unitNumber: district22List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district22List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district22List[indexSellerProfile].uid), {
                    [`${user.uid}${district22List[indexSellerProfile].uid}${district22List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district22List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district22List[indexSellerProfile].sellingPrice,
                            PSFValue: district22List[indexSellerProfile].PSFValue,
                            commission: district22List[indexSellerProfile].commission,
                            address: district22List[indexSellerProfile].propertyAddress,
                            postalCode: district22List[indexSellerProfile].postalCode,
                            unitNumber: district22List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district22List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 23") {
            console.log(district23List, indexSellerProfile);
            if (district23List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district23List[indexSellerProfile].uid}${district23List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district23List[indexSellerProfile].uid}${district23List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district23List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district23List[indexSellerProfile].uid}${district23List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district23List[indexSellerProfile].username,
                            photoURL: district23List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district23List[indexSellerProfile].sellingPrice,
                            PSFValue: district23List[indexSellerProfile].PSFValue,
                            commission: district23List[indexSellerProfile].commission,
                            address: district23List[indexSellerProfile].propertyAddress,
                            postalCode: district23List[indexSellerProfile].postalCode,
                            unitNumber: district23List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district23List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district23List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district23List[indexSellerProfile].uid}${district23List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district23List[indexSellerProfile].username,
                            photoURL: district23List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district23List[indexSellerProfile].sellingPrice,
                            PSFValue: district23List[indexSellerProfile].PSFValue,
                            commission: district23List[indexSellerProfile].commission,
                            address: district23List[indexSellerProfile].propertyAddress,
                            postalCode: district23List[indexSellerProfile].postalCode,
                            unitNumber: district23List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district23List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district23List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district23List[indexSellerProfile].uid), {
                    [`${user.uid}${district23List[indexSellerProfile].uid}${district23List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district23List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district23List[indexSellerProfile].sellingPrice,
                            PSFValue: district23List[indexSellerProfile].PSFValue,
                            commission: district23List[indexSellerProfile].commission,
                            address: district23List[indexSellerProfile].propertyAddress,
                            postalCode: district23List[indexSellerProfile].postalCode,
                            unitNumber: district23List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district23List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district23List[indexSellerProfile].uid), {
                    [`${user.uid}${district23List[indexSellerProfile].uid}${district23List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district23List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district23List[indexSellerProfile].sellingPrice,
                            PSFValue: district23List[indexSellerProfile].PSFValue,
                            commission: district23List[indexSellerProfile].commission,
                            address: district23List[indexSellerProfile].propertyAddress,
                            postalCode: district23List[indexSellerProfile].postalCode,
                            unitNumber: district23List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district23List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 24") {
            if (district24List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district24List[indexSellerProfile].uid}${district24List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district24List[indexSellerProfile].uid}${district24List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district24List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district24List[indexSellerProfile].uid}${district24List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district24List[indexSellerProfile].username,
                            photoURL: district24List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district24List[indexSellerProfile].sellingPrice,
                            PSFValue: district24List[indexSellerProfile].PSFValue,
                            commission: district24List[indexSellerProfile].commission,
                            address: district24List[indexSellerProfile].propertyAddress,
                            postalCode: district24List[indexSellerProfile].postalCode,
                            unitNumber: district24List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district24List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district24List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district24List[indexSellerProfile].uid}${district24List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district24List[indexSellerProfile].username,
                            photoURL: district24List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district24List[indexSellerProfile].sellingPrice,
                            PSFValue: district24List[indexSellerProfile].PSFValue,
                            commission: district24List[indexSellerProfile].commission,
                            address: district24List[indexSellerProfile].propertyAddress,
                            postalCode: district24List[indexSellerProfile].postalCode,
                            unitNumber: district24List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district24List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district24List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district24List[indexSellerProfile].uid), {
                    [`${user.uid}${district24List[indexSellerProfile].uid}${district24List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district24List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district24List[indexSellerProfile].sellingPrice,
                            PSFValue: district24List[indexSellerProfile].PSFValue,
                            commission: district24List[indexSellerProfile].commission,
                            address: district24List[indexSellerProfile].propertyAddress,
                            postalCode: district24List[indexSellerProfile].postalCode,
                            unitNumber: district24List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district24List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district24List[indexSellerProfile].uid), {
                    [`${user.uid}${district24List[indexSellerProfile].uid}${district24List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district24List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district24List[indexSellerProfile].sellingPrice,
                            PSFValue: district24List[indexSellerProfile].PSFValue,
                            commission: district24List[indexSellerProfile].commission,
                            address: district24List[indexSellerProfile].propertyAddress,
                            postalCode: district24List[indexSellerProfile].postalCode,
                            unitNumber: district24List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district24List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 25") {
            if (district25List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district25List[indexSellerProfile].uid}${district25List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district25List[indexSellerProfile].uid}${district25List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district25List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district25List[indexSellerProfile].uid}${district25List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district25List[indexSellerProfile].username,
                            photoURL: district25List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district25List[indexSellerProfile].sellingPrice,
                            PSFValue: district25List[indexSellerProfile].PSFValue,
                            commission: district25List[indexSellerProfile].commission,
                            address: district25List[indexSellerProfile].propertyAddress,
                            postalCode: district25List[indexSellerProfile].postalCode,
                            unitNumber: district25List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district25List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district25List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district25List[indexSellerProfile].uid}${district25List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district25List[indexSellerProfile].username,
                            photoURL: district25List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district25List[indexSellerProfile].sellingPrice,
                            PSFValue: district25List[indexSellerProfile].PSFValue,
                            commission: district25List[indexSellerProfile].commission,
                            address: district25List[indexSellerProfile].propertyAddress,
                            postalCode: district25List[indexSellerProfile].postalCode,
                            unitNumber: district25List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district25List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district25List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district25List[indexSellerProfile].uid), {
                    [`${user.uid}${district25List[indexSellerProfile].uid}${district25List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district25List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district25List[indexSellerProfile].sellingPrice,
                            PSFValue: district25List[indexSellerProfile].PSFValue,
                            commission: district25List[indexSellerProfile].commission,
                            address: district25List[indexSellerProfile].propertyAddress,
                            postalCode: district25List[indexSellerProfile].postalCode,
                            unitNumber: district25List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district25List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district25List[indexSellerProfile].uid), {
                    [`${user.uid}${district25List[indexSellerProfile].uid}${district25List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district25List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district25List[indexSellerProfile].sellingPrice,
                            PSFValue: district25List[indexSellerProfile].PSFValue,
                            commission: district25List[indexSellerProfile].commission,
                            address: district25List[indexSellerProfile].propertyAddress,
                            postalCode: district25List[indexSellerProfile].postalCode,
                            unitNumber: district25List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district25List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 26") {
            if (district26List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district26List[indexSellerProfile].uid}${district26List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district26List[indexSellerProfile].uid}${district26List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district26List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district26List[indexSellerProfile].uid}${district26List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district26List[indexSellerProfile].username,
                            photoURL: district26List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district26List[indexSellerProfile].sellingPrice,
                            PSFValue: district26List[indexSellerProfile].PSFValue,
                            commission: district26List[indexSellerProfile].commission,
                            address: district26List[indexSellerProfile].propertyAddress,
                            postalCode: district26List[indexSellerProfile].postalCode,
                            unitNumber: district26List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district26List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district26List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district26List[indexSellerProfile].uid}${district26List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district26List[indexSellerProfile].username,
                            photoURL: district26List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district26List[indexSellerProfile].sellingPrice,
                            PSFValue: district26List[indexSellerProfile].PSFValue,
                            commission: district26List[indexSellerProfile].commission,
                            address: district26List[indexSellerProfile].propertyAddress,
                            postalCode: district26List[indexSellerProfile].postalCode,
                            unitNumber: district26List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district26List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district26List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }

            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district26List[indexSellerProfile].uid), {
                    [`${user.uid}${district26List[indexSellerProfile].uid}${district26List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district26List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district26List[indexSellerProfile].sellingPrice,
                            PSFValue: district26List[indexSellerProfile].PSFValue,
                            commission: district26List[indexSellerProfile].commission,
                            address: district26List[indexSellerProfile].propertyAddress,
                            postalCode: district26List[indexSellerProfile].postalCode,
                            unitNumber: district26List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district26List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district26List[indexSellerProfile].uid), {
                    [`${user.uid}${district26List[indexSellerProfile].uid}${district26List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district26List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district26List[indexSellerProfile].sellingPrice,
                            PSFValue: district26List[indexSellerProfile].PSFValue,
                            commission: district26List[indexSellerProfile].commission,
                            address: district26List[indexSellerProfile].propertyAddress,
                            postalCode: district26List[indexSellerProfile].postalCode,
                            unitNumber: district26List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district26List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 27") {
            if (district27List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district27List[indexSellerProfile].uid}${district27List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district27List[indexSellerProfile].uid}${district27List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district27List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district27List[indexSellerProfile].uid}${district27List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district27List[indexSellerProfile].username,
                            photoURL: district27List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district27List[indexSellerProfile].sellingPrice,
                            PSFValue: district27List[indexSellerProfile].PSFValue,
                            commission: district27List[indexSellerProfile].commission,
                            address: district27List[indexSellerProfile].propertyAddress,
                            postalCode: district27List[indexSellerProfile].postalCode,
                            unitNumber: district27List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district27List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district27List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district27List[indexSellerProfile].uid}${district27List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district27List[indexSellerProfile].username,
                            photoURL: district27List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district27List[indexSellerProfile].sellingPrice,
                            PSFValue: district27List[indexSellerProfile].PSFValue,
                            commission: district27List[indexSellerProfile].commission,
                            address: district27List[indexSellerProfile].propertyAddress,
                            postalCode: district27List[indexSellerProfile].postalCode,
                            unitNumber: district27List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district27List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district27List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }

            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district27List[indexSellerProfile].uid), {
                    [`${user.uid}${district27List[indexSellerProfile].uid}${district27List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district27List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district27List[indexSellerProfile].sellingPrice,
                            PSFValue: district27List[indexSellerProfile].PSFValue,
                            commission: district27List[indexSellerProfile].commission,
                            address: district27List[indexSellerProfile].propertyAddress,
                            postalCode: district27List[indexSellerProfile].postalCode,
                            unitNumber: district27List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district27List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district27List[indexSellerProfile].uid), {
                    [`${user.uid}${district27List[indexSellerProfile].uid}${district27List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district27List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district27List[indexSellerProfile].sellingPrice,
                            PSFValue: district27List[indexSellerProfile].PSFValue,
                            commission: district27List[indexSellerProfile].commission,
                            address: district27List[indexSellerProfile].propertyAddress,
                            postalCode: district27List[indexSellerProfile].postalCode,
                            unitNumber: district27List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district27List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }
        else if (targetDistrict == "District 28") {
            if (district28List[indexSellerProfile].uid == user.uid) {
                setSomethingWrongSentence("This is your property profile");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }
            const findChatExist = await getDoc(doc(db, 'chats', `${user.uid}${district28List[indexSellerProfile].uid}${district28List[indexSellerProfile].propertyCompanyName}`));
            if (findChatExist.exists() == true) {
                setSomethingWrongSentence("You already have this seller contact");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }

            let userPublicInfo = await getDoc(doc(db, 'publicUserInfo', user.uid));
            userPublicInfo = userPublicInfo.data();
            // console.log(userPublicInfo)
            if (currentUserInfo.credit < credit) {
                setSomethingWrongSentence("Not enough credit");
                setSomethingWrong(true);
                setSpinnerLoading(false);
                return;
            }


            const detailChatConversation = await setDoc(doc(db, 'chats', `${user.uid}${district28List[indexSellerProfile].uid}${district28List[indexSellerProfile].propertyCompanyName}`), {
                messages: [{
                    createdAt: new Date(),
                    sender: user.uid,
                    senderName: userPublicInfo.username,
                    text: "Hi, I am interested."
                }]
            });

            const res_userChat = await getDoc(doc(db, 'userChat', user.uid));
            const opp_userChat = await getDoc(doc(db, 'userChat', district28List[indexSellerProfile].uid));
            if(res_userChat._document == null){
                const snapshotUser = await setDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district28List[indexSellerProfile].uid}${district28List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district28List[indexSellerProfile].username,
                            photoURL: district28List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district28List[indexSellerProfile].sellingPrice,
                            PSFValue: district28List[indexSellerProfile].PSFValue,
                            commission: district28List[indexSellerProfile].commission,
                            address: district28List[indexSellerProfile].propertyAddress,
                            postalCode: district28List[indexSellerProfile].postalCode,
                            unitNumber: district28List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district28List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district28List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }
            else{
                const snapshotUser = await updateDoc(doc(db, "userChat", user.uid), {
                    [`${user.uid}${district28List[indexSellerProfile].uid}${district28List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district28List[indexSellerProfile].username,
                            photoURL: district28List[indexSellerProfile].photoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district28List[indexSellerProfile].sellingPrice,
                            PSFValue: district28List[indexSellerProfile].PSFValue,
                            commission: district28List[indexSellerProfile].commission,
                            address: district28List[indexSellerProfile].propertyAddress,
                            postalCode: district28List[indexSellerProfile].postalCode,
                            unitNumber: district28List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Read",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: user.uid,
                            oppUserType: district28List[indexSellerProfile].agentRepresented ? "Agent" : "Landlord",
                            oppositeUID: district28List[indexSellerProfile].uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: true
                        }
                    }
    
                })
            }

            if(opp_userChat._document == null){
                const snapshotOppUser = await setDoc(doc(db, 'userChat', district28List[indexSellerProfile].uid), {
                    [`${user.uid}${district28List[indexSellerProfile].uid}${district28List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district28List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district28List[indexSellerProfile].sellingPrice,
                            PSFValue: district28List[indexSellerProfile].PSFValue,
                            commission: district28List[indexSellerProfile].commission,
                            address: district28List[indexSellerProfile].propertyAddress,
                            postalCode: district28List[indexSellerProfile].postalCode,
                            unitNumber: district28List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district28List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            else{
                const snapshotOppUser = await updateDoc(doc(db, 'userChat', district28List[indexSellerProfile].uid), {
                    [`${user.uid}${district28List[indexSellerProfile].uid}${district28List[indexSellerProfile].propertyCompanyName}`]: {
                        date: serverTimestamp(),
                        userInfo: {
                            displayName: district28List[indexSellerProfile].username,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Hi, I am interested.",
                            sellingPrice: district28List[indexSellerProfile].sellingPrice,
                            PSFValue: district28List[indexSellerProfile].PSFValue,
                            commission: district28List[indexSellerProfile].commission,
                            address: district28List[indexSellerProfile].propertyAddress,
                            postalCode: district28List[indexSellerProfile].postalCode,
                            unitNumber: district28List[indexSellerProfile].propertyUnitNumber,
                            statusRead: "Unread",
                            status: "Pending",
                            chatType: "sellerProfile",
                            myUID: district28List[indexSellerProfile].uid,
                            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : 'Tenant',
                            oppositeUID: user.uid,
                            agentProfileDeck: currentUserInfo.userType[0] == "Agent" ? userPublicInfo.agentProfile : null,
                            retractStatus: false
                        }
                    }
                })
            }
            

            

            const minusCredit = await updateDoc(doc(db, 'userInfo', user.uid), {
                credit: currentUserInfo.credit - credit
            })

            setSuccessfulConnection(true);
            setSpinnerLoading(false);

        }



        // if (oppUserPublicInfo.emailNotificationPending == true) {
        //     const requestOptions_1 = {
        //         method: 'POST',
        //         headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
        //         body: JSON.stringify({ data: { listingName: chatInformation.pendingInfo[1].userInfo.listingName, username: emailRes.data().username, email: emailRes.data().email, path: `Accepted Connection` } })
        //     };
        //     await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
        //         error => console.log(error)
        //     );
        // }

    }

    const openConfirmationToCollerSeller = (targetDistrict, indexSellerProfile) => {
        let credit = 23;

        // console.log(targetDistrict, indexSellerProfile);
        targetDistrictValue.current = targetDistrict;
        indexSellerProfileValue.current = indexSellerProfile;
        setCredit(credit);
        setConfirmation(true);
    }

    const displayNumber = (price) => {
        const numericPrice = typeof price === 'number' ? price : parseFloat(price);

        // Check if numericPrice is a valid number
        if (!isNaN(numericPrice)) {
            const formattedNumber = numericPrice.toLocaleString();
            // console.log(formattedNumber);
            return formattedNumber;
        } else {
            console.error('Invalid number:', price);
            return price; // return the original value if it's not a valid number
        }
    }

    const linkToAccount = () => {
        if (user != null) {
            const landlordRequirements = {
                type: "addPropetyLandlord",
                requirements: {
                    minFloorSize: "",
                    desiredArea: "",
                    sellerPrice: "",
                    isCommercial: false
                }

            };
            sessionStorage.setItem('requirements', JSON.stringify(landlordRequirements));
            window.location.href = '/account';
        }
        else {
            const landlordRequirements = {
                minFloorSize: "",
                desiredArea: "",
                sellerPrice: "",
                isCommercial: false
            };
            sessionStorage.setItem('landlordRequirements', JSON.stringify(landlordRequirements));
            window.location.href = '/sign-up';
        }
    }

    const backToDefault = () => {
        setLatestPostClick(false);
        setDistrict1(false);
        setDistrict2(false);
        setDistrict3(false);
        setDistrict4(false);
        setDistrict5(false);
        setDistrict6(false);
        setDistrict7(false);
        setDistrict8(false);
        setDistrict9(false);
        setDistrict10(false);
        setDistrict11(false);
        setDistrict12(false);
        setDistrict13(false);
        setDistrict14(false);
        setDistrict15(false);
        setDistrict16(false);
        setDistrict17(false);
        setDistrict18(false);
        setDistrict19(false);
        setDistrict20(false);
        setDistrict21(false);
        setDistrict22(false);
        setDistrict23(false);
        setDistrict24(false);
        setDistrict25(false);
        setDistrict26(false);
        setDistrict27(false);
        setDistrict28(false);
        setBreadCrumbDic(null);
    }

    return (
        <>

            {/* <Spinner trigger={spinnerLoading} setTrigger={setSpinnerLoading}></Spinner> */}
            <ZoomPicture trigger={zoomPic} setTrigger={setZoomPic} zoomPicURL={zoomPicURL}></ZoomPicture>
            <div className={confirmation ? "min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" : "hidden"} id="modal-id">
                <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
                <div className="w-full max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white z-50">
                    <div class="text-center p-5 flex-auto justify-center">
                        <h2 class="text-xl font-bold py-4 ">Connecting with seller</h2>
                    </div>
                    <div >
                        <center>
                            <div>

                                {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", padding: "8px 8px" }}>
                                        <p for="phone" class="text-l font-bold  ">Seller Profile</p>
                                    </div> */}
                                <p class="text-sm text-gray-500 p-2">Credit: {credit}</p>
                                <div class="p-3  mt-2 text-center space-x-4 md:block">
                                    <button class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100" onClick={() => setConfirmation(false)} >
                                        Close
                                    </button>
                                    <button class="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600" onClick={() => { setSpinnerLoading(true); setConfirmation(false); connectToSeller() }}>Connect</button>
                                </div>
                            </div>
                        </center>

                    </div>


                </div>
            </div >

            {successfulConnection ?
                <div className="flex items-center justify-center">
                    <div role="alert" className="rounded-xl border border-gray-100 bg-white p-4 absolute top-0 mt-10 z-50">
                        <div className="flex items-start gap-4">
                            <span className="text-green-600">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </span>

                            <div className="flex-1">
                                <strong className="block font-medium text-gray-900">Successful Connection</strong>

                                <p className="mt-1 text-sm text-gray-700">
                                    Connected with seller. Check your chat to drop a message to the seller
                                </p>
                            </div>


                        </div>
                    </div>
                </div> : null}
            {somethingWrong ?
                <div className="flex items-center justify-center">
                    <div role="alert" className="rounded border-s-4 border-red-500 bg-red-50 p-4 absolute top-0 mt-10 z-50">
                        <strong className="block font-medium text-red-800">Something went wrong</strong>

                        <p className="mt-2 text-sm text-red-700">
                            {somethingWrongSentence}
                        </p>
                    </div> </div> : null}
            <div className="bg-white py-12 sm:py-12">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Connect To Property Owners</h2>
                        <p className="mt-2 text-lg leading-8 text-gray-600">
                            Register your commercial and residential properties here
                        </p>
                    </div>
                    <div class="capitalize">
                        <nav aria-label="breadcrumb" class="w-max">
                            <ol class="flex flex-wrap items-center w-full bg-opacity-60 rounded-md bg-transparent p-0 transition-all">
                                <li class="flex items-center text-blue-gray-900 antialiased font-sans text-sm font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-light-blue-500">
                                    <Link onClick={() => {
                                        backToDefault()
                                    }}>
                                        <p class="block antialiased font-sans text-sm leading-normal text-blue-900 font-normal opacity-50 transition-all hover:text-blue-500 hover:opacity-100">Owners Directory</p>
                                    </Link>
                                    {breadcrumbDic != null ?

                                        <span class="text-gray-500 text-sm antialiased font-sans font-normal leading-normal mx-2 pointer-events-none select-none">/</span> : null}
                                </li>
                                {breadcrumbDic != null ?
                                    <>
                                        <li class="flex items-center text-blue-900 antialiased font-sans text-sm font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500">
                                            <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">{breadcrumbDic}</p>
                                        </li>
                                    </>
                                    : null}
                            </ol>
                        </nav>
                    </div>
                    {district1 == false && district2 == false
                        && district3 == false && district4 == false
                        && district5 == false && district6 == false
                        && district7 == false && district8 == false
                        && district9 == false && district10 == false
                        && district11 == false && district12 == false
                        && district13 == false && district14 == false
                        && district15 == false && district16 == false
                        && district17 == false && district18 == false
                        && district19 == false && district20 == false
                        && district21 == false && district22 == false
                        && district23 == false && district24 == false
                        && district25 == false && district26 == false
                        && district27 == false && district28 == false
                        && lastestPostClick == false ?
                        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">

                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="Office"
                                    src="https://images.unsplash.com/photo-1661956602116-aa6865609028?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80"
                                    className="absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setLatestPostClick(true); setBreadCrumbDic("Latest Profile") }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                Latest Profile
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Top lastest profile
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D1"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D1.jpg?updatedAt=1701918220174&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict1(true); setBreadCrumbDic("District 1"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 1
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Boat Quay
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Raffles Place
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Marina Downtown
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Suntec City
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Marina South

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D2"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D2.jpg?updatedAt=1701918225520&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict2(true); setBreadCrumbDic("District 2"); setAdvertisementNumber(0); }}>


                                        <a>
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 2
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Shenton Way
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Tanjong Pagar
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Chinatown

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D3"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D3.jpg?updatedAt=1701918228412&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict3(true); setBreadCrumbDic("District 3"); setAdvertisementNumber(0); }}>


                                        <a>
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 3
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Queenstown
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Alexandra
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Tiong Bahru

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D4"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D4.jpg?updatedAt=1701918227988&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict4(true); setBreadCrumbDic("District 4"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 4
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Sentosa
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Harbourfront
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Keppel
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Telok Blangah
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D5"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D5.jpg?updatedAt=1701918228548&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict5(true); setBreadCrumbDic("District 5"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 5
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Buona Vista
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Dover
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Pasir Panjang
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Clementi
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            West Coast
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D6"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D6.jpg?updatedAt=1701918231035&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict6(true); setBreadCrumbDic("District 6"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 6
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            City Hall
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Fort Canning

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D7"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D7.jpg?updatedAt=1701918230234&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict7(true); setBreadCrumbDic("District 7"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 7
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Bugis
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Rochor

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D8"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D8%20Little%20India.jpg?updatedAt=1701918229003&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict8(true); setBreadCrumbDic("District 8"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 8
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Little India
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Farrer Park

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D9"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D9.jpg?updatedAt=1701918230880&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict9(true); setBreadCrumbDic('District 9'); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 9
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Orchard
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Somerset
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            River Valley

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D10"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D10.jpg?updatedAt=1701918222440&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict10(true); setBreadCrumbDic('District 10'); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 10
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Tanglin
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Bukit Timah
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Holland

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D11"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D11.jpg?updatedAt=1701922804823&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict11(true); setBreadCrumbDic('District 11'); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 11
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Newton
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Novena
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Dunearn
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Watten

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D12"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D12.jpg?updatedAt=1701922806381&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict12(true); setBreadCrumbDic('District 12'); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 12
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Balestier
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Whampoa
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Toa Payoh
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Boon Keng
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Bendemeer
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Kampong Bugis
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D13"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D13%20Braddell%20Road.jpg?updatedAt=1701918220147&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict13(true); setBreadCrumbDic('District 13'); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 13
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Potong Pasir
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Bidadari
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            MacPherson
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Upper Aljunied

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D14"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D14%20Geylang.jpg?updatedAt=1701918220669&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict14(true); setBreadCrumbDic('District 14'); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 14
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Geylang
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Dakota
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Paya Lebar Central
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Eunos
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Ubi
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Aljunied
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Kembangan
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Kaki Bukit
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D15"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D15.jpg?updatedAt=1701918223364&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict15(true); setBreadCrumbDic('District 15'); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 15
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Tanjong Rhu
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Amber
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Meyer
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Katong
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Dunman
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Joo Chiat
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Marine Parade
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Telok Kurau
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Siglap
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Frankel
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D16"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D16%20Bedok%20Reservoir.jpg?updatedAt=1701918222197&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict16(true); setBreadCrumbDic('District 16'); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 16
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Bedok
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Upper East Coast
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Bayshore
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Tanah Merah
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Upper Changi

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D17"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D17%20Changi.jpg?updatedAt=1701918222188&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict17(true); setBreadCrumbDic('District 17'); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 17
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Flora Drive
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Loyang
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Changi

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D18"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D18%20Tampines.jpg?updatedAt=1701918223807&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict18(true); setBreadCrumbDic('District 18'); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 18
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Tampines
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Pasir Ris

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D19"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D19.jpg?updatedAt=1701918223876&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict19(true); setBreadCrumbDic("District 19"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 19
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Punggol
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Sengkang
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Hougang
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Kovan
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Serangoon
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Lorong Ah Soo
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D20"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D20%20Bishan.jpg?updatedAt=1701918224090&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict20(true); setBreadCrumbDic("District 20"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 20
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Ang Mo Kio
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Bishan
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>

                                            Thomson

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D21"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D21.jpg?updatedAt=1701918231252&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict21(true); setBreadCrumbDic("District 21"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 21
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Upper Bukit Timah
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Ulu Pandan
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Clementi Park

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D22"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D22.jpg?updatedAt=1701918225871&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict22(true); setBreadCrumbDic("District 22"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 22
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Jurong East
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Jurong West
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Boon Lay

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D23"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D23%20Bukit%20Batok.jpg?updatedAt=1701918228836&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict23(true); setBreadCrumbDic("District 23"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 23
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Hillview
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Bukit Panjang
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Bukit Batok
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Choa Chu Kang

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D24"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D24.png?updatedAt=1701922804511&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict24(true); setBreadCrumbDic("District 24"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 24
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Kranji
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Lim Chu Kang
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Sungei Gedong
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Tengah

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D25"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D25.jpg?updatedAt=1701922806119&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 h-full">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict25(true); setBreadCrumbDic("District 25"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 25
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Woodlands
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Admiralty

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D26"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D26.png?updatedAt=1701922805220&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 ">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict26(true); setBreadCrumbDic("District 26"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 26
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Lentor
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Springleaf
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Mandai

                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D27"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D27.jpg?updatedAt=1701922807023&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 ">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict27(true); setBreadCrumbDic("District 27"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 27
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Yishun,
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Sembawang


                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg">
                                <img
                                    alt="D28"
                                    src="https://ik.imagekit.io/NextLoop/Districts/D28.png?updatedAt=1701927729106&tr=f-format:webp"
                                    className="lazyload absolute inset-0 h-40 sm:h-48 lg:h-64 w-full object-cover"
                                />

                                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 ">
                                    <div className="p-4 sm:p-6" onClick={() => { setDistrict28(true); setBreadCrumbDic("District 28"); setAdvertisementNumber(0); }}>


                                        <a >
                                            <h3 className="mt-0.5 text-lg text-white">
                                                District 28
                                            </h3>
                                        </a>

                                        <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95">
                                            Seletar
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Seletar Hill
                                            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                                <circle cx={1} cy={1} r={1} />
                                            </svg>
                                            Sengkang West

                                        </p>
                                    </div>
                                </div>
                            </article>


                        </div> :
                        null}
                    {lastestPostClick ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">Latest Profile</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{latestPostList.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {latestPostList.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            {eachProfile.verified == "approve" ?
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    Verified
                                                                                </div>
                                                                                :
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>}
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {/* {console.log(eachProfile.sellingPrice)} */}
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`Latest Profile`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`Latest Profile`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {/* {console.log(eachProfile.sellingPrice)} */}
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => linkToAccount()}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District ${parseInt((eachProfile.district.split(' ')[0]).split('D')[1])}`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementList.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementList.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}

                    {district1 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 1</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district1List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district1List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {/* {console.log(eachProfile.sellingPrice)} */}
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 1`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District ${parseInt((eachProfile.district.split(' ')[0]).split('D')[1])}`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 1`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 1`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 1 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict1.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict1.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district2 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 2</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district2List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district2List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 2`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 2`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 2`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 2`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 2 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict2.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict2.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district3 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 3</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district3List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district3List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 3`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 3`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 3`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 3`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 3 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict3.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict3.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district4 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 4</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district4List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district4List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 4`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 4`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 4`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 4`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 4 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict4.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict4.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district5 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 5</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district5List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district5List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 5`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 5`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 5`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 5`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 5 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict5.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict5.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district6 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 6</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district6List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district6List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 6`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 6`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 6`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 6`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 6 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict6.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict6.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district7 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 7</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district7List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district7List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 7`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 7`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 7`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 7`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 7 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict7.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict7.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district8 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 8</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district8List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district8List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 8`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 8`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 8`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 8`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 8 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict8.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict8.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district9 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 9</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district9List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district9List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 9`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 9`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 9`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 9`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 9 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict9.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict9.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district10 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 10</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district10List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district10List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 10`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 10`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 10`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 10`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 10 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict10.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict10.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district11 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 11</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district11List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district11List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 11`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 11`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 11`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 11`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 11 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict11.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict11.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district12 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 12</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district12List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district12List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 12`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 12`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 12`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 12`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 12 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict12.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict12.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district13 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 13</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district13List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district13List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 13`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 13`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 13`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 13`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 13 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict13.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict13.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district14 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 14</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district14List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district14List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 14`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 14`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 14`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 14`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 14 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict14.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict14.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district15 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 15</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district15List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district15List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 15`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 15`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 15`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 15`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 15 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict15.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict15.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district16 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 16</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district16List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district16List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 16`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 16`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 16`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 16`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 16 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict16.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict16.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district17 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 17</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district17List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district17List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 17`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 17`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 17`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 17`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 17 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict17.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict17.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district18 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 18</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district18List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district18List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 18`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 18`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 18`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 18`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 18 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict18.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict18.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district19 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 19</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district19List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district19List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 19`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 19`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 19`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 19`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 19 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">

                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict19.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict19.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district20 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 20</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district20List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district20List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 20`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 20`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 20`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 20`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 20 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict20.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict20.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district21 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 21</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district21List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district21List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 21`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 21`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 21`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 21`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 21 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict21.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict21.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district22 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 22</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district22List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district22List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 22`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 22`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 22`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 22`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 22 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict22.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict22.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district23 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 23</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district23List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district23List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 23`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 23`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 23`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 23`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 23 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict23.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict23.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district24 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 24</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district24List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district24List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 24`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 24`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 24`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 24`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 24 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict24.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict24.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district25 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 25</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district25List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district25List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 25`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 25`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 25`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 25`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 25 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict25.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict25.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district26 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 26</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district26List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district26List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 26`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 26`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 26`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 26`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 26 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict26.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict26.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district27 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 27</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district27List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district27List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 27`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 27`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 27`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 27`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 27 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict27.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict27.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}
                    {district28 ?
                        <>
                            <section className="mx-auto mt-10">
                                <div class="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div class="flex items-center gap-x-3">
                                            <h2 class="text-lg font-medium text-gray-800">District 28</h2>

                                            <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full ">{district28List.length} property</span>
                                        </div>

                                        <p class="mt-1 text-sm text-gray-500">Connect with home owners</p>
                                    </div>


                                </div>
                                <div class="mt-6 md:flex md:items-center md:justify-between">
                                    <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
                                        <button class={filter == "View All" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('View All')}>
                                            View all
                                        </button>

                                        <button class={filter == "HDB" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('HDB')}>
                                            HDB
                                        </button>

                                        <button class={filter == "Condominium" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Condominium')}>
                                            Condominium
                                        </button>
                                        <button class={filter == "Landed" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Landed')}>
                                            Landed
                                        </button>
                                        <button class={filter == "Commercial" ? "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm"
                                            :
                                            "px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm"} onClick={() => setFilter('Commercial')}>
                                            Commercial
                                        </button>
                                    </div>

                                    <div class="relative flex items-center mt-4 md:mt-0">
                                        <button
                                            onClick={() => linkToAccount()}
                                            className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                        >
                                            Add your property
                                        </button>




                                    </div>
                                </div>
                                <div class="flex flex-col mt-6">
                                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200 ">
                                                    <thead class="bg-gray-50 ">
                                                        <tr>
                                                            <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                <button class="flex items-center gap-x-3 focus:outline-none">
                                                                    <span>Seller Profile Image</span>

                                                                    {/* <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                                                        <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                                                    </svg> */}
                                                                </button>
                                                            </th>

                                                            <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Status
                                                            </th>

                                                            <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                                                                Property
                                                            </th>


                                                            <th scope="col" class="relative py-3.5 px-4">
                                                                <span class="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200  ">
                                                        {district28List.map((eachProfile, index) => (

                                                            <tr>
                                                                {filter == 'View All' ?
                                                                    <>
                                                                        <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                            {eachProfile.allImageURL.length <= 0 ?
                                                                                <>
                                                                                    <div class="w-1/2 bg-white grid place-items-center">
                                                                                        <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                    </div>
                                                                                </> :

                                                                                <div class="bg-white grid place-items-center">
                                                                                    <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                </div>

                                                                            }

                                                                        </td>
                                                                        <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                            <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                New
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <div>
                                                                                {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                            </div>
                                                                        </td>


                                                                        <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                            <button
                                                                                onClick={() => openConfirmationToCollerSeller(`District 28`, index)}
                                                                                className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                            >
                                                                                Connect
                                                                            </button>
                                                                            {/* <Popover className="relative">
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Popover.Button
                                                                                            className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                            </svg>

                                                                                        </Popover.Button>
                                                                                        <Transition
                                                                                            show={open}
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-200"
                                                                                            enterFrom="opacity-0 scale-95"
                                                                                            enterTo="opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-150"
                                                                                            leaveFrom="opacity-100 scale-100"
                                                                                            leaveTo="opacity-0 scale-95"
                                                                                        >
                                                                                            <Popover.Panel
                                                                                                static

                                                                                            >
                                                                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                    <div className="relative bg-white py-3">

                                                                                                        <a
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                        >

                                                                                                            <div className="ml-4">
                                                                                                                <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 28`, index)}>
                                                                                                                    Connect
                                                                                                                </p>

                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </Popover.Panel>
                                                                                        </Transition>
                                                                                    </>
                                                                                )}
                                                                            </Popover> */}
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    filter == (eachProfile.hasOwnProperty('propertyTypeRes') ? eachProfile.propertyTypeRes : "Commercial") ?
                                                                        <>
                                                                            <td class="px-4 py-4 text-sm font-medium whitespace-nowrap md:w-1/4">

                                                                                {eachProfile.allImageURL.length <= 0 ?
                                                                                    <>
                                                                                        <div class="w-1/2 bg-white grid place-items-center">
                                                                                            <img src='https://ik.imagekit.io/NextLoop/navLogo.png?updatedAt=1684238653800' alt="nextloop logo" class="rounded-xl" />
                                                                                        </div>
                                                                                    </> :

                                                                                    <div class="bg-white grid place-items-center">
                                                                                        <img src={eachProfile.allImageURL[0]} alt="seller picture" class="rounded-xl" />
                                                                                    </div>

                                                                                }

                                                                            </td>
                                                                            <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                                <div class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 ">
                                                                                    New
                                                                                </div>
                                                                            </td>
                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <div>
                                                                                    {eachProfile.hasOwnProperty('sellingPrice') ? eachProfile.sellingPrice != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.sellingPrice)}</h4> : null : null}
                                                                                    {eachProfile.hasOwnProperty('rentalCost') ? eachProfile.rentalCost != "" ? <h4 class="text-gray-700 ">${displayNumber(eachProfile.rentalCost)}</h4> : null : null}
                                                                                    <p class="text-gray-500 ">{eachProfile.district}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyAddress}</p>
                                                                                    <p class="text-gray-500 ">{eachProfile.propertyType} {eachProfile.hasOwnProperty('propertyTypeRes') ? `[${eachProfile.propertyTypeRes}]` : null}</p>
                                                                                    {eachProfile.hasOwnProperty('commission') ? <p class="text-gray-500 ">Proposed Commission: {eachProfile.commission}</p> : null}
                                                                                </div>
                                                                            </td>


                                                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                                                <button
                                                                                    onClick={() => openConfirmationToCollerSeller(`District 28`, index)}
                                                                                    className="flex-none rounded-md bg-gray-700 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                                                                                >
                                                                                    Connect
                                                                                </button>
                                                                                {/* <Popover className="relative">
                                                                                    {({ open }) => (
                                                                                        <>
                                                                                            <Popover.Button
                                                                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                                                                </svg>

                                                                                            </Popover.Button>
                                                                                            <Transition
                                                                                                show={open}
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-200"
                                                                                                enterFrom="opacity-0 scale-95"
                                                                                                enterTo="opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-150"
                                                                                                leaveFrom="opacity-100 scale-100"
                                                                                                leaveTo="opacity-0 scale-95"
                                                                                            >
                                                                                                <Popover.Panel
                                                                                                    static

                                                                                                >
                                                                                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                                                                                        <div className="relative bg-white py-3">

                                                                                                            <a
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className=" rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                                                            >

                                                                                                                <div className="ml-4">
                                                                                                                    <p className="text-sm font-medium text-gray-900" onClick={() => openConfirmationToCollerSeller(`District 28`, index)}>
                                                                                                                        Connect
                                                                                                                    </p>

                                                                                                                </div>
                                                                                                            </a>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Popover.Panel>
                                                                                            </Transition>
                                                                                        </>
                                                                                    )}
                                                                                </Popover> */}
                                                                            </td>
                                                                        </>
                                                                        : null}
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            <div className='mt-8'>
                                <h2 class="text-lg font-medium text-gray-800">District 28 Advertisement</h2>
                                <div class="relative flex items-center justify-center mt-7">
                                    <div class="container mx-auto p-4">
                                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                            {advertisementListDistrict28.map((indivAdv, index) => {
                                                if (index == 0) {
                                                    return (
                                                        <>
                                                            <div class={`bg-white ${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                                <div className="CardAdvertiseDivCommercialRentAdv">
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                    </div>
                                                                    <div className="CardContainer">
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div class={`bg-white ${advertisementNumber == index ? `block` : `hidden`} md:${advertisementNumber == index || advertisementNumber + 1 == index || advertisementNumber + 2 == index || advertisementNumber + 3 == index ? `block` : `hidden`}`} onClick={() => { setZoomPicURL(indivAdv.imageURL); setZoomPic(true) }}>
                                                            <div className="CardAdvertiseDivCommercialRentAdv">
                                                                <div style={{ position: 'relative' }}>
                                                                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={indivAdv.imageURL} alt="Uploaded" />

                                                                </div>
                                                                <div className="CardContainer">
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            })}



                                        </div>
                                    </div>

                                    {advertisementNumber != 0 ?
                                        <button className="absolute top-0 mt-48 md:mt-56 left-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -ml-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(-1)' }} onClick={() => setAdvertisementNumber(advertisementNumber - 1)}>&#x279c;</span>
                                        </button> : null}
                                    {advertisementNumber + 3 < (advertisementListDistrict28.length - 1) ?
                                        <button className="absolute top-0 mt-48 md:mt-56 right-0 bg-white rounded-full shadow-md h-12 w-12 text-2xl text-indigo-600 hover:text-indigo-400 focus:text-indigo-400 -mr-6 focus:outline-none focus:shadow-outline z-2">
                                            <span className="block" style={{ transform: 'scale(1)' }} onClick={() => setAdvertisementNumber(advertisementNumber + 1)}>&#x279c;</span>
                                        </button> : null}
                                </div>
                            </div>
                        </> : null}










                </div >
            </div >


            <Footer />

        </>
    );
}



export default Explore;
