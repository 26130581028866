import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";

import "./css/Carousel.css";

export const CarouselItem = ({ children, width, src, type }) => {
  if (type == "home") {
    if (children == "firstVideo") {
      return (
        <div className="carousel-item" style={{ width: "100%" }}>
          <video controls src={src} style={{ width: "100%", height: "600px" }} autoPlay muted loop>
          </video>
        </div>
      );
    }
    else {
      return (
        <div className="carousel-item" style={{ width: "100%" }}>
          <img src={src} style={{ height: "600px", width: "100%" }}></img>
        </div>
      );
    }
  }
  else {
    return (
      <div className="carousel-item" style={{ width: "100%" }}>
        <img src={src} id="detailImg"  ></img>
      </div>
    );
  }
};

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  return (
    <div
      {...handlers}
      className="carousel"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div
        className="inner"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>

      <div className="indicators">
        <button id="buttonNextPre"
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        >
          {window.innerWidth > 996 ? <i class="fa-solid fa-chevron-left"></i> : <i class="fa-solid fa-chevron-left"></i>}
        </button>
        {React.Children.map(children, (child, index) => {
          return (
            <button
              className={`${index === activeIndex ? "active" : "notActive"}`}
              onClick={() => {
                updateIndex(index);
              }}
            >
              {index + 1}
            </button>
          );
        })}
        <button id="buttonNextPre"
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        >
          {window.innerWidth > 996 ? <i class="fa-solid fa-chevron-right"></i> : <i class="fa-solid fa-chevron-right"></i>}
        </button>
      </div>

    </div>
  );
};

export default Carousel;
