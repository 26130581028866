import React, { useEffect, useRef, useState } from 'react';
import { UserAuth } from '../../AuthContent';
import { doc, setDoc, getDoc, collection, addDoc, updateDoc, serverTimestamp, deleteField, getDocs } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes, deleteObject, uploadString, getBlob } from 'firebase/storage';
import { db, storage } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import Acknowledge from './Acknowledge';
import '../css/UserTypeModal.css';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import AcknowledgeNormal from '../../AcknowledgeNormal';
import Spinner from './Spinner';

export default function CEA(props) {
    const { user } = UserAuth();




    const [message, setMessage] = useState(null);

    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);

    const [activeSpin, setActiveSpin] = useState(false);
    const [chooseAdditionRole, setChooseAdditionRole] = useState(false);
    const [showRole, setShowRole] = useState(true);
    const [userTypeArr, setUserTypeArr] = useState([]);
    const [secondaryRole, setSecondaryRole] = useState(null);
    const [askAgentCEA, setAskAgentCEA] = useState(true);
    const [CEAName, setCEAName] = useState('');
    const [CEAHP, setCEAHP] = useState('');
    const [CEAAgency, setCEAAgency] = useState('');
    const [CEANumber, setCEANumber] = useState('');
    const [showGotSpace, setShowGotSpace] = useState(false);
    const [masterTenantType, setMasterTenantType] = useState(false);
    const [landlordType, setLandlordType] = useState(false);
    const [chooseTier, setChooseTier] = useState(false);
    const [standard, setStandard] = useState(false);
    const [PDFCEA,setPDFCEA] = useState(null);
    const [premium, setPremium] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [promotionTab, setPromotionTab] = useState(false);
    const [enterprise, setEnterprise] = useState(false);
    const [paymentTab, setPaymentTab] = useState(false);
    const [CEAAgencyNumber, setCEAAgencyNumber] = useState('');
    const [superPremium, setSuperPremium] = useState(false);
    const uploadSelfPDF = useRef(null);
    const [reason, setReason] = useState('');
    // useEffect(() => {
    //     console.log(props);
    // }, [props])
    useEffect(() => {
        if (props.currentUserInfo != null) {
            setUserTypeArr(props.currentUserInfo.userType);
        }
        if (props.modifyCEA == true) {
            setCEAName(props.publicInfo.agentProfile.CEAName);
            setCEAHP(props.publicInfo.agentProfile.CEAHP);
            setCEAAgency(props.publicInfo.agentProfile.CEAAgency);
            setCEANumber(props.publicInfo.agentProfile.CEANumber);
            setCEAAgencyNumber(props.publicInfo.agentProfile.CEAAgencyNumber);
            if (props.publicInfo.agentProfile.hasOwnProperty('reason')) {
                setReason(props.publicInfo.agentProfile.reason);
            }
        }
    }, [props])


    const proceedUpdatingRole = async () => {
        var agentProfile = {
            CEAName: CEAName,
            CEAHP: CEAHP,
            CEAAgency: CEAAgency,
            CEANumber: CEANumber,
            verified: false,
            CEAAgencyNumber: CEAAgencyNumber
        }
        if (CEAName == '' || CEAHP == "" || CEAAgency == "" || CEANumber == "") {
            alert("Missing parameters. Fill up all the details below");
        }
        else {
            if(PDFCEA != null){
             let snapshot = await uploadBytes(ref(storage, `${user.uid}/AgentProfilePDF`), PDFCEA);
             let urlLink = await getDownloadURL(ref(storage, `${user.uid}/AgentProfilePDF`));
             console.log(urlLink);
             agentProfile.PDFCEA = urlLink;
            }
            else{
                agentProfile.PDFCEA = null;
            }
            const querySnapshot = await getDocs(collection(db, 'publicUserInfo'));

            for (const indivDoc of querySnapshot.docs) {
                if (indivDoc.data().hasOwnProperty('agentProfile')) {
                    if (indivDoc.id != user.uid) {
                        if (
                            indivDoc.data().agentProfile.CEANumber === CEANumber ||
                            indivDoc.data().agentProfile.CEAHP === CEAHP ||
                            indivDoc.data().agentProfile.CEAName === CEAName
                        ) {
                            alert("Duplicate CEA information");
                            return;
                        }
                    }

                }
            }

            // if (props.currentUserInfo.subscriptionID == null) {
            //     setChooseTier(true);
            //     setAskAgentCEA(false);
            // } else 
            {
                var newCurrentUserInfo = props.currentUserInfo;
                await updateDoc(doc(db, 'publicUserInfo', user.uid), {
                    agentProfile: agentProfile
                }).then(() => {
                    var newPublicInfo = props.publicInfo;
                    newPublicInfo.agentProfile = agentProfile;
                    props.setPublicInfo(newPublicInfo);
                    closeUserTypeModal();
                })

            }


        }




    }




    const closeUserTypeModal = () => {
        setCEAName(null);
        setCEAHP(null);
        setCEAAgency(null);
        setCEANumber(null);
        setChooseAdditionRole(false);
        setShowRole(true);
        setUserTypeArr([]);
        setShowGotSpace(false);
        setSecondaryRole(null);
        setChooseTier(false);
        setStandard(false);
        setAskAgentCEA(true);
        setPremium(false);
        setEnterprise(false);
        setPromotionTab(false);
        setPaymentTab(false);
        setLandlordType(false);
        setCEAAgencyNumber(null);
        setMasterTenantType(false);
        props.setTrigger(false);
        props.setModifyCEA(false);

    }



    if (!props.trigger) {
        return;
    }
    else {

        const createSubscription = async (data, actions) => {
            try {
                const selectElement = document.querySelector('.select');
                console.log(selectElement.id);
                console.log(props.currentUserInfo);
                let planID = "";
                //P-87V97227543012720MSIEUPQ premium no charges
                //P-5JG55492SV880132GMSIEUEY standard no charges
                if (selectElement.id == "standard" && props.currentUserInfo.newUserSetup == true) {
                    planID = "P-4WE25674YD030400NMSFLZEI";
                } else if (selectElement.id == "premium" && props.currentUserInfo.newUserSetup == true) {
                    console.log("here");
                    planID = "P-6M109645LM7508252MSFLZ2Y";
                }
                else if (selectElement.id == "superPremium" && props.currentUserInfo.newUserSetup == true) {
                    planID = "P-7AD3724745234033TMSNY6EQ";
                }
                else if (selectElement.id == "standard" && props.currentUserInfo.newUserSetup == false) {
                    planID = "P-5JG55492SV880132GMSIEUEY"
                }
                else if (selectElement.id == "premium" && props.currentUserInfo.newUserSetup == false) {
                    planID = "P-87V97227543012720MSIEUPQ"
                }
                else if (selectElement.id == "superPremium" && props.currentUserInfo.newUserSetup == false) {
                    planID = "P-4XH70004EV358760SMSNY6RA"
                }

                if (props.currentUserInfo.subscriptionID == null) {
                    if (props.currentUserInfo.membership != null) {
                        const startDate = new Date(); // Get the current date
                        startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
                        startDate.setMinutes(startDate.getMinutes() + 2);
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription",
                                        start_time: startDate
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }
                    }

                    else {
                        console.log(planID);
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription"
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }
                    }
                }
                else {
                    //upgrade
                    if (selectElement.id == "premium" && props.currentUserInfo.membership == "Standard") {
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription"
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }

                    }
                    else {
                        //you have an subscription id
                        const startDate = new Date(); // Get the current date
                        startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
                        startDate.setMinutes(startDate.getMinutes() + 2);
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription",
                                        start_time: startDate
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }

                    }


                }
            } catch (error) {
                console.error(error);
            }
        };

        const onApprove = async (data, actions) => {
            //Default all agent role
            const selectElement = document.querySelector('.select');
            var CEANameInput = document.getElementById("CEAName");
            var CEAAgencyInput = document.getElementById("CEAAgency");
            var CEAAgencyNumberInput = document.getElementById("CEAAgencyNumber");
            var CEAHPInput = document.getElementById("CEAHP");
            var CEANumberInput = document.getElementById("CEANumber");
            var agentProfile = {
                CEAName: CEANameInput.value,
                CEAHP: CEAHPInput.value,
                CEAAgency: CEAAgencyInput.value,
                CEANumber: CEANumberInput.value,
                CEAAgencyNumber: CEAAgencyNumberInput.value,
                verified: false,

            }
            await updateDoc(doc(db, 'publicUserInfo', user.uid), {
                agentProfile: agentProfile
            }).then(async () => {
                if (props.currentUserInfo.subscriptionID === "PromotionCode") {

                    if (selectElement.id == "standard") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            downgrade: "Standard",
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = "Standard";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(true);
                            setPremium(false);
                            setEnterprise(false);
                            setPromotionTab(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setCEAAgencyNumber(null);
                            setMasterTenantType(false);
                            props.setModifyCEA(false);
                        })
                    }
                    else if (selectElement.id == "premium") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            downgrade: "Premium",
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = "Premium";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(true);
                            setPremium(false);
                            setEnterprise(false);
                            setPromotionTab(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setCEAAgencyNumber(null);
                            setMasterTenantType(false);
                            props.setModifyCEA(false);
                        })
                    }
                    else {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            downgrade: "Super-Premium",
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = "Super-Premium";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(true);
                            setPremium(false);
                            setEnterprise(false);
                            setPromotionTab(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setCEAAgencyNumber(null);
                            setMasterTenantType(false);
                            props.setModifyCEA(false);
                        })
                    }


                }
                else if (props.currentUserInfo.subscriptionID == null) {
                    if (selectElement.id == "standard" && props.currentUserInfo.membership == "Standard") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            downgrade: deleteField()
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = null;
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(true);
                            setPremium(false);
                            setEnterprise(false);
                            setPromotionTab(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setCEAAgencyNumber(null);
                            setMasterTenantType(false);
                            props.setModifyCEA(false);
                        })
                    }
                    else if (selectElement.id == "standard" && props.currentUserInfo.membership == "Premium") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            downgrade: "Standard"
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = "Standard";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(true);
                            setPremium(false);
                            setEnterprise(false);
                            setPromotionTab(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setCEAAgencyNumber(null);
                            setMasterTenantType(false);
                            props.setModifyCEA(false);
                        })
                    }
                    else if (selectElement.id == "standard" && props.currentUserInfo.membership == "Super-Premium") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            downgrade: "Standard"
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = "Standard";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(true);
                            setPremium(false);
                            setEnterprise(false);
                            setPromotionTab(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setCEAAgencyNumber(null);
                            setMasterTenantType(false);
                            props.setModifyCEA(false);
                        })
                    }
                    else if (selectElement.id == "premium" && props.currentUserInfo.membership == "Premium") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            downgrade: deleteField()
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = null;
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(true);
                            setPremium(false);
                            setEnterprise(false);
                            setPromotionTab(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setCEAAgencyNumber(null);
                            setMasterTenantType(false);
                            props.setModifyCEA(false);
                        })
                    }
                    else if (selectElement.id == "premium" && props.currentUserInfo.membership == "Standard") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            membership: "Premium",
                            billingAmount: 380,
                            subscriptionCounter: 30,
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            chatLeft: props.currentUserInfo.chatLeft + 16,
                            advancedDetails: props.currentUserInfo.advancedDetails + 16,
                            newUserSetup: false
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 16;
                            newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 16;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            newCurrentUserInfo.billingAmount = 380;
                            newCurrentUserInfo.membership = "Premium";
                            newCurrentUserInfo.newUserSetup = false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(true);
                            setPremium(false);
                            setEnterprise(false);
                            setPromotionTab(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setCEAAgencyNumber(null);
                            setMasterTenantType(false);
                            props.setModifyCEA(false);
                        })
                    }
                    else if (selectElement.id == "premium" && props.currentUserInfo.membership == "Super-Premium") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            newUserSetup: false,
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            downgrade: "Premium",
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.downgrade = "Premium";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(true);
                            setPremium(false);
                            setEnterprise(false);
                            setPromotionTab(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setCEAAgencyNumber(null);
                            setMasterTenantType(false);
                            props.setModifyCEA(false);
                        })
                    }
                    else if (selectElement.id == "standard") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            membership: "Standard",
                            billingAmount: 180,
                            subscriptionPeriod: "Monthly",
                            subscriptionCounter: 30,
                            chatLeft: props.currentUserInfo.chatLeft + 7,
                            advancedDetails: props.currentUserInfo.advancedDetails + 7,
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            newUserSetup: false,
                            downgrade: deleteField()
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 7;
                            newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 7;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            newCurrentUserInfo.billingAmount = 180;
                            newCurrentUserInfo.subscriptionPeriod = "Monthly";

                            newCurrentUserInfo.membership = "Standard";
                            newCurrentUserInfo.newUserSetup = false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(true);
                            setPremium(false);
                            setEnterprise(false);
                            setPromotionTab(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setCEAAgencyNumber(null);
                            setMasterTenantType(false);
                            props.setModifyCEA(false);
                        })
                    }
                    else if (selectElement.id == "premium") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            membership: "Premium",
                            billingAmount: 380,
                            subscriptionPeriod: "Monthly",
                            subscriptionCounter: 30,
                            chatLeft: props.currentUserInfo.chatLeft + 16,
                            advancedDetails: props.currentUserInfo.advancedDetails + 16,
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            newUserSetup: false,
                            downgrade: deleteField()
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 16;
                            newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 16;

                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            newCurrentUserInfo.billingAmount = 380;
                            newCurrentUserInfo.subscriptionPeriod = "Monthly";
                            newCurrentUserInfo.membership = "Premium";
                            newCurrentUserInfo.newUserSetup = false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(true);
                            setPremium(false);
                            setEnterprise(false);
                            setPromotionTab(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setCEAAgencyNumber(null);
                            setMasterTenantType(false);
                            props.setModifyCEA(false);
                        })
                    }
                    else {
                        //superpremium
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            oldsubscriptionID: props.currentUserInfo.subscriptionID,
                            subscriptionID: data.subscriptionID,
                            membership: "Super-Premium",
                            chatLeft: props.currentUserInfo.chatLeft + 40,
                            billingAmount: 880,
                            subscriptionPeriod: "Monthly",
                            subscriptionCounter: 30,
                            freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                            advancedDetails: props.currentUserInfo.advancedDetails + 40,
                            newUserSetup: false,
                            downgrade: deleteField()
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.subscriptionID = data.subscriptionID;
                            newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 40;
                            newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 40;
                            newCurrentUserInfo.billingAmount = 880;
                            newCurrentUserInfo.subscriptionPeriod = "Monthly";

                            newCurrentUserInfo.membership = "Super-Premium";
                            newCurrentUserInfo.newUserSetup = false;
                            newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(true);
                            setPremium(false);
                            setEnterprise(false);
                            setPromotionTab(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setCEAAgencyNumber(null);
                            setMasterTenantType(false);
                            props.setModifyCEA(false);
                        })
                    }
                }
                else {
                    await cancelMembership('backend').then(async () => {
                        if (selectElement.id == "standard") {
                            if (props.currentUserInfo.membership == "Standard") {
                                await updateDoc(doc(db, 'userInfo', user.uid), {
                                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                    subscriptionID: data.subscriptionID,
                                    newUserSetup: false,
                                    subscriptionCounter: 30,
                                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                    downgrade: deleteField()
                                }).then(() => {
                                    var newCurrentUserInfo = props.currentUserInfo;
                                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                    newCurrentUserInfo.downgrade = null;
                                    newCurrentUserInfo.newUserSetup = false;
                                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                    props.setCurrentUserInfo(newCurrentUserInfo);
                                    setMessage('Payment has been successful!')
                                    setAcknowledgeNormal(true);
                                    setCEAName(null);
                                    setCEAHP(null);
                                    setCEAAgency(null);
                                    setCEANumber(null);
                                    setChooseAdditionRole(false);
                                    setShowRole(true);
                                    setUserTypeArr([]);
                                    setShowGotSpace(false);
                                    setSecondaryRole(null);
                                    setChooseTier(false);
                                    setStandard(false);
                                    setAskAgentCEA(true);
                                    setPremium(false);
                                    setEnterprise(false);
                                    setPromotionTab(false);
                                    setPaymentTab(false);
                                    setLandlordType(false);
                                    setCEAAgencyNumber(null);
                                    setMasterTenantType(false);
                                    props.setModifyCEA(false);
                                })
                            }
                            else if (props.currentUserInfo.membership == "Premium") {
                                await updateDoc(doc(db, 'userInfo', user.uid), {
                                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                    subscriptionID: data.subscriptionID,
                                    newUserSetup: false,
                                    subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                    downgrade: "Standard"
                                }).then(() => {
                                    var newCurrentUserInfo = props.currentUserInfo;
                                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                    newCurrentUserInfo.downgrade = "Standard";
                                    newCurrentUserInfo.newUserSetup = false;
                                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                    props.setCurrentUserInfo(newCurrentUserInfo);
                                    setMessage('Payment has been successful!')
                                    setAcknowledgeNormal(true);
                                    setCEAName(null);
                                    setCEAHP(null);
                                    setCEAAgency(null);
                                    setCEANumber(null);
                                    setChooseAdditionRole(false);
                                    setShowRole(true);
                                    setUserTypeArr([]);
                                    setShowGotSpace(false);
                                    setSecondaryRole(null);
                                    setChooseTier(false);
                                    setStandard(false);
                                    setAskAgentCEA(true);
                                    setPremium(false);
                                    setEnterprise(false);
                                    setPromotionTab(false);
                                    setPaymentTab(false);
                                    setLandlordType(false);
                                    setCEAAgencyNumber(null);
                                    setMasterTenantType(false);
                                    props.setModifyCEA(false);
                                })
                            }
                            else {
                                //superpremium
                                await updateDoc(doc(db, 'userInfo', user.uid), {
                                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                    subscriptionID: data.subscriptionID,
                                    newUserSetup: false,
                                    subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                    downgrade: "Standard"
                                }).then(() => {
                                    var newCurrentUserInfo = props.currentUserInfo;
                                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                    newCurrentUserInfo.downgrade = "Standard";
                                    newCurrentUserInfo.newUserSetup = false;
                                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                    props.setCurrentUserInfo(newCurrentUserInfo);
                                    setMessage('Payment has been successful!')
                                    setAcknowledgeNormal(true);
                                    setCEAName(null);
                                    setCEAHP(null);
                                    setCEAAgency(null);
                                    setCEANumber(null);
                                    setChooseAdditionRole(false);
                                    setShowRole(true);
                                    setUserTypeArr([]);
                                    setShowGotSpace(false);
                                    setSecondaryRole(null);
                                    setChooseTier(false);
                                    setStandard(false);
                                    setAskAgentCEA(true);
                                    setPremium(false);
                                    setEnterprise(false);
                                    setPromotionTab(false);
                                    setPaymentTab(false);
                                    setLandlordType(false);
                                    setCEAAgencyNumber(null);
                                    setMasterTenantType(false);
                                    props.setModifyCEA(false);
                                })
                            }

                        }
                        else if (selectElement.id == "premium") {
                            if (props.currentUserInfo.membership == "Premium") {
                                await updateDoc(doc(db, 'userInfo', user.uid), {
                                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                    subscriptionID: data.subscriptionID,
                                    newUserSetup: false,
                                    subscriptionCounter: 30,
                                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                    downgrade: deleteField()
                                }).then(() => {
                                    var newCurrentUserInfo = props.currentUserInfo;
                                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                    newCurrentUserInfo.downgrade = null;
                                    newCurrentUserInfo.newUserSetup = false;
                                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                    props.setCurrentUserInfo(newCurrentUserInfo);
                                    setMessage('Payment has been successful!')
                                    setAcknowledgeNormal(true);
                                    setCEAName(null);
                                    setCEAHP(null);
                                    setCEAAgency(null);
                                    setCEANumber(null);
                                    setChooseAdditionRole(false);
                                    setShowRole(true);
                                    setUserTypeArr([]);
                                    setShowGotSpace(false);
                                    setSecondaryRole(null);
                                    setChooseTier(false);
                                    setStandard(false);
                                    setAskAgentCEA(true);
                                    setPremium(false);
                                    setEnterprise(false);
                                    setPromotionTab(false);
                                    setPaymentTab(false);
                                    setLandlordType(false);
                                    setCEAAgencyNumber(null);
                                    setMasterTenantType(false);
                                    props.setModifyCEA(false);
                                })
                            }
                            else if (props.currentUserInfo.membership == "Super-Premium") {
                                await updateDoc(doc(db, 'userInfo', user.uid), {
                                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                    subscriptionID: data.subscriptionID,
                                    newUserSetup: false,
                                    subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                    downgrade: "Premium"
                                }).then(() => {
                                    var newCurrentUserInfo = props.currentUserInfo;
                                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                    newCurrentUserInfo.downgrade = "Premium";
                                    newCurrentUserInfo.newUserSetup = false;
                                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                    props.setCurrentUserInfo(newCurrentUserInfo);
                                    setMessage('Payment has been successful!')
                                    setAcknowledgeNormal(true);
                                    setCEAName(null);
                                    setCEAHP(null);
                                    setCEAAgency(null);
                                    setCEANumber(null);
                                    setChooseAdditionRole(false);
                                    setShowRole(true);
                                    setUserTypeArr([]);
                                    setShowGotSpace(false);
                                    setSecondaryRole(null);
                                    setChooseTier(false);
                                    setStandard(false);
                                    setAskAgentCEA(true);
                                    setPremium(false);
                                    setEnterprise(false);
                                    setPromotionTab(false);
                                    setPaymentTab(false);
                                    setLandlordType(false);
                                    setCEAAgencyNumber(null);
                                    setMasterTenantType(false);
                                    props.setModifyCEA(false);
                                })
                            }
                            else {
                                //standard instant
                                await updateDoc(doc(db, 'userInfo', user.uid), {
                                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                    subscriptionID: data.subscriptionID,
                                    membership: "Premium",
                                    subscriptionCounter: 30,
                                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                    billingAmount: 380,
                                    chatLeft: props.currentUserInfo.chatLeft + 16,
                                    advancedDetails: props.currentUserInfo.advancedDetails + 16,
                                    newUserSetup: false
                                }).then(() => {
                                    var newCurrentUserInfo = props.currentUserInfo;
                                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                    newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 16;
                                    newCurrentUserInfo.billingAmount = 380;
                                    newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 16;
                                    newCurrentUserInfo.membership = "Premium";
                                    newCurrentUserInfo.newUserSetup = false;
                                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                    props.setCurrentUserInfo(newCurrentUserInfo);
                                    setMessage('Payment has been successful!')
                                    setAcknowledgeNormal(true);
                                    setCEAName(null);
                                    setCEAHP(null);
                                    setCEAAgency(null);
                                    setCEANumber(null);
                                    setChooseAdditionRole(false);
                                    setShowRole(true);
                                    setUserTypeArr([]);
                                    setShowGotSpace(false);
                                    setSecondaryRole(null);
                                    setChooseTier(false);
                                    setStandard(false);
                                    setAskAgentCEA(true);
                                    setPremium(false);
                                    setEnterprise(false);
                                    setPromotionTab(false);
                                    setPaymentTab(false);
                                    setLandlordType(false);
                                    setCEAAgencyNumber(null);
                                    setMasterTenantType(false);
                                    props.setModifyCEA(false);
                                })
                            }

                        }
                        else {
                            ///////////////////Super Premium////////////////
                            if (props.currentUserInfo.membership == "Super-Premium") {
                                await updateDoc(doc(db, 'userInfo', user.uid), {
                                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                    subscriptionID: data.subscriptionID,
                                    newUserSetup: false,
                                    subscriptionCounter: 30,
                                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                    downgrade: deleteField()
                                }).then(() => {
                                    var newCurrentUserInfo = props.currentUserInfo;
                                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                    newCurrentUserInfo.downgrade = null;
                                    newCurrentUserInfo.newUserSetup = false;
                                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                    props.setCurrentUserInfo(newCurrentUserInfo);
                                    setMessage('Payment has been successful!')
                                    setAcknowledgeNormal(true);
                                    setCEAName(null);
                                    setCEAHP(null);
                                    setCEAAgency(null);
                                    setCEANumber(null);
                                    setChooseAdditionRole(false);
                                    setShowRole(true);
                                    setUserTypeArr([]);
                                    setShowGotSpace(false);
                                    setSecondaryRole(null);
                                    setChooseTier(false);
                                    setStandard(false);
                                    setAskAgentCEA(true);
                                    setPremium(false);
                                    setEnterprise(false);
                                    setPromotionTab(false);
                                    setPaymentTab(false);
                                    setLandlordType(false);
                                    setCEAAgencyNumber(null);
                                    setMasterTenantType(false);
                                    props.setModifyCEA(false);
                                })
                            }
                            else if (props.currentUserInfo.membership == "Premium") {
                                await updateDoc(doc(db, 'userInfo', user.uid), {
                                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                    subscriptionID: data.subscriptionID,
                                    membership: "Super-Premium",
                                    subscriptionCounter: 30,
                                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                    billingAmount: 880,
                                    chatLeft: props.currentUserInfo.chatLeft + 40,
                                    advancedDetails: props.currentUserInfo.advancedDetails + 40,
                                    newUserSetup: false
                                }).then(() => {
                                    var newCurrentUserInfo = props.currentUserInfo;
                                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                    newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 40;
                                    newCurrentUserInfo.billingAmount = 880;
                                    newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 40;
                                    newCurrentUserInfo.membership = "Super-Premium";
                                    newCurrentUserInfo.newUserSetup = false;
                                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                    props.setCurrentUserInfo(newCurrentUserInfo);
                                    setMessage('Payment has been successful!')
                                    setAcknowledgeNormal(true);
                                    setCEAName(null);
                                    setCEAHP(null);
                                    setCEAAgency(null);
                                    setCEANumber(null);
                                    setChooseAdditionRole(false);
                                    setShowRole(true);
                                    setUserTypeArr([]);
                                    setShowGotSpace(false);
                                    setSecondaryRole(null);
                                    setChooseTier(false);
                                    setStandard(false);
                                    setAskAgentCEA(true);
                                    setPremium(false);
                                    setEnterprise(false);
                                    setPromotionTab(false);
                                    setPaymentTab(false);
                                    setLandlordType(false);
                                    setCEAAgencyNumber(null);
                                    setMasterTenantType(false);
                                    props.setModifyCEA(false);
                                })
                            }
                            else {
                                //standard instant upgrade
                                await updateDoc(doc(db, 'userInfo', user.uid), {
                                    oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                    subscriptionID: data.subscriptionID,
                                    membership: "Super-Premium",
                                    subscriptionCounter: 30,
                                    freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                    billingAmount: 880,
                                    chatLeft: props.currentUserInfo.chatLeft + 40,
                                    advancedDetails: props.currentUserInfo.advancedDetails + 40,
                                    newUserSetup: false
                                }).then(() => {
                                    var newCurrentUserInfo = props.currentUserInfo;
                                    newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                    newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 40;
                                    newCurrentUserInfo.billingAmount = 880;
                                    newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 40;
                                    newCurrentUserInfo.membership = "Super-Premium";
                                    newCurrentUserInfo.newUserSetup = false;
                                    newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                    props.setCurrentUserInfo(newCurrentUserInfo);
                                    setMessage('Payment has been successful!')
                                    setAcknowledgeNormal(true);
                                    setCEAName(null);
                                    setCEAHP(null);
                                    setCEAAgency(null);
                                    setCEANumber(null);
                                    setChooseAdditionRole(false);
                                    setShowRole(true);
                                    setUserTypeArr([]);
                                    setShowGotSpace(false);
                                    setSecondaryRole(null);
                                    setChooseTier(false);
                                    setStandard(false);
                                    setAskAgentCEA(true);
                                    setPremium(false);
                                    setEnterprise(false);
                                    setPromotionTab(false);
                                    setPaymentTab(false);
                                    setLandlordType(false);
                                    setCEAAgencyNumber(null);
                                    setMasterTenantType(false);
                                    props.setModifyCEA(false);
                                })
                            }

                        }
                    })
                }
            })


        };



        const paypalOnError = (err) => {
            console.log("Error", err)
        }

        const cancelMembership = (task) => {


            setActiveSpin(true);
            // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/cancelSubscription
            // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/cancelSubscription
            return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + user.accessToken
                },
                body: JSON.stringify({
                    data: {
                        orderID: props.currentUserInfo.subscriptionID,
                        // capturedDetails: details,
                    }
                }),
            })
                .then(async () => {

                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID
                    })
                        .then(() => {
                            if (task == "backend") {
                                setActiveSpin(false);
                                setMessage("Your membership will be changed at the end of the current billing cycle, and your subscription will not be automatically renewed thereafter.");
                                setAcknowledgeNormal(true);
                                return;
                            }
                            else {
                                setActiveSpin(false);
                                return;
                            }
                        })



                })



        }

        const proceedPromotion = async () => {
            var promotionDoc = await getDoc(doc(db, 'promotionCode', 'promoCode'));
            var promotionCount = await getDoc(doc(db, 'promotionCode', 'trackingPromo'));
            if (promoCode != promotionDoc.data().code && promoCode != promotionDoc.data().agentPromo && promoCode != promotionDoc.data().shaunPromo) {
                alert("Invalid Code");
            }
            else {
                if (props.currentUserInfo.launchedPromotionCode.includes(promoCode) == false) {
                    var newLaunchedPromotionCode = [...props.currentUserInfo.launchedPromotionCode];
                    newLaunchedPromotionCode.push(promoCode)
                    if (promoCode == promotionDoc.data().agentPromo) {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            subscriptionID: "PromotionCode",
                            membership: "Premium",
                            billingAmount: 380,
                            chatLeft: props.currentUserInfo.chatLeft + 32,
                            advancedDetails: props.currentUserInfo.advancedDetails + 32,
                            startDate: new Date(),
                            subscriptionPeriod: "Monthly",
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter + 60,
                            launchedPromotionCode: newLaunchedPromotionCode,
                            freeAppt: true
                        }).then(async () => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            var agentProfile = {
                                CEAName: CEAName,
                                CEAHP: CEAHP,
                                CEAAgency: CEAAgency,
                                CEANumber: CEANumber,
                                CEAAgencyNumber: CEAAgencyNumber,
                                verified: false
                            }
                            await updateDoc(doc(db, 'publicUserInfo', user.uid), {
                                agentProfile: agentProfile
                            }).then(() => {
                                var newPublicInfo = props.publicInfo;
                                newPublicInfo.agentProfile = agentProfile;
                                newCurrentUserInfo.subscriptionID = "PromotionCode";
                                newCurrentUserInfo.membership = "Premium";
                                newCurrentUserInfo.billingAmount = 380;
                                newCurrentUserInfo.chatLeft = props.currentUserInfo.chatLeft + 32;
                                newCurrentUserInfo.advancedDetails = props.currentUserInfo.advancedDetails + 32;
                                newCurrentUserInfo.startDate = new Date();
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.subscriptionCounter = props.currentUserInfo.subscriptionCounter + 60;
                                newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;
                                props.setPublicInfo(newPublicInfo);
                                setMessage("Promotion Code Verified. Once your trail period has ended, select a tier to continue your subscription");
                                setAcknowledgeNormal(true);
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setPromotionTab(false);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setCEAAgencyNumber(null);
                                setMasterTenantType(false);
                                // props.setTrigger(false);
                                props.setModifyCEA(false);

                            })

                        })
                    }
                    else {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            subscriptionID: "PromotionCode",
                            membership: "Premium",
                            billingAmount: 380,
                            chatLeft: props.currentUserInfo.chatLeft + 16,
                            advancedDetails: props.currentUserInfo.advancedDetails + 16,
                            startDate: new Date(),
                            subscriptionPeriod: "Monthly",
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter + 30,
                            launchedPromotionCode: newLaunchedPromotionCode,
                            freeAppt: true
                        }).then(async () => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            var agentProfile = {
                                CEAName: CEAName,
                                CEAHP: CEAHP,
                                CEAAgency: CEAAgency,
                                CEANumber: CEANumber,
                                CEAAgencyNumber: CEAAgencyNumber,
                                verified: false
                            }
                            if (promoCode == promotionDoc.data().shaunPromo) {
                                await updateDoc(doc(db, 'promotionCode', 'trackingPromo'), {
                                    shaunPromoCount: promotionCount.data().shaunPromoCount + 1
                                })
                            }
                            await updateDoc(doc(db, 'publicUserInfo', user.uid), {
                                agentProfile: agentProfile
                            }).then(() => {
                                var newPublicInfo = props.publicInfo;
                                newPublicInfo.agentProfile = agentProfile;
                                newCurrentUserInfo.subscriptionID = "PromotionCode";
                                newCurrentUserInfo.membership = "Premium";
                                newCurrentUserInfo.billingAmount = 380;
                                newCurrentUserInfo.chatLeft = props.currentUserInfo.chatLeft + 16;
                                newCurrentUserInfo.advancedDetails = props.currentUserInfo.advancedDetails + 16;
                                newCurrentUserInfo.startDate = new Date();
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.subscriptionCounter = props.currentUserInfo.subscriptionCounter + 30;
                                newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;
                                props.setPublicInfo(newPublicInfo);
                                setMessage("Promotion Code Verified. Once your trail period has ended, select a tier to continue your subscription");
                                setAcknowledgeNormal(true);
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setPromotionTab(false);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setCEAAgencyNumber(null);
                                setMasterTenantType(false);
                                // props.setTrigger(false);
                                props.setModifyCEA(false);

                            })

                        })
                    }
                }
                else {
                    alert("You have already redeemed the launch bonus")
                }

            }


        }


        const skipForNow = async () => {

            var agentProfile = {
                CEAName: CEAName,
                CEAHP: CEAHP,
                CEAAgency: CEAAgency,
                CEANumber: CEANumber,
                CEAAgencyNumber: CEAAgencyNumber,
                verified: false
            }

            await updateDoc(doc(db, 'publicUserInfo', user.uid), {
                agentProfile: agentProfile
            }).then(() => {
                var newCurrentUserInfo = props.currentUserInfo;
                var newPublicInfo = props.publicInfo;
                newPublicInfo.agentProfile = agentProfile;
                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                props.setPublicInfo(newPublicInfo);
                props.setCurrentUserInfo(newCurrentUserInfo);
                setCEAName(null);
                setCEAHP(null);
                setCEAAgency(null);
                setPromotionTab(false);
                setCEANumber(null);
                setChooseAdditionRole(false);
                setShowRole(true);
                setUserTypeArr([]);
                setShowGotSpace(false);
                setSecondaryRole(null);
                setChooseTier(false);
                setStandard(false);
                setAskAgentCEA(false);
                setPremium(false);
                setEnterprise(false);
                setPaymentTab(false);
                setLandlordType(false);
                setCEAAgencyNumber(null);
                setMasterTenantType(false);
                props.setTrigger(false);
            })

        }





        return (
            <>
                <Spinner trigger={activeSpin} setTrigger={setActiveSpin}></Spinner>
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={message} parentTrigger={props.setTrigger}></AcknowledgeNormal>
                {/* <Acknowledge trigger={acknowledge} setTrigger={setAcknowledge} message={message}></Acknowledge> */}
                <div className='modalNewProject' style={{ zIndex: "2" }}>
                    <div className='modal-contentUserType'>

                        <>

                            <div id="primaryRoleTab" style={askAgentCEA ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject' >
                                    {props.modifyCEA == true ? <div className="notification-close" onClick={() => closeUserTypeModal()}>
                                        ×
                                    </div> : null}
                                    <h4 className='modal-titleNewProject'>AGENT DETAILS</h4>
                                </div>

                                <div className='modal-bodyUserType' style={{ display: "block", margin: "1%" }}>
                                    <p style={{ fontWeight: "bold" }}>Name [As stated in CEA]</p>
                                    <input id="CEAName" style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "8px 10px", width: "100%" }} placeholder='Full name' value={CEAName} onChange={(e) => { setCEAName(e.target.value) }}></input>
                                    <p style={{ fontWeight: "bold", marginTop: "1%" }}>Mobile Number [As stated in CEA]</p>
                                    <input id="CEAHP" style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "8px 10px", width: "100%" }} placeholder='+65 1234 1234' value={CEAHP} onChange={(e) => { setCEAHP(e.target.value) }}></input>
                                    <p style={{ fontWeight: "bold", marginTop: "1%" }}>Agency</p>
                                    <input id="CEAAgency" style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "8px 10px", width: "100%" }} placeholder='Company name' value={CEAAgency} onChange={(e) => { setCEAAgency(e.target.value) }}></input>
                                    <p style={{ fontWeight: "bold", marginTop: "1%" }}>Agency Number</p>
                                    <input id="CEAAgencyNumber" style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "8px 10px", width: "100%" }} placeholder='Agency Number' value={CEAAgencyNumber} onChange={(e) => { setCEAAgencyNumber(e.target.value) }}></input>
                                    <p style={{ fontWeight: "bold", marginTop: "1%" }}>CEA Number</p>
                                    <input id="CEANumber" style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "8px 10px", width: "100%" }} placeholder='12312391239' value={CEANumber} onChange={(e) => { setCEANumber(e.target.value) }}></input>
                                    <p style={{ fontWeight: "bold", marginTop: "1%" }}>Agent Profile Deck</p>
                                    <button class="defaultUploadBtn" onClick={() => { uploadSelfPDF.current.click() }}>Upload PDF</button>
                                    <input ref={uploadSelfPDF} id="file-upload" type="file" accept=".pdf" style={{ display: "none" }} onChange={(event) => { setPDFCEA(event.target.files[0]) }}/>
                                    {reason != '' ? <>
                                        <p style={{ fontWeight: "bold", marginTop: "1%" }}>Reason of unverified</p>
                                        <p>{reason}</p>
                                    </> : null}
                                </div>

                                <div className='modal-footerNewProject'>


                                    <button id="addPropertyBtn" onClick={() => { proceedUpdatingRole() }}>Proceed</button>
                                    {/* <button id="addPropertyBtn" style={{ marginRight: "3%" }} onClick={() => { setChooseAdditionRole(true); setAskAgentCEA(false); setSecondaryRole(null); }}>Back</button> */}
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>

                            <div id="primaryRoleTab" style={chooseTier ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject' >
                                    <button style={{ backgroundColor: "#C4E5E0", width: "80%", marginLeft: "10%", padding: "8px 8px 8px 8px", border: "none", cursor: "pointer", borderRadius: "10px", marginTop: "15px" }} onClick={() => { skipForNow() }}><b>SKIP FOR NOW</b></button>

                                    <h4 className='modal-titleNewProject'>CHOOSE YOUR PLAN</h4>
                                </div>

                                <div className='modal-bodyUserType' style={{ display: "block" }}>
                                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                        <thead>
                                            <tr style={{ backgroundColor: "#316190" }}>
                                                <th></th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Standard</p>
                                                    <p style={{ margin: 0 }}>$180</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Premium</p>
                                                    <p style={{ margin: 0 }}>$380</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Super Premium</p>
                                                    <p style={{ margin: 0 }}>$880</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Enterprise</p>
                                                    <p style={{ margin: 0 }}>Starting from $3000</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>

                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Rental</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Sales</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Connections & Advanced Details</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p>7</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><p>16</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p>40</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Photos per property profile</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 5 photos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Maximum of 12 photos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 20 photos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Videos per property profile</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>1 video</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Maximum of 3 videos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 5 videos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Top up</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px" }}></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={standard ? "select" : "notSelect"} id="standard" onClick={() => { setStandard(!standard); setPremium(false); setSuperPremium(false); setEnterprise(false) }}>Select</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={premium ? "select" : "notSelect"} id="premium" onClick={() => { setStandard(false); setPremium(!premium); setSuperPremium(false); setEnterprise(false) }}>Select</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={superPremium ? "select" : "notSelect"} id="superPremium" onClick={() => { setStandard(false); setPremium(false); setSuperPremium(!superPremium); setEnterprise(false) }}>Select</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={enterprise ? "select" : "notSelect"} id="enterprise" onClick={() => { setStandard(false); setPremium(false); setSuperPremium(false); setEnterprise(!enterprise) }}>Select</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style={{ fontSize: "14px", color: "grey", marginLeft: "10px" }}>*$80 for a first-time set-up fee</p>
                                </div>

                                <div className='modal-footerNewProject'>

                                    <button id="addPropertyBtn" onClick={() => { setChooseTier(false); setPaymentTab(true) }}>Proceed</button>
                                    <button id="addPropertyBtn" onClick={() => { setChooseTier(false); setPromotionTab(true) }} style={{ marginRight: "2%" }}>Promo Code</button>
                                    <button id="addPropertyBtn" style={{ marginRight: "2%" }} onClick={() => { setAskAgentCEA(true); setChooseTier(false); }}>Back</button>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>
                            <div id="promoTab" style={promotionTab ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject'>

                                    <h4 className='modal-titleNewProject'>PROMOTION CODE</h4>
                                </div>

                                <div className='modal-bodyUserType' id="tierUserType">

                                    <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>

                                        <div>
                                            <center>

                                                <input style={{ padding: "10px 10px", width: "80%", borderRadius: "10px" }} placeholder='Key in your promotion code' value={promoCode} onChange={(e) => setPromoCode(e.target.value)}></input>

                                            </center>
                                        </div>
                                    </div>
                                </div>


                                <div className='modal-footerNewProject'>

                                    <button id="addPropertyBtn" onClick={() => proceedPromotion()}>Proceed</button>
                                    <button id="addPropertyBtn" onClick={() => { setChooseTier(true); setPromotionTab(false) }} style={{ marginRight: "2%" }}>Back</button>
                                    <div style={{ clear: 'both' }}></div>

                                </div>

                            </div>

                            <div id="paymentTab" style={paymentTab ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject'>

                                    <h4 className='modal-titleNewProject'>PAYMENT</h4>
                                </div>

                                <div className='modal-bodyUserType' id="tierUserType">

                                    <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                                        <PayPalScriptProvider
                                            options={{
                                                "client-id": "AUj95SHnSm6iN1zjvQnpSFuwehgQqPmaO2ZX9ZGFdLX_uNVWVZMnKwedYOeTmDp8UzxlLtlclw1SCnP-",
                                                currency: "SGD",
                                                intent: "subscription",
                                                vault: true
                                            }}
                                        >
                                            <PayPalButtons
                                                style={{ label: 'subscribe' }}
                                                createSubscription={(data, actions) => createSubscription(data, actions)}
                                                onApprove={(data, actions) => onApprove(data, actions)}
                                                catchError={paypalOnError}
                                                onError={paypalOnError}
                                                onCancel={paypalOnError}
                                            />
                                        </PayPalScriptProvider>
                                    </div>
                                </div>
                                <div className='modal-footerNewProject'>
                                    <button id="addPropertyBtn" onClick={() => { setChooseTier(true); setPaymentTab(false) }} >Back</button>

                                    <div style={{ clear: 'both' }}></div>

                                </div>

                            </div>





                        </>
                    </div>
                </div>
            </>
        )
    }
}




