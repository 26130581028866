import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import '../css/Product.css';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { collection, getDocs, onSnapshot, doc, query, orderBy, startAfter, limit, getDoc, setDoc, deleteDoc, where, Timestamp, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../../firebase/firebase'
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Footer from '../Footer';
import Notification from './Notification';
import ZoomPicture from './ZoomPictures';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';



export default function Products() {
  const searchbarRef = useRef(null);
  const searchbarRefMobile = useRef(null);
  const moreFilterRef = useRef(null);
  const [combineAllData, SetCombineAllData] = useState([]);
  const [currentListing, setCurrentListing] = useState([]);
  const [eyeBallAdvListingID, setEyeballAdvListingID] = useState([]);
  const [shortList, setShortList] = useState(null);
  const { user, deleteUserData } = UserAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [minPriceBud, setMinPriceBud] = useState(0);
  const [locationDetails, setLocationDetails] = useState(null);
  const [minLeaseNum, setMinLeaseNum] = useState("NA");
  const [minLeasePeriod, setMinLeasePeriod] = useState('Days');
  const [maxPriceBud, setMaxPriceBud] = useState("NA");
  const [maxLeasePeriod, setMaxLeasePeriod] = useState('Days');
  const [showFilters, setShowFilters] = useState(false);
  const [maxLeaseNum, setMaxLeaseNum] = useState("NA");
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [filterProject, setFilterProject] = useState(null);
  const [totalProject, setTotalProject] = useState(0);
  const [propertyType, setPropertyType] = useState('All');
  const [filterProjectSort, setFilterProjectSort] = useState(null);
  const [filterName, setFilterName] = useState(null);
  const [minOption, setMinOption] = useState(true);
  const [isAll, setIsAll] = useState(true);
  const [isFB, setIsFB] = useState(false);
  const [isRetail, setIsRetail] = useState(false);
  const [isService, setIsService] = useState(false);
  const [isStorage, setIsStorage] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [zoomPic, setZoomPic] = useState(false);
  const [zoomPicURL, setZoomPicURL] = useState(null);
  const [picturesIndex, setPicturesIndex] = useState([0, 1, 2, 3, 4]);





  useEffect(() => {
    // getFirstPage();
    getAllData().then((res) => {
      SetCombineAllData(res);
      setTotalProject(res.length);
      checkForPreviousPage(res);
    });

  }, []);

  useEffect(() => {
    if (eyeBallAdvListingID.length != 0) {
      activateAdvEyeball();
    }
  }, [eyeBallAdvListingID])

  const activateAdvEyeball = async () => {
    for (var i = 0; i < eyeBallAdvListingID.length; i++) {
      var newEyeballStat = [...eyeBallAdvListingID[i].details.eyeballStat];
      newEyeballStat[0] = newEyeballStat[0] + 1;
      await updateDoc(doc(db, 'advertiseListing', eyeBallAdvListingID[i].id), {
        eyeball: eyeBallAdvListingID[i].details.eyeball + 1,
        eyeballStat: newEyeballStat
      })
    }
  }

  useEffect(() => {
    const dataString = sessionStorage.getItem('filterHome');
    const filterHomeData = JSON.parse(dataString);
    if (filterHomeData != null && combineAllData.length != 0) {
      searchApply();
    }
  }, [combineAllData])

  useEffect(() => {

    if (!user) {
    }
    else {
      if (user.uid != undefined) {
        const subscriber = onSnapshot(collection(db, `userInfo/${user.uid}/shortList`), (doc) => {
          setShortList(doc.docs)
        });
        return (() => { subscriber() });
      }
    }

  }, [user]);
  // useEffect(() => {
  //   // add event listener to handle clicks outside of the searchbar
  //   function handleClickOutside(event) {
  //     // if (searchbarRef.current && !searchbarRef.current.contains(event.target)) {
  //     //   setShowFilters(false);
  //     // }
  //     if (searchbarRef.current && !searchbarRef.current.contains(event.target)) {
  //       if (moreFilterRef.current && moreFilterRef.current.contains(event.target)) {
  //         // ignore clicks inside the moreFilterProduct div
  //         return;
  //       }
  //       setShowFilters(false);
  //     }
  //   }

  //   document.addEventListener('mousedown', handleClickOutside);
  //   const filterButton = document.querySelector('.btn--filter');

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //     filterButton.removeEventListener('click', handleClick);
  //   };
  // }, [showFilters]);
  useEffect(() => {
    function handleClickOutside(event) {
      if (window.innerWidth <= 540) {
        if (searchbarRefMobile.current && !searchbarRefMobile.current.contains(event.target)) {
          if (moreFilterRef.current && moreFilterRef.current.contains(event.target)) {
            return;
          }
          setFilterName(null);
          setShowFilters(false);
        }
      }
      else {
        if (searchbarRef.current && !searchbarRef.current.contains(event.target)) {
          if (moreFilterRef.current && moreFilterRef.current.contains(event.target)) {
            return;
          }
          setFilterName(null);
          setShowFilters(false);
        }
      }
    }



    document.addEventListener('mousedown', handleClickOutside);
    const filterButton = document.querySelector('.btn--filter');
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      filterButton.removeEventListener('click', handleClick);
    };
  }, [showFilters]);


  useEffect(() => {
    const checkingUser = async () => {
      var docsnap = await getDoc(doc(db, 'userInfo', user.uid));
      if (docsnap.exists()) {
      }
      else {
        // alert("hacked your way through ah");
        deleteUserData();

      }
    }
    if (user != null) {
      if (Object.keys(user).length != 0) {
        checkingUser();
      }
    }
  }, [user])

  const handleAllCheckBoxChange = () => {
    setIsAll(!isAll);
    setIsFB(false);
    setIsRetail(false);
    setIsService(false);
    setIsOther(false);
    setIsStorage(false);
  }

  const handleFBCheckBoxChange = () => {
    setIsAll(false);
    setIsFB(!isFB);
  }

  const handleRetailCheckBoxChange = () => {
    setIsAll(false);
    setIsRetail(!isRetail);
  }

  const handleServiceCheckBoxChange = () => {
    setIsAll(false);
    setIsService(!isService);
  }

  const handleStorageCheckBoxChange = () => {
    setIsAll(false);
    setIsStorage(!isStorage);
  }

  const handleOtherCheckBoxChange = () => {
    setIsAll(false);
    setIsOther(!isOther);
  }

  const handlesMinPriceBud = (event) => {
    if (event.target.value == "") {
      setMinPriceBud(0);
    }
    else {
      setMinPriceBud(event.target.value);
    }

  }

  const handleLocationDetails = (event) => {
    setLocationDetails(event.target.value);
  }

  const handleMinLeaseNum = (event) => {
    setMinLeaseNum(event.target.value);
  }

  const handleMinLeasePeriod = (event) => {
    setMinLeaseNum(1);
    setMinLeasePeriod(event.target.value);
  }

  const handleMaxPriceBud = (event) => {
    if (event.target.value == "") {
      setMaxPriceBud("NA");
    }
    else {
      setMaxPriceBud(event.target.value);
    }

  }



  const handleMaxLeaseNum = (event) => {
    setMaxLeaseNum(event.target.value);
  }

  const handleMaxLeasePeriod = (event) => {
    setMaxLeaseNum(1);
    setMaxLeasePeriod(event.target.value);
  }

  const handleClick = (event) => {
    if (event.target.id == "BudgetFilterDropDown") {
      event.target.id = "BudgetFilter";
    }
    else if (event.target.id == "LeaseFilterDropDown") {
      event.target.id = "LeaseFilter";
    }
    else if (event.target.id == "PropertyFilterDropdown") {
      event.target.id = "PropertyFilter";
    }

    if (event.target.id == "") {
      return;
    }
    else {
      if (event.target.id == filterName) {
        setFilterName(null);
        // setShow(!show);
        setShowFilters(false);
        // setTarget(event.target);
      }
      else if (filterName == null) {
        setFilterName(event.target.id);
        // setShow(!show);
        setShowFilters(true);
        // setTarget(event.target);
      }
      else {
        setFilterName(event.target.id);
      }
    }
  };

  const handleSortingOrder = (event) => {

    var sortingFilteringProject = filterProject;

    if (event.target.value == "Adescending") {
      sortingFilteringProject.sort((a, b) => a.details.priceOverall - b.details.priceOverall);
      setFilterProject(sortingFilteringProject);
      setCurrentPage(1);
      setCurrentListing(sortingFilteringProject.slice(0, 40));
    }
    else if (event.target.value == "Descending") {
      sortingFilteringProject.sort((a, b) => b.details.priceOverall - a.details.priceOverall);
      setFilterProject(sortingFilteringProject);
      setCurrentPage(1);
      setCurrentListing(sortingFilteringProject.slice(0, 40));
    }
    else {
      setFilterProject(filterProjectSort);
      setCurrentPage(1);
      setCurrentListing(filterProjectSort.slice(0, 40));
    }


  }



  // useEffect(() => {
  //   if (showFilters) {
  //     handlePropertyClick(propertyType);
  //   }
  // }, [showFilters])

  const onPageChanged = (event, value) => {
    var all = [...combineAllData];

    setCurrentPage(value);
    var needUpEyeballAdv = [];
    var newListing = all.slice(((value - 1) * 40), (((value - 1) * 40) + 40));
    for (var i = 0; i < newListing.length; i++) {
      if (newListing[i].details.hasOwnProperty('activatedDate')) {
        needUpEyeballAdv.push(newListing[i]);
      }
    }
    setEyeballAdvListingID(needUpEyeballAdv);
    setCurrentListing(all.slice(((value - 1) * 40), (((value - 1) * 40) + 40)));
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth'
    // });
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    var savedValue = sessionStorage.getItem('currentTab');
    if (savedValue != null) {
      sessionStorage.removeItem('currentTab');
    }
  }, [currentListing])


  const searchApply = () => {
    setShowFilters(false);
    setFilterName(null);
    var filteringProject = [...combineAllData];
    const dataString = sessionStorage.getItem('filterHome');
    const filterHomeData = JSON.parse(dataString);
    if (filterHomeData != null) {
      sessionStorage.removeItem('filterHome');
    }

    searchBarFilter(filteringProject, filterHomeData).then((res) => searchPropertyType(res, filterHomeData).then((res) => searchPrice(res, filterHomeData)
      .then((res) => searchLease(res, filterHomeData).then((res) => {
        if (filterHomeData != null) {
          setPropertyType(filterHomeData.propertyType);
          setMinPriceBud(filterHomeData.minPriceBud);
          setLocationDetails(filterHomeData.locationDetails);
          setMinLeaseNum(filterHomeData.minLeaseNum);
          setMinLeasePeriod(filterHomeData.minLeasePeriod);
          setMaxLeaseNum(filterHomeData.maxLeaseNum);
          setMaxLeasePeriod(filterHomeData.maxLeasePeriod);
          setMaxPriceBud(filterHomeData.maxPriceBud);
        }
        setShow(false);
        setFilterProjectSort(res);
        setFilterProject(res);
        setCurrentPage(1);
        setCurrentListing(res.slice(0, 40));
      }))
    ));
  }

  const searchBarFilter = (filteringProject, filterHomeData) => {
    return new Promise((resolve, reject) => {



      if (locationDetails != null || filterHomeData != null) {

        if (filterHomeData == null) {
          var searchBarLocation = locationDetails.toUpperCase();
          if (searchBarLocation == "D1") {
            searchBarLocation = "D01";
            setLocationDetails("D01");
          }
          else if (searchBarLocation == "D2") {
            searchBarLocation = "D02";
            setLocationDetails("D02");
          }
          else if (searchBarLocation == "D3") {
            searchBarLocation = "D03";
            setLocationDetails("D03");
          }
          else if (searchBarLocation == "D4") {
            searchBarLocation = "D04";
            setLocationDetails("D04");
          }
          else if (searchBarLocation == "D5") {
            searchBarLocation = "D05";
            setLocationDetails("D05");
          }
          else if (searchBarLocation == "D6") {
            searchBarLocation = "D06";
            setLocationDetails("D06");
          }
          else if (searchBarLocation == "D7") {
            searchBarLocation = "D07";
            setLocationDetails("D07");
          }
          else if (searchBarLocation == "D8") {
            searchBarLocation = "D08";
            setLocationDetails("D08");
          }
          else if (searchBarLocation == "D9") {
            searchBarLocation = "D09";
            setLocationDetails("D09");
          }


          for (var i = filteringProject.length - 1; i >= 0; i--) {
            if ('advertiseName' in filteringProject[i].details) {
              filteringProject.splice(i, 1);
            }
            else {
              if (((filteringProject[i].details.district).toUpperCase()).search(searchBarLocation) < 0 && ((filteringProject[i].details.location).toUpperCase()).search(searchBarLocation) < 0 && ((filteringProject[i].details.listingName).toUpperCase()).search(searchBarLocation) < 0) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
            }

          }
        }
        else {
          if (filterHomeData.locationDetails != null) {
            var searchBarLocation = filterHomeData.locationDetails.toUpperCase();
            if (searchBarLocation == "D1") {
              searchBarLocation = "D01";
              setLocationDetails("D01");
            }
            else if (searchBarLocation == "D2") {
              searchBarLocation = "D02";
              setLocationDetails("D02");
            }
            else if (searchBarLocation == "D3") {
              searchBarLocation = "D03";
              setLocationDetails("D03");
            }
            else if (searchBarLocation == "D4") {
              searchBarLocation = "D04";
              setLocationDetails("D04");
            }
            else if (searchBarLocation == "D5") {
              searchBarLocation = "D05";
              setLocationDetails("D05");
            }
            else if (searchBarLocation == "D6") {
              searchBarLocation = "D06";
              setLocationDetails("D06");
            }
            else if (searchBarLocation == "D7") {
              searchBarLocation = "D07";
              setLocationDetails("D07");
            }
            else if (searchBarLocation == "D8") {
              searchBarLocation = "D08";
              setLocationDetails("D08");
            }
            else if (searchBarLocation == "D9") {
              searchBarLocation = "D09";
              setLocationDetails("D09");
            }
            else {
              setLocationDetails(filterHomeData.locationDetails);
            }
            for (var i = filteringProject.length - 1; i >= 0; i--) {
              if ('advertiseName' in filteringProject[i].details) {
                filteringProject.splice(i, 1);
              }
              else {
                if (((filteringProject[i].details.district).toUpperCase()).search(searchBarLocation) < 0 && ((filteringProject[i].details.location).toUpperCase()).search(searchBarLocation) < 0 && ((filteringProject[i].details.listingName).toUpperCase()).search(searchBarLocation) < 0) {
                  filteringProject.splice(i, 1);
                  // delete filteringProject[i];
                }
              }
            }
          }

        }

      }
      resolve(filteringProject);
    })
  }

  const searchPropertyType = (filteringProject, filterHomeData) => {
    return new Promise((resolve, reject) => {
      if (isAll == false || filterHomeData != null) {
        for (var i = filteringProject.length - 1; i >= 0; i--) {
          if ('advertiseName' in filteringProject[i].details) {
            filteringProject.splice(i, 1);
          }
          else {
            if (filterHomeData != null) {

              if (filterHomeData.propertyType.includes('F&B')) {
                setIsFB(true);
              }
              if (filterHomeData.propertyType.includes('Others')) {
                setIsOther(true);
              }
              if (filterHomeData.propertyType.includes('Retail')) {
                setIsRetail(true);
              }
              if (filterHomeData.propertyType.includes('Services')) {
                setIsService(true);
              }
              if (filterHomeData.propertyType.includes('Storage')) {
                setIsStorage(true);
              }
              if (filterHomeData.propertyType.includes("All") == false) {
                setIsAll(false);
                if (filterHomeData.propertyType.includes(filteringProject[i].details.propertyType) == false) {
                  filteringProject.splice(i, 1);
                }

              }
              else {
                setIsAll(true);
              }
            }
            else {
              var searchPropertyArr = [];
              if (isFB) {
                searchPropertyArr.push("F&B");
              }
              if (isOther) {
                searchPropertyArr.push("Others");
              }
              if (isRetail) {
                searchPropertyArr.push("Retail");
              }
              if (isService) {
                searchPropertyArr.push("Services");
              }
              if (isStorage) {
                searchPropertyArr.push("Storage");
              }
              if (searchPropertyArr.includes(filteringProject[i].details.propertyType) == false) {
                filteringProject.splice(i, 1);
              }


            }
          }



        }
      }
      resolve(filteringProject);
    })
  }

  const searchPrice = (filteringProject, filterHomeData) => {
    return new Promise((resolve, reject) => {
      if (minPriceBud > 0 || maxPriceBud > 0 || filterHomeData != null) {
        for (var i = filteringProject.length - 1; i >= 0; i--) {
          if ('advertiseName' in filteringProject[i].details) {
            filteringProject.splice(i, 1);
          }
          else {
            if (filterHomeData == null) {
              if (parseInt(minPriceBud) != "NA" && maxPriceBud != "NA" && (parseInt(filteringProject[i].details.priceOverall) < parseInt(minPriceBud) || parseInt(filteringProject[i].details.priceOverall) > parseInt(maxPriceBud))) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
              else if (parseInt(minPriceBud) != "NA" && parseInt(filteringProject[i].details.priceOverall) < parseInt(minPriceBud) && maxPriceBud == "NA") {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
              else if (parseInt(maxPriceBud) != "NA" && parseInt(filteringProject[i].details.priceOverall) > parseInt(maxPriceBud) && minPriceBud == "NA") {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
            }
            else {

              if (parseInt(filterHomeData.minPriceBud) != "NA" && parseInt(filterHomeData.maxPriceBud) != "NA" && (parseInt(filteringProject[i].details.priceOverall) < parseInt(filterHomeData.minPriceBud) || parseInt(filteringProject[i].details.priceOverall) > parseInt(filterHomeData.maxPriceBud))) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
              else if (parseInt(filterHomeData.minPriceBud) != "NA" && parseInt(filteringProject[i].details.priceOverall) < parseInt(filterHomeData.minPriceBud) && parseInt(filterHomeData.maxPriceBud) <= 0) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
              else if (parseInt(filterHomeData.minPriceBud) != "NA" && parseInt(filteringProject[i].details.priceOverall) > parseInt(filterHomeData.maxPriceBud) && parseInt(filterHomeData.minPriceBud) <= 0) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
            }
          }


        }
      }
      resolve(filteringProject);
    })
  }

  const searchLease = (filteringProject, filterHomeData) => {
    return new Promise((resolve, reject) => {


      if (minLeaseNum != "NA" || maxLeaseNum != "NA" || filterHomeData != null) {
        var newFilteringIndexLease = [];

        for (var i = filteringProject.length - 1; i >= 0; i--) {
          if ('advertiseName' in filteringProject[i].details) {
            filteringProject.splice(i, 1);
          }
          else {
            if (filterHomeData == null) {
              console.log(filteringProject[i])

              var splitLeaseMin = filteringProject[i].details.leaseTermMin.split(' ');
              var splitLeaseMax = filteringProject[i].details.leaseTermMax.split(' ');
              if (minLeaseNum != "NA" && maxLeaseNum != "NA") {
                if (minLeasePeriod == "Days" && maxLeasePeriod == "Days") {
                  if (splitLeaseMax[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Days" && parseInt(splitLeaseMin[0]) < minLeaseNum) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Days" && parseInt(splitLeaseMax[0]) > maxLeaseNum) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
                else if (minLeasePeriod == "Days" && maxLeasePeriod == "Months") {
                  if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Days" && parseInt(splitLeaseMin[0]) < minLeaseNum) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Months" && parseInt(splitLeaseMax[0]) > maxLeaseNum) {
                        filteringProject.splice(i, 1);
                      }
                    }

                  }
                }
                else if (minLeasePeriod == "Days" && maxLeasePeriod == "Years") {
                  if (splitLeaseMin[1] == "Days" && parseInt(splitLeaseMin[0]) < minLeaseNum) {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMax[1] == "Years" && parseInt(splitLeaseMax[0]) > maxLeaseNum) {
                      filteringProject.splice(i, 1);
                    }
                  }
                }
                else if (minLeasePeriod == "Months" && maxLeasePeriod == "Days") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Months" && parseInt(splitLeaseMin[0]) < minLeaseNum) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Days" && parseInt(splitLeaseMax[0]) > maxLeaseNum) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
                else if (minLeasePeriod == "Months" && maxLeasePeriod == "Months") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Months" && parseInt(splitLeaseMin[0]) < minLeaseNum) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Months" && parseInt(splitLeaseMax[0]) > maxLeaseNum) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
                else if (minLeasePeriod == "Months" && maxLeasePeriod == "Years") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Months" && parseInt(splitLeaseMin[0]) < minLeaseNum) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Years" && parseInt(splitLeaseMax[0]) > maxLeaseNum) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
                else if (minLeasePeriod == "Years" && maxLeasePeriod == "Days") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMin[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Years" && parseInt(splitLeaseMin[0]) < minLeaseNum) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Days" && parseInt(splitLeaseMax[0]) > maxLeaseNum) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
                else if (minLeasePeriod == "Years" && maxLeasePeriod == "Months") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMin[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Years" && parseInt(splitLeaseMin[0]) < minLeaseNum) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Months" && parseInt(splitLeaseMax[0]) > maxLeaseNum) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
                else if (minLeasePeriod == "Years" && maxLeasePeriod == "Years") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMin[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Years" && parseInt(splitLeaseMin[0]) < minLeaseNum) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Years" && parseInt(splitLeaseMax[0]) > maxLeaseNum) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
              }

              else if (minLeaseNum != "NA") {
                if (minLeasePeriod == "Days") {
                  if (splitLeaseMin[1] == "Days" && parseInt(splitLeaseMin[0]) < minLeaseNum) {
                    filteringProject.splice(i, 1);
                  }
                }
                else if (minLeasePeriod == "Months") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMin[1] == "Months" && parseInt(splitLeaseMin[0]) < minLeaseNum) {
                    filteringProject.splice(i, 1);
                  }
                }
                else if (minLeasePeriod == "Years") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMin[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMin[1] == "Years" && parseInt(splitLeaseMin[0]) < minLeaseNum) {
                    filteringProject.splice(i, 1);
                  }
                }
              }
              else if (maxLeaseNum != "NA") {
                if (maxLeasePeriod == "Days") {
                  if (splitLeaseMax[1] == "Days" && parseInt(splitLeaseMax[0]) > maxLeaseNum) {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                }
                else if (maxLeasePeriod == "Months") {
                  if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Months" && parseInt(splitLeaseMax[0]) > maxLeaseNum) {
                    filteringProject.splice(i, 1);
                  }
                }
                else if (maxLeasePeriod == "Years") {
                  if (splitLeaseMax[1] == "Years" && parseInt(splitLeaseMax[0]) > maxLeaseNum) {
                    filteringProject.splice(i, 1);
                  }
                }
              }

            }
            else {
              var splitLeaseMin = filteringProject[i].details.leaseTermMin.split(' ');
              var splitLeaseMax = filteringProject[i].details.leaseTermMax.split(' ');
              if (filterHomeData.minLeaseNum != "NA" && filterHomeData.maxLeaseNum != "NA") {


                if (filterHomeData.minLeasePeriod == "Days" && filterHomeData.maxLeasePeriod == "Days") {
                  if (splitLeaseMax[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Days" && parseInt(splitLeaseMin[0]) < parseInt(filterHomeData.minLeaseNum)) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Days" && parseInt(splitLeaseMax[0]) > parseInt(filterHomeData.maxLeaseNum)) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
                else if (filterHomeData.minLeasePeriod == "Days" && filterHomeData.maxLeasePeriod == "Months") {
                  if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Days" && parseInt(splitLeaseMin[0]) < parseInt(filterHomeData.minLeaseNum)) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Months" && parseInt(splitLeaseMax[0]) > parseInt(filterHomeData.maxLeaseNum)) {
                        filteringProject.splice(i, 1);
                      }
                    }

                  }
                }
                else if (filterHomeData.minLeasePeriod == "Days" && filterHomeData.maxLeasePeriod == "Years") {
                  if (splitLeaseMin[1] == "Days" && parseInt(splitLeaseMin[0]) < parseInt(filterHomeData.minLeaseNum)) {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMax[1] == "Years" && parseInt(splitLeaseMax[0]) > parseInt(filterHomeData.maxLeaseNum)) {
                      filteringProject.splice(i, 1);
                    }
                  }
                }
                else if (filterHomeData.minLeasePeriod == "Months" && filterHomeData.maxLeasePeriod == "Days") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Months" && parseInt(splitLeaseMin[0]) < parseInt(filterHomeData.minLeaseNum)) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Days" && parseInt(splitLeaseMax[0]) > parseInt(filterHomeData.maxLeaseNum)) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
                else if (filterHomeData.minLeasePeriod == "Months" && filterHomeData.maxLeasePeriod == "Months") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Months" && parseInt(splitLeaseMin[0]) < parseInt(filterHomeData.minLeaseNum)) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Months" && parseInt(splitLeaseMax[0]) > parseInt(filterHomeData.maxLeaseNum)) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
                else if (filterHomeData.minLeasePeriod == "Months" && filterHomeData.maxLeasePeriod == "Years") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Months" && parseInt(splitLeaseMin[0]) < parseInt(filterHomeData.minLeaseNum)) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Years" && parseInt(splitLeaseMax[0]) > parseInt(filterHomeData.maxLeaseNum)) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
                else if (filterHomeData.minLeasePeriod == "Years" && filterHomeData.maxLeasePeriod == "Days") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMin[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Years" && parseInt(splitLeaseMin[0]) < parseInt(filterHomeData.minLeaseNum)) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Days" && parseInt(splitLeaseMax[0]) > parseInt(filterHomeData.maxLeaseNum)) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
                else if (filterHomeData.minLeasePeriod == "Years" && filterHomeData.maxLeasePeriod == "Months") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMin[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Years" && parseInt(splitLeaseMin[0]) < parseInt(filterHomeData.minLeaseNum)) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Months" && parseInt(splitLeaseMax[0]) > parseInt(filterHomeData.maxLeaseNum)) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
                else if (filterHomeData.minLeasePeriod == "Years" && filterHomeData.maxLeasePeriod == "Years") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMin[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else {
                    if (splitLeaseMin[1] == "Years" && parseInt(splitLeaseMin[0]) < parseInt(filterHomeData.minLeaseNum)) {
                      filteringProject.splice(i, 1);
                    }
                    else {
                      if (splitLeaseMax[1] == "Years" && parseInt(splitLeaseMax[0]) > parseInt(filterHomeData.maxLeaseNum)) {
                        filteringProject.splice(i, 1);
                      }
                    }
                  }
                }
              }


              else if (filterHomeData.minLeaseNum != "NA") {
                if (filterHomeData.minLeasePeriod == "Days") {
                  if (splitLeaseMin[1] == "Days" && parseInt(splitLeaseMin[0]) < parseInt(filterHomeData.minLeaseNum)) {
                    filteringProject.splice(i, 1);
                  }
                }
                else if (filterHomeData.minLeasePeriod == "Months") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMin[1] == "Months" && parseInt(splitLeaseMin[0]) < parseInt(filterHomeData.minLeaseNum)) {
                    filteringProject.splice(i, 1);
                  }
                }
                else if (filterHomeData.minLeasePeriod == "Years") {
                  if (splitLeaseMin[1] == "Days") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMin[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMin[1] == "Years" && parseInt(splitLeaseMin[0]) < parseInt(filterHomeData.minLeaseNum)) {
                    filteringProject.splice(i, 1);
                  }
                }
              }
              else if (filterHomeData.maxLeaseNum != "NA") {
                if (filterHomeData.maxLeasePeriod == "Days") {
                  if (splitLeaseMax[1] == "Days" && parseInt(splitLeaseMax[0]) > parseInt(filterHomeData.maxLeaseNum)) {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Months") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                }
                else if (filterHomeData.maxLeasePeriod == "Months") {
                  if (splitLeaseMax[1] == "Years") {
                    filteringProject.splice(i, 1);
                  }
                  else if (splitLeaseMax[1] == "Months" && parseInt(splitLeaseMax[0]) > parseInt(filterHomeData.maxLeaseNum)) {
                    filteringProject.splice(i, 1);
                  }
                }
                else if (filterHomeData.maxLeasePeriod == "Years") {
                  if (splitLeaseMax[1] == "Years" && parseInt(splitLeaseMax[0]) > parseInt(filterHomeData.maxLeaseNum)) {
                    filteringProject.splice(i, 1);
                  }
                }
              }
            }
          }


        }
      }
      resolve(filteringProject);

    })
  }





  const getAllData = () => {
    return new Promise(async function (resolve, reject) {
      var allData = []
      var firstData = [];
      var needUpEyeballAdv = [];
      var boostList = [];
      var conversationRateListing = [];
      var preferredListing = [];
      var normalListing = [];


      const allListing = await getDocs(collection(db, 'projects'));
      const activatedAdvertise = query(collection(db, "advertiseListing"), where("status", "==", "leasing"));
      // Get all the documents that match the query criteria
      const activatedAdvertiseSnapshot = await getDocs(activatedAdvertise);
      var activatedAdvertiseListing = activatedAdvertiseSnapshot.docs

      for (const indiv of allListing.docs) {
        if (indiv.data().status === 'leasing') {

          const listing = { details: indiv.data(), id: indiv.id };


          if (listing.details.boost === true) {

            boostList.push(listing);
          } else if (listing.details.preferredListing === true) {

            preferredListing.push(listing);
          } else {

            conversationRateListing.push(listing);
            normalListing.push(listing);
          }

        }
      }


      conversationRateListing.sort((a, b) => {
        return b.details.conversationRate - a.details.conversationRate;
      });
      var boxNumber = 0;
      var counterAdv = 0;
      while (boostList.length != 0 || preferredListing.length != 0 || normalListing.length != 0 || conversationRateListing.length != 0) {
        var randomIndex = Math.floor(Math.random() * activatedAdvertiseListing.length);
        if (firstData.length % 5 == 0 && activatedAdvertiseListing.length != 0 && firstData.length != 0) {
          firstData.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() });
          needUpEyeballAdv.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() })

        }
        if (allData.length % 5 == 0 && activatedAdvertiseListing.length != 0 && allData.length != 0) {
          allData.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() });
          activatedAdvertiseListing.splice(randomIndex, 1);
        }
        if (boxNumber == 0) {
          if (boostList.length != 0) {
            allData.push(boostList[0]);

            if (firstData.length < 40) {
              firstData.push(boostList[0]);
              if (firstData.length == 40) {
                setCurrentListing(firstData);
              }
            }
            boostList.splice(0, 1);

          }

          if (preferredListing.length != 0) {
            boxNumber = 1;
          }
          else if (conversationRateListing.length != 0) {
            boxNumber = 2;
          }
          else if (normalListing.length != 0) {
            boxNumber = 3;
          }
          else {
            boxNumber = 0;
          }

        }
        else if (boxNumber == 1) {
          if (preferredListing.length != 0) {
            allData.push(preferredListing[0]);

            if (firstData.length < 40) {
              firstData.push(preferredListing[0]);
              if (firstData.length == 40) {
                setCurrentListing(firstData);
              }
            }
            preferredListing.splice(0, 1);

          }

          if (conversationRateListing.length != 0) {
            boxNumber = 2;
          }
          else if (normalListing.length != 0) {
            boxNumber = 3;
          }
          else if (boostList.length != 0) {
            boxNumber = 0;
          }
          else {
            boxNumber = 1;
          }

        }
        else if (boxNumber == 2) {
          if (conversationRateListing.length != 0) {
            var keepGoing = true;
            while (conversationRateListing.length != 0 && keepGoing == true) {
              const foundListing = allData.find(item => item.id == conversationRateListing[0].id);
              if (foundListing) {
                conversationRateListing.splice(0, 1);
              }
              else {
                allData.push(conversationRateListing[0]);
                if (firstData.length < 40) {
                  firstData.push(conversationRateListing[0]);
                  if (firstData.length == 40) {
                    setCurrentListing(firstData);
                  }
                }
                conversationRateListing.splice(0, 1);
                keepGoing = false;

              }
            }
          }

          if (normalListing.length != 0) {
            boxNumber = 3;
          }
          else if (boostList.length != 0) {
            boxNumber = 0;
          }
          else if (preferredListing.length != 0) {
            boxNumber = 1;
          }
          else {
            boxNumber = 2;
          }

        }
        else if (boxNumber == 3) {
          if (normalListing.length != 0) {
            var keepGoing = true;
            while (normalListing.length != 0 && keepGoing == true) {
              const foundListing = allData.find(item => item.id == normalListing[0].id);
              if (foundListing) {
                normalListing.splice(0, 1);
              }
              else {
                allData.push(normalListing[0]);

                if (firstData.length < 40) {
                  firstData.push(normalListing[0]);
                  if (firstData.length == 40) {
                    setCurrentListing(firstData);
                  }
                }
                normalListing.splice(0, 1);
                keepGoing = false;

              }
            }
          }

          if (boostList.length != 0) {
            boxNumber = 0;
          }
          else if (preferredListing.length != 0) {
            boxNumber = 1;
          }
          else if (conversationRateListing.length != 0) {
            boxNumber = 2;
          }
          else {
            boxNumber = 3;
          }

        }
      }
      if (firstData.length < 40) {
        var randomIndex = Math.floor(Math.random() * activatedAdvertiseListing.length);
        if (activatedAdvertiseListing.length != 0 && firstData.length != 0 && firstData.length <= 4) {
          firstData.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() });
          needUpEyeballAdv.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() })
        }
        if (activatedAdvertiseListing.length != 0 && allData.length != 0 && firstData.length <= 4) {
          allData.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() });
          activatedAdvertiseListing.splice(randomIndex, 1);
        }
        setCurrentListing(firstData);
      }


      setEyeballAdvListingID(needUpEyeballAdv);
      resolve(allData);
    });

  }

  const clearAllFilter = () => {
    setFilterName(null);
    setFilterProject(null);
    setMinPriceBud(0);
    setLocationDetails("");
    setMinLeaseNum("NA");
    setMinLeasePeriod('Days');
    // handlePropertyClick('All');
    setMaxPriceBud("NA");
    setMaxLeaseNum("NA");
    setMaxLeasePeriod('Days');
    setFilterProject(null);
    setIsAll(true);
    setIsFB(false);
    setIsOther(false);
    setIsRetail(false);
    setIsService(false);
    setIsStorage(false);
    // setShow(false);
    var allData = combineAllData;
    var resetListingCurrent = [];
    resetListingCurrent.push(...allData.slice(0, 40));
    setCurrentListing(resetListingCurrent);
    setCurrentPage(1);
  }




  const checkForPreviousPage = (allData) => {
    return new Promise(function (resolve, reject) {
      const persistedId = sessionStorage.getItem(
        "scroll-position-product-id-marker"
      );
      if (persistedId != null) {
        sessionStorage.removeItem("scroll-position-product-id-marker");
        setCurrentPage(Number(persistedId));

        // console.log(all);
        setCurrentListing(allData.slice(((Number(persistedId) - 1) * 40), (((Number(persistedId) - 1) * 40) + 40)));
        // console.log(allData.current[Number(persistedId) - 1]);
        // setCurrentListing(allData.current[Number(persistedId) - 1]);
      }
      resolve(true);
    })
  }

  const recordID = (pageNumber) => {
    sessionStorage.setItem("scroll-position-product-id-marker", pageNumber);
  }

  const addShortList = async (userID, id) => {
    if (user != null && userID != user.uid) {
      await setDoc(doc(db, `userInfo/${user.uid}/shortList`, id), {});
    }
    else {
      alert("Please Sign Up First or This is your post");
    }
  }

  const deteleShortList = async (id) => {
    if (user != null && id != user.uid) {
      await deleteDoc(doc(db, `userInfo/${user.uid}/shortList`, id));
    }
    else {
      alert("Please Sign Up First or This is your post");
    }
  }

  async function filteringPictures(details) {
    const filteredPictures = [];
    for (let index = 0; index < 5; index++) {
      const urlKey = `url${[index]}`;
      if (details.hasOwnProperty(urlKey)) {
        filteredPictures.push(picturesIndex[index]);
      }
    }
    return filteredPictures;
  }



  return (
    <>
      {/* <Notification></Notification> */}
      <ZoomPicture trigger={zoomPic} setTrigger={setZoomPic} zoomPicURL={zoomPicURL}></ZoomPicture>
      <div>
        <center><h2 style={{ paddingTop: "2%" }}>COMMERCIAL RENT</h2></center>
        <div className='searchBar' >
          <div id="searchBar" ref={searchbarRef}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px", backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
              <i class="fa-solid fa-magnifying-glass" id="magnifyingIcon"></i>
              <div className='searchbarIndivCol'>
                <input type="text" placeholder='Search district, listing name, etc' value={locationDetails} onChange={handleLocationDetails}></input>
              </div>
              <div className='searchbarIndivColBtn'>
                <button className="btn--search" style={{ marginLeft: "3%" }} onClick={() => searchApply()}>Search</button>
              </div>
            </div>

            <div id="filterBar">
              <div style={{ width: "30%", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p id="BudgetFilter" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Budget {filterName == "BudgetFilter" ? <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                {minPriceBud == 0 && maxPriceBud == "NA" ? null : <p id="BudgetFilter" className='btn--filter' style={{ cursor: "pointer", marginTop: "2%" }} onClick={(handleClick)}>{minPriceBud == 0 ? "Any" : `$${minPriceBud.toLocaleString()}`} - {maxPriceBud == "NA" ? "Any" : `$${maxPriceBud.toLocaleString()}`}</p>}
              </div>
              <div style={{ width: "30%", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p id="LeaseFilter" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Lease Term {filterName == "LeaseFilter" ? <i id="LeaseFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="LeaseFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                {minLeaseNum == "NA" && maxLeaseNum == "NA" ? null : <p id="LeaseFilter" className='btn--filter' style={{ cursor: "pointer", marginTop: "2%" }} onClick={(handleClick)}>{minLeaseNum == "NA" ? "Any" : minLeaseNum + " " + minLeasePeriod} - {maxLeaseNum == "NA" ? "Any" : maxLeaseNum + " " + maxLeasePeriod}</p>}
              </div>
              <div style={{ width: '30%', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                <p id="PropertyFilter" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Property Type {filterName == "PropertyFilter" ? <i id="PropertyFilterDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="PropertyFilterDropdown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>

                {isAll == true || (isFB == false && isRetail == false && isService == false && isStorage == false && isOther == false) ? null :
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', marginTop: "2%" }}>
                    {isFB ? <p id="PropertyFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }} >F&B <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsFB(false) }}></i></p> : null}
                    {isRetail ? <p id="PropertyFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Retail <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsRetail(false) }}></i></p> : null}
                    {isService ? <p id="PropertyFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }} >Service <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsService(false) }}></i></p> : null}
                    {isStorage ? <p id="PropertyFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Storage <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsStorage(false) }}></i></p> : null}
                    {isOther ? <p id="PropertyFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Other <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsOther(false) }}></i></p> : null}
                  </div>}
              </div>


            </div>
            <div id="clearBtnDiv">
              <button id="filterClear" onClick={() => { clearAllFilter() }}>Clear</button>
            </div>


            {showFilters && (
              <div id="moreFilter" ref={moreFilterRef} style={(filterName == "BudgetFilter") ? { left: "0", height: "300px" } : filterName == "PropertyFilter" ? { right: "0", height: "200px" } : window.innerWidth > 768 ? { height: "220px", marginLeft: "33.3%" } : { height: "220px", left: "0" }}>

                {(filterName == "BudgetFilter" || filterName == "BudgetFilterResidential") ?
                  <>
                    <div className='searchbarIndivCol'>
                      <input placeholder='Min' type="Number" id="minPriceBuy" value={minPriceBud} onClick={() => { setMinOption(true) }} onChange={handlesMinPriceBud} style={{ width: "45%", float: "left" }}></input>
                      <p style={{ float: "left", width: "7%", padding: "8px 10px", cursor: "default", border: "none" }}>-</p>
                      <input placeholder='Max' type="Number" id="maxPriceBuy" value={maxPriceBud} onClick={() => { setMinOption(false) }} onChange={handleMaxPriceBud} style={{ width: "45%", float: "left" }}></input>
                      <div style={{ clear: 'both' }}></div>
                      {minOption == true ?
                        <>
                          <p id="optionBudget" onClick={() => setMinPriceBud(500)}>$500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(1000)}>$1,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(1500)}>$1,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(2000)}>$2,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(2500)}>$2,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(3000)}>$3,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(3500)}>$3,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(4000)}>$4,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(4500)}>$4,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(5000)}>$5,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(5500)}>$5,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(6000)}>$6,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(6500)}>$6,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(7000)}>$7,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(7500)}>$7,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(8000)}>$8,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(8500)}>$8,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(9000)}>$9,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(9500)}>$9,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(10000)}>$10,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(10500)}>$10,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(11000)}>$11,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(11500)}>$11,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(12000)}>$12,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(12500)}>$12,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(13000)}>$13,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(13500)}>$13,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(14000)}>$14,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(14500)}>$14,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(15000)}>$15,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(15500)}>$15,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(16000)}>$16,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(16500)}>$16,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(17000)}>$17,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(17500)}>$17,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(18000)}>$18,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(18500)}>$18,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(19000)}>$19,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(19500)}>$19,500</p>
                        </> :
                        <>
                          <p id="optionBudget" onClick={() => setMaxPriceBud("NA")}>Any Price</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(500)}>$500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(1000)}>$1,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(1500)}>$1,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(2000)}>$2,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(2500)}>$2,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(3000)}>$3,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(3500)}>$3,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(4000)}>$4,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(4500)}>$4,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(5000)}>$5,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(5500)}>$5,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(6000)}>$6,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(6500)}>$6,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(7000)}>$7,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(7500)}>$7,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(8000)}>$8,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(8500)}>$8,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(9000)}>$9,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(9500)}>$9,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(10000)}>$10,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(10500)}>$10,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(11000)}>$11,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(11500)}>$11,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(12000)}>$12,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(12500)}>$12,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(13000)}>$13,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(13500)}>$13,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(14000)}>$14,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(14500)}>$14,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(15000)}>$15,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(15500)}>$15,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(16000)}>$16,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(16500)}>$16,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(17000)}>$17,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(17500)}>$17,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(18000)}>$18,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(18500)}>$18,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(19000)}>$19,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(19500)}>$19,500</p>
                        </>
                      }
                    </div>
                  </> :
                  filterName == "LeaseFilter" ?
                    <>
                      <div className='searchbarIndivCol'>
                        <p style={{ color: "grey", fontWeight: "bold" }}>Minimum Lease</p>
                        {minLeasePeriod == "Days" ?
                          <select id="dropdown" name="dropdown" value={minLeaseNum} onChange={handleMinLeaseNum}>
                            <option value="NA">NA</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                          </select> :
                          minLeasePeriod == "Months" ?
                            <select id="dropdown" name="dropdown" value={minLeaseNum} onChange={handleMinLeaseNum}>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                            </select> :
                            <select id="dropdown" name="dropdown" value={minLeaseNum} onChange={handleMinLeaseNum}>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                        }
                        <select id="dropdown" name="dropdown" value={minLeasePeriod} onChange={handleMinLeasePeriod}>
                          <option value="Days">Days</option>
                          <option value="Months">Months</option>
                          <option value="Years">Years</option>
                        </select>
                        <p style={{ color: "grey", fontWeight: "bold", marginTop: "5%" }}>Maximum Lease</p>

                        {maxLeasePeriod == "Days" ?
                          <select id="dropdown" name="dropdown" value={maxLeaseNum} onChange={handleMaxLeaseNum}>
                            <option value="NA">NA</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                          </select> :
                          maxLeasePeriod == "Months" ?
                            <select id="dropdown" name="dropdown" value={maxLeaseNum} onChange={handleMaxLeaseNum}>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                            </select> :
                            <select id="dropdown" name="dropdown" value={maxLeaseNum} onChange={handleMaxLeaseNum}>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                        }

                        <select id="dropdown" name="dropdown" value={maxLeasePeriod} onChange={handleMaxLeasePeriod}>
                          <option value="Days">Days</option>
                          <option value="Months">Months</option>
                          <option value="Years">Years</option>
                        </select>

                      </div>
                    </> :
                    filterName == "PropertyFilter" ?
                      <>
                        <div className='searchbarIndivCol'>


                          <div className='propertyType' id="buyPropertyType">
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isAll}
                                onChange={handleAllCheckBoxChange}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text">All</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isFB}
                                onChange={handleFBCheckBoxChange}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text">F&B</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isRetail}
                                onChange={handleRetailCheckBoxChange}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text">Retail</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isService}
                                onChange={handleServiceCheckBoxChange}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text">Service</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isStorage}
                                onChange={handleStorageCheckBoxChange}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text">Storage</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isOther}
                                onChange={handleOtherCheckBoxChange}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text">Other</span>
                            </label>
                          </div>
                        </div>
                      </> :
                      null


                }
              </div>
            )}
          </div>
        </div>



        {filterProject != null && (minPriceBud != 0 || maxPriceBud != 0) ? <div>
          <select id="dropdownFilterMin" name="dropdown" onChange={handleSortingOrder}>
            <option value="NA">NA</option>
            <option value="Adescending">Adescending </option>
            <option value="Descending">Descending</option>
          </select>
          <div style={{ clear: 'both' }}></div>
        </div> : null}

        <div className='rowCard'>
          {currentListing != null ? currentListing[0] != null ? currentListing.map((project, index) => {
            if (project.details.hasOwnProperty('activatedDate')) {
              return (
                <div className="CardAdvertiseDivCommercialRent" id={project.id} onClick={() => { setZoomPicURL(project.details.imageURL); setZoomPic(true) }}>
                  <div style={{ position: 'relative' }}>
                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={project.details.imageURL} alt="Uploaded" />
                    {/* <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#33434dbf', color: '#fff', padding: '4px 8px', borderRadius: '4px', marginTop: "1%", marginRight: "1%" }}>
                      Adv
                    </div> */}
                  </div>
                  <div className="CardContainer">
                    <div style={{ clear: 'both' }}></div>
                  </div>
                  <div style={{ clear: 'both' }}></div>
                </div>
              )
            }
            else {
              var ID = project.id;
              var heartIconStatus = false;
              if (shortList != null) {
                heartIconStatus = shortList.find(doc => { return doc.id === ID })
              }
              return (
                <div class="Card" style={{ border: "5px solid #33434d", borderRadius: "10px", position:"relative" }}>

                  {/* <button onClick={(e) => e.preventDefault()}>testing</button> */}
                  {/* <div style={{ position: "relative" }}> */}

                  {/* <div style={{ position: 'relative' }}>
                        <img id="Card_image" src={project.details.url0.search("https://ik.imagekit.io/NextLoop") >= 0 ? (project.details.url0).replaceAll("tr:h-200,w-300", "tr:h-190,w-280") : (project.details.url0).replaceAll("https://firebasestorage.googleapis.com", "https://ik.imagekit.io/NextLoop/tr:h-190,w-280")}></img>
                        {project.details.propertyAgent == true ? <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#33434dbf', color: '#fff', padding: '3%', borderRadius: '4px', marginTop: "3%", marginRight: "3%", fontSize: "13px" }}>
                          Agent
                        </div> : null}
                        <div id="preferredBackground">
                          <div id="informationPreferredBackground">
                             {project.details.preferredListing == true ? <p id="Card_preferred">Preferred Listing</p> : null}
                          </div>
                        </div>
                      </div> */}
                  <div style={{ position: 'relative' }}>

                    <Carousel>
                      {[0, 1, 2, 3].map((index) => {
                        const imageUrl = project.details[`url${index}`];
                        if (imageUrl) {
                          return (
                            <div key={index}>
                              <img id="Card_image" src={imageUrl} alt={`Picture ${index}`} />
                            </div>
                          );
                        } else {
                          return null; // Render nothing if there's no image for the index
                        }
                      }).filter(child => child !== null)}

                    </Carousel>
                    <div id="preferredBackground">
                      <div id="informationPreferredBackground">
                        {project.details.preferredListing == true ? <p id="Card_preferred">Preferred Listing</p> : null}
                      </div>
                    </div>


                    {/* <div id="gradientBackground">
                      <div id="informationGradientBackground">
                        <div id='informationGradientBackgroundLeft'>
                          <i class="fa fa-eye"></i> {project.details.eyeball}
                          <i class="far fa-comment-dots"></i> {project.details.connections}
                        </div>
                        <div id='informationGradientBackgroundRight'>
                          {heartIconStatus == undefined | heartIconStatus == false ? <button id="heartButton" onClick={(e) => { e.preventDefault(); addShortList(project.details.UID, ID) }}><i class="fa-regular fa-heart fa-2x" style={{ color: "white" }}></i></button> : <button id="heartButton" onClick={(e) => { e.preventDefault(); deteleShortList(ID) }}><i class="fa-solid fa-heart fa-2x" id="favouriteHeart"></i></button>}
                        </div>
                        <div style={{ clear: 'both' }}></div>
                      </div>
                    </div> */}
                  </div>
                  <Link to={"/commercialRentDetails/" + ID} onClick={() => recordID(currentPage)}>
                    <div class="CardContainer">
                      <div id="commericalRentMidPart">
                      <div style={{float:"left", width:"80%"}}>
                        {project.details.propertyAgent == true ? <p style={{ color: "#4d4c4c" }}>AGENT</p> : <p style={{ color: "#4d4c4c" }}>DIRECT TENANT</p>}
                        
                        
                        <p id="Card_district" style={{ paddingTop: "5px" }}>{project.details.district.toUpperCase()}</p>

                        <p id="Card_price">${project.details.priceOverall.toLocaleString()}</p>

                        <p id="monthlyRentalBudget">Monthly Rental Budget</p>
                        </div>
                        
                        <div style={{float:"right", width:"20%" , paddingTop:"15px"}}>
                         <img
                          className="inline-block h-12 w-12 rounded-full ring-2 ring-white mr-4 object-contain"
                          src={project.details.userImage}
                          alt=""
                        /> 
                        </div>
                        <div style={{ clear: 'both' }}></div>
                        {/* {project.details.propertyAgent == true ? <p style={{ color: "#4d4c4c" }}>AGENT</p> : <p style={{ color: "#4d4c4c" }}>DIRECT BUYER</p>} */}
                        {/* <p id="Card_district" style={{ paddingTop: "5px" }}>{project.details.district.toUpperCase()}</p> */}

                        {/* {project.details.preferredListing == true ? <p id="Card_preferred">Preferred Listing</p> : null} */}
                        {/* <p id="Card_price">${project.details.priceOverall.toLocaleString()}</p> */}

                        {/* <p id="monthlyRentalBudget">Monthly Rental Budget</p> */}

                      </div>
                      {/* {project.details.preferredListing == true ?<p id="Card_preferred">Preferred Listing</p>:null} */}


                      <div id="Card_overall">

                        <p style={{ color: "#4d4c4c", paddingLeft: "5px" }}>Business Type</p>
                        <p id="Card_type" style={{ paddingLeft: "5px", fontWeight: "bold" }}>{project.details.propertyType}</p>

                        {/* <p style={{ float: "left" }}>Monthly PSF Budget:</p><p style={{ float: "right", paddingRight: "10px", color: "black" }}>${project.details.pricePerSquareFeet}</p> */}
                        {/* <div style={{ clear: 'both' }}></div> */}
                      </div>
                    </div>
                  </Link>
                  
                          
                       
                  {/* // <div style={{ paddingTop: "5px", paddingBottom: "5px" }}> */}
                    {/* <div id='residentialEyeball'>
                      <i class="fa fa-eye"></i> 
                      <i class="far fa-comment-dots" style={{ marginLeft: "5px" }}></i> 
                    </div>
                    <div id='residentialHeart'>
                      {heartIconStatus == undefined | heartIconStatus == false ? <button id="heartButton" onClick={(e) => { e.preventDefault(); addShortList(project.details.UID, ID) }}><i class="fa-regular fa-heart fa-2x"></i></button> : <button id="heartButton" onClick={(e) => { e.preventDefault(); deteleShortList(ID) }}><i class="fa-solid fa-heart fa-2x" id="favouriteHeart"></i></button>}
                    </div>
                    <div style={{ clear: 'both' }}></div> */}

                  {/* // </div> */}
                  <div style={{ borderTop: "1px solid #c0c0c0", display:"flex", justifyContent:"space-between" }}>
                        <div id='residentialEyeball' style={{display:"flex", alignItems:"center"}}>
                          <i class="fa fa-eye" style={{marginLeft:"3px", marginRight:"3px"}}></i> {project.details.eyeball}
                          <i class="far fa-comment-dots" style={{marginLeft:"3px", marginRight:"3px"}}></i> {project.details.connections}
                        </div>
                        <div id='residentialHeart'>
                          {heartIconStatus == undefined | heartIconStatus == false ? <button id="heartButton" onClick={(e) => { e.preventDefault(); addShortList(project.UID, ID) }}><i class="fa-regular fa-heart fa-2x"></i></button> : <button id="heartButton" onClick={(e) => { e.preventDefault(); deteleShortList(ID) }}><i class="fa-solid fa-heart fa-2x" id="favouriteHeart"></i></button>}
                        </div>
                      </div>
                </div>
              )
            }
          }) : <center><h3 style={{ marginTop: "15%" }}>No Result</h3></center> : null}
          <div style={{ clear: 'both' }}></div>
        </div>
        <Stack alignItems="center">
          {filterProject != null ?
            <Pagination count={Math.ceil(filterProject.length / 40)} variant="outlined" shape="rounded" style={{ bottom: "0", marginBottom: "2%" }} page={currentPage} onChange={onPageChanged} />
            :
            <Pagination page={currentPage} count={Math.ceil(totalProject / 40)} variant="outlined" shape="rounded" style={{ bottom: "0", marginBottom: "2%" }} onChange={onPageChanged} />}
        </Stack>

      </div>
      <Footer />


    </>
  )
}

