import React, { useRef, useEffect } from 'react';
import '../../App.css';
import '../css/AboutUs.css'
import { db } from '../../firebase/firebase'
import { onSnapshot, doc, getDoc } from 'firebase/firestore';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import Footer from '../Footer';




function Career() {

  const chats = useRef(null);
  const { user, deleteUserData } = UserAuth();
  let unsbscribe;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  

  useEffect(() => {
    const checkingUser = async () => {
      var docsnap = await getDoc(doc(db, 'userInfo', user.uid));
      if (docsnap.exists()) {
      }
      else {
        // alert("hacked your way through ah");
        deleteUserData();

      }
    }
    if (user != null) {
      if (Object.keys(user).length != 0) {
        checkingUser();
      }
    }
  }, [user])

  return (
    <>
      {/* <ReactNotifications /> */}
      <div>
        {/* 
                <div className='ContactUsDiv' style={{ backgroundColor: "#30628E", color: "white" }}>
                    <center><h1>Join us!</h1></center>
                  
                    <h2>General Enquiries</h2>
                    <a href="mailto:info@nextloop.sg">info@nextloop.sg</a>
                
                    <h2>Support Enquiries</h2>
                    <a href="mailto:support@nextloop.sg">support@nextloop.sg</a>
                </div> */}
        <div style={{paddingBottom:"2%"}}>
          <center>
          <p id="careerTitle">Careers</p>
          <p id="careerDescription">Join our team and help us transform the rental experience by bridging the gap between landlords and tenants. At our company, we're dedicated to making property transactions effortless and empowering both renters and landlords alike. By simplifying the rental process, we aim to elevate the industry as a whole and make every transaction a breeze. Come join us in revolutionizing the way properties are rented and be a part of a team that's committed to making a real difference.</p>
          <a href="mailto:info@nextloop.sg"><button id="careerJoinUsBtn">Join us</button></a>
          </center>
        </div>
        <div style={{backgroundColor:"#FAFAFA", paddingTop:"2%", paddingBottom:"2%"}} >
          <center>
          <p id="careerDescriptionBenefit">BENEFITS</p>
          <p id="careerTitleBenefit">Why Join Us</p>
          </center>
          <div id="careerParentDiv">
            <div id="careerChildDiv">
              <div id="careerChildDivSub">
                <p id="careerChildDivTitle">A business that's quickly scaling <i class="fa-regular fa-circle-check"></i></p>
                <p id="careerChildDivDescription">We have reached a critical juncture where we can achieve accelerated growth and progress towards our goals.</p>
              </div>
              <div>
                <p id="careerChildDivTitle">Always strive to learn more <i class="fa-regular fa-circle-check"></i></p>
                <p id="careerChildDivDescription">Our company fosters an environment where continuous learning is always a top priority and actively encouraged.</p>
              </div>
            </div>
            <div id="careerChildDiv">
              <div id="careerChildDivSub">
                <p id="careerChildDivTitle">A team of exceptional professionals <i class="fa-regular fa-circle-check"></i></p>
                <p id="careerChildDivDescription">A tight-knit and supportive group</p>
              </div>
              <div>
                <p id="careerChildDivTitle">Take control and drive progress <i class="fa-regular fa-circle-check"></i></p>
                <p id="careerChildDivDescription">We encourage you to take on as much as you're comfortable with and to strive for excellence in all that you do</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div style={{ clear: 'both' }}></div>
      <Footer />
    </>


  )
}

export default Career;
