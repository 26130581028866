import React, { useState, useRef, useEffect, useContext } from 'react';
import '../../App.css';
import '../css/Notification.css'
import Footer from '../Footer'
import { Link } from 'react-router-dom';
import { collection, getDocs, onSnapshot, doc, query, orderBy, limit, getDoc, setDoc, deleteDoc, startAfter } from 'firebase/firestore';
import { db } from '../../firebase/firebase'
import CardItem from '../CardItem';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { useNavigate } from 'react-router-dom';


function Notification() {
    const [notifications, setNotifications] = useState([]);
    const chats = useRef(null);
    const { user, deleteUserData } = UserAuth();
    const [disappearTime, setDisappearTime] = useState(5000);

    useEffect(() => {

        if (!user) {

        }
        else {

            if (user.uid != undefined) {
                const unsbscribe = onSnapshot(doc(db, `userChat`, user.uid), (doc) => {
                    if (doc.exists()) {
                        var chat_data = Object.entries(doc.data())?.sort((a, b) => b[1].date - a[1].date);

                        if (chats.current != null) {
                            const newNotifications = chat_data.map((chat, index) => ({
                                key: `${chat[1].userInfo.listingName}-${new Date(chat[1].date.seconds * 1000).toISOString()}-${chat[1].userInfo.lastMessage.substring(2,3) + chat[1].userInfo.lastMessage.substring(5,6) + chat[1].userInfo.lastMessage.substring(8,9)}`,
                                msg_id: chat[1].userInfo.listingName,
                                msg: chat[1].userInfo.lastMessage,
                                time: new Date(chat[1].date.seconds * 1000).toLocaleString(),
                                mouseEnter: false,
                            }));
                            setNotifications((prevNotifications) => [
                                ...newNotifications,
                                ...prevNotifications,
                            ]);
                            //   Store.addNotification({
                            //     title: chat_data[0][1].userInfo.listingName,
                            //     message: chat_data[0][1].userInfo.lastMessage,
                            //     type: "default",
                            //     insert: "top",
                            //     container: "top-right",
                            //     animationIn: ["animate__animated", "animate__fadeIn"],
                            //     animationOut: ["animate__animated", "animate__fadeOut"],
                            //     dismiss: {
                            //       duration: 5000,
                            //     },
                            //     width: 300
                            //   })
                        }
                        chats.current = chat_data;
                    }
                })

                return (() => { unsbscribe() })
            }

        }


    }, [user]);

    useEffect(() => {
        // Remove the oldest notification if the number of notifications exceeds maxNotifications
        if (notifications.length > 7) {
          setNotifications((notifications) => notifications.slice(0, -1));
        }
      }, [notifications]);

    useEffect(() => {
        // Remove the notification after disappearTime milliseconds
        const interval = setInterval(() => {
          setNotifications((notifications) =>
            notifications.slice(0, -1)
          );
        }, disappearTime);
        
        return () => {
          clearInterval(interval);
        };
      }, [notifications, disappearTime]);



    const handleNotificationClose = (key) => {
        setNotifications((prevNotifications) =>
            prevNotifications.filter(
                (notification) => notification.key !== key
            )
        );
    };


    return (
        <>
            <div className={`notifications-container_position-topRight`}>
                {notifications.map((notification) => (
                    <div
                        className="notification"
                        key={notification.key}
                        style={{ transition: `opacity ${disappearTime / 1000}s` }}
                    >
                        <div className="notification-content">
                            <div className="notification-title">{notification.msg_id}</div>
                            <div className="notification-message">
                                {notification.mouseEnter === false
                                    ? notification.msg.split(" ").slice(0, 5).join(" ") +
                                    (notification.msg.split(" ").length > 5 ? "..." : "")
                                    : notification.msg}
                                <br></br>
                                {notification.time}
                            </div>
                        </div>
                        <div
                            className="notification-close"
                            onClick={() => handleNotificationClose(notification.key)}
                        >
                            ×
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </div>
                ))}
            </div>

        </>
    );
}



export default Notification;
