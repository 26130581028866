import {createContext, useReducer,useContext} from 'react';

import { UserAuth } from '../AuthContent';


const ChatContext = createContext();

export const ChatContextProvider = ({children}) => {
    const { user } = UserAuth();
    const INITIAL_STATE = {
        chatID:"null",
        user:{}
    }

    const chatReducer = (state,action) => {
        switch(action.type){
            
            case "CHANGE_USER":
               
                return{
                    user:action.payload,
                    chatID:user.uid > action.payload.uid ? user.uid + action.payload.uid : action.payload.uid + user.uid
                };
                
            default: 
                return state;
        }
       
    };

    const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE)

    return(
        <ChatContext.Provider value = {{data:state, dispatch}}>
            {children}
        </ChatContext.Provider>
    )
}

export const UserChat = () => {
    return useContext(ChatContext)
}