import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import '../../App.css';
import '../css/NewProject.css';
import { UserAuth } from '../../AuthContent';
import { Link } from 'react-router-dom';
import { doc, setDoc, addDoc, collection, updateDoc, getDoc, getDocs, deleteDoc } from "firebase/firestore";
import { db, storage } from '../../firebase/firebase';
import { getDownloadURL, ref, uploadBytes, listAll, getStorage, deleteObject } from 'firebase/storage';
import Acknowledge from './Acknowledge'
import DefaultImage from './DefaultImage';
import Timestamp from 'firebase-firestore-timestamp';
import axios from 'axios';
import CropImage from './CropImage';
import Warning from './Warning';
import LoaderPage from './LoaderPage';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import ExistingPhoto from './ExistingPhoto';
import { type } from '@testing-library/user-event/dist/type';
import Spinner from './Spinner';
import { isDisabled } from '@testing-library/user-event/dist/utils';



export default function PurchaseListing(props) {

  const { user } = UserAuth();
  const [clickConfirm, setClickConfirm] = useState(false);
  const [existingProject, setExistingProject] = useState(null);
  const [warning, setWarning] = useState(false);
  var moreDetailsID = useRef(null);
  var modifyListingData = useRef(null);
  var oldListingValue = useRef(0);
  var currentTab = useRef(0);
  const [acknowledgeNormalNoDelete, setAcknowledgeNormalNoDelete] = useState(false);
  const [continueWarningState, setContinueWarningState] = useState(null);
  const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
  const [acknowledgeMessage, setAcknowledgeMessage] = useState("");
  const [confirmedDelete, setConfirmedDelete] = useState(false);
  const [businessName, setBusinessName] = useState(null);
  const [additionalRemarks, setAdditionInformationRemarks] = useState(null);
  const MAX_CHARACTERS_SMALL = 100;
  const MAX_CHARACTERS_SMALL_RESIDENTIAL = 23;
  const MAX_CHARACTERS_BIG = 300;
  const [listingTenure, setListingTenure] = useState("Local");
  const [floorLevel, setFloorLevel] = useState("Ground");
  const [minFloorSize, setMinFloorSize] = useState(null);
  const [maxFloorSize, setMaxFloorSize] = useState(null);
  const [existingListingAmount, setExistingListingAmount] = useState(0);
  const [propertyTypeResidential, setPropertyTypeResidential] = useState("HDB");
  const [specificPropertyType, setSpecificPropertyType] = useState("Any");
  const [currentUserInfo, setCurrentUserInfo] = useState(null);
  const [districtArray, setDistrictArray] = useState([]);
  const [bedroomArray, setBedroomArray] = useState([]);
  const [tenureArray, setTenureArray] = useState([]);
  const [floorLevelArray, setFloorLevelArray] = useState([]);
  const [amenitiesArray, setAmenitiesArray] = useState([]);
  const [amenities, setAmenities] = useState(null);
  const [districtSelect, setDistrictSelect] = useState("D01 City - Business District");
  const [messages, setMessages] = useState("");
  const [bedrooms, setBedrooms] = useState(null);
  const [typeOfSpace, setTypeOfSpace] = useState("Commercial");
  const [maxFloorSizePSF, setMaxFloorSizePSF] = useState(null);
  const [minFloorSizePSF, setMinFloorSizePSF] = useState(null);
  const [openLoader, setOpenloader] = useState(false);
  const [propertyTypeCommercial, setPropertyTypeCommercial] = useState("Commercial")
  const [moveInPeriod, setMoveInPeriod] = useState(null);
  const [advancedFinancing, setAdvancedFinancing] = useState("");
  const [advancedAIP, setAdvancedAIP] = useState("No");
  const [advancedCOV, setAdvancedCOV] = useState("");
  const [purchaseTenancy, setPurchaseTenancy] = useState("");
  const [purposeOfProperty, setPurposeOfProperty] = useState("");
  const [advancedSellExisting, setAdvancedSellExisting] = useState("");
  const [acknowledgeNormalWarning, setAcknowledgeNormalWarning] = useState(false);
  const [budgetOverall, setBudgetOverall] = useState(0);
  const [dualRoleAgent, setDualRoleAgent] = useState(null);

  const handlePropertyTypeCommercial = (e) => {
    setPropertyTypeCommercial(e.target.value);
  }

  const handleAmenitiesChange = (e) => {
    var localAmenitiesArray = amenitiesArray;
    if (localAmenitiesArray.findIndex(item => item === e.target.value) >= 0) {
      alert("Amenities Exist");
    }
    else {
      localAmenitiesArray.push(e.target.value);

      setAmenities(e.target.value);
      setAmenitiesArray(localAmenitiesArray);
    }
  }

  const handleBudgetOverall = (e) => {
    if (e.target.value < 0) {
      return;
    }
    else {
      setBudgetOverall(e.target.value);
    }
  }

  useEffect(() => {
    if (props.trigger) {
      console.log(props, dualRoleAgent, props.currentUserInfo.userType.includes("Agent") == true && dualRoleAgent == "Agent" && props.publicInfo.agentProfile.verified == true);
      // if(continueWarningState == null){
      if (props.accountView == "Commercial") {
        setTypeOfSpace("Commercial");
      }
      else {
        setTypeOfSpace("Residential");
      }

      if(props.homeRequirements != null){
        setBudgetOverall(props.homeRequirements.budget);
        document.getElementById('locationListing').value = props.homeRequirements.desiredArea;
        setMinFloorSize(props.homeRequirements.minFloorSize);
      }
      // }
    }
  }, [props])


  const handleListingTenure = (e) => {
    var localTenureArray = [...tenureArray];

    if (e.target.value == "Not Applicable" || e.target.value == "Any") {
      localTenureArray.splice(0, localTenureArray.length);
      localTenureArray.push(e.target.value);
      setListingTenure(e.target.value);
      setTenureArray(localTenureArray);
    }
    else {
      var anyIndex = localTenureArray.findIndex(item => item === "Any");
      if (anyIndex >= 0) {
        localTenureArray.splice(anyIndex, 1);
      }
      var naIndex = localTenureArray.findIndex(item => item === "Not Applicable");
      if (naIndex >= 0) {
        localTenureArray.splice(naIndex, 1);
      }
      if (localTenureArray.findIndex(item => item === e.target.value) >= 0) {
        alert("Tenure Exist");
      }
      else {
        localTenureArray.push(e.target.value);

        setListingTenure(e.target.value);
        setTenureArray(localTenureArray);
      }
    }


  }

  const handleFloorLevel = (e) => {
    var localFloorLevelArray = [...floorLevelArray];
    if (e.target.value == "Any") {
      localFloorLevelArray.splice(0, localFloorLevelArray.length);
      localFloorLevelArray.push(e.target.value);
      setFloorLevel(e.target.value);
      setFloorLevelArray(localFloorLevelArray);
    }
    else {
      var anyIndex = localFloorLevelArray.findIndex(item => item === "Any");
      if (anyIndex >= 0) {
        localFloorLevelArray.splice(anyIndex, 1);
      }
      if (localFloorLevelArray.findIndex(item => item === e.target.value) >= 0) {
        alert("Floor Level Exist");
      }
      else {
        localFloorLevelArray.push(e.target.value);
        setFloorLevel(e.target.value);
        setFloorLevelArray(localFloorLevelArray);
      }
    }


  }

  const handleSpecificPropertyType = (e) => {
    setSpecificPropertyType(e.target.value);
  }

  const handleMoveInPeriod = (e) => {
    var today = new Date();
    var targetDate = new Date(e.target.value);
    today.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);
    if (targetDate >= today) {
      setMoveInPeriod(e.target.value);
    }
    else {
      alert("Invalid Date");
    }

  }

  const handleMinFloorSize = (e) => {
    if (e.target.value == "") {
      setMinFloorSize(null);
    }
    else {
      if (parseInt(e.target.value) >= 0) {
        setMinFloorSize(e.target.value);
      }
      else {
        alert("Invalid Value");
      }
    }


  }

  const handleTypeOfSpaceChange = (e) => {
    setTypeOfSpace(e.target.value);
    setBusinessName("");
    setDistrictArray([]);
    setAdditionInformationRemarks("");
    setAmenitiesArray([]);
    setBedroomArray([]);
    setTenureArray([]);
    setMaxFloorSize(0);
    setMaxFloorSizePSF(0);
    setMinFloorSize(0);
    setMinFloorSizePSF(0);

  }

  const handleMaxFloorSize = (e) => {
    if (e.target.value == "") {
      setMaxFloorSize(null);
    }
    else {
      if (parseInt(e.target.value) >= 0) {
        setMaxFloorSize(e.target.value);
      }
      else {
        alert("Invalid Value");
      }
    }
  }

  const handleMaxFloorSizePSF = (e) => {
    if (e.target.value == "") {
      setMaxFloorSizePSF(null);
    }
    else {
      if (parseInt(e.target.value) >= 0) {
        setMaxFloorSizePSF(e.target.value);
      }
      else {
        alert("Invalid Value");
      }
    }
  }

  const handleMinFloorSizePSF = (e) => {
    if (e.target.value == "") {
      setMinFloorSizePSF(null);
    }
    else {
      if (parseInt(e.target.value) >= 0) {
        setMinFloorSizePSF(e.target.value);
      }
      else {
        alert("Invalid Value");
      }
    }


  }

  const handleBedrooms = (e) => {
    var localBedroomArray = bedroomArray;
    if (localBedroomArray.findIndex(item => item === e.target.value) >= 0) {
      alert("Bedrooms Exist");
    }
    else {
      localBedroomArray.push(e.target.value);
      setBedrooms(e.target.value);
      setBedroomArray(localBedroomArray);
    }
  }

  const handlePropertyTypeResidential = (e) => {
    if (e.target.value == "HDB") {
      setSpecificPropertyType("Any");
      setPropertyTypeResidential(e.target.value);
    }
    else if (e.target.value == "Condominium") {
      setSpecificPropertyType("Any");
      setPropertyTypeResidential(e.target.value);
    }
    else {
      setSpecificPropertyType("Any");
      setPropertyTypeResidential(e.target.value);
    }





  }

  const handleBusinessName = (e) => {

    if (e.target.value.length <= MAX_CHARACTERS_SMALL) {
      setBusinessName(e.target.value);

    }


  }

  const showAddPropertyQuestionMark = () => {
    const notApprovedText = document.getElementById(`showAddPropertyQuestionMark`);
    if (notApprovedText.style.display == "none") {
      notApprovedText.style.display = "block";
    }
    else {
      notApprovedText.style.display = "none";
    }
  }

  const handleDistrictArrayChange = (e) => {
    var localDistrictArray = districtArray;
    if(e.target.value == "Any"){
      setDistrictSelect(e.target.value);
      setDistrictArray([e.target.value]);
    }
    else if (localDistrictArray.length >= 10) {
      alert("Maximum of 10 Districts");
    }
    else {
      if (localDistrictArray.findIndex(item => item === e.target.value) >= 0) {
        alert("District Exist");
      }
      else {
        localDistrictArray.push(e.target.value);
        setDistrictSelect(e.target.value);
        setDistrictArray(localDistrictArray);
      }
    }

  }

  const handleAddtionalRemarks = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS_BIG) {
      setAdditionInformationRemarks(e.target.value);
    }
  }

  useEffect(() => {
    if (confirmedDelete == true) {
      if (props.accountView == "Commercial") {
        deleteListing();
      }
      else {
        deleteListingResidential();
      }

    }
  }, [confirmedDelete])

  const showTab = (n) => {
    var allStepper = document.getElementsByClassName("stepper_tab");
    allStepper[n].style.display = "block";
    if (n == 0) {
      document.getElementById("prevBtn").style.display = "none";
    } else {
      document.getElementById("prevBtn").style.display = "inline";
    }
    if (n == (allStepper.length - 1)) {
      document.getElementById("nextBtn").style.display = "none";
    } else {
      document.getElementById("nextBtn").style.display = "inline";
    }

    fixStepIndicator(n);
  }

  const fixStepIndicator = (n) => {
    var x = document.getElementsByClassName("step");
    for (var i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(" activeStep", "");
    }
    //... and adds the "active" class on the current step:
    x[n].className += " activeStep";
  }

  useEffect(() => {
    if (user.hasOwnProperty("uid")) {
      getCurrentUserProfile();
    }
  }, [user])

  const getCurrentUserProfile = async () => {
    await getDoc(doc(db, `userInfo`, user.uid)).then((indivDoc) => {
      if (indivDoc.data().userType.includes("Agent")) {
        setDualRoleAgent("Agent");
      }
      setCurrentUserInfo(indivDoc.data());
    })
  }

  const getModifyListing = async (id) => {

    var moreDetails;
    const docSnap = await getDoc(doc(db, `listingCommercialPurchase`, id));



    var existingProjectData = docSnap.data();
    document.getElementById('locationListing').value = existingProjectData.location;
    document.getElementById('postalCode').value = existingProjectData.postalCode;
    setBusinessName(existingProjectData.listingName);
    setDistrictArray(existingProjectData.district);
    setPropertyTypeCommercial(existingProjectData.propertyType);
    setSpecificPropertyType(existingProjectData.specificPropertyType);
    setMinFloorSize(existingProjectData.minFloorSize);
    setMaxFloorSize(existingProjectData.maxFloorSize);
    setMinFloorSizePSF(existingProjectData.minFloorSizePSFBudget);
    setMaxFloorSizePSF(existingProjectData.maxFloorSizePSFBudget);
    const date = new Timestamp(existingProjectData.moveInPeriod.seconds, existingProjectData.moveInPeriod.nanoseconds).toDate();
    var year = date.getFullYear();
    if ((date.getMonth() + 1) < 10) {
      var month = "0" + (date.getMonth() + 1);
    }
    else {
      var month = (date.getMonth() + 1);
    }
    if (date.getDate() < 10) {
      var day = "0" + (date.getDate());
    }
    else {
      var day = date.getDate();
    }
    setMoveInPeriod(year + "-" + month + "-" + day);
    setTenureArray(existingProjectData.tenure);
    var radioButtons = document.querySelectorAll('input[name="currentlyTenated"]');
    if (existingProjectData.currentlyTenated == "Yes") {
      radioButtons[0].checked = true;
    }
    else {
      radioButtons[1].checked = true;
    }
    setAmenitiesArray(existingProjectData.amenities);
    document.getElementById('additionalRemarks').value.replace(/\n/g, '<br>')
    setAdditionInformationRemarks(existingProjectData.additionalRemarks.replace(/<br>/g, '\n'));

    if (docSnap.data().preferredStatus == true) {
      const preferredDetailsExist = await getDocs(collection(db, `listingCommercialPurchase/${id}/PreferredDetails`));
      preferredDetailsExist.docs.map((indivExist) => {
        setExistingProject({ ...docSnap.data(), ...indivExist.data() });
        setAdvancedFinancing(indivExist.data().advancedFinancing);
        setAdvancedAIP(indivExist.data().advancedAIP);
        setAdvancedCOV(indivExist.data().advancedCOV);
        setPurchaseTenancy(indivExist.data().purchaseTenancy);
        setPurposeOfProperty(indivExist.data().purposeOfProperty);
        setAdvancedSellExisting(indivExist.data().advancedSellExisting);

      })
    }
    else {
      setExistingProject({ ...docSnap.data() });
    }
  }

  const getModifyListingResidential = async (id) => {

    var moreDetails, preferredDetails;
    const docSnap = await getDoc(doc(db, `residentialListingPurchase`, id));
    const preferredDetailSnapshot = await getDocs(collection(db, `residentialListingPurchase/${id}/PreferredDetails`));


    preferredDetailSnapshot.forEach((indivDoc) => {
      if (indivDoc.exists()) {
        setAdvancedFinancing(indivDoc.data().advancedFinancing);
        setAdvancedAIP(indivDoc.data().advancedAIP);
        setAdvancedCOV(indivDoc.data().advancedCOV);
        setPurchaseTenancy(indivDoc.data().purchaseTenancy);
        setPurposeOfProperty(indivDoc.data().purposeOfProperty);
        setAdvancedSellExisting(indivDoc.data().advancedSellExisting);
      }
      preferredDetails = indivDoc.data();
    })


    var existingProjectData = docSnap.data();
    document.getElementById('locationListing').value = existingProjectData.location;
    document.getElementById('postalCode').value = existingProjectData.postalCode;
    setBusinessName(existingProjectData.listingName);
    setDistrictArray(existingProjectData.district);
    setPropertyTypeResidential(existingProjectData.propertyType);
    setBedroomArray(existingProjectData.bedrooms);
    setMinFloorSize(existingProjectData.minFloorSize);
    setMaxFloorSize(existingProjectData.maxFloorSize);
    setTenureArray(existingProjectData.tenure);
    setFloorLevelArray(existingProjectData.floorLevel);
    setBudgetOverall(existingProjectData.budgetOverall)
    const date = new Timestamp(existingProjectData.moveInPeriod.seconds, existingProjectData.moveInPeriod.nanoseconds).toDate();
    var year = date.getFullYear();
    if ((date.getMonth() + 1) < 10) {
      var month = "0" + (date.getMonth() + 1);
    }
    else {
      var month = (date.getMonth() + 1);
    }
    if (date.getDate() < 10) {
      var day = "0" + (date.getDate());
    }
    else {
      var day = date.getDate();
    }
    setMoveInPeriod(year + "-" + month + "-" + day);
    var radioButtons = document.querySelectorAll('input[name="BalconyOption"]');
    if (existingProjectData.balcony == "Yes") {
      radioButtons[0].checked = true;
    }
    else {
      radioButtons[1].checked = true;
    }
    setAmenitiesArray(existingProjectData.amenities);
    setAdditionInformationRemarks(existingProjectData.additionalRemarks.replace(/<br>/g, '\n'));

    setExistingProject({ ...docSnap.data(), ...preferredDetails });

  }

  const valueOfResidentialSale = async () => {


    const commonSnap = await getDoc(doc(db, "commonData", 'information'));
    const docSnap = await getDoc(doc(db, `residentialListingPurchase`, props.modifyListingId));
    // for (var i = 0; i < pendingListingResidentialSale.length; i++) {

    // if (pendingListingResidentialSale[i].id == id) {

    var answer = (docSnap.data().budgetOverall);
    console.log(answer);
    setExistingListingAmount(answer);
    // resolve(Number(commonSnap.data().totalCompanyValue) + answer);
    // }
    // }

  }

  const valueOfCommercialSale = async () => {


    const commonSnap = await getDoc(doc(db, "commonData", 'information'));
    const docSnap = await getDoc(doc(db, `listingCommercialPurchase`, props.modifyListingId));

    // for (var i = 0; i < pendingListingSale.length; i++) {
    var maxPSFBudget = docSnap.data().maxFloorSizePSFBudget;

    // if (pendingListingSale[i].id == id) {
    var answer = (docSnap.data().maxFloorSize * maxPSFBudget);
    console.log(answer);
    setExistingListingAmount(answer);
    // resolve(Number(commonSnap.data().totalCompanyValue) + answer);
    // }
    // }

  }

  useEffect(() => {
    if (!props.trigger) {

    }
    else {

      if (props.modifyListingId != null && props.accountView != null) {
        if (props.accountView == "Commercial") {
          setTypeOfSpace(props.accountView)
          getModifyListing(props.modifyListingId);
          valueOfCommercialSale();

        }
        else {
          setTypeOfSpace(props.accountView)
          getModifyListingResidential(props.modifyListingId);
          valueOfResidentialSale();
        }

      }
    }
  }, [props.modifyListingId, props.accountView])

  useEffect(() => {
    if (!props.trigger) {
    }
    else {
      // if (typeOfSpace == "Commercial") {
      showTab(currentTab.current);
      // }

    }
  })

  const deleteDistrictArray = (targetName) => {
    var localDistrictArray = [...districtArray];
    var indexFound = localDistrictArray.findIndex(item => item === targetName);
    if (indexFound >= 0) {
      localDistrictArray.splice(indexFound, 1);
      setDistrictArray(localDistrictArray);
    }
  }

  const shiftUp = (targetName) => {
    const indexFound = districtArray.findIndex(item => item === targetName);
    if (indexFound > 0) {
      const localDistrictArray = [...districtArray];
      // Swap the elements at indexFound and indexFound - 1
      [localDistrictArray[indexFound], localDistrictArray[indexFound - 1]] = [localDistrictArray[indexFound - 1], localDistrictArray[indexFound]];
      setDistrictArray(localDistrictArray);
    }
  }

  const shiftDown = (targetName) => {
    const indexFound = districtArray.findIndex(item => item === targetName);
    if (indexFound >= 0 && indexFound < districtArray.length - 1) {
      const localDistrictArray = [...districtArray];
      // Swap the elements at indexFound and indexFound + 1
      [localDistrictArray[indexFound], localDistrictArray[indexFound + 1]] = [localDistrictArray[indexFound + 1], localDistrictArray[indexFound]];
      setDistrictArray(localDistrictArray);
    }
  }

  const deleteBedroomArray = (targetName) => {
    var localBedroomArray = [...bedroomArray];
    var indexFound = localBedroomArray.findIndex(item => item === targetName);
    if (indexFound >= 0) {
      localBedroomArray.splice(indexFound, 1);
      setBedroomArray(localBedroomArray);
    }
  }

  const deleteTenureArray = (targetName) => {
    var localTenureArray = [...tenureArray];
    var indexFound = localTenureArray.findIndex(item => item === targetName);
    if (indexFound >= 0) {
      localTenureArray.splice(indexFound, 1);
      setTenureArray(localTenureArray);
    }
  }

  const deleteFloorLevel = (targetName) => {
    var localFloorLevelArray = [...floorLevelArray];
    var indexFound = localFloorLevelArray.findIndex(item => item === targetName);
    if (indexFound >= 0) {
      localFloorLevelArray.splice(indexFound, 1);
      setFloorLevelArray(localFloorLevelArray);
    }
  }

  const deleteAmenitiesArray = (targetName) => {
    var localAmenitiesArray = [...amenitiesArray];
    var indexFound = localAmenitiesArray.findIndex(item => item === targetName);
    if (indexFound >= 0) {
      localAmenitiesArray.splice(indexFound, 1);
      setAmenitiesArray(localAmenitiesArray);
    }
  }

  const newResidentialListing = async () => {
    setWarning(false);
    if (businessName == "" || businessName == null || districtArray.length == 0 || bedroomArray.length == 0 || minFloorSize == 0 || maxFloorSize == 0 || minFloorSize == null || maxFloorSize == null || moveInPeriod == null || budgetOverall == "") {
      if (businessName == "") {
        document.getElementById('listingName').style.border = "1px solid red"
      }
      if (businessName == null) {
        document.getElementById('listingName').style.border = "1px solid red"
      }
      if (districtArray.length == 0) {
        document.getElementById('districtType').style.border = "1px solid red"
      }
      if (bedroomArray.length == 0) {
        document.getElementById('bedrooms').style.border = "1px solid red"
      }
      if (minFloorSize == 0) {
        document.getElementById('minFloorSize').style.border = "1px solid red"
      }
      if (maxFloorSize == 0) {
        document.getElementById('maxFloorSize').style.border = "1px solid red"
      }
      if (minFloorSize == null) {
        document.getElementById('minFloorSize').style.border = "1px solid red"
      }
      if (maxFloorSize == null) {
        document.getElementById('maxFloorSize').style.border = "1px solid red"
      }
      if (moveInPeriod == null) {
        document.getElementById('periodMoveIn').style.border = "1px solid red"
      }
      if (budgetOverall == "") {
        document.getElementById('residentialSaleBudget').style.border = "1px solid red"
      }
      alert("Missing Parameters");
    }
    else {
      setOpenloader(true);
      var radioButtons = document.querySelectorAll('input[name="BalconyOption"]');
      var balconyValue = "No";
      for (var i = 0; i < radioButtons.length; i++) {
        if (radioButtons[i].checked) {
          balconyValue = radioButtons[i].value;
        }
      }

      var currentStatus = "pending";
      console.log(props, dualRoleAgent);
      if(props.currentUserInfo.userType.includes("Agent") == true && dualRoleAgent == "Agent" && props.publicInfo.agentProfile.verified == true){
        currentStatus = "leasing"
      }
      var cardInformation = {
        UID: user.uid,
        listingName: businessName,
        district: districtArray,
        propertyType: propertyTypeResidential,
        bedrooms: bedroomArray,
        minFloorSize: parseInt(minFloorSize),
        maxFloorSize: parseInt(maxFloorSize),
        tenure: tenureArray,
        floorLevel: floorLevelArray,
        moveInPeriod: new Date(moveInPeriod),
        balcony: balconyValue,
        amenities: amenitiesArray,
        additionalRemarks: document.getElementById('additionalRemarks').value.replace(/\n/g, '<br>'),
        status: currentStatus,
        eyeballStat: [0, 0, 0, 0, 0, 0, 0],
        connections: 0,
        timePosted: new Date(),
        boost: false,
        boostStart: null,
        boostLeft: 0,
        eyeball: 0,
        conversationRate: 0,
        preferredStatus: false,
        pendingChats: 0,
        budgetOverall: parseInt(budgetOverall),
        username: props.publicInfo.username,
        report: 0,
        location: document.getElementById('locationListing').value == "" ? null : document.getElementById('locationListing').value,
        postalCode: document.getElementById('postalCode').value == "" ? null : document.getElementById('postalCode').value,
        userImage: currentUserInfo.userPhotoURL,
        viewID: null,
        propertyAgent: dualRoleAgent == "Agent" ? true : false,
        propertyAgentCompany: dualRoleAgent == "Agent" ? props.publicInfo.hasOwnProperty('agentProfile') ? props.publicInfo.agentProfile.CEAAgency : null : null,
      }
      if (advancedFinancing != "" && advancedCOV != "" && purchaseTenancy != "" && purposeOfProperty != "" && advancedSellExisting != "") {
        var advancedDetails = {
          advancedFinancing: advancedFinancing,
          advancedAIP: advancedAIP,
          advancedCOV: advancedCOV,
          purchaseTenancy: purchaseTenancy,
          purposeOfProperty: purposeOfProperty,
          advancedSellExisting: advancedSellExisting
        }
        cardInformation.preferredStatus = true;
      }

      if(cardInformation.status == "leasing"){
        var companyValue = await getDoc(doc(db, 'commonData', "information"));
        console.log("inside", budgetOverall, companyValue);
        companyValue = companyValue.data().totalCompanyValue + (parseInt(budgetOverall));
        let updatedSnapshot = await updateDoc(doc(db, 'commonData', "information"), {
          totalCompanyValue: companyValue
        })
      }

      await addDoc(collection(db, `residentialListingPurchase`), cardInformation).then(async function (docRef) {
        if (advancedFinancing != "" && advancedCOV != "" && purchaseTenancy != "" && purposeOfProperty != "" && advancedSellExisting != "") {
          await addDoc(collection(db, `residentialListingPurchase/${docRef.id}/PreferredDetails`), advancedDetails).then(async () => {
            const token = user.accessToken;
            const requestOptions_1 = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
              body: JSON.stringify({ data: { email: `info@nextloop.sg`, path: "New Listing" } })
            };
            await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
              error => console.log(error)
            );
            setMessages("Listing Added");
            setClickConfirm(true);
            setOpenloader(false);
          })
        }
        else {
          const token = user.accessToken;
          const requestOptions_1 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify({ data: { email: `info@nextloop.sg`, path: "New Listing" } })
          };
          await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
            error => console.log(error)
          );
          setMessages("Listing Added");
          setClickConfirm(true);
          setOpenloader(false);
        }
      })
    }

  }

  const modifyResidentialListing = async () => {

    if (businessName == "" || districtArray.length == 0 || bedroomArray.length == 0 || minFloorSize == 0 || maxFloorSize == 0 || minFloorSize == null || maxFloorSize == null || moveInPeriod == null || budgetOverall == "") {
      if (businessName == "") {
        document.getElementById('listingName').style.border = "1px solid red"
      }
      if (districtArray.length == 0) {
        document.getElementById('districtType').style.border = "1px solid red"
      }
      if (bedroomArray.length == 0) {
        document.getElementById('bedrooms').style.border = "1px solid red"
      }
      if (minFloorSize == 0) {
        document.getElementById('minFloorSize').style.border = "1px solid red"
      }
      if (maxFloorSize == 0) {
        document.getElementById('maxFloorSize').style.border = "1px solid red"
      }
      if (minFloorSize == null) {
        document.getElementById('minFloorSize').style.border = "1px solid red"
      }
      if (maxFloorSize == null) {
        document.getElementById('maxFloorSize').style.border = "1px solid red"
      }
      if (moveInPeriod == null) {
        document.getElementById('periodMoveIn').style.border = "1px solid red"
      }
      if (budgetOverall == "") {
        document.getElementById('residentialSaleBudget').style.border = "1px solid red"
      }
      alert("Missing Parameters");
    }
    else {
      setOpenloader(true);
      var radioButtons = document.querySelectorAll('input[name="BalconyOption"]');
      var balconyValue = "No";
      for (var i = 0; i < radioButtons.length; i++) {
        if (radioButtons[i].checked) {
          balconyValue = radioButtons[i].value;
        }
      }

      // console.log(existingProject);

      var cardInformation = {
        UID: user.uid,
        listingName: businessName,
        district: districtArray,
        propertyType: propertyTypeResidential,
        bedrooms: bedroomArray,
        minFloorSize: minFloorSize,
        maxFloorSize: maxFloorSize,
        tenure: tenureArray,
        floorLevel: floorLevelArray,
        moveInPeriod: new Date(moveInPeriod),
        balcony: balconyValue,
        amenities: amenitiesArray,
        additionalRemarks: document.getElementById('additionalRemarks').value.replace(/\n/g, '<br>'),
        status: existingProject.status == "rejected" ? "pending" : existingProject.status,
        eyeballStat: existingProject.eyeballStat,
        connections: existingProject.connections,
        timePosted: new Date(),
        boost: existingProject.boost,
        budgetOverall: parseInt(budgetOverall),
        boostStart: existingProject.boostStart,
        boostLeft: existingProject.boostLeft,
        eyeball: existingProject.eyeball,
        conversationRate: existingProject.conversationRate,
        preferredStatus: existingProject.preferredStatus,
        pendingChats: existingProject.pendingChats,
        username: props.publicInfo.username,
        report: existingProject.report,
        userImage: currentUserInfo.userPhotoURL,
        viewID: existingProject.viewID,
        modifyBefore: true,
        location: document.getElementById('locationListing').value == "" ? null : document.getElementById('locationListing').value,
        postalCode: document.getElementById('postalCode').value == "" ? null : document.getElementById('postalCode').value,
        propertyAgent: dualRoleAgent == "Agent" ? true : false,
        propertyAgentCompany: dualRoleAgent == "Agent" ? props.publicInfo.hasOwnProperty('agentProfile') ? props.publicInfo.agentProfile.CEAAgency : null : null,
      }
      if (advancedFinancing != "" && advancedCOV != "" && purchaseTenancy != "" && purposeOfProperty != "" && advancedSellExisting != "") {
        var advancedDetails = {
          advancedFinancing: advancedFinancing,
          advancedAIP: advancedAIP,
          advancedCOV: advancedCOV,
          purchaseTenancy: purchaseTenancy,
          purposeOfProperty: purposeOfProperty,
          advancedSellExisting: advancedSellExisting
        }
        cardInformation.preferredStatus = true;
      }
      else {
        cardInformation.preferredStatus = false;
      }
      const commonSnap = await getDoc(doc(db, "commonData", 'information'));
      var left = (commonSnap.data().totalCompanyValue - existingListingAmount) + (parseInt(budgetOverall))
      console.log(left, existingListingAmount, budgetOverall, commonSnap.data().totalCompanyValue);
      await updateDoc(doc(db, "commonData", 'information'), {
        totalCompanyValue: left
      });
      await updateDoc(doc(db, `residentialListingPurchase`, props.modifyListingId), cardInformation).then(async () => {
        if (cardInformation.preferredStatus == false) {


          setMessages("Listing Modified")
          setClickConfirm(true);
          setOpenloader(false);



        }
        else {
          await getDocs(collection(db, `residentialListingPurchase/${props.modifyListingId}/PreferredDetails`)).then(async (preferredDoc) => {
            if (preferredDoc.docs.length != 0) {
              preferredDoc.forEach(async (indivPreferredDoc) => {
                await updateDoc(doc(db, `residentialListingPurchase/${props.modifyListingId}/PreferredDetails`, indivPreferredDoc.id), advancedDetails).then(() => {
                  setMessages("Listing Modified")
                  setClickConfirm(true);
                  setOpenloader(false);
                })
              })
            }
            else {
              await addDoc(collection(db, `residentialListingPurchase/${props.modifyListingId}/PreferredDetails`), advancedDetails).then(() => {
                setMessages("Listing Modified")
                setClickConfirm(true);
                setOpenloader(false);
              })
            }

          })
        }

      })
    }

  }

  const modifyListing = async () => {
    if (businessName == "" || districtArray.length == 0 || minFloorSize == 0 || maxFloorSize == 0 || minFloorSizePSF == 0 || maxFloorSizePSF == 0 || minFloorSize == null || maxFloorSize == null || minFloorSizePSF == null || maxFloorSizePSF == null || moveInPeriod == null) {
      if (businessName == "") {
        document.getElementById('listingName').style.border = "1px solid red"
      }
      if (districtArray.length == 0) {
        document.getElementById('districtType').style.border = "1px solid red"
      }
      if (minFloorSize == 0) {
        document.getElementById('minFloorSize').style.border = "1px solid red"
      }
      if (maxFloorSize == 0) {
        document.getElementById('maxFloorSize').style.border = "1px solid red"
      }
      if (minFloorSizePSF == 0) {
        document.getElementById('minFloorSizePSF').style.border = "1px solid red"
      }
      if (maxFloorSizePSF == 0) {
        document.getElementById('maxFloorSizePSF').style.border = "1px solid red"
      }
      if (minFloorSizePSF == null) {
        document.getElementById('minFloorSizePSF').style.border = "1px solid red"
      }
      if (maxFloorSizePSF == null) {
        document.getElementById('maxFloorSizePSF').style.border = "1px solid red"
      }
      if (moveInPeriod == null) {
        document.getElementById('periodMoveIn').style.border = "1px solid red"
      }
      alert("Missing Parameters");
    }
    else {
      setOpenloader(true);
      var radioButtons = document.querySelectorAll('input[name="currentlyTenated"]');
      var currentlyTenatedValue = "No";
      for (var i = 0; i < radioButtons.length; i++) {
        if (radioButtons[i].checked) {
          currentlyTenatedValue = radioButtons[i].value;
        }
      }
      // console.log(document.getElementById('locationListing').value);

      var cardInformation = {
        UID: user.uid,
        listingName: businessName,
        district: districtArray,
        propertyType: propertyTypeCommercial,
        specificPropertyType: specificPropertyType,
        minFloorSize: parseInt(minFloorSize),
        maxFloorSize: parseInt(maxFloorSize),
        minFloorSizePSFBudget: parseInt(minFloorSizePSF),
        maxFloorSizePSFBudget: parseInt(maxFloorSizePSF),
        moveInPeriod: new Date(moveInPeriod),
        tenure: tenureArray,
        currentlyTenated: currentlyTenatedValue,
        amenities: amenitiesArray,
        additionalRemarks: document.getElementById('additionalRemarks').value.replace(/\n/g, '<br>'),
        preferredStatus: existingProject.preferredStatus,
        status: existingProject.status == "rejected" ? "pending" : existingProject.status,
        eyeballStat: existingProject.eyeballStat,
        timePosted: new Date(),
        boost: existingProject.boost,
        boostStart: existingProject.boostStart,
        boostLeft: existingProject.boostLeft,
        eyeball: existingProject.eyeball,
        pendingChats: existingProject.pendingChats,
        username: props.publicInfo.username,
        connections: existingProject.connections,
        conversationRate: existingProject.conversationRate,
        userImage: currentUserInfo.userPhotoURL,
        viewID: existingProject.viewID,
        modifyBefore: true,
        location: document.getElementById('locationListing').value == "" ? null : document.getElementById('locationListing').value,
        postalCode: document.getElementById('postalCode').value == "" ? null : document.getElementById('postalCode').value,
        propertyAgent: dualRoleAgent == "Agent" ? true : false,
        propertyAgentCompany: dualRoleAgent == "Agent" ? props.publicInfo.hasOwnProperty('agentProfile') ? props.publicInfo.agentProfile.CEAAgency : null : null,
      }
      if (advancedFinancing != "" && advancedCOV != "" && purchaseTenancy != "" && purposeOfProperty != "" && advancedSellExisting != "") {
        var advancedDetails = {
          advancedFinancing: advancedFinancing,
          advancedAIP: advancedAIP,
          advancedCOV: advancedCOV,
          purchaseTenancy: purchaseTenancy,
          purposeOfProperty: purposeOfProperty,
          advancedSellExisting: advancedSellExisting
        }
        cardInformation.preferredStatus = true;
      }
      else {
        cardInformation.preferredStatus = false;
      }
      const commonSnap = await getDoc(doc(db, "commonData", 'information'));
      var left = (commonSnap.data().totalCompanyValue - existingListingAmount) + ((parseInt(maxFloorSizePSF) * parseInt(maxFloorSize)));
      console.log(commonSnap.data().totalCompanyValue, existingListingAmount, (((parseInt(maxFloorSizePSF) * parseInt(maxFloorSize)))))
      await updateDoc(doc(db, "commonData", 'information'), {
        totalCompanyValue: left
      }).then(() => {
        console.log('updated')
      });

      await updateDoc(doc(db, `listingCommercialPurchase`, props.modifyListingId), cardInformation).then(async () => {
        console.log(cardInformation);
        if (cardInformation.preferredStatus == false) {
          await getDocs(collection(db, `listingCommercialPurchase/${props.modifyListingId}/PreferredDetails`)).then(async (preferredDoc) => {
            if (preferredDoc.docs.length != 0) {
              preferredDoc.forEach(async (indivPreferredDoc) => {
                await deleteDoc(doc(db, `listingCommercialPurchase/${props.modifyListingId}/PreferredDetails`, indivPreferredDoc.id)).then(() => {

                  setMessages("Listing Modified")
                  setClickConfirm(true);
                  setOpenloader(false);
                })
              })
            }
            else {
              setMessages("Listing Modified")
              setClickConfirm(true);
              setOpenloader(false);
            }
          })

        }
        else {
          await getDocs(collection(db, `listingCommercialPurchase/${props.modifyListingId}/PreferredDetails`)).then(async (preferredDoc) => {
            console.log(preferredDoc);
            if (preferredDoc.docs.length != 0) {
              preferredDoc.forEach(async (indivPreferredDoc) => {
                await updateDoc(doc(db, `listingCommercialPurchase/${props.modifyListingId}/PreferredDetails`, indivPreferredDoc.id), advancedDetails).then(() => {
                  setMessages("Listing Modified")
                  setClickConfirm(true);
                  setOpenloader(false);
                })
              })
            }
            else {
              await addDoc(collection(db, `listingCommercialPurchase/${props.modifyListingId}/PreferredDetails`), advancedDetails).then(() => {
                setMessages("Listing Modified")
                setClickConfirm(true);
                setOpenloader(false);
              })
            }

          })
        }

      })

    }
  }

  const confirmBothListingType = () => {
    if (advancedFinancing == "" || advancedCOV == "" || purchaseTenancy == "" || purposeOfProperty == "" || advancedSellExisting == "") {
      setAcknowledgeMessage("Your listing is not preferred yet. Fill up all the information on second page to become a preferred listing.");
      setAcknowledgeNormalWarning(true);
    }
    else {
      if (typeOfSpace == "Commercial") {
        confirmNewProject();
      }
      else {
        newResidentialListing();
      }
    }
  }

  useEffect(() => {
    if (warning == true) {
      if (typeOfSpace == "Commercial") {
        confirmNewProject();
      }
      else {
        newResidentialListing();
      }
    }
  }, [warning])

  const confirmNewProject = async () => {

    setWarning(false);
    if (businessName == "" || districtArray.length == 0 || minFloorSize == 0 || maxFloorSize == 0 || minFloorSizePSF == 0 || maxFloorSizePSF == 0 || minFloorSize == null || maxFloorSize == null || minFloorSizePSF == null || maxFloorSizePSF == null || moveInPeriod == null) {
      if (businessName == "") {
        document.getElementById('listingName').style.border = "1px solid red"
      }
      if (districtArray.length == 0) {
        document.getElementById('districtType').style.border = "1px solid red"
      }
      if (minFloorSize == 0) {
        document.getElementById('minFloorSize').style.border = "1px solid red"
      }
      if (maxFloorSize == 0) {
        document.getElementById('maxFloorSize').style.border = "1px solid red"
      }
      if (minFloorSizePSF == 0) {
        document.getElementById('minFloorSizePSF').style.border = "1px solid red"
      }
      if (maxFloorSizePSF == 0) {
        document.getElementById('maxFloorSizePSF').style.border = "1px solid red"
      }
      if (minFloorSizePSF == null) {
        document.getElementById('minFloorSizePSF').style.border = "1px solid red"
      }
      if (maxFloorSizePSF == null) {
        document.getElementById('maxFloorSizePSF').style.border = "1px solid red"
      }
      if (moveInPeriod == null) {
        document.getElementById('periodMoveIn').style.border = "1px solid red"
      }
      alert("Missing Parameters");
    }
    else {
      setOpenloader(true);

      var radioButtons = document.querySelectorAll('input[name="currentlyTenated"]');
      var currentlyTenatedValue = "No";
      for (var i = 0; i < radioButtons.length; i++) {
        if (radioButtons[i].checked) {
          currentlyTenatedValue = radioButtons[i].value;
        }
      }
      var currentStatus = "pending"
      if(props.currentUserInfo.userType.includes("Agent") == true && dualRoleAgent == "Agent" && props.publicInfo.agentProfile.verified == true){
        currentStatus = "leasing"
      }
      var cardInformation = {
        UID: user.uid,
        listingName: businessName,
        district: districtArray,
        propertyType: propertyTypeCommercial,
        specificPropertyType: specificPropertyType,
        minFloorSize: parseInt(minFloorSize),
        maxFloorSize: parseInt(maxFloorSize),
        minFloorSizePSFBudget: parseInt(minFloorSizePSF),
        maxFloorSizePSFBudget: parseInt(maxFloorSizePSF),
        moveInPeriod: new Date(moveInPeriod),
        tenure: tenureArray,
        currentlyTenated: currentlyTenatedValue,
        amenities: amenitiesArray,
        additionalRemarks: document.getElementById('additionalRemarks').value.replace(/\n/g, '<br>'),
        preferredStatus: false,
        status: currentStatus,
        eyeballStat: [0, 0, 0, 0, 0, 0, 0],
        timePosted: new Date(),
        boost: false,
        boostStart: null,
        boostLeft: null,
        eyeball: 0,
        pendingChats: 0,
        username: props.publicInfo.username,
        connections: 0,
        conversationRate: 0,
        userImage: currentUserInfo.userPhotoURL,
        viewID: null,
        location: document.getElementById('locationListing').value == "" ? null : document.getElementById('locationListing').value,
        postalCode: document.getElementById('postalCode').value == "" ? null : document.getElementById('postalCode').value,
        propertyAgent: dualRoleAgent == "Agent" ? true : false,
        propertyAgentCompany: dualRoleAgent == "Agent" ? props.publicInfo.hasOwnProperty('agentProfile') ? props.publicInfo.agentProfile.CEAAgency : null : null,
      }
      if (advancedFinancing != "" && advancedCOV != "" && purchaseTenancy != "" && purposeOfProperty != "" && advancedSellExisting != "") {
        var advancedDetails = {
          advancedFinancing: advancedFinancing,
          advancedAIP: advancedAIP,
          advancedCOV: advancedCOV,
          purchaseTenancy: purchaseTenancy,
          purposeOfProperty: purposeOfProperty,
          advancedSellExisting: advancedSellExisting
        }
        cardInformation.preferredStatus = true;
      }

      if(cardInformation.status == "leasing"){
        var companyValue = await getDoc(doc(db, 'commonData', "information"));
        companyValue = companyValue.data().totalCompanyValue + (parseInt(maxFloorSizePSF) * parseInt(maxFloorSize));
        let updatedSnapshot = await updateDoc(doc(db, 'commonData', "information"), {
          totalCompanyValue: companyValue
        })
      }

      const token = user.accessToken;
      const requestOptions_1 = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({ data: { email: `info@nextloop.sg`, path: "New Listing" } })
      };
      await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
        error => console.log(error)
      );
      await addDoc(collection(db, `listingCommercialPurchase`), cardInformation).then(async function (docRef) {

        if (advancedFinancing != "" && advancedCOV != "" && purchaseTenancy != "" && purposeOfProperty != "" && advancedSellExisting != "") {
          await addDoc(collection(db, `listingCommercialPurchase/${docRef.id}/PreferredDetails`), advancedDetails).then(() => {
            setMessages("Listing Added");
            setClickConfirm(true);
            setOpenloader(false);
          })
        }
        else {
          setMessages("Listing Added");
          setClickConfirm(true);
          setOpenloader(false);
        }


      })
    }
  }

  const closeNewProjecct = () => {
    setTypeOfSpace("Commercial");
    setBusinessName("");
    setMinFloorSize(null);
    setMaxFloorSize(null);
    setMinFloorSizePSF(null);
    setMaxFloorSizePSF(null);
    setDistrictArray([]);
    setSpecificPropertyType("Any");
    setMoveInPeriod(null);
    setTenureArray([]);
    setAmenitiesArray([]);
    setFloorLevelArray([]);
    setAdditionInformationRemarks(null);
    currentTab.current = 0;
    setAdvancedFinancing("");
    setAdvancedAIP("No");
    setAdvancedCOV("");
    setPurchaseTenancy("");
    setPurposeOfProperty("");
    setDualRoleAgent(null);
    setBudgetOverall(0);
    setAdvancedSellExisting("");
    props.setModifyListingId(null);
    props.setHomeRequirements(null);
    props.setTrigger(false);
    

  }

  const nextPrev = (n) => {
    var allStepper = document.getElementsByClassName("stepper_tab");

    allStepper[currentTab.current].style.display = "none";
    if (currentTab.current + n >= allStepper.length) {
      return false;
    }
    else {
      currentTab.current = currentTab.current + n;
      showTab(currentTab.current);
    }

  }

  function handleDragStart(event, index) {
    event.dataTransfer.setData('text/plain', index);
  }

  function handleDragOver(event) {
    event.preventDefault();
  }

  function handleDrop(event, targetIndex) {
    event.preventDefault();
    const sourceIndex = event.dataTransfer.getData('text/plain');
    const updatedArray = [...districtArray];
    const [removed] = updatedArray.splice(sourceIndex, 1);
    updatedArray.splice(targetIndex, 0, removed);
    setDistrictArray(updatedArray);
  }

  const deleteListing = async () => {

    var cardInformation = {
      UID: user.uid,
      listingName: existingProject.listingName,
      district: existingProject.district,
      propertyType: existingProject.propertyType,
      specificPropertyType: existingProject.specificPropertyType,
      minFloorSize: existingProject.minFloorSize,
      maxFloorSize: existingProject.maxFloorSize,
      minFloorSizePSFBudget: existingProject.minFloorSizePSFBudget,
      maxFloorSizePSFBudget: existingProject.maxFloorSizePSFBudget,
      moveInPeriod: existingProject.moveInPeriod,
      tenure: existingProject.tenure,
      currentlyTenated: existingProject.currentlyTenated,
      amenities: existingProject.amenities,
      additionalRemarks: existingProject.additionalRemarks,
      preferredStatus: existingProject.preferredStatus,
      status: existingProject.status,
      eyeballStat: existingProject.eyeballStat,
      timePosted: existingProject.timePosted,
      boost: existingProject.boost,
      boostStart: existingProject.boostStart,
      boostLeft: existingProject.boostLeft,
      eyeball: existingProject.eyeball,
      pendingChats: existingProject.pendingChats,
      username: props.publicInfo.username,
      connections: existingProject.connections,
      conversationRate: existingProject.conversationRate,
      userImage: currentUserInfo.userPhotoURL
    }

    await setDoc(doc(db, `deleteListing`, props.modifyListingId), cardInformation).then(async () => {

      if (existingProject.preferredStatus == true) {
        var preferredDetails = {
          // paidUpCaptial: existingProject.paidUpCaptial,
          // coporateTurnover: existingProject.coporateTurnover,
          // otherRental: existingProject.otherRental,
          // clinteleBreakdownLocal: existingProject.clinteleBreakdownLocal,
          // clinteleBreakdownTourist: existingProject.clinteleBreakdownTourist,
          // product_pricing: existingProject.product_pricing,
          // revenueBreakdownWalkin: existingProject.revenueBreakdownWalkin,
          // revenueBreakdownRegular: existingProject.revenueBreakdownRegular,
          // outletLocation: existingProject.outletLocation,
          // expectedRevenue: existingProject.expectedRevenue,
          // companyDeckURL: existingProject.companyDeckURL,
          // companyName: existingProject.companyName,
          // autoFillLink: existingProject.autoFillLink
        }
        await getDocs(collection(db, `listingCommercialPurchase/${props.modifyListingId}/PreferredDetails`)).then(async (preferredDetailIndiv) => {
          preferredDetailIndiv.forEach(async (indivPreferred) => {
            await addDoc(collection(db, `deleteListing/${props.modifyListingId}/PreferredDetails`), preferredDetails, indivPreferred.id)
            await deleteDoc(doc(db, `listingCommercialPurchase/${props.modifyListingId}/PreferredDetails`, indivPreferred.id));
           
          })
        })
      }


    }).then(async () =>{
      const commonSnap = await getDoc(doc(db, "commonData", 'information'));
      if(existingProject.status == "rejected"){
        var left = commonSnap.data().totalCompanyValue - 0;
      }
      else{
        var left = commonSnap.data().totalCompanyValue - existingListingAmount;
      }
      // var left = commonSnap.data().totalCompanyValue - existingListingAmount;
      await updateDoc(doc(db, "commonData", 'information'), {
        totalCompanyValue: left
      }).then(async () =>{
        await deleteDoc(doc(db, `listingCommercialPurchase`, props.modifyListingId)).then(async () => {

          setMessages("Listing Deleted");
          setClickConfirm(true);
    
        });
        await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, props.modifyListingId));
      });
      
    })
    

  }

  const deleteListingResidential = async () => {
    var cardInformation = {
      UID: user.uid,
      listingName: existingProject.listingName,
      district: existingProject.district,
      propertyType: existingProject.propertyType,
      bedrooms: existingProject.bedrooms,
      minFloorSize: existingProject.minFloorSize,
      maxFloorSize: existingProject.maxFloorSize,
      tenure: existingProject.tenure,
      floorLevel: existingProject.floorLevel,
      moveInPeriod: existingProject.moveInPeriod,
      balcony: existingProject.balcony,
      amenities: existingProject.amenities,
      additionalRemarks: existingProject.additionalRemarks,
      status: existingProject.status,
      eyeballStat: existingProject.eyeballStat,
      connections: existingProject.connections,
      timePosted: existingProject.timePosted,
      boost: existingProject.boost,
      boostStart: existingProject.boostStart,
      boostLeft: existingProject.boostLeft,
      eyeball: existingProject.eyeball,
      conversationRate: existingProject.conversationRate,
      preferredStatus: existingProject.preferredStatus,
      pendingChats: existingProject.pendingChats,
      username: props.publicInfo.username,
      report: existingProject.report,
      userImage: currentUserInfo.userPhotoURL
    }

    await setDoc(doc(db, `deleteListing`, props.modifyListingId), cardInformation).then(async () => {

      if (existingProject.preferredListing == true) {
        var preferredDetails = {
          // compositeTenant: existingProject.compositeTenant,
          // relocation: existingProject.relocation,
          // residentialAge: existingProject.residentialAge,
          // residentialEmployment: existingProject.residentialEmployment,
          // residentialExpectedStay: existingProject.residentialExpectedStay,
          // residentialNationality: existingProject.residentialNationality,
          // residentialOccupation: existingProject.residentialOccupation,
          // residentialType: existingProject.residentialType,
          // residentialYearsRent: existingProject.residentialYearsRent,
          // smoke: existingProject.smoke
        }
        await getDocs(collection(db, `residentialListingPurchase/${props.modifyListingId}/PreferredDetails`)).then(async (preferredDetailIndiv) => {
          preferredDetailIndiv.forEach(async (indivPreferred) => {
            await addDoc(collection(db, `deleteListing/${props.modifyListingId}/PreferredDetails`), preferredDetails, indivPreferred.id)
            await deleteDoc(doc(db, `residentialListingPurchase/${props.modifyListingId}/PreferredDetails`, indivPreferred.id));

          })
        })
      }


    })
    await deleteDoc(doc(db, `residentialListingPurchase`, props.modifyListingId)).then(async () => {
      await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, props.modifyListingId)).then(async () => {
        const commonSnap = await getDoc(doc(db, "commonData", 'information'));
        if(existingProject.status == "rejected"){
          var left = commonSnap.data().totalCompanyValue - 0;
        }
        else{
          var left = commonSnap.data().totalCompanyValue - existingListingAmount;
        }
        // var left = commonSnap.data().totalCompanyValue - existingListingAmount;
        await updateDoc(doc(db, "commonData", 'information'), {
          totalCompanyValue: left
        });
        setMessages("Listing Deleted");
        setClickConfirm(true);
      })
    });

  }

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
      setTimeout(() => {
        e.target.focus()
    }, 0)
  }

  if (!props.trigger) {
    return;
  }
  else {
    return (
      <>
        <div className='modalNewProject'>

          <Spinner trigger={openLoader} setTrigger={setOpenloader}></Spinner>
          {/* <Warning trigger={warning} setTrigger={setWarning} continueWarning={continueWarningState} setContinueWarningState={setContinueWarningState}></Warning> */}
          <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={acknowledgeMessage} setConfirmedDelete={setConfirmedDelete}></AcknowledgeNormal>
          <AcknowledgeNormal trigger={acknowledgeNormalNoDelete} setTrigger={setAcknowledgeNormalNoDelete} message={acknowledgeMessage}></AcknowledgeNormal>
          <AcknowledgeNormal trigger={acknowledgeNormalWarning} setTrigger={setAcknowledgeNormalWarning} message={acknowledgeMessage} setWarningPurchase={setWarning}></AcknowledgeNormal>
          <Acknowledge trigger={clickConfirm} setTrigger={setClickConfirm} message={messages} />
          <div className='modal-contentNewProject'>
            <div className='modal-headerNewProject'>
              <div className="notification-close" onClick={() => closeNewProjecct()}>
                ×
              </div>
              <h4 className='modal-titleNewListing'>ADD NEW LISTING</h4>
              <span style={{paddingLeft:"10px"}}>{typeOfSpace == "Commercial" ? "Commercial Sale" : "Residential Sale"}</span>
            </div>
            <div className='modal-bodyNewProject'>


              {typeOfSpace == "Residential" ?
                //////////////////////////////////Residential
                <>

                  <div class="stepper_tab">
                    {/* {props.modifyListingId == null ? <select id="typeOfSpace" value={typeOfSpace} onChange={handleTypeOfSpaceChange}>
                      <option value="Residential">Residential</option>
                      <option value="Commercial">Commercial</option>
                    </select> : null} */}
                    <div style={{ clear: 'both' }}></div>
                    <p className='modalLabel' style={{ marginTop: "1%" }}>Listing Name*</p>
                    <input className='newListingInput' id="listingName" placeholder='(Brand name, if any) Cafe / Bicycle Shop /, etc' style={{ width: "100%" }} value={businessName} onChange={handleBusinessName}></input><span className='characterCount'>{businessName != null ? MAX_CHARACTERS_SMALL - businessName.length : MAX_CHARACTERS_SMALL} characters</span>
                    <p className='modalLabel'>*Location: </p>

                    <div style={{display:"inline-flex", width:"100%"}}><i class="fa-solid fa-plus" style={{display:"flex", justifyContent:"center", alignItems:"center"}}></i> <select className='newListingSelectMulti' id="districtType" value={districtSelect} onChange={handleDistrictArrayChange}>
                      <option value="Any">Any</option>
                      <option value="D01 City - Business District">D01 City - Business District</option>
                      <option value="D02 City - Business District">D02 City - Business District</option>
                      <option value="D03 Central South">D03 Central South</option>
                      <option value="D04 South">D04 South</option>
                      <option value="D05 South West">D05 South West</option>
                      <option value="D06 City - Business District">D06 City - Business District</option>
                      <option value="D07 City">D07 City</option>
                      <option value="D08 Central">D08 Central</option>
                      <option value="D09 Central - Orchard">D09 Central - Orchard</option>
                      <option value="D10 Central - Near Orchard">D10 Central - Near Orchard</option>
                      <option value="D11 Central - Near Orchard">D11 Central - Near Orchard</option>
                      <option value="D12 Central">D12 Central</option>
                      <option value="D13 Central East">D13 Central East</option>
                      <option value="D14 Central East">D14 Central East</option>
                      <option value="D15 East Coast">D15 East Coast</option>
                      <option value="D16 Upper East Coast">D16 Upper East Coast</option>
                      <option value="D17 Far East">D17 Far East</option>
                      <option value="D18 Far East">D18 Far East</option>
                      <option value="D19 North East">D19 North East</option>
                      <option value="D20 Central North">D20 Central North</option>
                      <option value="D21 Central West">D21 Central West</option>
                      <option value="D22 Far West">D22 Far West</option>
                      <option value="D23 North West">D23 North West</option>
                      <option value="D24 Far North West">D24 Far North West</option>
                      <option value="D25 Far North">D25 Far North</option>
                      <option value="D26 North">D26 North</option>
                      <option value="D27 Far North">D27 Far North</option>
                      <option value="D28 North East">D28 North East</option>
                    </select>
                    </div>
                    {districtArray.length !== 0 ?
                      districtArray.map((selectedDistrict, index) => (
                        <div
                          key={index}
                          draggable="true"
                          onDragStart={(event) => handleDragStart(event, index)}
                          onDragOver={handleDragOver}
                          onDrop={(event) => handleDrop(event, index)}
                        >
                          <p className='purchaseOption' style={{ cursor: "grab" }}>
                            {selectedDistrict.length >= 22
                              ? selectedDistrict.substring(0, 22) + "..."
                              : selectedDistrict} {index === 0 ? "[Primary]" : null}
                            <span
                              style={{ float: 'right', padding: '2px 8px', cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => deleteDistrictArray(selectedDistrict)}
                            >
                              <i class="fa-solid fa-minus"></i>
                            </span>

                            {index == 0 ? null : <span
                              style={{ float: 'right', padding: '2px 8px', cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => shiftUp(selectedDistrict)}
                            >
                              <i class="fa-solid fa-arrow-up"></i>
                            </span>}

                            {index == districtArray.length - 1 ? null : <span
                              style={{ float: 'right', padding: '2px 8px', cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => shiftDown(selectedDistrict)}
                            >
                              <i class="fa-solid fa-arrow-down"></i>
                            </span>}

                          </p>

                          <div style={{ clear: 'both' }}></div>
                        </div>
                      ))

                      : null}

                    <p className='modalLabel'>*Property Type</p>
                    <select className='newListingSelect' id="propertyTypeResidential" style={{width:"100%"}} value={propertyTypeResidential} onChange={handlePropertyTypeResidential}>
                      <option value="HDB">HDB</option>
                      <option value="Condominium">Condominium</option>
                      <option value="Landed">Landed</option>
                    </select>
                    <p className='modalLabel' style={{ color: "black" }}>*Bedroom(s)</p>
                    <div style={{display:"inline-flex", width:"100%"}}><i class="fa-solid fa-plus" style={{display:"flex", justifyContent:"center", alignItems:"center"}}></i> <select className='newListingSelectMulti' id="bedrooms" value={bedrooms} onChange={handleBedrooms} style={{ backgroundColor: "transparent" }} >
                      <option value="Studio">Studio</option>
                      <option value="1-Bedroom">1-Bedroom</option>
                      <option value="2-Bedroom">2-Bedroom</option>
                      <option value="3-Bedroom">3-Bedroom</option>
                      <option value="4-Bedroom">4-Bedroom</option>
                      <option value="5-Bedroom">5-Bedroom</option>
                      <option value="> 5-Bedroom">{"> 5-Bedroom"}</option>
                    </select>
                    </div>
                    {bedroomArray.length !== 0 ? bedroomArray.map((selectedBedroom) => {
                      return (
                        <>

                          <p className='purchaseOption'>{selectedBedroom}<span style={{ float: "right", padding: "2px 8px", cursor: "pointer", fontWeight: "bold" }} onClick={() => deleteBedroomArray(selectedBedroom)}><i class="fa-solid fa-minus"></i></span></p>
                          <div style={{ clear: 'both' }}></div>
                        </>
                      );
                    }) : null}
                    <div>
                      <div id="newProjectMinLeasePeriod">
                        <p className='modalLabel'>*Minimum Floor Size</p>
                        <input className='newListingInput' id="minFloorSize" placeholder='Min' type="Number" value={minFloorSize} onChange={handleMinFloorSize}></input>
                        <span className='characterCount'>Square Foot (SQFT)</span>
                      </div>

                      <div id="newProjectMaxLeasePeriod">
                        <p className='modalLabel'>*Maximum Floor Size</p>
                        <input className='newListingInput' id="maxFloorSize" placeholder='Max' type="Number" value={maxFloorSize} onChange={handleMaxFloorSize}></input>
                        <span className='characterCount'>Square Foot (SQFT)</span>
                      </div>

                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div id='newListingLeft'>
                      <p className='modalLabel'>Tenure</p>
                      <div style={{display:"inline-flex", width:"100%"}}><i class="fa-solid fa-plus" style={{display:"flex", justifyContent:"center", alignItems:"center"}}></i> <select className='newListingSelectMulti' id="listingTenure" value={listingTenure} onChange={handleListingTenure}>
                        <option value="99-year leasehold">99-year leasehold</option>
                        <option value="103-year leasehold">103-year leasehold</option>
                        <option value="110-year leasehold">110-year leasehold</option>
                        <option value="999-year leasehold">999-year leasehold</option>
                        <option value="9999-year leasehold">9999-year leasehold</option>
                        <option value="Freehold">Freehold</option>
                        <option value="Any">Any</option>
                      </select>
                      </div>
                      {tenureArray.length !== 0 ? tenureArray.map((selectedTenure) => {
                        return (
                          <>

                            <p className='purchaseOption' style={{ width: "95%" }}>{selectedTenure}<span style={{ float: "right", padding: "2px 8px", cursor: "pointer", fontWeight: "bold" }} onClick={() => deleteTenureArray(selectedTenure)}><i class="fa-solid fa-minus"></i></span></p>
                            <div style={{ clear: 'both' }}></div>
                          </>
                        );
                      }) : null}
                    </div>
                    <div id='newListingRight'>
                      <p className='modalLabel'>Floor Level</p>
                      <div style={{display:"inline-flex", width:"100%"}}><i class="fa-solid fa-plus" style={{display:"flex", justifyContent:"center", alignItems:"center"}}></i> <select className='newListingSelectMulti' id="floorLevel" value={floorLevel} onChange={handleFloorLevel}>
                        <option value="Ground">Ground</option>
                        <option value="Low">Low</option>
                        <option value="Mid">Mid</option>
                        <option value="High">High</option>
                        <option value="Penthouse">Penthouse</option>
                        <option value="Any">Any</option>
                      </select>
                      </div>
                      {floorLevelArray.length !== 0 ? floorLevelArray.map((selectedFloorLevel) => {
                        return (
                          <>

                            <p className='purchaseOption' style={{ width: "95%" }}>{selectedFloorLevel}<span style={{ float: "right", padding: "2px 8px", cursor: "pointer", fontWeight: "bold" }} onClick={() => deleteFloorLevel(selectedFloorLevel)}><i class="fa-solid fa-minus"></i></span></p>
                            <div style={{ clear: 'both' }}></div>
                          </>
                        );
                      }) : null}
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    <p className='modalLabel'>*Budget: </p>
                    <input className='newListingInput' type="Number" placeholder='Min budget' value={budgetOverall} id="residentialSaleBudget" onChange={handleBudgetOverall} onWheel={numberInputOnWheelPreventChange}></input>
                    <span className='characterCount'>Enter numbers without using the dollar sign ($) or any spacing characters</span>

                    <p className='modalLabel'>*Able to transact / purchase from: </p>
                    <input className='newListingInput' type="date" id="periodMoveIn" value={moveInPeriod} onChange={handleMoveInPeriod}></input>
                    <p className='modalLabel'>Balcony</p>
                    <input type="radio" id="Yes" name="BalconyOption" value="Yes" />
                    <label for="yes"> Yes</label><br />
                    <input type="radio" id="No" name="BalconyOption" value="No" />
                    <label for="no"> No</label><br></br>
                    <p className='modalLabel'>Amenities</p>
                    <div style={{display:"inline-flex", width:"100%"}}><i class="fa-solid fa-plus" style={{display:"flex", justifyContent:"center", alignItems:"center"}}></i> <select className='newListingSelectMulti' id="floorLevel" value={amenities} onChange={handleAmenitiesChange}>
                      <option value="Pool">Pool</option>
                      <option value="Gymnasium">Gymnasium</option>
                      <option value="Steam Room">Steam Room</option>
                      <option value="Sauna">Sauna</option>
                      <option value="Outdoor Facilities">Outdoor Facilities</option>
                      <option value="Court">Court</option>
                      <option value="Library">Library</option>
                      <option value="Games Room">Games Room</option>
                      <option value="Utility Room">Utility Room</option>
                      <option value="Karaoke">Karaoke</option>
                      <option value="Drop-off Point">Drop-off Point</option>
                      <option value="Lounge">Lounge</option>
                      <option value="Laundrette">Laundrette</option>
                      <option value="Basement Carpark">Basement Carpark</option>
                      <option value="Maid Room">Maid Room</option>
                      <option value="Bomb Shelter">Bomb Shelter</option>
                      <option value="Club House">Club House</option>
                    </select>
                    </div>
                    {amenitiesArray.length !== 0 ? amenitiesArray.map((selectedAmenities) => {
                      return (
                        <>

                          <p className='purchaseOption'>{selectedAmenities}<span style={{ float: "right", padding: "2px 8px", cursor: "pointer", fontWeight: "bold" }} onClick={() => deleteAmenitiesArray(selectedAmenities)}><i class="fa-solid fa-minus"></i></span></p>
                          <div style={{ clear: 'both' }}></div>
                        </>
                      );
                    }) : null}
                    <p className='modalLabel'>Specific street</p>
                    <input className='newListingInput' id="locationListing" placeholder="E.g. specific street, if any" style={{ width: "100%" }}></input>
                    <p className='modalLabel' style={{ position: "relative" }}>
                      <i
                        className="fa-solid fa-circle-question"
                        style={{ color: "#316190", paddingRight: "5px" }}
                        onMouseEnter={() => showAddPropertyQuestionMark()}
                        onMouseLeave={() => showAddPropertyQuestionMark()}
                        onClick={() => showAddPropertyQuestionMark()}
                      />
                      <div
                        id={`showAddPropertyQuestionMark`}
                        style={{
                          display: "none",
                          position: "absolute",
                          backgroundColor: "#fff",
                          padding: "15px",
                          border: "1px solid #ccc",
                          borderRadius: "10px",
                          fontWeight: "normal",
                          textAlign: "justify",
                          boxShadow:"0 4px 8px rgba(0,0,0,0.6)"
                        }}
                      >
                        {"This perimeter allows us to match you to your preferred landmarks (e.g. specific school, buildings, etc). Within a 1km radius of your preferred landmark, notifications will be sent to landlords/property agents with properties in the area."}
                      </div>
                      Proximity to:
                    </p>
                    <input className='newListingInput' type="number" id="postalCode" placeholder='Key in postal code' style={{ width: "100%" }} onWheel={(e)=>{e.preventDefault();}}></input>
                    <p className='modalLabel'>Additional Remarks</p>
                    <textarea class="newListingInputUSP" id="additionalRemarks" placeholder="Type your paragraph here..." value={additionalRemarks} onChange={handleAddtionalRemarks}></textarea><span className='characterCount'>{additionalRemarks != null ? MAX_CHARACTERS_BIG - additionalRemarks.length : MAX_CHARACTERS_BIG} characters</span>
                    {currentUserInfo != null ? currentUserInfo.userType.includes("Agent") && currentUserInfo.userType.includes("Tenant") ?
                      <><p className='modalLabel'>Who do you represent</p>
                        <select value={dualRoleAgent} onChange={(e) => { setDualRoleAgent(e.target.value) }} style={{ padding: "5px 8px", borderRadius: "10px" }}>
                          <option value={"Agent"}>Agent</option>
                          <option value={"Tenant"}>Tenant</option>
                        </select></> : null:null}
                  </div>
                  <div class="stepper_tab">
                    <div id='newListingRight'>
                      <p className='modalLabel'>Do you need financing</p>
                      <select className='newListingSelect' id="districtType" value={advancedFinancing} onChange={(e) => { setAdvancedFinancing(e.target.value); setAdvancedAIP("No"); }}>
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {advancedFinancing == "Yes" ? <><p className='modalLabel'>Do you have an Approval In-Principle (AIP)</p>
                        <select className='newListingSelect' id="districtType" value={advancedAIP} onChange={(e) => { setAdvancedAIP(e.target.value) }}>
                          <option value=""></option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select></> : null}
                      <p className='modalLabel'>Are you willing to pay COV</p>
                      <select className='newListingSelect' id="districtType" value={advancedCOV} onChange={(e) => { setAdvancedCOV(e.target.value) }}>
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div id='newListingLeft'>
                      <p className='modalLabel'>Are you agreeable to purchase with tenancy</p>
                      <select className='newListingSelect' id="districtType" value={purchaseTenancy} onChange={(e) => { setPurchaseTenancy(e.target.value) }}>
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <p className='modalLabel'>This property purchase is for</p>
                      <select className='newListingSelect' id="districtType" value={purposeOfProperty} onChange={(e) => { setPurposeOfProperty(e.target.value) }}>
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <p className='modalLabel'>Do you need to sell your existing property before you can make another purchase</p>
                      <select className='newListingSelect' id="districtType" value={advancedSellExisting} onChange={(e) => { setAdvancedSellExisting(e.target.value) }}>
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                  </div>


                </> :
                //////////////////////Commercial////////////////////////////////////////
                <>

                  <div class="stepper_tab">
                    {/* {props.modifyListingId == null ? <select id="typeOfSpace" value={typeOfSpace} onChange={handleTypeOfSpaceChange}>
                      <option value="Residential">Residential</option>
                      <option value="Commercial">Commercial</option>
                    </select> : null} */}
                    <div style={{ clear: 'both' }}></div>
                    <p className='modalLabel' style={{ marginTop: "1%" }}>Listing Name*</p>
                    <input className='newListingInput' id="listingName" placeholder='(Brand name, if any) Cafe / Bicycle Shop /, etc' style={{ width: "100%" }} value={businessName} onChange={handleBusinessName}></input><span className='characterCount'>{businessName != null ? MAX_CHARACTERS_SMALL - businessName.length : MAX_CHARACTERS_SMALL} characters</span>
                    <p className='modalLabel'>*Location: </p>

                    <div style={{display:"inline-flex", width:"100%"}}><i class="fa-solid fa-plus" style={{display:"flex", justifyContent:"center", alignItems:"center"}}></i> <select className='newListingSelectMulti' id="districtType" value={districtSelect} onChange={handleDistrictArrayChange}>
                      <option value="Any">Any</option>
                      <option value="D01 City - Business District">D01 City - Business District</option>
                      <option value="D02 City - Business District">D02 City - Business District</option>
                      <option value="D03 Central South">D03 Central South</option>
                      <option value="D04 South">D04 South</option>
                      <option value="D05 South West">D05 South West</option>
                      <option value="D06 City - Business District">D06 City - Business District</option>
                      <option value="D07 City">D07 City</option>
                      <option value="D08 Central">D08 Central</option>
                      <option value="D09 Central - Orchard">D09 Central - Orchard</option>
                      <option value="D10 Central - Near Orchard">D10 Central - Near Orchard</option>
                      <option value="D11 Central - Near Orchard">D11 Central - Near Orchard</option>
                      <option value="D12 Central">D12 Central</option>
                      <option value="D13 Central East">D13 Central East</option>
                      <option value="D14 Central East">D14 Central East</option>
                      <option value="D15 East Coast">D15 East Coast</option>
                      <option value="D16 Upper East Coast">D16 Upper East Coast</option>
                      <option value="D17 Far East">D17 Far East</option>
                      <option value="D18 Far East">D18 Far East</option>
                      <option value="D19 North East">D19 North East</option>
                      <option value="D20 Central North">D20 Central North</option>
                      <option value="D21 Central West">D21 Central West</option>
                      <option value="D22 Far West">D22 Far West</option>
                      <option value="D23 North West">D23 North West</option>
                      <option value="D24 Far North West">D24 Far North West</option>
                      <option value="D25 Far North">D25 Far North</option>
                      <option value="D26 North">D26 North</option>
                      <option value="D27 Far North">D27 Far North</option>
                      <option value="D28 North East">D28 North East</option>
                    </select>
                    </div>
                    {districtArray.length !== 0 ? districtArray.map((selectedDistrict, index) => (
                      <div
                        key={index}
                        draggable="true"
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        <p className='purchaseOption' style={{ cursor: "grab" }}>{selectedDistrict.length >= 22
                              ? selectedDistrict.substring(0, 22) + "..."
                              : selectedDistrict} {index === 0 ? "[Primary]" : null}
                             <span
                              style={{ float: 'right', padding: '2px 8px', cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => deleteDistrictArray(selectedDistrict)}
                            >
                              <i class="fa-solid fa-minus"></i>
                            </span>

                            {index == 0 ? null : <span
                              style={{ float: 'right', padding: '2px 8px', cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => shiftUp(selectedDistrict)}
                            >
                              <i class="fa-solid fa-arrow-up"></i>
                            </span>}

                            {index == districtArray.length - 1 ? null : <span
                              style={{ float: 'right', padding: '2px 8px', cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => shiftDown(selectedDistrict)}
                            >
                              <i class="fa-solid fa-arrow-down"></i>
                            </span>}
</p>

                        <div style={{ clear: 'both' }}></div>
                      </div>
                    )) : null}

                    <p className='modalLabel'>*Property Type</p>
                    <select className='newListingSelect' id="propertyTypeResidential" value={propertyTypeCommercial} onChange={handlePropertyTypeCommercial}>
                      <option value="Commercial">Commercial</option>
                      <option value="Industrial">Industrial</option>
                      <option value="Land">Land</option>
                      <option value="Industrial land use">Industrial land use</option>
                    </select>
                    {propertyTypeCommercial == "Commercial" ?
                      <>
                        <p className='modalLabel'>Specific Type</p>
                        <select className='newListingSelect' id="propertyTypeResidential" value={specificPropertyType} onChange={handleSpecificPropertyType}>
                          <option value="Any">Any</option>
                          <option value="Retail">Retail</option>
                          <option value="Shop">Shop</option>
                          <option value="Medical suites">Medical suites</option>
                          <option value="Food & beverage">Food & beverage</option>
                          <option value="Others">Others</option>
                          <option value="Office">Office</option>
                          <option value="Grade A">Grade A</option>
                          <option value="Others">Others</option>
                        </select>
                      </>
                      : null}
                    {propertyTypeCommercial == "Industrial" ?
                      <>
                        <p className='modalLabel'>Specific Type</p>
                        <select className='newListingSelect' id="propertyTypeResidential" value={specificPropertyType} onChange={handleSpecificPropertyType}>
                          <option value="Any">Any</option>
                          <option value="General industrial">General industrial</option>
                          <option value="Warehouse">Warehouse</option>
                          <option value="Showroom">Showroom</option>
                          <option value="Dormitory">Dormitory</option>
                          <option value="e-Business">e-Business</option>
                          <option value="Food & beverage">Food & beverage</option>
                          <option value="Childcare">Childcare</option>
                          <option value="Industrial office">Industrial office</option>
                          <option value="Shop">Shop</option>
                          <option value="Factory">Factory</option>
                        </select>
                      </>
                      : null}
                    {propertyTypeCommercial == "Land" ?
                      <>
                        <p className='modalLabel'>Specific Type</p>
                        <select className='newListingSelect' id="propertyTypeResidential" value={specificPropertyType} onChange={handleSpecificPropertyType}>
                          <option value="Any">Any</option>
                          <option value="Commercial">Land only</option>
                          <option value="Land with building">Land with building</option>
                        </select>
                      </>
                      : null}
                    {propertyTypeCommercial == "Industrial land use" ?
                      <>
                        <p className='modalLabel'>Specific Type</p>
                        <select className='newListingSelect' id="propertyTypeResidential" value={specificPropertyType} onChange={handleSpecificPropertyType}>
                          <option value="Any">Any</option>
                          <option value="B1">B1</option>
                          <option value="B2">B2</option>
                          <option value="Science / Business park">Science / Business park</option>
                        </select>
                      </> : null}

                    <div>
                      <div id="newProjectMinLeasePeriod">
                        <p className='modalLabel'>*Minimum Floor Size</p>
                        <input className='newListingInput' id="minFloorSize" placeholder='Min' type="Number" value={minFloorSize} onChange={handleMinFloorSize} onWheel={(e)=>{e.preventDefault();}}></input>
                        <span className='characterCount'>Square Foot (SQFT)</span>
                        
                      </div>

                      <div id="newProjectMaxLeasePeriod">
                        <p className='modalLabel'>*Maximum Floor Size</p>
                        <input className='newListingInput' id="maxFloorSize" placeholder='Max' type="Number" value={maxFloorSize} onChange={handleMaxFloorSize} onWheel={(e)=>{e.preventDefault();}}></input>
                        <span className='characterCount'>Square Foot (SQFT)</span>
                      </div>

                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div id="newProjectMinLeasePeriod">
                        <p className='modalLabel'>*Minimum PSF Budget</p>
                        <input className='newListingInput' id="minFloorSizePSF" placeholder='Min' type="Number" value={minFloorSizePSF} onChange={handleMinFloorSizePSF} onWheel={(e)=>{e.preventDefault();}}></input>

                      </div>

                      <div id="newProjectMaxLeasePeriod">
                        <p className='modalLabel'>*Maximum PSF Budget</p>
                        <input className='newListingInput' id="maxFloorSizePSF" placeholder='Max' type="Number" value={maxFloorSizePSF} onChange={handleMaxFloorSizePSF} onWheel={(e)=>{e.preventDefault();}}></input>

                      </div>

                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <p className='modalLabel'>*Able to transact / purchase from: </p>
                    <input className='newListingInput' type="date" id="periodMoveIn" value={moveInPeriod} onChange={handleMoveInPeriod}></input>
                    <p className='modalLabel'>Tenure</p>
                    <i class="fa-solid fa-plus"></i> <select className='newListingSelectMulti' id="listingTenure" value={listingTenure} onChange={handleListingTenure} style={{ width: "95%" }}>
                      <option value="30-year leasehold">30-year leasehold</option>
                      <option value="60-year leasehold">60-year leasehold</option>
                      <option value="99-year leasehold">99-year leasehold</option>
                      <option value="110-year leasehold">110-year leasehold </option>
                      <option value="999-year leasehold">999-year leasehold</option>
                      <option value="9999-year leasehold">9999-year leasehold</option>
                      <option value="Freehold">Freehold</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Any">Any</option>
                    </select>
                    {tenureArray.length !== 0 ? tenureArray.map((selectedTenure) => {
                      return (
                        <>

                          <p className='purchaseOption'>{selectedTenure}<span style={{ float: "right", padding: "2px 8px", cursor: "pointer", fontWeight: "bold" }} onClick={() => deleteTenureArray(selectedTenure)}><i class="fa-solid fa-minus"></i></span></p>
                          <div style={{ clear: 'both' }}></div>
                        </>
                      );
                    }) : null}


                    <div style={{ clear: 'both' }}></div>
                    <p className='modalLabel'>Are you willing to purchase with tenancy?</p>
                    <input type="radio" id="Yes" name="currentlyTenated" value="Yes" />
                    <label for="yes"> Yes</label><br />
                    <input type="radio" id="No" name="currentlyTenated" value="No" />
                    <label for="no"> No</label><br></br>

                    <p className='modalLabel'>Amenities</p>
                    <div style={{display:"inline-flex", width:"100%"}}><i class="fa-solid fa-plus" style={{display:"flex", justifyContent:"center", alignItems:"center"}}></i> <select className='newListingSelectMulti' id="floorLevel" value={amenities} onChange={handleAmenitiesChange}>
                      <option value="CCTV">CCTV</option>
                      <option value="Car park">Car park</option>
                      <option value="24 hour access">24 hour access</option>
                      <option value="Air-conditioning">Air-conditioning</option>
                      <option value="Column free">Column free</option>
                      <option value="Broadband">Broadband</option>
                      <option value="Bathroom">Bathroom</option>
                      <option value="Washroom">Washroom</option>
                      <option value="Cat ⅚ Cabling">Cat ⅚ Cabling</option>
                      <option value="Loading Bay">Loading Bay</option>
                      <option value="Security">Security</option>
                      <option value="Pantry">Pantry</option>
                      <option value="Dual Telecoms Risers">Dual Telecoms Risers</option>
                      <option value="Handicap-friendly">Handicap-friendly</option>
                      <option value="Raised Access Floor">Raised Access Floor</option>
                      <option value="Server Room">Server Room</option>
                      <option value="Reception Services">Reception Services</option>
                    </select>
                    </div>
                    {amenitiesArray.length !== 0 ? amenitiesArray.map((selectedAmenities) => {
                      return (
                        <>

                          <p className='purchaseOption'>{selectedAmenities}<span style={{ float: "right", padding: "2px 8px", cursor: "pointer", fontWeight: "bold" }} onClick={() => deleteAmenitiesArray(selectedAmenities)}><i class="fa-solid fa-minus"></i></span></p>
                          <div style={{ clear: 'both' }}></div>
                        </>
                      );
                    }) : null}
                    <p className='modalLabel'>Specific street</p>
                    <input className='newListingInput' id="locationListing" placeholder="E.g. specific street, if any" style={{ width: "100%" }}></input>
                    <p className='modalLabel' style={{ position: "relative" }}>
                      <i
                        className="fa-solid fa-circle-question"
                        style={{ color: "#316190", paddingRight: "5px" }}
                        onMouseEnter={() => showAddPropertyQuestionMark()}
                        onMouseLeave={() => showAddPropertyQuestionMark()}
                        onClick={() => showAddPropertyQuestionMark()}
                      />
                      <div
                        id={`showAddPropertyQuestionMark`}
                        style={{
                          display: "none",
                          position: "absolute",
                          backgroundColor: "#fff",
                          padding: "15px",
                          border: "1px solid #ccc",
                          borderRadius: "10px",
                          fontWeight: "normal",
                          textAlign: "justify",
                          boxShadow:"0 4px 8px rgba(0,0,0,0.6)"
                        }}
                      >
                        {"This perimeter allows us to match you to your preferred landmarks (e.g. specific school, buildings, etc). Within a 1km radius of your preferred landmark, notifications will be sent to landlords/property agents with properties in the area."}
                      </div>
                      Proximity to:
                    </p>
                    <input className='newListingInput' type="number" id="postalCode" placeholder='Key in postal code' style={{ width: "100%" }} onWheel={(e)=>{e.preventDefault();}}></input>


                    <p className='modalLabel'>Additional Remarks</p>
                    <textarea class="newListingInputUSP" id="additionalRemarks" placeholder="Type your paragraph here..." value={additionalRemarks} onChange={handleAddtionalRemarks}></textarea><span className='characterCount'>{additionalRemarks != null ? MAX_CHARACTERS_BIG - additionalRemarks.length : MAX_CHARACTERS_BIG} characters</span>
                    {currentUserInfo != null ?currentUserInfo.userType.includes("Agent") && currentUserInfo.userType.includes("Tenant") ?
                      <><p className='modalLabel'>Who do you represent</p>
                        <select value={dualRoleAgent} onChange={(e) => { setDualRoleAgent(e.target.value) }} style={{ padding: "5px 8px", borderRadius: "10px" }}>
                          <option value={"Agent"}>Agent</option>
                          <option value={"Tenant"}>Tenant</option>
                        </select></> : null:null}
                  </div>
                  <div class="stepper_tab">
                    <div id='newListingRight'>
                      <p className='modalLabel'>Do you need financing</p>
                      <select className='newListingSelect' id="districtType" value={advancedFinancing} onChange={(e) => { setAdvancedFinancing(e.target.value) }}>
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {advancedFinancing == "Yes" ? <><p className='modalLabel'>Do you have an Approval In-Principle (AIP)</p>
                        <select className='newListingSelect' id="districtType" value={advancedAIP} onChange={(e) => { setAdvancedAIP(e.target.value) }}>
                          <option value=""></option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select></> : null}
                      <p className='modalLabel'>Are you willing to pay COV</p>
                      <select className='newListingSelect' id="districtType" value={advancedCOV} onChange={(e) => { setAdvancedCOV(e.target.value) }}>
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div id='newListingLeft'>
                      <p className='modalLabel'>Are you agreeable to purchase with tenancy</p>
                      <select className='newListingSelect' id="districtType" value={purchaseTenancy} onChange={(e) => { setPurchaseTenancy(e.target.value) }}>
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <p className='modalLabel'>This property purchase is for</p>
                      <select className='newListingSelect' id="districtType" value={purposeOfProperty} onChange={(e) => { setPurposeOfProperty(e.target.value) }}>
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <p className='modalLabel'>Do you need to sell your existing property before you can make another purchase</p>
                      <select className='newListingSelect' id="districtType" value={advancedSellExisting} onChange={(e) => { setAdvancedSellExisting(e.target.value) }}>
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                  </div>

                </>}

            </div>
            <div className='modal-footerNewProject'>

              {typeOfSpace == "Commercial" ?
                <>
                  {props.modifyListingId == null ? <Link to='/account'><button className='newListingBtn' onClick={() => confirmBothListingType()} style={{ marginLeft: "2%" }}>Confirm</button></Link> : <Link to='/account'><button className='newListingBtn' style={{ marginLeft: "2%" }} onClick={modifyListing}>Modify</button></Link>}
                  {props.modifyListingId != null ? <button className='newListingBtn' style={{ marginLeft: "2%" }} onClick={() => { setAcknowledgeMessage("Confirm to delete your listing"); setAcknowledgeNormal(true) }}>Delete</button> : null}
                  <button className='newListingBtn' id="prevBtn" onClick={() => nextPrev(-1)}>Previous</button>
                  <button className='newListingBtn' id="nextBtn" onClick={() => nextPrev(1)}>Next</button>
                  <div style={{ clear: 'both' }}></div>
                  <p>* are mandatory</p>

                  <div style={{ textAlign: "center" }}>
                    <span class="step"></span>
                    <span class="step"></span>
                  </div>
                </>
                :
                <>
                  {props.modifyListingId == null ? <Link to='/account'><button className='newListingBtn' onClick={() => confirmBothListingType()} style={{ marginLeft: "2%" }}>Confirm</button></Link> : <Link to='/account'><button className='newListingBtn' style={{ marginLeft: "2%" }} onClick={() => modifyResidentialListing()}>Modify</button></Link>}
                  {props.modifyListingId != null ? <button className='newListingBtn' style={{ marginLeft: "2%" }} onClick={() => { setAcknowledgeMessage("Confirm to delete your listing"); setAcknowledgeNormal(true) }}>Delete</button> : null}
                  <button className='newListingBtn' id="prevBtn" onClick={() => nextPrev(-1)}>Previous</button>
                  <button className='newListingBtn' id="nextBtn" onClick={() => nextPrev(1)}>Next</button>
                  <div style={{ clear: 'both' }}></div>
                  <p>* are mandatory</p>
                  <div style={{ textAlign: "center" }}>
                    <span class="step"></span>
                    <span class="step"></span>
                  </div>
                </>
              }



            </div>
          </div>
        </div>

      </>
    );
  }

}
