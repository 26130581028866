import React, { useEffect, useState, useRef } from 'react';
import { UserAuth } from '../AuthContent';
import { setDoc, doc, getDoc, updateDoc, addDoc, deleteField } from 'firebase/firestore';
import { db, storage } from '../firebase/firebase';
import { getDownloadURL, ref, uploadBytes, listAll, getStorage, deleteObject, uploadString, getMetadata } from 'firebase/storage';
import axios from 'axios';
import Spinner from './pages/Spinner';
import AcknowledgeNormal from '../AcknowledgeNormal';



export default function AddPropertyLocation(props) {
    useEffect(() => {
        console.log(props)
    }, [props]);

    const { user } = UserAuth();
    const [propertyAddressDataList, setPropertyAddressDataList] = useState(null);
    const [typeOfSpace, setTypeOfSpace] = useState("Commercial Sale");
    const uploadSelfImage = useRef(null);
    const [uploadedImageRemove, setUploadedImageRemove] = useState([]);
    const [reason, setReason] = useState('');
    const [targetDistrict, setTargetDistrict] = useState('D01 City - Business District');
    const [sellingPrice, setSellingPrice] = useState(0);
    const [PSFValue, setPSFValue] = useState(0);
    const [propertyType, setPropertyType] = useState('HDB');
    const [commissionAgent, setCommissionAgent] = useState('0.95%');
    const [needAgent, setNeedAgent] = useState(true);
    const [isToggled, setIsToggled] = useState(
        props.modifyPropertyIndex != null
            ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].emailNotification
                : props.accountPropertyListResidential[props.modifyPropertyIndex][1].emailNotification
            : true
    );
    const [companyRepresentative, setCompanyRepresentative] = useState(
        props.modifyPropertyIndex != null
            ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].companyRepresentative
                : ""
            : "");
    const [companyName, setCompanyName] = useState(
        props.modifyPropertyIndex != null
            ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].propertyCompanyName
                : props.accountPropertyListResidential[props.modifyPropertyIndex][1].propertyCompanyName
            : ""
    );
    const [address, setAddress] = useState(props.modifyPropertyIndex != null
        ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].propertyAddress
            : props.accountPropertyListResidential[props.modifyPropertyIndex][1].propertyAddress
        : "");
    const [postalCode, setPostalCode] = useState(props.modifyPropertyIndex != null
        ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].postalCode
            : props.accountPropertyListResidential[props.modifyPropertyIndex][1].postalCode
        : "");

    // const district = [
    //     ['Boat Quay', 'Chinatown', 'Havelock Road', 'Marina Square', 'Raffles Place', 'Suntec City'],
    //     ['Anson Road', 'Chinatown', 'Neil Road', 'Raffles Place', 'Shenton Way', 'Tanjong Pagar'],
    //     ['Alexandra Road', 'Tiong Bahru', 'Queenstown'],
    //     ['Keppel', 'Mount Faber', 'Sentosa', 'Telok Blangah'],
    //     ['Buona Vista', 'Dover', 'Pasir Panjang', 'West Coast'],
    //     ['City Hall', 'High Street', 'North Bridge Road'],
    //     ['Beach Road', 'Bencoolen Road', 'Bugis', 'Rochor'],
    //     ['Little India', 'Farrer Park', 'Serangoon Road'],
    //     ['Cairnhill', 'Killiney', 'Leonie Hill', 'Orchard', 'Oxley'],
    //     ['Balmoral', 'Bukit Timah', 'Grange Road', 'Holland', 'Orchard Boulevard', 'River Valley', 'Tanglin Road'],
    //     ['Chancery', 'Bukit Timah', 'Dunearn Road', 'Newton'],
    //     ['Balestier', 'Moulmein', 'Novena', 'Toa Payoh'],
    //     ['Potong Pasir', 'Machpherson'],
    //     ['Eunos', 'Geylang', 'Kembangan', 'Paya Lebar'],
    //     ['Katong', 'Marine Parade', 'Siglap', 'Tanjong Rhu'],
    //     ['Bayshore', 'Bedok', 'Chai Chee'],
    //     ['Changi', 'Loyang', 'Pasir Ris'],
    //     ['Pasir Ris', 'Simei', 'Tampines'],
    //     ['Hougang', 'Punggol', 'Sengkang'],
    //     ['Ang Mo Kio', 'Bishan', 'Braddell Road', 'Thomson'],
    //     ['Clementi', 'Upper Bukit Timah', 'Hume Avenue'],
    //     ['Boon Lay', 'Jurong', 'Tuas'],
    //     ['Bukit Batok', 'Choa Chu Kang', 'Hillview Avenue', 'Upper Bukit Timah'],
    //     ['Kranji', 'Lim Chu Kang', 'Sungei Gedong', 'Tengah'],
    //     ['Admiralty', 'Woodlands'],
    //     ['Tagore', 'Yio Chu Kang'],
    //     ['Admiralty', 'Sembawang', 'Yishun'],
    //     ['Seletar', 'Yio Chu Kang']
    // ];
    const districtAreaCode = ['D01 City - Business District', 'D02 City - Business District', 'D03 Central South', 'D04 South', 'D05 South West', 'D06 City - Business District', 'D07 City',
        'D08 Central', 'D09 Central - Orchard', 'D10 Central - Near Orchard', 'D11 Central - Near Orchard', 'D12 Central', 'D13 Central East', 'D14 Central East',
        'D15 East Coast', 'D16 Upper East Coast', 'D17 Far East', 'D18 Far East', 'D19 North East', 'D20 Central North', 'D21 Central West', 'D22 Far West', 'D23 North West',
        'D24 Far North West', 'D25 Far North', 'D26 North', 'D27 Far North', 'D28 North East'];
    const [addtionalInformation, setAddtionalInformation] = useState("");

    const [uploadedImages, setUploadedImages] = useState([]);
    const [existingUploadedImages, setExistingUploadedImages] = useState([]);

    const [uploadFiles, setUploadFiles] = useState([]);
    const [spinnerActive, setSpinnerActive] = useState(false);

    const uploadSelfVideo = useRef(null);
    const [uploadVideosFiles, setUploadVideoFiles] = useState([]);
    const [uploadedVideos, setUploadedVideos] = useState([]);
    const [existingUploadedVideos, setExistingUploadedVideos] = useState([]);
    const [uploadedVideoRemove, setUploadedVideoRemove] = useState([]);
    const [unitNumber, setUnitNumber] = useState("");
    const [isAgentRepresented, setIsAgentRepresented] = useState(false);
    const [usageType, setUsageType] = useState('F&B');
    const [minLeaseTerm, setMinLeaseTerm] = useState("");
    const [propertyFurnishing, setPropertyFurnishing] = useState('Unfurnished');
    const [acknowledgeNormalActive, setAcknowledgeNormalActive] = useState(false);
    const [messages, setMessages] = useState("");


    const deleteExistingVideoUpload = (url, index) => {

        var newUploadVideos = [...existingUploadedVideos];
        newUploadVideos.splice(index, 1);
        setExistingUploadedVideos(newUploadVideos);
        var localUploadedVideoRemove = [...uploadedVideoRemove];
        localUploadedVideoRemove.push(url);
        setUploadedVideoRemove(localUploadedVideoRemove);

        // setExistingUploadedVideos((prevVideos) => {
        //     const updatedVideos = [...prevVideos];
        //     updatedVideos.splice(index, 1);
        //     return updatedVideos;
        // });
    };

    const deleteVideoUpload = (video, index) => {
        setUploadedVideos((prevVideos) => {
            const updatedVideos = [...prevVideos];
            updatedVideos.splice(index, 1);
            return updatedVideos;
        });
    };

    const handleVideoUploadWithWatermark = async (event) => {
        const files = event.target.files;
        const allowedSize = 800 * 1024 * 1024; // 800MB

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileSize = file.size;

            if (fileSize > allowedSize) {
                alert(`Video '${file.name}' exceeds the maximum size limit.`);
                continue;
            }
            var maximumVideo = props.currentUserInfo.membership == "Standard" ? 1 : props.currentUserInfo.membership == "Premium" ? 3 : props.currentUserInfo.membership == "Super-Premium" ? 5 : 0;
            if (files.length > maximumVideo || (uploadedVideoRemove.length) + files.length + uploadVideosFiles.length > maximumVideo) {
                alert(`Maximum of ${maximumVideo} videos`);
                return;
            }
            else {
                const reader = new FileReader();
                reader.onload = async () => {
                    const videoData = reader.result;
                    const inputVideo = document.createElement('video');
                    inputVideo.src = videoData;
                    await inputVideo.play();

                    const canvas = document.createElement('canvas');
                    canvas.width = inputVideo.videoWidth;
                    canvas.height = inputVideo.videoHeight;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(inputVideo, 0, 0, canvas.width, canvas.height);
                    const outputVideoData = canvas.toDataURL('video/mp4');
                    const previewData = canvas.toDataURL('image/png');

                    // const watermarkedVideoData = await addWatermarkToVideo(videoData);
                    setUploadedVideos((prevVideos) => [...prevVideos, previewData]);
                    setUploadVideoFiles((prevFiles) => [...prevFiles, file]);
                };
                reader.readAsDataURL(file);
            }


        }
    };

    const handleImageUpload = (event) => {
        const files = event.target.files;
        var maximumPhoto = 12;

        if (uploadFiles.length + files.length > maximumPhoto || existingUploadedImages.length + files.length + uploadFiles.length > maximumPhoto) {
            alert(`Maximum of ${maximumPhoto} photos`);
        }
        else {

            for (let i = 0; i < files.length; i++) {
                setSpinnerActive(true);
                const file = files[i];
                const reader = new FileReader();

                reader.onload = (e) => {
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0);

                        const watermarkText = 'NextLoop';
                        const textWidth = ctx.measureText(watermarkText).width;

                        ctx.font = 'bold 24px Arial';
                        ctx.fillStyle = 'rgba(192, 192, 192, 0.5)'; // Background color
                        ctx.fillRect(0, 30, canvas.width, 30); // Adjust the height as needed
                        ctx.fillStyle = '#000'; // Text color
                        ctx.fillText(watermarkText, (canvas.width - textWidth) / 2, 50); // Adjust the position as needed

                        const watermarkedImageUrl = canvas.toDataURL();
                        // Upload watermarked image to Firebase Storage
                        const storageRef = ref(storage, `checkingImage/${file.name}`);
                        uploadString(storageRef, watermarkedImageUrl, 'data_url')
                            .then(() => {
                                getDownloadURL(storageRef).then((url) => {

                                    axios.get('https://api.sightengine.com/1.0/check.json', {
                                        params: {
                                            'url': url,
                                            'models': 'nudity-2.0,wad,offensive,gore,gambling',
                                            'api_user': '206386815',
                                            'api_secret': 'BvES5DBmLA68GjALyBXo',
                                        }
                                    }).then(function (response) {
                                        // on success: handle response
                                        if (response.data.drugs <= 0.10 && response.data.gore.prob <= 0.30 && response.data.nudity.erotica <= 0.60 && response.data.nudity.sexual_activity <= 0.30 && response.data.nudity.sexual_display <= 0.30 && response.data.offensive.prob <= 0.30) {
                                            setUploadedImages((prevImages) => [...prevImages, watermarkedImageUrl]);
                                            setUploadFiles((prevFiles) => [...prevFiles, file]);
                                            setSpinnerActive(false);
                                        }
                                        else {
                                            setSpinnerActive(false);
                                            alert(`${file.name} not accepted`)

                                        }

                                    })
                                        .catch(function (error) {
                                            // handle error
                                            if (error.response) {
                                                alert(`There was an error uploading the picture. Try again by refreshing the page`)
                                                setSpinnerActive(false);
                                                // resolve('Image not accepted');
                                            }
                                            else {
                                                alert(`There was an error uploading the picture. Try again by refreshing the page`)
                                                setSpinnerActive(false);
                                                // resolve('Image not accepted');
                                            }
                                        });



                                })
                            })
                            .catch((error) => {
                                setSpinnerActive(false);
                            });


                    };

                    img.src = e.target.result;
                };

                reader.readAsDataURL(file);
            }

        }

    };

    const showAddPropertyQuestionMark = () => {
        const notApprovedText = document.getElementById(`showAddPropertyQuestionMark`);
        if (notApprovedText.style.display == "none") {
            notApprovedText.style.display = "block";
        }
        else {
            notApprovedText.style.display = "none";
        }
    }

    const saveResidentialProperty = async () => {
        if (companyName == null || address == null || postalCode == null) {
            alert("Missing Parameters");
            return
        }
        else if (companyName == "" || address == "" || postalCode == "") {
            alert("Missing Parameters");
            return
        }
        // else if (uploadFiles.length == 0) {
        //     alert("Missing Photos");
        //     return;
        // }
        else {
            setSpinnerActive(true);
            const userPropertyList = await getDoc(doc(db, 'userInfo', user.uid));
            var newAddressPropertyData = {};
            var newAddressPropertyArray = [];
            console.log(props.accountPropertyListResidential)
            if (props.accountPropertyListResidential.length != 0) {
                var continueProcess = true;
                for (var i = 0; i < props.accountPropertyListResidential.length; i++) {
                    if (props.accountPropertyListResidential[i][1].propertyAddress == document.getElementById("propertyAddress").value || props.accountPropertyListResidential[i][1].propertyCompanyName == companyName || (props.accountPropertyListResidential[i][1].postalCode == postalCode && props.accountPropertyListResidential[i][1].propertyUnitNumber.replace(/[#\-.]/g, '') == unitNumber.replace(/[#\-.]/g, ''))) {
                        alert("Duplicate Property Information");
                        setSpinnerActive(false);
                        continueProcess = false;
                        break;
                    }
                }


                if (continueProcess == false) {
                    return;
                }
                else {

                    await callOneMapAPI(document.getElementById("postalCode").value).then((res) => {
                        console.log(res);
                        if (res.results.length != 0) {
                            if (typeOfSpace == "Residential Sale") {
                                newAddressPropertyData.postalCode = res.results[0].POSTAL;
                                newAddressPropertyData.latitude = res.results[0].LATITUDE;
                                newAddressPropertyData.longtitude = res.results[0].LONGITUDE;
                                newAddressPropertyData.propertyCompanyName = companyName;
                                newAddressPropertyData.propertyAddress = address;
                                newAddressPropertyData.agentRepresented = isAgentRepresented;
                                newAddressPropertyData.propertyUnitNumber = unitNumber;
                                newAddressPropertyData.emailNotification = isToggled;
                                newAddressPropertyData.district = targetDistrict;
                                newAddressPropertyData.companyRepresentative = companyRepresentative
                                newAddressPropertyData.verified = "notApprove";
                                newAddressPropertyData.PSFValue = PSFValue;
                                newAddressPropertyData.sellingPrice = sellingPrice;
                                newAddressPropertyData.timePosted = new Date();
                                newAddressPropertyData.commission = commissionAgent;
                                newAddressPropertyData.username = props.publicInfo.username;
                                newAddressPropertyData.uid = user.uid;
                                newAddressPropertyData.propertyTypeRes = propertyType;
                                newAddressPropertyData.photoURL = props.currentUserInfo.userPhotoURL;
                                newAddressPropertyData.agentRequired = needAgent;
                                newAddressPropertyData.propertyType = typeOfSpace;
                            }
                            else {
                                newAddressPropertyData.postalCode = res.results[0].POSTAL;
                                newAddressPropertyData.latitude = res.results[0].LATITUDE;
                                newAddressPropertyData.longtitude = res.results[0].LONGITUDE;
                                newAddressPropertyData.propertyCompanyName = companyName;
                                newAddressPropertyData.propertyAddress = address;
                                newAddressPropertyData.emailNotification = isToggled;
                                newAddressPropertyData.agentRepresented = isAgentRepresented;
                                newAddressPropertyData.companyRepresentative = companyRepresentative
                                newAddressPropertyData.propertyUnitNumber = unitNumber;
                                newAddressPropertyData.district = targetDistrict;
                                newAddressPropertyData.verified = "notApprove";
                                newAddressPropertyData.PSFValue = PSFValue;
                                newAddressPropertyData.rentalCost = sellingPrice;
                                newAddressPropertyData.minLeaseTerm = minLeaseTerm;
                                newAddressPropertyData.propertyFurnishing = propertyFurnishing;
                                newAddressPropertyData.timePosted = new Date();
                                newAddressPropertyData.username = props.publicInfo.username;
                                newAddressPropertyData.propertyTypeRes = propertyType;
                                newAddressPropertyData.propertyType = typeOfSpace;
                            }



                            // propertyAddressList.push(res.results[0].POSTAL + "|" + res.results[0].LATITUDE + "|" + res.results[0].LONGTITUDE);
                            if (newAddressPropertyData.hasOwnProperty('postalCode')) {
                                var allImageURL = [];
                                var allVideoURL = [];
                                var uploadPromises = [];
                                var uploadPromisesVideo = [];
                                const allowedSize = 800 * 1024 * 1024; // 800MB
                                for (let i = 0; i < uploadFiles.length; i++) {
                                    const file = uploadFiles[i];
                                    const reader = new FileReader();
                                    const uploadPromise = new Promise((resolve, reject) => {
                                        reader.onload = (e) => {
                                            const img = new Image();
                                            img.onload = () => {
                                                const canvas = document.createElement('canvas');
                                                const ctx = canvas.getContext('2d');

                                                canvas.width = img.width;
                                                canvas.height = img.height;
                                                ctx.drawImage(img, 0, 0);

                                                const watermarkText = 'NextLoop';
                                                const textWidth = ctx.measureText(watermarkText).width;

                                                ctx.font = 'bold 24px Arial';
                                                ctx.fillStyle = 'rgba(192, 192, 192, 0.5)'; // Background color
                                                ctx.fillRect(0, 30, canvas.width, 30); // Adjust the height as needed
                                                ctx.fillStyle = '#000'; // Text color
                                                ctx.fillText(watermarkText, (canvas.width - textWidth) / 2, 50); // Adjust the position as needed
                                                const imageURL = canvas.toDataURL();

                                                // Upload watermarked image to Firebase Storage
                                                const storageRef = ref(storage, `${user.uid}/ownResidentialProperty/${companyName}/${file.name}`);
                                                uploadString(storageRef, imageURL, 'data_url')
                                                    .then(() => {
                                                        getDownloadURL(storageRef).then((url) => {
                                                            allImageURL.push(url);
                                                            resolve();
                                                        })
                                                    })
                                                    .catch((error) => {
                                                    });
                                            };

                                            img.src = e.target.result;
                                        };

                                        reader.readAsDataURL(file);
                                    });

                                    uploadPromises.push(uploadPromise)
                                }

                                for (let i = 0; i < uploadVideosFiles.length; i++) {
                                    const file = uploadVideosFiles[i];
                                    const fileSize = file.size;

                                    if (fileSize > allowedSize) {
                                        alert(`Video '${file.name}' exceeds the maximum size limit.`);
                                        continue;
                                    }

                                    const reader = new FileReader();
                                    const uploadPromise = new Promise((resolve, reject) => {
                                        reader.onload = async () => {
                                            const videoData = new Uint8Array(reader.result);
                                            const storageRef = ref(storage, `${user.uid}/ownResidentialProperty/${companyName}/${file.name}`);


                                            // Upload the video file to Firebase Storage with defined MIME type
                                            uploadBytes(storageRef, videoData, { contentType: file.type })
                                                .then((snapshot) => {
                                                    // File upload is complete

                                                    // Get the download URL of the uploaded video
                                                    getDownloadURL(storageRef)
                                                        .then((url) => {
                                                            allVideoURL.push(url);
                                                            resolve();
                                                        })
                                                        .catch((error) => {
                                                            console.log('Error getting download URL:', error);
                                                            reject(error);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log('Error uploading video:', error);
                                                    reject(error);
                                                });
                                        };
                                        reader.readAsArrayBuffer(file);
                                    });

                                    uploadPromisesVideo.push(uploadPromise);
                                }

                                Promise.all(uploadPromises.concat(uploadPromisesVideo)).then(() => {
                                    newAddressPropertyData.allImageURL = allImageURL;
                                    newAddressPropertyData.allVideoURL = allVideoURL;
                                    var currentUserInfoNew = props.accountPropertyListResidential;
                                    currentUserInfoNew.push({ '0': companyName, '1': newAddressPropertyData })
                                    return updateDoc(doc(db, `residentialPropertyList`, user.uid), {
                                        [companyName]: newAddressPropertyData
                                    }).then(() => {
                                        setSpinnerActive(false);
                                        setUploadedImages([]);
                                        setUploadedVideoRemove([]);
                                        setExistingUploadedVideos([]);
                                        setUploadFiles([]);
                                        setUploadVideoFiles([]);
                                        setUploadedVideos([]);
                                        props.setAccountPropertyListResidential(currentUserInfoNew);
                                        // props.setTrigger(false);
                                        setMessages("Property Added");
                                        setAcknowledgeNormalActive(true);
                                    });


                                })
                            }
                            else {
                                alert("You have enter an invalid postal code");
                                setSpinnerActive(false);
                            }
                        }
                        else {
                            alert("Invalid postal code");
                            setSpinnerActive(false);
                        }

                        // propertyAddressList.push(res.results[0].POSTAL + "|" + res.results[0].LATITUDE + "|" + res.results[0].LONGTITUDE);
                    })

                }
            }
            else {


                if (continueProcess == false) {
                    return;
                }
                else {
                    await callOneMapAPI(document.getElementById("postalCode").value).then((res) => {
                        if (res.results.length != 0) {
                            if (typeOfSpace == "Residential Sale") {
                                newAddressPropertyData.postalCode = res.results[0].POSTAL;
                                newAddressPropertyData.latitude = res.results[0].LATITUDE;
                                newAddressPropertyData.longtitude = res.results[0].LONGITUDE;
                                newAddressPropertyData.propertyCompanyName = companyName;
                                newAddressPropertyData.propertyAddress = address;
                                newAddressPropertyData.agentRepresented = isAgentRepresented;
                                newAddressPropertyData.propertyUnitNumber = unitNumber;
                                newAddressPropertyData.emailNotification = isToggled;
                                newAddressPropertyData.district = targetDistrict;
                                newAddressPropertyData.companyRepresentative = companyRepresentative
                                newAddressPropertyData.verified = "notApprove";
                                newAddressPropertyData.PSFValue = PSFValue;
                                newAddressPropertyData.sellingPrice = sellingPrice;
                                newAddressPropertyData.timePosted = new Date();
                                newAddressPropertyData.commission = commissionAgent;
                                newAddressPropertyData.username = props.publicInfo.username;
                                newAddressPropertyData.uid = user.uid;
                                newAddressPropertyData.propertyTypeRes = propertyType;
                                newAddressPropertyData.photoURL = props.currentUserInfo.userPhotoURL;
                                newAddressPropertyData.agentRequired = needAgent;
                                newAddressPropertyData.propertyType = typeOfSpace;
                            }
                            else {
                                newAddressPropertyData.postalCode = res.results[0].POSTAL;
                                newAddressPropertyData.latitude = res.results[0].LATITUDE;
                                newAddressPropertyData.longtitude = res.results[0].LONGITUDE;
                                newAddressPropertyData.propertyCompanyName = companyName;
                                newAddressPropertyData.propertyAddress = address;
                                newAddressPropertyData.emailNotification = isToggled;
                                newAddressPropertyData.agentRepresented = isAgentRepresented;
                                newAddressPropertyData.companyRepresentative = companyRepresentative
                                newAddressPropertyData.propertyUnitNumber = unitNumber;
                                newAddressPropertyData.district = targetDistrict;
                                newAddressPropertyData.verified = "notApprove";
                                newAddressPropertyData.PSFValue = PSFValue;
                                newAddressPropertyData.rentalCost = sellingPrice;
                                newAddressPropertyData.minLeaseTerm = minLeaseTerm;
                                newAddressPropertyData.propertyFurnishing = propertyFurnishing;
                                newAddressPropertyData.timePosted = new Date();
                                newAddressPropertyData.username = props.publicInfo.username;
                                newAddressPropertyData.propertyTypeRes = propertyType;
                                newAddressPropertyData.propertyType = typeOfSpace;
                            }


                            // propertyAddressList.push(res.results[0].POSTAL + "|" + res.results[0].LATITUDE + "|" + res.results[0].LONGTITUDE);
                            if (newAddressPropertyData.hasOwnProperty('postalCode')) {
                                var allImageURL = [];
                                var allVideoURL = [];
                                var uploadPromises = [];
                                var uploadPromisesVideo = [];
                                const allowedSize = 800 * 1024 * 1024; // 800MB
                                for (let i = 0; i < uploadFiles.length; i++) {
                                    const file = uploadFiles[i];
                                    const reader = new FileReader();
                                    const uploadPromise = new Promise((resolve, reject) => {
                                        reader.onload = (e) => {
                                            const img = new Image();
                                            img.onload = () => {
                                                const canvas = document.createElement('canvas');
                                                const ctx = canvas.getContext('2d');

                                                canvas.width = img.width;
                                                canvas.height = img.height;
                                                ctx.drawImage(img, 0, 0);

                                                const watermarkText = 'NextLoop';
                                                const textWidth = ctx.measureText(watermarkText).width;

                                                ctx.font = 'bold 24px Arial';
                                                ctx.fillStyle = 'rgba(192, 192, 192, 0.5)'; // Background color
                                                ctx.fillRect(0, 30, canvas.width, 30); // Adjust the height as needed
                                                ctx.fillStyle = '#000'; // Text color
                                                ctx.fillText(watermarkText, (canvas.width - textWidth) / 2, 50); // Adjust the position as needed
                                                const imageURL = canvas.toDataURL();

                                                // Upload watermarked image to Firebase Storage
                                                const storageRef = ref(storage, `${user.uid}/ownResidentialProperty/${companyName}/${file.name}`);
                                                uploadString(storageRef, imageURL, 'data_url')
                                                    .then(() => {
                                                        getDownloadURL(storageRef).then((url) => {
                                                            allImageURL.push(url);
                                                            resolve();
                                                        })
                                                    })
                                                    .catch((error) => {
                                                        console.log('Error uploading image:', error);
                                                    });
                                            };

                                            img.src = e.target.result;
                                        };

                                        reader.readAsDataURL(file);
                                    });

                                    uploadPromises.push(uploadPromise)
                                }

                                for (let i = 0; i < uploadVideosFiles.length; i++) {
                                    const file = uploadVideosFiles[i];
                                    const fileSize = file.size;

                                    if (fileSize > allowedSize) {
                                        alert(`Video '${file.name}' exceeds the maximum size limit.`);
                                        continue;
                                    }

                                    const reader = new FileReader();
                                    const uploadPromise = new Promise((resolve, reject) => {
                                        reader.onload = async () => {
                                            const videoData = new Uint8Array(reader.result);
                                            const storageRef = ref(storage, `${user.uid}/ownResidentialProperty/${companyName}/${file.name}`);


                                            // Upload the video file to Firebase Storage with defined MIME type
                                            uploadBytes(storageRef, videoData, { contentType: file.type })
                                                .then((snapshot) => {
                                                    // File upload is complete

                                                    // Get the download URL of the uploaded video
                                                    getDownloadURL(storageRef)
                                                        .then((url) => {
                                                            allVideoURL.push(url);
                                                            resolve();
                                                        })
                                                        .catch((error) => {
                                                            console.log('Error getting download URL:', error);
                                                            reject(error);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log('Error uploading video:', error);
                                                    reject(error);
                                                });
                                        };
                                        reader.readAsArrayBuffer(file);
                                    });

                                    uploadPromisesVideo.push(uploadPromise);
                                }

                                Promise.all(uploadPromises.concat(uploadPromisesVideo)).then(() => {
                                    newAddressPropertyData.allImageURL = allImageURL;
                                    newAddressPropertyData.allVideoURL = allVideoURL;
                                    var currentUserInfoNew = props.accountPropertyListResidential;
                                    currentUserInfoNew.push({ '0': companyName, '1': newAddressPropertyData })
                                    return setDoc(doc(db, `residentialPropertyList`, user.uid), {
                                        [companyName]: newAddressPropertyData
                                    }).then(() => {
                                        setSpinnerActive(false);
                                        setUploadedImages([]);
                                        setUploadFiles([]);
                                        setUploadVideoFiles([]);
                                        setUploadedVideos([]);
                                        setUploadedVideoRemove([]);
                                        setExistingUploadedVideos([]);
                                        props.setAccountPropertyListResidential(currentUserInfoNew);
                                        // props.setTrigger(false);
                                        setMessages("Property Added");
                                        setAcknowledgeNormalActive(true);
                                    });
                                })




                            }
                            else {
                                alert("You have enter an invalid postal code");
                                setSpinnerActive(false);
                            }
                        }
                        else {
                            alert("Invalid postal code");
                            setSpinnerActive(false);
                        }

                    })
                }

            }
        }

    }

    function handleCompanyNameChange(event) {
        setCompanyName(event.target.value);
    }

    function handleAddressChange(event) {
        setAddress(event.target.value);
    }

    function handlePostalCodeChange(event) {
        setPostalCode(event.target.value);
    }
    function handlePSFChange(event) {
        console.log(event.target.value)
        setPSFValue(event.target.value);
    }
    function handleSellingPriceChange(event) {
        setSellingPrice(event.target.value);
    }

    const handleCompanyRepresentative = (event) => {
        setCompanyRepresentative(event.target.value);
    }

    const handleClick = () => {
        setIsToggled(!isToggled);
    };

    const handleClickAgentRepresented = () => {
        setIsAgentRepresented(!isAgentRepresented);
    };

    const handleTypeOfSpaceChange = (e) => {
        setTypeOfSpace(e.target.value);
    }

    useEffect(() => {
        if (props.trigger == true && props.currentUserInfo.userType != null) {
            if (props.modifyPropertyIndex != null) {
                setTypeOfSpace(props.addPropertyView);
                if (props.addPropertyView == "Residential Sale") {
                    setExistingUploadedImages(props.accountPropertyListResidential != null ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].allImageURL : null);
                    setExistingUploadedVideos(props.accountPropertyListResidential != null ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].allVideoURL : null);
                    setTargetDistrict(props.accountPropertyListResidential != null ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].district : null);
                    setIsAgentRepresented(props.accountPropertyListResidential[props.modifyPropertyIndex][1].agentRepresented);
                    setNeedAgent(props.accountPropertyListResidential != null ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].agentRequired : false);
                    if (props.accountPropertyListResidential[props.modifyPropertyIndex][1].hasOwnProperty('reason')) {
                        setReason(props.accountPropertyListResidential[props.modifyPropertyIndex][1].reason);
                    }
                }
                else if ((props.addPropertyView == "Commercial Sale")) {
                    setExistingUploadedImages(props.accountPropertyList != null ? props.accountPropertyList[props.modifyPropertyIndex][1].allImageURL : null);
                    setExistingUploadedVideos(props.accountPropertyList != null ? props.accountPropertyList[props.modifyPropertyIndex][1].allVideoURL : null);
                    setTargetDistrict(props.accountPropertyList != null ? props.accountPropertyList[props.modifyPropertyIndex][1].district : null);
                    setIsAgentRepresented(props.accountPropertyList[props.modifyPropertyIndex][1].agentRepresented);
                    setNeedAgent(props.accountPropertyList != null ? props.accountPropertyList[props.modifyPropertyIndex][1].agentRequired : null);
                    if (props.accountPropertyList[props.modifyPropertyIndex][1].hasOwnProperty('reason')) {
                        setReason(props.accountPropertyList[props.modifyPropertyIndex][1].reason);
                    }
                }
                else if ((props.addPropertyView == "Commercial Rent")) {
                    console.log("here", props.accountPropertyList[props.modifyPropertyIndex][1])
                    setExistingUploadedImages(props.accountPropertyList != null ? props.accountPropertyList[props.modifyPropertyIndex][1].allImageURL : null);
                    setExistingUploadedVideos(props.accountPropertyList != null ? props.accountPropertyList[props.modifyPropertyIndex][1].allVideoURL : null);
                    setTargetDistrict(props.accountPropertyList != null ? props.accountPropertyList[props.modifyPropertyIndex][1].district : null);
                    setIsAgentRepresented(props.accountPropertyList[props.modifyPropertyIndex][1].agentRepresented);
                    setNeedAgent(props.accountPropertyList != null ? props.accountPropertyList[props.modifyPropertyIndex][1].agentRequired : null);
                    setUsageType(props.accountPropertyList != null ? props.accountPropertyList[props.modifyPropertyIndex][1].usageType : "F&B");
                    setMinLeaseTerm(props.accountPropertyList != null ? props.accountPropertyList[props.modifyPropertyIndex][1].minLeaseTerm : "")
                    if (props.accountPropertyList[props.modifyPropertyIndex][1].hasOwnProperty('reason')) {
                        setReason(props.accountPropertyList[props.modifyPropertyIndex][1].reason);
                    }
                }
                else if ((props.addPropertyView == "Residential Rent")) {
                    console.log("here", props.accountPropertyListResidential[props.modifyPropertyIndex][1])
                    setExistingUploadedImages(props.accountPropertyListResidential != null ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].allImageURL : null);
                    setExistingUploadedVideos(props.accountPropertyListResidential != null ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].allVideoURL : null);
                    setTargetDistrict(props.accountPropertyListResidential != null ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].district : null);
                    setIsAgentRepresented(props.accountPropertyListResidential[props.modifyPropertyIndex][1].agentRepresented);
                    setNeedAgent(props.accountPropertyListResidential != null ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].agentRequired : null);
                    setUsageType(props.accountPropertyListResidential != null ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].usageType : "F&B");
                    setMinLeaseTerm(props.accountPropertyListResidential != null ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].minLeaseTerm : "")
                    if (props.accountPropertyListResidential[props.modifyPropertyIndex][1].hasOwnProperty('reason')) {
                        setReason(props.accountPropertyListResidential[props.modifyPropertyIndex][1].reason);
                    }
                }
            }
            else {
                if (props.currentUserInfo != null) {
                    if (props.currentUserInfo.userType[0] == "Agent") {
                        setIsAgentRepresented(true);
                    }
                    // setTypeOfSpace("Commercial");
                }
            }
        }
    }, [props])

    useEffect(() => {
        setTypeOfSpace("Commercial Sale")
    }, [])



    const savePropertyLocation = async () => {
        if (companyName == null || address == null || postalCode == null) {
            alert("Missing Parameters");
            return
        }
        else if (companyName == "" || address == "" || postalCode == "") {
            alert("Missing Parameters");
            return
        }

        else {
            setSpinnerActive(true);
            // const userPropertyList = await getDoc(doc(db, 'userInfo', user.uid));
            var newAddressPropertyData = {};
            var newAddressPropertyArray = [];
            if (props.accountPropertyList.length != 0) {
                var continueProcess = true;

                for (var i = 0; i < props.accountPropertyList.length; i++) {
                    if (props.accountPropertyList[i][1].propertyAddress == document.getElementById("propertyAddress").value || props.accountPropertyList[i][1].propertyCompanyName == companyName || (props.accountPropertyList[i][1].postalCode == postalCode && props.accountPropertyList[i][1].propertyUnitNumber.replace(/[#\-.]/g, '') == unitNumber.replace(/[#\-.]/g, ''))) {
                        alert("Duplicate Property Information");
                        setSpinnerActive(false);
                        continueProcess = false;
                        break;
                    }
                }

                // var addressUpper = address.toUpperCase();
                // console.log(address);
                // var targetDistrict = null;
                // for (var i = 0; i < district.length; i++) {
                //     for (var a = 0; a < district[i].length; a++) {
                //         if (addressUpper.search(district[i][a].toUpperCase()) >= 0) {
                //             targetDistrict = districtAreaCode[i];
                //         }
                //     }
                // }
                // if (targetDistrict == null) {
                //     continueProcess = false;
                //     alert("Invalid Property Address");
                //     setSpinnerActive(false);
                // }
                if (continueProcess == false) {
                    return;
                }
                else {

                    await callOneMapAPI(document.getElementById("postalCode").value).then((res) => {
                        console.log(res)
                        if (res.results.length != 0) {
                            if (typeOfSpace == "Commercial Sale") {
                                newAddressPropertyData.postalCode = res.results[0].POSTAL;
                                newAddressPropertyData.latitude = res.results[0].LATITUDE;
                                newAddressPropertyData.longtitude = res.results[0].LONGITUDE;
                                newAddressPropertyData.propertyCompanyName = companyName;
                                newAddressPropertyData.companyRepresentative = companyRepresentative;
                                newAddressPropertyData.propertyAddress = address;
                                newAddressPropertyData.propertyUnitNumber = unitNumber;
                                newAddressPropertyData.emailNotification = isToggled;
                                newAddressPropertyData.district = targetDistrict;
                                newAddressPropertyData.verified = "notApprove";
                                newAddressPropertyData.agentRepresented = isAgentRepresented;
                                newAddressPropertyData.PSFValue = PSFValue;
                                newAddressPropertyData.propertyType = typeOfSpace;
                                newAddressPropertyData.sellingPrice = sellingPrice;
                                newAddressPropertyData.timePosted = new Date();
                                newAddressPropertyData.commission = commissionAgent;
                                newAddressPropertyData.username = props.publicInfo.username;
                                newAddressPropertyData.uid = user.uid;
                                newAddressPropertyData.photoURL = props.currentUserInfo.userPhotoURL;
                                newAddressPropertyData.agentRequired = needAgent;
                            }
                            else {
                                newAddressPropertyData.postalCode = res.results[0].POSTAL;
                                newAddressPropertyData.latitude = res.results[0].LATITUDE;
                                newAddressPropertyData.longtitude = res.results[0].LONGITUDE;
                                newAddressPropertyData.propertyCompanyName = companyName;
                                newAddressPropertyData.companyRepresentative = companyRepresentative;
                                newAddressPropertyData.propertyAddress = address;
                                newAddressPropertyData.propertyUnitNumber = unitNumber;
                                newAddressPropertyData.emailNotification = isToggled;
                                newAddressPropertyData.district = targetDistrict;
                                newAddressPropertyData.verified = "notApprove";
                                newAddressPropertyData.agentRepresented = isAgentRepresented;
                                newAddressPropertyData.PSFValue = PSFValue;
                                newAddressPropertyData.propertyType = typeOfSpace;
                                newAddressPropertyData.rentalCost = sellingPrice;
                                newAddressPropertyData.timePosted = new Date();
                                newAddressPropertyData.minLeaseTerm = minLeaseTerm;
                                newAddressPropertyData.usageType = usageType;
                                newAddressPropertyData.username = props.publicInfo.username;
                                newAddressPropertyData.uid = user.uid;
                                newAddressPropertyData.photoURL = props.currentUserInfo.userPhotoURL;
                                newAddressPropertyData.agentRequired = needAgent;
                            }


                            // propertyAddressList.push(res.results[0].POSTAL + "|" + res.results[0].LATITUDE + "|" + res.results[0].LONGTITUDE);
                            if (newAddressPropertyData.hasOwnProperty('postalCode')) {
                                var allImageURL = [];
                                var allVideoURL = [];
                                var uploadPromises = [];
                                var uploadPromisesVideo = [];
                                const allowedSize = 800 * 1024 * 1024; // 800MB
                                for (let i = 0; i < uploadFiles.length; i++) {
                                    const file = uploadFiles[i];
                                    const reader = new FileReader();
                                    const uploadPromise = new Promise((resolve, reject) => {
                                        reader.onload = (e) => {
                                            const img = new Image();
                                            img.onload = () => {
                                                const canvas = document.createElement('canvas');
                                                const ctx = canvas.getContext('2d');

                                                canvas.width = img.width;
                                                canvas.height = img.height;
                                                ctx.drawImage(img, 0, 0);

                                                const watermarkText = 'NextLoop';
                                                const textWidth = ctx.measureText(watermarkText).width;

                                                ctx.font = 'bold 24px Arial';
                                                ctx.fillStyle = 'rgba(192, 192, 192, 0.5)'; // Background color
                                                ctx.fillRect(0, 30, canvas.width, 30); // Adjust the height as needed
                                                ctx.fillStyle = '#000'; // Text color
                                                ctx.fillText(watermarkText, (canvas.width - textWidth) / 2, 50); // Adjust the position as needed
                                                const imageURL = canvas.toDataURL();

                                                // Upload watermarked image to Firebase Storage
                                                const storageRef = ref(storage, `${user.uid}/ownCommercialProperty/${companyName}/${file.name}`);
                                                uploadString(storageRef, imageURL, 'data_url')
                                                    .then(() => {
                                                        getDownloadURL(storageRef).then((url) => {
                                                            allImageURL.push(url);
                                                            resolve();
                                                        })
                                                    })
                                                    .catch((error) => {
                                                        console.log('Error uploading image:', error);
                                                    });
                                            };

                                            img.src = e.target.result;
                                        };

                                        reader.readAsDataURL(file);
                                    });

                                    uploadPromises.push(uploadPromise)
                                }

                                for (let i = 0; i < uploadVideosFiles.length; i++) {
                                    const file = uploadVideosFiles[i];
                                    const fileSize = file.size;

                                    if (fileSize > allowedSize) {
                                        alert(`Video '${file.name}' exceeds the maximum size limit.`);
                                        continue;
                                    }

                                    const reader = new FileReader();
                                    const uploadPromise = new Promise((resolve, reject) => {
                                        reader.onload = async () => {
                                            const videoData = new Uint8Array(reader.result);
                                            const storageRef = ref(storage, `${user.uid}/ownCommercialProperty/${companyName}/${file.name}`);


                                            // Upload the video file to Firebase Storage with defined MIME type
                                            uploadBytes(storageRef, videoData, { contentType: file.type })
                                                .then((snapshot) => {
                                                    // File upload is complete

                                                    // Get the download URL of the uploaded video
                                                    getDownloadURL(storageRef)
                                                        .then((url) => {
                                                            allVideoURL.push(url);
                                                            resolve();
                                                        })
                                                        .catch((error) => {
                                                            console.log('Error getting download URL:', error);
                                                            reject(error);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log('Error uploading video:', error);
                                                    reject(error);
                                                });
                                        };
                                        reader.readAsArrayBuffer(file);
                                    });

                                    uploadPromisesVideo.push(uploadPromise);
                                }

                                Promise.all(uploadPromises.concat(uploadPromisesVideo)).then(() => {
                                    newAddressPropertyData.allImageURL = allImageURL;
                                    newAddressPropertyData.allVideoURL = allVideoURL;
                                    // newAddressPropertyArray.push(newAddressPropertyData);
                                    var userCommercialPropertyListNew = [...props.accountPropertyList];
                                    if (props.modifyPropertyIndex != null) {
                                        userCommercialPropertyListNew[props.modifyPropertyIndex][1] = newAddressPropertyData;
                                    }
                                    else {
                                        userCommercialPropertyListNew.push({ '0': companyName, '1': newAddressPropertyData });
                                    }

                                    console.log(props.accountPropertyList.length);
                                    console.log(newAddressPropertyData)
                                    return updateDoc(doc(db, `commericalPropertyList`, user.uid), {
                                        [companyName]: newAddressPropertyData
                                    }).then(() => {
                                        setSpinnerActive(false);
                                        setUploadedImages([]);
                                        setUploadedVideoRemove([]);
                                        setExistingUploadedVideos([]);
                                        setUploadFiles([]);
                                        setUploadVideoFiles([]);
                                        setUploadedVideos([]);
                                        props.setModifyIndivPropertyIndex(null);
                                        props.setAccountPropertyList(userCommercialPropertyListNew);
                                        setMessages("Property Added");
                                        setAcknowledgeNormalActive(true);
                                        // props.setUserResidentialPropertyList(newAddressPropertyArray);
                                        // props.setTrigger(false);
                                    });




                                })
                            }
                            else {
                                alert("You have enter an invalid postal code");
                                setSpinnerActive(false);
                            }
                        }
                        else {
                            alert("Invalid postal code");
                            setSpinnerActive(false);
                        }

                        // propertyAddressList.push(res.results[0].POSTAL + "|" + res.results[0].LATITUDE + "|" + res.results[0].LONGTITUDE);
                    })

                }
            }
            else {

                if (continueProcess == false) {
                    return;
                }
                else {
                    await callOneMapAPI(document.getElementById("postalCode").value).then((res) => {
                        if (res.results.length != 0) {
                            if (typeOfSpace == "Commercial Sale") {
                                newAddressPropertyData.postalCode = res.results[0].POSTAL;
                                newAddressPropertyData.latitude = res.results[0].LATITUDE;
                                newAddressPropertyData.longtitude = res.results[0].LONGITUDE;
                                newAddressPropertyData.propertyCompanyName = companyName;
                                newAddressPropertyData.companyRepresentative = companyRepresentative
                                newAddressPropertyData.propertyAddress = address;
                                newAddressPropertyData.emailNotification = isToggled;
                                newAddressPropertyData.propertyUnitNumber = unitNumber;
                                newAddressPropertyData.district = targetDistrict;
                                newAddressPropertyData.verified = "notApprove";
                                newAddressPropertyData.agentRepresented = isAgentRepresented;
                                newAddressPropertyData.PSFValue = PSFValue;
                                newAddressPropertyData.propertyType = typeOfSpace;
                                newAddressPropertyData.sellingPrice = sellingPrice;
                                newAddressPropertyData.timePosted = new Date();
                                newAddressPropertyData.commission = commissionAgent;
                                newAddressPropertyData.uid = user.uid;
                                newAddressPropertyData.username = props.publicInfo.username;
                                newAddressPropertyData.photoURL = props.currentUserInfo.userPhotoURL;
                                newAddressPropertyData.agentRequired = needAgent;
                            }
                            else {
                                newAddressPropertyData.postalCode = res.results[0].POSTAL;
                                newAddressPropertyData.latitude = res.results[0].LATITUDE;
                                newAddressPropertyData.longtitude = res.results[0].LONGITUDE;
                                newAddressPropertyData.propertyCompanyName = companyName;
                                newAddressPropertyData.companyRepresentative = companyRepresentative;
                                newAddressPropertyData.propertyAddress = address;
                                newAddressPropertyData.propertyUnitNumber = unitNumber;
                                newAddressPropertyData.emailNotification = isToggled;
                                newAddressPropertyData.district = targetDistrict;
                                newAddressPropertyData.verified = "notApprove";
                                newAddressPropertyData.agentRepresented = isAgentRepresented;
                                newAddressPropertyData.PSFValue = PSFValue;
                                newAddressPropertyData.rentalCost = sellingPrice;
                                newAddressPropertyData.timePosted = new Date();
                                newAddressPropertyData.propertyType = typeOfSpace;
                                newAddressPropertyData.minLeaseTerm = minLeaseTerm;
                                newAddressPropertyData.usageType = usageType;
                                newAddressPropertyData.username = props.publicInfo.username;
                                newAddressPropertyData.uid = user.uid;
                                newAddressPropertyData.photoURL = props.currentUserInfo.userPhotoURL;
                                newAddressPropertyData.agentRequired = needAgent;
                            }


                            // propertyAddressList.push(res.results[0].POSTAL + "|" + res.results[0].LATITUDE + "|" + res.results[0].LONGTITUDE);
                            if (newAddressPropertyData.hasOwnProperty('postalCode')) {
                                var allImageURL = [];
                                var allVideoURL = [];
                                var uploadPromises = [];
                                var uploadPromisesVideo = [];
                                const allowedSize = 800 * 1024 * 1024; // 800MB
                                for (let i = 0; i < uploadFiles.length; i++) {
                                    const file = uploadFiles[i];
                                    const reader = new FileReader();
                                    const uploadPromise = new Promise((resolve, reject) => {
                                        reader.onload = (e) => {
                                            const img = new Image();
                                            img.onload = () => {
                                                const canvas = document.createElement('canvas');
                                                const ctx = canvas.getContext('2d');

                                                canvas.width = img.width;
                                                canvas.height = img.height;
                                                ctx.drawImage(img, 0, 0);

                                                const watermarkText = 'Nextloop';
                                                const textWidth = ctx.measureText(watermarkText).width;

                                                ctx.font = 'bold 24px Arial';
                                                ctx.fillStyle = 'rgba(192, 192, 192, 0.5)'; // Background color
                                                ctx.fillRect(0, 30, canvas.width, 30); // Adjust the height as needed
                                                ctx.fillStyle = '#000'; // Text color
                                                ctx.fillText(watermarkText, (canvas.width - textWidth) / 2, 50); // Adjust the position as needed
                                                const imageURL = canvas.toDataURL();

                                                // Upload watermarked image to Firebase Storage
                                                const storageRef = ref(storage, `${user.uid}/ownCommercialProperty/${companyName}/${file.name}`);
                                                uploadString(storageRef, imageURL, 'data_url')
                                                    .then(() => {
                                                        getDownloadURL(storageRef).then((url) => {
                                                            allImageURL.push(url);
                                                            resolve();
                                                        })
                                                        console.log('Image uploaded successfully');
                                                    })
                                                    .catch((error) => {
                                                        console.log('Error uploading image:', error);
                                                    });
                                            };

                                            img.src = e.target.result;
                                        };

                                        reader.readAsDataURL(file);
                                    });

                                    uploadPromises.push(uploadPromise)
                                }

                                for (let i = 0; i < uploadVideosFiles.length; i++) {
                                    const file = uploadVideosFiles[i];
                                    const fileSize = file.size;

                                    if (fileSize > allowedSize) {
                                        alert(`Video '${file.name}' exceeds the maximum size limit.`);
                                        continue;
                                    }

                                    const reader = new FileReader();
                                    const uploadPromise = new Promise((resolve, reject) => {
                                        reader.onload = async () => {
                                            const videoData = new Uint8Array(reader.result);
                                            const storageRef = ref(storage, `${user.uid}/ownCommercialProperty/${companyName}/${file.name}`);


                                            // Upload the video file to Firebase Storage with defined MIME type
                                            uploadBytes(storageRef, videoData, { contentType: file.type })
                                                .then((snapshot) => {
                                                    // File upload is complete

                                                    // Get the download URL of the uploaded video
                                                    getDownloadURL(storageRef)
                                                        .then((url) => {
                                                            allVideoURL.push(url);
                                                            resolve();
                                                        })
                                                        .catch((error) => {
                                                            console.log('Error getting download URL:', error);
                                                            reject(error);
                                                        });
                                                })
                                                .catch((error) => {
                                                    console.log('Error uploading video:', error);
                                                    reject(error);
                                                });
                                        };
                                        reader.readAsArrayBuffer(file);
                                    });

                                    uploadPromisesVideo.push(uploadPromise);
                                }


                                Promise.all(uploadPromises.concat(uploadPromisesVideo)).then(() => {
                                    newAddressPropertyData.allImageURL = allImageURL;
                                    newAddressPropertyData.allVideoURL = allVideoURL;
                                    // newAddressPropertyArray.push(newAddressPropertyData);
                                    var currentUserInfoNew = [...props.accountPropertyList];
                                    currentUserInfoNew.push({ '0': companyName, '1': newAddressPropertyData });
                                    return setDoc(doc(db, `commericalPropertyList`, user.uid), {
                                        [companyName]: newAddressPropertyData
                                    }).then(() => {
                                        setSpinnerActive(false);
                                        setUploadedImages([]);
                                        setUploadFiles([]);
                                        setUploadVideoFiles([]);
                                        setUploadedVideos([]);
                                        setUploadedVideoRemove([]);
                                        setExistingUploadedVideos([]);
                                        props.setAccountPropertyList(currentUserInfoNew);
                                        setMessages("Property Added");
                                        setAcknowledgeNormalActive(true);
                                        // closePropertyPop();
                                    });
                                })




                            }
                            else {
                                alert("You have enter an invalid postal code");
                            }
                        }
                        else {
                            alert("Invalid postal code");
                            setSpinnerActive(false);
                        }

                    })
                }

            }
        }

    }

    const updatePropertyLocation = async () => {
        if (companyName == null || address == null || postalCode == null) {
            alert("Missing Parameters");
            return
        }
        else if (companyName == "" || address == "" || postalCode == "") {
            alert("Missing Parameters");
            return
        }
        // else if (uploadFiles.length == 0 && existingUploadedImages.length == 0) {
        //     alert("Missing Photos");
        //     return;
        // }
        else {
            setSpinnerActive(true);
            var continueProcess = true;
            var newAddressPropertyData = {};
            var newAddressPropertyArray = props.accountPropertyList;
            for (var i = 0; i < props.accountPropertyList.length; i++) {
                if (props.accountPropertyList[i][1].propertyCompanyName != companyName) {
                    if (props.accountPropertyList[i].propertyAddress == document.getElementById("propertyAddress").value || (props.accountPropertyList[i][1].postalCode == postalCode && props.accountPropertyList[i][1].propertyUnitNumber.replace(/[#\-.]/g, '') == unitNumber.replace(/[#\-.]/g, ''))) {
                        alert("Duplicate Property Information");
                        setSpinnerActive(false);
                        continueProcess = false;
                        break;
                    }
                }
            }
            // var addressUpper = address.toUpperCase();
            // console.log(addressUpper);
            // var targetDistrict = null;
            // for (var i = 0; i < district.length; i++) {
            //     for (var a = 0; a < district[i].length; a++) {
            //         if (addressUpper.search(district[i][a].toUpperCase()) >= 0) {
            //             targetDistrict = districtAreaCode[i];
            //         }
            //     }
            // }
            // if (targetDistrict == null) {
            //     continueProcess = false;
            //     alert("Invalid Property Address");
            //     setSpinnerActive(false);
            // }
            if (continueProcess == false) {
                setSpinnerActive(false);
                return;
            }
            else {
                await callOneMapAPI(document.getElementById("postalCode").value).then((res) => {
                    newAddressPropertyData.postalCode = res.results[0].POSTAL;
                    newAddressPropertyData.latitude = res.results[0].LATITUDE;
                    newAddressPropertyData.longtitude = res.results[0].LONGITUDE;
                    newAddressPropertyData.emailNotification = isToggled;
                    newAddressPropertyData.agentRepresented = isAgentRepresented;
                    newAddressPropertyData.propertyCompanyName = companyName;
                    newAddressPropertyData.propertyAddress = address;
                    newAddressPropertyData.district = targetDistrict;
                    newAddressPropertyData.propertyUnitNumber = unitNumber;
                    newAddressPropertyData.companyRepresentative = companyRepresentative;
                    newAddressPropertyData.PSFValue = PSFValue;
                    newAddressPropertyData.sellingPrice = sellingPrice;
                    newAddressPropertyData.commission = commissionAgent;
                    newAddressPropertyData.uid = user.uid;
                    newAddressPropertyData.timePosted = props.accountPropertyList[props.modifyPropertyIndex][1].timePosted;
                    newAddressPropertyData.verified = props.accountPropertyList[props.modifyPropertyIndex][1].verified;
                    newAddressPropertyArray[props.modifyPropertyIndex][1] = newAddressPropertyData;
                    newAddressPropertyData.username = props.publicInfo.username;
                    newAddressPropertyData.photoURL = props.currentUserInfo.userPhotoURL;
                    newAddressPropertyData.agentRequired = needAgent;
                    // propertyAddressList.push(res.results[0].POSTAL + "|" + res.results[0].LATITUDE + "|" + res.results[0].LONGTITUDE);
                })
                console.log(newAddressPropertyData)
                if (newAddressPropertyData.hasOwnProperty('postalCode')) {
                    var allImageURL = [];
                    var allVideoURL = [];
                    var uploadPromisesVideo = [];
                    var uploadPromisesVideoDelete = [];
                    var uploadPromises = [];
                    var uploadPromisesDelete = [];
                    for (let i = 0; i < uploadFiles.length; i++) {
                        const file = uploadFiles[i];
                        const reader = new FileReader();
                        const uploadPromise = new Promise((resolve, reject) => {
                            reader.onload = (e) => {
                                const img = new Image();
                                img.onload = () => {
                                    const canvas = document.createElement('canvas');
                                    const ctx = canvas.getContext('2d');

                                    canvas.width = img.width;
                                    canvas.height = img.height;
                                    ctx.drawImage(img, 0, 0);

                                    const watermarkText = 'Nextloop';
                                    const textWidth = ctx.measureText(watermarkText).width;

                                    ctx.font = 'bold 24px Arial';
                                    ctx.fillStyle = 'rgba(192, 192, 192, 0.5)'; // Background color
                                    ctx.fillRect(0, 30, canvas.width, 30); // Adjust the height as needed
                                    ctx.fillStyle = '#000'; // Text color
                                    ctx.fillText(watermarkText, (canvas.width - textWidth) / 2, 50); // Adjust the position as needed
                                    const imageURL = canvas.toDataURL();

                                    // Upload watermarked image to Firebase Storage
                                    const storageRef = ref(storage, `${user.uid}/ownCommercialProperty/${companyName}/${file.name}`);
                                    uploadString(storageRef, imageURL, 'data_url')
                                        .then(() => {
                                            getDownloadURL(storageRef).then((url) => {
                                                allImageURL.push(url);
                                                resolve();
                                            })
                                        })
                                        .catch((error) => {
                                            console.log('Error uploading image:', error);
                                        });
                                };

                                img.src = e.target.result;
                            };

                            reader.readAsDataURL(file);
                        });

                        uploadPromises.push(uploadPromise)
                    }

                    if (uploadedImageRemove.length == 0) {
                        const uploadPromiseDelete = new Promise((resolve, reject) => {
                            resolve();
                        })
                        uploadPromisesDelete.push(uploadPromiseDelete)
                    }
                    else {
                        for (var i = 0; i < uploadedImageRemove.length; i++) {
                            var url = uploadedImageRemove[i];
                            // Get the index of the path start
                            var pathStartIndex = url.indexOf("/o/") + 3;

                            // Get the index of the path end
                            var pathEndIndex = url.indexOf("?alt=media");

                            // Extract the path
                            var path = url.substring(pathStartIndex, pathEndIndex);

                            // Decode the URL-encoded characters
                            var decodedPath = decodeURIComponent(path);

                            // Get the file name
                            var fileName = decodedPath.substring(decodedPath.lastIndexOf("/") + 1);




                            const uploadPromiseDelete = new Promise((resolve, reject) => {


                                // Upload watermarked image to Firebase Storage
                                const storageRef = ref(storage, `${user.uid}/ownCommercialProperty/${companyName}/${fileName}`);
                                deleteObject(storageRef).then(() => {
                                    resolve();
                                }).catch((error) => {
                                    console.log(error);
                                })


                            });

                            uploadPromisesDelete.push(uploadPromiseDelete)
                        }
                    }

                    for (let i = 0; i < uploadVideosFiles.length; i++) {
                        const file = uploadVideosFiles[i];
                        const fileSize = file.size;
                        const allowedSize = 800 * 1024 * 1024; // 800MB
                        if (fileSize > allowedSize) {
                            alert(`Video '${file.name}' exceeds the maximum size limit.`);
                            continue;
                        }

                        const reader = new FileReader();
                        const uploadPromise = new Promise((resolve, reject) => {
                            reader.onload = async () => {
                                const videoData = new Uint8Array(reader.result);
                                const storageRef = ref(storage, `${user.uid}/ownCommercialProperty/${companyName}/${file.name}`);


                                // Upload the video file to Firebase Storage with defined MIME type
                                uploadBytes(storageRef, videoData, { contentType: file.type })
                                    .then((snapshot) => {
                                        // File upload is complete

                                        // Get the download URL of the uploaded video
                                        getDownloadURL(storageRef)
                                            .then((url) => {
                                                allVideoURL.push(url);
                                                resolve();
                                            })
                                            .catch((error) => {
                                                console.log('Error getting download URL:', error);
                                                reject(error);
                                            });
                                    })
                                    .catch((error) => {
                                        console.log('Error uploading video:', error);
                                        reject(error);
                                    });
                            };
                            reader.readAsArrayBuffer(file);
                        });

                        uploadPromisesVideo.push(uploadPromise);
                    }

                    if (uploadedVideoRemove.length == 0) {
                        const uploadPromiseDelete = new Promise((resolve, reject) => {
                            resolve();
                        })
                        uploadPromisesVideoDelete.push(uploadPromiseDelete)
                    }
                    else {
                        for (var i = 0; i < uploadedVideoRemove.length; i++) {
                            var url = uploadedVideoRemove[i];
                            // Get the index of the path start
                            var pathStartIndex = url.indexOf("/o/") + 3;

                            // Get the index of the path end
                            var pathEndIndex = url.indexOf("?alt=media");

                            // Extract the path
                            var path = url.substring(pathStartIndex, pathEndIndex);

                            // Decode the URL-encoded characters
                            var decodedPath = decodeURIComponent(path);

                            // Get the file name
                            var fileName = decodedPath.substring(decodedPath.lastIndexOf("/") + 1);




                            const uploadPromiseDelete = new Promise((resolve, reject) => {


                                // Upload watermarked image to Firebase Storage
                                const storageRef = ref(storage, `${user.uid}/ownCommercialProperty/${companyName}/${fileName}`);
                                deleteObject(storageRef).then(() => {
                                    console.log("deleted");
                                    resolve();
                                }).catch((error) => {
                                    console.log(error);
                                })


                            });

                            uploadPromisesVideoDelete.push(uploadPromiseDelete)
                        }
                    }

                    Promise.all(uploadPromises).then(() => {
                        Promise.all(uploadPromisesDelete).then(() => {
                            Promise.all(uploadPromisesVideo).then(() => {
                                Promise.all(uploadPromisesDelete).then(async () => {
                                    newAddressPropertyData.allVideoURL = [...allVideoURL];
                                    newAddressPropertyData.allImageURL = [...allImageURL, ...existingUploadedImages];
                                    props.accountPropertyList[props.modifyPropertyIndex][1] = newAddressPropertyData;
                                    var currentUserInfoNew = [...props.accountPropertyList];
                                    console.log(newAddressPropertyData)
                                    // currentUserInfoNew.propertyAddressList = newAddressPropertyArray;
                                    await updateDoc(doc(db, `commericalPropertyList`, user.uid), {
                                        [companyName]: newAddressPropertyData
                                    }).then(() => {
                                        setSpinnerActive(false);
                                        setUploadedImages([]);
                                        setUploadFiles([]);
                                        setUploadedVideos([]);
                                        setUploadVideoFiles([]);
                                        setUploadedImageRemove([]);
                                        setReason('');
                                        // props.setCurrentUserInfo(currentUserInfoNew);
                                        props.setModifyIndivPropertyIndex(null);
                                        props.setAccountPropertyList(currentUserInfoNew);
                                        setMessages("Property Updated");
                                        setAcknowledgeNormalActive(true);
                                        // props.setTrigger(false);
                                    })
                                })
                            })
                        })
                    })



                }
                else {
                    alert("You have enter an invalid postal code");
                }
            }
        }

    }

    const updatePropertyLocationResidential = async () => {
        if (companyName == null || address == null || postalCode == null) {
            alert("Missing Parameters");
            return
        }
        else if (companyName == "" || address == "" || postalCode == "") {
            alert("Missing Parameters");
            return
        }
        // else if (uploadFiles.length == 0 && existingUploadedImages.length == 0) {
        //     alert("Missing Photos");
        //     return;
        // }
        else {
            setSpinnerActive(true);
            var continueProcess = true;
            var newAddressPropertyData = {};

            for (var i = 0; i < props.accountPropertyListResidential.length; i++) {
                if (props.accountPropertyListResidential[i][1].propertyCompanyName != companyName) {
                    if (props.accountPropertyListResidential[i][1].propertyAddress == document.getElementById("propertyAddress").value || (props.accountPropertyListResidential[i][1].postalCode == postalCode && props.accountPropertyListResidential[i][1].propertyUnitNumber.replace(/[#\-.]/g, '') == unitNumber.replace(/[#\-.]/g, ''))) {
                        alert("Duplicate Property Information");
                        setSpinnerActive(false);
                        continueProcess = false;
                        break;
                    }
                }
            }
            // var addressUpper = address.toUpperCase();
            // console.log(addressUpper);
            // var targetDistrict = null;
            // for (var i = 0; i < district.length; i++) {
            //     for (var a = 0; a < district[i].length; a++) {
            //         if (addressUpper.search(district[i][a].toUpperCase()) >= 0) {
            //             targetDistrict = districtAreaCode[i];
            //         }
            //     }
            // }
            // if (targetDistrict == null) {
            //     continueProcess = false;
            //     alert("Invalid Property Address");
            //     setSpinnerActive(false);
            // }
            if (continueProcess == false) {
                setSpinnerActive(false);
                return;
            }
            else {
                await callOneMapAPI(document.getElementById("postalCode").value).then((res) => {
                    newAddressPropertyData.postalCode = res.results[0].POSTAL;
                    newAddressPropertyData.latitude = res.results[0].LATITUDE;
                    newAddressPropertyData.longtitude = res.results[0].LONGITUDE;
                    newAddressPropertyData.propertyCompanyName = companyName;
                    newAddressPropertyData.propertyAddress = address;
                    newAddressPropertyData.propertyUnitNumber = unitNumber;
                    newAddressPropertyData.emailNotification = isToggled;
                    newAddressPropertyData.agentRepresented = isAgentRepresented;
                    newAddressPropertyData.district = targetDistrict;
                    newAddressPropertyData.timePosted = props.accountPropertyListResidential[props.modifyPropertyIndex][1].timePosted;
                    newAddressPropertyData.verified = props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified;
                    newAddressPropertyData.PSFValue = PSFValue;
                    newAddressPropertyData.sellingPrice = sellingPrice;
                    newAddressPropertyData.username = props.publicInfo.username;
                    newAddressPropertyData.propertyTypeRes = propertyType;
                    newAddressPropertyData.commission = commissionAgent;
                    newAddressPropertyData.uid = user.uid;
                    newAddressPropertyData.photoURL = props.currentUserInfo.userPhotoURL;
                    newAddressPropertyData.agentRequired = needAgent;

                    // propertyAddressList.push(res.results[0].POSTAL + "|" + res.results[0].LATITUDE + "|" + res.results[0].LONGTITUDE);
                    if (newAddressPropertyData.hasOwnProperty('postalCode')) {
                        var allImageURL = [];
                        var allVideoURL = [];
                        var uploadPromisesVideo = [];
                        var uploadPromisesVideoDelete = [];
                        var uploadPromises = [];
                        var uploadPromisesDelete = [];
                        for (let i = 0; i < uploadFiles.length; i++) {
                            const file = uploadFiles[i];
                            const reader = new FileReader();
                            const uploadPromise = new Promise((resolve, reject) => {
                                reader.onload = (e) => {
                                    const img = new Image();
                                    img.onload = () => {
                                        const canvas = document.createElement('canvas');
                                        const ctx = canvas.getContext('2d');

                                        canvas.width = img.width;
                                        canvas.height = img.height;
                                        ctx.drawImage(img, 0, 0);

                                        const watermarkText = 'Nextloop';
                                        const textWidth = ctx.measureText(watermarkText).width;

                                        ctx.font = 'bold 24px Arial';
                                        ctx.fillStyle = 'rgba(192, 192, 192, 0.5)'; // Background color
                                        ctx.fillRect(0, 30, canvas.width, 30); // Adjust the height as needed
                                        ctx.fillStyle = '#000'; // Text color
                                        ctx.fillText(watermarkText, (canvas.width - textWidth) / 2, 50); // Adjust the position as needed
                                        const imageURL = canvas.toDataURL();

                                        // Upload watermarked image to Firebase Storage
                                        const storageRef = ref(storage, `${user.uid}/ownResidentialProperty/${companyName}/${file.name}`);
                                        uploadString(storageRef, imageURL, 'data_url')
                                            .then(() => {
                                                getDownloadURL(storageRef).then((url) => {
                                                    allImageURL.push(url);
                                                    resolve();
                                                })
                                            })
                                            .catch((error) => {
                                                console.log('Error uploading image:', error);
                                            });
                                    };

                                    img.src = e.target.result;
                                };

                                reader.readAsDataURL(file);
                            });

                            uploadPromises.push(uploadPromise)
                        }

                        if (uploadedImageRemove.length == 0) {
                            const uploadPromiseDelete = new Promise((resolve, reject) => {
                                resolve();
                            })
                            uploadPromisesDelete.push(uploadPromiseDelete)
                        }
                        else {
                            for (var i = 0; i < uploadedImageRemove.length; i++) {
                                var url = uploadedImageRemove[i];
                                // Get the index of the path start
                                var pathStartIndex = url.indexOf("/o/") + 3;

                                // Get the index of the path end
                                var pathEndIndex = url.indexOf("?alt=media");

                                // Extract the path
                                var path = url.substring(pathStartIndex, pathEndIndex);

                                // Decode the URL-encoded characters
                                var decodedPath = decodeURIComponent(path);

                                // Get the file name
                                var fileName = decodedPath.substring(decodedPath.lastIndexOf("/") + 1);




                                const uploadPromiseDelete = new Promise((resolve, reject) => {


                                    // Upload watermarked image to Firebase Storage
                                    const storageRef = ref(storage, `${user.uid}/ownResidentialProperty/${companyName}/${fileName}`);
                                    deleteObject(storageRef).then(() => {
                                        resolve();
                                    }).catch((error) => {
                                        console.log(error);
                                    })


                                });

                                uploadPromisesDelete.push(uploadPromiseDelete)
                            }
                        }

                        for (let i = 0; i < uploadVideosFiles.length; i++) {
                            const file = uploadVideosFiles[i];
                            const fileSize = file.size;
                            const allowedSize = 800 * 1024 * 1024; // 800MB
                            if (fileSize > allowedSize) {
                                alert(`Video '${file.name}' exceeds the maximum size limit.`);
                                continue;
                            }

                            const reader = new FileReader();
                            const uploadPromise = new Promise((resolve, reject) => {
                                reader.onload = async () => {
                                    const videoData = new Uint8Array(reader.result);
                                    const storageRef = ref(storage, `${user.uid}/ownResidentialProperty/${companyName}/${file.name}`);


                                    // Upload the video file to Firebase Storage with defined MIME type
                                    uploadBytes(storageRef, videoData, { contentType: file.type })
                                        .then((snapshot) => {
                                            // File upload is complete

                                            // Get the download URL of the uploaded video
                                            getDownloadURL(storageRef)
                                                .then((url) => {
                                                    allVideoURL.push(url);
                                                    resolve();
                                                })
                                                .catch((error) => {
                                                    console.log('Error getting download URL:', error);
                                                    reject(error);
                                                });
                                        })
                                        .catch((error) => {
                                            console.log('Error uploading video:', error);
                                            reject(error);
                                        });
                                };
                                reader.readAsArrayBuffer(file);
                            });

                            uploadPromisesVideo.push(uploadPromise);
                        }

                        if (uploadedVideoRemove.length == 0) {
                            const uploadPromiseDelete = new Promise((resolve, reject) => {
                                resolve();
                            })
                            uploadPromisesVideoDelete.push(uploadPromiseDelete)
                        }
                        else {
                            for (var i = 0; i < uploadedVideoRemove.length; i++) {
                                var url = uploadedVideoRemove[i];
                                // Get the index of the path start
                                var pathStartIndex = url.indexOf("/o/") + 3;

                                // Get the index of the path end
                                var pathEndIndex = url.indexOf("?alt=media");

                                // Extract the path
                                var path = url.substring(pathStartIndex, pathEndIndex);

                                // Decode the URL-encoded characters
                                var decodedPath = decodeURIComponent(path);

                                // Get the file name
                                var fileName = decodedPath.substring(decodedPath.lastIndexOf("/") + 1);




                                const uploadPromiseDelete = new Promise((resolve, reject) => {


                                    // Upload watermarked image to Firebase Storage
                                    const storageRef = ref(storage, `${user.uid}/ownResidentialProperty/${companyName}/${fileName}`);
                                    deleteObject(storageRef).then(() => {
                                        resolve();
                                    }).catch((error) => {
                                        console.log(error);
                                    })


                                });

                                uploadPromisesVideoDelete.push(uploadPromiseDelete)
                            }
                        }

                        Promise.all(uploadPromises).then(() => {
                            Promise.all(uploadPromisesDelete).then(() => {
                                Promise.all(uploadPromisesVideo).then(() => {
                                    Promise.all(uploadPromisesDelete).then(() => {
                                        newAddressPropertyData.allVideoURL = [...allVideoURL];
                                        newAddressPropertyData.allImageURL = [...allImageURL, ...existingUploadedImages];
                                        props.accountPropertyListResidential[props.modifyPropertyIndex][1] = newAddressPropertyData;

                                        return updateDoc(doc(db, `residentialPropertyList`, user.uid), {
                                            [companyName]: newAddressPropertyData
                                        }).then(() => {
                                            setSpinnerActive(false);
                                            setUploadedImages([]);
                                            setUploadFiles([]);
                                            setUploadedVideos([]);
                                            setUploadVideoFiles([]);
                                            setUploadedImageRemove([]);
                                            setTypeOfSpace("Commercial Sale");
                                            setReason('');
                                            props.setModifyIndivPropertyIndex(null);
                                            props.setAddPropertyView(null);
                                            props.setAccountPropertyListResidential(props.accountPropertyListResidential);
                                            setMessages("Property Updated");
                                            setAcknowledgeNormalActive(true);
                                            // props.setTrigger(false);
                                        });
                                    })
                                })


                            })
                        })
                    }
                    else {
                        alert("You have enter an invalid postal code");
                    }
                    // propertyAddressList.push(res.results[0].POSTAL + "|" + res.results[0].LATITUDE + "|" + res.results[0].LONGTITUDE);
                })
            }

        }

    }

    const callOneMapAPI = (postal) => {
        return new Promise(function (resolve, reject) {
            fetch(`https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postal}&returnGeom=Y&getAddrDetails=Y`)
                .then(response => response.text())
                .then(text => {
                    var obj = JSON.parse(text)
                    resolve(obj);
                })
        })
    }

    const closePropertyPop = () => {
        setReason('');
        setTargetDistrict('D01 City - Business District');
        setCompanyName(
            props.modifyPropertyIndex != null
                ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].propertyCompanyName
                    : props.accountPropertyListResidential[props.modifyPropertyIndex][1].propertyCompanyName
                : ""
        );
        setAddress(
            props.modifyPropertyIndex != null
                ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].propertyAddress
                    : props.accountPropertyListResidential[props.modifyPropertyIndex][1].propertyAddress
                : ""
        );
        setPostalCode(
            props.modifyPropertyIndex != null
                ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].postalCode
                    : props.accountPropertyListResidential[props.modifyPropertyIndex][1].postalCode
                : ""
        );
        setCompanyRepresentative(
            props.modifyPropertyIndex != null
                ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].companyRepresentative
                    : ""
                : ""
        );
        setIsToggled(
            props.modifyPropertyIndex != null
                ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].emailNotification
                    : props.accountPropertyListResidential[props.modifyPropertyIndex][1].emailNotification
                : true
        );

        setUploadFiles([]);
        setUploadedImages([]);
        setUploadedImageRemove([]);
        setExistingUploadedImages([]);
        setTypeOfSpace("Commercial Sale")
        setUploadVideoFiles([]);
        setUploadedVideos([]);
        setUploadedVideoRemove([]);
        setExistingUploadedVideos([]);

        props.setModifyIndivPropertyIndex(null);
        props.setTrigger(false)
    }

    const deleteProperty = async () => {
        var uploadPromisesDelete = [];
        var uploadPromisesVideo = [];
        var newAddressPropertyArray = [...props.accountPropertyList];
        var modifyIndex = props.modifyPropertyIndex;
        props.setModifyIndivPropertyIndex(null);
        newAddressPropertyArray.splice(modifyIndex, 1); // remove 1 element
        await updateDoc(doc(db, `commericalPropertyList`, user.uid), {
            [companyName]: deleteField()
        });
        for (var i = 0; i < existingUploadedImages.length; i++) {
            var url = existingUploadedImages[i];
            // Get the index of the path start
            var pathStartIndex = url.indexOf("/o/") + 3;

            // Get the index of the path end
            var pathEndIndex = url.indexOf("?alt=media");

            // Extract the path
            var path = url.substring(pathStartIndex, pathEndIndex);

            // Decode the URL-encoded characters
            var decodedPath = decodeURIComponent(path);

            // Get the file name
            var fileName = decodedPath.substring(decodedPath.lastIndexOf("/") + 1);




            const uploadPromiseDelete = new Promise((resolve, reject) => {


                // Upload watermarked image to Firebase Storage
                const storageRef = ref(storage, `${user.uid}/ownCommercialProperty/${companyName}/${fileName}`);
                deleteObject(storageRef).then(() => {
                    resolve();
                }).catch((error) => {
                    console.log(error);
                })


            });
            uploadPromisesDelete.push(uploadPromiseDelete)
        }

        // for (let i = 0; i < existingUploadedVideos.length; i++) {
        //     const url = existingUploadedVideos[i];
        //     // Get the index of the path start
        //     var pathStartIndex = url.indexOf("/o/") + 3;

        //     // Get the index of the path end
        //     var pathEndIndex = url.indexOf("?alt=media");

        //     // Extract the path
        //     var path = url.substring(pathStartIndex, pathEndIndex);

        //     // Decode the URL-encoded characters
        //     var decodedPath = decodeURIComponent(path);

        //     // Get the file name
        //     var fileName = decodedPath.substring(decodedPath.lastIndexOf("/") + 1);




        //     const uploadPromiseDelete = new Promise((resolve, reject) => {


        //         // Upload watermarked image to Firebase Storage
        //         const storageRef = ref(storage, `${user.uid}/ownCommercialProperty/${companyName}/${fileName}`);
        //         deleteObject(storageRef).then(() => {
        //             resolve();
        //         }).catch((error) => {
        //             console.log(error);
        //         })


        //     });

        //     uploadPromisesVideo.push(uploadPromiseDelete);
        // }
        Promise.all(uploadPromisesDelete).then(() => {
            Promise.all(uploadPromisesVideo).then(() => {
                var currentUserInfoNew = props.currentUserInfo;
                currentUserInfoNew.propertyAddressList = newAddressPropertyArray;
                // return updateDoc(doc(db, `userInfo`, user.uid), {
                //     propertyAddressList: newAddressPropertyArray
                // }).then(() => {
                setSpinnerActive(false);
                setUploadedImages([]);
                setUploadFiles([]);
                setUploadedImageRemove([]);
                props.setCurrentUserInfo(currentUserInfoNew);
                props.setModifyIndivPropertyIndex(null);
                props.setAddPropertyView(null);
                props.setAccountPropertyList(newAddressPropertyArray);
                setMessages("Property Deleted");
                setAcknowledgeNormalActive(true);
                // props.setTrigger(false);
                // });
            })
        })
    }

    const deletePropertyResidential = async () => {
        var uploadPromisesDelete = [];
        var uploadPromisesVideo = [];
        var newAddressPropertyArray = [...props.accountPropertyListResidential];
        var modifyIndex = props.modifyPropertyIndex;
        props.setModifyIndivPropertyIndex(null);
        newAddressPropertyArray.splice(modifyIndex, 1);
        await updateDoc(doc(db, `residentialPropertyList`, user.uid), {
            [companyName]: deleteField()
        });
        for (var i = 0; i < existingUploadedImages.length; i++) {
            var url = existingUploadedImages[i];
            // Get the index of the path start
            var pathStartIndex = url.indexOf("/o/") + 3;

            // Get the index of the path end
            var pathEndIndex = url.indexOf("?alt=media");

            // Extract the path
            var path = url.substring(pathStartIndex, pathEndIndex);

            // Decode the URL-encoded characters
            var decodedPath = decodeURIComponent(path);

            // Get the file name
            var fileName = decodedPath.substring(decodedPath.lastIndexOf("/") + 1);




            const uploadPromiseDelete = new Promise((resolve, reject) => {


                // Upload watermarked image to Firebase Storage
                const storageRef = ref(storage, `${user.uid}/ownResidentialProperty/${companyName}/${fileName}`);
                deleteObject(storageRef).then(() => {
                    resolve();
                }).catch((error) => {
                    console.log(error);
                })


            });
            uploadPromisesDelete.push(uploadPromiseDelete)
        }

        // for (let i = 0; i < existingUploadedVideos.length; i++) {
        //     const url = existingUploadedVideos[i];
        //     // Get the index of the path start
        //     var pathStartIndex = url.indexOf("/o/") + 3;

        //     // Get the index of the path end
        //     var pathEndIndex = url.indexOf("?alt=media");

        //     // Extract the path
        //     var path = url.substring(pathStartIndex, pathEndIndex);

        //     // Decode the URL-encoded characters
        //     var decodedPath = decodeURIComponent(path);

        //     // Get the file name
        //     var fileName = decodedPath.substring(decodedPath.lastIndexOf("/") + 1);




        //     const uploadPromiseDelete = new Promise((resolve, reject) => {


        //         // Upload watermarked image to Firebase Storage
        //         const storageRef = ref(storage, `${user.uid}/ownResidentialProperty/${companyName}/${fileName}`);
        //         deleteObject(storageRef).then(() => {
        //             resolve();
        //         }).catch((error) => {
        //             console.log(error);
        //         })


        //     });

        //     uploadPromisesVideo.push(uploadPromiseDelete);
        // }

        Promise.all(uploadPromisesDelete).then(() => {
            Promise.all(uploadPromisesVideo).then(() => {
                var currentUserInfoNew = props.currentUserInfo;

                currentUserInfoNew.propertyAddressListResidential = newAddressPropertyArray;
                // return updateDoc(doc(db, `userInfo`, user.uid), {
                //     propertyAddressListResidential: newAddressPropertyArray
                // }).then(() => {
                setSpinnerActive(false);
                setUploadedImages([]);
                setUploadFiles([]);
                setUploadedImageRemove([]);
                props.setCurrentUserInfo(currentUserInfoNew);
                props.setModifyIndivPropertyIndex(null);
                props.setAddPropertyView(null);
                props.setAccountPropertyListResidential(newAddressPropertyArray);
                props.setTrigger(false);
                // });
            })

        });



    }

    const deleteImageUpload = (url, index) => {
        var newUploadImages = [...uploadedImages];
        newUploadImages.splice(index, 1);
        setUploadedImages(newUploadImages);
        var newUploadFiles = [...uploadFiles];
        newUploadFiles.splice(index, 1);
        setUploadFiles(newUploadFiles);
    }

    const deleteExistingImageUpload = (url, index) => {
        var newUploadImages = [...existingUploadedImages];
        newUploadImages.splice(index, 1);
        setExistingUploadedImages(newUploadImages);
        var localUploadedImageRemove = [...uploadedImageRemove];
        localUploadedImageRemove.push(url);
        setUploadedImageRemove(localUploadedImageRemove);
    }



    useEffect(() => {
        setCompanyName(props.modifyPropertyIndex != null
            ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].propertyCompanyName
                : props.accountPropertyListResidential[props.modifyPropertyIndex][1].propertyCompanyName
            : "");
        setAddress(props.modifyPropertyIndex != null
            ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].propertyAddress
                : props.accountPropertyListResidential[props.modifyPropertyIndex][1].propertyAddress
            : "");
        setPostalCode(props.modifyPropertyIndex != null
            ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].postalCode
                : props.accountPropertyListResidential[props.modifyPropertyIndex][1].postalCode
            : "");
        setCompanyRepresentative(props.modifyPropertyIndex != null
            ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].companyRepresentative
                : ""
            : "");
        setIsToggled(
            props.modifyPropertyIndex != null
                ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].emailNotification
                    : props.accountPropertyListResidential[props.modifyPropertyIndex][1].emailNotification
                : true
        );
        setPSFValue(
            props.modifyPropertyIndex != null
                ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].PSFValue
                    : props.accountPropertyListResidential[props.modifyPropertyIndex][1].PSFValue
                : ""
        );
        setSellingPrice(
            props.modifyPropertyIndex != null
                ? props.addPropertyView == "Commercial Sale" ? props.accountPropertyList[props.modifyPropertyIndex][1].sellingPrice
                    : props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].rentalCost
                        : props.addPropertyView == "Residential Rent" ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].rentalCost
                            : props.accountPropertyListResidential[props.modifyPropertyIndex][1].sellingPrice
                : ""
        );
        setUnitNumber(
            props.modifyPropertyIndex != null
                ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? props.accountPropertyList[props.modifyPropertyIndex][1].propertyUnitNumber
                    : props.accountPropertyListResidential[props.modifyPropertyIndex][1].propertyUnitNumber
                : ""
        );
        setPropertyType(
            props.modifyPropertyIndex != null
                ? props.addPropertyView == "Commercial Sale" || props.addPropertyView == "Commercial Rent" ? 'HDB'
                    : props.accountPropertyListResidential[props.modifyPropertyIndex][1].propertyTypeRes
                : 'HDB'
        )
        setCommissionAgent(
            props.modifyPropertyIndex != null
                ? props.addPropertyView == "Commercial Sale" ? props.accountPropertyList[props.modifyPropertyIndex][1].commission
                    : props.addPropertyView == "Residential Rent" || props.addPropertyView == "Commercial Rent" ? "0.95%"
                        : props.accountPropertyListResidential[props.modifyPropertyIndex][1].commission
                : '0.95%'
        )
        if (props.homeRequirements != null) {
            console.log(props.homeRequirements)
            setPostalCode(props.homeRequirements.desiredArea);
            setPSFValue(props.homeRequirements.minFloorSize);
            setSellingPrice(props.homeRequirements.sellingPrice);

        }


    }, [props]);

    const handleMinLeaseTerm = (e) => {
        setMinLeaseTerm(e.target.value);
    }



    if (!props.trigger) {
        return;
    }
    else {

        return (
            <>
                <AcknowledgeNormal trigger={acknowledgeNormalActive} setTrigger={setAcknowledgeNormalActive} message={messages} parentTrigger={props.setTrigger}></AcknowledgeNormal>

                <Spinner trigger={spinnerActive} setTrigger={setSpinnerActive}></Spinner>
                <div className='modalNewProject' style={{ zIndex: "2" }}>

                    <div className='modal-contentNewProject' >
                        <div className='modal-headerNewProject'>

                            <div className="notification-close" onClick={() => closePropertyPop()}>
                                ×
                            </div>

                            <h4 className='modal-titleNewProject'>ADDING PROPERTY LOCATION</h4>
                        </div>

                        <div className='modal-bodyNewProject'>
                            <div style={{ padding: "10px" }}>
                                {props.modifyPropertyIndex == null ? <select id="typeOfSpace" value={typeOfSpace} onChange={handleTypeOfSpaceChange}>
                                    <option value="Residential Sale">Residential Sale</option>
                                    <option value="Residential Rent">Residential Rent</option>
                                    <option value="Commercial Sale">Commercial Sale</option>
                                    <option value="Commercial Rent">Commercial Rent</option>
                                </select> : null}
                                <div style={{ clear: 'both' }}></div>
                                {console.log(typeOfSpace, props)}
                                {typeOfSpace == "Commercial Sale" ?
                                    <div id="propertyList">
                                        {props.accountPropertyList.length != 0 && props.modifyPropertyIndex != null ? props.accountPropertyList[props.modifyPropertyIndex][1].agentRepresented == false ? <p style={{ fontSize: "14px", backgroundColor: "#33434D", width: "fit-content", padding: " 4px 8px", borderRadius: "10px", color: "white", marginBottom: "10px" }}>{props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? "Verified Property" : "Unverified property"}</p> : null : null}
                                        <p className="addPropertyTitle">Property Name*

                                        </p>
                                        <input className="addPropertyInput" id="propertyCompanyName" value={companyName} style={props.modifyPropertyIndex != null ? { backgroundColor: "#c0c0c0" } : null} onChange={props.modifyPropertyIndex == null ? handleCompanyNameChange : null}></input>
                                        <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Corporate Representative (if any)</p>
                                        <input className="addPropertyInput" id="propertyRepresentative" value={companyRepresentative} style={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? null : handleCompanyRepresentative : handleCompanyRepresentative}></input>
                                        <p className="addPropertyTitle" style={{ marginTop: "1%" }}>District</p>
                                        <select className="addPropertyInput" id="propertyAddress" value={targetDistrict} style={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? null : (e) => { setTargetDistrict(e.target.value) } : (e) => { setTargetDistrict(e.target.value) }}>
                                            {districtAreaCode.map((indivDistrict) => {
                                                return (
                                                    <option value={indivDistrict}>{indivDistrict}</option>
                                                )
                                            })}
                                        </select>
                                        <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Address*</p>
                                        <input className="addPropertyInput" id="propertyAddress" value={address} style={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? null : handleAddressChange : handleAddressChange}></input>
                                        <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Unit Number</p>
                                        <input className='addPropertyInput' id="unitNumber" value={unitNumber} style={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? null : (e) => { setUnitNumber(e.target.value) } : (e) => { setUnitNumber(e.target.value) }}></input>
                                        <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Postal Code*</p>
                                        <input type="number" className='addPropertyInput' id="postalCode" onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} value={postalCode} style={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? null : handlePostalCodeChange : handlePostalCodeChange}></input>
                                        <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Floor Size (in Sqft)*</p>
                                        <input type="number" className='addPropertyInput' id="postalCode" onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} value={PSFValue} style={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? null : handlePSFChange : handlePSFChange}></input>
                                        <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Selling Price</p>
                                        <input type="number" className='addPropertyInput' id="postalCode" onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} value={sellingPrice} onChange={handleSellingPriceChange}></input>
                                        <div>
                                            <div className="sm:hidden">
                                                <label htmlFor="Tab" className="sr-only">Tab</label>

                                                <select id="Tab" className="w-full rounded-md border-gray-200">
                                                    <option>Settings</option>
                                                    <option>Messages</option>
                                                    <option>Archive</option>
                                                    <option select>Notifications</option>
                                                </select>
                                            </div>
                                            <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Proposed Commission</p>

                                            <div className="hidden sm:block">
                                                <nav className="flex gap-6" aria-label="Tabs">
                                                    <a
                                                        onClick={() => setCommissionAgent("0.95%")}
                                                        className={commissionAgent == "0.95%" ? "shrink-0 rounded-lg bg-sky-100 p-2 text-sm font-medium text-sky-600" : "shrink-0 rounded-lg p-2 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700"}
                                                    >
                                                        0.95%
                                                    </a>

                                                    <a
                                                        onClick={() => setCommissionAgent("2%")}
                                                        className={commissionAgent == "2%" ? "shrink-0 rounded-lg bg-sky-100 p-2 text-sm font-medium text-sky-600" : "shrink-0 rounded-lg p-2 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700"}
                                                    >
                                                        2%
                                                    </a>

                                                    <a
                                                        onClick={() => setCommissionAgent("3%")}
                                                        className={commissionAgent == "3%" ? "shrink-0 rounded-lg bg-sky-100 p-2 text-sm font-medium text-sky-600" : "shrink-0 rounded-lg p-2 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700"}
                                                    >
                                                        3%
                                                    </a>
                                                    <a
                                                        onClick={() => setCommissionAgent("NA")}
                                                        className={commissionAgent == "NA" ? "shrink-0 rounded-lg bg-sky-100 p-2 text-sm font-medium text-sky-600" : "shrink-0 rounded-lg p-2 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700"}
                                                    >
                                                        NA
                                                    </a>
                                                </nav>
                                            </div>
                                        </div>

                                        <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Image</p>
                                        <button class="defaultUploadBtn" onClick={() => { uploadSelfImage.current.click() }}>Upload Images</button>
                                        <input ref={uploadSelfImage} type="file" accept="image/*" multiple style={{ display: "none" }} onChange={handleImageUpload} />
                                        <div>
                                            <span className='characterCount'>Maximum of 12 photos</span>
                                            <br></br>
                                            {props.modifyPropertyIndex == null ? null : <p>Existing Photos</p>}
                                            {existingUploadedImages != null ? existingUploadedImages.map((image, index) => (

                                                <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                                                    <img style={{ width: "50px", height: "50px" }} key={index} src={image} alt="Image not available" /><span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "transparent", color: "red", padding: "1px", cursor: "pointer" }} onClick={() => deleteExistingImageUpload(image, index)}>x</span>
                                                </div>
                                            )) : null}
                                            <br></br>
                                            {props.modifyPropertyIndex == null ? null : <p>New Photos</p>}
                                            {uploadedImages.map((image, index) => (

                                                <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                                                    <img style={{ width: "50px", height: "50px" }} key={index} src={image} alt="Image not available" /><span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "transparent", color: "red", padding: "1px", cursor: "pointer" }} onClick={() => deleteImageUpload(image, index)}>x</span>
                                                </div>
                                            ))}
                                        </div>

                                        {/* <p className="addPropertyTitle" style={{ marginTop: '1%' }}>
                                            Video
                                        </p>
                                        <button class="defaultUploadBtn" style={props.currentUserInfo.membership != null && props.currentUserInfo.membership != "Inactive" ?{} : { backgroundColor: "#c1c1c1" } } onClick={props.currentUserInfo.membership != null && props.currentUserInfo.membership != "Inactive" ? () => uploadSelfVideo.current.click():null}>
                                            Upload Videos
                                        </button>
                                        <input
                                            ref={uploadSelfVideo}
                                            type="file"
                                            accept="video/*"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={handleVideoUploadWithWatermark}
                                        />
                                        <div>
                                            <span className="characterCount">Maximum of {props.currentUserInfo.membership == "Standard" ? "1" :
                                                props.currentUserInfo.membership == "Premium" ? "3" :
                                                    props.currentUserInfo.membership == "Super-Premium" ? "5" : null} videos</span>
                                            <br></br>
                                            {existingUploadedVideos.length > 0 && <p>Existing Videos</p>}
                                            {existingUploadedVideos != null ? existingUploadedVideos.map((video, index) => (
                                                <div style={{ display: 'inline-block', position: 'relative', marginRight: '1%' }}>
                                                    <video style={{ width: '150px', height: 'auto' }} key={index} src={video} controls></video>
                                                    <span
                                                        style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'transparent', color: 'red', padding: '1px', cursor: 'pointer' }}
                                                        onClick={() => deleteExistingVideoUpload(video, index)}
                                                    >
                                                        x
                                                    </span>
                                                </div>
                                            )) : null}
                                            <br></br>
                                            {uploadedVideos.length > 0 && <p>New Videos</p>}
                                            {uploadedVideos.map((video, index) => (
                                                <div style={{ display: 'inline-block', position: 'relative', marginRight: '1%' }}>
                                                    <img style={{ width: '150px', height: 'auto' }} key={index} src={video} controls></img>
                                                    <span
                                                        style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'transparent', color: 'red', padding: '1px', cursor: 'pointer' }}
                                                        onClick={() => deleteVideoUpload(video, index)}
                                                    >
                                                        x
                                                    </span>
                                                </div>
                                            ))} */}
                                        {/* </div> */}


                                        <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Email Notification</p>
                                        <div className="addPropertyEmailNotiBtn" onClick={handleClick}>

                                            <div className={`addPropertyslider ${isToggled ? 'on' : 'off'}`}>{isToggled ? "On" : "Off"}</div>
                                        </div>
                                        {isAgentRepresented == false ?
                                            <>
                                                <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Do you want an agent to represent you?</p>
                                                <div className="addPropertyEmailNotiBtn" onClick={() => setNeedAgent(!needAgent)}>

                                                    <div className={`addPropertyslider ${needAgent ? 'on' : 'off'}`}>{needAgent ? "On" : "Off"}</div>
                                                </div>
                                            </>
                                            : null}
                                        {/* <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Agent Represented</p>
                                        <div className="addPropertyEmailNotiBtn" onClick={props.currentUserInfo.userType != null ? props.currentUserInfo.userType.includes("Agent") && (props.currentUserInfo.userType.includes("Master Tenant") || props.currentUserInfo.userType.includes("Landlord")) ? handleClickAgentRepresented : null : null}>
                                            <div className={`addPropertyslider ${isAgentRepresented ? 'on' : 'off'}`}>{isAgentRepresented ? "Yes" : "No"}</div>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                        <span className='characterCount'>The change can only be made if you are fulfilling the roles of both an agent and a landlord/master tenant.</span> */}
                                        {reason != '' ? <>
                                            <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Reason of unverified</p>
                                            <p>{reason}</p>
                                        </> : null}
                                    </div> :
                                    typeOfSpace == "Residential Sale" ?
                                        <div id="propertyList">
                                            {props.accountPropertyListResidential.length != 0 && props.modifyPropertyIndex != null ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].agentRepresented == false ? <p style={{ fontSize: "14px", backgroundColor: "#33434D", width: "fit-content", padding: " 4px 8px", borderRadius: "10px", color: "white", marginBottom: "10px" }}>{props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? "Verified Property" : "Unverified property"}</p> : null : null}
                                            <p className="addPropertyTitle">Property Name*

                                            </p>
                                            <input className="addPropertyInput" id="propertyCompanyName" value={companyName} style={props.modifyPropertyIndex != null ? { backgroundColor: "#c0c0c0" } : null} onChange={props.modifyPropertyIndex == null ? handleCompanyNameChange : null}></input>
                                            <p className="addPropertyTitle" style={{ marginTop: "10px" }}>District</p>
                                            <select className="addPropertyInput" id="propertyAddress" value={targetDistrict} style={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? null : (e) => { setTargetDistrict(e.target.value) } : (e) => { setTargetDistrict(e.target.value) }}>
                                                {districtAreaCode.map((indivDistrict) => {
                                                    return (
                                                        <option value={indivDistrict}>{indivDistrict}</option>
                                                    )
                                                })}
                                            </select>
                                            <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Address</p>
                                            <input className="addPropertyInput" id="propertyAddress" value={address} style={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? null : handleAddressChange : handleAddressChange}></input>
                                            <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Unit Number</p>
                                            <input className='addPropertyInput' id="unitNumber" value={unitNumber} style={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? null : (e) => { setUnitNumber(e.target.value) } : (e) => { setUnitNumber(e.target.value) }}></input>
                                            <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Postal Code</p>
                                            <input type="number" className='addPropertyInput' id="postalCode" onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} value={postalCode} style={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? null : handlePostalCodeChange : handlePostalCodeChange}></input>
                                            <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Floor Size (in Sqft)*</p>
                                            <input type="number" className='addPropertyInput' id="postalCode" onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} value={PSFValue} style={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? null : handlePSFChange : handlePSFChange}></input>
                                            <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Selling Price</p>
                                            <input type="number" className='addPropertyInput' id="postalCode" onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} value={sellingPrice} onChange={handleSellingPriceChange}></input>
                                            <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Property Type*</p>
                                            <select className="addPropertyInput" id="propertyAddress" value={propertyType} style={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? null : (e) => { setPropertyType(e.target.value) } : (e) => { setPropertyType(e.target.value) }}>

                                                <option value={'HDB'}>HDB</option>
                                                <option value={'Condominium'}>Condominium</option>
                                                <option value={'Landed'}>Landed</option>

                                            </select>
                                            <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Proposed Commission</p>

                                            <div className="hidden sm:block">
                                                <nav className="flex gap-6" aria-label="Tabs">
                                                    <a
                                                        onClick={() => setCommissionAgent("0.95%")}
                                                        className={commissionAgent == "0.95%" ? "shrink-0 rounded-lg bg-sky-100 p-2 text-sm font-medium text-sky-600" : "shrink-0 rounded-lg p-2 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700"}
                                                    >
                                                        0.95%
                                                    </a>

                                                    <a
                                                        onClick={() => setCommissionAgent("2%")}
                                                        className={commissionAgent == "2%" ? "shrink-0 rounded-lg bg-sky-100 p-2 text-sm font-medium text-sky-600" : "shrink-0 rounded-lg p-2 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700"}
                                                    >
                                                        2%
                                                    </a>

                                                    <a
                                                        onClick={() => setCommissionAgent("3%")}
                                                        className={commissionAgent == "3%" ? "shrink-0 rounded-lg bg-sky-100 p-2 text-sm font-medium text-sky-600" : "shrink-0 rounded-lg p-2 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700"}
                                                    >
                                                        3%
                                                    </a>
                                                    <a
                                                        onClick={() => setCommissionAgent("NA")}
                                                        className={commissionAgent == "NA" ? "shrink-0 rounded-lg bg-sky-100 p-2 text-sm font-medium text-sky-600" : "shrink-0 rounded-lg p-2 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700"}
                                                    >
                                                        NA
                                                    </a>
                                                </nav>
                                            </div>
                                            {/* <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Image</p>
                                        <button class="defaultUploadBtn" onClick={() => { uploadSelfImage.current.click() }}>Upload Images</button>
                                        <input ref={uploadSelfImage} type="file" accept="image/*" multiple style={{ display: "none" }} onChange={handleImageUpload} /> */}
                                            {/* <div>
                                            <span className='characterCount'>Maximum of {props.currentUserInfo.membership == "Standard" ? "5" :
                                                props.currentUserInfo.membership == "Premium" ? "12" :
                                                    props.currentUserInfo.membership == "Super-Premium" ? "20" : null} photos</span>
                                            <br></br>
                                            {props.modifyPropertyIndex == null ? null : <p>Existing Photos</p>}
                                            {existingUploadedImages.map((image, index) => (

                                                <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                                                    <img style={{ width: "50px", height: "50px" }} key={index} src={image} alt="Image not available" /><span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "transparent", color: "red", padding: "1px", cursor: "pointer" }} onClick={() => deleteExistingImageUpload(image, index)}>x</span>
                                                </div>
                                            ))}
                                            <br></br>
                                            {props.modifyPropertyIndex == null ? null : <p>New Photos</p>}
                                            {uploadedImages.map((image, index) => (

                                                <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                                                    <img style={{ width: "50px", height: "50px" }} key={index} src={image} alt="Image not available" /><span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "transparent", color: "red", padding: "1px", cursor: "pointer" }} onClick={() => deleteImageUpload(image, index)}>x</span>
                                                </div>
                                            ))}
                                        </div>
                                        <p className="addPropertyTitle" style={{ marginTop: '3%' }}>
                                            Video
                                        </p>
                                        <button class="defaultUploadBtn" onClick={() => uploadSelfVideo.current.click()}>
                                            Upload Videos
                                        </button>
                                        <input
                                            ref={uploadSelfVideo}
                                            type="file"
                                            accept="video/*"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={handleVideoUploadWithWatermark}
                                        /> */}
                                            {/* <div>
                                            <span className="characterCount">Maximum of {props.currentUserInfo.membership == "Standard" ? "1" :
                                                props.currentUserInfo.membership == "Premium" ? "3" :
                                                    props.currentUserInfo.membership == "Super-Premium" ? "5" : null} videos</span>
                                            <br></br>
                                            {existingUploadedVideos.length > 0 && <p>Existing Videos</p>}
                                            {existingUploadedVideos.map((video, index) => (
                                                <div style={{ display: 'inline-block', position: 'relative', marginRight: '1%' }}>
                                                    <video style={{ width: '150px', height: 'auto' }} key={index} src={video} controls></video>
                                                    <span
                                                        style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'transparent', color: 'red', padding: '1px', cursor: 'pointer' }}
                                                        onClick={() => deleteExistingVideoUpload(video, index)}
                                                    >
                                                        x
                                                    </span>
                                                </div>
                                            ))}
                                            <br></br>
                                            {uploadedVideos.length > 0 && <p>New Videos</p>}
                                            {uploadedVideos.map((video, index) => (
                                                <div style={{ display: 'inline-block', position: 'relative', marginRight: '1%' }}>
                                                    <img style={{ width: '150px', height: 'auto' }} key={index} src={video} controls></img>
                                                    <span
                                                        style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'transparent', color: 'red', padding: '1px', cursor: 'pointer' }}
                                                        onClick={() => deleteVideoUpload(video, index)}
                                                    >
                                                        x
                                                    </span>
                                                </div>
                                            ))}
                                        </div> */}

                                            <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Email Notification</p>
                                            <div className="addPropertyEmailNotiBtn" onClick={handleClick}>
                                                <div className={`addPropertyslider ${isToggled ? 'on' : 'off'}`}>{isToggled ? "On" : "Off"}</div>
                                            </div>
                                            {isAgentRepresented == false ?
                                                <>

                                                    <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Do you want an agent to represent you?</p>
                                                    <div className="addPropertyEmailNotiBtn" onClick={() => setNeedAgent(!needAgent)}>

                                                        <div className={`addPropertyslider ${needAgent ? 'on' : 'off'}`}>{needAgent ? "On" : "Off"}</div>
                                                    </div>
                                                </>
                                                : null}


                                            {/* <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Agent Represented</p>
                                        <div className="addPropertyEmailNotiBtn" onClick={props.currentUserInfo.userType.includes("Agent") && (props.currentUserInfo.userType.includes("Master Tenant") || props.currentUserInfo.userType.includes("Landlord")) ? handleClickAgentRepresented : null}>
                                            <div className={`addPropertyslider ${isAgentRepresented ? 'on' : 'off'}`}>{isAgentRepresented ? "Yes" : "No"}</div>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                        <span className='characterCount'>The change can only be made if you are fulfilling the roles of both an agent and a landlord/master tenant.</span> */}
                                            {reason != '' ? <>
                                                <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Reason of unverified</p>
                                                <p>{reason}</p>
                                            </> : null}
                                        </div>
                                        :
                                        typeOfSpace == "Commercial Rent" ?
                                            <div id="propertyList">
                                                {props.accountPropertyListResidential.length != 0 && props.modifyPropertyIndex != null ? props.accountPropertyList[props.modifyPropertyIndex][1].agentRepresented == false ? <p style={{ fontSize: "14px", backgroundColor: "#33434D", width: "fit-content", padding: " 4px 8px", borderRadius: "10px", color: "white", marginBottom: "10px" }}>{props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? "Verified Property" : "Unverified property"}</p> : null : null}
                                                <p className="addPropertyTitle">Property Name*

                                                </p>
                                                <input className="addPropertyInput" id="propertyCompanyName" value={companyName} style={props.modifyPropertyIndex != null ? { backgroundColor: "#c0c0c0" } : null} onChange={props.modifyPropertyIndex == null ? handleCompanyNameChange : null}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>District</p>
                                                <select className="addPropertyInput" id="propertyAddress" value={targetDistrict} style={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyList != null ? props.accountPropertyList[props.modifyPropertyIndex].verified == "approve" ? null : (e) => { setTargetDistrict(e.target.value) } : (e) => { setTargetDistrict(e.target.value) }}>
                                                    {districtAreaCode.map((indivDistrict) => {
                                                        return (
                                                            <option value={indivDistrict}>{indivDistrict}</option>
                                                        )
                                                    })}
                                                </select>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Address</p>
                                                <input className="addPropertyInput" id="propertyAddress" value={address} style={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex].verified == "approve" ? null : handleAddressChange : handleAddressChange}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Unit Number</p>
                                                <input className='addPropertyInput' id="unitNumber" value={unitNumber} style={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex].verified == "approve" ? null : (e) => { setUnitNumber(e.target.value) } : (e) => { setUnitNumber(e.target.value) }}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Postal Code</p>
                                                <input type="number" className='addPropertyInput' id="postalCode" onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} value={postalCode} style={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex].verified == "approve" ? null : handlePostalCodeChange : handlePostalCodeChange}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Floor Size (in Sqft)*</p>
                                                <input type="number" className='addPropertyInput' id="postalCode" onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} value={PSFValue} style={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyList.length != 0 ? props.accountPropertyList[props.modifyPropertyIndex][1].verified == "approve" ? null : handlePSFChange : handlePSFChange}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Usage</p>
                                                <select className="addPropertyInput" id="propertyAddress" value={usageType} onChange={(e) => { setUsageType(e.target.value) }}>
                                                    <option value={'F&B'}>F&B</option>
                                                    <option value={'Retail'}>Retail</option>
                                                    <option value={'Services'}>Services</option>
                                                    <option value={'Storage'}>Storage</option>
                                                    <option value={'Others'}>Others</option>

                                                </select>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Rental Cost Per Month</p>
                                                <input type="number" className='addPropertyInput' id="postalCode" onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} value={sellingPrice} onChange={handleSellingPriceChange}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Min Lease Term</p>
                                                <input className='addPropertyInput' id="postalCode" value={minLeaseTerm} onChange={handleMinLeaseTerm}></input>

                                                {/* <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Image</p>
                                        <button class="defaultUploadBtn" onClick={() => { uploadSelfImage.current.click() }}>Upload Images</button>
                                        <input ref={uploadSelfImage} type="file" accept="image/*" multiple style={{ display: "none" }} onChange={handleImageUpload} /> */}
                                                {/* <div>
                                            <span className='characterCount'>Maximum of {props.currentUserInfo.membership == "Standard" ? "5" :
                                                props.currentUserInfo.membership == "Premium" ? "12" :
                                                    props.currentUserInfo.membership == "Super-Premium" ? "20" : null} photos</span>
                                            <br></br>
                                            {props.modifyPropertyIndex == null ? null : <p>Existing Photos</p>}
                                            {existingUploadedImages.map((image, index) => (

                                                <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                                                    <img style={{ width: "50px", height: "50px" }} key={index} src={image} alt="Image not available" /><span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "transparent", color: "red", padding: "1px", cursor: "pointer" }} onClick={() => deleteExistingImageUpload(image, index)}>x</span>
                                                </div>
                                            ))}
                                            <br></br>
                                            {props.modifyPropertyIndex == null ? null : <p>New Photos</p>}
                                            {uploadedImages.map((image, index) => (

                                                <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                                                    <img style={{ width: "50px", height: "50px" }} key={index} src={image} alt="Image not available" /><span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "transparent", color: "red", padding: "1px", cursor: "pointer" }} onClick={() => deleteImageUpload(image, index)}>x</span>
                                                </div>
                                            ))}
                                        </div>
                                        <p className="addPropertyTitle" style={{ marginTop: '3%' }}>
                                            Video
                                        </p>
                                        <button class="defaultUploadBtn" onClick={() => uploadSelfVideo.current.click()}>
                                            Upload Videos
                                        </button>
                                        <input
                                            ref={uploadSelfVideo}
                                            type="file"
                                            accept="video/*"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={handleVideoUploadWithWatermark}
                                        /> */}
                                                {/* <div>
                                            <span className="characterCount">Maximum of {props.currentUserInfo.membership == "Standard" ? "1" :
                                                props.currentUserInfo.membership == "Premium" ? "3" :
                                                    props.currentUserInfo.membership == "Super-Premium" ? "5" : null} videos</span>
                                            <br></br>
                                            {existingUploadedVideos.length > 0 && <p>Existing Videos</p>}
                                            {existingUploadedVideos.map((video, index) => (
                                                <div style={{ display: 'inline-block', position: 'relative', marginRight: '1%' }}>
                                                    <video style={{ width: '150px', height: 'auto' }} key={index} src={video} controls></video>
                                                    <span
                                                        style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'transparent', color: 'red', padding: '1px', cursor: 'pointer' }}
                                                        onClick={() => deleteExistingVideoUpload(video, index)}
                                                    >
                                                        x
                                                    </span>
                                                </div>
                                            ))}
                                            <br></br>
                                            {uploadedVideos.length > 0 && <p>New Videos</p>}
                                            {uploadedVideos.map((video, index) => (
                                                <div style={{ display: 'inline-block', position: 'relative', marginRight: '1%' }}>
                                                    <img style={{ width: '150px', height: 'auto' }} key={index} src={video} controls></img>
                                                    <span
                                                        style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'transparent', color: 'red', padding: '1px', cursor: 'pointer' }}
                                                        onClick={() => deleteVideoUpload(video, index)}
                                                    >
                                                        x
                                                    </span>
                                                </div>
                                            ))}
                                        </div> */}
                                                <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Email Notification</p>
                                                <div className="addPropertyEmailNotiBtn" onClick={handleClick}>
                                                    <div className={`addPropertyslider ${isToggled ? 'on' : 'off'}`}>{isToggled ? "On" : "Off"}</div>
                                                </div>
                                                {isAgentRepresented == false ?
                                                    <>

                                                        <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Do you want an agent to represent you?</p>
                                                        <div className="addPropertyEmailNotiBtn" onClick={() => setNeedAgent(!needAgent)}>

                                                            <div className={`addPropertyslider ${needAgent ? 'on' : 'off'}`}>{needAgent ? "On" : "Off"}</div>
                                                        </div>
                                                    </>
                                                    : null}


                                                {/* <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Agent Represented</p>
                                        <div className="addPropertyEmailNotiBtn" onClick={props.currentUserInfo.userType.includes("Agent") && (props.currentUserInfo.userType.includes("Master Tenant") || props.currentUserInfo.userType.includes("Landlord")) ? handleClickAgentRepresented : null}>
                                            <div className={`addPropertyslider ${isAgentRepresented ? 'on' : 'off'}`}>{isAgentRepresented ? "Yes" : "No"}</div>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                        <span className='characterCount'>The change can only be made if you are fulfilling the roles of both an agent and a landlord/master tenant.</span> */}
                                                {reason != '' ? <>
                                                    <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Reason of unverified</p>
                                                    <p>{reason}</p>
                                                </> : null}
                                            </div>
                                            :
                                            <div id="propertyList">
                                                {props.accountPropertyListResidential.length != 0 && props.modifyPropertyIndex != null ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].agentRepresented == false ? <p style={{ fontSize: "14px", backgroundColor: "#33434D", width: "fit-content", padding: " 4px 8px", borderRadius: "10px", color: "white", marginBottom: "10px" }}>{props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? "Verified Property" : "Unverified property"}</p> : null : null}
                                                <p className="addPropertyTitle">Property Name*

                                                </p>
                                                <input className="addPropertyInput" id="propertyCompanyName" value={companyName} style={props.modifyPropertyIndex != null ? { backgroundColor: "#c0c0c0" } : null} onChange={props.modifyPropertyIndex == null ? handleCompanyNameChange : null}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>District</p>
                                                <select className="addPropertyInput" id="propertyAddress" value={targetDistrict} style={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? null : (e) => { setTargetDistrict(e.target.value) } : (e) => { setTargetDistrict(e.target.value) }}>
                                                    {districtAreaCode.map((indivDistrict) => {
                                                        return (
                                                            <option value={indivDistrict}>{indivDistrict}</option>
                                                        )
                                                    })}
                                                </select>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Address</p>
                                                <input className="addPropertyInput" id="propertyAddress" value={address} style={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? null : handleAddressChange : handleAddressChange}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Unit Number</p>
                                                <input className='addPropertyInput' id="unitNumber" value={unitNumber} style={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? null : (e) => { setUnitNumber(e.target.value) } : (e) => { setUnitNumber(e.target.value) }}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Postal Code</p>
                                                <input type="number" className='addPropertyInput' onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} id="postalCode" value={postalCode} style={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? null : handlePostalCodeChange : handlePostalCodeChange}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Floor Size (in Sqft)*</p>
                                                <input type="number" className='addPropertyInput' onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} id="postalCode" value={PSFValue} style={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? null : handlePSFChange : handlePSFChange}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Rental Cost Per Month</p>
                                                <input type="number" className='addPropertyInput' onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} id="postalCode" value={sellingPrice} onChange={handleSellingPriceChange}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Min Lease Term</p>
                                                <input className='addPropertyInput' id="postalCode" value={minLeaseTerm} onChange={handleMinLeaseTerm}></input>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Property Type*</p>
                                                <select className="addPropertyInput" id="propertyAddress" value={propertyType} style={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? { backgroundColor: "#c0c0c0" } : null : null} onChange={props.modifyPropertyIndex != null && props.accountPropertyListResidential.length != 0 ? props.accountPropertyListResidential[props.modifyPropertyIndex][1].verified == "approve" ? null : (e) => { setPropertyType(e.target.value) } : (e) => { setPropertyType(e.target.value) }}>

                                                    <option value={'HDB'}>HDB</option>
                                                    <option value={'Condominium'}>Condominium</option>
                                                    <option value={'Landed'}>Landed</option>

                                                </select>
                                                <p className="addPropertyTitle" style={{ marginTop: "10px" }}>Furnishing</p>
                                                <select className="addPropertyInput" id="propertyAddress" value={propertyFurnishing} onChange={(e) => { setPropertyFurnishing(e.target.value) }}>

                                                    <option value={'Unfurnished'}>Unfurnished</option>
                                                    <option value={'Partially Furnishing'}>Partially Furnishing</option>
                                                    <option value={'Fully Furnished'}>Fully Furnished</option>

                                                </select>

                                                {/* <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Image</p>
                                        <button class="defaultUploadBtn" onClick={() => { uploadSelfImage.current.click() }}>Upload Images</button>
                                        <input ref={uploadSelfImage} type="file" accept="image/*" multiple style={{ display: "none" }} onChange={handleImageUpload} /> */}
                                                {/* <div>
                                            <span className='characterCount'>Maximum of {props.currentUserInfo.membership == "Standard" ? "5" :
                                                props.currentUserInfo.membership == "Premium" ? "12" :
                                                    props.currentUserInfo.membership == "Super-Premium" ? "20" : null} photos</span>
                                            <br></br>
                                            {props.modifyPropertyIndex == null ? null : <p>Existing Photos</p>}
                                            {existingUploadedImages.map((image, index) => (

                                                <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                                                    <img style={{ width: "50px", height: "50px" }} key={index} src={image} alt="Image not available" /><span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "transparent", color: "red", padding: "1px", cursor: "pointer" }} onClick={() => deleteExistingImageUpload(image, index)}>x</span>
                                                </div>
                                            ))}
                                            <br></br>
                                            {props.modifyPropertyIndex == null ? null : <p>New Photos</p>}
                                            {uploadedImages.map((image, index) => (

                                                <div style={{ display: "inline-block", position: "relative", marginRight: "1%" }}>
                                                    <img style={{ width: "50px", height: "50px" }} key={index} src={image} alt="Image not available" /><span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "transparent", color: "red", padding: "1px", cursor: "pointer" }} onClick={() => deleteImageUpload(image, index)}>x</span>
                                                </div>
                                            ))}
                                        </div>
                                        <p className="addPropertyTitle" style={{ marginTop: '3%' }}>
                                            Video
                                        </p>
                                        <button class="defaultUploadBtn" onClick={() => uploadSelfVideo.current.click()}>
                                            Upload Videos
                                        </button>
                                        <input
                                            ref={uploadSelfVideo}
                                            type="file"
                                            accept="video/*"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={handleVideoUploadWithWatermark}
                                        /> */}
                                                {/* <div>
                                            <span className="characterCount">Maximum of {props.currentUserInfo.membership == "Standard" ? "1" :
                                                props.currentUserInfo.membership == "Premium" ? "3" :
                                                    props.currentUserInfo.membership == "Super-Premium" ? "5" : null} videos</span>
                                            <br></br>
                                            {existingUploadedVideos.length > 0 && <p>Existing Videos</p>}
                                            {existingUploadedVideos.map((video, index) => (
                                                <div style={{ display: 'inline-block', position: 'relative', marginRight: '1%' }}>
                                                    <video style={{ width: '150px', height: 'auto' }} key={index} src={video} controls></video>
                                                    <span
                                                        style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'transparent', color: 'red', padding: '1px', cursor: 'pointer' }}
                                                        onClick={() => deleteExistingVideoUpload(video, index)}
                                                    >
                                                        x
                                                    </span>
                                                </div>
                                            ))}
                                            <br></br>
                                            {uploadedVideos.length > 0 && <p>New Videos</p>}
                                            {uploadedVideos.map((video, index) => (
                                                <div style={{ display: 'inline-block', position: 'relative', marginRight: '1%' }}>
                                                    <img style={{ width: '150px', height: 'auto' }} key={index} src={video} controls></img>
                                                    <span
                                                        style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'transparent', color: 'red', padding: '1px', cursor: 'pointer' }}
                                                        onClick={() => deleteVideoUpload(video, index)}
                                                    >
                                                        x
                                                    </span>
                                                </div>
                                            ))}
                                        </div> */}

                                                <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Email Notification</p>
                                                <div className="addPropertyEmailNotiBtn" onClick={handleClick}>
                                                    <div className={`addPropertyslider ${isToggled ? 'on' : 'off'}`}>{isToggled ? "On" : "Off"}</div>
                                                </div>
                                                {isAgentRepresented == false ?
                                                    <>

                                                        <p className="addPropertyTitle" style={{ marginTop: "1%" }}>Do you want an agent to represent you?</p>
                                                        <div className="addPropertyEmailNotiBtn" onClick={() => setNeedAgent(!needAgent)}>

                                                            <div className={`addPropertyslider ${needAgent ? 'on' : 'off'}`}>{needAgent ? "On" : "Off"}</div>
                                                        </div>
                                                    </>
                                                    : null}


                                                {/* <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Agent Represented</p>
                                        <div className="addPropertyEmailNotiBtn" onClick={props.currentUserInfo.userType.includes("Agent") && (props.currentUserInfo.userType.includes("Master Tenant") || props.currentUserInfo.userType.includes("Landlord")) ? handleClickAgentRepresented : null}>
                                            <div className={`addPropertyslider ${isAgentRepresented ? 'on' : 'off'}`}>{isAgentRepresented ? "Yes" : "No"}</div>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                        <span className='characterCount'>The change can only be made if you are fulfilling the roles of both an agent and a landlord/master tenant.</span> */}
                                                {reason != '' ? <>
                                                    <p className="addPropertyTitle" style={{ marginTop: "3%" }}>Reason of unverified</p>
                                                    <p>{reason}</p>
                                                </> : null}
                                            </div>

                                }
                            </div>

                        </div>

                        <div className='modal-footerNewProject'>
                            {typeOfSpace == "Commercial Sale" ?
                                <>
                                    {props.modifyPropertyIndex == null ? <button id="addPropertyBtn" onClick={() => savePropertyLocation()}>Add property</button> : <button id="addPropertyBtn" onClick={() => updatePropertyLocation()}>Update Property</button>}
                                    {props.modifyPropertyIndex == null ? null : <button id="addPropertyBtn" onClick={() => deleteProperty()} style={{ marginRight: "2%" }}>Delete</button>}
                                </> :
                                typeOfSpace == "Commercial Rent" ?
                                    <>
                                        {props.modifyPropertyIndex == null ? <button id="addPropertyBtn" onClick={() => savePropertyLocation()}>Add property</button> : <button id="addPropertyBtn" onClick={() => updatePropertyLocation()}>Update Property</button>}
                                        {props.modifyPropertyIndex == null ? null : <button id="addPropertyBtn" onClick={() => deleteProperty()} style={{ marginRight: "2%" }}>Delete</button>}
                                    </> :
                                    typeOfSpace == "Residential Sale" ?
                                        <>
                                            {props.modifyPropertyIndex == null ? <button id="addPropertyBtn" onClick={() => saveResidentialProperty()}>Add property</button> : <button id="addPropertyBtn" onClick={() => updatePropertyLocationResidential()}>Update Property</button>}
                                            {props.modifyPropertyIndex == null ? null : <button id="addPropertyBtn" onClick={() => deletePropertyResidential()} style={{ marginRight: "2%" }}>Delete</button>}
                                        </> :
                                        <>
                                            {props.modifyPropertyIndex == null ? <button id="addPropertyBtn" onClick={() => { saveResidentialProperty() }}>Add property</button> : <button id="addPropertyBtn" onClick={() => updatePropertyLocationResidential()}>Update Property</button>}
                                            {props.modifyPropertyIndex == null ? null : <button id="addPropertyBtn" onClick={() => deletePropertyResidential()} style={{ marginRight: "2%" }}>Delete</button>}
                                        </>
                            }
                            <div style={{ clear: 'both' }}></div>
                            {/* <button  onClick={() => savePropertyLocation()}>Save</button> */}

                        </div>
                    </div>
                </div>
            </>
        );
    }

}