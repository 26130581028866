import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../css/NewProject.css';
import '../css/Boost.css';
import { UserAuth } from '../../AuthContent';
import timeunit from 'timeunit';
import GooglePayButton from "@google-pay/button-react";
import { db } from '../../firebase/firebase'
import { collection, doc, onSnapshot, getDocs, updateDoc, getDoc, setDoc, deleteField } from 'firebase/firestore';
import axios from 'axios';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import BoostPromotion from './BoostPromotion';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import PaypalPayment from './PaypalPayment';

export default function NewProject(props) {
    const { user } = UserAuth();
    const [payClick, setPayClick] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(3);
    const [boostPeriod, setBoostPeriod] = useState(4);
    const [tierLeft, setTierLeft] = useState(1);
    const [pricing, setPricing] = useState([]);
    const [previousTier, setPreviousTier] = useState(null);
    const [spinner, setSpinner] = useState(false);
    const [targetListing, setTargetedListing] = useState(null);
    const [activateBoostPromo, setActivateBoostPromo] = useState(false);
    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
    const [messages, setMessages] = useState(null);




    // useEffect(() => {

    //     const docRef = doc(db, 'commonData', 'boostInfo');

    //     const unsubscribe = onSnapshot(docRef, (doc) => {
    //         // Do something with the data
    //         if (doc.data().tier1 != 0) {

    //             setTierLeft(doc.data().tier1);
    //             setPricing([3, 6, 9, 8]);
    //             setPaymentAmount(8);
    //         }
    //         else if (doc.data().tier2 != 0) {

    //             setTierLeft(doc.data().tier2)
    //             setPricing([4, 8, 12, 11])
    //             setPaymentAmount(11);
    //         }
    //         else if (doc.data().tier3 != 0) {

    //             setTierLeft(doc.data().tier3)
    //             setPricing([5, 10, 15, 14])
    //             setPaymentAmount(14);
    //         }
    //         else {

    //             setTierLeft(-1);
    //             setPricing([6, 12, 18, 17])
    //             setPaymentAmount(17);
    //         }
    //     });
    //     return () => {
    //         unsubscribe();
    //     };
    // }, []);
    useEffect(() => {
        async function fetchData() {
            try {
                const docRef = doc(db, 'commonData', 'boostInfo');
                const docSnapshot = await getDoc(docRef);
                const docData = docSnapshot.data();

                if (docData.tier1 !== 0) {
                    setTierLeft(docData.tier1);
                    setPricing([2, 3, 5, 4]);
                    setPaymentAmount(3);
                } else if (docData.tier2 !== 0) {
                    setTierLeft(docData.tier2);
                    setPricing([3, 4, 6, 5]);
                    setPaymentAmount(4);
                } else if (docData.tier3 !== 0) {
                    setTierLeft(docData.tier3);
                    setPricing([4, 5, 7, 6]);
                    setPaymentAmount(5);
                } else {
                    setTierLeft(-1);
                    setPricing([5, 6, 8, 7]);
                    setPaymentAmount(8);
                }
            } catch (error) {
                // Handle any potential errors here
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    // useEffect(() => {
    //     if (props.trigger == true) {
    //         const elements = document.getElementsByClassName('modal-bodyNewProject');
    //         if (payClick == true) {
    //             Array.from(elements).forEach(element => element.style.display = "none");
    //         }
    //         else {
    //             Array.from(elements).forEach(element => element.style.display = "block");
    //         }
    //     }

    // }, [payClick, props])

    useEffect(() => {
        if (!props.trigger) {

        }
        else {
            if (props.accountView == "Commercial") {
                const index = props.commercialListingPurcahse.findIndex((listing) => listing.id === props.id.id);
                setTargetedListing(props.commercialListingPurcahse[index]);
            }
            else {
                const index = props.residentialListingPurchase.findIndex((listing) => listing.id === props.id.id);
                setTargetedListing(props.residentialListingPurchase[index]);
            }
        }
    }, [props])

    const closeBoost = async () => {
        if (pricing[0] == 2 && previousTier == 'tier1') {
            await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                tier1: tierLeft + 1
            })
        }
        else if (pricing[0] == 3 && previousTier == 'tier2') {
            await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                tier2: tierLeft + 1
            })
        }

        else if (pricing[0] == 4 && previousTier == 'tier3') {
            await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                tier3: tierLeft + 1
            })

        }
        setPayClick(false);
        setPaymentAmount(3);
        setBoostPeriod(4);
        setTargetedListing(null);
        setPreviousTier(null);
        setSpinner(false);
        props.setTrigger(false);
    }

    const changeDate = async () => {
        if (targetListing.details.boost == true) {
            alert("Boost has already been activated");
        }
        else {
            var start = new Date(document.getElementById('BoostPeriodFrom').value);
            var today = new Date();

            var yesterday = new Date(today);
            start = new Date(Date.parse(start));
            today = new Date(Date.parse(today));
            start.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);
            yesterday.setDate(today.getDate() - 1);
            if (start < yesterday || start == "Invalid Date") {
                alert("You have enter an invalid date");
                return;
            } else {
                if (props.accountView == "Commercial") {
                    const index = props.commercialListingPurcahse.findIndex((listing) => listing.id === props.id.id);
                    var userProject = props.commercialListingPurcahse;
                    if (start.getTime() === today.getTime()) {
                        userProject[index].details.boost = true;
                        userProject[index].details.boostStart = start;
                        props.setCommercialListingPurchase(userProject);
                        await updateDoc(doc(db, 'listingCommercialPurchase', props.id.id), {
                            boost: true,
                            boostStart: start
                        }).then(() => {
                            props.setTrigger(false);
                            window.location.reload(true);
                        })
                    }
                    else {
                        userProject[index].details.boostStart = start;
                        props.setCommercialListingPurchase(userProject);
                        await updateDoc(doc(db, 'listingCommercialPurchase', props.id.id), {
                            boostStart: start
                        }).then(() => {
                            props.setTrigger(false);
                            window.location.reload(true);
                        })
                    }
                }
                else {
                    const index = props.residentialListingPurchase.findIndex((listing) => listing.id === props.id.id);
                    var userProject = props.residentialListingPurchase;
                    if (start.getTime() === today.getTime()) {
                        userProject[index].details.boost = true;
                        userProject[index].details.boostStart = start;
                        props.setCommercialListingPurchase(userProject);
                        await updateDoc(doc(db, 'residentialListingPurchase', props.id.id), {
                            boost: true,
                            boostStart: start
                        }).then(() => {
                            props.setTrigger(false);
                            window.location.reload(true);
                        })
                    }
                    else {
                        userProject[index].details.boostStart = start;
                        props.setCommercialListingPurchase(userProject);
                        await updateDoc(doc(db, 'residentialListingPurchase', props.id.id), {
                            boostStart: start
                        }).then(() => {
                            props.setTrigger(false);
                            window.location.reload(true);
                        })
                    }
                }

            }
        }
    }

    if (!props.trigger) {
        return;
    }
    else {
        const proceedButton = async (boostPeriodVar, pricingAmountVar) => {

            var start = new Date(document.getElementById('BoostPeriodFrom').value);
            var today = new Date();

            var yesterday = new Date(today);
            start = new Date(Date.parse(start));
            today = new Date(Date.parse(today));
            start.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);
            yesterday.setDate(today.getDate() - 1);
            if (start < yesterday || start == "Invalid Date") {
                alert("You have enter an invalid date");
                return;
            }
            else if (props.currentUserInfo.credit < pricingAmountVar) {
                alert("Not enough credit");
            }
            else {


                setPayClick(!payClick);

                if (pricing[0] == 2) {
                    await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                        tier1: tierLeft - 1
                    })
                    setPreviousTier('tier1')
                }
                else if (pricing[0] == 3) {
                    await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                        tier2: tierLeft - 1
                    })
                    setPreviousTier('tier2')
                }

                else if (pricing[0] == 4) {
                    await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                        tier3: tierLeft - 1
                    })
                    setPreviousTier('tier3')

                }
                else {
                    setPreviousTier('tier4')
                }

                if (props.accountView == "Commercial") {
                    const index = props.commercialListingPurcahse.findIndex((listing) => listing.id === props.id.id);
                    var userProject = props.commercialListingPurcahse;
                    if (userProject[index].details.boostStart != null) {
                        await updateDoc(doc(db, 'listingCommercialPurchase', userProject[index].id),{
                            boostLeft: userProject[index].details.boostLeft + boostPeriodVar
                        })
                        // props.setCommercialListingPurchase(userProject);
                        // setSpinner(false);
                    }
                    else {
                        if (start.getTime() === today.getTime()) {
                            await updateDoc(doc(db, 'listingCommercialPurchase', userProject[index].id),{
                                boost: true,
                                boostStart: start,
                                boostLeft: boostPeriodVar
                            })
                            

                        }
                        else {
                            await updateDoc(doc(db, 'listingCommercialPurchase', userProject[index].id),{
                                boost: false,
                                boostStart: start,
                                boostLeft: boostPeriodVar
                            })

                        }
                    }
                }
                else {
                    const index = props.residentialListingPurchase.findIndex((listing) => listing.id === props.id.id);
                    var userProject = props.residentialListingPurchase;
                    if (userProject[index].details.boostStart != null) {
                        await updateDoc(doc(db, 'residentialListingPurchase', userProject[index].id),{
                            boostLeft: userProject[index].details.boostLeft + boostPeriodVar
                        })
                        
                    }
                    else {
                        if (start.getTime() === today.getTime()) {
                            await updateDoc(doc(db, 'residentialListingPurchase', userProject[index].id),{
                                boost: true,
                                boostStart: start,
                                boostLeft: boostPeriodVar
                            })
                            

                        }
                        else {
                            await updateDoc(doc(db, 'residentialListingPurchase', userProject[index].id),{
                                boost: false,
                                boostStart: start,
                                boostLeft: boostPeriodVar
                            })

                        }
                    }
                }

                await updateDoc(doc(db, 'userInfo',user.uid),{
                    credit: props.currentUserInfo.credit - pricingAmountVar
                }).then(()=>{
                    setPayClick(false);
                    setPaymentAmount(4);
                    setBoostPeriod(4);
                    setPreviousTier(null);
                    // props.setTrigger(false);
                    setMessages("Payment Successful");
                    setAcknowledgeNormal(true);
                    window.location.reload(true);
                })


                

            }


        }

        const proceedPromoCode = () => {
            var start = new Date(document.getElementById('BoostPeriodFrom').value);
            var today = new Date();

            var yesterday = new Date(today);
            start = new Date(Date.parse(start));
            today = new Date(Date.parse(today));
            start.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);
            yesterday.setDate(today.getDate() - 1);
            if (start < yesterday || start == "Invalid Date") {
                alert("You have enter an invalid date");
                return;
            } else {
                if (pricing.includes(3) == true) {
                    setActivateBoostPromo(true);
                }
                else {
                    setMessages("The maximum limit for today's Boost promotion has been reached.")
                    setAcknowledgeNormal(true);
                }

            }
        }

        const changeDeal = (targetDiv, pricingAmount) => {
            // document.getElementById("deal1").className = "eachBoostOffer";
            // document.getElementById("deal2").className = "eachBoostOffer";
            // document.getElementById("deal3").className = "eachBoostOffer";
            // document.getElementById("deal4").className = "eachBoostOffer";
            // document.getElementById(targetDiv).className = "eachBoostOfferSelected";
            setPaymentAmount(pricingAmount);
            if (targetDiv == "deal1") {
                setBoostPeriod(1);
                proceedButton(1, pricingAmount);
            }
            else if (targetDiv == "deal2") {
                setBoostPeriod(2);
                proceedButton(1, pricingAmount);
            }
            else if (targetDiv == "deal3") {
                setBoostPeriod(3);
                proceedButton(1, pricingAmount);
            }
            else if (targetDiv == "deal4") {
                setBoostPeriod(4);
                proceedButton(1, pricingAmount);
            }
            
        }

        const createOrder = (data, actions) => {
            var start = new Date(document.getElementById('BoostPeriodFrom').value);
            var today = new Date();

            var yesterday = new Date(today);
            start = new Date(Date.parse(start));
            today = new Date(Date.parse(today));
            start.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);
            yesterday.setDate(today.getDate() - 1);
            if (start < yesterday || start == "Invalid Date") {
                alert("You have enter an invalid date");
                return;
            }
            else {
                // Order is created on the server and the order id is returned
                // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/createPayPalOrder
                // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalOrder
                return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalOrder", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + user.accessToken
                    },
                    // use the "body" param to optionally pass additional order information
                    // like product skus and quantities
                    body: JSON.stringify({
                        data: {
                            amount: paymentAmount,
                            currencyCode: "SGD",
                            intent: "CAPTURE"
                        }
                    }),
                })
                    .then((res) => {
                        if (res.ok) {
                            return res.json();
                        } else {
                            throw new Error("Failed to create order");
                        }
                    })
                    .then((data) => {
                        return data.result.orderID;
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        };

        const onApprove = (data, actions) => {
            var start = new Date(document.getElementById('BoostPeriodFrom').value);
            var today = new Date();

            var yesterday = new Date(today);
            start = new Date(Date.parse(start));
            today = new Date(Date.parse(today));
            start.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);
            yesterday.setDate(today.getDate() - 1);
            if (start < yesterday || start == "Invalid Date") {
                alert("You have enter an invalid date");
                return;
            }
            else {
                // Capture the funds from the transaction
                // return actions.order.capture().then(function (details) {
                // Send the order ID and captured details to your server
                // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/capturePayPalOrder
                // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/capturePayPalOrder
                setSpinner(true);
                return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/capturePayPalOrder", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + user.accessToken
                    },
                    body: JSON.stringify({
                        data: {
                            orderID: data.orderID,
                            path: "boostIndiv",
                            boostFor: props.accountView == "Commercial" ? "commercialSale" : "residentialSale",
                            boostListingId: props.id.id,
                            boostPeriod: boostPeriod,
                            startDate: start,
                            today: today.getTime()
                            // capturedDetails: details,
                        }
                    }),
                })
                    .then((response) => {

                        return response.json();
                    })
                    .then(async (data) => {
                        // Do something with the data here
                        if (data.hasOwnProperty('result')) {

                            if (data.result.status == "COMPLETED") {
                                await setDoc(doc(db, 'payment', data.result.id), {
                                    payer: data.result.payer,
                                    amount: data.result.purchase_units
                                }).then(() => {

                                    if (props.accountView == "Commercial") {
                                        const index = props.commercialListingPurcahse.findIndex((listing) => listing.id === props.id.id);
                                        var userProject = props.commercialListingPurcahse;
                                        if (userProject[index].details.boostStart != null) {
                                            userProject[index].details.boostLeft = userProject[index].details.boostLeft + boostPeriod;
                                            props.setCommercialListingPurchase(userProject);
                                            setSpinner(false);
                                        }
                                        else {
                                            if (start.getTime() === today.getTime()) {
                                                // if (userProject[index].details.boostStart == null) {
                                                userProject[index].details.boost = true;
                                                userProject[index].details.boostStart = start;
                                                userProject[index].details.boostLeft = boostPeriod;
                                                props.setCommercialListingPurchase(userProject);
                                                setSpinner(false);
                                                // }
                                                // else {
                                                //     userProject[index].details.boostLeft = userProject[index].details.boostLeft + boostPeriod;
                                                //     props.setCommercialListingPurchase(userProject);
                                                //     setSpinner(false);
                                                // }

                                            }
                                            else {
                                                // if (userProject[index].details.boostStart == null) {
                                                userProject[index].details.boost = false;
                                                userProject[index].details.boostStart = start;
                                                userProject[index].details.boostLeft = boostPeriod;
                                                props.setCommercialListingPurchase(userProject);
                                                setSpinner(false);
                                                // }
                                                // else {
                                                //     userProject[index].details.boostLeft = userProject[index].details.boostLeft + boostPeriod;
                                                //     props.setCommercialListingPurchase(userProject);
                                                //     setSpinner(false);
                                                // }

                                            }
                                        }
                                    }
                                    else {
                                        const index = props.residentialListingPurchase.findIndex((listing) => listing.id === props.id.id);
                                        var userProject = props.residentialListingPurchase;
                                        if (userProject[index].details.boostStart != null) {
                                            userProject[index].details.boostLeft = userProject[index].details.boostLeft + boostPeriod;
                                            props.setResidentialPurchase(userProject);
                                            setSpinner(false);
                                        }
                                        else {
                                            if (start.getTime() === today.getTime()) {
                                                // if (userProject[index].details.boostStart == null) {
                                                userProject[index].details.boost = true;
                                                userProject[index].details.boostStart = start;
                                                userProject[index].details.boostLeft = boostPeriod;
                                                props.setResidentialPurchase(userProject);
                                                setSpinner(false);
                                                // }
                                                // else {
                                                //     userProject[index].details.boostLeft = userProject[index].details.boostLeft + boostPeriod;
                                                //     props.setResidentialPurchase(userProject);
                                                //     setSpinner(false);
                                                // }

                                            }
                                            else {
                                                // if (userProject[index].details.boostStart == null) {
                                                userProject[index].details.boost = false;
                                                userProject[index].details.boostStart = start;
                                                userProject[index].details.boostLeft = boostPeriod;
                                                props.setResidentialPurchase(userProject);
                                                setSpinner(false);
                                                // }
                                                // else {
                                                //     userProject[index].details.boostLeft = userProject[index].details.boostLeft + boostPeriod;
                                                //     props.setResidentialPurchase(userProject);
                                                //     setSpinner(false);
                                                // }

                                            }
                                        }
                                    }


                                    setPayClick(false);
                                    setPaymentAmount(3);
                                    setBoostPeriod(4);
                                    setPreviousTier(null);
                                    props.setTrigger(false);
                                    window.location.reload(true);


                                })

                            }
                        }
                    })
                    .catch((error) => {
                        console.error('onapprove', error);
                    });
            }
            // });
        };


        return (
            <>
                {console.log(props)}
                <BoostPromotion trigger={activateBoostPromo} setTrigger={setActivateBoostPromo} path={props.accountView == "Commercial" ? 'commercialIndivSale' : 'residentialIndivSale'} boostListingId={props.id.id} currentUserInfo={props.currentUserInfo}></BoostPromotion>
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={messages} tierLeft={tierLeft}></AcknowledgeNormal>
                <div className='modalNewProject'>
                    <div className='modalBoost'>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => { closeBoost(); }}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>BOOST LISTING</h4>
                        </div>
                        <div className='modal-bodyNewProject'>
                            <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", padding: "8px 8px", borderRadius: "10px", marginBottom: "3%" }}>
                                <i class="fa-solid fa-exclamation" style={{ color: "#33434d", display: "flex", alignItems: "center", fontSize: "25px", marginRight: "2%" }}></i>

                                <div style={{ display: "flex", textAlign: "left", flexDirection: "column" }}>
                                    {/* <p style={{ fontWeight: "bold" }}></p> */}
                                    <p> If your listing has already been boosted, any additional boosts will simply extend the current boost period. To modify the date, kindly click on the boost button for the specific listing.</p>
                                </div>
                            </div>
                            <p className='modalLabelBoost'>Start: </p>
                            <input className='newListingInputBoost' type="date" id="BoostPeriodFrom"></input>

                            <div class='flex '>
                                <div class="min-w-full">


                                    <div class="mt-[20px] grid grid-cols-2 gap-[20px]">
                                        <div key="1" class="w-full bg-[#fff] rounded-[10px] shadow-[0px 1px 2px #E1E3E5] border border-[#E1E3E5] divide-y">
                                            <div class="pt-[15px] px-[25px] pb-[25px]">


                                                <div>

                                                    <p class="text-[#00153B] text-[50px] leading-[63px] font-bold">
                                                        1 Day
                                                    </p>
                                                </div>

                                                <div>
                                                    <p class="text-[#717F87] text-[18px] leading-[28px] font-medium">
                                                    {pricing[0]} Credits
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="pt-[25px] px-[25px] pb-[35px]">
                                                <div class="mt-[25px]">
                                                    <button class="bg-[#33434D] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold" onClick={() => changeDeal("deal1", pricing[0])}>Purchase</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div key="2" class="w-full bg-[#fff] rounded-[10px] shadow-[0px 1px 2px #E1E3E5] border border-[#E1E3E5] divide-y">
                                            <div class="pt-[15px] px-[25px] pb-[25px]">


                                                <div>

                                                    <p class="text-[#00153B] text-[50px] leading-[63px] font-bold">
                                                        2 Days
                                                    </p>
                                                </div>

                                                <div>
                                                    <p class="text-[#717F87] text-[18px] leading-[28px] font-medium">
                                                    {pricing[1]} Credits
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="pt-[25px] px-[25px] pb-[35px]">


                                                <div class="mt-[25px]">
                                                    <button class="bg-[#33434D] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold" onClick={() => changeDeal("deal2", pricing[1])}>Purchase</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="mt-[20px] grid grid-cols-2 gap-[20px]">
                                        <div key="1" class="w-full bg-[#fff] rounded-[10px] shadow-[0px 1px 2px #E1E3E5] border border-[#E1E3E5] divide-y">
                                            <div class="pt-[15px] px-[25px] pb-[25px]">


                                                <div>

                                                    <p class="text-[#00153B] text-[50px] leading-[63px] font-bold">
                                                        3 Days
                                                    </p>
                                                </div>

                                                <div>
                                                    <p class="text-[#717F87] text-[18px] leading-[28px] font-medium">
                                                    {pricing[2]}  Credits
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="pt-[25px] px-[25px] pb-[35px]">


                                                <div class="mt-[25px]">
                                                    <button class="bg-[#33434D] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold" onClick={() => changeDeal("deal3", pricing[2])}>Purchase</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div key="2" class="w-full bg-[#fff] rounded-[10px] shadow-[0px 1px 2px #E1E3E5] border border-[#E1E3E5] divide-y">
                                            <div class="pt-[15px] px-[25px] pb-[25px]">


                                                <div>

                                                    <p class="text-[#00153B] text-[50px] leading-[63px] font-bold">
                                                        4 Days
                                                    </p>
                                                </div>

                                                <div>
                                                    <p class="text-[#717F87] text-[18px] leading-[28px] font-medium">
                                                    {pricing[3]} Credits
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="pt-[25px] px-[25px] pb-[35px]">


                                                <div class="mt-[25px]">
                                                    <button class="bg-[#33434D] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold" onClick={() => changeDeal("deal4", pricing[3])}>Purchase</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            {tierLeft > 0 && tierLeft <= 3 ? <p style={{ marginTop: "5%", textAlign: "center", color: "red" }}>*Only {tierLeft} left at this price! Grab it Now!</p> : null}
                            
                        </div>

                        <div className='modal-footerNewProject'>
                            {/* {spinner == true ? <center><i class="fas fa-spinner fa-spin fa-lg" style={{ marginTop: "5%", marginBottom: "5%" }}></i></center> :
                                payClick == false ? <>
                                    <button className='boostBtn' onClick={() => proceedButton()}>Proceed</button>
                                    {targetListing != null ? targetListing.details.boostStart != null ? <button className='boostBtn' onClick={() => changeDate()}>Change Date</button> : null : null}
                                    <button className='boostBtn' onClick={() => proceedPromoCode()}>Promo Code</button>
                                    <div style={{ clear: 'both' }}></div>
                                </> :
                                    <div>
                                        <center>
                                           
                                        </center>
                                    </div>
                            } */}

                        </div>
                    </div>
                </div>
            </>
        );
    }

}