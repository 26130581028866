import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../css/NewProject.css';
import '../css/Boost.css';
import { UserAuth } from '../../AuthContent';
import timeunit from 'timeunit';
import GooglePayButton from "@google-pay/button-react";
import { db } from '../../firebase/firebase'
import { collection, doc, onSnapshot, getDocs, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import axios from 'axios';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import PaypalPayment from './PaypalPayment';

export default function NewProject(props) {
    const { user } = UserAuth();
    const [payClick, setPayClick] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(3);
    const [boostPeriod, setBoostPeriod] = useState(4);
    const [spinner, setSpinner] = useState(false);

    const proceedButton = () => {
        setPayClick(true);
    }

    const createOrder = (data, actions) => {

        // Order is created on the server and the order id is returned
        return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalOrder", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + user.accessToken
            },
            // use the "body" param to optionally pass additional order information
            // like product skus and quantities
            body: JSON.stringify({
                data: {
                    amount: 200,
                    currencyCode: "SGD",
                    intent: "CAPTURE"
                }
            }),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error("Failed to create order");
                }
            })
            .then((data) => {
                return data.result.orderID;
            })
            .catch((err) => {
                console.error(err);
            });

    };

    const onApprove = (data, actions) => {

        // Capture the funds from the transaction
        // return actions.order.capture().then(function (details) {
        // Send the order ID and captured details to your server
        setSpinner(true);
        return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/capturePayPalOrder", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + user.accessToken
            },
            body: JSON.stringify({
                data: {
                    orderID: data.orderID,
                    path: "addOns",

                    // capturedDetails: details,
                }
            }),
        })
            .then((response) => {

                return response.json();
            })
            .then(async (data) => {
                // Do something with the data here
                if (data.hasOwnProperty('result')) {

                    if (data.result.status == "COMPLETED") {
                        await updateDoc(doc(db, 'userInfo', user.uid),{
                            advancedDetails: props.currentUserInfo.advancedDetails + 10,
                            chatLeft: props.currentUserInfo.chatLeft + 10
                        }).then(() =>{
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 10;
                            newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 10;
                            setSpinner(false);
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            props.setTrigger(false);
                        })
                    }
                }
            })
            .catch((error) => {
                console.error('onapprove', error);
            });

        // });
    };

    const closeAddOns = async () => {

        setSpinner(false);
        setPayClick(false);
        props.setTrigger(false);
    }

    if (!props.trigger) {
        return;
    }
    else {
        return (
            <>
                <div className='modalNewProject'>
                    <div className='modalBoost'>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => { closeAddOns(); }}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>ADD ONS</h4>
                        </div>
                        <div className='modal-bodyNewProject' style={payClick ? { display: "none" } : { display: "block" }}>

                            <div style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "8px 8px" }}>
                                <div style={{ float: "left" }}>
                                    <p style={{ fontWeight: "bold", fontSize: "18px" }}>Addtional Deals</p>
                                    <p style={{ color: "grey", padding: "3px 8px" }}>10 Chat Connections</p>
                                    <p style={{ color: "grey", padding: "3px 8px" }}>10 Advanced Details</p>
                                </div>
                                <div style={{ float: "right", display: "flex", flexDirection: "row" }}>
                                    <p style={{ fontSize: "35px", paddingRight: "10px" }}>$200</p>
                                    <p style={{ padding: "5px 0px" }}>per add-on</p>
                                </div>
                                <div style={{ clear: 'both' }}></div>
                            </div>



                        </div>

                        <div className='modal-footerNewProject'>
                            {spinner == true ? <center><i class="fas fa-spinner fa-spin fa-lg" style={{ marginTop: "5%", marginBottom: "5%" }}></i></center> :
                                payClick == false ? <>
                                    <button className='boostBtn' onClick={() => proceedButton()}>Proceed</button>
                                    <div style={{ clear: 'both' }}></div>
                                </> :
                                    <div>
                                        <center>
                                            <PayPalScriptProvider
                                                options={{
                                                    "client-id": "AUj95SHnSm6iN1zjvQnpSFuwehgQqPmaO2ZX9ZGFdLX_uNVWVZMnKwedYOeTmDp8UzxlLtlclw1SCnP-",
                                                    currency: "SGD",
                                                    intent: "capture"
                                                }}
                                            >
                                                <PayPalButtons
                                                    createOrder={(data, actions) => createOrder(data, actions)}
                                                    onApprove={(data, actions) => onApprove(data, actions)}
                                                />
                                            </PayPalScriptProvider>
                                        </center>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </>
        );
    }

}