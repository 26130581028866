import React, { useState, useEffect, useRef } from 'react';
import '../css/Details.css';
import { UserAuth } from '../../AuthContent';
import { useNavigate } from 'react-router-dom';

import Footer from '../Footer';


export default function NotAvailable() {





  return (
    <>
      
    <div style={{marginTop:"15%", marginBottom:"15%"}}>
      <center><h3>Page Not Found</h3></center>
    </div>
   
  
      <Footer />

    </>
  )
}