import React, { useEffect, useState, useRef } from 'react';
import { UserAuth } from '../../AuthContent';
import { db, storage } from '../../firebase/firebase';
import { setDoc, doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import '../css/ThirdParty.css'
import { jsPDF } from "jspdf";
import SignaturePad from 'signature_pad';
import LOI from './LOI';
import '../css/PLServices.css';
import LoaderPage from './LoaderPage';
// import PDFDocument from 'pdfkit'
// import fs from 'fs'

export default function PLServices(props) {
    const { user } = UserAuth();
    const [propertyAddressDataList, setPropertyAddressDataList] = useState(null);
    const [generateLOI, setGenerateLOI] = useState(false);
    const [LOIDone, setLOIDone] = useState(false);
    const [LOIPopup, setLOIPopup] = useState(false);
    const [agree, setAgree] = useState(false);
    var currentPage = useRef(0);
    const [openLoader, setOpenloader] = useState(false);
    const [natureOfBus, setNatureOfBus] = useState(null);

    useEffect(() => {
        if (!props.trigger) {
            return;
        }
        else {

            // callOfferedListingData();

        }
    }, [user, props.trigger, props.navID])

    const callOfferedListingData = async () => {
        var data = await getDoc(doc(db, 'offeredListing', props.chatID));
        console.log(data.data());
        if (data.data().LOI == true) {
            setLOIDone(true);
        }
    }

    // const approvedLOI = () => {
    //     return new Promise(function (resolve, reject) {
    //         const PDFdoc = new jsPDF();

    //         // add content to the PDF
    //         PDFdoc.setFont("helvetica", "bold");
    //         PDFdoc.setFontSize(12);
    //         PDFdoc.setTextColor(0);
    //         let textWidth = PDFdoc.getStringUnitWidth("LETTER OF INTENT") * PDFdoc.internal.getFontSize() / PDFdoc.internal.scaleFactor;
    //         let x = (PDFdoc.internal.pageSize.width - textWidth) / 2;
    //         PDFdoc.text("LETTER OF INTENT", x, 10);

    //         PDFdoc.setFont("helvetica", "normal");
    //         PDFdoc.text("This non binding document made on the ______ day of _______________ 20____ is intended to outline", 10, 20);
    //         PDFdoc.text("the terms under which ________________________________________________________", 10, 30);
    //         PDFdoc.text("NRIC /FIN/Company Registration No. ________________________________________ contact details", 10, 40);
    //         PDFdoc.text("____________________________________ hereinafter called ' the Tenant ' of the one part would", 10, 50);
    //         PDFdoc.text("be willing to pursue negotiation with _____________________________________________ ", 10, 60);
    //         PDFdoc.text("NRIC /FIN/Company Registration No. __________________________________ contact details", 10, 70);
    //         PDFdoc.text("_________________________________________ hereinafter called ' the Landlord ' of the", 10, 80);
    //         PDFdoc.text("other part in regards to the property known as ______________________________________", 10, 90);
    //         const pdfBlob = new Blob([PDFdoc.output("blob")], { type: "application/pdf" });

    //         uploadBytes(ref(storage, `LOI/${user.uid}/LOI_${props.chatID}.pdf`), pdfBlob).then((snapshot) => {
    //             console.log(snapshot);
    //             console.log("uploaded");
    //             getDownloadURL(ref(storage, `LOI/${user.uid}/LOI_${props.chatID}.pdf`)).then(async (url) => {
    //                 const newTab = window.open(url, "_blank");
    //                 setLOIDone(true);
    //                 await updateDoc(doc(db, "offeredListing", props.chatID), {
    //                     LOI: true
    //                 })
    //                 resolve(url);
    //             });
    //         });
    //     })
    // }

    // const disapprovedLOI = () => {
    //     console.log("cannot LOI");
    //     setGenerateLOI(false);
    // }

    // const activateLOI = async () => {
    //     // 

    //     const doc = new jsPDF();

    //     // add a watermark to the PDF
    //     //for(var i = 0; i<10; i++){
    //     doc.setFont("helvetica");
    //     doc.setFontSize(150);
    //     doc.setTextColor(200);
    //     // doc.rotate(45, { origin: [75, 75] });
    //     //doc.text("WATERMARK", 75, 75);
    //     //doc.text("Next Loop", 35, i*30);
    //     //doc.text("Next Loop", 85, i*30);
    //     doc.text(30, 50, 'Next Loop', null, -60);
    //     //doc.rotate(-45, { origin: [75, 75] });
    //     //}


    //     // add content to the PDF
    //     // doc.setFont("helvetica", "bold");
    //     // doc.setFontSize(12);
    //     // doc.setTextColor(0);
    //     // let textWidth = doc.getStringUnitWidth("LETTER OF INTENT") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    //     // let x = (doc.internal.pageSize.width - textWidth) / 2;
    //     // doc.text("LETTER OF INTENT", x, 10);

    //     // doc.setFont("helvetica", "normal");
    //     // doc.text("This non binding document made on the ______ day of _______________ 20____ is intended to outline", 10, 20);
    //     // doc.text("the terms under which ________________________________________________________", 10, 30);
    //     // doc.text("NRIC /FIN/Company Registration No. ________________________________________ contact details", 10, 40);
    //     // doc.text("____________________________________ hereinafter called ' the Tenant ' of the one part would", 10, 50);
    //     // doc.text("be willing to pursue negotiation with _____________________________________________ ", 10, 60);
    //     // doc.text("NRIC /FIN/Company Registration No. __________________________________ contact details", 10, 70);
    //     // doc.text("_________________________________________ hereinafter called ' the Landlord ' of the", 10, 80);
    //     // doc.text("other part in regards to the property known as ______________________________________", 10, 90);

    //     doc.addField('Signature', {
    //         type: 'signature',
    //         page: 1,
    //         name: 'signatureField',
    //         x: 100,
    //         y: 200,
    //         w: 200,
    //         h: 50
    //     });
    //     doc.save('signed.pdf');

    //     // const newTab = window.open("", "_blank");
    //     // newTab.document.write("<iframe width='100%' height='100%' src='" + doc.output('datauristring') + "'></iframe>");
    //     // setGenerateLOI(true);
    //     // doc.save("a4.pdf");
    //     // const output = new PDFDocument;
    //     // output.addPage()


    //     // theOutput.fontSize(27).text("this is the articale",100,100);
    //     // theOutput.end();
    // }



    const nextPage = () => {
        console.log(currentPage.current);
        if (currentPage.current + 1 == 1) {
            setNatureOfBus(document.getElementById("PL_Dropdown").value);
            console.log(document.getElementById("PL_Dropdown").value);
            document.getElementById(`PLPage${currentPage.current}`).style.display = "none"
            document.getElementById(`PLPage${currentPage.current + 1}`).style.display = "inline"
        }
        else if (currentPage.current + 1 == 2) {
            document.getElementById(`PLPage${currentPage.current}`).style.display = "none"
            document.getElementById(`PLPage${currentPage.current + 1}`).style.display = "inline"
        }
        else if (currentPage.current + 1 == 3) {
            document.getElementById(`PLPage${currentPage.current}`).style.display = "none"
            document.getElementById(`PLPage${currentPage.current + 1}`).style.display = "inline"
            document.getElementById("PLServicesButton").textContent = "Checkout"
            document.getElementById("PLServicesButton").onclick = activateCheckout;
        }

        currentPage.current = currentPage.current + 1;

    }

    const activateCheckout = async () => {
        console.log("checkout");
        setOpenloader(true);
        var typeOfPL = document.querySelectorAll('.radioButtonInput');
        var PLType = null;
        for (var i = 0; i < typeOfPL.length; i++) {
            if (typeOfPL[i].checked == true) {
                PLType = typeOfPL[i].value;
            }
        }
        await setDoc(doc(db, `ThirdPartyServices`, document.getElementById("UEN").value), {
            PL: serverTimestamp(),
            UID: user.uid,
            PLType: PLType
        });
        const token = user.accessToken;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify({ data: {} })
        };
        await fetch('http://localhost:5000/next-loop-57e04/asia-southeast1/PlEmail', requestOptions).then(data => console.log(data)).then(
            error => console.log(error)
        );
        setOpenloader(false);
        alert("DONE! this could be better position. this is just for confirmation");
        props.setTrigger(false);
    }

    const increment = () => {
        const formatter = new Intl.NumberFormat('en-US');
        var input = document.getElementById('quantity');
       input.innerHTML = Number(input.innerHTML) + 1;
       var currentTotal = document.getElementById("currentTotal");
       console.log(currentTotal);
       console.log(Number(currentTotal.innerHTML.replace(/[$,]/g,"")));
       currentTotal.innerHTML = "$" + (Number(currentTotal.innerHTML.replace(/[$,]/g,"")) + (input.innerHTML * 250000)).toLocaleString();
      }
      
    const decrement = () => {
        var input = document.getElementById('quantity');
        if(Number(input.innerHTML) > 0){
        console.log("minus");
        input.innerHTML = Number(input.innerHTML) - 1;
        }
      }



    if (!props.trigger) {
        return;
    }
    else {


        return (

            <>

                <LoaderPage trigger={openLoader} setTrigger={setOpenloader} />
                <div className='modalNewProject' style={{ zIndex: "2" }}>
                    <div className='modal-contentNewProject' >
                        <div className='modal-headerNewProject'>
                            <h4 className='modal-titleNewProject'>Title of this services</h4>
                        </div>
                        <div className='modal-bodyNewProject'>
                            {agree == false ? <div>
                                <p>RULES</p>
                            </div> :
                                <div id="PLPage0">

                                    {/* <div id="PLServicesLeft">
                                        <center>
                                            <label className='radioButtonPL' for="Basic">Basic</label><br></br>
                                            <input className='radioButtonInput' type="radio" id="Basic" name="addtionalInfo" value="Basic" />
                                        </center>
                                    </div>
                                    <div id="PLServicesRight">
                                        <center>
                                            <label className='radioButtonPL' for="Comprehensive">Comprehensive</label><br></br>
                                            <input className='radioButtonInput' type="radio" id="Comprehensive" name="addtionalInfo" value="Comprehensive" />
                                        </center>
                                    </div> */}
                                    <label className='PLLabel'>UEN: </label><input type="text" className='PLinput' id="UEN"></input>
                                    <br></br>
                                    <label className='PLLabel'>Company Name: </label><input type="text" className='PLinput' ></input>
                                    <br></br>
                                    <label className='PLLabel'>Nature of Business: </label>
                                    <select className='PL-dropdown' name="PL-dropdown" id="PL_Dropdown">
                                        <option value="Office">Office</option>
                                        <option value="F&B">F&B</option>
                                        <option value="option3">Option 3</option>
                                    </select>
                                    <br></br>

                                    <input className='PLRadio' type="radio" id="Facility" name="PLRoute" value="Facility" />
                                    <label className='PLOption' for="Facility">Facility</label>
                                    <input className='PLRadio' type="radio" id="Event" name="PLRoute" value="Event" />
                                    <label className='PLOption' for="Event">Event</label>
                                    <input className='PLRadio' type="radio" id="CAR" name="PLRoute" value="CAR" />
                                    <label className='PLOption' for="CAR">CAR</label>


                                </div>
                            }

                            <div id="PLPage1" style={{ display: "none" }}>
                                <label className='PLLabel'>Location of Risk: </label><input type="text" className='PLinput' ></input>
                                <br></br>
                                <label className='PLLabel'>Postal Code: </label><input type="text" className='PLinput' ></input>
                                <br></br>
                                <label className='PLLabel'>Blk Number: </label><input type="text" className='PLinput' ></input>
                                <br></br>
                                <label className='PLLabel'>Street Name: </label><input type="text" className='PLinput' ></input>
                                <br></br>
                                <label className='PLLabel'>Unit Number: </label><input type="text" className='PLinput' ></input>
                                <br></br>
                                {/* Office  */}
                                {natureOfBus == null || natureOfBus == "Office" ?
                                <>
                                <label className='PLQuestion'>Question 1: Is the insured premises constructed of brick, tile or concrete? </label>
                                <br></br>
                                <input className='PLRadio' type="radio" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Yes</label><br></br>
                                <input className='PLRadio' type="radio" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">No</label><br></br>
                                <br></br>
                                <label className='PLQuestion'>Question 2: Does the insured solely occupied the insured premises?</label>
                                <br></br>
                                <input className='PLCheckbox' type="radio" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Yes</label><br></br>
                                <input className='PLCheckbox' type="radio" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">No</label><br></br>
                                <label className='PLOption' for="Basic">If "No", please state the business of neighbour:</label><input type="number" className='postalCodeInput' ></input><br></br>
                                <br></br>
                                <label className='PLQuestion'>Question 3: Please tick on the appropriate fire preventive & security system in the premises?</label>
                                <br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Fire Preventive Systems</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Fire Alarm System</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Fire Extinguisher</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Sprinkler System</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Fire Hose Reel</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Security Systems</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">CCTV</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Burglary Alarm System</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Grilled Windows/Doors</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">24-hour Security Guard</label><br></br>
                                <label className='PLOption' for="Basic">Others (Please give details):</label><input type="number" className='postalCodeInput' ></input><br></br>
                                <label className='PLQuestion'>Question 4: What is the type of property for the location of the insured property?</label>
                                <br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Commercial Building</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Industrial Building</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Shopping Mall</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">Pre-war Building</label><br></br>
                                <input className='PLCheckbox' type="checkbox" id="Basic" name="addtionalInfo" value="Basic" />
                                <label className='PLOption' for="Basic">HDB Shop</label><br></br>
                                <label className='PLOption' for="Basic">Others (Please give details):</label><input type="number" className='postalCodeInput' ></input><br></br></>
                                : natureOfBus == "F&B" ? <> 
                                <p>NOT OUT YET F&B</p>  
                                </> : null
                                
                                }
                                <label className='PLFinishingLabel'>Amount:</label><p style={{ display: "inline", fontSize: "18px" }}>$500,000 (SGD)</p>
                                <br></br>
                                <label>Do you need more coverage? <b>$50 for every $250,000</b></label>
                                <div class="input-group">
                                    <button type="button" class="btn btn-secondary" onClick={() => decrement()}>-</button>
                                    <label  id="quantity" name="quantity">0</label>
                                    <button type="button" class="btn btn-secondary" onClick={() => increment()}>+</button>
                                </div>
                                <br></br>
                                <label style={{fontWeight:"bold"}}>Current Total: </label><p style={{ display: "inline", fontSize: "18px" }} id="currentTotal">$500,000</p>
                            </div>
                            <div id="PLPage2" style={{ display: "none" }}>
                                <label>Policy Price: </label><input type="number" className='postalCodeInput' ></input>
                                <br></br>
                                <label>Contact Number: </label><input type="number" className='postalCodeInput' ></input>
                                <br></br>
                                <label>Name of person: </label><input type="number" className='postalCodeInput' ></input>
                                <br></br>
                                <label>Email: </label><input type="number" className='postalCodeInput' ></input>
                            </div>
                            <div id="PLPage3" style={{ display: "none" }}>
                                <h4>Adds On</h4>
                                <p>CAR</p>
                                <p>FIRE</p>
                            </div>

                        </div>
                        <div style={{ clear: 'both' }}></div>
                        <div className='modal-footerNewProject'>
                            {agree == false ? <button className='btn--general' onClick={() => setAgree(true)}>Agree</button> : <button id="PLServicesButton" className='btn--general' onClick={() => nextPage()}>Proceed</button>}
                            <button className='btn--general' onClick={() => { props.setTrigger(false); currentPage.current = 0; setAgree(false) }}>Close</button>

                        </div>
                    </div>
                </div>
            </>
        );
    }

}