import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../css/NewProject.css';
import '../css/Boost.css'
import { UserAuth } from '../../AuthContent';
import timeunit from 'timeunit';
import { db } from '../../firebase/firebase'
import { collection, doc, onSnapshot, getDocs, updateDoc, setDoc } from 'firebase/firestore';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import PaypalPayment from './PaypalPayment';
import AcknowledgeNormal from '../../AcknowledgeNormal';


export default function NewProject(props) {
    const { user } = UserAuth();
    const [payClick, setPayClick] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(3);
    const [boostPeriod, setBoostPeriod] = useState(4);
    const [tierLeft, setTierLeft] = useState(1);
    const [pricing, setPricing] = useState([]);
    const [previousTier, setPreviousTier] = useState(null);
    const [spinner, setSpinner] = useState(false);
    const [allTierData, setAllTierData] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
    const [messages, setMessages] = useState("");

    useEffect(() => {

        const docRef = doc(db, 'commonData', 'boostInfo');

        const unsubscribe = onSnapshot(docRef, (doc) => {
            setAllTierData(doc.data());
            // Do something with the data
            if (doc.data().tier1 != 0) {

                setTierLeft(doc.data().tier1);
                setPricing([2, 3, 5, 4])
            }
            else if (doc.data().tier2 != 0) {

                setTierLeft(doc.data().tier2)
                setPricing([3, 4, 6, 5])
            }
            else if (doc.data().tier3 != 0) {

                setTierLeft(doc.data().tier3)
                setPricing([4, 5, 7, 6])
            }
            else {

                setTierLeft(-1);
                setPricing([5, 6, 8, 7])
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (pricing.length != 0) {
            if (props.accountView == "Commercial") {
                setPaymentAmount(Math.ceil(pricing[3] * props.commercialRentAvailableNumber * (1 - discount)));
            }
            else {
                setPaymentAmount(Math.ceil(pricing[3] * props.userResidentialRentAvailableNumber * (1 - discount)));
            }

        }
    }, [discount, pricing, props])

    // useEffect(() => {
    //     if (props.trigger == true) {
    //         const elements = document.getElementsByClassName('modal-bodyNewProject');
    //         if (payClick == true) {
    //             Array.from(elements).forEach(element => element.style.display = "none");
    //         }
    //         else {
    //             Array.from(elements).forEach(element => element.style.display = "block");
    //         }
    //         // if (props.accountView == "Commercial") {
    //         //     if (props.commercialRentAvailableNumber >= 6) {

    //         //         setDiscount(0.3);
    //         //     }
    //         //     else if (props.commercialRentAvailableNumber == 5) {

    //         //         setDiscount(0.25);
    //         //     }
    //         //     else if (props.commercialRentAvailableNumber == 4) {

    //         //         setDiscount(0.2);
    //         //     }
    //         //     else if (props.commercialRentAvailableNumber == 3) {

    //         //         setDiscount(0.15);
    //         //     }
    //         //     else if (props.commercialRentAvailableNumber == 2) {

    //         //         setDiscount(0.1);
    //         //     }
    //         //     else if (props.commercialRentAvailableNumber == 1) {
    //         //         // setPaymentAmount(Math.floor(pricingAmount* props.userProject.length * (1-0.05)));
    //         //         setDiscount(0.05);
    //         //     }
    //         //     else if (props.commercialRentAvailableNumber == 0) {
    //         //         // setPaymentAmount(Math.floor(pricingAmount* props.userProject.length * (1-0)));
    //         //         setDiscount(0);
    //         //     }
    //         // }
    //         // else {
    //         //     if (props.userResidentialRentAvailableNumber >= 6) {

    //         //         setDiscount(0.3);
    //         //     }
    //         //     else if (props.userResidentialRentAvailableNumber == 5) {

    //         //         setDiscount(0.25);
    //         //     }
    //         //     else if (props.userResidentialRentAvailableNumber == 4) {

    //         //         setDiscount(0.2);
    //         //     }
    //         //     else if (props.userResidentialRentAvailableNumber == 3) {

    //         //         setDiscount(0.15);
    //         //     }
    //         //     else if (props.userResidentialRentAvailableNumber == 2) {

    //         //         setDiscount(0.1);
    //         //     }
    //         //     else if (props.userResidentialRentAvailableNumber == 1) {
    //         //         // setPaymentAmount(Math.floor(pricingAmount* props.userProject.length * (1-0.05)));
    //         //         setDiscount(0.05);
    //         //     }
    //         //     else if (props.userResidentialRentAvailableNumber == 0) {
    //         //         // setPaymentAmount(Math.floor(pricingAmount* props.userProject.length * (1-0)));
    //         //         setDiscount(0);
    //         //     }
    //         // }

    //     }


    // }, [payClick, props])

    if (!props.trigger) {
        return;
    }
    else {


        const proceedButton = async (boostPeriodVar, pricingAmountVar) => {

            if (props.accountView == "Commercial") {
                if (props.userProject.length == 0) {
                    alert("No available listing");
                    return;
                }
            }
            else {
                if (props.userResidentialListing.length == 0) {
                    alert("No available listing");
                    return;
                }
            }
            var start = new Date(document.getElementById('BoostPeriodFrom').value);
            var today = new Date();

            var yesterday = new Date(today);
            start = new Date(Date.parse(start));
            today = new Date(Date.parse(today));
            start.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);
            yesterday.setDate(today.getDate() - 1);
            if (start < yesterday || start == "Invalid Date") {
                alert("You have enter an invalid date");
                return;
            }
            else if (props.currentUserInfo.credit < pricingAmountVar) {
                alert("Not enough credit");
                return;
            } else {

                setSpinner(true);
                // setPayClick(!payClick);

                if (pricing[0] == 1) {
                    await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                        tier1: allTierData.tier1 - 1
                    })
                    setPreviousTier('tier1')
                }
                else if (pricing[0] == 2) {
                    await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                        tier2: allTierData.tier2 - 1
                    })
                    setPreviousTier('tier2')
                }

                else if (pricing[0] == 3) {
                    await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                        tier3: allTierData.tier3 - 1
                    })
                    setPreviousTier('tier3')

                }
                else {
                    setPreviousTier('tier4')
                }
                if (props.accountView == "Commercial") {
                    var userProject = props.userProject;
                    for (var i = 0; i < props.userProject.length; i++) {
                        if (userProject[i].details.status == "leasing") {
                            if (start.getTime() === today.getTime()) {
                                if (userProject[i].details.boostStart == null) {

                                    await updateDoc(doc(db, 'projects', userProject[i].id), {
                                        boost: true,
                                        boostStart: start,
                                        boostLeft: boostPeriodVar
                                    })
                                }
                                else {
                                    await updateDoc(doc(db, 'projects', userProject[i].id), {

                                        boostLeft: userProject[i].details.boostLeft + boostPeriodVar
                                    })

                                }

                            }
                            else {
                                if (userProject[i].details.boostStart == null) {
                                    await updateDoc(doc(db, 'projects', userProject[i].id), {
                                        boost: false,
                                        boostStart: start,
                                        boostLeft: boostPeriodVar
                                    })

                                }
                                else {
                                    await updateDoc(doc(db, 'projects', userProject[i].id), {

                                        boostLeft: userProject[i].details.boostLeft + boostPeriodVar
                                    })

                                }
                            }
                        }

                        if (i == props.userProject.length - 1) {
                            // props.setUserProject(userProject);
                            setSpinner(false);
                        }
                    }
                }
                else {
                    var userProject = props.userResidentialListing;
                    for (var i = 0; i < props.userProject.length; i++) {
                        if (userProject[i].details.status == "leasing") {
                            if (start.getTime() === today.getTime()) {
                                if (userProject[i].details.boostStart == null) {

                                    await updateDoc(doc(db, 'residentialListing', userProject[i].id), {
                                        boost: true,
                                        boostStart: start,
                                        boostLeft: boostPeriodVar
                                    })
                                }
                                else {
                                    await updateDoc(doc(db, 'residentialListing', userProject[i].id), {

                                        boostLeft: userProject[i].details.boostLeft + boostPeriodVar
                                    })
                                }

                            }
                            else {
                                if (userProject[i].details.boostStart == null) {
                                    await updateDoc(doc(db, 'residentialListing', userProject[i].id), {
                                        boost: false,
                                        boostStart: start,
                                        boostLeft: boostPeriodVar
                                    })
                                }
                                else {
                                    await updateDoc(doc(db, 'residentialListing', userProject[i].id), {

                                        boostLeft: userProject[i].details.boostLeft + boostPeriodVar
                                    })
                                }
                                // props.setUserProject(userProject);
                                // setSpinner(false);
                            }
                        }

                        if (i == props.userProject.length - 1) {
                            // props.setUserResidentialListing(userProject);
                            setSpinner(false);
                        }
                    }
                }
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    credit: props.currentUserInfo.credit - pricingAmountVar
                }).then(() => {
                    setPayClick(false);
                    setPaymentAmount(4);
                    setBoostPeriod(4);
                    setPreviousTier(null);
                    setMessages("Payment Successful");
                    setAcknowledgeNormal(true);
                    // props.setTrigger(false);
                    window.location.reload(true);
                })


            }

        }


        // const createOrder = (data, actions) => {
        //     var start = new Date(document.getElementById('BoostPeriodFrom').value);
        //     var today = new Date();

        //     var yesterday = new Date(today);
        //     start = new Date(Date.parse(start));
        //     today = new Date(Date.parse(today));
        //     start.setHours(0, 0, 0, 0);
        //     today.setHours(0, 0, 0, 0);
        //     yesterday.setDate(today.getDate() - 1);
        //     if (start < yesterday || start == "Invalid Date") {
        //         alert("You have enter an invalid date");
        //         return;
        //     }
        //     else {
        //         // Order is created on the server and the order id is returned
        //         // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/createPayPalOrder
        //         // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalOrder
        //         return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalOrder", {
        //             method: "POST",
        //             headers: {
        //                 "Content-Type": "application/json",
        //                 'Authorization': 'Bearer ' + user.accessToken
        //             },
        //             // use the "body" param to optionally pass additional order information
        //             // like product skus and quantities
        //             body: JSON.stringify({
        //                 data: {
        //                     amount: paymentAmount,
        //                     currencyCode: "SGD",
        //                     intent: "CAPTURE"
        //                 }
        //             }),
        //         })
        //             .then((res) => {
        //                 if (res.ok) {
        //                     return res.json();
        //                 } else {
        //                     throw new Error("Failed to create order");
        //                 }
        //             })
        //             .then((data) => {
        //                 return data.result.orderID;
        //             })
        //             .catch((err) => {
        //                 console.error(err);
        //             });
        //     }
        // };

        // const onApprove = (data, actions) => {
        //     var start = new Date(document.getElementById('BoostPeriodFrom').value);
        //     var today = new Date();

        //     var yesterday = new Date(today);
        //     start = new Date(Date.parse(start));
        //     today = new Date(Date.parse(today));
        //     start.setHours(0, 0, 0, 0);
        //     today.setHours(0, 0, 0, 0);
        //     yesterday.setDate(today.getDate() - 1);
        //     if (start < yesterday || start == "Invalid Date") {
        //         alert("You have enter an invalid date");
        //         return;
        //     }
        //     else {
        //         // Capture the funds from the transaction
        //         // return actions.order.capture().then(function (details) {
        //         // Send the order ID and captured details to your server
        //         // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/capturePayPalOrder
        //         // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/capturePayPalOrder
        //         setSpinner(true);
        //         return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/capturePayPalOrder", {
        //             method: "POST",
        //             headers: {
        //                 "Content-Type": "application/json",
        //                 'Authorization': 'Bearer ' + user.accessToken
        //             },
        //             body: JSON.stringify({
        //                 data: {
        //                     orderID: data.orderID,
        //                     path: "boostAll",
        //                     boostFor: props.accountView == "Commercial" ? "commercialRent": "residentialRent",
        //                     boostPeriod: boostPeriod,
        //                     startDate: start,
        //                     today: today.getTime()

        //                     // capturedDetails: details,
        //                 }
        //             }),
        //         })
        //             .then((response) => {

        //                 return response.json();
        //             })
        //             .then(async (data) => {
        //                 // Do something with the data here
        //                 if (data.hasOwnProperty('result')) {

        //                     if (data.result.status == "COMPLETED") {
        //                         await setDoc(doc(db, 'payment', data.result.id), {
        //                             payer: data.result.payer,
        //                             amount: data.result.purchase_units
        //                         }).then(() => {

        //                             if (props.accountView == "Commercial") {
        //                                 var userProject = props.userProject;
        //                                 for (var i = 0; i < props.userProject.length; i++) {
        //                                     if (userProject[i].details.status == "leasing") {
        //                                         if (start.getTime() === today.getTime()) {
        //                                             if(userProject[i].details.boostStart == null){
        //                                                 userProject[i].details.boost = true;
        //                                                 userProject[i].details.boostStart = start;
        //                                                 userProject[i].details.boostLeft = boostPeriod;
        //                                             }
        //                                             else{
        //                                                 userProject[i].details.boostLeft = userProject[i].details.boostLeft + boostPeriod;
        //                                             }

        //                                         }
        //                                         else {
        //                                             if(userProject[i].details.boostStart == null){
        //                                                 userProject[i].details.boost = false;
        //                                                 userProject[i].details.boostStart = start;
        //                                                 userProject[i].details.boostLeft = boostPeriod;
        //                                             }
        //                                             else{
        //                                                 userProject[i].details.boostLeft = userProject[i].details.boostLeft + boostPeriod;
        //                                             }

        //                                             // props.setUserProject(userProject);
        //                                             // setSpinner(false);
        //                                         }
        //                                     }

        //                                     if (i == props.userProject.length - 1) {
        //                                         props.setUserProject(userProject);
        //                                         setSpinner(false);
        //                                     }
        //                                 }
        //                             }
        //                             else {
        //                                 var userProject = props.userResidentialListing;
        //                                 for (var i = 0; i < props.userProject.length; i++) {
        //                                     if (userProject[i].details.status == "leasing") {
        //                                         if (start.getTime() === today.getTime()) {
        //                                             userProject[i].details.boost = true;
        //                                             userProject[i].details.boostStart = start;
        //                                             userProject[i].details.boostLeft = boostPeriod;
        //                                             // props.setUserProject(userProject);
        //                                             // setSpinner(false);
        //                                         }
        //                                         else {
        //                                             userProject[i].details.boost = false;
        //                                             userProject[i].details.boostStart = start;
        //                                             userProject[i].details.boostLeft = boostPeriod;
        //                                             // props.setUserProject(userProject);
        //                                             // setSpinner(false);
        //                                         }
        //                                     }

        //                                     if (i == props.userProject.length - 1) {
        //                                         props.setUserResidentialListing(userProject);
        //                                         setSpinner(false);
        //                                     }
        //                                 }
        //                             }


        //                             setPayClick(false);
        //                             setPaymentAmount(3);
        //                             setBoostPeriod(4);
        //                             setPreviousTier(null);

        //                             props.setTrigger(false);
        //                             window.location.reload(true);

        //                         })

        //                     }

        //                 }
        //                 else if (data.hasOwnProperty('error')) {
        //                     alert('Payment Declined');
        //                     closeBoost();
        //                 }
        //             })
        //             .catch((error) => {
        //                 console.error('onapprove', error);
        //             });
        //     }
        //     // });
        // };

        const changeDeal = (targetDiv, pricingAmount) => {
            // document.getElementById("deal1").className = "eachBoostOffer";
            // document.getElementById("deal2").className = "eachBoostOffer";
            // document.getElementById("deal3").className = "eachBoostOffer";
            // document.getElementById("deal4").className = "eachBoostOffer";
            // document.getElementById(targetDiv).className = "eachBoostOfferSelected";
            if (props.accountView == "Commercial") {
                if (props.commercialRentAvailableNumber != 0) {
                    setPaymentAmount(Math.ceil(pricingAmount * props.commercialRentAvailableNumber * (1 - discount)));
                }
                else {
                    setPaymentAmount(pricingAmount);
                }
            }
            else {
                if (props.userResidentialRentAvailableNumber != 0) {
                    setPaymentAmount(Math.ceil(pricingAmount * props.userResidentialRentAvailableNumber * (1 - discount)));
                }
                else {
                    setPaymentAmount(pricingAmount);
                }
            }


            if (targetDiv == "deal1") {
                // setTierLeft(allTierData.tier1);
                setBoostPeriod(1);
                proceedButton(1, pricingAmount);
            }
            else if (targetDiv == "deal2") {
                // setTierLeft(allTierData.tier2);
                setBoostPeriod(2);
                proceedButton(2, pricingAmount);
            }
            else if (targetDiv == "deal3") {
                // setTierLeft(allTierData.tier3);
                setBoostPeriod(3);
                proceedButton(3, pricingAmount);
            }
            else if (targetDiv == "deal4") {
                // setTierLeft(-1);
                setBoostPeriod(4);
                proceedButton(4, pricingAmount);
            }

        }

        const closeBoost = async () => {
            if (pricing[0] == 3 && previousTier == 'tier1') {
                await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                    tier1: tierLeft + 1
                })
            }
            else if (pricing[0] == 4 && previousTier == 'tier2') {
                await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                    tier2: tierLeft + 1
                })
            }

            else if (pricing[0] == 5 && previousTier == 'tier3') {
                await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                    tier3: tierLeft + 1
                })

            }
            setPayClick(false);
            setPaymentAmount(3);
            setBoostPeriod(4);
            setPreviousTier(null);
            setSpinner(false);
            props.setTrigger(false);
        }
        return (
            <>
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={messages} tierLeft={tierLeft}></AcknowledgeNormal>

                <div className='modalNewProject'>
                    <div className='modalBoost' style={{ minWidth: "40%" }}>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => closeBoost()}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>BOOST ALL LISTINGS</h4>
                        </div>
                        <div className='modal-bodyNewProject' >
                            <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", padding: "8px 8px", borderRadius: "10px", marginBottom: "3%" }}>
                                <i class="fa-solid fa-exclamation" style={{ color: "#33434d", display: "flex", alignItems: "center", fontSize: "25px", marginRight: "2%" }}></i>

                                <div style={{ display: "flex", textAlign: "left", flexDirection: "column" }}>
                                    <p> If your listing has already been boosted, any additional boosts will simply extend the current boost period. To modify the date, kindly click on the boost button for the specific listing.</p>
                                </div>
                            </div>
                            <p className='modalLabelBoost'>Start: </p>
                            <input className='newListingInputBoost' type="date" id="BoostPeriodFrom"></input>
                            <div class='flex '>
                                <div class="min-w-full">


                                    <div class="mt-[20px] grid grid-cols-2 gap-[20px]">
                                        <div key="1" class="w-full bg-[#fff] rounded-[10px] shadow-[0px 1px 2px #E1E3E5] border border-[#E1E3E5] divide-y">
                                            <div class="pt-[15px] px-[25px] pb-[25px]">


                                                <div>

                                                    <p class="text-[#00153B] text-[50px] leading-[63px] font-bold">
                                                        1 Day
                                                    </p>
                                                </div>

                                                <div>
                                                    <p class="text-[#717F87] text-[18px] leading-[28px] font-medium">
                                                        {props.accountView == "Commercial" ? props.commercialRentAvailableNumber != 0 ? Math.ceil(pricing[0] * props.commercialRentAvailableNumber * (1 - discount)) : pricing[0]
                                                            : props.userResidentialRentAvailableNumber != 0 ? Math.ceil(pricing[0] * props.userResidentialRentAvailableNumber * (1 - discount)) : pricing[0]} Credits
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="pt-[25px] px-[25px] pb-[35px]">
                                                <div class="mt-[25px]">
                                                    <button class="bg-[#33434D] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold" onClick={() => changeDeal("deal1", pricing[0])}>Purchase</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div key="2" class="w-full bg-[#fff] rounded-[10px] shadow-[0px 1px 2px #E1E3E5] border border-[#E1E3E5] divide-y">
                                            <div class="pt-[15px] px-[25px] pb-[25px]">


                                                <div>

                                                    <p class="text-[#00153B] text-[50px] leading-[63px] font-bold">
                                                        2 Days
                                                    </p>
                                                </div>

                                                <div>
                                                    <p class="text-[#717F87] text-[18px] leading-[28px] font-medium">
                                                        {props.accountView == "Commercial" ? props.commercialRentAvailableNumber != 0 ? Math.ceil(pricing[1] * props.commercialRentAvailableNumber * (1 - discount)) : pricing[1]
                                                            : props.userResidentialRentAvailableNumber != 0 ? Math.ceil(pricing[1] * props.userResidentialRentAvailableNumber * (1 - discount)) : pricing[1]} Credits
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="pt-[25px] px-[25px] pb-[35px]">


                                                <div class="mt-[25px]">
                                                    <button class="bg-[#33434D] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold" onClick={() => changeDeal("deal2", pricing[1])}>Purchase</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="mt-[20px] grid grid-cols-2 gap-[20px]">
                                        <div key="1" class="w-full bg-[#fff] rounded-[10px] shadow-[0px 1px 2px #E1E3E5] border border-[#E1E3E5] divide-y">
                                            <div class="pt-[15px] px-[25px] pb-[25px]">


                                                <div>

                                                    <p class="text-[#00153B] text-[50px] leading-[63px] font-bold">
                                                        3 Days
                                                    </p>
                                                </div>

                                                <div>
                                                    <p class="text-[#717F87] text-[18px] leading-[28px] font-medium">
                                                        {props.accountView == "Commercial" ? props.commercialRentAvailableNumber != 0 ? Math.ceil(pricing[2] * props.commercialRentAvailableNumber * (1 - discount)) : pricing[2]
                                                            : props.userResidentialRentAvailableNumber != 0 ? Math.ceil(pricing[2] * props.userResidentialRentAvailableNumber * (1 - discount)) : pricing[2]} Credits
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="pt-[25px] px-[25px] pb-[35px]">


                                                <div class="mt-[25px]">
                                                    <button class="bg-[#33434D] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold" onClick={() => changeDeal("deal3", pricing[2])}>Purchase</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div key="2" class="w-full bg-[#fff] rounded-[10px] shadow-[0px 1px 2px #E1E3E5] border border-[#E1E3E5] divide-y">
                                            <div class="pt-[15px] px-[25px] pb-[25px]">


                                                <div>

                                                    <p class="text-[#00153B] text-[50px] leading-[63px] font-bold">
                                                        4 Days
                                                    </p>
                                                </div>

                                                <div>
                                                    <p class="text-[#717F87] text-[18px] leading-[28px] font-medium">
                                                        {props.accountView == "Commercial" ? props.commercialRentAvailableNumber != 0 ? Math.ceil(pricing[3] * props.commercialRentAvailableNumber * (1 - discount)) : pricing[3]
                                                            : props.userResidentialRentAvailableNumber != 0 ? Math.ceil(pricing[3] * props.userResidentialRentAvailableNumber * (1 - discount)) : pricing[3]} Credits
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="pt-[25px] px-[25px] pb-[35px]">


                                                <div class="mt-[25px]">
                                                    <button class="bg-[#33434D] rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold" onClick={() => changeDeal("deal4", pricing[3])}>Purchase</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            {/* <div style={{ marginTop: "5%" }}>
                                <div className='boostAllCol1'>
                                    <div className='eachBoostOffer' id="deal1" onClick={() => changeDeal("deal1", pricing[0])}>

                                        <p className='eachBoostAmount'>{props.accountView == "Commercial" ? props.commercialRentAvailableNumber != 0 ? Math.ceil(pricing[0] * props.commercialRentAvailableNumber * (1 - discount)) : pricing[0]
                                            : props.userResidentialRentAvailableNumber != 0 ? Math.ceil(pricing[0] * props.userResidentialRentAvailableNumber * (1 - discount)) : pricing[0]} Credit</p>
                                        <p className='eachBoostday'>1 Day</p>
                                    </div>
                                    <div className='eachBoostOffer' id="deal2" onClick={() => changeDeal("deal2", pricing[1])}>

                                        <p className='eachBoostAmount'>{props.accountView == "Commercial" ? props.commercialRentAvailableNumber != 0 ? Math.ceil(pricing[1] * props.commercialRentAvailableNumber * (1 - discount)) : pricing[1]
                                            : props.userResidentialRentAvailableNumber != 0 ? Math.ceil(pricing[1] * props.userResidentialRentAvailableNumber * (1 - discount)) : pricing[1]} Credit</p>
                                        <p className='eachBoostday'>2 Days</p>
                                    </div>
                                </div>
                                <div className='boostAllCol2'>
                                    <div className='eachBoostOffer' id="deal3" onClick={() => changeDeal("deal3", pricing[2])}>

                                        <p className='eachBoostAmount'>{props.accountView == "Commercial" ? props.commercialRentAvailableNumber != 0 ? Math.ceil(pricing[2] * props.commercialRentAvailableNumber * (1 - discount)) : pricing[2]
                                            : props.userResidentialRentAvailableNumber != 0 ? Math.ceil(pricing[2] * props.userResidentialRentAvailableNumber * (1 - discount)) : pricing[2]} Credit</p>
                                        <p className='eachBoostday'>3 Days</p>
                                    </div>
                                    <div className='eachBoostOfferSelected' id="deal4" onClick={() => changeDeal("deal4", pricing[3])}>
                                        <p className='eachBoostAmount'>{props.accountView == "Commercial" ? props.commercialRentAvailableNumber != 0 ? Math.ceil(pricing[3] * props.commercialRentAvailableNumber * (1 - discount)) : pricing[3]
                                            : props.userResidentialRentAvailableNumber != 0 ? Math.ceil(pricing[3] * props.userResidentialRentAvailableNumber * (1 - discount)) : pricing[3]} Credit</p>
                                        <p className='eachBoostday'>4 Days</p>
                                    </div>
                                </div>
                            </div> */}

                            {tierLeft > 0 && tierLeft <= 3 ? <p style={{ marginTop: "5%", textAlign: "center", color: "red" }}>*Only {tierLeft} left at this price! Grab it Now!</p> : null}


                            <p className='modal_p' id="BoostPrice"></p>
                        </div>

                        <div className='modal-footerNewProject'>

                            {/* {spinner == true ? <center><i class="fas fa-spinner fa-spin fa-lg" style={{ marginTop: "5%", marginBottom: "5%" }}></i></center> :
                                payClick == false ? <>
                                    <button className='boostBtn' onClick={() => proceedButton()}>Proceed</button>
                                    <div style={{ clear: 'both' }}></div>
                                </> :
                                    <div>
                                        <center>
                                           
                                        </center>
                                    </div>
                            } */}
                        </div>

                    </div>
                </div>
            </>
        );
    }

}