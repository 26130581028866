import React, { useEffect, useState, useRef } from 'react';
import '../../App.css';
import '../css/AdminAccount.css'
import '../css/Account.css';
import { UserAuth } from '../../AuthContent';
// import { Button } from '../Button';
import NewProject from './NewProject'
import { Navigate, Link, useFetcher } from 'react-router-dom';
import { collection, getDocs, onSnapshot, doc, updateDoc, arrayUnion, serverTimestamp, deleteDoc, getDoc, query, where, getDocsFromServer, addDoc, setDoc, deleteField, orderBy } from 'firebase/firestore';
import { db,storage } from '../../firebase/firebase'
import { getDownloadURL, ref, uploadBytes, listAll, getStorage, deleteObject, uploadString } from 'firebase/storage';
import BoostAll from './BoostAll';
import BoostIndiv from './BoostIndiv';
// import { UserChat } from '../ChatContext';
import { useLocation } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';
import Footer from '../Footer';
import LoaderPage from './LoaderPage';
import { list } from 'firebase/storage';
import { setUserId } from 'firebase/analytics';
import Spinner from './Spinner';
import ZoomPicture from './ZoomPictures';
import {
    ChartBarIcon,
    EnvelopeIcon,
    HeartIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    PencilSquareIcon

} from '@heroicons/react/24/outline'


export default function Account() {
    const uploadSelfImage = useRef(null);
    const { logOut, user } = UserAuth();
    const [pendingBus, setPendingBus] = useState([]);
    const [pendingListingSale, setPendingListingSale] = useState([]);
    const [pendingListingResidentialSale, setPendingDataResidentialSale] = useState([]);
    const [pendingBusAuto, setPendingBusAuto] = useState([]);
    const [advertisementTab, setAdvertisementTab] = useState(false);
    const [appointmentTab, setAppointmentTab] = useState(false);
    const [approve, setApprove] = useState(true);
    const [actions, setActions] = useState(false);
    const [modifyDatabase, setModifyDatabase] = useState(false);
    const [openLoader, setOpenloader] = useState(false);
    const [allListing, setAllListing] = useState(false);
    const [commercialSaleTab, setCommercialSaleTab] = useState(false);
    const [residentialRentTab, setResidentialRentTab] = useState(false);
    const [residentialSaleTab, setResidentialSaleTab] = useState(false);
    const [userAccountTab, setUserAccountTab] = useState(false);
    const [enterprise, setEnterprise] = useState(false);
    const [allUser, setAllUser] = useState([]);
    const [report, setReport] = useState(false);
    const [allReport, setAllReport] = useState([]);
    const [photo, setPhoto] = useState(null);
    const [pendingListingResidential, setPendingListingResidential] = useState([]);
    const [pendingListingResidentialAuto, setPendingListingResidentialAuto] = useState([]);
    const [pendingListingSaleAuto, setPendingListingSaleAuto] = useState([]);
    const [pendingAdvListing, setPendingAdvListing] = useState([]);
    const [allAdvertisement, setAllAdvertisement] = useState([]);
    const [pendingDataResidentialSaleAuto, setPendingDataResidentialSaleAuto] = useState([]);
    const changes = useRef([]);
    const [promotionCode, setPromotionCode] = useState('');
    const [promotionCredit, setPromotionCredit] = useState(0);
    const [promotionCount, setPromotionCount] = useState(0);
    const [zoomPic, setZoomPic] = useState(false);
    const [zoomPicURL, setZoomPicURL] = useState(null);
    const [appointmentList, setAppointmentList] = useState([]);
    const [filterUserAccount, setFilterUserAccount] = useState('');
    const [filterUserAccountCat, setFilterUserAccountCat] = useState("Appointment Date");
    const [userInformation, setUserInformation] = useState([]);
    const [publicUserInformation, setPublicUserInformation] = useState([]);
    const [search, setSearch] = useState(false);
    const [filterSearchInformation, setFilteredSearchInformation] = useState([]);
    const [isAllUser, setIsAllUser] = useState(false);
    const [targetID, setTargetID] = useState(null);
    const [fieldType, setFieldType] = useState("String");
    const [collectionName, setCollectionName] = useState(null);
    const [fieldName, setFieldName] = useState(null);
    const [commercialPropertyList, setCommercialPropertyList] = useState([]);
    const [residentialPropertyList, setResidentialPropertyList] = useState([]);
    const [freeCredits, setFreeCredits] = useState(0);
    const [titleRewards, setTitleRewards] = useState('');

    const handleAllUserCheckbox = (event) => {
        setIsAllUser(event.target.checked);
    };

    function setPhotoUpload(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          setPhoto(reader.result);
        };
    
        reader.readAsDataURL(file);
    
    
    
      }

    const modifyDatabaseButton = async () => {
        if (isAllUser == true) {
            const querySnapshot = await getDocs(collection(db, collectionName));

            querySnapshot.forEach(async (indivDoc) => {
                if (fieldType == "String") {
                    await updateDoc(doc(db, `${collectionName}`, indivDoc.id), {
                        [fieldName]: null
                    })
                }
                else if (fieldType == "Boolean") {
                    await updateDoc(doc(db, `${collectionName}`, indivDoc.id), {
                        [fieldName]: false
                    })
                }
                else {
                    await updateDoc(doc(db, `${collectionName}`, indivDoc.id), {
                        [fieldName]: 0
                    })
                }

            });
        }
        else {

        }

    }

    const updatePromotionCode = async () => {
        updateDoc(doc(db, 'promotionCode', 'instagramPromo'), {
            code: promotionCode,
            credits: Number(promotionCredit),
            count: 0
        }).then(() => {
            alert("done");
        })
    }

    const getData = async () => {
        var pendingData = [];
        var pendingDataAuto = [];
        var pendingDataResidential = [];
        var pendingDataResidentialAuto = [];
        var pendingDataSale = [];
        var pendingDataSaleAuto = [];
        var pendingDataResidentialSale = [];
        var pendingDataResidentialSaleAuto = [];
        var advListingData = [];
        var allAdvListingData = [];
        var userInfo = [];
        var publicUserInfoList = [];
        const today = new Date(); // Get the current date
        const threeDaysAgo = new Date(today);
        threeDaysAgo.setDate(today.getDate() - 2); // Subtract 2 to include today in the range

        const allBus = query(collection(db, "projects"), where('status', '==', 'pending'));
        const allBusAutoLease = query(collection(db, "projects"), orderBy('timePosted', 'desc'));

        const documentSnapshots = await getDocs(allBus);
        const allBusAutoLeaseDoc = await getDocs(allBusAutoLease);
        documentSnapshots.forEach((doc) => {
            pendingData.push({ id: doc.id, details: doc.data() })
        })
        for (let i = 0; i < allBusAutoLeaseDoc.docs.length; i++) {
            const doc = allBusAutoLeaseDoc.docs[i];
            const indivDoc = doc.data();
            if (indivDoc.timePosted) {
                const postDate = new Date(indivDoc.timePosted.toDate()); // Assuming timePosted is a Firestore timestamp field
                if (postDate >= threeDaysAgo && postDate <= today) {
                    if (indivDoc.status == "leasing" && indivDoc.propertyAgent == true) {
                        pendingDataAuto.push({ id: doc.id, details: indivDoc });
                    }

                } else {
                    break; // Stop the loop if the condition fails
                }
            }
        }

        console.log(pendingDataAuto);


        const allResidentialListing = query(collection(db, "residentialListing"), where('status', '==', 'pending'));
        const allResidentialListingAutoLease = query(collection(db, "residentialListing"), orderBy('timePosted', 'desc'));
        const allResidentialListingSnap = await getDocs(allResidentialListing);
        const allResidentialListingSnapAuto = await getDocs(allResidentialListingAutoLease);
        allResidentialListingSnap.forEach((doc) => {
            var residentialDetails = doc.data();
            var periodMoveInDate = new Date(residentialDetails.periodMoveIn.seconds * 1000);
            var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
            residentialDetails.periodMoveInDateFormatted = formattedDate;
            pendingDataResidential.push({ id: doc.id, details: residentialDetails })
        })
        for (let i = 0; i < allResidentialListingSnapAuto.docs.length; i++) {
            const doc = allResidentialListingSnapAuto.docs[i];
            const indivDoc = doc.data();
            if (indivDoc.timePosted) {
                const postDate = new Date(indivDoc.timePosted.toDate()); // Assuming timePosted is a Firestore timestamp field
                if (postDate >= threeDaysAgo && postDate <= today) {
                    if (indivDoc.status == "leasing" && indivDoc.propertyAgent == true) {
                        pendingDataResidentialAuto.push({ id: doc.id, details: indivDoc });
                    }
                } else {
                    break; // Stop the loop if the condition fails
                }
            }
        }

        const allListingSale = query(collection(db, "listingCommercialPurchase"), where('status', '==', 'pending'));
        const allListingSaleAuto = query(collection(db, "listingCommercialPurchase"), orderBy('timePosted', 'desc'));
        const allListingSaleSnap = await getDocs(allListingSale);
        const allListingSaleSnapAuto = await getDocs(allListingSaleAuto);
        allListingSaleSnap.forEach((doc) => {
            var commercialDetails = doc.data();
            var periodMoveInDate = new Date(commercialDetails.moveInPeriod.seconds * 1000);
            var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
            commercialDetails.periodMoveInDateFormatted = formattedDate;
            pendingDataSale.push({ id: doc.id, details: commercialDetails })
        })
        for (let i = 0; i < allListingSaleSnapAuto.docs.length; i++) {
            const doc = allListingSaleSnapAuto.docs[i];
            const indivDoc = doc.data();
            if (indivDoc.timePosted) {
                const postDate = new Date(indivDoc.timePosted.toDate()); // Assuming timePosted is a Firestore timestamp field
                if (postDate >= threeDaysAgo && postDate <= today) {
                    if (indivDoc.status == "leasing" && indivDoc.propertyAgent == true) {
                        pendingDataSaleAuto.push({ id: doc.id, details: indivDoc });
                    }
                } else {
                    break; // Stop the loop if the condition fails
                }
            }
        }

        const allResidentialListingSale = query(collection(db, "residentialListingPurchase"), where('status', '==', 'pending'));
        const allResidentialListingSaleAuto = query(collection(db, "residentialListingPurchase"), orderBy('timePosted', 'desc'));
        const allResidentialListingSaleSnap = await getDocs(allResidentialListingSale);
        const allResidentialListingSaleSnapAuto = await getDocs(allResidentialListingSaleAuto);
        allResidentialListingSaleSnap.forEach((doc) => {
            var residentialDetailsSale = doc.data();
            var periodMoveInDate = new Date(residentialDetailsSale.moveInPeriod.seconds * 1000);
            var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
            residentialDetailsSale.periodMoveInDateFormatted = formattedDate;
            pendingDataResidentialSale.push({ id: doc.id, details: residentialDetailsSale })
        })
        for (let i = 0; i < allResidentialListingSaleSnapAuto.docs.length; i++) {
            const doc = allResidentialListingSaleSnapAuto.docs[i];
            const indivDoc = doc.data();
            if (indivDoc.timePosted) {
                const postDate = new Date(indivDoc.timePosted.toDate()); // Assuming timePosted is a Firestore timestamp field
                if (postDate >= threeDaysAgo && postDate <= today) {
                    if (indivDoc.status == "leasing" && indivDoc.propertyAgent == true) {
                        pendingDataResidentialSaleAuto.push({ id: doc.id, details: indivDoc });
                    }
                } else {
                    break; // Stop the loop if the condition fails
                }
            }
        }

        const advertiseListing = query(collection(db, "advertiseListing"), where('status', '==', 'pending'));
        const advertiseListingSnap = await getDocs(advertiseListing);
        advertiseListingSnap.forEach(async (indivDoc) => {
            var userDoc = await getDoc(doc(db, 'userInfo', indivDoc.data().UID));
            var advDetails = { id: indivDoc.id, details: indivDoc.data(), userData: userDoc.data() }
            advListingData.push(advDetails)
        })

        const allAdvertiseListing = query(collection(db, "advertiseListing"));
        const allAdvertiseListingSnap = await getDocs(allAdvertiseListing);
        allAdvertiseListingSnap.forEach(async (indivDoc) => {
            var userDoc = await getDoc(doc(db, 'userInfo', indivDoc.data().UID));
            var advDetails = { id: indivDoc.id, details: indivDoc.data(), userData: userDoc.data() }
            allAdvListingData.push(advDetails)
        })

        const publicUserInfoRef = query(collection(db, "publicUserInfo"));
        const publicUserInfoSnap = await getDocs(publicUserInfoRef);
        publicUserInfoSnap.forEach((doc) => {
            publicUserInfoList.push({ id: doc.id, details: doc.data() })
        })

        const userInfoData = query(collection(db, "userInfo"));
        const userInfoDataSnap = await getDocs(userInfoData);
        var index = 0;
        userInfoDataSnap.forEach((doc) => {
            userInfo.push({ id: doc.id, details: doc.data(), publicUserInfo: publicUserInfoList[index] })
            index++;
        })

        let allCommercialPropertyListDocs = await getDocs(collection(db, 'commericalPropertyList'));
        let allCommercialPropertyArray = [];
        allCommercialPropertyListDocs.docs.forEach((doc) => {
            allCommercialPropertyArray.push(doc.data());
        })
        setCommercialPropertyList(allCommercialPropertyArray)
        let allResidentialPropertyListDocs = await getDocs(collection(db, 'residentialPropertyList'));
        let allResidentialPropertyArray = [];
        allResidentialPropertyListDocs.docs.forEach((doc) => {
            allResidentialPropertyArray.push(doc.data());
        })
        setResidentialPropertyList(allResidentialPropertyArray)


        var promotionCodeData = await getDoc(doc(db, 'promotionCode', 'instagramPromo'));
        promotionCodeData = promotionCodeData.data();
        setPromotionCode(promotionCodeData.code);
        setPromotionCredit(promotionCodeData.credits);
        setPromotionCount(promotionCodeData.count);

        console.log(userInfo, publicUserInfoList);
        setPublicUserInformation(publicUserInfoList);
        setUserInformation(userInfo)
        setPendingListingSale(pendingDataSale);
        setPendingDataResidentialSale(pendingDataResidentialSale);
        setPendingListingResidential(pendingDataResidential);
        setPendingBus(pendingData);
        setPendingAdvListing(advListingData);
        setAllAdvertisement(allAdvListingData)
        setPendingBusAuto(pendingDataAuto);
        setPendingListingSaleAuto(pendingDataSaleAuto);
        setPendingDataResidentialSaleAuto(pendingDataResidentialSaleAuto);
        setPendingListingResidentialAuto(pendingDataResidentialAuto);
    }

    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.log(error);
        }
    }

    const valueOfBus = (id) => {
        return new Promise(async function (resolve, reject) {
            const commonSnap = await getDoc(doc(db, "commonData", 'information'));

            for (var i = 0; i < pendingBus.length; i++) {
                var maxLeaseNum = pendingBus[i].details.leaseTermMax;
                maxLeaseNum = maxLeaseNum.split(' ');
                var multipler;
                if (maxLeaseNum[1] == "Days") {
                    multipler = 1;

                }
                else if (maxLeaseNum[1] == "Months") {
                    multipler = Number(maxLeaseNum[0]);
                    console.log("there");
                }
                else if (maxLeaseNum[1] == "Years") {
                    multipler = Number(maxLeaseNum[0]) * 12;
                    console.log("ther111");
                }
                if (pendingBus[i].id == id) {

                    var answer = (pendingBus[i].details.priceOverall * multipler);
                    console.log(pendingBus[i].details.priceOverall);
                    console.log(multipler);
                    resolve(Number(commonSnap.data().totalCompanyValue) + answer);
                }
            }
        })
    }

    const valueOfCommercialSale = (id) => {
        return new Promise(async function (resolve, reject) {
            console.log(pendingListingSale)

            const commonSnap = await getDoc(doc(db, "commonData", 'information'));

            for (var i = 0; i < pendingListingSale.length; i++) {
                var maxPSFBudget = pendingListingSale[i].details.maxFloorSizePSFBudget;

                if (pendingListingSale[i].id == id) {
                    var answer = (pendingListingSale[i].details.maxFloorSize * maxPSFBudget);
                    resolve(Number(commonSnap.data().totalCompanyValue) + answer);
                }
            }
        })
    }

    const helpme = async () => {
        await updateDoc(doc(db, 'commonData', 'appointment'), {
            '3': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '4': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '5': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '6': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '7': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '8': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '9': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '10': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '11': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '12': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '13': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '14': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '15': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '16': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '17': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '18': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '19': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '20': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '21': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '22': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '23': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '24': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '25': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '26': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '27': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '28': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '29': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            },
            '30': {
                '1300': false,
                '1320': false,
                '1340': false,
                '1400': false,
                '1420': false,
                '1440': false,
                '1500': false,
                '1520': false,
                '1540': false,
                '1600': false,
                '1620': false,
                '1640': false,
                '1700': false,
            }
        })
    }

    const valueOfResidentialRent = (id) => {
        return new Promise(async function (resolve, reject) {
            console.log(pendingListingResidential)

            const commonSnap = await getDoc(doc(db, "commonData", 'information'));

            for (var i = 0; i < pendingListingResidential.length; i++) {
                var maxLeaseNum = pendingListingResidential[i].details.leaseTermResidential;
                var multipler;
                if (maxLeaseNum == "Short Term / Flexi") {
                    multipler = 1;
                }
                else {
                    maxLeaseNum = maxLeaseNum.split(' ');

                    if (maxLeaseNum[1] == "Days") {
                        multipler = 1;
                    }
                    else if (maxLeaseNum[1] == "Months") {
                        multipler = Number(maxLeaseNum[0]);
                        console.log("there");
                    }
                    else if (maxLeaseNum[1] == "Years" || maxLeaseNum[1] == "Year") {
                        multipler = Number(maxLeaseNum[0]) * 12;
                        console.log("ther111");
                    }
                }
                if (pendingListingResidential[i].id == id) {

                    var answer = (pendingListingResidential[i].details.priceOverall * multipler);
                    console.log(pendingListingResidential[i].details.priceOverall);
                    console.log(multipler);
                    resolve(Number(commonSnap.data().totalCompanyValue) + answer);
                }
            }
        })
    }

    const valueOfResidentialSale = (id) => {
        return new Promise(async function (resolve, reject) {
            console.log(pendingListingResidentialSale)

            const commonSnap = await getDoc(doc(db, "commonData", 'information'));

            for (var i = 0; i < pendingListingResidentialSale.length; i++) {

                if (pendingListingResidentialSale[i].id == id) {

                    var answer = (pendingListingResidentialSale[i].details.budgetOverall);
                    resolve(Number(commonSnap.data().totalCompanyValue) + answer);
                }
            }
        })
    }

    const changePendingCommercialRent = async (id, partyUID, status, listingName, location, postalCode) => {

        await updateDoc(doc(db, "projects", id), {
            status: "pending",
            timePosted: new Date()
        })

    }
    const changePendingCommercialSale = async (id, partyUID, status, listingName, location, postalCode) => {

        await updateDoc(doc(db, "listingCommercialPurchase", id), {
            status: "pending",
            timePosted: new Date()
        })

    }
    const changePendingResidentialRent = async (id, partyUID, status, listingName, location, postalCode) => {

        await updateDoc(doc(db, "residentialListing", id), {
            status: "pending",
            timePosted: new Date()
        })

    }
    const changePendingResidentialSale = async (id, partyUID, status, listingName, location, postalCode) => {

        await updateDoc(doc(db, "residentialListingPurchase", id), {
            status: "pending",
            timePosted: new Date()
        })

    }

    const approveListing = async (id, partyUID, status, listingName, location, postalCode) => {

        await getDoc(doc(db, 'publicUserInfo', partyUID)).then(async (resDoc) => {

            const commonData = doc(db, "commonData", 'information');
            const commonSnap = await getDoc(commonData);
            if (commonSnap.exists()) {
                console.log("Document data:", commonSnap.data().totalProjectCount);
                await valueOfBus(id).then(async (res) => {
                    console.log(res);
                    // console.log('return value', res);t
                    await setDoc(doc(db, `commonData`, 'information'), {
                        totalCompanyValue: res
                    });
                    await updateDoc(doc(db, "projects", id), {
                        status: "leasing",
                        timePosted: new Date()
                    }).then(async () => {
                        console.log(location, postalCode, id);
                        if (postalCode != "") {
                            // const requestOptions = {
                            //     method: 'POST',
                            //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                            //     body: JSON.stringify({ data: { postalCode: postalCode, listingUID: id, path: "CommercialRent" } })
                            // };
                            // await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/nearbyListing', requestOptions).then(data => console.log(data)).then(
                            //     error => console.log(error)
                            // );
                            nearbyListingCommercial(postalCode, id, "CommercialRent")
                        }
                        const requestOptions_1 = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                            body: JSON.stringify({ data: { username: resDoc.data().username, email: resDoc.data().email, path: "Approved Listing", listingName: listingName } })
                        };
                        // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                        // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                        await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                            error => console.log(error)
                        );
                        let userAccountInfo = await getDoc(doc(db, 'userInfo', partyUID));
                        userAccountInfo = userAccountInfo.data();
                        if (userAccountInfo.transactionHistory.length < 30) {
                            var newTransactionHistory = [...userAccountInfo.transactionHistory];
                            newTransactionHistory.push({
                                Actions: "+2 Points",
                                Activity: "Approval Listing",
                                Date: new Date(),
                                Type: "Listing"
                            })
                        }
                        else {
                            var newTransactionHistory = [...userAccountInfo.transactionHistory];
                            newTransactionHistory.shift();
                            newTransactionHistory.push({
                                Actions: "+2 Points",
                                Activity: "Approval Listing",
                                Date: new Date(),
                                Type: "Listing"
                            })
                        }

                        await updateDoc(doc(db, 'userInfo', partyUID), {
                            points: userAccountInfo.points + 2,
                            transactionHistory: newTransactionHistory
                        })
                    });
                });

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        })

    }

    const proceedPortingPhoto = async ()=>{
        const advID = prompt('Please enter your name:', 'adv ID');
        const userUID = prompt('Please enter your name:', 'user UID');
        const storageRef = ref(storage, `${userUID}/advertise_${advID}`);
        await uploadString(storageRef, photo, 'data_url').then(async () =>{
            const url = await getDownloadURL(storageRef);
            await updateDoc(doc(db, 'advertiseListing', advID), {
                imageURL: url
              }).then(() => {
                alert("done")
              })
        })
    }

    const approveResidentialListing = async (id, partyUID, status, listingName, location, postalCode) => {
        await getDoc(doc(db, 'publicUserInfo', partyUID)).then(async (resDoc) => {

            const commonData = doc(db, "commonData", 'information');
            const commonSnap = await getDoc(commonData);
            if (commonSnap.exists()) {
                console.log("Document data:", commonSnap.data().totalProjectCount);
                await valueOfResidentialRent(id).then(async (res) => {
                    console.log(res);
                    // console.log('return value', res);t
                    await setDoc(doc(db, `commonData`, 'information'), {
                        totalCompanyValue: res
                    });
                    await updateDoc(doc(db, "residentialListing", id), {
                        status: "leasing",
                        timePosted: new Date()
                    }).then(async () => {
                        if (postalCode != "") {
                            // const requestOptions = {
                            //     method: 'POST',
                            //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                            //     body: JSON.stringify({ data: { postalCode: postalCode, listingUID: id, path: "ResidentialRent" } })
                            // };
                            // await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/nearbyListing', requestOptions).then(data => console.log(data)).then(
                            //     error => console.log(error)
                            // );
                            nearbyListingResidential(postalCode, id, "ResidentialRent");
                        }
                        const requestOptions_1 = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                            body: JSON.stringify({ data: { username: resDoc.data().username, email: resDoc.data().email, path: "Approved Listing", listingName: listingName } })
                        };
                        // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                        // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                        await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                            error => console.log(error)
                        );
                        let userAccountInfo = await getDoc(doc(db, 'userInfo', partyUID));
                        userAccountInfo = userAccountInfo.data();
                        if (userAccountInfo.transactionHistory.length < 30) {
                            var newTransactionHistory = [...userAccountInfo.transactionHistory];
                            newTransactionHistory.push({
                                Actions: "+2 Points",
                                Activity: "Approval Listing",
                                Date: new Date(),
                                Type: "Listing"
                            })
                        }
                        else {
                            var newTransactionHistory = [...userAccountInfo.transactionHistory];
                            newTransactionHistory.shift();
                            newTransactionHistory.push({
                                Actions: "+2 Points",
                                Activity: "Approval Listing",
                                Date: new Date(),
                                Type: "Listing"
                            })
                        }

                        await updateDoc(doc(db, 'userInfo', partyUID), {
                            points: userAccountInfo.points + 2,
                            transactionHistory: newTransactionHistory
                        })
                    });
                });

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        })

    }

    const approveListingCommercial = async (id, partyUID, status, listingName, location, postalCode) => {
        await getDoc(doc(db, 'publicUserInfo', partyUID)).then(async (resDoc) => {

            const commonData = doc(db, "commonData", 'information');
            const commonSnap = await getDoc(commonData);
            if (commonSnap.exists()) {
                console.log("Document data:", commonSnap.data().totalProjectCount);
                await valueOfCommercialSale(id).then(async (res) => {
                    console.log(res);
                    // console.log('return value', res);t
                    await setDoc(doc(db, `commonData`, 'information'), {
                        totalCompanyValue: res
                    });
                    await updateDoc(doc(db, "listingCommercialPurchase", id), {
                        status: "leasing",
                        timePosted: new Date()
                    }).then(async () => {
                        if (postalCode != "") {
                            // const requestOptions = {
                            //     method: 'POST',
                            //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                            //     body: JSON.stringify({ data: { postalCode: postalCode, listingUID: id, path: "CommercialSale" } })
                            // };
                            // await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/nearbyListing', requestOptions).then(data => console.log(data)).then(
                            //     error => console.log(error)
                            // );
                            nearbyListingCommercial(postalCode, id, "CommercialSale");
                        }
                        const requestOptions_1 = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                            body: JSON.stringify({ data: { username: resDoc.data().username, email: resDoc.data().email, path: "Approved Listing", listingName: listingName } })
                        };
                        // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                        // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                        await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                            error => console.log(error)
                        );
                        let userAccountInfo = await getDoc(doc(db, 'userInfo', partyUID));
                        userAccountInfo = userAccountInfo.data();
                        if (userAccountInfo.transactionHistory.length < 30) {
                            var newTransactionHistory = [...userAccountInfo.transactionHistory];
                            newTransactionHistory.push({
                                Actions: "+2 Points",
                                Activity: "Approval Listing",
                                Date: new Date(),
                                Type: "Listing"
                            })
                        }
                        else {
                            var newTransactionHistory = [...userAccountInfo.transactionHistory];
                            newTransactionHistory.shift();
                            newTransactionHistory.push({
                                Actions: "+2 Points",
                                Activity: "Approval Listing",
                                Date: new Date(),
                                Type: "Listing"
                            })
                        }

                        await updateDoc(doc(db, 'userInfo', partyUID), {
                            points: userAccountInfo.points + 2,
                            transactionHistory: newTransactionHistory
                        })
                    });
                });

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        })

    }



    const approveResidentialListingSale = async (id, partyUID, status, listingName, location, postalCode) => {
        await getDoc(doc(db, 'publicUserInfo', partyUID)).then(async (resDoc) => {

            const commonData = doc(db, "commonData", 'information');
            const commonSnap = await getDoc(commonData);
            if (commonSnap.exists()) {
                console.log("Document data:", commonSnap.data().totalProjectCount);
                await valueOfResidentialSale(id).then(async (res) => {
                    console.log(res);
                    // console.log('return value', res);t
                    await setDoc(doc(db, `commonData`, 'information'), {
                        totalCompanyValue: res
                    });
                    await updateDoc(doc(db, "residentialListingPurchase", id), {
                        status: "leasing",
                        timePosted: new Date()
                    }).then(async () => {
                        if (postalCode != "") {
                            // const requestOptions = {
                            //     method: 'POST',
                            //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                            //     body: JSON.stringify({ data: { postalCode: postalCode, listingUID: id, path: "ResidentialSale" } })
                            // };
                            // await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/nearbyListing', requestOptions).then(data => console.log(data)).then(
                            //     error => console.log(error)
                            // );
                            nearbyListingResidential(postalCode, id, "ResidentialSale");
                        }
                        const requestOptions_1 = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                            body: JSON.stringify({ data: { username: resDoc.data().username, email: resDoc.data().email, path: "Approved Listing", listingName: listingName } })
                        };
                        // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                        // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                        await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                            error => console.log(error)
                        );
                        let userAccountInfo = await getDoc(doc(db, 'userInfo', partyUID));
                        userAccountInfo = userAccountInfo.data();
                        if (userAccountInfo.transactionHistory.length < 30) {
                            var newTransactionHistory = [...userAccountInfo.transactionHistory];
                            newTransactionHistory.push({
                                Actions: "+2 Points",
                                Activity: "Approval Listing",
                                Date: new Date(),
                                Type: "Listing"
                            })
                        }
                        else {
                            var newTransactionHistory = [...userAccountInfo.transactionHistory];
                            newTransactionHistory.shift();
                            newTransactionHistory.push({
                                Actions: "+2 Points",
                                Activity: "Approval Listing",
                                Date: new Date(),
                                Type: "Listing"
                            })
                        }

                        await updateDoc(doc(db, 'userInfo', partyUID), {
                            points: userAccountInfo.points + 2,
                            transactionHistory: newTransactionHistory
                        })
                    });
                });

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        })
    }

    const nearbyListingCommercial = async (postalCode, listingId, path) => {
        const allCommercialPropertyInfo = await getDocs(collection(db, 'commericalPropertyList'));
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: "jchua0205@gmail.com", password: "Kranjips08!!" })
        };
        await fetch('https://www.onemap.gov.sg/api/auth/post/getToken', requestOptions).then(async (data) => {
            if (data.ok) {
                const dataToken = await data.json();
                console.log(dataToken);
                allCommercialPropertyInfo.forEach(async (indivCommerInfo) => {
                    console.log(indivCommerInfo.data());
                    var allKeys = Object.keys(indivCommerInfo.data());
                    for (var i = 0; i < allKeys.length; i++) {
                        if (indivCommerInfo.data()[allKeys[i]].emailNotification == true) {
                            const IndivPublicInfo = await getDoc(doc(db, 'publicUserInfo', indivCommerInfo.data()[allKeys[i]].uid));
                            console.log(IndivPublicInfo.data())
                            await fetch(`https://developers.onemap.sg/commonapi/search?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`, { method: 'GET' }).then(async (postalCodeGeo) => {
                                if (postalCodeGeo.ok) {
                                    const postalCodeGeoData = await postalCodeGeo.json();
                                    console.log(postalCodeGeoData);
                                    await fetch(`https://www.onemap.gov.sg/api/public/routingsvc/route?start=${indivCommerInfo.data()[allKeys[i]].latitude}%2C${indivCommerInfo.data()[allKeys[i]].longtitude}&end=${postalCodeGeoData.results[0].LATITUDE}%2C${postalCodeGeoData.results[0].LONGITUDE}&routeType=walk`,
                                        {
                                            method: 'GET',
                                            headers: {
                                                'Authorization': `Bearer ${dataToken.access_token}`
                                            }
                                        }).then(async (postalCodeRoute) => {
                                            if (postalCodeRoute.ok) {
                                                const postalCodeRouteData = await postalCodeRoute.json();
                                                var urlPath = "commercialRentDetails";
                                                if (path == "CommercialSale") {
                                                    urlPath = "commercialPurchaseDetails"
                                                }
                                                else if (path == "ResidentialRent") {
                                                    urlPath = "residentialRentDetails"
                                                }
                                                else if (path == "ResidentialSale") {
                                                    urlPath = "residentialPurchaseDetails"
                                                }

                                                if (postalCodeRouteData.route_summary.total_distance <= 1000) {
                                                    console.log(postalCodeRouteData.route_summary);
                                                    const requestOptions_1 = {
                                                        method: 'POST',
                                                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                                                        body: JSON.stringify({ data: { username: IndivPublicInfo.data().username, email: IndivPublicInfo.data().email, path: urlPath, listingId: listingId, propertyName: indivCommerInfo.data()[allKeys[i]].propertyCompanyName } })
                                                    };
                                                    // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                                                    // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                                                    await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/nearbyListingEmail', requestOptions_1).then(
                                                        error => console.log(error)
                                                    );
                                                }

                                            }
                                        })
                                }
                            })
                        }
                    }


                    // if ((indivUserInfo.data().userType.includes("Landlord") || indivUserInfo.data().userType.includes("Agent") || indivUserInfo.data().userType.includes('Master Tenant'))) {
                    //     var propertyAddressList = indivUserInfo.data().propertyAddressList != null ? indivUserInfo.data().propertyAddressList : []
                    //     for (var i = 0; i < propertyAddressList.length; i++) {
                    //         if (propertyAddressList[i].emailNotification == true) {
                    //             await fetch(`https://developers.onemap.sg/commonapi/search?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`, { method: 'GET' }).then(async (postalCodeGeo) => {
                    //                 if (postalCodeGeo.ok) {
                    //                     const postalCodeGeoData = await postalCodeGeo.json();
                    //                     console.log(postalCodeGeoData);
                    //                     await fetch(`https://www.onemap.gov.sg/api/public/routingsvc/route?start=${propertyAddressList[i].latitude}%2C${propertyAddressList[i].longtitude}&end=${postalCodeGeoData.results[0].LATITUDE}%2C${postalCodeGeoData.results[0].LONGITUDE}&routeType=walk`,
                    //                         {
                    //                             method: 'GET',
                    //                             headers: {
                    //                                 'Authorization': `Bearer ${dataToken.access_token}`
                    //                             }
                    //                         }).then(async (postalCodeRoute) => {
                    //                             if (postalCodeRoute.ok) {
                    //                                 const postalCodeRouteData = await postalCodeRoute.json();
                    //                                 var urlPath = "commercialRentDetails";
                    //                                 if (path == "CommercialSale") {
                    //                                     urlPath = "commercialPurchaseDetails"
                    //                                 }
                    //                                 else if (path == "ResidentialRent") {
                    //                                     urlPath = "residentialRentDetails"
                    //                                 }
                    //                                 else if (path == "ResidentialSale") {
                    //                                     urlPath = "residentialPurchaseDetails"
                    //                                 }

                    //                                 if (postalCodeRouteData.route_summary.total_distance <= 1000) {
                    //                                     console.log(postalCodeRouteData.route_summary);
                    //                                     const requestOptions_1 = {
                    //                                         method: 'POST',
                    //                                         headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                    //                                         body: JSON.stringify({ data: { username: IndivPublicInfo.data().username, email: IndivPublicInfo.data().email, path: urlPath, listingId: listingId, propertyName: propertyAddressList[i].propertyCompanyName } })
                    //                                     };
                    //                                     // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                    //                                     // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                    //                                     await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/nearbyListingEmail', requestOptions_1).then(
                    //                                         error => console.log(error)
                    //                                     );
                    //                                 }

                    //                             }
                    //                         })
                    //                 }
                    //             })

                    //         }
                    //     }

                    // }
                })
            }

        }).then(
            error => console.log(error)
        );
    }

    const nearbyListingResidential = async (postalCode, listingId, path) => {
        const residentialPropertyList = await getDocs(collection(db, 'residentialPropertyList'));
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: "jchua0205@gmail.com", password: "Kranjips08!!" })
        };
        await fetch('https://www.onemap.gov.sg/api/auth/post/getToken', requestOptions).then(async (data) => {
            if (data.ok) {
                const dataToken = await data.json();
                console.log(dataToken);
                residentialPropertyList.forEach(async (indivCommerInfo) => {
                    console.log(indivCommerInfo.data());
                    var allKeys = Object.keys(indivCommerInfo.data());
                    for (var i = 0; i < allKeys.length; i++) {
                        if (indivCommerInfo.data()[allKeys[i]].emailNotification == true) {
                            const IndivPublicInfo = await getDoc(doc(db, 'publicUserInfo', indivCommerInfo.data()[allKeys[i]].uid));
                            console.log(IndivPublicInfo.data())
                            await fetch(`https://developers.onemap.sg/commonapi/search?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`, { method: 'GET' }).then(async (postalCodeGeo) => {
                                if (postalCodeGeo.ok) {
                                    const postalCodeGeoData = await postalCodeGeo.json();
                                    console.log(postalCodeGeoData);
                                    await fetch(`https://www.onemap.gov.sg/api/public/routingsvc/route?start=${indivCommerInfo.data()[allKeys[i]].latitude}%2C${indivCommerInfo.data()[allKeys[i]].longtitude}&end=${postalCodeGeoData.results[0].LATITUDE}%2C${postalCodeGeoData.results[0].LONGITUDE}&routeType=walk`,
                                        {
                                            method: 'GET',
                                            headers: {
                                                'Authorization': `Bearer ${dataToken.access_token}`
                                            }
                                        }).then(async (postalCodeRoute) => {
                                            if (postalCodeRoute.ok) {
                                                const postalCodeRouteData = await postalCodeRoute.json();
                                                var urlPath = "commercialRentDetails";
                                                if (path == "CommercialSale") {
                                                    urlPath = "commercialPurchaseDetails"
                                                }
                                                else if (path == "ResidentialRent") {
                                                    urlPath = "residentialRentDetails"
                                                }
                                                else if (path == "ResidentialSale") {
                                                    urlPath = "residentialPurchaseDetails"
                                                }

                                                if (postalCodeRouteData.route_summary.total_distance <= 1000) {
                                                    console.log(postalCodeRouteData.route_summary);
                                                    const requestOptions_1 = {
                                                        method: 'POST',
                                                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                                                        body: JSON.stringify({ data: { username: IndivPublicInfo.data().username, email: IndivPublicInfo.data().email, path: urlPath, listingId: listingId, propertyName: indivCommerInfo.data()[allKeys[i]].propertyCompanyName } })
                                                    };
                                                    // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                                                    // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                                                    await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/nearbyListingEmail', requestOptions_1).then(
                                                        error => console.log(error)
                                                    );
                                                }

                                            }
                                        })
                                }
                            })
                        }
                    }


                    // if ((indivUserInfo.data().userType.includes("Landlord") || indivUserInfo.data().userType.includes("Agent") || indivUserInfo.data().userType.includes('Master Tenant'))) {
                    //     var propertyAddressList = indivUserInfo.data().propertyAddressList != null ? indivUserInfo.data().propertyAddressList : []
                    //     for (var i = 0; i < propertyAddressList.length; i++) {
                    //         if (propertyAddressList[i].emailNotification == true) {
                    //             await fetch(`https://developers.onemap.sg/commonapi/search?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`, { method: 'GET' }).then(async (postalCodeGeo) => {
                    //                 if (postalCodeGeo.ok) {
                    //                     const postalCodeGeoData = await postalCodeGeo.json();
                    //                     console.log(postalCodeGeoData);
                    //                     await fetch(`https://www.onemap.gov.sg/api/public/routingsvc/route?start=${propertyAddressList[i].latitude}%2C${propertyAddressList[i].longtitude}&end=${postalCodeGeoData.results[0].LATITUDE}%2C${postalCodeGeoData.results[0].LONGITUDE}&routeType=walk`,
                    //                         {
                    //                             method: 'GET',
                    //                             headers: {
                    //                                 'Authorization': `Bearer ${dataToken.access_token}`
                    //                             }
                    //                         }).then(async (postalCodeRoute) => {
                    //                             if (postalCodeRoute.ok) {
                    //                                 const postalCodeRouteData = await postalCodeRoute.json();
                    //                                 var urlPath = "commercialRentDetails";
                    //                                 if (path == "CommercialSale") {
                    //                                     urlPath = "commercialPurchaseDetails"
                    //                                 }
                    //                                 else if (path == "ResidentialRent") {
                    //                                     urlPath = "residentialRentDetails"
                    //                                 }
                    //                                 else if (path == "ResidentialSale") {
                    //                                     urlPath = "residentialPurchaseDetails"
                    //                                 }

                    //                                 if (postalCodeRouteData.route_summary.total_distance <= 1000) {
                    //                                     console.log(postalCodeRouteData.route_summary);
                    //                                     const requestOptions_1 = {
                    //                                         method: 'POST',
                    //                                         headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                    //                                         body: JSON.stringify({ data: { username: IndivPublicInfo.data().username, email: IndivPublicInfo.data().email, path: urlPath, listingId: listingId, propertyName: propertyAddressList[i].propertyCompanyName } })
                    //                                     };
                    //                                     // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                    //                                     // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                    //                                     await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/nearbyListingEmail', requestOptions_1).then(
                    //                                         error => console.log(error)
                    //                                     );
                    //                                 }

                    //                             }
                    //                         })
                    //                 }
                    //             })

                    //         }
                    //     }

                    // }
                })
            }

        }).then(
            error => console.log(error)
        );

    }

    const approveAdvertise = async (id, partyUID, status, listingName) => {
        await updateDoc(doc(db, "advertiseListing", id), {
            status: "approved"
        })
        let userAccountInfo = await getDoc(doc(db, 'userInfo', partyUID));
        userAccountInfo = userAccountInfo.data();
        console.log(userAccountInfo)
        if (userAccountInfo.transactionHistory.length < 30) {
            var newTransactionHistory = [...userAccountInfo.transactionHistory];
            newTransactionHistory.push({
                Actions: "",
                Activity: "Approval Advertisement",
                Date: new Date(),
                Type: "Advertisement"
            })
        }
        else {
            var newTransactionHistory = [...userAccountInfo.transactionHistory];
            newTransactionHistory.shift();
            newTransactionHistory.push({
                Actions: "",
                Activity: "Approval Advertisement",
                Date: new Date(),
                Type: "Advertisement"
            })
        }
        console.log(newTransactionHistory)
        await updateDoc(doc(db, 'userInfo', partyUID), {
            transactionHistory: newTransactionHistory
        })
    }

    const ClickOnReport = async () => {
        setApprove(false);
        setCommercialSaleTab(false);
        setResidentialRentTab(false);
        setResidentialSaleTab(false);
        setReport(true);
        setUserAccountTab(false);
        setEnterprise(false);
        setAdvertisementTab(false);
        setAppointmentTab(false);
        // document.getElementById("btn-Appointment").className = "account-btn";
        // document.getElementById("btn-Advertise").className = "account-btn";
        // document.getElementById("btn-Approve").className = "account-btn";
        // document.getElementById("btn-CommercialSale").className = "account-btn";
        // document.getElementById("btn-ResidentialRent").className = "account-btn";
        // document.getElementById("btn-ResidentialSale").className = "account-btn";
        // document.getElementById("btn-Report").className = "activeAccount";
        // document.getElementById("btn-UserAccount").className = "account-btn";
        // document.getElementById("btn-Enterprise").className = "account-btn";

        var allReportArray = [];
        await getDocs(collection(db, 'reportListing')).then((allDoc) => {
            // console.log(allDoc.data())
            allDoc.forEach((IndivDoc) => {
                console.log(IndivDoc.data());
                var keys = Object.keys(IndivDoc.data());
                for (var i = 0; i < keys.length; i++) {
                    var { reportMessage, reportDate, emailFrom } = IndivDoc.data()[keys[i]];
                    console.log(IndivDoc.data()[keys[i]]);
                    allReportArray.push({
                        id: IndivDoc.id, details: {
                            userUID: keys[i],
                            reportMessage: reportMessage,
                            reportDate: reportDate,
                            emailFrom: emailFrom
                        }
                    })
                }

            })
            console.log(allReportArray);
            setAllReport(allReportArray);
            setOpenloader(false);
        })
    }

    const ClickOnApprove = () => {
        setApprove(true);
        setCommercialSaleTab(false);
        setResidentialRentTab(false);
        setResidentialSaleTab(false);
        setReport(false);
        setUserAccountTab(false);
        setEnterprise(false);
        setAdvertisementTab(false);
        setAppointmentTab(false);
        // document.getElementById("btn-Appointment").className = "account-btn";
        // document.getElementById("btn-Advertise").className = "account-btn";
        // document.getElementById("btn-Approve").className = "activeAccount";
        // document.getElementById("btn-CommercialSale").className = "account-btn";
        // document.getElementById("btn-ResidentialRent").className = "account-btn";
        // document.getElementById("btn-ResidentialSale").className = "account-btn";
        // document.getElementById("btn-Report").className = "account-btn";
        // document.getElementById("btn-UserAccount").className = "account-btn";
        // document.getElementById("btn-Enterprise").className = "account-btn";
    }

    const ClickOnAdvertisement = () => {
        setApprove(false);
        setCommercialSaleTab(false);
        setResidentialRentTab(false);
        setResidentialSaleTab(false);
        setReport(false);
        setUserAccountTab(false);
        setEnterprise(false);
        setAdvertisementTab(true);
        setAppointmentTab(false);
        // document.getElementById("btn-Appointment").className = "account-btn";
        // document.getElementById("btn-Advertise").className = "activeAccount";
        // document.getElementById("btn-Approve").className = "account-btn";
        // document.getElementById("btn-CommercialSale").className = "account-btn";
        // document.getElementById("btn-ResidentialRent").className = "account-btn";
        // document.getElementById("btn-ResidentialSale").className = "account-btn";
        // document.getElementById("btn-Report").className = "account-btn";
        // document.getElementById("btn-UserAccount").className = "account-btn";
        // document.getElementById("btn-Enterprise").className = "account-btn";
    }

    const ClickOnCommercialSale = () => {
        setApprove(false);
        setCommercialSaleTab(true);
        setResidentialRentTab(false);
        setResidentialSaleTab(false);
        setReport(false);
        setUserAccountTab(false);
        setEnterprise(false);
        setAdvertisementTab(false);
        setAppointmentTab(false);
        // document.getElementById("btn-Appointment").className = "account-btn";
        // document.getElementById("btn-Advertise").className = "account-btn";
        // document.getElementById("btn-Approve").className = "account-btn";
        // document.getElementById("btn-CommercialSale").className = "activeAccount";
        // document.getElementById("btn-ResidentialRent").className = "account-btn";
        // document.getElementById("btn-ResidentialSale").className = "account-btn";
        // document.getElementById("btn-Report").className = "account-btn";
        // document.getElementById("btn-UserAccount").className = "account-btn";
        // document.getElementById("btn-Enterprise").className = "account-btn";
    }

    const ClickOnResidentialRent = () => {
        setApprove(false);
        setCommercialSaleTab(false);
        setResidentialRentTab(true);
        setResidentialSaleTab(false);
        setReport(false);
        setUserAccountTab(false);
        setEnterprise(false);
        setAdvertisementTab(false);
        setAppointmentTab(false);
        // document.getElementById("btn-Appointment").className = "account-btn";
        // document.getElementById("btn-Advertise").className = "account-btn";
        // document.getElementById("btn-Approve").className = "account-btn";
        // document.getElementById("btn-CommercialSale").className = "account-btn";
        // document.getElementById("btn-ResidentialRent").className = "activeAccount";
        // document.getElementById("btn-ResidentialSale").className = "account-btn";
        // document.getElementById("btn-Report").className = "account-btn";
        // document.getElementById("btn-UserAccount").className = "account-btn";
        // document.getElementById("btn-Enterprise").className = "account-btn";
    }

    const ClickOnResidentialSale = () => {
        setApprove(false);
        setCommercialSaleTab(false);
        setResidentialRentTab(false);
        setResidentialSaleTab(true);
        setReport(false);
        setUserAccountTab(false);
        setEnterprise(false);
        setAdvertisementTab(false);
        setAppointmentTab(false);
        // document.getElementById("btn-Appointment").className = "account-btn";
        // document.getElementById("btn-Advertise").className = "account-btn";
        // document.getElementById("btn-Approve").className = "account-btn";
        // document.getElementById("btn-CommercialSale").className = "account-btn";
        // document.getElementById("btn-ResidentialRent").className = "account-btn";
        // document.getElementById("btn-ResidentialSale").className = "activeAccount";
        // document.getElementById("btn-Report").className = "account-btn";
        // document.getElementById("btn-UserAccount").className = "account-btn";
        // document.getElementById("btn-Enterprise").className = "account-btn";
    }

    const ClickOnUserAccount = () => {
        setApprove(false);
        setCommercialSaleTab(false);
        setResidentialRentTab(false);
        setResidentialSaleTab(false);
        setReport(false);
        setUserAccountTab(true);
        setEnterprise(false);
        setAdvertisementTab(false);
        setAppointmentTab(false);
        // document.getElementById("btn-Appointment").className = "account-btn";
        // document.getElementById("btn-Advertise").className = "account-btn";
        // document.getElementById("btn-Approve").className = "account-btn";
        // document.getElementById("btn-CommercialSale").className = "account-btn";
        // document.getElementById("btn-ResidentialRent").className = "account-btn";
        // document.getElementById("btn-ResidentialSale").className = "account-btn";
        // document.getElementById("btn-Report").className = "account-btn";
        // document.getElementById("btn-UserAccount").className = "activeAccount";
        // document.getElementById("btn-Enterprise").className = "account-btn";
    }

    const ClickOnActions = async () => {
        setOpenloader(true);
        var allListing = await getDocs(collection(db, 'projects'));
        var allListingArray = allListing.docs.map((listing) => {
            return { id: listing.id, ...listing.data() };
        });
        console.log(allListingArray);
        setAllListing(allListingArray);
        setReport(false);
        setApprove(false);
        setActions(true);
        setModifyDatabase(false);
        // setPremium(false);
        // document.getElementById("btn-Approve").className = "account-btn";
        // document.getElementById("btn-Actions").className = "activeAccount";
        // document.getElementById("btn-Modify").className = "account-btn";
        // document.getElementById("btn-Premium").className = "account-btn";
        // document.getElementById("btn-Report").className = "account-btn";
        setOpenloader(false);
    }

    const ClickOnModify = () => {
        setApprove(false);
        setCommercialSaleTab(false);
        setResidentialRentTab(false);
        setResidentialSaleTab(false);
        setReport(false);
        setUserAccountTab(false);
        setEnterprise(false);
        setAdvertisementTab(false);
        setAppointmentTab(false);
        setModifyDatabase(true);
        // setPremium(false);
        // document.getElementById("btn-Approve").className = "account-btn";
        // document.getElementById("btn-Actions").className = "account-btn";
        // document.getElementById("btn-Modify").className = "activeAccount";
        // document.getElementById("btn-Premium").className = "account-btn";
        // document.getElementById("btn-Report").className = "account-btn";

    }

    const ClickOnEnterprise = async () => {
        var allUser = await getDocs(collection(db, 'userInfo'));
        var allUserArray = allUser.docs.map((indivUser) => {
            return { id: indivUser.id, ...indivUser.data() };
        });
        console.log(allUserArray);
        setAllUser(allUserArray);
        setApprove(false);
        setCommercialSaleTab(false);
        setResidentialRentTab(false);
        setResidentialSaleTab(false);
        setReport(false);
        setModifyDatabase(false);
        setUserAccountTab(false);
        setEnterprise(true);
        setAdvertisementTab(false);
        setAppointmentTab(false);
        // document.getElementById("btn-Appointment").className = "account-btn";
        // document.getElementById("btn-Advertise").className = "account-btn";
        // document.getElementById("btn-Approve").className = "account-btn";
        // document.getElementById("btn-CommercialSale").className = "account-btn";
        // document.getElementById("btn-ResidentialRent").className = "account-btn";
        // document.getElementById("btn-ResidentialSale").className = "account-btn";
        // document.getElementById("btn-Report").className = "account-btn";
        // document.getElementById("btn-UserAccount").className = "account-btn";
        // document.getElementById("btn-Enterprise").className = "activeAccount";
    }

    const ClickOnAppointment = async () => {
        var appointmentInfo = await getDoc(doc(db, 'commonData', 'appointment'));
        console.log(appointmentInfo.data());
        setAppointmentList(appointmentInfo.data());
        setApprove(false);
        setCommercialSaleTab(false);
        setResidentialRentTab(false);
        setResidentialSaleTab(false);
        setReport(false);
        setUserAccountTab(false);
        setEnterprise(false);
        setAdvertisementTab(false);
        setAppointmentTab(true);
        // document.getElementById("btn-Appointment").className = "activeAccount";
        // document.getElementById("btn-Advertise").className = "account-btn";
        // document.getElementById("btn-Approve").className = "account-btn";
        // document.getElementById("btn-CommercialSale").className = "account-btn";
        // document.getElementById("btn-ResidentialRent").className = "account-btn";
        // document.getElementById("btn-ResidentialSale").className = "account-btn";
        // document.getElementById("btn-Report").className = "account-btn";
        // document.getElementById("btn-UserAccount").className = "account-btn";
        // document.getElementById("btn-Enterprise").className = "account-btn";
    }

    const updateAllData = async () => {
        var docRef = await getDocs(collection(db, 'projects'));
        docRef.forEach(async (document) => {
            console.log("updating");
            await updateDoc(doc(db, `projects`, document.id), {
                preferredListing: false
            })


        })
    }

    const handleSearch = (e) => {
        // setFilterUserAccount(e.target.value);

        var filteredList = [];
        if (filterUserAccountCat == "Appointment Date") {
            for (var i = 0; i < userInformation.length; i++) {
                if (userInformation[i].details.hasOwnProperty('apptDate')) {
                    if (userInformation[i].details.apptDate == filterUserAccount) {
                        filteredList.push(userInformation[i]);
                    }
                }
            }
            setFilteredSearchInformation(filteredList);
            setSearch(true);
        }
        else {
            for (var i = 0; i < userInformation.length; i++) {

                if (userInformation[i].publicUserInfo.details.username == filterUserAccount || userInformation[i].publicUserInfo.details.email == filterUserAccount) {
                    console.log(userInformation[i]);
                    filteredList.push(userInformation[i]);
                }

            }
            console.log(filteredList)
            setFilteredSearchInformation(filteredList);
            setSearch(true);
        }
    }

    useEffect(() => {
        const unsubscribeFunctions = [
            onSnapshot(collection(db, "projects"), (doc) => {
                console.log('snap');
                getData();
            }),
            onSnapshot(collection(db, "residentialListing"), (doc) => {
                console.log('snap');
                getData();
            }),
            onSnapshot(collection(db, "listingCommercialPurchase"), (doc) => {
                console.log('snap');
                getData();
            }),
            onSnapshot(collection(db, "residentialListingPurchase"), (doc) => {
                console.log('snap');
                getData();
            }),
            onSnapshot(collection(db, "advertiseListing"), (doc) => {
                console.log('snap');
                getData();
            }),
            onSnapshot(collection(db, "userInfo"), (doc) => {
                console.log('snap');
                getData();
            }),
            onSnapshot(collection(db, "publicUserInfo"), (doc) => {
                console.log('snap');
                getData();
            })
        ];

        const unsubscribeAll = Promise.all(unsubscribeFunctions);

        return () => {
            unsubscribeAll.then(unsubscribeArray => {
                unsubscribeArray.forEach(unsubscribe => unsubscribe());
            });

        };
        // getData();
    }, []);

    useEffect(() => {
        console.log(filterSearchInformation)
    }, [filterSearchInformation])


    const AddField = async () => {
        var collectionTopic = document.getElementById("collection").value;
        var Subcollection = document.getElementById("Subcollection").value;
        var documentID = document.getElementById("document-id").value;
        var fields = document.getElementById("fields").value;
        var fieldsInput = document.getElementById("fieldsInput").value;
        var targetInputType = document.getElementById("targetInputSelect").value;
        const obj = {};
        if (targetInputType == "Number") {
            obj[fields] = (fieldsInput * 1);
        }
        else if (targetInputType == "Boolean") {
            if (fieldsInput.toUpperCase() == "FALSE") {
                obj[fields] = false;
            }
            else {
                obj[fields] = true;
            }
        }
        else {
            obj[fields] = fieldsInput;
        }
        if (documentID == "") {
            console.log(documentID);
            var allDoc = await getDocs(collection(db, collectionTopic));
            allDoc.docs.map(async (indivID) => {
                await updateDoc(doc(db, collectionTopic, indivID.id), obj).catch(error => console.log(error));
            })
        }
        else {
            await updateDoc(doc(db, collectionTopic, documentID), obj).catch(error => console.log(error));
        }
    }

    const upgradeEnterprise = async () => {
        var leader = prompt("Please enter the leader UID");
        var advancedDetails = prompt("Please enter the advanced Details per month");
        var chat = prompt("Please enter the chat per month");
        if (leader != null && advancedDetails != null && chat != null) {
            var allcheckbox = document.querySelectorAll(".premiumCheckbox");
            var teamArray = [];
            for (var i = 0; i < allcheckbox.length; i++) {
                if (allcheckbox[i].checked == true && allcheckbox[i].id != leader) {
                    await updateDoc(doc(db, 'userInfo', allcheckbox[i].id), {
                        membership: "Premium",
                        chatLeft: chat,
                        advancedDetails: advancedDetails,
                        premiumLeader: leader
                    })
                    teamArray.push(allcheckbox[i].id);
                }
            }

            await updateDoc(doc(db, 'userInfo', leader), {
                membership: "Premium",
                chatLeft: chat,
                advancedDetails: advancedDetails,
                premiumLeader: leader
            })

            await setDoc(doc(db, 'premiumInformation', leader), {
                teamMembers: teamArray,
                advancedDetailsCountPerMonth: advancedDetails,
                chatPerMonth: chat
            })
        }

    }

    const sortUser = () => {
        var newSortAllUser = { ...allUser };
        newSortAllUser = Object.values(newSortAllUser);

        // Custom sorting function
        newSortAllUser.sort((a, b) => {
            let timestampA = a.startDate.seconds + a.startDate.nanoseconds / 1e9;
            let timestampB = b.startDate.seconds + b.startDate.nanoseconds / 1e9;

            return timestampB - timestampA;
        });

        setAllUser(newSortAllUser);
    };

    const approveListingRejectPreferred = async (id, partyUID, status, listingName, location, postalCode) => {
        if (status == true) {
            await getDoc(doc(db, 'publicUserInfo', partyUID)).then(async (resDoc) => {

                const commonData = doc(db, "commonData", 'information');
                const commonSnap = await getDoc(commonData);
                if (commonSnap.exists()) {
                    console.log("Document data:", commonSnap.data().totalProjectCount);
                    await valueOfBus(id).then(async (res) => {
                        console.log(res);
                        // console.log('return value', res);t
                        await setDoc(doc(db, `commonData`, 'information'), {
                            totalProjectCount: commonSnap.data().totalProjectCount + 1,
                            totalCompanyValue: res
                        });
                        await updateDoc(doc(db, "projects", id), {
                            status: "leasing",
                            timePosted: new Date(),
                            preferredListing: false
                        }).then(async () => {
                            var reason = prompt("Key in the reason");
                            await getDocs(collection(db, `projects/${id}/PreferredDetails`)).then((allDoc) => {
                                allDoc.forEach((indivDoc) => {
                                    console.log(indivDoc);
                                    deleteDoc(doc(db, `projects/${id}/PreferredDetails`, indivDoc.id)).then(async () => {
                                        if (postalCode != "") {
                                            const requestOptions = {
                                                method: 'POST',
                                                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                                                body: JSON.stringify({ data: { postalCode: postalCode, listingUID: id, path: "CommercialRent" } })
                                            };
                                            // await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/nearbyListing', requestOptions).then(data => console.log(data)).then(
                                            //     error => console.log(error)
                                            // );
                                        }

                                        const requestOptions_1 = {
                                            method: 'POST',
                                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                                            body: JSON.stringify({ data: { listingName: listingName, username: resDoc.data().username, email: resDoc.data().email, path: "Approved Rejected Preferred", reason: reason } })
                                        };
                                        await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                                            error => console.log(error)
                                        );
                                    })
                                })
                            })

                        });
                    });

                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
        }
        else {
            alert("listing is not preferred");
        }


    }


    const banListing = async (listingID, type) => {
        if (type == "Commercial") {
            await updateDoc(doc(db, 'projects', listingID), {
                status: "block"
            })
        }
        else {
            await updateDoc(doc(db, 'residentialListing', listingID), {
                status: "block"
            })
        }

    }

    const notApproveResidentialListing = async (id, partyUID, listingName) => {
        console.log(partyUID);
        await getDoc(doc(db, 'publicUserInfo', partyUID)).then(async (resDoc) => {
            if (resDoc.exists()) {
                var reason = prompt("Key in the reason");
                if (reason != "") {
                    await updateDoc(doc(db, 'residentialListing', id), {
                        status: "rejected",
                        rejectedReason: reason
                    }).then(async () => {
                        // const requestOptions_1 = {
                        //     method: 'POST',
                        //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                        //     body: JSON.stringify({ data: { username: resDoc.data().username, email: resDoc.data().email, path: "Rejected Listing", listingName: listingName } })
                        // };
                        // // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                        // // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                        // await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                        //     error => console.log(error)
                        // );
                    })
                }
                else {
                    alert("Invalid");
                }
            }
        })

    }

    const notApproveListing = async (id, partyUID, listingName) => {
        console.log(partyUID);
        await getDoc(doc(db, 'publicUserInfo', partyUID)).then(async (resDoc) => {
            if (resDoc.exists()) {
                var reason = prompt("Key in the reason");
                if (reason != "") {
                    await updateDoc(doc(db, 'projects', id), {
                        status: "rejected",
                        rejectedReason: reason
                    }).then(async () => {
                        const requestOptions_1 = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                            body: JSON.stringify({ data: { username: resDoc.data().username, email: resDoc.data().email, path: "Rejected Listing", listingName: listingName } })
                        };
                        // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                        // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                        await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                            error => console.log(error)
                        );
                    })
                }
                else {
                    alert("Invalid");
                }
            }
        })

    }

    const notApproveListingCommercial = async (id, partyUID, listingName) => {
        console.log(partyUID);
        await getDoc(doc(db, 'publicUserInfo', partyUID)).then(async (resDoc) => {
            if (resDoc.exists()) {
                var reason = prompt("Key in the reason");
                if (reason != "") {
                    await updateDoc(doc(db, 'listingCommercialPurchase', id), {
                        status: "rejected",
                        rejectedReason: reason
                    }).then(async () => {
                        const requestOptions_1 = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                            body: JSON.stringify({ data: { username: resDoc.data().username, email: resDoc.data().email, path: "Rejected Listing", listingName: listingName } })
                        };
                        // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                        // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                        await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                            error => console.log(error)
                        );
                    })
                }
                else {
                    alert("Invalid");
                }
            }
        })

    }

    const notApproveResidentialListingSale = async (id, partyUID, listingName) => {
        console.log(partyUID);
        await getDoc(doc(db, 'publicUserInfo', partyUID)).then(async (resDoc) => {
            if (resDoc.exists()) {
                var reason = prompt("Key in the reason");
                if (reason != "") {
                    await updateDoc(doc(db, 'residentialListingPurchase', id), {
                        status: "rejected",
                        rejectedReason: reason
                    }).then(async () => {
                        const requestOptions_1 = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                            body: JSON.stringify({ data: { username: resDoc.data().username, email: resDoc.data().email, path: "Rejected Listing", listingName: listingName } })
                        };
                        // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                        // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                        await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                            error => console.log(error)
                        );
                    })
                }
                else {
                    alert("Invalid");
                }
            }
        })

    }

    const giveFreeCredit = async () => {
        let allUser = await getDocs(collection(db, 'userInfo'));
        for (let i = 0; i < allUser.docs.length; i++) {
            let eachProfile = allUser.docs[i].data();
            console.log(eachProfile, allUser.docs);
            let newTransactionHistory;
            if (eachProfile.transactionHistory.length < 30) {
                newTransactionHistory = [...eachProfile.transactionHistory];
                newTransactionHistory.push(
                    {
                        'Actions': `+${freeCredits} Credits`,
                        'Activity': `${titleRewards}`,
                        'Date': new Date(),
                        'Type': `Points Activity`
                    }
                )
            }
            else {
                newTransactionHistory = [...eachProfile.transactionHistory];
                newTransactionHistory.shift();
                newTransactionHistory.push(
                    {
                        'Actions': `+${freeCredits} Credits`,
                        'Activity': `${titleRewards}`,
                        'Date': new Date(),
                        'Type': `Points Activity`
                    }
                )
            }

            // console.log(eachProfile.uid);
            let updatedProfile = await updateDoc(doc(db, 'userInfo', eachProfile.uid), {
                'credit': Number(eachProfile.credit) + Number(freeCredits),
                'transactionHistory': newTransactionHistory
            });


        }

        alert("Free Credit Send");
    }

    const notApproveAdvertise = async (id, partyUID, listingName) => {
        console.log(partyUID);
        // await getDoc(doc(db, 'publicUserInfo', partyUID)).then(async (resDoc) => {
        // if (resDoc.exists()) {
        var reason = prompt("Key in the reason");
        if (reason != "") {
            await updateDoc(doc(db, 'advertiseListing', id), {
                status: "rejected",
                rejectedReason: reason
            }).then(async () => {
                // const requestOptions_1 = {
                //     method: 'POST',
                //     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                //     body: JSON.stringify({ data: { username: resDoc.data().username, email: resDoc.data().email, path: "Rejected Listing", listingName: listingName } })
                // };
                // // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
                // // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
                // await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                //     error => console.log(error)
                // );
            })
        }
        else {
            alert("Invalid");
        }
        // }
        // })

    }

    const notApproveAgent = async (targetedProfile) => {
        console.log(targetedProfile);
        var reason = prompt("Key in the reason");
        if (reason != "") {
            var newAgentProfile = targetedProfile.details.agentProfile;
            newAgentProfile.verified = false;
            newAgentProfile.reason = reason;
            await updateDoc(doc(db, 'publicUserInfo', targetedProfile.id), {
                agentProfile: newAgentProfile
            }).then(async () => {

            })
        }
        else {
            alert("Invalid");
        }
    }

    const approveAgent = async (targetedProfile) => {
        console.log(targetedProfile);

        var newAgentProfile = targetedProfile.details.agentProfile;
        newAgentProfile.verified = true;
        await updateDoc(doc(db, 'publicUserInfo', targetedProfile.id), {
            agentProfile: newAgentProfile
        }).then(async () => {

        })

    }

    const approvePropertyAddressList = async (targetedProfile, index, keyName) => {
        console.log(targetedProfile);

        targetedProfile.verified = "approve";
        await updateDoc(doc(db, 'commericalPropertyList', targetedProfile.uid), {
            [keyName]: targetedProfile
        }).then(async () => {
            let targetUserInfo = await getDoc(doc(db, 'userInfo', targetedProfile.uid));
            if (targetUserInfo.data().transactionHistory.length < 30) {
                var newTransactionHistory = [...targetUserInfo.data().transactionHistory];
                newTransactionHistory.push({
                    'Actions': "",
                    "Activity": "Approve Property List",
                    "Date": new Date(),
                    "Type": "Commercial Activity"
                })
            }
            else {
                newTransactionHistory = [...targetUserInfo.data().transactionHistory];
                newTransactionHistory.shift();
                newTransactionHistory.push({
                    'Actions': "",
                    "Activity": "Approve Property List",
                    "Date": new Date(),
                    "Type": "Commercial Activity"
                })
            }
            await updateDoc(doc(db, 'userInfo', targetedProfile.uid), {
                transactionHistory: newTransactionHistory
            })
        })
    }

    const notApprovePropertyAddressList = async (targetedProfile, index, keyName) => {
        console.log(targetedProfile);
        var reason = prompt("Key in the reason");
        if (reason != "") {
            targetedProfile.verified = "notApprove";
            await updateDoc(doc(db, 'commericalPropertyList', targetedProfile.uid), {
                [keyName]: targetedProfile
            }).then(async () => {
                let targetUserInfo = await getDoc(doc(db, 'userInfo', targetedProfile.uid));
                if (targetUserInfo.data().transactionHistory.length < 30) {
                    var newTransactionHistory = [...targetUserInfo.data().transactionHistory];
                    newTransactionHistory.push({
                        'Actions': "",
                        "Activity": "Disapprove Property List",
                        "Date": new Date(),
                        "Type": "Commercial Activity"
                    })
                }
                else {
                    newTransactionHistory = [...targetUserInfo.data().transactionHistory];
                    newTransactionHistory.shift();
                    newTransactionHistory.push({
                        'Actions': "",
                        "Activity": "Disapprove Property List",
                        "Date": new Date(),
                        "Type": "Commercial Activity"
                    })
                }
                await updateDoc(doc(db, 'userInfo', targetedProfile.uid), {
                    transactionHistory: newTransactionHistory
                })
            })
        } else {
            alert("Invalid");
        }
    }

    const approvePropertyAddressListResidential = async (targetedProfile, index, keyName) => {
        console.log(targetedProfile);
        targetedProfile.verified = "approve";
        await updateDoc(doc(db, 'residentialPropertyList', targetedProfile.uid), {
            [keyName]: targetedProfile
        }).then(async () => {
            let targetUserInfo = await getDoc(doc(db, 'userInfo', targetedProfile.uid));
            if (targetUserInfo.data().transactionHistory.length < 30) {
                var newTransactionHistory = [...targetUserInfo.data().transactionHistory];
                newTransactionHistory.push({
                    'Actions': "",
                    "Activity": "Approve Property List",
                    "Date": new Date(),
                    "Type": "Residential Activity"
                })
            }
            else {
                newTransactionHistory = [...targetUserInfo.data().transactionHistory];
                newTransactionHistory.shift();
                newTransactionHistory.push({
                    'Actions': "",
                    "Activity": "Approve Property List",
                    "Date": new Date(),
                    "Type": "Residential Activity"
                })
            }
            await updateDoc(doc(db, 'userInfo', targetedProfile.uid), {
                transactionHistory: newTransactionHistory
            })
        })
    }

    const notApprovePropertyAddressListResidential = async (targetedProfile, index, keyName) => {
        console.log(targetedProfile);
        var reason = prompt("Key in the reason");
        if (reason != "") {
            targetedProfile.verified = "notApprove";
            await updateDoc(doc(db, 'residentialPropertyList', targetedProfile.uid), {
                [keyName]: targetedProfile
            }).then(async () => {
                let targetUserInfo = await getDoc(doc(db, 'userInfo', targetedProfile.uid));
                if (targetUserInfo.data().transactionHistory.length < 30) {
                    var newTransactionHistory = [...targetUserInfo.data().transactionHistory];
                    newTransactionHistory.push({
                        'Actions': "",
                        "Activity": "Disapprove Property List",
                        "Date": new Date(),
                        "Type": "Residential Activity"
                    })
                }
                else {
                    newTransactionHistory = [...targetUserInfo.data().transactionHistory];
                    newTransactionHistory.shift();
                    newTransactionHistory.push({
                        'Actions': "",
                        "Activity": "Disapprove Property List",
                        "Date": new Date(),
                        "Type": "Residential Activity"
                    })
                }
                await updateDoc(doc(db, 'userInfo', targetedProfile.uid), {
                    transactionHistory: newTransactionHistory
                })
            })
        } else {
            alert("Invalid");
        }
    }

    function formatDate(date) {
        const formattedDate = new Date(date);
        const day = formattedDate.getDate();
        const month = formattedDate.getMonth() + 1;
        const year = formattedDate.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const verifiedUser = async (targetedProfile) => {
        await updateDoc(doc(db, 'userInfo', targetedProfile.id), {
            verifiedBefore: true,
            apptDate: deleteField(),
            apptTime: deleteField()
        })
    }

    const banUser = async (targetedProfile) => {

    }

    const userNoShow = async (targetedProfile) => {
        await updateDoc(doc(db, 'userInfo', targetedProfile.id), {
            apptDate: deleteField(),
            apptTime: deleteField()

        })
    }

    const liveAdvertisement = async (indivAdv) => {
        console.log(indivAdv);
        await updateDoc(doc(db, 'advertiseListing', indivAdv.id), {
            status: "leasing"
        }).then(() => {
            alert("live. refresh to see")
        })
    }

    const giveIndivCredit = async (indivUser) => {
        console.log(indivUser);

        // Prompt the user to enter an amount
        var userInput = prompt('Please enter an amount:');

        // Check if the user entered a value
        if (userInput !== null) {
            // Convert the user input to a number
            var amountToAdd = parseFloat(userInput);

            // Check if the conversion is successful
            if (!isNaN(amountToAdd)) {
                // Display the entered amount using alert
                await updateDoc(doc(db, 'userInfo', indivUser.uid), {
                    credit: indivUser.credit + amountToAdd
                }).then(() => {
                    alert("done");
                });
            } else {
                alert('Please enter a valid number.');
            }
        } else {
            // Handle the case where the user canceled the prompt
            alert('You canceled the operation.');
        }
    };



    if (!user) {
        return <Navigate to='/' />
    }
    else {
        return (

            <>
                <div class="flex h-full w-full">
                    <div className="flex h-screen w-16 flex-col justify-between border-e bg-white">
                        <div>


                            <div className="border-t border-gray-100">
                                <div className="px-2">
                                    <ul className="space-y-1 border-t border-gray-100 pt-4">
                                        <li>
                                            <a
                                                onClick={() => ClickOnApprove()}
                                                className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                            >
                                                <ChartBarIcon className="h-5 w-5 opacity-75" fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2" ></ChartBarIcon>
                                                <span
                                                    className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                                                >
                                                    Commercial Rent
                                                </span>
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                onClick={() => ClickOnCommercialSale()}
                                                className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                            >

                                                <i class="fa-solid fa-r h-5 w-5" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}></i>
                                                <span
                                                    className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                                                >

                                                    Commercial Sale
                                                </span>
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                onClick={() => ClickOnResidentialRent()}
                                                className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                            >
                                                <i class="fa-solid fa-p w-5 h-5" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}></i>

                                                <span
                                                    className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                                                >
                                                    Residential Rent
                                                </span>
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                onClick={() => ClickOnResidentialSale()}
                                                className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                            >
                                                <i class="fa-solid fa-rectangle-ad"></i>


                                                <span
                                                    className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                                                >
                                                    Residential Sale
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => ClickOnAdvertisement()}
                                                className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                            >
                                                <HeartIcon className="h-5 w-5 opacity-75" fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"></HeartIcon>


                                                <span
                                                    className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                                                >
                                                    Advertisement
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => ClickOnAppointment()}
                                                className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                            >
                                                <ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5 opacity-75" fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"></ChatBubbleOvalLeftEllipsisIcon>


                                                <span
                                                    className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                                                >
                                                    Appointment
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => ClickOnReport()}
                                                className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                            >
                                                <EnvelopeIcon className="h-5 w-5 opacity-75" fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"></EnvelopeIcon>


                                                <span
                                                    className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                                                >
                                                    Report
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => ClickOnUserAccount()}
                                                className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                            >

                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 opacity-75"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                </svg>

                                                <span
                                                    className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                                                >
                                                    User Account
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => ClickOnEnterprise()}
                                                className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                            >

                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 opacity-75"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                    />
                                                </svg>

                                                <span
                                                    className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                                                >
                                                    User Enterprise
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2">
                            <form action='/'>
                                <button
                                    onClick={handleSignOut}
                                    className="group relative flex w-full justify-center rounded-lg px-2 py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 opacity-75"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                        />
                                    </svg>

                                    <span
                                        className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
                                    >
                                        Logout
                                    </span>
                                </button>
                            </form>
                        </div>
                    </div>
                    <ZoomPicture trigger={zoomPic} setTrigger={setZoomPic} zoomPicURL={zoomPicURL}></ZoomPicture>
                    <Spinner trigger={openLoader} setTrigger={setOpenloader} />
                    {/* <div id="leftAccount">
                        <div id="leftAccountTab">
                            <button className='activeAccount' id="btn-Approve" onClick={() => ClickOnApprove()}>Commercial Rent</button>
                            <button className='account-btn' id="btn-CommercialSale" onClick={() => ClickOnCommercialSale()}>Commercial Sale</button>
                            <button className='account-btn' id="btn-ResidentialRent" onClick={() => ClickOnResidentialRent()}>Residential Rent</button>
                            <button className='account-btn' id="btn-ResidentialSale" onClick={() => ClickOnResidentialSale()}>Residential Sale</button>
                            <button className='account-btn' id="btn-Advertise" onClick={() => ClickOnAdvertisement()}>Advertisement</button>
                            <button className='account-btn' id="btn-Appointment" onClick={() => ClickOnAppointment()}>Appointment</button>
                            <button className='account-btn' id="btn-Report" onClick={() => ClickOnReport()} >Report</button>
                          

                            <button className='account-btn' id="btn-UserAccount" onClick={() => ClickOnUserAccount()} >User Account</button>
                            <button className='account-btn' id="btn-Enterprise" onClick={() => ClickOnEnterprise()} >User Enterprise</button>
                            <button className='account-btn' id="btn-Modify" onClick={() => ClickOnModify()} >Modify on Database</button>

                        </div>
                        <button className='logout-btn' onClick={handleSignOut}>Log Out</button>
                        

                    </div> */}
                    <div className='p-4 xl:ml-5 w-full'>
                        <>
                            {approve == true ?
                                <div id="listingContent">
                                    {
                                        <>
                                            {
                                                pendingBus != null && pendingBus.map((project, index) => {
                                                    console.log(project);
                                                    var postalCodeGiven = project.details.hasOwnProperty("postalCode") ? project.details.postalCode : null;
                                                    console.log(postalCodeGiven);
                                                    console.log(project.details.location);
                                                    return (
                                                        <div class="Card" style={{ height: "auto" }}>
                                                            <Link to={"/commercialRentDetails/" + project.id}>
                                                                <div style={{ position: "relative" }}>

                                                                    <div style={{ position: 'relative' }}>
                                                                        <img id="Card_image" src={project.details.url0.search("https://ik.imagekit.io/NextLoop") >= 0 ? (project.details.url0).replaceAll("tr:h-200,w-300", "tr:h-190,w-280") : (project.details.url0).replaceAll("https://firebasestorage.googleapis.com", "https://ik.imagekit.io/NextLoop/tr:h-190,w-280")}></img>
                                                                        {project.details.propertyAgent == true ? <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#33434dbf', color: '#fff', padding: '3%', borderRadius: '4px', marginTop: "3%", marginRight: "3%", fontSize: "13px" }}>
                                                                            Agent
                                                                        </div> : null}
                                                                        <div id="preferredBackground">
                                                                            <div id="informationPreferredBackground">
                                                                                {console.log(project.details.preferredListing)}
                                                                                {project.details.preferredListing == true ? <p id="Card_preferred">Preferred Listing</p> : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div class="CardContainer">

                                                                    <p id="Card_type">Trade: {project.details.propertyType}</p>
                                                                    {/* {project.details.preferredListing == true ?<p id="Card_preferred">Preferred Listing</p>:null} */}
                                                                    <div style={{ clear: 'both' }}></div>
                                                                    <p id="Card_district"><h4>{project.details.district}</h4></p>
                                                                    <div id="Card_overall">
                                                                        <p style={{ float: "left" }}>Monthly Rental Budget:</p><p style={{ float: "right", paddingRight: "10px", color: "black" }}>${project.details.priceOverall.toLocaleString()}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left" }}>Monthly PSF Budget:</p><p style={{ float: "right", paddingRight: "10px", color: "black" }}>${project.details.pricePerSquareFeet}</p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <div style={{ clear: 'both' }}></div>
                                                            <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => approveListing(project.id, project.details.UID, project.details.preferredListing, project.details.listingName, project.details.location, postalCodeGiven)}>Approve</button>
                                                            <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => approveListingRejectPreferred(project.id, project.details.UID, project.details.preferredListing, project.details.listingName, project.details.location, postalCodeGiven)}>Approve but reject Preferred</button>
                                                            <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => notApproveListing(project.id, project.details.UID, project.details.listingName)}>Not Approve</button>

                                                            <div style={{ clear: 'both' }}></div>
                                                        </div>

                                                    )
                                                })
                                            }
                                            {pendingBusAuto != null && pendingBusAuto.map((project, index) => {
                                                console.log(project);
                                                var postalCodeGiven = project.details.hasOwnProperty("postalCode") ? project.details.postalCode : null;
                                                console.log(postalCodeGiven);
                                                console.log(project.details.location);
                                                return (
                                                    <div class="Card" style={{ height: "auto" }}>
                                                        <Link to={"/commercialRentDetails/" + project.id}>
                                                            <div style={{ position: "relative" }}>

                                                                <div style={{ position: 'relative' }}>
                                                                    <img id="Card_image" src={project.details.url0.search("https://ik.imagekit.io/NextLoop") >= 0 ? (project.details.url0).replaceAll("tr:h-200,w-300", "tr:h-190,w-280") : (project.details.url0).replaceAll("https://firebasestorage.googleapis.com", "https://ik.imagekit.io/NextLoop/tr:h-190,w-280")}></img>
                                                                    {project.details.propertyAgent == true ? <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#33434dbf', color: '#fff', padding: '3%', borderRadius: '4px', marginTop: "3%", marginRight: "3%", fontSize: "13px" }}>
                                                                        Agent
                                                                    </div> : null}
                                                                    <div id="preferredBackground">
                                                                        <div id="informationPreferredBackground">
                                                                            {console.log(project.details.preferredListing)}
                                                                            {project.details.preferredListing == true ? <p id="Card_preferred">Preferred Listing</p> : null}
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                            <div class="CardContainer">

                                                                <p id="Card_type">Trade: {project.details.propertyType}</p>
                                                                {/* {project.details.preferredListing == true ?<p id="Card_preferred">Preferred Listing</p>:null} */}
                                                                <div style={{ clear: 'both' }}></div>
                                                                <p id="Card_district"><h4>{project.details.district}</h4></p>
                                                                <div id="Card_overall">
                                                                    <p style={{ float: "left" }}>Monthly Rental Budget:</p><p style={{ float: "right", paddingRight: "10px", color: "black" }}>${project.details.priceOverall.toLocaleString()}</p>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                    <p style={{ float: "left" }}>Monthly PSF Budget:</p><p style={{ float: "right", paddingRight: "10px", color: "black" }}>${project.details.pricePerSquareFeet}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <div style={{ clear: 'both' }}></div>
                                                        <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => changePendingCommercialRent(project.id, project.details.UID, project.details.preferredListing, project.details.listingName, project.details.location, postalCodeGiven)}>Change Pending</button>


                                                        <div style={{ clear: 'both' }}></div>
                                                    </div>

                                                )
                                            })
                                            }
                                        </>
                                    }
                                </div> : null}
                            {commercialSaleTab ?
                                <div id="listingContent">
                                    {
                                        <>
                                            {
                                                pendingListingSale != null && pendingListingSale.map((project, index) => {
                                                    console.log(project);
                                                    var postalCodeGiven = project.details.hasOwnProperty("postalCode") ? project.details.postalCode : null;
                                                    return (
                                                        <div class="CardPurchase">
                                                            <Link to={"/commercialPurchaseDetails/" + project.id}>
                                                                <div>
                                                                    <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                                                    <div id="cardResidentialUserInfo" >
                                                                        {project.details.propertyAgent == true ? <div style={{ backgroundColor: '#33434dbf', width: "fit-content", color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                                                                            Agent
                                                                        </div> : null}
                                                                        <p style={{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p>
                                                                        <p style={{ fontWeight: "bold" }}>{project.details.district[0]}</p>
                                                                        <p>{project.details.propertyType}</p>
                                                                        <p>{project.details.specificPropertyType}</p>

                                                                    </div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                                {project.details.preferredStatus == true ?
                                                                    <div id="residentialPreferred" style={{ marginTop: "2%", marginBottom: "2%" }}>
                                                                        <p>Preferred Listing</p>
                                                                    </div>
                                                                    :
                                                                    <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white" }}>

                                                                    </div>
                                                                }

                                                                <div class="CardContainer">


                                                                    <div style={{ clear: 'both' }}></div>

                                                                    <div id="Card_overall">
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min PSF Budget:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>${project.details.minFloorSizePSFBudget.toLocaleString()}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Max PSF Budget:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>${project.details.maxFloorSizePSFBudget.toLocaleString()}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>{project.details.minFloorSize} SQFT</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={{ float: "right", color: "black" }}  >{project.details.periodMoveInDateFormatted}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>


                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </Link>
                                                            <div style={{ clear: 'both' }}></div>
                                                            <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => approveListingCommercial(project.id, project.details.UID, project.details.preferredListing, project.details.listingName, project.details.location, postalCodeGiven)}>Approve</button>
                                                            <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => notApproveListingCommercial(project.id, project.details.UID, project.details.listingName)}>Not Approve</button>
                                                            <div style={{ clear: 'both' }}></div>
                                                        </div>

                                                    )
                                                })
                                            }
                                            {
                                                pendingListingSaleAuto != null && pendingListingSaleAuto.map((project, index) => {
                                                    console.log(project);
                                                    var postalCodeGiven = project.details.hasOwnProperty("postalCode") ? project.details.postalCode : null;
                                                    return (
                                                        <div class="CardPurchase">
                                                            <Link to={"/commercialPurchaseDetails/" + project.id}>
                                                                <div>
                                                                    <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                                                    <div id="cardResidentialUserInfo" >
                                                                        {project.details.propertyAgent == true ? <div style={{ backgroundColor: '#33434dbf', width: "fit-content", color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                                                                            Agent
                                                                        </div> : null}
                                                                        <p style={{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p>
                                                                        <p style={{ fontWeight: "bold" }}>{project.details.district[0]}</p>
                                                                        <p>{project.details.propertyType}</p>
                                                                        <p>{project.details.specificPropertyType}</p>

                                                                    </div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                                {project.details.preferredStatus == true ?
                                                                    <div id="residentialPreferred" style={{ marginTop: "2%", marginBottom: "2%" }}>
                                                                        <p>Preferred Listing</p>
                                                                    </div>
                                                                    :
                                                                    <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white" }}>

                                                                    </div>
                                                                }

                                                                <div class="CardContainer">


                                                                    <div style={{ clear: 'both' }}></div>

                                                                    <div id="Card_overall">
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min PSF Budget:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>${project.details.minFloorSizePSFBudget.toLocaleString()}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Max PSF Budget:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>${project.details.maxFloorSizePSFBudget.toLocaleString()}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>{project.details.minFloorSize} SQFT</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={{ float: "right", color: "black" }}  >{project.details.periodMoveInDateFormatted}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>


                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </Link>
                                                            <div style={{ clear: 'both' }}></div>
                                                            <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => changePendingCommercialSale(project.id, project.details.UID, project.details.preferredListing, project.details.listingName, project.details.location, postalCodeGiven)}>Change Pending</button>

                                                            <div style={{ clear: 'both' }}></div>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </div> : null
                            }
                            {residentialRentTab ?
                                <div id="listingContent">
                                    {
                                        <>
                                            {
                                                pendingListingResidential != null && pendingListingResidential.map((project, index) => {
                                                    console.log(project);
                                                    var postalCodeGiven = project.details.hasOwnProperty("postalCode") ? project.details.postalCode : null;
                                                    return (
                                                        <div class="CardResidential">
                                                            <Link to={"/residentialRentDetails/" + project.id}>
                                                                <div>
                                                                    <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                                                    <div id="cardResidentialUserInfo" >
                                                                        {project.details.propertyAgent == true ? <div style={{ backgroundColor: '#33434dbf', width: "fit-content", color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                                                                            Agent
                                                                        </div> : null}
                                                                        <p style={{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p>
                                                                        <p style={{ fontWeight: "bold" }}>{project.details.district}</p>

                                                                        <p>{project.details.propertyTypeResidential}</p>
                                                                        <p>{project.details.specificPropertyType}</p>

                                                                    </div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                                {project.details.preferredStatus == true ?
                                                                    <div id="residentialPreferred">
                                                                        <p>Preferred Listing</p>
                                                                    </div>
                                                                    :
                                                                    <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white" }}>

                                                                    </div>
                                                                }

                                                                <div class="CardContainer">
                                                                    <div id="residentialHuman" style={{ marginTop: "3%", marginBottom: "3%" }}>
                                                                        {project.details.adults == "1" ?
                                                                            <>
                                                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                            </> :
                                                                            project.details.adults == "2" ?
                                                                                <>
                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                </> :
                                                                                project.details.adults == "3" ?
                                                                                    <>
                                                                                        <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                        <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                        <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                    </> :
                                                                                    project.details.adults == "4" ?
                                                                                        <>
                                                                                            <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                            <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                            <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                            <i class="fa-solid fa-person" id="humanIcon" ></i>

                                                                                        </> :
                                                                                        project.details.adults == "5" ?
                                                                                            <>
                                                                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                            </> :
                                                                                            project.details.adults == "6" ?
                                                                                                <>
                                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i></> :
                                                                                                null
                                                                        }
                                                                        {project.details.children == "1" ?
                                                                            <>
                                                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                            </> :
                                                                            project.details.children == "2" ?
                                                                                <>
                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                </> :
                                                                                project.details.children == "3" ?
                                                                                    <>
                                                                                        <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                        <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                        <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                    </> :
                                                                                    project.details.children == "4" ?
                                                                                        <>
                                                                                            <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                            <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                            <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                            <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                        </> :
                                                                                        project.details.children == "5" ?
                                                                                            <>
                                                                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                            </> :
                                                                                            project.details.children == "6" ?
                                                                                                <>
                                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                </> :
                                                                                                null
                                                                        }
                                                                    </div>
                                                                    <div style={{ float: "right", marginTop: "3%", marginBottom: "3%" }}>
                                                                        <p id="residentialBedroom">{project.details.bedrooms != "Entire Unit" ? project.details.bedrooms + " Rooms Only" : "Entire Unit"}</p>
                                                                    </div>



                                                                    <div style={{ clear: 'both' }}></div>

                                                                    <div id="Card_overall">
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Monthly Rental Budget:</p><p style={project.details.status == "closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>${project.details.priceOverall.toLocaleString()}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Furnishing Type:</p><p style={project.details.status == "closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.furnishingType}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={project.details.status == "closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}  >{project.details.periodMoveInDateFormatted}</p>
                                                                        <div style={{ clear: 'both' }}></div>

                                                                    </div>

                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </Link>
                                                            <div style={{ clear: 'both' }}></div>
                                                            <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => approveResidentialListing(project.id, project.details.UID, project.details.preferredListing, project.details.listingName, project.details.location, postalCodeGiven)}>Approve</button>
                                                            <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => notApproveResidentialListing(project.id, project.details.UID, project.details.listingName)}>Not Approve</button>
                                                            <div style={{ clear: 'both' }}></div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                pendingListingResidentialAuto != null && pendingListingResidentialAuto.map((project, index) => {
                                                    console.log(project);
                                                    var postalCodeGiven = project.details.hasOwnProperty("postalCode") ? project.details.postalCode : null;
                                                    return (
                                                        <div class="CardResidential">
                                                            <Link to={"/residentialRentDetails/" + project.id}>
                                                                <div>
                                                                    <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                                                    <div id="cardResidentialUserInfo" >
                                                                        {project.details.propertyAgent == true ? <div style={{ backgroundColor: '#33434dbf', width: "fit-content", color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                                                                            Agent
                                                                        </div> : null}
                                                                        <p style={{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p>
                                                                        <p style={{ fontWeight: "bold" }}>{project.details.district}</p>

                                                                        <p>{project.details.propertyTypeResidential}</p>
                                                                        <p>{project.details.specificPropertyType}</p>

                                                                    </div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                                {project.details.preferredStatus == true ?
                                                                    <div id="residentialPreferred">
                                                                        <p>Preferred Listing</p>
                                                                    </div>
                                                                    :
                                                                    <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white" }}>

                                                                    </div>
                                                                }

                                                                <div class="CardContainer">
                                                                    <div id="residentialHuman" style={{ marginTop: "3%", marginBottom: "3%" }}>
                                                                        {project.details.adults == "1" ?
                                                                            <>
                                                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                            </> :
                                                                            project.details.adults == "2" ?
                                                                                <>
                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                </> :
                                                                                project.details.adults == "3" ?
                                                                                    <>
                                                                                        <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                        <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                        <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                    </> :
                                                                                    project.details.adults == "4" ?
                                                                                        <>
                                                                                            <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                            <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                            <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                            <i class="fa-solid fa-person" id="humanIcon" ></i>

                                                                                        </> :
                                                                                        project.details.adults == "5" ?
                                                                                            <>
                                                                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                            </> :
                                                                                            project.details.adults == "6" ?
                                                                                                <>
                                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                                                                    <i class="fa-solid fa-person" id="humanIcon" ></i></> :
                                                                                                null
                                                                        }
                                                                        {project.details.children == "1" ?
                                                                            <>
                                                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                            </> :
                                                                            project.details.children == "2" ?
                                                                                <>
                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                </> :
                                                                                project.details.children == "3" ?
                                                                                    <>
                                                                                        <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                        <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                        <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                    </> :
                                                                                    project.details.children == "4" ?
                                                                                        <>
                                                                                            <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                            <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                            <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                            <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                        </> :
                                                                                        project.details.children == "5" ?
                                                                                            <>
                                                                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                            </> :
                                                                                            project.details.children == "6" ?
                                                                                                <>
                                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                                                                </> :
                                                                                                null
                                                                        }
                                                                    </div>
                                                                    <div style={{ float: "right", marginTop: "3%", marginBottom: "3%" }}>
                                                                        <p id="residentialBedroom">{project.details.bedrooms != "Entire Unit" ? project.details.bedrooms + " Rooms Only" : "Entire Unit"}</p>
                                                                    </div>



                                                                    <div style={{ clear: 'both' }}></div>

                                                                    <div id="Card_overall">
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Monthly Rental Budget:</p><p style={project.details.status == "closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>${project.details.priceOverall.toLocaleString()}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Furnishing Type:</p><p style={project.details.status == "closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.furnishingType}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={project.details.status == "closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}  >{project.details.periodMoveInDateFormatted}</p>
                                                                        <div style={{ clear: 'both' }}></div>

                                                                    </div>

                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </Link>
                                                            <div style={{ clear: 'both' }}></div>
                                                            <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => changePendingResidentialRent(project.id, project.details.UID, project.details.preferredListing, project.details.listingName, project.details.location, postalCodeGiven)}>Change Pending</button>

                                                            <div style={{ clear: 'both' }}></div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </div> : null
                            }
                            {residentialSaleTab ?
                                <div id="listingContent">
                                    {
                                        <>{
                                            pendingListingResidentialSale != null && pendingListingResidentialSale.map((project, index) => {
                                                console.log(project);
                                                var postalCodeGiven = project.details.hasOwnProperty("postalCode") ? project.details.postalCode : null;
                                                return (
                                                    <div class="CardPurchase">
                                                        <Link to={"/residentialPurchaseDetails/" + project.id}>
                                                            <div >
                                                                <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                                                <div id="cardResidentialUserInfo" >
                                                                    {project.details.propertyAgent == true ? <div style={{ backgroundColor: '#33434dbf', width: "fit-content", color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                                                                        Agent
                                                                    </div> : null}
                                                                    <p style={{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p>
                                                                    <p style={{ fontWeight: "bold" }}>{project.details.district[0]}</p>
                                                                    <p>{project.details.propertyType}</p>

                                                                </div>

                                                            </div>
                                                            <div style={{ clear: 'both' }}></div>
                                                            {project.details.preferredStatus == true ?
                                                                <div id="residentialPreferred">
                                                                    <p>Preferred Listing</p>
                                                                </div>
                                                                :
                                                                <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white" }}>

                                                                </div>
                                                            }

                                                            <div class="CardContainer">

                                                                <div id="Card_overall">

                                                                    <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Budget Overall:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>${project.details.budgetOverall.toLocaleString()}</p>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                    <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.minFloorSize}</p>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                    <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Max Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.maxFloorSize}</p>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                    <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.periodMoveInDateFormatted}</p>
                                                                    <div style={{ clear: 'both' }}></div>
                                                                </div>



                                                            </div>
                                                            <div style={{ clear: 'both' }}></div>
                                                        </Link>
                                                        <div style={{ clear: 'both' }}></div>
                                                        <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => approveResidentialListingSale(project.id, project.details.UID, project.details.preferredListing, project.details.listingName, project.details.location, postalCodeGiven)}>Approve</button>
                                                        <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => notApproveResidentialListingSale(project.id, project.details.UID, project.details.listingName)}>Not Approve</button>
                                                        <div style={{ clear: 'both' }}></div>
                                                    </div>

                                                )
                                            })
                                        }
                                            {
                                                pendingDataResidentialSaleAuto != null && pendingDataResidentialSaleAuto.map((project, index) => {
                                                    console.log(project);
                                                    var postalCodeGiven = project.details.hasOwnProperty("postalCode") ? project.details.postalCode : null;
                                                    return (
                                                        <div class="CardPurchase">
                                                            <Link to={"/residentialPurchaseDetails/" + project.id}>
                                                                <div >
                                                                    <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                                                    <div id="cardResidentialUserInfo" >
                                                                        {project.details.propertyAgent == true ? <div style={{ backgroundColor: '#33434dbf', width: "fit-content", color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                                                                            Agent
                                                                        </div> : null}
                                                                        <p style={{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p>
                                                                        <p style={{ fontWeight: "bold" }}>{project.details.district[0]}</p>
                                                                        <p>{project.details.propertyType}</p>

                                                                    </div>

                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                                {project.details.preferredStatus == true ?
                                                                    <div id="residentialPreferred">
                                                                        <p>Preferred Listing</p>
                                                                    </div>
                                                                    :
                                                                    <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white" }}>

                                                                    </div>
                                                                }

                                                                <div class="CardContainer">

                                                                    <div id="Card_overall">

                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Budget Overall:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>${project.details.budgetOverall.toLocaleString()}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.minFloorSize}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Max Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.maxFloorSize}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.periodMoveInDateFormatted}</p>
                                                                        <div style={{ clear: 'both' }}></div>
                                                                    </div>



                                                                </div>
                                                                <div style={{ clear: 'both' }}></div>
                                                            </Link>
                                                            <div style={{ clear: 'both' }}></div>
                                                            <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => changePendingResidentialSale(project.id, project.details.UID, project.details.preferredListing, project.details.listingName, project.details.location, postalCodeGiven)}>Change Pending</button>

                                                            <div style={{ clear: 'both' }}></div>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </div> : null
                            }

                            {advertisementTab == true ?
                                <div id="listingContent">
                                    {
                                        pendingAdvListing != null && pendingAdvListing.map((project, index) => {
                                            console.log(project);
                                            return (
                                                <div className="CardAdvertiseDivAccount" id={project.id} onClick={() => { setZoomPicURL(project.details.imageURL); setZoomPic(true) }} style={{ height: "auto", flexDirection: "column" }}>
                                                    <div style={{ position: 'relative' }}>
                                                        <img style={{ alignItems: 'center' }} className="advertiseCard" src={project.details.imageURL} alt="Uploaded" />
                                                        <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#33434dbf', color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                                                            Adv
                                                        </div>
                                                    </div>
                                                    <div className="CardContainerAdv">
                                                        <p>Name: {project.userData.displayName}</p>
                                                        <p>HP: {project.userData.phoneNumber}</p>
                                                        <div style={{ clear: 'both' }}></div>
                                                    </div>
                                                    <div style={{ clear: 'both' }}></div>
                                                    <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => approveAdvertise(project.id, project.details.UID, project.details.advertiseName)}>Verified</button>
                                                    <button className='account-btnInsideCard' style={{ marginBottom: "1%" }} onClick={() => notApproveAdvertise(project.id, project.details.UID, project.details.advertiseName)}>Unverified</button>
                                                </div>

                                            )
                                        })
                                    }
                                    {allAdvertisement.length != 0 ?
                                        <div class="bg-gray-200">
                                            <div class="container mx-auto px-4 sm:px-8">
                                                <div class="py-8">
                                                    <div>
                                                        <h2 class="text-2xl font-semibold leading-tight">All Advertisement</h2>
                                                    </div>
                                                    <div class="my-2 flex sm:flex-row flex-col">

                                                    </div>
                                                    <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                                                        <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                                            <table class="min-w-full leading-normal">
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                                            Advertisement
                                                                        </th>
                                                                        <th
                                                                            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                                            User UID
                                                                        </th>
                                                                        <th
                                                                            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                                            Dates
                                                                        </th>
                                                                        <th
                                                                            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                                            Status
                                                                        </th>
                                                                        <th
                                                                            class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                                            Actions
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {allAdvertisement.map((indivAdv) => {
                                                                        console.log(indivAdv)
                                                                        return (
                                                                            <tr>
                                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                                    <div class="flex items-center">
                                                                                        <div class="flex-shrink-0 w-20 h-20">
                                                                                            <img class="w-full h-full"
                                                                                                src={indivAdv.details.imageURL}
                                                                                                alt="" />
                                                                                        </div>
                                                                                        {/* <div class="ml-3">
                                                                                    <p class="text-gray-900 whitespace-no-wrap">
                                                                                        Vera Carpenter
                                                                                    </p>
                                                                                </div> */}
                                                                                    </div>
                                                                                </td>
                                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                                    <p class="text-gray-900 whitespace-no-wrap">{indivAdv.details.UID}</p>
                                                                                </td>
                                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                                    {indivAdv.details.activatedDate != null ?
                                                                                        indivAdv.details.activatedDate.map((indivDates) => {
                                                                                            return (
                                                                                                <p class="text-gray-900 whitespace-no-wrap">
                                                                                                    {indivDates}
                                                                                                </p>
                                                                                            )
                                                                                        })
                                                                                        : null}

                                                                                </td>
                                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                                    {indivAdv.details.status == "pending" ?
                                                                                        <span
                                                                                            class="relative inline-block px-3 py-1 font-semibold text-orange-900 leading-tight">
                                                                                            <span aria-hidden
                                                                                                class="absolute inset-0 bg-orange-200 opacity-50 rounded-full"></span>
                                                                                            <span class="relative">pending</span>
                                                                                        </span>
                                                                                        : indivAdv.details.status == "leasing" ?
                                                                                            <span
                                                                                                class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                                                                                <span aria-hidden
                                                                                                    class="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                                                                                                <span class="relative">leasing</span>
                                                                                            </span>
                                                                                            :
                                                                                            indivAdv.details.status == "approved" ?
                                                                                                <span
                                                                                                    class="relative inline-block px-3 py-1 font-semibold text-gray-900 leading-tight">
                                                                                                    <span aria-hidden
                                                                                                        class="absolute inset-0 bg-slate-400 opacity-50 rounded-full"></span>
                                                                                                    <span class="relative">approve</span>
                                                                                                </span>
                                                                                                : <span
                                                                                                    class="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                                                                                                    <span aria-hidden
                                                                                                        class="absolute inset-0 bg-red-200 opacity-50 rounded-full"></span>
                                                                                                    <span class="relative">rejected</span>
                                                                                                </span>
                                                                                    }



                                                                                </td>
                                                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                                    <button className='bg-blue-500 p-2 text-white hover:shadow-lg text-xs font-thin' onClick={() => liveAdvertisement(indivAdv)}>Live</button>



                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}


                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null}
                                        <div>
                                            <p>Manually Portover</p>
                                            <button class="defaultUploadBtn" onClick={() => { uploadSelfImage.current.click() }} style={{ width: "90%", marginLeft: "5%" }}>Upload Images</button>
                                            
                                            <input ref={uploadSelfImage} id="file-upload" type="file" accept=".png, .jpg, .jpeg" style={{ display: "none" }} onChange={(event) => { setPhotoUpload(event) }} />
                                            <button className='boostBtn' onClick={() => proceedPortingPhoto()}>Proceed</button>
                                        </div>
                                </div> : null}



                            {appointmentTab == true ?
                                <div id="listingContent">
                                    {
                                        appointmentList.length != 0 ?
                                            <>
                                                <div style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #ccc", justifyContent: "space-evenly" }}>
                                                    <div style={{ margin: "1%", padding: "5px 5px" }}>
                                                        <p style={{ fontWeight: "bold", marginBottom: "1%" }}>
                                                            {formatDate(new Date().setDate(new Date().getDate() + 1))}
                                                        </p>
                                                        <p style={appointmentList['1']['1300'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1300: <span>{appointmentList['1']['1300'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['1']['1320'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1320: <span>{appointmentList['1']['1320'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['1']['1340'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1340: <span>{appointmentList['1']['1340'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['1']['1400'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1400: <span>{appointmentList['1']['1400'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['1']['1420'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1420: <span>{appointmentList['1']['1420'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['1']['1440'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1440: <span>{appointmentList['1']['1440'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['1']['1500'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1500: <span>{appointmentList['1']['1500'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['1']['1520'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1520: <span>{appointmentList['1']['1520'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['1']['1540'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1540: <span>{appointmentList['1']['1540'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['1']['1600'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1600: <span>{appointmentList['1']['1600'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['1']['1620'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1620: <span>{appointmentList['1']['1620'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['1']['1640'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1640: <span>{appointmentList['1']['1640'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['1']['1700'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1700: <span>{appointmentList['1']['1700'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                    </div>
                                                    <div style={{ margin: "1%", padding: "5px 5px" }}>
                                                        <p style={{ fontWeight: "bold", marginBottom: "1%" }}>
                                                            {formatDate(new Date().setDate(new Date().getDate() + 2))}
                                                        </p>
                                                        <p style={appointmentList['2']['1300'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1300: <span>{appointmentList['2']['1300'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['2']['1320'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1320: <span>{appointmentList['2']['1320'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['2']['1340'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1340: <span>{appointmentList['2']['1340'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['2']['1400'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1400: <span>{appointmentList['2']['1400'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['2']['1420'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1420: <span>{appointmentList['2']['1420'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['2']['1440'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1440: <span>{appointmentList['2']['1440'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['2']['1500'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1500: <span>{appointmentList['2']['1500'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['2']['1520'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1520: <span>{appointmentList['2']['1520'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['2']['1540'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1540: <span>{appointmentList['2']['1540'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['2']['1600'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1600: <span>{appointmentList['2']['1600'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['2']['1620'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1620: <span>{appointmentList['2']['1620'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['2']['1640'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1640: <span>{appointmentList['2']['1640'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['2']['1700'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1700: <span>{appointmentList['2']['1700'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                    </div>
                                                    <div style={{ margin: "1%", padding: "5px 5px" }}>
                                                        <p style={{ fontWeight: "bold", marginBottom: "1%" }}>
                                                            {formatDate(new Date().setDate(new Date().getDate() + 3))}
                                                        </p>
                                                        <p style={appointmentList['3']['1300'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1300: <span>{appointmentList['3']['1300'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['3']['1320'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1320: <span>{appointmentList['3']['1320'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['3']['1340'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1340: <span>{appointmentList['3']['1340'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['3']['1400'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1400: <span>{appointmentList['3']['1400'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['3']['1420'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1420: <span>{appointmentList['3']['1420'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['3']['1440'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1440: <span>{appointmentList['3']['1440'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['3']['1500'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1500: <span>{appointmentList['3']['1500'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['3']['1520'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1520: <span>{appointmentList['3']['1520'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['3']['1540'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1540: <span>{appointmentList['3']['1540'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['3']['1600'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1600: <span>{appointmentList['3']['1600'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['3']['1620'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1620: <span>{appointmentList['3']['1620'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['3']['1640'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1640: <span>{appointmentList['3']['1640'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['3']['1700'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1700: <span>{appointmentList['3']['1700'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                    </div>
                                                    <div style={{ margin: "1%", padding: "5px 5px" }}>
                                                        <p style={{ fontWeight: "bold", marginBottom: "1%" }}>
                                                            {formatDate(new Date().setDate(new Date().getDate() + 4))}
                                                        </p>
                                                        <p style={appointmentList['4']['1300'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1300: <span>{appointmentList['4']['1300'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['4']['1320'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1320: <span>{appointmentList['4']['1320'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['4']['1340'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1340: <span>{appointmentList['4']['1340'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['4']['1400'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1400: <span>{appointmentList['4']['1400'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['4']['1420'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1420: <span>{appointmentList['4']['1420'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['4']['1440'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1440: <span>{appointmentList['4']['1440'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['4']['1500'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1500: <span>{appointmentList['4']['1500'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['4']['1520'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1520: <span>{appointmentList['4']['1520'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['4']['1540'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1540: <span>{appointmentList['4']['1540'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['4']['1600'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1600: <span>{appointmentList['4']['1600'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['4']['1620'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1620: <span>{appointmentList['4']['1620'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['4']['1640'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1640: <span>{appointmentList['4']['1640'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['4']['1700'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1700: <span>{appointmentList['4']['1700'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #ccc", justifyContent: "space-evenly" }}>
                                                    <div style={{ margin: "1%", padding: "5px 5px" }}>
                                                        <p style={{ fontWeight: "bold", marginBottom: "1%" }}>
                                                            {formatDate(new Date().setDate(new Date().getDate() + 5))}
                                                        </p>
                                                        <p style={appointmentList['5']['1300'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1300: <span>{appointmentList['5']['1300'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['5']['1320'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1320: <span>{appointmentList['5']['1320'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['5']['1340'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1340: <span>{appointmentList['5']['1340'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['5']['1400'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1400: <span>{appointmentList['5']['1400'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['5']['1420'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1420: <span>{appointmentList['5']['1420'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['5']['1440'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1440: <span>{appointmentList['5']['1440'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['5']['1500'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1500: <span>{appointmentList['5']['1500'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['5']['1520'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1520: <span>{appointmentList['5']['1520'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['5']['1540'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1540: <span>{appointmentList['5']['1540'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['5']['1600'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1600: <span>{appointmentList['5']['1600'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['5']['1620'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1620: <span>{appointmentList['5']['1620'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['5']['1640'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1640: <span>{appointmentList['5']['1640'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['5']['1700'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1700: <span>{appointmentList['5']['1700'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                    </div>
                                                    <div style={{ margin: "1%", padding: "5px 5px" }}>
                                                        <p style={{ fontWeight: "bold", marginBottom: "1%" }}>
                                                            {formatDate(new Date().setDate(new Date().getDate() + 6))}
                                                        </p>
                                                        <p style={appointmentList['6']['1300'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1300: <span>{appointmentList['6']['1300'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['6']['1320'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1320: <span>{appointmentList['6']['1320'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['6']['1340'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1340: <span>{appointmentList['6']['1340'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['6']['1400'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1400: <span>{appointmentList['6']['1400'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['6']['1420'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1420: <span>{appointmentList['6']['1420'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['6']['1440'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1440: <span>{appointmentList['6']['1440'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['6']['1500'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1500: <span>{appointmentList['6']['1500'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['6']['1520'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1520: <span>{appointmentList['6']['1520'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['6']['1540'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1540: <span>{appointmentList['6']['1540'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['6']['1600'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1600: <span>{appointmentList['6']['1600'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['6']['1620'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1620: <span>{appointmentList['6']['1620'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['6']['1640'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1640: <span>{appointmentList['6']['1640'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['6']['1700'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1700: <span>{appointmentList['6']['1700'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                    </div>
                                                    <div style={{ margin: "1%", padding: "5px 5px" }}>
                                                        <p style={{ fontWeight: "bold", marginBottom: "1%" }}>
                                                            {formatDate(new Date().setDate(new Date().getDate() + 7))}
                                                        </p>
                                                        <p style={appointmentList['7']['1300'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1300: <span>{appointmentList['7']['1300'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['7']['1320'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1320: <span>{appointmentList['7']['1320'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['7']['1340'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1340: <span>{appointmentList['7']['1340'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['7']['1400'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1400: <span>{appointmentList['7']['1400'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['7']['1420'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1420: <span>{appointmentList['7']['1420'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['7']['1440'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1440: <span>{appointmentList['7']['1440'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['7']['1500'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1500: <span>{appointmentList['7']['1500'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['7']['1520'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1520: <span>{appointmentList['7']['1520'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['7']['1540'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1540: <span>{appointmentList['7']['1540'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['7']['1600'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1600: <span>{appointmentList['7']['1600'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['7']['1630'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1620: <span>{appointmentList['7']['1620'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['7']['1640'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1640: <span>{appointmentList['7']['1640'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['7']['1700'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1700: <span>{appointmentList['7']['1700'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                    </div>
                                                    <div style={{ margin: "1%", padding: "5px 5px" }}>
                                                        <p style={{ fontWeight: "bold", marginBottom: "1%" }}>
                                                            {formatDate(new Date().setDate(new Date().getDate() + 8))}
                                                        </p>
                                                        <p style={appointmentList['8']['1300'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1300: <span>{appointmentList['8']['1300'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['8']['1320'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1320: <span>{appointmentList['8']['1320'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['8']['1340'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1340: <span>{appointmentList['8']['1340'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['8']['1400'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1400: <span>{appointmentList['8']['1400'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['8']['1420'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1420: <span>{appointmentList['8']['1420'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['8']['1440'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1440: <span>{appointmentList['8']['1440'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['8']['1500'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1500: <span>{appointmentList['8']['1500'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['8']['1520'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1520: <span>{appointmentList['8']['1520'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['8']['1540'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1540: <span>{appointmentList['8']['1540'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['8']['1600'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1600: <span>{appointmentList['8']['1600'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['8']['1620'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1620: <span>{appointmentList['8']['1620'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['8']['1640'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1640: <span>{appointmentList['8']['1640'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['8']['1700'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1700: <span>{appointmentList['8']['1700'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #ccc", justifyContent: "space-evenly" }}>
                                                    <div style={{ margin: "1%", padding: "5px 5px" }}>
                                                        <p style={{ fontWeight: "bold", marginBottom: "1%" }}>
                                                            {formatDate(new Date().setDate(new Date().getDate() + 9))}
                                                        </p>
                                                        <p style={appointmentList['9']['1300'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1300: <span>{appointmentList['9']['1300'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['9']['1320'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1320: <span>{appointmentList['9']['1320'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['9']['1340'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1340: <span>{appointmentList['9']['1340'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['9']['1400'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1400: <span>{appointmentList['9']['1400'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['9']['1420'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1420: <span>{appointmentList['9']['1420'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['9']['1440'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1440: <span>{appointmentList['9']['1440'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['9']['1500'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1500: <span>{appointmentList['9']['1500'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['9']['1520'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1520: <span>{appointmentList['9']['1520'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['9']['1540'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1540: <span>{appointmentList['9']['1540'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['9']['1600'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1600: <span>{appointmentList['9']['1600'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['9']['1620'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1620: <span>{appointmentList['9']['1620'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['9']['1640'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1640: <span>{appointmentList['9']['1640'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['9']['1700'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1700: <span>{appointmentList['9']['1700'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                    </div>
                                                    <div style={{ margin: "1%", padding: "5px 5px" }}>
                                                        <p style={{ fontWeight: "bold", marginBottom: "1%" }}>
                                                            {formatDate(new Date().setDate(new Date().getDate() + 10))}
                                                        </p>
                                                        <p style={appointmentList['10']['1300'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1300: <span>{appointmentList['10']['1300'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['10']['1320'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1320: <span>{appointmentList['10']['1320'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['10']['1340'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1340: <span>{appointmentList['10']['1340'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['10']['1400'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1400: <span>{appointmentList['10']['1400'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['10']['1420'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1420: <span>{appointmentList['10']['1420'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['10']['1440'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1440: <span>{appointmentList['10']['1440'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['10']['1500'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1500: <span>{appointmentList['10']['1500'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['10']['1520'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1520: <span>{appointmentList['10']['1520'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['10']['1540'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1540: <span>{appointmentList['10']['1540'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['10']['1600'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1600: <span>{appointmentList['10']['1600'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['10']['1620'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1620: <span>{appointmentList['10']['1620'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['10']['1640'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1640: <span>{appointmentList['10']['1640'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['10']['1700'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1700: <span>{appointmentList['10']['1700'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                    </div>
                                                    <div style={{ margin: "1%", padding: "5px 5px" }}>
                                                        <p style={{ fontWeight: "bold", marginBottom: "1%" }}>
                                                            {formatDate(new Date().setDate(new Date().getDate() + 11))}
                                                        </p>
                                                        <p style={appointmentList['11']['1300'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1300: <span>{appointmentList['11']['1300'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['11']['1320'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1320: <span>{appointmentList['11']['1320'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['11']['1340'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1340: <span>{appointmentList['11']['1340'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['11']['1400'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1400: <span>{appointmentList['11']['1400'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['11']['1420'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1420: <span>{appointmentList['11']['1420'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['11']['1440'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1440: <span>{appointmentList['11']['1440'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['11']['1500'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1500: <span>{appointmentList['11']['1500'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['11']['1520'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1520: <span>{appointmentList['11']['1520'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['11']['1540'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1540: <span>{appointmentList['11']['1540'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['11']['1600'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1600: <span>{appointmentList['11']['1600'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['11']['1620'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1620: <span>{appointmentList['11']['1620'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['11']['1640'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1640: <span>{appointmentList['11']['1640'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['11']['1700'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1700: <span>{appointmentList['11']['1700'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                    </div>
                                                    <div style={{ margin: "1%", padding: "5px 5px" }}>
                                                        <p style={{ fontWeight: "bold", marginBottom: "1%" }}>
                                                            {formatDate(new Date().setDate(new Date().getDate() + 12))}
                                                        </p>
                                                        <p style={appointmentList['12']['1300'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1300: <span>{appointmentList['12']['1300'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['12']['1320'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1320: <span>{appointmentList['12']['1320'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['12']['1340'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1340: <span>{appointmentList['12']['1340'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['12']['1400'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1400: <span>{appointmentList['12']['1400'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['12']['1420'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1420: <span>{appointmentList['12']['1420'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['12']['1440'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1440: <span>{appointmentList['12']['1440'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['12']['1500'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1500: <span>{appointmentList['12']['1500'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['12']['1520'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1520: <span>{appointmentList['12']['1520'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['12']['1540'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1540: <span>{appointmentList['12']['1540'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['12']['1600'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1600: <span>{appointmentList['12']['1600'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['12']['1620'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1620: <span>{appointmentList['12']['1620'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['12']['1640'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1640: <span>{appointmentList['12']['1640'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                        <p style={appointmentList['12']['1700'] ? { padding: "5px 0px", backgroundColor: "#c1c1c1" } : { padding: "5px 0px" }}>1700: <span>{appointmentList['12']['1700'] == false ? "No Appointment" : "Appointment"}</span></p>
                                                    </div>
                                                </div>


                                            </>
                                            : null

                                    }
                                </div> : null}

                            {report === true ? (
                                <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                                    {allReport.map((indivDoc) => {
                                        console.log(indivDoc);
                                        return (
                                            <>
                                                <div style={{ width: "30%", margin: "1%" }}>
                                                    <p>Listing: {indivDoc.id}</p>
                                                    <p>Report from user UID: {indivDoc.details.userUID.split("_")[0]}</p>
                                                    <p>Message: {indivDoc.details.reportMessage}</p>
                                                    <button onClick={() => { banListing(indivDoc.id, indivDoc.details.listingType) }}>Ban Listing</button>
                                                    {indivDoc.details.listingType == "Commercial" ? <button style={{ marginLeft: "2%" }}><a style={{ textDecoration: "none", color: "black" }} href={`/CommercialDetails/${indivDoc.id}`}>Visit</a></button> : <button style={{ marginLeft: "2%" }}><a style={{ textDecoration: "none", color: "black" }} href={`/ResidentialDetails/${indivDoc.id}`}>Visit</a></button>}
                                                </div>

                                            </>
                                        );
                                    })}
                                </div>
                            ) : null}

                            {/* {actions == true ? <div id="listingContent">
                            <table id="adminTable">
                                <thead>
                                    <tr>
                                        <th>Listing ID</th>
                                        <th>Boost</th>
                                        <th>Status</th>
                                        <th>preferred Listing</th>
                                        <th>Listing Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allListing != null && allListing.map((listing) => {
                                        console.log(listing.id);
                                        // const boundHandleChange = addToChanges.bind(null, listing.id, listing.boost, listing.status);
                                        return (

                                            <tr key={listing.id}>
                                                <td>{listing.id}</td>
                                                <td>{listing.boost == false ? <p>UnBoost</p> : <p>Boost</p>}
                                                  
                                                </td>
                                                <td>{listing.status}<br></br>
                                                   
                                                </td>
                                                <td>{listing.preferredListing == false ? <p>Not Preferred</p> : <p>Preferred</p>}</td>
                                                <td>{listing.listingName}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div> : null} */}

                            {modifyDatabase == true ?
                                <>
                                    <p>Collection:</p>
                                    <input style={{ padding: "8px 10px", width: "100%" }} value={collectionName} onChange={(e) => { setCollectionName(e.target.value) }}></input>
                                    <p>Field Name:</p>
                                    <input style={{ padding: "8px 10px", width: "100%" }} value={fieldName} onChange={(e) => { setFieldName(e.target.value) }}></input>
                                    <p>Field Type:</p>
                                    <select style={{ padding: "8px 10px", width: "100%" }} value={fieldType} onChange={(e) => setFieldType(e.target.value)}>
                                        <option value={"String"}>String</option>
                                        <option value={"Boolean"}>Boolean</option>
                                        <option value={"Number"}>Number</option>
                                    </select>
                                    <p>Target Document ID:</p>
                                    <input style={{ padding: "8px 10px", width: "100%" }} value={targetID} onChange={(e) => setTargetID(e.target.value)}></input>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isAllUser}
                                            onChange={handleAllUserCheckbox}
                                        />
                                        All User
                                    </label>
                                    <button onClick={() => modifyDatabaseButton()}>Proceed</button>

                                </>
                                : null}

                            {userAccountTab ?
                                <>
                                    <div style={{ margin: "1%" }}>

                                        <input value={filterUserAccount} onChange={(e) => setFilterUserAccount(e.target.value)} style={{ width: "70%", padding: "5px 5px", borderRadius: "10px" }} placeholder='Appointment Date (DD/MM/YY) or username'></input>
                                        <select style={{ width: "20%", borderRadius: "10px", padding: "5px 5px" }} value={filterUserAccountCat} onChange={(e) => setFilterUserAccountCat(e.target.value)} >
                                            <option value={"Appointment Date"}>Appointment Date</option>
                                            <option value={"Username"}>Username</option>
                                        </select>
                                        <button style={{ width: "10%", borderRadius: "10px", padding: "5px 5px", backgroundColor: "transparent" }} onClick={() => handleSearch()}>Search</button>
                                    </div>
                                    <p onClick={() => { setSearch(false); setFilteredSearchInformation([]) }}>Clear</p>
                                    <div style={{ margin: "1%" }}>
                                        {search && filterSearchInformation.length != 0 ?
                                            <>
                                                {filterSearchInformation.map((indivFilterInformation) => {
                                                    return (
                                                        <div>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                <div style={{ width: "50%" }}>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Username: </span>{indivFilterInformation.publicUserInfo.details.username}</p>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Email: </span>{indivFilterInformation.publicUserInfo.details.email}</p>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Membership: </span>{indivFilterInformation.details.membership}</p>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Subscription ID: </span>{indivFilterInformation.details.subscriptionID}</p>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Start Date: </span>{new Date(indivFilterInformation.details.startDate.seconds * 1000).toLocaleDateString("en-GB")}</p>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Subscription Counter: </span>{indivFilterInformation.details.subscriptionCounter}</p>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Chat Connections: </span>{indivFilterInformation.details.chatLeft}</p>
                                                                </div>
                                                                <div style={{ width: "50%" }}>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Advanced Details: </span>{indivFilterInformation.details.advancedDetails}</p>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Role: </span>{indivFilterInformation.details.userType.map((indivRole, index) => {
                                                                        if (index != indivFilterInformation.details.userType.length - 1) {
                                                                            return (
                                                                                <span>{indivRole} | </span>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <span>{indivRole}</span>
                                                                            )
                                                                        }

                                                                    })}</p>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Verified Before: </span>{indivFilterInformation.details.verifiedBefore == false ? "No" : "Yes"}</p>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Launched Promotion: </span>{indivFilterInformation.details.launchedPromotionCode == false ? "No" : "Used"}</p>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Appointment Date: </span>{indivFilterInformation.details.hasOwnProperty('apptDate') ? indivFilterInformation.details.apptDate : null}</p>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Appointment Time: </span>{indivFilterInformation.details.hasOwnProperty('apptTime') ? indivFilterInformation.details.apptTime : null}</p>
                                                                    <p style={{ padding: "8px 0px" }}><span style={{ fontWeight: "bold" }}>Mobile: </span>{indivFilterInformation.details.phoneNumber}</p>
                                                                </div>
                                                            </div>
                                                            <button style={{ padding: "5px 5px", borderRadius: "10px", border: "none", cursor: "pointer" }} onClick={() => verifiedUser(indivFilterInformation)}>Verified User as a landlord / MT</button>
                                                            <button style={{ padding: "5px 5px", borderRadius: "10px", border: "none", marginLeft: "5px", cursor: "pointer" }} onClick={() => banUser(indivFilterInformation)}>Ban user</button>
                                                            <button style={{ padding: "5px 5px", borderRadius: "10px", border: "none", marginLeft: "5px", cursor: "pointer" }} onClick={() => userNoShow(indivFilterInformation)}>No show</button>

                                                            <div style={{ marginTop: "1%", borderTop: "1px solid #c1c1c1" }}>
                                                                <p style={{ fontWeight: "bold" }}>Property Profile</p>



                                                                {indivFilterInformation.details.hasOwnProperty('propertyAddressList') ?
                                                                    indivFilterInformation.details.propertyAddressList.map((indivPropertyProfile, index) => {
                                                                        if (indivPropertyProfile.verified == "pending" && indivPropertyProfile.agentRepresented == false) {
                                                                            var targetedProfile = indivFilterInformation;
                                                                            return (
                                                                                <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                                    <p style={{ fontWeight: "bold" }}>Commercial Property Profile</p>
                                                                                    <p>Username: {indivFilterInformation.publicUserInfo.details.username}</p>
                                                                                    <p>Agent Represented: {indivPropertyProfile.agentRepresented == true ? "Yes" : "No"}</p>
                                                                                    <p>Name: {indivPropertyProfile.propertyCompanyName}</p>
                                                                                    <p>District: {indivPropertyProfile.district}</p>
                                                                                    <p>Address: {indivPropertyProfile.propertyAddress}</p>
                                                                                    <p>Unit: {indivPropertyProfile.propertyUnitNumber}</p>
                                                                                    <p>Postal Code: {indivPropertyProfile.postalCode}</p>
                                                                                    <button onClick={() => approvePropertyAddressList(targetedProfile, index)}>Approve</button>
                                                                                    <button onClick={() => notApprovePropertyAddressList(targetedProfile, index)}>Not Approve</button>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                                    <p style={{ fontWeight: "bold" }}>Commercial Property Profile</p>

                                                                                    <p>Username: {indivFilterInformation.publicUserInfo.details.username}</p>
                                                                                    <p>Agent Represented: {indivPropertyProfile.agentRepresented == true ? "Yes" : "No"}</p>
                                                                                    <p>Name: {indivPropertyProfile.propertyCompanyName}</p>
                                                                                    <p>District: {indivPropertyProfile.district}</p>
                                                                                    <p>Address: {indivPropertyProfile.propertyAddress}</p>
                                                                                    <p>Unit: {indivPropertyProfile.propertyUnitNumber}</p>
                                                                                    <p>Postal Code: {indivPropertyProfile.postalCode}</p>
                                                                                    <p>Verified: {indivPropertyProfile.verified}</p>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                    : null}


                                                                {indivFilterInformation.details.hasOwnProperty('propertyAddressListResidential') ?
                                                                    indivFilterInformation.details.propertyAddressListResidential.map((indivPropertyProfile, index) => {
                                                                        if (indivPropertyProfile.verified == "pending" && indivPropertyProfile.agentRepresented == false) {
                                                                            var targetedProfile = indivFilterInformation;
                                                                            return (
                                                                                <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                                    <p style={{ fontWeight: "bold" }}>Residential Property Profile</p>
                                                                                    <p>Username: {indivFilterInformation.publicUserInfo.details.username}</p>
                                                                                    <p>Agent Represented: {indivPropertyProfile.agentRepresented == true ? "Yes" : "No"}</p>
                                                                                    <p>Name: {indivPropertyProfile.propertyCompanyName}</p>
                                                                                    <p>District: {indivPropertyProfile.district}</p>
                                                                                    <p>Address: {indivPropertyProfile.propertyAddress}</p>
                                                                                    <p>Unit: {indivPropertyProfile.propertyUnitNumber}</p>
                                                                                    <p>Postal Code: {indivPropertyProfile.postalCode}</p>
                                                                                    <p>Company: {indivPropertyProfile.propertyCompanyName}</p>
                                                                                    <button onClick={() => { approvePropertyAddressListResidential(targetedProfile, index) }}>Approve</button>
                                                                                    <button onClick={() => { notApprovePropertyAddressListResidential(targetedProfile, index) }}>Not Approve</button>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        else {

                                                                            var targetedProfile = indivFilterInformation;
                                                                            return (
                                                                                <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                                    <p style={{ fontWeight: "bold" }}>Residential Property Profile</p>
                                                                                    <p>Username: {indivFilterInformation.publicUserInfo.details.username}</p>
                                                                                    <p>Agent Represented: {indivPropertyProfile.agentRepresented == true ? "Yes" : "No"}</p>
                                                                                    <p>Name: {indivPropertyProfile.propertyCompanyName}</p>
                                                                                    <p>District: {indivPropertyProfile.district}</p>
                                                                                    <p>Address: {indivPropertyProfile.propertyAddress}</p>
                                                                                    <p>Unit: {indivPropertyProfile.propertyUnitNumber}</p>
                                                                                    <p>Postal Code: {indivPropertyProfile.postalCode}</p>
                                                                                    <p>Company: {indivPropertyProfile.propertyCompanyName}</p>
                                                                                </div>
                                                                            )

                                                                        }
                                                                    })
                                                                    : null}
                                                            </div>

                                                            {indivFilterInformation.details.userType.includes("Agent") ?
                                                                indivFilterInformation.publicUserInfo.details.agentProfile.hasOwnProperty("reason") ?
                                                                    <>
                                                                        <p style={{ fontWeight: "bold" }}>Agent Profile</p>
                                                                        <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                            <p>Username: {indivFilterInformation.publicUserInfo.details.username}</p>
                                                                            <p>CEA Name: {indivFilterInformation.publicUserInfo.details.agentProfile.CEAName}</p>
                                                                            <p>CEA Number: {indivFilterInformation.publicUserInfo.details.agentProfile.CEANumber}</p>
                                                                            <p>CEA Agency: {indivFilterInformation.publicUserInfo.details.agentProfile.CEAAgency}</p>
                                                                            <p>CEA Agency Number: {indivFilterInformation.publicUserInfo.details.agentProfile.CEAAgencyNumber}</p>
                                                                            <p>CEA HP: {indivFilterInformation.publicUserInfo.details.agentProfile.CEAHP}</p>
                                                                            <p>Reason of Reject: {indivFilterInformation.publicUserInfo.details.agentProfile.reason}</p>
                                                                        </div>
                                                                    </> :
                                                                    indivFilterInformation.publicUserInfo.details.agentProfile.verified == true ?
                                                                        <>
                                                                            <p style={{ fontWeight: "bold" }}>Agent Profile</p>
                                                                            <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                                <p>Username: {indivFilterInformation.publicUserInfo.details.username}</p>
                                                                                <p>CEA Name: {indivFilterInformation.publicUserInfo.details.agentProfile.CEAName}</p>
                                                                                <p>CEA Number: {indivFilterInformation.publicUserInfo.details.agentProfile.CEANumber}</p>
                                                                                <p>CEA Agency: {indivFilterInformation.publicUserInfo.details.agentProfile.CEAAgency}</p>
                                                                                <p>CEA Agency Number: {indivFilterInformation.publicUserInfo.details.agentProfile.CEAAgencyNumber}</p>
                                                                                <p>CEA HP: {indivFilterInformation.publicUserInfo.details.agentProfile.CEAHP}</p>
                                                                                <p>Verified: {indivFilterInformation.publicUserInfo.details.agentProfile.verified == true ? "Yes" : "No"}</p>
                                                                            </div>
                                                                        </> :

                                                                        <>
                                                                            <p style={{ fontWeight: "bold" }}>Agent Profile</p>
                                                                            <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                                <p>Username: {indivFilterInformation.publicUserInfo.details.username}</p>
                                                                                <p>CEA Name: {indivFilterInformation.publicUserInfo.details.agentProfile.CEAName}</p>
                                                                                <p>CEA Number: {indivFilterInformation.publicUserInfo.details.agentProfile.CEANumber}</p>
                                                                                <p>CEA Agency: {indivFilterInformation.publicUserInfo.details.agentProfile.CEAAgency}</p>
                                                                                <p>CEA Agency Number: {indivFilterInformation.publicUserInfo.details.agentProfile.CEAAgencyNumber}</p>
                                                                                <p>CEA HP: {indivFilterInformation.publicUserInfo.details.agentProfile.CEAHP}</p>
                                                                                <p>Verified: {indivFilterInformation.publicUserInfo.details.agentProfile.verified == true ? "Yes" : "No"}</p>
                                                                                <button onClick={() => approveAgent(indivFilterInformation.publicUserInfo)}>Approve</button>
                                                                                <button onClick={() => notApproveAgent(indivFilterInformation.publicUserInfo)}>Not Approve</button>
                                                                            </div>
                                                                        </>
                                                                : null


                                                            }
                                                        </div>

                                                    )
                                                })}
                                            </> :
                                            null}
                                        {search == false ? <p style={{ fontWeight: "bold" }}>Agent Profile</p> : null}
                                        {publicUserInformation.length != 0 && search == false ?

                                            publicUserInformation.map((indivPublicUser, index) => {
                                                console.log(indivPublicUser, userInformation, userInformation[index]);
                                                if (indivPublicUser.details.hasOwnProperty('agentProfile')) {
                                                    if (indivPublicUser.details.agentProfile.verified == false && userInformation[index].details.userType.includes("Agent") && (indivPublicUser.details.agentProfile.hasOwnProperty('reason') == false)) {
                                                        var targetedProfile = indivPublicUser;
                                                        return (
                                                            <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                <p>Username: {indivPublicUser.details.username}</p>
                                                                <p>CEA Name: {indivPublicUser.details.agentProfile.CEAName}</p>
                                                                <p>CEA Number: {indivPublicUser.details.agentProfile.CEANumber}</p>
                                                                <p>CEA Agency: {indivPublicUser.details.agentProfile.CEAAgency}</p>
                                                                <p>CEA Agency Number: {indivPublicUser.details.agentProfile.CEAAgencyNumber}</p>
                                                                <p>CEA HP: {indivPublicUser.details.agentProfile.CEAHP}</p>
                                                                <button onClick={() => approveAgent(targetedProfile)}>Approve</button>
                                                                <button onClick={() => notApproveAgent(targetedProfile)}>Not Approve</button>
                                                            </div>
                                                        )
                                                    }
                                                }
                                            }) : null
                                        }
                                        {/* {search == false ? <p style={{ fontWeight: "bold" }}>Property Profile [First Time]</p> : null}
                                        {userInformation.length != 0 && search == false ?
                                            userInformation.map((indivPublicUser) => {
                                                console.log(indivPublicUser)
                                                if (indivPublicUser.details.verifiedBefore == false) {

                                                    if (indivPublicUser.details.hasOwnProperty('propertyAddressList')) {
                                                        for (var i = 0; i < indivPublicUser.details.propertyAddressList.length; i++) {
                                                            if (indivPublicUser.details.propertyAddressList[i].verified == "pending" && indivPublicUser.details.propertyAddressList[i].agentRepresented == false) {
                                                                var targetedProfile = indivPublicUser;
                                                                return (
                                                                    <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                        <p>Commercial Property Profile</p>
                                                                        <p>Username: {indivPublicUser.publicUserInfo.details.username}</p>
                                                                        <p>Name: {indivPublicUser.details.propertyAddressList[i].propertyCompanyName}</p>
                                                                        <p>District: {indivPublicUser.details.propertyAddressList[i].district}</p>
                                                                        <p>Address: {indivPublicUser.details.propertyAddressList[i].propertyAddress}</p>
                                                                        <p>Unit: {indivPublicUser.details.propertyAddressList[i].propertyUnitNumber}</p>
                                                                        <p>Postal Code: {indivPublicUser.details.propertyAddressList[i].postalCode}</p>
                                                                        <button onClick={() => approvePropertyAddressList(targetedProfile, i)}>Approve</button>
                                                                        <button onClick={() => notApprovePropertyAddressList(targetedProfile, i)}>Not Approve</button>
                                                                    </div>
                                                                )
                                                            }
                                                        }

                                                    }

                                                    if (indivPublicUser.details.hasOwnProperty('propertyAddressListResidential')) {
                                                        for (var i = 0; i < indivPublicUser.details.propertyAddressListResidential.length; i++) {
                                                            if (indivPublicUser.details.propertyAddressListResidential[i].verified == "pending" && indivPublicUser.details.propertyAddressListResidential[i].agentRepresented == false) {
                                                                var targetedProfile = indivPublicUser;
                                                                return (
                                                                    <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                        <p>Residential Property Profile</p>
                                                                        <p>Username: {indivPublicUser.publicUserInfo.details.username}</p>
                                                                        <p>Name: {indivPublicUser.details.propertyAddressListResidential[i].propertyCompanyName}</p>
                                                                        <p>District: {indivPublicUser.details.propertyAddressListResidential[i].district}</p>
                                                                        <p>Address: {indivPublicUser.details.propertyAddressListResidential[i].propertyAddress}</p>
                                                                        <p>Unit: {indivPublicUser.details.propertyAddressListResidential[i].propertyUnitNumber}</p>
                                                                        <p>Postal Code: {indivPublicUser.details.propertyAddressListResidential[i].postalCode}</p>
                                                                        <p>Company: {indivPublicUser.details.propertyAddressListResidential[i].propertyCompanyName}</p>
                                                                        <button onClick={() => { approvePropertyAddressListResidential(targetedProfile, i) }}>Approve</button>
                                                                        <button onClick={() => { notApprovePropertyAddressListResidential(targetedProfile, i) }}>Not Approve</button>
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    }
                                                }
                                            }) : null
                                        }
                                        {search == false ? <p style={{ fontWeight: "bold" }}>Property Profile [Verified Before]</p> : null}
                                        {userInformation.length != 0 && search == false ?
                                            userInformation.map((indivPublicUser) => {
                                                console.log(indivPublicUser.publicUserInfo)
                                                if (indivPublicUser.details.verifiedBefore == true) {

                                                    if (indivPublicUser.details.hasOwnProperty('propertyAddressList')) {
                                                        for (var i = 0; i < indivPublicUser.details.propertyAddressList.length; i++) {
                                                            if (indivPublicUser.details.propertyAddressList[i].verified == "pending" && indivPublicUser.details.propertyAddressList[i].agentRepresented == false) {
                                                                var targetedProfile = indivPublicUser;
                                                                return (
                                                                    <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                        <p>Commercial Property Profile</p>
                                                                        <p>Username: {indivPublicUser.publicUserInfo.details.username}</p>
                                                                        <p>Name: {indivPublicUser.details.propertyAddressList[i].propertyCompanyName}</p>
                                                                        <p>District: {indivPublicUser.details.propertyAddressList[i].district}</p>
                                                                        <p>Address: {indivPublicUser.details.propertyAddressList[i].propertyAddress}</p>
                                                                        <p>Unit: {indivPublicUser.details.propertyAddressList[i].propertyUnitNumber}</p>
                                                                        <p>Postal Code: {indivPublicUser.details.propertyAddressList[i].postalCode}</p>
                                                                        <button onClick={() => approvePropertyAddressList(targetedProfile, i)}>Approve</button>
                                                                        <button onClick={() => notApprovePropertyAddressList(targetedProfile, i)}>Not Approve</button>
                                                                    </div>
                                                                )
                                                            }
                                                        }

                                                    }

                                                    if (indivPublicUser.details.hasOwnProperty('propertyAddressListResidential')) {
                                                        for (var i = 0; i < indivPublicUser.details.propertyAddressListResidential.length; i++) {
                                                            if (indivPublicUser.details.propertyAddressListResidential[i].verified == "pending" && indivPublicUser.details.propertyAddressListResidential[i].agentRepresented == false) {
                                                                var targetedProfile = indivPublicUser;
                                                                return (
                                                                    <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                        <p>Residential Property Profile</p>
                                                                        <p>Username: {indivPublicUser.publicUserInfo.details.username}</p>
                                                                        <p>Name: {indivPublicUser.details.propertyAddressListResidential[i].propertyCompanyName}</p>
                                                                        <p>District: {indivPublicUser.details.propertyAddressListResidential[i].district}</p>
                                                                        <p>Address: {indivPublicUser.details.propertyAddressListResidential[i].propertyAddress}</p>
                                                                        <p>Unit: {indivPublicUser.details.propertyAddressListResidential[i].propertyUnitNumber}</p>
                                                                        <p>Postal Code: {indivPublicUser.details.propertyAddressListResidential[i].postalCode}</p>
                                                                        <p>Company: {indivPublicUser.details.propertyAddressListResidential[i].propertyCompanyName}</p>
                                                                        <button onClick={() => { approvePropertyAddressListResidential(targetedProfile, i) }}>Approve</button>
                                                                        <button onClick={() => { notApprovePropertyAddressListResidential(targetedProfile, i) }}>Not Approve</button>
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    }
                                                }
                                            }) : null
                                        } */}

                                        {search == false ? <p style={{ fontWeight: "bold" }}>Property Profile [Commercial]</p> : null}
                                        {commercialPropertyList.length != 0 && search == false ?
                                            commercialPropertyList.map((indivCommercialList, index) => {
                                                console.log(indivCommercialList, Object.keys(indivCommercialList))
                                                let keyName = Object.keys(indivCommercialList)[0];
                                                if (Object.keys(indivCommercialList).length != 0) {
                                                    if (indivCommercialList[keyName].verified == "notApprove" && indivCommercialList[keyName].agentRepresented == false) {
                                                        var targetedProfile = indivCommercialList[keyName];
                                                        return (
                                                            <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                <p>Commercial Property Profile</p>
                                                                <p>Username: {targetedProfile.username}</p>
                                                                <p>Property Name: {targetedProfile.propertyCompanyName}</p>
                                                                <p>District: {targetedProfile.district}</p>
                                                                <p>Address: {targetedProfile.propertyAddress}</p>
                                                                <p>Unit: {targetedProfile.propertyUnitNumber}</p>
                                                                <p>Postal Code: {targetedProfile.postalCode}</p>
                                                                {targetedProfile.hasOwnProperty('rentalCost') ? <p>Rental Cost: {targetedProfile.rentalCost}</p> : <p>Selling Price: {targetedProfile.sellingPrice}</p>}
                                                                <button className='middle none center rounded-lg bg-pink-500 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none' onClick={() => approvePropertyAddressList(targetedProfile, index, keyName)}>Approve</button>
                                                                <button className='middle none center rounded-lg bg-pink-500 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none' onClick={() => notApprovePropertyAddressList(targetedProfile, index, keyName)}>Not Approve</button>
                                                            </div>
                                                        )




                                                    }
                                                }
                                            }) : null}
                                        {search == false ? <p style={{ fontWeight: "bold" }}>Property Profile [Residential]</p> : null}
                                        {residentialPropertyList.length != 0 && search == false ?
                                            residentialPropertyList.map((indivResidentialProfile, index) => {
                                                console.log(indivResidentialProfile, Object.keys(indivResidentialProfile))
                                                let keyName = Object.keys(indivResidentialProfile)[0];
                                                if (Object.keys(indivResidentialProfile).length != 0) {
                                                    if (indivResidentialProfile[keyName].verified == "notApprove" && indivResidentialProfile[keyName].agentRepresented == false) {
                                                        var targetedProfile = indivResidentialProfile[keyName];
                                                        return (
                                                            <div style={{ borderBottom: "1px solid #c1c1c1", marginBottom: "1%", padding: "5px 5px" }}>
                                                                <p>Residential Property Profile</p>
                                                                <p>Username: {targetedProfile.username}</p>
                                                                <p>Property Name: {targetedProfile.propertyCompanyName}</p>
                                                                <p>District: {targetedProfile.district}</p>
                                                                <p>Address: {targetedProfile.propertyAddress}</p>
                                                                <p>Unit: {targetedProfile.propertyUnitNumber}</p>
                                                                <p>Postal Code: {targetedProfile.postalCode}</p>
                                                                {targetedProfile.hasOwnProperty('rentalCost') ? <p>Rental Cost: {targetedProfile.rentalCost}</p> : <p>Selling Price: {targetedProfile.sellingPrice}</p>}
                                                                <button className='middle none center rounded-lg bg-pink-500 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none' onClick={() => approvePropertyAddressListResidential(targetedProfile, index, keyName)}>Approve</button>
                                                                <button className='middle none center rounded-lg bg-pink-500 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none' onClick={() => notApprovePropertyAddressListResidential(targetedProfile, index, keyName)}>Not Approve</button>
                                                            </div>
                                                        )




                                                    }
                                                }
                                            }) : null}
                                    </div>

                                </> : null}


                            {enterprise == true ?
                                <>
                                    <div class="table w-full p-2">
                                        <table class="w-full border">
                                            <thead>
                                                <tr class="bg-gray-50 border-b">
                                                    {/* <th class="border-r p-2">
                                                        <input type="checkbox" />
                                                    </th> */}
                                                    <th class="p-2 border-r cursor-pointer text-sm font-thin text-gray-500" onClick={() => sortUser()}>
                                                        <div class="flex items-center justify-center">
                                                            UID
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                                                            </svg>
                                                        </div>
                                                    </th>
                                                    <th class="p-2 border-r cursor-pointer text-sm font-thin text-gray-500">
                                                        <div class="flex items-center justify-center">
                                                            Name
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                                                            </svg>
                                                        </div>
                                                    </th>
                                                    <th class="p-2 border-r cursor-pointer text-sm font-thin text-gray-500">
                                                        <div class="flex items-center justify-center">
                                                            HP
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                                                            </svg>
                                                        </div>
                                                    </th>
                                                    <th class="p-2 border-r cursor-pointer text-sm font-thin text-gray-500">
                                                        <div class="flex items-center justify-center">
                                                            Role
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                                                            </svg>
                                                        </div>
                                                    </th>
                                                    <th class="p-2 border-r cursor-pointer text-sm font-thin text-gray-500">
                                                        <div class="flex items-center justify-center">
                                                            credits
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                                                            </svg>
                                                        </div>
                                                    </th>
                                                    <th class="p-2 border-r cursor-pointer text-sm font-thin text-gray-500">
                                                        <div class="flex items-center justify-center">
                                                            Action
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                                                            </svg>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* <tr class="bg-gray-50 text-center">
                                                    <td class="p-2 border-r">

                                                    </td>
                                                    <td class="p-2 border-r">
                                                        <input type="text" class="border p-1" />
                                                    </td>
                                                    <td class="p-2 border-r">
                                                        <input type="text" class="border p-1" />
                                                    </td>
                                                    <td class="p-2 border-r">
                                                        <input type="text" class="border p-1" />
                                                    </td>
                                                    <td class="p-2 border-r">
                                                        <input type="text" class="border p-1" />
                                                    </td>
                                                    <td class="p-2">
                                                        <input type="text" class="border p-1" />
                                                    </td>


                                                </tr> */}
                                                {allUser != null && allUser.map((indivUser) => {
                                                    return (
                                                        <tr class="bg-gray-100 text-center border-b text-sm text-gray-600">
                                                            {/* <td class="p-2 border-r">
                                                                <input type="checkbox" />
                                                            </td> */}
                                                            <td class="p-2 border-r">{indivUser.id}</td>
                                                            <td class="p-2 border-r">{indivUser.displayName}</td>
                                                            <td class="p-2 border-r">{indivUser.phoneNumber}</td>
                                                            <td class="p-2 border-r">{indivUser.userType[0]}</td>
                                                            <td class="p-2 border-r">{indivUser.credit}</td>
                                                            {/* <td class="p-2 border-r"></td> */}
                                                            <td>
                                                                <a href="#" class="bg-blue-500 p-2 text-white hover:shadow-lg text-xs font-thin" onClick={() => giveIndivCredit(indivUser)}>Give Credit</a>
                                                                <a href="#" class="bg-red-500 p-2 text-white hover:shadow-lg text-xs font-thin">No Actions</a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                {/* <tr class="bg-gray-100 text-center border-b text-sm text-gray-600">
                                                    <td class="p-2 border-r">
                                                        <input type="checkbox" />
                                                    </td>
                                                    <td class="p-2 border-r">2</td>
                                                    <td class="p-2 border-r">Adam Smith</td>
                                                    <td class="p-2 border-r">adam@gmail.com</td>
                                                    <td class="p-2 border-r">Sydney, Australia</td>
                                                    <td>
                                                        <a href="#" class="bg-blue-500 p-2 text-white hover:shadow-lg text-xs font-thin">Edit</a>
                                                        <a href="#" class="bg-red-500 p-2 text-white hover:shadow-lg text-xs font-thin">Remove</a>
                                                    </td>
                                                </tr>
                                                <tr class="bg-gray-100 text-center border-b text-sm text-gray-600">
                                                    <td class="p-2 border-r">
                                                        <input type="checkbox" />
                                                    </td>
                                                    <td class="p-2 border-r">3</td>
                                                    <td class="p-2 border-r">Jean Doe</td>
                                                    <td class="p-2 border-r">jean@gmail.com</td>
                                                    <td class="p-2 border-r">Sydney, Australia</td>
                                                    <td>
                                                        <a href="#" class="bg-blue-500 p-2 text-white hover:shadow-lg text-xs font-thin">Edit</a>
                                                        <a href="#" class="bg-red-500 p-2 text-white hover:shadow-lg text-xs font-thin">Remove</a>
                                                    </td>
                                                </tr>
                                                <tr class="bg-gray-100 text-center border-b text-sm text-gray-600">
                                                    <td class="p-2 border-r">
                                                        <input type="checkbox" />
                                                    </td>
                                                    <td class="p-2 border-r">3</td>
                                                    <td class="p-2 border-r">Jean Doe</td>
                                                    <td class="p-2 border-r">jean@gmail.com</td>
                                                    <td class="p-2 border-r">Sydney, Australia</td>
                                                    <td>
                                                        <a href="#" class="bg-blue-500 p-2 text-white hover:shadow-lg text-xs font-thin">Edit</a>
                                                        <a href="#" class="bg-red-500 p-2 text-white hover:shadow-lg text-xs font-thin">Remove</a>
                                                    </td>
                                                </tr>
                                                <tr class="bg-gray-100 text-center border-b text-sm text-gray-600">
                                                    <td class="p-2 border-r">
                                                        <input type="checkbox" />
                                                    </td>
                                                    <td class="p-2 border-r">3</td>
                                                    <td class="p-2 border-r">Jean Doe</td>
                                                    <td class="p-2 border-r">jean@gmail.com</td>
                                                    <td class="p-2 border-r">Sydney, Australia</td>
                                                    <td>
                                                        <a href="#" class="bg-blue-500 p-2 text-white hover:shadow-lg text-xs font-thin">Edit</a>
                                                        <a href="#" class="bg-red-500 p-2 text-white hover:shadow-lg text-xs font-thin">Remove</a>
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="container mx-auto">
                                        <div class="w-full rounded-lg bg-white border border-gray-300 ">
                                            <div class="flex items-center justify-between border-b">
                                                <div class="p-3 text-gray-700 text-lg font-bold">Give free credits to all user</div>
                                                <div class="p-3 flex">
                                                    {/* <button class="text-slate-800 hover:text-blue-600 text-sm bg-white hover:bg-slate-100 border border-slate-200 rounded-l-lg font-medium px-4 py-2 inline-flex space-x-1 items-center">
                                                        <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                        </svg>
                                                        </span>
                                                        <span>Edit</span>
                                                    </button> */}
                                                    <button class="text-slate-800 hover:text-blue-600 text-sm bg-white hover:bg-slate-100 border border-slate-200 rounded-r-lg font-medium px-4 py-2 inline-flex space-x-1 items-center"
                                                        onClick={() => giveFreeCredit()}>
                                                        {/* <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                            </svg>
                                                        </span> */}

                                                        <span>Send</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="p-3 text-lg text-gray-600">
                                                <input className='w-full' placeholder='Enter amount of credits' value={freeCredits} onChange={(e) => setFreeCredits(e.target.value)}></input>
                                                <input className='w-full' placeholder='Title of rewards' value={titleRewards} onChange={(e) => setTitleRewards(e.target.value)}></input>
                                            </div>

                                        </div>
                                        <div class=" w-full rounded-lg bg-white border border-gray-300 mt-5">
                                            <div class="flex items-center justify-between border-b">
                                                <div class="p-3 text-gray-700 text-lg font-bold">Promotion Code</div>
                                                <div class="p-3 flex">
                                                    {/* <button class="text-slate-800 hover:text-blue-600 text-sm bg-white hover:bg-slate-100 border border-slate-200 rounded-l-lg font-medium px-4 py-2 inline-flex space-x-1 items-center">
                                                        <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                        </svg>
                                                        </span>
                                                        <span>Edit</span>
                                                    </button> */}
                                                    <button class="text-slate-800 hover:text-blue-600 text-sm bg-white hover:bg-slate-100 border border-slate-200 rounded-r-lg font-medium px-4 py-2 inline-flex space-x-1 items-center"
                                                        onClick={() => updatePromotionCode()}>
                                                        {/* <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                            </svg>
                                                        </span> */}

                                                        <span>Update</span>
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="p-3 text-lg text-gray-600">
                                                <input className='w-full' placeholder='Enter amount of credits' value={promotionCode} onChange={(e) => setPromotionCode(e.target.value)}></input>
                                                <input className='w-full' placeholder='Title of rewards' type='number' value={promotionCredit} onChange={(e) => setPromotionCredit(e.target.value)} onWheel={(e) => {
                                                    e.target.blur()

                                                    // Prevent the page/container scrolling
                                                    e.stopPropagation()
                                                }}></input>
                                                <p>Count: {promotionCount}</p>
                                            </div>

                                        </div>
                                    </div>

                                    {/* <div id="listingContent">
                                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                            <div style={{ display: "flex", flexDirection: "row", textAlign: "center", borderBottom: "1px solid #c1c1c1" }}>
                                                <p style={{ width: "20%", padding: "10px 10px", fontWeight: "bold", borderRight: "1px solid #c1c1c1" }}>User UID</p>
                                                <p style={{ width: "20%", padding: "10px 10px", fontWeight: "bold", borderRight: "1px solid #c1c1c1" }}>Name</p>
                                                <p style={{ width: "20%", padding: "10px 10px", fontWeight: "bold", borderRight: "1px solid #c1c1c1" }}>Membership</p>
                                                <p style={{ width: "20%", padding: "10px 10px", fontWeight: "bold", borderRight: "1px solid #c1c1c1" }}>Role</p>
                                                <button style={{ width: "20%", borderRadius: "10px", backgroundColor: "transparent", cursor: "pointer" }} onClick={() => upgradeEnterprise()}>Change to Premium</button>
                                                <button style={{ width: "20%", borderRadius: "10px", backgroundColor: "transparent", cursor: "pointer" }} onClick={() => sortUser()}>Sort</button>
                                            </div>
                                            {allUser != null && allUser.map((indivUser) => {
                                                console.log(indivUser.id);
                                                return (
                                                    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>

                                                        <p style={{ width: "20%", padding: "10px 10px" }}>{indivUser.id}</p>
                                                        <p style={{ width: "20%", padding: "10px 10px" }}>{indivUser.displayName}</p>
                                                        <p style={{ width: "20%", padding: "10px 10px" }}>{indivUser.membership}</p>
                                                        <p style={{ width: "20%", padding: "10px 10px" }}>{indivUser.userType.map((indivRole) => {
                                                            return (
                                                                indivRole + " | "
                                                            )
                                                        })}</p>
                                                        <p style={{ width: "20%", padding: "10px 10px", justifyContent: "center", display: "flex" }}><input className='premiumCheckbox' type="checkbox" id={indivUser.id} name="premiumCheckbox" value={indivUser.displayName} /></p>
                                                        <p style={{ width: "20%", padding: "10px 10px", justifyContent: "center", display: "flex" }}>Empty</p>
                                                    </div>

                                                )
                                            })}

                                        </div>
                                        
                                    </div> */}
                                </> : null
                            }

                        </>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <Footer />

            </>
        )
    }
}

