import React, { useState } from 'react';
import '../css/NewProject.css';
import '../css/Report.css'
import { UserAuth } from '../../AuthContent';
import emailjs from 'emailjs-com';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import { getDoc, setDoc, updateDoc, doc } from 'firebase/firestore';
import { db, auth } from '../../firebase/firebase'

export default function NewProject(props) {
    const { user } = UserAuth();
    const [message, setMessage] = useState('');
    const [showAck, setShowAck] = useState(false);

    if (!props.trigger) {
        return;
    }
    else {
        const sendReport = async () => {
            if (user != null) {
                if (document.getElementById('reportMessage').value != "") {
                    await getDoc(doc(db, 'reportListing', props.listing_id)).then(async (IndivReportListing) => {
                        if (IndivReportListing.exists()) {
                            await updateDoc(doc(db, 'reportListing', props.listing_id), {
                                [user.uid + "_" + (new Date().toISOString())]: {
                                    reportDate: new Date(),
                                    reportMessage: document.getElementById('reportMessage').value,
                                    emailFrom: user.email,
                                    listingType: props.listingType
                                }
                            }).then(() => {
                                setMessage("Thank you! Our team will look into it");
                                setShowAck(true);
                            })
                        }
                        else {
                            await setDoc(doc(db, 'reportListing', props.listing_id), {
                                [user.uid + "_" + (new Date().toISOString())]: {
                                    reportDate: new Date(),
                                    reportMessage: document.getElementById('reportMessage').value,
                                    emailFrom: user.email,
                                    listingType: props.listingType
                                }
                            }).then(() => {
                                setMessage("Thank you! Our team will look into it");
                                setShowAck(true);
                            })
                        }
                    })
                }
                else {
                    alert("Invalid Response");
                    return;
                }


            }
            else {
                setMessage("Kindly log in to transmit a report");
                setShowAck(true);
            }


        }

        return (
            <>
                <AcknowledgeNormal trigger={showAck} setTrigger={setShowAck} message={message} parentTrigger={props.setTrigger} />
                <div className='modalNewProject'>
                    <div className='modal-contentNewProject'>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => props.setTrigger(false)}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>REPORT LISTING</h4>
                        </div>
                        <div className='modal-bodyNewProject'>
                            <center><textarea id="reportMessage" style={{ padding: "5px" }} placeholder=' State what is this report about?' /></center>
                        </div>

                        <div className='modal-footerNewProject'>
                            {/* <button className='reportBtn' onClick={() => props.setTrigger(false)}>Close</button> */}
                            <button className='reportBtn' onClick={() => sendReport()} style={{ marginRight: '1%' }}>Report</button>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

