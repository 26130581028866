import React, { useRef, useEffect, useState } from 'react';
import '../../App.css';
import '../css/AboutUs.css'
import { db } from '../../firebase/firebase'
import { onSnapshot, doc, getDoc } from 'firebase/firestore';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import Footer from '../Footer';




function TermOfService() {

    const chats = useRef(null);
    const { user, deleteUserData } = UserAuth();
    const [paidServices, setPaidServices] = useState(false);
    const [isCommercial, setIsCommercial] = useState(true);
    const [advertiserGuide, setAdvertiserGuide] = useState(false);
    const [tenantGuide, setTenantGuide] = useState(false);

    let unsbscribe;
    const [isFlipped, setFlipped] = useState(false);

    const handleFlip = () => {
        setFlipped(!isFlipped);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {
        console.log("t&s", user);
        const checkingUser = async () => {
            var docsnap = await getDoc(doc(db, 'userInfo', user.uid));
            if (docsnap.exists()) {
                console.log("approved user");
            }
            else {
                // alert("hacked your way through ah");
                deleteUserData();

            }
        }
        if (user != null) {
            if (Object.keys(user).length != 0) {
                checkingUser();
            }
        }
    }, [user])


    const handleToggleClick = () => {

        setIsCommercial(!isCommercial);
    };



    return (
        <>
            <ReactNotifications />
            {/* <div style={{ width: "100%", height: "30vh", margin: "0", borderRadius: "0", background: "none", position: "relative", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", padding: "0" }}>
                <div style={{ position: "absolute", height: "30vh", top: "0", left: "0", right: "0", bottom: "0", opacity: "0.4", backgroundImage: isCommercial ? `url("./commercial.png")` : `url("./residential.png")`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}></div>

                <div id="landingPageLeft" style={{ position: "relative", zIndex: "1", textAlign: "center", top: "0", left: "0", float: "none", padding: "8px 8px", width: "auto", backgroundColor: "rgb(244,245,247,0.7)", borderRadius: "10px", marginBottom: "1%" }}>
                    <p id="landingPageSecondItem" style={{ padding: "0" }}>Everything you need to know</p>


                </div>
                <div className="toggleDivHome" >
                    <button className={`toggleBtn ${isCommercial ? 'active' : ''}`} onClick={handleToggleClick} >
                        Commercial
                    </button>
                    <button className={`toggleBtn ${!isCommercial ? 'active' : ''}`} onClick={handleToggleClick}>
                        Residential
                    </button>
                </div>
                <div style={{ clear: 'both' }}></div>

            </div > */}
            {/* <div class={`flip-box ${isFlipped ? 'flipped' : ''}`} onClick={handleFlip}>
                <div class="flip-box-inner">
                    <div class="flip-box-front">
                        <img id="howItWorkImg" src="./howitwork_1.png" />
                    </div>
                    <div class="flip-box-back">
                        <img id="howItWorkImg" src="./howitwork_2.png" />
                    </div>
                </div>
            </div>

            <div style={{ clear: 'both' }}></div> */}

            <h3 className='mt-2 ml-5 text-2xl font-bold sm:text-3xl'>Everything you need to know</h3>
            <div style={{ padding: "8px 8px", borderBottom: "1px solid #c1c1c1", margin: "1%", cursor: "pointer" }} onClick={() => setPaidServices(!paidServices)}>
                <p style={{ float: "left" }}>Homeowner / Seller / Property Agent / Landlord / Master Tenant</p><i style={{ float: "right" }} onClick={() => setPaidServices(!paidServices)} class={paidServices ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down"}></i>
                <div style={{ clear: 'both' }}></div>
            </div>
            {paidServices ? <>
                {/* <div className='indivRoleSection'>

                    <div className='indivRoleSectionCol' style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign:"center"}}>1</p>
                        <p>Sign up for an account, along with the verification of your mobile number and select [Got Space], followed by your role as property / landlord / master tenant</p>
                    </div>
                    <div className='indivRoleSectionColSpecial' style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign:"center" }}>2</p>
                        <p>Add in your properties' postal codes under Account > Profile and you will be notified when there are new listings within your district(s)</p>
                    </div>

                    <div className='indivRoleSectionCol' style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign:"center"}}>3</p>
                        <p>(Optional step for landlords and master tenant) We strongly encourage all landlords and master tenants to verify your title deed and tenancy agreement respectively. The first verification, regardless of the number of properties / tenancy agreements, will be unlimited. Kindly note that any future verifications will be charged by the number of properties.</p>
                    </div>
                </div>
                <div className='indivRoleSection' style={{marginTop: "1%" }}>
                    <div className='indivRoleSectionCol' style={{ display: "flex", flexDirection: "row", textAlign:"justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign:"center" }}>4</p>
                        <p>Browse listings under our [Listing] tab and shortlist potential buyers / tenants</p>
                    </div>
                    <div className='indivRoleSectionColSpecial' style={{ display: "flex", flexDirection: "row", textAlign:"justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign:"center" }}>5</p>
                        <p>To connect with a buyer / tenant, request to connect via [Drop a message]. You may start exchanging messages once the user accepts the connection<br></br>Tips: you may retract the connection if user is unresponsive to your request</p>
                    </div>
                    <div className='indivRoleSectionCol' style={{ display: "flex", flexDirection: "row", textAlign:"justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign:"center" }}>6</p>
                        <p>You may keep track of your connections and new listings in your district under your dashboard</p>
                    </div>

                </div> */}
                <div>
                    <h1 class="mb-4 text-center font-black text-gray-700">STEPS</h1>
                    <div class="flex">
                        <div class="w-1/3 text-center px-6">
                            <div
                                class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200"
                            >
                                <div
                                    class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.995 24h-1.995c0-3.104.119-3.55-1.761-3.986-2.877-.664-5.594-1.291-6.584-3.458-.361-.791-.601-2.095.31-3.814 2.042-3.857 2.554-7.165 1.403-9.076-1.341-2.229-5.413-2.241-6.766.034-1.154 1.937-.635 5.227 1.424 9.025.93 1.712.697 3.02.338 3.815-.982 2.178-3.675 2.799-6.525 3.456-1.964.454-1.839.87-1.839 4.004h-1.995l-.005-1.241c0-2.52.199-3.975 3.178-4.663 3.365-.777 6.688-1.473 5.09-4.418-4.733-8.729-1.35-13.678 3.732-13.678 4.983 0 8.451 4.766 3.732 13.678-1.551 2.928 1.65 3.624 5.09 4.418 2.979.688 3.178 2.143 3.178 4.663l-.005 1.241zm-13.478-6l.91 2h1.164l.92-2h-2.994zm2.995 6l-.704-3h-1.615l-.704 3h3.023z" /></svg>
                                </div>
                                <div
                                    class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step"
                                >
                                    <h2 class="font-bold text-sm">Personal Info</h2>
                                    <p class="text-xs text-gray-600">
                                        Sign up for a new account with your email or Google, followed by OTP verification via moblie
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-1 flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" /></svg>
                        </div>
                        <div class="w-1/3 text-center px-6">
                            <div
                                class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200"
                            >
                                <div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 22h-24v-20h24v20zm-1-19h-22v18h22v-18zm-4 13v1h-4v-1h4zm-6.002 1h-10.997l-.001-.914c-.004-1.05-.007-2.136 1.711-2.533.789-.182 1.753-.404 1.892-.709.048-.108-.04-.301-.098-.407-1.103-2.036-1.305-3.838-.567-5.078.514-.863 1.448-1.359 2.562-1.359 1.105 0 2.033.488 2.545 1.339.737 1.224.542 3.033-.548 5.095-.057.106-.144.301-.095.41.14.305 1.118.531 1.83.696 1.779.41 1.773 1.503 1.767 2.56l-.001.9zm-9.998-1h8.999c.003-1.014-.055-1.27-.936-1.473-1.171-.27-2.226-.514-2.57-1.267-.174-.381-.134-.816.119-1.294.921-1.739 1.125-3.199.576-4.111-.332-.551-.931-.855-1.688-.855-.764 0-1.369.31-1.703.871-.542.91-.328 2.401.587 4.09.259.476.303.912.13 1.295-.342.757-1.387.997-2.493 1.252-.966.222-1.022.478-1.021 1.492zm18-3v1h-6v-1h6zm0-3v1h-6v-1h6zm0-3v1h-6v-1h6z" /></svg>
                                </div>
                                <div
                                    class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step"
                                >
                                    <h2 class="font-bold text-sm">Upload Property Profile</h2>
                                    <p class="text-xs text-gray-600">
                                        Key in your property's address to receive notifications for potential buyer(s) or connect with an agent
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-1 flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" /></svg>
                        </div>
                        <div class="w-1/3 text-center px-6">
                            <div
                                class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200"
                            >
                                <div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" /></svg>
                                </div>
                                <div
                                    class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step"
                                >
                                    <h2 class="font-bold text-sm">Explore</h2>
                                    <p class="text-xs text-gray-600">
                                        Find a suitable buyer for your property!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> : null
            }
            <div style={{ padding: "8px 8px", borderBottom: "1px solid #c1c1c1", margin: "1%", cursor: "pointer" }} onClick={() => setTenantGuide(!tenantGuide)}>
                <p style={{ float: "left" }}>Tenant / Buyer</p><i style={{ float: "right" }} onClick={() => setTenantGuide(!tenantGuide)} class={tenantGuide ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down"}></i>
                <div style={{ clear: 'both' }}></div>
            </div>
            {tenantGuide ? <>
                <div>
                    <h1 class="mb-4 text-center font-black text-gray-700">STEPS</h1>
                    <div class="flex">
                        <div class="w-1/3 text-center px-6">
                            <div
                                class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200"
                            >
                                <div
                                    class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.995 24h-1.995c0-3.104.119-3.55-1.761-3.986-2.877-.664-5.594-1.291-6.584-3.458-.361-.791-.601-2.095.31-3.814 2.042-3.857 2.554-7.165 1.403-9.076-1.341-2.229-5.413-2.241-6.766.034-1.154 1.937-.635 5.227 1.424 9.025.93 1.712.697 3.02.338 3.815-.982 2.178-3.675 2.799-6.525 3.456-1.964.454-1.839.87-1.839 4.004h-1.995l-.005-1.241c0-2.52.199-3.975 3.178-4.663 3.365-.777 6.688-1.473 5.09-4.418-4.733-8.729-1.35-13.678 3.732-13.678 4.983 0 8.451 4.766 3.732 13.678-1.551 2.928 1.65 3.624 5.09 4.418 2.979.688 3.178 2.143 3.178 4.663l-.005 1.241zm-13.478-6l.91 2h1.164l.92-2h-2.994zm2.995 6l-.704-3h-1.615l-.704 3h3.023z" /></svg>
                                </div>
                                <div
                                    class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step"
                                >
                                    <h2 class="font-bold text-sm">Personal Info</h2>
                                    <p class="text-xs text-gray-600">
                                    Sign up for a new account with your email or Google, followed by OTP verification via moblie                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-1 flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" /></svg>
                        </div>
                        <div class="w-1/3 text-center px-6">
                            <div
                                class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200"
                            >
                                <div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 22h-24v-20h24v20zm-1-19h-22v18h22v-18zm-4 13v1h-4v-1h4zm-6.002 1h-10.997l-.001-.914c-.004-1.05-.007-2.136 1.711-2.533.789-.182 1.753-.404 1.892-.709.048-.108-.04-.301-.098-.407-1.103-2.036-1.305-3.838-.567-5.078.514-.863 1.448-1.359 2.562-1.359 1.105 0 2.033.488 2.545 1.339.737 1.224.542 3.033-.548 5.095-.057.106-.144.301-.095.41.14.305 1.118.531 1.83.696 1.779.41 1.773 1.503 1.767 2.56l-.001.9zm-9.998-1h8.999c.003-1.014-.055-1.27-.936-1.473-1.171-.27-2.226-.514-2.57-1.267-.174-.381-.134-.816.119-1.294.921-1.739 1.125-3.199.576-4.111-.332-.551-.931-.855-1.688-.855-.764 0-1.369.31-1.703.871-.542.91-.328 2.401.587 4.09.259.476.303.912.13 1.295-.342.757-1.387.997-2.493 1.252-.966.222-1.022.478-1.021 1.492zm18-3v1h-6v-1h6zm0-3v1h-6v-1h6zm0-3v1h-6v-1h6z" /></svg>
                                </div>
                                <div
                                    class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step"
                                >
                                    <h2 class="font-bold text-sm">Upload Property Requirements</h2>
                                    <p class="text-xs text-gray-600">
                                        Key in all your requirements and budget
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-1 flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" /></svg>
                        </div>
                        <div class="w-1/3 text-center px-6">
                            <div
                                class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200"
                            >
                                <div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" /></svg>
                                </div>
                                <div
                                    class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step"
                                >
                                    <h2 class="font-bold text-sm">That's it</h2>
                                    <p class="text-xs text-gray-600">
                                        Landlords who are interested in your listing will reach out to you
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='indivRoleSection'>

                    <div className='indivRoleSectionCol' style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign: "center" }}>1</p>
                        <p>Sign up for an account, along with the verification of your mobile number and select your role as tenant under [Looking For Space]</p>
                    </div>
                    <div className='indivRoleSectionColSpecial' style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign: "center" }}>2</p>
                        <p>To start looking for a space, go to [Add Listing] under [To Rent] or [To Purchase] in your account tab, key in all your requirements and budget<br></br>Tips: To achieve a higher viewer rating and conversion rate, do fill in all fields and be as detailed as possible</p>
                    </div>

                    <div className='indivRoleSectionCol' style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign: "center" }}>3</p>
                        <p>Upon approval by our team, your listing will go live. You may then track your listing's performance via your dashboard</p>
                    </div>
                </div>
                <div className='indivRoleSection' style={{ marginTop: "1%" }}>
                    <div className='indivRoleSectionCol' style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign: "center" }}>4</p>
                        <p>Landlord who are interested in your listing will reach out to you via [Pending Message]. Click on [Approve] and you will be connected for further discussion</p>
                    </div>
                    <div className='indivRoleSectionColSpecial' style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign: "center" }}>5</p>
                        <p>Do check your dashboard periodically to check on recent connection request and see how your listing is performing</p>
                    </div>

                </div> */}
            </> : null
            }
            <div style={{ padding: "8px 8px", borderBottom: "1px solid #c1c1c1", margin: "1%", cursor: "pointer" }} onClick={() => setAdvertiserGuide(!advertiserGuide)}>
                <p style={{ float: "left" }}>Advertiser</p><i style={{ float: "right" }} onClick={() => setAdvertiserGuide(!advertiserGuide)} class={advertiserGuide ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down"}></i>
                <div style={{ clear: 'both' }}></div>
            </div>
            {advertiserGuide ? <>
                <div>
                    <h1 class="mb-4 text-center font-black text-gray-700">STEPS</h1>
                    <div class="flex">
                        <div class="w-1/3 text-center px-6">
                            <div
                                class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200"
                            >
                                <div
                                    class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.995 24h-1.995c0-3.104.119-3.55-1.761-3.986-2.877-.664-5.594-1.291-6.584-3.458-.361-.791-.601-2.095.31-3.814 2.042-3.857 2.554-7.165 1.403-9.076-1.341-2.229-5.413-2.241-6.766.034-1.154 1.937-.635 5.227 1.424 9.025.93 1.712.697 3.02.338 3.815-.982 2.178-3.675 2.799-6.525 3.456-1.964.454-1.839.87-1.839 4.004h-1.995l-.005-1.241c0-2.52.199-3.975 3.178-4.663 3.365-.777 6.688-1.473 5.09-4.418-4.733-8.729-1.35-13.678 3.732-13.678 4.983 0 8.451 4.766 3.732 13.678-1.551 2.928 1.65 3.624 5.09 4.418 2.979.688 3.178 2.143 3.178 4.663l-.005 1.241zm-13.478-6l.91 2h1.164l.92-2h-2.994zm2.995 6l-.704-3h-1.615l-.704 3h3.023z" /></svg>
                                </div>
                                <div
                                    class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step"
                                >
                                    <h2 class="font-bold text-sm">Personal Info</h2>
                                    <p class="text-xs text-gray-600">
                                    Sign up for a new account with your email or Google, followed by OTP verification via moblie                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-1 flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" /></svg>
                        </div>
                        <div class="w-1/3 text-center px-6">
                            <div
                                class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200"
                            >
                                <div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 22h-24v-20h24v20zm-1-19h-22v18h22v-18zm-4 13v1h-4v-1h4zm-6.002 1h-10.997l-.001-.914c-.004-1.05-.007-2.136 1.711-2.533.789-.182 1.753-.404 1.892-.709.048-.108-.04-.301-.098-.407-1.103-2.036-1.305-3.838-.567-5.078.514-.863 1.448-1.359 2.562-1.359 1.105 0 2.033.488 2.545 1.339.737 1.224.542 3.033-.548 5.095-.057.106-.144.301-.095.41.14.305 1.118.531 1.83.696 1.779.41 1.773 1.503 1.767 2.56l-.001.9zm-9.998-1h8.999c.003-1.014-.055-1.27-.936-1.473-1.171-.27-2.226-.514-2.57-1.267-.174-.381-.134-.816.119-1.294.921-1.739 1.125-3.199.576-4.111-.332-.551-.931-.855-1.688-.855-.764 0-1.369.31-1.703.871-.542.91-.328 2.401.587 4.09.259.476.303.912.13 1.295-.342.757-1.387.997-2.493 1.252-.966.222-1.022.478-1.021 1.492zm18-3v1h-6v-1h6zm0-3v1h-6v-1h6zm0-3v1h-6v-1h6z" /></svg>
                                </div>
                                <div
                                    class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step"
                                >
                                    <h2 class="font-bold text-sm">Upload Advertisement Artwork</h2>
                                    <p class="text-xs text-gray-600">
                                        Upload an artwork with 4:5 aspect ratio with all details included
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex-1 flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" /></svg>
                        </div>
                        <div class="w-1/3 text-center px-6">
                            <div
                                class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200"
                            >
                                <div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" /></svg>
                                </div>
                                <div
                                    class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step"
                                >
                                    <h2 class="font-bold text-sm">Set Advertising Date</h2>
                                    <p class="text-xs text-gray-600">
                                        Once the artwork has been approved, select your date(s) and make payment with your credits
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='indivRoleSection'>

                    <div className='indivRoleSectionCol' style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign: "center" }}>1</p>
                        <p>Start advertising for your business from as low as $38!</p>
                    </div>
                    <div className='indivRoleSectionColSpecial' style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign: "center" }}>2</p>
                        <p>Sign up for an account and under "Select your role", select "Advertiser"</p>
                    </div>

                    <div className='indivRoleSectionCol' style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign: "center" }}>3</p>
                        <p>Under the Advertise tab, upload your artwork (in 4:5 aspect ratio) with all details included</p>
                    </div>
                </div>
                <div className='indivRoleSection' style={{ marginTop: "1%" }}>
                    <div className='indivRoleSectionCol' style={{ display: "flex", flexDirection: "row", textAlign: "justify" }}>
                        <p style={{ backgroundColor: "#33434D", borderRadius: "50%", padding: "2px 7px", color: 'white', height: "fit-content", margin: "1%", textAlign: "center" }}>4</p>
                        <p>Once the artwork has been approved (within 3 to 5 working days), you will be able to select your date(s) and make payment</p>
                    </div>
                </div> */}
            </> : null
            }

            <div style={{ margin: "1%", overflow: "auto" }}>
                {/* <center><h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>Ready to get started?</h3></center> */}
                <div class="w-full mx-auto bg-white px-5 py-10 text-gray-600 mb-5">
                    <div class="text-center max-w-xl mx-auto">
                        <h1 class="text-2xl md:text-2xl font-bold mb-5 mt-5">Credits Plan</h1>
                        {/* <h3 class="text-xl font-medium mb-5">Different Package comes with different benefits!<br></br>Credits does not expire!</h3> */}
                    </div>
                    <div class="w-full md:flex mb-5">
                        <div class="w-full md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:my-3 rounded-md shadow-lg shadow-gray-600 md:relative  md:flex md:flex-col">
                            <div class="w-full flex-grow">
                                <h2 class="text-center font-bold uppercase mb-4">Tier 1</h2>
                                <h3 class="text-center font-bold text-4xl mb-2">$25</h3>
                                {/* <p class="text-center font-bold mb-5">
                <a href="#" class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i class="mdi mdi-arrow-right-thick ml-1"></i></a>
            </p> */}
                                <ul class="text-sm px-5 mb-8">
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>25 Credits</li>
                                </ul>
                            </div>
                            {/* <div class="w-full">
            <button class="font-bold bg-gray-600 hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full" onClick={() => {
                setChooseTier(false);
                setPaymentTab(true);
                setTierPayment('Tier 1');
            }}>Buy Now</button>
        </div> */}
                        </div>
                        <div class="w-full md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:my-3 rounded-md shadow-lg shadow-gray-600 md:relative  md:flex md:flex-col">
                            <div class="w-full flex-grow">
                                <h2 class="text-center font-bold uppercase mb-4">Tier 2</h2>
                                <h3 class="text-center font-bold text-3xl md:text-4xl mb-2">$100</h3>
                                {/* <p class="text-center font-bold mb-5">
                <a href="#" class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i class="mdi mdi-arrow-right-thick ml-1"></i></a>
            </p> */}
                                <ul class="text-sm px-5 mb-8">
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>100 Credits</li>
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>+</li>
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>20 Credits<br></br><span class="text-red-500 font-bold">Bonus</span></li>
                                </ul>
                            </div>
                            {/* <div class="w-full">
            <button class="font-bold bg-gray-600 hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full" onClick={() => {
                setChooseTier(false);
                setPaymentTab(true);
                setTierPayment('Tier 2');
            }}>Buy Now</button>
        </div> */}
                        </div>
                        <div class="w-full md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto  md:my-3 rounded-md shadow-lg shadow-gray-600 md:relative  md:flex md:flex-col">
                            <div class="w-full flex-grow">
                                <h2 class="text-center font-bold uppercase mb-4">Tier 3</h2>
                                <h3 class="text-center font-bold text-3xl md:text-4xl mb-2">$250</h3>
                                {/* <p class="text-center font-bold mb-5">
                <a href="#" class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i class="mdi mdi-arrow-right-thick ml-1"></i></a>
            </p> */}
                                <ul class="text-sm px-5 mb-8">
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>250 Credits</li>
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>+</li>
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>70 Credits<br></br><span class="text-red-500 font-bold">Bonus</span></li>
                                </ul>
                            </div>
                            {/* <div class="w-full">
            <button class="font-bold bg-gray-600 hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full" onClick={() => {
                setChooseTier(false);
                setPaymentTab(true);
                setTierPayment('Tier 3');
            }}>Buy Now</button>
        </div> */}
                        </div>
                        <div class="w-full md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:-mx-3 md:mb-0 rounded-md shadow-lg shadow-gray-600 md:relative md:z-50 md:flex md:flex-col">
                            <div class="w-full flex-grow">
                                <h2 class="text-center font-bold uppercase mb-4">Tier 4</h2>
                                <h3 class="text-center font-bold text-4xl md:text-5xl mb-2">$500</h3>
                                {/* <p class="text-center font-bold mb-5">
                <a href="#" class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i class="mdi mdi-arrow-right-thick ml-1"></i></a>
            </p> */}
                                <ul class="text-sm px-5 mb-8">
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>500 Credits</li>
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>+</li>
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>250 Credits<br></br><span class="text-red-500 font-bold">Bonus</span></li>
                                </ul>
                            </div>
                            {/* <div class="w-full">
            <button class="font-bold bg-gray-600 hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full" onClick={() => {
                setChooseTier(false);
                setPaymentTab(true);
                setTierPayment('Tier 4');
            }}>Buy Now</button>
        </div> */}
                        </div>
                        <div class="w-full md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto  md:my-3 rounded-md shadow-lg shadow-gray-600 md:relative  md:flex md:flex-col">
                            <div class="w-full flex-grow">
                                <h2 class="text-center font-bold uppercase mb-4">Tier 5</h2>
                                <h3 class="text-center font-bold text-3xl md:text-4xl mb-2">$1000</h3>

                                {/* <p class="text-center font-bold mb-5">
                <a href="#" class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i class="mdi mdi-arrow-right-thick ml-1"></i></a>
            </p> */}
                                <ul class="text-sm px-5 mb-8">
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>1000 Credits</li>
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>+</li>
                                    <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>550 Credits<br></br><span class="text-red-500 font-bold">Bonus</span></li>
                                </ul>
                            </div>
                            {/* <div class="w-full">
            <button class="font-bold bg-gray-600 hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full" onClick={() => {
                setChooseTier(false);
                setPaymentTab(true);
                setTierPayment('Tier 5');
            }}>Buy Now</button>
        </div> */}
                        </div>
                    </div>
                    <div class="text-center max-w-xl mx-auto">
                        <p class="text-xs leading-tight">*Terms and condition does apply</p>
                    </div>
                </div>
                {/* <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                        <tr style={{ backgroundColor: "#316190" }}>
                            <th></th>
                            <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                <p style={{ fontWeight: "bold", margin: 0 }}>Standard</p>
                                <p style={{ margin: 0 }}>$180</p>
                                <p style={{ margin: 0 }}>Monthly</p>
                            </th>
                            <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                <p style={{ fontWeight: "bold", margin: 0 }}>Premium</p>
                                <p style={{ margin: 0 }}>$380</p>
                                <p style={{ margin: 0 }}>Monthly</p>
                            </th>
                            <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                <p style={{ fontWeight: "bold", margin: 0 }}>Super Premium</p>
                                <p style={{ margin: 0 }}>$880</p>
                                <p style={{ margin: 0 }}>Monthly</p>
                            </th>
                            <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                <p style={{ fontWeight: "bold", margin: 0 }}>Enterprise</p>
                                <p style={{ margin: 0 }}>Starting from $3000</p>
                                <p style={{ margin: 0 }}>Monthly</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ borderTop: "1px solid #ccc" }}>

                            <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Rental</td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                        </tr>
                        <tr style={{ borderTop: "1px solid #ccc" }}>
                            <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Sales</td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-xmark"></i></td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                        </tr>
                        <tr style={{ borderTop: "1px solid #ccc" }}>
                            <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Connections</td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}><p>7</p></td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><p>16</p></td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}><p>40</p></td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                        </tr>
                        <tr style={{ borderTop: "1px solid #ccc" }}>
                            <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Advanced Details</td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-xmark"></i></td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><p>16</p></td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}><p>40</p></td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                        </tr>
                        <tr style={{ borderTop: "1px solid #ccc" }}>
                            <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Photos per property profile</td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 5 photos</td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Maximum of 12 photos</td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 20 photos</td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                        </tr>
                        <tr style={{ borderTop: "1px solid #ccc" }}>
                            <td style={{ padding: "10px 5px 10px 5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Videos per property profile</td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}>1 video</td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Maximum of 3 videos</td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 5 videos</td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                        </tr>
                        <tr style={{ borderTop: "1px solid #ccc" }}>
                            <td style={{ padding: "10px 5px 10px 5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Top up</td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-xmark"></i></td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-xmark"></i></td>
                            <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                            <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                        </tr>

                    </tbody>
                </table> */}
                {/* <p style={{ fontSize: "14px", color: "grey", marginLeft: "10px" }}>*A one time set-up fee of $80 will be applicable to all subscription plans</p> */}
            </div>

            <Footer />
        </>


    )
}

export default TermOfService;
