import React, { useContext, createContext, useEffect, useState, useRef } from "react";
import { GoogleAuthProvider, signInWithPopup, updateProfile, signOut, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, getAdditionalUserInfo, FacebookAuthProvider, getAuth, sendEmailVerification, updateCurrentUser } from "firebase/auth";
import { auth, db, storage } from './firebase/firebase'
import { doc, setDoc, addDoc, collection, updateDoc, getDoc, serverTimestamp } from "firebase/firestore";
import { getDownloadURL, listAll, ref, uploadBytes, getStorage } from 'firebase/storage';
import { async } from "@firebase/util";
import {
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier, deleteUser, sendPasswordResetEmail, getMultiFactorResolver
} from "firebase/auth";
import firebase from './firebase/firebase'

import AcknowledgeNormal from "./AcknowledgeNormal";


const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [needMFAuser, setNeedMFAuser] = useState({});
    const [acknowledgeActivated, setAcknowledgeActivated] = useState(false);
    const [acknowledgeMessage, setAcknowledgeMessage] = useState([]);


    const deleteUserData = async () => {
        deleteUser(user).then(() => {
        })
    }

    const disabledAccount = async () => {
        try {
            await updateProfile(user, {
                disabled: true,
            }).then(() => {
                return;
            });



        } catch (error) {
            console.error("Error disabling user account:", error);
        }
    }

    const googleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        if (prompt) {
            provider.setCustomParameters({ prompt: "select_account" });
        }

        try {
            const result = await signInWithPopup(auth, provider);
            // // Check if MFA is required for the user

            //     // MFA is not set up for the user
            //     console.log('MFA is not set up');
            //     setNeedMFAuser(result.user);
            //     return "newuser-MFA";
            // Display an error message or redirect to MFA setup page

        } catch (error) {
            if (error.code === "auth/multi-factor-auth-required") {
                console.log("error");
                return error;
            }
        }
    }

    const facebookSignIn = async () => {
        const provider = new FacebookAuthProvider();
        provider.addScope('email');
        provider.addScope('public_profile');
        provider.setCustomParameters({ auth_type: 'rerequest' });
        var result = await signInWithPopup(auth, provider);
        // console.log(result);
        const emailVerified = result.user.emailVerified;
        if (emailVerified == false) {
            // console.log("This is a first-time user.");
            send_verification();
            // Do something specific for new users (e.g., show onboarding, set up initial profile, etc.)
        } else {
            // console.log("This is an existing user.");
            // Do something specific for existing users (e.g., redirect to the main app screen, show user dashboard, etc.)
        }


    }

    const registerSignIn = async () => {

        var registerUsername = document.getElementById('usernameSignUp').value;
        var registerEmail = document.getElementById('emailSignUp').value;
        var registerPassword = document.getElementById('passwordSignUp').value;
        var registerPassword_1 = document.getElementById('confirmPasswordSignUp').value;
        var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])(?=.*[a-zA-Z]).{12,}$/;
        if (registerPassword == registerPassword_1) {
            if (passwordRegex.test(registerPassword)) { // Check if password meets the requirements
                try {
                    var user = await createUserWithEmailAndPassword(auth, registerEmail, registerPassword);
                    await updateProfile(user.user, {
                        displayName: registerUsername
                    });
                    var imageRef = ref(storage, 'Default/userIcon.png');
                    getDownloadURL(imageRef).then(async (url) => {
                        await updateProfile(user.user, {
                            photoURL: url
                        });
                    });
                    send_verification();
                    return user.user;
                } catch (error) {
                    console.log(error.message);
                }
            } else {
                alert("Password must be at least 12 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*()_+)");
            }
        } else {
            alert("Password not matched");
        }
    }

    const send_verification = () => {
        // console.log(auth.currentUser);
        sendEmailVerification(auth.currentUser).then(() => {}).catch(error => console.log(error));
        // currentUser.sendEmailVerification().then(function(){
        //     console.log("verify");
        // }).catch(error => console.log(error));
    }

    const resetPasswordwithEmail = (email) => {
        sendPasswordResetEmail(auth, email).then(() => {
            alert("Email Sent");
        }).catch((error) => alert("Invalid Email"))
    }


    const loginSignIn = async () => {
        try {
            var registerEmail = document.getElementById('emailSignUp').value;
            var registerPassowrd = document.getElementById('passwordSignUp').value;
            const user = await signInWithEmailAndPassword(auth, registerEmail, registerPassowrd);
            return user;
        } catch (error) {
            // console.log(error.code);
            switch (error.code) {
                case 'Firebase: Error (auth/user-not-found).':
                    alert("User not found");
                    break;
                case 'Firebase: Error (auth/wrong-password).':
                    alert("Wrong Password");
                    break;
                case 'Firebase: Error (auth/user-disabled).':
                    setAcknowledgeMessage("Please reach out to us if you wish to reactivate your disabled account, and we will respond to your inquiry shortly.");
                    setAcknowledgeActivated(true);

                    break;
                case 'auth/multi-factor-auth-required':
                    return error;



                    break;
                default:
                    alert("Email/Password Wrong");
            }

        }
    }



    const logOut = () => {
        signOut(auth);
    }

    useEffect(() => {
        const unsubsribe = onAuthStateChanged(auth, async (currentUser) => {
            // console.log(currentUser);
            setUser(currentUser);
            if (currentUser) { // Check if currentUser is not undefined or null
                

                await getDoc(doc(db, 'publicUserInfo', currentUser.uid)).then(async (res) => {
                    // console.log(res);
                    if (res._document != null) {
                        let lastLoginTiming = res.data().lastLogin.toDate();
                        // Extracting date, month, and year
                        const day = lastLoginTiming.getDate();
                        const month = lastLoginTiming.getMonth() + 1; // Months are zero-based
                        const year = lastLoginTiming.getFullYear();
                        let today = new Date();
                        if(today.getDate() == day &&
                        today.getMonth() + 1 ==  month &&
                        today.getFullYear() == year){
                            await updateDoc(doc(db, 'publicUserInfo', currentUser.uid), {
                                lastLogin: serverTimestamp()
                            });
                        }
                        else{
                            await getDoc(doc(db, 'userInfo', currentUser.uid)).then(async (resDoc) =>{
                                let transactionHistoryNew = [...resDoc.data().transactionHistory];
                                if(transactionHistoryNew.length >= 30){
                                    transactionHistoryNew.shift();
                                }
                                transactionHistoryNew.push({
                                    'Activity': 'Daily Login',
                                    'Date': new Date(),
                                    'Type': "Points Activity",
                                    "Actions": '+1 Points'
                                })
                                let snapshotUpdateTime = await updateDoc(doc(db, 'publicUserInfo', currentUser.uid),{
                                    lastLogin: serverTimestamp()
                                })
                                let snapshotUpdatePoints = await updateDoc(doc(db, 'userInfo', currentUser.uid),{
                                    points: resDoc.data().points + 1,
                                    transactionHistory: transactionHistoryNew,
                                    pointsDate: serverTimestamp()
                                })
                            })
                        }
                        
                    }
                });
            }
        });

        return () => {
            unsubsribe();
        };
    }, []);

    return (
        <>

            <AcknowledgeNormal trigger={acknowledgeActivated} setTrigger={setAcknowledgeActivated} message={acknowledgeMessage}></AcknowledgeNormal>
            <AuthContext.Provider value={{ googleSignIn, facebookSignIn, registerSignIn, loginSignIn, logOut, deleteUserData, user, needMFAuser, setUser, resetPasswordwithEmail, disabledAccount }}>
                {children}
            </AuthContext.Provider>
        </>
    )
}


export const UserAuth = () => {
    return useContext(AuthContext)
}