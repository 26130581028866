import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../App.css';
import '../css/Account.css';
import { UserAuth } from '../../AuthContent';
import { updatePassword, reauthenticateWithCredential, reauthenticateWithPopup, updateProfile, getAuth, EmailAuthProvider, deleteUser, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider, confirmPasswordReset } from "firebase/auth";
// import { Button } from '../Button';
import NewProject from './NewProject'
import { Navigate, Link, useFetcher } from 'react-router-dom';
import { collection, getDocs, onSnapshot, doc, updateDoc, setDoc, arrayUnion, serverTimestamp, deleteDoc, getDoc, query, where, deleteField, Timestamp, addDoc } from 'firebase/firestore';
import { db, auth, storage } from '../../firebase/firebase'
import { getDownloadURL, ref, uploadBytes, listAll, getStorage, deleteObject, uploadString, getMetadata } from 'firebase/storage';
import firebase from '../../firebase/firebase'
import BoostAll from './BoostAll';
import BoostIndiv from './BoostIndiv';
// import { UserChat } from '../ChatContext';
import { useLocation } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';
import Footer from '../Footer';
import AdminAccount from '../pages/AdminAccount';
import UserTypeModal from './UserTypeModal';
import TempOTP from './TempOTP';
import ChatAccount from './ChatAccount';
import AddPropertyLocation from '../AddPropertyLocation';
import axios from 'axios';
import Verification from './Verification';
import InsuranceAccount from './InsuranceAccount';
import Chart from 'chart.js/auto'
import Acknowledge from './Acknowledge';
import AddBusiness from './AddBusiness';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import ChangeProfilePic from './ChangeProfilePic';
import ChangeUserName from './ChangeUserName';
import LOI from './LOI';
import Spinner from './Spinner';
import PurchaseListing from './PurchaseListing';
import AdvertiseListing from './AdvertiseListing';
import AdvertiseAction from './AdvertiseAction';
import NewUserType from './NewUserType';
import Appointment from './Appointment';
import ChangePlan from './ChangePlan';
import CEA from './CEA';
import AddOns from './AddOns';
import BoostAllSale from './BoostAllSale';
import BoostIndivSale from './BoostIndivSale'
// import PromoPage from './PromoPage';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReferAFriend from './ReferAFriend';
import {
  ChartBarIcon,
  EnvelopeIcon,
  HeartIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  PencilSquareIcon

} from '@heroicons/react/24/outline'
import ChangePassword from './ChangePassword';
import ChangePoints from './ChangePoints';
import RedeemCode from './RedeemCode';


export default function Account(props) {
  const { logOut, user } = UserAuth();
  const [data, setData] = useState({});
  const [offerData, setOfferData] = useState(null);
  const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
  const [formValue, setFormValue] = useState('');
  const [userProfile, setUserProfile] = useState([]);
  const [shortList, setShortList] = useState([]);
  const [advertiseDashboardName, setAdvertiseDashboardName] = useState("All");
  const [messageClick, setMessageClick] = useState(false);
  const [listingClick, setListingClick] = useState(false);
  const [bookingClick, setBookingClick] = useState(false);
  const [accountClick, setAccountClick] = useState(false);
  const [shortListClick, setShortListClick] = useState(false);
  const [purchaseclick, setPurchaseClick] = useState(false);
  const [advertiseclick, setAdvertiseClick] = useState(false);
  const [changePointsToCredit, setChangePointsToCredit] = useState(false);
  const [referFriend, setReferFriend] = useState(false);
  const [listingClosed, setListingClosedID] = useState([]);
  const [activateCEA, setActivateCEA] = useState(false);
  const [dashboardClick, setDashboardClick] = useState(false);
  const [dashboardViewUserTypeAccountView, setDashboardViewUserTypeAccountView] = useState("Commercial Rent");
  const [activateID, setActivateID] = useState(null);
  const [homeRequirements, setHomeRequirements] = useState(null);
  const [changeSubscriptionPlan, setChangeSubscriptionPlan] = useState(false);
  const [chats, setChat] = useState([]);
  const [sortedEyeballUserProject, setSortedEyeballUserProject] = useState(null);
  const [sortedEyeballCommercialSales, setSortedEyeballCommercialSales] = useState(null);
  const [sortedEyeballResidentialRent, setSortedEyeballResidentialRent] = useState(null);
  const [sortedEyeballResidentialSales, setSortedEyeballResidentialSales] = useState(null);
  const [cancelSubscription, setCancelSubcription] = useState(false);
  const [accountListingView, setAccountListingView] = useState("Commercial");
  const [chatClick, setChatClick] = useState(false);
  const [messages, setMessages] = useState([]);
  const [click, setClick] = useState(false);
  const [clickPurchase, setClickPurchase] = useState(false);
  const [clickBoostIndiv, setClickBoostIndiv] = useState(false);
  const [clickBoostIndivPurchase, setClickBoostIndivPurchase] = useState(false);
  const [boostIndivID, setBoostIndivID] = useState(null);
  const [clickBoostAll, setClickBoostAll] = useState(false);
  const [clickBoostAllPurchase, setClickBoostAllPurchase] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [advListing, setAdvListing] = useState([]);
  const [navID, setNavID] = useState('');
  const dummy = useRef();
  const location = useLocation();
  let unsbscribe, unsubscribeCurrentUserInfo;
  const chatNotification = useRef(null);
  const userChatInfo = useRef(null);
  const [imageUpload, setImageUpload] = useState(null);
  const [modifyListingId, setModifyListingId] = useState(null);
  const [unReadMessageCount, setUnReadMessageCount] = useState(0);
  const [arrIDUnRead, setArrIdUnread] = useState([]);
  const [userProject, setUserProject] = useState(null);
  const [commercialListingPurcahse, setCommercialListingPurchase] = useState([]);
  const [residentialListingPurchase, setResidentialPurchase] = useState([]);
  const [activateUserType, setActivateUserType] = useState(false);
  const [userInformation, setUserInformation] = useState("free");
  const [completeButton, setCompleteButton] = useState(false);
  const [unReadMessageCountPending, setUnReadMessagePending] = useState(false);
  const [addProperty, setAddProperty] = useState(false);
  const [addAppointment, setAddAppointment] = useState(false);
  const [verification, setVerification] = useState(false);
  const [thirdPartyClick, setThirdPartyClick] = useState(false);
  const [thirdPartyList, setThirdPartyList] = useState(null);
  const [pendingMessagePage, setPendingMessagePage] = useState(false);
  const [leftAccount, setLeftAccount] = useState(false);
  const [allPendingConverstation, setAllPendingConversation] = useState([]);
  const [currentUserInfo, setCurrentUserInfo] = useState(null);
  const [billingDate, setBillingDate] = useState(null);
  const [isCommercial, setIsCommercial] = useState(true);
  const [isToggled, setIsToggled] = useState(true);
  // const [accountUserType, setAccountUserType] = useState(null);
  const [accountPropertyList, setAccountPropertyList] = useState([]);
  const [accountPropertyListResidential, setAccountPropertyListResidential] = useState([]);
  const [modifyIndivPropertyIndex, setModifyIndivPropertyIndex] = useState(null);
  const [modifyRole, setModifyRole] = useState(false);
  const [TempOTPTrigger, setTempOTPTrigger] = useState(false);
  const [displayPasswordEye, setDisplayPasswordEye] = useState(false);
  const [changeAccountTier, setChangeAccountTier] = useState(false);
  const [publicInfo, setPublicInfo] = useState(null);
  const [addPropertyView, setAddPropertyView] = useState(null);
  const [allRejectedConversation, setAllRejectedConversation] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [allListingDataDetails, setAllListingDataDetails] = useState([]);
  const [changeUserName, setChangeUserName] = useState(false);
  const [changeProfilePic, setChangeProfilePic] = useState(false);
  const [proceedAccountDelete, setProceedAccountDelete] = useState(false);
  const [processCancelSubcription, setProcessCancelSubcription] = useState(false);
  const [commercialRentAvailableNumber, setCommercialRentAvailableNumber] = useState(0);
  const [currentTab, setCurrentTab] = useState('dashboard');
  const [filterShortList, setFilterShortList] = useState("All");
  const [passwordActive, setPasswordActive] = useState(false);
  const [newListingDataTable, setNewListingDataTable] = useState([
    { date: 'NA', count: 0 },
    { date: 'NA', count: 0 },
    { date: 'NA', count: 0 },
    { date: 'NA', count: 0 },
    { date: 'NA', count: 0 },
    { date: 'NA', count: 0 },
    { date: 'NA', count: 0 },
  ]);

  const [promoCode, setPromoCode] = useState(false);
  const [totalListingDistrict, setTotalListingDistrict] = useState(0);
  const [modifyAdvertiseDetails, setModifyAdvertiseDetails] = useState(null);
  const [addBusiness, setAddBusiness] = useState(false);
  const [modifyBusinessIndex, setModifyBusinessIndex] = useState(null);
  const [accountBusinessArray, setAccountBusinessArray] = useState(null);
  const [businessAccountIndex, setBusinessAccountIndex] = useState(null);
  const chartContainer = useRef(null);
  const [unReadMessagePendingCount, setUnReadMessagePendingCount] = useState(0);
  const [dashboardViewUserType, setDashboardViewUserType] = useState(null);
  const [CANResLevel, setCANResLevel] = useState('Top');
  const [acknowledge, setAcknowledge] = useState(false);
  const [acknowledgeMessage, setAcknowledgeMessage] = useState('');
  const [rejected, setRejected] = useState(false);
  const [acknowledgeNormalDelete, setAcknowledgeNormalDelete] = useState(false);
  const [userResidentialListing, setUserResidentialListing] = useState([]);
  const [userResidentialSalesAvailableNumber, setUserResidentialSalesAvailableNumber] = useState(0);
  const [advertiseActivated, setAdvertiseActivated] = useState(false);
  const [advertiseActionActivated, setAdvertiseActionActivated] = useState(false);
  const [activateNewUserType, setActivateNewUserType] = useState(false);
  const [acknowledgeNormalDeleteAdv, setAcknowledgeNormalDeleteAdv] = useState(false);
  const [proceedAdvertiseDelete, setProceedAdvertiseDelete] = useState(false);
  const [userResidentialRentAvailableNumber, setUserResidentialRentAvailableNumber] = useState(0);
  const [commercialSalesAvailableNumber, setCommercialSalesAvailableNumber] = useState(0);
  const [modifyCEA, setModifyCEA] = useState(false);
  const [advertisePath, setAdvertisePath] = useState("newAdvertisement");
  const [addOns, setAddOns] = useState(false);
  const [openPendingRightSideIndex, setOpenPendingRightSideIndex] = useState(0);
  const [openPendingRightSide, setOpenPendingRightSide] = useState(false);
  const [openOptionCommercialProfile, setOpenOptionCommercialProfile] = useState(false);
  // const [activatePromoCode, setActivatePromoCode] = useState(false);
  const [eyeballStats, setEyeballStats] = useState([
    { date: 'NA', count: 0 },
    { date: 'NA', count: 0 },
    { date: 'NA', count: 0 },
    { date: 'NA', count: 0 },
    { date: 'NA', count: 0 },
    { date: 'NA', count: 0 },
    { date: 'NA', count: 0 }]);
  const chartData = useMemo(() => {
    if (dashboardViewUserType == "Tenant" || dashboardViewUserType == "Advertiser") {
      return {
        labels: eyeballStats.map(row => row.date),
        datasets: [
          {
            label: 'Number of Views',
            data: eyeballStats.map(row => row.count)
          }
        ]
      };
    }
    else {
      return {
        labels: newListingDataTable.map(row => row.date),
        datasets: [
          {
            label: 'Number of Listing',
            data: newListingDataTable.map(row => row.count)
          }
        ]
      };
    }
  }, [newListingDataTable, eyeballStats]);
  const [totalListingEyeball, setTotalListingEyeball] = useState(0);
  const [tenantDistrict, setTenantDistrict] = useState(null);
  const [tenantDistrictNum, setTenantDistrictNum] = useState(0);
  const [dashboardTenantListing, setDashboardTenantListing] = useState('');
  const [isReady, SetIsReady] = useState(false);
  const [breadcrumbDic, setBreadCrumbDic] = useState([]);

  const handleCommercial = () => {
    setIsCommercial(!isCommercial);
    if (!isCommercial == true) {
      setAccountListingView("Commercial")
    }
    else {
      setAccountListingView("Residential")
    }
  };

  useEffect(() => {
    if (proceedAdvertiseDelete == true) {
      callDeleteWholeAdv();
    }
  }, [proceedAdvertiseDelete])

  const callDeleteWholeAdv = async () => {
    setLoadingSpinner(true);
    var newAdvListing = [...advListing];
    var index = newAdvListing.findIndex(obj => obj.id === activateID);
    // newAdvListing.splice(index,1);
    await getDoc(doc(db, 'commonData', 'advertiseDetails')).then(async (quota) => {
      var currentQuota = quota.data().advertiseSlot;
      if (newAdvListing[index].details.activatedDate != null) {
        for (var i = 0; i < newAdvListing[index].details.activatedDate.length; i++) {
          var splitSelectedDate = newAdvListing[index].details.activatedDate[i].split("/");
          const targetDate = new Date(`${splitSelectedDate[2]}-${splitSelectedDate[1] <= 9 ? "0" + splitSelectedDate[1] : splitSelectedDate[1]}-${splitSelectedDate[0]}`);
          // Replace with your target date
          const currentDate = new Date(); // Current date

          // Calculate the difference in milliseconds between the target date and the current date
          const timeDifference = targetDate.getTime() - currentDate.getTime();

          // Convert the time difference to days
          const daysUntilTarget = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
          //somehow i need to minus 1
          currentQuota[daysUntilTarget - 1] = currentQuota[daysUntilTarget - 1] + 1;
        }
      }
      newAdvListing.splice(index, 1);
      const storageRef = ref(storage, `${user.uid}/advertise_${activateID}`);
      deleteObject(storageRef).then(async () => {
        await deleteDoc(doc(db, 'advertiseListing', activateID)).then(async () => {
          await updateDoc(doc(db, 'commonData', 'advertiseDetails'), {
            advertiseSlot: currentQuota
          }).then(() => {
            setProceedAdvertiseDelete(false);
            setActivateID(null);
            setLoadingSpinner(false);
            setAdvListing(newAdvListing);
          })
        })
      })


    })


  }

  useEffect(() => {
    // Retrieve the value from session storage when the component mounts

    if (currentTab != "dashboard") {
      // if (isReady == true) {
      sessionStorage.setItem('currentTab', currentTab);
    }

    // }

  }, [currentTab]);

  const expiredTime = () => {
    if (currentUserInfo != null) {
      try {
        let dateExpired = currentUserInfo.pointsDate.toDate();
        dateExpired.setDate(dateExpired.getDate() + 180);
        // console.log(dateExpired);
        return (`${dateExpired.getDate()}/${dateExpired.getMonth() + 1}/${dateExpired.getFullYear()}`)
      }
      catch (error) {
        return ""
      }
    }
    else {
      return ""
    }
  }



  const handleDashboardViewUserTypeAccountView = (e) => {
    if (dashboardViewUserType == "Tenant") {
      if (e.target.value == "Commercial Rent") {
        const now = new Date();
        var eyeballStat = [
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 }
        ]
        for (var i = 0; i < 7; i++) {
          const targetData = eyeballStat[i];
          targetData.date = formatDate(now, i);
        }
        if (userProject != null || userProject.length != 0) {
          for (var i = 0; i < userProject.length; i++) {
            if (userProject[i].details.status == "leasing") {
              eyeballStat[0].count = userProject[i].details.eyeballStat[0] + eyeballStat[0].count;
              eyeballStat[1].count = userProject[i].details.eyeballStat[1] + eyeballStat[1].count;
              eyeballStat[2].count = userProject[i].details.eyeballStat[2] + eyeballStat[2].count;
              eyeballStat[3].count = userProject[i].details.eyeballStat[3] + eyeballStat[3].count;
              eyeballStat[4].count = userProject[i].details.eyeballStat[4] + eyeballStat[4].count;
              eyeballStat[5].count = userProject[i].details.eyeballStat[5] + eyeballStat[5].count;
              eyeballStat[6].count = userProject[i].details.eyeballStat[6] + eyeballStat[6].count;
            }
          }
        }
        setEyeballStats(eyeballStat.reverse());
        setDashboardViewUserTypeAccountView("Commercial Rent");
        const randomIndex = Math.floor(Math.random() * userProject.length);
        if (userProject != null && userProject.length != 0) {
          setTenantDistrict(userProject[randomIndex].details.district);
          var tenantDistrictNum = 0;
          var sortedByEyeballListing = [...userProject];
          sortedByEyeballListing.sort((a, b) => {
            const aTime = a.details.eyeball ? a.details.eyeball : 0;
            const bTime = b.details.eyeball ? b.details.eyeball : 0;
            return bTime - aTime || (b.details.eyeball ? b.details.eyeball : 0) - (a.details.eyeball ? a.details.eyeball : 0);
          });
          for (var i = 0; i < sortedByEyeballListing.length; i++) {
            if (sortedByEyeballListing[i].details.district == userProject[randomIndex].details.district) {
              tenantDistrictNum++;
            }
          }
          setTenantDistrictNum(tenantDistrictNum);
          setDashboardTenantListing(userProject[0].details.listingName);
          if (sortedByEyeballListing.length >= 3) {
            var botIndex = Math.floor(sortedByEyeballListing.length / 3);
            var TopStopIndex = 0 + botIndex - 1;
            var MidStartIndex = botIndex;
            var MidStopIndex = botIndex + botIndex - 1;
            var BotStartIndex = botIndex + botIndex;
            for (var i = 0; i < sortedByEyeballListing.length; i++) {
              if (sortedByEyeballListing[i].details.listingName == userProject[0].details.listingName) {
                if (i <= TopStopIndex) {
                  setCANResLevel('Top');
                }
                else if (MidStartIndex <= i <= MidStopIndex) {
                  setCANResLevel('Mid');
                }
                else {
                  setCANResLevel('Bottom');
                }
              }
            }
          }
          else {
            setCANResLevel('Mid');
          }
        }
      }
      else if (e.target.value == "Commercial Sales") {
        const now = new Date();
        var eyeballStat = [
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 }
        ]
        for (var i = 0; i < 7; i++) {
          const targetData = eyeballStat[i];
          targetData.date = formatDate(now, i);
        }
        if (commercialListingPurcahse != null || commercialListingPurcahse.length != 0) {
          for (var i = 0; i < commercialListingPurcahse.length; i++) {
            if (commercialListingPurcahse[i].details.status == "leasing") {
              eyeballStat[0].count = commercialListingPurcahse[i].details.eyeballStat[0] + eyeballStat[0].count;
              eyeballStat[1].count = commercialListingPurcahse[i].details.eyeballStat[1] + eyeballStat[1].count;
              eyeballStat[2].count = commercialListingPurcahse[i].details.eyeballStat[2] + eyeballStat[2].count;
              eyeballStat[3].count = commercialListingPurcahse[i].details.eyeballStat[3] + eyeballStat[3].count;
              eyeballStat[4].count = commercialListingPurcahse[i].details.eyeballStat[4] + eyeballStat[4].count;
              eyeballStat[5].count = commercialListingPurcahse[i].details.eyeballStat[5] + eyeballStat[5].count;
              eyeballStat[6].count = commercialListingPurcahse[i].details.eyeballStat[6] + eyeballStat[6].count;
            }
          }
        }
        setEyeballStats(eyeballStat.reverse());
        setDashboardViewUserTypeAccountView("Commercial Sales");
        const randomIndex = Math.floor(Math.random() * commercialListingPurcahse.length);

        if (commercialListingPurcahse != null && commercialListingPurcahse.length != 0) {
          setTenantDistrict(commercialListingPurcahse[randomIndex].details.district[0]);
          var tenantDistrictNum = 0;
          var sortedByEyeballListing = [...commercialListingPurcahse];
          sortedByEyeballListing.sort((a, b) => {
            const aTime = a.details.eyeball ? a.details.eyeball : 0;
            const bTime = b.details.eyeball ? b.details.eyeball : 0;
            return bTime - aTime || (b.details.eyeball ? b.details.eyeball : 0) - (a.details.eyeball ? a.details.eyeball : 0);
          });
          for (var i = 0; i < sortedByEyeballListing.length; i++) {
            if (sortedByEyeballListing[i].details.district[0] == commercialListingPurcahse[randomIndex].details.district[0]) {
              tenantDistrictNum++;
            }
          }
          setTenantDistrictNum(tenantDistrictNum);
          setDashboardTenantListing(commercialListingPurcahse[0].details.listingName);
          if (sortedByEyeballListing.length >= 3) {
            var botIndex = Math.floor(sortedByEyeballListing.length / 3);
            var TopStopIndex = 0 + botIndex - 1;
            var MidStartIndex = botIndex;
            var MidStopIndex = botIndex + botIndex - 1;
            var BotStartIndex = botIndex + botIndex;
            for (var i = 0; i < sortedByEyeballListing.length; i++) {
              if (sortedByEyeballListing[i].details.listingName == commercialListingPurcahse[0].details.listingName) {
                if (i <= TopStopIndex) {
                  setCANResLevel('Top');
                }
                else if (MidStartIndex <= i <= MidStopIndex) {
                  setCANResLevel('Mid');
                }
                else {
                  setCANResLevel('Bottom');
                }
              }
            }
          }
          else {
            setCANResLevel('Mid');
          }
        }
      }
      else if (e.target.value == "Residential Rent") {
        // userResidentialListing
        const now = new Date();
        var eyeballStat = [
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 }
        ]
        for (var i = 0; i < 7; i++) {
          const targetData = eyeballStat[i];
          targetData.date = formatDate(now, i);
        }
        if (userResidentialListing != null || userResidentialListing.length != 0) {
          for (var i = 0; i < userResidentialListing.length; i++) {
            if (userResidentialListing[i].details.status == "leasing") {
              eyeballStat[0].count = userResidentialListing[i].details.eyeballStat[0] + eyeballStat[0].count;
              eyeballStat[1].count = userResidentialListing[i].details.eyeballStat[1] + eyeballStat[1].count;
              eyeballStat[2].count = userResidentialListing[i].details.eyeballStat[2] + eyeballStat[2].count;
              eyeballStat[3].count = userResidentialListing[i].details.eyeballStat[3] + eyeballStat[3].count;
              eyeballStat[4].count = userResidentialListing[i].details.eyeballStat[4] + eyeballStat[4].count;
              eyeballStat[5].count = userResidentialListing[i].details.eyeballStat[5] + eyeballStat[5].count;
              eyeballStat[6].count = userResidentialListing[i].details.eyeballStat[6] + eyeballStat[6].count;
            }
          }
        }
        setEyeballStats(eyeballStat.reverse());
        setDashboardViewUserTypeAccountView("Residential Rent");
        const randomIndex = Math.floor(Math.random() * userResidentialListing.length);

        if (userResidentialListing != null && userResidentialListing.length != 0) {
          setTenantDistrict(userResidentialListing[randomIndex].details.district);
          var tenantDistrictNum = 0;
          var sortedByEyeballListing = [...userResidentialListing];
          sortedByEyeballListing.sort((a, b) => {
            const aTime = a.details.eyeball ? a.details.eyeball : 0;
            const bTime = b.details.eyeball ? b.details.eyeball : 0;
            return bTime - aTime || (b.details.eyeball ? b.details.eyeball : 0) - (a.details.eyeball ? a.details.eyeball : 0);
          });
          for (var i = 0; i < sortedByEyeballListing.length; i++) {
            if (sortedByEyeballListing[i].details.district == userResidentialListing[randomIndex].details.district) {
              tenantDistrictNum++;
            }
          }
          setTenantDistrictNum(tenantDistrictNum);
          setDashboardTenantListing(userResidentialListing[0].details.listingName);
          if (sortedByEyeballListing.length >= 3) {
            var botIndex = Math.floor(sortedByEyeballListing.length / 3);
            var TopStopIndex = 0 + botIndex - 1;
            var MidStartIndex = botIndex;
            var MidStopIndex = botIndex + botIndex - 1;
            var BotStartIndex = botIndex + botIndex;
            for (var i = 0; i < sortedByEyeballListing.length; i++) {
              if (sortedByEyeballListing[i].details.listingName == userResidentialListing[0].details.listingName) {
                if (i <= TopStopIndex) {
                  setCANResLevel('Top');
                }
                else if (MidStartIndex <= i <= MidStopIndex) {
                  setCANResLevel('Mid');
                }
                else {
                  setCANResLevel('Bottom');
                }
              }
            }
          }
          else {
            setCANResLevel('Mid');
          }
        }
      }
      else {
        //residentialListingPurchase
        const now = new Date();
        var eyeballStat = [
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 }
        ]
        for (var i = 0; i < 7; i++) {
          const targetData = eyeballStat[i];
          targetData.date = formatDate(now, i);
        }
        if (residentialListingPurchase != null || residentialListingPurchase.length != 0) {
          for (var i = 0; i < residentialListingPurchase.length; i++) {
            if (residentialListingPurchase[i].details.status == "leasing") {
              eyeballStat[0].count = residentialListingPurchase[i].details.eyeballStat[0] + eyeballStat[0].count;
              eyeballStat[1].count = residentialListingPurchase[i].details.eyeballStat[1] + eyeballStat[1].count;
              eyeballStat[2].count = residentialListingPurchase[i].details.eyeballStat[2] + eyeballStat[2].count;
              eyeballStat[3].count = residentialListingPurchase[i].details.eyeballStat[3] + eyeballStat[3].count;
              eyeballStat[4].count = residentialListingPurchase[i].details.eyeballStat[4] + eyeballStat[4].count;
              eyeballStat[5].count = residentialListingPurchase[i].details.eyeballStat[5] + eyeballStat[5].count;
              eyeballStat[6].count = residentialListingPurchase[i].details.eyeballStat[6] + eyeballStat[6].count;
            }
          }
        }
        setEyeballStats(eyeballStat.reverse());
        setDashboardViewUserTypeAccountView("Residential Sales");
        const randomIndex = Math.floor(Math.random() * residentialListingPurchase.length);

        if (residentialListingPurchase != null && residentialListingPurchase.length != 0) {
          setTenantDistrict(residentialListingPurchase[randomIndex].details.district[0]);
          var tenantDistrictNum = 0;
          var sortedByEyeballListing = [...residentialListingPurchase];
          sortedByEyeballListing.sort((a, b) => {
            const aTime = a.details.eyeball ? a.details.eyeball : 0;
            const bTime = b.details.eyeball ? b.details.eyeball : 0;
            return bTime - aTime || (b.details.eyeball ? b.details.eyeball : 0) - (a.details.eyeball ? a.details.eyeball : 0);
          });
          for (var i = 0; i < sortedByEyeballListing.length; i++) {
            if (sortedByEyeballListing[i].details.district[0] == residentialListingPurchase[randomIndex].details.district[0]) {
              tenantDistrictNum++;
            }
          }
          setTenantDistrictNum(tenantDistrictNum);
          setDashboardTenantListing(residentialListingPurchase[0].details.listingName);
          if (sortedByEyeballListing.length >= 3) {
            var botIndex = Math.floor(sortedByEyeballListing.length / 3);
            var TopStopIndex = 0 + botIndex - 1;
            var MidStartIndex = botIndex;
            var MidStopIndex = botIndex + botIndex - 1;
            var BotStartIndex = botIndex + botIndex;
            for (var i = 0; i < sortedByEyeballListing.length; i++) {
              if (sortedByEyeballListing[i].details.listingName == residentialListingPurchase[0].details.listingName) {
                if (i <= TopStopIndex) {
                  setCANResLevel('Top');
                }
                else if (MidStartIndex <= i <= MidStopIndex) {
                  setCANResLevel('Mid');
                }
                else {
                  setCANResLevel('Bottom');
                }
              }
            }
          }
          else {
            setCANResLevel('Mid');
          }
        }

      }
    }
    else if (dashboardViewUserType == "Master Tenant" || dashboardViewUserType == "Landlord" || dashboardViewUserType == "Agent") {
      if (e.target.value == "Commercial Rent") {
        setDashboardViewUserTypeAccountView("Commercial Rent");
        if (currentUserInfo.hasOwnProperty('propertyAddressList')) {
          if (currentUserInfo.propertyAddressList.length != 0) {
            setSelectedOption(currentUserInfo.propertyAddressList[0].district);
            if (userProject.length != 0) {
              const now = new Date();
              var newListingTableData = [
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 }
              ]
              for (var i = 0; i < 7; i++) {
                const targetData = newListingTableData[i];
                targetData.date = formatDate(now, i);
              }

              for (var i = 0; i < userProject.length; i++) {
                if (userProject[i].details.district == currentUserInfo.propertyAddressList[0].district && userProject[i].details.status == "leasing") {
                  const dateFromFirebase = new Date(userProject[i].details.timePosted.seconds * 1000 + userProject[i].details.timePosted.nanoseconds / 1000000);

                  // Get today's date and time
                  // Calculate the time difference in milliseconds
                  const timeDiff = now.getTime() - dateFromFirebase.getTime();

                  // Convert the time difference from milliseconds to days
                  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
                  if (daysDiff >= 0 && daysDiff <= 6) {
                    const targetData = newListingTableData[daysDiff];
                    targetData.count += 1;

                  }
                }
              }
              setNewListingDataTable(newListingTableData.reverse());
            }
          }
          else {
            setSelectedOption('');
          }
        }
        else {
          setSelectedOption('');

          const now = new Date();
          var newListingTableData = [
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 }
          ]
          for (var i = 0; i < 7; i++) {
            const targetData = newListingTableData[i];
            targetData.date = formatDate(now, i);
          }
          setNewListingDataTable(newListingTableData.reverse());

        }
      }
      else if (e.target.value == "Commercial Sales") {
        setDashboardViewUserTypeAccountView("Commercial Sales");
        if (currentUserInfo.hasOwnProperty('propertyAddressList')) {
          if (currentUserInfo.propertyAddressList.length != 0) {
            setSelectedOption(currentUserInfo.propertyAddressList[0].district);
            if (userProject.length != 0) {
              const now = new Date();
              var newListingTableData = [
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 }
              ]
              for (var i = 0; i < 7; i++) {
                const targetData = newListingTableData[i];
                targetData.date = formatDate(now, i);
              }

              for (var i = 0; i < commercialListingPurcahse.length; i++) {
                if (commercialListingPurcahse[i].details.district[0] == currentUserInfo.propertyAddressList[0].district && commercialListingPurcahse[i].details.status == "leasing") {
                  const dateFromFirebase = new Date(commercialListingPurcahse[i].details.timePosted.seconds * 1000 + commercialListingPurcahse[i].details.timePosted.nanoseconds / 1000000);

                  // Get today's date and time


                  // Calculate the time difference in milliseconds
                  const timeDiff = now.getTime() - dateFromFirebase.getTime();

                  // Convert the time difference from milliseconds to days
                  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
                  if (daysDiff >= 0 && daysDiff <= 6) {
                    const targetData = newListingTableData[daysDiff];
                    targetData.count += 1;

                  }
                }
              }
              setNewListingDataTable(newListingTableData.reverse());
            }
          }
          else {
            setSelectedOption('');
          }
        }
        else {
          setSelectedOption('');

          const now = new Date();
          var newListingTableData = [
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 }
          ]
          for (var i = 0; i < 7; i++) {
            const targetData = newListingTableData[i];
            targetData.date = formatDate(now, i);
          }
          setNewListingDataTable(newListingTableData.reverse());

        }
      }
      else if (e.target.value == "Residential Rent") {
        setDashboardViewUserTypeAccountView("Residential Rent");
        if (currentUserInfo.hasOwnProperty('propertyAddressListResidential')) {
          if (currentUserInfo.propertyAddressListResidential.length != 0) {
            setSelectedOption(currentUserInfo.propertyAddressListResidential[0].district);
            if (userResidentialListing.length != 0) {
              const now = new Date();
              var newListingTableData = [
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 }
              ]
              for (var i = 0; i < 7; i++) {
                const targetData = newListingTableData[i];
                targetData.date = formatDate(now, i);
              }

              for (var i = 0; i < userResidentialListing.length; i++) {
                if (userResidentialListing[i].details.district == currentUserInfo.propertyAddressListResidential[0].district && userResidentialListing[i].details.status == "leasing") {
                  const dateFromFirebase = new Date(userResidentialListing[i].details.timePosted.seconds * 1000 + userResidentialListing[i].details.timePosted.nanoseconds / 1000000);

                  // Get today's date and time


                  // Calculate the time difference in milliseconds
                  const timeDiff = now.getTime() - dateFromFirebase.getTime();

                  // Convert the time difference from milliseconds to days
                  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
                  if (daysDiff >= 0 && daysDiff <= 6) {
                    const targetData = newListingTableData[daysDiff];
                    targetData.count += 1;

                  }
                }
              }
              setNewListingDataTable(newListingTableData.reverse());
            }
            else {
              const now = new Date();
              var newListingTableData = [
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 }
              ]
              for (var i = 0; i < 7; i++) {
                const targetData = newListingTableData[i];
                targetData.date = formatDate(now, i);
              }
              setNewListingDataTable(newListingTableData.reverse());
            }
          }
          else {
            const now = new Date();
            var newListingTableData = [
              { date: 'NA', count: 0 },
              { date: 'NA', count: 0 },
              { date: 'NA', count: 0 },
              { date: 'NA', count: 0 },
              { date: 'NA', count: 0 },
              { date: 'NA', count: 0 },
              { date: 'NA', count: 0 }
            ]
            for (var i = 0; i < 7; i++) {
              const targetData = newListingTableData[i];
              targetData.date = formatDate(now, i);
            }
            setNewListingDataTable(newListingTableData.reverse());
            setSelectedOption('');
          }
        }
        else {
          setSelectedOption('');

          const now = new Date();
          var newListingTableData = [
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 }
          ]
          for (var i = 0; i < 7; i++) {
            const targetData = newListingTableData[i];
            targetData.date = formatDate(now, i);
          }
          setNewListingDataTable(newListingTableData.reverse());

        }
      }
      else {
        setDashboardViewUserTypeAccountView("Residential Sales");
        if (currentUserInfo.hasOwnProperty('propertyAddressListResidential')) {
          if (currentUserInfo.propertyAddressListResidential.length != 0) {
            setSelectedOption(currentUserInfo.propertyAddressListResidential[0].district);
            if (residentialListingPurchase.length != 0) {
              const now = new Date();
              var newListingTableData = [
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 }
              ]
              for (var i = 0; i < 7; i++) {
                const targetData = newListingTableData[i];
                targetData.date = formatDate(now, i);
              }

              for (var i = 0; i < residentialListingPurchase.length; i++) {
                if (residentialListingPurchase[i].details.district[0] == currentUserInfo.propertyAddressListResidential[0].district && residentialListingPurchase[i].details.status == "leasing") {
                  const dateFromFirebase = new Date(residentialListingPurchase[i].details.timePosted.seconds * 1000 + residentialListingPurchase[i].details.timePosted.nanoseconds / 1000000);

                  // Get today's date and time


                  // Calculate the time difference in milliseconds
                  const timeDiff = now.getTime() - dateFromFirebase.getTime();

                  // Convert the time difference from milliseconds to days
                  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
                  if (daysDiff >= 0 && daysDiff <= 6) {
                    const targetData = newListingTableData[daysDiff];
                    targetData.count += 1;

                  }
                }
              }
              setNewListingDataTable(newListingTableData.reverse());
            }
          }
          else {
            setSelectedOption('');
          }
        }
        else {
          setSelectedOption('');

          const now = new Date();
          var newListingTableData = [
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 }
          ]
          for (var i = 0; i < 7; i++) {
            const targetData = newListingTableData[i];
            targetData.date = formatDate(now, i);
          }
          setNewListingDataTable(newListingTableData.reverse());

        }
      }
    }

  }



  const handleAdvertiseDashboardNameChange = (e) => {
    setAdvertiseDashboardName(e.target.value);
    if (e.target.value == "All") {
      const now = new Date();
      var eyeballStat = [
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 }
      ]
      for (var i = 0; i < 7; i++) {
        const targetData = eyeballStat[i];
        targetData.date = formatDate(now, i);
      }
      if (advListing.length != 0) {
        for (var i = 0; i < advListing.length; i++) {
          if (advListing[i].details.status == "approved" || advListing[i].details.status == "leasing") {
            eyeballStat[0].count = advListing[i].details.eyeballStat[0] + eyeballStat[0].count;
            eyeballStat[1].count = advListing[i].details.eyeballStat[1] + eyeballStat[1].count;
            eyeballStat[2].count = advListing[i].details.eyeballStat[2] + eyeballStat[2].count;
            eyeballStat[3].count = advListing[i].details.eyeballStat[3] + eyeballStat[3].count;
            eyeballStat[4].count = advListing[i].details.eyeballStat[4] + eyeballStat[4].count;
            eyeballStat[5].count = advListing[i].details.eyeballStat[5] + eyeballStat[5].count;
            eyeballStat[6].count = advListing[i].details.eyeballStat[6] + eyeballStat[6].count;
          }
        }
      }
      setEyeballStats(eyeballStat.reverse());
      const randomIndex = Math.floor(Math.random() * advListing.length);
    }
    else {
      const now = new Date();
      var eyeballStat = [
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 }
      ]
      for (var i = 0; i < 7; i++) {
        const targetData = eyeballStat[i];
        targetData.date = formatDate(now, i);
      }
      if (advListing.length != 0) {
        for (var i = 0; i < advListing.length; i++) {
          if ((advListing[i].details.status == "approved" || advListing[i].details.status == "leasing") && advListing[i].details.advertiseName == e.target.value) {
            eyeballStat[0].count = advListing[i].details.eyeballStat[0] + eyeballStat[0].count;
            eyeballStat[1].count = advListing[i].details.eyeballStat[1] + eyeballStat[1].count;
            eyeballStat[2].count = advListing[i].details.eyeballStat[2] + eyeballStat[2].count;
            eyeballStat[3].count = advListing[i].details.eyeballStat[3] + eyeballStat[3].count;
            eyeballStat[4].count = advListing[i].details.eyeballStat[4] + eyeballStat[4].count;
            eyeballStat[5].count = advListing[i].details.eyeballStat[5] + eyeballStat[5].count;
            eyeballStat[6].count = advListing[i].details.eyeballStat[6] + eyeballStat[6].count;
          }
        }
      }
      setEyeballStats(eyeballStat.reverse());
      const randomIndex = Math.floor(Math.random() * advListing.length);
    }

  }

  const ClickDashboard = () => {
    if (currentUserInfo != null) {
      if (currentUserInfo.userType != null) {

        setDashboardViewUserType(currentUserInfo.userType[0]);



        if (currentUserInfo.userType[0] == "Tenant") {
          const now = new Date();
          var eyeballStat = [
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 }
          ]
          for (var i = 0; i < 7; i++) {
            const targetData = eyeballStat[i];
            targetData.date = formatDate(now, i);
          }
          if (userProject.length != 0) {
            for (var i = 0; i < userProject.length; i++) {
              if (userProject[i].details.status == "leasing") {
                eyeballStat[0].count = userProject[i].details.eyeballStat[0] + eyeballStat[0].count;
                eyeballStat[1].count = userProject[i].details.eyeballStat[1] + eyeballStat[1].count;
                eyeballStat[2].count = userProject[i].details.eyeballStat[2] + eyeballStat[2].count;
                eyeballStat[3].count = userProject[i].details.eyeballStat[3] + eyeballStat[3].count;
                eyeballStat[4].count = userProject[i].details.eyeballStat[4] + eyeballStat[4].count;
                eyeballStat[5].count = userProject[i].details.eyeballStat[5] + eyeballStat[5].count;
                eyeballStat[6].count = userProject[i].details.eyeballStat[6] + eyeballStat[6].count;
              }
            }
          }
          setEyeballStats(eyeballStat.reverse());
          setDashboardViewUserTypeAccountView("Commercial Rent");
          const randomIndex = Math.floor(Math.random() * userProject.length);
          if (userProject != null && userProject.length != 0) {
            setTenantDistrict(userProject[randomIndex].details.district);
            var tenantDistrictNum = 0;
            var sortedByEyeballListing = [...userProject];
            sortedByEyeballListing.sort((a, b) => {
              const aTime = a.details.eyeball ? a.details.eyeball : 0;
              const bTime = b.details.eyeball ? b.details.eyeball : 0;
              return bTime - aTime || (b.details.eyeball ? b.details.eyeball : 0) - (a.details.eyeball ? a.details.eyeball : 0);
            });
            setSortedEyeballUserProject(sortedByEyeballListing);
            for (var i = 0; i < sortedByEyeballListing.length; i++) {
              if (sortedByEyeballListing[i].details.district == userProject[randomIndex].details.district) {
                tenantDistrictNum++;
              }
            }
            setTenantDistrictNum(tenantDistrictNum);
            setDashboardTenantListing(userProject[0].details.listingName);
            if (sortedByEyeballListing.length >= 3) {
              var botIndex = Math.floor(sortedByEyeballListing.length / 3);
              var TopStopIndex = 0 + botIndex - 1;
              var MidStartIndex = botIndex;
              var MidStopIndex = botIndex + botIndex - 1;
              var BotStartIndex = botIndex + botIndex;
              for (var i = 0; i < sortedByEyeballListing.length; i++) {
                if (sortedByEyeballListing[i].details.listingName == userProject[0].details.listingName) {
                  if (i <= TopStopIndex) {
                    setCANResLevel('Top');
                  }
                  else if (MidStartIndex <= i <= MidStopIndex) {
                    setCANResLevel('Mid');
                  }
                  else {
                    setCANResLevel('Bottom');
                  }
                }
              }
            }
            else {
              setCANResLevel('Mid');
            }
          }

        }
        else if (currentUserInfo.userType[0] == "Agent" || currentUserInfo.userType[0] == "Master Tenant" || currentUserInfo.userType[0] == "Landlord") {
          if (currentUserInfo.hasOwnProperty('propertyAddressList')) {
            if (currentUserInfo.propertyAddressList.length != 0) {
              setSelectedOption(currentUserInfo.propertyAddressList[0].district);
              if (userProject.length != 0) {
                const now = new Date();
                var newListingTableData = [
                  { date: 'NA', count: 0 },
                  { date: 'NA', count: 0 },
                  { date: 'NA', count: 0 },
                  { date: 'NA', count: 0 },
                  { date: 'NA', count: 0 },
                  { date: 'NA', count: 0 },
                  { date: 'NA', count: 0 }
                ]
                for (var i = 0; i < 7; i++) {
                  const targetData = newListingTableData[i];
                  targetData.date = formatDate(now, i);
                }

                for (var i = 0; i < userProject.length; i++) {
                  if (userProject[i].details.district == currentUserInfo.propertyAddressList[0].district && userProject[i].details.status == "leasing") {
                    const dateFromFirebase = new Date(userProject[i].details.timePosted.seconds * 1000 + userProject[i].details.timePosted.nanoseconds / 1000000);

                    // Get today's date and time


                    // Calculate the time difference in milliseconds
                    const timeDiff = now.getTime() - dateFromFirebase.getTime();

                    // Convert the time difference from milliseconds to days
                    const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
                    if (daysDiff >= 0 && daysDiff <= 6) {
                      const targetData = newListingTableData[daysDiff];
                      targetData.count += 1;

                    }
                  }
                }
                setNewListingDataTable(newListingTableData.reverse());
              }
              else {
                const now = new Date();
                var newListingTableData = [
                  { date: 'NA', count: 0 },
                  { date: 'NA', count: 0 },
                  { date: 'NA', count: 0 },
                  { date: 'NA', count: 0 },
                  { date: 'NA', count: 0 },
                  { date: 'NA', count: 0 },
                  { date: 'NA', count: 0 }
                ]
                for (var i = 0; i < 7; i++) {
                  const targetData = newListingTableData[i];
                  targetData.date = formatDate(now, i);
                }
                setNewListingDataTable(newListingTableData.reverse());
              }
            }
            else {
              const now = new Date();
              var newListingTableData = [
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 },
                { date: 'NA', count: 0 }
              ]
              for (var i = 0; i < 7; i++) {
                const targetData = newListingTableData[i];
                targetData.date = formatDate(now, i);
              }
              setNewListingDataTable(newListingTableData.reverse());
              setSelectedOption('');
            }
          }
          else {
            setSelectedOption('');

            const now = new Date();
            var newListingTableData = [
              { date: 'NA', count: 0 },
              { date: 'NA', count: 0 },
              { date: 'NA', count: 0 },
              { date: 'NA', count: 0 },
              { date: 'NA', count: 0 },
              { date: 'NA', count: 0 },
              { date: 'NA', count: 0 }
            ]
            for (var i = 0; i < 7; i++) {
              const targetData = newListingTableData[i];
              targetData.date = formatDate(now, i);
            }
            setNewListingDataTable(newListingTableData.reverse());

          }
        }
        else {
          const now = new Date();
          var eyeballStat = [
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 },
            { date: 'NA', count: 0 }
          ]
          for (var i = 0; i < 7; i++) {
            const targetData = eyeballStat[i];
            targetData.date = formatDate(now, i);
          }
          if (advListing.length != 0) {
            for (var i = 0; i < advListing.length; i++) {
              if (advListing[i].details.status == "approved" || advListing[i].details.status == "leasing") {
                eyeballStat[0].count = advListing[i].details.eyeballStat[0] + eyeballStat[0].count;
                eyeballStat[1].count = advListing[i].details.eyeballStat[1] + eyeballStat[1].count;
                eyeballStat[2].count = advListing[i].details.eyeballStat[2] + eyeballStat[2].count;
                eyeballStat[3].count = advListing[i].details.eyeballStat[3] + eyeballStat[3].count;
                eyeballStat[4].count = advListing[i].details.eyeballStat[4] + eyeballStat[4].count;
                eyeballStat[5].count = advListing[i].details.eyeballStat[5] + eyeballStat[5].count;
                eyeballStat[6].count = advListing[i].details.eyeballStat[6] + eyeballStat[6].count;
              }
            }
          }
          setEyeballStats(eyeballStat.reverse());
          const randomIndex = Math.floor(Math.random() * advListing.length);
          if (advListing != null && advListing.length != 0) {
            // setAdvertiseDashboardName(advListing[randomIndex].details.advertiseName);
            var tenantDistrictNum = 0;
            var sortedByEyeballListing = [...advListing];
            sortedByEyeballListing.sort((a, b) => {
              const aTime = a.details.eyeball ? a.details.eyeball : 0;
              const bTime = b.details.eyeball ? b.details.eyeball : 0;
              return bTime - aTime || (b.details.eyeball ? b.details.eyeball : 0) - (a.details.eyeball ? a.details.eyeball : 0);
            });

          }
        }

      }
      else {


      }






      if (messageClick == true) {
        var footer = document.getElementById("footer");
        footer.style.display = null;
        // var leftAccount = document.getElementById("leftAccountMessageClick");
        // leftAccount.id = "leftAccount";
        // var rightAccount = document.getElementById("rightAccountMessageClick");
        // rightAccount.id = "rightAccount";
      }
      setDashboardClick(true);
      setMessageClick(false);
      setListingClick(false);
      setShortListClick(false);
      setPendingMessagePage(false);
      setAccountClick(false);
      setPurchaseClick(false);
      setAdvertiseClick(false);
      setCurrentTab("dashboard");
      setBreadCrumbDic(['Dashboard']);
      // var messageTab = document.getElementById('btn-Message');
      // messageTab.setAttribute('class', 'account-btn')
      // var listingTab = document.getElementById('btn-Listing');
      // listingTab.setAttribute('class', 'account-btn');
      // var shortlistTab = document.getElementById('btn-ShortList');
      // shortlistTab.setAttribute('class', 'account-btn');
      // var pendingMessageTab = document.getElementById('btn-PendingMessage');
      // pendingMessageTab.setAttribute('class', 'account-btn');
      // var accountTab = document.getElementById('btn-Account');
      // accountTab.setAttribute('class', 'account-btn');
      // var dashboardTab = document.getElementById('btn-Dashboard');
      // dashboardTab.setAttribute('class', 'activeAccount');
      // var purchaseTab = document.getElementById('btn-Purchase');
      // purchaseTab.setAttribute('class', 'account-btn');
      // var advertiseTab = document.getElementById('btn-Advertise');
      // advertiseTab.setAttribute('class', 'account-btn');
    }

  }

  const ClickOnPurchase = () => {
    if (messageClick == true) {
      var footer = document.getElementById("footer");
      footer.style.display = null;
    }
    setMessageClick(false);
    setListingClick(false);
    setShortListClick(false);
    setPendingMessagePage(false);
    setAccountClick(false);
    setDashboardClick(false);
    setPurchaseClick(true);
    setAdvertiseClick(false);
    setCurrentTab("purchase")
    setBreadCrumbDic(['Purchase Requirements']);
    // var messageTab = document.getElementById('btn-Message');
    // messageTab.setAttribute('class', 'account-btn')
    // var listingTab = document.getElementById('btn-Listing');
    // listingTab.setAttribute('class', 'account-btn');
    // var purchaseTab = document.getElementById('btn-Purchase');
    // purchaseTab.setAttribute('class', 'activeAccount');
    // var shortlistTab = document.getElementById('btn-ShortList');
    // shortlistTab.setAttribute('class', 'account-btn');
    // var pendingMessageTab = document.getElementById('btn-PendingMessage');
    // pendingMessageTab.setAttribute('class', 'account-btn');
    // var accountTab = document.getElementById('btn-Account');
    // accountTab.setAttribute('class', 'account-btn');
    // var dashboardTab = document.getElementById('btn-Dashboard');
    // dashboardTab.setAttribute('class', 'account-btn');
    // var advertiseTab = document.getElementById('btn-Advertise');
    // advertiseTab.setAttribute('class', 'account-btn');
  }

  const ClickOnAdvertise = () => {
    if (messageClick == true) {
      var footer = document.getElementById("footer");
      footer.style.display = null;
    }
    setMessageClick(false);
    setListingClick(false);
    setShortListClick(false);
    setPendingMessagePage(false);
    setAccountClick(false);
    setDashboardClick(false);
    setPurchaseClick(false);
    setAdvertiseClick(true);
    setCurrentTab("advertise");
    setBreadCrumbDic(['Advertise']);
    // var messageTab = document.getElementById('btn-Message');
    // messageTab.setAttribute('class', 'account-btn')
    // var listingTab = document.getElementById('btn-Listing');
    // listingTab.setAttribute('class', 'account-btn');
    // var shortlistTab = document.getElementById('btn-ShortList');
    // shortlistTab.setAttribute('class', 'account-btn');
    // var pendingMessageTab = document.getElementById('btn-PendingMessage');
    // pendingMessageTab.setAttribute('class', 'account-btn');
    // var accountTab = document.getElementById('btn-Account');
    // accountTab.setAttribute('class', 'account-btn');
    // var dashboardTab = document.getElementById('btn-Dashboard');
    // dashboardTab.setAttribute('class', 'account-btn');
    // var purchaseTab = document.getElementById('btn-Purchase');
    // purchaseTab.setAttribute('class', 'account-btn');
    // var advertiseTab = document.getElementById('btn-Advertise');
    // advertiseTab.setAttribute('class', 'activeAccount');
  }

  const ClickOnListing = () => {
    if (messageClick == true) {
      var footer = document.getElementById("footer");
      footer.style.display = null;
      // var leftAccount = document.getElementById("leftAccountMessageClick");
      // leftAccount.id = "leftAccount";
      // var rightAccount = document.getElementById("rightAccountMessageClick");
      // rightAccount.id = "rightAccount";
    }
    setMessageClick(false);
    setListingClick(true);
    setShortListClick(false);
    setPendingMessagePage(false);
    setAccountClick(false);
    setDashboardClick(false);
    setAdvertiseClick(false);
    setPurchaseClick(false);
    setCurrentTab("listing");
    setBreadCrumbDic(['Rent Requirements']);
    // var messageTab = document.getElementById('btn-Message');
    // messageTab.setAttribute('class', 'account-btn')
    // var listingTab = document.getElementById('btn-Listing');
    // listingTab.setAttribute('class', 'activeAccount');
    // var shortlistTab = document.getElementById('btn-ShortList');
    // shortlistTab.setAttribute('class', 'account-btn');
    // var pendingMessageTab = document.getElementById('btn-PendingMessage');
    // pendingMessageTab.setAttribute('class', 'account-btn');
    // var accountTab = document.getElementById('btn-Account');
    // accountTab.setAttribute('class', 'account-btn');
    // var dashboardTab = document.getElementById('btn-Dashboard');
    // dashboardTab.setAttribute('class', 'account-btn');
    // var purchaseTab = document.getElementById('btn-Purchase');
    // purchaseTab.setAttribute('class', 'account-btn');
    // var advertiseTab = document.getElementById('btn-Advertise');
    // advertiseTab.setAttribute('class', 'account-btn');
  }

  const ClickShortList = () => {
    if (messageClick == true) {
      var footer = document.getElementById("footer");
      footer.style.display = null;
      // var leftAccount = document.getElementById("leftAccountMessageClick");
      // leftAccount.id = "leftAccount";
      // var rightAccount = document.getElementById("rightAccountMessageClick");
      // rightAccount.id = "rightAccount";
    }
    setMessageClick(false);
    setListingClick(false);
    setShortListClick(true);
    setPendingMessagePage(false);
    setAccountClick(false);
    setDashboardClick(false);
    setPurchaseClick(false);
    setAdvertiseClick(false);
    setCurrentTab("shortList");
    setBreadCrumbDic(['Favourites']);
    // setChatClick(false);
    // var messageTab = document.getElementById('btn-Message');
    // messageTab.setAttribute('class', 'account-btn')
    // var listingTab = document.getElementById('btn-Listing');
    // listingTab.setAttribute('class', 'account-btn');
    // var shortlistTab = document.getElementById('btn-ShortList');
    // shortlistTab.setAttribute('class', 'activeAccount');
    // var pendingMessageTab = document.getElementById('btn-PendingMessage');
    // pendingMessageTab.setAttribute('class', 'account-btn');
    // var accountTab = document.getElementById('btn-Account');
    // accountTab.setAttribute('class', 'account-btn');
    // var dashboardTab = document.getElementById('btn-Dashboard');
    // dashboardTab.setAttribute('class', 'account-btn');
    // var purchaseTab = document.getElementById('btn-Purchase');
    // purchaseTab.setAttribute('class', 'account-btn');
    // var advertiseTab = document.getElementById('btn-Advertise');
    // advertiseTab.setAttribute('class', 'account-btn');
  }

  const ClickOnPendingMessage = () => {
    if (messageClick == true) {
      var footer = document.getElementById("footer");
      footer.style.display = null;
      // var leftAccount = document.getElementById("leftAccountMessageClick");
      // leftAccount.id = "leftAccount";
      // var rightAccount = document.getElementById("rightAccountMessageClick");
      // rightAccount.id = "rightAccount";
    }
    setMessageClick(false);
    setListingClick(false);
    setShortListClick(false);
    setPendingMessagePage(true);
    setAccountClick(false);
    setPurchaseClick(false);
    setAdvertiseClick(false);
    setDashboardClick(false);
    setCurrentTab("pendingMessage");
    setBreadCrumbDic(['Inbox']);
    // var messageTab = document.getElementById('btn-Message');
    // messageTab.setAttribute('class', 'account-btn')
    // var listingTab = document.getElementById('btn-Listing');
    // listingTab.setAttribute('class', 'account-btn');
    // var shortlistTab = document.getElementById('btn-ShortList');
    // shortlistTab.setAttribute('class', 'account-btn');
    // var pendingMessageTab = document.getElementById('btn-PendingMessage');
    // pendingMessageTab.setAttribute('class', 'activeAccount');
    // var accountTab = document.getElementById('btn-Account');
    // accountTab.setAttribute('class', 'account-btn');
    // var dashboardTab = document.getElementById('btn-Dashboard');
    // dashboardTab.setAttribute('class', 'account-btn');
    // var purchaseTab = document.getElementById('btn-Purchase');
    // purchaseTab.setAttribute('class', 'account-btn');
    // var advertiseTab = document.getElementById('btn-Advertise');
    // advertiseTab.setAttribute('class', 'account-btn');
  }

  const ClickOnMessage = () => {
    setDashboardClick(false);
    setMessageClick(true);
    setListingClick(false);
    setShortListClick(false);
    setPendingMessagePage(false);
    setAccountClick(false);
    setPurchaseClick(false);
    setAdvertiseClick(false);
    setCurrentTab("message")
    setBreadCrumbDic(['Chat Message']);
    // var messageTab = document.getElementById('btn-Message');
    // messageTab.setAttribute('class', 'activeAccount')
    // var listingTab = document.getElementById('btn-Listing');
    // listingTab.setAttribute('class', 'account-btn')
    // var shortlistTab = document.getElementById('btn-ShortList');
    // shortlistTab.setAttribute('class', 'account-btn')
    // var pendingMessageTab = document.getElementById('btn-PendingMessage');
    // pendingMessageTab.setAttribute('class', 'account-btn')
    // var accountTab = document.getElementById('btn-Account');
    // accountTab.setAttribute('class', 'account-btn');
    // var dashboardTab = document.getElementById('btn-Dashboard');
    // dashboardTab.setAttribute('class', 'account-btn');
    // var purchaseTab = document.getElementById('btn-Purchase');
    // purchaseTab.setAttribute('class', 'account-btn');
    // var advertiseTab = document.getElementById('btn-Advertise');
    // advertiseTab.setAttribute('class', 'account-btn');

    var footer = document.getElementById("footer");
    footer.style.display = "none";
    var leftAccount = document.getElementById("leftAccount");
    // leftAccount.id = "leftAccountMessageClick";
    var rightAccount = document.getElementById("rightAccount");
    // rightAccount.id = "rightAccountMessageClick";
    // var thirdPartyTab = document.getElementById('btn-ThirdParty');
    // thirdPartyTab.setAttribute('class', 'tablinks');
  }

  const ClickOnAccount = () => {
    if (messageClick == true) {
      var footer = document.getElementById("footer");
      footer.style.display = null;
      // var leftAccount = document.getElementById("leftAccountMessageClick");
      // leftAccount.id = "leftAccount";
      // var rightAccount = document.getElementById("rightAccountMessageClick");
      // rightAccount.id = "rightAccount";
    }
    setMessageClick(false);
    setListingClick(false);
    setShortListClick(false);
    setPendingMessagePage(false);
    setPurchaseClick(false);
    setAccountClick(true);
    setDashboardClick(false);

    setAdvertiseClick(false);
    setCurrentTab("account");
    setBreadCrumbDic(['Profile']);
    // var messageTab = document.getElementById('btn-Message');
    // messageTab.setAttribute('class', 'account-btn')
    // var listingTab = document.getElementById('btn-Listing');
    // listingTab.setAttribute('class', 'account-btn');
    // var shortlistTab = document.getElementById('btn-ShortList');
    // shortlistTab.setAttribute('class', 'account-btn');
    // var pendingMessageTab = document.getElementById('btn-PendingMessage');
    // pendingMessageTab.setAttribute('class', 'account-btn');
    // var accountTab = document.getElementById('btn-Account');
    // accountTab.setAttribute('class', 'activeAccount');
    // var purchaseTab = document.getElementById('btn-Purchase');
    // purchaseTab.setAttribute('class', 'account-btn');
    // var dashboardTab = document.getElementById('btn-Dashboard');
    // dashboardTab.setAttribute('class', 'account-btn');
    // var advertiseTab = document.getElementById('btn-Advertise');
    // advertiseTab.setAttribute('class', 'account-btn');
  }
  useEffect(() => {
    if (currentUserInfo != null) {
      if (currentUserInfo.membership != "admin") {
        if (props.mobileClick == "Dashboard" && isReady == true) {
          ClickDashboard();
          // props.setMobileClick(null);
        }
        else if (props.mobileClick == "Rent" && isReady == true) {
          ClickOnListing();
          // props.setMobileClick(null);
        }
        else if (props.mobileClick == "Purchase" && isReady == true) {
          ClickOnPurchase();
        }
        else if (props.mobileClick == "Advertise" && isReady == true) {
          ClickOnAdvertise();
        }
        else if (props.mobileClick == "Favourites" && isReady == true) {
          ClickShortList();
          // props.setMobileClick(null);
        }
        else if (props.mobileClick == "Message" && isReady == true) {
          ClickOnMessage();
          // props.setMobileClick(null);
        }
        else if (props.mobileClick == "PendingMessage" && isReady == true) {
          ClickOnPendingMessage();
          // props.setMobileClick(null);
        }
        else if (props.mobileClick == "Profile" && isReady == true) {
          ClickOnAccount();
          // props.setMobileClick(null);
        }
        else if (isReady == true) {
          if (isReady == true) {
            var savedValue = sessionStorage.getItem('currentTab');
            if (savedValue != null) {
              savedValue = savedValue.trim();
              sessionStorage.removeItem('currentTab');
              if (savedValue === "account".trim()) {
                ClickOnAccount();
              }
              else if (savedValue == "message".trim()) {
                ClickOnMessage();
              }
              else if (savedValue == "listing".trim()) {
                ClickOnListing();
              }
              else if (savedValue == "shortList".trim()) {
                ClickShortList();
              }
              else if (savedValue == "pendingMessage".trim()) {
                ClickOnPendingMessage();
              }
              else if (savedValue == "purchase".trim()) {
                ClickOnPurchase();
              }
              else if (savedValue == "advertise".trim()) {
                ClickOnAdvertise();
              }
              else {
                ClickDashboard();
              }
            }
            else {
              ClickDashboard();
            }


          }
        }

      }
    }

  }, [props, isReady])




  const deleteStorageFirebase = async (folderRef) => {
    const { prefixes, items } = await listAll(folderRef);
    // Delete all files
    const deleteFilePromises = items.map((item) => deleteObject(item));
    await Promise.all(deleteFilePromises);

    // Recursively delete subfolders
    const deleteFolderPromises = prefixes.map((prefix) => deleteStorageFirebase(prefix));
    await Promise.all(deleteFolderPromises);

    // Delete the current folder
    // await deleteObject(folderRef);
  };

  const disableAccount = async () => {
    var credential = null;
    if (user.providerData[0].providerId == "google.com") {
      const provider = new GoogleAuthProvider();
      reauthenticateWithPopup(user, provider).then(async (result) => {
        credential = result.credential;

        var deleteCurrentUserInfo = currentUserInfo;
        deleteCurrentUserInfo.registeredEmail = user.email;
        deleteCurrentUserInfo.deleteDate = new Date();
        await setDoc(doc(db, `deleteUserInfo`, user.uid), deleteCurrentUserInfo);
        //Commercial Rent
        const q = query(collection(db, "projects"), where("UID", "==", user.uid));
        // Get all the documents that match the query criteria
        const querySnapshot = await getDocs(q);
        // Loop through each document in the result set
        querySnapshot.forEach(async (indivDoc) => {

          await setDoc(doc(db, 'deleteListing', indivDoc.id), indivDoc.data()).then(async () => {
            await getDocs(collection(db, `projects/${indivDoc.id}/MoreDetails`)).then((moreDetailsDoc) => {
              moreDetailsDoc.forEach(async (moreDetailsDocIndiv) => {
                await addDoc(collection(db, `deleteListing/${indivDoc.id}/MoreDetails`), moreDetailsDocIndiv.data(), moreDetailsDocIndiv.id).then(async () => {
                  await deleteDoc(doc(db, `projects/${indivDoc.id}/MoreDetails`, moreDetailsDocIndiv.id));
                });
              })
            })
            await getDocs(collection(db, `projects/${indivDoc.id}/PreferredDetails`)).then((preferredDetailsDoc) => {
              preferredDetailsDoc.forEach(async (preferredDetailsDocIndiv) => {
                await addDoc(collection(db, `deleteListing/${indivDoc.id}/PreferredDetails`), preferredDetailsDocIndiv.data(), preferredDetailsDocIndiv.id).then(async () => {
                  await deleteDoc(doc(db, `projects/${indivDoc.id}/PreferredDetails`, preferredDetailsDocIndiv.id));
                });
              })
            })
          })
            .then(async () => {
              // await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, indivDoc.id));
              await deleteDoc(doc(db, `projects/`, indivDoc.id))
            })

        });
        //Commercial Sale
        const commercialSale = query(collection(db, "listingCommercialPurchase"), where("UID", "==", user.uid));
        // Get all the documents that match the query criteria
        const queryCommercialSale = await getDocs(commercialSale);

        // Loop through each document in the result set
        queryCommercialSale.forEach(async (indivDoc) => {

          await setDoc(doc(db, 'deleteListing', indivDoc.id), indivDoc.data()).then(async () => {
            await getDocs(collection(db, `listingCommercialPurchase/${indivDoc.id}/PreferredDetails`)).then((preferredDetailsDoc) => {
              preferredDetailsDoc.forEach(async (preferredDetailsDocIndiv) => {
                await addDoc(collection(db, `deleteListing/${indivDoc.id}/PreferredDetails`), preferredDetailsDocIndiv.data(), preferredDetailsDocIndiv.id).then(async () => {
                  await deleteDoc(doc(db, `listingCommercialPurchase/${indivDoc.id}/PreferredDetails`, preferredDetailsDocIndiv.id));
                });
              })
            })
          })
            .then(async () => {
              // await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, indivDoc.id));
              await deleteDoc(doc(db, `listingCommercialPurchase/`, indivDoc.id))
            })

        });
        //Residential Rent
        const residentialRent = query(collection(db, "residentialListing"), where("UID", "==", user.uid));
        // Get all the documents that match the query criteria
        const queryResidentialRent = await getDocs(residentialRent);

        // Loop through each document in the result set
        queryResidentialRent.forEach(async (indivDoc) => {

          await setDoc(doc(db, 'deleteListing', indivDoc.id), indivDoc.data()).then(async () => {
            await getDocs(collection(db, `residentialListing/${indivDoc.id}/MoreDetails`)).then((moreDetailsDoc) => {
              moreDetailsDoc.forEach(async (moreDetailsDocIndiv) => {
                await addDoc(collection(db, `deleteListing/${indivDoc.id}/MoreDetails`), moreDetailsDocIndiv.data(), moreDetailsDocIndiv.id).then(async () => {
                  await deleteDoc(doc(db, `residentialListing/${indivDoc.id}/MoreDetails`, moreDetailsDocIndiv.id));
                });
              })
            })
            await getDocs(collection(db, `residentialListing/${indivDoc.id}/PreferredDetails`)).then((preferredDetailsDoc) => {
              preferredDetailsDoc.forEach(async (preferredDetailsDocIndiv) => {
                await addDoc(collection(db, `deleteListing/${indivDoc.id}/PreferredDetails`), preferredDetailsDocIndiv.data(), preferredDetailsDocIndiv.id).then(async () => {
                  await deleteDoc(doc(db, `residentialListing/${indivDoc.id}/PreferredDetails`, preferredDetailsDocIndiv.id));
                });
              })
            })
          })
            .then(async () => {
              await deleteDoc(doc(db, `residentialListing/`, indivDoc.id))
            })

        });
        //Residential Sale
        const residentialSale = query(collection(db, "residentialListingPurchase"), where("UID", "==", user.uid));
        // Get all the documents that match the query criteria
        const queryResidentialSale = await getDocs(residentialSale);

        // Loop through each document in the result set
        queryResidentialSale.forEach(async (indivDoc) => {

          await setDoc(doc(db, 'deleteListing', indivDoc.id), indivDoc.data()).then(async () => {
            getDocs(collection(db, `residentialListingPurchase/${indivDoc.id}/PreferredDetails`)).then((preferredDetailsDoc) => {
              preferredDetailsDoc.forEach(async (preferredDetailsDocIndiv) => {
                await addDoc(collection(db, `deleteListing/${indivDoc.id}/PreferredDetails`), preferredDetailsDocIndiv.data(), preferredDetailsDocIndiv.id).then(async () => {
                  await deleteDoc(doc(db, `residentialListingPurchase/${indivDoc.id}/PreferredDetails`, preferredDetailsDocIndiv.id));
                });
              })
            })
          })
            .then(async () => {
              // await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, indivDoc.id));
              await deleteDoc(doc(db, `residentialListingPurchase/`, indivDoc.id))
            })
        });
        //Advertise
        const advertiseListing = query(collection(db, "advertiseListing"), where("UID", "==", user.uid));
        // Get all the documents that match the query criteria
        const queryAdvertiseListing = await getDocs(advertiseListing);

        // Loop through each document in the result set
        queryAdvertiseListing.forEach(async (indivDoc) => {


          await deleteDoc(doc(db, `advertiseListing/`, indivDoc.id))
          // resolve();


        });
        // cancelMembership("delete");


        await getDocs(collection(db, `userInfo/${user.uid}/shortList`)).then((replyFromSL) => {
          replyFromSL.forEach(async (indivSL) => {
            await deleteDoc(doc(db, `userInfo/${user.uid}/shortList`, indivSL.id));
          })
        })



        await getDoc(doc(db, `userChat`, user.uid)).then(async (chatInfo) => {
          for (const key in chatInfo.data()) {
            if (chatInfo.data()[key].userInfo.status != "Pending") {

              var keyID = chatInfo.data()[key].userInfo.oppositeUID;

              await updateDoc(doc(db, 'chats', key), {

                messages: arrayUnion({
                  createdAt: new Date(),
                  text: "Account has been deleted",
                  sender: user.uid,
                  senderName: user.displayName
                }),

              });
              await getDoc(doc(db, `userChat`, keyID)).then(async (oppChatInfo) => {
                if (oppChatInfo.data()[key].userInfo.status != "Pending") {
                  if (oppChatInfo.data()[key].userInfo.chatType == "CommercialRent") {
                    if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            agentProfile: oppChatInfo.data()[key].userInfo.agentProfile,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            listingPhoto: oppChatInfo.data()[key].userInfo.listingPhoto,
                            listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                            listingPricePerSquareFeet: oppChatInfo.data()[key].userInfo.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                    else {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            listingPhoto: oppChatInfo.data()[key].userInfo.listingPhoto,
                            listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                            listingPricePerSquareFeet: oppChatInfo.data()[key].userInfo.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }

                  }
                  else if (oppChatInfo.data()[key].userInfo.chatType == "CommercialSales") {
                    if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            agentProfile: oppChatInfo.data()[key].userInfo.agentProfile,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            minFloorSizePSFBudget: oppChatInfo.data()[key].userInfo.minFloorSizePSFBudget,
                            moveInPeriod: oppChatInfo.data()[key].moveInPeriod != undefined ? new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000) : new Date(),
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                    else {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            minFloorSizePSFBudget: oppChatInfo.data()[key].userInfo.minFloorSizePSFBudget,
                            moveInPeriod: oppChatInfo.data()[key].moveInPeriod != undefined ? new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000) : new Date(),
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                  }
                  else if (oppChatInfo.data()[key].userInfo.chatType == "ResidentialRent") {
                    if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            agentProfile: oppChatInfo.data()[key].userInfo.agentProfile,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                            moveInPeriod: oppChatInfo.data()[key].moveInPeriod != undefined ? new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000) : new Date(),
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                    else {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                            moveInPeriod: oppChatInfo.data()[key].moveInPeriod != undefined ? new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000) : new Date(),
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                  }
                  else if (oppChatInfo.data()[key].userInfo.chatType == "ResidentialSales") {
                    if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            agentProfile: oppChatInfo.data()[key].userInfo.agentProfile,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            budgetOverall: oppChatInfo.data()[key].userInfo.budgetOverall,
                            moveInPeriod: oppChatInfo.data()[key].moveInPeriod != undefined ? new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000) : new Date(),
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                    else {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            budgetOverall: oppChatInfo.data()[key].userInfo.budgetOverall,
                            moveInPeriod: oppChatInfo.data()[key].moveInPeriod != undefined ? new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000) : new Date(),
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                  }
                  else if (oppChatInfo.data()[key].userInfo.chatType == "sellerProfile") {
                    //seller Profile
                    console.log(oppChatInfo.data()[key].userInfo);
                    await updateDoc(doc(db, 'userChat', keyID), {
                      [key]: {
                        date: serverTimestamp(),
                        userInfo: {
                          PSFValue: oppChatInfo.data()[key].userInfo.PSFValue,
                          address: oppChatInfo.data()[key].userInfo.address,
                          chatType: oppChatInfo.data()[key].userInfo.chatType,
                          agentProfileDeck: oppChatInfo.data()[key].userInfo.agentProfileDeck,
                          commission: oppChatInfo.data()[key].userInfo.commission,
                          displayName: publicInfo.username,
                          lastMessage: "Account has been deleted",
                          myUID: oppChatInfo.data()[key].userInfo.myUID,
                          oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                          oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                          photoURL: currentUserInfo.userPhotoURL,
                          postalCode: oppChatInfo.data()[key].userInfo.postalCode,
                          sellingPrice: oppChatInfo.data()[key].userInfo.sellingPrice,
                          status: "Deleted",
                          statusRead: "Unread",
                          unitNumber: oppChatInfo.data()[key].userInfo.unitNumber
                        }
                      }
                    })

                  }
                }
              })
            }
            else if (chatInfo.data()[key].userInfo.status == "Pending") {
              var keyID = chatInfo.data()[key].userInfo.oppositeUID;

              await updateDoc(doc(db, 'chats', key), {

                messages: arrayUnion({
                  createdAt: new Date(),
                  text: "Account has been deleted",
                  sender: user.uid,
                  senderName: user.displayName
                }),

              });
              await getDoc(doc(db, `userChat`, keyID)).then(async (oppChatInfo) => {
                if (oppChatInfo.data()[key].userInfo.status != "Pending") {
                  if (oppChatInfo.data()[key].userInfo.chatType == "CommercialRent") {
                    if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            agentProfile: data.agentProfile,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            listingPhoto: oppChatInfo.data()[key].userInfo.listingPhoto,
                            listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                            listingPricePerSquareFeet: oppChatInfo.data()[key].userInfo.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                    else {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            listingPhoto: oppChatInfo.data()[key].userInfo.listingPhoto,
                            listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                            listingPricePerSquareFeet: oppChatInfo.data()[key].userInfo.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }

                  }
                  else if (oppChatInfo.data()[key].userInfo.chatType == "CommercialSales") {
                    if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            agentProfile: data.agentProfile,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            minFloorSizePSFBudget: oppChatInfo.data()[key].userInfo.minFloorSizePSFBudget,
                            moveInPeriod: oppChatInfo.data()[key].moveInPeriod != undefined ? new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000) : new Date(),
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                    else {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            minFloorSizePSFBudget: oppChatInfo.data()[key].userInfo.minFloorSizePSFBudget,
                            moveInPeriod: oppChatInfo.data()[key].moveInPeriod != undefined ? new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000) : new Date(),
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                  }
                  else if (oppChatInfo.data()[key].userInfo.chatType == "ResidentialRent") {
                    if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            agentProfile: data.agentProfile,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                            moveInPeriod: oppChatInfo.data()[key].moveInPeriod != undefined ? new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000) : new Date(),
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                    else {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                            moveInPeriod: oppChatInfo.data()[key].moveInPeriod != undefined ? new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000) : new Date(),
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                  }
                  else if (oppChatInfo.data()[key].userInfo.chatType == "ResidentialSales") {
                    if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            agentProfile: data.agentProfile,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            budgetOverall: oppChatInfo.data()[key].userInfo.budgetOverall,
                            moveInPeriod: oppChatInfo.data()[key].moveInPeriod != undefined ? new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000) : new Date(),
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                    else {
                      //Commercial Rent Chat
                      await updateDoc(doc(db, 'userChat', keyID), {
                        [key]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentRepresented: oppChatInfo.data()[key].userInfo.agentRepresented,
                            displayName: publicInfo.username,
                            listingID: oppChatInfo.data()[key].userInfo.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: "Account has been deleted",
                            listingName: oppChatInfo.data()[key].userInfo.listingName,
                            budgetOverall: oppChatInfo.data()[key].userInfo.budgetOverall,
                            moveInPeriod: oppChatInfo.data()[key].moveInPeriod != undefined ? new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000) : new Date(),
                            statusRead: "Unread",
                            chatType: oppChatInfo.data()[key].userInfo.chatType,
                            status: "Deleted",
                            myUID: oppChatInfo.data()[key].userInfo.myUID,
                            oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                            oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                            propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                            recommended: oppChatInfo.data()[key].userInfo.recommended
                          }
                        }
                      })
                    }
                  }
                  else if (oppChatInfo.data()[key].userInfo.chatType == "sellerProfile") {

                    //seller Profile
                    await updateDoc(doc(db, 'userChat', keyID), {
                      [key]: {
                        date: serverTimestamp(),
                        userInfo: {
                          PSFValue: oppChatInfo.data()[key].userInfo.PSFValue,
                          address: oppChatInfo.data()[key].userInfo.address,
                          agentProfileDeck: oppChatInfo.data()[key].agentProfileDeck,
                          chatType: oppChatInfo.data()[key].userInfo.chatType,
                          commission: oppChatInfo.data()[key].userInfo.commission,
                          displayName: publicInfo.username,
                          lastMessage: "Account has been deleted",
                          myUID: oppChatInfo.data()[key].userInfo.myUID,
                          oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                          oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                          photoURL: currentUserInfo.userPhotoURL,
                          postalCode: oppChatInfo.data()[key].postalCode,
                          sellingPrice: oppChatInfo.data()[key].sellingPrice,
                          status: "Deleted",
                          statusRead: "Unread",
                          unitNumber: oppChatInfo.data()[key].unitNumber
                        }
                      }
                    })

                  }
                }
              })
            }

          }
          await deleteDoc(doc(db, `userChat`, user.uid));
        })

        //commercialProperty

        // Get all the documents that match the query criteria
        const queryCommercialPropertyList = await getDoc(doc(db, 'commericalPropertyList', user.uid));
        console.log(queryCommercialPropertyList);
        if (queryCommercialPropertyList._document != null) {
          await deleteDoc(doc(db, `commericalPropertyList/`, user.uid))
        }


        //residentialProperty
        // Get all the documents that match the query criteria
        const queryResidentialProperty = await getDoc(doc(db, 'residentialPropertyList', user.uid));
        console.log(queryResidentialProperty);
        if (queryResidentialProperty._document != null) {
          await deleteDoc(doc(db, `residentialPropertyList/`, user.uid))
        }

        await deleteDoc(doc(db, `publicUserInfo`, user.uid))
        await deleteDoc(doc(db, 'userInfo', user.uid)).then(async () => {

          await deleteStorageFirebase(ref(storage, `${user.uid}`))
          await deleteUser(user).then(() => {
            return;
            // return;
          }).then((error) => {
            console.log(error)
          })
        })

        // setAcknowledgeMessage("Please contact us for deactivation of account");
        // setAcknowledgeNormal(true);




      })

    }
    else if (user.providerData[0].providerId == "facebook.com") {
      const provider = new FacebookAuthProvider();
      reauthenticateWithPopup(user, provider).then(async (result) => {
        credential = result.credential;
        if (currentUserInfo.membership != "Enterprise") {
          var deleteCurrentUserInfo = currentUserInfo;
          deleteCurrentUserInfo.registeredEmail = user.email;
          deleteCurrentUserInfo.deleteDate = new Date();
          await setDoc(doc(db, `deleteUserInfo`, user.uid), deleteCurrentUserInfo);
          //Commercial Rent
          const q = query(collection(db, "projects"), where("UID", "==", user.uid));
          // Get all the documents that match the query criteria
          const querySnapshot = await getDocs(q);
          // Loop through each document in the result set
          querySnapshot.forEach(async (indivDoc) => {

            await setDoc(doc(db, 'deleteListing', indivDoc.id), indivDoc.data()).then(async () => {
              await getDocs(collection(db, `projects/${indivDoc.id}/MoreDetails`)).then((moreDetailsDoc) => {
                moreDetailsDoc.forEach(async (moreDetailsDocIndiv) => {
                  await addDoc(collection(db, `deleteListing/${indivDoc.id}/MoreDetails`), moreDetailsDocIndiv.data(), moreDetailsDocIndiv.id).then(async () => {
                    await deleteDoc(doc(db, `projects/${indivDoc.id}/MoreDetails`, moreDetailsDocIndiv.id));
                  });
                })
              })
              await getDocs(collection(db, `projects/${indivDoc.id}/PreferredDetails`)).then((preferredDetailsDoc) => {
                preferredDetailsDoc.forEach(async (preferredDetailsDocIndiv) => {
                  await addDoc(collection(db, `deleteListing/${indivDoc.id}/PreferredDetails`), preferredDetailsDocIndiv.data(), preferredDetailsDocIndiv.id).then(async () => {
                    await deleteDoc(doc(db, `projects/${indivDoc.id}/PreferredDetails`, preferredDetailsDocIndiv.id));
                  });
                })
              })
            })
              .then(async () => {
                // await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, indivDoc.id));
                await deleteDoc(doc(db, `projects/`, indivDoc.id))
              })

          });
          //Commercial Sale
          const commercialSale = query(collection(db, "listingCommercialPurchase"), where("UID", "==", user.uid));
          // Get all the documents that match the query criteria
          const queryCommercialSale = await getDocs(commercialSale);

          // Loop through each document in the result set
          queryCommercialSale.forEach(async (indivDoc) => {

            await setDoc(doc(db, 'deleteListing', indivDoc.id), indivDoc.data()).then(async () => {
              await getDocs(collection(db, `listingCommercialPurchase/${indivDoc.id}/PreferredDetails`)).then((preferredDetailsDoc) => {
                preferredDetailsDoc.forEach(async (preferredDetailsDocIndiv) => {
                  await addDoc(collection(db, `deleteListing/${indivDoc.id}/PreferredDetails`), preferredDetailsDocIndiv.data(), preferredDetailsDocIndiv.id).then(async () => {
                    await deleteDoc(doc(db, `listingCommercialPurchase/${indivDoc.id}/PreferredDetails`, preferredDetailsDocIndiv.id));
                  });
                })
              })
            })
              .then(async () => {
                // await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, indivDoc.id));
                await deleteDoc(doc(db, `listingCommercialPurchase/`, indivDoc.id))
              })

          });
          //Residential Rent
          const residentialRent = query(collection(db, "residentialListing"), where("UID", "==", user.uid));
          // Get all the documents that match the query criteria
          const queryResidentialRent = await getDocs(residentialRent);

          // Loop through each document in the result set
          queryResidentialRent.forEach(async (indivDoc) => {

            await setDoc(doc(db, 'deleteListing', indivDoc.id), indivDoc.data()).then(async () => {
              await getDocs(collection(db, `residentialListing/${indivDoc.id}/MoreDetails`)).then((moreDetailsDoc) => {
                moreDetailsDoc.forEach(async (moreDetailsDocIndiv) => {
                  await addDoc(collection(db, `deleteListing/${indivDoc.id}/MoreDetails`), moreDetailsDocIndiv.data(), moreDetailsDocIndiv.id).then(async () => {
                    await deleteDoc(doc(db, `residentialListing/${indivDoc.id}/MoreDetails`, moreDetailsDocIndiv.id));
                  });
                })
              })
              await getDocs(collection(db, `residentialListing/${indivDoc.id}/PreferredDetails`)).then((preferredDetailsDoc) => {
                preferredDetailsDoc.forEach(async (preferredDetailsDocIndiv) => {
                  await addDoc(collection(db, `deleteListing/${indivDoc.id}/PreferredDetails`), preferredDetailsDocIndiv.data(), preferredDetailsDocIndiv.id).then(async () => {
                    await deleteDoc(doc(db, `residentialListing/${indivDoc.id}/PreferredDetails`, preferredDetailsDocIndiv.id));
                  });
                })
              })
            })
              .then(async () => {
                await deleteDoc(doc(db, `residentialListing/`, indivDoc.id))
              })

          });
          //Residential Sale
          const residentialSale = query(collection(db, "residentialListingPurchase"), where("UID", "==", user.uid));
          // Get all the documents that match the query criteria
          const queryResidentialSale = await getDocs(residentialSale);

          // Loop through each document in the result set
          queryResidentialSale.forEach(async (indivDoc) => {

            await setDoc(doc(db, 'deleteListing', indivDoc.id), indivDoc.data()).then(async () => {
              getDocs(collection(db, `residentialListingPurchase/${indivDoc.id}/PreferredDetails`)).then((preferredDetailsDoc) => {
                preferredDetailsDoc.forEach(async (preferredDetailsDocIndiv) => {
                  await addDoc(collection(db, `deleteListing/${indivDoc.id}/PreferredDetails`), preferredDetailsDocIndiv.data(), preferredDetailsDocIndiv.id).then(async () => {
                    await deleteDoc(doc(db, `residentialListingPurchase/${indivDoc.id}/PreferredDetails`, preferredDetailsDocIndiv.id));
                  });
                })
              })
            })
              .then(async () => {
                // await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, indivDoc.id));
                await deleteDoc(doc(db, `residentialListingPurchase/`, indivDoc.id))
              })

          });
          //Advertise
          const advertiseListing = query(collection(db, "advertiseListing"), where("UID", "==", user.uid));
          // Get all the documents that match the query criteria
          const queryAdvertiseListing = await getDocs(advertiseListing);

          // Loop through each document in the result set
          queryAdvertiseListing.forEach(async (indivDoc) => {


            await deleteDoc(doc(db, `advertiseListing/`, indivDoc.id))
            // resolve();


          });
          // cancelMembership("delete");


          await getDocs(collection(db, `userInfo/${user.uid}/shortList`)).then((replyFromSL) => {
            replyFromSL.forEach(async (indivSL) => {
              await deleteDoc(doc(db, `userInfo/${user.uid}/shortList`, indivSL.id));
            })
          })



          await getDoc(doc(db, `userChat`, user.uid)).then(async (chatInfo) => {
            for (const key in chatInfo.data()) {
              if (chatInfo.data()[key].userInfo.status != "Pending") {
                var keyID = chatInfo.data()[key].userInfo.oppositeUID;

                await updateDoc(doc(db, 'chats', key), {

                  messages: arrayUnion({
                    createdAt: new Date(),
                    text: "Account has been deleted",
                    sender: user.uid,
                    senderName: user.displayName
                  }),

                });
                await getDoc(doc(db, `userChat`, keyID)).then(async (oppChatInfo) => {
                  if (oppChatInfo.data()[key].userInfo.status != "Pending") {
                    if (oppChatInfo.data()[key].userInfo.chatType == "CommercialRent") {
                      if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                        //Commercial Rent Chat
                        await updateDoc(doc(db, 'userChat', keyID), {
                          [key]: {
                            date: serverTimestamp(),
                            userInfo: {
                              agentProfile: data.agentProfile,
                              displayName: publicInfo.username,
                              listingID: oppChatInfo.data()[key].userInfo.listingID,
                              photoURL: currentUserInfo.userPhotoURL,
                              lastMessage: "Account has been deleted",
                              listingName: oppChatInfo.data()[key].userInfo.listingName,
                              listingPhoto: oppChatInfo.data()[key].userInfo.listingPhoto,
                              listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                              listingPricePerSquareFeet: oppChatInfo.data()[key].userInfo.listingPricePerSquareFeet,
                              statusRead: "Unread",
                              chatType: oppChatInfo.data()[key].userInfo.chatType,
                              status: "Deleted",
                              myUID: oppChatInfo.data()[key].userInfo.myUID,
                              oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                              oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                              propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                              recommended: oppChatInfo.data()[key].userInfo.recommended
                            }
                          }
                        })
                      }
                      else {
                        //Commercial Rent Chat
                        await updateDoc(doc(db, 'userChat', keyID), {
                          [key]: {
                            date: serverTimestamp(),
                            userInfo: {
                              displayName: publicInfo.username,
                              listingID: oppChatInfo.data()[key].userInfo.listingID,
                              photoURL: currentUserInfo.userPhotoURL,
                              lastMessage: "Account has been deleted",
                              listingName: oppChatInfo.data()[key].userInfo.listingName,
                              listingPhoto: oppChatInfo.data()[key].userInfo.listingPhoto,
                              listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                              listingPricePerSquareFeet: oppChatInfo.data()[key].userInfo.listingPricePerSquareFeet,
                              statusRead: "Unread",
                              chatType: oppChatInfo.data()[key].userInfo.chatType,
                              status: "Deleted",
                              myUID: oppChatInfo.data()[key].userInfo.myUID,
                              oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                              oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                              propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                              recommended: oppChatInfo.data()[key].userInfo.recommended
                            }
                          }
                        })
                      }

                    }
                    else if (oppChatInfo.data()[key].userInfo.chatType == "CommercialSales") {
                      if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                        //Commercial Rent Chat
                        await updateDoc(doc(db, 'userChat', keyID), {
                          [key]: {
                            date: serverTimestamp(),
                            userInfo: {
                              agentProfile: data.agentProfile,
                              displayName: publicInfo.username,
                              listingID: oppChatInfo.data()[key].userInfo.listingID,
                              photoURL: currentUserInfo.userPhotoURL,
                              lastMessage: "Account has been deleted",
                              listingName: oppChatInfo.data()[key].userInfo.listingName,
                              minFloorSizePSFBudget: oppChatInfo.data()[key].userInfo.minFloorSizePSFBudget,
                              moveInPeriod: new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000),
                              statusRead: "Unread",
                              chatType: oppChatInfo.data()[key].userInfo.chatType,
                              status: "Deleted",
                              myUID: oppChatInfo.data()[key].userInfo.myUID,
                              oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                              oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                              propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                              recommended: oppChatInfo.data()[key].userInfo.recommended
                            }
                          }
                        })
                      }
                      else {
                        //Commercial Rent Chat
                        await updateDoc(doc(db, 'userChat', keyID), {
                          [key]: {
                            date: serverTimestamp(),
                            userInfo: {
                              displayName: publicInfo.username,
                              listingID: oppChatInfo.data()[key].userInfo.listingID,
                              photoURL: currentUserInfo.userPhotoURL,
                              lastMessage: "Account has been deleted",
                              listingName: oppChatInfo.data()[key].userInfo.listingName,
                              minFloorSizePSFBudget: oppChatInfo.data()[key].userInfo.minFloorSizePSFBudget,
                              moveInPeriod: new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000),
                              statusRead: "Unread",
                              chatType: oppChatInfo.data()[key].userInfo.chatType,
                              status: "Deleted",
                              myUID: oppChatInfo.data()[key].userInfo.myUID,
                              oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                              oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                              propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                              recommended: oppChatInfo.data()[key].userInfo.recommended
                            }
                          }
                        })
                      }
                    }
                    else if (oppChatInfo.data()[key].userInfo.chatType == "ResidentialRent") {
                      if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                        //Commercial Rent Chat
                        await updateDoc(doc(db, 'userChat', keyID), {
                          [key]: {
                            date: serverTimestamp(),
                            userInfo: {
                              agentProfile: data.agentProfile,
                              displayName: publicInfo.username,
                              listingID: oppChatInfo.data()[key].userInfo.listingID,
                              photoURL: currentUserInfo.userPhotoURL,
                              lastMessage: "Account has been deleted",
                              listingName: oppChatInfo.data()[key].userInfo.listingName,
                              listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                              moveInPeriod: new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000),
                              statusRead: "Unread",
                              chatType: oppChatInfo.data()[key].userInfo.chatType,
                              status: "Deleted",
                              myUID: oppChatInfo.data()[key].userInfo.myUID,
                              oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                              oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                              propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                              recommended: oppChatInfo.data()[key].userInfo.recommended
                            }
                          }
                        })
                      }
                      else {
                        //Commercial Rent Chat
                        await updateDoc(doc(db, 'userChat', keyID), {
                          [key]: {
                            date: serverTimestamp(),
                            userInfo: {
                              displayName: publicInfo.username,
                              listingID: oppChatInfo.data()[key].userInfo.listingID,
                              photoURL: currentUserInfo.userPhotoURL,
                              lastMessage: "Account has been deleted",
                              listingName: oppChatInfo.data()[key].userInfo.listingName,
                              listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                              moveInPeriod: new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000),
                              statusRead: "Unread",
                              chatType: oppChatInfo.data()[key].userInfo.chatType,
                              status: "Deleted",
                              myUID: oppChatInfo.data()[key].userInfo.myUID,
                              oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                              oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                              propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                              recommended: oppChatInfo.data()[key].userInfo.recommended
                            }
                          }
                        })
                      }
                    }
                    else if (oppChatInfo.data()[key].userInfo.chatType == "ResidentialSales") {
                      if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                        //Commercial Rent Chat
                        await updateDoc(doc(db, 'userChat', keyID), {
                          [key]: {
                            date: serverTimestamp(),
                            userInfo: {
                              agentProfile: data.agentProfile,
                              displayName: publicInfo.username,
                              listingID: oppChatInfo.data()[key].userInfo.listingID,
                              photoURL: currentUserInfo.userPhotoURL,
                              lastMessage: "Account has been deleted",
                              listingName: oppChatInfo.data()[key].userInfo.listingName,
                              budgetOverall: oppChatInfo.data()[key].userInfo.budgetOverall,
                              moveInPeriod: new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000),
                              statusRead: "Unread",
                              chatType: oppChatInfo.data()[key].userInfo.chatType,
                              status: "Deleted",
                              myUID: oppChatInfo.data()[key].userInfo.myUID,
                              oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                              oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                              propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                              recommended: oppChatInfo.data()[key].userInfo.recommended
                            }
                          }
                        })
                      }
                      else {
                        //Commercial Rent Chat
                        await updateDoc(doc(db, 'userChat', keyID), {
                          [key]: {
                            date: serverTimestamp(),
                            userInfo: {
                              displayName: publicInfo.username,
                              listingID: oppChatInfo.data()[key].userInfo.listingID,
                              photoURL: currentUserInfo.userPhotoURL,
                              lastMessage: "Account has been deleted",
                              listingName: oppChatInfo.data()[key].userInfo.listingName,
                              budgetOverall: oppChatInfo.data()[key].userInfo.budgetOverall,
                              moveInPeriod: new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000),
                              statusRead: "Unread",
                              chatType: oppChatInfo.data()[key].userInfo.chatType,
                              status: "Deleted",
                              myUID: oppChatInfo.data()[key].userInfo.myUID,
                              oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                              oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                              propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                              recommended: oppChatInfo.data()[key].userInfo.recommended
                            }
                          }
                        })
                      }
                    }
                  }
                })
              }

            }
            await deleteDoc(doc(db, `userChat`, user.uid));
          })
          await deleteDoc(doc(db, `publicUserInfo`, user.uid))
          await deleteDoc(doc(db, 'userInfo', user.uid)).then(async () => {

            await deleteStorageFirebase(ref(storage, `${user.uid}`))
            await deleteUser(user).then(() => {
              return;
              // return;
            }).then((error) => {
              console.log(error)
            })
          })
        }
        else {
          setAcknowledgeMessage("Please contact us for deactivation of account");
          setAcknowledgeNormal(true);
        }


      })
    }
    else if (user.providerData[0].providerId == "password") {
      var password = prompt("Please enter password for confirmation");
      credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        password
      )
      await reauthenticateWithCredential(user, credential)
        .then(async () => {
          if (currentUserInfo.membership != "Enterprise") {
            var deleteCurrentUserInfo = currentUserInfo;
            deleteCurrentUserInfo.registeredEmail = user.email;
            deleteCurrentUserInfo.deleteDate = new Date();
            await setDoc(doc(db, `deleteUserInfo`, user.uid), deleteCurrentUserInfo);
            //Commercial Rent
            const q = query(collection(db, "projects"), where("UID", "==", user.uid));
            // Get all the documents that match the query criteria
            const querySnapshot = await getDocs(q);
            // Loop through each document in the result set
            querySnapshot.forEach(async (indivDoc) => {

              await setDoc(doc(db, 'deleteListing', indivDoc.id), indivDoc.data()).then(async () => {
                await getDocs(collection(db, `projects/${indivDoc.id}/MoreDetails`)).then((moreDetailsDoc) => {
                  moreDetailsDoc.forEach(async (moreDetailsDocIndiv) => {
                    await addDoc(collection(db, `deleteListing/${indivDoc.id}/MoreDetails`), moreDetailsDocIndiv.data(), moreDetailsDocIndiv.id).then(async () => {
                      await deleteDoc(doc(db, `projects/${indivDoc.id}/MoreDetails`, moreDetailsDocIndiv.id));
                    });
                  })
                })
                await getDocs(collection(db, `projects/${indivDoc.id}/PreferredDetails`)).then((preferredDetailsDoc) => {
                  preferredDetailsDoc.forEach(async (preferredDetailsDocIndiv) => {
                    await addDoc(collection(db, `deleteListing/${indivDoc.id}/PreferredDetails`), preferredDetailsDocIndiv.data(), preferredDetailsDocIndiv.id).then(async () => {
                      await deleteDoc(doc(db, `projects/${indivDoc.id}/PreferredDetails`, preferredDetailsDocIndiv.id));
                    });
                  })
                })
              })
                .then(async () => {
                  // await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, indivDoc.id));
                  await deleteDoc(doc(db, `projects/`, indivDoc.id))
                })

            });
            //Commercial Sale
            const commercialSale = query(collection(db, "listingCommercialPurchase"), where("UID", "==", user.uid));
            // Get all the documents that match the query criteria
            const queryCommercialSale = await getDocs(commercialSale);

            // Loop through each document in the result set
            queryCommercialSale.forEach(async (indivDoc) => {

              await setDoc(doc(db, 'deleteListing', indivDoc.id), indivDoc.data()).then(async () => {
                await getDocs(collection(db, `listingCommercialPurchase/${indivDoc.id}/PreferredDetails`)).then((preferredDetailsDoc) => {
                  preferredDetailsDoc.forEach(async (preferredDetailsDocIndiv) => {
                    await addDoc(collection(db, `deleteListing/${indivDoc.id}/PreferredDetails`), preferredDetailsDocIndiv.data(), preferredDetailsDocIndiv.id).then(async () => {
                      await deleteDoc(doc(db, `listingCommercialPurchase/${indivDoc.id}/PreferredDetails`, preferredDetailsDocIndiv.id));
                    });
                  })
                })
              })
                .then(async () => {
                  // await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, indivDoc.id));
                  await deleteDoc(doc(db, `listingCommercialPurchase/`, indivDoc.id))
                })

            });
            //Residential Rent
            const residentialRent = query(collection(db, "residentialListing"), where("UID", "==", user.uid));
            // Get all the documents that match the query criteria
            const queryResidentialRent = await getDocs(residentialRent);

            // Loop through each document in the result set
            queryResidentialRent.forEach(async (indivDoc) => {

              await setDoc(doc(db, 'deleteListing', indivDoc.id), indivDoc.data()).then(async () => {
                // console.log(`listingCommercialPurchase/${indivDoc.id}/MoreDetails`);
                await getDocs(collection(db, `residentialListing/${indivDoc.id}/MoreDetails`)).then((moreDetailsDoc) => {
                  console.log('moreDetailsDoc', moreDetailsDoc);
                  moreDetailsDoc.forEach(async (moreDetailsDocIndiv) => {
                    console.log(moreDetailsDocIndiv);
                    await addDoc(collection(db, `deleteListing/${indivDoc.id}/MoreDetails`), moreDetailsDocIndiv.data(), moreDetailsDocIndiv.id).then(async () => {
                      await deleteDoc(doc(db, `residentialListing/${indivDoc.id}/MoreDetails`, moreDetailsDocIndiv.id));
                    });
                  })
                })
                await getDocs(collection(db, `residentialListing/${indivDoc.id}/PreferredDetails`)).then((preferredDetailsDoc) => {
                  preferredDetailsDoc.forEach(async (preferredDetailsDocIndiv) => {
                    await addDoc(collection(db, `deleteListing/${indivDoc.id}/PreferredDetails`), preferredDetailsDocIndiv.data(), preferredDetailsDocIndiv.id).then(async () => {
                      await deleteDoc(doc(db, `residentialListing/${indivDoc.id}/PreferredDetails`, preferredDetailsDocIndiv.id));
                    });
                  })
                })
              })
                .then(async () => {
                  await deleteDoc(doc(db, `residentialListing/`, indivDoc.id))
                })

            });
            //Residential Sale
            const residentialSale = query(collection(db, "residentialListingPurchase"), where("UID", "==", user.uid));
            // Get all the documents that match the query criteria
            const queryResidentialSale = await getDocs(residentialSale);

            // Loop through each document in the result set
            queryResidentialSale.forEach(async (indivDoc) => {

              await setDoc(doc(db, 'deleteListing', indivDoc.id), indivDoc.data()).then(async () => {
                getDocs(collection(db, `residentialListingPurchase/${indivDoc.id}/PreferredDetails`)).then((preferredDetailsDoc) => {
                  preferredDetailsDoc.forEach(async (preferredDetailsDocIndiv) => {
                    await addDoc(collection(db, `deleteListing/${indivDoc.id}/PreferredDetails`), preferredDetailsDocIndiv.data(), preferredDetailsDocIndiv.id).then(async () => {
                      await deleteDoc(doc(db, `residentialListingPurchase/${indivDoc.id}/PreferredDetails`, preferredDetailsDocIndiv.id));
                    });
                  })
                })
              })
                .then(async () => {
                  // await deleteDoc(doc(db, `userInfo/${user.uid}/projects`, indivDoc.id));
                  await deleteDoc(doc(db, `residentialListingPurchase/`, indivDoc.id))
                })

            });
            //Advertise
            const advertiseListing = query(collection(db, "advertiseListing"), where("UID", "==", user.uid));
            // Get all the documents that match the query criteria
            const queryAdvertiseListing = await getDocs(advertiseListing);

            // Loop through each document in the result set
            queryAdvertiseListing.forEach(async (indivDoc) => {


              await deleteDoc(doc(db, `advertiseListing/`, indivDoc.id))
              // resolve();


            });
            // cancelMembership("delete");

            //commercialProperty

            // Get all the documents that match the query criteria
            const queryCommercialPropertyList = await getDoc(doc(db, 'commericalPropertyList', user.uid));
            console.log(queryCommercialPropertyList);
            if (queryCommercialPropertyList._document != null) {
              await deleteDoc(doc(db, `commericalPropertyList/`, user.uid))
            }


            //residentialProperty
            // Get all the documents that match the query criteria
            const queryResidentialProperty = await getDoc(doc(db, 'residentialPropertyList', user.uid));
            console.log(queryResidentialProperty);
            if (queryResidentialProperty._document != null) {
              await deleteDoc(doc(db, `residentialPropertyList/`, user.uid))
            }

            await getDocs(collection(db, `userInfo/${user.uid}/shortList`)).then((replyFromSL) => {
              replyFromSL.forEach(async (indivSL) => {
                await deleteDoc(doc(db, `userInfo/${user.uid}/shortList`, indivSL.id));
              })
            })



            await getDoc(doc(db, `userChat`, user.uid)).then(async (chatInfo) => {
              for (const key in chatInfo.data()) {
                if (chatInfo.data()[key].userInfo.status != "Pending") {
                  var keyID = chatInfo.data()[key].userInfo.oppositeUID;
                  // var listingID = chatInfo.data()[key].userInfo.listingID;

                  // // Filter out user.uid and listingID from keyID
                  // keyID = keyID.replace(user.uid, '').replace(listingID, '');
                  // // Trim any leading or trailing spaces
                  // keyID = keyID.trim();

                  await updateDoc(doc(db, 'chats', key), {

                    messages: arrayUnion({
                      createdAt: new Date(),
                      text: "Account has been deleted",
                      sender: user.uid,
                      senderName: user.displayName
                    }),

                  });
                  await getDoc(doc(db, `userChat`, keyID)).then(async (oppChatInfo) => {
                    if (oppChatInfo.data()[key].userInfo.status != "Pending") {
                      if (oppChatInfo.data()[key].userInfo.chatType == "CommercialRent") {
                        if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                          //Commercial Rent Chat
                          await updateDoc(doc(db, 'userChat', keyID), {
                            [key]: {
                              date: serverTimestamp(),
                              userInfo: {
                                agentProfile: data.agentProfile,
                                displayName: publicInfo.username,
                                listingID: oppChatInfo.data()[key].userInfo.listingID,
                                photoURL: currentUserInfo.userPhotoURL,
                                lastMessage: "Account has been deleted",
                                listingName: oppChatInfo.data()[key].userInfo.listingName,
                                listingPhoto: oppChatInfo.data()[key].userInfo.listingPhoto,
                                listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                                listingPricePerSquareFeet: oppChatInfo.data()[key].userInfo.listingPricePerSquareFeet,
                                statusRead: "Unread",
                                chatType: oppChatInfo.data()[key].userInfo.chatType,
                                status: "Deleted",
                                myUID: oppChatInfo.data()[key].userInfo.myUID,
                                oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                                oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                                propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                                recommended: oppChatInfo.data()[key].userInfo.recommended
                              }
                            }
                          })
                        }
                        else {
                          //Commercial Rent Chat
                          await updateDoc(doc(db, 'userChat', keyID), {
                            [key]: {
                              date: serverTimestamp(),
                              userInfo: {
                                displayName: publicInfo.username,
                                listingID: oppChatInfo.data()[key].userInfo.listingID,
                                photoURL: currentUserInfo.userPhotoURL,
                                lastMessage: "Account has been deleted",
                                listingName: oppChatInfo.data()[key].userInfo.listingName,
                                listingPhoto: oppChatInfo.data()[key].userInfo.listingPhoto,
                                listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                                listingPricePerSquareFeet: oppChatInfo.data()[key].userInfo.listingPricePerSquareFeet,
                                statusRead: "Unread",
                                chatType: oppChatInfo.data()[key].userInfo.chatType,
                                status: "Deleted",
                                myUID: oppChatInfo.data()[key].userInfo.myUID,
                                oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                                oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                                propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                                recommended: oppChatInfo.data()[key].userInfo.recommended
                              }
                            }
                          })
                        }

                      }
                      else if (oppChatInfo.data()[key].userInfo.chatType == "CommercialSales") {
                        if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                          //Commercial Rent Chat
                          await updateDoc(doc(db, 'userChat', keyID), {
                            [key]: {
                              date: serverTimestamp(),
                              userInfo: {
                                agentProfile: data.agentProfile,
                                displayName: publicInfo.username,
                                listingID: oppChatInfo.data()[key].userInfo.listingID,
                                photoURL: currentUserInfo.userPhotoURL,
                                lastMessage: "Account has been deleted",
                                listingName: oppChatInfo.data()[key].userInfo.listingName,
                                minFloorSizePSFBudget: oppChatInfo.data()[key].userInfo.minFloorSizePSFBudget,
                                moveInPeriod: new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000),
                                statusRead: "Unread",
                                chatType: oppChatInfo.data()[key].userInfo.chatType,
                                status: "Deleted",
                                myUID: oppChatInfo.data()[key].userInfo.myUID,
                                oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                                oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                                propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                                recommended: oppChatInfo.data()[key].userInfo.recommended
                              }
                            }
                          })
                        }
                        else {
                          //Commercial Rent Chat
                          await updateDoc(doc(db, 'userChat', keyID), {
                            [key]: {
                              date: serverTimestamp(),
                              userInfo: {
                                displayName: publicInfo.username,
                                listingID: oppChatInfo.data()[key].userInfo.listingID,
                                photoURL: currentUserInfo.userPhotoURL,
                                lastMessage: "Account has been deleted",
                                listingName: oppChatInfo.data()[key].userInfo.listingName,
                                minFloorSizePSFBudget: oppChatInfo.data()[key].userInfo.minFloorSizePSFBudget,
                                moveInPeriod: new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000),
                                statusRead: "Unread",
                                chatType: oppChatInfo.data()[key].userInfo.chatType,
                                status: "Deleted",
                                myUID: oppChatInfo.data()[key].userInfo.myUID,
                                oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                                oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                                propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                                recommended: oppChatInfo.data()[key].userInfo.recommended
                              }
                            }
                          })
                        }
                      }
                      else if (oppChatInfo.data()[key].userInfo.chatType == "ResidentialRent") {
                        if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                          //Commercial Rent Chat
                          await updateDoc(doc(db, 'userChat', keyID), {
                            [key]: {
                              date: serverTimestamp(),
                              userInfo: {
                                agentProfile: data.agentProfile,
                                displayName: publicInfo.username,
                                listingID: oppChatInfo.data()[key].userInfo.listingID,
                                photoURL: currentUserInfo.userPhotoURL,
                                lastMessage: "Account has been deleted",
                                listingName: oppChatInfo.data()[key].userInfo.listingName,
                                listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                                moveInPeriod: new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000),
                                statusRead: "Unread",
                                chatType: oppChatInfo.data()[key].userInfo.chatType,
                                status: "Deleted",
                                myUID: oppChatInfo.data()[key].userInfo.myUID,
                                oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                                oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                                propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                                recommended: oppChatInfo.data()[key].userInfo.recommended
                              }
                            }
                          })
                        }
                        else {
                          //Commercial Rent Chat
                          await updateDoc(doc(db, 'userChat', keyID), {
                            [key]: {
                              date: serverTimestamp(),
                              userInfo: {
                                displayName: publicInfo.username,
                                listingID: oppChatInfo.data()[key].userInfo.listingID,
                                photoURL: currentUserInfo.userPhotoURL,
                                lastMessage: "Account has been deleted",
                                listingName: oppChatInfo.data()[key].userInfo.listingName,
                                listingPriceOverall: oppChatInfo.data()[key].userInfo.listingPriceOverall,
                                moveInPeriod: new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000),
                                statusRead: "Unread",
                                chatType: oppChatInfo.data()[key].userInfo.chatType,
                                status: "Deleted",
                                myUID: oppChatInfo.data()[key].userInfo.myUID,
                                oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                                oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                                propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                                recommended: oppChatInfo.data()[key].userInfo.recommended
                              }
                            }
                          })
                        }
                      }
                      else if (oppChatInfo.data()[key].userInfo.chatType == "ResidentialSales") {
                        if (oppChatInfo.data()[key].userInfo.hasOwnProperty('agentProfile')) {
                          //Commercial Rent Chat
                          await updateDoc(doc(db, 'userChat', keyID), {
                            [key]: {
                              date: serverTimestamp(),
                              userInfo: {
                                agentProfile: data.agentProfile,
                                displayName: publicInfo.username,
                                listingID: oppChatInfo.data()[key].userInfo.listingID,
                                photoURL: currentUserInfo.userPhotoURL,
                                lastMessage: "Account has been deleted",
                                listingName: oppChatInfo.data()[key].userInfo.listingName,
                                budgetOverall: oppChatInfo.data()[key].userInfo.budgetOverall,
                                moveInPeriod: new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000),
                                statusRead: "Unread",
                                chatType: oppChatInfo.data()[key].userInfo.chatType,
                                status: "Deleted",
                                myUID: oppChatInfo.data()[key].userInfo.myUID,
                                oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                                oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                                propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                                recommended: oppChatInfo.data()[key].userInfo.recommended
                              }
                            }
                          })
                        }
                        else {
                          //Commercial Rent Chat
                          await updateDoc(doc(db, 'userChat', keyID), {
                            [key]: {
                              date: serverTimestamp(),
                              userInfo: {
                                displayName: publicInfo.username,
                                listingID: oppChatInfo.data()[key].userInfo.listingID,
                                photoURL: currentUserInfo.userPhotoURL,
                                lastMessage: "Account has been deleted",
                                listingName: oppChatInfo.data()[key].userInfo.listingName,
                                budgetOverall: oppChatInfo.data()[key].userInfo.budgetOverall,
                                moveInPeriod: new Date(oppChatInfo.data()[key].moveInPeriod.seconds * 1000),
                                statusRead: "Unread",
                                chatType: oppChatInfo.data()[key].userInfo.chatType,
                                status: "Deleted",
                                myUID: oppChatInfo.data()[key].userInfo.myUID,
                                oppositeUID: oppChatInfo.data()[key].userInfo.oppositeUID,
                                oppUserType: oppChatInfo.data()[key].userInfo.oppUserType,
                                propertyList: oppChatInfo.data()[key].userInfo.propertyList,
                                recommended: oppChatInfo.data()[key].userInfo.recommended
                              }
                            }
                          })
                        }
                      }
                    }
                  })
                }

              }
              await deleteDoc(doc(db, `userChat`, user.uid));
            })
            await deleteDoc(doc(db, `publicUserInfo`, user.uid))
            await deleteDoc(doc(db, 'userInfo', user.uid)).then(async () => {

              await deleteStorageFirebase(ref(storage, `${user.uid}`))
              await deleteUser(user).then(() => {
                return;
                // return;
              }).then((error) => {
                console.log(error)
              })
            })
          }
          else {
            setAcknowledgeMessage("Please contact us for deactivation of account");
            setAcknowledgeNormal(true);
          }

        }).catch((error) => {
          setProceedAccountDelete(false);
          return;
        })
    }
    else {
      alert("Contact us as you have encountered an error in this process");
    }



  };

  useEffect(() => {
    if (proceedAccountDelete == true) {

      disableAccount();
    }

  }, [proceedAccountDelete])

  useEffect(() => {
    var number = 0;
    if (chartContainer && chartContainer.current) {
      const chart = new Chart(chartContainer.current, {
        type: 'bar',
        data: chartData,
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 1
              }
            }]
          }
        }
      });
      if (dashboardViewUserType == "Tenant") {
        eyeballStats.map((indivData) => {
          number = number + indivData.count;
        })
        setTotalListingEyeball(number);
        return () => {
          chart.destroy();
        };
      }
      else if (dashboardViewUserType == "Master Tenant" || dashboardViewUserType == "Landlord" || dashboardViewUserType == "Agent") {
        newListingDataTable.map((indivData) => {
          number = number + indivData.count;
        })
        setTotalListingDistrict(number);
        return () => {
          chart.destroy();
        };
      }
      else {
        eyeballStats.map((indivData) => {
          number = number + indivData.count;
        })
        setTotalListingEyeball(number);
        return () => {
          chart.destroy();
        };
      }

    }
  }, [chartData, chartContainer, newListingDataTable, eyeballStats]);

  useEffect(() => {
    // if (currentUserInfo.userType != null && userProject != null) {
    //   SetIsReady(true);
    // }
    if (currentUserInfo != null && userProject != null) {
      // if (currentUserInfo.userType == null) {
      SetIsReady(true);
      // }
    }
  }, [userProject, currentUserInfo])

  const handleChangeBusinessAccountIndex = (event) => {
    setBusinessAccountIndex(event.target.value);
  }

  const handleChangesDashboardTenantListing = (event) => {
    setDashboardTenantListing(event.target.value);
    if (dashboardViewUserTypeAccountView == "Commercial Rent") {
      if (sortedEyeballUserProject != null) {
        if (sortedEyeballUserProject.length >= 3) {
          var botIndex = Math.floor(sortedEyeballUserProject.length / 3);
          var TopStopIndex = 0 + botIndex - 1;
          var MidStartIndex = botIndex;
          var MidStopIndex = botIndex + botIndex - 1;
          var BotStartIndex = botIndex + botIndex;
          for (var i = 0; i < sortedEyeballUserProject.length; i++) {
            if (sortedEyeballUserProject[i].details.listingName == event.target.value) {
              if (i <= TopStopIndex) {
                setCANResLevel('Top');
              }
              else if (MidStartIndex <= i <= MidStopIndex) {
                setCANResLevel('Mid');
              }
              else {
                setCANResLevel('Bottom');
              }
            }
          }
        }
        else {
          setCANResLevel('Mid');
        }
      }
      else {
        var sortedByEyeballListing = [...userProject];
        sortedByEyeballListing.sort((a, b) => {
          const aTime = a.details.eyeball ? a.details.eyeball : 0;
          const bTime = b.details.eyeball ? b.details.eyeball : 0;
          return bTime - aTime || (b.details.eyeball ? b.details.eyeball : 0) - (a.details.eyeball ? a.details.eyeball : 0);
        });
        setSortedEyeballUserProject(sortedByEyeballListing);
        if (sortedByEyeballListing.length >= 3) {
          var botIndex = Math.floor(sortedByEyeballListing.length / 3);
          var TopStopIndex = 0 + botIndex - 1;
          var MidStartIndex = botIndex;
          var MidStopIndex = botIndex + botIndex - 1;
          var BotStartIndex = botIndex + botIndex;
          for (var i = 0; i < sortedByEyeballListing.length; i++) {
            if (sortedByEyeballListing[i].details.listingName == event.target.value) {
              if (i <= TopStopIndex) {
                setCANResLevel('Top');
              }
              else if (MidStartIndex <= i <= MidStopIndex) {
                setCANResLevel('Mid');
              }
              else {
                setCANResLevel('Bottom');
              }
            }
          }
        }
        else {
          setCANResLevel('Mid');
        }
      }
    }
    else if (dashboardViewUserTypeAccountView == "Commercial Sales") {
      if (sortedEyeballCommercialSales != null) {
        if (sortedEyeballCommercialSales.length >= 3) {
          var botIndex = Math.floor(sortedEyeballCommercialSales.length / 3);
          var TopStopIndex = 0 + botIndex - 1;
          var MidStartIndex = botIndex;
          var MidStopIndex = botIndex + botIndex - 1;
          var BotStartIndex = botIndex + botIndex;
          for (var i = 0; i < sortedEyeballCommercialSales.length; i++) {
            if (sortedEyeballCommercialSales[i].details.listingName == event.target.value) {
              if (i <= TopStopIndex) {
                setCANResLevel('Top');
              }
              else if (MidStartIndex <= i <= MidStopIndex) {
                setCANResLevel('Mid');
              }
              else {
                setCANResLevel('Bottom');
              }
            }
          }
        }
        else {
          setCANResLevel('Mid');
        }
      }
      else {
        var sortedByEyeballListing = [...commercialListingPurcahse];
        sortedByEyeballListing.sort((a, b) => {
          const aTime = a.details.eyeball ? a.details.eyeball : 0;
          const bTime = b.details.eyeball ? b.details.eyeball : 0;
          return bTime - aTime || (b.details.eyeball ? b.details.eyeball : 0) - (a.details.eyeball ? a.details.eyeball : 0);
        });
        setSortedEyeballCommercialSales(sortedByEyeballListing);
        if (sortedByEyeballListing.length >= 3) {
          var botIndex = Math.floor(sortedByEyeballListing.length / 3);
          var TopStopIndex = 0 + botIndex - 1;
          var MidStartIndex = botIndex;
          var MidStopIndex = botIndex + botIndex - 1;
          var BotStartIndex = botIndex + botIndex;
          for (var i = 0; i < sortedByEyeballListing.length; i++) {
            if (sortedByEyeballListing[i].details.listingName == event.target.value) {
              if (i <= TopStopIndex) {
                setCANResLevel('Top');
              }
              else if (MidStartIndex <= i <= MidStopIndex) {
                setCANResLevel('Mid');
              }
              else {
                setCANResLevel('Bottom');
              }
            }
          }
        }
        else {
          setCANResLevel('Mid');
        }
      }
    }
    else if (dashboardViewUserTypeAccountView == "Residential Rent") {
      if (sortedEyeballResidentialRent != null) {
        if (sortedEyeballResidentialRent.length >= 3) {
          var botIndex = Math.floor(sortedEyeballResidentialRent.length / 3);
          var TopStopIndex = 0 + botIndex - 1;
          var MidStartIndex = botIndex;
          var MidStopIndex = botIndex + botIndex - 1;
          var BotStartIndex = botIndex + botIndex;
          for (var i = 0; i < sortedEyeballResidentialRent.length; i++) {
            if (sortedEyeballResidentialRent[i].details.listingName == event.target.value) {
              if (i <= TopStopIndex) {
                setCANResLevel('Top');
              }
              else if (MidStartIndex <= i <= MidStopIndex) {
                setCANResLevel('Mid');
              }
              else {
                setCANResLevel('Bottom');
              }
            }
          }
        }
        else {
          setCANResLevel('Mid');
        }
      }
      else {
        var sortedByEyeballListing = [...userResidentialListing];
        sortedByEyeballListing.sort((a, b) => {
          const aTime = a.details.eyeball ? a.details.eyeball : 0;
          const bTime = b.details.eyeball ? b.details.eyeball : 0;
          return bTime - aTime || (b.details.eyeball ? b.details.eyeball : 0) - (a.details.eyeball ? a.details.eyeball : 0);
        });
        setSortedEyeballResidentialRent(sortedByEyeballListing);
        if (sortedByEyeballListing.length >= 3) {
          var botIndex = Math.floor(sortedByEyeballListing.length / 3);
          var TopStopIndex = 0 + botIndex - 1;
          var MidStartIndex = botIndex;
          var MidStopIndex = botIndex + botIndex - 1;
          var BotStartIndex = botIndex + botIndex;
          for (var i = 0; i < sortedByEyeballListing.length; i++) {
            if (sortedByEyeballListing[i].details.listingName == event.target.value) {
              if (i <= TopStopIndex) {
                setCANResLevel('Top');
              }
              else if (MidStartIndex <= i <= MidStopIndex) {
                setCANResLevel('Mid');
              }
              else {
                setCANResLevel('Bottom');
              }
            }
          }
        }
        else {
          setCANResLevel('Mid');
        }
      }
    }
    else {
      if (sortedEyeballResidentialSales != null) {
        if (sortedEyeballResidentialSales.length >= 3) {
          var botIndex = Math.floor(sortedEyeballResidentialSales.length / 3);
          var TopStopIndex = 0 + botIndex - 1;
          var MidStartIndex = botIndex;
          var MidStopIndex = botIndex + botIndex - 1;
          var BotStartIndex = botIndex + botIndex;
          for (var i = 0; i < sortedEyeballResidentialSales.length; i++) {
            if (sortedEyeballResidentialSales[i].details.listingName == event.target.value) {
              if (i <= TopStopIndex) {
                setCANResLevel('Top');
              }
              else if (MidStartIndex <= i <= MidStopIndex) {
                setCANResLevel('Mid');
              }
              else {
                setCANResLevel('Bottom');
              }
            }
          }
        }
        else {
          setCANResLevel('Mid');
        }
      }
      else {
        var sortedByEyeballListing = [...residentialListingPurchase];
        sortedByEyeballListing.sort((a, b) => {
          const aTime = a.details.eyeball ? a.details.eyeball : 0;
          const bTime = b.details.eyeball ? b.details.eyeball : 0;
          return bTime - aTime || (b.details.eyeball ? b.details.eyeball : 0) - (a.details.eyeball ? a.details.eyeball : 0);
        });
        setSortedEyeballResidentialSales(sortedByEyeballListing);
        if (sortedByEyeballListing.length >= 3) {
          var botIndex = Math.floor(sortedByEyeballListing.length / 3);
          var TopStopIndex = 0 + botIndex - 1;
          var MidStartIndex = botIndex;
          var MidStopIndex = botIndex + botIndex - 1;
          var BotStartIndex = botIndex + botIndex;
          for (var i = 0; i < sortedByEyeballListing.length; i++) {
            if (sortedByEyeballListing[i].details.listingName == event.target.value) {
              if (i <= TopStopIndex) {
                setCANResLevel('Top');
              }
              else if (MidStartIndex <= i <= MidStopIndex) {
                setCANResLevel('Mid');
              }
              else {
                setCANResLevel('Bottom');
              }
            }
          }
        }
        else {
          setCANResLevel('Mid');
        }
      }
    }
  }

  const handlePendingEmailNotification = async () => {
    await updateDoc(doc(db, 'publicUserInfo', user.uid), {
      emailNotificationPending: !isToggled
    }).then(() => {
      setIsToggled(!isToggled);
    })

  }

  const handleChangesDashboardViewUserType = (event) => {

    if (event.target.value == "Tenant") {
      const now = new Date();
      var eyeballStat = [
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 }
      ]
      for (var i = 0; i < 7; i++) {
        const targetData = eyeballStat[i];
        targetData.date = formatDate(now, i);
      }
      if (userProject != null || userProject.length != 0) {
        for (var i = 0; i < userProject.length; i++) {
          if (userProject[i].details.status == "leasing") {
            eyeballStat[0].count = userProject[i].details.eyeballStat[0] + eyeballStat[0].count;
            eyeballStat[1].count = userProject[i].details.eyeballStat[1] + eyeballStat[1].count;
            eyeballStat[2].count = userProject[i].details.eyeballStat[2] + eyeballStat[2].count;
            eyeballStat[3].count = userProject[i].details.eyeballStat[3] + eyeballStat[3].count;
            eyeballStat[4].count = userProject[i].details.eyeballStat[4] + eyeballStat[4].count;
            eyeballStat[5].count = userProject[i].details.eyeballStat[5] + eyeballStat[5].count;
            eyeballStat[6].count = userProject[i].details.eyeballStat[6] + eyeballStat[6].count;
          }
        }
      }
      setEyeballStats(eyeballStat.reverse());

    }
    else if (event.target.value == "Master Tenant" || event.target.value == "Landlord" || event.target.value == "Agent") {
      if (currentUserInfo.hasOwnProperty('propertyAddressList')) {
        const now = new Date();
        var newListingTableData = [
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 }
        ]
        for (var i = 0; i < 7; i++) {
          const targetData = newListingTableData[i];
          targetData.date = formatDate(now, i);
        }
        if (currentUserInfo.propertyAddressList.length != 0) {
          setSelectedOption(currentUserInfo.propertyAddressList[0].district);

          if (userProject.length != 0) {

            for (var i = 0; i < userProject.length; i++) {
              if (userProject[i].details.district == currentUserInfo.propertyAddressList[0].district && userProject[i].details.status == "leasing") {
                const dateFromFirebase = new Date(userProject[i].details.timePosted.seconds * 1000 + userProject[i].details.timePosted.nanoseconds / 1000000);

                // Get today's date and time


                // Calculate the time difference in milliseconds
                const timeDiff = now.getTime() - dateFromFirebase.getTime();

                // Convert the time difference from milliseconds to days
                const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
                if (daysDiff >= 0 && daysDiff <= 6) {
                  const targetData = newListingTableData[daysDiff];
                  targetData.count += 1;

                }
              }
            }
            setNewListingDataTable(newListingTableData.reverse());
          }
          else {
            setNewListingDataTable(newListingTableData.reverse());
          }
        }
        else {
          setSelectedOption('');
          setNewListingDataTable(newListingTableData.reverse());
        }
      }
      else {
        setSelectedOption('');
        const now = new Date();
        var newListingTableData = [
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 },
          { date: 'NA', count: 0 }
        ]
        for (var i = 0; i < 7; i++) {
          const targetData = newListingTableData[i];
          targetData.date = formatDate(now, i);
        }
        setNewListingDataTable(newListingTableData.reverse());
      }
    }
    else if (event.target.value == "Advertiser") {

      const now = new Date();
      var eyeballStat = [
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 }
      ]
      for (var i = 0; i < 7; i++) {
        const targetData = eyeballStat[i];
        targetData.date = formatDate(now, i);
      }
      if (advListing.length != 0) {
        for (var i = 0; i < advListing.length; i++) {
          if (advListing[i].details.status == "approved" || advListing[i].details.status == "leasing") {
            eyeballStat[0].count = advListing[i].details.eyeballStat[0] + eyeballStat[0].count;
            eyeballStat[1].count = advListing[i].details.eyeballStat[1] + eyeballStat[1].count;
            eyeballStat[2].count = advListing[i].details.eyeballStat[2] + eyeballStat[2].count;
            eyeballStat[3].count = advListing[i].details.eyeballStat[3] + eyeballStat[3].count;
            eyeballStat[4].count = advListing[i].details.eyeballStat[4] + eyeballStat[4].count;
            eyeballStat[5].count = advListing[i].details.eyeballStat[5] + eyeballStat[5].count;
            eyeballStat[6].count = advListing[i].details.eyeballStat[6] + eyeballStat[6].count;
          }
        }
      }
      setEyeballStats(eyeballStat.reverse());
      // const randomIndex = Math.floor(Math.random() * advListing.length);


    }
    setDashboardViewUserType(event.target.value);
  }

  const showNotApprovedText = (index) => {
    const notApprovedText = document.getElementById(`notApprovedText${index}`);
    if (notApprovedText.style.display == "none") {
      notApprovedText.style.display = "block";
    }
    else {
      notApprovedText.style.display = "none";
    }
  }

  const showNotApprovedTextCommercial = (index) => {
    const notApprovedText = document.getElementById(`notApprovedTextCommercial${index}`);
    if (notApprovedText.style.display == "none") {
      notApprovedText.style.display = "block";
    }
    else {
      notApprovedText.style.display = "none";
    }
  }

  const showNotApprovedTextPending = (index) => {
    const notApprovedText = document.getElementById(`notApprovedTextPending${index}`);
    if (notApprovedText.style.display == "none") {
      notApprovedText.style.display = "block";
    }
    else {
      notApprovedText.style.display = "none";
    }
  }

  const showRecommendedPending = (index) => {
    const notApprovedText = document.getElementById(`recommendedPending${index}`);
    if (notApprovedText.style.display == "none") {
      notApprovedText.style.display = "block";
    }
    else {
      notApprovedText.style.display = "none";
    }
  }

  const showApprovedTextPending = (index) => {
    const notApprovedText = document.getElementById(`ApprovedTextPending${index}`);
    if (notApprovedText.style.display == "none") {
      notApprovedText.style.display = "block";
    }
    else {
      notApprovedText.style.display = "none";
    }
  }

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    if (userProject.length != 0) {
      const now = new Date();
      var newListingTableData = [
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 },
        { date: 'NA', count: 0 }
      ]
      for (var i = 0; i < 7; i++) {
        const targetData = newListingTableData[i];
        targetData.date = formatDate(now, i);
      }
      for (var i = 0; i < userProject.length; i++) {
        if (userProject[i].deatails.district == event.target.value) {
          const dateFromFirebase = new Date(userProject[i].details.timePosted.seconds * 1000 + userProject[i].deatails.timePosted.nanoseconds / 1000000);

          // Get today's date and time


          // Calculate the time difference in milliseconds
          const timeDiff = now.getTime() - dateFromFirebase.getTime();

          // Convert the time difference from milliseconds to days
          const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
          if (daysDiff >= 0 && daysDiff <= 6) {
            const targetData = newListingTableData[daysDiff];
            targetData.count += 1;

          }
        }
      }
      setNewListingDataTable(newListingTableData.reverse());
    }
  };

  function formatDate(date, offsetDays) {
    const formattedDate = new Date(date.getTime() - offsetDays * 24 * 60 * 60 * 1000);
    const year = formattedDate.getFullYear();
    const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
    const day = ('0' + formattedDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  const getUserData = async () => {
    var docSnap = await getDoc(doc(db, "userInfo", user.uid));
    if (docSnap.exists()) {
      if (docSnap.data().disableAccount == true) {
        alert("Your account has been suspended. Please contact us for more information");
        await logOut();
      }
      else {
        setUserInformation(docSnap.data());
        if (docSnap.data().phoneNumber == 0 || docSnap.data().phoneNumber == undefined) {
          setTempOTPTrigger(false);// No OTP off for facebook

        }
        if (docSnap.data().userType == null) {
          setActivateNewUserType(true);
        }
      }

    }
  }

  async function getFirebaseData() {
    const docRef = doc(db, "userChat", user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      unsbscribe = onSnapshot(doc(db, `userChat`, user.uid), async (document) => {
        var userInformation = await getDoc(doc(db, 'userInfo', user.uid));
        if (document.data() != null) {
          var chat_data = Object.entries(document.data())?.sort((a, b) => b[1].date - a[1].date);
          var unReadMessage = 0;
          var unReadPending = 0;
          var arrayIDUnRead = [];
          var pendingConversation = [];
          var rejectedConversation = [];
          var listingClosedID = [];
          for (var i = 0; i < chat_data.length; i++) {
            var IndivData = [];
            console.log(chat_data[i][1].userInfo.statusRead, chat_data[i][1].userInfo.status);
            if (chat_data[i][1].userInfo.status == "Closed") {
              listingClosedID.push(chat_data[i][1].userInfo.listingID);
            }
            if (chat_data[i][1].userInfo.statusRead == "Unread" && chat_data[i][1].userInfo.status != "Pending" && chat_data[i][1].userInfo.status != "Rejected") {
              arrayIDUnRead.push(chat_data[i][0]);
              unReadMessage++;
            }
            else if (chat_data[i][1].userInfo.status == "Pending") {
              unReadPending++;
              const lastSeenRef = doc(db, "publicUserInfo", chat_data[i][1].userInfo.oppositeUID);
              const lastSeenDocSnap = await getDoc(lastSeenRef);
              if (lastSeenDocSnap.exists() && lastSeenDocSnap.data().hasOwnProperty('lastLogin')) {
                const date = new Date(lastSeenDocSnap.data().lastLogin.seconds * 1000 + lastSeenDocSnap.data().lastLogin.nanoseconds / 1000000);
                const formattedDate = date.toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
                if (currentUserInfo.membership == null || currentUserInfo.membership == "Inactive") {
                  if (currentUserInfo.gracePeriod == 0) {
                    var modifyChatData = chat_data[i];
                    modifyChatData[1].userInfo.displayName = lastSeenDocSnap.data().username;
                    IndivData.push({
                      lastSeen: formattedDate,
                      pendingInfo: modifyChatData,
                      retractStatus: false
                    })
                  }
                  else {
                    var modifyChatData = chat_data[i];
                    modifyChatData[1].userInfo.displayName = lastSeenDocSnap.data().username;
                    IndivData.push({
                      lastSeen: formattedDate,
                      pendingInfo: modifyChatData,
                      retractStatus: true
                    })
                  }
                }
                else {

                  var modifyChatData = chat_data[i];
                  modifyChatData[1].userInfo.displayName = lastSeenDocSnap.data().username;
                  IndivData.push({
                    lastSeen: formattedDate,
                    pendingInfo: modifyChatData,
                    retractStatus: true
                  })
                }
                // }

              }
              pendingConversation.push(IndivData);
            }
            if (chat_data[i][1].userInfo.status == "Rejected") {

              unReadPending++;
              const lastSeenRef = doc(db, "publicUserInfo", chat_data[i][1].userInfo.oppositeUID);
              const lastSeenDocSnap = await getDoc(lastSeenRef);
              if (lastSeenDocSnap.exists()) {
                const date = new Date(lastSeenDocSnap.data().lastLogin.seconds * 1000 + lastSeenDocSnap.data().lastLogin.nanoseconds / 1000000);
                const formattedDate = date.toLocaleString();
                if (currentUserInfo.membership == null || currentUserInfo.membership == "Inactive") {
                  if (currentUserInfo.gracePeriod == 0) {
                    var modifyChatData = chat_data[i];
                    modifyChatData[1].userInfo.displayName = lastSeenDocSnap.data().username;
                    IndivData.push({
                      lastSeen: formattedDate,
                      pendingInfo: modifyChatData,
                      retractStatus: false
                    })
                  }
                  else {
                    var modifyChatData = chat_data[i];
                    modifyChatData[1].userInfo.displayName = lastSeenDocSnap.data().username;
                    IndivData.push({
                      lastSeen: formattedDate,
                      pendingInfo: modifyChatData,
                      retractStatus: true
                    })
                  }
                }
                else {
                  var modifyChatData = chat_data[i];
                  modifyChatData[1].userInfo.displayName = lastSeenDocSnap.data().username;
                  IndivData.push({
                    lastSeen: formattedDate,
                    pendingInfo: modifyChatData,
                    retractStatus: true
                  })
                }

                // const chatDate = new Timestamp(chat_data[i][1].date.seconds, chat_data[i][1].date.nanoseconds);
                // const userAccountDate = new Timestamp(userInformation.data().startDate.seconds, userInformation.data().startDate.nanoseconds);
                // const diffInMillis = chatDate.toMillis() - userAccountDate.toMillis();

                // const thirty_one_days_in_millis = 30 * 24 * 60 * 60 * 1000;
                // if (diffInMillis <= thirty_one_days_in_millis) {
                //   IndivData.push({
                //     lastSeen: formattedDate,
                //     username: lastSeenDocSnap.data().username,
                //     pendingInfo: chat_data[i],
                //     retractStatus: true
                //   })
                // }
                // else {
                //   IndivData.push({
                //     lastSeen: formattedDate,
                //     username: lastSeenDocSnap.data().username,
                //     pendingInfo: chat_data[i],
                //     retractStatus: false
                //   })
                // }
                // IndivData.push({
                //   lastSeen: formattedDate,
                //   username: lastSeenDocSnap.data().username,
                //   pendingInfo: chat_data[i]
                // })
              }
              rejectedConversation.push(IndivData);
            }
            console.log(unReadMessage, unReadPending)
            if (i == (chat_data.length - 1)) {
              setUnReadMessageCount(unReadMessage);
              setArrIdUnread(arrayIDUnRead);
              setUnReadMessagePendingCount(unReadPending);
              setListingClosedID(listingClosedID);
            }

          }

          setAllRejectedConversation(rejectedConversation);
          setAllPendingConversation(pendingConversation);
          // if (chatNotification.current != null) {

          //   Store.addNotification({
          //     title: chat_data[0][1].userInfo.listingName,
          //     message: chat_data[0][1].userInfo.lastMessage,
          //     type: "default",
          //     insert: "top",
          //     container: "top-right",
          //     animationIn: ["animate__animated", "animate__fadeIn"],
          //     animationOut: ["animate__animated", "animate__fadeOut"],
          //     dismiss: {
          //       duration: 5000,
          //     },
          //     width: 300
          //   })
          // }
          chatNotification.current = chat_data;
          // setChat(doc.data());
        }
      })
    }
    else {
      // doc.data() will be undefined in this case
    }
  }

  const getCurrentUserInfo = async () => {
    var userInfoLocal;
    unsubscribeCurrentUserInfo = onSnapshot(doc(db, `userInfo`, user.uid), async () => {
      // })
      await getDoc(doc(db, 'userInfo', user.uid)).then(async (docData) => {

        var paymentPeriod = "Monthly";
        var nextBillingDate;
        // if (docData.data().hasOwnProperty('subscriptionPeriod')) {
        // if (docData.data().subscriptionPeriod == "Monthly") {
        //   nextBillingDate = new Date(docData.data().startDate.seconds * 1000 + docData.data().startDate.nanoseconds / 1000000);
        //   // Set the time to 12 PM by default
        //   nextBillingDate.setHours(0, 0, 0, 0);
        //   nextBillingDate = new Date(nextBillingDate.getTime() + ((docData.data().subscriptionCounter) * 24 * 60 * 60 * 1000));
        // }
        // else {
        //   nextBillingDate = new Date(docData.data().startDate.seconds * 1000 + docData.data().startDate.nanoseconds / 1000000);
        //   // Set the time to 12 PM by default
        //   nextBillingDate.setHours(0, 0, 0, 0);
        //   nextBillingDate.setFullYear(nextBillingDate.getFullYear() + 1);
        //   nextBillingDate.setDate(nextBillingDate.getDate() + 1);
        // }
        // // // Format the date as a string
        // var formattedDate = nextBillingDate.toLocaleDateString('en-US', {
        //   weekday: 'short',
        //   year: 'numeric',
        //   month: 'short',
        //   day: 'numeric',
        //   hour: 'numeric',
        //   minute: 'numeric',
        //   hour12: true
        // });

        // setBillingDate(formattedDate);

        // } 
        // else if (docData.data().membership == "Free") {
        //   nextBillingDate = new Date(docData.data().startDate.seconds * 1000 + docData.data().startDate.nanoseconds / 1000000);
        //   // Set the time to 12 PM by default
        //   nextBillingDate.setHours(0, 0, 0, 0);
        //   nextBillingDate = new Date(nextBillingDate.getTime() + ((30 + 1) * 24 * 60 * 60 * 1000));
        //   var formattedDate = nextBillingDate.toLocaleDateString('en-US', {
        //     weekday: 'short',
        //     year: 'numeric',
        //     month: 'short',
        //     day: 'numeric',
        //     hour: 'numeric',
        //     minute: 'numeric',
        //     hour12: true
        //   });

        //   setBillingDate(formattedDate);
        // }

        // if (docData.data().userType != null) {
        //   if (docData.data().userType.includes("Master Tenant") || docData.data().userType.includes("Landlord")) {
        //     // if (docData.data().hasOwnProperty('propertyAddressList')) {
        //     //   if (docData.data().propertyAddressList.length == 0) {
        //     //     if (docData.data().hasOwnProperty('propertyAddressListResidential')) {
        //     //       if (docData.data().propertyAddressListResidential.length == 0) {
        //     //         setAddProperty(true);
        //     //       }
        //     //     }
        //     //   }
        //     // }
        //     // else if (docData.data().hasOwnProperty('propertyAddressListResidential')) {
        //     //   if (docData.data().propertyAddressListResidential.length == 0) {
        //     //     if (docData.data().hasOwnProperty('propertyAddressList')) {
        //     //       if (docData.data().propertyAddressList.length == 0) {
        //     //         setAddProperty(true);
        //     //       }
        //     //     }

        //     //   }
        //     // }
        //     // else {
        //     //   setAddProperty(true);
        //     // }
        //   }
        // }

        userInfoLocal = docData.data();
        setCurrentUserInfo(docData.data());
        if (docData.data().userType != null) {
          const myAdvListing = await query(collection(db, "advertiseListing"), where("UID", "==", user.uid));
          const queryAdvListing = await getDocs(myAdvListing);
          var advListingArr = [];
          queryAdvListing.forEach((indivDoc) => {
            var details = indivDoc.data();
            advListingArr.push({ id: indivDoc.id, details: details });
          });
          if (advListingArr.length != 0) {
            setAdvListing(advListingArr);
          }
        }
        if (docData.data().hasOwnProperty('businessArray')) {
          setAccountBusinessArray(docData.data().businessArray);
          setBusinessAccountIndex(0);
        }
        var userPropertyList = await getDoc(doc(db, 'commericalPropertyList', user.uid));
        console.log(userPropertyList.exists())
        if (userPropertyList.exists() == true) {
          console.log(Object.entries(userPropertyList.data()));
          setAccountPropertyList(Object.entries(userPropertyList.data()));
        }
        var userResidentialPropertyList = await getDoc(doc(db, 'residentialPropertyList', user.uid));
        if (userResidentialPropertyList.exists() == true) {
          setAccountPropertyListResidential(Object.entries(userResidentialPropertyList.data()));
        }


      }).then(async () => {
        await getDoc(doc(db, 'publicUserInfo', user.uid)).then((docDataPublic) => {
          if (docDataPublic.data().emailNotificationPending == false) {
            setIsToggled(false);
          }
          var publicInformation = docDataPublic.data();
          if (publicInformation.hasOwnProperty('usernameDate')) {
            var usernameDate = new Date(docDataPublic.data().usernameDate.seconds * 1000);
            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() - 2);
            if (usernameDate.getTime() < currentDate.getTime()) {
              publicInformation.allowChangeUsername = true;
            } else {
              publicInformation.allowChangeUsername = false;
            }
          }
          else {
            publicInformation.allowChangeUsername = true;
          }
          if (userInfoLocal.userType != null) {
            if (userInfoLocal.userType.includes("Agent")) {
              if (publicInformation.hasOwnProperty('agentProfile')) {

              }
              else {
                setActivateCEA(true);
              }
            }
          }


          setPublicInfo(publicInformation);
        })
      })




    })


    //   var premiumChildren = await getDoc(doc(db, 'premiumInformation', user.uid));
    //   if(premiumChildren.exists()){
    //     setArrayChildren(premiumChildren.data().teamMembers);
    //   }
  }

  useEffect(() => {

    if (!user) {
    }
    else {
      // user.uid && getFirebaseData();
      if (user.hasOwnProperty('uid')) {
        getCurrentUserInfo();
      }
      const storedData = sessionStorage.getItem('requirements');

      // Parse the string back to an object
      const requirements = JSON.parse(storedData);
      console.log(requirements);
      if (requirements) {
        sessionStorage.removeItem('requirements');
        setHomeRequirements(requirements.requirements);
        if (requirements.type == "residentialSale") {
          setClickPurchase(true);
          setAccountListingView('Residential');
        }
        else if (requirements.type == "residentialRent") {
          setClick(true);
          setAccountListingView('Residential');
        }
        else if (requirements.type == "commercialSale") {
          setClickPurchase(true);
          setAccountListingView('Commercial');
        }
        else if (requirements.type == "commercialRent") {
          setClick(true);
          setAccountListingView('Commercial');
        }
        else if (requirements.type == "addPropetyLandlord") {
          setAddProperty(true);
        }
      }

      return unsubscribeCurrentUserInfo;
    }

  }, [user]);

  useEffect(() => {

    if (currentUserInfo == null) {
    }
    else {
      if (user.hasOwnProperty('uid')) {
        getFirebaseData();
      }

      // getCurrentUserInfo();

      return unsbscribe;
    }

  }, [currentUserInfo]);


  useEffect(() => {

    if (!user) {

    }
    else {
      // user.uid && getFirebaseData()
      // return unsbscribe;

      if (user.uid != undefined) {
        if (user.emailVerified == false) {
          setVerification(true);
        }
        return;
      }
    }

  }, [user]);


  useEffect(() => {
    if (click == false) {
      setModifyListingId(null);
    }
  }, [click])


  useEffect(() => {
    if (!user) {
    }
    else {
      if (user.uid != null || user.uid != undefined) {
        getData();
        getUserData();
        // getThirdParty();
      }
    }
  }, [user]);

  const getData = async () => {
    var allData = [];
    var allDataResidential = [];
    var allDataCommercialPurchase = [];
    var allDataResidenitalPurchase = [];
    var shortlistData = [];

    var availableResidentialRentListing = 0;
    var availableCommercialRentListing = 0;
    var availableResidentialSalesListing = 0;
    var availableCommercialSalesListing = 0;
    const q = query(collection(db, "projects"), where("UID", "==", user.uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.data().status == "leasing") {
        availableCommercialRentListing++;
      }
      allData.push({ id: doc.id, details: doc.data() })


    });
    allData.sort((a, b) => b.details.timePosted - a.details.timePosted);


    const residentialListing = query(collection(db, "residentialListing"), where("UID", "==", user.uid));
    const residentialListingSnapshot = await getDocs(residentialListing);
    residentialListingSnapshot.forEach((docIndiv) => {
      if (docIndiv.data().status == "leasing") {
        availableResidentialRentListing++;
      }
      var residentialDetails = docIndiv.data();
      var periodMoveInDate = new Date(residentialDetails.periodMoveIn.seconds * 1000);
      var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
      residentialDetails.periodMoveInDateFormatted = formattedDate;
      allDataResidential.push({ id: docIndiv.id, details: residentialDetails })


    });

    const listingCommercialPurchase = query(collection(db, "listingCommercialPurchase"), where("UID", "==", user.uid));
    const listingCommercialPurchaseSnapshot = await getDocs(listingCommercialPurchase);
    listingCommercialPurchaseSnapshot.forEach((docIndiv) => {
      if (docIndiv.data().status == "leasing") {
        availableCommercialSalesListing++;
      }
      var commercialDetailsIndiv = docIndiv.data();
      var periodMoveInDate = new Date(commercialDetailsIndiv.moveInPeriod.seconds * 1000);
      var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
      commercialDetailsIndiv.periodMoveInDateFormatted = formattedDate;
      allDataCommercialPurchase.push({ id: docIndiv.id, details: commercialDetailsIndiv })


    });

    const residentialListingPurchase = query(collection(db, "residentialListingPurchase"), where("UID", "==", user.uid));
    const residentialListingPurchaseSnapshot = await getDocs(residentialListingPurchase);
    residentialListingPurchaseSnapshot.forEach((docIndiv) => {
      if (docIndiv.data().status == "leasing") {
        availableResidentialSalesListing++;
      }
      var residentialDetailsIndiv = docIndiv.data();
      var periodMoveInDate = new Date(residentialDetailsIndiv.moveInPeriod.seconds * 1000);
      var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
      residentialDetailsIndiv.periodMoveInDateFormatted = formattedDate;
      allDataResidenitalPurchase.push({ id: docIndiv.id, details: residentialDetailsIndiv })


    });
    allDataResidential.sort((a, b) => b.details.timePosted - a.details.timePosted);
    allDataCommercialPurchase.sort((a, b) => b.details.timePosted - a.details.timePosted);
    allDataResidenitalPurchase.sort((a, b) => b.details.timePosted - a.details.timePosted);
    setCommercialListingPurchase(allDataCommercialPurchase);
    setResidentialPurchase(allDataResidenitalPurchase);
    setUserResidentialListing(allDataResidential);
    setUserProject(allData);
    setUserResidentialSalesAvailableNumber(availableResidentialSalesListing);
    setCommercialSalesAvailableNumber(availableCommercialSalesListing);
    setCommercialRentAvailableNumber(availableCommercialRentListing);
    setUserResidentialRentAvailableNumber(availableResidentialRentListing);



    const q_1 = await getDocs(collection(db, `userInfo/${user.uid}/shortList`));
    q_1.forEach(async (IndivDoc) => {
      const shortlistIndiv_data = await getDoc(doc(db, 'projects', IndivDoc.id));
      if (shortlistIndiv_data.exists()) {
        shortlistData.push({ id: shortlistIndiv_data.id, details: shortlistIndiv_data.data() })
      }
      else {
        const residentialShortList = await getDoc(doc(db, 'residentialListing', IndivDoc.id));
        if (residentialShortList.exists()) {
          var residentialDetails = residentialShortList.data();
          var periodMoveInDate = new Date(residentialDetails.periodMoveIn.seconds * 1000);
          var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
          residentialDetails.periodMoveInDateFormatted = formattedDate;
          shortlistData.push({ id: residentialShortList.id, details: residentialDetails })
        }
        else {
          const residentialShortListPurchase = await getDoc(doc(db, 'residentialListingPurchase', IndivDoc.id));
          if (residentialShortListPurchase.exists()) {
            var residentialDetails = residentialShortListPurchase.data();
            var periodMoveInDate = new Date(residentialDetails.moveInPeriod.seconds * 1000);
            var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
            residentialDetails.periodMoveInDateFormatted = formattedDate;
            shortlistData.push({ id: residentialShortListPurchase.id, details: residentialDetails })
          }
          else {
            const commercialShortListPurcahse = await getDoc(doc(db, 'listingCommercialPurchase', IndivDoc.id));
            if (commercialShortListPurcahse.exists()) {
              var commericalPurchase = commercialShortListPurcahse.data();
              var periodMoveInDate = new Date(commericalPurchase.moveInPeriod.seconds * 1000);
              var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
              commericalPurchase.periodMoveInDateFormatted = formattedDate;
              shortlistData.push({ id: commercialShortListPurcahse.id, details: commericalPurchase })
            }
            else {
              deleteDoc(doc(db, `userInfo/${user.uid}/shortList`, IndivDoc.id));
            }
          }
        }
      }

    })
    setShortList(shortlistData);

  }

  const ModifyListing = (id, status) => {
    if (status == "rejected") {
      setModifyListingId(id);
      setClick(true);
      setRejected(true);
    }
    else {
      setModifyListingId(id);
      setClick(true);
    }

  }

  const modifyAdvertise = (id, status, project) => {

    setActivateID(id);
    setModifyAdvertiseDetails(project);
    setAdvertisePath("modify");
    setAdvertiseActionActivated(true);

  }

  const ModifyListingPurchase = (id, status) => {
    if (status == "rejected") {
      setModifyListingId(id);
      setClickPurchase(true);
      setRejected(true);
    }
    else {
      setModifyListingId(id);
      setClickPurchase(true);
    }

  }


  if (!user) {
    return <Navigate to='/' />
  }
  else if (currentUserInfo != null) {
    if (currentUserInfo.userType[0] != "Admin") {


      const handleSignOut = async () => {
        try {
          var savedValue = sessionStorage.getItem('currentTab');
          if (savedValue != null) {
            sessionStorage.removeItem('currentTab');
          }
          await logOut();
        } catch (error) {
          console.log(error);
        }
      }

      const IndivBoost = (id, name, type) => {
        setBoostIndivID({
          'id': id,
          'name': name
        });
        if (type == "rent") {
          setClickBoostIndiv(true);
        }
        else {
          setClickBoostIndivPurchase(true);
        }


      }

      const deteleShortList = async (id) => {
        if (user != null) {
          await deleteDoc(doc(db, `userInfo/${user.uid}/shortList`, id));
          var targetArr = [];
          targetArr = [...shortList];
          for (var i = 0; i < targetArr.length; i++) {
            if (targetArr[i].id == id) {
              targetArr.splice(i, 1);
              setShortList(targetArr);
              break;
            }
          }
        }
        else {
          alert("Please Sign Up First or This is your post");
        }
      }

      const displayNumber = (price) => {
        const numericPrice = typeof price === 'number' ? price : parseFloat(price);

        // Check if numericPrice is a valid number
        if (!isNaN(numericPrice)) {
          const formattedNumber = numericPrice.toLocaleString();
          console.log(formattedNumber);
          return formattedNumber;
        } else {
          console.error('Invalid number:', price);
          return price; // return the original value if it's not a valid number
        }
      }

      const resetPassword = () => {
        var newPassword = document.getElementById("newPassword").value;
        var confirmPassword = document.getElementById("confirmPassword").value;
        if (newPassword == "" | confirmPassword == "") {
          alert("Invalid Password");
        }
        else {
          const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{12,}$/;

          if (passwordRegex.test(newPassword)) {
            // Password is valid, do something
            var password = prompt("Please enter old password for confirmation");
            if (newPassword === confirmPassword) {
              const credential = firebase.auth.EmailAuthProvider.credential(
                user.email,
                password
              )
              reauthenticateWithCredential(user, credential)
                .then(() => {
                  updatePassword(user, newPassword).then(() => { alert("password updated") }).catch((error) => {
                    console.log(error);
                  })
                }).catch((error) => {
                  console.log(error);
                })
            }

          } else {
            alert("Password must be at least 12 characters long, contain at least one special character (!@#$%^&*) and at least one number (0-9)");
          }
        }
      }

      const displayPassword = () => {
        document.getElementById("newPassword").type = "text";
        document.getElementById("confirmPassword").type = "text";
        setDisplayPasswordEye(true);
      }

      const hidePassword = () => {
        document.getElementById("newPassword").type = "password";
        document.getElementById("confirmPassword").type = "password";
        setDisplayPasswordEye(false);
      }

      const openAccountTab = (idName) => {
        if (idName == "accountDetails") {
          document.getElementById("accountDetailsBtn").className = "accountButtonActive";
          document.getElementById("accountPasswordBtn").className = "accountButtonNotActive";
          if (currentUserInfo.userType.includes("Landlord") || currentUserInfo.userType.includes("Agent") || currentUserInfo.userType.includes("Master Tenant")) {
            document.getElementById("accountViewPropertyBtn").className = "accountButtonNotActive";
            document.getElementById("accountViewProperty").style.display = "none";
          }
          if (currentUserInfo.userType.includes("Agent")) {
            document.getElementById("accountViewAgentBtn").className = "accountButtonNotActive";
            document.getElementById("accountViewAgent").style.display = "none";
          }
          document.getElementById("accountDetails").style.display = "block";
          document.getElementById("accountPassword").style.display = "none";

          // document.getElementById("accountPremium").style.display = "none";
        }
        else if (idName == "accountPassword") {
          document.getElementById("accountDetailsBtn").className = "accountButtonNotActive";
          document.getElementById("accountPasswordBtn").className = "accountButtonActive";
          if (currentUserInfo.userType.includes("Landlord") || currentUserInfo.userType.includes("Agent") || currentUserInfo.userType.includes("Master Tenant")) {
            document.getElementById("accountViewPropertyBtn").className = "accountButtonNotActive";
            document.getElementById("accountViewProperty").style.display = "none";
          }
          if (currentUserInfo.userType.includes("Agent")) {
            document.getElementById("accountViewAgentBtn").className = "accountButtonNotActive";
            document.getElementById("accountViewAgent").style.display = "none";
          }
          // document.getElementById("accountPremiumBtn").className = "accountButtonNotActive";
          document.getElementById("accountDetails").style.display = "none";
          document.getElementById("accountPassword").style.display = "block";

          // document.getElementById("accountPremium").style.display = "none";
        }
        else if (idName == "accountViewProperty") {
          document.getElementById("accountDetailsBtn").className = "accountButtonNotActive";
          document.getElementById("accountPasswordBtn").className = "accountButtonNotActive";
          if (currentUserInfo.userType.includes("Landlord") || currentUserInfo.userType.includes("Agent") || currentUserInfo.userType.includes("Master Tenant")) {
            document.getElementById("accountViewPropertyBtn").className = "accountButtonActive";
            document.getElementById("accountViewProperty").style.display = "block";
          }
          if (currentUserInfo.userType.includes("Agent")) {
            document.getElementById("accountViewAgentBtn").className = "accountButtonNotActive";
            document.getElementById("accountViewAgent").style.display = "none";
          }
          // document.getElementById("accountPremiumBtn").className = "accountButtonNotActive";
          document.getElementById("accountDetails").style.display = "none";
          document.getElementById("accountPassword").style.display = "none";

          // document.getElementById("accountPremium").style.display = "none";
        }
        else if (idName == "accountViewAgent") {
          document.getElementById("accountDetailsBtn").className = "accountButtonNotActive";
          document.getElementById("accountPasswordBtn").className = "accountButtonNotActive";
          document.getElementById('accountViewPropertyBtn').className = "accountButtonNotActive";
          document.getElementById('accountViewAgentBtn').className = "accountButtonActive";
          document.getElementById("accountDetails").style.display = "none";
          document.getElementById("accountPassword").style.display = "none";
          document.getElementById("accountViewProperty").style.display = "none";
          document.getElementById("accountViewAgent").style.display = "block";
        }
        else if (idName = "accountEnterprise") {
          document.getElementById("accountDetailsBtn").className = "accountButtonNotActive";
          document.getElementById("accountPasswordBtn").className = "accountButtonNotActive";
          if (currentUserInfo.userType.includes("Landlord")) {
            document.getElementById("accountViewPropertyBtn").className = "accountButtonNotActive";
            document.getElementById("accountViewProperty").style.display = "none";
          }
          // document.getElementById("accountPremiumBtn").className = "accountButtonActive";
          document.getElementById("accountDetails").style.display = "none";
          document.getElementById("accountPassword").style.display = "none";

          // document.getElementById("accountPremium").style.display = "block";
        }




      }

      const modifyIndivPropertyList = (targetIndex, view) => {
        setAddPropertyView(view);
        setModifyIndivPropertyIndex(targetIndex);
        setAddProperty(true);
      }

      const changePlanTier = () => {
        setChangeAccountTier(true);
        setActivateUserType(true);
      }

      const changeRoleTier = () => {
        setChangeAccountTier(false);
        setActivateUserType(true);
      }

      const retractConversation = async (chatInformation) => {
        try {

          if (chatInformation.pendingInfo[1].userInfo.chatType == "sellerProfile") {
            var stringLeft = chatInformation.pendingInfo[1].userInfo.oppositeUID;
          }
          else {
            var stringLeft = chatInformation.pendingInfo[0].replace(user.uid, "");
            stringLeft = stringLeft.replace(chatInformation.pendingInfo[1].userInfo.listingID, "");
          }

          await deleteDoc(doc(db, 'chats', chatInformation.pendingInfo[0]));
          await updateDoc(doc(db, 'userChat', user.uid), {
            [chatInformation.pendingInfo[0]]: deleteField()
          })
          await updateDoc(doc(db, 'userChat', stringLeft), {
            [chatInformation.pendingInfo[0]]: deleteField()
          })
          await updateDoc(doc(db, 'userChat', user.uid), {
            [chatInformation.pendingInfo[0]]: deleteField()
          })

          if (chatInformation.retractStatus == true) {

            await updateDoc(doc(db, 'userInfo', user.uid), {
              credit: currentUserInfo.credit + 23
            }).then(async () => {

              const updatedCurrentUserInfo = { ...currentUserInfo, credit: currentUserInfo.credit + 23 };
              setCurrentUserInfo(updatedCurrentUserInfo);
            }).catch((error) => {
              console.log(error);
            })

          }




        }
        catch (error) {
        }

      }

      const acceptConversation = async (chatInformation) => {
        //update the chats
        const detailChatConversation = await getDoc(doc(db, 'chats', chatInformation.pendingInfo[0]));
        var changedMessage = detailChatConversation.data().messages;
        changedMessage.push({
          createdAt: new Date(),
          text: "SystemNextloopNoti: Accepted Connection",
          sender: user.uid,
          senderName: user.displayName
        })
        await updateDoc(doc(db, 'chats', chatInformation.pendingInfo[0]), {
          messages: changedMessage
        })

        let newTransactionHistory = [...currentUserInfo.transactionHistory];
        if (newTransactionHistory.length >= 30) {
          newTransactionHistory.pop();
          newTransactionHistory.push(
            {
              Actions: "+1 Points",
              Activity: "Accepted Connections",
              Date: new Date(),
              Type: "Connections"
            }
          )
        }
        else {
          newTransactionHistory.push(
            {
              Actions: "+1 Points",
              Activity: "Accepted Connections",
              Date: new Date(),
              Type: "Connections"
            }
          )
        }

        let snapshotUserInfo = await updateDoc(doc(db, 'userInfo', user.uid), {
          points: currentUserInfo.points + 1,
          transactionHistory: newTransactionHistory
        })

        if (chatInformation.pendingInfo[1].userInfo.chatType == "CommercialRent") {
          if (chatInformation.pendingInfo[1].userInfo.oppUserType == "Master Tenant" || chatInformation.pendingInfo[1].userInfo.oppUserType == "Landlord") {
            console.log(chatInformation.pendingInfo[1].userInfo)
            await updateDoc(doc(db, "userChat", user.uid), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: chatInformation.pendingInfo[1].userInfo.displayName,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: chatInformation.pendingInfo[1].userInfo.photoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
                  listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
                  listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
                  statusRead: "Read",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: chatInformation.pendingInfo[1].userInfo.oppUserType,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented

                }
              }

            })

            await getDoc(doc(db, 'projects', chatInformation.pendingInfo[1].userInfo.listingID))
              .then(async (res) => {
                console.log(res.data())
                if (res.data().hasOwnProperty('connections')) {
                  var connection = res.data().connections;
                  await updateDoc(doc(db, "projects", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  }).then(() => {
                    console.log('updated');
                  })
                }
                else {
                  var connection = 0;
                  await updateDoc(doc(db, "projects", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
              })

            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
                  listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
                  listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
                  statusRead: "Unread",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppUserType: "Tenant",
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })

            if (currentUserInfo.membership == "Enterprise") {
              // await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {
              //   [chatInformation.pendingInfo[0]]: {
              //     date: serverTimestamp(),
              //     userInfo: {
              //       displayName: user.displayName,
              //       listingID: chatInformation.pendingInfo[1].userInfo.listingID,
              //       uid: user.uid,
              //       photoURL: user.photoURL,
              //       lastMessage: "Accepted Connection",
              //       listingName: chatInformation.pendingInfo[1].userInfo.listingName,
              //       listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
              //       listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
              //       listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
              //       statusRead: "Unread",
              //       status: "Accepted",
              //       chatType: chatInformation.pendingInfo[1].userInfo.chatType,
              //       ownerUID: chatInformation.pendingInfo[1].userInfo.ownerUID
              //     }
              //   }
              // })
            }
          }
          else {
            //agent
            await updateDoc(doc(db, "userChat", user.uid), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: chatInformation.pendingInfo[1].userInfo.displayName,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: chatInformation.pendingInfo[1].userInfo.photoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
                  listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
                  listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
                  statusRead: "Read",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  agentProfile: chatInformation.pendingInfo[1].userInfo.agentProfile,
                  myUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  oppUserType: chatInformation.pendingInfo[1].userInfo.oppUserType,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }

            })

            await getDoc(doc(db, 'projects', chatInformation.pendingInfo[1].userInfo.listingID))
              .then(async (res) => {
                if (res.data().hasOwnProperty('connections')) {
                  var connection = res.data().connections;
                  await updateDoc(doc(db, "projects", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
                else {
                  var connection = 0;
                  await updateDoc(doc(db, "projects", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
              })

            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
                  listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
                  listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
                  statusRead: "Unread",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  oppUserType: "Tenant",
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  agentProfile: chatInformation.pendingInfo[1].userInfo.agentProfile,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })

            if (currentUserInfo.membership == "Enterprise") {
              // await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {
              //   [chatInformation.pendingInfo[0]]: {
              //     date: serverTimestamp(),
              //     userInfo: {
              //       displayName: user.displayName,
              //       listingID: chatInformation.pendingInfo[1].userInfo.listingID,
              //       uid: user.uid,
              //       photoURL: user.photoURL,
              //       lastMessage: "Accepted Connection",
              //       listingName: chatInformation.pendingInfo[1].userInfo.listingName,
              //       listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
              //       listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
              //       listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
              //       statusRead: "Unread",
              //       status: "Accepted",
              //       chatType: chatInformation.pendingInfo[1].userInfo.chatType,
              //       ownerUID: chatInformation.pendingInfo[1].userInfo.ownerUID
              //     }
              //   }
              // })
            }
          }

        }
        else if (chatInformation.pendingInfo[1].userInfo.chatType == "CommercialSales") {
          if (chatInformation.pendingInfo[1].userInfo.oppUserType == "Master Tenant" || chatInformation.pendingInfo[1].userInfo.oppUserType == "Landlord") {
            await updateDoc(doc(db, "userChat", user.uid), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: chatInformation.pendingInfo[1].userInfo.displayName,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: chatInformation.pendingInfo[1].userInfo.photoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  minFloorSizePSFBudget: chatInformation.pendingInfo[1].userInfo.minFloorSizePSFBudget,
                  statusRead: "Read",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: chatInformation.pendingInfo[1].userInfo.oppUserType,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented

                }
              }

            })

            await getDoc(doc(db, 'listingCommercialPurchase', chatInformation.pendingInfo[1].userInfo.listingID))
              .then(async (res) => {
                if (res.data().hasOwnProperty('connections')) {
                  var connection = res.data().connections;
                  await updateDoc(doc(db, "listingCommercialPurchase", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
                else {
                  var connection = 0;
                  await updateDoc(doc(db, "listingCommercialPurchase", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
              })

            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  minFloorSizePSFBudget: chatInformation.pendingInfo[1].userInfo.minFloorSizePSFBudget,
                  statusRead: "Unread",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppUserType: "Tenant",
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })

            if (currentUserInfo.membership == "Enterprise") {
              // await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {
              //   [chatInformation.pendingInfo[0]]: {
              //     date: serverTimestamp(),
              //     userInfo: {
              //       displayName: user.displayName,
              //       listingID: chatInformation.pendingInfo[1].userInfo.listingID,
              //       uid: user.uid,
              //       photoURL: user.photoURL,
              //       lastMessage: "Accepted Connection",
              //       listingName: chatInformation.pendingInfo[1].userInfo.listingName,
              //       listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
              //       listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
              //       listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
              //       statusRead: "Unread",
              //       status: "Accepted",
              //       chatType: chatInformation.pendingInfo[1].userInfo.chatType,
              //       ownerUID: chatInformation.pendingInfo[1].userInfo.ownerUID
              //     }
              //   }
              // })
            }
          }
          else {
            //agent
            await updateDoc(doc(db, "userChat", user.uid), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: chatInformation.pendingInfo[1].userInfo.displayName,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: chatInformation.pendingInfo[1].userInfo.photoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  minFloorSizePSFBudget: chatInformation.pendingInfo[1].userInfo.minFloorSizePSFBudget,
                  statusRead: "Read",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  agentProfile: chatInformation.pendingInfo[1].userInfo.agentProfile,
                  myUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  oppUserType: chatInformation.pendingInfo[1].userInfo.oppUserType,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }

            })

            await getDoc(doc(db, 'listingCommercialPurchase', chatInformation.pendingInfo[1].userInfo.listingID))
              .then(async (res) => {
                if (res.data().hasOwnProperty('connections')) {
                  var connection = res.data().connections;
                  await updateDoc(doc(db, "listingCommercialPurchase", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
                else {
                  var connection = 0;
                  await updateDoc(doc(db, "listingCommercialPurchase", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
              })

            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  minFloorSizePSFBudget: chatInformation.pendingInfo[1].userInfo.minFloorSizePSFBudget,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Unread",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  oppUserType: "Tenant",
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  agentProfile: chatInformation.pendingInfo[1].userInfo.agentProfile,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })

            if (currentUserInfo.membership == "Enterprise") {
              // await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {
              //   [chatInformation.pendingInfo[0]]: {
              //     date: serverTimestamp(),
              //     userInfo: {
              //       displayName: user.displayName,
              //       listingID: chatInformation.pendingInfo[1].userInfo.listingID,
              //       uid: user.uid,
              //       photoURL: user.photoURL,
              //       lastMessage: "Accepted Connection",
              //       listingName: chatInformation.pendingInfo[1].userInfo.listingName,
              //       listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
              //       listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
              //       listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
              //       statusRead: "Unread",
              //       status: "Accepted",
              //       chatType: chatInformation.pendingInfo[1].userInfo.chatType,
              //       ownerUID: chatInformation.pendingInfo[1].userInfo.ownerUID
              //     }
              //   }
              // })
            }
          }
        }
        else if (chatInformation.pendingInfo[1].userInfo.chatType == "ResidentialRent") {
          if (chatInformation.pendingInfo[1].userInfo.oppUserType == "Master Tenant" || chatInformation.pendingInfo[1].userInfo.oppUserType == "Landlord") {
            await updateDoc(doc(db, "userChat", user.uid), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: chatInformation.pendingInfo[1].userInfo.displayName,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: chatInformation.pendingInfo[1].userInfo.photoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Read",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: chatInformation.pendingInfo[1].userInfo.oppUserType,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }

            })

            await getDoc(doc(db, 'residentialListing', chatInformation.pendingInfo[1].userInfo.listingID))
              .then(async (res) => {
                if (res.data().hasOwnProperty('connections')) {
                  var connection = res.data().connections;
                  await updateDoc(doc(db, "residentialListing", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
                else {
                  var connection = 0;
                  await updateDoc(doc(db, "residentialListing", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
              })

            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {

                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Unread",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppUserType: "Tenant",
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })

            if (currentUserInfo.membership == "Enterprise") {
              // await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {
              //   [chatInformation.pendingInfo[0]]: {
              //     date: serverTimestamp(),
              //     userInfo: {
              //       displayName: user.displayName,
              //       listingID: chatInformation.pendingInfo[1].userInfo.listingID,
              //       uid: user.uid,
              //       photoURL: user.photoURL,
              //       lastMessage: "Accepted Connection",
              //       listingName: chatInformation.pendingInfo[1].userInfo.listingName,
              //       listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
              //       listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
              //       listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
              //       statusRead: "Unread",
              //       status: "Accepted",
              //       chatType: chatInformation.pendingInfo[1].userInfo.chatType,
              //       ownerUID: chatInformation.pendingInfo[1].userInfo.ownerUID
              //     }
              //   }
              // })
            }
          }
          else {
            //agent
            await updateDoc(doc(db, "userChat", user.uid), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: chatInformation.pendingInfo[1].userInfo.displayName,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: chatInformation.pendingInfo[1].userInfo.photoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Read",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: chatInformation.pendingInfo[1].userInfo.oppUserType,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentProfile: chatInformation.pendingInfo[1].userInfo.agentProfile,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }

            })

            await getDoc(doc(db, 'residentialListing', chatInformation.pendingInfo[1].userInfo.listingID))
              .then(async (res) => {
                if (res.data().hasOwnProperty('connections')) {
                  var connection = res.data().connections;
                  await updateDoc(doc(db, "residentialListing", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
                else {
                  var connection = 0;
                  await updateDoc(doc(db, "residentialListing", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
              })

            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Unread",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppUserType: "Tenant",
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentProfile: chatInformation.pendingInfo[1].userInfo.agentProfile,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })

            if (currentUserInfo.membership == "Enterprise") {
              // await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {
              //   [chatInformation.pendingInfo[0]]: {
              //     date: serverTimestamp(),
              //     userInfo: {
              //       displayName: user.displayName,
              //       listingID: chatInformation.pendingInfo[1].userInfo.listingID,
              //       uid: user.uid,
              //       photoURL: user.photoURL,
              //       lastMessage: "Accepted Connection",
              //       listingName: chatInformation.pendingInfo[1].userInfo.listingName,
              //       listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
              //       listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
              //       listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
              //       statusRead: "Unread",
              //       status: "Accepted",
              //       chatType: chatInformation.pendingInfo[1].userInfo.chatType,
              //       ownerUID: chatInformation.pendingInfo[1].userInfo.ownerUID
              //     }
              //   }
              // })
            }
          }

        }
        else if (chatInformation.pendingInfo[1].userInfo.chatType == "ResidentialSales") {
          if (chatInformation.pendingInfo[1].userInfo.oppUserType == "Master Tenant" || chatInformation.pendingInfo[1].userInfo.oppUserType == "Landlord") {
            await updateDoc(doc(db, "userChat", user.uid), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: chatInformation.pendingInfo[1].userInfo.displayName,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: chatInformation.pendingInfo[1].userInfo.photoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  budgetOverall: chatInformation.pendingInfo[1].userInfo.budgetOverall,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Read",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: chatInformation.pendingInfo[1].userInfo.oppUserType,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }

            })

            await getDoc(doc(db, 'residentialListingPurchase', chatInformation.pendingInfo[1].userInfo.listingID))
              .then(async (res) => {
                if (res.data().hasOwnProperty('connections')) {
                  var connection = res.data().connections;
                  await updateDoc(doc(db, "residentialListingPurchase", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
                else {
                  var connection = 0;
                  await updateDoc(doc(db, "residentialListingPurchase", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
              })

            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  budgetOverall: chatInformation.pendingInfo[1].userInfo.budgetOverall,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Unread",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppUserType: "Tenant",
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })

            if (currentUserInfo.membership == "Enterprise") {
              // await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {
              //   [chatInformation.pendingInfo[0]]: {
              //     date: serverTimestamp(),
              //     userInfo: {
              //       displayName: user.displayName,
              //       listingID: chatInformation.pendingInfo[1].userInfo.listingID,
              //       uid: user.uid,
              //       photoURL: user.photoURL,
              //       lastMessage: "Accepted Connection",
              //       listingName: chatInformation.pendingInfo[1].userInfo.listingName,
              //       listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
              //       listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
              //       listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
              //       statusRead: "Unread",
              //       status: "Accepted",
              //       chatType: chatInformation.pendingInfo[1].userInfo.chatType,
              //       ownerUID: chatInformation.pendingInfo[1].userInfo.ownerUID
              //     }
              //   }
              // })
            }
          }
          else {
            //agent
            await updateDoc(doc(db, "userChat", user.uid), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: chatInformation.pendingInfo[1].userInfo.displayName,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: chatInformation.pendingInfo[1].userInfo.photoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  budgetOverall: chatInformation.pendingInfo[1].userInfo.budgetOverall,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Read",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: chatInformation.pendingInfo[1].userInfo.oppUserType,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentProfile: chatInformation.pendingInfo[1].userInfo.agentProfile,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }

            })

            await getDoc(doc(db, 'residentialListingPurchase', chatInformation.pendingInfo[1].userInfo.listingID))
              .then(async (res) => {
                if (res.data().hasOwnProperty('connections')) {
                  var connection = res.data().connections;
                  await updateDoc(doc(db, "residentialListingPurchase", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
                else {
                  var connection = 0;
                  await updateDoc(doc(db, "residentialListingPurchase", chatInformation.pendingInfo[1].userInfo.listingID), {
                    connections: connection + 1
                  })
                }
              })

            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Accepted Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  budgetOverall: chatInformation.pendingInfo[1].userInfo.budgetOverall,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Unread",
                  status: "Accepted",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppUserType: "Tenant",
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentProfile: chatInformation.pendingInfo[1].userInfo.agentProfile,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })

            if (currentUserInfo.membership == "Enterprise") {
              // await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {
              //   [chatInformation.pendingInfo[0]]: {
              //     date: serverTimestamp(),
              //     userInfo: {
              //       displayName: user.displayName,
              //       listingID: chatInformation.pendingInfo[1].userInfo.listingID,
              //       uid: user.uid,
              //       photoURL: user.photoURL,
              //       lastMessage: "Accepted Connection",
              //       listingName: chatInformation.pendingInfo[1].userInfo.listingName,
              //       listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
              //       listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
              //       listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
              //       statusRead: "Unread",
              //       status: "Accepted",
              //       chatType: chatInformation.pendingInfo[1].userInfo.chatType,
              //       ownerUID: chatInformation.pendingInfo[1].userInfo.ownerUID
              //     }
              //   }
              // })
            }
          }

        }
        else if (chatInformation.pendingInfo[1].userInfo.chatType == "sellerProfile") {
          await updateDoc(doc(db, "userChat", user.uid), {
            [chatInformation.pendingInfo[0]]: {
              date: serverTimestamp(),
              userInfo: {
                PSFValue: chatInformation.pendingInfo[1].userInfo.PSFValue,
                address: chatInformation.pendingInfo[1].userInfo.address,
                agentProfileDeck: chatInformation.pendingInfo[1].userInfo.agentProfileDeck,
                chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                commission: chatInformation.pendingInfo[1].userInfo.commission,
                displayName: chatInformation.pendingInfo[1].userInfo.displayName,
                lastMessage: "Accepted Connection",
                myUID: chatInformation.pendingInfo[1].userInfo.myUID,
                oppUserType: chatInformation.pendingInfo[1].userInfo.oppUserType,
                photoURL: chatInformation.pendingInfo[1].userInfo.photoURL,
                postalCode: chatInformation.pendingInfo[1].userInfo.postalCode,
                sellingPrice: chatInformation.pendingInfo[1].userInfo.sellingPrice,
                statusRead: "Read",
                status: "Accepted",
                oppositeUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                unitNumber: chatInformation.pendingInfo[1].userInfo.unitNumber
              }
            }

          })



          await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
            [chatInformation.pendingInfo[0]]: {
              date: serverTimestamp(),
              userInfo: {
                PSFValue: chatInformation.pendingInfo[1].userInfo.PSFValue,
                address: chatInformation.pendingInfo[1].userInfo.address,
                agentProfileDeck: chatInformation.pendingInfo[1].userInfo.agentProfileDeck,
                chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                commission: chatInformation.pendingInfo[1].userInfo.commission,
                displayName: publicInfo.username,
                lastMessage: "Accepted Connection",
                oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                oppUserType: "Landlord",
                photoURL: currentUserInfo.userPhotoURL,
                postalCode: chatInformation.pendingInfo[1].userInfo.postalCode,
                sellingPrice: chatInformation.pendingInfo[1].userInfo.sellingPrice,
                statusRead: "Unread",
                status: "Accepted",
                unitNumber: chatInformation.pendingInfo[1].userInfo.unitNumber
              }
            }
          })




        }

        if (chatInformation.pendingInfo[1].userInfo.chatType != "sellerProfile") {
          await getDoc(doc(db, 'publicUserInfo', chatInformation.pendingInfo[1].userInfo.oppositeUID)).then(async (emailRes) => {
            if (emailRes.data().emailNotificationPending == true) {
              const requestOptions_1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                body: JSON.stringify({ data: { listingName: chatInformation.pendingInfo[1].userInfo.listingName, username: emailRes.data().username, email: emailRes.data().email, path: `Accepted Connection` } })
              };
              await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                error => console.log(error)
              );
            }

          })
        }



      }

      const rejectConversation = async (chatInformation) => {
        const detailChatConversation = await getDoc(doc(db, 'chats', chatInformation.pendingInfo[0]));
        var changedMessage = detailChatConversation.data().messages;
        changedMessage.push({
          createdAt: new Date(),
          text: "SystemNextloopNoti: Rejected Conversation",
          sender: user.uid,
          senderName: user.displayName
        })
        await updateDoc(doc(db, 'chats', chatInformation.pendingInfo[0]), {
          messages: changedMessage
        })

        await updateDoc(doc(db, "userChat", user.uid), {
          [chatInformation.pendingInfo[0]]: deleteField()

        })
        if (chatInformation.pendingInfo[1].userInfo.chatType == "CommercialRent") {
          if (chatInformation.pendingInfo[1].userInfo.hasOwnProperty('agentProfile')) {
            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  agentProfile: chatInformation.pendingInfo[1].userInfo.agentProfile,
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Rejected Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
                  listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
                  listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
                  statusRead: "Unread",
                  status: "Rejected",
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: "Tenant",
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })
          }
          else {
            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Rejected Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  listingPhoto: chatInformation.pendingInfo[1].userInfo.listingPhoto,
                  listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
                  listingPricePerSquareFeet: chatInformation.pendingInfo[1].userInfo.listingPricePerSquareFeet,
                  statusRead: "Unread",
                  status: "Rejected",
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: "Tenant",
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })
          }

        }
        else if (chatInformation.pendingInfo[1].userInfo.chatType == "ResidentialRent") {
          ///////////RESIDENTIAL
          if (chatInformation.pendingInfo[1].userInfo.hasOwnProperty('agentProfile')) {
            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  agentProfile: chatInformation.pendingInfo[1].userInfo.agentProfile,
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Rejected Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Unread",
                  status: "Rejected",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: "Tenant",
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })
          }
          else {
            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Rejected Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  listingPriceOverall: chatInformation.pendingInfo[1].userInfo.listingPriceOverall,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Unread",
                  status: "Rejected",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: "Tenant",
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })
          }

        }
        else if (chatInformation.pendingInfo[1].userInfo.chatType == "CommercialSales") {

          if (chatInformation.pendingInfo[1].userInfo.hasOwnProperty('agentProfile')) {
            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  agentProfile: chatInformation.pendingInfo[1].userInfo.agentProfile,
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Rejected Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  minFloorSizePSFBudget: chatInformation.pendingInfo[1].userInfo.minFloorSizePSFBudget,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Unread",
                  status: "Rejected",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: "Tenant",
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })
          }
          else {
            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Rejected Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  minFloorSizePSFBudget: chatInformation.pendingInfo[1].userInfo.minFloorSizePSFBudget,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Unread",
                  status: "Rejected",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: "Tenant",
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })
          }

        }
        else if (chatInformation.pendingInfo[1].userInfo.chatType == "ResidentialSales") {
          ///////////RESIDENTIAL
          if (chatInformation.pendingInfo[1].userInfo.hasOwnProperty('agentProfile')) {
            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  agentProfile: chatInformation.pendingInfo[1].userInfo.agentProfile,
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Rejected Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  budgetOverall: chatInformation.pendingInfo[1].userInfo.budgetOverall,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Unread",
                  status: "Rejected",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: "Tenant",
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })
          }
          else {
            await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
              [chatInformation.pendingInfo[0]]: {
                date: serverTimestamp(),
                userInfo: {
                  displayName: publicInfo.username,
                  listingID: chatInformation.pendingInfo[1].userInfo.listingID,
                  photoURL: currentUserInfo.userPhotoURL,
                  lastMessage: "Rejected Connection",
                  listingName: chatInformation.pendingInfo[1].userInfo.listingName,
                  budgetOverall: chatInformation.pendingInfo[1].userInfo.budgetOverall,
                  moveInPeriod: chatInformation.pendingInfo[1].userInfo.moveInPeriod,
                  statusRead: "Unread",
                  status: "Rejected",
                  chatType: chatInformation.pendingInfo[1].userInfo.chatType,
                  myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                  oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                  oppUserType: "Tenant",
                  propertyList: chatInformation.pendingInfo[1].userInfo.propertyList,
                  recommended: chatInformation.pendingInfo[1].userInfo.recommended,
                  agentRepresented: chatInformation.pendingInfo[1].userInfo.agentRepresented
                }
              }
            })
          }

        }
        else if (chatInformation.pendingInfo[1].userInfo.chatType == "sellerProfile") {
          await updateDoc(doc(db, 'userChat', chatInformation.pendingInfo[1].userInfo.oppositeUID), {
            [chatInformation.pendingInfo[0]]: {
              date: serverTimestamp(),
              userInfo: {
                PSFValue: chatInformation.pendingInfo[1].userInfo.PSFValue,
                address: chatInformation.pendingInfo[1].userInfo.address,
                agentProfileDeck: chatInformation.pendingInfo[1].userInfo.agentProfileDeck,
                chatType: "sellerProfile",
                commission: chatInformation.pendingInfo[1].userInfo.commission,
                displayName: publicInfo.username,
                lastMessage: "Rejected Connection",
                statusRead: "Unread",
                status: "Rejected",
                myUID: chatInformation.pendingInfo[1].userInfo.oppositeUID,
                oppUserType: "Landlord",
                oppositeUID: chatInformation.pendingInfo[1].userInfo.myUID,
                photoURL: currentUserInfo.userPhotoURL,
                postalCode: chatInformation.pendingInfo[1].userInfo.postalCode,
                retractStatus: true,
              }
            }
          })
        }


        if (chatInformation.pendingInfo[1].userInfo.chatType != "sellerProfile") {
          // setUnReadMessagePendingCount(unReadMessageCountPending-1);
          await getDoc(doc(db, 'publicUserInfo', chatInformation.senderUID)).then(async (emailRes) => {
            if (emailRes.data().emailNotificationPending == true) {
              const requestOptions_1 = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
                body: JSON.stringify({ data: { listingName: chatInformation.pendingInfo[1].userInfo.listingName, username: emailRes.data().username, email: emailRes.data().email, path: `Rejected Connection` } })
              };
              await fetch('https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
                error => console.log(error)
              );
            }

          })
        }
      }

      const changeBusiness = () => {
        setAddBusiness(true);
        setModifyBusinessIndex(businessAccountIndex);
      }

      return (

        <>
          {isReady == false ? <><center><i style={{ marginTop: "10%" }} class="fa-solid fa-spinner fa-spin fa-2xl"></i></center></>
            :
            <>
              <ChangeProfilePic trigger={changeProfilePic} setTrigger={setChangeProfilePic}></ChangeProfilePic>
              <ChangePoints trigger={changePointsToCredit} setTrigger={setChangePointsToCredit} currentUserInfo={currentUserInfo} setCurrentUserInfo={setCurrentUserInfo}></ChangePoints>
              <ChangeUserName trigger={changeUserName} setTrigger={setChangeUserName} setPublicInfo={setPublicInfo} publicInfo={publicInfo}></ChangeUserName>
              <Spinner trigger={loadingSpinner} setTrigger={setLoadingSpinner}></Spinner>
              <AddOns trigger={addOns} setTrigger={setAddOns} currentUserInfo={currentUserInfo} setCurrentUserInfo={setCurrentUserInfo}></AddOns>
              <AcknowledgeNormal trigger={cancelSubscription} setTrigger={setCancelSubcription} message={acknowledgeMessage} setProcessCancelSubcription={setProcessCancelSubcription}></AcknowledgeNormal>
              <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={acknowledgeMessage}></AcknowledgeNormal>
              <AcknowledgeNormal trigger={acknowledgeNormalDeleteAdv} setTrigger={setAcknowledgeNormalDeleteAdv} message={acknowledgeMessage} setProceedAdvertiseDelete={setProceedAdvertiseDelete}></AcknowledgeNormal>
              <AcknowledgeNormal trigger={acknowledgeNormalDelete} setTrigger={setAcknowledgeNormalDelete} message={acknowledgeMessage} setProceedAccountDelete={setProceedAccountDelete}></AcknowledgeNormal>
              <ChangePlan trigger={changeSubscriptionPlan} setTrigger={setChangeSubscriptionPlan} currentUserInfo={currentUserInfo} setCurrentUserInfo={setCurrentUserInfo} commercialPurchase={commercialListingPurcahse} residentialPurchase={residentialListingPurchase} commercialRent={userProject} residentialRent={userResidentialListing}></ChangePlan>
              <Acknowledge trigger={acknowledge} setTrigger={setAcknowledge} message={acknowledgeMessage}></Acknowledge>
              <Verification trigger={verification} setTrigger={setVerification}></Verification>
              <TempOTP trigger={TempOTPTrigger} setTrigger={setTempOTPTrigger} />
              <UserTypeModal trigger={activateUserType} setTrigger={setActivateUserType} currentUserInfo={currentUserInfo} setCurrentUserInfo={setCurrentUserInfo} changeAccountTier={changeAccountTier} setChangeAccountTier={setChangeAccountTier} path="account" publicInfo={publicInfo} setPublicInfo={setPublicInfo} />
              <CEA trigger={activateCEA} setTrigger={setActivateCEA} modifyCEA={modifyCEA} setModifyCEA={setModifyCEA} currentUserInfo={currentUserInfo} setCurrentUserInfo={setCurrentUserInfo} publicInfo={publicInfo} setPublicInfo={setPublicInfo}></CEA>
              <ChangePassword trigger={passwordActive} setTrigger={setPasswordActive}></ChangePassword>
              <NewUserType trigger={activateNewUserType} setTrigger={setActivateNewUserType} currentUserInfo={currentUserInfo} setCurrentUserInfo={setCurrentUserInfo} changeAccountTier={changeAccountTier} setChangeAccountTier={setChangeAccountTier} path="account" />
              <AddPropertyLocation trigger={addProperty} setTrigger={setAddProperty} currentUserInfo={currentUserInfo} setCurrentUserInfo={setCurrentUserInfo} modifyPropertyIndex={modifyIndivPropertyIndex} accountPropertyList={accountPropertyList} setAccountPropertyList={setAccountPropertyList} setModifyIndivPropertyIndex={setModifyIndivPropertyIndex} accountPropertyListResidential={accountPropertyListResidential} setAccountPropertyListResidential={setAccountPropertyListResidential} addPropertyView={addPropertyView} setAddPropertyView={setAddPropertyView} publicInfo={publicInfo} homeRequirements={homeRequirements} setHomeRequirements={setHomeRequirements} />
              <ReferAFriend trigger={referFriend} setTrigger={setReferFriend} currentUserInfo={currentUserInfo} setCurrentUserInfo={setCurrentUserInfo}></ReferAFriend>
              <AddBusiness trigger={addBusiness} setTrigger={setAddBusiness} modifyBusinessIndex={modifyBusinessIndex} setModifyBusinessIndex={setModifyBusinessIndex} userProject={userProject} setUserProject={setUserProject} accountBusinessArray={accountBusinessArray} setAccountBusinessArray={setAccountBusinessArray}></AddBusiness>
              <PurchaseListing trigger={clickPurchase} setTrigger={setClickPurchase} modifyListingId={modifyListingId} accountBusinessArray={accountBusinessArray} rejected={rejected} accountView={accountListingView} setModifyListingId={setModifyListingId} publicInfo={publicInfo} currentUserInfo={currentUserInfo} homeRequirements={homeRequirements} setHomeRequirements={setHomeRequirements} />
              <NewProject trigger={click} setTrigger={setClick} modifyListingId={modifyListingId} accountBusinessArray={accountBusinessArray} rejected={rejected} accountView={accountListingView} publicInfo={publicInfo} currentUserInfo={currentUserInfo} homeRequirements={homeRequirements} setHomeRequirements={setHomeRequirements} />
              <RedeemCode trigger={promoCode} setTrigger={setPromoCode} currentUserInfo={currentUserInfo}></RedeemCode>
              <div class="flex h-full w-full">
                <div className="flex h-screen w-16 flex-col justify-between border-e bg-white">
                  <div>


                    <div className="border-t border-gray-100">
                      <div className="px-2">
                        <ul className="space-y-1 border-t border-gray-100 pt-4">
                          <li>
                            <span className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                            <a
                              onClick={() => ClickDashboard()}
                              
                            >

                              <ChartBarIcon className="h-5 w-5 opacity-75" fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2" ></ChartBarIcon>
                            </a>
                            <span
                              className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                            >
                              Dashboard
                            </span>
                            </span>


                          </li>

                          <li>
                            <span className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                            <a
                              onClick={() => ClickOnListing()}
                              
                            >

                              <i class="fa-solid fa-r h-5 w-5" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}></i>
                            </a>
                            <span
                              className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                            >

                              Rent Requirements
                            </span>
                            </span>

                          </li>

                          <li>
                            <span className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                              <a
                                onClick={() => ClickOnPurchase()}

                              >
                                <i class="fa-solid fa-p w-5 h-5" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}></i>
                              </a>

                              <span
                                className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                              >
                                Purchase Requirements
                              </span>
                            </span>

                          </li>

                          <li>
                            <span className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                              <a
                                onClick={() => ClickOnAdvertise()}

                              >
                                <i class="fa-solid fa-rectangle-ad"></i>
                              </a>

                              <span
                                className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                              >
                                Advertise
                              </span>
                            </span>

                          </li>
                          <li>
                            <span className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                              <a
                                onClick={() => ClickShortList()}

                              >
                                <HeartIcon className="h-5 w-5 opacity-75" fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"></HeartIcon>
                              </a>

                              <span
                                className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                              >
                                Favourites
                              </span>
                            </span>

                          </li>
                          <li>
                            <span className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                              <a
                                onClick={() => ClickOnMessage()}

                              >
                                <ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5 opacity-75" fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"></ChatBubbleOvalLeftEllipsisIcon>
                              </a>

                              <span
                                className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                              >
                                Messages
                              </span>
                            </span>

                          </li>
                          <li>
                            <span className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                              <a
                                onClick={() => ClickOnPendingMessage()}

                              >
                                <EnvelopeIcon className="h-5 w-5 opacity-75" fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"></EnvelopeIcon>
                              </a>

                              <span
                                className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                              >
                                Inbox
                              </span>
                            </span>

                          </li>
                          <li>
                            <span className="group relative flex justify-center rounded px-2 py-1.5 text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                              <a
                                onClick={() => ClickOnAccount()}

                              >

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5 opacity-75"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                  />
                                </svg>
                              </a>
                              <span
                                className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 z-10"
                              >
                                Profile
                              </span>
                            </span>

                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2">
                    <form action='/'>
                      <span className="group relative flex w-full justify-center rounded-lg px-2 py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                        <button
                          onClick={handleSignOut}

                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 opacity-75"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            />
                          </svg>
                        </button>
                        <span
                          className="absolute start-full top-1/2 ms-4 -translate-y-1/2 rounded bg-gray-900 px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100"
                        >
                          Logout
                        </span>

                      </span>

                    </form>
                  </div>
                </div>
                <div className='p-4 xl:ml-5 w-full'>
                  <nav class="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1">
                    <div class="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
                      <div class="capitalize">
                        <nav aria-label="breadcrumb" class="w-max">
                          <ol class="flex flex-wrap items-center w-full bg-opacity-60 rounded-md bg-transparent p-0 transition-all">
                            <li class="flex items-center text-blue-gray-900 antialiased font-sans text-sm font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-light-blue-500">
                              <Link to="/Account">
                                <p class="block antialiased font-sans text-sm leading-normal text-blue-900 font-normal opacity-50 transition-all hover:text-blue-500 hover:opacity-100">Account</p>
                              </Link>
                              <span class="text-gray-500 text-sm antialiased font-sans font-normal leading-normal mx-2 pointer-events-none select-none">/</span>
                            </li>
                            <li class="flex items-center text-blue-900 antialiased font-sans text-sm font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500">
                              <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">{breadcrumbDic.length != 0 ? breadcrumbDic[0] : "Dashboard"}</p>
                            </li>
                          </ol>
                        </nav>
                        <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-900">{breadcrumbDic.length != 0 ? breadcrumbDic[0] : "Dashboard"}</h6>
                      </div>
                      {/* <div class="flex items-center">
                        <div class="mr-auto md:mr-4 md:w-56">
                          <div class="relative w-full min-w-[200px] h-10">
                            <input class="peer w-full h-full bg-transparent text-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-blue-500" placeholder=" " />
                            <label class="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal peer-placeholder-shown:text-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-blue-500 before:border-blue-gray-200 peer-focus:before:border-blue-500 after:border-blue-gray-200 peer-focus:after:border-blue-500">Type here</label>
                          </div>
                        </div>
                        <button class="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 grid xl:hidden" type="button">
                          <span class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" stroke-width="3" class="h-6 w-6 text-blue-gray-500">
                              <path fill-rule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z" clip-rule="evenodd"></path>
                            </svg>
                          </span>
                        </button>
                        <a href="#">
                          <button class="middle none font-sans font-bold center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 hidden items-center gap-1 px-4 xl:flex" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="h-5 w-5 text-blue-gray-500">
                              <path fill-rule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clip-rule="evenodd"></path>
                            </svg>Sign In </button>
                          <button class="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 grid xl:hidden" type="button">
                            <span class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="h-5 w-5 text-blue-gray-500">
                                <path fill-rule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clip-rule="evenodd"></path>
                              </svg>
                            </span>
                          </button>
                        </a>
                        <button class="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30" type="button">
                          <span class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="h-5 w-5 text-blue-gray-500">
                              <path fill-rule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clip-rule="evenodd"></path>
                            </svg>
                          </span>
                        </button>
                        <button aria-expanded="false" aria-haspopup="menu" id=":r2:" class="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30" type="button">
                          <span class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="h-5 w-5 text-blue-gray-500">
                              <path fill-rule="evenodd" d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clip-rule="evenodd"></path>
                            </svg>
                          </span>
                        </button>
                      </div> */}
                    </div>
                  </nav>

                  {dashboardClick ?
                    <div class="mt-12">
                      <div class="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4" >
                        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md cursor-pointer" onClick={() => ClickOnAccount()}>
                          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 h-6 text-white">
                              <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"></path>
                              <path fill-rule="evenodd" d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z" clip-rule="evenodd"></path>
                              <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z"></path>
                            </svg>
                          </div>
                          <div class="p-4 text-right">
                            <p class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">Credits</p>
                            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{currentUserInfo.credit}</h4>
                          </div>

                        </div>
                        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md cursor-pointer" onClick={() => ClickOnPendingMessage()}>
                          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-pink-600 to-pink-400 text-white shadow-pink-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 h-6 text-white">
                              <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clip-rule="evenodd"></path>
                            </svg>
                          </div>
                          <div class="p-4 text-right">
                            <p class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">Inbox</p>
                            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{unReadMessagePendingCount}</h4>
                          </div>
                          {/* <div class="border-t border-blue-gray-50 p-4">
                          <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                            <strong class="text-green-500">+3%</strong>&nbsp;than last month
                          </p>
                        </div> */}
                        </div>
                        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md cursor-pointer" onClick={() => ClickOnMessage()}>
                          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-green-600 to-green-400 text-white shadow-green-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 h-6 text-white">
                              <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z"></path>
                            </svg>
                          </div>
                          <div class="p-4 text-right">
                            <p class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">Chat Message</p>
                            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{unReadMessageCount}</h4>
                          </div>
                          {/* <div class="border-t border-blue-gray-50 p-4">
                          <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                            <strong class="text-red-500">-2%</strong>&nbsp;than yesterday
                          </p>
                        </div> */}
                        </div>
                        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md cursor-pointer" onClick={() => ClickOnAccount()}>
                          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-orange-600 to-orange-400 text-white shadow-orange-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="w-6 h-6 text-white">
                              <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"></path>
                            </svg>
                          </div>
                          <div class="p-4 text-right">
                            <p class="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">Points</p>
                            <h4 class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">{currentUserInfo.points}</h4>
                          </div>
                          <div class="border-t border-blue-gray-50 p-4">
                            <p class="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                              Expired on: {expiredTime()}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="mb-4 grid grid-cols-1 gap-6">
                        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden xl:col-span-2">
                          {/* <div class="relative bg-clip-border rounded-xl overflow-hidden bg-transparent text-gray-700 shadow-none m-0 flex items-center justify-between p-6">
                          <div>
                            <h6 class="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-blue-gray-900 mb-1">Projects</h6>
                            <p class="antialiased font-sans text-sm leading-normal flex items-center gap-1 font-normal text-blue-gray-600">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" aria-hidden="true" class="h-4 w-4 text-blue-500">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                              </svg>
                              <strong>30 done</strong> this month
                            </p>
                          </div>
                          <button aria-expanded="false" aria-haspopup="menu" id=":r5:" class="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-8 max-w-[32px] h-8 max-h-[32px] rounded-lg text-xs text-blue-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30" type="button">
                            <span class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="currenColor" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" aria-hidden="true" class="h-6 w-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"></path>
                              </svg>
                            </span>
                          </button>
                        </div> */}
                          <div id="dashboardAccountLowerPart">
                            <div className='dashboardListingDiv'>
                              <div style={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
                                {dashboardViewUserType != null ? dashboardViewUserType != "Advertiser" ?

                                  <select value={dashboardViewUserTypeAccountView} onChange={handleDashboardViewUserTypeAccountView} style={{ padding: "5px 5px", borderRadius: "10px", color: "black", border: "1px solid #c0c0c0" }}>
                                    <option value={"Commercial Rent"}>Commercial Rent</option>
                                    <option value={"Commercial Sales"}>Commercial Sales</option>
                                    <option value={"Residential Rent"}>Residential Rent</option>
                                    <option value={"Residential Sales"}>Residential Sales</option>
                                  </select>
                                  : null : null}
                                {dashboardViewUserType != null ? dashboardViewUserType != "Agent" ?
                                  <select value={dashboardViewUserType} onChange={handleChangesDashboardViewUserType} style={{ padding: "5px 5px", borderRadius: "10px", color: "black", border: "1px solid #c0c0c0" }}>
                                    <option value={"Tenant"}>Tenant</option>
                                    <option value={"Landlord"}>Landlord</option>
                                    <option value={"Advertiser"}>Advertiser</option>
                                  </select> : null : null}
                              </div>
                              {dashboardViewUserType == "Landlord" || dashboardViewUserType == "Agent" || dashboardViewUserType == "Master Tenant" ?
                                <>
                                  <p className='dashboardListingTitle'>New Listing Within
                                    <select className='dashboardListingDropdown' value={selectedOption} onChange={handleSelectChange}>
                                      {dashboardViewUserTypeAccountView != null ?
                                        dashboardViewUserTypeAccountView.search("Commercial") >= 0 ?
                                          currentUserInfo.hasOwnProperty('propertyAddressList') == true ? currentUserInfo.propertyAddressList.map((indivOption) => {
                                            return (
                                              <option>{indivOption.district}</option>
                                            )
                                          })
                                            :
                                            null
                                          :
                                          currentUserInfo.hasOwnProperty('propertyAddressListResidential') == true ? currentUserInfo.propertyAddressListResidential.map((indivOption) => {
                                            return (
                                              <option>{indivOption.district}</option>
                                            )
                                          }) : null : null
                                      }
                                    </select>
                                  </p>
                                  <div>
                                    <canvas ref={chartContainer} />
                                    <div style={{ clear: 'both' }}></div>
                                  </div>
                                  <div className='dashboardListingNewListing'>
                                    <p className='dashboardNewListingNumber'>{totalListingDistrict}</p>
                                    <p className='dashboardNewListingSubTitle'>No. of New Listings</p>
                                  </div>
                                  <div className='dashboardConnectionNewListing'>
                                    <p className='dashboardNewListingNumber'>{currentUserInfo.connectionsNumber}</p>
                                    <p className='dashboardNewListingSubTitle'>No. of Connections</p>
                                  </div>
                                  <div style={{ clear: 'both' }}></div>
                                </> :
                                dashboardViewUserType == "Tenant" ?
                                  <>
                                    <p className='dashboardListingTitle'>Insight Of Your Listings</p>
                                    <div>
                                      <canvas ref={chartContainer} />
                                      <div style={{ clear: 'both' }}></div>
                                    </div>
                                    <div className='dashboardListingNewListing'>
                                      <p className='dashboardNewListingNumber'>{totalListingEyeball}</p>
                                      <p className='dashboardNewListingSubTitle'>No. of Views</p>
                                    </div>
                                    <div className='dashboardConnectionNewListing'>
                                      <p className='dashboardNewListingNumber'>{currentUserInfo.connectionsNumber}</p>
                                      <p className='dashboardNewListingSubTitle'>No. of Connections</p>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                  </> :
                                  <>
                                    <p className='dashboardListingTitle'>Insight Of Your Advertisement</p>
                                    <div>
                                      <canvas ref={chartContainer} />
                                      <div style={{ clear: 'both' }}></div>
                                    </div>
                                    <div className='dashboardListingNewListing'>
                                      <p className='dashboardNewListingNumber'>{totalListingEyeball}</p>
                                      <p className='dashboardNewListingSubTitle'>No. of Views</p>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                  </>

                              }
                            </div>
                            <div id="dashboardAccountLowerPart2" >
                              {dashboardViewUserType == "Landlord" || dashboardViewUserType == "Agent" || dashboardViewUserType == "Master Tenant" ?
                                dashboardViewUserTypeAccountView == "Commercial Rent" ?
                                  <>
                                    <div style={{ clear: 'both' }}></div>
                                    <div className='dashboardNewListingDiv'>
                                      <p style={{ marginBottom: "1%", fontWeight: "bold" }}>Latest Information On Commercial Rent</p>
                                      <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", width: "100%", padding: "8px 8px", borderRadius: "10px", marginBottom: "3%" }}>
                                        <i class="fa-solid fa-circle-info" id="notificationIcon"></i>
                                        {/* <i class=""></i> */}
                                        <div style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "95%", alignItems: "center" }}>
                                          {/* <p style={{ fontWeight: "bold" }}></p> */}
                                          <p className='dashboardComment'>There is {totalListingDistrict} new listings within {selectedOption}</p>
                                          <button style={{ width: "10%", border: "none", backgroundColor: "transparent", color: "black" }}><Link to={`/commercialRent`} style={{ textDecoration: "none" }}>View</Link></button>
                                        </div>
                                      </div>
                                      <div style={{ clear: 'both' }}></div>
                                    </div>
                                  </>
                                  :
                                  dashboardViewUserTypeAccountView == "Commercial Sales" ?
                                    <>
                                      <div style={{ clear: 'both' }}></div>
                                      <div className='dashboardNewListingDiv'>
                                        <p style={{ marginBottom: "1%", fontWeight: "bold" }}>Latest Information On Commercial Sales</p>
                                        <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", width: "100%", padding: "8px 8px", borderRadius: "10px", marginBottom: "3%" }}>
                                          <i class="fa-solid fa-circle-info" id="notificationIcon"></i>
                                          {/* <i class=""></i> */}
                                          <div style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "95%", alignItems: "center" }}>
                                            {/* <p style={{ fontWeight: "bold" }}></p> */}
                                            <p className='dashboardComment'>There is {totalListingDistrict} new listings within {selectedOption}</p>
                                            <button style={{ width: "10%", border: "none", backgroundColor: "transparent", color: "black" }}><Link to={`/commercialPurchase`} style={{ textDecoration: "none" }}>View</Link></button>
                                          </div>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                      </div>
                                    </>
                                    :
                                    dashboardViewUserTypeAccountView == "Residential Rent" ?
                                      <>
                                        <div style={{ clear: 'both' }}></div>
                                        <div className='dashboardNewListingDiv'>
                                          <p style={{ marginBottom: "1%", fontWeight: "bold" }}>Latest Information On Residential Rent</p>
                                          <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", width: "100%", padding: "8px 8px", borderRadius: "10px", marginBottom: "3%" }}>
                                            <i class="fa-solid fa-circle-info" id="notificationIcon"></i>
                                            {/* <i class=""></i> */}
                                            <div style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "95%", alignItems: "center" }}>
                                              {/* <p style={{ fontWeight: "bold" }}></p> */}
                                              <p className='dashboardComment'>There is {totalListingDistrict} new listings within {selectedOption}</p>
                                              <button style={{ width: "10%", border: "none", backgroundColor: "transparent", color: "black" }}><Link to={`/residentialRent`} style={{ textDecoration: "none" }}>View</Link></button>
                                            </div>
                                          </div>
                                          <div style={{ clear: 'both' }}></div>
                                        </div>
                                      </> :
                                      dashboardViewUserTypeAccountView == "Residential Sales" ?
                                        <>
                                          <div style={{ clear: 'both' }}></div>
                                          <div className='dashboardNewListingDiv'>
                                            <p style={{ marginBottom: "1%", fontWeight: "bold" }}>Latest Information On Residential Sales</p>
                                            <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", width: "100%", padding: "8px 8px", borderRadius: "10px", marginBottom: "3%" }}>
                                              <i class="fa-solid fa-circle-info" id="notificationIcon"></i>
                                              {/* <i class=""></i> */}
                                              <div style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "95%", alignItems: "center" }}>
                                                {/* <p style={{ fontWeight: "bold" }}></p> */}
                                                <p className='dashboardComment'>There is {totalListingDistrict} new listings within {selectedOption}</p>
                                                <button style={{ width: "10%", border: "none", backgroundColor: "transparent", color: "black" }}><Link to={`/residentialPurchase`} style={{ textDecoration: "none" }}>View</Link></button>
                                              </div>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                          </div>
                                        </> :

                                        null :
                                dashboardViewUserType == "Tenant" ?
                                  dashboardViewUserTypeAccountView == "Commercial Rent" ?
                                    <>
                                      <div style={{ clear: 'both' }}></div>
                                      {tenantDistrict != null ? <>
                                        <div className='dashboardNewListingDiv'>
                                          <p style={{ marginBottom: "1%", fontWeight: "bold" }}>Latest Information On Commercial Rent</p>
                                          <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", width: "100%", padding: "8px 8px", borderRadius: "10px" }}>
                                            <i class="fa-regular fa-bell" id="notificationIcon" ></i>
                                            {/* <i class=""></i> */}
                                            <div style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "95%", alignItems: "center" }}>
                                              {/* <p style={{ fontWeight: "bold" }}></p> */}
                                              <p className='dashboardComment'>There is {tenantDistrictNum} new listings within {tenantDistrict}</p>
                                              <button style={{ width: "10%", border: "none", backgroundColor: "transparent", color: "black" }}><Link to={`/commercialRent`} style={{ textDecoration: "none" }}>View</Link></button>
                                            </div>
                                          </div>
                                          {/* <p className='dashboardNewListingStatement'></p> */}
                                          {/* <button className='dashboardNewListingBtn'><Link to={`/listing`} style={{ textDecoration: "none", color: "#8CD1C7" }}>View</Link></button> */}

                                          <div style={{ clear: 'both' }}></div>
                                        </div>
                                      </> : null}

                                      {userProject.length != 0 && dashboardViewUserType == "Tenant" ? <>
                                        <p style={{ marginBottom: "1%", marginTop: "3%", fontWeight: "bold" }}>Information On Your Commercial Rent</p>
                                        <select className='dashboardTenantListingDropDown' value={dashboardTenantListing} onChange={handleChangesDashboardTenantListing}>
                                          {userProject.map((res) => {
                                            return res.details.status === "leasing" ? (
                                              <option value={res.details.listingName}>{res.details.listingName}</option>
                                            ) : null;
                                          })}
                                        </select>


                                        <div className='dashboardNewListingDiv'>
                                          <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", width: "100%", padding: "8px 8px", borderRadius: "10px", marginBottom: "3%" }}>
                                            <i class="fa-solid fa-circle-info" id="notificationIcon"></i>
                                            {/* <i class=""></i> */}
                                            <div style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "95%", alignItems: "center" }}>
                                              {/* <p style={{ fontWeight: "bold" }}></p> */}
                                              {
                                                CANResLevel == "Top" ? <>
                                                  <p className='dashboardComment'>Your listing {dashboardTenantListing} is doing great!</p>
                                                </>
                                                  :
                                                  CANResLevel == "Mid" ? <>
                                                    <p className='dashboardComment'>Try to add in more details and be sure to add in your unique selling point<br></br>Try rephrasing the title of your listing to attract more viewers</p>
                                                  </> :
                                                    CANResLevel == "Bottom" ? <>
                                                      <p className='dashboardComment'>To boost your number of connections, try filling in all fields.<br></br>To increase your number of views, try adding more images.</p>
                                                    </>
                                                      : null

                                              }
                                              <button style={{ width: "10%", border: "none", backgroundColor: "transparent", color: "black" }} onClick={() => ClickOnListing()}>View</button>
                                            </div>
                                          </div>

                                          <div style={{ clear: 'both' }}></div>
                                        </div> </> : null}
                                    </>
                                    :
                                    dashboardViewUserTypeAccountView == "Commercial Sales" ?
                                      <>
                                        <div style={{ clear: 'both' }}></div>
                                        {tenantDistrict != null ? <>
                                          <div className='dashboardNewListingDiv'>
                                            <p style={{ marginBottom: "1%", fontWeight: "bold" }}>Latest Information On Commercial Sales</p>
                                            <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", width: "100%", padding: "8px 8px", borderRadius: "10px" }}>
                                              <i class="fa-regular fa-bell" id="notificationIcon"></i>
                                              {/* <i class=""></i> */}
                                              <div style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "95%", alignItems: "center" }}>
                                                {/* <p style={{ fontWeight: "bold" }}></p> */}
                                                <p className='dashboardComment'>There is {tenantDistrictNum} new listings within {tenantDistrict}</p>
                                                <button style={{ width: "10%", border: "none", backgroundColor: "transparent", color: "black" }}><Link to={`/commercialPurchase`} style={{ textDecoration: "none" }}>View</Link></button>
                                              </div>
                                            </div>
                                            {/* <p className='dashboardNewListingStatement'></p> */}
                                            {/* <button className='dashboardNewListingBtn'><Link to={`/listing`} style={{ textDecoration: "none", color: "#8CD1C7" }}>View</Link></button> */}

                                            <div style={{ clear: 'both' }}></div>
                                          </div>
                                        </> : null}

                                        {commercialListingPurcahse.length != 0 && dashboardViewUserType == "Tenant" ? <>
                                          <p style={{ marginBottom: "1%", marginTop: "3%", fontWeight: "bold" }}>Information On Your Commercial Sales</p>
                                          <select className='dashboardTenantListingDropDown' value={dashboardTenantListing} onChange={handleChangesDashboardTenantListing}>
                                            {commercialListingPurcahse.map((res) => {
                                              return res.details.status === "leasing" ? (
                                                <option value={res.details.listingName}>{res.details.listingName}</option>
                                              ) : null;

                                            })}
                                          </select>

                                          <div className='dashboardNewListingDiv'>
                                            <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", width: "100%", padding: "8px 8px", borderRadius: "10px", marginBottom: "3%" }}>
                                              <i class="fa-solid fa-circle-info" id="notificationIcon"></i>
                                              {/* <i class=""></i> */}
                                              <div style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "95%", alignItems: "center" }}>
                                                {/* <p style={{ fontWeight: "bold" }}></p> */}
                                                {
                                                  CANResLevel == "Top" ? <>
                                                    <p className='dashboardComment'>Your listing {dashboardTenantListing} is doing great!</p>
                                                  </>
                                                    :
                                                    CANResLevel == "Mid" ? <>
                                                      <p className='dashboardComment'>Try to add in more details and be sure to add in your unique selling point<br></br>Try rephrasing the title of your listing to attract more viewers</p>
                                                    </> :
                                                      CANResLevel == "Bottom" ? <>
                                                        <p className='dashboardComment'>To boost your number of connections, try filling in all fields.<br></br>To increase your number of views, try adding more images.</p>
                                                      </>
                                                        : null

                                                }
                                                <button style={{ width: "10%", border: "none", backgroundColor: "transparent", color: "black" }} onClick={() => ClickOnPurchase()}>View</button>
                                              </div>
                                            </div>

                                            <div style={{ clear: 'both' }}></div>
                                          </div> </> : null}
                                      </>
                                      :
                                      dashboardViewUserTypeAccountView == "Residential Rent" ?
                                        <>
                                          <div style={{ clear: 'both' }}></div>
                                          {tenantDistrict != null ? <>
                                            <div className='dashboardNewListingDiv'>
                                              <p style={{ marginBottom: "1%", fontWeight: "bold" }}>Latest Information On Residential Rent</p>
                                              <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", width: "100%", padding: "8px 8px", borderRadius: "10px" }}>
                                                <i class="fa-regular fa-bell" id="notificationIcon"></i>
                                                {/* <i class=""></i> */}
                                                <div style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "95%", alignItems: "center" }}>
                                                  {/* <p style={{ fontWeight: "bold" }}></p> */}
                                                  <p className='dashboardComment'>There is {tenantDistrictNum} new listings within {tenantDistrict}</p>
                                                  <button style={{ width: "10%", border: "none", backgroundColor: "transparent", color: "black" }}><Link to={`/residentialRent`} style={{ textDecoration: "none" }}>View</Link></button>
                                                </div>
                                              </div>
                                              {/* <p className='dashboardNewListingStatement'></p> */}
                                              {/* <button className='dashboardNewListingBtn'><Link to={`/listing`} style={{ textDecoration: "none", color: "#8CD1C7" }}>View</Link></button> */}

                                              <div style={{ clear: 'both' }}></div>
                                            </div>
                                          </> : null}

                                          {userResidentialListing.length != 0 && dashboardViewUserType == "Tenant" ? <>
                                            <p style={{ marginBottom: "1%", marginTop: "3%", fontWeight: "bold" }}>Information On Your Residential Rent</p>
                                            <select className='dashboardTenantListingDropDown' value={dashboardTenantListing} onChange={handleChangesDashboardTenantListing}>
                                              {userResidentialListing.map((res) => {
                                                return res.details.status === "leasing" ? (
                                                  <option value={res.details.listingName}>{res.details.listingName}</option>
                                                ) : null;
                                              })}
                                            </select>

                                            <div className='dashboardNewListingDiv'>
                                              <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", width: "100%", padding: "8px 8px", borderRadius: "10px", marginBottom: "3%" }}>
                                                <i class="fa-solid fa-circle-info" id="notificationIcon"></i>
                                                {/* <i class=""></i> */}
                                                <div style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "95%", alignItems: "center" }}>
                                                  {/* <p style={{ fontWeight: "bold" }}></p> */}
                                                  {
                                                    CANResLevel == "Top" ? <>
                                                      <p className='dashboardComment'>Your listing {dashboardTenantListing} is doing great!</p>
                                                    </>
                                                      :
                                                      CANResLevel == "Mid" ? <>
                                                        <p className='dashboardComment'>Try to add in more details and be sure to add in your unique selling point<br></br>Try rephrasing the title of your listing to attract more viewers</p>
                                                      </> :
                                                        CANResLevel == "Bottom" ? <>
                                                          <p className='dashboardComment'>To boost your number of connections, try filling in all fields.<br></br>To increase your number of views, try adding more images.</p>
                                                        </>
                                                          : null

                                                  }
                                                  <button style={{ width: "10%", border: "none", backgroundColor: "transparent", color: "black" }} onClick={() => ClickOnListing()}>View</button>
                                                </div>
                                              </div>

                                              <div style={{ clear: 'both' }}></div>
                                            </div> </> : null}
                                        </>
                                        :
                                        dashboardViewUserTypeAccountView == "Residential Sales" ?
                                          <>
                                            <div style={{ clear: 'both' }}></div>
                                            {tenantDistrict != null ? <>
                                              <div className='dashboardNewListingDiv'>
                                                <p style={{ marginBottom: "1%", fontWeight: "bold" }}>Latest Information On Residential Sales</p>
                                                <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", width: "100%", padding: "8px 8px", borderRadius: "10px" }}>
                                                  <i class="fa-regular fa-bell" id="notificationIcon"></i>
                                                  {/* <i class=""></i> */}
                                                  <div style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "95%", alignItems: "center" }}>
                                                    {/* <p style={{ fontWeight: "bold" }}></p> */}
                                                    <p className='dashboardComment'>There is {tenantDistrictNum} new listings within {tenantDistrict}</p>
                                                    <button style={{ width: "10%", border: "none", backgroundColor: "transparent", color: "black" }}><Link to={`/residentialPurchase`} style={{ textDecoration: "none" }}>View</Link></button>
                                                  </div>
                                                </div>
                                                {/* <p className='dashboardNewListingStatement'></p> */}
                                                {/* <button className='dashboardNewListingBtn'><Link to={`/listing`} style={{ textDecoration: "none", color: "#8CD1C7" }}>View</Link></button> */}

                                                <div style={{ clear: 'both' }}></div>
                                              </div>
                                            </> : null}

                                            {residentialListingPurchase.length != 0 && dashboardViewUserType == "Tenant" ? <>
                                              <p style={{ marginBottom: "1%", marginTop: "3%", fontWeight: "bold" }}>Information On Your Residential Sales</p>
                                              <select className='dashboardTenantListingDropDown' value={dashboardTenantListing} onChange={handleChangesDashboardTenantListing}>
                                                {residentialListingPurchase.map((res) => {
                                                  return res.details.status === "leasing" ? (
                                                    <option value={res.details.listingName}>{res.details.listingName}</option>
                                                  ) : null;
                                                })}
                                              </select>

                                              <div className='dashboardNewListingDiv'>
                                                <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", width: "100%", padding: "8px 8px", borderRadius: "10px", marginBottom: "3%" }}>
                                                  <i class="fa-solid fa-circle-info" id="notificationIcon"></i>
                                                  {/* <i class=""></i> */}
                                                  <div style={{ display: "flex", textAlign: "left", flexDirection: "row", width: "95%", alignItems: "center" }}>
                                                    {/* <p style={{ fontWeight: "bold" }}></p> */}
                                                    {
                                                      CANResLevel == "Top" ? <>
                                                        <p className='dashboardComment' >Your listing {dashboardTenantListing} is doing great!</p>
                                                      </>
                                                        :
                                                        CANResLevel == "Mid" ? <>
                                                          <p className='dashboardComment'>Try to add in more details and be sure to add in your unique selling point<br></br>Try rephrasing the title of your listing to attract more viewers</p>
                                                        </> :
                                                          CANResLevel == "Bottom" ? <>
                                                            <p className='dashboardComment'>To boost your number of connections, try filling in all fields.<br></br>To increase your number of views, try adding more images.</p>
                                                          </>
                                                            : null

                                                    }
                                                    <button style={{ width: "10%", border: "none", backgroundColor: "transparent", color: "black" }} onClick={() => ClickOnPurchase()}>View</button>
                                                  </div>
                                                </div>

                                                <div style={{ clear: 'both' }}></div>
                                              </div> </> : null}
                                          </>
                                          :
                                          null :
                                  <>
                                    <p style={{ marginBottom: "1%", marginTop: "3%", fontWeight: "bold" }}>Information On Your Advertisement</p>
                                    <select className='dashboardTenantListingDropDown' value={advertiseDashboardName} onChange={handleAdvertiseDashboardNameChange}>
                                      <option value={"All"}>All</option>
                                      {advListing.map((res) => {
                                        return res.details.status === "approved" || res.details.status === "leasing" ? (
                                          <option value={res.details.advertiseName}>{res.details.advertiseName}</option>
                                        ) : null;
                                      })}
                                    </select>
                                  </>

                              }
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                    : null}
                  {listingClick ?
                    <>
                      {/* <ReactNotifications /> */}
                      <div id="listingTab">
                        <div className="toggleDivAccount">
                          <button className={`toggleBtnAccount ${isCommercial ? 'active' : ''}`} onClick={handleCommercial} >
                            Commercial
                          </button>
                          <button className={`toggleBtnAccount ${!isCommercial ? 'active' : ''}`} onClick={handleCommercial}>
                            Residential
                          </button>
                        </div>
                        {/* <div className={`toggleBtnAccountListingView ${isCommercial ? 'toggled' : ''}`} style={{ marginTop: "1%", marginLeft: "1%" }} onClick={handleCommercial}>
                        <div className="circle"></div>
                        <span style={isCommercial == false ? { marginLeft: "30px", fontSize: '14px' } : { marginRight: "30px", fontSize: '14px' }}>{isCommercial ? 'Commercial' : 'Residential'}</span>
                      </div> */}
                        <button className='account-btnNormal' style={{ marginRight: "1%", marginTop: "1%", float: 'right' }} onClick={() => setClick(true)} >Looking For A Space</button>
                        <button className='account-btnNormal' onClick={() => setClickBoostAll(true)} style={{ marginRight: "1%", marginTop: "1%", float: 'right' }}><i class="fa-solid fa-bolt" ></i> Boost All</button>

                        <BoostAll trigger={clickBoostAll} setTrigger={setClickBoostAll} userProject={userProject} setUserProject={setUserProject} commercialRentAvailableNumber={commercialRentAvailableNumber} userResidentialRentAvailableNumber={userResidentialRentAvailableNumber} accountView={accountListingView} userResidentialListing={userResidentialListing} setUserResidentialListing={setUserResidentialListing} currentUserInfo={currentUserInfo} setCurrentUserInfo={setCurrentUserInfo} />
                        <BoostIndiv trigger={clickBoostIndiv} setTrigger={setClickBoostIndiv} id={boostIndivID} userProject={userProject} setUserProject={setUserProject} accountView={accountListingView} userResidentialListing={userResidentialListing} setUserResidentialListing={setUserResidentialListing} currentUserInfo={currentUserInfo} setCurrentUserInfo={setCurrentUserInfo} />
                        <div style={{ clear: 'both' }}></div>
                      </div>


                      <div style={{ clear: 'both' }}></div>
                      <div id="listingContent">
                        {
                          accountListingView == "Commercial" ?
                            userProject != null && userProject.map((project, index) => {
                              return (
                                <div class="CardAccount" style={{ border: "1px solid #c1c1c1", borderRadius: "10px", position: "relative" }}>
                                  <div style={{ position: 'relative' }}>

                                    <Carousel>
                                      {[0, 1, 2, 3].map((index) => {
                                        const imageUrl = project.details[`url${index}`];
                                        if (imageUrl) {
                                          return (
                                            <div key={index}>
                                              <img id="Card_image" src={imageUrl} alt={`Picture ${index}`} />
                                            </div>
                                          );
                                        } else {
                                          return null; // Render nothing if there's no image for the index
                                        }
                                      }).filter(child => child !== null)}

                                    </Carousel>
                                    <div id="preferredBackground">
                                      <div id="informationPreferredBackground">
                                        {project.details.preferredListing == true ? <p id="Card_preferred">Preferred Listing</p> : null}
                                      </div>
                                    </div>



                                  </div>
                                  <Link to={project.details.status == "leasing" ? "/commercialRentDetails/" + project.id : null}>
                                    <div class="CardContainer">
                                      <div style={{ borderBottom: "1px solid #c1c1c1", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px" }}>
                                        {project.details.propertyAgent == true ? <p style={{ color: "#4d4c4c" }}>AGENT</p> : <p style={{ color: "#4d4c4c" }}>DIRECT TENANT</p>}
                                        <p id="Card_district" style={{ paddingTop: "5px" }}>{project.details.district.toUpperCase()}</p>

                                        {/* {project.details.preferredListing == true ? <p id="Card_preferred">Preferred Listing</p> : null} */}
                                        <p id="Card_price">${project.details.priceOverall.toLocaleString()}</p>

                                        <p id="monthlyRentalBudget">Monthly Rental Budget</p>

                                      </div>
                                      {/* {project.details.preferredListing == true ?<p id="Card_preferred">Preferred Listing</p>:null} */}


                                      <div id="Card_overall">

                                        <p style={{ color: "#4d4c4c", paddingLeft: "5px" }}>Business Type</p>
                                        <p id="Card_type" style={{ paddingLeft: "5px", fontWeight: "bold" }}>{project.details.propertyType}</p>

                                        {/* <p style={{ float: "left" }}>Monthly PSF Budget:</p><p style={{ float: "right", paddingRight: "10px", color: "black" }}>${project.details.pricePerSquareFeet}</p> */}
                                        {/* <div style={{ clear: 'both' }}></div> */}
                                      </div>
                                    </div>
                                  </Link>
                                  <div style={{ paddingTop: "5px" }}>
                                    {project.details.boost == true ? <>
                                      <div className="icon-tooltip">
                                        <i style={{ float: "right", marginTop: "3%", marginRight: "5%", color: "#c8c803" }} class="fa-solid fa-bolt"></i>
                                        <div className="tooltip-content">
                                          <p>Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                          })}</p>
                                          <p>Boost Left: {project.details.boostLeft}</p>
                                        </div>
                                      </div>
                                    </> :
                                      project.details.boostStart != null ? <>
                                        <div className="icon-tooltip">
                                          <i style={{ float: "right", marginTop: "3%", marginRight: "5%" }} class="fa-solid fa-bolt"></i>
                                          <div className="tooltip-content">
                                            <p>Upcoming Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                              day: '2-digit',
                                              month: '2-digit',
                                              year: 'numeric',
                                            })}</p>
                                            <p>Boost Left: {project.details.boostLeft}</p>
                                          </div>
                                        </div>
                                      </> : null}
                                    {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => ModifyListing(project.id, "approved")} style={{ marginRight: "2%" }}>Modify</button> : null}
                                    {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => IndivBoost(project.id, project.details.listingName, 'rent')}><i class="fa-solid fa-bolt"></i> Boost</button> : null}
                                    {project.details.status == "pending" ? <button className='account-btnInsideCard'>Pending</button> : null}
                                    {project.details.status == "rejected" ? <><button className='account-btnInsideCard' onClick={() => ModifyListing(project.id, "rejected")} style={{ marginRight: "2%" }}>Modify</button>
                                      <button className='account-btnInsideCard' onClick={(() => { setAcknowledgeNormal(true); setAcknowledgeMessage(project.details.rejectedReason) })}>Rejected Review</button></> : null}
                                    {project.details.status == "Closed" || (listingClosed.includes(project.id)) ? <p className='account-btnInsideCard' style={{ cursor: "default", textAlign: "center" }}>Listing Closed</p> : null}
                                    <div style={{ clear: 'both' }}></div>
                                  </div>
                                  {/* <Link to={project.details.status == "leasing" ? "/commercialRentDetails/" + project.id : null}>
                                  <div style={{ position: "relative" }}>
                                    {project.details.propertyAgent == true ? <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#33434dbf', color: '#fff', padding: '3%', borderRadius: '4px', marginTop: "3%", marginRight: "3%", fontSize: "13px" }}>
                                      Agent
                                    </div> : null}
                                    <div id="preferredBackground">
                                      <div id="informationPreferredBackground">
                                        {project.details.preferredListing == true ? <p id="Card_preferred">Preferred Listing</p> : null}
                                      </div>
                                    </div>
                                    {project.details.status == "Closed" || (listingClosed.includes(project.id)) ?
                                      <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                                        <img id="Card_image" src={project.details.url0.search("https://ik.imagekit.io/NextLoop") >= 0 ? (project.details.url0).replaceAll("tr:h-200,w-300", "tr:h-190,w-280") : (project.details.url0).replaceAll("https://firebasestorage.googleapis.com", "https://ik.imagekit.io/NextLoop/tr:h-190,w-280")}></img>
                                        <div id="closedOverlayImg"></div>

                                      </div> :
                                      <img id="Card_image" src={project.details.url0.search("https://ik.imagekit.io/NextLoop") >= 0 ? (project.details.url0).replaceAll("tr:h-200,w-300", "tr:h-190,w-280") : (project.details.url0).replaceAll("https://firebasestorage.googleapis.com", "https://ik.imagekit.io/NextLoop/tr:h-190,w-280")}></img>}
                                    <div id="gradientBackground">
                                      <div id="informationGradientBackground">
                                        <div id='informationGradientBackgroundLeft'>
                                          <i class="fa fa-eye"></i> {project.details.eyeball}
                                          <i class="far fa-comment-dots"></i> {project.details.connections}
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <img id="Card_image" loading='lazy' src={(project.details.url0).replaceAll("https://firebasestorage.googleapis.com", "https://ik.imagekit.io/NextLoop/tr:h-200,w-300")} /> */}
                                  {/* <div class="CardContainer">
                                    <p id="Card_type" style={{ float: "left" }}>Trade: {project.details.propertyType}</p>
                                    {project.details.boost == true ? <>
                                      <div className="icon-tooltip">
                                        <i style={{ float: "right", marginTop: "3%", marginRight: "5%", color: "#c8c803" }} class="fa-solid fa-bolt"></i>
                                        <div className="tooltip-content">
                                          <p>Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                          })}</p>
                                          <p>Boost Left: {project.details.boostLeft}</p>
                                        </div>
                                      </div>
                                    </> :
                                      project.details.boostStart != null ? <>
                                        <div className="icon-tooltip">
                                          <i style={{ float: "right", marginTop: "3%", marginRight: "5%" }} class="fa-solid fa-bolt"></i>
                                          <div className="tooltip-content">
                                            <p>Upcoming Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                              day: '2-digit',
                                              month: '2-digit',
                                              year: 'numeric',
                                            })}</p>
                                            <p>Boost Left: {project.details.boostLeft}</p>
                                          </div>
                                        </div>
                                      </> : null}
                                    <div style={{ clear: 'both' }}></div> */}
                                  {/* {project.details.preferredListing == true ? <p id="Card_preferred">Preferred Listing</p> : null} */}
                                  {/* <div style={{ clear: 'both' }}></div>
                                    <p id="Card_district" style={project.details.status == "Closed" || (listingClosed.includes(project.id)) ? { color: "grey" } : null}><h4>{project.details.district}</h4></p>
                                    <div id="Card_overall">
                                      <p style={{ float: "left" }}>Monthly Rental Budget:</p><p style={project.details.status == "Closed" || (listingClosed.includes(project.id)) ? { float: "right", color: "grey" } : { float: "right", color: "black" }}>${project.details.priceOverall.toLocaleString()}</p>
                                      <div style={{ clear: 'both' }}></div>
                                      <p style={{ float: "left" }}>Monthly PSF Budget:</p><p style={project.details.status == "Closed" || (listingClosed.includes(project.id)) ? { float: "right", color: "grey" } : { float: "right", color: "black" }}  >${project.details.pricePerSquareFeet.toLocaleString()}</p>
                                      <div style={{ clear: 'both' }}></div>
                                    </div>


                                  </div>
                                </Link>
                                <div style={{ clear: 'both' }}></div>
                                {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => ModifyListing(project.id, "approved")} style={{ marginRight: "2%" }}>Modify</button> : null}
                                {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => IndivBoost(project.id, project.details.listingName, 'rent')}><i class="fa-solid fa-bolt"></i> Boost</button> : null}
                                {project.details.status == "pending" ? <button className='account-btnInsideCard'>Pending</button> : null}
                                {project.details.status == "rejected" ? <><button className='account-btnInsideCard' onClick={() => ModifyListing(project.id, "rejected")} style={{ marginRight: "2%" }}>Modify</button>
                                  <button className='account-btnInsideCard' onClick={(() => { setAcknowledgeNormal(true); setAcknowledgeMessage(project.details.rejectedReason) })}>Rejected Review</button></> : null}
                                {project.details.status == "Closed" || (listingClosed.includes(project.id)) ? <p className='account-btnInsideCard' style={{ cursor: "default", textAlign: "center" }}>Listing Closed</p> : null}
                                <div style={{ clear: 'both' }}></div> */}
                                </div>
                              )
                            }) :
                            accountListingView == "Residential" ?
                              userResidentialListing != null && userResidentialListing.map((project, index) => {
                                return (
                                  <div class="CardResidentialAccount">
                                    <Link to={project.details.status == "leasing" ? "/residentialRentDetails/" + project.id : null}>
                                      <div id="residentialRentInfo" style={{ borderBottom: "1px solid #c1c1c1" }}>
                                        <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                        <div id="cardResidentialUserInfo" >

                                          {project.details.propertyAgent == true ? <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>AGENT</p> : <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>DIRECT TENANT</p>}
                                          {project.details.preferredStatus == true ?
                                            <div id="residentialPreferred" style={{ margin: "0", height: "auto", padding: "5px" }}>
                                              <p>Preferred Listing</p>
                                            </div>
                                            :
                                            null
                                          }
                                          {/* <p style={project.details.propertyAgent == true ?{fontWeight:"bold", padding:"4px 0px"}:{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ fontWeight: "bold" }}>{project.details.district == "Any" ? "District: " + project.details.district : project.details.district}</p> */}

                                          {/* <p>{project.details.propertyTypeResidential == "Any" ? 'Type: ' + project.details.propertyTypeResidential : project.details.propertyTypeResidential}</p> */}
                                          {/* <p>{project.details.specificPropertyType}</p> */}

                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                      </div>
                                      {/* <div style={{ clear: 'both' }}></div> */}


                                      <div class="CardContainer">



                                        <div style={{ clear: 'both' }}></div>

                                        <div id="Card_overall">
                                          <p id="Card_district" style={{ paddingTop: "0" }}>{project.details.district == "Any" ? project.details.district.toUpperCase() + " DISTRICT" : project.details.district.toUpperCase()}</p>
                                          <p id="Card_price" style={project.details.status == "closed" ? { color: "grey" } : { color: "black" }}>${project.details.priceOverall.toLocaleString()}</p>
                                          <p id="monthlyRentalBudget">Monthly Rental Budget</p>
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Furnishing Type:</p><p style={project.details.status == "closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.furnishingType}</p>
                        <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={project.details.status == "closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}  >{project.details.periodMoveInDateFormatted}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "5px", paddingTop: "5px", height: "20%" }}>
                                          <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                            <i style={{ color: "#717171" }} class="fa-solid fa-house"></i>
                                            <p id="Card_type" style={{ fontWeight: "bold", paddingLeft: "5px" }}>{project.details.propertyTypeResidential}</p>
                                          </div>
                                          <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                            <i style={{ color: "#717171" }} class="fa-solid fa-bed"></i>
                                            <p id="Card_type" style={{ fontWeight: "bold", paddingLeft: "5px" }}>{project.details.bedrooms != "Entire Unit" ? project.details.bedrooms + " Bedrooms" : "Entire Unit"}</p>

                                          </div>
                                        </div>

                                      </div>
                                      <div style={{ clear: 'both' }}></div>
                                    </Link>
                                    <div style={{ clear: 'both' }}></div>
                                    <div style={{ paddingTop: "5px" }}>
                                      {project.details.boost == true ? <>

                                        <div className="icon-tooltip">
                                          <i style={{ float: "right", marginTop: "3%", marginRight: "5%", color: "#c8c803" }} class="fa-solid fa-bolt"></i>
                                          <div className="tooltip-content">
                                            <p>Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                              day: '2-digit',
                                              month: '2-digit',
                                              year: 'numeric',
                                            })}</p>
                                            <p>Boost Left: {project.details.boostLeft}</p>
                                          </div>
                                        </div>
                                      </> :
                                        project.details.boostStart != null ? <>
                                          <div className="icon-tooltip">
                                            <i style={{ float: "right", marginTop: "3%", marginRight: "5%" }} class="fa-solid fa-bolt"></i>
                                            <div className="tooltip-content">
                                              <p>Upcoming Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                              })}</p>
                                              <p>Boost Left: {project.details.boostLeft}</p>
                                            </div>
                                          </div>
                                        </> : null}
                                      {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => ModifyListing(project.id, "approved")} style={{ marginRight: "2%" }}>Modify</button> : null}
                                      {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => IndivBoost(project.id, project.details.listingName, 'rent')}><i class="fa-solid fa-bolt"></i> Boost</button> : null}
                                      {project.details.status == "pending" ? <button className='account-btnInsideCard'>Pending</button> : null}
                                      {project.details.status == "rejected" ? <><button className='account-btnInsideCard' onClick={() => ModifyListing(project.id, "rejected")} style={{ marginRight: "2%" }}>Modify</button>
                                        <button className='account-btnInsideCard' onClick={(() => { setAcknowledgeNormal(true); setAcknowledgeMessage(project.details.rejectedReason) })}>Rejected Review</button></> : null}
                                      {project.details.status == "Closed" || (listingClosed.includes(project.id)) ? <p className='account-btnInsideCard' style={{ cursor: "default", textAlign: "center" }}>Listing Closed</p> : null}
                                      <div style={{ clear: 'both' }}></div>
                                    </div>
                                    {/* <Link to={project.details.status == "leasing" ? "/residentialRentDetails/" + project.id : null}>
                                    <div id="residentialRentInfoAccount">
                                      <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                      <div id="cardResidentialUserInfo" >
                                        {project.details.propertyAgent == true ? <div style={{ backgroundColor: '#33434dbf', width: "fit-content", color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                                          Agent
                                        </div> : null}
                                        <p style={{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p>

                                        <p style={{ fontWeight: "bold" }}>{project.details.district}</p>

                                        <p>{project.details.propertyTypeResidential}</p>
                                        <p>{project.details.specificPropertyType}</p>

                                      </div>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                    {project.details.preferredStatus == true ?
                                      <div id="residentialPreferred" style={{ height: "10%" }}>
                                        <p>Preferred Listing</p>
                                      </div>
                                      :
                                      <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white" }}>

                                      </div>
                                    }

                                    <div class="CardContainer">
                                      <div id="residentialHuman" >
                                        {project.details.adults == "1" ?
                                          <>
                                            <i class="fa-solid fa-person" id="humanIcon" ></i>
                                          </> :
                                          project.details.adults == "2" ?
                                            <>
                                              <i class="fa-solid fa-person" id="humanIcon" ></i>
                                              <i class="fa-solid fa-person" id="humanIcon" ></i>
                                            </> :
                                            project.details.adults == "3" ?
                                              <>
                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                <i class="fa-solid fa-person" id="humanIcon" ></i>
                                              </> :
                                              project.details.adults == "4" ?
                                                <>
                                                  <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                  <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                  <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                  <i class="fa-solid fa-person" id="humanIcon" ></i>

                                                </> :
                                                project.details.adults == "5" ?
                                                  <>
                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                    <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                  </> :
                                                  project.details.adults == "6" ?
                                                    <>
                                                      <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                      <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                      <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                      <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                      <i class="fa-solid fa-person" id="humanIcon" ></i>
                                                      <i class="fa-solid fa-person" id="humanIcon" ></i></> :
                                                    null
                                        }
                                        {project.details.children == "1" ?
                                          <>
                                            <i class="fa-solid fa-child" id='childIcon' ></i>
                                          </> :
                                          project.details.children == "2" ?
                                            <>
                                              <i class="fa-solid fa-child" id='childIcon' ></i>
                                              <i class="fa-solid fa-child" id='childIcon' ></i>
                                            </> :
                                            project.details.children == "3" ?
                                              <>
                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                                <i class="fa-solid fa-child" id='childIcon' ></i>
                                              </> :
                                              project.details.children == "4" ?
                                                <>
                                                  <i class="fa-solid fa-child" id='childIcon' ></i>
                                                  <i class="fa-solid fa-child" id='childIcon' ></i>
                                                  <i class="fa-solid fa-child" id='childIcon' ></i>
                                                  <i class="fa-solid fa-child" id='childIcon' ></i>
                                                </> :
                                                project.details.children == "5" ?
                                                  <>
                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                    <i class="fa-solid fa-child" id='childIcon' ></i>
                                                  </> :
                                                  project.details.children == "6" ?
                                                    <>
                                                      <i class="fa-solid fa-child" id='childIcon' ></i>
                                                      <i class="fa-solid fa-child" id='childIcon' ></i>
                                                      <i class="fa-solid fa-child" id='childIcon' ></i>
                                                      <i class="fa-solid fa-child" id='childIcon' ></i>
                                                      <i class="fa-solid fa-child" id='childIcon' ></i>
                                                      <i class="fa-solid fa-child" id='childIcon' ></i>
                                                    </> :
                                                    null
                                        }
                                      </div>

                                      <div style={{ float: "right", marginTop: "3%", marginBottom: "3%" }}>
                                        <p id="residentialBedroom">{project.details.bedrooms != "Entire Unit" ? project.details.bedrooms + " Rooms Only" : "Entire Unit"}</p>
                                      </div>

                                      <div style={{ clear: 'both' }}></div>
                                      {project.details.boost == true ? <>

                                        <div className="icon-tooltip">
                                          <i style={{ float: "right", marginTop: "3%", marginRight: "5%", color: "#c8c803" }} class="fa-solid fa-bolt"></i>
                                          <div className="tooltip-content">
                                            <p>Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                              day: '2-digit',
                                              month: '2-digit',
                                              year: 'numeric',
                                            })}</p>
                                            <p>Boost Left: {project.details.boostLeft}</p>
                                          </div>
                                        </div>
                                      </> :
                                        project.details.boostStart != null ? <>
                                          <div className="icon-tooltip">
                                            <i style={{ float: "right", marginTop: "3%", marginRight: "5%" }} class="fa-solid fa-bolt"></i>
                                            <div className="tooltip-content">
                                              <p>Upcoming Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                              })}</p>
                                              <p>Boost Left: {project.details.boostLeft}</p>
                                            </div>
                                          </div>
                                        </> : null}
                                      <div style={{ clear: 'both' }}></div>

                                      <div id="Card_overall">
                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Monthly Rental Budget:</p><p style={project.details.status == "Closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>${project.details.priceOverall.toLocaleString()}</p>
                                        <div style={{ clear: 'both' }}></div> */}
                                    {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Furnishing Type:</p><p style={project.details.status == "Closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.furnishingType}</p> */}
                                    {/* <div style={{ clear: 'both' }}></div> */}
                                    {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={project.details.status == "Closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}  >{project.details.periodMoveInDateFormatted}</p>
                                        <div style={{ clear: 'both' }}></div>

                                      </div>

                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                  </Link>
                                  <div style={{ clear: 'both' }}></div>
                                  {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => ModifyListing(project.id, "approved")} style={{ marginRight: "2%" }}>Modify</button> : null}
                                  {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => IndivBoost(project.id, project.details.listingName, 'rent')}><i class="fa-solid fa-bolt"></i> Boost</button> : null}
                                  {project.details.status == "pending" ? <button className='account-btnInsideCard'>Pending</button> : null}
                                  {project.details.status == "rejected" ? <><button className='account-btnInsideCard' onClick={() => ModifyListing(project.id, "rejected")} style={{ marginRight: "2%" }}>Modify</button>
                                    <button className='account-btnInsideCard' onClick={(() => { setAcknowledgeNormal(true); setAcknowledgeMessage(project.details.rejectedReason) })}>Rejected Review</button></> : null}
                                  {project.details.status == "Closed" || (listingClosed.includes(project.id)) ? <p className='account-btnInsideCard' style={{ cursor: "default", textAlign: "center" }}>Listing Closed</p> : null}
                                  <div style={{ clear: 'both' }}></div> */}
                                  </div>
                                )
                              })
                              : null
                        }
                        <div style={{ clear: 'both' }}></div>
                      </div>
                    </> : null}
                  {purchaseclick ?
                    <>
                      {/* <ReactNotifications /> */}
                      <div id="listingTab">
                        <div className="toggleDivAccount">
                          <button className={`toggleBtnAccount ${isCommercial ? 'active' : ''}`} onClick={handleCommercial} >
                            Commercial
                          </button>
                          <button className={`toggleBtnAccount ${!isCommercial ? 'active' : ''}`} onClick={handleCommercial}>
                            Residential
                          </button>
                        </div>
                        {/* <div className={`toggleBtnAccountListingView ${isCommercial ? 'toggled' : ''}`} style={{ marginTop: "1%", marginLeft: "1%" }} onClick={handleCommercial}>
                        <div className="circle"></div>
                        <span style={isCommercial == false ? { marginLeft: "30px", fontSize: '14px' } : { marginRight: "30px", fontSize: '14px' }}>{isCommercial ? 'Commercial' : 'Residential'}</span>
                      </div> */}
                        {/* <select style={{ marginRight: "2%", borderRadius: "10px" }} value={accountListingView} onChange={(e) => setAccountListingView(e.target.value)}>
                      <option value={"Commercial"}>Commercial</option>
                      <option value={"Residential"}>Residential</option>
                    </select> */}
                        <button className='account-btnNormal' style={{ marginRight: "1%", marginTop: "1%", float: 'right' }} onClick={() => setClickPurchase(true)} >Looking For A Space</button>
                        <button className='account-btnNormal' onClick={() => setClickBoostAllPurchase(true)} style={{ marginRight: "1%", marginTop: "1%", float: 'right' }}><i class="fa-solid fa-bolt"></i> Boost All</button>

                        <BoostAllSale trigger={clickBoostAllPurchase} setTrigger={setClickBoostAllPurchase} commercialListingPurcahse={commercialListingPurcahse} setCommercialListingPurchase={setCommercialListingPurchase} commercialSalesAvailableNumber={commercialSalesAvailableNumber} userResidentialSalesAvailableNumber={userResidentialSalesAvailableNumber} accountView={accountListingView} residentialListingPurchase={residentialListingPurchase} setResidentialPurchase={setResidentialPurchase} currentUserInfo={currentUserInfo} />
                        <BoostIndivSale trigger={clickBoostIndivPurchase} setTrigger={setClickBoostIndivPurchase} id={boostIndivID} commercialListingPurcahse={commercialListingPurcahse} setCommercialListingPurchase={setCommercialListingPurchase} accountView={accountListingView} residentialListingPurchase={residentialListingPurchase} setResidentialPurchase={setResidentialPurchase} currentUserInfo={currentUserInfo} />
                        <div style={{ clear: 'both' }}></div>
                      </div>


                      <div style={{ clear: 'both' }}></div>
                      <div id="listingContent">
                        {
                          accountListingView == "Commercial" ?
                            commercialListingPurcahse != null && commercialListingPurcahse.map((project, index) => {
                              return (
                                <div class="CardPurchaseAccount">
                                  <Link to={project.details.status == "leasing" ? "/commercialPurchaseDetails/" + project.id : null}>
                                    <div id="commercialSaleInfo" style={{ borderBottom: "1px solid #c1c1c1" }}>


                                      <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                      <div id="cardResidentialUserInfo" >





                                        {/* <p style={project.details.propertyAgent == true ?{fontWeight:"bold", padding:"4px 0px"}:{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p> */}
                                        {project.details.propertyAgent == true ? <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>AGENT</p> : <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>DIRECT BUYER</p>}
                                        {project.details.preferredStatus == true ?
                                          <div id="residentialPreferred" style={{ margin: "0", height: "auto", padding: "5px" }}>
                                            <p>Preferred Listing</p>
                                          </div>
                                          :
                                          // <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white", height:"10%" }}>

                                          // </div>
                                          null
                                        }
                                        <div style={{ clear: 'both' }}></div>

                                        {/* <p style={{ fontWeight: "bold" }}>{project.details.district[0] == "Any" ? "District: " + project.details.district[0] : project.details.district[0]}</p>
                        <p>{project.details.propertyType}</p>
                        <p>{project.details.specificPropertyType == "Any" ? "Type: " + project.details.specificPropertyType : project.details.specificPropertyType}</p> */}

                                      </div>
                                      <div style={{ clear: 'both' }}></div>
                                    </div>

                                    <div style={{ clear: 'both' }}></div>


                                    <div class="CardContainer" style={{ height: "65%" }}>


                                      {/* <div style={{ clear: 'both' }}></div> */}

                                      <div id="Card_overall">

                                        <p id="Card_district" style={{ paddingTop: "0" }}>{project.details.district[0].toUpperCase() == "ANY" ? <>{project.details.district[0].toUpperCase()} DISTRICT</> : <>{project.details.district[0].toUpperCase()}</>}</p>
                                        <p id="Card_price">${project.details.minFloorSizePSFBudget.toLocaleString()}</p>
                                        <p id="monthlyRentalBudget">Min PSF Budget</p>
                                      </div>
                                      <div className="Card_SecondOverall">
                                        <div style={{ width: "50%" }}>
                                          <p style={{ color: "#4d4c4c" }}>Property Type</p>
                                          <p id="Card_type" style={{ fontWeight: "bold" }}>{project.details.propertyType}</p>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                          <p style={{ color: "#4d4c4c" }}>Specific Type</p>
                                          <p id="Card_type" style={{ fontWeight: "bold" }}>{project.details.specificPropertyType}</p>
                                        </div>
                                      </div>
                                      <div>
                                        <p style={{ color: "#4d4c4c" }}>Floor Size <span style={{ fontSize: "10px" }}>[PSF]</span></p>
                                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "5px", paddingTop: "5px" }}>
                                          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                            <div style={{ width: "50%" }}>
                                              <span style={{ color: "#4d4c4c", fontSize: "10px" }}>Min </span>
                                              <span id="Card_type" style={{ fontWeight: "bold" }}>{project.details.minFloorSize}</span>


                                            </div>
                                            <div style={{ width: "50%" }}>
                                              <span style={{ color: "#4d4c4c", fontSize: "10px" }}>Max </span>
                                              <span id="Card_type" style={{ fontWeight: "bold" }}>{project.details.maxFloorSize}</span>

                                            </div>
                                          </div>



                                        </div>
                                      </div>


                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                  </Link>
                                  <div style={{ clear: 'both' }}></div>

                                  <div style={{ clear: 'both' }}></div>
                                  <div>
                                    {project.details.boost == true ? <>
                                      <div className="icon-tooltip">
                                        <i style={{ float: "right", marginTop: "3%", marginRight: "5%", color: "#c8c803" }} class="fa-solid fa-bolt"></i>
                                        <div className="tooltip-content">
                                          <p>Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                          })}</p>
                                          <p>Boost Left: {project.details.boostLeft}</p>
                                        </div>
                                      </div>
                                    </> :
                                      project.details.boostStart != null ? <>
                                        <div className="icon-tooltip">
                                          <i style={{ float: "right", marginTop: "3%", marginRight: "5%" }} class="fa-solid fa-bolt"></i>
                                          <div className="tooltip-content">
                                            <p>Upcoming Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                              day: '2-digit',
                                              month: '2-digit',
                                              year: 'numeric',
                                            })}</p>
                                            <p>Boost Left: {project.details.boostLeft}</p>
                                          </div>
                                        </div>
                                      </> : null}
                                    {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => ModifyListingPurchase(project.id, "approved")} style={{ marginRight: "2%" }}>Modify</button> : null}
                                    {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => IndivBoost(project.id, project.details.listingName, 'sales')}><i class="fa-solid fa-bolt"></i> Boost</button> : null}
                                    {project.details.status == "pending" ? <button className='account-btnInsideCard'>Pending</button> : null}
                                    {project.details.status == "rejected" ? <><button className='account-btnInsideCard' onClick={() => ModifyListingPurchase(project.id, "rejected")} style={{ marginRight: "2%" }}>Modify</button>
                                      <button className='account-btnInsideCard' onClick={(() => { setAcknowledgeNormal(true); setAcknowledgeMessage(project.details.rejectedReason) })}>Rejected Review</button></> : null}
                                    {project.details.status == "Closed" || (listingClosed.includes(project.id)) ? <p className='account-btnInsideCard' style={{ cursor: "default", textAlign: "center" }}>Listing Closed</p> : null}
                                    <div style={{ clear: 'both' }}></div>
                                  </div>
                                  {/* <Link to={project.details.status == "leasing" ? "/commercialPurchaseDetails/" + project.id : null}>
                                  <div id="commercialSaleInfoAccount">
                                    <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                    <div id="cardResidentialUserInfo" >
                                      {project.details.propertyAgent == true ? <div style={{ backgroundColor: '#33434dbf', width: "fit-content", color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                                        Agent
                                      </div> : null}
                                      <p style={{ fontWeight: "bold" }}>{project.details.listingName.length > 15 ? `${project.details.listingName.slice(0, 15)}...` : project.details.listingName}</p>

                                      <p style={{ fontWeight: "bold" }}>{project.details.district[0]}</p>
                                      <p>{project.details.propertyType}</p>
                                      <p>{project.details.specificPropertyType}</p>

                                    </div>
                                  </div>
                                  <div style={{ clear: 'both' }}></div>
                                  {project.details.preferredStatus == true ?
                                    <div id="residentialPreferred" style={{ height: "10%" }}>
                                      <p>Preferred Listing</p>
                                    </div>
                                    :
                                    <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white" }}>

                                    </div>
                                  }

                                  <div class="CardContainer">

                                    {project.details.boost == true ? <>
                                      <div className="icon-tooltip">
                                        <i style={{ float: "right", marginTop: "3%", marginRight: "5%", color: "#c8c803" }} class="fa-solid fa-bolt"></i>
                                        <div className="tooltip-content">
                                          <p>Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                          })}</p>
                                          <p>Boost Left: {project.details.boostLeft}</p>
                                        </div>
                                      </div>
                                    </> :
                                      project.details.boostStart != null ? <>
                                        <div className="icon-tooltip">
                                          <i style={{ float: "right", marginTop: "3%", marginRight: "5%" }} class="fa-solid fa-bolt"></i>
                                          <div className="tooltip-content">
                                            <p>Upcoming Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                              day: '2-digit',
                                              month: '2-digit',
                                              year: 'numeric',
                                            })}</p>
                                            <p>Boost Left: {project.details.boostLeft}</p>
                                          </div>
                                        </div>
                                      </> : null}
                                    <div style={{ clear: 'both' }}></div>

                                    <div id="Card_overall">
                                      <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min PSF Budget:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>${project.details.minFloorSizePSFBudget.toLocaleString()}</p>
                                      <div style={{ clear: 'both' }}></div>
                                      <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Max PSF Budget:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>${project.details.maxFloorSizePSFBudget.toLocaleString()}</p>
                                      <div style={{ clear: 'both' }}></div>
                                      <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>{project.details.minFloorSize} SQFT</p>
                                      <div style={{ clear: 'both' }}></div>
                                      <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Able to transact / :{<br></br>}Purchase from</p><p style={{ float: "right", color: "black" }}  >{project.details.periodMoveInDateFormatted}</p>
                                      <div style={{ clear: 'both' }}></div>
                                    </div>


                                  </div>
                                  <div style={{ clear: 'both' }}></div>
                                </Link>
                                <div style={{ clear: 'both' }}></div>
                                {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => ModifyListingPurchase(project.id, "approved")} style={{ marginRight: "2%" }}>Modify</button> : null}
                                {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => IndivBoost(project.id, project.details.listingName, 'sales')}><i class="fa-solid fa-bolt"></i> Boost</button> : null}
                                {project.details.status == "pending" ? <button className='account-btnInsideCard'>Pending</button> : null}
                                {project.details.status == "rejected" ? <><button className='account-btnInsideCard' onClick={() => ModifyListingPurchase(project.id, "rejected")} style={{ marginRight: "2%" }}>Modify</button>
                                  <button className='account-btnInsideCard' onClick={(() => { setAcknowledgeNormal(true); setAcknowledgeMessage(project.details.rejectedReason) })}>Rejected Review</button></> : null}
                                {project.details.status == "Closed" || (listingClosed.includes(project.id)) ? <p className='account-btnInsideCard' style={{ cursor: "default", textAlign: "center" }}>Listing Closed</p> : null}
                                <div style={{ clear: 'both' }}></div> */}
                                </div>
                              )
                            }) :
                            accountListingView == "Residential" ?
                              residentialListingPurchase != null && residentialListingPurchase.map((project, index) => {
                                return (
                                  <div class="CardResidentialSaleAccount">
                                    <Link to={project.details.status == "leasing" ? "/residentialPurchaseDetails/" + project.id : null}>
                                      <div id="residentialPurchaseInfo" style={{ borderBottom: "1px solid #c1c1c1" }}>
                                        <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                        <div id="cardResidentialUserInfo" >
                                          {project.details.propertyAgent == true ? <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>AGENT</p> : <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>DIRECT BUYER</p>}
                                          {/* <p style={project.details.propertyAgent == true ? {fontWeight:"bold", padding:"4px 0px"}:{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ fontWeight: "bold" }}>{project.details.district[0]}</p> */}
                                          {/* <p>{project.details.propertyType}</p> */}
                                          {project.details.preferredStatus == true ?
                                            <div id="residentialPreferred" style={{ margin: "0", height: "auto", padding: "5px" }}>
                                              <p>Preferred Listing</p>
                                            </div>
                                            :
                                            null
                                          }
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                      </div>
                                      <div style={{ clear: 'both' }}></div>
                                      {/* {project.details.preferredStatus == true ?
                      <div id="residentialPreferred" style={{height:"10%"}}>
                        <p>Preferred Listing</p>
                      </div>
                      :
                      <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white", height:"10%" }}>

                      </div>
                    } */}

                                      <div class="CardContainer">

                                        <div id="Card_overall">
                                          <p id="Card_district" style={{ paddingTop: "0" }}>{project.details.district[0].toUpperCase()}</p>
                                          <p id="Card_price">${project.details.budgetOverall.toLocaleString()}</p>
                                          <p id="monthlyRentalBudget">Budget Overall</p>
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Budget Overall:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>${project.details.budgetOverall.toLocaleString()}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.minFloorSize}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Max Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.maxFloorSize}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.periodMoveInDateFormatted}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "5px", paddingTop: "5px", height: "20%" }}>
                                          <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                            <i style={{ color: "#717171" }} class="fa-solid fa-house"></i>
                                            <p id="Card_type" style={{ fontWeight: "bold", paddingLeft: "5px" }}>{project.details.propertyType}</p>
                                          </div>
                                          <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                            <i style={{ color: "#717171" }} class="fa-solid fa-bed"></i>
                                            <p id="Card_type" style={{ fontWeight: "bold", paddingLeft: "5px" }}>{project.details.bedrooms[0]}</p>

                                          </div>
                                        </div>


                                      </div>
                                      <div style={{ clear: 'both' }}></div>
                                    </Link>
                                    <div style={{ clear: 'both' }}></div>

                                    <div style={{ clear: 'both' }}></div>
                                    <div style={{ paddingTop: "5px" }}>
                                      {project.details.boost == true ? <>
                                        <div className="icon-tooltip">
                                          <i style={{ float: "right", marginTop: "3%", marginRight: "5%", color: "#c8c803" }} class="fa-solid fa-bolt"></i>
                                          <div className="tooltip-content">
                                            <p>Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                              day: '2-digit',
                                              month: '2-digit',
                                              year: 'numeric',
                                            })}</p>
                                            <p>Boost Left: {project.details.boostLeft}</p>
                                          </div>
                                        </div>
                                      </> :
                                        project.details.boostStart != null ? <>
                                          <div className="icon-tooltip">
                                            <i style={{ float: "right", marginTop: "3%", marginRight: "5%" }} class="fa-solid fa-bolt"></i>
                                            <div className="tooltip-content">
                                              <p>Upcoming Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                              })}</p>
                                              <p>Boost Left: {project.details.boostLeft}</p>
                                            </div>
                                          </div>
                                        </> : null}
                                      {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => ModifyListingPurchase(project.id, "approved")} style={{ marginRight: "2%" }}>Modify</button> : null}
                                      {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => IndivBoost(project.id, project.details.listingName, 'sales')}><i class="fa-solid fa-bolt"></i> Boost</button> : null}
                                      {project.details.status == "pending" ? <button className='account-btnInsideCard'>Pending</button> : null}
                                      {project.details.status == "rejected" ? <><button className='account-btnInsideCard' onClick={() => ModifyListingPurchase(project.id, "rejected")} style={{ marginRight: "2%" }}>Modify</button>
                                        <button className='account-btnInsideCard' onClick={(() => { setAcknowledgeNormal(true); setAcknowledgeMessage(project.details.rejectedReason) })}>Rejected Review</button></> : null}
                                      {project.details.status == "Closed" || (listingClosed.includes(project.id)) ? <p className='account-btnInsideCard' style={{ cursor: "default", textAlign: "center" }}>Listing Closed</p> : null}
                                      <div style={{ clear: 'both' }}></div>
                                    </div>
                                    {/* <Link to={project.details.status == "leasing" ? "/residentialPurchaseDetails/" + project.id : null}>
                                    <div id="residentialPurchaseInfoAccount">
                                      <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                      <div id="cardResidentialUserInfo" >
                                        {project.details.propertyAgent == true ? <div style={{ backgroundColor: '#33434dbf', width: "fit-content", color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                                          Agent
                                        </div> : null}
                                        <p style={{ fontWeight: "bold" }}>{project.details.listingName.length > 15 ? `${project.details.listingName.slice(0, 15)}...` : project.details.listingName}</p>


                                        <p style={{ fontWeight: "bold" }}>{project.details.district[0]}</p>
                                        <p>{project.details.propertyType}</p>

                                      </div>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                    {project.details.preferredStatus == true ?
                                      <div id="residentialPreferred" style={{ height: "10%" }}>
                                        <p>Preferred Listing</p>
                                      </div>
                                      :
                                      <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white" }}>

                                      </div>
                                    }

                                    <div class="CardContainer">

                                      {project.details.boost == true ? <>
                                        <div className="icon-tooltip">
                                          <i style={{ float: "right", marginTop: "3%", marginRight: "5%", color: "#c8c803" }} class="fa-solid fa-bolt"></i>
                                          <div className="tooltip-content">
                                            <p>Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                              day: '2-digit',
                                              month: '2-digit',
                                              year: 'numeric',
                                            })}</p>
                                            <p>Boost Left: {project.details.boostLeft}</p>
                                          </div>
                                        </div>
                                      </> :
                                        project.details.boostStart != null ? <>
                                          <div className="icon-tooltip">
                                            <i style={{ float: "right", marginTop: "3%", marginRight: "5%" }} class="fa-solid fa-bolt"></i>
                                            <div className="tooltip-content">
                                              <p>Upcoming Boost Start: {new Date(project.details.boostStart.seconds * 1000).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                              })}</p>
                                              <p>Boost Left: {project.details.boostLeft}</p>
                                            </div>
                                          </div>
                                        </> : null}
                                      <div style={{ clear: 'both' }}></div>

                                      <div id="Card_overall">
                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Budget Overall:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>${project.details.budgetOverall.toLocaleString()}</p>
                                        <div style={{ clear: 'both' }}></div>
                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.minFloorSize}</p>
                                        <div style={{ clear: 'both' }}></div>
                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Max Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.maxFloorSize}</p>
                                        <div style={{ clear: 'both' }}></div>
                                        <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Able to transact / :{<br></br>}Purchase from</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.periodMoveInDateFormatted}</p>
                                        <div style={{ clear: 'both' }}></div>
                                      </div>


                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                  </Link>
                                  <div style={{ clear: 'both' }}></div>
                                  {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => ModifyListingPurchase(project.id, "approved")} style={{ marginRight: "2%" }}>Modify</button> : null}
                                  {project.details.status == "leasing" && (listingClosed.includes(project.id) != true) ? <button className='account-btnInsideCard' onClick={() => IndivBoost(project.id, project.details.listingName, 'sales')}><i class="fa-solid fa-bolt"></i> Boost</button> : null}
                                  {project.details.status == "pending" ? <button className='account-btnInsideCard'>Pending</button> : null}
                                  {project.details.status == "rejected" ? <><button className='account-btnInsideCard' onClick={() => ModifyListingPurchase(project.id, "rejected")} style={{ marginRight: "2%" }}>Modify</button>
                                    <button className='account-btnInsideCard' onClick={(() => { setAcknowledgeNormal(true); setAcknowledgeMessage(project.details.rejectedReason) })}>Rejected Review</button></> : null}
                                  {project.details.status == "Closed" || (listingClosed.includes(project.id)) ? <p className='account-btnInsideCard' style={{ cursor: "default", textAlign: "center" }}>Listing Closed</p> : null}
                                  <div style={{ clear: 'both' }}></div> */}
                                  </div>
                                )
                              })
                              : null
                        }
                        <div style={{ clear: 'both' }}></div>
                      </div>
                    </> : null}
                  {advertiseclick ?
                    <>
                      <AdvertiseListing trigger={advertiseActivated} setTrigger={setAdvertiseActivated} />
                      <AdvertiseAction trigger={advertiseActionActivated} setTrigger={setAdvertiseActionActivated} advListing={advListing} setAdvListing={setAdvListing} activateID={activateID} setActivateID={setActivateID} advertisePath={advertisePath} setAdvertisePath={setAdvertisePath} modifyAdvertiseDetails={modifyAdvertiseDetails} setModifyAdvertiseDetails={setModifyAdvertiseDetails} currentUserInfo={currentUserInfo} setCurrentUserInfo={setCurrentUserInfo} />
                      <div id="listingTab">
                        {<button className='account-btnNormal' style={{ float: "right", marginTop: "1%", marginRight: "1%" }} onClick={() => setAdvertiseActivated(true)} >Post an Advertisement</button>}
                        <div style={{ clear: 'both' }}></div>
                      </div>
                      {advListing.length != 0 && advListing.map((project, index) => {
                        return (

                          <div class="CardAdvertiseDivAccount">

                            <div style={{ position: 'relative', height: "90%", display: "flex", justifyContent: "center" }}>
                              <img style={{ alignItems: 'center' }} className="advertiseCard" src={project.details.imageURL} alt="Uploaded" />
                              <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#33434dbf', color: '#fff', padding: '4px 8px', borderRadius: '4px', marginTop: "1%", marginRight: "1%" }}>
                                {project.details.status == "leasing" ? <p>Activated</p> : null}
                              </div>
                            </div>


                            <div class="CardContainerAdv" >

                              <div id="Card_overall">
                                <p>Advertise Name: {project.details.advertiseName}</p>
                                {project.details.activatedDate != null && project.details.activatedDate.length != 0 ? <>
                                  <p style={{ float: "left" }}>Next Activated Date:</p><p style={project.details.status == "Closed" || (listingClosed.includes(project.id)) ? { float: "right", color: "grey" } : { float: "right", color: "black" }}>{project.details.activatedDate[0]}</p>
                                </> : <> <p style={{ float: "left" }}>Next Activated Date:</p><p style={project.details.status == "Closed" || (listingClosed.includes(project.id)) ? { float: "right", color: "grey" } : { float: "right", color: "black" }}>Not Applicable</p></>
}
                                <div style={{ clear: 'both' }}></div>

                              </div>
                              <div style={{ clear: 'both' }}></div>




                            </div>
                            <div style={{ clear: 'both' }}></div>

                            <div style={{ paddingTop: "5px" }}>
                              {project.details.status == "approved" && (project.details.activatedDate == null || project.details.activatedDate.length == 0) ? <button className='account-btnInsideCard' onClick={() => { setModifyAdvertiseDetails(project); setActivateID(project.id); setAdvertiseActionActivated(true); }} style={{ marginRight: "2%" }}>Activate</button> : null}
                              {project.details.status == "pending" ? <button className='account-btnInsideCard'>Pending</button> : null}
                              {project.details.status == "rejected" ? <button className='account-btnInsideCard' onClick={(() => { setAcknowledgeNormal(true); setAcknowledgeMessage(project.details.rejectedReason) })} style={{ marginRight: "2%" }}>Review</button> : null}
                              {(project.details.status == "approved" || project.details.status == "leasing") && project.details.activatedDate != null && project.details.activatedDate.length != 0 ? <><button className='account-btnInsideCard' onClick={() => modifyAdvertise(project.id, "approved", project)} style={{ marginRight: "2%" }}>Modify</button></> : null}
                              {project.details.status == "approved" || project.details.status == "leasing" || project.details.status == "rejected" ? <button className='account-btnInsideCard' onClick={(() => { setAcknowledgeNormalDeleteAdv(true); setAcknowledgeMessage("When your advertisement is deleted, all upcoming dates and related information will be removed."); setActivateID(project.id) })} style={{ marginRight: "2%" }}>Delete</button> : null}

                              <div style={{ clear: 'both' }}></div>
                            </div>
                          </div>
                        )
                      })
                      }

                    </> : null}

                  {shortListClick ?
                    <>
                      {/* <ReactNotifications /> */}
                      <select style={{ float: "right", padding: "5px 8px", marginRight: "2%", borderRadius: "10px", border: "1px solid #c0c0c0" }} value={filterShortList} onChange={(e) => { setFilterShortList(e.target.value) }}>
                        <option>All</option>
                        <option>Residential Rent</option>
                        <option>Residential Sale</option>
                        <option>Commercial Rent</option>
                        <option>Commercial Sale</option>
                      </select>
                      <div style={{ clear: 'both' }}></div>
                      {shortList.length != 0 && (filterShortList == "All" || filterShortList == "Commercial Rent") ? <p style={{ paddingLeft: "1%", fontWeight: "bold", fontSize: "18px" }}>Commercial Rent</p> : null}
                      {shortList.length != 0 && (filterShortList == "All" || filterShortList == "Commercial Rent") && shortList.map(project => {
                        return (
                          <>
                            {project.details.hasOwnProperty("bedrooms") ?
                              null
                              :
                              project.details.hasOwnProperty('amenities') ?
                                null
                                :
                                ////Commercial Rent
                                <div class="Card" style={{ border: "1px solid #c1c1c1", borderRadius: "10px", position: "relative" }}>

                                  {/* <button onClick={(e) => e.preventDefault()}>testing</button> */}
                                  {/* <div style={{ position: "relative" }}> */}

                                  {/* <div style={{ position: 'relative' }}>
                        <img id="Card_image" src={project.details.url0.search("https://ik.imagekit.io/NextLoop") >= 0 ? (project.details.url0).replaceAll("tr:h-200,w-300", "tr:h-190,w-280") : (project.details.url0).replaceAll("https://firebasestorage.googleapis.com", "https://ik.imagekit.io/NextLoop/tr:h-190,w-280")}></img>
                        {project.details.propertyAgent == true ? <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#33434dbf', color: '#fff', padding: '3%', borderRadius: '4px', marginTop: "3%", marginRight: "3%", fontSize: "13px" }}>
                          Agent
                        </div> : null}
                        <div id="preferredBackground">
                          <div id="informationPreferredBackground">
                             {project.details.preferredListing == true ? <p id="Card_preferred">Preferred Listing</p> : null}
                          </div>
                        </div>
                      </div> */}
                                  <div style={{ position: 'relative' }}>

                                    <Carousel>
                                      {[0, 1, 2, 3].map((index) => {
                                        const imageUrl = project.details[`url${index}`];
                                        if (imageUrl) {
                                          return (
                                            <div key={index}>
                                              <img id="Card_image" src={imageUrl} alt={`Picture ${index}`} />
                                            </div>
                                          );
                                        } else {
                                          return null; // Render nothing if there's no image for the index
                                        }
                                      }).filter(child => child !== null)}

                                    </Carousel>
                                    <div id="preferredBackground">
                                      <div id="informationPreferredBackground">
                                        {project.details.preferredListing == true ? <p id="Card_preferred">Preferred Listing</p> : null}
                                      </div>
                                    </div>


                                    {/* <div id="gradientBackground">
                      <div id="informationGradientBackground">
                        <div id='informationGradientBackgroundLeft'>
                          <i class="fa fa-eye"></i> {project.details.eyeball}
                          <i class="far fa-comment-dots"></i> {project.details.connections}
                        </div>
                        <div id='informationGradientBackgroundRight'>
                          {heartIconStatus == undefined | heartIconStatus == false ? <button id="heartButton" onClick={(e) => { e.preventDefault(); addShortList(project.details.UID, ID) }}><i class="fa-regular fa-heart fa-2x" style={{ color: "white" }}></i></button> : <button id="heartButton" onClick={(e) => { e.preventDefault(); deteleShortList(ID) }}><i class="fa-solid fa-heart fa-2x" id="favouriteHeart"></i></button>}
                        </div>
                        <div style={{ clear: 'both' }}></div>
                      </div>
                    </div> */}
                                  </div>
                                  <Link to={project.details.status == "leasing" ? "/commercialRentDetails/" + project.id : null}>
                                    <div class="CardContainer">
                                      <div style={{ borderBottom: "1px solid #c1c1c1", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "5px" }}>
                                        {project.details.propertyAgent == true ? <p style={{ color: "#4d4c4c" }}>AGENT</p> : <p style={{ color: "#4d4c4c" }}>DIRECT BUYER</p>}
                                        <p id="Card_district" style={{ paddingTop: "5px" }}>{project.details.district.toUpperCase()}</p>

                                        {/* {project.details.preferredListing == true ? <p id="Card_preferred">Preferred Listing</p> : null} */}
                                        <p id="Card_price">${project.details.priceOverall.toLocaleString()}</p>

                                        <p id="monthlyRentalBudget">Monthly Rental Budget</p>

                                      </div>
                                      {/* {project.details.preferredListing == true ?<p id="Card_preferred">Preferred Listing</p>:null} */}


                                      <div id="Card_overall">

                                        <p style={{ color: "#4d4c4c", paddingLeft: "5px" }}>Business Type</p>
                                        <p id="Card_type" style={{ paddingLeft: "5px", fontWeight: "bold" }}>{project.details.propertyType}</p>

                                        {/* <p style={{ float: "left" }}>Monthly PSF Budget:</p><p style={{ float: "right", paddingRight: "10px", color: "black" }}>${project.details.pricePerSquareFeet}</p> */}
                                        {/* <div style={{ clear: 'both' }}></div> */}
                                      </div>
                                    </div>
                                  </Link>



                                  {/* // <div style={{ paddingTop: "5px", paddingBottom: "5px" }}> */}
                                  <div id='residentialEyeball'>
                                    <i class="fa fa-eye"></i> {project.details.eyeball}
                                    <i class="far fa-comment-dots" style={{ marginLeft: "5px" }}></i> {project.details.connections}
                                  </div>
                                  <div id='residentialHeart'>
                                    <button id="heartButton" onClick={(e) => { e.preventDefault(); deteleShortList(project.id) }}><i class="fa-solid fa-heart fa-2x" id="favouriteHeart"></i></button>
                                  </div>
                                  <div style={{ clear: 'both' }}></div>
                                </div>
                            }
                          </>
                        )
                      })
                      }
                      <div style={{ clear: 'both' }}></div>
                      {shortList.length != 0 && (filterShortList == "All" || filterShortList == "Commercial Sale") ? <p style={{ paddingLeft: "1%", fontWeight: "bold", fontSize: "18px" }}>Commercial Sale</p> : null}
                      {shortList.length != 0 && (filterShortList == "All" || filterShortList == "Commercial Sale") && shortList.map(project => {
                        return (
                          <>
                            {project.details.hasOwnProperty("bedrooms") ?
                              null
                              :
                              project.details.hasOwnProperty('amenities') ?
                                <>
                                  {/* commercial SALES */}
                                  <div class="CardPurchase">
                                    <Link to={project.details.status == "leasing" ? "/commercialPurchaseDetails/" + project.id : null}>
                                      <div id="commercialSaleInfo" style={{ borderBottom: "1px solid #c1c1c1" }}>


                                        <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                        <div id="cardResidentialUserInfo" >

                                          {/* {project.details.propertyAgent == true ? <div style={{ backgroundColor: '#33434dbf', width: "fit-content", color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                          Agent
                        </div> : null} */}



                                          {/* <p style={project.details.propertyAgent == true ?{fontWeight:"bold", padding:"4px 0px"}:{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p> */}
                                          {project.details.propertyAgent == true ? <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>AGENT</p> : <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>DIRECT BUYER</p>}
                                          {project.details.preferredStatus == true ?
                                            <div id="residentialPreferred" style={{ margin: "0", height: "auto", padding: "5px" }}>
                                              <p>Preferred Listing</p>
                                            </div>
                                            :
                                            // <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white", height:"10%" }}>

                                            // </div>
                                            null
                                          }
                                          <div style={{ clear: 'both' }}></div>

                                          {/* <p style={{ fontWeight: "bold" }}>{project.details.district[0] == "Any" ? "District: " + project.details.district[0] : project.details.district[0]}</p>
                        <p>{project.details.propertyType}</p>
                        <p>{project.details.specificPropertyType == "Any" ? "Type: " + project.details.specificPropertyType : project.details.specificPropertyType}</p> */}

                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                      </div>

                                      <div style={{ clear: 'both' }}></div>


                                      <div class="CardContainer" style={{ height: "80%" }}>


                                        {/* <div style={{ clear: 'both' }}></div> */}

                                        <div id="Card_overall">
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min PSF Budget:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>${project.details.minFloorSizePSFBudget.toLocaleString()}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Max PSF Budget:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>${project.details.maxFloorSizePSFBudget.toLocaleString()}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>{project.details.minFloorSize} SQFT</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={{ float: "right", color: "black" }}  >{project.details.periodMoveInDateFormatted}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          <p id="Card_district" style={{ paddingTop: "0" }}>{project.details.district[0].toUpperCase() == "ANY" ? <>{project.details.district[0].toUpperCase()} DISTRICT</> : <>{project.details.district[0].toUpperCase()}</>}</p>
                                          <p id="Card_price">${project.details.minFloorSizePSFBudget.toLocaleString()}</p>
                                          <p id="monthlyRentalBudget">Min PSF Budget</p>
                                        </div>
                                        <div className="Card_SecondOverall">
                                          <div style={{ width: "50%" }}>
                                            <p style={{ color: "#4d4c4c" }}>Property Type</p>
                                            <p id="Card_type" style={{ fontWeight: "bold" }}>{project.details.propertyType}</p>
                                          </div>
                                          <div style={{ width: "50%" }}>
                                            <p style={{ color: "#4d4c4c" }}>Specific Type</p>
                                            <p id="Card_type" style={{ fontWeight: "bold" }}>{project.details.specificPropertyType}</p>
                                          </div>
                                        </div>
                                        <div>
                                          <p style={{ color: "#4d4c4c" }}>Floor Size <span style={{ fontSize: "10px" }}>[PSF]</span></p>
                                          <div style={{ display: "flex", flexDirection: "row", paddingBottom: "5px", paddingTop: "5px" }}>
                                            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                              <div style={{ width: "50%" }}>
                                                <span style={{ color: "#4d4c4c", fontSize: "10px" }}>Min </span>
                                                <span id="Card_type" style={{ fontWeight: "bold" }}>{project.details.minFloorSize}</span>


                                              </div>
                                              <div style={{ width: "50%" }}>
                                                <span style={{ color: "#4d4c4c", fontSize: "10px" }}>Max </span>
                                                <span id="Card_type" style={{ fontWeight: "bold" }}>{project.details.maxFloorSize}</span>

                                              </div>
                                            </div>



                                          </div>
                                        </div>
                                        <div id='residentialEyeball'>
                                          <i class="fa fa-eye" style={{ margin: "0" }}></i> {project.details.eyeball}
                                          <i class="far fa-comment-dots" style={{ marginLeft: "5px" }}></i> {project.details.connections}
                                        </div>
                                        <div id='residentialHeart'>
                                          <button id="heartButton" onClick={(e) => { e.preventDefault(); deteleShortList(project.id) }}><i class="fa-solid fa-heart fa-2x" id="favouriteHeart"></i></button>
                                        </div>

                                      </div>
                                      <div style={{ clear: 'both' }}></div>
                                    </Link>
                                    <div style={{ clear: 'both' }}></div>

                                    <div style={{ clear: 'both' }}></div>
                                  </div>
                                </>
                                :
                                null
                            }
                          </>
                        )
                      })
                      }
                      <div style={{ clear: 'both' }}></div>
                      {shortList.length != 0 && (filterShortList == "All" || filterShortList == "Residential Rent") ? <p style={{ paddingLeft: "1%", fontWeight: "bold", fontSize: "18px" }}>Residential Rent</p> : null}
                      {shortList.length != 0 && (filterShortList == "All" || filterShortList == "Residential Rent") && shortList.map(project => {
                        return (
                          <>
                            {project.details.hasOwnProperty("bedrooms") ?
                              project.details.hasOwnProperty('amenities') ?
                                null :
                                // Residenital RENT
                                <div class="CardResidential">
                                  <Link to={project.details.status == "leasing" ? "/residentialRentDetails/" + project.id : null}>
                                    <div id="residentialRentInfo" style={{ borderBottom: "1px solid #c1c1c1" }}>
                                      <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                      <div id="cardResidentialUserInfo" >

                                        {project.details.propertyAgent == true ? <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>AGENT</p> : <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>DIRECT BUYER</p>}
                                        {project.details.preferredStatus == true ?
                                          <div id="residentialPreferred" style={{ margin: "0", height: "auto", padding: "5px" }}>
                                            <p>Preferred Listing</p>
                                          </div>
                                          :
                                          null
                                        }


                                      </div>
                                      <div style={{ clear: 'both' }}></div>
                                    </div>
                                    {/* <div style={{ clear: 'both' }}></div> */}


                                    <div class="CardContainer">




                                      <div style={{ clear: 'both' }}></div>

                                      <div id="Card_overall">
                                        <p id="Card_district" style={{ paddingTop: "0" }}>{project.details.district == "Any" ? project.details.district.toUpperCase() + " DISTRICT" : project.details.district.toUpperCase()}</p>
                                        <p id="Card_price" style={project.details.status == "closed" ? { color: "grey" } : { color: "black" }}>${project.details.priceOverall.toLocaleString()}</p>
                                        <p id="monthlyRentalBudget">Monthly Rental Budget</p>
                                        {/* <div style={{ clear: 'both' }}></div> */}
                                        {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Furnishing Type:</p><p style={project.details.status == "closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.furnishingType}</p>
                        <div style={{ clear: 'both' }}></div> */}
                                        {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={project.details.status == "closed" ? { float: "right", color: "grey", marginTop: "3%", marginBottom: "3%" } : { float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}  >{project.details.periodMoveInDateFormatted}</p> */}
                                        {/* <div style={{ clear: 'both' }}></div> */}

                                      </div>
                                      <div style={{ display: "flex", flexDirection: "row", paddingBottom: "5px", paddingTop: "5px", height: "20%" }}>
                                        <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                          <i style={{ color: "#717171" }} class="fa-solid fa-house"></i>
                                          <p id="Card_type" style={{ fontWeight: "bold", paddingLeft: "5px" }}>{project.details.propertyTypeResidential}</p>
                                        </div>
                                        <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                          <i style={{ color: "#717171" }} class="fa-solid fa-bed"></i>
                                          <p id="Card_type" style={{ fontWeight: "bold", paddingLeft: "5px" }}>{project.details.bedrooms != "Entire Unit" ? project.details.bedrooms + " Bedrooms" : "Entire Unit"}</p>

                                        </div>
                                      </div>
                                      <div>
                                        <div id='residentialEyeball'>
                                          <i class="fa fa-eye" style={{ margin: "0" }}></i> {project.details.eyeball}
                                          <i class="far fa-comment-dots" style={{ marginLeft: "5px" }}></i> {project.details.connections}
                                        </div>
                                        <div id='residentialHeart'>
                                          <button id="heartButton" onClick={(e) => { e.preventDefault(); deteleShortList(project.id) }}><i class="fa-solid fa-heart fa-2x" id="favouriteHeart"></i></button>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                      </div>

                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                  </Link>
                                  <div style={{ clear: 'both' }}></div>
                                </div>


                              :
                              null
                            }
                          </>
                        )
                      })
                      }
                      <div style={{ clear: 'both' }}></div>
                      {shortList.length != 0 && (filterShortList == "All" || filterShortList == "Residential Sale") ? <p style={{ paddingLeft: "1%", fontWeight: "bold", fontSize: "18px" }}>Residential Sale</p> : null}
                      {shortList.length != 0 && (filterShortList == "All" || filterShortList == "Residential Sale") && shortList.map(project => {
                        return (
                          <>
                            {project.details.hasOwnProperty("bedrooms") ?
                              project.details.hasOwnProperty('amenities') ?
                                <>
                                  {/* residential SALES */}
                                  <div class="CardResidentialSales">
                                    <Link to={project.details.status == "leasing" ? "/residentialPurchaseDetails/" + project.id : null}>
                                      <div id="residentialPurchaseInfo" style={{ borderBottom: "1px solid #c1c1c1" }}>
                                        <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                                        <div id="cardResidentialUserInfo" >
                                          {project.details.propertyAgent == true ? <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>AGENT</p> : <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>DIRECT BUYER</p>}
                                          {/* <p style={project.details.propertyAgent == true ? {fontWeight:"bold", padding:"4px 0px"}:{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ fontWeight: "bold" }}>{project.details.district[0]}</p> */}
                                          {/* <p>{project.details.propertyType}</p> */}
                                          {project.details.preferredStatus == true ?
                                            <div id="residentialPreferred" style={{ margin: "0", height: "auto", padding: "5px" }}>
                                              <p>Preferred Listing</p>
                                            </div>
                                            :
                                            null
                                          }
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                      </div>
                                      <div style={{ clear: 'both' }}></div>
                                      {/* {project.details.preferredStatus == true ?
                      <div id="residentialPreferred" style={{height:"10%"}}>
                        <p>Preferred Listing</p>
                      </div>
                      :
                      <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white", height:"10%" }}>

                      </div>
                    } */}

                                      <div class="CardContainer">

                                        <div id="Card_overall">
                                          <p id="Card_district" style={{ paddingTop: "0" }}>{project.details.district[0].toUpperCase()}</p>
                                          <p id="Card_price">${project.details.budgetOverall.toLocaleString()}</p>
                                          <p id="monthlyRentalBudget">Budget Overall</p>
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Budget Overall:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>${project.details.budgetOverall.toLocaleString()}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.minFloorSize}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Max Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.maxFloorSize}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                          {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={{ float: "right", color: "black", marginTop: "3%", marginBottom: "3%" }}>{project.details.periodMoveInDateFormatted}</p> */}
                                          {/* <div style={{ clear: 'both' }}></div> */}
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", paddingBottom: "5px", paddingTop: "5px", height: "20%" }}>
                                          <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                            <i style={{ color: "#717171" }} class="fa-solid fa-house"></i>
                                            <p id="Card_type" style={{ fontWeight: "bold", paddingLeft: "5px" }}>{project.details.propertyType}</p>
                                          </div>
                                          <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
                                            <i style={{ color: "#717171" }} class="fa-solid fa-bed"></i>
                                            <p id="Card_type" style={{ fontWeight: "bold", paddingLeft: "5px" }}>{project.details.bedrooms[0]}</p>

                                          </div>
                                        </div>
                                        <div id='residentialEyeball'>
                                          <i class="fa fa-eye" style={{ margin: "0" }}></i> {project.details.eyeball}
                                          <i class="far fa-comment-dots" style={{ marginLeft: "5px" }}></i> {project.details.connections}
                                        </div>
                                        <div id='residentialHeart'>
                                          <button id="heartButton" onClick={(e) => { e.preventDefault(); deteleShortList(project.id) }}><i class="fa-solid fa-heart fa-2x" id="favouriteHeart"></i></button>
                                        </div>


                                      </div>
                                      <div style={{ clear: 'both' }}></div>
                                    </Link>
                                    <div style={{ clear: 'both' }}></div>

                                    <div style={{ clear: 'both' }}></div>
                                  </div>

                                </> :
                                null
                              :
                              null
                            }
                          </>
                        )
                      })
                      }


                    </> : null}
                  {messageClick ? <ChatAccount passingBackFunction={() => ClickOnListing()} publicInfo={publicInfo}></ChatAccount> : null}
                  {pendingMessagePage ?
                    <>
                      <div class="flex w-full shadow-lg rounded-3xl">

                        <section class="flex flex-col pt-3 w-full h-full overflow-y-auto">

                          {/* {console.log(allPendingConverstation)} */}
                          <ul class="mt-6">
                            {allPendingConverstation.length != 0 ? allPendingConverstation.map((chatInformation, index) => {
                              if (chatInformation[0].pendingInfo[1].userInfo.chatType == "sellerProfile") {

                                return (
                                  <li class="py-5 border-b px-3 transition hover:bg-indigo-100" >
                                    {console.log(chatInformation[0].pendingInfo[1])}
                                    <p class="text-l font-semibold">Owner Directory</p>
                                    <p class="text-l font-semibold">Address: {chatInformation[0].pendingInfo[1].userInfo.address}</p>
                                    <a class="flex justify-between items-center pt-3">
                                      <div style={{ display: "flex" }}>
                                        <div class="h-10 w-10 rounded-full overflow-hidden">
                                          <img src={chatInformation[0].pendingInfo[1].userInfo.photoURL} loading="lazy" class="h-full w-full object-contain" />
                                        </div>
                                        <div className='pl-2'>
                                          <p class="font-semibold" >@{chatInformation[0].pendingInfo[1].userInfo.displayName}</p>
                                          {chatInformation[0].pendingInfo[1].userInfo.agentProfileDeck != null && chatInformation[0].pendingInfo[1].userInfo.oppUserType == "Agent" ?
                                            <p class="" >CEA: {chatInformation[0].pendingInfo[1].userInfo.agentProfileDeck.CEAAgencyNumber}</p> : null}
                                          <p class="text-md italic text-gray-400">{chatInformation[0].pendingInfo[1].userInfo.oppUserType != "Tenant" && chatInformation[0].pendingInfo[1].userInfo.agentRepresented == true ? "Agent Represented" : chatInformation[0].pendingInfo[1].userInfo.oppUserType}</p>
                                        </div>
                                      </div>
                                      <p class="text-md text-gray-400">{chatInformation[0].lastSeen}</p>
                                    </a>
                                    <div class="text-md italic text-gray-400 truncate" style={{ marginTop: "10px" }}>{chatInformation[0].pendingInfo[1].userInfo.lastMessage}</div>

                                    {chatInformation[0].pendingInfo[1].userInfo.retractStatus == true ?
                                      <><button className='pendingTableRetract' style={{ marginTop: "10px" }} onClick={() => retractConversation(chatInformation[0])}>{chatInformation[0].retractStatus == true ? "Retract" : "Delete"}</button></> :
                                      <>
                                        <button className='pendingTableRetract' style={{ marginTop: "10px" }} onClick={() => acceptConversation(chatInformation[0])}>Accept</button>

                                        <button className='pendingTableRetract' style={{ marginLeft: "10px", marginTop: "10px", backgroundColor: "#33434D", color: "white" }} onClick={() => rejectConversation(chatInformation[0])}>Reject</button></>}
                                  </li>
                                )
                              }
                              else {
                                return (
                                  <>
                                    {console.log(chatInformation[0].pendingInfo[1])}
                                    <li class="py-5 border-b px-3 transition hover:bg-indigo-100" >
                                      <p class="text-l font-semibold">{chatInformation[0].pendingInfo[1].userInfo.chatType == "ResidentialSales" ? "Residential Sale" :
                                        chatInformation[0].pendingInfo[1].userInfo.chatType == "ResidentialRent" ? "Residential Rent" :
                                          chatInformation[0].pendingInfo[1].userInfo.chatType == "CommercialRent" ? "Commercial Rent" : "Commercial Sale"}</p>
                                      <p class="text-l font-semibold">Listing Name: {chatInformation[0].pendingInfo[1].userInfo.listingName}</p>
                                      <a class="flex justify-between items-center pt-3">
                                        {/* <p>{chatInformation[0].pendingInfo[1].userInfo.chatType == }</p> */}
                                        <div style={{ display: "flex" }}>
                                          <div class="h-10 w-10 rounded-full overflow-hidden">
                                            <img src={chatInformation[0].pendingInfo[1].userInfo.photoURL} loading="lazy" class="h-full w-full object-contain" />
                                          </div>
                                          <div className='pl-2'>
                                            <p class="font-semibold">@{chatInformation[0].pendingInfo[1].userInfo.displayName}</p>
                                            {chatInformation[0].pendingInfo[1].userInfo.agentProfile != null && chatInformation[0].pendingInfo[1].userInfo.oppUserType == "Agent" ? <p class="">CEA: {chatInformation[0].pendingInfo[1].userInfo.agentProfile.CEAAgencyNumber}</p> : null}
                                            <p class="text-md italic text-gray-400">{chatInformation[0].pendingInfo[1].userInfo.oppUserType == "Tenant" && chatInformation[0].pendingInfo[1].userInfo.agentRepresented == true ? "Agent Represented" : chatInformation[0].pendingInfo[1].userInfo.oppUserType}</p>
                                          </div>
                                        </div>
                                        <p class="text-md text-gray-400">{chatInformation[0].lastSeen}</p>
                                      </a>
                                      <div class="text-md italic text-gray-400 truncate" style={{ marginTop: "10px" }}>{chatInformation[0].pendingInfo[1].userInfo.lastMessage}</div>
                                      {chatInformation[0].pendingInfo[1].userInfo.oppUserType == "Tenant" ?
                                        <><button className='pendingTableRetract' style={{ marginTop: "10px" }} onClick={() => retractConversation(chatInformation[0])}>{chatInformation[0].retractStatus == true ? "Retract" : "Delete"}</button></> :
                                        <>
                                          <button className='pendingTableRetract' style={{ marginTop: "10px" }} onClick={() => acceptConversation(chatInformation[0])}>Accept</button>

                                          <button className='pendingTableRetract' style={{ marginLeft: "10px", marginTop: "10px", backgroundColor: "#33434D", color: "white" }} onClick={() => rejectConversation(chatInformation[0])}>Reject</button></>}
                                    </li>
                                  </>
                                )
                              }

                            }) : null}
                            {allRejectedConversation.length != 0 ? allRejectedConversation.map((chatInformation, index) => {
                              if (chatInformation[0].pendingInfo[1].userInfo.chatType == "sellerProfile") {
                                return (
                                  <>
                                    <li class="py-5 border-b px-3 transition hover:bg-indigo-100" >
                                      <p class="text-l font-semibold">Owner Directory</p>
                                      <p class="text-l font-semibold">Address: {chatInformation[0].pendingInfo[1].userInfo.address}</p>
                                      <a class="flex justify-between items-center pt-3">
                                        <div style={{ display: "flex" }}>
                                          <div class="h-10 w-10 rounded-full overflow-hidden">
                                            <img src={chatInformation[0].pendingInfo[1].userInfo.photoURL} loading="lazy" class="h-full w-full object-cover" />
                                          </div>
                                          <div className='pl-2'>
                                            <p class="font-semibold" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>@{chatInformation[0].pendingInfo[1].userInfo.displayName}</p>
                                            {chatInformation[0].pendingInfo[1].userInfo.agentProfileDeck != null && chatInformation[0].pendingInfo[1].userInfo.oppUserType == "Agent" ?
                                              <p class="" >CEA: {chatInformation[0].pendingInfo[1].userInfo.agentProfileDeck.CEAAgencyNumber}</p> : null}
                                            <p class="text-md italic text-gray-400">{chatInformation[0].pendingInfo[1].userInfo.oppUserType == "Tenant" && chatInformation[0].pendingInfo[1].userInfo.agentRepresented == true ? "Agent Represented" : chatInformation[0].pendingInfo[1].userInfo.oppUserType}</p>
                                          </div>
                                        </div>
                                        <p class="text-md text-gray-400">{chatInformation[0].lastSeen}</p>
                                      </a>
                                      <div class="text-md italic text-gray-400 truncate" style={{ marginTop: "10px" }}>{chatInformation[0].pendingInfo[1].userInfo.lastMessage}</div>
                                      {chatInformation[0].pendingInfo[1].userInfo.oppUserType == "Tenant" || chatInformation[0].pendingInfo[1].userInfo.chatType == "sellerProfile" ?
                                        <><button className='pendingTableRetract' style={{ marginTop: "10px" }} onClick={() => retractConversation(chatInformation[0])}>{chatInformation[0].retractStatus == true ? "Retract" : "Delete"}</button></> :
                                        null}
                                    </li>
                                  </>
                                )
                              }
                              else {
                                return (
                                  <>
                                    <li class="py-5 border-b px-3 transition hover:bg-indigo-100" >
                                      <p class="text-l font-semibold">{chatInformation[0].pendingInfo[1].userInfo.chatType == "ResidentialSales" ? "Residential Sale" :
                                        chatInformation[0].pendingInfo[1].userInfo.chatType == "ResidentialRent" ? "Residential Rent" :
                                          chatInformation[0].pendingInfo[1].userInfo.chatType == "CommercialRent" ? "Commercial Rent" : "Commercial Sale"}</p>
                                      <p class="text-l font-semibold">Listing Name: {chatInformation[0].pendingInfo[1].userInfo.listingName}</p>
                                      <a class="flex justify-between items-center pt-3">
                                        <div style={{ display: "flex" }}>
                                          <div class="h-10 w-10 rounded-full overflow-hidden">
                                            <img src={chatInformation[0].pendingInfo[1].userInfo.photoURL} loading="lazy" class="h-full w-full object-cover" />
                                          </div>
                                          <div className='pl-2'>
                                            <p class="font-semibold" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>@{chatInformation[0].pendingInfo[1].userInfo.displayName}</p>
                                            {chatInformation[0].pendingInfo[1].userInfo.agentProfile != null && chatInformation[0].pendingInfo[1].userInfo.oppUserType == "Agent" ? <p class="">CEA: {chatInformation[0].pendingInfo[1].userInfo.agentProfile.CEAAgencyNumber}</p> : null}
                                            <p class="text-md italic text-gray-400">{chatInformation[0].pendingInfo[1].userInfo.oppUserType == "Tenant" && chatInformation[0].pendingInfo[1].userInfo.agentRepresented == true ? "Agent Represented" : chatInformation[0].pendingInfo[1].userInfo.oppUserType}</p>
                                          </div>
                                        </div>
                                        <p class="text-md text-gray-400">{chatInformation[0].lastSeen}</p>
                                      </a>
                                      <div class="text-md italic text-gray-400 truncate" style={{ marginTop: "10px" }}>{chatInformation[0].pendingInfo[1].userInfo.lastMessage}</div>
                                      {chatInformation[0].pendingInfo[1].userInfo.oppUserType == "Tenant" || chatInformation[0].pendingInfo[1].userInfo.chatType == "sellerProfile" ?
                                        <><button className='pendingTableRetract' style={{ marginTop: "10px" }} onClick={() => retractConversation(chatInformation[0])}>{chatInformation[0].retractStatus == true ? "Retract" : "Delete"}</button></> :
                                        null}
                                    </li>
                                  </>
                                )
                              }

                            }) : null}


                          </ul>
                        </section>
                        {/* {openPendingRightSide ?
                          <section class="w-8/12 px-4 flex flex-col bg-white rounded-r-3xl">
                            {console.log(allPendingConverstation[openPendingRightSideIndex][0])}
                            <div class="flex justify-between items-center h-48 border-b-2 mb-8">
                              <div class="flex space-x-4 items-center">
                                <div class="h-12 w-12 rounded-full overflow-hidden">
                                  <img src={allPendingConverstation[openPendingRightSideIndex][0].pendingInfo[1].userInfo.photoURL} loading="lazy" class="h-full w-full object-cover" />
                                </div>
                                <div class="flex flex-col">
                                  <h3 class="font-semibold text-lg">{allPendingConverstation[openPendingRightSideIndex][0].pendingInfo[1].userInfo.displayName}</h3>
                                  <p class="text-light text-gray-400">Last Seen: {allPendingConverstation[openPendingRightSideIndex][0].lastSeen}</p>
                                </div>
                              </div>
                              <div>
                                <ul class="flex text-gray-400 space-x-4">
                                  {allPendingConverstation[openPendingRightSideIndex][0].pendingInfo[1].userInfo.oppUserType == "Tenant" ?
                                    <><button className='pendingTableRetract' onClick={() => retractConversation(allPendingConverstation[openPendingRightSideIndex][0])}>{allPendingConverstation[openPendingRightSideIndex][0].retractStatus == true ? "Retract" : "Delete"}</button></>
                                    : null}
                                  <li class="w-6 h-6">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                                  </svg>
                                </li>
                                <li class="w-6 h-6">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                                </li>

                                <li class="w-6 h-6">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                  </svg>
                                </li>
                                <li class="w-6 h-6">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                  </svg>
                                </li>
                                <li class="w-6 h-6">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                                  </svg>
                                </li>
                                </ul>
                              </div>
                            </div>
                            <section>
                              <h1 class="font-bold text-2xl">We need UI/UX designer</h1>
                              <article class="mt-8 text-gray-500 leading-7 tracking-wider">
                                <p>{allPendingConverstation[openPendingRightSideIndex][0].pendingInfo[1].userInfo.lastMessage}</p>

                                <footer class="mt-12">
                                <p>Thanks & Regards,</p>
                                <p>{}</p>
                              </footer>
                              </article>
                              <ul class="flex space-x-4 mt-12">
                                {allPendingConverstation[openPendingRightSideIndex][0].pendingInfo[1].userInfo.oppUserType == "Tenant" ?
                                  null :
                                  <>
                                    <button className='pendingTableRetract' onClick={() => acceptConversation(allPendingConverstation[openPendingRightSideIndex][0])}>Accept</button>

                                    <button className='pendingTableRetract' style={{ marginTop: "10%", backgroundColor: "#33434D", color: "white" }} onClick={() => rejectConversation(allPendingConverstation[openPendingRightSideIndex][0])}>Reject</button></>}
                                <li
                                  class="w-10 h-10 border rounded-lg p-1 cursor-pointer transition duration-200 text-indigo-600 hover:bg-blue-100">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                                      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                                  </svg>
                                </li>
                                <li
                                  class="w-10 h-10 border rounded-lg p-1 cursor-pointer transition duration-200 text-blue-800 hover:bg-blue-100">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                                      d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
                                  </svg>
                                </li>
                                <li
                                  class="w-10 h-10 border rounded-lg p-1 cursor-pointer transition duration-200 text-pink-400 hover:bg-blue-100">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                                      d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
                                  </svg>
                                </li>
                                <li
                                  class="w-10 h-10 border rounded-lg p-1 cursor-pointer transition duration-200 text-yellow-500 hover:bg-blue-100">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                                      d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
                                  </svg>
                                </li>
                              </ul>
                            </section>
                            <section class="mt-6 border rounded-xl bg-gray-50 mb-3">
                              <textarea class="w-full bg-gray-50 p-2 rounded-xl" placeholder="Type your reply here..." rows="3"></textarea>
                              <div class="flex items-center justify-between p-2">
                                <button class="h-6 w-6 text-gray-400">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                  </svg>
                                </button>
                                <button class="bg-purple-600 text-white px-6 py-2 rounded-xl">Reply</button>
                              </div>
                            </section>
                          </section> : <p className='text-md italic text-gray-400' style={{display:"flex", justifyContent:"center", alignItems:"center", marginLeft:"25%"}}>Click to expand inbox message</p>} */}
                      </div>
                    </> : null}
                  {accountClick ?
                    <>
                      <div>
                        <div class="container mx-auto my-5 p-5">
                          <div class="md:flex no-wrap md:-mx-2 ">
                            <div class="w-full md:w-3/12 md:mx-2">
                              <div class="bg-white p-3 border-t-4 border-Slate-600">
                                <div class="image overflow-hidden">
                                  <img class="h-auto w-full mx-auto"
                                    src={currentUserInfo.userPhotoURL}
                                    alt="" />
                                </div>
                                <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">{currentUserInfo.displayName}</h1>
                                <h3 class="text-gray-600 font-lg text-semibold leading-6">@{publicInfo != null ? publicInfo.username : null}</h3>
                                <p class="text-sm text-gray-500 hover:text-gray-600 leading-6">{user.email}</p>
                                <p class="text-sm text-gray-500 hover:text-gray-600 leading-6">{currentUserInfo.phoneNumber}</p>
                                <p class="text-sm text-gray-500 hover:text-gray-600 leading-6">{currentUserInfo.userType[0]}</p>
                                <ul
                                  class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                                  <li class="flex items-center py-3">
                                    <div style={{ display: "inline-flex" }}>
                                      <span style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px" }}>Credits </span>
                                      <button class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-black" onClick={() => setChangeSubscriptionPlan(true)}>
                                        <svg class="h-5 w-5" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="2.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12 5v14M5 12h14" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                      </button>

                                    </div>

                                    <span class="ml-auto">{currentUserInfo.credit}</span>
                                  </li>
                                  <li class="flex items-center py-3">
                                    <div style={{ display: "inline-flex" }}>
                                      <span style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px" }}>Points</span>
                                      <button class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-black" onClick={() => setChangePointsToCredit(true)}>
                                        {/* <svg class="h-5 w-5" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="2.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12 5v14M5 12h14" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg> */}
                                        <i class="fa-solid fa-arrow-right-arrow-left h-5 w-5"></i>
                                      </button>
                                    </div>

                                    <span class="ml-auto">{currentUserInfo.points}</span>

                                  </li>
                                </ul>
                              </div>
                              <div class="my-4"></div>
                              <div class="bg-white p-3 hover:shadow">
                                <div class="flex items-center space-x-3 font-semibold text-gray-900 text-xl leading-8">
                                  <span class="text-green-500">
                                    <svg class="h-5 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none"
                                      viewBox="0 0 24 24" stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                    </svg>
                                  </span>
                                  <span>Settings</span>
                                </div>
                                <div class="grid grid-cols-1">
                                  <div class="text-center my-2">
                                    <button id="passwordTabDeleteBtn" onClick={() => { setAcknowledgeMessage("After the account cancellation, any associated subscriptions will be automatically terminated."); setAcknowledgeNormalDelete(true); }}>Delete account</button>
                                  </div>
                                  <div class="text-center my-2">
                                    {user.providerData[0].providerId == "password" ?
                                      <button id="settingBtn" onClick={() => { setPasswordActive(true) }}>Reset Password</button>
                                      :
                                      <button id="settingBtn" onClick={() => { alert('Password is link with your mail provider') }}>Reset Password</button>
                                    }
                                  </div>
                                  <div class="text-center my-2">
                                    <button id="settingBtn" onClick={() => setReferFriend(true)}>Refer A Friend</button>
                                  </div>
                                  <div class="text-center my-2">
                                    <button id="settingBtn" onClick={() => { setChangeUserName(true) }}>Change Username</button>
                                  </div>
                                  <div class="text-center my-2">
                                    <button id="settingBtn" onClick={() => { setChangeProfilePic(true) }}>Change Profile Picture</button>
                                  </div>
                                  <div class="text-center my-2">
                                    <button id="settingBtn" onClick={() => { setPromoCode(true) }}>Redeem Promotion Code</button>
                                  </div>
                                  {/* <div class="text-center my-2">
                                    <button id="settingBtn">Request Paperwork</button>
                                  </div> */}


                                </div>
                              </div>
                            </div>
                            <div class="w-full md:w-9/12 mx-2 h-auto">
                              <div class="bg-white p-3 shadow-sm rounded-sm">


                                <div>
                                  <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                    <span clas="text-green-500">
                                      <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                      </svg>

                                    </span>
                                    <span class="tracking-wide">My Property Profile</span>
                                    <div>
                                      <button class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-black" onClick={() => setAddProperty(true)}>
                                        <svg class="h-5 w-5" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="2.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12 5v14M5 12h14" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                  <ul class="list-inside space-y-2">
                                    {accountPropertyList.map((eachPropertyProfile, index) => (
                                      <li>
                                        <div className="relative" style={{ float: "right" }}>
                                          <div
                                            className="inline-flex items-center overflow-hidden rounded-md border bg-white"
                                          >
                                            <a
                                              onClick={() => { document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display == "block" ? document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display = "none" : document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display = "block" }}
                                              className="border-e px-4 py-2 text-sm/none text-gray-600 hover:bg-gray-50 hover:text-gray-700"
                                            >
                                              Edit
                                            </a>

                                            <button
                                              className="h-full p-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700"
                                              onClick={() => { document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display == "block" ? document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display = "none" : document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display = "block" }}

                                            >
                                              <span className="sr-only">Menu</span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-4 w-4"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                            </button>
                                          </div>

                                          <div
                                            className="absolute end-0 mt-2 z-10 w-56 divide-y divide-gray-100 rounded-md border border-gray-100 bg-white shadow-lg"
                                            role="menu"
                                            id={eachPropertyProfile[1].propertyCompanyName}
                                            style={{ display: "none" }}
                                          >
                                            <div className="p-2">


                                              <a
                                                onClick={() => { modifyIndivPropertyList(index, eachPropertyProfile[1].propertyType); document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display = "none" }}
                                                className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                                role="menuitem"
                                              >
                                                Edit Profile
                                              </a>


                                            </div>

                                            <div className="p-2">

                                              {/* <form method="POST" action="#">
                <button
                  type="submit"
                  className="flex w-full items-center gap-2 rounded-lg px-4 py-2 text-sm text-red-700 hover:bg-red-50"
                  role="menuitem"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>

                  Delete Profile
                </button>
              </form> */}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="text-Slate-600">{eachPropertyProfile[1].propertyCompanyName}</div>
                                        {eachPropertyProfile[1].propertyType == "Commercial Sale" || eachPropertyProfile[1].propertyType == "Residential Sale" ? eachPropertyProfile[1].sellingPrice != "" ? <div class="text-gray-500 text-xs">${displayNumber(eachPropertyProfile[1].sellingPrice)}</div> : null : null}
                                        {eachPropertyProfile[1].propertyType == "Commercial Rent" || eachPropertyProfile[1].propertyType == "Residential Rent" ? eachPropertyProfile[1].rentalCost != "" ? <div class="text-gray-500 text-xs">${displayNumber(eachPropertyProfile[1].rentalCost)}</div> : null : null}
                                        <div class="text-gray-500 text-xs">{eachPropertyProfile[1].PSFValue} PSF</div>
                                        <div class="text-gray-500 text-xs">{eachPropertyProfile[1].propertyAddress} {eachPropertyProfile[1].propertyUnitNumber}, {eachPropertyProfile[1].postalCode}</div>
                                      </li>
                                    ))}
                                    {accountPropertyListResidential.map((eachPropertyProfile, index) => (
                                      <li>
                                        <div className="relative" style={{ float: "right" }}>
                                          <div
                                            className="inline-flex items-center overflow-hidden rounded-md border bg-white"
                                          >
                                            <a
                                              onClick={() => { document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display == "block" ? document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display = "none" : document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display = "block" }}
                                              className="border-e px-4 py-2 text-sm/none text-gray-600 hover:bg-gray-50 hover:text-gray-700"
                                            >
                                              Edit
                                            </a>

                                            <button
                                              className="h-full p-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700"
                                              onClick={() => { document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display == "block" ? document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display = "none" : document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display = "block" }}

                                            >
                                              <span className="sr-only">Menu</span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-4 w-4"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                            </button>
                                          </div>

                                          <div
                                            className="absolute end-0 mt-2 z-10 w-56 divide-y divide-gray-100 rounded-md border border-gray-100 bg-white shadow-lg"
                                            role="menu"
                                            id={eachPropertyProfile[1].propertyCompanyName}
                                            style={{ display: "none" }}
                                          >
                                            <div className="p-2">


                                              <a
                                                onClick={() => { modifyIndivPropertyList(index, eachPropertyProfile[1].propertyType); document.getElementById(eachPropertyProfile[1].propertyCompanyName).style.display = "none" }}
                                                className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                                role="menuitem"
                                              >
                                                Edit Profile
                                              </a>


                                            </div>

                                            {/* <div className="p-2">

              <form method="POST" action="#">
                <button
                  type="submit"
                  className="flex w-full items-center gap-2 rounded-lg px-4 py-2 text-sm text-red-700 hover:bg-red-50"
                  role="menuitem"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>

                  Delete Profile
                </button>
              </form>
            </div> */}
                                          </div>
                                        </div>
                                        <div class="text-Slate-600">{eachPropertyProfile[1].propertyCompanyName}</div>
                                        {eachPropertyProfile[1].propertyType == "Commercial Sale" || eachPropertyProfile[1].propertyType == "Residential Sale" ? eachPropertyProfile[1].sellingPrice != "" ? <div class="text-gray-500 text-xs">${displayNumber(eachPropertyProfile[1].sellingPrice)}</div> : null : null}
                                        {eachPropertyProfile[1].propertyType == "Commercial Rent" || eachPropertyProfile[1].propertyType == "Residential Rent" ? eachPropertyProfile[1].rentalCost != "" ? <div class="text-gray-500 text-xs">${displayNumber(eachPropertyProfile[1].rentalCost)}</div> : null : null}
                                        <div class="text-gray-500 text-xs">{eachPropertyProfile[1].PSFValue} PSF</div>
                                        <div class="text-gray-500 text-xs">{eachPropertyProfile[1].propertyAddress} {eachPropertyProfile[1].propertyUnitNumber}, {eachPropertyProfile[1].postalCode}</div>
                                      </li>
                                    ))}



                                  </ul>
                                </div>


                              </div>
                              <div class="bg-white p-3 shadow-sm rounded-sm">
                                <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                  <span clas="text-green-500">
                                    {/* <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                      stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                    </svg> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
                                    </svg>

                                  </span>
                                  <span class="tracking-wide">My Business Profile</span>
                                  <div>
                                    <button class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-black" onClick={() => setAddBusiness(true)}>
                                      <svg class="h-5 w-5" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="2.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5v14M5 12h14" stroke-linecap="round" stroke-linejoin="round"></path>
                                      </svg>
                                    </button>
                                  </div>

                                </div>
                                <div class="text-gray-700">

                                  <div style={{ clear: 'both' }}></div>
                                  {currentUserInfo.businessArray.length > 0 ?
                                    currentUserInfo.businessArray.map((eachBusiness) => (
                                      <>
                                        <div className="relative" style={{ float: "right" }}>
                                          <div
                                            className="inline-flex items-center overflow-hidden rounded-md border bg-white"
                                          >
                                            <a
                                              onClick={() => { document.getElementById(eachBusiness.companyName).style.display == "block" ? document.getElementById(eachBusiness.companyName).style.display = "none" : document.getElementById(eachBusiness.companyName).style.display = "block" }}
                                              className="border-e px-4 py-2 text-sm/none text-gray-600 hover:bg-gray-50 hover:text-gray-700"
                                            >
                                              Edit
                                            </a>

                                            <button
                                              className="h-full p-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700"
                                              onClick={() => { document.getElementById(eachBusiness.companyName).style.display == "block" ? document.getElementById(eachBusiness.companyName).style.display = "none" : document.getElementById(eachBusiness.companyName).style.display = "block" }}

                                            >
                                              <span className="sr-only">Menu</span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-4 w-4"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                            </button>
                                          </div>

                                          <div
                                            className="absolute end-0 mt-2 z-10 w-56 divide-y divide-gray-100 rounded-md border border-gray-100 bg-white shadow-lg"
                                            role="menu"
                                            id={eachBusiness.companyName}
                                            style={{ display: "none" }}
                                          >
                                            <div className="p-2">


                                              <a
                                                onClick={() => { changeBusiness(); document.getElementById(eachBusiness.companyName).style.display = "none" }}
                                                className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                                                role="menuitem"
                                              >
                                                Edit Profile
                                              </a>


                                            </div>

                                            <div className="p-2">

                                              {/* <form method="POST" action="#">
                                                <button
                                                  type="submit"
                                                  className="flex w-full items-center gap-2 rounded-lg px-4 py-2 text-sm text-red-700 hover:bg-red-50"
                                                  role="menuitem"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-4 w-4"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                    />
                                                  </svg>

                                                  Delete Profile
                                                </button>
                                              </form> */}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="grid md:grid-cols-2 text-sm">
                                          <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Company Name</div>
                                            <div class="px-4 py-2">{eachBusiness.companyName}</div>
                                          </div>
                                          <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Paid Up Captial</div>
                                            <div class="px-4 py-2">{eachBusiness.paidUpCapital}</div>
                                          </div>
                                          <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Revenue Breakdown</div>
                                            <div class="px-4 py-2">{eachBusiness.walkInBreakdown}% Walk In / {eachBusiness.regularBreakdown}% Regular</div>
                                          </div>
                                          <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Clientele Breakdown</div>
                                            <div class="px-4 py-2">{eachBusiness.localBreakdown}% Local / {eachBusiness.touristBreakdown}% Tourist</div>
                                          </div>
                                          <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Product Business / Pricing of product</div>
                                            <i class="fa fa-file-pdf-o px-4 py-2" style={{ fontSize: "24px", color: "grey", cursor: "pointer", width: "1.25rem", height: "1.25rem" }} onClick={() => window.open(eachBusiness.productPricing)}></i>
                                          </div>
                                          <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Company Deck</div>
                                            <i class="fa fa-file-pdf-o px-4 py-2" style={{ fontSize: "24px", color: "grey", cursor: "pointer", width: "1.25rem", height: "1.25rem" }} onClick={() => window.open(eachBusiness.deckUpload)}></i>
                                          </div>

                                        </div>
                                      </>
                                    ))
                                    : null
                                  }

                                </div>

                              </div>

                              <div class="my-4"></div>


                              {currentUserInfo.userType[0] == "Agent" ?
                                <div class="bg-white p-3 shadow-sm rounded-sm">
                                  <div>
                                    <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                      <span clas="text-green-500">
                                        {/* <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                          stroke="currentColor">
                                          <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                                          <path fill="#fff"
                                            d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                                        </svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                        </svg>

                                      </span>
                                      <span class="tracking-wide">Agent Profile</span>
                                      <div>
                                        <button class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-black" onClick={() => { setActivateCEA(true); setModifyCEA(true) }}>

                                          <PencilSquareIcon class="h-5 w-5"></PencilSquareIcon>
                                        </button>
                                      </div>

                                    </div>
                                    <div class="text-gray-700">
                                      <div class="grid md:grid-cols-2 text-sm">
                                        <div class="grid grid-cols-2">
                                          <div class="px-4 py-2 font-semibold">Name</div>
                                          {console.log(publicInfo)}
                                          <div class="px-4 py-2">{publicInfo != null ? publicInfo.hasOwnProperty('agentProfile') ? publicInfo.agentProfile.CEAName : null : null}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                          <div class="px-4 py-2 font-semibold">CEA Number</div>
                                          <div class="px-4 py-2">{publicInfo != null ? publicInfo.hasOwnProperty('agentProfile') ? publicInfo.agentProfile.CEANumber : null : null}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                          <div class="px-4 py-2 font-semibold">Agency</div>
                                          <div class="px-4 py-2">{publicInfo != null ? publicInfo.hasOwnProperty('agentProfile') ? publicInfo.agentProfile.CEAAgency : null : null}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                          <div class="px-4 py-2 font-semibold">Agency Number</div>
                                          <div class="px-4 py-2">{publicInfo != null ? publicInfo.hasOwnProperty('agentProfile') ? publicInfo.agentProfile.CEAAgencyNumber : null : null}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                          <div class="px-4 py-2 font-semibold">Mobile Number</div>
                                          <div class="px-4 py-2">{publicInfo != null ? publicInfo.hasOwnProperty('agentProfile') ? publicInfo.agentProfile.CEAHP : null : null}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                          <div class="px-4 py-2 font-semibold">Verified</div>
                                          <div class="px-4 py-2">{publicInfo != null ? publicInfo.hasOwnProperty('agentProfile') ? publicInfo.agentProfile.verified == true ? <span className="text-green-600">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth="1.5"
                                              stroke="currentColor"
                                              className="h-6 w-6"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                              />
                                            </svg>
                                          </span>
                                            :
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth="1.5"
                                              stroke="red"
                                              className="h-6 w-6"
                                            >
                                              <circle
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="red"
                                                stroke-width="1"
                                                fill="transparent"
                                              />

                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                              />
                                            </svg>
                                            : null : null}</div>

                                        </div>
                                        {publicInfo != null ? publicInfo.agentProfile.PDFCEA != null
                                          ?
                                          <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Agent Profile PDF</div>
                                            <i class="fa fa-file-pdf-o px-4 py-2" style={{ fontSize: "24px", color: "grey", cursor: "pointer", width: "1.25rem", height: "1.25rem" }} onClick={publicInfo != null & publicInfo.hasOwnProperty('agentProfile') ? publicInfo.agentProfile.PDFCEA != null ? () => { window.open(publicInfo.agentProfile.PDFCEA) } : null : null}></i>
                                          </div> : null : null}


                                      </div>
                                    </div>
                                  </div>
                                </div>
                                : null}
                              <div class="bg-white p-3 shadow-sm rounded-sm">
                                <div>
                                  <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                    <span clas="text-green-500">
                                      {/* <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                                        <path fill="#fff"
                                          d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                                      </svg> */}
                                      <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>

                                    </span>
                                    <span class="tracking-wide">Transaction History</span>
                                  </div>
                                  <div class="text-gray-700">
                                    <ul class="list-inside space-y-2">
                                      {currentUserInfo.transactionHistory.slice().reverse().map((eachTransaction) => {
                                        if (eachTransaction.Type == "Points Activity" || eachTransaction.Type == "Seller Profile"
                                          || eachTransaction.Type == "Conversion Points"
                                          || eachTransaction.Type == "Connections" || eachTransaction.Type == "Listing") {
                                          return (
                                            <li>
                                              <div style={{ float: "left" }}>
                                                <div class="text-Slate-600">{eachTransaction.Activity}</div>
                                                <div class="text-gray-500 text-xs">{eachTransaction.Actions}</div>
                                              </div>
                                              <div style={{ float: "right" }}>
                                                {/* {console.log(eachTransaction.Date.getDate())} */}
                                                <div class="text-gray-500 text-xs">{eachTransaction.Date.toDate().getDate()}/{eachTransaction.Date.toDate().getMonth() + 1}/{eachTransaction.Date.toDate().getFullYear()}</div>
                                              </div>
                                              <div style={{ clear: 'both' }}></div>

                                            </li>
                                          )
                                        }
                                        else if (eachTransaction.Type == "Commercial Activity" || eachTransaction.Type == "Residential Activity"
                                          || eachTransaction.Type == "Advertisement") {
                                          return (
                                            <li>
                                              <div style={{ float: "left" }}>
                                                <div class="text-Slate-600">{eachTransaction.Activity}</div>
                                              </div>
                                              <div style={{ float: "right" }}>
                                                {/* {console.log(eachTransaction.Date.getDate())} */}
                                                <div class="text-gray-500 text-xs">{eachTransaction.Date.toDate().getDate()}/{eachTransaction.Date.toDate().getMonth() + 1}/{eachTransaction.Date.toDate().getFullYear()}</div>
                                              </div>
                                              <div style={{ clear: 'both' }}></div>

                                            </li>
                                          )
                                        }

                                      })}



                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </> : null}
                </div>

              </div>


              <div id="footer"><Footer /></div></>}
        </>
      )
    }

    else {
      return (
        <AdminAccount />
      );
    }
  }
}

