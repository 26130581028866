import React, { useEffect, useState, useRef } from 'react';
import '../css/SignUp.css';
import { GoogleButton } from 'react-google-button';
import { UserAuth } from '../../AuthContent';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase/firebase';
import { doc, getDoc, addDoc, collection } from "firebase/firestore";
import { getMultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, signInWithEmailAndPassword, multiFactor } from 'firebase/auth';
import { RecaptchaVerifier } from 'firebase/auth';
import firebase from '../../firebase/firebase';
import UserTypeModal from './UserTypeModal';
import OTP from './OTP';
import OTPComponent from './OTPComponent';
import Footer from '../Footer';
import Spinner from './Spinner';

export default function SignUp() {

  const { googleSignIn, facebookSignIn, registerSignIn, loginSignIn, user, resetPasswordwithEmail, deleteUserData, needMFAuser, setUser } = UserAuth();
  const [signUp, setSignUp] = useState(false);
  const [userType, setUserType] = useState(false);
  const [OTPResult, setOTPResult] = useState(false);
  const navigate = useNavigate();
  const recaptchaVerifierRef = useRef(null);
  const phoneInfoOptionsRef = useRef(null);
  const verificationIdRef = useRef(null);
  const resolverRef = useRef(null);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [otpInput, setOTP] = useState('');
  const [spinnerActive, setSpinnerActive] = useState(false);
  const [timer, setTimer] = useState(0);
  const [allowReverification, setAllowReverification] = useState(false);
  const [newUserLogin, setNewUserLogin] = useState(false);
  const [newUserLoginReady, setNewUserLoginReady] = useState(false);
  const phoneIndexRef = useRef(null);
  const [phoneNum, setPhoneNum] = useState(null);
  const [choosePrimaryRole, setChoosePrimaryRole] = useState(false);
  const [targetPrimaryRole, setTargetPrimaryRole] = useState(null);
  const [displayPasswordEye, setDisplayPasswordEye] = useState(false);
  const [requirementsFromHome, setRequirementsFromHome] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  const inputRefs = useRef([]);

  // const handleOTPChange = (e, index) => {
  //   const enteredOTP = e.target.value;

  //   // Only allow 6 digits in the OTP input
  //   if (/^\d{0,6}$/.test(enteredOTP)) {
  //     const newOTP = [...otpInput];
  //     newOTP[index] = enteredOTP;
  //     setOTP(newOTP.join(''));

  //     // Move focus to the next input box if available
  //     if (enteredOTP && index < inputRefs.current.length - 1) {
  //       inputRefs.current[index + 1].focus();
  //     }
  //   }
  // };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {


  //     // Set OTPResult to trigger OTP component


  //     // Wait for OTP input
  //     // const otp = await new Promise((resolve) => {
  //     //   const handleOTPInput = (otp) => {

  //     //     resolve(otp);
  //     //   };

  //     //   handleOTPInput(otpInput);
  //     // });

  //     if (otpInput != null) {
  //       setSpinnerActive(true);
  //       const cred = PhoneAuthProvider.credential(verificationIdRef.current, otpInput);
  //       const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

  //       try {
  //         // Complete sign-in.
  //         const userCredential = await resolverRef.current.resolveSignIn(multiFactorAssertion);
  //         setSpinnerActive(false);

  //         // User successfully signed in with the Phone Multi-factor Authentication.
  //       } catch (error) {
  //         // Handle the error when the verification code is incorrect
  //         console.error(error);
  //         setSpinnerActive(false);
  //         alert('Incorrect verification code. Please try again.'); // Display an alert message
  //       }
  //     } else {
  //       // User did not enter the verification code
  //       throw new Error('Verification code not provided.');
  //     }
  //   } catch (error) {
  //     // Handle any errors
  //     console.error(error);
  //   }


  // };


  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn().then(async (MFAError) => {
        // if(MFAError.hasOwnProperty('code')){
        //   if (MFAError.code == "auth/multi-factor-auth-required") {
        //     const resolver = getMultiFactorResolver(auth, MFAError);
        //     resolverRef.current = resolver;

        //     // Find the index of Phone Multi-factor Authentication in the resolver hints
        //     const phoneIndex = resolver.hints.findIndex(hint => hint.factorId === PhoneMultiFactorGenerator.FACTOR_ID);

        //     if (phoneIndex !== -1) {
        //       recaptchaVerifierRef.current = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        //         size: 'invisible',
        //         callback: function (response) {
        //           // reCAPTCHA verification successful
        //         }
        //       });

        //       phoneIndexRef.current = phoneIndex;
        //       phoneInfoOptionsRef.current = {
        //         multiFactorHint: resolver.hints[phoneIndex],
        //         session: resolver.session
        //       };
        //       const phoneAuthProvider = new PhoneAuthProvider(auth);

        //       // Send SMS verification code
        //       const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptionsRef.current, recaptchaVerifierRef.current);
        //       verificationIdRef.current = verificationId;
        //       // const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, verify);
        //       setOTPResult(true);
        //       setTimer(30);
        //       setSpinnerActive(false);

        //     } else {
        //       // Phone Multi-factor Authentication is not available or supported.
        //     }
        //   }
        // }
        // else if (MFAError == "newuser-MFA") {
        //   setNewUserLogin(true);
        // }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      await facebookSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  const handleRegisterSignIn = async () => {

    try {
      await registerSignIn().then((code) => {
      });
    } catch (error) {
      console.log(error);

    }

  };

  const handleLoginSignIn = async () => {
    try {
      setSpinnerActive(true);
      await loginSignIn().then(async (MFAError) => {
        setSpinnerActive(false);
        // if(MFAError.hasOwnProperty('code')){
        //   if (MFAError.code == "auth/multi-factor-auth-required") {
        //     const resolver = getMultiFactorResolver(auth, MFAError);
        //     resolverRef.current = resolver;

        //     // Find the index of Phone Multi-factor Authentication in the resolver hints
        //     const phoneIndex = resolver.hints.findIndex(hint => hint.factorId === PhoneMultiFactorGenerator.FACTOR_ID);

        //     if (phoneIndex !== -1) {
        //       recaptchaVerifierRef.current = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        //         size: 'invisible',
        //         callback: function (response) {
        //           // reCAPTCHA verification successful
        //         }
        //       });

        //       phoneIndexRef.current = phoneIndex;
        //       phoneInfoOptionsRef.current = {
        //         multiFactorHint: resolver.hints[phoneIndex],
        //         session: resolver.session
        //       };
        //       const phoneAuthProvider = new PhoneAuthProvider(auth);

        //       // Send SMS verification code
        //       const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptionsRef.current, recaptchaVerifierRef.current);
        //       verificationIdRef.current = verificationId;
        //       // const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, verify);
        //       setOTPResult(true);
        //       setTimer(30);
        //       setSpinnerActive(false);

        //     } else {
        //       // Phone Multi-factor Authentication is not available or supported.
        //     }
        //   }
        // }

      });
    } catch (error) {
      console.log(error);
      setSpinnerActive(false);

    }
  };

  // const confirmPhoneNumber = (e) =>{
  //   e.preventDefault();
  //   console.log(phoneNum)
  //   if (phoneNum == null || phoneNum.length != 8 || phoneNum == "") {
  //     alert("Invalid number");
  //   }
  //   else {
  //     setSpinnerActive(true);
  //     recaptchaVerifierRef.current = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
  //       size: 'invisible',
  //       callback: function (response) {
  //         // reCAPTCHA verification successful
  //       }
  //     });
  //     console.log(needMFAuser);
  //     multiFactor(needMFAuser).getSession()
  //       .then(function (multiFactorSession) {
  //         // Specify the phone number and pass the MFA session.
  //         phoneInfoOptionsRef.current = {
  //           phoneNumber: `+65${phoneNum}`,
  //           session: multiFactorSession
  //         };

  //         const phoneAuthProvider = new PhoneAuthProvider(auth);

  //         // Send SMS verification code.
  //         return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptionsRef.current, recaptchaVerifierRef.current);
  //       }).then(function (verificationId) {
  //         verificationIdRef.current = verificationId;
  //         setNewUserLoginReady(true);
  //         setTimer(30);
  //         setSpinnerActive(false);
  //         return;
  //       });
  //   }
  // }

  // const handleSubmitNewOTP = () => {
  //   if (otpInput != null) {
  //   // Ask user for the verification code. Then:
  //   const cred = PhoneAuthProvider.credential(verificationIdRef.current, otpInput);
  //   const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

  //   // Complete enrollment.
  //   multiFactor(needMFAuser).enroll(multiFactorAssertion, "mobileNumber")
  //   return 
  //   }
  //   else{
  //     alert("Invalid Code")
  //   }


  // }





  const callSignUp = async () => {
    // setChoosePrimaryRole(true);
    setSignUp(true);

  }

  const checkNewUser = async () => {
    if (user != null) {
      console.log(user);
      var snapDoc = await getDoc(doc(db, `userInfo`, user.uid));
      console.log(snapDoc);
      if (snapDoc.exists()) {
        return false;
      }
      else {
        return true;
      }
    }

  }

  useEffect(() => {
    const checkingUser = async () => {
      var docsnap = await getDoc(doc(db, 'userInfo', user.uid));
      if (docsnap.exists()) {
      }
      else {
        // alert("hacked your way through ah");
        // deleteUserData();

      }
    }
    if (user != null) {
      if (Object.keys(user).length != 0) {
        checkingUser();
      }
    }
  }, [user])

  useEffect(() => {
    if (user != null) {
      checkNewUser().then((res) => {
        if (res == true) {
          setOTPResult(true);// off for facebook

        }
        else {
          navigate('/account');
        }
      })
    }
  }, [user]);

  useEffect(() => {
    const storedData = sessionStorage.getItem('buyerRequirements');

    // Parse the string back to an object
    const buyerRequirements = JSON.parse(storedData);
    if(buyerRequirements){
      sessionStorage.removeItem('buyerRequirements');
      setSignUp(true);
      setRequirementsFromHome({
        type:buyerRequirements.isCommercial ?"commercialSale":"residentialSale",
        requirements: buyerRequirements
      })
      saveHomeRequirements({
        type:buyerRequirements.isCommercial ?"commercialSale":"residentialSale",
        requirements: buyerRequirements
      });
    }
    const storedSellerData = sessionStorage.getItem('sellerRequirements');

    // Parse the string back to an object
    const sellerRequirements = JSON.parse(storedSellerData);
    if(sellerRequirements){
      sessionStorage.removeItem('sellerRequirements');
      setSignUp(true)
      setRequirementsFromHome({
        type:"addPropetyLandlord",
        requirements: sellerRequirements
      })
      saveHomeRequirements({
        type:"addPropetyLandlord",
        requirements: sellerRequirements
      });
    }
    const storedLandlordData = sessionStorage.getItem('landlordRequirements');

    // Parse the string back to an object
    const landlordRequirements = JSON.parse(storedLandlordData);
    if(landlordRequirements){
      sessionStorage.removeItem('landlordRequirements');
      setSignUp(true);
      setRequirementsFromHome({
        type:"addPropetyLandlord",
        requirements: landlordRequirements
      })
      saveHomeRequirements({
        type:"addPropetyLandlord",
        requirements: landlordRequirements
      });
    }
    const storedTenantData = sessionStorage.getItem('tenantRequirements');

    // Parse the string back to an object
    const tenantRequirements = JSON.parse(storedTenantData);
    if(tenantRequirements){
      sessionStorage.removeItem('tenantRequirements');
      setSignUp(true);
      setRequirementsFromHome({
        type: tenantRequirements.isCommercial ?"commercialRent":"residentialRent",
        requirements: tenantRequirements
      })
      saveHomeRequirements({
        type: tenantRequirements.isCommercial ?"commercialRent":"residentialRent",
        requirements: tenantRequirements
      });
    }

    

    
  }, [])

  const saveHomeRequirements = async(requirementsItem) =>{
    await addDoc(collection(db, 'requirementsHome'), requirementsItem);
  }

  // useEffect(() => {
  //   if (timer > 0) {
  //     const interval = setInterval(() => {
  //       setTimer(timer - 1)
  //     }, 1000);
  //     return () => clearInterval(interval);
  //   }
  //   else {
  //     setAllowReverification(true);
  //   }
  // }, [timer]);

  const resetPassword = async () => {
    var email = document.getElementById('emailSignUp').value;
    await resetPasswordwithEmail(email);
  }

  const chooseRole = (side) => {
    setTargetPrimaryRole(side);
    setChoosePrimaryRole(false);
    setSignUp(true);
  }

  const displayPassword = () => {
    document.getElementById("passwordSignUp").type = "text";
    if (signUp) {
      document.getElementById("confirmPasswordSignUp").type = "text";
    }

    setDisplayPasswordEye(true);
  }

  const hidePassword = () => {
    document.getElementById("passwordSignUp").type = "password";
    if (signUp) {
      document.getElementById("confirmPasswordSignUp").type = "password";
    }
    setDisplayPasswordEye(false);
  }

  // const changePhoneNum = (e)=>{
  //   setPhoneNum(e.target.value);
  // }

  // const reSendOTP = async () => {
  //   setSpinnerActive(true);
  //   recaptchaVerifierRef.current = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
  //     size: 'invisible',
  //     callback: function (response) {
  //       // reCAPTCHA verification successful
  //     }
  //   });


  //   phoneInfoOptionsRef.current = {
  //     multiFactorHint: resolverRef.current.hints[phoneIndexRef.current],
  //     session: resolverRef.current.session
  //   };
  //   const phoneAuthProvider = new PhoneAuthProvider(auth);

  //   // Send SMS verification code
  //   const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptionsRef.current, recaptchaVerifierRef.current);
  //   verificationIdRef.current = verificationId;
  //   alert("One Time Pin Sent");
  //   // const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, verify);
  //   // setOTPResult(true);
  //   setTimer(30);
  //   setSpinnerActive(false);
  // }

  return (
    <>
      <Spinner trigger={spinnerActive} setTrigger={setSpinnerActive}></Spinner>
      {/* <div id="recaptcha-container"></div> */}

      {/* <OTPComponent trigger={OTPResult} setTrigger={setOTPResult} setOTPInput={setOTPInput} /> */}

      <OTP trigger={OTPResult} setTrigger={setOTPResult} targetPrimaryRole={targetPrimaryRole} requirementsFromHome={requirementsFromHome}/>
      {/* <UserTypeModal trigger={userType} setTrigger={setUserType} userInfo={user} displayName={signUp == true ? document.getElementById('usernameSignUp') != null ? document.getElementById('usernameSignUp').value : null : null} /> */}
      {/* <div className='Adv'>ADV</div> */}
      <form>
        <div id="sign-in-button"></div>
      </form>
      <div style={{ marginTop: "2%" }} id="signUpDiv">
        <center>
          {choosePrimaryRole ?
            <div class="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
              <div class="absolute bg-black opacity-80 inset-0 z-0"></div>
              <div class="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
                <div class="">
                  <div class="text-center p-5 flex-auto justify-center">

                    <h2 class="text-xl font-bold py-4 ">Are you sure?</h2>
                    <p class="text-sm text-gray-500 px-8">Do you really want to delete your account?
                      This process cannot be undone</p>
                  </div>
                  <div class="p-3  mt-2 text-center space-x-4 md:block">
                    <button class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">
                      Cancel
                    </button>
                    <button class="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600">Delete</button>
                  </div>
                </div>
              </div>
            </div> : null}
          {/* <div id="primaryRoleSubTier" style={choosePrimaryRole ? { display: "block", width: "100%", cursor: "pointer", paddingTop: "30px", paddingBottom: "30px", borderRadius: "10px", fontWeight: "bold", backgroundColor: "#3161906b" } : { display: "none" }} onClick={() => { chooseRole("Property") }}>Property Agent</div>
          <div style={choosePrimaryRole ? { display: "flex", flexDirection: "row", justifyContent: "center", width: "60%" } : { display: "none", flexDirection: "row", justifyContent: "center" }}>
            <div id="lookingSpace" onClick={() => { chooseRole("Looking") }}>Looking For Space<br></br><span style={{ fontSize: "15px" }}>(Buyers/Tenants)</span></div>
            <div id="gotSpace" onClick={() => { chooseRole("Got") }}>Got Space<br></br><span style={{ fontSize: "15px" }}>(Landlords/Master Tenants)</span></div>

          </div> */}
          <div id="primaryRoleSubTier" style={choosePrimaryRole ? { display: "block", width: "100%", cursor: "pointer", paddingTop: "30px", paddingBottom: "30px", borderRadius: "10px", fontWeight: "bold", backgroundColor: "#3161906b" } : { display: "none" }} onClick={() => { chooseRole("Advertiser") }}>Advertise With Us</div>
          {forgetPassword == false ? choosePrimaryRole == false ? <>
            {signUp == false ? <h2 className='className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"'>Welcome to Nextloop</h2> : <h2 className='className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"'>Create new account</h2>}
            <div id='loginContent'>
              {signUp == true ? <><input className='inputSignUp' id="usernameSignUp" placeholder='username' style={signUp == false ? { marginTop: "0px" } : { marginTop: "2%" }}></input><br></br></> : null}
              <input className='inputSignUp' id="emailSignUp" placeholder='Email Address' style={signUp == false ? { marginTop: "2%" } : { marginTop: "0px" }}></input>
              <br></br>
              <div id="passwordDiv" style={{ border: "1px solid #adadad", borderRadius: "10px", marginBottom: "1%", display: "flex", flexDirection: "row" }}>
                <input className='inputSignUp' style={{ width: "90%", border: "none", marginBottom: "0", paddingLeft: "10%" }} type='password' id="passwordSignUp" placeholder='Password'></input>{displayPasswordEye == false ? <i style={{ color: "grey", width: "10%", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }} class="fa-solid fa-eye-slash" onClick={() => displayPassword()}></i> : <i style={{ color: "grey", width: "10%", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }} class="fa-solid fa-eye" onClick={() => hidePassword()}></i>}
              </div>
              {signUp == true ? <><div id="passwordDiv" style={{ border: "1px solid #adadad", borderRadius: "10px", marginBottom: "1%", display: "flex", flexDirection: "row" }}><input className='inputSignUp' style={{ width: "90%", border: "none", marginBottom: "0", paddingLeft: "10%" }} type='password' id="confirmPasswordSignUp" placeholder='Confirm Password'></input>{displayPasswordEye == false ? <i style={{ color: "grey", width: "10%", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }} class="fa-solid fa-eye-slash" onClick={() => displayPassword()}></i> : <i style={{ color: "grey", width: "10%", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }} class="fa-solid fa-eye" onClick={() => hidePassword()}></i>}</div></> : null}
            </div>

            <div id='bottomSignUp'>
              <a id='keywordForget' onClick={() => setForgetPassword(true)}>Forgot Password</a>
              {signUp == true ? <button id='LoginButton' onClick={handleRegisterSignIn}>Sign Up</button> :
                <button id='LoginButton' onClick={handleLoginSignIn}>Log In</button>}
              <div style={{ clear: 'both' }}></div>
            </div>
            <p style={{ color: "grey" }} id="ORWordSignUp">OR</p>
            <GoogleButton style={window.innerWidth > 996 ? { width: "30%", marginTop: "1%" } : { width: "90%", marginTop: "1%" }} onClick={handleGoogleSignIn} />
            {/* <button className="btn--facebook" onClick={() => handleFacebookSignIn()}><i class="fa-brands fa-facebook"></i> Sign in with Facebook</button> */}
            <p style={{ marginTop: "1%" }}>You don't have an account yet? <a className='keywordSignUp' onClick={callSignUp}>Sign Up</a></p>
            <p style={{ marginTop: "5%", marginBottom: "5%" }}>By creating account you agree to our <a className='keywordSignUp' href='/TermOfService'>Terms of Service</a> and <a className='keywordSignUp' href='/PrivacyPolicy'>Privacy Policy</a></p>
            <div className='leftSignUp-divider'></div>
          </> : null :
            <>
              <h1>Welcome to NextLoop</h1>
              <div id='loginContent'>
                <input className='inputSignUp' id="emailSignUp" placeholder='Email Address' style={signUp == false ? { marginTop: "2%" } : { marginTop: "0px" }}></input>
                <br></br>
                <button type="submit" className='resetPasswordBtn' onClick={() => resetPassword()}>Reset Password</button>
              </div>
            </>
          }

        </center>
      </div>

      <div style={{ clear: 'both' }}></div>
      <Footer />
    </>
  );
}
