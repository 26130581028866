import React, { useEffect } from 'react';
import { useState } from 'react';
import '../../App.css';
import '../css/NewProject.css';
import '../css/CropImage.css';

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'




export default function CropImage(props) {

  const [documentImg, setDocumentImg] = useState(null);
  const [landscapeImg, setLandscapeImg] = useState(null);
  const [index, setIndex] = useState(0);
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [output, setOutput] = useState([]);


  const cropImageNow = async (slotNumber) => {

    var URLArr = [];
    URLArr = output;
    if (crop.height != null) {
      var image = document.getElementById('thisImage');
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      );

      // Converting to base64
      const base64Image = canvas.toDataURL('image/jpeg');
      URLArr[slotNumber] = base64Image;
      if (index + 1 < documentImg.length) {
        setIndex(index + 1);
      }
    }
    return URLArr;
  };



  const changeToFile = async () => {


    var dt = new DataTransfer();
    var img = document.getElementsByClassName('cropped');
    var number = props.landscape.length;
    var imgReady = document.getElementsByClassName('landscapeReady');
    for (var i = 0; i < img.length; i++) {
      await fetch(img[i].src)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "cropped_" + number, blob)
          dt.items.add(file);
          number++;
        })
    }
 
    var newLandScape = [...props.landscape, ...dt.files];
    props.setLandscape(newLandScape);
    props.setPortrait([]);
    setDocumentImg(null);
    setLandscapeImg(null);
    setOutput(null);
    setIndex(0);
    props.setTrigger(false);

  }

  const selectCropPhoto = (number) => {
    setIndex(number);
  }

  const closeButton = () => {
    setDocumentImg(null);
    setLandscapeImg(null);
    setOutput(null);
    setIndex(0);
    props.setTrigger(false);
  }

  const renderCropped = async (URLArr) => {

    for (var i = 0; i < URLArr.length; i++) {
      if (URLArr[i] == null) {
        alert("One of your photo is not crop yet");
        setIndex(i);
        return false;
      }
    }

    var div = document.getElementById('AllCroppedImages');
    for (var i = 0; i < URLArr.length; i++) {
      var img = document.createElement('img');
      img.setAttribute('src', URLArr[i]);
      img.setAttribute('class', 'cropped');
      img.setAttribute('width', '50');
      img.setAttribute('height', '50');
      img.setAttribute('hidden', '');
      div.appendChild(img);
    }

    return true;
  }



  useEffect(() => {
   
    if (props.trigger) {
      if (props.portrait.length != 0) {
        setOutput(new Array(props.portrait.length));
        if (props.portrait[0].size != null) {
          var URLArrLocal = [];
          for (var i = 0; i < props.portrait.length; i++) {
            URLArrLocal.push(URL.createObjectURL(props.portrait[i]));
          }
          setDocumentImg(URLArrLocal);
        }
        else {
          for (var i = 0; i < props.portrait.length; i++) {
            props.portrait[i] = props.portrait[i].replaceAll('300', '500').replaceAll('200', '400');
          }
          setDocumentImg(props.portrait);
        }

      }
      if (props.allImage.length != 0) {
        if (props.allImage[0].size != null) {
          var URLArrLocal = [];
          for (var i = 0; i < props.allImage.length; i++) {
            URLArrLocal.push(URL.createObjectURL(props.allImage[i]));
          }
          setLandscapeImg(URLArrLocal);
        }
        else {
          setLandscapeImg(props.allImage);
        }

      }
    }
  }, [props.trigger])


  if (!props.trigger) {
    return;
  }
  else {
    return (
      <>
        <div className='modalNewProject'>
          <div className='modal-contentAcknowledge'>
            <div className='modal-headerNewProject' style={{border:"none"}}>
            <div className="notification-close" onClick={() => closeButton()}>
                ×
              </div>
              <h4 className='modal-titleNewProject'>Crop Portrait Image</h4>
              <div style={{ clear: 'both' }}></div>
            {/* <button className='btn--general' onClick={() => closeButton()}>Close</button> */}
            </div>
 
            <div class='modal-acknowledge'>

              <center>
                <div>
                  {documentImg && (
                    <>
                      <div id="rightCrop">
                      {/* <ReactCrop crop={crop} onChange={setCrop} minWidth={400} minHeight={370} maxHeight={370} maxWidth={400}> */}
                        <ReactCrop crop={crop} onChange={setCrop} minWidth={window.innerWidth <= 900 ?250:400} minHeight={window.innerWidth <= 900 ?200:370} maxHeight={window.innerWidth <= 900 ?200:370} maxWidth={window.innerWidth <= 900 ?250:400}>
                          <img crossorigin="anonymous" id="thisImage" src={documentImg[index]} ></img>
                        </ReactCrop>

                      </div>
                    </>
                  )}
                </div>
                <div id="leftCrop">
                  <div style={{ clear: 'both' }}></div>
                  <div>
                    {documentImg && (documentImg.map((imgSrc, number) => { return (<><img id="croppedImg" src={imgSrc} onClick={() => selectCropPhoto(number)} style={{ margin: "1%", cursor: "pointer" }}></img></>) }))}
                  </div>
                  {landscapeImg && (landscapeImg.map((imgSrc) => { return (<><img className='landscapeReady' width={50} height={50} src={imgSrc} hidden></img></>) }))}
                  <div id='AllCroppedImages'></div>
                
                  
                </div>
              </center>
              <div style={{ clear: 'both' }}></div>
            </div>
            <div className='modal-footerNewProject'>
            {documentImg != null ? index == (documentImg.length - 1) ? <button className='newListingBtn' onClick={() => cropImageNow(index).then((res) => renderCropped(res).then((res) => res == false ? null : changeToFile()))}>Done</button> : <button className='newListingBtn' onClick={() => cropImageNow(index)}>Crop</button> : null}
            </div>
          </div>
        </div>

      </>
    );
  }
}


