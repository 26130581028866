import React, { useEffect, useState, useRef } from 'react';
import { UserAuth } from '../../AuthContent';
import '../css/ZoomPic.css';


export default function ZoomPicture(props) {
    const { user } = UserAuth();




    if (!props.trigger) {
        return;
    }
    else {


        return (
            <>
                <div className='modalNewProject' style={{ zIndex: "3" }}>
                    <div style={{ height: '90vh', maxWidth:'90%', display:"flex", justifyContent:"center", alignItems:"center" }}>
                        <div style={{position:"relative"}}>
                        <img id="zoomPicture" src={props.zoomPicURL} alt="Zoom Pic" />
                        <div
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                padding: '5px',
                                fontSize: '16px',
                                backgroundColor:"black",
                                borderRadius:"30%",
                                color: 'white',
                                cursor: 'pointer',
                                transform: 'translate(50%, -50%)',
                            }}
                            onClick={() => {
                                props.setTrigger(false);
                            }}
                        >
                            X
                        </div>
                        </div>
                    </div>
                    {/* <div style={{ position: "absolute", top: "0", right: "0", color: "black", fontSize: "30px", marginTop: "1%", padding: "1px 10px", cursor: "pointer", background: "white", borderRadius: "20px", marginRight: "1%" }} onClick={() => props.setTrigger(false)}>
                        ×
                    </div>
                    <img style={{ height: "90vh" }} src={props.zoomPicURL}></img> */}
                    {/* <div className='modal-contentNewProject' > */}

                    {/* <div className='modal-headerNewProject'>
                            
                           

                        </div>
                        <div className='modal-bodyNewProject'>
                            
                               

                        </div>
                        <div style={{ clear: 'both' }}></div>
                        <div className='modal-footerNewProject'>
                           

                        </div> */}
                    {/* </div> */}
                </div>
            </>
        );
    }

}