import React from 'react';
import '../css/NewProject.css';


export default function LoaderPage(props) {


  if (!props.trigger) {
    return;
  }
  else {

    return (
      <>
        <div className='modalNewProject' style={{zIndex:"999"}}>
          <div className='modal-contentAcknowledge' style={{backgroundColor:"transparent"}}>
            <div className='modal-headerNewProject' style={{border:"none"}}>
             
            </div>
            <div class='modal-acknowledge'>
            <center><div class="loader"></div></center>
            </div>
            <div className='modal-footerNewProject' style={{border:"none"}}>
             
            </div>
          </div>
        </div>

      </>
    );
  }

}
