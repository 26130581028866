import React, { useEffect, useState, useRef } from 'react';
import { UserAuth } from '../../AuthContent';
import { setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase/firebase';
import { getDownloadURL, ref, uploadBytes, listAll, getStorage, deleteObject, uploadString, getMetadata } from 'firebase/storage';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import Acknowledge from './Acknowledge';



export default function BoostPromotion(props) {
    const { user } = UserAuth();
    const [promoCodeInput, setPromoCodeInput] = useState('');
    const [acknowledge, setAcknowledge] = useState(false);
    const [message, setMessage] = useState(null);

    const closePromoPage = () => {
        props.setTrigger(false);
    }

    const proceedPromotionCode = async () => {
        await getDoc(doc(db, 'promotionCode', 'promoCode')).then(async (promotionCodeDoc) => {
            if (promoCodeInput == promotionCodeDoc.data().tenantCode) {
                console.log(props);
                var start = new Date(document.getElementById('BoostPeriodFrom').value);
                var today = new Date();

                var yesterday = new Date(today);
                start = new Date(Date.parse(start));
                today = new Date(Date.parse(today));
                start.setHours(0, 0, 0, 0);
                today.setHours(0, 0, 0, 0);
                if (props.currentUserInfo.launchedPromotionCode.includes(promoCodeInput) == false) {
                    if (props.path == "commercialIndivRent") {
                        if (start.getTime() === today.getTime()) {
                            await updateDoc(doc(db, 'projects', props.boostListingId), {
                                boost: true,
                                boostLeft: 4,
                                boostStart: start
                            }).then(async () => {
                                await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                                    tier1: props.tierLeft - 1
                                }).then(async () => {
                                    await updateDoc(doc(db, 'userInfo', user.uid), {
                                        launchedPromotionCode: [...props.currentUserInfo.launchedPromotionCode, promoCodeInput]
                                    }).then(() => {
                                        setMessage('Promotion code verified.')
                                        setAcknowledge(true);
                                    })
                                })

                            })
                        }
                        else {
                            await updateDoc(doc(db, 'projects', props.boostListingId), {
                                boost: false,
                                boostLeft: 4,
                                boostStart: start
                            }).then(async () => {
                                await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                                    tier1: props.tierLeft - 1
                                }).then(async () => {
                                    await updateDoc(doc(db, 'userInfo', user.uid), {
                                        launchedPromotionCode: [...props.currentUserInfo.launchedPromotionCode, promoCodeInput]
                                    }).then(() => {
                                        setMessage('Promotion code verified.')
                                        setAcknowledge(true);
                                    })
                                })
                            })
                        }
                    }
                    else if (props.path == "commercialIndivSale") {
                        if (start.getTime() === today.getTime()) {
                            await updateDoc(doc(db, 'listingCommercialPurchase', props.boostListingId), {
                                boost: true,
                                boostLeft: 4,
                                boostStart: start
                            }).then(async () => {
                                await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                                    tier1: props.tierLeft - 1
                                }).then(async () => {
                                    await updateDoc(doc(db, 'userInfo', user.uid), {
                                        launchedPromotionCode: [...props.currentUserInfo.launchedPromotionCode, promoCodeInput]
                                    }).then(() => {
                                        setMessage('Promotion code verified.')
                                        setAcknowledge(true);
                                    })
                                })

                            })
                        }
                        else {
                            await updateDoc(doc(db, 'listingCommercialPurchase', props.boostListingId), {
                                boost: false,
                                boostLeft: 4,
                                boostStart: start
                            }).then(async () => {
                                await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                                    tier1: props.tierLeft - 1
                                }).then(async () => {
                                    await updateDoc(doc(db, 'userInfo', user.uid), {
                                        launchedPromotionCode: [...props.currentUserInfo.launchedPromotionCode, promoCodeInput]
                                    }).then(() => {
                                        setMessage('Promotion code verified.')
                                        setAcknowledge(true);
                                    })
                                })
                            })
                        }
                    }
                    else if (props.path == "residentialIndivRent") {
                        if (start.getTime() === today.getTime()) {
                            await updateDoc(doc(db, 'residentialListing', props.boostListingId), {
                                boost: true,
                                boostLeft: 4,
                                boostStart: start
                            }).then(async () => {
                                await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                                    tier1: props.tierLeft - 1
                                }).then(async () => {
                                    await updateDoc(doc(db, 'userInfo', user.uid), {
                                        launchedPromotionCode: [...props.currentUserInfo.launchedPromotionCode, promoCodeInput]
                                    }).then(() => {
                                        setMessage('Promotion code verified.')
                                        setAcknowledge(true);
                                    })
                                })

                            })
                        }
                        else {
                            await updateDoc(doc(db, 'residentialListing', props.boostListingId), {
                                boost: false,
                                boostLeft: 4,
                                boostStart: start
                            }).then(async () => {
                                await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                                    tier1: props.tierLeft - 1
                                }).then(async () => {
                                    await updateDoc(doc(db, 'userInfo', user.uid), {
                                        launchedPromotionCode: [...props.currentUserInfo.launchedPromotionCode, promoCodeInput]
                                    }).then(() => {
                                        setMessage('Promotion code verified.')
                                        setAcknowledge(true);
                                    })
                                })
                            })
                        }
                    }
                    else if (props.path == "residentialIndivSale") {
                        if (start.getTime() === today.getTime()) {
                            await updateDoc(doc(db, 'residentialListingPurchase', props.boostListingId), {
                                boost: true,
                                boostLeft: 4,
                                boostStart: start
                            }).then(async () => {
                                await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                                    tier1: props.tierLeft - 1
                                }).then(async () => {
                                    await updateDoc(doc(db, 'userInfo', user.uid), {
                                        launchedPromotionCode: [...props.currentUserInfo.launchedPromotionCode, promoCodeInput]
                                    }).then(() => {
                                        setMessage('Promotion code verified.')
                                        setAcknowledge(true);
                                    })
                                })

                            })
                        }
                        else {
                            await updateDoc(doc(db, 'residentialListingPurchase', props.boostListingId), {
                                boost: false,
                                boostLeft: 4,
                                boostStart: start
                            }).then(async () => {
                                await updateDoc(doc(db, 'commonData', 'boostInfo'), {
                                    tier1: props.tierLeft - 1
                                }).then(async () => {
                                    await updateDoc(doc(db, 'userInfo', user.uid), {
                                        launchedPromotionCode: [...props.currentUserInfo.launchedPromotionCode, promoCodeInput]
                                    }).then(() => {
                                        setMessage('Promotion code verified.')
                                        setAcknowledge(true);
                                    })
                                })
                            })
                        }
                    }
                    
                }
                else {
                    console.log(props);
                    alert("You have already redeemed this promotion")
                }

            }
            else {
                alert('Invalid Code');
            }
        })

    }


    if (!props.trigger) {
        return;
    }
    else {

        return (
            <>
                <Acknowledge trigger={acknowledge} setTrigger={setAcknowledge} message={message} ></Acknowledge>
                <div className='modalNewProject' style={{ zIndex: "3" }}>
                    <div className='modal-contentNewProject' >
                        <div className='modal-headerNewProject'>

                            <div className="notification-close" onClick={() => closePromoPage()}>
                                ×
                            </div>

                            <h4 className='modal-titleNewProject'>PROMOTION CODE</h4>
                        </div>

                        <div className='modal-bodyNewProject'>
                            <input style={{ width: "90%", marginLeft: "5%", padding: "5px 10px", borderRadius: "10px" }} placeholder='Key in your promo code' value={promoCodeInput} onChange={(e) => setPromoCodeInput(e.target.value)}></input>

                        </div>

                        <div className='modal-footerNewProject'>
                            <button id="addPropertyBtn" onClick={() => proceedPromotionCode()}>Proceed</button>

                            <div style={{ clear: 'both' }}></div>
                            {/* <button  onClick={() => savePropertyLocation()}>Save</button> */}

                        </div>
                    </div>
                </div>
            </>
        );
    }

}