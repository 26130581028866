import React, { useState } from 'react';
import '../css/NewProject.css';
import { UserAuth } from '../../AuthContent';
import { updateDoc } from 'firebase/firestore';
import firebase from '../../firebase/firebase'
import AcknowledgeNormal from '../../AcknowledgeNormal';
import { updatePassword, reauthenticateWithCredential, reauthenticateWithPopup, updateProfile, getAuth, EmailAuthProvider, deleteUser, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider, confirmPasswordReset } from "firebase/auth";

import { collection, getDocs, onSnapshot, doc, arrayUnion, serverTimestamp, deleteDoc, getDoc, query, where, deleteField, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebase'

export default function ChangePassword(props) {
    const { logOut, user } = UserAuth();
    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
    const [displayPasswordEye, setDisplayPasswordEye] = useState(false);
    const [acknowledgeMessage, setAcknowledgeMessage] = useState("");
    if (!props.trigger) {
        return;
    }
    else {

        const closeAcknowledge = async () => {
            props.setTrigger(false);
        }

        const resetPassword = () => {
            var newPassword = document.getElementById("newPassword").value;
            var confirmPassword = document.getElementById("confirmPassword").value;
            if (newPassword == "" | confirmPassword == "") {
              alert("Invalid Password");
            }
            else {
              const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{12,}$/;
    
              if (passwordRegex.test(newPassword)) {
                // Password is valid, do something
                var password = prompt("Please enter old password for confirmation");
                if (newPassword === confirmPassword) {
                  const credential = firebase.auth.EmailAuthProvider.credential(
                    user.email,
                    password
                  )
                  reauthenticateWithCredential(user, credential)
                    .then(() => {
                      updatePassword(user, newPassword).then(() => { alert("password updated") }).catch((error) => {
                        console.log(error);
                      })
                    }).catch((error) => {
                      console.log(error);
                    })
                }
    
              } else {
                alert("Password must be at least 12 characters long, contain at least one special character (!@#$%^&*) and at least one number (0-9)");
              }
            }
          }
    
        const displayPassword = () => {
            document.getElementById("newPassword").type = "text";
            document.getElementById("confirmPassword").type = "text";
            setDisplayPasswordEye(true);
          }
    
          const hidePassword = () => {
            document.getElementById("newPassword").type = "password";
            document.getElementById("confirmPassword").type = "password";
            setDisplayPasswordEye(false);
          }

          
        return (
            <>
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={acknowledgeMessage} parentTrigger={props.setTrigger}></AcknowledgeNormal>
                <div className='modalNewProject' style={{ zIndex: "10" }}>
                    <div className='modal-contentAcknowledge'>
                        <div className='modal-headerNewProject'>
                            <div className="notification-close" onClick={() => closeAcknowledge()}>
                                ×
                            </div>
                            <h4 className='modal-titleNewProject'>Reset Password</h4>
                        </div>
                        <div class='modal-acknowledge' style={{ textAlign: "left" }}>
                            {/* <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", padding: "3px 8px", borderRadius: "10px",  width: "98%", margin:"1%" }}>
                                <i class="fa-solid fa-exclamation" style={{ color: "#33434d", display: "flex", alignItems: "center", fontSize: "25px", marginRight: "2%" }}></i>

                                <div style={{ display: "flex", textAlign: "left", flexDirection: "column" }}>
                                    <p>Username changes are only available every six months, following any modifications.</p>
                                </div>
                            </div> */}
                            <p className='accountDetailsInfoTitle' style={{ marginTop: "1%", fontWeight: "normal", color: "grey" }}>Password must contain 1 special character, 1 number and 12 characters</p>

                          <p className='accountDetailsInfoTitle'>New Password{displayPasswordEye == false ? <i style={{ color: "grey", marginLeft: "2%", cursor: "pointer" }} class="fa-solid fa-eye-slash" onClick={() => displayPassword()}></i> : <i style={{ color: "grey", marginLeft: "2%", cursor: "pointer" }} class="fa-solid fa-eye" onClick={() => hidePassword()}></i>}</p>
                          <input type='password' id="newPassword" className='accountTabPassword'></input>
                          <p className='accountDetailsInfoTitle'>Confirm Password{displayPasswordEye == false ? <i style={{ color: "grey", marginLeft: "2%", cursor: "pointer" }} class="fa-solid fa-eye-slash" onClick={() => displayPassword()}></i> : <i style={{ color: "grey", marginLeft: "2%", cursor: "pointer" }} class="fa-solid fa-eye" onClick={() => hidePassword()}></i>}</p>
                          <input type='password' id="confirmPassword" className='accountTabPassword'></input>
                            

                        </div>
                        <div className='modal-footerNewProject'>
                            <button className='newListingBtn' onClick={() => resetPassword()}>Confirm</button>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </div>

            </>
        );
    }

}
