import React from 'react';
import '../css/NewProject.css';


export default function Warning(props) {

    const continueWarning = () =>{
        var continueWarningString = props.continueWarning;
        props.setContinueWarningState(continueWarningString.replace("pending ", ''));
        props.setTrigger(false);
    }
  if (!props.trigger) {
    return;
  }
  else {

    return (
      <>
        <div className='modalNewProject' style={{zIndex:"3"}}>
          <div className='modal-contentAcknowledge'>
            <div className='modal-headerNewProject'>
            <div className="notification-close" onClick={() => props.setTrigger(false)}>
                ×
              </div>
              <h4 className='modal-titleNewProject'>REMINDER</h4>
            </div>
            <div class='modal-acknowledge'>
              <p>Your listing is not preferred yet. Fill up all the information on second page to become a preferred listing.</p>
            </div>
            <div className='modal-footerNewProject'>
              {/* <button className='newListingBtn' onClick={() => props.setTrigger(false)}>Close</button> */}
             <button className='newListingBtn' onClick={()=>continueWarning()}>Continue</button>
             <div style={{ clear: 'both' }}></div>
            
            </div>
          </div>
        </div>

      </>
    );
  }

}
