import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/16/solid'; // Ensure you have @heroicons/react installed

// Helper function to get the number of days in a month
const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

// Helper function to get the starting day of the month (0 = Sunday, 1 = Monday, etc.)
const getStartDayOfMonth = (year, month) => {
  return new Date(year, month, 1).getDay();
};

const CalendarComponent = ({ dataArray }) => {
  const [calendarPage, setCalendarPage] = useState(0);
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  const [firstSetCalendar, setFirstSetCalendar] = useState([]);
  const [secondSetCalendar, setSecondSetCalendar] = useState([]);
  const [thirdSetCalendar, setThirdSetCalendar] = useState([]);

  useEffect(() => {
    const firstMonthDays = getDaysInMonth(currentYear, currentMonth);
    const secondMonthDays = getDaysInMonth(currentYear, currentMonth + 1);
    const thirdMonthDays = getDaysInMonth(currentYear, currentMonth + 2);

    const firstMonthData = new Array(firstMonthDays).fill(0);
    const secondMonthData = new Array(secondMonthDays).fill(0);
    const thirdMonthData = new Array(thirdMonthDays).fill(0);

    let dataIndex = 0;

    // Fill the first month
    for (let i = today.getDate() - 1; i < firstMonthDays; i++) {
      if (dataIndex < dataArray.length) {
        firstMonthData[i] = dataArray[dataIndex];
        dataIndex++;
      }
    }

    // Fill the second month
    for (let i = 0; i < secondMonthDays; i++) {
      if (dataIndex < dataArray.length) {
        secondMonthData[i] = dataArray[dataIndex];
        dataIndex++;
      }
    }

    // Fill the third month
    for (let i = 0; i < thirdMonthDays; i++) {
      if (dataIndex < dataArray.length) {
        thirdMonthData[i] = dataArray[dataIndex];
        dataIndex++;
      }
    }

    setFirstSetCalendar(firstMonthData);
    setSecondSetCalendar(secondMonthData);
    setThirdSetCalendar(thirdMonthData);
  }, [dataArray, today]);

  const targetMonth = [
    today.toLocaleString('default', { month: 'long' }),
    new Date(currentYear, currentMonth + 1).toLocaleString('default', { month: 'long' }),
    new Date(currentYear, currentMonth + 2).toLocaleString('default', { month: 'long' })
  ];

  const getCalendarForMonth = (daysInMonth, startDay, monthData) => {
    const calendar = new Array(42).fill(null);
    for (let i = 0; i < daysInMonth; i++) {
      calendar[startDay + i] = monthData[i];
    }
    return calendar;
  };

  const firstMonthStartDay = getStartDayOfMonth(currentYear, currentMonth);
  const secondMonthStartDay = getStartDayOfMonth(currentYear, currentMonth + 1);
  const thirdMonthStartDay = getStartDayOfMonth(currentYear, currentMonth + 2);

  const firstMonthCalendar = getCalendarForMonth(firstSetCalendar.length, firstMonthStartDay, firstSetCalendar);
  const secondMonthCalendar = getCalendarForMonth(secondSetCalendar.length, secondMonthStartDay, secondSetCalendar);
  const thirdMonthCalendar = getCalendarForMonth(thirdSetCalendar.length, thirdMonthStartDay, thirdSetCalendar);

  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-4">
        <ChevronLeftIcon
          className={`h-6 w-6 cursor-pointer ${calendarPage === 0 ? 'text-gray-400 cursor-not-allowed' : ''}`}
          onClick={() => calendarPage > 0 && setCalendarPage((prev) => prev - 1)}
        />
        <p className="text-lg font-semibold">{targetMonth[calendarPage]}</p>
        <ChevronRightIcon
          className={`h-6 w-6 cursor-pointer ${calendarPage === 2 ? 'text-gray-400 cursor-not-allowed' : ''}`}
          onClick={() => calendarPage < 2 && setCalendarPage((prev) => prev + 1)}
        />
      </div>
      <div className="grid grid-cols-7 gap-0 text-center">
        <div className="font-bold">Sun</div>
        <div className="font-bold">Mon</div>
        <div className="font-bold">Tue</div>
        <div className="font-bold">Wed</div>
        <div className="font-bold">Thu</div>
        <div className="font-bold">Fri</div>
        <div className="font-bold">Sat</div>
        {calendarPage === 0 && firstMonthCalendar.map((quantity, index) => (
          <div key={index} className={`relative border p-2 h-16 ${quantity === 0 ? 'bg-gray-200' : ''}`}>
            <div className="absolute inset-0 flex items-center justify-center">
              {quantity !== null ? index + 1 - firstMonthStartDay : ''}
            </div>
            <div className="absolute bottom-1 right-1 text-xs">
              {quantity !== null && quantity !== 0 ? `${quantity} left` : ''}
            </div>
          </div>
        ))}
        {calendarPage === 1 && secondMonthCalendar.map((quantity, index) => (
          <div key={index} className={`relative border p-2 h-16 ${quantity === 0 ? 'bg-gray-200' : ''}`}>
            <div className="absolute inset-0 flex items-center justify-center">
              {quantity !== null ? index + 1 - secondMonthStartDay : ''}
            </div>
            <div className="absolute bottom-1 right-1 text-xs">
              {quantity !== null && quantity !== 0 ? `${quantity} left` : ''}
            </div>
          </div>
        ))}
        {calendarPage === 2 && thirdMonthCalendar.map((quantity, index) => (
          <div key={index} className={`relative border p-2 h-16 ${quantity === 0 ? 'bg-gray-200' : ''}`}>
            <div className="absolute inset-0 flex items-center justify-center">
              {quantity !== null ? index + 1 - thirdMonthStartDay : ''}
            </div>
            <div className="absolute bottom-1 right-1 text-xs">
              {quantity !== null && quantity !== 0 ? `${quantity} left` : ''}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarComponent;
