import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import '../css/Product.css';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { collection, getDocs, onSnapshot, doc, query, orderBy, startAfter, limit, getDoc, setDoc, deleteDoc, where, Timestamp, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../../firebase/firebase'
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Footer from '../Footer';
import Notification from './Notification';
import ZoomPicture from './ZoomPictures';



export default function ProductsCommercialPurchase() {
  const searchbarRef = useRef(null);
  const searchbarRefMobile = useRef(null);
  const moreFilterRef = useRef(null);
  const [combineAllData, SetCombineAllData] = useState([]);
  const [currentListing, setCurrentListing] = useState([]);
  const [shortList, setShortList] = useState(null);
  const { user, deleteUserData } = UserAuth();
  const [eyeBallAdvListingID, setEyeballAdvListingID] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [minPriceBud, setMinPriceBud] = useState(0);
  const [locationDetails, setLocationDetails] = useState(null);
  const [minLeaseNum, setMinLeaseNum] = useState("NA");
  const [minLeasePeriod, setMinLeasePeriod] = useState('Days');
  const [maxPriceBud, setMaxPriceBud] = useState("NA");
  const [maxLeasePeriod, setMaxLeasePeriod] = useState('Days');
  const [showFilters, setShowFilters] = useState(false);
  const [maxLeaseNum, setMaxLeaseNum] = useState("NA");
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [filterProject, setFilterProject] = useState(null);
  const [totalProject, setTotalProject] = useState(0);
  const [propertyType, setPropertyType] = useState('All');
  const [filterProjectSort, setFilterProjectSort] = useState(null);
  const [filterName, setFilterName] = useState(null);
  const [minOption, setMinOption] = useState(true);
  const [isAll, setIsAll] = useState(true);
  const [isFB, setIsFB] = useState(false);
  const [isRetail, setIsRetail] = useState(false);
  const [isService, setIsService] = useState(false);
  const [isStorage, setIsStorage] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [isAny, setIsAny] = useState(true);
  const [is30Year, setIs30Year] = useState(false);
  const [is110Year, setIs110Year] = useState(false);
  const [is60Year, setIs60Year] = useState(false);
  const [is9999Year, setIs9999Year] = useState(false);
  const [is999Year, setIs999Year] = useState(false);
  const [is99Year, setIs99Year] = useState(false);
  const [isFreehold, setIsFreehold] = useState(false);
  const [isTenureNA, setIsTenureNA] = useState(false);
  const [zoomPic, setZoomPic] = useState(false);
  const [zoomPicURL, setZoomPicURL] = useState(null);
  const [isAllPropertyFilterSales, setIsAllPropertyFilterSales] = useState(true);
  const [isCommercialPropertyFilterSales, setIsCommercialPropertyFilterSales] = useState(false);
  const [isIndustrialPropertyFilterSales, setIsIndustrialPropertyFilterSales] = useState(false);
  const [isLandPropertyFilterSales, setIsLandPropertyFilterSales] = useState(false);
  const [isIndustrialLandUsePropertyFilterSales, setIsIndustrialLandUsePropertyFilterSales] = useState(false);




  useEffect(() => {
    // getFirstPage();
    getAllData().then((res) => {
      SetCombineAllData(res);
      setTotalProject(res.length);
      checkForPreviousPage(res);
    });

  }, []);

  useEffect(() => {
    const dataString = sessionStorage.getItem('filterHome');
    const filterHomeData = JSON.parse(dataString);
    if (filterHomeData != null && combineAllData.length != 0) {
      searchApply();
    }
  }, [combineAllData])

  useEffect(() => {

    if (!user) {
    }
    else {
      if (user.uid != undefined) {
        const subscriber = onSnapshot(collection(db, `userInfo/${user.uid}/shortList`), (doc) => {
          setShortList(doc.docs)
        });
        return (() => { subscriber() });
      }
    }

  }, [user]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (window.innerWidth <= 540) {
        if (searchbarRefMobile.current && !searchbarRefMobile.current.contains(event.target)) {
          if (moreFilterRef.current && moreFilterRef.current.contains(event.target)) {
            return;
          }
          setFilterName(null);
          setShowFilters(false);
        }
      }
      else {
        if (searchbarRef.current && !searchbarRef.current.contains(event.target)) {
          if (moreFilterRef.current && moreFilterRef.current.contains(event.target)) {
            return;
          }
          setFilterName(null);
          setShowFilters(false);
        }
      }
    }



    document.addEventListener('mousedown', handleClickOutside);
    const filterButton = document.querySelector('.btn--filter');
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      filterButton.removeEventListener('click', handleClick);
    };
  }, [showFilters]);


  useEffect(() => {
    const checkingUser = async () => {
      var docsnap = await getDoc(doc(db, 'userInfo', user.uid));
      if (docsnap.exists()) {
      }
      else {
        // alert("hacked your way through ah");
        deleteUserData();

      }
    }
    if (user != null) {
      if (Object.keys(user).length != 0) {
        checkingUser();
      }
    }
  }, [user])

  const handleAllCheckBoxChange = () => {
    setIsAll(!isAll);
    setIsFB(false);
    setIsRetail(false);
    setIsService(false);
    setIsOther(false);
    setIsStorage(false);
  }

  const handleFBCheckBoxChange = () => {
    setIsAll(false);
    setIsFB(!isFB);
  }

  const handleRetailCheckBoxChange = () => {
    setIsAll(false);
    setIsRetail(!isRetail);
  }

  const handleServiceCheckBoxChange = () => {
    setIsAll(false);
    setIsService(!isService);
  }

  const handleStorageCheckBoxChange = () => {
    setIsAll(false);
    setIsStorage(!isStorage);
  }

  const handleOtherCheckBoxChange = () => {
    setIsAll(false);
    setIsOther(!isOther);
  }

  const handlesMinPriceBud = (event) => {
    if (event.target.value == "") {
      setMinPriceBud(0);
    }
    else {
      setMinPriceBud(event.target.value);
    }

  }

  const handleLocationDetails = (event) => {
    setLocationDetails(event.target.value);
  }

  const handleMinLeaseNum = (event) => {
    setMinLeaseNum(event.target.value);
  }

  const handleMinLeasePeriod = (event) => {
    setMinLeaseNum(1);
    setMinLeasePeriod(event.target.value);
  }

  const handleMaxPriceBud = (event) => {
    if (event.target.value == "") {
      setMaxPriceBud("NA");
    }
    else {
      setMaxPriceBud(event.target.value);
    }

  }

  const handleMaxLeaseNum = (event) => {
    setMaxLeaseNum(event.target.value);
  }

  const handleMaxLeasePeriod = (event) => {
    setMaxLeaseNum(1);
    setMaxLeasePeriod(event.target.value);
  }

  const handleClick = (event) => {
    if (event.target.id == "BudgetFilterDropDown") {
      event.target.id = "BudgetFilter";
    }
    else if (event.target.id == "TenureFilterDropDown") {
      event.target.id = "TenureFilter";
    }
    else if (event.target.id == "PropertyFilterSalesDropdown") {
      event.target.id = "PropertyFilterSales";
    }

    if (event.target.id == "") {
      return;
    }
    else {
      if (event.target.id == filterName) {
        setFilterName(null);
        // setShow(!show);
        setShowFilters(false);
        // setTarget(event.target);
      }
      else if (filterName == null) {
        setFilterName(event.target.id);
        // setShow(!show);
        setShowFilters(true);
        // setTarget(event.target);
      }
      else {
        setFilterName(event.target.id);
      }
    }
  };

  const handleSortingOrder = (event) => {

    var sortingFilteringProject = filterProject;

    if (event.target.value == "Adescending") {
      sortingFilteringProject.sort((a, b) => a.details.minFloorSizePSFBudget - b.details.minFloorSizePSFBudget);
      setFilterProject(sortingFilteringProject);
      setCurrentPage(1);
      setCurrentListing(sortingFilteringProject.slice(0, 40));
    }
    else if (event.target.value == "Descending") {
      sortingFilteringProject.sort((a, b) => b.details.minFloorSizePSFBudget - a.details.minFloorSizePSFBudget);
      setFilterProject(sortingFilteringProject);
      setCurrentPage(1);
      setCurrentListing(sortingFilteringProject.slice(0, 40));
    }
    else {
      setFilterProject(filterProjectSort);
      setCurrentPage(1);
      setCurrentListing(filterProjectSort.slice(0, 40));
    }


  }


  // useEffect(() => {
  //   if (showFilters) {
  //     handlePropertyClick(propertyType);
  //   }
  // }, [showFilters])

  const onPageChanged = (event, value) => {
    var all = [...combineAllData];

    setCurrentPage(value);
    setCurrentListing(all.slice(((value - 1) * 40), (((value - 1) * 40) + 40)));
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth'
    // });
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    var savedValue = sessionStorage.getItem('currentTab');
    if (savedValue != null) {
      sessionStorage.removeItem('currentTab');
    }
  }, [])


  const searchApply = () => {
    setShowFilters(false);
    setFilterName(null);
    var filteringProject = [...combineAllData];
    const dataString = sessionStorage.getItem('filterHome');
    const filterHomeData = JSON.parse(dataString);
    if (filterHomeData != null) {
      sessionStorage.removeItem('filterHome');
    }

    searchBarFilter(filteringProject, filterHomeData).then((res) => searchPropertyType(res, filterHomeData).then((res) => searchPrice(res, filterHomeData)
      .then((res) => searchLease(res, filterHomeData).then((res) => {
        if (filterHomeData != null) {
          setPropertyType(filterHomeData.propertyType);
          setMinPriceBud(filterHomeData.minPriceBud);
          setLocationDetails(filterHomeData.locationDetails);
          setMinLeaseNum(filterHomeData.minLeaseNum);
          setMinLeasePeriod(filterHomeData.minLeasePeriod);
          setMaxLeaseNum(filterHomeData.maxLeaseNum);
          setMaxLeasePeriod(filterHomeData.maxLeasePeriod);
          setMaxPriceBud(filterHomeData.maxPriceBud);
        }
        setShow(false);
        setFilterProjectSort(res);
        setFilterProject(res);
        setCurrentPage(1);
        setCurrentListing(res.slice(0, 40));
      }))
    ));
  }

  const searchBarFilter = (filteringProject, filterHomeData) => {
    return new Promise((resolve, reject) => {
      if (locationDetails != null || filterHomeData != null) {
        if (filterHomeData == null) {
          var searchBarLocation = locationDetails.toUpperCase();
          if (searchBarLocation == "D1") {
            searchBarLocation = "D01";
            setLocationDetails("D01");
          }
          else if (searchBarLocation == "D2") {
            searchBarLocation = "D02";
            setLocationDetails("D02");
          }
          else if (searchBarLocation == "D3") {
            searchBarLocation = "D03";
            setLocationDetails("D03");
          }
          else if (searchBarLocation == "D4") {
            searchBarLocation = "D04";
            setLocationDetails("D04");
          }
          else if (searchBarLocation == "D5") {
            searchBarLocation = "D05";
            setLocationDetails("D05");
          }
          else if (searchBarLocation == "D6") {
            searchBarLocation = "D06";
            setLocationDetails("D06");
          }
          else if (searchBarLocation == "D7") {
            searchBarLocation = "D07";
            setLocationDetails("D07");
          }
          else if (searchBarLocation == "D8") {
            searchBarLocation = "D08";
            setLocationDetails("D08");
          }
          else if (searchBarLocation == "D9") {
            searchBarLocation = "D09";
            setLocationDetails("D09");
          }
          for (var i = filteringProject.length - 1; i >= 0; i--) {
            if ('advertiseName' in filteringProject[i]) {
              filteringProject.splice(i, 1);
            }
            else {
              if (((filteringProject[i].details.district[0]).toUpperCase()).search(searchBarLocation) < 0 && ((filteringProject[i].details.location).toUpperCase()).search(searchBarLocation) < 0 && ((filteringProject[i].details.listingName).toUpperCase()).search(searchBarLocation) < 0) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
            }

          }
        }
        else {
          if (filterHomeData.locationDetails != null) {
            var searchBarLocation = filterHomeData.locationDetails.toUpperCase();
            if (searchBarLocation == "D1") {
              searchBarLocation = "D01";
              setLocationDetails("D01");
            }
            else if (searchBarLocation == "D2") {
              searchBarLocation = "D02";
              setLocationDetails("D02");
            }
            else if (searchBarLocation == "D3") {
              searchBarLocation = "D03";
              setLocationDetails("D03");
            }
            else if (searchBarLocation == "D4") {
              searchBarLocation = "D04";
              setLocationDetails("D04");
            }
            else if (searchBarLocation == "D5") {
              searchBarLocation = "D05";
              setLocationDetails("D05");
            }
            else if (searchBarLocation == "D6") {
              searchBarLocation = "D06";
              setLocationDetails("D06");
            }
            else if (searchBarLocation == "D7") {
              searchBarLocation = "D07";
              setLocationDetails("D07");
            }
            else if (searchBarLocation == "D8") {
              searchBarLocation = "D08";
              setLocationDetails("D08");
            }
            else if (searchBarLocation == "D9") {
              searchBarLocation = "D09";
              setLocationDetails("D09");
            }
            else {
              setLocationDetails(filterHomeData.locationDetails);
            }
            for (var i = filteringProject.length - 1; i >= 0; i--) {
              if ('advertiseName' in filteringProject[i]) {
                filteringProject.splice(i, 1);
              }
              else {
                if (((filteringProject[i].details.district[0]).toUpperCase()).search(searchBarLocation) < 0 && ((filteringProject[i].details.location).toUpperCase()).search(searchBarLocation) < 0 && ((filteringProject[i].details.listingName).toUpperCase()).search(searchBarLocation) < 0) {
                  filteringProject.splice(i, 1);
                  // delete filteringProject[i];
                }
              }

            }
          }

        }

      }
      resolve(filteringProject);
    })
  }

  const searchPropertyType = (filteringProject, filterHomeData) => {
    return new Promise((resolve, reject) => {
      if (isAllPropertyFilterSales == false || filterHomeData != null) {
        for (var i = filteringProject.length - 1; i >= 0; i--) {
          if ('advertiseName' in filteringProject[i]) {
            filteringProject.splice(i, 1);
          }
          else {
            if (filterHomeData != null) {
              if (filterHomeData.propertyType.includes("All") == false) {
                setIsAllPropertyFilterSales(false);
                if (filterHomeData.propertyType.includes('Commercial')) {
                  setIsCommercialPropertyFilterSales(true);
                }
                if (filterHomeData.propertyType.includes('Industrial')) {
                  setIsIndustrialPropertyFilterSales(true);
                }
                if (filterHomeData.propertyType.includes('Land')) {
                  setIsLandPropertyFilterSales(true);
                }
                if (filterHomeData.propertyType.includes('Industrial Land Use')) {
                  setIsIndustrialLandUsePropertyFilterSales(true);
                }
                if (filterHomeData.propertyType.includes(filteringProject[i].details.propertyType) == false) {
                  filteringProject.splice(i, 1);
                }
              }
              else {
                setIsAllPropertyFilterSales(true)
              }
            }
            else {
              var searchPropertyArr = [];
              if (isCommercialPropertyFilterSales) {
                searchPropertyArr.push("Commercial");
              }
              if (isIndustrialPropertyFilterSales) {
                searchPropertyArr.push("Industrial");
              }
              if (isLandPropertyFilterSales) {
                searchPropertyArr.push("Land");
              }
              if (isIndustrialLandUsePropertyFilterSales) {
                searchPropertyArr.push("Industrial land use");
              }

              if (searchPropertyArr.includes(filteringProject[i].details.propertyType) == false) {
                filteringProject.splice(i, 1);
              }


            }
          }



        }
      }
      resolve(filteringProject);
    })
  }

  const searchPrice = (filteringProject, filterHomeData) => {
    return new Promise((resolve, reject) => {
      if (minPriceBud > 0 || maxPriceBud > 0 || filterHomeData != null) {
        for (var i = filteringProject.length - 1; i >= 0; i--) {
          if ('advertiseName' in filteringProject[i]) {
            filteringProject.splice(i, 1);
          }
          else {
            if (filterHomeData == null) {

              if (parseInt(minPriceBud) != "NA" && maxPriceBud != "NA" && (parseInt(filteringProject[i].details.minFloorSizePSFBudget) < parseInt(minPriceBud) || parseInt(filteringProject[i].details.maxFloorSizePSFBudget) > parseInt(maxPriceBud))) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
              else if (parseInt(minPriceBud) != "NA" && parseInt(filteringProject[i].details.minFloorSizePSFBudget) < parseInt(minPriceBud) && maxPriceBud == "NA") {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
              else if (parseInt(maxPriceBud) != "NA" && parseInt(filteringProject[i].details.maxFloorSizePSFBudget) > parseInt(maxPriceBud) && minPriceBud == "NA") {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
            }
            else {


              if (parseInt(filterHomeData.minPriceBud) != "NA" && parseInt(filterHomeData.maxPriceBud) != "NA" && (parseInt(filteringProject[i].details.minFloorSizePSFBudget) < parseInt(filterHomeData.minPriceBud) || parseInt(filteringProject[i].details.maxFloorSizePSFBudget) > parseInt(filterHomeData.maxPriceBud))) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
              else if (parseInt(filterHomeData.minPriceBud) != "NA" && parseInt(filteringProject[i].details.minFloorSizePSFBudget) < parseInt(filterHomeData.minPriceBud) && parseInt(filterHomeData.maxPriceBud) <= 0) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
              else if (parseInt(filterHomeData.maxPriceBud) != "NA" && parseInt(filteringProject[i].details.maxFloorSizePSFBudget) > parseInt(filterHomeData.maxPriceBud) && parseInt(filterHomeData.minPriceBud) <= 0) {
                filteringProject.splice(i, 1);
                // delete filteringProject[i];
              }
            }
          }


        }
      }
      resolve(filteringProject);
    })
  }

  const searchLease = (filteringProject, filterHomeData) => {
    return new Promise((resolve, reject) => {


      if (isAny == false || filterHomeData != null) {
        var newFilteringIndexLease = [];

        for (var i = filteringProject.length - 1; i >= 0; i--) {
          console.log(filteringProject[i])
          if ('advertiseName' in filteringProject[i]) {
            filteringProject.splice(i, 1);
          }
          else {
            if (filterHomeData != null) {
              if (filterHomeData.tenure.includes("Any") == false) {
                setIsAny(false);
                if (filterHomeData.tenure.includes('30-year leasehold')) {
                  setIs30Year(true);
                }
                if (filterHomeData.tenure.includes('60-year leasehold')) {
                  setIs60Year(true);
                }
                if (filterHomeData.tenure.includes('99-year leasehold')) {
                  setIs99Year(true);
                }
                if (filterHomeData.tenure.includes('110-year leasehold')) {
                  setIs110Year(true);
                }
                if (filterHomeData.tenure.includes('999-year leasehold')) {
                  setIs999Year(true);
                }
                if (filterHomeData.tenure.includes('9999-year leasehold')) {
                  setIs9999Year(true);
                }
                if (filterHomeData.tenure.includes('Freehold')) {
                  setIsFreehold(true);
                }
                if (filterHomeData.tenure.includes('Not Applicable')) {
                  setIsTenureNA(true);
                }

                for (var a = 0; a < filteringProject[i].details.tenure.length; a++) {
                  if (filterHomeData.tenure.includes(filteringProject[i].details.tenure[a]) == false) {
                    filteringProject.splice(i, 1);
                    break;
                  }
                }
              }
              else {
                setIsAny(true);
              }
            }
            else {
              var tenureArr = [];
              if (is30Year) {
                tenureArr.push("30-year leasehold");
              }
              if (is60Year) {
                tenureArr.push("60-year leasehold");
              }
              if (is99Year) {
                tenureArr.push("99-year leasehold");
              }
              if (is110Year) {
                tenureArr.push("110-year leasehold");
              }
              if (is999Year) {
                tenureArr.push("999-year leasehold");
              }
              if (is9999Year) {
                tenureArr.push("9999-year leasehold");
              }
              if (isFreehold) {
                tenureArr.push("Freehold");
              }
              if (isTenureNA) {
                tenureArr.push("Not Applicable");
              }

              for (var a = 0; a < tenureArr.length; a++) {
                // console.log(filteringProject[i].details.tenure, `looking for ${tenureArr[a]}`)
                if (filteringProject[i].details.tenure.includes('Any')) {

                }
                else if (filteringProject[i].details.tenure.includes(tenureArr[a]) == false) {
                  filteringProject.splice(i, 1);
                  break;
                }
              }
            }
          }



        }
      }
      resolve(filteringProject);

    })
  }

  useEffect(() => {
    if (eyeBallAdvListingID.length != 0) {
      activateAdvEyeball();
    }
  }, [eyeBallAdvListingID])

  const activateAdvEyeball = async () => {
    for (var i = 0; i < eyeBallAdvListingID.length; i++) {
      var newEyeballStat = [...eyeBallAdvListingID[i].details.eyeballStat];
      newEyeballStat[0] = newEyeballStat[0] + 1;
      await updateDoc(doc(db, 'advertiseListing', eyeBallAdvListingID[i].id), {
        eyeball: eyeBallAdvListingID[i].details.eyeball + 1,
        eyeballStat: newEyeballStat
      })
    }
  }




  const getAllData = () => {
    return new Promise(async function (resolve, reject) {
      var allData = []
      var firstData = [];
      var needUpEyeballAdv = [];
      var boostList = [];
      var conversationRateListing = [];
      var preferredListing = [];
      var normalListing = [];


      const allListing = await getDocs(collection(db, 'listingCommercialPurchase'));
      const activatedAdvertise = query(collection(db, "advertiseListing"), where("status", "==", "leasing"));
      // Get all the documents that match the query criteria
      const activatedAdvertiseSnapshot = await getDocs(activatedAdvertise);

      var activatedAdvertiseListing = activatedAdvertiseSnapshot.docs

      // for (let i = activatedAdvertiseListing.length - 1; i > 0; i--) {
      //   const j = Math.floor(Math.random() * (i + 1));
      //   [activatedAdvertiseListing[i], activatedAdvertiseListing[j]] = [activatedAdvertiseListing[j], activatedAdvertiseListing[i]];
      // }



      for (const indiv of allListing.docs) {
        if (indiv.data().status === 'leasing') {
          var commercialPurchase = indiv.data();
          var periodMoveInDate = new Date(commercialPurchase.moveInPeriod.seconds * 1000);
          var formattedDate = periodMoveInDate.getDate() + "/" + (periodMoveInDate.getMonth() + 1) + "/" + periodMoveInDate.getFullYear();
          commercialPurchase.periodMoveInDateFormatted = formattedDate;
          const listing = { details: commercialPurchase, id: indiv.id };


          if (listing.details.boost === true) {

            boostList.push(listing);
          } else if (listing.details.preferredListing === true) {

            preferredListing.push(listing);
          } else {

            conversationRateListing.push(listing);
            normalListing.push(listing);
          }

        }
      }


      conversationRateListing.sort((a, b) => {
        return b.details.conversationRate - a.details.conversationRate;
      });
      var boxNumber = 0;
      var counterAdv = 0;
      while (boostList.length != 0 || preferredListing.length != 0 || normalListing.length != 0 || conversationRateListing.length != 0) {
        var randomIndex = Math.floor(Math.random() * activatedAdvertiseListing.length);
        if (firstData.length % 5 == 0 && activatedAdvertiseListing.length != 0 && firstData.length != 0) {
          firstData.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() });
          needUpEyeballAdv.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() })
        }
        if (allData.length % 5 == 0 && activatedAdvertiseListing.length != 0 && allData.length != 0) {
          allData.push(activatedAdvertiseListing[0].data());
          activatedAdvertiseListing.splice(randomIndex, 1);
        }
        if (boxNumber == 0) {
          if (boostList.length != 0) {
            allData.push(boostList[0]);

            if (firstData.length < 40) {
              firstData.push(boostList[0]);
              if (firstData.length == 40) {
                setCurrentListing(firstData);
              }
            }
            boostList.splice(0, 1);

          }

          if (preferredListing.length != 0) {
            boxNumber = 1;
          }
          else if (conversationRateListing.length != 0) {
            boxNumber = 2;
          }
          else if (normalListing.length != 0) {
            boxNumber = 3;
          }
          else {
            boxNumber = 0;
          }

        }
        else if (boxNumber == 1) {
          if (preferredListing.length != 0) {
            allData.push(preferredListing[0]);

            if (firstData.length < 40) {
              firstData.push(preferredListing[0]);
              if (firstData.length == 40) {
                setCurrentListing(firstData);
              }
            }
            preferredListing.splice(0, 1);

          }

          if (conversationRateListing.length != 0) {
            boxNumber = 2;
          }
          else if (normalListing.length != 0) {
            boxNumber = 3;
          }
          else if (boostList.length != 0) {
            boxNumber = 0;
          }
          else {
            boxNumber = 1;
          }

        }
        else if (boxNumber == 2) {
          if (conversationRateListing.length != 0) {
            var keepGoing = true;
            while (conversationRateListing.length != 0 && keepGoing == true) {
              const foundListing = allData.find(item => item.id == conversationRateListing[0].id);
              if (foundListing) {
                conversationRateListing.splice(0, 1);
              }
              else {
                allData.push(conversationRateListing[0]);
                if (firstData.length < 40) {
                  firstData.push(conversationRateListing[0]);
                  if (firstData.length == 40) {
                    setCurrentListing(firstData);
                  }
                }
                conversationRateListing.splice(0, 1);
                keepGoing = false;

              }
            }
          }

          if (normalListing.length != 0) {
            boxNumber = 3;
          }
          else if (boostList.length != 0) {
            boxNumber = 0;
          }
          else if (preferredListing.length != 0) {
            boxNumber = 1;
          }
          else {
            boxNumber = 2;
          }

        }
        else if (boxNumber == 3) {
          if (normalListing.length != 0) {
            var keepGoing = true;
            while (normalListing.length != 0 && keepGoing == true) {
              const foundListing = allData.find(item => item.id == normalListing[0].id);
              if (foundListing) {
                normalListing.splice(0, 1);
              }
              else {
                allData.push(normalListing[0]);

                if (firstData.length < 40) {
                  firstData.push(normalListing[0]);
                  if (firstData.length == 40) {
                    setCurrentListing(firstData);
                  }
                }
                normalListing.splice(0, 1);
                keepGoing = false;

              }
            }
          }

          if (boostList.length != 0) {
            boxNumber = 0;
          }
          else if (preferredListing.length != 0) {
            boxNumber = 1;
          }
          else if (conversationRateListing.length != 0) {
            boxNumber = 2;
          }
          else {
            boxNumber = 3;
          }

        }
      }
      if (firstData.length < 40) {
        var randomIndex = Math.floor(Math.random() * activatedAdvertiseListing.length);
        if (activatedAdvertiseListing.length != 0 && firstData.length != 0 && firstData.length <= 4) {
          firstData.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() });
          needUpEyeballAdv.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() })
        }
        if (activatedAdvertiseListing.length != 0 && allData.length != 0 && firstData.length <= 4) {
          allData.push({ id: activatedAdvertiseListing[randomIndex].id, details: activatedAdvertiseListing[randomIndex].data() });
          activatedAdvertiseListing.splice(randomIndex, 1);
        }
        setCurrentListing(firstData);
      }


      // SetCombineAllData(allData);
      // setTotalProject(allData.length);
      setEyeballAdvListingID(needUpEyeballAdv);
      resolve(allData);
    });

  }

  const clearAllFilter = () => {
    setFilterName(null);
    setFilterProject(null);
    setMinPriceBud(0);
    setLocationDetails("");
    setMinLeaseNum("NA");
    setMinLeasePeriod('Days');

    setMaxPriceBud("NA");
    setMaxLeaseNum("NA");
    setMaxLeasePeriod('Days');
    setFilterProject(null);
    setIsAny(true);
    setIs30Year(false);
    setIs60Year(false);
    setIs99Year(false);
    setIs110Year(false);
    setIs999Year(false);
    setIs9999Year(false);
    setIsFreehold(false);
    setIsTenureNA(false);
    setIsAllPropertyFilterSales(true);
    setIsCommercialPropertyFilterSales(false);
    setIsIndustrialPropertyFilterSales(false);
    setIsIndustrialLandUsePropertyFilterSales(false);
    setIsLandPropertyFilterSales(false);
    // setShow(false);
    var allData = combineAllData;
    var resetListingCurrent = [];
    resetListingCurrent.push(...allData.slice(0, 40));
    setCurrentListing(resetListingCurrent);
    setCurrentPage(1);
  }




  const checkForPreviousPage = (allData) => {
    return new Promise(function (resolve, reject) {
      const persistedId = sessionStorage.getItem(
        "scroll-position-product-id-marker"
      );
      if (persistedId != null) {
        sessionStorage.removeItem("scroll-position-product-id-marker");
        setCurrentPage(Number(persistedId));

        setCurrentListing(allData.slice(((Number(persistedId) - 1) * 40), (((Number(persistedId) - 1) * 40) + 40)));
        // setCurrentListing(allData.current[Number(persistedId) - 1]);
      }
      resolve(true);
    })
  }

  const recordID = (pageNumber) => {
    sessionStorage.setItem("scroll-position-product-id-marker", pageNumber);
  }

  const addShortList = async (userID, id) => {
    if (user != null && userID != user.uid) {
      await setDoc(doc(db, `userInfo/${user.uid}/shortList`, id), {});
    }
    else {
      alert("Please Sign Up First or This is your post");
    }
  }

  const deteleShortList = async (id) => {
    if (user != null && id != user.uid) {
      await deleteDoc(doc(db, `userInfo/${user.uid}/shortList`, id));
    }
    else {
      alert("Please Sign Up First or This is your post");
    }
  }


  return (
    <>
      {/* <Notification></Notification> */}
      <ZoomPicture trigger={zoomPic} setTrigger={setZoomPic} zoomPicURL={zoomPicURL}></ZoomPicture>
      <div>
        <center><h2 style={{ paddingTop: "2%" }}>COMMERCIAL PURCHASE</h2></center>
        <div className='searchBar' >
          <div id="searchBar" ref={searchbarRef}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px", backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
              <i class="fa-solid fa-magnifying-glass" id="magnifyingIcon"></i>
              <div className='searchbarIndivCol'>
                <input type="text" placeholder='Search district, listing name, etc' value={locationDetails} onChange={handleLocationDetails}></input>
              </div>
              <div className='searchbarIndivColBtn'>
                <button className="btn--search" style={{ marginLeft: "3%" }} onClick={() => searchApply()}>Search</button>
              </div>
            </div>
            <div id="filterBar">
              <div style={{ width: "30%", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p id="BudgetFilter" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Budget {filterName == "BudgetFilter" ? <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                {minPriceBud == 0 && maxPriceBud == "NA" ? null : <p id="BudgetFilter" className='btn--filter' style={{ cursor: "pointer", marginTop: "2%" }} onClick={(handleClick)}>{minPriceBud == 0 ? "Any" : `$${minPriceBud.toLocaleString()}`} - {maxPriceBud == "NA" ? "Any" : `$${maxPriceBud.toLocaleString()}`}</p>}
              </div>

              <div style={{ width: '30%', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                <p id="TenureFilter" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Tenure {filterName == "TenureFilter" ? <i id="TenureFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="TenureFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                {isAny == true || (is30Year == false && is60Year == false && is99Year == false && is110Year == false && is999Year == false && is9999Year == false && isFreehold == false && isTenureNA == false) ? null :
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', marginTop: "2%" }}>
                    {is30Year ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>30-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs30Year(false) }}></i></p> : null}
                    {is60Year ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>60-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs60Year(false) }}></i></p> : null}
                    {is99Year ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>99-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs99Year(false) }}></i></p> : null}
                    {is110Year ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>110-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs110Year(false) }}></i></p> : null}
                    {is999Year ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>999-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs999Year(false) }}></i></p> : null}
                    {is9999Year ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>9999-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs9999Year(false) }}></i></p> : null}
                    {isFreehold ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Freehold <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsFreehold(false) }}></i></p> : null}
                    {isTenureNA ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>NA <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsTenureNA(false) }}></i></p> : null}
                  </div>}
              </div>

              <div style={{ width: '30%', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                <p id="PropertyFilterSales" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Property Type {filterName == "PropertyFilterSales" ? <i id="PropertyFilterSalesDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="PropertyFilterSalesDropdown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                {isAllPropertyFilterSales == true || (isCommercialPropertyFilterSales == false && isIndustrialLandUsePropertyFilterSales == false && isIndustrialPropertyFilterSales == false && isLandPropertyFilterSales == false) ? null :
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', marginTop: "2%" }}>
                    {isCommercialPropertyFilterSales ? <p id="PropertyFilterSales" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Commercial <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsCommercialPropertyFilterSales(false) }}></i></p> : null}
                    {isIndustrialPropertyFilterSales ? <p id="PropertyFilterSales" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Industrial <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsIndustrialPropertyFilterSales(false) }}></i></p> : null}
                    {isLandPropertyFilterSales ? <p id="PropertyFilterSales" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Land <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsLandPropertyFilterSales(false) }}></i></p> : null}
                    {isIndustrialLandUsePropertyFilterSales ? <p id="PropertyFilterSales" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Industrial Land Use <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsIndustrialLandUsePropertyFilterSales(false) }}></i></p> : null}
                  </div>}
              </div>

              {/* <button id="filterClear" onClick={() => { clearAllFilter() }}>Clear</button> */}
            </div>
            <div id="clearBtnDiv">
              <button id="filterClear" onClick={() => { clearAllFilter() }}>Clear</button>
            </div>


            {showFilters && (
              <div id="moreFilter" ref={moreFilterRef} style={(filterName == "BudgetFilter") ? { left: "0", maxHeight: "300px" } : filterName == "PropertyFilterSales" ? { right: "0", maxHeight: "200px" } : window.innerWidth > 768 ? { height: "220px", marginLeft: "33.3%" } : { height: "220px", left: "0" }}>

                {(filterName == "BudgetFilter" || filterName == "BudgetFilterResidential") ?
                  <>
                    <div className='searchbarIndivCol'>
                      <input placeholder='Min' type="Number" id="minPriceBuy" value={minPriceBud} onClick={() => { setMinOption(true) }} onChange={handlesMinPriceBud} style={{ width: "45%", float: "left" }}></input>
                      <p style={{ float: "left", width: "7%", padding: "8px 10px", cursor: "default", border: "none" }}>-</p>
                      <input placeholder='Max' type="Number" id="maxPriceBuy" value={maxPriceBud} onClick={() => { setMinOption(false) }} onChange={handleMaxPriceBud} style={{ width: "45%", float: "left" }}></input>
                      <div style={{ clear: 'both' }}></div>
                      {minOption == true ?
                        <>
                          <p id="optionBudget" onClick={() => setMinPriceBud(500)}>$500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(1000)}>$1,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(1500)}>$1,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(2000)}>$2,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(2500)}>$2,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(3000)}>$3,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(3500)}>$3,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(4000)}>$4,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(4500)}>$4,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(5000)}>$5,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(5500)}>$5,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(6000)}>$6,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(6500)}>$6,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(7000)}>$7,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(7500)}>$7,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(8000)}>$8,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(8500)}>$8,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(9000)}>$9,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(9500)}>$9,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(10000)}>$10,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(10500)}>$10,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(11000)}>$11,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(11500)}>$11,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(12000)}>$12,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(12500)}>$12,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(13000)}>$13,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(13500)}>$13,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(14000)}>$14,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(14500)}>$14,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(15000)}>$15,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(15500)}>$15,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(16000)}>$16,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(16500)}>$16,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(17000)}>$17,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(17500)}>$17,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(18000)}>$18,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(18500)}>$18,500</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(19000)}>$19,000</p>
                          <p id="optionBudget" onClick={() => setMinPriceBud(19500)}>$19,500</p>
                        </> :
                        <>
                          <p id="optionBudget" onClick={() => setMaxPriceBud("NA")}>Any Price</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(500)}>$500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(1000)}>$1,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(1500)}>$1,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(2000)}>$2,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(2500)}>$2,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(3000)}>$3,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(3500)}>$3,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(4000)}>$4,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(4500)}>$4,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(5000)}>$5,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(5500)}>$5,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(6000)}>$6,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(6500)}>$6,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(7000)}>$7,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(7500)}>$7,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(8000)}>$8,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(8500)}>$8,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(9000)}>$9,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(9500)}>$9,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(10000)}>$10,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(10500)}>$10,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(11000)}>$11,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(11500)}>$11,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(12000)}>$12,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(12500)}>$12,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(13000)}>$13,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(13500)}>$13,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(14000)}>$14,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(14500)}>$14,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(15000)}>$15,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(15500)}>$15,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(16000)}>$16,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(16500)}>$16,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(17000)}>$17,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(17500)}>$17,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(18000)}>$18,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(18500)}>$18,500</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(19000)}>$19,000</p>
                          <p id="optionBudget" onClick={() => setMaxPriceBud(19500)}>$19,500</p>
                        </>
                      }
                    </div>
                  </> :
                  filterName == "TenureFilter" ?
                    <>
                      <div className='searchbarIndivCol'>
                        <div className='propertyType' id="buyPropertyType">
                          <label style={{ display: "block", width: "100%" }}>
                            <input
                              type="checkbox"
                              checked={isAny}
                              onChange={() => {
                                setIsAny(true);
                                setIs30Year(false);
                                setIs60Year(false);
                                setIs99Year(false);
                                setIs110Year(false);
                                setIs999Year(false);
                                setIs9999Year(false);
                                setIsFreehold(false);
                                setIsTenureNA(false);
                              }}
                              style={{ width: "auto" }}
                            />
                            <span class="label-text"> Any</span>
                          </label>
                          <br></br>
                          <label style={{ display: "block", width: "100%" }}>
                            <input
                              type="checkbox"
                              checked={is30Year}
                              onChange={() => {
                                setIsAny(false);
                                setIs30Year(!is30Year);
                                // setIs60Year(false);
                                // setIs99Year(false);
                                // setIs110Year(false);
                                // setIs999Year(false);
                                // setIs9999Year(false);
                                // setIsFreehold(false);
                                setIsTenureNA(false);
                              }}
                              style={{ width: "auto" }}
                            />
                            <span class="label-text">30-year leasehold</span>
                          </label>
                          <br></br>
                          <label style={{ display: "block", width: "100%" }}>
                            <input
                              type="checkbox"
                              checked={is60Year}
                              onChange={() => {
                                setIsAny(false);
                                // setIs30Year(false);
                                setIs60Year(!is60Year);
                                // setIs99Year(false);
                                // setIs110Year(false);
                                // setIs999Year(false);
                                // setIs9999Year(false);
                                // setIsFreehold(false);
                                setIsTenureNA(false);
                              }}
                              style={{ width: "auto" }}
                            />
                            <span class="label-text"> 60-year leasehold</span>
                          </label>
                          <br></br>
                          <label style={{ display: "block", width: "100%" }}>
                            <input
                              type="checkbox"
                              checked={is99Year}
                              onChange={() => {
                                setIsAny(false);
                                // setIs30Year(false);
                                // setIs60Year(false);
                                setIs99Year(!is99Year);
                                // setIs110Year(false);
                                // setIs999Year(false);
                                // setIs9999Year(false);
                                // setIsFreehold(false);
                                setIsTenureNA(false);
                              }}
                              style={{ width: "auto" }}
                            />
                            <span class="label-text"> 99-year leasehold</span>
                          </label>
                          <br></br>
                          <label style={{ display: "block", width: "100%" }}>
                            <input
                              type="checkbox"
                              checked={is110Year}
                              onChange={() => {
                                setIsAny(false);
                                // setIs30Year(false);
                                // setIs60Year(false);
                                // setIs99Year(false);
                                setIs110Year(!is110Year);
                                // setIs999Year(false);
                                // setIs9999Year(false);
                                // setIsFreehold(false);
                                setIsTenureNA(false);
                              }}
                              style={{ width: "auto" }}
                            />
                            <span class="label-text"> 110-year leasehold</span>
                          </label>
                          <br></br>
                          <label style={{ display: "block", width: "100%" }}>
                            <input
                              type="checkbox"
                              checked={is999Year}
                              onChange={() => {
                                setIsAny(false);
                                // setIs30Year(false);
                                // setIs60Year(false);
                                // setIs99Year(false);
                                // setIs110Year(false);
                                setIs999Year(!is999Year);
                                // setIs9999Year(false);
                                // setIsFreehold(false);
                                setIsTenureNA(false);
                              }}
                              style={{ width: "auto" }}
                            />
                            <span class="label-text"> 999-year leasehold</span>
                          </label>
                          <br></br>
                          <label style={{ display: "block", width: "100%" }}>
                            <input
                              type="checkbox"
                              checked={is9999Year}
                              onChange={() => {
                                setIsAny(false);
                                // setIs30Year(false);
                                // setIs60Year(false);
                                // setIs99Year(false);
                                // setIs110Year(false);
                                // setIs999Year(false);
                                setIs9999Year(!is9999Year);
                                // setIsFreehold(false);
                                setIsTenureNA(false);
                              }}
                              style={{ width: "auto" }}
                            />
                            <span class="label-text"> 9999-year leasehold</span>
                          </label>
                          <br></br>
                          <label style={{ display: "block", width: "100%" }}>
                            <input
                              type="checkbox"
                              checked={isFreehold}
                              onChange={() => {
                                setIsAny(false);
                                // setIs30Year(false);
                                // setIs60Year(false);
                                // setIs99Year(false);
                                // setIs110Year(false);
                                // setIs999Year(false);
                                // setIs9999Year(false);
                                setIsFreehold(true);
                                setIsTenureNA(false);
                              }}
                              style={{ width: "auto" }}
                            />
                            <span class="label-text"> Freehold</span>
                          </label>
                          <br></br>
                          <label style={{ display: "block", width: "100%" }}>
                            <input
                              type="checkbox"
                              checked={isTenureNA}
                              onChange={() => {
                                setIsAny(false);
                                setIs30Year(false);
                                setIs60Year(false);
                                setIs99Year(false);
                                setIs110Year(false);
                                setIs999Year(false);
                                setIs9999Year(false);
                                setIsFreehold(false);
                                setIsTenureNA(true);
                              }}
                              style={{ width: "auto" }}
                            />
                            <span class="label-text"> Not Applicable</span>
                          </label>

                        </div>
                      </div>
                    </> :
                    filterName == "PropertyFilterSales" ?
                      <>
                        <div className='searchbarIndivCol'>


                          <div className='propertyType' id="buyPropertyType">
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isAllPropertyFilterSales}
                                onChange={() => {
                                  setIsAllPropertyFilterSales(true);
                                  setIsCommercialPropertyFilterSales(false);
                                  setIsIndustrialPropertyFilterSales(false);
                                  setIsLandPropertyFilterSales(false);
                                  setIsIndustrialLandUsePropertyFilterSales(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text">All</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isCommercialPropertyFilterSales}
                                onChange={() => {
                                  setIsAllPropertyFilterSales(false);
                                  setIsCommercialPropertyFilterSales(true);
                                  // setIsIndustrialPropertyFilterSales(false);
                                  // setIsLandPropertyFilterSales(false);
                                  // setIsIndustrialLandUsePropertyFilterSales(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text">Commercial</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isIndustrialPropertyFilterSales}
                                onChange={() => {
                                  setIsAllPropertyFilterSales(false);
                                  // setIsCommercialPropertyFilterSales(false);
                                  setIsIndustrialPropertyFilterSales(true);
                                  // setIsLandPropertyFilterSales(false);
                                  // setIsIndustrialLandUsePropertyFilterSales(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text">Industrial</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isLandPropertyFilterSales}
                                onChange={() => {
                                  setIsAllPropertyFilterSales(false);
                                  // setIsCommercialPropertyFilterSales(false);
                                  // setIsIndustrialPropertyFilterSales(false);
                                  setIsLandPropertyFilterSales(true);
                                  // setIsIndustrialLandUsePropertyFilterSales(false);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text">Land</span>
                            </label>
                            <br></br>
                            <label style={{ display: "block", width: "100%" }}>
                              <input
                                type="checkbox"
                                checked={isIndustrialLandUsePropertyFilterSales}
                                onChange={() => {
                                  setIsAllPropertyFilterSales(false);
                                  // setIsCommercialPropertyFilterSales(false);
                                  // setIsIndustrialPropertyFilterSales(false);
                                  // setIsLandPropertyFilterSales(false);
                                  setIsIndustrialLandUsePropertyFilterSales(true);
                                }}
                                style={{ width: "auto" }}
                              />
                              <span class="label-text">Industrial Land Use</span>
                            </label>

                          </div>
                        </div>
                      </> :
                      null


                }
              </div>
            )}
          </div>
        </div>



        {filterProject != null && (minPriceBud != 0 || maxPriceBud != 0) ? <div>
          <select id="dropdownFilterMin" name="dropdown" onChange={handleSortingOrder}>
            <option value="NA">NA</option>
            <option value="Adescending">Adescending </option>
            <option value="Descending">Descending</option>
          </select>
          <div style={{ clear: 'both' }}></div>
        </div> : null}

        <div className='rowCard'>
          {currentListing != null ? currentListing[0] != null ? currentListing.map((project, index) => {
            if (project.details.hasOwnProperty('activatedDate')) {
              return (
                <div className="CardAdvertiseDivCommercialSale" id={project.id} onClick={() => { setZoomPicURL(project.details.imageURL); setZoomPic(true) }}>
                  <div style={{ position: 'relative' }}>
                    <img style={{ alignItems: 'center' }} className="advertiseCard" src={project.details.imageURL} alt="Uploaded" />
                    {/* <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#33434dbf', color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                      Adv
                    </div> */}
                  </div>
                  <div className="CardContainer">
                    <div style={{ clear: 'both' }}></div>
                  </div>
                  <div style={{ clear: 'both' }}></div>
                </div>
              )
            }
            else {
              var ID = project.id;
              var heartIconStatus = false;
              if (shortList != null) {
                heartIconStatus = shortList.find(doc => { return doc.id === ID })
              }
              return (
                <div class="CardPurchase">
                  <Link to={project.details.status == "leasing" ? "/commercialPurchaseDetails/" + project.id : null}>
                    <div id="commercialSaleInfo" style={{ borderBottom: "1px solid #c1c1c1" }}>


                      <img src={project.details.userImage} id="cardResidentialUserImage"></img>
                      <div id="cardResidentialUserInfo" >

                        {/* {project.details.propertyAgent == true ? <div style={{ backgroundColor: '#33434dbf', width: "fit-content", color: '#fff', padding: '4px 8px', borderRadius: '4px' }}>
                          Agent
                        </div> : null} */}



                        {/* <p style={project.details.propertyAgent == true ?{fontWeight:"bold", padding:"4px 0px"}:{ fontWeight: "bold" }}>{project.details.listingName.length > 23 ? `${project.details.listingName.slice(0, 20)}...` : project.details.listingName}</p> */}
                        {project.details.propertyAgent == true ? <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>AGENT</p> : <p style={{ color: "#4d4c4c", paddingBottom: "5px" }}>DIRECT BUYER</p>}
                        {project.details.preferredStatus == true ?
                          <div id="residentialPreferred" style={{ margin: "0", height: "auto", padding: "5px" }}>
                            <p>Preferred Listing</p>
                          </div>
                          :
                          // <div id="residentialPreferred" style={{ backgroundColor: "transparent", color: "white", height:"10%" }}>

                          // </div>
                          null
                        }
                        <div style={{ clear: 'both' }}></div>

                        {/* <p style={{ fontWeight: "bold" }}>{project.details.district[0] == "Any" ? "District: " + project.details.district[0] : project.details.district[0]}</p>
                        <p>{project.details.propertyType}</p>
                        <p>{project.details.specificPropertyType == "Any" ? "Type: " + project.details.specificPropertyType : project.details.specificPropertyType}</p> */}

                      </div>
                      <div style={{ clear: 'both' }}></div>
                    </div>

                    <div style={{ clear: 'both' }}></div>


                    <div class="CardContainer" style={{height:"80%"}}>


                      {/* <div style={{ clear: 'both' }}></div> */}

                      <div id="Card_overall">
                        {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min PSF Budget:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>${project.details.minFloorSizePSFBudget.toLocaleString()}</p> */}
                        {/* <div style={{ clear: 'both' }}></div> */}
                        {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Max PSF Budget:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>${project.details.maxFloorSizePSFBudget.toLocaleString()}</p> */}
                        {/* <div style={{ clear: 'both' }}></div> */}
                        {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Min Floor Size:</p><p style={{ float: "right", color: "black", marginTop: "1%", marginBottom: "1%" }}>{project.details.minFloorSize} SQFT</p> */}
                        {/* <div style={{ clear: 'both' }}></div> */}
                        {/* <p style={{ float: "left", marginTop: "3%", marginBottom: "3%" }}>Moving In Period:{<br></br>}(Starting From)</p><p style={{ float: "right", color: "black" }}  >{project.details.periodMoveInDateFormatted}</p> */}
                        {/* <div style={{ clear: 'both' }}></div> */}
                        <p id="Card_district" style={{paddingTop:"0"}}>{project.details.district[0].toUpperCase() == "ANY" ? <>{project.details.district[0].toUpperCase()} DISTRICT</> : <>{project.details.district[0].toUpperCase()}</>}</p>
                        <p id="Card_price">${project.details.minFloorSizePSFBudget.toLocaleString()}</p>
                        <p id="monthlyRentalBudget">Min PSF Budget</p>
                      </div>
                      <div className ="Card_SecondOverall">
                        <div style={{ width: "50%" }}>
                          <p style={{color:"#4d4c4c" }}>Property Type</p>
                          <p id="Card_type" style={{fontWeight:"bold"}}>{project.details.propertyType}</p>
                        </div>
                        <div style={{ width: "50%" }}>
                          <p style={{color:"#4d4c4c" }}>Specific Type</p>
                          <p id="Card_type" style={{ fontWeight:"bold"}}>{project.details.specificPropertyType}</p>
                        </div>
                      </div>
                      <div>
                        <p style={{color:"#4d4c4c" }}>Floor Size <span style={{fontSize:"10px"}}>[PSF]</span></p>
                        <div style={{ display: "flex", flexDirection: "row", paddingBottom:"5px", paddingTop:"5px"}}>
                          <div style={{ display: "flex", flexDirection: "row", width:"100%" }}>
                            <div style={{width:"50%"}}>
                            <span style={{color:"#4d4c4c", fontSize:"10px" }}>Min </span>
                            <span id="Card_type" style={{fontWeight:"bold"}}>{project.details.minFloorSize}</span>
                            
                            
                           </div>
                            <div style={{width:"50%"}}>
                            <span style={{color:"#4d4c4c", fontSize:"10px" }}>Max </span>
                            <span id="Card_type" style={{fontWeight:"bold"}}>{project.details.maxFloorSize}</span>
                            
                            </div>
                          </div>
                          
                      
                          
                        </div>
                      </div>
                      <div id='residentialEyeball'>
                        <i class="fa fa-eye" style={{ margin: "0" }}></i> {project.details.eyeball}
                        <i class="far fa-comment-dots" style={{ marginLeft: "5px" }}></i> {project.details.connections}
                      </div>
                      <div id='residentialHeart'>
                        {heartIconStatus == undefined | heartIconStatus == false ? <button id="heartButton" onClick={(e) => { e.preventDefault(); addShortList(project.details.UID, ID) }}><i class="fa-regular fa-heart fa-2x" ></i></button> : <button id="heartButton" onClick={(e) => { e.preventDefault(); deteleShortList(ID) }}><i class="fa-solid fa-heart fa-2x" id="favouriteHeart"></i></button>}
                      </div>

                    </div>
                    <div style={{ clear: 'both' }}></div>
                  </Link>
                  <div style={{ clear: 'both' }}></div>

                  <div style={{ clear: 'both' }}></div>
                </div>
              )
            }
          }) : <center><h3 style={{ marginTop: "15%" }}>No Result</h3></center> : null}
          <div style={{ clear: 'both' }}></div>
        </div>
        <Stack alignItems="center">
          {filterProject != null ?
            <Pagination count={Math.ceil(filterProject.length / 40)} variant="outlined" shape="rounded" style={{ bottom: "0", marginBottom: "2%" }} page={currentPage} onChange={onPageChanged} />
            :
            <Pagination page={currentPage} count={Math.ceil(totalProject / 40)} variant="outlined" shape="rounded" style={{ bottom: "0", marginBottom: "2%" }} onChange={onPageChanged} />}
        </Stack>

      </div>
      <Footer />


    </>
  )
}

