import React from 'react';
import '../css/NewProject.css';
import { UserAuth } from '../../AuthContent';

export default function Spinner(props) {
    if (!props.trigger) {
        return;
    }
    else {



        return (
            <>
                <div className='modalNewProject' style={{ zIndex: "5" }}>
                    <div className='modal-contentAcknowledge' style={{backgroundColor:"transparent", border:"none", boxShadow:"none"}}>
                     
                        <div class='modal-acknowledge' >
                            <center><i style={{ marginTop: "10%", color:"white" }} class="fa-solid fa-spinner fa-spin fa-2xl"></i></center>

                        </div>
                        
                    </div>
                </div>

            </>
        );
    }

}
