import React, { useState, useRef, useEffect, useContext } from 'react';
import '../../App.css';
import '../css/Home.css'
import Footer from '../Footer'
import { Link } from 'react-router-dom';
import { collection, getDocs, onSnapshot, doc, query, orderBy, limit, getDoc, setDoc, deleteDoc, startAfter, where } from 'firebase/firestore';
import { db } from '../../firebase/firebase'
import CardItem from '../CardItem';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import { UserAuth } from '../../AuthContent';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { useNavigate } from 'react-router-dom';
import Notification from './Notification';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import BuyerHomeModal from './BuyerHomeModal';
import TenantHomeModal from './TenantHomeModal';
import LandlordHomeModal from './LandlordHomeModal';
import SellerHomeModal from './SellerHomeModal';
import ZoomPicture from './ZoomPictures';
import { FireIcon } from '@heroicons/react/24/outline';
import { MapPinIcon } from '@heroicons/react/20/solid';
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";
import RentalDetailPage from './RentalDetailPage';


function Home(props) {

  const chats = useRef(null);
  const targetDivRef = useRef(null);
  const navigate = useNavigate();


  const [shortList, setShortList] = useState(null);
  const [queryFirstRow, setQueryFirstRow] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [buyerBudget, setBuyerBudget] = useState('');
  const [tenantArea, setTenantArea] = useState('');
  const [landlordPrice, setLandlordPrice] = useState('');
  const [sellerPrice, setSellerPrice] = useState('');
  const [buyerModalActive, setBuyerModalActive] = useState(false);
  const [sellerModalActive, setSellerModalActive] = useState(false);
  const [landlordModalActive, setLandlordModalActive] = useState(false);
  const [somethingWrong, setSomethingWrong] = useState(false);
  const [tenantModalActive, setTenantModalActive] = useState(false);

  let timer;


  const resetTimer = (start = true) => {
    clearInterval(timer);
    if (start) {
      startTimer();
    }
  };

  useEffect(() => {
    if (somethingWrong) {
      // Set a timer to reset both OTPSendNoti and somethingWrong after 5 seconds
      const timer = setTimeout(() => {

        if (somethingWrong) {
          setSomethingWrong(false)
        }
      }, 5000);

      // Cleanup timer when component unmounts or either OTPSendNoti or somethingWrong changes
      return () => clearTimeout(timer);
    }
  }, [somethingWrong]);

  const startTimer = () => {
    const carouselButtons = document.querySelectorAll('[data-carousel-slide-to]');
    timer = setInterval(() => {
      setCurrentSlide((prevSlide) => parseInt((prevSlide + 1) % carouselButtons.length));
    }, 10000);
  };

  useEffect(() => {

    // Start the timer initially without resetting the current slide
    startTimer();

    return () => {
      clearInterval(timer); // Clean up timer on component unmount
    };
  }, [currentSlide]);




  const [zoomPic, setZoomPic] = useState(false);
  const [zoomPicURL, setZoomPicURL] = useState('');


  useEffect(() => {
    document.title = 'Nextloop';
  }, []);






  useEffect(() => {

    const getFirstPage = async () => {
      var firstRow = [];
      const q = query(collection(db, "Rental"), orderBy("likes", "desc"));
      const queryFirstRow = await getDocs(q);
      var lastVisible = queryFirstRow.docs[queryFirstRow.docs.length - 1];
      for (var i = 0; i < queryFirstRow.docs.length; i++) {
        if (queryFirstRow.docs[i].data().status == "Approve") {
          firstRow.push(queryFirstRow.docs[i]);
        }
      }

      setQueryFirstRow(firstRow);





    }



    getFirstPage();
  }, [])



  return (
    <>
      {/* <div className="relative bg-white pb-[10px] pt-[40px] dark:bg-dark lg:pt-[60px] 
   sm:mx-2 md:mx-5 lg:mx-[5%]">
        <div className="container">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 lg:w-6/12">
              <div className="hero-content">
                <h1 className="mb-5 text-4xl font-bold !leading-[1.208] text-dark dark:text-white sm:text-[42px] lg:text-[40px] xl:text-5xl">
                  Kickstart Your Property Journey with Nextloop
                </h1>
                <p className="mb-8 max-w-[480px] text-base text-body-color dark:text-dark-6">
                  With Nextloop, buyers can discover their optimal purchase price based on their budget, while sellers can take a proactive approach in finding buyers, eliminating the need for conventional waiting.
                </p>
                <ul className="flex flex-wrap items-center">
                 
                  <div ref={targetDivRef} style={{ width: "100%", paddingTop: "2%", borderRadius: "0", position: "relative", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                    <div className="toggleDivHome" >
                      <button className={`toggleBtn ${isCommercial ? 'active' : ''}`} onClick={handleToggleClick} >
                        Commercial
                      </button>
                      <button className={`toggleBtn ${!isCommercial ? 'active' : ''}`} onClick={handleToggleClick}>
                        Residential
                      </button>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    <div className='searchBarHome' style={{ position: "relative", zIndex: "1", top: "0", left: "0", right: "0", bottom: "0" }}>
                      <div id="searchBaHome" ref={searchbarRef}>
                        <div style={{ display: "flex", alignItems: "center", color: "white" }}>
                          <button className={`purchase_rent ${rentTab ? 'active' : ''}`} onClick={() => { setRentTab(true); setFilterName(null); setShowFilters(false); }}>
                            RENT
                          </button>
                          <button className={`purchase_rent ${rentTab ? '' : 'active'}`} onClick={() => { setRentTab(false); setFilterName(null); setShowFilters(false); }}>
                            PURCHASE
                          </button>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px", backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
                          <i class="fa-solid fa-magnifying-glass" id="magnifyingIcon" ></i>
                          <div className='searchbarIndivCol'>
                            <input type="text" placeholder='Search district, listing name, etc' value={locationDetails} onChange={handleLocationDetails}></input>
                          </div>
                          <div className='searchbarIndivColBtn'>
                            {isCommercial ? rentTab ? <button className="btn--search" style={{ marginLeft: "3%" }} onClick={() => searchApply()}>Search</button> :
                              <button className="btn--search" style={{ marginLeft: "3%" }} onClick={() => searchApplyPurchaseCommercial()}>Search</button> :
                              rentTab ? <button className="btn--search" style={{ marginLeft: "3%" }} onClick={() => searchApplyResidential()}>Search</button> :
                                <button className="btn--search" style={{ marginLeft: "3%" }} onClick={() => searchApplyResidentialPurchase()}>Search</button>
                            }
                          </div>
                        </div>
                        {isCommercial == true ?
                          rentTab ?
                            <div id="filterBar" >
                              <div style={{ width: "30%", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <p id="BudgetFilter" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Budget {filterName == "BudgetFilter" ? <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                                {minPriceBud == 0 && maxPriceBud == "NA" ? null : <p id="BudgetFilter" className='btn--filter' style={{ cursor: "pointer", marginTop: "2%" }} onClick={(handleClick)}>{minPriceBud == 0 ? "Any" : `$${minPriceBud.toLocaleString()}`} - {maxPriceBud == "NA" ? "Any" : `$${maxPriceBud.toLocaleString()}`}</p>}
                              </div>
                              <div style={{ width: "30%", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <p id="LeaseFilter" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Lease Term {filterName == "LeaseFilter" ? <i id="LeaseFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="LeaseFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                                {minLeaseNum == "NA" && maxLeaseNum == "NA" ? null : <p id="LeaseFilter" className='btn--filter' style={{ cursor: "pointer", marginTop: "2%" }} onClick={(handleClick)}>{minLeaseNum == "NA" ? "Any" : minLeaseNum + " " + minLeasePeriod} - {maxLeaseNum == "NA" ? "Any" : maxLeaseNum + " " + maxLeasePeriod}</p>}
                              </div>
                              <div style={{ width: '30%', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                <p id="PropertyFilter" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Property Type {filterName == "PropertyFilter" ? <i id="PropertyFilterDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="PropertyFilterDropdown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>

                                {isAll == true || (isFB == false && isRetail == false && isService == false && isStorage == false && isOther == false) ? null :
                                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', marginTop: "2%" }}>
                                    {isFB ? <p id="PropertyFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }} >F&B <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsFB(false) }}></i></p> : null}
                                    {isRetail ? <p id="PropertyFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Retail <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsRetail(false) }}></i></p> : null}
                                    {isService ? <p id="PropertyFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }} >Service <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsService(false) }}></i></p> : null}
                                    {isStorage ? <p id="PropertyFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Storage <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsStorage(false) }}></i></p> : null}
                                    {isOther ? <p id="PropertyFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Other <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsOther(false) }}></i></p> : null}
                                  </div>}
                              </div>

                            </div>
                            :
                            <div id="filterBar">
                              <div style={{ width: "30%", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <p id="BudgetFilter" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Budget {filterName == "BudgetFilter" ? <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                                {minPriceBud == 0 && maxPriceBud == "NA" ? null : <p id="BudgetFilter" className='btn--filter' style={{ cursor: "pointer", marginTop: "2%" }} onClick={(handleClick)}>{minPriceBud == 0 ? "Any" : `$${minPriceBud.toLocaleString()}`} - {maxPriceBud == "NA" ? "Any" : `$${maxPriceBud.toLocaleString()}`}</p>}
                              </div>

                              <div style={{ width: '30%', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                <p id="TenureFilter" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Tenure {filterName == "TenureFilter" ? <i id="TenureFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="TenureFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                                {isAny == true || (is30Year == false && is60Year == false && is99Year == false && is110Year == false && is999Year == false && is9999Year == false && isFreehold == false && isTenureNA == false) ? null :
                                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', marginTop: "2%" }}>
                                    {is30Year ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>30-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs30Year(false) }}></i></p> : null}
                                    {is60Year ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>60-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs60Year(false) }}></i></p> : null}
                                    {is99Year ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>99-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs99Year(false) }}></i></p> : null}
                                    {is110Year ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>110-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs110Year(false) }}></i></p> : null}
                                    {is999Year ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>999-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs999Year(false) }}></i></p> : null}
                                    {is9999Year ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>9999-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs9999Year(false) }}></i></p> : null}
                                    {isFreehold ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Freehold <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsFreehold(false) }}></i></p> : null}
                                    {isTenureNA ? <p id="TenureFilter" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>NA <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsTenureNA(false) }}></i></p> : null}
                                  </div>}
                              </div>

                              <div style={{ width: '30%', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                <p id="PropertyFilterSales" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Property Type {filterName == "PropertyFilterSales" || filterName == "PropertyFilterSalesDropdown" ? <i id="PropertyFilterSalesDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="PropertyFilterSalesDropdown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                                {isAllPropertyFilterSales == true || (isCommercialPropertyFilterSales == false && isIndustrialLandUsePropertyFilterSales == false && isIndustrialPropertyFilterSales == false && isLandPropertyFilterSales == false) ? null :
                                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', marginTop: "2%" }}>
                                    {isCommercialPropertyFilterSales ? <p id="PropertyFilterSales" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Commercial <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsCommercialPropertyFilterSales(false) }}></i></p> : null}
                                    {isIndustrialPropertyFilterSales ? <p id="PropertyFilterSales" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Industrial <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsIndustrialPropertyFilterSales(false) }}></i></p> : null}
                                    {isLandPropertyFilterSales ? <p id="PropertyFilterSales" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Land <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsLandPropertyFilterSales(false) }}></i></p> : null}
                                    {isIndustrialLandUsePropertyFilterSales ? <p id="PropertyFilterSales" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Industrial Land Use <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsIndustrialLandUsePropertyFilterSales(false) }}></i></p> : null}
                                  </div>}
                              </div>

                            </div>
                          :
                          rentTab ?
                            <div id="filterBar">
                              <div style={{ width: "22.5%", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <p id="BudgetFilterResidential" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Budget {filterName == "BudgetFilter" ? <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                                {minPriceBud == 0 && maxPriceBud == "NA" ? null : <p id="BudgetFilter" className='btn--filter' style={{ cursor: "pointer", marginTop: "2%" }} onClick={(handleClick)}>{minPriceBud == 0 ? "Any" : `$${minPriceBud.toLocaleString()}`} - {maxPriceBud == "NA" ? "Any" : `$${maxPriceBud.toLocaleString()}`}</p>}
                              </div>

                              <div style={{ width: '22.5%', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                <p id="PropertiesFilterResidential" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Properties {filterName == "PropertiesFilterResidential" ? <i id="PropertiesFilterResidentialDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="PropertiesFilterResidentialDropdown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                                {isHDB == false && isCondo == false && isLanded == false ? null :

                                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', marginTop: "2%" }}>
                                    {isHDB ? <p id="PropertiesFilterResidential" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>HDB <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsHDB(false) }}></i></p> : null}
                                    {isCondo ? <p id="PropertiesFilterResidential" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Condo <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsCondo(false) }}></i></p> : null}
                                    {isLanded ? <p id="PropertiesFilterResidential" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Landed <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsLanded(false) }}></i></p> : null}
                                  </div>}
                              </div>
                              <div style={{ width: "22.5%", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <p id="SpecificFilterResidential" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Specific Type {filterName == "SpecificFilterResidential" || filterName == "SpecificFilterResidentialDropdown" ? <i id="SpecificFilterResidentialDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="SpecificFilterResidentialDropdown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                                {isHDB == false && isCondo == false && isLanded == false ? null :
                                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', marginTop: "2%" }}>
                                    {isHDB ? <p id="SpecificFilterResidential" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>{specificPropertyTypeHDB == "Not Applicable" ? "Any" : specificPropertyTypeHDB}</p> : null}
                                    {isCondo ? <p id="SpecificFilterResidential" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>{specificPropertyTypeCondo == "Not Applicable" ? "Any" : specificPropertyTypeCondo}</p> : null}
                                    {isLanded ? <p id="SpecificFilterResidential" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>{specificPropertyTypeLanded == "Not Applicable" ? "Any" : specificPropertyTypeLanded}</p> : null}
                                  </div>}
                              </div>
                              <div style={{ width: "22.5%", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <p id="LeaseFilterResidential" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Lease Term {filterName == "LeaseFilterResidential" || filterName == "LeaseFilterResidentialDropdown" ? <i id="LeaseFilterResidentialDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="LeaseFilterResidentialDropdown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                                {leaseTermResidential == "Not Applicable" ? null :
                                  <p id="LeaseFilterResidential" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%", marginTop: "2%" }}>{leaseTermResidential == "Not Applicable" ? "Any" : leaseTermResidential}</p>}
                              </div>

                            </div>
                            :
                            <div id="filterBar">
                              <div style={{ width: "30%", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <p id="BudgetFilterResidential" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Budget {filterName == "BudgetFilter" ? <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="BudgetFilterDropDown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                                {minPriceBud == 0 && maxPriceBud == "NA" ? null : <p id="BudgetFilter" className='btn--filter' style={{ cursor: "pointer", marginTop: "2%" }} onClick={(handleClick)}>{minPriceBud == 0 ? "Any" : `$${minPriceBud.toLocaleString()}`} - {maxPriceBud == "NA" ? "Any" : `$${maxPriceBud.toLocaleString()}`}</p>}
                              </div>


                              <div style={{ width: '30%', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                <p id="PropertiesFilterResidentialSales" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Properties {filterName == "PropertiesFilterResidentialSales" || filterName == "PropertiesFilterResidentialSalesDropdown" ? <i id="PropertiesFilterResidentialSalesDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="PropertiesFilterResidentialSalesDropdown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                                  {isHDB == false && isCondo == false && isLanded == false ? null :
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', marginTop: "2%" }}>
                                      {isHDB ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>HDB <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsHDB(false) }}></i></p> : null}
                                      {isCondo ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Condo <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsCondo(false) }}></i></p> : null}
                                      {isLanded ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Landed <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsLanded(false) }}></i></p> : null}
                                    </div>}
                                </div>
                              </div>

                              <div style={{ width: '30%', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                <p id="TenureFilterResidentialSales" className='btn--filter' style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(handleClick)}>Lease Term {filterName == "TenureFilterResidentialSales" || filterName == "TenureFilterResidentialSalesDropdown" ? <i id="TenureFilterResidentialSalesDropdown" onClick={(handleClick)} class="fa-solid fa-angle-up"></i> : <i id="TenureFilterResidentialSalesDropdown" onClick={(handleClick)} class="fa-solid fa-angle-down"></i>}</p>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                                  {isAnyResidential ? null :
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', marginTop: "2%" }}>
                                      {is99YearResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>99-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs99YearResidential(false) }}></i></p> : null}
                                      {is103YearResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>103-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs103YearResidential(false) }}></i></p> : null}
                                      {is110YearResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>110-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs110YearResidential(false) }}></i></p> : null}
                                      {is999YearResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>999-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs999YearResidential(false) }}></i></p> : null}
                                      {is9999YearResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>9999-year <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIs9999YearResidential(false) }}></i></p> : null}
                                      {isFreeholdResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>Freehold <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsFreeholdResidential(false) }}></i></p> : null}
                                      {isTenureNAResidential ? <p id="filterText" style={{ border: "1px solid #FAFAFA", borderRadius: "10px", padding: "2px 7px", margin: "1%" }}>NA <i class="fa-solid fa-xmark" style={{ fontSize: "13px", cursor: "pointer" }} onClick={() => { setIsTenureNAResidential(false) }}></i></p> : null}
                                    </div>}
                                </div>
                              </div>

                            </div>
                        }


                        {showFilters && (
                          <div id="moreFilter" ref={moreFilterRef} style={window.innerWidth >= 1020 ? (filterName == "BudgetFilter" || filterName == "BudgetFilterResidential") ? { left: "0", maxHeight: "300px" } : filterName == "PropertyFilter" ? { right: "0", maxHeight: "200px" } : filterName == "LeaseFilter" || filterName == "TenureFilter" || filterName == "PropertiesFilterResidentialSales" ? { maxHeight: "250px" } :
                            filterName == "PropertiesFilterResidential" ? { left: "0", marginLeft: "25%", maxHeight: "auto" } : filterName == "SpecificFilterResidential" ? { left: "0", marginLeft: "50%", maxHeight: "auto" } : { right: "0", maxHeight: "200px" } : { left: '0', maxHeight: "auto" }}>

                            {(filterName == "BudgetFilter" || filterName == "BudgetFilterResidential") ?
                              <>
                                <div className='searchbarIndivCol' style={{ marginTop: "3%" }}>

                                  <input placeholder='Min' type="Number" id="minPriceBuy" value={minPriceBud} onClick={() => { setMinOption(true) }} onChange={handlesMinPriceBud} style={{ width: "45%", float: "left" }}></input>
                                  <p style={{ float: "left", width: "7%", padding: "8px 10px", cursor: "default", border: "none" }}>-</p>
                                  <input placeholder='Max' type="Number" id="maxPriceBuy" value={maxPriceBud} onClick={() => { setMinOption(false) }} onChange={handleMaxPriceBud} style={{ width: "45%", float: "left" }}></input>
                                  <div style={{ clear: 'both' }}></div>
                                  {minOption == true ?
                                    <>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(500)}>$500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(1000)}>$1,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(1500)}>$1,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(2000)}>$2,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(2500)}>$2,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(3000)}>$3,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(3500)}>$3,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(4000)}>$4,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(4500)}>$4,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(5000)}>$5,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(5500)}>$5,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(6000)}>$6,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(6500)}>$6,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(7000)}>$7,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(7500)}>$7,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(8000)}>$8,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(8500)}>$8,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(9000)}>$9,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(9500)}>$9,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(10000)}>$10,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(10500)}>$10,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(11000)}>$11,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(11500)}>$11,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(12000)}>$12,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(12500)}>$12,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(13000)}>$13,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(13500)}>$13,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(14000)}>$14,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(14500)}>$14,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(15000)}>$15,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(15500)}>$15,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(16000)}>$16,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(16500)}>$16,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(17000)}>$17,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(17500)}>$17,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(18000)}>$18,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(18500)}>$18,500</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(19000)}>$19,000</p>
                                      <p id="optionBudget" onClick={() => setMinPriceBud(19500)}>$19,500</p>
                                    </> :
                                    <>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud("NA")}>Any Price</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(500)}>$500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(1000)}>$1,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(1500)}>$1,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(2000)}>$2,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(2500)}>$2,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(3000)}>$3,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(3500)}>$3,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(4000)}>$4,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(4500)}>$4,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(5000)}>$5,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(5500)}>$5,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(6000)}>$6,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(6500)}>$6,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(7000)}>$7,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(7500)}>$7,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(8000)}>$8,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(8500)}>$8,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(9000)}>$9,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(9500)}>$9,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(10000)}>$10,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(10500)}>$10,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(11000)}>$11,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(11500)}>$11,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(12000)}>$12,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(12500)}>$12,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(13000)}>$13,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(13500)}>$13,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(14000)}>$14,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(14500)}>$14,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(15000)}>$15,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(15500)}>$15,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(16000)}>$16,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(16500)}>$16,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(17000)}>$17,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(17500)}>$17,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(18000)}>$18,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(18500)}>$18,500</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(19000)}>$19,000</p>
                                      <p id="optionBudget" onClick={() => setMaxPriceBud(19500)}>$19,500</p>
                                    </>
                                  }
                                </div>
                              </> :
                              filterName == "LeaseFilter" ?
                                <>
                                  <div className='searchbarIndivCol'>
                                    <p style={{ color: "grey", fontWeight: "bold" }}>Minimum Lease</p>
                                    {minLeasePeriod == "Days" ?
                                      <select id="dropdown" name="dropdown" value={minLeaseNum} onChange={handleMinLeaseNum}>
                                        <option value="NA">Not Applicable</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                      </select> :
                                      minLeasePeriod == "Months" ?
                                        <select id="dropdown" name="dropdown" value={minLeaseNum} onChange={handleMinLeaseNum}>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                          <option value="9">9</option>
                                          <option value="10">10</option>
                                          <option value="11">11</option>
                                        </select> :
                                        <select id="dropdown" name="dropdown" value={minLeaseNum} onChange={handleMinLeaseNum}>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                          <option value="9">9</option>
                                          <option value="10">10</option>
                                        </select>
                                    }
                                    <select id="dropdown" name="dropdown" value={minLeasePeriod} onChange={handleMinLeasePeriod}>
                                      <option value="Days">Days</option>
                                      <option value="Months">Months</option>
                                      <option value="Years">Years</option>
                                    </select>
                                    <p style={{ color: "grey", fontWeight: "bold", marginTop: "5%" }}>Maximum Lease</p>

                                    {maxLeasePeriod == "Days" ?
                                      <select id="dropdown" name="dropdown" value={maxLeaseNum} onChange={handleMaxLeaseNum}>
                                        <option value="NA">Not Applicable</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                      </select> :
                                      maxLeasePeriod == "Months" ?
                                        <select id="dropdown" name="dropdown" value={maxLeaseNum} onChange={handleMaxLeaseNum}>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                          <option value="9">9</option>
                                          <option value="10">10</option>
                                          <option value="11">11</option>
                                        </select> :
                                        <select id="dropdown" name="dropdown" value={maxLeaseNum} onChange={handleMaxLeaseNum}>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                          <option value="9">9</option>
                                          <option value="10">10</option>
                                        </select>
                                    }

                                    <select id="dropdown" name="dropdown" value={maxLeasePeriod} onChange={handleMaxLeasePeriod}>
                                      <option value="Days">Days</option>
                                      <option value="Months">Months</option>
                                      <option value="Years">Years</option>
                                    </select>

                                  </div>
                                </> :
                                filterName == "TenureFilter" ?
                                  <>
                                    <div className='searchbarIndivCol'>
                                      <div className='propertyType' id="buyPropertyType">
                                        <label style={{ display: "block", width: "100%" }}>
                                          <input
                                            type="checkbox"
                                            checked={isAny}
                                            onChange={() => {
                                              setIsAny(true);
                                              setIs30Year(false);
                                              setIs60Year(false);
                                              setIs99Year(false);
                                              setIs110Year(false);
                                              setIs999Year(false);
                                              setIs9999Year(false);
                                              setIsFreehold(false);
                                              setIsTenureNA(false);
                                            }}
                                            style={{ width: "auto" }}
                                          />
                                          <span class="label-text"> Any</span>
                                        </label>
                                        <br></br>
                                        <label style={{ display: "block", width: "100%" }}>
                                          <input
                                            type="checkbox"
                                            checked={is30Year}
                                            onChange={() => {
                                              setIsAny(false);
                                              setIs30Year(true);
                                             
                                              setIsTenureNA(false);
                                            }}
                                            style={{ width: "auto" }}
                                          />
                                          <span class="label-text">30-year leasehold</span>
                                        </label>
                                        <br></br>
                                        <label style={{ display: "block", width: "100%" }}>
                                          <input
                                            type="checkbox"
                                            checked={is60Year}
                                            onChange={() => {
                                              setIsAny(false);
                                              setIs60Year(true);
                                              
                                              setIsTenureNA(false);
                                            }}
                                            style={{ width: "auto" }}
                                          />
                                          <span class="label-text"> 60-year leasehold</span>
                                        </label>
                                        <br></br>
                                        <label style={{ display: "block", width: "100%" }}>
                                          <input
                                            type="checkbox"
                                            checked={is99Year}
                                            onChange={() => {
                                              setIsAny(false);
                                              
                                              setIs99Year(true);
                                              
                                              setIsTenureNA(false);
                                            }}
                                            style={{ width: "auto" }}
                                          />
                                          <span class="label-text"> 99-year leasehold</span>
                                        </label>
                                        <br></br>
                                        <label style={{ display: "block", width: "100%" }}>
                                          <input
                                            type="checkbox"
                                            checked={is110Year}
                                            onChange={() => {
                                              setIsAny(false);
                                              
                                              setIs110Year(true);
                                             
                                              setIsTenureNA(false);
                                            }}
                                            style={{ width: "auto" }}
                                          />
                                          <span class="label-text"> 110-year leasehold</span>
                                        </label>
                                        <br></br>
                                        <label style={{ display: "block", width: "100%" }}>
                                          <input
                                            type="checkbox"
                                            checked={is999Year}
                                            onChange={() => {
                                              setIsAny(false);
                                              
                                              setIs999Year(true);
                                              
                                              setIsTenureNA(false);
                                            }}
                                            style={{ width: "auto" }}
                                          />
                                          <span class="label-text"> 999-year leasehold</span>
                                        </label>
                                        <br></br>
                                        <label style={{ display: "block", width: "100%" }}>
                                          <input
                                            type="checkbox"
                                            checked={is9999Year}
                                            onChange={() => {
                                              setIsAny(false);
                                              
                                              setIs9999Year(true);
                                              setIsTenureNA(false);
                                            }}
                                            style={{ width: "auto" }}
                                          />
                                          <span class="label-text"> 9999-year leasehold</span>
                                        </label>
                                        <br></br>
                                        <label style={{ display: "block", width: "100%" }}>
                                          <input
                                            type="checkbox"
                                            checked={isFreehold}
                                            onChange={() => {
                                              setIsAny(false);
                                              
                                              setIsFreehold(true);
                                              setIsTenureNA(false);
                                            }}
                                            style={{ width: "auto" }}
                                          />
                                          <span class="label-text"> Freehold</span>
                                        </label>
                                        <br></br>
                                        <label style={{ display: "block", width: "100%" }}>
                                          <input
                                            type="checkbox"
                                            checked={isTenureNA}
                                            onChange={() => {
                                              setIsAny(false);
                                              setIs30Year(false);
                                              setIs60Year(false);
                                              setIs99Year(false);
                                              setIs110Year(false);
                                              setIs999Year(false);
                                              setIs9999Year(false);
                                              setIsFreehold(false);
                                              setIsTenureNA(true);
                                            }}
                                            style={{ width: "auto" }}
                                          />
                                          <span class="label-text"> Not Applicable</span>
                                        </label>

                                      </div>
                                    </div>
                                  </> :
                                  filterName == "TenureFilterResidentialSales" || filterName == "TenureFilterResidentialSalesDropdown" ?
                                    <>
                                      <div className='searchbarIndivCol'>
                                        <div className='propertyType' id="buyPropertyType">
                                          <label style={{ display: "block", width: "100%" }}>
                                            <input
                                              type="checkbox"
                                              checked={isAnyResidential}
                                              onChange={() => {
                                                setIsAnyResidential(true);
                                                setIs30YearResidential(false);
                                                setIs60YearResidential(false);
                                                setIs99YearResidential(false);
                                                setIs103YearResidential(false);
                                                setIs110YearResidential(false);
                                                setIs999YearResidential(false);
                                                setIs9999YearResidential(false);
                                                setIsFreeholdResidential(false);
                                                setIsTenureNAResidential(false);
                                              }}
                                              style={{ width: "auto" }}
                                            />
                                            <span class="label-text"> Any</span>
                                          </label>
                                          <br></br>
                                         
                                          <label style={{ display: "block", width: "100%" }}>
                                            <input
                                              type="checkbox"
                                              checked={is99YearResidential}
                                              onChange={() => {
                                                setIsAnyResidential(false);
                                                
                                                setIs99YearResidential(true);
                                               
                                                setIsTenureNAResidential(false);
                                              }}
                                              style={{ width: "auto" }}
                                            />
                                            <span class="label-text"> 99-year leasehold</span>
                                          </label>
                                          <br></br>
                                          <label style={{ display: "block", width: "100%" }}>
                                            <input
                                              type="checkbox"
                                              checked={is103YearResidential}
                                              onChange={() => {
                                                setIsAnyResidential(false);
                                                
                                                setIs103YearResidential(true);
                                                
                                                setIsTenureNAResidential(false);
                                              }}
                                              style={{ width: "auto" }}
                                            />
                                            <span class="label-text"> 103-year leasehold</span>
                                          </label>
                                          <br></br>
                                          <label style={{ display: "block", width: "100%" }}>
                                            <input
                                              type="checkbox"
                                              checked={is110YearResidential}
                                              onChange={() => {
                                                setIsAnyResidential(false);
                                               
                                                setIs110YearResidential(true);
                                                
                                                setIsTenureNAResidential(false);
                                              }}
                                              style={{ width: "auto" }}
                                            />
                                            <span class="label-text"> 110-year leasehold</span>
                                          </label>
                                          <br></br>
                                          <label style={{ display: "block", width: "100%" }}>
                                            <input
                                              type="checkbox"
                                              checked={is999YearResidential}
                                              onChange={() => {
                                                setIsAnyResidential(false);
                                                
                                                setIs999YearResidential(true);
                                               
                                                setIsTenureNAResidential(false);
                                              }}
                                              style={{ width: "auto" }}
                                            />
                                            <span class="label-text"> 999-year leasehold</span>
                                          </label>
                                          <br></br>
                                          <label style={{ display: "block", width: "100%" }}>
                                            <input
                                              type="checkbox"
                                              checked={is9999YearResidential}
                                              onChange={() => {
                                                setIsAnyResidential(false);
                                               
                                                setIs9999YearResidential(true);

                                                setIsTenureNAResidential(false);
                                              }}
                                              style={{ width: "auto" }}
                                            />
                                            <span class="label-text"> 9999-year leasehold</span>
                                          </label>
                                          <br></br>
                                          <label style={{ display: "block", width: "100%" }}>
                                            <input
                                              type="checkbox"
                                              checked={isFreeholdResidential}
                                              onChange={() => {
                                                setIsAnyResidential(false);
                                                
                                                setIsFreeholdResidential(true);
                                                setIsTenureNAResidential(false);
                                              }}
                                              style={{ width: "auto" }}
                                            />
                                            <span class="label-text"> Freehold</span>
                                          </label>
                                          
                                        </div>
                                      </div>
                                    </> :
                                    filterName == "PropertyFilterSales" || filterName == "PropertyFilterSalesDropdown" ?
                                      <>
                                        <div className='searchbarIndivCol'>


                                          <div className='propertyType' id="buyPropertyType">
                                            <label style={{ display: "block", width: "100%" }}>
                                              <input
                                                type="checkbox"
                                                checked={isAllPropertyFilterSales}
                                                onChange={() => {
                                                  setIsAllPropertyFilterSales(true);
                                                  setIsCommercialPropertyFilterSales(false);
                                                  setIsIndustrialPropertyFilterSales(false);
                                                  setIsLandPropertyFilterSales(false);
                                                  setIsIndustrialLandUsePropertyFilterSales(false);
                                                }}
                                                style={{ width: "auto" }}
                                              />
                                              <span class="label-text">All</span>
                                            </label>
                                            <br></br>
                                            <label style={{ display: "block", width: "100%" }}>
                                              <input
                                                type="checkbox"
                                                checked={isCommercialPropertyFilterSales}
                                                onChange={() => {
                                                  setIsAllPropertyFilterSales(false);
                                                  setIsCommercialPropertyFilterSales(true);
                                                  
                                                }}
                                                style={{ width: "auto" }}
                                              />
                                              <span class="label-text">Commercial</span>
                                            </label>
                                            <br></br>
                                            <label style={{ display: "block", width: "100%" }}>
                                              <input
                                                type="checkbox"
                                                checked={isIndustrialPropertyFilterSales}
                                                onChange={() => {
                                                  setIsAllPropertyFilterSales(false);
                                                  setIsIndustrialPropertyFilterSales(true);
                                                  
                                                }}
                                                style={{ width: "auto" }}
                                              />
                                              <span class="label-text">Industrial</span>
                                            </label>
                                            <br></br>
                                            <label style={{ display: "block", width: "100%" }}>
                                              <input
                                                type="checkbox"
                                                checked={isLandPropertyFilterSales}
                                                onChange={() => {
                                                  setIsAllPropertyFilterSales(false);
                                                  
                                                  setIsLandPropertyFilterSales(true);
                                                }}
                                                style={{ width: "auto" }}
                                              />
                                              <span class="label-text">Land</span>
                                            </label>
                                            <br></br>
                                            <label style={{ display: "block", width: "100%" }}>
                                              <input
                                                type="checkbox"
                                                checked={isIndustrialLandUsePropertyFilterSales}
                                                onChange={() => {
                                                  setIsAllPropertyFilterSales(false);
                                                  
                                                  setIsIndustrialLandUsePropertyFilterSales(true);
                                                }}
                                                style={{ width: "auto" }}
                                              />
                                              <span class="label-text">Industrial Land Use</span>
                                            </label>

                                          </div>
                                        </div>
                                      </> :
                                      filterName == "PropertyFilter" ?
                                        <>
                                          <div className='searchbarIndivCol'>


                                            <div className='propertyType' id="buyPropertyType">
                                              <label style={{ display: "block", width: "100%" }}>
                                                <input
                                                  type="checkbox"
                                                  checked={isAll}
                                                  onChange={handleAllCheckBoxChange}
                                                  style={{ width: "auto" }}
                                                />
                                                <span class="label-text">All</span>
                                              </label>
                                              <br></br>
                                              <label style={{ display: "block", width: "100%" }}>
                                                <input
                                                  type="checkbox"
                                                  checked={isFB}
                                                  onChange={handleFBCheckBoxChange}
                                                  style={{ width: "auto" }}
                                                />
                                                <span class="label-text">F&B</span>
                                              </label>
                                              <br></br>
                                              <label style={{ display: "block", width: "100%" }}>
                                                <input
                                                  type="checkbox"
                                                  checked={isRetail}
                                                  onChange={handleRetailCheckBoxChange}
                                                  style={{ width: "auto" }}
                                                />
                                                <span class="label-text">Retail</span>
                                              </label>
                                              <br></br>
                                              <label style={{ display: "block", width: "100%" }}>
                                                <input
                                                  type="checkbox"
                                                  checked={isService}
                                                  onChange={handleServiceCheckBoxChange}
                                                  style={{ width: "auto" }}
                                                />
                                                <span class="label-text">Service</span>
                                              </label>
                                              <br></br>
                                              <label style={{ display: "block", width: "100%" }}>
                                                <input
                                                  type="checkbox"
                                                  checked={isStorage}
                                                  onChange={handleStorageCheckBoxChange}
                                                  style={{ width: "auto" }}
                                                />
                                                <span class="label-text">Storage</span>
                                              </label>
                                              <br></br>
                                              <label style={{ display: "block", width: "100%" }}>
                                                <input
                                                  type="checkbox"
                                                  checked={isOther}
                                                  onChange={handleOtherCheckBoxChange}
                                                  style={{ width: "auto" }}
                                                />
                                                <span class="label-text">Other</span>
                                              </label>
                                            </div>
                                          </div>
                                        </> :
                                        filterName == "PropertiesFilterResidentialSales" || filterName == "PropertiesFilterResidentialSalesDropdown" ?
                                          <>
                                            <div className='searchbarIndivCol'>
                                              <div className='propertyType' id="buyPropertyType">
                                                <label style={{ display: "block", width: "100%" }}>
                                                  <input
                                                    type="checkbox"
                                                    checked={isHDB}
                                                    onChange={handleHDBCheckBoxChange}
                                                    style={{ width: "auto" }}
                                                  />
                                                  <span class="label-text">HDB</span>
                                                </label>
                                                <br></br>
                                                <label style={{ display: "block", width: "100%" }}>
                                                  <input
                                                    type="checkbox"
                                                    checked={isCondo}
                                                    onChange={handleCondoCheckBoxChange}
                                                    style={{ width: "auto" }}
                                                  />
                                                  <span class="label-text">Condo</span>
                                                </label>
                                                <br></br>
                                                <label style={{ display: "block", width: "100%" }}>
                                                  <input
                                                    type="checkbox"
                                                    checked={isLanded}
                                                    onChange={handleLandedCheckBoxChange}
                                                    style={{ width: "auto" }}
                                                  />
                                                  <span class="label-text">Landed</span>
                                                </label>

                                              </div>
                                            </div>
                                          </> :
                                          filterName == "PropertiesFilterResidential" || filterName == "PropertiesFilterResidentialDropdown" ?
                                            <>
                                              <div className='searchbarIndivCol'>
                                                <div className='propertyType' id="buyPropertyType">
                                                  <label style={{ display: "block", width: "100%" }}>
                                                    <input
                                                      type="checkbox"
                                                      checked={isHDB}
                                                      onChange={handleHDBCheckBoxChange}
                                                      style={{ width: "auto" }}
                                                    />
                                                    <span class="label-text">HDB</span>
                                                  </label>
                                                  <br></br>
                                                  <label style={{ display: "block", width: "100%" }}>
                                                    <input
                                                      type="checkbox"
                                                      checked={isCondo}
                                                      onChange={handleCondoCheckBoxChange}
                                                      style={{ width: "auto" }}
                                                    />
                                                    <span class="label-text">Condo</span>
                                                  </label>
                                                  <br></br>
                                                  <label style={{ display: "block", width: "100%" }}>
                                                    <input
                                                      type="checkbox"
                                                      checked={isLanded}
                                                      onChange={handleLandedCheckBoxChange}
                                                      style={{ width: "auto" }}
                                                    />
                                                    <span class="label-text">Landed</span>
                                                  </label>

                                                </div>
                                              </div>
                                            </>
                                            :
                                            filterName == "SpecificFilterResidential" || filterName == "SpecificFilterResidentialDropdown" ?
                                              <>
                                                {isHDB == true ?
                                                  <>
                                                    <p style={{ color: "grey", fontWeight: "bold" }}>HDB</p>
                                                    <select id="residentialDropDown" value={specificPropertyTypeHDB} onChange={handleSpecificPropertyTypeHDB}>
                                                      <option value="Not Applicable">Not Applicable</option>
                                                      <option value="1 Room">1 Room</option>
                                                      <option value="1 Room / Studio">1 Room / Studio</option>
                                                      <option value="2 Rooms">2 Rooms</option>
                                                      <option value="2 Rooms 2A">2 Rooms 2A</option>
                                                      <option value="2 Rooms 2I (Improved)">2 Rooms 2I (Improved)</option>
                                                      <option value="2 Rooms 2S (Standard)">2 Rooms 2S (Standard)</option>
                                                      <option value="3 Rooms">3 Rooms</option>
                                                      <option value="3 Rooms 3A">3 Rooms 3A</option>
                                                      <option value="3 Rooms 3NG (New Generation)">3 Rooms 3NG (New Generation)</option>
                                                      <option value="3 Rooms 3A (Modified)">3 Rooms 3A (Modified)</option>
                                                      <option value="3 Rooms 3S (Simplified)">3 Rooms 3S (Simplified)</option>
                                                      <option value="3 Rooms 3STD (Standard)">3 Rooms 3STD (Standard)</option>
                                                      <option value="3 Rooms 3PA (Premium Apartment)">3 Rooms 3PA (Premium Apartment)</option>
                                                      <option value="4 Rooms">4 Rooms</option>
                                                      <option value="4 Rooms 4A">4 Rooms 4A</option>
                                                      <option value="4 Rooms 4NG (New Generation)">4 Rooms 4NG (New Generation)</option>
                                                      <option value="4 Rooms 4PA (Premium Apartment)">4 Rooms 4PA (Premium Apartment)</option>
                                                      <option value="4 Rooms 4S (Simplified)">4 Rooms 4S (Simplified)</option>
                                                      <option value="4 Rooms 4I (Improved)">4 Rooms 4I (Improved)</option>
                                                      <option value="4 Rooms 4STD (Standard)">4 Rooms 4STD (Standard)</option>
                                                      <option value="5 Rooms">5 Rooms</option>
                                                      <option value="5 Rooms 5A">5 Rooms 5A</option>
                                                      <option value="5 Rooms 5I (Improved)">5 Rooms 5I (Improved)</option>
                                                      <option value="5 Rooms 5PA (Premium Apartment)">5 Rooms 5PA (Premium Apartment)</option>
                                                      <option value="5 Rooms 5S (Simplified)">5 Rooms 5S (Simplified)</option>
                                                      <option value="Jumbo">Jumbo</option>
                                                      <option value="EA (Exec Apartment)">EA (Exec Apartment)</option>
                                                      <option value="EM (Exec Maisonette)">EM (Exec Maisonette)</option>
                                                      <option value="MG (Multi-Generation)">MG (Multi-Generation)</option>
                                                      <option value="Terrace">Terrace</option>
                                                    </select>
                                                  </>
                                                  : null
                                                }
                                                {isCondo == true ?
                                                  <>
                                                    <p style={{ color: "grey", fontWeight: "bold" }}>Condo</p>
                                                    <select id="residentialDropDown" value={specificPropertyTypeCondo} onChange={handleSpecificPropertyTypeCondo}>
                                                      <option value="Not Applicable">Not Applicable</option>
                                                      <option value="Condominium">Condominium</option>
                                                      <option value="Apartment">Apartment</option>
                                                      <option value="Walk-up">Walk-up</option>
                                                      <option value="Cluster House">Cluster House</option>
                                                      <option value="Executive Condominium">Executive Condominium</option>
                                                    </select>
                                                  </>
                                                  : null
                                                }
                                                {isLanded == true ?
                                                  <>
                                                    <p style={{ color: "grey", fontWeight: "bold" }}>Landed</p>
                                                    <select id="residentialDropDown" value={specificPropertyTypeLanded} onChange={handleSpecificPropertyTypeLanded}>
                                                      <option value="Not Applicable">Not Applicable</option>
                                                      <option value="Detached House">Detached House</option>
                                                      <option value="Semi-Deteached House">Semi-Deteached House</option>
                                                      <option value="Corner Terrace">Corner Terrace</option>
                                                      <option value="Bungalow House">Bungalow House</option>
                                                      <option value="Good Class Bungalow">Good Class Bungalow</option>
                                                      <option value="Shophouse">Shophouse</option>
                                                      <option value="Town House">Town House</option>
                                                      <option value="Conservation House">Conservation House</option>
                                                      <option value="Cluster House">Cluster House</option>
                                                    </select>
                                                  </>
                                                  : null
                                                }
                                                {isHDB == false && isCondo == false && isLanded == false ? <p>Not Applicable</p> : null}
                                              </>
                                              :
                                              filterName == "LeaseFilterResidential" || filterName == "LeaseFilterResidentialDropdown" ?
                                                <>
                                                  <select id="residentialDropDown" value={leaseTermResidential} onChange={handleLeaseTermResidential}>
                                                    <option value="Not Applicable">Not Applicable</option>
                                                    <option value="1 Year">1 Year</option>
                                                    <option value="2 Years">2 Years</option>
                                                    <option value="3 Years">3 Years</option>
                                                    <option value="Short Term / Flexi">Short Term / Flexi</option>
                                                  </select>
                                                </>
                                                :
                                                null


                            }
                          </div>
                        )}

                      </div>
                    </div>

                  </div >
                </ul>
                
              </div>
            </div>
            <div className="w-full px-4 lg:w-6/12">
              <div className="lg:ml-auto lg:text-right">
                <div className="relative">
                  <div className="bg-white">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">

                      <div className="mx-auto mt-10 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16  lg:mx-0 lg:max-w-none lg:grid-cols-2">

                        <article className="flex max-w-xl flex-col items-start justify-between border border-gray-300 rounded-md">

                          <div className="group relative w-full">
                            <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600 text-center">

                              I am a Buyer

                            </h3>
                            <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600 text-center">What is your budget</p>
                          </div>
                          <div className="relative mt-8 flex items-center gap-x-4 w-full">
                          
                            <button style={{ backgroundColor: "#33434D" }} className="block mx-auto w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-red-700 focus:outline-none focus:ring active:bg-red-500 sm:w-auto mb-3">Get Started</button>

                          </div>
                        </article>
                        <article className="flex max-w-xl flex-col items-start justify-between border border-gray-300 rounded-md">

                          <div className="group relative w-full">
                            <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600 text-center">

                              I am a Buyer

                            </h3>
                            <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600 text-center">What is your budget</p>
                          </div>
                          <div className="relative mt-8 flex items-center gap-x-4 w-full">
                           
                            <button style={{ backgroundColor: "#33434D" }} className="block mx-auto w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-red-700 focus:outline-none focus:ring active:bg-red-500 sm:w-auto mb-3">Get Started</button>

                          </div>
                        </article>

                      </div>
                      <div className="mx-auto mt-10 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-2">

                        <article className="flex max-w-xl flex-col items-start justify-between border border-gray-300 rounded-md">

                          <div className="group relative w-full">
                            <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600 text-center">

                              I am a Buyer

                            </h3>
                            <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600 text-center">What is your budget</p>
                          </div>
                          <div className="relative mt-8 flex items-center gap-x-4 w-full">
                           
                            <button style={{ backgroundColor: "#33434D" }} className="block mx-auto w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-red-700 focus:outline-none focus:ring active:bg-red-500 sm:w-auto mb-3">Get Started</button>

                          </div>
                        </article>
                        <article className="flex max-w-xl flex-col items-start justify-between border border-gray-300 rounded-md">

                          <div className="group relative w-full">
                            <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600 text-center">

                              I am a Buyer

                            </h3>
                            <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600 text-center">What is your budget</p>
                          </div>
                          <div className="relative mt-8 flex items-center gap-x-4 w-full">
                          
                            <button style={{ backgroundColor: "#33434D" }} className="block mx-auto w-full rounded bg-red-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-red-700 focus:outline-none focus:ring active:bg-red-500 sm:w-auto mb-3">Get Started</button>

                          </div>
                        </article>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      {somethingWrong ?
        <div className="flex items-center justify-center">
          <div role="alert" className="rounded border-s-4 border-red-500 bg-red-50 p-4 absolute top-0 mt-10 z-50">
            <strong className="block font-medium text-red-800">Something went wrong</strong>

            <p className="mt-2 text-sm text-red-700">
              There is a missing value or invalid value
            </p>
          </div> </div> : null}

      {/* <div ref={targetDivRef} style={{ width: "100%", paddingTop: "2%", borderRadius: "0", backgroundColor: "#FAFAFA", position: "relative", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

        <div style={{ clear: 'both' }}></div>
        <div className='searchBarHome' style={{ position: "relative", zIndex: "1", top: "0", left: "0", right: "0", bottom: "0" }}>
          <div id="searchBaHome" ref={searchbarRef}>
            
            <div style={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px", backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
              <i class="fa-solid fa-magnifying-glass" id="magnifyingIcon" ></i>
              <div className='searchbarIndivCol'>
                <input type="text" placeholder='Search district, listing name, etc' value={locationDetails} onChange={handleLocationDetails}></input>
              </div>
              <div className='searchbarIndivColBtn'>
                <button className="btn--search" style={{ marginLeft: "3%" }} onClick={() => searchApply()}>Search</button> :
                 
              </div>
            </div>

          </div>
        </div>

      </div > */}


      {/* <section className="bg-slate-50">
        <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <h2
            className="text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl"
          >
            Read trusted reviews from our users
          </h2>

          <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-8">
            <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
              <div className="flex items-center gap-4">
                <img
                  alt="Man"
                  src="https://ik.imagekit.io/NextLoop/WhatsApp%20Image%202023-12-13%20at%204.14.21%20PM.jpeg?updatedAt=1703744259583"
                  className="h-14 w-14 rounded-full object-cover"
                />

                <div>
                  <div className="flex justify-center gap-0.5 text-green-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                  </div>

                  <p className="mt-0.5 text-lg font-medium text-gray-900">Tan Hong Ting<br></br>Buyer</p>
                </div>
              </div>

              <p className="mt-4 text-gray-700 text-justify">
                Nextloop.sg proved to be an efficient platform for my needs. After posting my requirements, viewings were swiftly arranged. I am now a delighted new owner of a property at Hillsta. Thank you!
              </p>
            </blockquote>



            <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
              <div className="flex items-center gap-4">
                <img
                  alt="Man"
                  src="https://firebasestorage.googleapis.com/v0/b/letsprop-6ae81.appspot.com/o/Default%2FuserIcon.png?alt=media&token=853c39bb-d07b-4bfb-a4c8-6c4c3d5a740f"
                  className="h-14 w-14 rounded-full object-cover"
                />

                <div>
                  <div className="flex justify-center gap-0.5 text-green-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                  </div>

                  <p className="mt-0.5 text-lg font-medium text-gray-900">William Wong<br></br>WeCan Realty</p>
                </div>
              </div>

              <p className="mt-4 text-gray-700 text-justify">
                I was able to quickly fulfill a listing, all thanks to the buyer's concise requirements. Thank you Nextloop.sg for providing a platform that allows me to connect directly with clients.
              </p>
            </blockquote>

            <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
              <div className="flex items-center gap-4">
                <img
                  alt="Man"
                  src="https://firebasestorage.googleapis.com/v0/b/letsprop-6ae81.appspot.com/o/MS4rfp65lsb2SmMVt9QrYL9XxFb2%2FprofilePic?alt=media&token=6203f4ee-05ff-43cc-b4b0-0b3dab22575f"
                  className="h-14 w-14 rounded-full object-contain"
                />

                <div>
                  <div className="flex justify-center gap-0.5 text-green-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      />
                    </svg>
                  </div>

                  <p className="mt-0.5 text-lg font-medium text-gray-900">Shaun Lim<br></br>Agent</p>
                </div>
              </div>

              <p className="mt-4 text-gray-700 text-justify">
                Advertising on Nextloop is seamless and efficient. Highly responsive support team, which adds an extra layer of satisfaction to the overall experience.
              </p>
            </blockquote>
          </div>
        </div>
      </section> */}
   <section class="bg-white">
  <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 md:grid-cols-1">
  <div class="lg:col-span-5 md:col-span-1 lg:flex lg:mt-0 flex items-center justify-center">
      <img src="./Phone mockup.png" alt="mockup" class="w-full h-auto" />
    </div>
    <div class="mr-auto place-self-center lg:col-span-7 md:col-span-1">
      <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">Nextloop : Rental Marketplace</h1>
      <p class="text-justify max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
        A rental platform to support peer-to-peer lending - Do you have any items that are under utilised at home? Why not monetise them on Nextloop rental app? Instead of letting these unused or under utilised item cluttering your homes, rent them to users around your estate to earn some spare cash. Simply state your rental charges and terms to start your first rental listings!
      </p>
      
        <GooglePlayButton
          url={'https://play.google.com/store/apps/details?id=com.mycompany.nextlabor&hl=en-SG'}
          theme={"light"}
          className={"custom-style"}
        />
        <div className='pt-4'>
        <AppStoreButton
          url={'https://apps.apple.com/sg/app/nextloop-rental-marketplace/id6535674656'}
          theme={"light"}
          className={"custom-style"}
        />
        </div>
      
    </div>
    
  </div>
</section>

      <section className="bg-gray-50 ">
        <div className=" px-4 py-8 sm:py-12  lg:py-16  lg:mx-[5%]">
          <div className="max-w-2xl">
            <div className="flex items-center">
              <FireIcon className="h-8 w-8 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
              <h2 className="ml-2 text-2xl font-bold sm:text-3xl">All Rentable Items</h2>
            </div>



            <p className="mt-4">
              Explore and seek your ideal deals
            </p>
          </div>
          <div className='homeProductList'>

            {queryFirstRow != null ? queryFirstRow.map((project, index) => {
              // var heartIconStatus = false;
              var ID = project.id;
              var project = project.data();
              // if (shortList != null) {
              //   heartIconStatus = shortList.find(doc => { return doc.id === ID })
              // }
              return (

                <div class="Card" style={{ border: "2px solid #33434d", borderRadius: "10px", position: "relative", cursor:"pointer" }} onClick={() => {navigate(`/rental-detail/${ID}`)}}>

                  <div style={{ position: 'relative' }}>


                    <div key={index}>
                      <img id="Card_image" src={project.imageURL} alt={`Picture ${index}`} />
                    </div>



                  </div>
                  {/* <Link to={"/commercialRentDetails/" + ID}> */}

                  <div class="CardContainer">

                    <div id="commericalRentMidPart" style={{ height: "100%" }}>
                      <div style={{ width: "95%", height: "100%" }}>


                        <p id="Card_district" className='pl-2' style={{
                          paddingTop: "5px",
                          height: "30%", /* Adjust height as needed */
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}>{project.title}</p>
                        <div className="flex items-center space-x-2">
                          <MapPinIcon className="h-6 w-6 text-orange-500" />
                          <p className="text-gray-700">{project.location}</p>
                        </div>
                        {project.hasOwnProperty('rentalFree') ?
                          <div className="flex items-center space-x-2">
                            <p id="Card_price" className="text-center pl-2 pt-4">Free</p>
                          </div>
                          : <div className="grid grid-cols-3 gap-0">
                            <div className="py-4">
                              <p id="Card_price" className="text-center">{project.rentalDaily != null ? `$${project.rentalDaily}` : `NA`}</p>
                              <p id="monthlyRentalBudget" className="text-center">Daily</p>
                            </div>
                            <div className="py-4 ">
                              <p id="Card_price" className="text-center">{project.rentalWeekly != null ? `$${project.rentalWeekly}` : `NA`}</p>
                              <p id="monthlyRentalBudget" className="text-center">Weekly</p>
                            </div>
                            <div className="py-4 ">
                              <p id="Card_price" className="text-center">{project.rentalMonthly != null ? `$${project.rentalMonthly}` : `NA`}</p>
                              <p id="monthlyRentalBudget" className="text-center">Monthly</p>
                            </div>
                          </div>}

                      </div>


                      <div style={{ clear: 'both' }}></div>
                    </div>



                    <div style={{ clear: 'both' }}></div>
                  </div>


                  {/* </Link> */}


                </div>


              )
            }) : <h3 style={{ paddingLeft: "20px", paddingBottom: "30px" }}>No Listing Available</h3>}
            <div style={{ clear: 'both' }}></div>

          </div>

        </div>
      </section>


      {/* <section className="overflow-hidden bg-white sm:grid sm:grid-cols-2">
        <div className="p-8 md:p-12 lg:px-16 lg:py-24">
          <div
            className="mx-auto max-w-xl text-center ltr:sm:text-left rtl:sm:text-right"
          >
            <h2 className="text-2xl font-bold text-gray-900 md:text-3xl">
              Introducing Nextloop: Revolutionizing Real Estate
            </h2>

            <p className="hidden text-gray-500 md:mt-4 md:block">
              Nextloop disrupts the traditional real estate landscape by offering a groundbreaking SaaS platform that empowers real estate users to list their specific requirements, encompassing budget constraints and desired property conditions. Unlike conventional real estate web apps, Nextloop shifts the focus to the user's needs, enabling property owners and corporate representatives to effortlessly connect with individuals who have the closest matching real estate requirements. By eliminating redundant communication and facilitating precise matches, Nextloop saves valuable time for both landlords and tenants, ensuring seamless transactions and budget optimization. Experience the future of real estate with Nextloop's innovative approach to property listings.
            </p>

            <div className="mt-4 md:mt-8">
              <a
                href="sign-up"
                style={{ backgroundColor: "#33434d" }}
                className="inline-block rounded px-12 py-3 text-sm font-medium text-white  focus:outline-none "
              >
                Get Started Today
              </a>
            </div>
          </div>
        </div>

     
        <div class="flex flex-col items-center p-8 md:p-12 lg:px-16 lg:py-24">
          <div class="gifDiv mt-10">
            <ChatBubbleLeftRightIcon className="h-8 w-8 text-gray-600 group-hover:text-indigo-600" aria-hidden="true"></ChatBubbleLeftRightIcon>
            <div class="mt-2 flex flex-col">


              <h2 className="text-xl font-bold text-gray-900 md:text-2xl">
                Direct communication
              </h2>
            </div>
          </div>
          <div class="gifDiv mt-10">
            <GlobeAltIcon className="h-8 w-8 text-gray-600 group-hover:text-indigo-600" aria-hidden="true"></GlobeAltIcon>
            <div class="mt-2 flex flex-col">

              <h2 className="text-xl font-bold text-gray-900 md:text-2xl">
                Faster outreach
              </h2>
            </div>
          </div>
          <div class="gifDiv mt-10">
            <WrenchScrewdriverIcon className="h-8 w-8 text-gray-600 group-hover:text-indigo-600" aria-hidden="true"></WrenchScrewdriverIcon>
            <div class="mt-2 flex flex-col">

              <h2 className="text-xl font-bold text-gray-900 md:text-2xl">
                Your Terms - your way
              </h2>
            </div>
          </div>
        </div>


      </section> */}

      {/* <section className="bg-white">
        <div className="mx-auto px-4 py-12 sm:px-6 md:py-16 lg:px-8">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
              Nextloop Community
            </h2>

            <p className="mt-4 text-gray-500 sm:text-xl">
              Creating a platform that facilitates genuine connections between buyers and sellers, fostering quick and fruitful conversations
            </p>
          </div>

          <div className="mt-8 sm:mt-12">
            <dl
              className="grid grid-cols-1 gap-4 sm:grid-cols-4 sm:divide-x sm:divide-gray-100"
            >
              <div className="flex flex-col px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">
                  Views In The Last 30 Days
                </dt>

                <dd className="text-4xl font-extrabold text-blue-600 md:text-5xl">
                  {viewsInLast30Days}
                </dd>
              </div>

              <div className="flex flex-col px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">
                  Agents
                </dt>

                <dd className="text-4xl font-extrabold text-blue-600 md:text-5xl">{agentCount}</dd>
              </div>
              <div className="flex flex-col px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">
                  Landlords
                </dt>

                <dd className="text-4xl font-extrabold text-blue-600 md:text-5xl">{landlordCount}</dd>
              </div>

              <div className="flex flex-col px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">
                  Real Estate Leads
                </dt>

                <dd className="text-4xl font-extrabold text-blue-600 md:text-5xl">{realEstateRequestCount}</dd>
              </div>
            </dl>
          </div>

        </div>
      </section>
      <section className="bg-gray-50">
        <div className="mx-auto px-4 py-12 sm:px-6 md:py-16 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
              Top Interactors in the month of May 2024
            </h2>

            <p className="mt-4 text-gray-500 sm:text-xl">
              Congrats on winning 500 credits!
            </p>
          </div>

          <div className="mt-8 sm:mt-12">
            <dl
              className="grid grid-cols-1 gap-4 sm:grid-cols-3 sm:divide-x sm:divide-gray-100"
            >
              <div className="flex flex-col px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">
                  Huttons Asia Pte Ltd
                </dt>

                <dd className="text-2xl font-extrabold text-blue-600 md:text-3xl">
                  Shaun Lim
                </dd>
              </div>

              <div className="flex flex-col px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">
                  Propnex Pte Ltd
                </dt>

                <dd className="text-2xl font-extrabold text-blue-600 md:text-3xl">Emily Chua</dd>
              </div>

              <div className="flex flex-col px-4 py-8 text-center">
                <dt className="order-last text-lg font-medium text-gray-500">
                  WeCan Realty
                </dt>

                <dd className="text-2xl font-extrabold text-blue-600 md:text-3xl">William Wong</dd>
              </div>
            </dl>
          </div>

        </div>
      </section>
      <section className="py-10 relative bg-white sm:py-16 lg:py-24 lg:pt-36">
        <svg
          id="visual"
          viewBox="0 0 2000 600"
          className="w-full absolute top-0 left-0 z-0"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
        >
          <path
            d="M0 18L65 18L65 66L129 66L129 37L194 37L194 44L258 44L258 25L323 25L323 35L387 35L387 36L452 36L452 38L516 38L516 34L581 34L581 26L645 26L645 68L710 68L710 32L774 32L774 27L839 27L839 29L903 29L903 31L968 31L968 83L1032 83L1032 65L1097 65L1097 57L1161 57L1161 66L1226 66L1226 90L1290 90L1290 66L1355 66L1355 32L1419 32L1419 35L1484 35L1484 18L1548 18L1548 94L1613 94L1613 96L1677 96L1677 72L1742 72L1742 88L1806 88L1806 42L1871 42L1871 46L1935 46L1935 33L2000 33L2000 18L2000 0L2000 0L1935 0L1935 0L1871 0L1871 0L1806 0L1806 0L1742 0L1742 0L1677 0L1677 0L1613 0L1613 0L1548 0L1548 0L1484 0L1484 0L1419 0L1419 0L1355 0L1355 0L1290 0L1290 0L1226 0L1226 0L1161 0L1161 0L1097 0L1097 0L1032 0L1032 0L968 0L968 0L903 0L903 0L839 0L839 0L774 0L774 0L710 0L710 0L645 0L645 0L581 0L581 0L516 0L516 0L452 0L452 0L387 0L387 0L323 0L323 0L258 0L258 0L194 0L194 0L129 0L129 0L65 0L65 0L0 0Z"
            fill="#eaeaea"
            strokeLinecap="square"
            strokeLinejoin="miter"
          />
        </svg>

        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 text-center">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-2xl font-light text-black sm:text-4xl sm:leading-tight">
              Nextloop is honoured to have real estate professionals representing the following real estate firms on our platform
            </h2>
          </div>

          <div className="grid items-center max-w-5xl grid-cols-1 gap-4 mx-auto mt-12 md:mt-20 md:grid-cols-1">
            <p className="mt-2 line-clamp-3 text-l/relaxed md:text-2xl/relaxed text-black/95">
              ERA Realty Network Pte Ltd
              <svg viewBox="0 0 2 2" className="mx-4 inline h-2 w-2 fill-current" aria-hidden="true">
                <circle cx={1} cy={1} r={1} />
              </svg>
              Huttons Asia Pte Ltd
              <svg viewBox="0 0 2 2" className="mx-4 inline h-2 w-2 fill-current" aria-hidden="true">
                <circle cx={1} cy={1} r={1} />
              </svg>
              Propnex Pte Ltd
              <svg viewBox="0 0 2 2" className="mx-4 inline h-2 w-2 fill-current" aria-hidden="true">
                <circle cx={1} cy={1} r={1} />
              </svg>
              WeCan Realty



            </p>

          

            <dd className="text-l font-extrabold text-slate-600 md:text-xl"></dd>

          </div>

         
        </div>
      </section> */}



      <div style={{ clear: 'both' }}></div>
      <Footer />

    </>
  );
}



export default Home;
