import React, { useEffect, useRef, useState } from 'react';
import { UserAuth } from '../../AuthContent';
import { doc, setDoc, getDoc, collection, addDoc, updateDoc, serverTimestamp, deleteField } from "firebase/firestore";
import { db, storage } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import Acknowledge from './Acknowledge';
import '../css/UserTypeModal.css';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import AcknowledgeNormal from '../../AcknowledgeNormal';
import Spinner from './Spinner';
import { deleteObject } from 'firebase/storage';

export default function ChangePlan(props) {
    const { user } = UserAuth();




    const [message, setMessage] = useState(null);

    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);

    const [activeSpin, setActiveSpin] = useState(false);

    const [chooseTier, setChooseTier] = useState(true);
    const [standard, setStandard] = useState(false);
    const [premium, setPremium] = useState(false);
    const [superPremium, setSuperPremium] = useState(false);
    const [enterprise, setEnterprise] = useState(false);
    const [paymentTab, setPaymentTab] = useState(false);
    const [promotionTab, setPromotionTab] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [tierPayment, setTierPayment] = useState('Tier 1');




    const closeUserTypeModal = () => {
        setStandard(false);
        setPremium(false);
        setEnterprise(false);
        setPaymentTab(false);
        setChooseTier(true);
        setPromotionTab(false);
        props.setTrigger(false);

    }


    const proceedPromotion = async () => {
        var promotionDoc = await getDoc(doc(db, 'promotionCode', 'promoCode'));
        if (promoCode != promotionDoc.data().code && promoCode != promotionDoc.data().agentPromo && promoCode != promotionDoc.data().additionalMonth) {
            alert("Invalid Code");
        }
        else {
            if (props.currentUserInfo.launchedPromotionCode.includes(promoCode) == false) {
                if (promoCode == promotionDoc.data().agentPromo) {
                    var newLaunchedPromotionCode = [...props.currentUserInfo.launchedPromotionCode];
                    newLaunchedPromotionCode.push(promoCode)
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        subscriptionID: "PromotionCode",
                        membership: "Premium",
                        billingAmount: 380,
                        gracePeriod: deleteField(),
                        chatLeft: props.currentUserInfo.chatLeft + 32,
                        advancedDetails: props.currentUserInfo.advancedDetails + 32,
                        subscriptionPeriod: "Monthly",
                        subscriptionCounter: props.currentUserInfo.subscriptionCounter + 60,
                        launchedPromotionCode: newLaunchedPromotionCode,
                        freeAppt: true
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;


                        newCurrentUserInfo.subscriptionID = "PromotionCode";
                        newCurrentUserInfo.membership = "Premium";
                        newCurrentUserInfo.billingAmount = 380;
                        newCurrentUserInfo.chatLeft = props.currentUserInfo.chatLeft + 32;
                        newCurrentUserInfo.advancedDetails = props.currentUserInfo.advancedDetails + 32;
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.subscriptionCounter = props.currentUserInfo.subscriptionCounter + 60;
                        newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;

                        setMessage("Promotion Code Verified. Once your trail period has ended, select a tier to continue your subscription");
                        setAcknowledgeNormal(true);
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        // closeUserTypeModal();

                    })
                }
                else if (promoCode == promotionDoc.data().additionalMonth) {
                    var allow = false;
                    if (props.commercialPurchase.length != 0) {
                        const timestampDate = new Date(props.commercialPurchase[0].details.timePosted.seconds * 1000 + props.commercialPurchase[0].details.timePosted.nanoseconds / 1000000);
                        const currentDate = new Date();

                        if (
                            timestampDate.getFullYear() === currentDate.getFullYear() &&
                            timestampDate.getMonth() === currentDate.getMonth() &&
                            props.commercialPurchase[0].details.status == "leasing"
                        ) {
                            console.log('commercial', props.commercialPurchase[0]);
                            allow = true;
                        }
                    }
                    if (props.commercialRent != 0) {
                        const timestampDate = new Date(props.commercialRent[0].details.timePosted.seconds * 1000 + props.commercialRent[0].details.timePosted.nanoseconds / 1000000);
                        const currentDate = new Date();

                        if (
                            timestampDate.getFullYear() === currentDate.getFullYear() &&
                            timestampDate.getMonth() === currentDate.getMonth() &&
                            props.commercialRent[0].details.status == "leasing"
                        ) {
                            console.log('commercialRent', props.commercialRent[0]);
                            allow = true;
                        }
                    }
                    if (props.residentialPurchase != 0) {
                        const timestampDate = new Date(props.residentialPurchase[0].details.timePosted.seconds * 1000 + props.residentialPurchase[0].details.timePosted.nanoseconds / 1000000);
                        const currentDate = new Date();

                        if (
                            timestampDate.getFullYear() === currentDate.getFullYear() &&
                            timestampDate.getMonth() === currentDate.getMonth() &&
                            props.residentialPurchase[0].details.status == "leasing"
                        ) {
                            console.log('residentialPurchase', props.residentialPurchase[0]);
                            allow = true;
                        }
                    }
                    if (props.residentialRent != 0) {
                        const timestampDate = new Date(props.residentialRent[0].details.timePosted.seconds * 1000 + props.residentialRent[0].details.timePosted.nanoseconds / 1000000);
                        const currentDate = new Date();

                        if (
                            timestampDate.getFullYear() === currentDate.getFullYear() &&
                            timestampDate.getMonth() === currentDate.getMonth() &&
                            props.residentialRent[0].details.status == "leasing"
                        ) {
                            console.log('residentialRent', props.residentialRent[0]);
                            allow = true;
                        }
                    }
                    if (allow) {
                        var newLaunchedPromotionCode = [...props.currentUserInfo.launchedPromotionCode];
                        newLaunchedPromotionCode.push(promoCode)

                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            subscriptionID: "PromotionCode",
                            membership: "Premium",
                            billingAmount: 380,
                            gracePeriod: deleteField(),
                            chatLeft: props.currentUserInfo.chatLeft + 16,
                            advancedDetails: props.currentUserInfo.advancedDetails + 16,
                            subscriptionPeriod: "Monthly",
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter + 30,
                            launchedPromotionCode: newLaunchedPromotionCode,
                            freeAppt: true
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;


                            newCurrentUserInfo.subscriptionID = "PromotionCode";
                            newCurrentUserInfo.membership = "Premium";
                            newCurrentUserInfo.billingAmount = 380;
                            newCurrentUserInfo.chatLeft = props.currentUserInfo.chatLeft + 16;
                            newCurrentUserInfo.advancedDetails = props.currentUserInfo.advancedDetails + 16;
                            newCurrentUserInfo.subscriptionPeriod = "Monthly";
                            newCurrentUserInfo.subscriptionCounter = props.currentUserInfo.subscriptionCounter + 30;
                            newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;

                            setMessage("Promotion Code Verified. Once your trail period has ended, select a tier to continue your subscription");
                            setAcknowledgeNormal(true);
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            // closeUserTypeModal();
                        })
                    }
                    else {
                        setMessage("Please have at least 1 approved listing before applying this promotion code");
                        setAcknowledgeNormal(true);
                    }
                }
                else {
                    var newLaunchedPromotionCode = [...props.currentUserInfo.launchedPromotionCode];
                    newLaunchedPromotionCode.push(promoCode)
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        subscriptionID: "PromotionCode",
                        membership: "Premium",
                        billingAmount: 380,
                        gracePeriod: deleteField(),
                        chatLeft: props.currentUserInfo.chatLeft + 16,
                        advancedDetails: props.currentUserInfo.advancedDetails + 16,
                        startDate: new Date(),
                        subscriptionPeriod: "Monthly",
                        subscriptionCounter: props.currentUserInfo.subscriptionCounter + 30,
                        launchedPromotionCode: newLaunchedPromotionCode,
                        freeAppt: true
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;


                        newCurrentUserInfo.subscriptionID = "PromotionCode";
                        newCurrentUserInfo.membership = "Premium";
                        newCurrentUserInfo.billingAmount = 380;
                        newCurrentUserInfo.chatLeft = props.currentUserInfo.chatLeft + 16;
                        newCurrentUserInfo.advancedDetails = props.currentUserInfo.advancedDetails + 16;
                        newCurrentUserInfo.startDate = new Date();
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.subscriptionCounter = props.currentUserInfo.subscriptionCounter + 30;
                        newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;

                        setMessage("Promotion Code Verified. Once your trail period has ended, select a tier to continue your subscription");
                        setAcknowledgeNormal(true);
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        // closeUserTypeModal();

                    })


                }

            }
            else {
                alert("You have already redeemed the launch bonus")
            }

        }


    }

    const createOrder = async (data, actions) => {
        try {
            // const selectElement = document.querySelector('.select');

            // let planID = "";
            // //P-87V97227543012720MSIEUPQ premium no charges
            // //P-5JG55492SV880132GMSIEUEY standard no charges
            // if (selectElement.id == "standard" && props.currentUserInfo.newUserSetup == true) {
            //     planID = "P-4WE25674YD030400NMSFLZEI";
            // } else if (selectElement.id == "premium" && props.currentUserInfo.newUserSetup == true) {
            //     planID = "P-6M109645LM7508252MSFLZ2Y";
            // }
            // else if (selectElement.id == "superPremium" && props.currentUserInfo.newUserSetup == true) {
            //     planID = "P-7AD3724745234033TMSNY6EQ";
            // }
            // else if (selectElement.id == "standard" && props.currentUserInfo.newUserSetup == false) {
            //     planID = "P-5JG55492SV880132GMSIEUEY"
            // }
            // else if (selectElement.id == "premium" && props.currentUserInfo.newUserSetup == false) {
            //     planID = "P-87V97227543012720MSIEUPQ"
            // }
            // else if (selectElement.id == "superPremium" && props.currentUserInfo.newUserSetup == false) {
            //     planID = "P-4XH70004EV358760SMSNY6RA"
            // }
            var paymentAmount = 25;
            if (tierPayment == "Tier 2") {
                paymentAmount = 100;
            }
            else if (tierPayment == "Tier 3") {
                paymentAmount = 250;
            }

            else if (tierPayment == "Tier 4") {
                paymentAmount = 500;
            }
            else if (tierPayment == "Tier 5") {
                paymentAmount = 1000;
            }

            return fetch("https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/createPayPalOrder", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + user.accessToken
                },
                // use the "body" param to optionally pass additional order information
                // like product skus and quantities
                body: JSON.stringify({
                    data: {
                        amount: paymentAmount,
                        currencyCode: "SGD",
                        intent: "CAPTURE"
                    }
                }),
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error("Failed to create order");
                    }
                })
                .then((data) => {
                    return data.result.orderID;
                })
                .catch((err) => {
                    console.error(err);
                });

            // if (props.currentUserInfo.subscriptionID === "PromotionCode") {
            //     const startDate = new Date(); // Get the current date
            //     startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
            //     startDate.setMinutes(startDate.getMinutes() + 2);
            //     const response = await fetch(
            //         "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
            //         {
            //             method: "POST",
            //             headers: {
            //                 "Content-Type": "application/json",
            //                 Authorization: "Bearer " + user.accessToken,
            //             },
            //             body: JSON.stringify({
            //                 data: {
            //                     plan_id: planID,
            //                     intent: "subscription",
            //                     start_time: startDate
            //                 },
            //             }),
            //         }
            //     );

            //     if (response.ok) {
            //         const data = await response.json();
            //         return data.result.orderID;
            //     } else {
            //         throw new Error("Failed to create order");
            //     }
            // }
            // else if (props.currentUserInfo.subscriptionID == null) {
            //     if (props.currentUserInfo.membership != null) {
            //         const startDate = new Date(); // Get the current date
            //         startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
            //         startDate.setMinutes(startDate.getMinutes() + 2);
            //         console.log(startDate, planID)
            //         const response = await fetch(
            //             "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
            //             {
            //                 method: "POST",
            //                 headers: {
            //                     "Content-Type": "application/json",
            //                     Authorization: "Bearer " + user.accessToken,
            //                 },
            //                 body: JSON.stringify({
            //                     data: {
            //                         plan_id: planID,
            //                         intent: "subscription",
            //                         start_time: startDate
            //                     },
            //                 }),
            //             }
            //         );

            //         if (response.ok) {
            //             const data = await response.json();
            //             return data.result.orderID;
            //         } else {
            //             throw new Error("Failed to create order");
            //         }
            //     }

            //     else {
            //         const response = await fetch(
            //             "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
            //             {
            //                 method: "POST",
            //                 headers: {
            //                     "Content-Type": "application/json",
            //                     Authorization: "Bearer " + user.accessToken,
            //                 },
            //                 body: JSON.stringify({
            //                     data: {
            //                         plan_id: planID,
            //                         intent: "subscription"
            //                     },
            //                 }),
            //             }
            //         );

            //         if (response.ok) {
            //             const data = await response.json();
            //             return data.result.orderID;
            //         } else {
            //             throw new Error("Failed to create order");
            //         }
            //     }
            // }
            // else {
            //     //upgrade
            //     if (selectElement.id == "premium" && props.currentUserInfo.membership == "Standard") {
            //         const response = await fetch(
            //             "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
            //             {
            //                 method: "POST",
            //                 headers: {
            //                     "Content-Type": "application/json",
            //                     Authorization: "Bearer " + user.accessToken,
            //                 },
            //                 body: JSON.stringify({
            //                     data: {
            //                         plan_id: planID,
            //                         intent: "subscription"
            //                     },
            //                 }),
            //             }
            //         );

            //         if (response.ok) {
            //             const data = await response.json();
            //             return data.result.orderID;
            //         } else {
            //             throw new Error("Failed to create order");
            //         }

            //     }
            //     else if (selectElement.id == "superPremium" && props.currentUserInfo.membership == "Premium") {
            //         const response = await fetch(
            //             "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
            //             {
            //                 method: "POST",
            //                 headers: {
            //                     "Content-Type": "application/json",
            //                     Authorization: "Bearer " + user.accessToken,
            //                 },
            //                 body: JSON.stringify({
            //                     data: {
            //                         plan_id: planID,
            //                         intent: "subscription"
            //                     },
            //                 }),
            //             }
            //         );

            //         if (response.ok) {
            //             const data = await response.json();
            //             return data.result.orderID;
            //         } else {
            //             throw new Error("Failed to create order");
            //         }

            //     }
            //     else {
            //         //you have an subscription id
            //         const startDate = new Date(); // Get the current date
            //         startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
            //         startDate.setMinutes(startDate.getMinutes() + 2);
            //         const response = await fetch(
            //             "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
            //             {
            //                 method: "POST",
            //                 headers: {
            //                     "Content-Type": "application/json",
            //                     Authorization: "Bearer " + user.accessToken,
            //                 },
            //                 body: JSON.stringify({
            //                     data: {
            //                         plan_id: planID,
            //                         intent: "subscription",
            //                         start_time: startDate
            //                     },
            //                 }),
            //             }
            //         );

            //         if (response.ok) {
            //             const data = await response.json();
            //             return data.result.orderID;
            //         } else {
            //             throw new Error("Failed to create order");
            //         }

            //     }


            // }
        } catch (error) {
            console.error(error);
        }
    };



    const onApprove = async (data, actions) => {
        var creditGiven = 500;
        if (tierPayment == "Tier 1") {
            creditGiven = 25;
        }
        else if (tierPayment == "Tier 2") {
            creditGiven = 120;
        }
        else if (tierPayment == "Tier 3") {
            creditGiven = 320;
        }
        else if (tierPayment == "Tier 4") {
            creditGiven = 750;
        }
        else if (tierPayment == "Tier 5") {
            creditGiven = 1550
        }
        setActiveSpin(true);
        return fetch("https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/capturePayPalOrder", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + user.accessToken
            },
            body: JSON.stringify({
                data: {
                    orderID: data.orderID,
                    path: "creditPayment"
                }
            }),
        })
            .then((response) => {
                console.log(response)
                return response.json();
            })
            .then(async (data) => {
                // Do something with the data here
                console.log(data);
                if (data.hasOwnProperty('result')) {

                    if (data.result.status == "COMPLETED") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            credit: props.currentUserInfo.credit + creditGiven
                        }).then(() => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            newCurrentUserInfo.credit = props.currentUserInfo.credit + creditGiven;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setMessage('Payment has been successful!')
                            setAcknowledgeNormal(true);
                            setStandard(false);
                            setPremium(false);
                            setEnterprise(false);
                            setPaymentTab(false);
                            setChooseTier(true);
                            setPromotionTab(false);
                        })
                    }
                }
                setActiveSpin(false)
            })
        // const selectElement = document.querySelector('.select');
        // if (props.currentUserInfo.subscriptionID === "PromotionCode") {

        //     if (selectElement.id == "standard") {
        //         await updateDoc(doc(db, 'userInfo', user.uid), {
        //             subscriptionID: data.subscriptionID,
        //             newUserSetup: false,
        //             downgrade: "Standard",
        //             gracePeriod: deleteField(),
        //             subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
        //             subscriptionPeriod: "Monthly",
        //             freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //         }).then(() => {
        //             var newCurrentUserInfo = props.currentUserInfo;
        //             newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //             newCurrentUserInfo.downgrade = "Standard";
        //             newCurrentUserInfo.newUserSetup = false;
        //             newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //             newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //             props.setCurrentUserInfo(newCurrentUserInfo);
        //             setMessage('Payment has been successful!')
        //             setAcknowledgeNormal(true);
        //             setStandard(false);
        //             setPremium(false);
        //             setEnterprise(false);
        //             setPaymentTab(false);
        //             setChooseTier(true);
        //             setPromotionTab(false);
        //         })
        //     }
        //     else if (selectElement.id == "premium") {
        //         await updateDoc(doc(db, 'userInfo', user.uid), {
        //             subscriptionID: data.subscriptionID,
        //             newUserSetup: false,
        //             downgrade: "Premium",
        //             subscriptionPeriod: "Monthly",
        //             subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
        //             gracePeriod: deleteField(),
        //             freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //         }).then(() => {
        //             var newCurrentUserInfo = props.currentUserInfo;
        //             newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //             newCurrentUserInfo.downgrade = "Premium";
        //             newCurrentUserInfo.newUserSetup = false;
        //             newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //             newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //             props.setCurrentUserInfo(newCurrentUserInfo);
        //             setMessage('Payment has been successful!')
        //             setAcknowledgeNormal(true);
        //             setStandard(false);
        //             setPremium(false);
        //             setEnterprise(false);
        //             setPaymentTab(false);
        //             setChooseTier(true);
        //             setPromotionTab(false);
        //         })
        //     }
        //     else {
        //         await updateDoc(doc(db, 'userInfo', user.uid), {
        //             subscriptionID: data.subscriptionID,
        //             newUserSetup: false,
        //             downgrade: "Super-Premium",
        //             subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
        //             subscriptionPeriod: "Monthly",
        //             gracePeriod: deleteField(),
        //             freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //         }).then(() => {
        //             var newCurrentUserInfo = props.currentUserInfo;
        //             newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //             newCurrentUserInfo.downgrade = "Super-Premium";
        //             newCurrentUserInfo.newUserSetup = false;
        //             newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //             newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //             props.setCurrentUserInfo(newCurrentUserInfo);
        //             setMessage('Payment has been successful!')
        //             setAcknowledgeNormal(true);
        //             setStandard(false);
        //             setPremium(false);
        //             setEnterprise(false);
        //             setPaymentTab(false);
        //             setChooseTier(true);
        //             setPromotionTab(false);
        //         })
        //     }


        // }
        // else if (props.currentUserInfo.subscriptionID == null) {
        //     if (selectElement.id == "standard" && props.currentUserInfo.membership == "Standard") {
        //         await updateDoc(doc(db, 'userInfo', user.uid), {
        //             oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //             subscriptionID: data.subscriptionID,
        //             newUserSetup: false,
        //             subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
        //             subscriptionPeriod: "Monthly",
        //             freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //             downgrade: deleteField(),
        //             gracePeriod: deleteField()
        //         }).then(() => {
        //             var newCurrentUserInfo = props.currentUserInfo;
        //             newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //             newCurrentUserInfo.downgrade = null;
        //             newCurrentUserInfo.newUserSetup = false;
        //             newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //             newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //             props.setCurrentUserInfo(newCurrentUserInfo);
        //             setMessage('Payment has been successful!')
        //             setAcknowledgeNormal(true);
        //             setStandard(false);
        //             setPremium(false);
        //             setEnterprise(false);
        //             setPaymentTab(false);
        //             setChooseTier(true);
        //             setPromotionTab(false);
        //         })
        //     }
        //     else if (selectElement.id == "standard" && props.currentUserInfo.membership == "Premium") {
        //         await updateDoc(doc(db, 'userInfo', user.uid), {
        //             oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //             subscriptionID: data.subscriptionID,
        //             newUserSetup: false,
        //             subscriptionPeriod: "Monthly",
        //             subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
        //             gracePeriod: deleteField(),
        //             freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //             downgrade: "Standard"
        //         }).then(() => {
        //             var newCurrentUserInfo = props.currentUserInfo;
        //             newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //             newCurrentUserInfo.downgrade = "Standard";
        //             newCurrentUserInfo.newUserSetup = false;
        //             newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //             newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //             props.setCurrentUserInfo(newCurrentUserInfo);
        //             setMessage('Payment has been successful!')
        //             setAcknowledgeNormal(true);
        //             setStandard(false);
        //             setPremium(false);
        //             setEnterprise(false);
        //             setPaymentTab(false);
        //             setChooseTier(true);
        //             setPromotionTab(false);
        //         })
        //     }
        //     else if (selectElement.id == "standard" && props.currentUserInfo.membership == "Super-Premium") {
        //         await updateDoc(doc(db, 'userInfo', user.uid), {
        //             oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //             subscriptionID: data.subscriptionID,
        //             subscriptionPeriod: "Monthly",
        //             newUserSetup: false,
        //             subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
        //             gracePeriod: deleteField(),
        //             freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //             downgrade: "Standard"
        //         }).then(() => {
        //             var newCurrentUserInfo = props.currentUserInfo;
        //             newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //             newCurrentUserInfo.downgrade = "Standard";
        //             newCurrentUserInfo.newUserSetup = false;
        //             newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //             newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //             props.setCurrentUserInfo(newCurrentUserInfo);
        //             setMessage('Payment has been successful!')
        //             setAcknowledgeNormal(true);
        //             setStandard(false);
        //             setPremium(false);
        //             setEnterprise(false);
        //             setPaymentTab(false);
        //             setChooseTier(true);
        //             setPromotionTab(false);
        //         })
        //     }
        //     else if (selectElement.id == "premium" && props.currentUserInfo.membership == "Premium") {
        //         await updateDoc(doc(db, 'userInfo', user.uid), {
        //             oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //             subscriptionID: data.subscriptionID,
        //             subscriptionPeriod: "Monthly",
        //             subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
        //             newUserSetup: false,
        //             gracePeriod: deleteField(),
        //             freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //             downgrade: deleteField()
        //         }).then(() => {
        //             var newCurrentUserInfo = props.currentUserInfo;
        //             newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //             newCurrentUserInfo.downgrade = null;
        //             newCurrentUserInfo.newUserSetup = false;
        //             newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //             newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //             props.setCurrentUserInfo(newCurrentUserInfo);
        //             setMessage('Payment has been successful!')
        //             setAcknowledgeNormal(true);
        //             setStandard(false);
        //             setPremium(false);
        //             setEnterprise(false);
        //             setPaymentTab(false);
        //             setChooseTier(true);
        //             setPromotionTab(false);
        //         })
        //     }
        //     else if (selectElement.id == "premium" && props.currentUserInfo.membership == "Standard") {
        //         await updateDoc(doc(db, 'userInfo', user.uid), {
        //             oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //             subscriptionID: data.subscriptionID,
        //             subscriptionPeriod: "Monthly",
        //             membership: "Premium",
        //             gracePeriod: deleteField(),
        //             subscriptionCounter: 30,
        //             downgrade: deleteField(),
        //             billingAmount: 380,
        //             freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //             chatLeft: props.currentUserInfo.chatLeft + 16,
        //             advancedDetails: props.currentUserInfo.advancedDetails + 16,
        //             newUserSetup: false
        //         }).then(() => {
        //             var newCurrentUserInfo = props.currentUserInfo;
        //             newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //             newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 16;
        //             newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //             newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 16;
        //             newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //             newCurrentUserInfo.billingAmount = 380;
        //             newCurrentUserInfo.membership = "Premium";
        //             newCurrentUserInfo.newUserSetup = false;
        //             props.setCurrentUserInfo(newCurrentUserInfo);
        //             setMessage('Payment has been successful!')
        //             setAcknowledgeNormal(true);
        //             setStandard(false);
        //             setPremium(false);
        //             setEnterprise(false);
        //             setPaymentTab(false);
        //             setChooseTier(true);
        //             setPromotionTab(false);
        //         })
        //     }
        //     else if (selectElement.id == "premium" && props.currentUserInfo.membership == "Super-Premium") {
        //         await updateDoc(doc(db, 'userInfo', user.uid), {
        //             oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //             subscriptionID: data.subscriptionID,
        //             subscriptionPeriod: "Monthly",
        //             subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
        //             gracePeriod: deleteField(),
        //             newUserSetup: false,
        //             freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //             downgrade: "Premium",
        //         }).then(() => {
        //             var newCurrentUserInfo = props.currentUserInfo;
        //             newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //             newCurrentUserInfo.downgrade = "Premium";
        //             newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //             newCurrentUserInfo.newUserSetup = false;
        //             newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //             props.setCurrentUserInfo(newCurrentUserInfo);
        //             setMessage('Payment has been successful!')
        //             setAcknowledgeNormal(true);
        //             setStandard(false);
        //             setPremium(false);
        //             setEnterprise(false);
        //             setPaymentTab(false);
        //             setChooseTier(true);
        //             setPromotionTab(false);
        //         })
        //     }
        //     else if (selectElement.id == "standard") {
        //         await updateDoc(doc(db, 'userInfo', user.uid), {
        //             oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //             subscriptionID: data.subscriptionID,
        //             subscriptionPeriod: "Monthly",
        //             downgrade: deleteField(),
        //             gracePeriod: deleteField(),
        //             membership: "Standard",
        //             billingAmount: 180,
        //             subscriptionCounter: 30,
        //             chatLeft: props.currentUserInfo.chatLeft + 7,
        //             freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //             newUserSetup: false
        //         }).then(() => {
        //             var newCurrentUserInfo = props.currentUserInfo;
        //             newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //             newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 7;
        //             newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //             newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //             newCurrentUserInfo.billingAmount = 180;
        //             newCurrentUserInfo.membership = "Standard";
        //             newCurrentUserInfo.newUserSetup = false;
        //             props.setCurrentUserInfo(newCurrentUserInfo);
        //             setMessage('Payment has been successful!')
        //             setAcknowledgeNormal(true);
        //             setStandard(false);
        //             setPremium(false);
        //             setEnterprise(false);
        //             setPaymentTab(false);
        //             setChooseTier(true);
        //             setPromotionTab(false);
        //         })
        //     }
        //     else if (selectElement.id == "premium") {
        //         await updateDoc(doc(db, 'userInfo', user.uid), {
        //             oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //             subscriptionID: data.subscriptionID,
        //             subscriptionPeriod: "Monthly",
        //             downgrade: deleteField(),
        //             gracePeriod: deleteField(),
        //             membership: "Premium",
        //             subscriptionCounter: 30,
        //             billingAmount: 380,
        //             chatLeft: props.currentUserInfo.chatLeft + 16,
        //             advancedDetails: props.currentUserInfo.advancedDetails + 16,
        //             freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //             newUserSetup: false
        //         }).then(() => {
        //             var newCurrentUserInfo = props.currentUserInfo;
        //             newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //             newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 16;
        //             newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //             newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 16;
        //             newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //             newCurrentUserInfo.billingAmount = 380;
        //             newCurrentUserInfo.membership = "Premium";
        //             newCurrentUserInfo.newUserSetup = false;
        //             props.setCurrentUserInfo(newCurrentUserInfo);
        //             setMessage('Payment has been successful!')
        //             setAcknowledgeNormal(true);
        //             setStandard(false);
        //             setPremium(false);
        //             setEnterprise(false);
        //             setPaymentTab(false);
        //             setChooseTier(true);
        //             setPromotionTab(false);
        //         })
        //     }
        //     else {
        //         //superPremium
        //         await updateDoc(doc(db, 'userInfo', user.uid), {
        //             oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //             subscriptionID: data.subscriptionID,
        //             subscriptionPeriod: "Monthly",
        //             downgrade: deleteField(),
        //             gracePeriod: deleteField(),
        //             membership: "Super-Premium",
        //             subscriptionCounter: 30,
        //             chatLeft: props.currentUserInfo.chatLeft + 40,
        //             billingAmount: 380,
        //             freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //             advancedDetails: props.currentUserInfo.advancedDetails + 40,
        //             newUserSetup: false
        //         }).then(() => {
        //             var newCurrentUserInfo = props.currentUserInfo;
        //             newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //             newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //             newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 40;
        //             newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 40;
        //             newCurrentUserInfo.billingAmount = 380;
        //             newCurrentUserInfo.membership = "Super-Premium";
        //             newCurrentUserInfo.newUserSetup = false;
        //             newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //             props.setCurrentUserInfo(newCurrentUserInfo);
        //             setMessage('Payment has been successful!')
        //             setAcknowledgeNormal(true);
        //             setStandard(false);
        //             setPremium(false);
        //             setEnterprise(false);
        //             setPaymentTab(false);
        //             setChooseTier(true);
        //             setPromotionTab(false);
        //         })
        //     }
        // }
        // else {
        //     await cancelMembership('backend').then(async () => {
        //         if (selectElement.id == "standard") {
        //             if (props.currentUserInfo.membership == "Standard") {
        //                 await updateDoc(doc(db, 'userInfo', user.uid), {
        //                     oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //                     subscriptionID: data.subscriptionID,
        //                     subscriptionPeriod: "Monthly",
        //                     gracePeriod: deleteField(),
        //                     newUserSetup: false,
        //                     subscriptionCounter: 30,
        //                     freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //                     downgrade: deleteField()
        //                 }).then(() => {
        //                     var newCurrentUserInfo = props.currentUserInfo;
        //                     newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //                     newCurrentUserInfo.downgrade = null;
        //                     newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //                     newCurrentUserInfo.newUserSetup = false;
        //                     newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //                     props.setCurrentUserInfo(newCurrentUserInfo);
        //                     setMessage('Payment has been successful!')
        //                     setAcknowledgeNormal(true);
        //                     setStandard(false);
        //                     setPremium(false);
        //                     setEnterprise(false);
        //                     setPaymentTab(false);
        //                     setChooseTier(true);
        //                     setPromotionTab(false);
        //                 })
        //             }
        //             else if (props.currentUserInfo.membership == "Premium") {
        //                 await updateDoc(doc(db, 'userInfo', user.uid), {
        //                     oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //                     subscriptionID: data.subscriptionID,
        //                     subscriptionPeriod: "Monthly",
        //                     gracePeriod: deleteField(),
        //                     newUserSetup: false,
        //                     subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
        //                     freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //                     downgrade: "Standard"
        //                 }).then(() => {
        //                     var newCurrentUserInfo = props.currentUserInfo;
        //                     newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //                     newCurrentUserInfo.downgrade = "Standard";
        //                     newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //                     newCurrentUserInfo.newUserSetup = false;
        //                     newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //                     props.setCurrentUserInfo(newCurrentUserInfo);
        //                     setMessage('Payment has been successful!')
        //                     setAcknowledgeNormal(true);
        //                     setStandard(false);
        //                     setPremium(false);
        //                     setEnterprise(false);
        //                     setPaymentTab(false);
        //                     setChooseTier(true);
        //                     setPromotionTab(false);
        //                 })
        //             }
        //             else {
        //                 //superPremium
        //                 await updateDoc(doc(db, 'userInfo', user.uid), {
        //                     oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //                     subscriptionID: data.subscriptionID,
        //                     subscriptionPeriod: "Monthly",
        //                     gracePeriod: deleteField(),
        //                     newUserSetup: false,
        //                     subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
        //                     freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //                     downgrade: "Standard"
        //                 }).then(() => {
        //                     var newCurrentUserInfo = props.currentUserInfo;
        //                     newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //                     newCurrentUserInfo.downgrade = "Standard";
        //                     newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //                     newCurrentUserInfo.newUserSetup = false;
        //                     newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //                     props.setCurrentUserInfo(newCurrentUserInfo);
        //                     setMessage('Payment has been successful!')
        //                     setAcknowledgeNormal(true);
        //                     setStandard(false);
        //                     setPremium(false);
        //                     setEnterprise(false);
        //                     setPaymentTab(false);
        //                     setChooseTier(true);
        //                     setPromotionTab(false);
        //                 })
        //             }

        //         }
        //         else if (selectElement.id == "premium") {
        //             if (props.currentUserInfo.membership == "Premium") {
        //                 await updateDoc(doc(db, 'userInfo', user.uid), {
        //                     oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //                     subscriptionID: data.subscriptionID,
        //                     subscriptionPeriod: "Monthly",
        //                     gracePeriod: deleteField(),
        //                     newUserSetup: false,
        //                     subscriptionCounter: 30,
        //                     freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //                     downgrade: deleteField()
        //                 }).then(() => {
        //                     var newCurrentUserInfo = props.currentUserInfo;
        //                     newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //                     newCurrentUserInfo.downgrade = null;
        //                     newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //                     newCurrentUserInfo.newUserSetup = false;
        //                     newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //                     props.setCurrentUserInfo(newCurrentUserInfo);
        //                     setMessage('Payment has been successful!')
        //                     setAcknowledgeNormal(true);
        //                     setStandard(false);
        //                     setPremium(false);
        //                     setEnterprise(false);
        //                     setPaymentTab(false);
        //                     setChooseTier(true);
        //                     setPromotionTab(false);
        //                 })
        //             }
        //             else if (props.currentUserInfo.membership == "Super-Premium") {
        //                 await updateDoc(doc(db, 'userInfo', user.uid), {
        //                     oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //                     subscriptionID: data.subscriptionID,
        //                     subscriptionPeriod: "Monthly",
        //                     gracePeriod: deleteField(),
        //                     newUserSetup: false,
        //                     subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
        //                     freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //                     downgrade: "Premium"
        //                 }).then(() => {
        //                     var newCurrentUserInfo = props.currentUserInfo;
        //                     newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //                     newCurrentUserInfo.downgrade = "Premium";
        //                     newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //                     newCurrentUserInfo.newUserSetup = false;
        //                     newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //                     props.setCurrentUserInfo(newCurrentUserInfo);
        //                     setMessage('Payment has been successful!')
        //                     setAcknowledgeNormal(true);
        //                     setStandard(false);
        //                     setPremium(false);
        //                     setEnterprise(false);
        //                     setPaymentTab(false);
        //                     setChooseTier(true);
        //                     setPromotionTab(false);
        //                 })
        //             }
        //             else {
        //                 //standard instant upgrade
        //                 await updateDoc(doc(db, 'userInfo', user.uid), {
        //                     oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //                     subscriptionID: data.subscriptionID,
        //                     subscriptionPeriod: "Monthly",
        //                     subscriptionCounter: 30,
        //                     downgrade: deleteField(),
        //                     startDate: new Date(),
        //                     membership: "Premium",
        //                     freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //                     billingAmount: 380,
        //                     chatLeft: props.currentUserInfo.chatLeft + 16,
        //                     advancedDetails: props.currentUserInfo.advancedDetails + 16,
        //                     newUserSetup: false
        //                 }).then(() => {
        //                     var newCurrentUserInfo = props.currentUserInfo;
        //                     newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //                     newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 16;
        //                     newCurrentUserInfo.billingAmount = 380;
        //                     newCurrentUserInfo.subscriptionCounter = 30;
        //                     newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //                     newCurrentUserInfo.startDate = new Date();
        //                     newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 16;
        //                     newCurrentUserInfo.membership = "Premium";
        //                     newCurrentUserInfo.newUserSetup = false;
        //                     newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //                     props.setCurrentUserInfo(newCurrentUserInfo);
        //                     setMessage('Payment has been successful!')
        //                     setAcknowledgeNormal(true);
        //                     setStandard(false);
        //                     setPremium(false);
        //                     setEnterprise(false);
        //                     setPaymentTab(false);
        //                     setChooseTier(true);
        //                     setPromotionTab(false);
        //                 })
        //             }

        //         }
        //         else {
        //             ///////////////////Super Premium////////////////
        //             if (props.currentUserInfo.membership == "Super-Premium") {
        //                 await updateDoc(doc(db, 'userInfo', user.uid), {
        //                     oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //                     subscriptionID: data.subscriptionID,
        //                     subscriptionPeriod: "Monthly",
        //                     newUserSetup: false,
        //                     subscriptionCounter: 30,
        //                     gracePeriod: deleteField(),
        //                     freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //                     downgrade: deleteField()
        //                 }).then(() => {
        //                     var newCurrentUserInfo = props.currentUserInfo;
        //                     newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //                     newCurrentUserInfo.downgrade = null;
        //                     newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //                     newCurrentUserInfo.newUserSetup = false;
        //                     newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //                     props.setCurrentUserInfo(newCurrentUserInfo);
        //                     setMessage('Payment has been successful!')
        //                     setAcknowledgeNormal(true);
        //                     setStandard(false);
        //                     setPremium(false);
        //                     setEnterprise(false);
        //                     setPaymentTab(false);
        //                     setChooseTier(true);
        //                     setPromotionTab(false);
        //                 })
        //             }
        //             else if (props.currentUserInfo.membership == "Premium") {
        //                 await updateDoc(doc(db, 'userInfo', user.uid), {
        //                     oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //                     subscriptionID: data.subscriptionID,
        //                     subscriptionPeriod: "Monthly",
        //                     membership: "Super-Premium",
        //                     startDate: new Date(),
        //                     gracePeriod: deleteField(),
        //                     downgrade: deleteField(),
        //                     subscriptionCounter: 30,
        //                     freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //                     billingAmount: 880,
        //                     chatLeft: props.currentUserInfo.chatLeft + 40,
        //                     advancedDetails: props.currentUserInfo.advancedDetails + 40,
        //                     newUserSetup: false
        //                 }).then(() => {
        //                     var newCurrentUserInfo = props.currentUserInfo;
        //                     newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //                     newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 40;
        //                     newCurrentUserInfo.billingAmount = 880;
        //                     newCurrentUserInfo.subscriptionCounter = 30;
        //                     newCurrentUserInfo.startDate = new Date();
        //                     newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //                     newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 40;
        //                     newCurrentUserInfo.membership = "Super-Premium";
        //                     newCurrentUserInfo.newUserSetup = false;
        //                     newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //                     props.setCurrentUserInfo(newCurrentUserInfo);
        //                     setMessage('Payment has been successful!')
        //                     setAcknowledgeNormal(true);
        //                     setStandard(false);
        //                     setPremium(false);
        //                     setEnterprise(false);
        //                     setPaymentTab(false);
        //                     setChooseTier(true);
        //                     setPromotionTab(false);
        //                 })
        //             }
        //             else {
        //                 //standard instant upgrade
        //                 await updateDoc(doc(db, 'userInfo', user.uid), {
        //                     oldsubscriptionID: props.currentUserInfo.subscriptionID,
        //                     subscriptionID: data.subscriptionID,
        //                     membership: "Super-Premium",
        //                     subscriptionPeriod: "Monthly",
        //                     gracePeriod: deleteField(),
        //                     downgrade: deleteField(),
        //                     freeAppt: props.currentUserInfo.newUserSetup ? true : false,
        //                     billingAmount: 880,
        //                     subscriptionCounter: 30,
        //                     startDate: new Date(),
        //                     chatLeft: props.currentUserInfo.chatLeft + 40,
        //                     advancedDetails: props.currentUserInfo.advancedDetails + 40,
        //                     newUserSetup: false
        //                 }).then(() => {
        //                     var newCurrentUserInfo = props.currentUserInfo;
        //                     newCurrentUserInfo.subscriptionID = data.subscriptionID;
        //                     newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 40;
        //                     newCurrentUserInfo.billingAmount = 880;
        //                     newCurrentUserInfo.subscriptionCounter = 30;
        //                     newCurrentUserInfo.startDate = 30;
        //                     newCurrentUserInfo.subscriptionPeriod = "Monthly";
        //                     newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 40;
        //                     newCurrentUserInfo.membership = "Super-Premium";
        //                     newCurrentUserInfo.newUserSetup = false;
        //                     newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
        //                     props.setCurrentUserInfo(newCurrentUserInfo);
        //                     setMessage('Payment has been successful!')
        //                     setAcknowledgeNormal(true);
        //                     setStandard(false);
        //                     setPremium(false);
        //                     setEnterprise(false);
        //                     setPaymentTab(false);
        //                     setChooseTier(true);
        //                     setPromotionTab(false);
        //                 })
        //             }

        //         }
        //     })
        // }
    };

    const paypalOnError = (err) => {
        console.log("Error", err)
    }

    const checkProceedPayment = () => {
        // if (props.currentUserInfo.subscriptionID == "PromotionCode") {
        //     setChooseTier(false);
        //     setPaymentTab(true);
        // }
        // else if (props.currentUserInfo.membership == "Standard" && standard == true) {
        //     alert("The plan has already been activated");
        // }
        // else if (props.currentUserInfo.membership == "Premium" && premium == true) {
        //     alert("The plan has already been activated");
        // }
        // else if (props.currentUserInfo.membership == "Super-Premium" && superPremium == true) {
        //     alert("The plan has already been activated");
        // }
        // else if (enterprise) {
        //     setMessage("Please feel free to send us an email regarding this plan, as we will customize it to meet your needs.")
        //     setAcknowledgeNormal(true);
        // }
        // else {
        setChooseTier(false);
        setPaymentTab(true);
        // }

    }

    // const cancelMembership = (task) => {


    //     setActiveSpin(true);
    //     // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/cancelSubscription
    //     // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/cancelSubscription
    //     return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/cancelSubscription", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             'Authorization': 'Bearer ' + user.accessToken
    //         },
    //         body: JSON.stringify({
    //             data: {
    //                 orderID: props.currentUserInfo.subscriptionID,
    //                 // capturedDetails: details,
    //             }
    //         }),
    //     })
    //         .then(async () => {

    //             await updateDoc(doc(db, 'userInfo', user.uid), {
    //                 oldsubscriptionID: props.currentUserInfo.subscriptionID
    //             })
    //                 .then(() => {
    //                     if (task == "backend") {
    //                         setActiveSpin(false);
    //                         setMessage("Your membership will be changed at the end of the current billing cycle, and your subscription will not be automatically renewed thereafter.");
    //                         setAcknowledgeNormal(true);
    //                         return;
    //                     }
    //                     else {
    //                         setActiveSpin(false);
    //                         return;
    //                     }
    //                 })



    //         })



    // }



    if (!props.trigger) {
        return;
    }
    else {

        return (
            <>
                <Spinner trigger={activeSpin} setTrigger={setActiveSpin}></Spinner>
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={message} parentTrigger={closeUserTypeModal}></AcknowledgeNormal>
                {/* <Acknowledge trigger={acknowledge} setTrigger={setAcknowledge} message={message}></Acknowledge> */}
                <div className='modalNewProject' style={{ zIndex: "2" }}>
                    <div className='modal-contentUserType' style={paymentTab ? { minWidth: "30%" } : { minWidth: "50%" }}>

                        <>

                            <div id="primaryRoleTab" style={chooseTier ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject' >
                                    <div className="notification-close" onClick={() => closeUserTypeModal()}>
                                        ×
                                    </div>
                                    <div class="text-center max-w-xl mx-auto">
                                        <h1 class="text-2xl md:text-2xl font-bold mb-5 mt-5">Credits Topup</h1>
                                        <h3 class="text-xl font-medium mb-5">Credits do not expire!</h3>
                                    </div>
                                </div>

                                <div className='modal-bodyUserType' style={{ display: "block" }}>
                                    <div class="w-full mx-auto bg-white px-5 py-10 text-gray-600 mb-5">

                                        <div class="w-full md:flex mb-5">
                                            <div class="w-full md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:my-3 rounded-md shadow-lg shadow-gray-600 md:relative  md:flex md:flex-col">
                                                <div class="w-full flex-grow">
                                                    <h2 class="text-center font-bold uppercase mb-4">Tier 1</h2>
                                                    <h3 class="text-center font-bold text-4xl mb-2">$25</h3>
                                                    {/* <p class="text-center font-bold mb-5">
                                                        <a href="#" class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i class="mdi mdi-arrow-right-thick ml-1"></i></a>
                                                    </p> */}
                                                    <ul class="text-sm px-5 mb-8">
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>25 Credits</li>
                                                    </ul>
                                                </div>
                                                <div class="w-full">
                                                    <button class="font-bold bg-gray-600 hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full" onClick={() => {
                                                        setChooseTier(false);
                                                        setPaymentTab(true);
                                                        setTierPayment('Tier 1');
                                                    }}>Buy Now</button>
                                                </div>
                                            </div>
                                            <div class="w-full md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:my-3 rounded-md shadow-lg shadow-gray-600 md:relative  md:flex md:flex-col">
                                                <div class="w-full flex-grow">
                                                    <h2 class="text-center font-bold uppercase mb-4">Tier 2</h2>
                                                    <h3 class="text-center font-bold text-3xl md:text-4xl mb-2">$100</h3>
                                                    {/* <p class="text-center font-bold mb-5">
                                                        <a href="#" class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i class="mdi mdi-arrow-right-thick ml-1"></i></a>
                                                    </p> */}
                                                    <ul class="text-sm px-5 mb-8">
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>100 Credits</li>
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>+</li>
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>20 Credits<br></br><span class="text-red-500 font-bold">Bonus</span></li>
                                                    </ul>
                                                </div>
                                                <div class="w-full">
                                                    <button class="font-bold bg-gray-600 hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full" onClick={() => {
                                                        setChooseTier(false);
                                                        setPaymentTab(true);
                                                        setTierPayment('Tier 2');
                                                    }}>Buy Now</button>
                                                </div>
                                            </div>
                                            <div class="w-full md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto  md:my-3 rounded-md shadow-lg shadow-gray-600 md:relative  md:flex md:flex-col">
                                                <div class="w-full flex-grow">
                                                    <h2 class="text-center font-bold uppercase mb-4">Tier 3</h2>
                                                    <h3 class="text-center font-bold text-3xl md:text-4xl mb-2">$250</h3>
                                                    {/* <p class="text-center font-bold mb-5">
                                                        <a href="#" class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i class="mdi mdi-arrow-right-thick ml-1"></i></a>
                                                    </p> */}
                                                    <ul class="text-sm px-5 mb-8">
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>250 Credits</li>
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>+</li>
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>70 Credits<br></br><span class="text-red-500 font-bold">Bonus</span></li>
                                                    </ul>
                                                </div>
                                                <div class="w-full">
                                                    <button class="font-bold bg-gray-600 hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full" onClick={() => {
                                                        setChooseTier(false);
                                                        setPaymentTab(true);
                                                        setTierPayment('Tier 3');
                                                    }}>Buy Now</button>
                                                </div>
                                            </div>
                                            <div class="w-full md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:-mx-3 md:mb-0 rounded-md shadow-lg shadow-gray-600 md:relative md:z-50 md:flex md:flex-col">
                                                <div class="w-full flex-grow">
                                                    <h2 class="text-center font-bold uppercase mb-4">Tier 4</h2>
                                                    <h3 class="text-center font-bold text-4xl md:text-5xl mb-2">$500</h3>
                                                    {/* <p class="text-center font-bold mb-5">
                                                        <a href="#" class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i class="mdi mdi-arrow-right-thick ml-1"></i></a>
                                                    </p> */}
                                                    <ul class="text-sm px-5 mb-8">
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>500 Credits</li>
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>+</li>
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>250 Credits<br></br><span class="text-red-500 font-bold">Bonus</span></li>
                                                    </ul>
                                                </div>
                                                <div class="w-full">
                                                    <button class="font-bold bg-gray-600 hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full" onClick={() => {
                                                        setChooseTier(false);
                                                        setPaymentTab(true);
                                                        setTierPayment('Tier 4');
                                                    }}>Buy Now</button>
                                                </div>
                                            </div>
                                            <div class="w-full md:w-1/4 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto  md:my-3 rounded-md shadow-lg shadow-gray-600 md:relative  md:flex md:flex-col">
                                                <div class="w-full flex-grow">
                                                    <h2 class="text-center font-bold uppercase mb-4">Tier 5</h2>
                                                    <h3 class="text-center font-bold text-3xl md:text-4xl mb-2">$1000</h3>

                                                    {/* <p class="text-center font-bold mb-5">
                                                        <a href="#" class="hover:underline hover:text-gray-700 transition-all transform hover:scale-110 inline-block">Read more<i class="mdi mdi-arrow-right-thick ml-1"></i></a>
                                                    </p> */}
                                                    <ul class="text-sm px-5 mb-8">
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>1000 Credits</li>
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>+</li>
                                                        <li class="leading-tight text-center"><i class="mdi mdi-check-bold text-lg"></i>550 Credits<br></br><span class="text-red-500 font-bold">Bonus</span></li>
                                                    </ul>
                                                </div>
                                                <div class="w-full">
                                                    <button class="font-bold bg-gray-600 hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full" onClick={() => {
                                                        setChooseTier(false);
                                                        setPaymentTab(true);
                                                        setTierPayment('Tier 5');
                                                    }}>Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center max-w-xl mx-auto">
                                            <p class="text-xs leading-tight">*Terms apply</p>
                                        </div>
                                    </div>
                                    {/* <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                        <thead>
                                            <tr style={{ backgroundColor: "#316190" }}>
                                                <th></th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Standard</p>
                                                    <p style={{ margin: 0 }}>$180</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Premium</p>
                                                    <p style={{ margin: 0 }}>$380</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Super Premium</p>
                                                    <p style={{ margin: 0 }}>$880</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Enterprise</p>
                                                    <p style={{ margin: 0 }}>Starting from $3000</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>

                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Rental</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Sales</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-xmark"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Connections</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p>7</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><p>16</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p>40</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Advanced Details</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-xmark"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><p>16</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p>40</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Photos per property profile</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 5 photos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Maximum of 12 photos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 20 photos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px 10px 5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Videos per property profile</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>1 video</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Maximum of 3 videos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 5 videos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px 10px 5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Top up</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-xmark"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-xmark"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px" }}></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={standard ? "select" : "notSelect"} id="standard" onClick={() => { setStandard(!standard); setPremium(false); setSuperPremium(false); setEnterprise(false) }}>Select</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={premium ? "select" : "notSelect"} id="premium" onClick={() => { setStandard(false); setPremium(!premium); setSuperPremium(false); setEnterprise(false) }}>Select</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={superPremium ? "select" : "notSelect"} id="superPremium" onClick={() => { setStandard(false); setPremium(false); setSuperPremium(!superPremium); setEnterprise(false) }}>Select</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={enterprise ? "select" : "notSelect"} id="enterprise" onClick={() => { setStandard(false); setPremium(false); setSuperPremium(false); setEnterprise(!enterprise) }}>Select</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style={{ fontSize: "14px", color: "grey", marginLeft: "10px" }}>*$80 for a first-time set-up fee</p>
                                    <p style={{ fontSize: "14px", color: "grey", marginLeft: "10px" }}>*For promo code user, don't have to select a plan</p> */}
                                </div>

                                <div className='modal-footerNewProject'>

                                    {/* <button id="addPropertyBtn" onClick={() => { checkProceedPayment() }}>Proceed</button> */}
                                    <button id="addPropertyBtn" onClick={() => { setChooseTier(false); setPromotionTab(true) }} style={{ marginRight: "2%" }}>Promo Code</button>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>
                            <div id="promoTab" style={promotionTab ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject'>

                                    {/* <h4 className='modal-titleNewProject'></h4> */}
                                    <div class="text-center max-w-xl mx-auto">
                                        <h1 class="text-2xl md:text-2xl font-bold mb-5 mt-5">Promotion Code</h1>
                                    </div>
                                </div>

                                <div className='modal-bodyUserType' id="tierUserType">

                                    <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>

                                        <div>
                                            <center>

                                                <input style={{ padding: "10px 10px", width: "80%", borderRadius: "10px", border: "1px solid #64748b" }} placeholder='Key in your promotion code' value={promoCode} onChange={(e) => setPromoCode(e.target.value)}></input>

                                            </center>
                                        </div>
                                    </div>
                                </div>


                                <div className='modal-footerNewProject'>

                                    <button id="addPropertyBtn" onClick={() => proceedPromotion()}>Proceed</button>
                                    <button id="addPropertyBtn" onClick={() => { setChooseTier(true); setPromotionTab(false) }} style={{ marginRight: "2%" }}>Back</button>
                                    <div style={{ clear: 'both' }}></div>

                                </div>

                            </div>

                            <div id="paymentTab" style={paymentTab ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject'>
                                    <div className="notification-close" onClick={() => closeUserTypeModal()}>
                                        ×
                                    </div>
                                    <h4 className='modal-titleNewProject'>PAYMENT</h4>
                                </div>

                                <div className='modal-bodyUserType' id="tierUserType" style={{ minWidth: "50%" }}>

                                    <div style={{ width: "90%", display: "flex", flexDirection: "column" }}>
                                        <PayPalScriptProvider
                                            options={{
                                                "client-id": "Ab4HDZo9z5gaurKdt1QrICoSFPi516gGpSXCGLaGQq8_w8y3A8tRUTeSyjcC_nPu7R1myC8lDfhsHDVE",
                                                currency: "SGD",
                                                intent: "capture"
                                            }}
                                        >
                                            <PayPalButtons
                                                createOrder={(data, actions) => createOrder(data, actions)}
                                                onApprove={(data, actions) => onApprove(data, actions)}
                                            />
                                            {/* <PaypalPayment userProject={props.userProject} setUserProject={props.setUserProject} boostPeriod={boostPeriod} paymentAmount={paymentAmount} path="boostIndiv" boostListingId={props.id.id} setTrigger={props.setTrigger}></PaypalPayment> */}
                                        </PayPalScriptProvider>
                                    </div>
                                </div>
                                <div className='modal-footerNewProject'>
                                    <button id="addPropertyBtn" onClick={() => { setChooseTier(true); setPaymentTab(false) }} >Back</button>

                                    <div style={{ clear: 'both' }}></div>

                                </div>

                            </div>





                        </>
                    </div>
                </div>
            </>
        )
    }
}




