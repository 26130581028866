import React, { useEffect, useRef, useState } from 'react';
import { UserAuth } from '../../AuthContent';
import { doc, setDoc, getDoc, collection, addDoc, updateDoc, serverTimestamp, deleteField } from "firebase/firestore";
import { db, storage } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import Acknowledge from './Acknowledge';
import '../css/UserTypeModal.css';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import AcknowledgeNormal from '../../AcknowledgeNormal';
import Spinner from './Spinner';

export default function UserTypeModal(props) {
    const { user } = UserAuth();




    const [message, setMessage] = useState(null);

    const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);

    const [activeSpin, setActiveSpin] = useState(false);
    const [chooseAdditionRole, setChooseAdditionRole] = useState(false);
    const [showRole, setShowRole] = useState(true);
    const [userTypeArr, setUserTypeArr] = useState([]);
    const [secondaryRole, setSecondaryRole] = useState(null);
    const [askAgentCEA, setAskAgentCEA] = useState(false);
    const [CEAName, setCEAName] = useState('');
    const [CEAHP, setCEAHP] = useState('');
    const [CEAAgency, setCEAAgency] = useState('');
    const [CEANumber, setCEANumber] = useState('');
    const [showGotSpace, setShowGotSpace] = useState(false);
    const [masterTenantType, setMasterTenantType] = useState(false);
    const [landlordType, setLandlordType] = useState(false);
    const [chooseTier, setChooseTier] = useState(false);
    const [standard, setStandard] = useState(false);
    const [premium, setPremium] = useState(false);
    const [enterprise, setEnterprise] = useState(false);
    const [paymentTab, setPaymentTab] = useState(false);
    const [superPremium, setSuperPremium] = useState(false);
    const [CEAAgencyNumber, setCEAAgencyNumber] = useState('');
    const [promotionTab, setPromotionTab] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    useEffect(() => {
        if (props.currentUserInfo != null) {
            setUserTypeArr(props.currentUserInfo.userType);
        }
    }, [props])

    const handleDragStart = (event, index) => {
        event.dataTransfer.setData("text/plain", index);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event, newIndex) => {
        event.preventDefault();
        const oldIndex = event.dataTransfer.getData("text/plain");
        const updatedArr = [...userTypeArr];
        const [removed] = updatedArr.splice(oldIndex, 1);
        updatedArr.splice(newIndex, 0, removed);
        setUserTypeArr(updatedArr);
    };

    const updateRoleArrangement = async () => {
        await updateDoc(doc(db, 'userInfo', user.uid), {
            userType: userTypeArr
        })
            .then(() => {
                var newCurrentUserInfo = props.currentUserInfo;
                newCurrentUserInfo.userType = userTypeArr;
                props.setCurrentUserInfo(newCurrentUserInfo);
                closeUserTypeModal();
            })


    }

    const addRole = () => {
        setShowRole(false);
        setChooseAdditionRole(true);
    }
    const proceedPromotion = async () => {
        var promotionDoc = await getDoc(doc(db, 'promotionCode', 'promoCode'));
        var promotionCount = await getDoc(doc(db, 'promotionCode', 'trackingPromo'));
        if (promoCode != promotionDoc.data().code && promoCode != promotionDoc.data().agentPromo && promoCode != promotionDoc.data().shaunPromo) {
            alert("Invalid Code");
        }
        else {
            if (props.currentUserInfo.launchedPromotionCode.includes(promoCode) == false) {
                if (promoCode == promotionDoc.data().agentPromo) {
                    var newLaunchedPromotionCode = [...props.currentUserInfo.launchedPromotionCode];
                    newLaunchedPromotionCode.push(promoCode)
                    if (secondaryRole == "Agent") {

                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            subscriptionID: "PromotionCode",
                            membership: "Premium",
                            billingAmount: 380,
                            chatLeft: props.currentUserInfo.chatLeft + 32,
                            advancedDetails: props.currentUserInfo.advancedDetails + 32,
                            startDate: new Date(),
                            subscriptionPeriod: "Monthly",
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter + 60,
                            launchedPromotionCode: newLaunchedPromotionCode,
                            userType: [...userTypeArr, secondaryRole],
                            freeAppt: true
                        }).then(async () => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            var agentProfile = {
                                CEAName: document.getElementById('CEAName').value,
                                CEAHP: document.getElementById('CEAHP').value,
                                CEAAgency: document.getElementById('CEAAgency').value,
                                CEANumber: document.getElementById('CEANumber').value,
                                CEAAgencyNumber: document.getElementById('CEAAgencyNumber').value,
                                verified: false
                            }
                            await updateDoc(doc(db, 'publicUserInfo', user.uid), {
                                agentProfile: agentProfile
                            }).then(() => {
                                var newPublicInfo = props.publicInfo;
                                newPublicInfo.agentProfile = agentProfile;
                                newCurrentUserInfo.subscriptionID = "PromotionCode";
                                newCurrentUserInfo.membership = "Premium";
                                newCurrentUserInfo.billingAmount = 380;
                                newCurrentUserInfo.chatLeft = props.currentUserInfo.chatLeft + 32;
                                newCurrentUserInfo.advancedDetails = props.currentUserInfo.advancedDetails + 32;
                                newCurrentUserInfo.userType = [...userTypeArr, secondaryRole];
                                newCurrentUserInfo.startDate = new Date();
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.subscriptionCounter = newCurrentUserInfo.subscriptionCounter + 60;
                                newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;
                                props.setPublicInfo(newPublicInfo);
                                setMessage("Promotion Code Verified. Once your trial period has ended, select a tier to continue your subscription");
                                setAcknowledgeNormal(true);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setActiveSpin(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setMasterTenantType(false);
                                setPromotionTab(false);


                            })

                        })
                    }
                    else {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            subscriptionID: "PromotionCode",
                            membership: "Premium",
                            billingAmount: 380,
                            chatLeft: props.currentUserInfo.chatLeft + 32,
                            advancedDetails: props.currentUserInfo.advancedDetails + 32,
                            startDate: new Date(),
                            subscriptionPeriod: "Monthly",
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter + 60,
                            userType: [...userTypeArr, secondaryRole],
                            launchedPromotionCode: newLaunchedPromotionCode,
                            freeAppt: true
                        }).then(async () => {
                            var newCurrentUserInfo = props.currentUserInfo;

                            newCurrentUserInfo.subscriptionID = "PromotionCode";
                            newCurrentUserInfo.membership = "Premium";
                            newCurrentUserInfo.billingAmount = 380;
                            newCurrentUserInfo.chatLeft = props.currentUserInfo.chatLeft + 32;
                            newCurrentUserInfo.advancedDetails = props.currentUserInfo.advancedDetails + 32;
                            newCurrentUserInfo.userType = [...userTypeArr, secondaryRole];
                            newCurrentUserInfo.startDate = new Date();
                            newCurrentUserInfo.subscriptionPeriod = "Monthly";
                            newCurrentUserInfo.subscriptionCounter = newCurrentUserInfo.subscriptionCounter + 60;
                            newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;

                            setMessage("Promotion Code Verified. Once your trail period has ended, select a tier to continue your subscription");
                            setAcknowledgeNormal(true);
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setActiveSpin(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(false);
                            setPremium(false);
                            setEnterprise(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setMasterTenantType(false);
                            setPromotionTab(false);



                        })
                    }
                }
                else {
                    var newLaunchedPromotionCode = [...props.currentUserInfo.launchedPromotionCode];
                    newLaunchedPromotionCode.push(promoCode)
                    if (secondaryRole == "Agent") {

                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            subscriptionID: "PromotionCode",
                            membership: "Premium",
                            billingAmount: 380,
                            chatLeft: props.currentUserInfo.chatLeft + 16,
                            advancedDetails: props.currentUserInfo.advancedDetails + 16,
                            startDate: new Date(),
                            subscriptionPeriod: "Monthly",
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter + 30,
                            launchedPromotionCode: newLaunchedPromotionCode,
                            userType: [...userTypeArr, secondaryRole],
                            freeAppt: true
                        }).then(async () => {
                            var newCurrentUserInfo = props.currentUserInfo;
                            var agentProfile = {
                                CEAName: document.getElementById('CEAName').value,
                                CEAHP: document.getElementById('CEAHP').value,
                                CEAAgency: document.getElementById('CEAAgency').value,
                                CEANumber: document.getElementById('CEANumber').value,
                                CEAAgencyNumber: document.getElementById('CEAAgencyNumber').value,
                                verified: false
                            }
                            if(promoCode == promotionDoc.data().shaunPromo){
                                await updateDoc(doc(db, 'promotionCode', 'trackingPromo'), {
                                    shaunPromoCount: promotionCount.data().shaunPromoCount + 1
                                })
                            }
                            await updateDoc(doc(db, 'publicUserInfo', user.uid), {
                                agentProfile: agentProfile
                            }).then(() => {
                                var newPublicInfo = props.publicInfo;
                                newPublicInfo.agentProfile = agentProfile;
                                newCurrentUserInfo.subscriptionID = "PromotionCode";
                                newCurrentUserInfo.membership = "Premium";
                                newCurrentUserInfo.billingAmount = 380;
                                newCurrentUserInfo.chatLeft = props.currentUserInfo.chatLeft + 16;
                                newCurrentUserInfo.advancedDetails = props.currentUserInfo.advancedDetails + 16;
                                newCurrentUserInfo.userType = [...userTypeArr, secondaryRole];
                                newCurrentUserInfo.startDate = new Date();
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.subscriptionCounter = newCurrentUserInfo.subscriptionCounter + 30;
                                newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;
                                props.setPublicInfo(newPublicInfo);
                                setMessage("Promotion Code Verified. Once your trial period has ended, select a tier to continue your subscription");
                                setAcknowledgeNormal(true);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setActiveSpin(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setMasterTenantType(false);
                                setPromotionTab(false);


                            })

                        })
                    }
                    else {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            subscriptionID: "PromotionCode",
                            membership: "Premium",
                            billingAmount: 380,
                            chatLeft: props.currentUserInfo.chatLeft + 16,
                            advancedDetails: props.currentUserInfo.chatLeft + 16,
                            startDate: new Date(),
                            subscriptionPeriod: "Monthly",
                            subscriptionCounter: props.currentUserInfo.subscriptionCounter + 30,
                            userType: [...userTypeArr, secondaryRole],
                            launchedPromotionCode: newLaunchedPromotionCode,
                            freeAppt: true
                        }).then(async () => {
                            var newCurrentUserInfo = props.currentUserInfo;

                            newCurrentUserInfo.subscriptionID = "PromotionCode";
                            newCurrentUserInfo.membership = "Premium";
                            newCurrentUserInfo.billingAmount = 380;
                            newCurrentUserInfo.chatLeft = props.currentUserInfo.chatLeft + 16;
                            newCurrentUserInfo.advancedDetails = props.currentUserInfo.advancedDetails + 16;
                            newCurrentUserInfo.userType = [...userTypeArr, secondaryRole];
                            newCurrentUserInfo.startDate = new Date();
                            newCurrentUserInfo.subscriptionPeriod = "Monthly";
                            newCurrentUserInfo.subscriptionCounter = newCurrentUserInfo.subscriptionCounter + 30;
                            newCurrentUserInfo.launchedPromotionCode = newLaunchedPromotionCode;

                            setMessage("Promotion Code Verified. Once your trail period has ended, select a tier to continue your subscription");
                            setAcknowledgeNormal(true);
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            setCEAName(null);
                            setCEAHP(null);
                            setCEAAgency(null);
                            setCEANumber(null);
                            setChooseAdditionRole(false);
                            setShowRole(true);
                            setUserTypeArr([]);
                            setShowGotSpace(false);
                            setActiveSpin(false);
                            setSecondaryRole(null);
                            setChooseTier(false);
                            setStandard(false);
                            setAskAgentCEA(false);
                            setPremium(false);
                            setEnterprise(false);
                            setPaymentTab(false);
                            setLandlordType(false);
                            setMasterTenantType(false);
                            setPromotionTab(false);



                        })
                    }
                }


            }
            else {
                alert("You have already redeemed the launch bonus")
            }

        }


    }

    const proceedUpdatingRole = async () => {
        var agentProfile = {
            CEAName: CEAName,
            CEAHP: CEAHP,
            CEAAgency: CEAAgency,
            CEANumber: CEANumber,
            verified: false,
            CEAAgencyNumber: CEAAgencyNumber
        }

        if (secondaryRole == "Agent") {
            if (CEAName != '' && CEAHP != '' && CEAAgency != '' && CEANumber != '' && CEAAgencyNumber != '') {
                var newCurrentUserInfo = props.currentUserInfo;
                await updateDoc(doc(db, 'publicUserInfo', user.uid), {
                    agentProfile: agentProfile
                }).then(async () => {
                    var newUserTypeArr = [...userTypeArr];
                    newUserTypeArr.push(secondaryRole);
                    var newCurrentUserInfo = props.currentUserInfo;

                    if (props.currentUserInfo.membership != null && props.currentUserInfo.membership != "Inactive") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            userType: newUserTypeArr
                        }).then(() => {
                            newCurrentUserInfo.userType = newUserTypeArr;
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            closeUserTypeModal();
                        })
                    }
                    else {
                        setAskAgentCEA(false);
                        setChooseTier(true);
                        setChooseAdditionRole(false);
                    }

                })
            }
            else {
                alert("All information are required")
                return;
            }

        }
        else if (secondaryRole == "Master Tenant" || secondaryRole == "Landlord") {
            var newUserTypeArr = [...userTypeArr];
            newUserTypeArr.push(secondaryRole);
            var newCurrentUserInfo = props.currentUserInfo;
            if (props.currentUserInfo.membership != null && props.currentUserInfo.membership != "Inactive") {
                await updateDoc(doc(db, 'userInfo', user.uid), {
                    userType: newUserTypeArr
                }).then(() => {
                    newCurrentUserInfo.userType = newUserTypeArr;
                    props.setCurrentUserInfo(newCurrentUserInfo);
                    closeUserTypeModal();
                })
            }
            else {
                setShowGotSpace(false);
                setChooseTier(true);
                setChooseAdditionRole(false);
            }

        }
        else if (secondaryRole == "Advertiser" || secondaryRole == "Tenant") {
            var newCurrentUserInfo = props.currentUserInfo;
            var newUserTypeArr = [...userTypeArr];
            newUserTypeArr.push(secondaryRole);
            await updateDoc(doc(db, 'userInfo', user.uid), {
                userType: newUserTypeArr
            }).then(() => {
                newCurrentUserInfo.userType = newUserTypeArr;
                props.setCurrentUserInfo(newCurrentUserInfo);
                closeUserTypeModal();
                // props.setTrigger(false);
            })
        }
        else {
            alert("No role seleceted");
        }

    }

    const chooseRole = (side) => {
        if (userTypeArr.includes(side)) {
            alert("Role exist");
        }
        else {
            // setNewAddtionRole(side);
            if (side == "Agent") {
                setSecondaryRole(side);
                setAskAgentCEA(true);
                setChooseAdditionRole(false);
            }
            else if (side == "Looking") {
                if (userTypeArr.includes("Tenant")) {
                    alert("Role exist");
                }
                else {
                    setSecondaryRole("Tenant");
                }


            }
            else if (side == "Advertiser") {
                setSecondaryRole("Advertiser");

            }
            else {
                setChooseAdditionRole(false);
                setShowGotSpace(true);
            }
        }

    }

    useEffect(() => {
        if (secondaryRole == "Tenant" || secondaryRole == "Advertiser") {
            proceedUpdatingRole();
        }

    }, [secondaryRole])

    const deleteRole = async (index) => {
        setActiveSpin(true);
        var newUserTypeArr = [...userTypeArr];
        var newCurrentUserInfo = props.currentUserInfo;
        if (newUserTypeArr[index] == "Tenant" || newUserTypeArr[index] == "Advertiser") {
            newUserTypeArr.splice(index, 1);
            await updateDoc(doc(db, 'userInfo', user.uid), {
                userType: newUserTypeArr
            }).then(() => {
                newCurrentUserInfo.userType = newUserTypeArr;
                props.setCurrentUserInfo(newCurrentUserInfo);
                closeUserTypeModal();
            })
        }
        else if (newUserTypeArr[index] == "Agent" || newUserTypeArr[index] == "Master Tenant" || newUserTypeArr[index] == "Landlord") {
            newUserTypeArr.splice(index, 1);
            if (newUserTypeArr.includes("Agent") || newUserTypeArr.includes("Master Tenant") || newUserTypeArr.includes("Landlord")) {
                if (props.currentUserInfo.membership != null && props.currentUserInfo.membership != "Inactive") {
                    if (newUserTypeArr.includes("Agent") && (newUserTypeArr.includes("Master Tenant") || newUserTypeArr.includes("Landlord"))) {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            userType: newUserTypeArr
                        })
                            .then(() => {
                                var newCurrentUserInfo = props.currentUserInfo;
                                newCurrentUserInfo.userType = newUserTypeArr;
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                closeUserTypeModal();
                            })
                    }
                    else if (newUserTypeArr.includes("Agent") && (newUserTypeArr.includes("Master Tenant") == false && newUserTypeArr.includes("Landlord") == false)) {
                        var newPropertyAddressList = props.currentUserInfo.hasOwnProperty('propertyAddressList') ? props.currentUserInfo.propertyAddressList : [];
                        var newPropertyAddressListResidential = props.currentUserInfo.hasOwnProperty('propertyAddressListResidential') ? props.currentUserInfo.propertyAddressListResidential : [];
                        for (var i = 0; i < newPropertyAddressList.length; i++) {
                            newPropertyAddressList[i].agentRepresented = true;
                            newPropertyAddressList[i].verified = "notApprove";
                        }
                        for (var i = 0; i < newPropertyAddressListResidential.length; i++) {
                            newPropertyAddressListResidential[i].agentRepresented = true;
                            newPropertyAddressList[i].verified = "notApprove";
                        }
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            userType: newUserTypeArr,
                            propertyAddressList: newPropertyAddressList,
                            propertyAddressListResidential: newPropertyAddressListResidential
                        })
                            .then(() => {
                                var newCurrentUserInfo = props.currentUserInfo;
                                newCurrentUserInfo.propertyAddressList = newPropertyAddressList;
                                newCurrentUserInfo.propertyAddressListResidential = newPropertyAddressListResidential;
                                newCurrentUserInfo.userType = newUserTypeArr;
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                closeUserTypeModal();
                            })
                    }
                    else if (newUserTypeArr.includes("Agent") == false && (newUserTypeArr.includes("Master Tenant") || newUserTypeArr.includes("Landlord"))) {
                        var newPropertyAddressList = props.currentUserInfo.hasOwnProperty('propertyAddressList') ? props.currentUserInfo.propertyAddressList : [];
                        var newPropertyAddressListResidential = props.currentUserInfo.hasOwnProperty('propertyAddressListResidential') ? props.currentUserInfo.propertyAddressListResidential : [];
                        for (var i = 0; i < newPropertyAddressList.length; i++) {
                            newPropertyAddressList[i].agentRepresented = false;
                        }
                        for (var i = 0; i < newPropertyAddressListResidential.length; i++) {
                            newPropertyAddressListResidential[i].agentRepresented = false
                        }
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            userType: newUserTypeArr,
                            propertyAddressList: newPropertyAddressList,
                            propertyAddressListResidential: newPropertyAddressListResidential
                        })
                            .then(() => {
                                var newCurrentUserInfo = props.currentUserInfo;
                                newCurrentUserInfo.propertyAddressList = newPropertyAddressList;
                                newCurrentUserInfo.propertyAddressListResidential = newPropertyAddressListResidential;
                                newCurrentUserInfo.userType = newUserTypeArr;
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                closeUserTypeModal();
                            })
                    }
                }
                // await updateDoc(doc(db, 'userInfo', user.uid), {
                //     userType: newUserTypeArr
                // }).then(() => {
                //     newCurrentUserInfo.userType = newUserTypeArr;
                //     props.setCurrentUserInfo(newCurrentUserInfo);
                //     closeUserTypeModal();
                // })
            }
            else {
                if (newCurrentUserInfo.subscriptionID == null) {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        userType: newUserTypeArr
                    }).then(() => {
                        newCurrentUserInfo.userType = newUserTypeArr;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        closeUserTypeModal();
                    })
                }
                else {
                    if (newCurrentUserInfo.subscriptionID == "PromotionCode") {
                        await updateDoc(doc(db, 'userInfo', user.uid), {
                            userType: newUserTypeArr,
                            gracePeriod: 30,
                            downgrade: "Inactive",
                            subscriptionID: null,
                            oldsubscriptionID: "PromotionCode"
                        }).then(() => {
                            newCurrentUserInfo.userType = newUserTypeArr;
                            newCurrentUserInfo.gracePeriod = 30;
                            newCurrentUserInfo.downgrade = "Inactive";
                            newCurrentUserInfo.subscriptionID = null;
                            newCurrentUserInfo.oldsubscriptionID = "PromotionCode"
                            props.setCurrentUserInfo(newCurrentUserInfo);
                            closeUserTypeModal();
                        })
                    }
                    else {
                        setMessage("Before transitioning to a non-subscription role, kindly ensure that you cancel your subscription beforehand.");
                        setAcknowledgeNormal(true);
                        setActiveSpin(false);
                    }

                }

            }
        }
    }

    const closeUserTypeModal = () => {
        setCEAName(null);
        setCEAHP(null);
        setCEAAgency(null);
        setCEANumber(null);
        setChooseAdditionRole(false);
        setShowRole(true);
        setUserTypeArr([]);
        setShowGotSpace(false);
        setActiveSpin(false);
        setSecondaryRole(null);
        setChooseTier(false);
        setStandard(false);
        setAskAgentCEA(false);
        setPremium(false);
        setEnterprise(false);
        setPaymentTab(false);
        setLandlordType(false);
        setMasterTenantType(false);
        setPromotionTab(false);
        props.setTrigger(false);

    }


    if (!props.trigger) {
        return;
    }
    else {



        const createSubscription = async (data, actions) => {
            try {

                const selectElement = document.querySelector('.select');
                const gotSpaceElement = document.querySelector('.userTypeActive');
                let planID = "";
                //P-87V97227543012720MSIEUPQ premium no charges
                //P-5JG55492SV880132GMSIEUEY standard no charges
                if (selectElement.id == "standard" && props.currentUserInfo.newUserSetup == true) {
                    planID = "P-4WE25674YD030400NMSFLZEI";
                } else if (selectElement.id == "premium" && props.currentUserInfo.newUserSetup == true) {
                    planID = "P-6M109645LM7508252MSFLZ2Y";
                }
                else if (selectElement.id == "superPremium" && props.currentUserInfo.newUserSetup == true) {
                    planID = "P-7AD3724745234033TMSNY6EQ";
                }
                else if (selectElement.id == "standard" && props.currentUserInfo.newUserSetup == false) {
                    planID = "P-5JG55492SV880132GMSIEUEY"
                }
                else if (selectElement.id == "premium" && props.currentUserInfo.newUserSetup == false) {
                    planID = "P-87V97227543012720MSIEUPQ"
                }
                else if (selectElement.id == "superPremium" && props.currentUserInfo.newUserSetup == false) {
                    planID = "P-4XH70004EV358760SMSNY6RA"
                }


                if (props.currentUserInfo.subscriptionID === "PromotionCode") {
                    const startDate = new Date(); // Get the current date
                    startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
                    startDate.setMinutes(startDate.getMinutes()+2);
                    const response = await fetch(
                        "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + user.accessToken,
                            },
                            body: JSON.stringify({
                                data: {
                                    plan_id: planID,
                                    intent: "subscription",
                                    start_time: startDate
                                },
                            }),
                        }
                    );

                    if (response.ok) {
                        const data = await response.json();
                        return data.result.orderID;
                    } else {
                        throw new Error("Failed to create order");
                    }
                }
                else if (props.currentUserInfo.subscriptionID == null) {
                    if (props.currentUserInfo.membership != null && props.currentUserInfo.membership != "Inactive") {
                        const startDate = new Date(); // Get the current date
                        startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
                        startDate.setMinutes(startDate.getMinutes()+2);
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription",
                                        start_time: startDate
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }
                    }

                    else {
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription"
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }
                    }
                }
                else {
                    //upgrade
                    if (selectElement.id == "premium" && props.currentUserInfo.membership == "Standard") {
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription"
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }

                    }
                    else if (selectElement.id == "superPremium" && props.currentUserInfo.membership == "premium") {
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription"
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }

                    }
                    else {
                        //you have an subscription id
                        const startDate = new Date(); // Get the current date
                        startDate.setDate(startDate.getDate() + props.currentUserInfo.subscriptionCounter);
                        startDate.setMinutes(startDate.getMinutes()+2);
                        const response = await fetch(
                            "https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + user.accessToken,
                                },
                                body: JSON.stringify({
                                    data: {
                                        plan_id: planID,
                                        intent: "subscription",
                                        start_time: startDate
                                    },
                                }),
                            }
                        );

                        if (response.ok) {
                            const data = await response.json();
                            return data.result.orderID;
                        } else {
                            throw new Error("Failed to create order");
                        }

                    }


                }
            } catch (error) {
                console.error(error);
            }
        };

        const onApprove = async (data, actions) => {
            const selectElement = document.querySelector('.select');
            const gotSpaceElement = document.querySelector('.userTypeActive');
            if (props.currentUserInfo.subscriptionID === "PromotionCode") {

                if (selectElement.id == "standard") {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        subscriptionID: data.subscriptionID,
                        subscriptionPeriod: "Monthly",
                        newUserSetup: false,
                        gracePeriod: deleteField(),
                        downgrade: "Standard",
                        subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                        freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;
                        newCurrentUserInfo.subscriptionID = data.subscriptionID;
                        newCurrentUserInfo.downgrade = "Standard";
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.newUserSetup = false;
                        newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        setMessage('Payment has been successful')
                        setAcknowledgeNormal(true);
                        setCEAName(null);
                        setCEAHP(null);
                        setCEAAgency(null);
                        setCEANumber(null);
                        setChooseAdditionRole(false);
                        setShowRole(true);
                        setUserTypeArr([]);
                        setShowGotSpace(false);
                        setActiveSpin(false);
                        setSecondaryRole(null);
                        setChooseTier(false);
                        setStandard(false);
                        setAskAgentCEA(false);
                        setPremium(false);
                        setEnterprise(false);
                        setPaymentTab(false);
                        setLandlordType(false);
                        setMasterTenantType(false);
                        setPromotionTab(false);

                    })
                }
                else if (selectElement.id == "premium") {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        subscriptionID: data.subscriptionID,
                        subscriptionPeriod: "Monthly",
                        newUserSetup: false,
                        gracePeriod: deleteField(),
                        downgrade: "Premium",
                        subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                        freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;
                        newCurrentUserInfo.subscriptionID = data.subscriptionID;
                        newCurrentUserInfo.downgrade = "Premium";
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.newUserSetup = false;
                        newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        setMessage('Payment has been successful!');
                        setAcknowledgeNormal(true);
                        setCEAName(null);
                        setCEAHP(null);
                        setCEAAgency(null);
                        setCEANumber(null);
                        setChooseAdditionRole(false);
                        setShowRole(true);
                        setUserTypeArr([]);
                        setShowGotSpace(false);
                        setActiveSpin(false);
                        setSecondaryRole(null);
                        setChooseTier(false);
                        setStandard(false);
                        setAskAgentCEA(false);
                        setPremium(false);
                        setEnterprise(false);
                        setPaymentTab(false);
                        setLandlordType(false);
                        setMasterTenantType(false);
                        setPromotionTab(false);
                    })
                }
                else {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        subscriptionID: data.subscriptionID,
                        subscriptionPeriod: "Monthly",
                        newUserSetup: false,
                        gracePeriod: deleteField(),
                        downgrade: "Super-Premium",
                        subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                        freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;
                        newCurrentUserInfo.subscriptionID = data.subscriptionID;
                        newCurrentUserInfo.downgrade = "Super-Premium";
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.newUserSetup = false;
                        newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        setMessage('Payment has been successful!');
                        setAcknowledgeNormal(true);
                        setCEAName(null);
                        setCEAHP(null);
                        setCEAAgency(null);
                        setCEANumber(null);
                        setChooseAdditionRole(false);
                        setShowRole(true);
                        setUserTypeArr([]);
                        setShowGotSpace(false);
                        setActiveSpin(false);
                        setSecondaryRole(null);
                        setChooseTier(false);
                        setStandard(false);
                        setAskAgentCEA(false);
                        setPremium(false);
                        setEnterprise(false);
                        setPaymentTab(false);
                        setLandlordType(false);
                        setMasterTenantType(false);
                        setPromotionTab(false);
                    })
                }


            }
            else if (props.currentUserInfo.subscriptionID == null) {
                if (selectElement.id == "standard" && props.currentUserInfo.membership == "Standard") {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID,
                        subscriptionPeriod: "Monthly",
                        subscriptionCounter: 30,
                        subscriptionID: data.subscriptionID,
                        newUserSetup: false,
                        gracePeriod: deleteField(),
                        freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                        downgrade: deleteField()
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;
                        newCurrentUserInfo.subscriptionID = data.subscriptionID;
                        newCurrentUserInfo.downgrade = null;
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.newUserSetup = false;
                        newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        setMessage('Payment has been successful!');
                        setAcknowledgeNormal(true);
                        setCEAName(null);
                        setCEAHP(null);
                        setCEAAgency(null);
                        setCEANumber(null);
                        setChooseAdditionRole(false);
                        setShowRole(true);
                        setUserTypeArr([]);
                        setShowGotSpace(false);
                        setActiveSpin(false);
                        setSecondaryRole(null);
                        setChooseTier(false);
                        setStandard(false);
                        setAskAgentCEA(false);
                        setPremium(false);
                        setEnterprise(false);
                        setPaymentTab(false);
                        setLandlordType(false);
                        setMasterTenantType(false);
                        setPromotionTab(false);
                    })
                }
                else if (selectElement.id == "standard" && props.currentUserInfo.membership == "Premium") {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID,
                        subscriptionPeriod: "Monthly",
                        subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                        subscriptionID: data.subscriptionID,
                        gracePeriod: deleteField(),
                        newUserSetup: false,
                        freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                        downgrade: "Standard"
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;
                        newCurrentUserInfo.subscriptionID = data.subscriptionID;
                        newCurrentUserInfo.downgrade = "Standard";
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.newUserSetup = false;
                        newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        setMessage('Payment has been successful!');
                        setAcknowledgeNormal(true);
                        setCEAName(null);
                        setCEAHP(null);
                        setCEAAgency(null);
                        setCEANumber(null);
                        setChooseAdditionRole(false);
                        setShowRole(true);
                        setUserTypeArr([]);
                        setShowGotSpace(false);
                        setActiveSpin(false);
                        setSecondaryRole(null);
                        setChooseTier(false);
                        setStandard(false);
                        setAskAgentCEA(false);
                        setPremium(false);
                        setEnterprise(false);
                        setPaymentTab(false);
                        setLandlordType(false);
                        setMasterTenantType(false);
                        setPromotionTab(false);
                    })
                }
                else if (selectElement.id == "standard" && props.currentUserInfo.membership == "Super-Premium") {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID,
                        subscriptionPeriod: "Monthly",
                        subscriptionID: data.subscriptionID,
                        newUserSetup: false,
                        gracePeriod: deleteField(),
                        subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                        freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                        downgrade: "Standard"
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;
                        newCurrentUserInfo.subscriptionID = data.subscriptionID;
                        newCurrentUserInfo.downgrade = "Standard";
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.newUserSetup = false;
                        newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        setMessage('Payment has been successful!');
                        setAcknowledgeNormal(true);
                        setCEAName(null);
                        setCEAHP(null);
                        setCEAAgency(null);
                        setCEANumber(null);
                        setChooseAdditionRole(false);
                        setShowRole(true);
                        setUserTypeArr([]);
                        setShowGotSpace(false);
                        setActiveSpin(false);
                        setSecondaryRole(null);
                        setChooseTier(false);
                        setStandard(false);
                        setAskAgentCEA(false);
                        setPremium(false);
                        setEnterprise(false);
                        setPaymentTab(false);
                        setLandlordType(false);
                        setMasterTenantType(false);
                        setPromotionTab(false);
                    })
                }
                else if (selectElement.id == "premium" && props.currentUserInfo.membership == "Premium") {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID,
                        subscriptionPeriod: "Monthly",
                        subscriptionID: data.subscriptionID,
                        gracePeriod: deleteField(),
                        subscriptionCounter: 30,
                        newUserSetup: false,
                        freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                        downgrade: deleteField()
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;
                        newCurrentUserInfo.subscriptionID = data.subscriptionID;
                        newCurrentUserInfo.downgrade = null;
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.newUserSetup = false;
                        newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        setMessage('Payment has been successful!');
                        setAcknowledgeNormal(true);
                        setCEAName(null);
                        setCEAHP(null);
                        setCEAAgency(null);
                        setCEANumber(null);
                        setChooseAdditionRole(false);
                        setShowRole(true);
                        setUserTypeArr([]);
                        setShowGotSpace(false);
                        setActiveSpin(false);
                        setSecondaryRole(null);
                        setChooseTier(false);
                        setStandard(false);
                        setAskAgentCEA(false);
                        setPremium(false);
                        setEnterprise(false);
                        setPaymentTab(false);
                        setLandlordType(false);
                        setMasterTenantType(false);
                        setPromotionTab(false);
                    })
                }
                else if (selectElement.id == "premium" && props.currentUserInfo.membership == "Standard") {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID,
                        subscriptionPeriod: "Monthly",
                        subscriptionID: data.subscriptionID,
                        downgrade: deleteField(),
                        gracePeriod: deleteField(),
                        membership: "Premium",
                        billingAmount: 380,
                        subscriptionCounter: 30,
                        freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                        chatLeft: props.currentUserInfo.chatLeft + 16,
                        advancedDetails: props.currentUserInfo.advancedDetails + 16,
                        newUserSetup: false
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;
                        newCurrentUserInfo.subscriptionID = data.subscriptionID;
                        newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 16;
                        newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 16;
                        newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                        newCurrentUserInfo.billingAmount = 380;
                        newCurrentUserInfo.membership = "Premium";
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.newUserSetup = false;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        setMessage('Payment has been successful!');
                        setAcknowledgeNormal(true);
                        setCEAName(null);
                        setCEAHP(null);
                        setCEAAgency(null);
                        setCEANumber(null);
                        setChooseAdditionRole(false);
                        setShowRole(true);
                        setUserTypeArr([]);
                        setShowGotSpace(false);
                        setActiveSpin(false);
                        setSecondaryRole(null);
                        setChooseTier(false);
                        setStandard(false);
                        setAskAgentCEA(false);
                        setPremium(false);
                        setEnterprise(false);
                        setPaymentTab(false);
                        setLandlordType(false);
                        setMasterTenantType(false);
                        setPromotionTab(false);
                    })
                }
                else if (selectElement.id == "premium" && props.currentUserInfo.membership == "Super-Premium") {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID,
                        subscriptionPeriod: "Monthly",
                        subscriptionID: data.subscriptionID,
                        gracePeriod: deleteField(),
                        subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                        newUserSetup: false,
                        freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                        downgrade: "Premium",
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;
                        newCurrentUserInfo.subscriptionID = data.subscriptionID;
                        newCurrentUserInfo.downgrade = "Premium";
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.newUserSetup = false;
                        newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        setMessage('Payment has been successful!');
                        setAcknowledgeNormal(true);
                        setCEAName(null);
                        setCEAHP(null);
                        setCEAAgency(null);
                        setCEANumber(null);
                        setChooseAdditionRole(false);
                        setShowRole(true);
                        setUserTypeArr([]);
                        setShowGotSpace(false);
                        setActiveSpin(false);
                        setSecondaryRole(null);
                        setChooseTier(false);
                        setStandard(false);
                        setAskAgentCEA(false);
                        setPremium(false);
                        setEnterprise(false);
                        setPaymentTab(false);
                        setLandlordType(false);
                        setMasterTenantType(false);
                        setPromotionTab(false);
                    })
                }
                else if (selectElement.id == "standard") {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID,
                        subscriptionID: data.subscriptionID,
                        gracePeriod: deleteField(),
                        membership: "Standard",
                        subscriptionPeriod: "Monthly",
                        subscriptionCounter: 30,
                        billingAmount: 180,
                        userType: [...props.currentUserInfo.userType, gotSpaceElement.id],
                        chatLeft: props.currentUserInfo.chatLeft + 7,
                        freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                        newUserSetup: false,
                        downgrade: deleteField()
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;
                        newCurrentUserInfo.subscriptionID = data.subscriptionID;
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 7;
                        newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                        newCurrentUserInfo.billingAmount = 180;
                        newCurrentUserInfo.userType = [...props.currentUserInfo.userType, gotSpaceElement.id];
                        newCurrentUserInfo.membership = "Standard";
                        newCurrentUserInfo.newUserSetup = false;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        setMessage('Payment has been successful!');
                        setAcknowledgeNormal(true);
                        setCEAName(null);
                        setCEAHP(null);
                        setCEAAgency(null);
                        setCEANumber(null);
                        setChooseAdditionRole(false);
                        setShowRole(true);
                        setUserTypeArr([]);
                        setShowGotSpace(false);
                        setActiveSpin(false);
                        setSecondaryRole(null);
                        setChooseTier(false);
                        setStandard(false);
                        setAskAgentCEA(false);
                        setPremium(false);
                        setEnterprise(false);
                        setPaymentTab(false);
                        setLandlordType(false);
                        setMasterTenantType(false);
                        setPromotionTab(false);
                    })
                }
                else if (selectElement.id == "premium") {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID,
                        subscriptionID: data.subscriptionID,
                        membership: "Premium",
                        gracePeriod: deleteField(),
                        subscriptionCounter: 30,
                        billingAmount: 380,
                        subscriptionPeriod: "Monthly",
                        userType: [...props.currentUserInfo.userType, gotSpaceElement.id],
                        chatLeft: props.currentUserInfo.chatLeft + 16,
                        advancedDetails: props.currentUserInfo.advancedDetails + 16,
                        freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                        newUserSetup: false,
                        downgrade: deleteField()
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;
                        newCurrentUserInfo.subscriptionID = data.subscriptionID;
                        newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 16;
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 16;
                        newCurrentUserInfo.userType = [...props.currentUserInfo.userType, gotSpaceElement.id];
                        newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                        newCurrentUserInfo.billingAmount = 380;
                        newCurrentUserInfo.membership = "Premium";
                        newCurrentUserInfo.newUserSetup = false;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        setMessage('Payment has been successful!');
                        setAcknowledgeNormal(true);
                        setMessage('Payment has been successful!')
                        setCEAName(null);
                        setCEAHP(null);
                        setCEAAgency(null);
                        setCEANumber(null);
                        setChooseAdditionRole(false);
                        setShowRole(true);
                        setUserTypeArr([]);
                        setShowGotSpace(false);
                        setActiveSpin(false);
                        setSecondaryRole(null);
                        setChooseTier(false);
                        setStandard(false);
                        setAskAgentCEA(false);
                        setPremium(false);
                        setEnterprise(false);
                        setPaymentTab(false);
                        setLandlordType(false);
                        setMasterTenantType(false);
                        setPromotionTab(false);
                    })
                }
                else {
                    //superpremium
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID,
                        subscriptionID: data.subscriptionID,
                        gracePeriod: deleteField(),
                        membership: "Super-Premium",
                        subscriptionCounter: 30,
                        subscriptionPeriod: "Monthly",
                        chatLeft: props.currentUserInfo.chatLeft + 40,
                        billingAmount: 880,
                        userType: [...props.currentUserInfo.userType, gotSpaceElement.id],
                        freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                        advancedDetails: props.currentUserInfo.advancedDetails + 40,
                        newUserSetup: false,
                        downgrade: deleteField()
                    }).then(() => {
                        var newCurrentUserInfo = props.currentUserInfo;
                        newCurrentUserInfo.subscriptionID = data.subscriptionID;
                        newCurrentUserInfo.subscriptionPeriod = "Monthly";
                        newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 40;
                        newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 40;
                        newCurrentUserInfo.billingAmount = 880;
                        newCurrentUserInfo.userType = [...props.currentUserInfo.userType, gotSpaceElement.id];
                        newCurrentUserInfo.membership = "Super-Premium";
                        newCurrentUserInfo.newUserSetup = false;
                        newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                        props.setCurrentUserInfo(newCurrentUserInfo);
                        setMessage('Payment has been successful!');
                        setAcknowledgeNormal(true);
                        setCEAName(null);
                        setCEAHP(null);
                        setCEAAgency(null);
                        setCEANumber(null);
                        setChooseAdditionRole(false);
                        setShowRole(true);
                        setUserTypeArr([]);
                        setShowGotSpace(false);
                        setActiveSpin(false);
                        setSecondaryRole(null);
                        setChooseTier(false);
                        setStandard(false);
                        setAskAgentCEA(false);
                        setPremium(false);
                        setEnterprise(false);
                        setPaymentTab(false);
                        setLandlordType(false);
                        setMasterTenantType(false);
                        setPromotionTab(false);
                    })
                }
            }
            else {
                await cancelMembership('backend').then(async () => {
                    if (selectElement.id == "standard") {
                        if (props.currentUserInfo.membership == "Standard") {
                            await updateDoc(doc(db, 'userInfo', user.uid), {
                                oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                subscriptionPeriod: "Monthly",
                                subscriptionID: data.subscriptionID,
                                gracePeriod: deleteField(),
                                newUserSetup: false,
                                subscriptionCounter: 30,
                                freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                downgrade: deleteField()
                            }).then(() => {
                                var newCurrentUserInfo = props.currentUserInfo;
                                newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                newCurrentUserInfo.downgrade = null;
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.newUserSetup = false;
                                newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                setMessage('Payment has been successful!');
                                setAcknowledgeNormal(true);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setActiveSpin(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setMasterTenantType(false);
                                setPromotionTab(false);
                            })
                        }
                        else if (props.currentUserInfo.membership == "Premium") {
                            await updateDoc(doc(db, 'userInfo', user.uid), {
                                oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                subscriptionPeriod: "Monthly",
                                subscriptionID: data.subscriptionID,
                                subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                                gracePeriod: deleteField(),
                                newUserSetup: false,
                                freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                downgrade: "Standard"
                            }).then(() => {
                                var newCurrentUserInfo = props.currentUserInfo;
                                newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                newCurrentUserInfo.downgrade = "Standard";
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.newUserSetup = false;
                                newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                setMessage('Payment has been successful!');
                                setAcknowledgeNormal(true);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setActiveSpin(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setMasterTenantType(false);
                                setPromotionTab(false);
                            })
                        }
                        else {
                            //superpremium
                            await updateDoc(doc(db, 'userInfo', user.uid), {
                                oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                subscriptionPeriod: "Monthly",
                                subscriptionID: data.subscriptionID,
                                newUserSetup: false,
                                gracePeriod: deleteField(),
                                subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                                freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                downgrade: "Standard"
                            }).then(() => {
                                var newCurrentUserInfo = props.currentUserInfo;
                                newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                newCurrentUserInfo.downgrade = "Standard";
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.newUserSetup = false;
                                newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                setMessage('Payment has been successful!');
                                setAcknowledgeNormal(true);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setActiveSpin(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setMasterTenantType(false);
                                setPromotionTab(false);
                            })
                        }

                    }
                    else if (selectElement.id == "premium") {
                        if (props.currentUserInfo.membership == "Premium") {
                            await updateDoc(doc(db, 'userInfo', user.uid), {
                                oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                subscriptionPeriod: "Monthly",
                                subscriptionID: data.subscriptionID,
                                newUserSetup: false,
                                subscriptionCounter: 30,
                                gracePeriod: deleteField(),
                                freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                downgrade: deleteField()
                            }).then(() => {
                                var newCurrentUserInfo = props.currentUserInfo;
                                newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                newCurrentUserInfo.downgrade = null;
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.newUserSetup = false;
                                newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                setMessage('Payment has been successful!');
                                setAcknowledgeNormal(true);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setActiveSpin(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setMasterTenantType(false);
                                setPromotionTab(false);
                            })
                        }
                        else if (props.currentUserInfo.membership == "Super-Premium") {
                            await updateDoc(doc(db, 'userInfo', user.uid), {
                                oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                subscriptionPeriod: "Monthly",
                                subscriptionID: data.subscriptionID,
                                newUserSetup: false,
                                gracePeriod: deleteField(),
                                subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                                freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                downgrade: "Premium"
                            }).then(() => {
                                var newCurrentUserInfo = props.currentUserInfo;
                                newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                newCurrentUserInfo.downgrade = "Premium";
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.newUserSetup = false;
                                newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                setMessage('Payment has been successful!');
                                setAcknowledgeNormal(true);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setActiveSpin(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setMasterTenantType(false);
                                setPromotionTab(false);
                            })
                        }
                        else {
                            //standard instant
                            await updateDoc(doc(db, 'userInfo', user.uid), {
                                oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                subscriptionPeriod: "Monthly",
                                subscriptionID: data.subscriptionID,
                                membership: "Premium",
                                subscriptionCounter: 30,
                                freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                billingAmount: 380,
                                gracePeriod: deleteField(),
                                downgrade: deleteField(),
                                chatLeft: props.currentUserInfo.chatLeft + 16,
                                advancedDetails: props.currentUserInfo.advancedDetails + 16,
                                newUserSetup: false
                            }).then(() => {
                                var newCurrentUserInfo = props.currentUserInfo;
                                newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 16;
                                newCurrentUserInfo.billingAmount = 380;
                                newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 16;
                                newCurrentUserInfo.membership = "Premium";
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.newUserSetup = false;
                                newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                setMessage('Payment has been successful!');
                                setAcknowledgeNormal(true);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setActiveSpin(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setMasterTenantType(false);
                                setPromotionTab(false);
                            })
                        }

                    }
                    else {
                        ///////////////////Super Premium////////////////
                        if (props.currentUserInfo.membership == "Super-Premium") {
                            await updateDoc(doc(db, 'userInfo', user.uid), {
                                oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                subscriptionPeriod: "Monthly",
                                subscriptionID: data.subscriptionID,
                                newUserSetup: false,
                                subscriptionCounter: 30,
                                gracePeriod: deleteField(),
                                freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                downgrade: deleteField()
                            }).then(() => {
                                var newCurrentUserInfo = props.currentUserInfo;
                                newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                newCurrentUserInfo.downgrade = null;
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.newUserSetup = false;
                                newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                setMessage('Payment has been successful!');
                                setAcknowledgeNormal(true);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setActiveSpin(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setMasterTenantType(false);
                                setPromotionTab(false);
                            })
                        }
                        else if (props.currentUserInfo.membership == "Premium") {
                            await updateDoc(doc(db, 'userInfo', user.uid), {
                                oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                subscriptionPeriod: "Monthly",
                                subscriptionID: data.subscriptionID,
                                membership: "Super-Premium",
                                freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                billingAmount: 880,
                                gracePeriod: deleteField(),
                                subscriptionCounter: 30,
                                chatLeft: props.currentUserInfo.chatLeft + 40,
                                advancedDetails: props.currentUserInfo.advancedDetails + 40,
                                newUserSetup: false
                            }).then(() => {
                                var newCurrentUserInfo = props.currentUserInfo;
                                newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 40;
                                newCurrentUserInfo.billingAmount = 880;
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 40;
                                newCurrentUserInfo.membership = "Super-Premium";
                                newCurrentUserInfo.newUserSetup = false;
                                newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                setMessage('Payment has been successful!');
                                setAcknowledgeNormal(true);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setActiveSpin(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setMasterTenantType(false);
                                setPromotionTab(false);
                            })
                        }
                        else {
                            //standard instant upgrade
                            await updateDoc(doc(db, 'userInfo', user.uid), {
                                oldsubscriptionID: props.currentUserInfo.subscriptionID,
                                subscriptionPeriod: "Monthly",
                                subscriptionID: data.subscriptionID,
                                membership: "Super-Premium",
                                freeAppt: props.currentUserInfo.newUserSetup ? true : false,
                                billingAmount: 880,
                                subscriptionCounter: props.currentUserInfo.subscriptionCounter <= 0 ? 30 : props.currentUserInfo.subscriptionCounter,
                                gracePeriod: deleteField(),
                                chatLeft: props.currentUserInfo.chatLeft + 40,
                                advancedDetails: props.currentUserInfo.advancedDetails + 40,
                                newUserSetup: false
                            }).then(() => {
                                var newCurrentUserInfo = props.currentUserInfo;
                                newCurrentUserInfo.subscriptionID = data.subscriptionID;
                                newCurrentUserInfo.chatLeft = newCurrentUserInfo.chatLeft + 40;
                                newCurrentUserInfo.billingAmount = 880;
                                newCurrentUserInfo.subscriptionPeriod = "Monthly";
                                newCurrentUserInfo.advancedDetails = newCurrentUserInfo.advancedDetails + 40;
                                newCurrentUserInfo.membership = "Super-Premium";
                                newCurrentUserInfo.newUserSetup = false;
                                newCurrentUserInfo.freeAppt = props.currentUserInfo.newUserSetup ? true : false;
                                props.setCurrentUserInfo(newCurrentUserInfo);
                                setMessage('Payment has been successful!');
                                setAcknowledgeNormal(true);
                                setCEAName(null);
                                setCEAHP(null);
                                setCEAAgency(null);
                                setCEANumber(null);
                                setChooseAdditionRole(false);
                                setShowRole(true);
                                setUserTypeArr([]);
                                setShowGotSpace(false);
                                setActiveSpin(false);
                                setSecondaryRole(null);
                                setChooseTier(false);
                                setStandard(false);
                                setAskAgentCEA(false);
                                setPremium(false);
                                setEnterprise(false);
                                setPaymentTab(false);
                                setLandlordType(false);
                                setMasterTenantType(false);
                                setPromotionTab(false);
                            })
                        }

                    }
                })
            }
        };

        const cancelMembership = (task) => {
            setActiveSpin(true);
            // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/cancelSubscription
            // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/cancelSubscription
            return fetch("https://asia-southeast1-next-loop-57e04.cloudfunctions.net/createPayPalSubscription", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + user.accessToken
                },
                body: JSON.stringify({
                    data: {
                        orderID: props.currentUserInfo.subscriptionID,
                        // capturedDetails: details,
                    }
                }),
            })
                .then(async () => {
                    await updateDoc(doc(db, 'userInfo', user.uid), {
                        oldsubscriptionID: props.currentUserInfo.subscriptionID
                    })
                        .then(() => {
                            if (task == "backend") {
                                setActiveSpin(false);
                                setMessage("Your membership will be changed at the end of the current billing cycle, and your subscription will not be automatically renewed thereafter.");
                                setAcknowledgeNormal(true);
                                return;
                            }
                            else {
                                setActiveSpin(false);
                                return;
                            }
                        })
                })
        }

        const paypalOnError = (err) => {
            console.log("Error", err)
        }

        return (
            <>
                <Spinner trigger={activeSpin} setTrigger={setActiveSpin}></Spinner>
                <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={message}></AcknowledgeNormal>
                {/* <Acknowledge trigger={acknowledge} setTrigger={setAcknowledge} message={message}></Acknowledge> */}
                <div className='modalNewProject' style={{ zIndex: "2" }}>
                    <div className='modal-contentUserType'>

                        <>
                            <div id="primaryRoleTab" style={chooseAdditionRole ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject' >
                                    <div className="notification-close" onClick={() => closeUserTypeModal()}>
                                        ×
                                    </div>
                                    <h4 className='modal-titleNewProject'>CHOOSE A SECONDARY ROLE</h4>
                                </div>

                                <div className='modal-bodyUserType' style={{ display: "block", textAlign: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column", margin: "1%" }}>
                                        <div id="primaryRoleSubTier" style={{ display: "block", width: "100%", cursor: "pointer", paddingTop: "30px", paddingBottom: "30px", borderRadius: "10px", fontWeight: "bold", backgroundColor: "#3161906b" }} onClick={() => { chooseRole("Agent") }}>Property Agent</div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" }}>
                                            <div id="lookingSpace" style={{ paddingTop: "100px", paddingBottom: "100px" }} onClick={() => { chooseRole("Looking") }}>Looking For Space<br></br><span style={{ fontSize: "15px" }}>(Buyers/Tenants)</span></div>
                                            <div id="gotSpace" style={{ paddingTop: "100px", paddingBottom: "100px" }} onClick={() => { chooseRole("Got") }}>Got Space<br></br><span style={{ fontSize: "15px" }}>(Landlords/Master Tenants)</span></div>

                                        </div>
                                        <div id="primaryRoleSubTier" style={{ display: "block", width: "100%", cursor: "pointer", paddingTop: "30px", paddingBottom: "30px", borderRadius: "10px", fontSize: "20x", fontWeight: "bold", backgroundColor: "#3161906b" }} onClick={() => { chooseRole("Advertiser") }}>Advertise With Us</div>
                                    </div>
                                </div>

                                <div className='modal-footerNewProject'>

                                    {/* <button id="addPropertyBtn" onClick={() => { proceedUpdatingRole() }}>Proceed</button> */}
                                    <button id="addPropertyBtn" onClick={() => { setShowRole(true); setChooseAdditionRole(false); }}>Back</button>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>
                            <div id="primaryRoleTab" style={showRole ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject' >
                                    <div className="notification-close" onClick={() => closeUserTypeModal()}>
                                        ×
                                    </div>
                                    <h4 className='modal-titleNewProject'>CURRENT ROLE</h4>
                                </div>

                                <div className='modal-bodyUserType' style={{ display: "block", textAlign: "center" }}>
                                    {userTypeArr.map((indivType, index) => (
                                        <div
                                            style={{ paddingTop: "8px", paddingBottom: "8px", borderBottom: "1px solid #ccc", cursor: "grab" }}
                                            key={indivType}
                                            draggable
                                            onDragStart={(event) => handleDragStart(event, index)}
                                            onDragOver={handleDragOver}
                                            onDrop={(event) => handleDrop(event, index)}
                                        >
                                            <p><span style={{ float: "left", marginLeft: "2%" }}>{indivType} {index == 0 ? "[Primary Role]" : "[Secondary Role]"}</span>{index == 0 ? null : <i style={{ float: "right", marginRight: "5%", cursor: "pointer" }} onClick={() => { deleteRole(index) }} class="fa-solid fa-trash-can"></i>}</p>
                                            <div style={{ clear: 'both' }}></div>
                                        </div>
                                    ))}
                                </div>

                                <div className='modal-footerNewProject'>

                                    <button id="addPropertyBtn" onClick={() => updateRoleArrangement()}>Update</button>
                                    <button id="addPropertyBtn" style={{ marginRight: "3%" }} onClick={() => addRole()}>Add Role</button>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>
                            <div id="primaryRoleTab" style={askAgentCEA ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject' >
                                    <div className="notification-close" onClick={() => closeUserTypeModal()}>
                                        ×
                                    </div>
                                    <h4 className='modal-titleNewProject'>AGENT DETAILS</h4>
                                </div>

                                <div className='modal-bodyUserType' style={{ display: "block", margin: "1%" }}>
                                    <p style={{ fontWeight: "bold" }}>Name [As stated in CEA]</p>
                                    <input id="CEAName" style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "8px 10px", width: "100%" }} placeholder='Full name' value={CEAName} onChange={(e) => { setCEAName(e.target.value) }}></input>
                                    <p style={{ fontWeight: "bold", marginTop: "1%" }}>Mobile Number [As stated in CEA]</p>
                                    <input id="CEAHP" style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "8px 10px", width: "100%" }} placeholder='+65 1234 1234' value={CEAHP} onChange={(e) => { setCEAHP(e.target.value) }}></input>
                                    <p style={{ fontWeight: "bold", marginTop: "1%" }}>Agency</p>
                                    <input id="CEAAgency" style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "8px 10px", width: "100%" }} placeholder='Company name' value={CEAAgency} onChange={(e) => { setCEAAgency(e.target.value) }}></input>
                                    <p style={{ fontWeight: "bold", marginTop: "1%" }}>Agency Number</p>
                                    <input id="CEAAgencyNumber" style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "8px 10px", width: "100%" }} placeholder='Agency Number' value={CEAAgencyNumber} onChange={(e) => { setCEAAgencyNumber(e.target.value) }}></input>
                                    <p style={{ fontWeight: "bold", marginTop: "1%" }}>CEA Number</p>
                                    <input id="CEANumber" style={{ border: "1px solid #ccc", borderRadius: "10px", padding: "8px 10px", width: "100%" }} placeholder='12312391239' value={CEANumber} onChange={(e) => { setCEANumber(e.target.value) }}></input>
                                </div>

                                <div className='modal-footerNewProject'>

                                    <button id="addPropertyBtn" onClick={() => { proceedUpdatingRole() }}>Proceed</button>

                                    <button id="addPropertyBtn" style={{ marginRight: "3%" }} onClick={() => { setChooseAdditionRole(true); setAskAgentCEA(false); setSecondaryRole(null); }}>Back</button>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>
                            <div id="promoTab" style={promotionTab ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject'>

                                    <h4 className='modal-titleNewProject'>PROMOTION CODE</h4>
                                </div>

                                <div className='modal-bodyUserType' id="tierUserType">

                                    <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>

                                        <div>
                                            <center>

                                                <input style={{ padding: "10px 10px", width: "80%", borderRadius: "10px" }} placeholder='Key in your promotion code' value={promoCode} onChange={(e) => setPromoCode(e.target.value)}></input>

                                            </center>
                                        </div>
                                    </div>
                                </div>


                                <div className='modal-footerNewProject'>

                                    <button id="addPropertyBtn" onClick={() => proceedPromotion()}>Proceed</button>
                                    <button id="addPropertyBtn" onClick={() => { setChooseTier(true); setPromotionTab(false) }} style={{ marginRight: "2%" }}>Back</button>
                                    <div style={{ clear: 'both' }}></div>

                                </div>

                            </div>
                            <div id="primaryRoleTab" style={showGotSpace ? { display: "block", textAlign: "center" } : { display: "none" }}>
                                <div className='modal-headerNewProject' >
                                    <div className="notification-close" onClick={() => closeUserTypeModal()}>
                                        ×
                                    </div>
                                    <h4 className='modal-titleNewProject'>CHOOSE A SECONDARY ROLE</h4>
                                </div>

                                <div className='modal-bodyUserType' style={{ display: "flex" }}>
                                    <div className={masterTenantType ? 'userTypeActive' : 'userTypeNotActive'} id="Master Tenant" onClick={() => { setMasterTenantType(!masterTenantType); setLandlordType(false); setSecondaryRole("Master Tenant") }}><i class="fa-solid fa-house-user"></i>Master Tenant</div>
                                    <div className={landlordType ? 'userTypeActive' : 'userTypeNotActive'} id="Landlord" onClick={() => { setMasterTenantType(false); setLandlordType(!landlordType); setSecondaryRole("Landlord") }}><i class="fa-solid fa-key"></i>Landlord</div>

                                </div>

                                <div className='modal-footerNewProject'>

                                    <button id="addPropertyBtn" onClick={() => { proceedUpdatingRole() }}>Proceed</button>
                                    <button id="addPropertyBtn" style={{ marginRight: "3%" }} onClick={() => { setChooseAdditionRole(true); setShowGotSpace(false); setSecondaryRole(null); }}>Back</button>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>
                            <div id="primaryRoleTab" style={chooseTier ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject' >
                                    <div className="notification-close" onClick={() => closeUserTypeModal()}>
                                        ×
                                    </div>
                                    <h4 className='modal-titleNewProject'>CHOOSE A SUBSCRIPTION PLAN</h4>
                                </div>

                                <div className='modal-bodyUserType' style={{ display: "block" }}>
                                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                        <thead>
                                            <tr style={{ backgroundColor: "#316190" }}>
                                                <th></th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Standard</p>
                                                    <p style={{ margin: 0 }}>$180</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Premium</p>
                                                    <p style={{ margin: 0 }}>$380</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Super Premium</p>
                                                    <p style={{ margin: 0 }}>$880</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                                <th style={{ fontWeight: "normal", color: "white", padding: "8px 5px" }}>
                                                    <p style={{ fontWeight: "bold", margin: 0 }}>Enterprise</p>
                                                    <p style={{ margin: 0 }}>Starting from $3000</p>
                                                    <p style={{ margin: 0 }}>Monthly</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>

                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Rental</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Sales</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-xmark"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-check"></i></td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Connections</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p>7</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><p>16</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p>40</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Advanced Details</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-xmark"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><p>16</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p>40</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Photos per property profile</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 5 photos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Maximum of 12 photos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 20 photos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Videos per property profile</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>1 video</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Maximum of 3 videos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}>Maximum of 5 videos</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr style={{ borderTop: "1px solid #ccc" }}>
                                                <td style={{ padding: "10px 5px", backgroundColor: "#d9f0ff", fontWeight: "bold" }}>Top up</td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-xmark"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}><i class="fa-solid fa-xmark"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><i class="fa-solid fa-check"></i></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center", backgroundColor: "#d9f0ff" }}>Customize</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "10px 5px", paddingLeft: "5px" }}></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={standard ? "select" : "notSelect"} id="standard" onClick={() => { setStandard(!standard); setPremium(false); setSuperPremium(false); setEnterprise(false) }}>Select</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={premium ? "select" : "notSelect"} id="premium" onClick={() => { setStandard(false); setPremium(!premium); setSuperPremium(false); setEnterprise(false) }}>Select</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={superPremium ? "select" : "notSelect"} id="superPremium" onClick={() => { setStandard(false); setPremium(false); setSuperPremium(!superPremium); setEnterprise(false) }}>Select</p></td>
                                                <td style={{ padding: "10px 5px", textAlign: "center" }}><p className={enterprise ? "select" : "notSelect"} id="enterprise" onClick={() => { setStandard(false); setPremium(false); setSuperPremium(false); setEnterprise(!enterprise) }}>Select</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style={{ fontSize: "14px", color: "grey", marginLeft: "10px" }}>*$80 for a first-time set-up fee</p>
                                </div>

                                <div className='modal-footerNewProject'>

                                    <button id="addPropertyBtn" onClick={() => { setChooseTier(false); setPaymentTab(true) }}>Proceed</button>
                                    <button id="addPropertyBtn" onClick={() => { setChooseTier(false); setPromotionTab(true) }} style={{ marginRight: "2%" }}>Promo Code</button>
                                    <button id="addPropertyBtn" style={{ marginRight: "2%" }} onClick={() => { setShowGotSpace(true); setChooseTier(false); setSecondaryRole(null); }}>Back</button>
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </div>
                            <div id="paymentTab" style={paymentTab ? { display: "block" } : { display: "none" }}>
                                <div className='modal-headerNewProject'>
                                    <div className="notification-close" onClick={() => closeUserTypeModal()}>
                                        ×
                                    </div>
                                    <h4 className='modal-titleNewProject'>PAYMENT</h4>
                                </div>

                                <div className='modal-bodyUserType' id="tierUserType">

                                    <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                                        <PayPalScriptProvider
                                            options={{
                                                "client-id": "AUj95SHnSm6iN1zjvQnpSFuwehgQqPmaO2ZX9ZGFdLX_uNVWVZMnKwedYOeTmDp8UzxlLtlclw1SCnP-",
                                                currency: "SGD",
                                                intent: "subscription",
                                                vault: true
                                            }}
                                        >
                                            <PayPalButtons
                                                style={{ label: 'subscribe' }}
                                                createSubscription={(data, actions) => createSubscription(data, actions)}
                                                onApprove={(data, actions) => onApprove(data, actions)}
                                                catchError={paypalOnError}
                                                onError={paypalOnError}
                                                onCancel={paypalOnError}
                                            />
                                        </PayPalScriptProvider>
                                    </div>
                                </div>
                                <div className='modal-footerNewProject'>
                                    <button id="addPropertyBtn" onClick={() => { setChooseTier(true); setPaymentTab(false) }} >Back</button>

                                    <div style={{ clear: 'both' }}></div>

                                </div>

                            </div>





                        </>
                    </div>
                </div>
            </>
        )
    }
}




