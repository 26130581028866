// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
import {getStorage} from 'firebase/storage';
import { getFunctions,connectFunctionsEmulator } from 'firebase/functions';
import { getPerformance } from "firebase/performance";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//for deployment
const firebaseConfig = {
  apiKey: "AIzaSyD8xP36cErM-v1D6agJm6F6YZnu4Dl0j2o",
  authDomain: "nextloop-31377.firebaseapp.com",
  projectId: "nextloop-31377",
  storageBucket: "nextloop-31377.appspot.com",
  messagingSenderId: "614447700005",
  appId: "1:614447700005:web:aa37956471fa959ebb47cc",
  measurementId: "G-Z82NVJ7165"
};

//for testing
// const firebaseConfig = {
//   apiKey: process.env.React_App_Firebase_API,
//   authDomain: "next-loop-57e04.firebaseapp.com",
//   projectId: "next-loop-57e04",
//   storageBucket: "next-loop-57e04.appspot.com",
//   messagingSenderId: "273696052151",
//   appId: "1:273696052151:web:068296675420ad82868e32",
//   measurementId: "G-LV7ZZX47NS"
// };





////////////////////////////////////////////
firebase.initializeApp(firebaseConfig);
// Initialize Performance Monitoring and get a reference to the service
//  const perf = getPerformance(app);
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db  = getFirestore(app);
export const storage  = getStorage(app);
export const functions = getFunctions(app);

export default firebase