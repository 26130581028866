import React, { useEffect, useState, useRef } from 'react';
import '../../App.css';
import '../css/Account.css';
import { UserAuth } from '../../AuthContent';
// import { Button } from '../Button';
import NewProject from './NewProject'
import { Navigate, Link } from 'react-router-dom';
import { collection, getDocs, onSnapshot, doc, updateDoc, arrayUnion, serverTimestamp, deleteDoc, getDoc, query, where, deleteField, setDoc } from 'firebase/firestore';
import { db, functions, storage } from '../../firebase/firebase'
import BoostAll from './BoostAll';
import BoostIndiv from './BoostIndiv';
// import { UserChat } from '../ChatContext';
import { useLocation } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';
import Footer from '../Footer';
import AdminAccount from '../pages/AdminAccount';
import UserTypeModal from './UserTypeModal';
import TempOTP from './TempOTP';
import { getFunctions, httpsCallable, connectFunctionsEmulator, } from "firebase/functions";
import ThirdParty from './ThirdParty';
import LoaderPage from './LoaderPage';
import { listAll, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import LOI from './LOI';
import ZoomPicture from './ZoomPictures';
import ChooseProperty from './ChooseProperty';
import ZoomVideo from './ZoomVideo';
import AcknowledgeNormal from '../../AcknowledgeNormal';
import ReportChat from './ReportChat';
import Spinner from './Spinner';

export default function Account(props) {
  const { logOut, user } = UserAuth();
  const [data, setData] = useState({});
  const [formValue, setFormValue] = useState('');
  const [ready, setReady] = useState(false);
  const [activatedReportChat, setActivatedReportChat] = useState(false);
  const [chats, setChat] = useState(null);
  const [chatClick, setChatClick] = useState(false);
  const [messages, setMessages] = useState(null);
  const [acknowledgeNormalMessage, setAcknowledgeNormalMessage] = useState(null);
  const [acknowledgeNormal, setAcknowledgeNormal] = useState(false);
  const [navID, setNavID] = useState('');
  const dummy = useRef();
  const location = useLocation();
  const [inputSearchChat, setInputSearchChat] = useState("");
  let unsbscribe;
  const userChatInfo = useRef(null);
  const [arrIDUnRead, setArrIdUnread] = useState([]);
  const [oppositeChatInfo, setOppositeChatInfo] = useState(null);
  const [currentUserInfo, setCurrentUserInfo] = useState(null);
  const [closeListingActivated, setCloseListingActivated] = useState(false);
  const fileInputRef = useRef(null);
  const LOOInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const [mobileChat, setMoblieChat] = useState(false);
  const [mobileVersion, setMobileVersion] = useState(false);
  const [threeDot, setThreeDot] = useState(false);
  const [activateLOI, setAcitvateLOI] = useState(false);
  const [LOISend, setLOISend] = useState(false);
  const [expandedTopInfo, setExpandedTopInfo] = useState(false);
  const [oppositePublicInfo, setOppositePublicInfo] = useState(null);
  const [publicUserInfo, setPublicUserInfo] = useState(null);

  const [zoomPic, setZoomPic] = useState(false);
  const [zoomPicURL, setZoomPicURL] = useState(null);
  const [chooseProperty, setChooseProperty] = useState(false);
  const [zoomVideo, setZoomVideo] = useState(false);
  const [zoomVideoURL, setZoomVideoURL] = useState(null);
  const [matchingChat, setMatchingChat] = useState(null);
  const [sellerProfileClick, setSellerProfileClick] = useState(false);
  const [shortclock, setShortClock] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  useEffect(() =>{
    console.log(shortclock)
  },[shortclock])

  useEffect(() => {
    // Function to be called every 20 minutes
    const updateState = () => {
      setShortClock(true);
    };

    // Set up the interval
    const intervalId = setInterval(updateState, 20 * 60 * 1000); // 20 minutes in milliseconds

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once on mount


  useEffect(() => {
    if (window.innerWidth < 996) {
      setMobileVersion(true);
    }
    else {
      setMobileVersion(false);
    }

    setNavID('');
  }, [])

  const handleImageUpload = (e) => {
    imageInputRef.current.click();
  }

  const handleFileUpload = (e) => {
    // Handle file upload logic here
    fileInputRef.current.click();
  };

  const handleLOOUpload = (e) => {
    LOOInputRef.current.click();
  }

  const showApprovedTextPending = () => {
    const notApprovedText = document.getElementById(`ApprovedTextPending`);
    if (notApprovedText.style.display == "none") {
      notApprovedText.style.display = "block";
    }
    else {
      notApprovedText.style.display = "none";
    }
  }

  const showNotApprovedTextPending = () => {
    const notApprovedText = document.getElementById(`notApprovedTextPending`);
    if (notApprovedText.style.display == "none") {
      notApprovedText.style.display = "block";
    }
    else {
      notApprovedText.style.display = "none";
    }
  }

  const filterChat = async (e) => {
    setInputSearchChat(e.target.value);
    if (e.target.value != "") {
      const extractedID = [];
      const matchingString = [];
      for (const item of chats) {
        if (item && item.length > 0) {
          extractedID.push(item[0]);
        }
      }


      const allDetailsChatRef = await getDocs(collection(db, 'chats'));
      allDetailsChatRef.forEach((indivDoc) => {
        if (extractedID.includes(indivDoc.id)) {
          var indexID = extractedID.indexOf(indivDoc.id);
          const matchingIndexes = indivDoc.data().messages
            .map((message, index) => {
              if (message.text.hasOwnProperty('latitude')) {
                if (message.text['district'].includes(e.target.value)
                  || message.text['postalCode'].includes(e.target.value)
                  || message.text['propertyAddress'].includes(e.target.value)
                  || message.text['propertyCompanyName'].includes(e.target.value)
                  || message.text['propertyUnitNumber'].includes(e.target.value)) {
                  matchingString.push({ "key": indivDoc.id, "data": message, "chatInfo": chats[indexID] })
                  return index;
                }
                else {
                  return -1;
                }
              }
              else if (message.text && message.text.includes(e.target.value)) {
                matchingString.push({ "key": indivDoc.id, "data": message, "chatInfo": chats[indexID] })
                return index;
              }
              return -1; // Return -1 for no match
            })
            .filter(index => index !== -1);

          // if (matchingIndexes.length > 0) {
          //   console.log("Matching indexes found:", matchingIndexes);

          // } else {
          //   console.log("No matching indexes found.");
          // }
        }
      })

      // console.log(matchingString)
      // console.log(matchingString);

      setMatchingChat(matchingString);
    }
    else {
      setMatchingChat(null);
    }


  }

  const handleLOOFileChange = (e) => {
    var fileURL;
    if (e.target.files[0].type == "application/pdf") {
      listAll(ref(storage, navID))
        .then((res) => {
          uploadBytes(ref(storage, `${navID}/LOO`), e.target.files[0]).then((snapshot) => {
            getDownloadURL(ref(storage, `${navID}/LOO`)).then(async (url) => {
              fileURL = url;
              await updateDoc(doc(db, 'chats', navID), {
                messages: arrayUnion({
                  createdAt: new Date(),
                  text: "SystemNextloopNoti: LOO Submitted",
                  sender: user.uid,
                  senderName: user.displayName,
                  contentType: "document"
                }),

              });
              if (currentUserInfo.membership == "Enterprise") {
                // await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {

                //   [navID]: {
                //     date: serverTimestamp(),
                //     userInfo: {
                //       displayName: data.displayName,
                //       listingID: data.listingID,
                //       uid: user.uid,
                //       photoURL: currentUserInfo.userPhotoURL,
                //       lastMessage: "LOO Submitted",
                //       listingName: data.listingName,
                //       listingPhoto: data.listingPhoto,
                //       listingPriceOverall: data.listingPriceOverall,
                //       listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                //       statusRead: "Unread",
                //       status: "Pending Closing",
                //       chatType: data.chatType,
                //       ownerUID: data.ownerUID,
                //       closeCounter: 30
                //     }
                //   }
                // })
              }

              if (data.chatType == "CommercialRent") {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: "LOO Submitted",
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: "Pending Closing",
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: 30,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: "LOO Submitted",
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: "Pending Closing",
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: 30,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: "LOO Submitted",
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: "Pending Closing",
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: 30,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: "LOO Submitted",
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: "Pending Closing",
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: 30,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
              }
              ///////////////////CommericalSales
              else if (data.chatType == "CommercialSales") {

              }
              //////////////////////ResidentialRent
              else if (data.chatType == "ResidentialRent") {

              }
              //////////////////////ResidentialSales
              else if (data.chatType == "ResidentialSales") {

              }
            })
          });
        })
    }

  }

  const closeListing = async () => {
    await updateDoc(doc(db, 'chats', navID), {
      messages: arrayUnion({
        createdAt: new Date(),
        text: "SystemNextloopNoti: Pending Closing",
        sender: user.uid,
        senderName: user.displayName,
        contentType: "document"
      }),
    });
    if (data.chatType == "CommercialRent") {

    }
    else if (data.chatType == "CommercialSales") {
      if (currentUserInfo.membership == "Enterprise") {

      }
      if (data.hasOwnProperty("agentProfile")) {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Pending Closing",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              minFloorSizePSFBudget: data.minFloorSizePSFBudget,
              statusRead: "Read",
              status: "Pending Closing",
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: 30,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Pending Closing",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              minFloorSizePSFBudget: data.minFloorSizePSFBudget,
              statusRead: "Unread",
              status: "Pending Closing",
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: 30,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
      else {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Pending Closing",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              minFloorSizePSFBudget: data.minFloorSizePSFBudget,
              statusRead: "Read",
              status: "Pending Closing",
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: 30,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Pending Closing",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              minFloorSizePSFBudget: data.minFloorSizePSFBudget,
              statusRead: "Unread",
              status: "Pending Closing",
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: 30,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
    }
    else if (data.chatType == "ResidentialRent") {
      if (currentUserInfo.membership == "Enterprise") {

      }
      if (data.hasOwnProperty("agentProfile")) {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Pending Closing",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              listingPriceOverall: data.listingPriceOverall,
              statusRead: "Read",
              status: "Pending Closing",
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: 30,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Pending Closing",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              listingPriceOverall: data.listingPriceOverall,
              statusRead: "Unread",
              status: "Pending Closing",
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: 30,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
      else {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Pending Closing",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              listingPriceOverall: data.listingPriceOverall,
              statusRead: "Read",
              status: "Pending Closing",
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: 30,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Pending Closing",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              listingPriceOverall: data.listingPriceOverall,
              statusRead: "Unread",
              status: "Pending Closing",
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: 30,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
    }
    else if (data.chatType == "ResidentialSales") {
      if (currentUserInfo.membership == "Enterprise") {

      }
      if (data.hasOwnProperty("agentProfile")) {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Pending Closing",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              budgetOverall: data.budgetOverall,
              statusRead: "Read",
              status: "Pending Closing",
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: 30,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Pending Closing",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              budgetOverall: data.budgetOverall,
              statusRead: "Unread",
              status: "Pending Closing",
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: 30,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
      else {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Pending Closing",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              budgetOverall: data.budgetOverall,
              statusRead: "Read",
              status: "Pending Closing",
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: 30,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Pending Closing",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              budgetOverall: data.budgetOverall,
              statusRead: "Unread",
              status: "Pending Closing",
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: 30,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
    }
  }

  const handleFileInputChange = (e) => {
    // Handle image upload logic here
    var fileURL;
    if (e.target.files.length != 0 && navID != null) {
      if (e.target.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        listAll(ref(storage, navID))
          .then((res) => {
            uploadBytes(ref(storage, `${navID}/document_${res.items.length + 1}`), e.target.files[0], { contentType: "application/msword", contentDisposition: `attachment; filename="${res.items.length + 1}.doc"` }).then((snapshot) => {
              getDownloadURL(ref(storage, `${navID}/document_${res.items.length + 1}`)).then(async (url) => {
                fileURL = url;
                await updateDoc(doc(db, 'chats', navID), {
                  messages: arrayUnion({
                    createdAt: new Date(),
                    text: url,
                    sender: user.uid,
                    senderName: user.displayName,
                    contentType: "document"
                  }),

                });
                if (currentUserInfo.membership == "Enterprise") {

                }
                if (data.chatType == "CommercialRent") {
                  if (data.status == "Pending Closing") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else if (data.status == "Pending LOO") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                }
                ///////////CommercialSales/////////////////////////////////////////////////////////////////////////
                else if (data.chatType == "CommercialSales") {
                  if (data.status == "Pending Closing") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else if (data.status == "Pending LOO") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                }
                ///////////////////////ResidentialRent/////////////
                else if (data.chatType == "ResidentialRent") {
                  if (data.status == "Pending Closing") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPriceOverall: data.listingPriceOverall,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPriceOverall: data.listingPriceOverall,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else if (data.status == "Pending LOO") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                }
                ///////////////ResidentialSales////////////
                else if (data.chatType == "ResidentialSales") {
                  if (data.status == "Pending Closing") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            budgetOverall: data.budgetOverall,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            budgetOverall: data.budgetOverall,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else if (data.status == "Pending LOO") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                  }
                }
                else if (data.chatType == "sellerProfile") {



                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: data.displayName,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        sellingPrice: data.sellingPrice,
                        PSFValue: data.PSFValue,
                        commission: data.commission,
                        address: data.propertyAddress,
                        postalCode: data.postalCode,
                        unitNumber: data.propertyUnitNumber,
                        statusRead: "Read",
                        status: data.status,
                        chatType: data.chatType,
                        myUID: data.myUID,
                        oppUserType: data.oppUserType,
                        oppositeUID: data.oppositeUID,
                        agentProfileDeck: data.agentProfileDeck
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: data.displayName,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        sellingPrice: data.sellingPrice,
                        PSFValue: data.PSFValue,
                        commission: data.commission,
                        address: data.propertyAddress,
                        postalCode: data.postalCode,
                        unitNumber: data.propertyUnitNumber,
                        statusRead: "Unread",
                        status: data.status,
                        chatType: data.chatType,
                        myUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        oppositeUID: data.myUID,
                        agentProfileDeck: data.agentProfileDeck
                      }
                    }
                  })


                }
              })
            });
          })
      }
      else if (e.target.files[0].type == "application/pdf") {
        listAll(ref(storage, navID))
          .then((res) => {
            uploadBytes(ref(storage, `${navID}/document_${res.items.length + 1}`), e.target.files[0]).then((snapshot) => {
              getDownloadURL(ref(storage, `${navID}/document_${res.items.length + 1}`)).then(async (url) => {
                fileURL = url;
                await updateDoc(doc(db, 'chats', navID), {
                  messages: arrayUnion({
                    createdAt: new Date(),
                    text: url,
                    sender: user.uid,
                    senderName: user.displayName,
                    contentType: "document"
                  }),

                });

                if (data.chatType == "CommercialRent") {
                  if (data.status == "Pending Closing") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else if (data.status == "Pending LOO") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                }
                ///////////CommercialSales/////////////////////////////////////////////////////////////////////////
                else if (data.chatType == "CommercialSales") {
                  if (data.status == "Pending Closing") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else if (data.status == "Pending LOO") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                }
                ///////////////////////ResidentialRent/////////////
                else if (data.chatType == "ResidentialRent") {
                  if (data.status == "Pending Closing") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPriceOverall: data.listingPriceOverall,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPriceOverall: data.listingPriceOverall,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else if (data.status == "Pending LOO") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                }
                ///////////////ResidentialSales////////////
                else if (data.chatType == "ResidentialSales") {
                  if (data.status == "Pending Closing") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            budgetOverall: data.budgetOverall,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            budgetOverall: data.budgetOverall,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else if (data.status == "Pending LOO") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                }
                else if (data.chatType == "sellerProfile") {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: data.displayName,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        sellingPrice: data.sellingPrice,
                        PSFValue: data.PSFValue,
                        commission: data.commission,
                        address: data.propertyAddress,
                        postalCode: data.postalCode,
                        unitNumber: data.propertyUnitNumber,
                        statusRead: "Read",
                        status: data.status,
                        chatType: data.chatType,
                        myUID: data.myUID,
                        oppUserType: data.oppUserType,
                        oppositeUID: data.oppositeUID,
                        agentProfileDeck: data.agentProfileDeck
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: data.displayName,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        sellingPrice: data.sellingPrice,
                        PSFValue: data.PSFValue,
                        commission: data.commission,
                        address: data.propertyAddress,
                        postalCode: data.postalCode,
                        unitNumber: data.propertyUnitNumber,
                        statusRead: "Unread",
                        status: data.status,
                        chatType: data.chatType,
                        myUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        oppositeUID: data.myUID,
                        agentProfileDeck: data.agentProfileDeck
                      }
                    }
                  })


                }
              })
            });
          })
      }
      else if (e.target.files[0].type == "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
        listAll(ref(storage, navID))
          .then((res) => {
            uploadBytes(ref(storage, `${navID}/document_${res.items.length + 1}`), e.target.files[0], { contentType: "application/vnd.ms-powerpoint", contentDisposition: `attachment; filename="${res.items.length + 1}.pptx"` }).then((snapshot) => {
              getDownloadURL(ref(storage, `${navID}/document_${res.items.length + 1}`)).then(async (url) => {
                fileURL = url;
                await updateDoc(doc(db, 'chats', navID), {
                  messages: arrayUnion({
                    createdAt: new Date(),
                    text: url,
                    sender: user.uid,
                    senderName: user.displayName,
                    contentType: "document"
                  }),

                });

                if (data.chatType == "CommercialRent") {
                  if (data.status == "Pending Closing") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else if (data.status == "Pending LOO") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPhoto: data.listingPhoto,
                            listingPriceOverall: data.listingPriceOverall,
                            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                }
                ///////////CommercialSales/////////////////////////////////////////////////////////////////////////
                else if (data.chatType == "CommercialSales") {
                  if (data.status == "Pending Closing") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else if (data.status == "Pending LOO") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                }
                ///////////////////////ResidentialRent/////////////
                else if (data.chatType == "ResidentialRent") {
                  if (data.status == "Pending Closing") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPriceOverall: data.listingPriceOverall,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            listingPriceOverall: data.listingPriceOverall,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else if (data.status == "Pending LOO") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            listingPriceOverall: data.listingPriceOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                }
                ///////////////ResidentialSales////////////
                else if (data.chatType == "ResidentialSales") {
                  if (data.status == "Pending Closing") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            budgetOverall: data.budgetOverall,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            budgetOverall: data.budgetOverall,
                            moveInPeriod: data.moveInPeriod,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            closeCounter: data.closeCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else if (data.status == "Pending LOO") {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            looCounter: data.looCounter,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                  else {
                    if (data.hasOwnProperty('agentProfile')) {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            agentProfile: data.agentProfile,
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }
                    else {
                      await updateDoc(doc(db, 'userChat', user.uid), {
                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: oppositePublicInfo.username,
                            listingID: data.listingID,
                            photoURL: data.photoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Read",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.myUID,
                            oppositeUID: data.oppositeUID,
                            oppUserType: data.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })

                      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                        [navID]: {
                          date: serverTimestamp(),
                          userInfo: {
                            displayName: props.publicInfo.username,
                            listingID: data.listingID,
                            photoURL: currentUserInfo.userPhotoURL,
                            lastMessage: url,
                            listingName: data.listingName,
                            moveInPeriod: data.moveInPeriod,
                            budgetOverall: data.budgetOverall,
                            statusRead: "Unread",
                            chatType: data.chatType,
                            status: data.status,
                            myUID: data.oppositeUID,
                            oppositeUID: data.myUID,
                            oppUserType: oppositeChatInfo.oppUserType,
                            propertyList: data.propertyList,
                            recommended: data.recommended,
                            agentRepresented: data.agentRepresented
                          }
                        }
                      })
                    }

                  }
                }
                else if (data.chatType == "sellerProfile") {



                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: data.displayName,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        sellingPrice: data.sellingPrice,
                        PSFValue: data.PSFValue,
                        commission: data.commission,
                        address: data.propertyAddress,
                        postalCode: data.postalCode,
                        unitNumber: data.propertyUnitNumber,
                        statusRead: "Read",
                        status: data.status,
                        chatType: data.chatType,
                        myUID: data.myUID,
                        oppUserType: data.oppUserType,
                        oppositeUID: data.oppositeUID,
                        agentProfileDeck: data.agentProfileDeck
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: data.displayName,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        sellingPrice: data.sellingPrice,
                        PSFValue: data.PSFValue,
                        commission: data.commission,
                        address: data.propertyAddress,
                        postalCode: data.postalCode,
                        unitNumber: data.propertyUnitNumber,
                        statusRead: "Unread",
                        status: data.status,
                        chatType: data.chatType,
                        myUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        oppositeUID: data.myUID,
                        agentProfileDeck: data.agentProfileDeck
                      }
                    }
                  })


                }
              })
            });
          })
      }

    }

  };

  useEffect(() => {
    if (LOISend == true) {
      updateLOI();
      setLOISend(false);
    }
  }, [LOISend])

  const openLOO = () => {
    getDownloadURL(ref(storage, `${navID}/LOO`)).then(async (url) => {
      window.open(url, '_blank');
    }).catch((error) => {
      // Handle the error
      alert("LOO not uploaded yet");
      // You can add additional error handling code here
    });
  }

  const sendPropertyProfile = () => {

  }

  const sampleHDBTA = () => {
    window.open("https://firebasestorage.googleapis.com/v0/b/next-loop-57e04.appspot.com/o/Default%2Ftenancy-agreement-template-for-hdb-flats.pdf?alt=media&token=c5b29bed-471d-4a3b-a712-d179da7c4e38&_gl=1*eqb755*_ga*MTc4Nzc4NTEyOC4xNjgzNzk0MTgy*_ga_CW55HF8NVT*MTY4NTk2MjU1Ni41MC4xLjE2ODU5NjI4MjguMC4wLjA.", '_blank');
  }

  const samplePrivateTA = () => {
    window.open("https://firebasestorage.googleapis.com/v0/b/next-loop-57e04.appspot.com/o/Default%2Ftenancy-agreement-template-for-private-residential-property-2021.pdf?alt=media&token=524300d6-fa5c-4556-b11c-de64e5fcfb09&_gl=1*o2daj1*_ga*MTc4Nzc4NTEyOC4xNjgzNzk0MTgy*_ga_CW55HF8NVT*MTY4NTk2MjU1Ni41MC4xLjE2ODU5NjI5NjQuMC4wLjA.", '_blank');
  }

  const retractLOINoAllowChat = async () => {
    deleteObject(ref(storage, `${navID}/LOI.pdf`)).then(async () => {
      await updateDoc(doc(db, 'chats', navID), {
        messages: arrayUnion({
          createdAt: new Date(),
          text: "SystemNextloopNoti: Retracted LOI",
          sender: user.uid,
          senderName: user.displayName
        }),

      });
      if (data.chatType == "CommercialRent") {
        if (currentUserInfo.membership == "Enterprise") {

        }
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: "Retracted LOI",
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Read",
                chatType: data.chatType,
                status: "Time Expired for LOO (Retracted LOI)",
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: "Retracted LOI",
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Unread",
                chatType: data.chatType,
                status: "Time Expired for LOO (Retracted LOI)",
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: "Retracted LOI",
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Read",
                chatType: data.chatType,
                status: "Time Expired for LOO (Retracted LOI)",
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: "Retracted LOI",
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Unread",
                chatType: data.chatType,
                status: "Time Expired for LOO (Retracted LOI)",
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
      }
      ////////////////////////////////CommercialSales/////////////////////////////////////////
      else if (data.chatType == "CommercialSales") {

      }
      else if (data.chatType == "ResidentialRent") {

      }
      else if (data.chatType == "ResidentialSales") {

      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const retractLOI = async () => {
    deleteObject(ref(storage, `${navID}/LOI.pdf`)).then(async () => {
      await updateDoc(doc(db, 'chats', navID), {
        messages: arrayUnion({
          createdAt: new Date(),
          text: "SystemNextloopNoti: Retracted LOI",
          sender: user.uid,
          senderName: user.displayName
        }),

      });
      if (currentUserInfo.membership == "Enterprise") {

      }
      if (data.hasOwnProperty('agentProfile')) {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Retracted LOI",
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Read",
              chatType: data.chatType,
              status: "Accepted",
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: props.publicInfo.username,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Retracted LOI",
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Unread",
              chatType: data.chatType,
              status: "Accepted",
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
      else {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Retracted LOI",
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Read",
              chatType: data.chatType,
              status: "Accepted",
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: props.publicInfo.username,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Retracted LOI",
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Unread",
              chatType: data.chatType,
              status: "Accepted",
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }

    }).catch((error) => {
      console.log(error);
    })
  }

  const openLOI = () => {
    getDownloadURL(ref(storage, `${navID}/LOI.pdf`)).then(async (url) => {
      window.open(url, '_blank');
    }).catch((error) => {
      // Handle the error
      alert("LOI not uploaded yet");
      // You can add additional error handling code here
    });
  }

  const extendLOOCounter = async () => {
    await updateDoc(doc(db, 'chats', navID), {
      messages: arrayUnion({
        createdAt: new Date(),
        text: "SystemNextloopNoti: Extend Pending LOO",
        sender: user.uid,
        senderName: user.displayName
      }),

    });
    if (currentUserInfo.membership == "Enterprise") {

    }
    if (data.hasOwnProperty("agentProfile")) {
      await updateDoc(doc(db, 'userChat', user.uid), {
        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            agentProfile: data.agentProfile,
            displayName: oppositePublicInfo.username,
            listingID: data.listingID,
            photoURL: data.photoURL,
            lastMessage: "Extend Pending LOO",
            listingName: data.listingName,
            listingPhoto: data.listingPhoto,
            listingPriceOverall: data.listingPriceOverall,
            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
            statusRead: "Read",
            status: "Pending LOO",
            chatType: data.chatType,
            looCounter: data.looCounter + 3,
            myUID: data.myUID,
            oppositeUID: data.oppositeUID,
            oppUserType: data.oppUserType,
            propertyList: data.propertyList,
            recommended: data.recommended,
            agentRepresented: data.agentRepresented

          }
        }
      })
      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            agentProfile: data.agentProfile,
            displayName: data.displayName,
            listingID: data.listingID,
            photoURL: currentUserInfo.userPhotoURL,
            lastMessage: "Extend Pending LOO",
            listingName: data.listingName,
            listingPhoto: data.listingPhoto,
            listingPriceOverall: data.listingPriceOverall,
            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
            statusRead: "Unread",
            status: "Pending LOO",
            chatType: data.chatType,
            looCounter: data.looCounter + 3,
            myUID: data.oppositeUID,
            oppositeUID: data.myUID,
            oppUserType: oppositeChatInfo.oppUserType,
            propertyList: data.propertyList,
            recommended: data.recommended,
            agentRepresented: data.agentRepresented
          }
        }
      })
    }
    else {
      await updateDoc(doc(db, 'userChat', user.uid), {
        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            displayName: oppositePublicInfo.username,
            listingID: data.listingID,
            photoURL: data.photoURL,
            lastMessage: "Extend Pending LOO",
            listingName: data.listingName,
            listingPhoto: data.listingPhoto,
            listingPriceOverall: data.listingPriceOverall,
            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
            statusRead: "Read",
            status: "Pending LOO",
            chatType: data.chatType,
            looCounter: data.looCounter + 3,
            myUID: data.myUID,
            oppositeUID: data.oppositeUID,
            oppUserType: data.oppUserType,
            propertyList: data.propertyList,
            recommended: data.recommended,
            agentRepresented: data.agentRepresented

          }
        }
      })
      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            displayName: data.displayName,
            listingID: data.listingID,
            photoURL: currentUserInfo.userPhotoURL,
            lastMessage: "Extend Pending LOO",
            listingName: data.listingName,
            listingPhoto: data.listingPhoto,
            listingPriceOverall: data.listingPriceOverall,
            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
            statusRead: "Unread",
            status: "Pending LOO",
            chatType: data.chatType,
            looCounter: data.looCounter + 3,
            myUID: data.oppositeUID,
            oppositeUID: data.myUID,
            oppUserType: oppositeChatInfo.oppUserType,
            propertyList: data.propertyList,
            recommended: data.recommended,
            agentRepresented: data.agentRepresented
          }
        }
      })
    }

  }

  const disputeActivated = async () => {
    await updateDoc(doc(db, 'chats', navID), {
      messages: arrayUnion({
        createdAt: new Date(),
        text: "SystemNextloopNoti: Dispute Activated",
        sender: user.uid,
        senderName: user.displayName
      }),

    });
    if (data.chatType == "CommercialRent") {
      if (currentUserInfo.membership == "Enterprise") {

      }
      if (data.hasOwnProperty("agentProfile")) {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Read",
              status: "Pending LOO",
              looCounter: 7,
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Unread",
              status: "Pending LOO",
              looCounter: 7,
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
      else {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Read",
              status: "Pending LOO",
              looCounter: 7,
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Unread",
              status: "Pending LOO",
              looCounter: 7,
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
    }
    ////////////////////////////////////////////////////////////CommercialSales//////////////////////////////////////////////
    else if (data.chatType == "CommercialSales") {
      if (currentUserInfo.membership == "Enterprise") {

      }
      if (data.hasOwnProperty("agentProfile")) {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              minFloorSizePSFBudget: data.minFloorSizePSFBudget,
              statusRead: "Read",
              status: "Accepted",
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              minFloorSizePSFBudget: data.minFloorSizePSFBudget,
              statusRead: "Unread",
              status: "Accepted",
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
      else {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              minFloorSizePSFBudget: data.minFloorSizePSFBudget,
              statusRead: "Read",
              status: "Accepted",
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              minFloorSizePSFBudget: data.minFloorSizePSFBudget,
              statusRead: "Unread",
              status: "Accepted",
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
    }
    ////////////////////////////////////////////////////////////ResidentialRent////////////////////////////////////
    else if (data.chatType == "ResidentialRent") {
      if (currentUserInfo.membership == "Enterprise") {

      }
      if (data.hasOwnProperty("agentProfile")) {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              listingPriceOverall: data.listingPriceOverall,
              statusRead: "Read",
              status: "Accepted",
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              listingPriceOverall: data.listingPriceOverall,
              statusRead: "Unread",
              status: "Accepted",
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
      else {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              listingPriceOverall: data.listingPriceOverall,
              statusRead: "Read",
              status: "Accepted",
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              listingPriceOverall: data.listingPriceOverall,
              statusRead: "Unread",
              status: "Accepted",
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
    }
    ///////////////////////////////////////////////////ResidentialSales/////////////////////////////////////
    else if (data.chatType == "ResidentialSales") {
      if (currentUserInfo.membership == "Enterprise") {

      }
      if (data.hasOwnProperty("agentProfile")) {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              budgetOverall: data.budgetOverall,
              statusRead: "Read",
              status: "Accepted",
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              budgetOverall: data.budgetOverall,
              statusRead: "Unread",
              status: "Accepted",
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
      else {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: oppositePublicInfo.username,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              budgetOverall: data.budgetOverall,
              statusRead: "Read",
              status: "Accepted",
              chatType: data.chatType,
              myUID: data.myUID,
              oppositeUID: data.oppositeUID,
              oppUserType: data.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "Dispute Activated",
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              budgetOverall: data.budgetOverall,
              statusRead: "Unread",
              status: "Accepted",
              chatType: data.chatType,
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
    }


  }

  const updateLOI = async () => {
    await updateDoc(doc(db, 'chats', navID), {
      messages: arrayUnion({
        createdAt: new Date(),
        text: `SystemNextloopNoti: LOI Submitted`,
        sender: user.uid,
        senderName: user.displayName
      }),

    });
    if (currentUserInfo.membership == "Enterprise") {
      // await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {

      //   [navID]: {
      //     date: serverTimestamp(),
      //     userInfo: {
      //       displayName: data.displayName,
      //       listingID: data.listingID,
      //       uid: user.uid,
      //       photoURL: currentUserInfo.userPhotoURL,
      //       lastMessage: "LOI Submitted",
      //       listingName: data.listingName,
      //       listingPhoto: data.listingPhoto,
      //       listingPriceOverall: data.listingPriceOverall,
      //       listingPricePerSquareFeet: data.listingPricePerSquareFeet,
      //       statusRead: "Unread",
      //       status: "Pending LOI Approval",
      //       chatType: data.chatType,
      //       ownerUID: data.ownerUID
      //     }
      //   }
      // })
    }
    if (data.hasOwnProperty("agentProfile")) {
      await updateDoc(doc(db, 'userChat', user.uid), {
        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            agentProfile: data.agentProfile,
            displayName: oppositePublicInfo.username,
            listingID: data.listingID,
            photoURL: data.photoURL,
            lastMessage: "LOI Submitted",
            listingName: data.listingName,
            listingPhoto: data.listingPhoto,
            listingPriceOverall: data.listingPriceOverall,
            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
            statusRead: "Read",
            chatType: data.chatType,
            status: "Pending LOI Approval",
            myUID: data.myUID,
            oppositeUID: data.oppositeUID,
            oppUserType: data.oppUserType,
            propertyList: data.propertyList,
            recommended: data.recommended,
            agentRepresented: data.agentRepresented
          }
        }
      }).then(async () => {
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: props.publicInfo.username,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "LOI Submitted",
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Unread",
              chatType: data.chatType,
              status: "Pending LOI Approval",
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
      })
    }
    else {
      await updateDoc(doc(db, 'userChat', user.uid), {
        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            displayName: oppositePublicInfo.username,
            listingID: data.listingID,
            photoURL: data.photoURL,
            lastMessage: "LOI Submitted",
            listingName: data.listingName,
            listingPhoto: data.listingPhoto,
            listingPriceOverall: data.listingPriceOverall,
            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
            statusRead: "Read",
            chatType: data.chatType,
            status: "Pending LOI Approval",
            myUID: data.myUID,
            oppositeUID: data.oppositeUID,
            oppUserType: data.oppUserType,
            propertyList: data.propertyList,
            recommended: data.recommended,
            agentRepresented: data.agentRepresented
          }
        }
      }).then(async () => {
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: props.publicInfo.username,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "LOI Submitted",
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Unread",
              chatType: data.chatType,
              status: "Pending LOI Approval",
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              agentRepresented: data.agentRepresented
            }
          }
        })
      })
    }



  }

  const acceptLOI = async () => {
    await updateDoc(doc(db, 'chats', navID), {
      messages: arrayUnion({
        createdAt: new Date(),
        text: "SystemNextloopNoti: LOI Accepted & Pending LOO within 7 days",
        sender: user.uid,
        senderName: user.displayName
      }),

    });
    if (currentUserInfo.membership == "Enterprise") {
      // await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {

      //   [navID]: {
      //     date: serverTimestamp(),
      //     userInfo: {
      //       displayName: data.displayName,
      //       listingID: data.listingID,
      //       uid: user.uid,
      //       photoURL: currentUserInfo.userPhotoURL,
      //       lastMessage: "LOI Accepted & Pending LOO within 7 days",
      //       listingName: data.listingName,
      //       listingPhoto: data.listingPhoto,
      //       listingPriceOverall: data.listingPriceOverall,
      //       listingPricePerSquareFeet: data.listingPricePerSquareFeet,
      //       statusRead: "Unread",
      //       looCounter: 7,
      //       status: "Pending LOO",
      //       chatType: data.chatType,
      //       ownerUID: data.ownerUID
      //     }
      //   }
      // })
    }
    if (data.hasOwnProperty('agentProfile')) {
      await updateDoc(doc(db, 'userChat', user.uid), {
        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            agentProfile: data.agentProfile,
            displayName: oppositePublicInfo.username,
            listingID: data.listingID,
            photoURL: data.photoURL,
            lastMessage: "LOI Accepted & Pending LOO within 7 days",
            listingName: data.listingName,
            listingPhoto: data.listingPhoto,
            listingPriceOverall: data.listingPriceOverall,
            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
            statusRead: "Read",
            chatType: data.chatType,
            status: "Pending LOO",
            myUID: data.myUID,
            oppositeUID: data.oppositeUID,
            oppUserType: data.oppUserType,
            propertyList: data.propertyList,
            recommended: data.recommended,
            looCounter: 7,
            agentRepresented: data.agentRepresented
          }
        }
      }).then(async () => {
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: props.publicInfo.username,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "LOI Accepted & Pending LOO within 7 days",
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Unread",
              chatType: data.chatType,
              status: "Pending LOO",
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              looCounter: 7,
              agentRepresented: data.agentRepresented
            }
          }
        })

      })
    }
    else {
      await updateDoc(doc(db, 'userChat', user.uid), {
        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            displayName: oppositePublicInfo.username,
            listingID: data.listingID,
            photoURL: data.photoURL,
            lastMessage: "LOI Accepted & Pending LOO within 7 days",
            listingName: data.listingName,
            listingPhoto: data.listingPhoto,
            listingPriceOverall: data.listingPriceOverall,
            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
            statusRead: "Read",
            chatType: data.chatType,
            status: "Pending LOO",
            myUID: data.myUID,
            oppositeUID: data.oppositeUID,
            oppUserType: data.oppUserType,
            propertyList: data.propertyList,
            recommended: data.recommended,
            looCounter: 7,
            agentRepresented: data.agentRepresented
          }
        }
      }).then(async () => {
        await updateDoc(doc(db, 'userChat', data.oppositeUID), {

          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: props.publicInfo.username,
              listingID: data.listingID,
              photoURL: currentUserInfo.userPhotoURL,
              lastMessage: "LOI Accepted & Pending LOO within 7 days",
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Unread",
              chatType: data.chatType,
              status: "Pending LOO",
              myUID: data.oppositeUID,
              oppositeUID: data.myUID,
              oppUserType: oppositeChatInfo.oppUserType,
              propertyList: data.propertyList,
              recommended: data.recommended,
              looCounter: 7,
              agentRepresented: data.agentRepresented
            }
          }
        })

      })

    }
  }

  const declineLOI = async () => {
    await updateDoc(doc(db, 'chats', navID), {
      messages: arrayUnion({
        createdAt: new Date(),
        text: "SystemNextloopNoti: LOI Declined",
        sender: user.uid,
        senderName: user.displayName
      }),

    });
    if (currentUserInfo.membership == "Enterprise") {
      // await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {

      //   [navID]: {
      //     date: serverTimestamp(),
      //     userInfo: {
      //       displayName: data.displayName,
      //       listingID: data.listingID,
      //       uid: user.uid,
      //       photoURL: currentUserInfo.userPhotoURL,
      //       lastMessage: "LOI Declined",
      //       listingName: data.listingName,
      //       listingPhoto: data.listingPhoto,
      //       listingPriceOverall: data.listingPriceOverall,
      //       listingPricePerSquareFeet: data.listingPricePerSquareFeet,
      //       statusRead: "Unread",
      //       status: "Pending LOI",
      //       chatType: data.chatType,
      //       ownerUID: data.ownerUID

      //     }
      //   }
      // })
    }
    if (data.hasOwnProperty('agentProfile')) {
      await updateDoc(doc(db, 'userChat', user.uid), {
        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            agentProfile: data.agentProfile,
            displayName: oppositePublicInfo.username,
            listingID: data.listingID,
            photoURL: data.photoURL,
            lastMessage: "LOI Declined",
            listingName: data.listingName,
            listingPhoto: data.listingPhoto,
            listingPriceOverall: data.listingPriceOverall,
            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
            statusRead: "Read",
            chatType: data.chatType,
            status: "Pending LOI",
            myUID: data.myUID,
            oppositeUID: data.oppositeUID,
            oppUserType: data.oppUserType,
            propertyList: data.propertyList,
            recommended: data.recommended,
            agentRepresented: data.agentRepresented
          }
        }
      })
      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            agentProfile: data.agentProfile,
            displayName: props.publicInfo.username,
            listingID: data.listingID,
            photoURL: currentUserInfo.userPhotoURL,
            lastMessage: "LOI Declined",
            listingName: data.listingName,
            listingPhoto: data.listingPhoto,
            listingPriceOverall: data.listingPriceOverall,
            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
            statusRead: "Unread",
            chatType: data.chatType,
            status: "Pending LOI",
            myUID: data.oppositeUID,
            oppositeUID: data.myUID,
            oppUserType: oppositeChatInfo.oppUserType,
            propertyList: data.propertyList,
            recommended: data.recommended,
            agentRepresented: data.agentRepresented
          }
        }
      })
    }
    else {
      await updateDoc(doc(db, 'userChat', user.uid), {
        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            displayName: oppositePublicInfo.username,
            listingID: data.listingID,
            photoURL: data.photoURL,
            lastMessage: "LOI Declined",
            listingName: data.listingName,
            listingPhoto: data.listingPhoto,
            listingPriceOverall: data.listingPriceOverall,
            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
            statusRead: "Read",
            chatType: data.chatType,
            status: "Pending LOI",
            myUID: data.myUID,
            oppositeUID: data.oppositeUID,
            oppUserType: data.oppUserType,
            propertyList: data.propertyList,
            recommended: data.recommended,
            agentRepresented: data.agentRepresented
          }
        }
      })
      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            displayName: props.publicInfo.username,
            listingID: data.listingID,
            photoURL: currentUserInfo.userPhotoURL,
            lastMessage: "LOI Declined",
            listingName: data.listingName,
            listingPhoto: data.listingPhoto,
            listingPriceOverall: data.listingPriceOverall,
            listingPricePerSquareFeet: data.listingPricePerSquareFeet,
            statusRead: "Unread",
            chatType: data.chatType,
            status: "Pending LOI",
            myUID: data.oppositeUID,
            oppositeUID: data.myUID,
            oppUserType: oppositeChatInfo.oppUserType,
            propertyList: data.propertyList,
            recommended: data.recommended,
            agentRepresented: data.agentRepresented
          }
        }
      })
    }


  }

  const handleImageInputChange = (e) => {
    var fileURL;
    listAll(ref(storage, navID))
      .then((res) => {
        uploadBytes(ref(storage, `${navID}/document_${res.items.length + 1}`), e.target.files[0], { contentType: "image/jpeg", contentDisposition: `attachment; filename="${res.items.length + 1}.jpg"` }).then((snapshot) => {
          getDownloadURL(ref(storage, `${navID}/document_${res.items.length + 1}`)).then(async (url) => {
            fileURL = url;
            await updateDoc(doc(db, 'chats', navID), {
              messages: arrayUnion({
                createdAt: new Date(),
                text: url,
                sender: user.uid,
                senderName: user.displayName,
                contentType: "image"
              }),

            });
            if (data.chatType == "CommercialRent") {

              if (data.status == "Pending Closing") {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }

              }
              else if (data.status == "Pending LOO") {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }

              }
              else {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPhoto: data.listingPhoto,
                        listingPriceOverall: data.listingPriceOverall,
                        listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }

              }
            }
            ////////////////////////////////CommercialSales///////////////////////////////////////////////////
            else if (data.chatType == "CommercialSales") {
              if (currentUserInfo.membership == "Enterprise") {

              }
              if (data.status == "Pending Closing") {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        moveInPeriod: data.moveInPeriod,
                        minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }

              }
              else if (data.status == "Pending LOO") {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }

              }
              else {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        moveInPeriod: data.moveInPeriod,
                        minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        moveInPeriod: data.moveInPeriod,
                        minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        moveInPeriod: data.moveInPeriod,
                        minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }

              }
            }
            ////////////////////////////////ResidentialRent///////////////////////////////////////
            else if (data.chatType == "ResidentialRent") {
              if (currentUserInfo.membership == "Enterprise") {

              }
              if (data.status == "Pending Closing") {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        moveInPeriod: data.moveInPeriod,
                        listingPriceOverall: data.listingPriceOverall,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPriceOverall: data.listingPriceOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPriceOverall: data.listingPriceOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPriceOverall: data.listingPriceOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }

              }
              else if (data.status == "Pending LOO") {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPriceOverall: data.listingPriceOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPriceOverall: data.listingPriceOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPriceOverall: data.listingPriceOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPriceOverall: data.listingPriceOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }

              }
              else {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        listingPriceOverall: data.listingPriceOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        moveInPeriod: data.moveInPeriod,
                        listingPriceOverall: data.listingPriceOverall,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        moveInPeriod: data.moveInPeriod,
                        listingPriceOverall: data.listingPriceOverall,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        moveInPeriod: data.moveInPeriod,
                        listingPriceOverall: data.listingPriceOverall,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }

              }
            }
            else if (data.chatType == "ResidentialSales") {
              if (currentUserInfo.membership == "Enterprise") {

              }
              if (data.status == "Pending Closing") {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        moveInPeriod: data.moveInPeriod,
                        budgetOverall: data.budgetOverall,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        budgetOverall: data.budgetOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        budgetOverall: data.budgetOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        budgetOverall: data.budgetOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        closeCounter: data.closeCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }

              }
              else if (data.status == "Pending LOO") {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        budgetOverall: data.budgetOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        budgetOverall: data.budgetOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        budgetOverall: data.budgetOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        budgetOverall: data.budgetOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        looCounter: data.looCounter,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }

              }
              else {
                if (data.hasOwnProperty('agentProfile')) {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        budgetOverall: data.budgetOverall,
                        moveInPeriod: data.moveInPeriod,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        agentProfile: data.agentProfile,
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        moveInPeriod: data.moveInPeriod,
                        budgetOverall: data.budgetOverall,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }
                else {
                  await updateDoc(doc(db, 'userChat', user.uid), {
                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: oppositePublicInfo.username,
                        listingID: data.listingID,
                        photoURL: data.photoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        moveInPeriod: data.moveInPeriod,
                        budgetOverall: data.budgetOverall,
                        statusRead: "Read",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.myUID,
                        oppositeUID: data.oppositeUID,
                        oppUserType: data.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })

                  await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                    [navID]: {
                      date: serverTimestamp(),
                      userInfo: {
                        displayName: props.publicInfo.username,
                        listingID: data.listingID,
                        photoURL: currentUserInfo.userPhotoURL,
                        lastMessage: url,
                        listingName: data.listingName,
                        moveInPeriod: data.moveInPeriod,
                        budgetOverall: data.budgetOverall,
                        statusRead: "Unread",
                        chatType: data.chatType,
                        status: data.status,
                        myUID: data.oppositeUID,
                        oppositeUID: data.myUID,
                        oppUserType: oppositeChatInfo.oppUserType,
                        propertyList: data.propertyList,
                        recommended: data.recommended,
                        agentRepresented: data.agentRepresented
                      }
                    }
                  })
                }

              }
            }
            else if (data.chatType == "sellerProfile") {




              await updateDoc(doc(db, 'userChat', user.uid), {
                [navID]: {
                  date: serverTimestamp(),
                  userInfo: {
                    displayName: data.displayName,
                    photoURL: data.photoURL,
                    lastMessage: url,
                    sellingPrice: data.sellingPrice,
                    PSFValue: data.PSFValue,
                    commission: data.commission,
                    address: data.propertyAddress,
                    postalCode: data.postalCode,
                    unitNumber: data.propertyUnitNumber,
                    statusRead: "Read",
                    status: data.status,
                    chatType: data.chatType,
                    myUID: data.myUID,
                    oppUserType: data.oppUserType,
                    oppositeUID: data.oppositeUID,
                    agentProfileDeck: data.agentProfileDeck
                  }
                }
              })

              await updateDoc(doc(db, 'userChat', data.oppositeUID), {

                [navID]: {
                  date: serverTimestamp(),
                  userInfo: {
                    displayName: data.displayName,
                    photoURL: data.photoURL,
                    lastMessage: url,
                    sellingPrice: data.sellingPrice,
                    PSFValue: data.PSFValue,
                    commission: data.commission,
                    address: data.propertyAddress,
                    postalCode: data.postalCode,
                    unitNumber: data.propertyUnitNumber,
                    statusRead: "Unread",
                    status: data.status,
                    chatType: data.chatType,
                    myUID: data.oppositeUID,
                    oppUserType: data.oppUserType,
                    oppositeUID: data.myUID,
                    agentProfileDeck: data.agentProfileDeck
                  }
                }
              })




            }

          })
        });
      })
  }

  useEffect(() => {
    var getOppIdData = async () => {
      var data = await getDoc(doc(db, 'userChat', location.state.myID));
      var navID = location.state.navID;
      var data = data.get(navID);

      await getDoc(doc(db, 'publicUserInfo', data.userInfo.oppositeUID)).then((oppPublicInfo) => {
        var lastLoginTimestamp = oppPublicInfo.data().lastLogin;

        var lastLoginDate = new Date(lastLoginTimestamp.seconds * 1000);
        var formattedDate = lastLoginDate.toLocaleString("en-GB", {
          year: 'numeric', month: 'long', day: 'numeric'
        });

        var formattedTime = lastLoginDate.toLocaleString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });

        var formattedDateTime = `${formattedDate} ${formattedTime}`;

        if (data.userInfo.hasOwnProperty('moveInPeriod')) {
          var timeStampMoveInPeriod = data.userInfo.moveInPeriod;
          var moveInPeriodDate = new Date(timeStampMoveInPeriod.seconds * 1000);
          var formattedDateTimeMoveInPeriod = moveInPeriodDate.toLocaleString('en-GB', {
            year: 'numeric', month: 'long', day: 'numeric'
          })
          data.userInfo.moveInPeriodFormatted = formattedDateTimeMoveInPeriod;
        }


        data.userInfo.lastLogin = formattedDateTime;

        setData(data.userInfo);
        // setChatClick(true);
        setNavID(navID);

      })

    }

    if (location.state != null) {
      getOppIdData();
    }

    console.log(location)
  }, [location])

  useEffect(() => {
    const currentChat = async () => {
      for (var i = 0; i < arrIDUnRead.length; i++) {
        if (arrIDUnRead[i] == navID) {
          for (const [key, information] of Object.entries(chats)) {
            if (navID == information[0]) {
              await updateDoc(doc(db, 'userChat', user.uid), {
                [`${information[0]}.userInfo.statusRead`]: "Read"
              })
            }
          }
        }
      }
    }
    if (navID != '' && arrIDUnRead.length != 0) {

      currentChat();
    }
  }, [])

  useEffect(() => {
    if (chatClick == true) {
      dummy.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  async function getFirebaseData(user) {
    const docRef = doc(db, "userChat", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (docSnap.data() != undefined) {
        var chat_data = Object.entries(docSnap.data())?.sort((a, b) => b[1].date - a[1].date);
        var unReadMessage = 0;
        var arrayIDUnRead = [];
        var availableChatMessage = [];

        for (var i = 0; i < chat_data.length; i++) {
          if (chat_data[i][1].userInfo.statusRead === 'Unread' && chat_data[i][1].userInfo.status !== 'Pending' && chat_data[i][1].userInfo.status !== 'Rejected') {
            arrayIDUnRead.push(chat_data[i][0]);
            unReadMessage++;
          }

          if (i === (chat_data.length - 1)) {
            setArrIdUnread(arrayIDUnRead);
          }

          if (chat_data[i][1].userInfo.status !== 'Pending' && chat_data[i][1].userInfo.status !== 'Rejected') {
            // console.log(chat_data[i][1].userInfo);

            const publicInfoIndiv = await getDoc(doc(db, 'publicUserInfo', chat_data[i][1].userInfo.oppositeUID));

            if (publicInfoIndiv.exists()) {
              var lastLoginTimestamp = publicInfoIndiv.data().lastLogin;
              var lastLoginDate = new Date(lastLoginTimestamp.seconds * 1000);

              var formattedDate = lastLoginDate.toLocaleString("en-GB", {
                year: 'numeric', month: 'long', day: 'numeric'
              });

              var formattedTime = lastLoginDate.toLocaleString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              });

              var formattedDateTime = `${formattedDate} ${formattedTime}`;

              chat_data[i][1].userInfo.displayName = publicInfoIndiv.data().username;
              chat_data[i][1].userInfo.lastLogin = formattedDateTime;
              availableChatMessage.push(chat_data[i]);
            } else {
              chat_data[i][1].userInfo.displayName = "Not Available";
              chat_data[i][1].userInfo.lastLogin = "Not Available";
              availableChatMessage.push(chat_data[i]);
            }
          }
        }

        console.log(availableChatMessage);
        setChat(availableChatMessage);
        // setReady(true);
      }
    } else {
      setChat([]);
      console.log("No such document!");
    }
  }

  useEffect(() => {
    if (chats != null) {
      setReady(true);
    }
  }, [chats])

  // useEffect(() => {
  //   if (user.hasOwnProperty("uid") && user != null && navID != "") {
  //     const unsubscribe = onSnapshot(doc(db, 'userChat', user.uid), async (snapshot) => {
  //       if ((snapshot.data()[navID].userInfo != undefined || snapshot.data()[navID].userInfo != null) && snapshot.data() != undefined) {
  //         await getDoc(doc(db, 'publicUserInfo', snapshot.data()[navID].userInfo.oppositeUID)).then((oppPublicInfo) => {
  //           if (oppPublicInfo.exists()) {
  //             var lastLoginTimestamp = oppPublicInfo.data().lastLogin;
  //             var dataUserInfo = snapshot.data()[navID].userInfo;
  //             var lastLoginDate = new Date(lastLoginTimestamp.seconds * 1000);
  //             var formattedDate = lastLoginDate.toLocaleString("en-GB", {
  //               year: 'numeric', month: 'long', day: 'numeric'
  //             });

  //             var formattedTime = lastLoginDate.toLocaleString("en-GB", {
  //               hour: "2-digit",
  //               minute: "2-digit",
  //               hour12: false,
  //             });

  //             var formattedDateTime = `${formattedDate} ${formattedTime}`;

  //             if (dataUserInfo.hasOwnProperty('moveInPeriod')) {
  //               var timeStampMoveInPeriod = dataUserInfo.moveInPeriod;
  //               var moveInPeriodDate = new Date(timeStampMoveInPeriod.seconds * 1000);
  //               var formattedDateTimeMoveInPeriod = moveInPeriodDate.toLocaleString('en-GB', {
  //                 year: 'numeric', month: 'long', day: 'numeric'
  //               })
  //               dataUserInfo.moveInPeriodFormatted = formattedDateTimeMoveInPeriod;
  //             }

  //             dataUserInfo.lastLogin = formattedDateTime;
  //             setData(dataUserInfo);
  //           }
  //           else {

  //             var dataUserInfo = snapshot.data()[navID].userInfo;

  //             if (dataUserInfo.hasOwnProperty('moveInPeriod')) {
  //               var timeStampMoveInPeriod = dataUserInfo.moveInPeriod;
  //               var moveInPeriodDate = new Date(timeStampMoveInPeriod.seconds * 1000);
  //               var formattedDateTimeMoveInPeriod = moveInPeriodDate.toLocaleString('en-GB', {
  //                 year: 'numeric', month: 'long', day: 'numeric'
  //               })
  //               dataUserInfo.moveInPeriodFormatted = formattedDateTimeMoveInPeriod;
  //             }

  //             dataUserInfo.lastLogin = formattedDateTime;
  //             setData(dataUserInfo);
  //           }

  //         })
  //         // setData(snapshot.data()[navID].userInfo)
  //       }

  //     });

  //     // Clean up the listener when the component unmounts
  //     return () => {
  //       unsubscribe();
  //     };
  //   }

  // }, [navID]);

  useEffect(() => {
    console.log(data, navID, user)
    if ((Object.keys(data).length !== 0) && navID !== '' && user.hasOwnProperty("uid") && user !== null) {
      // First useEffect logic
      const unsubChat = onSnapshot(doc(db, `chats`, navID), async (document) => {
        if (document.exists()) {
          console.log('snapshot');
          if (document.data().messages[document.data().messages.length - 1].text == "Dispute Listing Activated") {
            setCloseListingActivated(false);
            setMessages(document.data().messages);
          }
          else if (document.data().messages[document.data().messages.length - 1].text == "Close Listing Activated") {
            setCloseListingActivated(true);
            setMessages(document.data().messages);
          }
          else {
            await getDoc(doc(db, 'closeListing', navID)).then((closingListing) => {
              if (closingListing.exists()) {
                setCloseListingActivated(true);
                setMessages(document.data().messages);
              }
              else {
                setCloseListingActivated(false);
                setMessages(document.data().messages);
              }

            })
          }
        }
      });

      // Second useEffect logic
      const unsubUserChat = onSnapshot(doc(db, 'userChat', user.uid), async (snapshot) => {
        if ((snapshot.data()[navID].userInfo != undefined || snapshot.data()[navID].userInfo != null) && snapshot.data() != undefined) {
          console.log('snapshot', snapshot.data());
          var chat_data = Object.entries(snapshot.data())?.sort((a, b) => b[1].date - a[1].date);
          var unReadMessage = 0;
          var arrayIDUnRead = [];
          var availableChatMessage = [];

          for (var i = 0; i < chat_data.length; i++) {
            if (chat_data[i][1].userInfo.statusRead === 'Unread' && chat_data[i][1].userInfo.status !== 'Pending' && chat_data[i][1].userInfo.status !== 'Rejected') {
              arrayIDUnRead.push(chat_data[i][0]);
              unReadMessage++;
            }

            if (i === (chat_data.length - 1)) {
              setArrIdUnread(arrayIDUnRead);
            }

            if (chat_data[i][1].userInfo.status !== 'Pending' && chat_data[i][1].userInfo.status !== 'Rejected') {
              // console.log(chat_data[i][1].userInfo);

              const publicInfoIndiv = await getDoc(doc(db, 'publicUserInfo', chat_data[i][1].userInfo.oppositeUID));

              if (publicInfoIndiv.exists()) {
                var lastLoginTimestamp = publicInfoIndiv.data().lastLogin;
                var lastLoginDate = new Date(lastLoginTimestamp.seconds * 1000);

                var formattedDate = lastLoginDate.toLocaleString("en-GB", {
                  year: 'numeric', month: 'long', day: 'numeric'
                });

                var formattedTime = lastLoginDate.toLocaleString("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                });

                var formattedDateTime = `${formattedDate} ${formattedTime}`;

                chat_data[i][1].userInfo.displayName = publicInfoIndiv.data().username;
                chat_data[i][1].userInfo.lastLogin = formattedDateTime;
                availableChatMessage.push(chat_data[i]);
              } else {
                chat_data[i][1].userInfo.displayName = "Not Available";
                chat_data[i][1].userInfo.lastLogin = "Not Available";
                availableChatMessage.push(chat_data[i]);
              }
            }
          }

          console.log(availableChatMessage);
          setChat(availableChatMessage);

          await getDoc(doc(db, 'publicUserInfo', snapshot.data()[navID].userInfo.oppositeUID)).then((oppPublicInfo) => {
            if (oppPublicInfo.exists()) {
              var lastLoginTimestamp = oppPublicInfo.data().lastLogin;
              var dataUserInfo = snapshot.data()[navID].userInfo;
              var lastLoginDate = new Date(lastLoginTimestamp.seconds * 1000);
              var formattedDate = lastLoginDate.toLocaleString("en-GB", {
                year: 'numeric', month: 'long', day: 'numeric'
              });

              var formattedTime = lastLoginDate.toLocaleString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              });

              var formattedDateTime = `${formattedDate} ${formattedTime}`;

              if (dataUserInfo.hasOwnProperty('moveInPeriod')) {
                var timeStampMoveInPeriod = dataUserInfo.moveInPeriod;
                var moveInPeriodDate = new Date(timeStampMoveInPeriod.seconds * 1000);
                var formattedDateTimeMoveInPeriod = moveInPeriodDate.toLocaleString('en-GB', {
                  year: 'numeric', month: 'long', day: 'numeric'
                })
                dataUserInfo.moveInPeriodFormatted = formattedDateTimeMoveInPeriod;
              }

              dataUserInfo.lastLogin = formattedDateTime;
              setData(dataUserInfo);
            }
            else {

              var dataUserInfo = snapshot.data()[navID].userInfo;

              if (dataUserInfo.hasOwnProperty('moveInPeriod')) {
                var timeStampMoveInPeriod = dataUserInfo.moveInPeriod;
                var moveInPeriodDate = new Date(timeStampMoveInPeriod.seconds * 1000);
                var formattedDateTimeMoveInPeriod = moveInPeriodDate.toLocaleString('en-GB', {
                  year: 'numeric', month: 'long', day: 'numeric'
                })
                dataUserInfo.moveInPeriodFormatted = formattedDateTimeMoveInPeriod;
              }

              dataUserInfo.lastLogin = formattedDateTime;
              setData(dataUserInfo);
            }

          })
          // setData(snapshot.data()[navID].userInfo)
        }

      });

      // Clean up both listeners when the component unmounts
      return () => {
        unsubChat();
        unsubUserChat();
      };
    }
  }, [navID, user]);


  useEffect(() => {
    if (!user) {

    } else {
      user.uid && getFirebaseData(user);
      user.uid && getCurrentUserInfo();
      return () => getFirebaseData(user);
    }
  }, [user]);

  useEffect(() => {
    // console.log(Object.keys(data));
    if (messages != null && Object.keys(data).length != 0) {
      setReady(true);
      if (window.innerWidth < 996) {
        setMoblieChat(true);
      }
      setChatClick(true);
    }
  }, [messages, data])

  // useEffect(() => {
  //   if ((Object.keys(data).length != 0) && navID != '') {
  //     const unsub = onSnapshot(doc(db, `chats`, navID), async (document) => {
  //       if (document.exists()) {
  //         if (document.data().messages[document.data().messages.length - 1].text == "Dispute Listing Activated") {
  //           // setCloseListingActivated(false);
  //           setMessages(document.data().messages);
  //         }
  //         else if (document.data().messages[document.data().messages.length - 1].text == "Close Listing Activated") {
  //           // setCloseListingActivated(true);
  //           setMessages(document.data().messages);
  //         }
  //         else {
  //           await getDoc(doc(db, 'closeListing', navID)).then((closingListing) => {
  //             if (closingListing.exists()) {
  //               // setCloseListingActivated(true);
  //               setMessages(document.data().messages);
  //             }
  //             else {
  //               // setCloseListingActivated(false);
  //               setMessages(document.data().messages);
  //             }

  //           })
  //         }
  //       }
  //     })
  //     return () => {

  //       unsub();

  //     }
  //   }

  // }, [data, navID]);

  const getCurrentUserInfo = async () => {
    await getDoc(doc(db, 'userInfo', user.uid)).then((docData) => {
      setCurrentUserInfo(docData.data());
    })

    await getDoc(doc(db, 'publicUserInfo', user.uid)).then((docData) => {
      setPublicUserInfo(docData.data());
    })
  }

  const selectChat = async (key, u, date) => {

    console.log('called select chat', key);
    setThreeDot(false);
    setReady(false);
    setShortClock(true);

    var userInfoData = Object.entries(u);
    var oppositePublicInfo;
    if (u.userInfo.chatType == "CommercialRent") {
      if (u.userInfo.status == "Pending Closing") {
        if (u.userInfo.hasOwnProperty("agentProfile")) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: u.userInfo.agentProfile,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                listingPhoto: u.userInfo.listingPhoto,
                listingPriceOverall: u.userInfo.listingPriceOverall,
                listingPricePerSquareFeet: u.userInfo.listingPricePerSquareFeet,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                closeCounter: u.userInfo.closeCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented

              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                listingPhoto: u.userInfo.listingPhoto,
                listingPriceOverall: u.userInfo.listingPriceOverall,
                listingPricePerSquareFeet: u.userInfo.listingPricePerSquareFeet,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                closeCounter: u.userInfo.closeCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
      }
      else if (u.userInfo.status == "Pending LOO") {
        if (u.userInfo.hasOwnProperty("agentProfile")) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: u.userInfo.agentProfile,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                listingPhoto: u.userInfo.listingPhoto,
                listingPriceOverall: u.userInfo.listingPriceOverall,
                listingPricePerSquareFeet: u.userInfo.listingPricePerSquareFeet,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                looCounter: u.userInfo.looCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                listingPhoto: u.userInfo.listingPhoto,
                listingPriceOverall: u.userInfo.listingPriceOverall,
                listingPricePerSquareFeet: u.userInfo.listingPricePerSquareFeet,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                looCounter: u.userInfo.looCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }






      }
      else {

        if (u.userInfo.hasOwnProperty("agentProfile")) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: u.userInfo.agentProfile,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                listingName: u.userInfo.listingName,
                listingPhoto: u.userInfo.listingPhoto,
                listingPriceOverall: u.userInfo.listingPriceOverall,
                listingPricePerSquareFeet: u.userInfo.listingPricePerSquareFeet,
                statusRead: "Read",
                lastMessage: u.userInfo.lastMessage,
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                propertyList: u.userInfo.propertyList,
                oppositeUID: u.userInfo.oppositeUID,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                listingName: u.userInfo.listingName,
                listingPhoto: u.userInfo.listingPhoto,
                listingPriceOverall: u.userInfo.listingPriceOverall,
                listingPricePerSquareFeet: u.userInfo.listingPricePerSquareFeet,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                lastMessage: u.userInfo.lastMessage,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }

      }
    }
    else if (u.userInfo.chatType == "CommercialSales") {
      if (u.userInfo.status == "Pending Closing") {
        if (u.userInfo.hasOwnProperty("agentProfile")) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: u.userInfo.agentProfile,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                minFloorSizePSFBudget: u.userInfo.minFloorSizePSFBudget,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                closeCounter: u.userInfo.closeCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                minFloorSizePSFBudget: u.userInfo.minFloorSizePSFBudget,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                closeCounter: u.userInfo.closeCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
      }
      else if (u.userInfo.status == "Pending LOO") {
        if (u.userInfo.hasOwnProperty("agentProfile")) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: u.userInfo.agentProfile,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                minFloorSizePSFBudget: u.userInfo.minFloorSizePSFBudget,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                looCounter: u.userInfo.looCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                minFloorSizePSFBudget: u.userInfo.minFloorSizePSFBudget,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                looCounter: u.userInfo.looCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
      }
      else {

        if (u.userInfo.hasOwnProperty("agentProfile")) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: u.userInfo.agentProfile,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                listingName: u.userInfo.listingName,
                minFloorSizePSFBudget: u.userInfo.minFloorSizePSFBudget,
                moveInPeriod: u.userInfo.moveInPeriod,
                statusRead: "Read",
                lastMessage: u.userInfo.lastMessage,
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                recommended: u.userInfo.recommended,
                propertyList: u.userInfo.propertyList,
                oppositeUID: u.userInfo.oppositeUID,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                minFloorSizePSFBudget: u.userInfo.minFloorSizePSFBudget,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                lastMessage: u.userInfo.lastMessage,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }

      }
      setSellerProfileClick(false);

    }
    else if (u.userInfo.chatType == "ResidentialRent") {
      if (u.userInfo.status == "Pending Closing") {
        if (u.userInfo.hasOwnProperty("agentProfile")) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: u.userInfo.agentProfile,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                listingPriceOverall: u.userInfo.listingPriceOverall,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                closeCounter: u.userInfo.closeCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                listingPriceOverall: u.userInfo.listingPriceOverall,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                closeCounter: u.userInfo.closeCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
      }
      else if (u.userInfo.status == "Pending LOO") {
        if (u.userInfo.hasOwnProperty("agentProfile")) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: u.userInfo.agentProfile,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                listingPriceOverall: u.userInfo.listingPriceOverall,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                looCounter: u.userInfo.looCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                listingPriceOverall: u.userInfo.listingPriceOverall,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                looCounter: u.userInfo.looCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }






      }
      else {

        if (u.userInfo.hasOwnProperty("agentProfile")) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: u.userInfo.agentProfile,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                listingName: u.userInfo.listingName,
                listingPriceOverall: u.userInfo.listingPriceOverall,
                moveInPeriod: u.userInfo.moveInPeriod,
                statusRead: "Read",
                lastMessage: u.userInfo.lastMessage,
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                recommended: u.userInfo.recommended,
                propertyList: u.userInfo.propertyList,
                oppositeUID: u.userInfo.oppositeUID,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                listingPriceOverall: u.userInfo.listingPriceOverall,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                lastMessage: u.userInfo.lastMessage,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }

      }

    }
    else if (u.userInfo.chatType == "ResidentialSales") {
      if (u.userInfo.status == "Pending Closing") {
        if (u.userInfo.hasOwnProperty("agentProfile")) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: u.userInfo.agentProfile,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                budgetOverall: u.userInfo.budgetOverall,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                closeCounter: u.userInfo.closeCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                budgetOverall: u.userInfo.budgetOverall,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                closeCounter: u.userInfo.closeCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
      }
      else if (u.userInfo.status == "Pending LOO") {
        if (u.userInfo.hasOwnProperty("agentProfile")) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: u.userInfo.agentProfile,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                budgetOverall: u.userInfo.budgetOverall,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                looCounter: u.userInfo.looCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                lastMessage: u.userInfo.lastMessage,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                budgetOverall: u.userInfo.budgetOverall,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                looCounter: u.userInfo.looCounter,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }






      }
      else {

        if (u.userInfo.hasOwnProperty("agentProfile")) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: u.userInfo.agentProfile,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                listingName: u.userInfo.listingName,
                budgetOverall: u.userInfo.budgetOverall,
                moveInPeriod: u.userInfo.moveInPeriod,
                statusRead: "Read",
                lastMessage: u.userInfo.lastMessage,
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                recommended: u.userInfo.recommended,
                propertyList: u.userInfo.propertyList,
                oppositeUID: u.userInfo.oppositeUID,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }
        else {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [key]: {
              date: date,
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: u.userInfo.displayName,
                listingID: u.userInfo.listingID,
                photoURL: u.userInfo.photoURL,
                listingName: u.userInfo.listingName,
                moveInPeriod: u.userInfo.moveInPeriod,
                budgetOverall: u.userInfo.budgetOverall,
                statusRead: "Read",
                status: u.userInfo.status,
                chatType: u.userInfo.chatType,
                lastMessage: u.userInfo.lastMessage,
                myUID: u.userInfo.myUID,
                oppUserType: u.userInfo.oppUserType,
                oppositeUID: u.userInfo.oppositeUID,
                propertyList: u.userInfo.propertyList,
                recommended: u.userInfo.recommended,
                agentRepresented: u.userInfo.agentRepresented
              }
            }
          })
        }

      }
    }
    else if (u.userInfo.chatType == "sellerProfile") {
      await updateDoc(doc(db, 'userChat', user.uid), {
        [key]: {
          date: date,
          userInfo: {
            displayName: u.userInfo.displayName,
            photoURL: u.userInfo.photoURL,
            lastMessage: u.userInfo.lastMessage,
            sellingPrice: u.userInfo.sellingPrice,
            PSFValue: u.userInfo.PSFValue,
            commission: u.userInfo.commission,
            address: u.userInfo.address,
            postalCode: u.userInfo.postalCode,
            unitNumber: u.userInfo.unitNumber,
            statusRead: "Read",
            status: u.userInfo.status,
            chatType: u.userInfo.chatType,
            myUID: u.userInfo.myUID,
            oppUserType: u.userInfo.oppUserType,
            oppositeUID: u.userInfo.oppositeUID,
            agentProfileDeck: u.userInfo.agentProfileDeck
          }
        }
      })
      setSellerProfileClick(true);
    }

    await getDoc(doc(db, 'publicUserInfo', u.userInfo.oppositeUID)).then(async (oppPublicInfo) => {
      if (oppPublicInfo.exists()) {
        var lastLoginTimestamp = oppPublicInfo.data().lastLogin;

        var lastLoginDate = new Date(lastLoginTimestamp.seconds * 1000);
        var formattedDate = lastLoginDate.toLocaleString("en-GB", {
          year: 'numeric', month: 'long', day: 'numeric'
        });

        var formattedTime = lastLoginDate.toLocaleString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });

        var formattedDateTime = `${formattedDate} ${formattedTime}`;
        if (u.userInfo.hasOwnProperty('moveInPeriod')) {
          var timeStampMoveInPeriod = u.userInfo.moveInPeriod;
          var moveInPeriodDate = new Date(timeStampMoveInPeriod.seconds * 1000);
          var formattedDateTimeMoveInPeriod = moveInPeriodDate.toLocaleString('en-GB', {
            year: 'numeric', month: 'long', day: 'numeric'
          })
          u.userInfo.moveInPeriodFormatted = formattedDateTimeMoveInPeriod;
        }

        u.userInfo.lastLogin = formattedDateTime;
        setOppositePublicInfo(oppPublicInfo.data())
        setData(u.userInfo);
        setNavID(key);
        if (u.userInfo.chatType != "sellerProfile") {
          setSellerProfileClick(false);
        }
        else {
          setSellerProfileClick(true);
        }

        await getDoc(doc(db, 'userChat', u.userInfo.oppositeUID)).then((oppositeIndivDoc) => {
          if (oppositeIndivDoc.data().hasOwnProperty(key)) {
            setOppositeChatInfo(oppositeIndivDoc.data()[key].userInfo);
            // setChatClick(true);
          }
          else {
            // setChatClick(true);
          }
        })
      }
      else {
        if (u.userInfo.hasOwnProperty('moveInPeriod')) {
          var timeStampMoveInPeriod = u.userInfo.moveInPeriod;
          var moveInPeriodDate = new Date(timeStampMoveInPeriod.seconds * 1000);
          var formattedDateTimeMoveInPeriod = moveInPeriodDate.toLocaleString('en-GB', {
            year: 'numeric', month: 'long', day: 'numeric'
          })
          u.userInfo.moveInPeriodFormatted = formattedDateTimeMoveInPeriod;
        }

        u.userInfo.lastLogin = formattedDateTime;
        setNavID(key);
        setData(u.userInfo);
        // setChatClick(true);
      }



    })
    if (mobileVersion == true) {
      var messageRight = document.getElementById('mainRight');
      messageRight.style.display = "block";
    }

  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage(event);
    }
  };

  const sendMessage = async (e) => {

    e.preventDefault();

    // var combinedID = user.uid > data.uid ? user.uid + data.uid : data.uid + user.uid;
    await updateDoc(doc(db, 'chats', navID), {
      messages: arrayUnion({
        createdAt: new Date(),
        text: formValue,
        sender: user.uid,
        senderName: user.displayName
      }),

    });
    if (data.chatType == "CommercialRent") {
      if (currentUserInfo.membership == "Enterprise") {
        // if (data.status == "Pending Closing") {
        //   await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {

        //     [navID]: {
        //       date: serverTimestamp(),
        //       userInfo: {
        //         displayName: data.displayName,
        //         listingID: data.listingID,
        //         uid: user.uid,
        //         photoURL: currentUserInfo.userPhotoURL,
        //         lastMessage: formValue,
        //         listingName: data.listingName,
        //         listingPhoto: data.listingPhoto,
        //         listingPriceOverall: data.listingPriceOverall,
        //         listingPricePerSquareFeet: data.listingPricePerSquareFeet,
        //         statusRead: "Unread",
        //         status: data.status,
        //         chatType: data.chatType,
        //         ownerUID: data.ownerUID,
        //         closeCounter: data.closeCounter
        //       }
        //     }
        //   })
        // }
        // else if (data.status == "Pending LOO") {
        //   await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {

        //     [navID]: {
        //       date: serverTimestamp(),
        //       userInfo: {
        //         displayName: data.displayName,
        //         listingID: data.listingID,
        //         uid: user.uid,
        //         photoURL: currentUserInfo.userPhotoURL,
        //         lastMessage: formValue,
        //         listingName: data.listingName,
        //         listingPhoto: data.listingPhoto,
        //         listingPriceOverall: data.listingPriceOverall,
        //         listingPricePerSquareFeet: data.listingPricePerSquareFeet,
        //         statusRead: "Unread",
        //         status: data.status,
        //         ownerUID: data.ownerUID,
        //         chatType: data.chatType,
        //         looCounter: data.looCounter
        //       }
        //     }
        //   })
        // }
        // else {
        //   await updateDoc(doc(db, 'userChat', currentUserInfo.premiumLeader), {

        //     [navID]: {
        //       date: serverTimestamp(),
        //       userInfo: {
        //         displayName: data.displayName,
        //         listingID: data.listingID,
        //         uid: user.uid,
        //         photoURL: currentUserInfo.userPhotoURL,
        //         lastMessage: formValue,
        //         listingName: data.listingName,
        //         listingPhoto: data.listingPhoto,
        //         listingPriceOverall: data.listingPriceOverall,
        //         listingPricePerSquareFeet: data.listingPricePerSquareFeet,
        //         statusRead: "Unread",
        //         status: data.status,
        //         chatType: data.chatType,
        //         ownerUID: data.ownerUID
        //       }
        //     }
        //   })
        // }

      }
      if (data.status == "Pending Closing") {
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : deleteField(),
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
        else {
          //No property Agent
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }

      }
      else if (data.status == "Pending LOO") {
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                agentRepresented: data.agentRepresented

              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                agentRepresented: data.agentRepresented

              }
            }
          })
        }
        else {
          //No property Agent
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }

      }
      else {
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
        else {
          //No Property Agent
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPhoto: data.listingPhoto,
                listingPriceOverall: data.listingPriceOverall,
                listingPricePerSquareFeet: data.listingPricePerSquareFeet,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
      }
    }
    ////////////////////////////////////CommericalSales
    else if (data.chatType == "CommercialSales") {
      if (currentUserInfo.membership == "Enterprise") {

      }
      if (data.status == "Pending Closing") {
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.moveInPeriod,
                minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.moveInPeriod,
                minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
        else {
          //No property Agent
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.listingPriceOverall,
                minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.moveInPeriod,
                minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }

      }
      else if (data.status == "Pending LOO") {
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.moveInPeriod,
                minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.listingPriceOverall,
                minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
        else {
          //No property Agent
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.moveInPeriod,
                minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.moveInPeriod,
                minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }

      }
      else {
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.moveInPeriod,
                minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.moveInPeriod,
                minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
        else {
          //No Property Agent
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.moveInPeriod,
                minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.moveInPeriod,
                minFloorSizePSFBudget: data.minFloorSizePSFBudget,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
      }
    }
    //////////////////////ResidentialRent
    else if (data.chatType == "ResidentialRent") {
      if (currentUserInfo.membership == "Enterprise") {

      }
      if (data.status == "Pending Closing") {
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                moveInPeriod: data.moveInPeriod,
                listingPriceOverall: data.listingPriceOverall,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPriceOverall: data.listingPriceOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
        else {
          //No property Agent
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPriceOverall: data.listingPriceOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPriceOverall: data.listingPriceOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }

      }
      else if (data.status == "Pending LOO") {
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPriceOverall: data.listingPriceOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPriceOverall: data.listingPriceOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
        else {
          //No property Agent
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPriceOverall: data.listingPriceOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPriceOverall: data.listingPriceOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }

      }
      else {
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPriceOverall: data.listingPriceOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPriceOverall: data.listingPriceOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
        else {
          //No Property Agent
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPriceOverall: data.listingPriceOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                listingPriceOverall: data.listingPriceOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
      }
    }
    ///////////////////////////////ResidentialSales
    else if (data.chatType == "ResidentialSales") {
      if (currentUserInfo.membership == "Enterprise") {

      }
      if (data.status == "Pending Closing") {
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                budgetOverall: data.budgetOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                budgetOverall: data.budgetOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
        else {
          //No property Agent
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                budgetOverall: data.budgetOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                budgetOverall: data.budgetOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                closeCounter: data.closeCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }

      }
      else if (data.status == "Pending LOO") {
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                budgetOverall: data.budgetOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                budgetOverall: data.budgetOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
        else {
          //No property Agent
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                budgetOverall: data.budgetOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                budgetOverall: data.budgetOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                looCounter: data.looCounter,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }

      }
      else {
        if (data.hasOwnProperty('agentProfile')) {
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                budgetOverall: data.budgetOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                agentProfile: data.agentProfile,
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                budgetOverall: data.budgetOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
        else {
          //No Property Agent
          await updateDoc(doc(db, 'userChat', user.uid), {
            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: oppositePublicInfo.username,
                listingID: data.listingID,
                photoURL: data.photoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                budgetOverall: data.budgetOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Read",
                chatType: data.chatType,
                status: data.status,
                myUID: data.myUID,
                oppositeUID: data.oppositeUID,
                oppUserType: data.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
          await updateDoc(doc(db, 'userChat', data.oppositeUID), {

            [navID]: {
              date: serverTimestamp(),
              userInfo: {
                displayName: props.publicInfo.username,
                listingID: data.listingID,
                photoURL: currentUserInfo.userPhotoURL,
                lastMessage: formValue,
                listingName: data.listingName,
                budgetOverall: data.budgetOverall,
                moveInPeriod: data.moveInPeriod,
                statusRead: "Unread",
                chatType: data.chatType,
                status: data.status,
                myUID: data.oppositeUID,
                oppositeUID: data.myUID,
                oppUserType: oppositeChatInfo.oppUserType,
                propertyList: data.propertyList,
                recommended: data.recommended,
                recommendedBefore: data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore : null,
                agentRepresented: data.agentRepresented
              }
            }
          })
        }
      }
    }
    else if (data.chatType == "sellerProfile") {
      await updateDoc(doc(db, 'userChat', user.uid), {
        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            displayName: oppositePublicInfo.username,
            photoURL: data.photoURL,
            lastMessage: formValue,
            sellingPrice: data.sellingPrice,
            PSFValue: data.PSFValue,
            commission: data.commission,
            address: data.address,
            postalCode: data.postalCode,
            unitNumber: data.unitNumber,
            statusRead: "Read",
            status: data.status,
            chatType: data.chatType,
            myUID: data.myUID,
            oppUserType: data.oppUserType,
            oppositeUID: data.oppositeUID,
            agentProfileDeck: data.agentProfileDeck
          }
        }
      })
      await updateDoc(doc(db, 'userChat', data.oppositeUID), {

        [navID]: {
          date: serverTimestamp(),
          userInfo: {
            displayName: props.publicInfo.username,
            photoURL: currentUserInfo.userPhotoURL,
            lastMessage: formValue,
            sellingPrice: data.sellingPrice,
            PSFValue: data.PSFValue,
            commission: data.commission,
            address: data.address,
            postalCode: data.postalCode,
            unitNumber: data.unitNumber,
            statusRead: "Unread",
            status: data.status,
            chatType: data.chatType,
            myUID: data.oppositeUID,
            oppUserType: currentUserInfo.userType[0] == "Agent" ? "Agent" : "Landlord",
            oppositeUID: data.myUID,
            agentProfileDeck: data.agentProfileDeck
          }
        }
      })
    }

    // console.log(oppositePublicInfo, publicUserInfo);
    if (shortclock) {
      setShortClock(false);
      if (publicUserInfo.emailNotificationPending) {
        const requestOptions_1 = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + user.accessToken },
          body: JSON.stringify({ data: { username: oppositePublicInfo.username, email: oppositePublicInfo.email, path: "Message Notification", chatType: data.chatType } })
        };
        // http://127.0.0.1:5001/next-loop-57e04/asia-southeast1/emailNotification
        // https://asia-southeast1-next-loop-57e04.cloudfunctions.net/emailNotification
        await fetch('https://asia-southeast1-letsprop-6ae81.cloudfunctions.net/emailNotification', requestOptions_1).then(data => console.log(data)).then(
          error => console.log(error)
        );
      }
    }


    setFormValue('');
  }

  const giveRecommended = async () => {
    if (oppositePublicInfo.hasOwnProperty("recommended")) {
      var recommended = oppositePublicInfo.recommended;
      recommended = recommended + 1;
      await updateDoc(doc(db, 'publicUserInfo', data.oppositeUID), {
        recommended: recommended
      }).then(() => {
        setAcknowledgeNormalMessage("Thank you for the recommendation");
        setAcknowledgeNormal(true);
      })
    }
    else {
      await updateDoc(doc(db, 'publicUserInfo', data.oppositeUID), {
        recommended: 1
      }).then(() => {
        setAcknowledgeNormalMessage("Thank you for the recommendation");
        setAcknowledgeNormal(true);
      })
    }

    if (data.chatType == "CommercialRent") {
      if (data.hasOwnProperty("agentProfile")) {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: data.lastMessage,
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Read",
              status: data.status,
              chatType: data.chatType,
              myUID: data.myUID,
              oppUserType: data.oppUserType,
              oppositeUID: data.oppositeUID,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: data.hasOwnProperty('closeCounter') ? data.closeCounter : 0,
              recommendedBefore: true,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
      else {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {

              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: data.lastMessage,
              listingName: data.listingName,
              listingPhoto: data.listingPhoto,
              listingPriceOverall: data.listingPriceOverall,
              listingPricePerSquareFeet: data.listingPricePerSquareFeet,
              statusRead: "Read",
              status: data.status,
              chatType: data.chatType,
              closeCounter: data.hasOwnProperty('closeCounter') ? data.closeCounter : 0,
              myUID: data.myUID,
              oppUserType: data.oppUserType,
              oppositeUID: data.oppositeUID,
              propertyList: data.propertyList,
              recommended: data.recommended,
              recommendedBefore: true,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }

    }
    else if (data.chatType == "CommercialSales") {
      if (data.hasOwnProperty('agentProfile')) {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: data.lastMessage,
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              minFloorSizePSFBudget: data.minFloorSizePSFBudget,
              statusRead: "Read",
              status: data.status,
              chatType: data.chatType,
              myUID: data.myUID,
              oppUserType: data.oppUserType,
              oppositeUID: data.oppositeUID,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: data.hasOwnProperty('closeCounter') ? data.closeCounter : 0,
              recommendedBefore: true,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
      else {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: data.lastMessage,
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              minFloorSizePSFBudget: data.minFloorSizePSFBudget,
              statusRead: "Read",
              status: data.status,
              chatType: data.chatType,
              closeCounter: data.hasOwnProperty('closeCounter') ? data.closeCounter : 0,
              myUID: data.myUID,
              oppUserType: data.oppUserType,
              oppositeUID: data.oppositeUID,
              propertyList: data.propertyList,
              recommended: data.recommended,
              recommendedBefore: true,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }

    }
    else if (data.chatType == "ResidentialRent") {
      if (data.hasOwnProperty('agentProfile')) {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: data.lastMessage,
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              listingPriceOverall: data.listingPriceOverall,
              statusRead: "Read",
              status: data.status,
              chatType: data.chatType,
              myUID: data.myUID,
              oppUserType: data.oppUserType,
              oppositeUID: data.oppositeUID,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: data.hasOwnProperty('closeCounter') ? data.closeCounter : 0,
              recommendedBefore: true,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
      else {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: data.lastMessage,
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              listingPriceOverall: data.listingPriceOverall,
              statusRead: "Read",
              status: data.status,
              chatType: data.chatType,
              myUID: data.myUID,
              oppUserType: data.oppUserType,
              oppositeUID: data.oppositeUID,
              propertyList: data.propertyList,
              recommended: data.recommended,
              closeCounter: data.hasOwnProperty('closeCounter') ? data.closeCounter : 0,
              recommendedBefore: true,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }

    }
    else if (data.chatType == "ResidentialSales") {
      if (data.hasOwnProperty('agentProfile')) {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              agentProfile: data.agentProfile,
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: data.lastMessage,
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              budgetOverall: data.budgetOverall,
              statusRead: "Read",
              status: data.status,
              chatType: data.chatType,
              myUID: data.myUID,
              oppUserType: data.oppUserType,
              oppositeUID: data.oppositeUID,
              propertyList: data.propertyList,
              closeCounter: data.hasOwnProperty('closeCounter') ? data.closeCounter : 0,
              recommended: data.recommended,
              recommendedBefore: true,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }
      else {
        await updateDoc(doc(db, 'userChat', user.uid), {
          [navID]: {
            date: serverTimestamp(),
            userInfo: {
              displayName: data.displayName,
              listingID: data.listingID,
              photoURL: data.photoURL,
              lastMessage: data.lastMessage,
              listingName: data.listingName,
              moveInPeriod: data.moveInPeriod,
              budgetOverall: data.budgetOverall,
              statusRead: "Read",
              status: data.status,
              chatType: data.chatType,
              closeCounter: data.hasOwnProperty('closeCounter') ? data.closeCounter : 0,
              myUID: data.myUID,
              oppUserType: data.oppUserType,
              oppositeUID: data.oppositeUID,
              propertyList: data.propertyList,
              recommended: data.recommended,
              recommendedBefore: true,
              agentRepresented: data.agentRepresented
            }
          }
        })
      }

    }
  }

  const showHelpLOI = () => {
    const notApprovedText = document.getElementById(`showHelpLOI`);
    if (notApprovedText.style.display == "none") {
      notApprovedText.style.display = "block";
    }
    else {
      notApprovedText.style.display = "none";
    }
  }

  const showHelpLOO = () => {
    const notApprovedText = document.getElementById(`showHelpLOO`);
    if (notApprovedText.style.display == "none") {
      notApprovedText.style.display = "block";
    }
    else {
      notApprovedText.style.display = "none";
    }
  }

  const zoomPicfunction = (url) => {
    setZoomPic(true);
    setZoomPicURL(url);
  }

  const zoomVideofunction = (url) => {
    setZoomVideo(true);
    setZoomVideoURL(url);
  }

  if (!user) {
    return <Navigate to='/' />
  }
  else {

    const backToMessage = () => {
      var messageRight = document.getElementById("mainRight");
      messageRight.style.display = "none";
      setMoblieChat(false);
    }

    return (
      <>
        <AcknowledgeNormal trigger={acknowledgeNormal} setTrigger={setAcknowledgeNormal} message={acknowledgeNormalMessage}></AcknowledgeNormal>
        <LOI trigger={activateLOI} setTrigger={setAcitvateLOI} chatID={navID} setLOISend={setLOISend}></LOI>
        <ZoomPicture trigger={zoomPic} setTrigger={setZoomPic} zoomPicURL={zoomPicURL}></ZoomPicture>
        <ZoomVideo trigger={zoomVideo} setTrigger={setZoomVideo} zoomVideoURL={zoomVideoURL}></ZoomVideo>
        <ChooseProperty trigger={chooseProperty} setTrigger={setChooseProperty} currentUserInfo={currentUserInfo} navID={navID} data={data} publicInfo={props.publicInfo} oppositeChatInfo={oppositeChatInfo} oppositePublicInfo={oppositePublicInfo}></ChooseProperty>
        <ReportChat trigger={activatedReportChat} setTrigger={setActivatedReportChat} currentUserInfo={currentUserInfo} navID={navID} data={data} publicInfo={props.publicInfo} oppositePublicInfo={oppositePublicInfo}></ReportChat>
        {ready ?
          <>
            <div id="messageLeft" style={mobileVersion == true ? mobileChat == true ? { display: "none" } : { display: "block" } : null}>
              <div style={{ padding: "5px", backgroundColor: "#f0f2f5", borderRadius: "5px", margin: "5px" }}>
                <i class="fa-solid fa-magnifying-glass" id="magnifyingIconMessageBox" style={{ width: "10%" }} ></i>
                <input style={{ backgroundColor: "#f0f2f5", padding: "5px", width: "90%", border: "none", outline: "none" }} placeholder='Search chat' value={inputSearchChat} onChange={(e) => { filterChat(e) }}></input>
              </div>
              {matchingChat == null ? <p id='tableTitle'>All Conversations</p> : null}
              <table id="messageTable">

                {matchingChat == null ? chats.length != 0 ? Object.entries(chats)?.sort((a, b) => b[1].date - a[1].date).map((chat, index) => (
                  chat[1][1].userInfo.statusRead == "Read" ?
                    <tr onClick={() => selectChat(chat[1][0], chat[1][1], chat[1][1].date.toDate())} >

                      <td style={chat[1][1].userInfo.chatType == "sellerProfile" ? { backgroundColor: "#c0c0c0" } : chat[1][1].userInfo.chatType.search("Commercial") >= 0 ? { backgroundColor: "#8cd1c7" } : { backgroundColor: "#33434D" }}>

                      </td>

                      <td>
                        <img src={chat[1][1].userInfo.photoURL} ></img>
                      </td>
                      <td>
                        <p style={{ fontWeight: "bold", paddingBottom: "2px" }}>@{chat[1][1].userInfo.displayName}</p>
                        {chat[1][1].userInfo.chatType == "sellerProfile" ?
                          <p style={{ paddingTop: "2px", paddingBottom: "2px" }}>Owner Directory</p>
                          :
                          <p style={{ paddingTop: "2px", paddingBottom: "2px" }}>Listing Name: {chat[1][1].userInfo.listingName.length > 15 ? `${chat[1][1].userInfo.listingName.slice(0, 12)}...` : chat[1][1].userInfo.listingName} [{chat[1][1].userInfo.chatType == "CommercialRent" ? "CR" : chat[1][1].userInfo.chatType == "CommercialSales" ? "CP" : chat[1][1].userInfo.chatType == "ResidentialRent" ? "RR" : chat[1][1].userInfo.chatType == "ResidentialSales" ? "RP" : null}]</p>

                        }
                        {chat[1][1].userInfo.lastMessage != null ? <p id="chatboxLeft_Message" style={{ paddingTop: "2px" }}>{chat[1][1].userInfo.lastMessage.includes("https://firebasestorage.googleapis.com") ? "Photo / File Send" : chat[1][1].userInfo.lastMessage}</p> : null}
                        <p style={{ float: "right", fontSize: "12px", color: "grey" }}>{chat[1][1].userInfo.lastLogin}</p>
                        <div style={{ clear: 'both' }}></div>
                      </td>
                    </tr>
                    :

                    <tr onClick={() => { selectChat(chat[1][0], chat[1][1], chat[1][1].date.toDate()) }}>
                      <td style={chat[1][1].userInfo.chatType == "sellerProfile" ? { backgroundColor: "#c0c0c0" } : chat[1][1].userInfo.chatType.search("Commercial") >= 0 ? { backgroundColor: "#8cd1c7" } : { backgroundColor: "#33434D" }}>

                      </td>

                      <td>
                        <img src={chat[1][1].userInfo.photoURL} ></img>
                      </td>
                      <td>
                        <p style={{ fontWeight: "bold", paddingBottom: "2px" }}>@{chat[1][1].userInfo.displayName}<span id="chatNotificationCircle"></span></p>
                        {chat[1][1].userInfo.chatType == "sellerProfile" ?
                          <p style={{ paddingTop: "2px", paddingBottom: "2px" }}>Owner Directory</p>
                          :
                          <p style={{ paddingTop: "2px", paddingBottom: "2px" }}>Listing Name: {chat[1][1].userInfo.listingName.length > 15 ? `${chat[1][1].userInfo.listingName.slice(0, 12)}...` : chat[1][1].userInfo.listingName} [{chat[1][1].userInfo.chatType == "CommercialRent" ? "CR" : chat[1][1].userInfo.chatType == "CommercialSales" ? "CP" : chat[1][1].userInfo.chatType == "ResidentialRent" ? "RR" : chat[1][1].userInfo.chatType == "ResidentialSales" ? "RP" : null}]</p>

                        }
                        {chat[1][1].userInfo.lastMessage != null ? <p id="chatboxLeft_Message">{chat[1][1].userInfo.lastMessage.includes("https://firebasestorage.googleapis.com") ? "Photo / File Send" : chat[1][1].userInfo.lastMessage}</p> : null}
                        <p style={{ float: "right", fontSize: "12px", color: "grey" }}>{chat[1][1].userInfo.lastLogin}</p>
                        <div style={{ clear: 'both' }}></div>
                      </td>
                    </tr>
                )) :
                  <center><p style={{ marginTop: "5%" }}>No Message</p></center>
                  :
                  matchingChat.map((indivMatchingChat) => {
                    return (
                      <tr style={(indivMatchingChat.chatInfo[1].userInfo.oppUserType == "Tenant") && (currentUserInfo.membership == null || currentUserInfo.membership == "Inactive") ? { backgroundColor: "#c1c1c1" } : {}} onClick={(indivMatchingChat.chatInfo[1].userInfo.oppUserType == "Tenant") && (currentUserInfo.membership == null || currentUserInfo.membership == "Inactive") ? null : () => selectChat(indivMatchingChat.chatInfo[0], indivMatchingChat.chatInfo[1], indivMatchingChat.chatInfo[1].date.toDate())} >
                        <td style={indivMatchingChat.chatInfo[1].userInfo.chatType.search("Commercial") >= 0 ? { backgroundColor: "#8cd1c7" } : { backgroundColor: "#33434D" }}>

                        </td>

                        <td>
                          <img src={indivMatchingChat.chatInfo[1].userInfo.photoURL} ></img>
                        </td>

                        <td>
                          <p style={{ fontWeight: "bold", paddingBottom: "2px" }}>@{indivMatchingChat.chatInfo[1].userInfo.displayName}</p>
                          <p>{indivMatchingChat.data.text.replace(/^SystemNextloopNoti:\s*/, '').includes("https://firebasestorage.googleapis.com") ? "Photo / File Send" : `${indivMatchingChat.data.text.replace(/^SystemNextloopNoti:\s*/, '').slice(0, 35)}...`}</p>


                        </td>
                      </tr>)
                  })
                }
              </table>
            </div>
            <div id="mainRight" style={mobileVersion == true ? mobileChat == true ? { display: "block" } : { display: "none" } : null}>
              {chatClick == false ?
                <>
                  <div id="chatEmpty">
                    <img style={{ width: "30%", marginTop: "-10%", opacity: "0.5" }} src='./navLogo.png'></img>
                  </div>
                </> : null}
              <div id="messageRight" >
                {console.log(chatClick, mobileVersion, mobileChat, messages)}
                {chatClick == true && messages != null ? messages.length > 1 ?
                  <>
                    {mobileVersion == true && mobileChat == true ? <i id="backarrow" onClick={() => { backToMessage() }} class="fa-solid fa-arrow-left"></i> : null}

                    {sellerProfileClick == false ? expandedTopInfo == false ?
                      <div id="topInfomationChat">
                        <div style={data.chatType == "sellerProfile" ? { backgroundColor: "#c0c0c0", width: "0.5%", float: "left", height: "inherit", borderRadius: "50px" } : data.chatType.search("Commercial") >= 0 ? { backgroundColor: "#8cd1c7", width: "0.5%", float: "left", height: "inherit", borderRadius: "50px" } : { backgroundColor: "#33434D", width: "0.5%", float: "left", height: "inherit", borderRadius: "50px" }}></div>
                        {data.status != "Deleted" && data.chatType != "sellerProfile" ? <i id="messageThreeDot" class="fa-solid fa-bars" onClick={() => { setThreeDot(!threeDot) }}></i> : null}
                        <i id="messageExpandedIcon" onClick={() => { setExpandedTopInfo(true) }} class="fa-solid fa-angle-down"></i>
                        {/* {data.chatType == "CommercialRent" ? <img src={} style={{ width: '60px', height: '60px', marginLeft: "1%", borderRadius: "30px", float: "left" }}></img> : null} */}
                        <div id='messageLabelDescription'>
                          <div className="flex flex-row items-center">
                            <img
                              alt="Man"
                              src={data.chatType == "CommercialRent" ? data.listingPhoto : data.photoURL}
                              className="h-10 w-10 rounded-full object-cover"
                            />
                            <div className='pl-4'>
                              <p class="text-l font-semibold">{data.chatType == "CommercialRent" ? "Commercial Rent" : data.chatType == "ResidentialSales" ? "Residential Purchase" : data.chatType == "ResidentialRent" ? "Residential Rent" : "Commercial Purchase"}</p>
                              <p className='font-semibold text-l'>{data.displayName}</p>
                              <p className='font text-sm text-gray-800'>Last Seen: {data.lastLogin}</p>
                              <p className='font text-sm text-gray-800'>Chat Status: {data.status} {data.status == "Pending Closing" ? <span style={{ fontSize: "12px" }}>[{data.closeCounter} Days]</span> : null} {data.status == "Pending LOO" ? <span style={{ fontSize: "12px" }}>[{data.looCounter} Days]</span> : null}</p>
                              <p className='font text-sm text-gray-800'>{data.agentRepresented && data.oppUserType == "Tenant" ? "Agent Represented" : data.oppUserType}</p>
                            </div>
                          </div>
                          {/* <p style={{ fontWeight: "bold", display: "flex" }}>@{data.displayName} <img src={data.photoURL} className='w-5 h-5' style={{ marginLeft: "1%", borderRadius: "10px" }}></img> */}

                          {/* </p> */}
                          {/* <p>Listing Name: {data.listingName.length > 50 ? `${data.listingName.slice(0, 47)}...` : data.listingName} [{data.chatType == "CommercialRent" ? "CR" : data.chatType == "CommercialSales" ? "CP" : data.chatType == "ResidentialRent" ? "RR" : data.chatType == "ResidentialSales" ? "RP" : null}]</p> */}
                          {/* <p>Role: {data.agentRepresented && data.oppUserType == "Tenant" ? "Agent Represented" : data.oppUserType}</p> */}
                          {/* <p>Status: {data.status} {data.status == "Pending Closing" ? <span style={{ fontSize: "12px" }}>[{data.closeCounter} Days]</span> : null} {data.status == "Pending LOO" ? <span style={{ fontSize: "12px" }}>[{data.looCounter} Days]</span> : null}</p> */}
                          {/* <p>Last Seen: {data.lastLogin}</p> */}

                          <div style={{ clear: 'both' }}></div>


                        </div>
                        <div style={{ clear: 'both' }}></div>
                        {threeDot == true ? <div class="ellipsis-menu">
                          {data.status != "Closed" ?
                            data.chatType == "CommercialRent" ?
                              <ul>
                                {data.oppUserType == "Tenant" ? <li onClick={() => setChooseProperty(true)}>Send Property Profile</li> : null}
                                {data.oppUserType != "Tenant" && (data.status == "Accepted" || data.status == "Pending LOI") ? <li onClick={() => setAcitvateLOI(true)}>Generate LOI <i class="fa-solid fa-circle-question"
                                  style={{ color: "#316190", paddingRight: "5px" }}
                                  onMouseEnter={() => showHelpLOI()}
                                  onMouseLeave={() => showHelpLOI()}
                                  onClick={() => showHelpLOI()}></i>
                                  <div id={`showHelpLOI`} style={{ display: "none", position: "absolute", backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc", borderRadius: "10px" }}>
                                    {"Letter of Intent"}
                                  </div>
                                </li> : null}
                                <li onClick={() => window.open("https://app.sla.gov.sg/inlis/#")}>Retrive title deed</li>
                                {data.status == "Pending LOI Approval" || data.status == "Pending LOO" || data.status == "Pending Closing" || data.status == "Listing Closed" ? <li onClick={() => openLOI()}>View LOI</li> : null}
                                {(data.status == "Pending LOI Approval") && data.oppUserType != "Tenant" ? <li onClick={() => retractLOI()}>Retract LOI</li> : null}
                                {(data.status == "Time Expired for LOO") && data.oppUserType != "Tenant" ? <li onClick={() => retractLOINoAllowChat()}>Retract LOI</li> : null}
                                {data.status == "Pending LOI Approval" && data.oppUserType == "Tenant" ? <li onClick={() => acceptLOI()}>Accept LOI</li> : null}
                                {data.status == "Pending LOI Approval" && data.oppUserType == "Tenant" ? <li onClick={() => declineLOI()}>Decline LOI</li> : null}
                                {data.oppUserType == "Tenant" && data.status != "Pending Closing" ? data.status == "Pending LOO" ? <><input
                                  type="file"
                                  ref={LOOInputRef}
                                  accept=".pdf"
                                  style={{ display: "none" }}
                                  onChange={handleLOOFileChange}
                                /><li onClick={handleLOOUpload}>Upload LOO <i class="fa-solid fa-circle-question"
                                  style={{ color: "#316190", paddingRight: "5px" }}
                                  onMouseEnter={() => showHelpLOO()}
                                  onMouseLeave={() => showHelpLOO()}
                                  onClick={() => showHelpLOO()}></i>
                                    <div id={`showHelpLOO`} style={{ display: "none", position: "absolute", backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc", borderRadius: "10px" }}>
                                      {"Letter of Offer / Tenancy Agreement"}
                                    </div></li> </> :
                                  <li onClick={handleLOOUpload} disabled style={{ color: "grey" }}>Upload LOO <i class="fa-solid fa-circle-question"
                                    style={{ color: "#316190", paddingRight: "5px" }}
                                    onMouseEnter={() => showHelpLOO()}
                                    onMouseLeave={() => showHelpLOO()}
                                    onClick={() => showHelpLOO()}></i>
                                    <div id={`showHelpLOO`} style={{ display: "none", position: "absolute", backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc", borderRadius: "10px" }}>
                                      {"Letter of Offer / Tenancy Agreement"}
                                    </div> </li> : null}
                                {data.status == "Pending Closing" || data.status == "Listing Closed" ? <><li onClick={() => openLOO()}>View LOO</li> </> : null}

                                {data.status == "Closed" && data.oppUserType != "Tenant" ? <li onClick={() => giveRecommended()}>Give Recomemnded</li> : null}
                              </ul> :
                              <ul>
                                {data.oppUserType == "Tenant" ? <li onClick={() => { setChooseProperty(true) }}>Send Property Profile</li> : null}
                                <li onClick={() => window.open("https://app.sla.gov.sg/inlis/#")}>Retrive title deed</li>
                                <li onClick={() => sampleHDBTA()}>Sample HDB TA</li>
                                <li onClick={() => samplePrivateTA()}>Sample Private Property TA</li>
                                {data.oppUserType == "Tenant" && data.status != "Pending Closing" ? <li onClick={() => { closeListing() }}>Close Listing</li> : null}
                              </ul> :
                            <ul>
                              {data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore == true ? null : <li onClick={() => giveRecommended()}>Recommended</li> : <li onClick={() => giveRecommended()}>Recommended</li>}
                            </ul>
                          }
                        </div> : null}

                      </div>
                      :
                      <div id="topInfomationChat">
                        <div style={data.chatType.search("Commercial") >= 0 ? { backgroundColor: "#8cd1c7", width: "0.5%", float: "left", height: "inherit", borderRadius: "50px" } : { backgroundColor: "#33434D", width: "0.5%", float: "left", height: "inherit", borderRadius: "50px" }}></div>
                        {data.status != "Deleted" ? <i id="messageThreeDot" class="fa-solid fa-bars" onClick={() => { setThreeDot(!threeDot) }}></i> : null}
                        <i id="messageExpandedIcon" onClick={() => { setExpandedTopInfo(false) }} class="fa-solid fa-angle-up"></i>
                        {/* {data.chatType == "CommercialRent" ? <>
                          <img src={data.listingPhoto} style={{ float: "left", maxWidth: '15%', maxHeight: '80%', marginLeft: "1%", borderRadius: "10px" }}></img>

                        </> : null} */}
                        <div id='messageLabelDescription'>
                          <div className="flex flex-row items-center">
                            <img
                              alt="Man"
                              src={data.chatType == "CommercialRent" ? data.listingPhoto : data.photoURL}
                              className="h-10 w-10 rounded-full object-cover"
                            />
                            <div className='pl-4'>
                              <p className='font-semibold text-l'>{data.displayName}</p>
                              {data.oppUserType == "Agent" ? <>
                                <p className='font text-sm text-gray-800'>CEA: {data.agentProfile.CEAAgencyNumber}</p>
                                <p className='font text-sm text-gray-800'>{data.agentProfile.CEAHP}</p>
                              </> : null}
                              <p className='font text-sm text-gray-800'>Last Seen: {data.lastLogin}</p>
                              <p className='font text-sm text-gray-800'>Chat Status: {data.status} {data.status == "Pending Closing" ? <span style={{ fontSize: "12px" }}>[{data.closeCounter} Days]</span> : null} {data.status == "Pending LOO" ? <span style={{ fontSize: "12px" }}>[{data.looCounter} Days]</span> : null}</p>
                              <p className='font text-sm text-gray-800'>{data.agentRepresented && data.oppUserType == "Tenant" ? "Agent Represented" : data.oppUserType}</p>

                              <div className='mt-2'>
                                <p class="text-l font-semibold">{data.chatType == "CommercialRent" ? "Commercial Rent" : data.chatType == "ResidentialSales" ? "Residential Purchase" : data.chatType == "ResidentialRent" ? "Residential Rent" : "Commercial Purchase"}</p>
                                <p class="">Listing Name: {data.listingName}</p>
                                {data.chatType == "CommercialRent" ?
                                  <>
                                    <p>Listed Budget Per Square Feet: ${data.listingPriceOverall.toLocaleString()}</p>
                                    <p>Listing Overall Budget: ${data.listingPricePerSquareFeet.toLocaleString()}</p>
                                  </> : null}

                                {data.chatType == "CommercialSales" ?
                                  <>
                                    <p>Min PSF Budget: ${data.minFloorSizePSFBudget.toLocaleString()}</p>
                                    <p>Move In Period: {data.moveInPeriodFormatted}</p>
                                  </> : null}
                                {data.chatType == "ResidentialRent" ?
                                  <>
                                    <p>Monthly Rental Budget: ${data.listingPriceOverall}</p>
                                    <p>Move In Period: {data.moveInPeriodFormatted}</p>
                                  </> : null}
                                {data.chatType == "ResidentialSales" ?
                                  <>
                                    <p>Overall Budget: ${data.budgetOverall.toLocaleString()}</p>
                                    <p>Move In Period: {data.moveInPeriodFormatted}</p>
                                  </> : null}
                                {data.status != "Deleted" ? data.chatType == "CommercialRent" ? <Link to={`/commercialRentDetails/${data.listingID}`}><button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%" }}>Explore</button></Link> : null : null}
                                {data.status != "Deleted" ? data.chatType == "CommercialSales" ? <Link to={`/commercialPurchaseDetails/${data.listingID}`}><button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%" }}>Explore</button></Link> : null : null}
                                {data.status != "Deleted" ? data.chatType == "ResidentialRent" ? <Link to={`/residentialRentDetails/${data.listingID}`}><button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%" }}>Explore</button></Link> : null : null}
                                {data.status != "Deleted" ? data.chatType == "ResidentialSales" ? <Link to={`/residentialPurchaseDetails/${data.listingID}`}><button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%" }}>Explore</button></Link> : null : null}
                                {data.status != "Deleted" ? <button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%", marginLeft: "1%" }} onClick={() => setActivatedReportChat(true)}>Report</button> : null}
                                {data.status == "Pending Closing" && data.oppUserType != "Tenant" ? <button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%", marginLeft: "1%" }} onClick={() => disputeActivated()}>Dispute</button> : null}
                                {data.status == "Pending LOO" && data.oppUserType != "Tenant" && data.looCounter <= 3 ? <button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%", marginLeft: "1%" }} onClick={() => extendLOOCounter()}>Extend</button> : null}

                              </div>

                            </div>
                          </div>
                          {/* <p style={{ fontWeight: "bold", display: "flex" }}>@{data.displayName} <img src={data.photoURL} style={{ width: '3%', height: '3%', marginLeft: "1%", borderRadius: "10px" }}></img> */}

                          {/* </p> */}

                          {/* <p>Listing Name: </p>
                          {data.chatType == "CommercialRent" ?
                            <>
                              <p>Listed Budget Per Square Feet: ${data.listingPriceOverall.toLocaleString()}</p>
                              <p>Listing Overall Budget: ${data.listingPricePerSquareFeet.toLocaleString()}</p>
                            </> : null}

                          {data.chatType == "CommercialSales" ?
                            <>
                              <p>Min PSF Budget: ${data.minFloorSizePSFBudget.toLocaleString()}</p>
                              <p>Move In Period: {data.moveInPeriodFormatted}</p>
                            </> : null}
                          {data.chatType == "ResidentialRent" ?
                            <>
                              <p>Monthly Rental Budget: ${data.listingPriceOverall}</p>
                              <p>Move In Period: {data.moveInPeriodFormatted}</p>
                            </> : null}
                          {data.chatType == "ResidentialSales" ?
                            <>
                              <p>Overall Budget: ${data.budgetOverall.toLocaleString()}</p>
                              <p>Move In Period: {data.moveInPeriodFormatted}</p>
                            </> : null} */}
                          {/* <p>Status: {data.status} {data.status == "Pending Closing" ? <span style={{ fontSize: "12px" }}>[{data.closeCounter} Days]</span> : null} {data.status == "Pending LOO" ? <span style={{ fontSize: "12px" }}>[{data.looCounter} Days]</span> : null}</p> */}
                          {/* {data.status != "Deleted" ? data.chatType == "CommercialRent" ? <Link to={`/commercialRentDetails/${data.listingID}`}><button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%" }}>Explore</button></Link> : null : null}
                          {data.status != "Deleted" ? data.chatType == "CommercialSales" ? <Link to={`/commercialPurchaseDetails/${data.listingID}`}><button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%" }}>Explore</button></Link> : null : null}
                          {data.status != "Deleted" ? data.chatType == "ResidentialRent" ? <Link to={`/residentialRentDetails/${data.listingID}`}><button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%" }}>Explore</button></Link> : null : null}
                          {data.status != "Deleted" ? data.chatType == "ResidentialSales" ? <Link to={`/residentialPurchaseDetails/${data.listingID}`}><button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%" }}>Explore</button></Link> : null : null}
                          {data.status != "Deleted" ? <button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%", marginLeft: "1%" }} onClick={() => setActivatedReportChat(true)}>Report</button> : null}
                          {data.status == "Pending Closing" && data.oppUserType != "Tenant" ? <button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%", marginLeft: "1%" }} onClick={() => disputeActivated()}>Dispute</button> : null}
                          {data.status == "Pending LOO" && data.oppUserType != "Tenant" && data.looCounter <= 3 ? <button className='btn_message' style={{ marginTop: "1%", marginBottom: "1%", marginLeft: "1%" }} onClick={() => extendLOOCounter()}>Extend</button> : null} */}


                        </div>
                        <div style={{ clear: 'both' }}></div>
                        {threeDot == true ? <div class="ellipsis-menu">
                          {data.status != "Closed" ? data.chatType == "CommercialRent" ?
                            <ul>
                              {data.oppUserType == "Tenant" ? <li onClick={() => { setChooseProperty(true) }}>Send Property Profile</li> : null}
                              {data.oppUserType != "Tenant" && (data.status == "Accepted" || data.status == "Pending LOI") ? <li onClick={() => setAcitvateLOI(true)}>Generate LOI <i class="fa-solid fa-circle-question"
                                style={{ color: "#316190", paddingRight: "5px" }}
                                onMouseEnter={() => showHelpLOI()}
                                onMouseLeave={() => showHelpLOI()}
                                onClick={() => showHelpLOI()}></i>
                                <div id={`showHelpLOI`} style={{ display: "none", position: "absolute", backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc", borderRadius: "10px" }}>
                                  {"Letter of Intent"}
                                </div></li> : null}
                              <li onClick={() => window.open("https://app.sla.gov.sg/inlis/#")}>Retrive title deed</li>
                              {data.status == "Pending LOI Approval" || data.status == "Pending LOO" || data.status == "Pending Closing" || data.status == "Listing Closed" ? <li onClick={() => openLOI()}>View LOI</li> : null}
                              {(data.status == "Pending LOI Approval") && data.oppUserType != "Tenant" ? <li onClick={() => retractLOI()}>Retract LOI</li> : null}
                              {(data.status == "Time Expired for LOO") && data.oppUserType != "Tenant" ? <li onClick={() => retractLOINoAllowChat()}>Retract LOI</li> : null}

                              {data.status == "Pending LOI Approval" && data.oppUserType == "Tenant" ? <li onClick={() => acceptLOI()}>Accept LOI</li> : null}
                              {data.status == "Pending LOI Approval" && data.oppUserType == "Tenant" ? <li onClick={() => declineLOI()}>Decline LOI</li> : null}
                              {data.oppUserType == "Tenant" && data.status != "Pending Closing" ? data.status == "Pending LOO" ? <><input
                                type="file"
                                ref={LOOInputRef}
                                accept=".pdf"
                                style={{ display: "none" }}
                                onChange={handleLOOFileChange}
                              /><li onClick={handleLOOUpload}>Upload LOO <i class="fa-solid fa-circle-question"
                                style={{ color: "#316190", paddingRight: "5px" }}
                                onMouseEnter={() => showHelpLOO()}
                                onMouseLeave={() => showHelpLOO()}
                                onClick={() => showHelpLOO()}></i>
                                  <div id={`showHelpLOO`} style={{ display: "none", position: "absolute", backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc", borderRadius: "10px" }}>
                                    {"Letter of Offer / Tenancy Agreement"}
                                  </div></li> </> :
                                <li onClick={handleLOOUpload} disabled style={{ color: "grey" }}>Upload LOO <i class="fa-solid fa-circle-question"
                                  style={{ color: "#316190", paddingRight: "5px" }}
                                  onMouseEnter={() => showHelpLOO()}
                                  onMouseLeave={() => showHelpLOO()}
                                  onClick={() => showHelpLOO()}></i>
                                  <div id={`showHelpLOO`} style={{ display: "none", position: "absolute", backgroundColor: "#fff", padding: "5px", border: "1px solid #ccc", borderRadius: "10px" }}>
                                    {"Letter of Offer / Tenancy Agreement"}
                                  </div></li> : null}
                              {data.status == "Pending Closing" || data.status == "Listing Closed" ? <><li onClick={() => openLOO()}>View LOO</li> </> : null}
                            </ul>
                            :

                            <ul>
                              {data.oppUserType == "Tenant" ? <li onClick={() => { setChooseProperty(true) }}>Send Property Profile</li> : null}
                              <li onClick={() => window.open("https://app.sla.gov.sg/inlis/#")}>Retrive title deed</li>
                              <li onClick={() => sampleHDBTA()}>Sample HDB TA</li>
                              <li onClick={() => samplePrivateTA()}>Sample Private Property TA</li>
                              {data.oppUserType == "Tenant" ? <li onClick={() => { closeListing() }}>Close Listing</li> : null}
                            </ul> :
                            <ul>
                              {data.hasOwnProperty('recommendedBefore') ? data.recommendedBefore == true ? null : <li onClick={() => giveRecommended()}>Recommended</li> : <li onClick={() => giveRecommended()}>Recommended</li>}
                            </ul>

                          }
                        </div> : null}

                      </div>
                      : null
                    }
                    {sellerProfileClick ? expandedTopInfo == false ?
                      <>

                        <div id="topInfomationChat">
                          <div style={{ backgroundColor: "#c0c0c0", width: "0.5%", float: "left", height: "inherit", borderRadius: "50px" }}></div>
                          <div id='messageLabelDescription'>
                            <div className="flex flex-row items-center">
                              <img
                                alt="Man"
                                src={data.photoURL}
                                className="h-10 w-10 rounded-full object-cover"
                              />
                              <div className='pl-4'>
                                <p class="text-l font-semibold">Owner Directory</p>
                                <p className='font-semibold text-l'>{data.displayName}</p>
                                <p className='font text-sm text-gray-800'>Last Seen: {data.lastLogin}</p>
                                <p className='font text-sm text-gray-800'>{data.oppUserType}</p>
                              </div>
                            </div>




                            <div style={{ clear: 'both' }}></div>


                          </div>

                          <i id="messageExpandedIcon" onClick={() => { setExpandedTopInfo(true) }} class="fa-solid fa-angle-down"></i>
                          <div style={{ clear: 'both' }}></div>


                        </div>
                      </> : <>

                        <div id="topInfomationChat">
                          <div style={{ backgroundColor: "#c0c0c0", width: "0.5%", float: "left", height: "inherit", borderRadius: "50px" }}></div>
                          <div id='messageLabelDescription'>
                            <div className="flex flex-row items-center">
                              <img
                                alt="Man"
                                src={data.photoURL}
                                className="h-10 w-10 rounded-full object-cover"
                              />
                              <div className='pl-4'>
                                <p className='font-semibold text-l'>{data.displayName}</p>
                                <p className='font text-sm text-gray-800'>Last Seen: {data.lastLogin}</p>
                                <p className='font text-sm text-gray-800'>{data.oppUserType}</p>
                                {data.oppUserType == "Agent" && data.hasOwnProperty('agentProfileDeck') ? <p className='font text-sm text-gray-800'>CEA: {data.agentProfileDeck.CEAAgencyNumber}</p> : null}
                                {data.oppUserType == "Agent" && data.hasOwnProperty('agentProfileDeck') ? <p className='font text-sm text-gray-800'>{data.agentProfileDeck.CEAHP}</p> : null}
                                <div className='mt-2'>
                                  <p class="text-l font-semibold">Owner Directory</p>
                                  <p class="">Address: {data.address}</p>
                                  <p class="">Proposed Commission: {data.commission}</p>
                                </div>
                              </div>

                            </div>


                            <div style={{ clear: 'both' }}></div>


                          </div>

                          <div style={{ clear: 'both' }}></div>

                          <i id="messageExpandedIcon" onClick={() => { setExpandedTopInfo(false) }} class="fa-solid fa-angle-up"></i>
                        </div>
                      </> : null}

                  </>
                  : null : null}
                <div id="mainMessage">

                  {chatClick == true && messages != null && messages.length > 1 ?
                    messages.map((m, message_index) => {
                      if (m.sender == user.uid) {
                        return (
                          <>
                            {m.image != null ?
                              <>
                                <img src={currentUserInfo.userPhotoURL} id='meProfilePic' style={{ width: "30px", height: "30px" }}></img>
                                <img src={m.image} style={{ width: "250px", height: "250px", float: "right", marginRight: "2%" }}></img>
                                <div style={{ clear: 'both' }}></div>
                              </>
                              : null}
                            {m.text.hasOwnProperty('latitude') == false ? m.text.search("SystemNextloopNoti") < 0 ? <img src={currentUserInfo.userPhotoURL} id='meProfilePic' style={{ width: "30px", height: "30px" }}></img> : null : null}
                            {m.text.hasOwnProperty('latitude') ?
                              <>

                                <div class='me' ref={message_index + 1 == messages.length ? dummy : null}>
                                  <div>
                                    {m.text.hasOwnProperty("allVideoURL")
                                      ? m.text.allVideoURL.map((indivURL, index) => {
                                        return (
                                          <video
                                            key={index}
                                            style={{ width: "50%", height: "200px", cursor: "pointer" }}
                                            controls
                                            onClick={() => zoomVideofunction(indivURL)}

                                          >
                                            <source src={indivURL} type="video/mp4" />
                                            Your browser does not support the video tag.
                                          </video>
                                        );
                                      })
                                      : null}
                                    {m.text.hasOwnProperty('allImageURL') ? m.text.allImageURL.map((indivURL) => {
                                      return (
                                        <>
                                          <img src={indivURL} style={{ maxWidth: "100px", maxHeight: "100px", cursor: "pointer" }} onClick={() => zoomPicfunction(indivURL)} alt='Not Available'></img>
                                        </>
                                      )
                                    }) : null}
                                    {m.text.hasOwnProperty("allVideoURL") || m.text.hasOwnProperty('allImageURL') ? <br></br> : null}
                                  </div>
                                  <p>
                                    {"Property Name: " + m.text.propertyCompanyName}
                                    <br></br>
                                    {"Address: " + m.text.propertyAddress}
                                    <br></br>
                                    {"Postal Code: " + m.text.postalCode}
                                    <br></br>
                                    {"District: " + m.text.district}
                                    <br></br>
                                    {"PSF: " + m.text.PSFValue}
                                    {m.text.sellingPrice != "" ? <><br></br>Selling Price: ${m.text.sellingPrice}</> : null}
                                    {m.text.commission != "NA" ? <><br></br>Commission: ${m.text.commission}</> : null}
                                    {m.text.agentRepresented == false ? <>
                                      <br></br>
                                      {m.text.verified == "notApprove" || m.text.verified == "pending" ? "Verified: Not Approve" : "Verified: Approve"}</> : null}

                                  </p>
                                </div>
                              </> :
                              m.text.search("https://firebasestorage.googleapis.com") >= 0 ?
                                <div class='me' style={{ backgroundColor: "transparent" }} ref={message_index + 1 == messages.length ? dummy : null}>
                                  {m.text.search("https://firebasestorage.googleapis.com") >= 0 ? m.contentType == "image" ?
                                    <img id="imageLink" src={m.text} onClick={() => zoomPicfunction(m.text)}></img> :
                                    <a id="documentLink" href={m.text} target="_blank" download>Document <i class="fa-regular fa-file"></i></a> :
                                    <p>{m.text}</p>}

                                </div> :
                                <div class={m.text.hasOwnProperty('latitude') == false ? m.text.search("SystemNextloopNoti") >= 0 ? 'systemNoti' : "me" : "me"} ref={message_index + 1 == messages.length ? dummy : null}>

                                  {m.text.hasOwnProperty('latitude') == false ? m.text.search("SystemNextloopNoti") >= 0 ? m.text.search("LOI Submitted") >= 0 ? <b><p style={{ cursor: "pointer" }} onClick={() => openLOI()}>{m.text.split("SystemNextloopNoti: ")[1]}</p></b> : <b><p>{m.text.split("SystemNextloopNoti: ")[1]}</p></b> : <p>{m.text}</p> : null}

                                </div>
                            }

                            <div style={{ clear: 'both' }}></div>

                          </>
                        )
                      }
                      else {

                        return (
                          <>
                            {m.image != null ?
                              <>
                                <img src={data.photoURL} id='otherProfilePic' style={{ width: "30px", height: "30px" }}></img>
                                <img src={m.image} style={{ width: "250px", height: "250px", float: "left", marginLeft: "2%" }}></img>
                                <div style={{ clear: 'both' }}></div>
                              </>
                              : null}
                            {m.text.hasOwnProperty('latitude') == false ? m.text.search("SystemNextloopNoti") < 0 ? <img src={data.photoURL} id='otherProfilePic' style={{ width: "30px", height: "30px" }}></img> : null : null}
                            {m.text.hasOwnProperty('latitude') ?
                              <>

                                <div class='otherParty' ref={message_index + 1 == messages.length ? dummy : null}>
                                  <div>
                                    {m.text.hasOwnProperty("allVideoURL")
                                      ? m.text.allVideoURL.map((indivURL, index) => {
                                        return (
                                          <video
                                            key={index}
                                            style={{ width: "50%", height: "200px", cursor: "pointer" }}
                                            controls
                                            onClick={() => zoomVideofunction(indivURL)}

                                          >
                                            <source src={indivURL} type="video/mp4" />
                                            Your browser does not support the video tag.
                                          </video>
                                        );
                                      })
                                      : null}
                                    {m.text.hasOwnProperty('allImageURL') ? m.text.allImageURL.map((indivURL) => {
                                      return (
                                        <>
                                          <img src={indivURL} style={{ maxWidth: "100px", maxHeight: "100px", cursor: "pointer" }} onClick={() => zoomPicfunction(indivURL)} alt='Not Available'></img>
                                        </>
                                      )
                                    }) : null}
                                    {m.text.hasOwnProperty("allVideoURL") || m.text.hasOwnProperty('allImageURL') ? <br></br> : null}
                                  </div>

                                  <p>
                                    {"Property Name: " + m.text.propertyCompanyName}
                                    <br></br>
                                    {"Address: " + m.text.propertyAddress}
                                    <br></br>
                                    {"Postal Code: " + m.text.postalCode}
                                    <br></br>
                                    {"District: " + m.text.district}
                                    <br></br>
                                    {"PSF: " + m.text.PSFValue}
                                    {m.text.sellingPrice != "" ? <><br></br>Selling Price: ${m.text.sellingPrice}</> : null}
                                    {m.text.commission != "NA" ? <><br></br>Commission: ${m.text.commission}</> : null}
                                    {m.text.agentRepresented == false ? <>
                                      <br></br>
                                      {m.text.verified == "notApprove" || m.text.verified == "pending" ? "Verified: Not Approve" : "Verified: Approve"}</> : null}

                                  </p>
                                </div>
                              </> :
                              m.text.search("https://firebasestorage.googleapis.com") >= 0 ?
                                <div class={m.text.search("SystemNextloopNoti") >= 0 ? 'systemNoti' : 'otherParty'} style={{ backgroundColor: "transparent" }} ref={message_index + 1 == messages.length ? dummy : null}>
                                  {m.text.search("https://firebasestorage.googleapis.com") >= 0 ? m.contentType == "image" ? <img id="imageLink" src={m.text} onClick={() => zoomPicfunction(m.text)}></img> : <a id="documentLink" href={m.text} target="_blank" download>Document <i class="fa-regular fa-file"></i></a> :
                                    m.text.search("SystemNextloopNoti") >= 0 ? <b><p>{m.text.split("SystemNextloopNoti: ")[1]}</p></b> : <p>{m.text}</p>}

                                </div> :
                                <div class={m.text.search("SystemNextloopNoti") >= 0 ? 'systemNoti' : 'otherParty'} ref={message_index + 1 == messages.length ? dummy : null}>
                                  {m.text.search("https://firebasestorage.googleapis.com") >= 0 ? m.contentType == "image" ? <img id="imageLink" src={m.text} onClick={() => zoomPicfunction(m.text)}></img> : <a id="documentLink" href={m.text} target="_blank" download>Document <i class="fa-regular fa-file"></i></a> :
                                    m.text.search("SystemNextloopNoti") >= 0 ? m.text.search("LOI Submitted") >= 0 ? <b><p style={{ cursor: "pointer" }} onClick={() => openLOI()}>{m.text.split("SystemNextloopNoti: ")[1]}</p></b> : <b><p>{m.text.split("SystemNextloopNoti: ")[1]}</p></b> : <p>{m.text}</p>}

                                </div>
                            }

                            <div style={{ clear: 'both' }}></div>
                          </>
                        )
                      }


                    }) : null}
                </div>
                <div style={{ clear: 'both' }}></div>

              </div>
              <div id="messageRightForm">
                {chatClick == true && messages != null && messages.length > 1 ? <div style={{ border: "1px solid #33434d", width: "fit-content", display: "flex", flexDirection: "row", padding: "8px 8px", borderRadius: "10px", width: "100%" }}>
                  <i class="fa-solid fa-circle-exclamation" id="notificationIcon" ></i>
                  <div id="chatSafetyTip" >
                    <p style={{ fontWeight: "bold" }}>Safety tip</p>
                    <ul>
                      <li>Do check your landlord's or seller's title deed before making any payment. We Strongly recommend users to transact in person at all times.</li>
                    </ul>
                  </div>
                </div> : null}
                {chatClick == true && messages != null && messages.length > 1 &&
                  navID.search(user.uid) >= 0 ? data.status != "Deleted" ?
                  <form onSubmit={sendMessage}>
                    <div style={{ display: "flex", alignItems: "center", borderTop: "1px solid #CCC" }}>
                      <i className="fa-regular fa-image" id="messageImage" style={data.status != "Closed" ? {} : { color: "grey" }} onClick={data.status != "Closed" ? handleImageUpload : null} />
                      <i className="fa-solid fa-paperclip" id="messagePaperClip" style={data.status != "Closed" ? {} : { color: "grey" }} onClick={data.status != "Closed" ? handleFileUpload : null} />
                      <textarea value={formValue} style={{ resize: "none" }} onChange={(e) => setFormValue(e.target.value)} onKeyDown={handleKeyDown} placeholder="Type something here" />
                      <button type="submit"><i class="fa-regular fa-paper-plane"></i></button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept=".ppt,.pptx,.doc,.docx,.pdf"
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                      />
                      <input
                        type="file"
                        ref={imageInputRef}
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImageInputChange}
                      />
                    </div>
                  </form> :
                  <form disabled style={{ color: "grey" }}>
                    <div style={{ display: "flex", alignItems: "center", borderTop: "1px solid #CCC" }}>
                      <i className="fa-regular fa-image" id="messageImage" />
                      <i className="fa-solid fa-paperclip" id="messagePaperClip" />
                      <textarea style={{ resize: "none" }} placeholder="Type something here" disabled />
                      <button type="submit" disabled><i class="fa-regular fa-paper-plane"></i></button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept=".ppt,.pptx,.doc,.docx,.pdf"
                        style={{ display: "none" }}
                        disabled
                      />
                      <input
                        type="file"
                        ref={imageInputRef}
                        accept="image/*"
                        style={{ display: "none" }}
                        disabled
                      />
                    </div>
                  </form> : null}
              </div>
            </div>

          </>
          : <>
            <Spinner></Spinner></>
        }



        <div style={{ clear: 'both' }}></div>
      </>
    )
  }
}

